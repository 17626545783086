import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import ListItemICPOLD from 'common/old-refactory/list/inner/ListItemICPOLD'
import { TextCP } from 'main/common/components/text/TextCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ISalesPaymentReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesPaymentReportFilterRequestDTO'
import { ISalesPaymentReportListItemRespDTO } from 'main/modules/sale/services/sale/dtos/responses/ISalesPaymentReportListItemRespDTO'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ListCP } from 'main/common/components/list/ListCP'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { ChartPieCP, PieChartSeriesTP } from 'main/common/components/chart/ChartPieCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface ICPProps {
    dateFilter: IDateRangeFilter
    showChart?: boolean
    franchiseCodes?: number[]
}

/**
 * Exibe widgets relacionados vendas por forma de pagamento
 */
export function ListSalesByPaymentMethodCP(props: ICPProps): JSX.Element {

    const [chartData, setChartData] = useState<PieChartSeriesTP[]>([])
    const [salesPaymentList, setSalesPaymentList] = useState<ISalesPaymentReportListItemRespDTO[]>()

    const salesPaymentRequest = useRequest<IGenericListResponseDTO<ISalesPaymentReportListItemRespDTO>>()
    useEffect(onSalesPaymentRequestChange, [salesPaymentRequest.isAwaiting])

    useEffect(defineSalesPayment, [props.dateFilter])

    /** Obtem vendas por forma de pagamento da API. */
    function defineSalesPayment(): void {

        const requestDto: ISalesPaymentReportFilterRequestDTO = {
            beginDate: moment(props.dateFilter.beginDate).format('YYYY-MM-DD'),
            endDate: moment(props.dateFilter.endDate).format('YYYY-MM-DD'),
            franchiseCodes: props.franchiseCodes,
        }

        salesPaymentRequest.runRequest(SaleRequests.getSalesPaymentConfig(requestDto))
    }

    /** Trata quando request das lista de requisicao retorna resultado. */
    function onSalesPaymentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(salesPaymentRequest, 'Erro obter vendas por formas de pagamento'))
            return

        const result = salesPaymentRequest.responseData!.list
        setSalesPaymentList(result)

        // Transforma os dados retornados para montar o grafico
        const _chartData: PieChartSeriesTP[] = []
        result.forEach((current) => {

            // Monta dado para cada fonte do lead
            _chartData.push({
                id: StringUtils.getSlugStyleString(current.paymentMethod),
                label: current.paymentMethod,
                value: current.percentageValue,
            })
        })
        setChartData(_chartData)
    }

    return (
        <FlexCP alignItems={'center'}>
            <ConditionalRenderCP shouldRender={!!props.showChart}>
                <ChartPieCP
                    height={300}
                    width={300}
                    series={chartData}
                    loading={salesPaymentRequest.isAwaiting}
                />
            </ConditionalRenderCP>

            <CardCP title={'Análise por Forma de Pagamento'} innerSpacing={'small'} margin={'0 0 0 20px'}>
                <ListCP
                    bordered={false}
                    dataSource={salesPaymentList}
                    loading={salesPaymentRequest.isAwaiting}
                    renderItem={(item: ISalesPaymentReportListItemRespDTO) => (
                        <ListItemICPOLD title={item.paymentMethod}>
                            <>
                                <TextCP text={item.netValue ? `${MaskUtils.applyMoneyMask(`${item.netValue}`)}` : 'R$0,00'}/>
                                <TextCP style={{ marginLeft: 5 }} text={`(${item.percentageValue}%)`}/>
                            </>
                        </ListItemICPOLD>
                    )}
                />
            </CardCP>
        </FlexCP>
    )
}
