import { faChartLine, faCopy, faPencilAlt, faPowerOff, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type _ActionTP = 'clone' | 'delete' | 'unschedule'

interface IShotListItemActionsColumnICPProps {
    shotType: MarketingAccountTypeEnum
    shotStatus: MarketingShotStatusEnum
    shotCode: number
    onUpdateList?: () => void
}

/**
 *
 * @author renatofs
 */
export function ShotListItemActionsColumnICP(props: IShotListItemActionsColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [action, setAction] = useState<_ActionTP>()
    const actionRequest = useRequest<any>('none')
    useEffect(onActionRequestChange, [actionRequest.isAwaiting])

    function onActionRequestChange(): void {

        if (!RequestUtils.isRequestConcluded(actionRequest))
            return

        if (RequestUtils.isRequestError(actionRequest, true))
            return RequestUtils.showDefaultErrorNotification(actionRequest.error, 'Erro ao executar a ação escolhida')

        switch (action) {
            case 'clone':
                NotificationHelper.success('Yes', 'E-mail clonado com sucesso')
                RoutingHelper.historyPush(`${MarketingRoutes.NEW_SHOT}/${props.shotType}/${((actionRequest.responseData?.data?.code ?? 0) as number).toString()}`)
                break

            case 'delete':
                NotificationHelper.success('Yes', 'E-mail removido com sucesso')
                if (props.onUpdateList)
                    props.onUpdateList()
                break

            case 'unschedule':
                NotificationHelper.success('Yes', 'O agendamento do envio foi cancelado com sucesso')
                if (props.onUpdateList)
                    props.onUpdateList()
                break
            // no default
        }
    }

    async function runActionRequest(selectedAction: _ActionTP): Promise<void> {

        let configGetter: (shotType: MarketingAccountTypeEnum, shotCode: number) => Promise<RequestConfigTP>

        switch (selectedAction) {
            case 'clone':
                configGetter = MarketingRequests.cloneCampaignShot
                break
            case 'delete':
                configGetter = MarketingRequests.deleteCampaignShot
                break
            case 'unschedule':
                configGetter = MarketingRequests.unscheduleCampaignShot
                break
            // no default
        }

        const requestConfig = await configGetter(props.shotType, props.shotCode)
        actionRequest.runRequest(requestConfig)
        setAction(selectedAction)
    }

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <LoadingOverlayCP show={actionRequest.isAwaiting}/>

            {
                props.shotStatus === MarketingShotStatusEnum.DRAFT &&
                <>
                    <TooltipCP text={'Editar'} placement={'left'}>
                        <div onClick={() => RoutingHelper.historyPush(`${MarketingRoutes.NEW_SHOT}/${props.shotType}/${props.shotCode}`)}>
                            <FontAwsomeIconCP type={faPencilAlt} color={ThemeAnt.primaryColor}/>
                        </div>
                    </TooltipCP>

                    <PopConfirmCP
                        title={'Tem certeza que deseja remover este e-mail?'}
                        onConfirm={async () => runActionRequest('delete')}
                    >
                        <div>
                            <FontAwsomeIconCP type={faTrash} color={ThemeAnt.primaryColor}/>
                        </div>
                    </PopConfirmCP>
                </>
            }

            <TooltipCP text={'Clonar'} placement={'left'}>
                <div onClick={async () => runActionRequest('clone')}>
                    <FontAwsomeIconCP type={faCopy} color={ThemeAnt.primaryColor}/>
                </div>
            </TooltipCP>

            {
                props.shotStatus === MarketingShotStatusEnum.SCHEDULED &&
                <PopConfirmCP
                    title={'Tem certeza que deseja cancelar o envio deste e-mail?'}
                    onConfirm={async () => runActionRequest('unschedule')}
                >
                    <div>
                        <FontAwsomeIconCP type={faPowerOff} color={ThemeAnt.primaryColor}/>
                    </div>
                </PopConfirmCP>
            }

            {
                ([MarketingShotStatusEnum.SENT, MarketingShotStatusEnum.ERROR].includes(props.shotStatus)) &&
                <TooltipCP text={'Ver Relatório'} placement={'left'}>
                    <div onClick={() => RoutingHelper.historyPush(`${MarketingRoutes.SHOT_DETAILS}/${props.shotType}/${props.shotCode}`)}>
                        <FontAwsomeIconCP type={faChartLine} color={ThemeAnt.primaryColor}/>
                    </div>
                </TooltipCP>
            }
        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`

    display: ${props => (props.isSmallDevice ? 'flex' : 'inline-grid')};
    padding: 10px;
    align-items: center;
    border-left: 1px solid #dedede;
    background: #f9f9f9;
    justify-content: ${props => (props.isSmallDevice ? 'space-evenly' : 'center')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    div {
        cursor: pointer;
    }
`
