import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'

type _NumberFrom1To24TP = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24

type _AntPropsTP = {
    size: _NumberFrom1To24TP,
    xs?: _NumberFrom1To24TP,
    sm?: _NumberFrom1To24TP,
    md?: _NumberFrom1To24TP,
    lg?: _NumberFrom1To24TP,
}

type _CustomPropsTP = {
    centerContent?: boolean,
}

interface IColumnCPProps extends React.PropsWithChildren<_AntPropsTP & _CustomPropsTP> { }

/**
 * COMPONENTE
 * COLUNA para alinhamento de layout em GRID.
 *
 * @todo: Adicionar wrapper
 *
 * @author renatofs
 * @author hjcostabr
 */
export function ColumnCP(props: IColumnCPProps): JSX.Element {
    return (
        <ColSCP
            centerContent={!!props.centerContent}
            span={props.size}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
        >
            {props.children}
        </ColSCP>
    )
}

const ColSCP = styled(Col) <{ centerContent: boolean, span: _NumberFrom1To24TP }>`
    &.ant-col-${props => props.span} {
        ${props => (props.centerContent ? 'display: flex;' : '')}
        ${props => (props.centerContent ? 'justify-layout: center;' : '')}
        ${props => (props.centerContent ? 'align-items: center;' : '')}
    }
`
