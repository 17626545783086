import React from 'react'
import { IAddressResponseDTO } from 'main/modules/people/services/person/dtos/response/IAddressResponseDTO'
import styled from 'styled-components'

interface IAddressInfoICPProps {
    address?: IAddressResponseDTO
}

export function AddressInfoICP(props: IAddressInfoICPProps): JSX.Element {

    if (!props.address)
        return <> Sem dados de endereço </>

    return (
        <WrapperSCP>
            <AddressWrapperSCP>
                <p>
                    <>{props.address.street && props.address.street}</>
                    <>{props.address.number && `, ${props.address.number}`}</>
                    <>{props.address.complement && ` / ${props.address.complement} `}</>
                </p>
                <p>
                    <>{props.address.neighborhood && `${props.address.neighborhood}`}</>
                    <>{props.address.city && ` - ${props.address.city}`}</>
                    <>{props.address.state && ` / ${props.address.state}`}</>
                </p>
            </AddressWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    flex-direction: column;
    position: relative;
    &::after {
        content: '';
        height: 50px;
        border-radius: 0 10px 10px 0;
        width: 4px;
        background: ${props => props.theme.primaryColor};
        position: absolute;
        top: 0;
        left: -24px;
    }

`

const AddressWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    span{
        font-size: 13px;
    }
    p{
        margin: 0;
    }
   
`
