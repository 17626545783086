import React from 'react'
import { TagCP } from 'main/common/components/tag/TagCP'
import { BulkOperationStatusEnum, BulkOperationStatusLabelEnum } from 'main/modules/admin/services/bulk-operation/enums/BulkOperationStatusEnum'
import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { LoadingIconCP } from 'main/common/components/icons/LoadingIconCP'

interface ICPProps {
    status: BulkOperationStatusEnum
}

/**
 * COMPONENTE
 * Status de 01 importacao, na lista de historico de importacoes.
 */
export function TagBulkOperationStatusCP(props: ICPProps): JSX.Element {

    let color: TagCPColorTP
    switch (props.status) {

        case BulkOperationStatusEnum.CONCLUDED:
            color = 'green'
            break

        case BulkOperationStatusEnum.ERROR:
            color = 'red'
            break

        case BulkOperationStatusEnum.IN_PROGRESS:
        default:
            color = 'yellow'
            break
    }

    return (
        <FlexCP>
            <ConditionalRenderCP shouldRender={props.status === BulkOperationStatusEnum.IN_PROGRESS}>
                <LoadingIconCP/>
            </ConditionalRenderCP>
            <TagCP
                content={BulkOperationStatusLabelEnum[props.status]}
                color={color}
                minWidth={73}
                small={true}
            />
        </FlexCP>
    )
}
