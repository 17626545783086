import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DomainFormValidator } from 'main/modules/marketing/components/modal-domain/inner/DomainFormValidator'
import { IMarketingDomainSaveRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingDomainSaveRequestDTO'
import { IMarketingDomainResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainResponseDTO'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

interface IDomainModalCPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    domainCode?: number
}

/**
 * COMPONENTE modal de dados de dominio.
 */
export function ModalDomainCP(props: IDomainModalCPProps): JSX.Element | null {

    const [formValidator] = useState<DomainFormValidator>((new DomainFormValidator()))
    const formStateManager = useFormStateManager<DomainFormValidator>(formValidator)

    const saveDomainRequest = useRequest<IMarketingDomainResponseDTO>()
    useEffect(onSaveDomainRequestChange, [saveDomainRequest.isAwaiting])

    /**
     * Salva dominio
     */
    async function saveDomain(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const domainDto: IMarketingDomainSaveRequestDTO = {
            domain: formValues.name
        }

        saveDomainRequest.runRequest(await MarketingRequests.getSaveDomainConfig(domainDto))
    }

    /**
     * Apos reotorno da api de salvar dominio
     */
    function onSaveDomainRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveDomainRequest, 'Ocorreu algun erro ao salvar dominio'))
            return

        NotificationHelper.info('Yes', 'Domínio criado com sucesso')

        formStateManager.reset(undefined)
        props.onSave()
    }

    return (
        <ModalCP
            title={'Novo domínio'}
            onOk={saveDomain}
            onCancel={props.onCancel}
            visible={props.visible}
            actionLoading={saveDomainRequest.isAwaiting}
        >
            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        label={'Domínio'}
                        formStateManager={formStateManager}
                        fieldName={'name'}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>
        </ModalCP>
    )
}
