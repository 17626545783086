import { SliderInputCP } from 'main/common/components/slider-input/SliderInputCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IProductReleaseFranchiseSaveRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/IProductReleaseFranchiseSaveRequestDTO'
import { ProductReleaseFranchiseRequests } from 'main/modules/products/services/product-release-franchise/ProductReleaseFranchiseRequests'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'

interface IPriceRangeSliderInputCPProps {
    minValue: number
    maxValue: number
    hasPrice: boolean
    value: number
    productReleaseCode: number
    productCode: number
    onEditDone: () => void
}

/**
 * Componente de slider e input sincronizados para seleção do preço de um pacote de serviços
 */
export function PriceRangeSliderInputCP(props: IPriceRangeSliderInputCPProps): JSX.Element {

    // Caso tenha mudado os valores max e min da release, e o valor atual nao esta no range mais
    const isMinAndMaxPriceEqual: boolean = props.minValue === props.maxValue
    const isCurrentPriceDifferentMinOrMax: boolean = (isMinAndMaxPriceEqual && (props.minValue > props.value || props.maxValue < props.value))
    const canChoosePrice: boolean = (!isMinAndMaxPriceEqual || isCurrentPriceDifferentMinOrMax)

    const [inputValue, setInputValue] = useState<string>(MaskUtils.applyMoneyMask(props.value))
    

    const savePriceRequest = useRequest<number>()
    useEffect(onSavePriceRequestChange, [savePriceRequest.isAwaiting])
    function onPriceChanges(value: any): void {

        if (isNaN(value) || !value)
            return

        setInputValue(MaskUtils.applyMoneyMask(value))
    }


    function onSavePrice(): void {

        if (props.hasPrice) {
            const dto: IProductReleaseFranchiseSaveRequestDTO = {
                price: MoneyUtils.convertToFloat(inputValue)
            }
            savePriceRequest.runRequest(ProductReleaseFranchiseRequests.update(dto, props.productCode))
            return
        }

        const dto: IProductReleaseFranchiseSaveRequestDTO = {
            productReleaseCode: props.productReleaseCode,
            price: MoneyUtils.convertToFloat(inputValue)
        }
        savePriceRequest.runRequest(ProductReleaseFranchiseRequests.create(dto))
    }

    function onSavePriceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePriceRequest, 'Erro ao salvar preço do pacote.'))
            return

        NotificationHelper.success('Pacote configurado com sucesso!')
        props.onEditDone()
    }

    return (
        <WrapperSCP>
            <RowCP>
                {
                    !isMinAndMaxPriceEqual &&
                    <ColumnCP size={9}>
                        <SliderInputCP
                            minValue={props.minValue}
                            maxValue={props.maxValue}
                            value={MoneyUtils.convertToFloat(inputValue)}
                            step={1}
                            onChange={onPriceChanges}
                            marks={{
                                [props.maxValue]: {
                                    style: {
                                        top: '-2.8em',
                                        left: '91%',
                                    },
                                    label: `${MaskUtils.applyMoneyMask(`${props.maxValue}`)}`
                                },
                                [props.value]: `${MaskUtils.applyMoneyMask(`${props.value}`)}`,
                                [props.minValue]: {
                                    style: {
                                        top: '-2.8em',
                                        left: '8%',
                                    },
                                    label: `${MaskUtils.applyMoneyMask(`${props.minValue}`)}`
                                },
                            }}
                            tipFormatter={(value) => <span>{MaskUtils.applyMoneyMask(`${value}`)}</span>}
                        />
                    </ColumnCP>
                }

                <ColumnCP size={9}>
                    <InputCP
                        mask={InputMaskTypeEnum.MONEY}
                        value={inputValue}
                        onChange={setInputValue}
                        disabled={!canChoosePrice}
                    />
                </ColumnCP>

                

                <ColumnCP size={6}>
                    <FlexCP justifyContent={'flex-end'} alignItems={'flex-end'}>
                        <ButtonCP
                            onClick={onSavePrice}
                            type={'primary'}
                            icon={'check'}
                            loading={savePriceRequest.isAwaiting}
                            disabled={(props.value === MoneyUtils.convertToFloat(inputValue) && props.hasPrice)}
                        />
                        <ButtonCP
                            onClick={() => setInputValue(MaskUtils.applyMoneyMask(props.value))}
                            icon={'close'}
                            type={'danger'}
                            marginLeft={5}
                            disabled={(props.value === MoneyUtils.convertToFloat(inputValue) && props.hasPrice)}
                        />
                    </FlexCP>
                </ColumnCP>
            </RowCP>
            
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  width: 100%;
  .sub{
    color: #2b698cff;
    font-weight: 589;
  }
  .painel-comissao{
    padding-bottom: 14px;
    margin-inline: 10px;
    border-radius: 8px;
    padding-left: 14px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .dir-botao{
    display:flex;
    justify-content: right;
    margin-right: 10px;
    margin-top: 4px;
  }
`

