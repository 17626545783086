import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'

/**
 * Util de filtros de vendas.
 */
export const SalesFiltersUtils = {

    /**
     * Limpa os filtros da tela.
     */
    clearFilters(filterStateManager: IFormStateManager<SalesFiltersFormModel>): void {

        filterStateManager.changeFieldValue('dateRange', undefined)
        filterStateManager.changeFieldValue('status', undefined)
        filterStateManager.changeFieldValue('searchString', undefined)
        filterStateManager.changeFieldValue('personCode', undefined)
        filterStateManager.changeFieldValue('showCancelled', undefined)
        filterStateManager.changeFieldValue('userSellerCode', undefined)
        filterStateManager.changeFieldValue('userSellerName', undefined)
        filterStateManager.changeFieldValue('valueMin', undefined)
        filterStateManager.changeFieldValue('valueMax', undefined)
    }
}

