import { registerDecorator, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { ValidationTypes } from 'main/common/validation/ValidationTypes'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

/**
 * VALIDATOR
 * Valida se é uma data válida.
 *
 * TODO: colocar a formatação da string por parâmetro para ser genérico
 */
@ValidatorConstraint({ name: ValidationTypes.IS_DATE_STRING })
class IsDateStringConstraint implements ValidatorConstraintInterface {

    validate(value: string/*, args: ValidationArguments*/): boolean {
        const date = DateUtilsOLD.toDate(value, DateFormatEnum.BR_WITHOUT_TIME)
        const validator = new Validator()
        return validator.isDate(date)
    }

    defaultMessage(/*args: ValidationArguments*/): string {
        return 'Data inválida! (dd/mm/aaaa)'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsDateString(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsDateStringConstraint,
        })
    }
}
