import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { IDokfyPersonSaveRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyPersonSaveRequestDTO'
import { IDokfyCreateDocumentRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyCreateDocumentRequestDTO'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IDokfyUserLoginRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyUserLoginRequestDTO'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { IDokfyDocumentSearchRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyDocumentSearchRequestDTO'

/**
 * Requests de comunicacao com DOKFY
 */
export class DokfyRequests {

    private static readonly _PERSON_MODULE_BASE = `${SystemConfig.getInstance().dokfyBaseUrl}/people`
    private static readonly _DOCUMENTS_MODULE_BASE = `${SystemConfig.getInstance().dokfyBaseUrl}/documents`

    private static readonly _ERROR_NO_MODOULE_ACCESS = 'Usuario nao possui acesso a Dokfy'

    static async getPersonByCpf(cpfCnpj: string): Promise<RequestConfigTP> {
        return await DokfyRequests._getRequestConfig({
            url: `${DokfyRequests._PERSON_MODULE_BASE}/by-cpf-cnpj/${MaskUtils.removeMask(cpfCnpj)}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async getPersonInviteConfig(dto: IDokfyPersonSaveRequestDTO): Promise<RequestConfigTP> {
        return await DokfyRequests._getRequestConfig({
            url: `${DokfyRequests._PERSON_MODULE_BASE}/invite`,
            method: HttpMethodEnum.POST,
            params: dto,
        })
    }

    static async createDocumentConfig(file: File, dto: IDokfyCreateDocumentRequestDTO): Promise<RequestConfigTP> {

        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(dto))

        return await DokfyRequests._getRequestConfig({
            url: `${DokfyRequests._DOCUMENTS_MODULE_BASE}/ELECTRONIC_SIGNATURE`,
            method: HttpMethodEnum.POST,
            params: formData
        })
    }

    static async getDocumentSearchConfig(dto: IDokfyDocumentSearchRequestDTO): Promise<RequestConfigTP> {
        return await DokfyRequests._getRequestConfig({
            url: DokfyRequests._DOCUMENTS_MODULE_BASE,
            method: HttpMethodEnum.GET,
            params: dto
        })
    }

    static async getDocumentConfig(code: number): Promise<RequestConfigTP> {
        return await DokfyRequests._getRequestConfig({
            url: `${DokfyRequests._DOCUMENTS_MODULE_BASE}/${code}`,
            method: HttpMethodEnum.GET,
        })
    }

    /**
     * Monta as configuracoes, HEADER, para enviar na requisicao.
     */
    private static async _getRequestConfig(customConfig: RequestConfigTP): Promise<RequestConfigTP> {

        let error: unknown

        try {
            if (!PermissionUtils.isModuleAvailable(ModuleEnum.MODULE_DOKFY))
                throw new Error(DokfyRequests._ERROR_NO_MODOULE_ACCESS)

            const token = await DokfyRequests._getDokfyAuthToken()
            if (!token)
                throw new Error(DokfyRequests._ERROR_NO_MODOULE_ACCESS)

            return {
                ...customConfig,
                headers: [AuthUtils.getBearerAuthHeaderConfig(token)]
            }

        } catch (exception) {
            const aux = DokfyRequests._handleFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    /**
     * Obtem o token de autenticacao para enviar na requisicao principal.
     */
    public static async _getDokfyAuthToken(): Promise<string | undefined> {

        if (!AppStateUtils.getCurrentFranchise()?.integrationKeys?.dokfy)
            throw new Error(DokfyRequests._ERROR_NO_MODOULE_ACCESS)

        const dto: IDokfyUserLoginRequestDTO = {
            email: AppStateUtils.getCurrentFranchise()!.integrationKeys.dokfy!.login,
            password: AppStateUtils.getCurrentFranchise()!.integrationKeys.dokfy!.password
        }

        const requestConfig: RequestConfigTP = {
            url: `${SystemConfig.getInstance().dokfyBaseUrl}/auth/login`,
            method: HttpMethodEnum.POST,
            params: dto,
            noAuth: true,
        }

        type RequestReturnTP = IApiReturn<{ token: string }>
        const result = await RequestHelper.runRequest<RequestReturnTP>(requestConfig)

        return result?.data.data?.token
    }

    /**
     * Trata algum erro na preparacao para requisicao da API.
     */
    private static _handleFailure(exception: any): any {
        console.error('FALHA: ', exception)
        if (exception.message !== DokfyRequests._ERROR_NO_MODOULE_ACCESS)
            return exception
        RoutingHelper.historyReplace('default')
    }

}
