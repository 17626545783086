import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ProductReleaseFormICP } from 'main/modules/products/components/drawer-product-release/inner/ProductReleaseFormICP'
import { IProductReleaseSaveRequestDTO } from 'main/modules/products/services/product-release/dtos/request/IProductReleaseSaveRequestDTO'
import { ProductReleaseFormModel } from 'main/modules/products/components/drawer-product-release/inner/ProductReleaseFormModel'
import { IProductReleaseResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseResponseDTO'
import { ProductReleaseRequests } from 'main/modules/products/services/product-release/ProductReleaseRequests'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { IProductReleaseTreatmentConfigSaveRequestDTO } from 'main/modules/products/services/product-release/dtos/request/IProductReleaseTreatmentConfigSaveRequestDTO'
import * as _ from 'lodash'

interface IDrawerProductReleaseCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    productReleaseCode?: number
}

/**
 * Drawer de Pacote de Atendimento.
 */
export function DrawerProductReleaseCP(props: IDrawerProductReleaseCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<ProductReleaseFormModel>((new ProductReleaseFormModel()))
    const formStateManager = useFormStateManager<ProductReleaseFormModel>(formValidator)

    const getProductReleaseRequest = useRequest<IProductReleaseResponseDTO>()
    useEffect(onGetProductReleaseRequestChange, [getProductReleaseRequest.isAwaiting])

    const saveProductReleaseRequest = useRequest<IProductReleaseResponseDTO>()
    useEffect(onSaveProductReleaseRequestChange, [saveProductReleaseRequest.isAwaiting])

    const deleteProductReleaseRequest = useRequest<void>('none')
    useEffect(onDeleteProductReleaseRequestChange, [deleteProductReleaseRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        if (!props.visible)
            return

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        formStateManager.reset(new ProductReleaseFormModel({
            active: true
        }))

        if (props.productReleaseCode)
            return getProductReleaseRequest.runRequest(ProductReleaseRequests.findOne(props.productReleaseCode))

    }

    /**
     * Retorno da requisicao que obtem a releases selecionada.
     */
    function onGetProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getProductReleaseRequest, 'Erro pacote de atendimento'))
            return

        const responseDTO = getProductReleaseRequest.responseData!
        setFormValidator(new ProductReleaseFormModel({
            name: responseDTO.name,
            productCode: responseDTO.product.code,
            active: responseDTO.active,
            minPrice: MaskUtils.applyMoneyMask(responseDTO.minPriceValue),
            maxPrice: MaskUtils.applyMoneyMask(responseDTO.maxPriceValue),
            treatmentConfigs: responseDTO.treatmentsConfig,
        }))
    }

    /**
     * Salva tratamento.
     */
    async function saveProductRelease(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()

        if (_.isEmpty(formValues?.treatmentConfigs)) {
            NotificationHelper.error('Ops', 'Adicione ao menos um atendimento')
            return
        }

        if (!await formStateManager.validate())
            return

        if (!formValues)
            return

        const treatmentConfigsDto: IProductReleaseTreatmentConfigSaveRequestDTO[] = formValues.treatmentConfigs.map((_treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => (
            {
                treatmentCode: _treatmentConfig.treatment.code,
                totalSessions: _treatmentConfig.totalSessions,
                sessionDuration: _treatmentConfig.sessionDuration,
                personPerSession: _treatmentConfig.personPerSession,
                specificAttendant: _treatmentConfig.specificAttendant,
            }
        ))

        const dto: IProductReleaseSaveRequestDTO = {
            productCode: formValues.productCode,
            name: formValues.name,
            active: formValues.active,
            minPrice: MoneyUtils.convertToFloat(formValues.minPrice),
            maxPrice: MoneyUtils.convertToFloat(formValues.maxPrice),
            treatmentConfigs: treatmentConfigsDto
        }

        if (!!props.productReleaseCode)
            saveProductReleaseRequest.runRequest(ProductReleaseRequests.update(props.productReleaseCode, dto))
        else
            saveProductReleaseRequest.runRequest(ProductReleaseRequests.save(dto))
    }

    /**
     * Remover release.
     */
    function deleteProductRelease(): void {

        if (!props.productReleaseCode)
            return

        deleteProductReleaseRequest.runRequest(ProductReleaseRequests.delete(props.productReleaseCode))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteProductReleaseRequest, 'Não foi remover o pacote de atendimento', true))
            return

        NotificationHelper.info('Pronto', 'O Pacote de Atendimento foi removido')
        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveProductReleaseRequest, 'Ocorreu algun erro ao salvar pacote de atendimento'))
            return

        NotificationHelper.info('Yes', 'Pacote de atendimento salvo com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Pacote de Atendimento'}
            visible={props.visible}
            onClose={props.onClose}
            footerSpaced={!!props.productReleaseCode}
            loading={getProductReleaseRequest.isAwaiting}
            footer={
                <>
                    <ConditionalRenderCP shouldRender={!!props.productReleaseCode}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteProductReleaseRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover este pacote?'}
                            onClick={deleteProductRelease}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveProductRelease}
                        loading={saveProductReleaseRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }
        >

            <ProductReleaseFormICP formStateManager={formStateManager}/>

        </DrawerCP>
    )
}
