import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DocumentsRequests } from 'submodules/dokfy-sdk/services/documents/DocumentsRequests'

interface ICPProps {
    documentCode: number
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function ButtonResendDokfyDocumentCP(props: ICPProps): JSX.Element {

    const resendDocumentRequest = useRequest<void>('none')
    useEffect(onResendDocumentRequestChange, [resendDocumentRequest.isAwaiting])

    /**
     * Reenviar
     */
    function resend(): void {

        resendDocumentRequest.runRequest(DocumentsRequests.resend(props.documentCode))
    }

    /**
     *  Retorno da remocao.
     */
    function onResendDocumentRequestChange(): void {

        RequestUtils.isValidRequestReturn(resendDocumentRequest, 'Erro ao reenviar contrato para assinatura', 'Contrato reenviado com sucesso', true)
    }

    return (
        <ButtonCP
            icon={'inbox'}
            type={'ghost'}
            loading={resendDocumentRequest.isAwaiting}
            onClick={resend}
        >
            Reenviar
        </ButtonCP>
    )
}
