import { Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import React from 'react'
import * as _ from 'lodash'
import { MimeTypeEnum } from 'main/common/enums/MimeTypeEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { FileUtils } from 'main/common/utils/FileUtils'
import { MimeTypeUtils } from 'main/common/utils/MimeTypeUtils'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IconUploadCloudCP } from 'main/common/components/icons/IconUploadCloudCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

const MAX_FILE_SIZE_MB = 50

interface IFileUploadCPProps {

    uploadFiles: (file: File) => void

    loading?: boolean
    multiple?: boolean
    fileTypes?: MimeTypeEnum[]
    loadingMsg?: string
    disabled?: boolean
    imgSrc?: string
    hintSize?: FontSizeTP

    maxMbSize?: number

    previewImgHeight?: string
    previewImgWidth?: string

    onProgress?: (percent: number, file: File) => void
    onError?: (error: Error) => void
    onSuccess?: (response: {}, file: File) => void
}

/**
 * Componente responsável por fazer o upload de arquivos para o sitema.
 * Utiliza handlers customizados para fazer este upload.
 */
export function FileUploadCP(props: IFileUploadCPProps): JSX.Element {

    const hasCsv = props.fileTypes?.some(type => MimeTypeUtils.isCsvMimeType(type))

    function validateFile(file: RcFile): boolean {

        // Valida se há algum arquivo
        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }

        // Valida tipo do arquivo
        if (!!props.fileTypes?.length && !props.fileTypes.includes(file.type as MimeTypeEnum) && (!hasCsv || !FileUtils.isCsv(file, file.name))) {
            NotificationHelper.error(`Apenas ${props.fileTypes.join(', ')} são permitidos`)
            return false
        }

        const maxSize = props.maxMbSize ?? MAX_FILE_SIZE_MB
        // Verifica se arquivo possui no maximo 50mb
        if (file.size / 1024 / 1024 > maxSize) {
            NotificationHelper.error(`Somente é permitido arquivos menores que ${maxSize}MB`)
            return false
        }

        return true
    }

    function customRequest(_requestData: RcCustomRequestOptions): void {

        if (!!props.onProgress)
            _requestData.onProgress = (event, file) => props.onProgress!(event.percent, file)

        if (!!props.onError)
            _requestData.onError = props.onError

        if (!!props.onSuccess)
            _requestData.onSuccess = props.onSuccess

        props.uploadFiles(_requestData.file)
    }

    function getAcceptedMimeTypes(): OrUndefTP<string> {
        const acceptedMimes = (props.fileTypes?.length && hasCsv) ? _.uniq([...props.fileTypes, ...MimeTypeUtils.getCsvMimeTypes()]) : props.fileTypes
        return acceptedMimes ? acceptedMimes.join(',') : undefined
    }

    const hintSize = props.hintSize ?? 'normal'

    return (
        <WrapperSCP>
            <Spin spinning={!!props.loading} tip={props.loadingMsg ? props.loadingMsg : 'Fazendo upload ...'}>
                <Upload.Dragger
                    accept={getAcceptedMimeTypes()}
                    showUploadList={false}
                    listType={'picture-card'}
                    beforeUpload={validateFile}
                    customRequest={customRequest}
                    multiple={!!props.multiple}
                    disabled={props.disabled}
                >
                    {
                        !!props.imgSrc ?
                            <ImgSCP
                                imgHeight={props.previewImgHeight}
                                imgWidth={props.previewImgWidth}
                                src={props.imgSrc}
                            />
                            : <>
                                <IconUploadCloudCP size={25} color={ThemeAnt.primaryColor}/>

                                <HintSCP size={hintSize}>
                                    Clique ou arraste arquivos aqui para enviar ao sistema
                                </HintSCP>

                                {
                                    props.fileTypes?.length &&
                                    <HintSCP size={hintSize}>{`Apenas arquivos ${props.fileTypes.join(', ')} são aceitos`}</HintSCP>
                                }
                            </>
                    }
                </Upload.Dragger>
            </Spin>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    .ant-upload.ant-upload-drag {
        
        .ant-upload {
            padding: 5px;
        }

        .ant-upload-drag-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: .95;
            transition: opacity .3s, color .3s;
        }
    }
    .ant-upload.ant-upload-drag:hover {
        .ant-upload-drag-container {
            opacity: 1;
            color: ${props => props.theme.black}
        }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover  {
        border-color: ${props => props.theme.primaryColor}
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: ${props => props.theme.primaryColor}
    }
    .ant-spin-blur {
        opacity: 0.2;
    }
`

const ImgSCP = styled.img<{ imgHeight?: string, imgWidth?: string }>`
    height: ${props => props.imgHeight ?? ''};
    width: ${props => props.imgWidth ?? '100%'};
`

const HintSCP = styled.p<{ size: FontSizeTP }>`
    font-size: ${props => ThemeAnt.fontSizes[props.size]};
`
