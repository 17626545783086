import React from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ColumnProps } from 'antd/lib/table'
import { ISearchProductReleaseRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IProductReleaseFranchiseResponseDTO } from 'main/modules/products/services/product-release-franchise/dtos/response/IProductReleaseFranchiseResponseDTO'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'

interface IProductReleaseTableColumns {
    setFilters: React.Dispatch<React.SetStateAction<ISearchProductReleaseRequestDTO>>
    filters: ISearchProductReleaseRequestDTO
    editProductRelease: (release: IProductReleaseFranchiseResponseDTO) => void
}

export function ProductReleaseFranchiseTableColumns(props: IProductReleaseTableColumns): Array<ColumnProps<IProductReleaseFranchiseResponseDTO>> {
    return [
        {
            title: 'Cód.',
            key: 'code',
            render: item => item.productRelease.code
        },
        {
            title: 'Pacote',
            key: 'name',
            align: 'left',
            render: item => item.productRelease.name
        },
        {
            title: 'Atendimentos',
            key: 'service',
            width: '25%',
            render: item => {
                return item.productRelease.treatmentsConfig.map((service: IProductReleaseTreatmentConfigResponseDTO) => (
                    <TagCP
                        key={service.treatment.name}
                        color={'purple'}
                        onClick={() => props.setFilters({ ...props.filters, treatment: service.treatment.name })}
                        content={`${service.totalSessions} x ${service.treatment.name}`}
                    />
                ))
            },
        },
        {
            title: 'Valor',
            key: 'price',
            align: 'center',
            render: (text, item) => {
                if (item.price !== undefined)
                    return <span style={{ color: '#80bf97', fontWeight: 'bold' }}>{MaskUtils.applyMoneyMask(item.price)}</span>
                return <span style={{ color: '#f59872', fontWeight: 'bold' }}> Não configurado </span>
            },
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: item => (
                <ButtonCP
                    type={'primary'}
                    icon={'setting'}
                    onClick={() => props.editProductRelease(item)}
                />
            )
        }
    ]
}
