import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { MapFranchisesCP } from 'main/modules/dashboard/components/map-franchises/MapFranchisesCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

/**
 * Mapa das unidades
 */
export function ScreenContentFranchisesMap(): JSX.Element {

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState<boolean>(false)

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {

        const beginDateFormatted = moment().startOf('month').toDate()
        const endDateFormatted = moment().endOf('month').toDate()

        const dates: IDateRangeFilter = {
            beginDate: beginDateFormatted,
            endDate: endDateFormatted
        }

        setDateFilters(dates)
    }

    return (
        <>
            <FilterSCP>
                <PopOverCP
                    title={'Período para montar ranking de vendas'}
                    visible={isFilterPopoverVisible}
                    placement={'left'}
                    onClose={() => setIsFilterPopoverVisible(false)}
                    content={
                        <DateRangePickerCP
                            value={dateFilters}
                            onChange={(dates) => {
                                setDateFilters(dates)
                                setIsFilterPopoverVisible(false)
                            }}
                            fastFilter={'all'}
                        />
                    }
                >
                    <ButtonCP
                        onClick={() => setIsFilterPopoverVisible(true)}
                        size={'large'}
                        icon={'sort-ascending'}
                        type={'primary'}
                    />
                </PopOverCP>
            </FilterSCP>

            <MapFranchisesCP dateRange={dateFilters}/>
        </>
    )
}

const FilterSCP = styled.div`
  position: absolute;
  z-index: 9999;
  top: 125px;
  right: 10px;
`
