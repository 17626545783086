import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import React, { useEffect, useState } from 'react'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { TableSalesCP } from 'main/modules/sale/components/table-sales/TableSalesCP'
import { TableSalesBySellerCP } from 'main/modules/reports/components/table-sales-by-seller/TableSalesBySellerCP'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'

interface ISalesBySellerCPProps {
    dateFilter: IDateRangeFilter
    franchiseCodes?: number[]
}

/**
 * Tabela de vendas agrupadas por vendedor
 */
export function TabsSalesAnalysisCP(props: ISalesBySellerCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<SalesFiltersFormModel>((new SalesFiltersFormModel()))
    const formStateManager = useFormStateManager<SalesFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)

    useEffect(init, [props.dateFilter])
    useEffect(loadReport, [formStateManager.getFieldValue('dateRange')])

    /**
     * Inicializa dados.
     */
    function init(): void {

        setFormValidator(new SalesFiltersFormModel({
            considerPppAsSale: false,
            dateRange: props.dateFilter,
        }))
    }

    /**
     * Evento ao setar os filtros no FM, para entao carregas os dados.
     */
    function loadReport(): void {
        if (!!formStateManager.getFormValues())
            setShouldLoadReport(true)
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TabsCP
                tabs={[
                    {
                        title: 'Análise por Vendedor',
                        key: 'by-seller',
                        content: (
                            <TableSalesBySellerCP dateFilter={props.dateFilter} franchiseCodes={props.franchiseCodes}/>
                        ),
                    },
                    {
                        title: 'Vendas',
                        key: 'sales',
                        content: (
                            <TableSalesCP
                                mustUpdateSalesTable={shouldLoadReport}
                                filtersFormStateManager={formStateManager}
                                onLoadDataChanged={() => setShouldLoadReport(false)}
                            />
                        ),
                    },
                ]}
            />
        </CardCP>
    )
}
