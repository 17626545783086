import { Icon } from 'antd'
import { TimePickerCP } from 'main/common/components/timepicker/TimePickerCP'
import React from 'react'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { WorkScheduleValidationTP } from 'main/modules/scheduler/types/WorkScheduleValidationTP'

interface ITimePickerWorkRangeCPProps {
    time?: string
    onChangeTime: (time: string) => void
    timeValidation: WorkScheduleValidationTP
    readonly?: boolean
}

/**
 * Componente com range de horário de trabalho ou intervalo
 */
export function TimePickerWorkRangeCP(props: ITimePickerWorkRangeCPProps): JSX.Element {

    if (!!props.readonly)
        return <>{ props.time }</>

    return (
        <TimePickerCP
            value={DateUtils.getMomentFromTime(props.time)}
            onChange={(t, time) => props.onChangeTime(time)}
            suffixIcon={
                props.timeValidation.isCorrect
                    ?
                    <Icon type={'clock-circle'}/>
                    :
                    <Icon type={'warning'} style={{ color: '#ea4336' }}/>
            }
        />
    )
}
