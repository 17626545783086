import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'
import { CalendarEventsSubtiteColorICP } from 'main/modules/scheduler/components/calendar-events-subtitle/inner/CalendarEventsSubtiteColorICP'
import { CalendarEventsSubtiteIconICP } from 'main/modules/scheduler/components/calendar-events-subtitle/inner/CalendarEventsSubtiteIconICP'
import { faBan, faCheckDouble, faClock, faCommentDots, faDollarSign, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ThemeProjectCommon } from 'submodules/neritclin-components-ui/theme/project/white-labels/ThemeProjectCommon'

/**
 * Legenda da agenda com o significado das cores
 */
export function CalendarEventsSubtitleCP(): JSX.Element {

    return (
        <WrapperSCP>
            <i>Legenda:</i>

            <FlexCP flexWrap={'wrap'}>
                <CalendarEventsSubtiteColorICP text={'Agendado'} color={ThemeProjectCommon.schedulerSessionAwaiting}/>
                <AccessControlCP module={ModuleEnum.MODULE_LISOLASER} redirect={false}>
                    <CalendarEventsSubtiteColorICP text={SessionStatusLabelEnum.CHECK_IN_DONE} color={ThemeProjectCommon.schedulerSessionCheckinDone}/>
                    <CalendarEventsSubtiteColorICP text={SessionStatusLabelEnum.IN_PROGRESS} color={ThemeProjectCommon.schedulerSessionInProgress}/>
                </AccessControlCP>
                <CalendarEventsSubtiteColorICP text={'Agendado (não evoluído)'} color={ThemeProjectCommon.schedulerSessionNotMarkedAsDone}/>
                <CalendarEventsSubtiteColorICP text={'Agendado (presença confirmada)'} color={ThemeProjectCommon.schedulerPresenceConfirmed}/>
                <CalendarEventsSubtiteColorICP text={'Realizado'} color={ThemeProjectCommon.schedulerSessionConcluded}/>
                <CalendarEventsSubtiteColorICP text={'Não compareceu'} color={ThemeProjectCommon.schedulerSessionNoShow}/>
                <CalendarEventsSubtiteColorICP text={'Inadimplente (cor da borda)'} color={'transparent'} borderColor={ThemeAnt.errorColor}/>
                <CalendarEventsSubtiteColorICP text={'Avaliação'} color={ThemeProjectCommon.schedulerEvaluation}/>
                <CalendarEventsSubtiteColorICP text={'Horário Bloqueado'} color={ThemeProjectCommon.schedulerUnavailable} backgroundExtra={ThemeProjectCommon.schedulerUnavailableBackgroundExtra}/>
                <CalendarEventsSubtiteColorICP text={'Clínica Fechada'} color={ThemeProjectCommon.schedulerNonBusinessHour} backgroundExtra={ThemeProjectCommon.schedulerNonBusinessHourBackgroundExtra}/>
            </FlexCP>

            <FlexCP flexWrap={'wrap'} margin={{top: 10}}>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faClock}/>} text={'Agendado'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faBan}/>} text={'Agendado (não evoluído)'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faUserCheck}/>} text={'Agendado (presença confirmada)'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faCheckDouble}/>} text={'Realizado'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faUserSlash}/>} text={'Não compareceu'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faDollarSign} color={'red'}/>} text={'Inadimplente'}/>
                <CalendarEventsSubtiteIconICP icon={<FontAwsomeIconCP type={faCommentDots}/>} text={'Tem comentário'}/>
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 0 20px;
`
