import { WizardCreateSaleCP } from 'main/modules/sale/components/wizard-create-sale/WizardCreateSaleCP'
import React, { useEffect, useState } from 'react'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { SalesUtils } from 'main/modules/sale/utils/SalesUtils'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { SaleTypeEnum, SaleTypeLabelEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { ISaleQuoteResponseDTO } from 'main/modules/sale/services/sale-quote/dtos/response/ISaleQuoteResponseDTO'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'

interface ICreateSaleDrawerCPProps {
    visible: boolean
    type: SaleTypeEnum
    opportunityCode?: number
    closeDrawer: () => void
    onSave?: (saleCode: number) => void
    customer?: INameAndCodeResponseDTO
    saleQuote?: ISaleQuoteResponseDTO
}

/**
 * Drawer que gera uma venda.
 */
export function DrawerCreateSaleCP(props: ICreateSaleDrawerCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [drawerNeededData, setDrawerNeededData] = useState<JSX.Element>()
    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)

    const [opportunity, setOpportunity] = useState<IOpportunityResponseDTO>()
    const getOpportunityRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onGetOpportunityRequestChange, [getOpportunityRequest.isAwaiting])

    const [personPayer, setPersonPayer] = useState<IPersonResponseDTO>()
    useEffect(validatePersonPayerData, [personPayer])

    const getPersonPayerRequest = useRequest<IPersonResponseDTO>()
    useEffect(onGetPersonPayerRequestChange, [getPersonPayerRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        if (!!props.customer)
            getPersonPayer(props.customer.code)

        if (!!props.opportunityCode)
            getOpportunityRequest.runRequest(OpportunityRequests.getOpportuityConfig(props.opportunityCode))

        if (!!props.saleQuote)
            getPersonPayer(props.saleQuote.saleData.saleBasicData.personPayer?.code ?? props.saleQuote.saleData.saleBasicData.personCustomer.code)
    }

    /**
     * Retorno da API para buscar dados da oportunidade
     */
    function onGetOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getOpportunityRequest, 'Erro ao buscar os dados da oportunidade.'))
            return

        const _opportunity = getOpportunityRequest.responseData
        setOpportunity(_opportunity)

        // Valida os dados do pagador, por padrao eh o cliente da oportunidade
        if (_opportunity?.personCustomer)
            getPersonPayer(_opportunity.personCustomer.code)
    }

    /**
     * Busca pessoa pagadora.
     */
    function getPersonPayer(personPayerCode: number): void {
        getPersonPayerRequest.runRequest(PersonRequests.getOne(personPayerCode))
    }

    /**
     * Retorno da API para buscar dados da pessoa pagadora.
     */
    function onGetPersonPayerRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonPayerRequest, 'Erro ao buscar os dados da pessoa pagadora.'))
            return

        setPersonPayer(getPersonPayerRequest.responseData)
    }

    /**
     * Verifica se o cadastro do cliente pagador está completo.
     */
    function validatePersonPayerData(): void {

        // Nao valida para orcamentos
        if (!personPayer || props.type === SaleTypeEnum.SALE_QUOTE)
            return

        // Verifica se tem todos os dados necessários para realizar uma venda
        if (!SalesUtils.checkIfAllDataIsFilled(personPayer)) {
            NotificationHelper.warning('Ops!', 'Preenche todos os dados do cliente para poder prosseguir ...')
            setIsPersonDrawerVisible(true)
            setDrawerNeededData(
                <FallbackScreenContentCP
                    status={'warning'}
                    message={'Complete o cadastro do Cliente. Verifique se todos os campos abaixo estão preenchidos: CPF / E-mail / Telefone / Endereço completo (incluindo número)'}
                />
            )
            return
        }

        setIsPersonDrawerVisible(false)
        return
    }

    if (props.visible && !!props.opportunityCode && !opportunity)
        return <LoadingOverlayCP show={true}/>

    return (
        <>
            <DrawerCP
                title={`Geração de ${SaleTypeLabelEnum[props.type]}`}
                width={800}
                visible={props.visible}
                onClose={props.closeDrawer}
                loading={getOpportunityRequest.isAwaiting}
            >
                {
                    !opportunity && props.type === SaleTypeEnum.SALE_QUOTE
                        ?
                        <FallbackScreenContentCP
                            status={'error'}
                            title={'Não encontramos a oportunidade selecionada'}
                            message={'Para criar um orçamento é necessário informar uma oportunidade válida'}
                        />
                        :
                        <WizardCreateSaleCP
                            type={props.type}
                            opportunity={opportunity}
                            customer={props.customer}
                            onSave={props.onSave}
                            closeDrawer={props.closeDrawer}
                            onSelectPayer={getPersonPayer}
                            saleQuote={props.saleQuote}
                        />
                }
            </DrawerCP>

            <DrawerPersonDataCP
                visible={isPersonDrawerVisible}
                personCode={personPayer?.code}
                segmentType={SegmentTypeEnum.CUSTOMER}
                customHeader={drawerNeededData}
                onClose={() => {
                    setIsPersonDrawerVisible(false)
                    props.closeDrawer()
                }}
                onSave={(person) => {
                    setPersonPayer(person)
                    setIsPersonDrawerVisible(false)
                }}
            />
        </>
    )
}

