import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'

/**
 * Utils da tabela de origem das vendas.
 */
export const OpportunitySourceTableUtils = {

    getTableColumns(
        onEdit: (code: number) => void,
        onChangeCheck: (opportunitySource: IOpportunitySourceResponseDTO, type: 'hasDetail' | 'isActive', isChecked: boolean) => void,
    ): Array<ColumnProps<IOpportunitySourceResponseDTO>> {

        return [
            {
                key: 'title',
                title: 'Origem da Venda',
                render: (text, item) => item.name
            },
            {
                key: 'has-details',
                title: 'Solicitar Detalhes?',
                align: 'center',
                render: (text, item) => {
                    return (
                        <FlexCP justifyContent={'center'}>
                            <SwitchCP
                                isChecked={item.hasPersonLink}
                                isTextInside={false}
                                onChange={(isChecked) => onChangeCheck(item, 'hasDetail', isChecked)}
                            />
                        </FlexCP>
                    )
                }
            },
            {
                key: 'active',
                title: 'Ativo?',
                align: 'center',
                render: (text, item) => {
                    return (
                        <FlexCP justifyContent={'center'}>
                            <SwitchCP
                                isChecked={item.isActive}
                                isTextInside={false}
                                onChange={(isChecked) => onChangeCheck(item, 'isActive', isChecked)}
                            />
                        </FlexCP>
                    )
                }
            },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEdit(item.code)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

}
