import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { UnitValueICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueICP'
import { UnitValueOperatorDisplayICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueOperatorDisplayICP'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'

/**
 * COMPONENTE
 * Exibe valores atuais selecionados de 01 unidade de lista de segmentacao em liguagem natural.
 */
export function UnitValueDisplayICP(): JSX.Element {

    const expressionContext = ExpressionContext.useState()
    const unitContext = UnitContext.use()

    const isLoading = (unitContext.state.isLoadingValueWithLabel || expressionContext.state.isLoading)
    const fieldName = unitContext.state.isSelected ? SegmentUtils.getUnitFieldLabel(unitContext.state.field!) : 'Adicionar Filtro'

    return (
        <SelectionWrapperSCP marginRight={isLoading ? 0 : 6}>
            <LoadingOverlayCP show={isLoading} size={15}/>
            <FieldNameSCP bold={unitContext.state.isSelected}>{fieldName}</FieldNameSCP>

            {
                unitContext.state.isSelected &&
                <>
                    <UnitValueOperatorDisplayICP>{SegmentUtils.getUnitCompareOperatorLabel(unitContext.state.operator!)}</UnitValueOperatorDisplayICP>
                    <UnitValueICP/>
                </>
            }
        </SelectionWrapperSCP>
    )
}

const SelectionWrapperSCP = styled.div<{ marginRight: number }>`

    display: flex;
    align-items: stretch;

    > div:last-child {
        margin-right: ${props => props.marginRight}px;
    }
`

const FieldNameSCP = styled.div<{ bold: boolean }>`
    font-weight: ${props => (props.bold ? 'bold' : 'unset')};
    padding: 3px 3px 3px 10px;
`
