import { CardCP } from 'main/common/components/card/CardCP'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { SaleContractTemplateEditorCP } from 'main/modules/sale/components/sale-contract-template-editor/SaleContractTemplateEditorCP'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import * as _ from 'lodash'
import { FranchiseRequests } from 'main/modules/admin/services/franchise/FranchiseRequests'
import { IFranchiseContractTemplateSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseContractTemplateSaveRequestDTO'

interface ISaleContractTemplateScreenContentCPProps {
    franchise: IFranchiseResponseDTO
}

/**
 * Tela para definicao do modelo de contrato utilizado na venda.
 */
export function SaleContractTemplateScreenContentCP(props: ISaleContractTemplateScreenContentCPProps): JSX.Element {

    const [editorContent, setEditorContent] = useState<string>(props.franchise.htmlContractTemplate ?? '')

    const saveContractTemplateRequest = useRequest('none')
    useEffect(onSaveContractTemplateRequestChange, [saveContractTemplateRequest.isAwaiting])

    /**
     * Chama API para salvar template
     */
    function save(): void {

        if (_.isEmpty(editorContent))
            return NotificationHelper.error('Ops!', 'Preencha o modelo de contrato')

        const saveDto: IFranchiseContractTemplateSaveRequestDTO = {
            htmlContractTemplate: editorContent
        }
        console.log("HTML ", editorContent);
        saveContractTemplateRequest.runRequest(FranchiseRequests.saveContractTemplate(saveDto))
    }

    /**
     * Retorno ao salvar modelo de contrato.
     */
    function onSaveContractTemplateRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveContractTemplateRequest, 'Erro ao atualizar contrato'))
            return

        NotificationHelper.success('Pronto!', 'Modelo de contrato salvo com sucesso')
    }

    return (
        <>
            <CardCP innerSpacing={'none'}>
                <SaleContractTemplateEditorCP
                    franchise={props.franchise}
                    onEditorContentChange={setEditorContent}
                />
            </CardCP>
            <ButtonWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={save}
                    loading={saveContractTemplateRequest.isAwaiting}
                >
                    Salvar
                </ButtonCP>
            </ButtonWrapperSCP>
        </>
    )
}

const ButtonWrapperSCP = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px;
`
