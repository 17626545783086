import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IReleaseSearchRequestDTO } from 'main/modules/products/services/dtos/request/IReleaseSearchRequestDTO'
import { IProductReleaseSaveRequestDTO } from 'main/modules/products/services/product-release/dtos/request/IProductReleaseSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisições do modulo PRODUTOS
 *
 */
export class ProductReleaseRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/product-releases`

    static update = (code: number, dto: IProductReleaseSaveRequestDTO): RequestConfigTP => ({
        url: `${ProductReleaseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static save = (dto: IProductReleaseSaveRequestDTO): RequestConfigTP => ({
        url: ProductReleaseRequests._MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${ProductReleaseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static search = (dto: IReleaseSearchRequestDTO): RequestConfigTP => ({
        url: ProductReleaseRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static findOne = (code: number): RequestConfigTP => ({
        url: `${ProductReleaseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static searchNames = (dto: IReleaseSearchRequestDTO): RequestConfigTP => ({
        url: `${ProductReleaseRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })
}
