import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { TreatmentRequests } from 'main/modules/admin/services/treatment/TreatmentRequests'
import { TreatmentGroupTableUtils } from 'main/modules/admin/components/table-treatment-group/inner/TreatmentGroupTableUtils'
import { ITreatmentGroupListItemResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentGroupListItemResponseDTO'

interface ITableTreatmentGroupCPProps {
    onEdit: (code: number) => void
    forceLoadList: boolean
    onListLoaded: () => void
}

/**
 * Listagem de grupos de atendimento.
 */
export function TableTreatmentGroupCP(props: ITableTreatmentGroupCPProps): JSX.Element {

    useEffect(init, [props.forceLoadList])

    const [treatmentGroups, setTreatmentGroups] = useState<ITreatmentGroupListItemResponseDTO[]>()

    const searchTreatmentGroupsRequest = useRequest<IGenericListResponseDTO<ITreatmentGroupListItemResponseDTO>>()
    useEffect(onSearchTreatmentGroupsRequestChange, [searchTreatmentGroupsRequest.isAwaiting])

    /**
     * Obtem listagem de cargos e funcoes.
     */
    function init(): void {

        if (!props.forceLoadList)
            return

        getTreatmentGroups()
    }

    /**
     * Obtem lista de grupos de tratamento da API.
     */
    function getTreatmentGroups(): void {
        searchTreatmentGroupsRequest.runRequest(TreatmentRequests.searchTreatmentGroup())
    }

    /**
     * Retorno da requisicao de cargos e funcoes.
     */
    function onSearchTreatmentGroupsRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(searchTreatmentGroupsRequest, 'Não foi possível obter lista de grupos de tratamentos.'))
            return

        setTreatmentGroups(searchTreatmentGroupsRequest.responseData!.list)
        props.onListLoaded()
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableCPOLD2
                data={treatmentGroups}
                loading={searchTreatmentGroupsRequest.isAwaiting}
                columns={TreatmentGroupTableUtils.getTableColumns(props.onEdit)}
            />
        </CardCP>
    )
}
