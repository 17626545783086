import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { ValidateIf } from 'class-validator'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { IsDateString } from 'main/common/validation/decorators/IsDateString'
import { FinancialTransactionValueFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionValueFormModel'
import { IsOptional } from 'class-validator/decorator/decorators'

export class FinancialTransactionFormModel extends FormModel {

    @IsNotEmpty()
    description: string

    @IsNotEmpty()
    @IsDateString()
    expirationDate: Date

    @IsOptional()
    @IsDateString()
    referenceDate: Date

    @IsNotEmpty()
    suplierCode: number

    @IsNotEmpty()
    paymentMethod: PaymentMethodEnum

    @IsNotEmpty()
    bankCode: number

    @IsNotEmpty()
    isPaid: boolean

    @ValidateIf((obj) => obj.isPaid)
    @IsNotEmpty()
    paymentDate?: Date

    @ValidateIf((obj) => obj.isPaid)
    @IsNotEmpty()
    paidValue?: string

    @IsNotEmpty()
    values: FinancialTransactionValueFormModel[]

    @IsOptional()
    nfeNumber: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
