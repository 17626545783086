import React, { useRef } from 'react'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { ScreenContentConsultantDashboardTabCP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/inner/ScreenContentConsultantDashboardTabCP'
import styled from 'styled-components'
import { ScreenContentAttendantDashboardTabCP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/inner/ScreenContentAttendantDashboardTabCP'
import { ScreenContentFranchiseManagerDashboardTabCP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/inner/ScreenContentFranchiseManagerDashboardTabCP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ScreenContentMultipleFranchiseManagerDashboardTabCP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/inner/ScreenContentMultipleFranchiseManagerDashboardTabCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { useReactToPrint } from 'react-to-print'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PrintAreaCP } from 'main/common/components/print-area-cp/PrintAreaCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetFranchiseSalePerformanceCP } from 'main/modules/dashboard/components/widget-franchise-sale-performance/WidgetFranchiseSalePerformanceCP'
import { Boleto } from 'submodules/nerit-framework-ui/One/boleto/Boleto'

/**
 * Tela do módulo Dashboard
 */
export function ScreenDashboardFranchiseCP(): JSX.Element {

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: 'nc-dashboard',
        content: () => componentRef.current as any,
    })

    return (
        <>
            <HeaderCP title={'Dashboard'}/>
            <Boleto/>
            <WidgetsRowCP marginTop={20} marginBottom={20} >
                <WidgetFranchiseSalePerformanceCP/>
            </WidgetsRowCP>

            <TabWrapperSCP>

                <TabsCP
                    tabs={[
                        {
                            title: 'Gerente da Regional',
                            key: 'franchiseManagerMultiple',
                            content: <PrintAreaCP ref={componentRef}><ScreenContentMultipleFranchiseManagerDashboardTabCP/></PrintAreaCP>,
                            hide: !PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_MANAGER]) || AppStateUtils.getUserFranchises()!.length <= 1,
                        },
                        {
                            title: 'Gerente da Unidade',
                            key: 'franchiseManager',
                            content:
                                <PrintAreaCP ref={componentRef}>
                                    <ScreenContentFranchiseManagerDashboardTabCP/>
                                </PrintAreaCP>,
                            hide: !PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_MANAGER]),
                        },
                        {
                            title: 'Avaliadora / Vendedora',
                            key: 'dashboardConsultant',
                            content: <PrintAreaCP ref={componentRef}><ScreenContentConsultantDashboardTabCP/></PrintAreaCP>,
                            hide: !PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_MANAGER, PermissionEnum.ROLE_DASHBOARD_CONSULTANT])
                        },
                        {
                            title: 'Técnica de Procedimentos',
                            key: 'dashboardAttendant',
                            content: <PrintAreaCP ref={componentRef}><ScreenContentAttendantDashboardTabCP/></PrintAreaCP>,
                            hide: !PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_MANAGER, PermissionEnum.ROLE_DASHBOARD_ATTENDANT]),
                        },
                    ]}
                    extraContent={
                        <ButtonCP
                            onClick={handlePrint}
                            icon={'printer'}
                        />
                    }
                />
            </TabWrapperSCP>
        </>
    )
}

const TabWrapperSCP = styled.div`
    
  overflow-x: auto;
  
  .ant-tabs-nav-scroll {
    padding: 20px 20px 0 20px;
  }

  .ant-tabs-content {
    padding: 10px;
  }
  
`
