import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { FontSizeTP } from 'main/config/theme/ThemeTypes'
import React from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { TextCP } from 'main/common/components/text/TextCP'

interface INumberCPProps{
    value: number
    size?: FontSizeTP
    strong?: boolean
}

/**
 * COMPONENTE
 * Texto de um numero podendo considerar cor da fonte de acordo com positivo ou negativo, etc.
 */
export function NumberCP(props: INumberCPProps): JSX.Element {

    return (
        <TextCP
            text={MaskUtils.applyMoneyMask(`${props.value}`)}
            size={props.size}
            strong={props.strong}
            color={props.value > 0 ? ThemeAnt.primaryColor : ThemeAnt.errorColor}
        />
    )
}

