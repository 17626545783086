import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { StringUtils } from 'main/common/utils/StringUtils'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { SessionAnswersHistorySessionsTP } from 'main/modules/scheduler/types/SessionAnswersHistorySessionsTP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import * as _ from 'lodash'

/**
 * Utilitario da tabela de respostas de formulario de sessoes.
 */
export const SessionFormHistoryTableUtils = {

    getTableColumns(
        grupedTitles: string[]
    ): Array<ColumnProps<SessionAnswersHistorySessionsTP>> {

        const columns: Array<ColumnProps<SessionAnswersHistorySessionsTP>> = []

        columns.push({
            title: 'Sessão',
            key: 'session',
            render: (text, dto) => (
                <>
                    <TextCP text={`Sessão ${dto.session.number}/${dto.session.totalSessions}`}/>
                    <TextCP text={`em ${DateUtils.getFormatted(dto.session.date, DateFormatEnum.BR_WITH_TIME_H_M)}`} fontStyle={'italic'} size={'small'} color={ThemeAnt.gray}/>
                    <TextCP text={`por ${_.uniq(dto.answers.map((answer) => answer.userCreator.name)).join(',')}`} fontStyle={'italic'} size={'small'} color={ThemeAnt.gray}/>
                </>
            )
        })

        // Itera nos agrupamentos para montar o conjunto de colunas dinamico
        grupedTitles.forEach((currentTitle) => {
            columns.push({
                title: currentTitle,
                key: StringUtils.getSlugStyleString(currentTitle),
                render: (text, dto) => dto.answers.find(data => data.question.name === currentTitle)?.answer
            })
        })

        return columns
    },
}
