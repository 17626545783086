import React from 'react'
import * as _ from 'lodash'
import { TextCP } from 'main/common/components/text/TextCP'
import { FontStyleTP } from 'main/common/types/FontStyleTP'

interface ISaleDescriptionCPProps {
    saleItemsProductReleaseNames: string[]
    characterLimit?: number
    textColor?: string
    fontStyle?: FontStyleTP
}

/**
 * COMPONENTE Exibe o nome dos produtos de uma venda
 */
export function TextSaleItemsProductReleaseNamesCP(props: ISaleDescriptionCPProps): JSX.Element {

    let flatName = props.saleItemsProductReleaseNames.join(', ')
    if (!!props.characterLimit) {
        flatName = _.truncate(flatName, {
            length: props.characterLimit,
            omission: ' ...',
        })
    }

    return (
        <TextCP
            text={flatName}
            color={props.textColor}
            fontStyle={props.fontStyle}
        />
    )
}