import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { OpportunitySourceTableUtils } from 'main/modules/admin/components/table-opportunity-source/inner/OpportunitySourceTableUtils'
import { IOpportunitySourceSaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/request/IOpportunitySourceSaveRequestDTO'
import { OpportunitySourceRequests } from 'main/modules/sales-funnel/services/opportunity-source/OpportunitySourceRequests'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

interface ITableOpportunitySourceCPProps {
    onEdit: (code: number) => void
    forceLoadList: boolean
    onListLoaded: () => void
}

/**
 * Listagem de origem de venda.
 */
export function TableOpportunitySourceCP(props: ITableOpportunitySourceCPProps): JSX.Element {

    useEffect(init, [props.forceLoadList])

    const [sources, setSources] = useState<IOpportunitySourceResponseDTO[]>()

    const searchSourcesRequest = useRequest<IGenericListResponseDTO<IOpportunitySourceResponseDTO>>()
    useEffect(onSearchSourcesRequestChange, [searchSourcesRequest.isAwaiting])

    const updateSourceRequest = useRequest<IOpportunitySourceSaveRequestDTO>()
    useEffect(onUpdateSourceRequestChange, [updateSourceRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.forceLoadList)
            return

        loadSourcesFromApi()
    }

    /**
     * Obtem lista de cargos da API.
     */
    function loadSourcesFromApi(): void {
        searchSourcesRequest.runRequest(OpportunitySourceRequests.searchOpportunitySources())
    }

    /**
     * Retorno da requisicao.
     */
    function onSearchSourcesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchSourcesRequest, 'Não foi possível obter lista de origem dos leads.'))
            return

        setSources(searchSourcesRequest.responseData!.list)
        props.onListLoaded()
    }

    /**
     * Atualiza fonte com os switchs de ativo e se tem campo.
     */
    function updateSource(opportunitySource: IOpportunitySourceResponseDTO, type: 'hasDetail' | 'isActive', isChecked: boolean): void {

        const dto: IOpportunitySourceSaveRequestDTO = {
            name: opportunitySource.name,
            isActive: type === 'isActive' ? isChecked : opportunitySource.isActive,
            hasPersonLink: type === 'hasDetail' ? isChecked : opportunitySource.hasPersonLink
        }

        updateSourceRequest.runRequest(OpportunitySourceRequests.updateOpportunitySource(opportunitySource.code, dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onUpdateSourceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateSourceRequest, 'Não foi possível atualizar esta origem dos leads.'))
            return

        NotificationHelper.info('Pronto', 'Atualizado com sucesso')
        loadSourcesFromApi()
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableCPOLD2
                data={sources}
                loading={searchSourcesRequest.isAwaiting}
                columns={OpportunitySourceTableUtils.getTableColumns(
                    props.onEdit,
                    updateSource
                )}
            />
        </CardCP>
    )
}
