import React from 'react'
import { TransactionalMessagesFormModel } from 'main/modules/marketing/components/collapse-mkt-transactionals/inner/TransactionalMessagesFormModel'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import styled from 'main/config/theme/styledWithTheme'

interface ICPProps {
    formStateManager: IFormStateManager<TransactionalMessagesFormModel>
    switchFieldName: keyof TransactionalMessagesFormModel
    title: string
    content: JSX.Element
}

/**
 * Titulo do conteudo de cada tipo de disparo.
 */
export function MktTransactionalCollpaseTitleItemICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TitleCP underLine={true} textSize={'normal'}>
                <FlexCP alignItems={'center'}>
                    <SwitchCP
                        isTextInside={false}
                        marginRight={10}
                        formStateManager={props.formStateManager}
                        fieldName={props.switchFieldName}
                    />
                    <>{props.title}</>
                </FlexCP>
            </TitleCP>

            {
                !!props.formStateManager.getFieldValue(props.switchFieldName) &&
                <>{props.content}</>
            }
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div`
    margin-bottom: 30px;
`
