import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISalesSummaryReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesSummaryReportFilterRequestDTO'
import { ISalesPaymentReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesPaymentReportFilterRequestDTO'
import { ISalesBySellerReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesBySellerReportFilterRequestDTO'
import { ISaleUpdateRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISaleUpdateRequestDTO'
import { SaleSummaryRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSummaryRequestDTO'
import { SaleSearchRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSearchRequestDTO'

export class SaleRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/sales`

    static updateSale = (code: number, dto: ISaleUpdateRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    /** Busca as vendas por data e status e cliente */
    static getSales = (dto?: SaleSearchRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static salesExport = (dto?: SaleSearchRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/export`,
        method: HttpMethodEnum.GET,
        params: dto,
        responseType: 'blob',
    })

    /** Busca os detalhes de uma compra para montar o PDF */
    static getSaleDetail = (saleCode: number): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${saleCode}`,
        method: HttpMethodEnum.GET,
    })

    /** Paga uma parcela de uma compra */
    static payInstallment = (saleCode: number, installmentCode: number): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${saleCode}/installment/${installmentCode}/pay`,
        method: HttpMethodEnum.PUT,
    })

    static generateContractConfig = (saleCode: number): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${saleCode}/create-contract`,
        method: HttpMethodEnum.PUT,
    })

    static summaryConfig = (dto: SaleSummaryRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/summary`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSalesSummaryConfig = (dto: ISalesSummaryReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/summary-report`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSalesPaymentConfig = (dto: ISalesPaymentReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/by-payment-method`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSalesBySellerConfig = (dto: ISalesBySellerReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/by-seller`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static cancelSale = (saleCode: number): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${saleCode}/cancel`,
        method: HttpMethodEnum.PUT,
    })

    static updateSaleStatus = (saleCode: number): RequestConfigTP => ({
        url: `${SaleRequests._MODULE_BASE}/${saleCode}/update-sale-status`,
        method: HttpMethodEnum.PUT,
    })

}
