import { ColumnProps } from 'antd/lib/table'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import React from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { StringUtils } from 'main/common/utils/StringUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { SessionStatusEnum, SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { IUserAttendancesResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendancesResponseDTO'

/**
 * UTILITARIOS do componente de listagem de agendamentos.
 */
export const TableAttendanceQueuesUtils = {

    getColumns(
        onEditEvent: (event: IFullCalendarEvent) => void,
        renderUserAttendantName: (item: IFullCalendarEvent) => string | undefined,
    ): Array<ColumnProps<IFullCalendarEvent>> {

        return [
            {
                title: 'Status',
                key: 'status',
                render: (text, item) => (
                    <TagCP
                        content={SessionStatusLabelEnum[item.id]}
                    />
                )
            },
            {
                title: 'Horário',
                key: 'hour',
                render: (text, item) => (
                    <TextCP
                        text={`${DateUtils.getFormatted(item.start, DateFormatEnum.TIME_H_M)} às ${DateUtils.getFormatted(item.end, DateFormatEnum.TIME_H_M)}`}
                    />
                )
            },
            {
                key: 'presence',
                render: (text, item) => item.extendedProps?.presenceConfirmation ? <FontAwsomeIconCP type={faUserCheck} tooltip={'Presença confirmada'} color={ThemeAnt.successColor}/> : undefined
            },
            {
                title: 'Cliente',
                key: 'customer',
                render: (text, item) => StringUtils.getFirstAndSecondName(item.extendedProps?.customer?.name ?? '')
            },
            {
                key: 'phone',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <TextPersonPhoneCP phone={item.extendedProps?.customer?.phone}/>
                    </FlexCP>
                )
            },
            {
                title: 'Agenda',
                key: 'calendar',
                render: (text, item) => renderUserAttendantName(item)
            },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={[SessionStatusEnum.CHECK_IN_DONE, SessionStatusEnum.IN_PROGRESS].includes(item.id as SessionStatusEnum) ? 'primary' : undefined}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEditEvent(item)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

    /**
     * Obtem o usuario no retorno dos atendimentos a partir do codigo dele
     */
    getUserAttendant(userAttendances: IUserAttendancesResponseDTO[], resourceId?: string): NameAndCodeResponseDTO | undefined {

        if (!resourceId)
            return

        const foundUser = userAttendances.find((userAttendance) => userAttendance.user.code === +resourceId)
        if (!foundUser)
            return

        return new NameAndCodeResponseDTO(foundUser.user.code, foundUser.user.name)
    }

}
