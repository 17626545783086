import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { DreReportRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/request/DreReportRequestDTO'

/**
 * UTILITARIOS do componente de listagem de agendamentos.
 */
export const TableDreUtils = {

    getTableColumns(
        groupedValues: Map<string, Map<string, number | string>>,
        consolidateInterval?: string
    ): Array<ColumnProps<string>> {

        const columns: Array<ColumnProps<string>> = []

        columns.push({
            key: 'category',
            render: (text, category) => category
        })

        // Obtem os meses unicos que estao dentro do mapa
        console.log({aa: groupedValues})
        const uniqueMonths = new Set<string>()
        groupedValues.forEach((category) => {
            category.forEach((value, month) => {
                uniqueMonths.add(month)
            })
        })

        // Para cada mes monta a coluna e pega os valores corretamente
        uniqueMonths.forEach((currentInterval) => {
            columns.push({
                title: consolidateInterval ?? currentInterval,
                key: `key-dre-col-${StringUtils.getSlugStyleString(currentInterval)}`,
                align: 'right',
                render: (text, category) => {

                    // Obtem o valor da categoria certa
                    const valueOfCategory = groupedValues.get(category)
                    const valueOfMonth = valueOfCategory?.get(currentInterval)

                    if (!!category)
                        return MaskUtils.applyMoneyMask(valueOfMonth ?? 0)

                    if (!valueOfMonth)
                        return <></>

                    // Nao nao tiver categoria, sao as mensagens
                    return (
                        <TooltipCP
                            showSpan={true}
                            text={valueOfMonth}
                        >
                            <FlexCP justifyContent={'flex-end'}>
                                <IconICP iconName={'warning'} color={ThemeFrameworkCommon.warningColor}/>
                            </FlexCP>
                        </TooltipCP>
                    )
                }
            })
        })

        return columns
    },

    /**
     * Monta os DTO dos filtros com base no formModel.
     */
    mountFiltersDto(filterModel: ReportsCommonFiltersFormModel): DreReportRequestDTO | undefined {

        if (!filterModel.dateRange)
            return

        return {
            franchiseCodes: filterModel.franchisesCodes,
            dateRange: DtoUtils.convertToDateRangeDto(filterModel.dateRange)!,
            shouldConsolidate: filterModel.showConsolidated,
        }
    },

}
