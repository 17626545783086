import { faCheckCircle, faClock, faEquals, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { ResumeWidgetCardCP } from 'main/common/components/resume-widget-card/ResumeWidgetCardCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFinancialTransactionsSummaryRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionsSummaryRequestDTO'
import { IFinancialTransactionSummaryResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionSummaryResponseDTO'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'

interface IFinancialTransactionsSummaryHeaderCPProps {
    transactionsType: FinancialTransactionTypeEnum
    onChangeFilters: (dto: IFinancialTransactionSearchRequestDTO) => void
    currentFilters: IFinancialTransactionSearchRequestDTO
    shouldReloadList: boolean
    onReportLoaded: () => void
}

export function FinancialTransactionsSummaryHeaderCP(props: IFinancialTransactionsSummaryHeaderCPProps): JSX.Element {

    useEffect(init, [props.shouldReloadList])

    const [summaryData, setSummaryData] = useState<IFinancialTransactionSummaryResponseDTO>()
    const summaryRequest = useRequest<IFinancialTransactionSummaryResponseDTO>()
    useEffect(onSummaryRequestChange, [summaryRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldReloadList)
            return

        const searchDto: IFinancialTransactionsSummaryRequestDTO = {
            type: props.transactionsType,
            expirateInterval: props.currentFilters.expirateInterval,
            franchiseCodes: props.currentFilters.franchiseCodes,
            paymentInterval: props.currentFilters.paymentInterval,
            bankAccountCode: props.currentFilters.bankAccountCode,
            paymentMethod: props.currentFilters.paymentMethod,
            personCode: props.currentFilters.personCode,
            searchString: props.currentFilters.searchString,
            paymentPartnerCode: props.currentFilters.paymentPartnerCode,
            saleInterval: props.currentFilters.saleInterval,
            valueRange: props.currentFilters.valueRange,
        }
        summaryRequest.runRequest(FinancialTransactionsRequests.getSummaryConfig(searchDto))
    }

    /**
     * Retorno dos dados da API
     */
    function onSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(summaryRequest, 'Ocorreu algun erro ao obter resumo movimentações'))
            return

        setSummaryData(summaryRequest.responseData)
        props.onReportLoaded()
    }

    return (
        <WrapperSCP>
            <CardWrapperSCP>
                <ResumeWidgetCardCP
                    loading={summaryRequest.isAwaiting}
                    icon={<FontAwsomeIconCP type={faCheckCircle} color={ThemeAnt.successColor}/>}
                    onClick={() => props.onChangeFilters({
                        ...props.currentFilters,
                        status: [FinancialTransactionStatusEnum.PAID]
                    })}
                    value={MaskUtils.applyMoneyMask(summaryData?.paidValue ?? 0)}
                    extra={`${summaryData?.paidCount ?? 0} foram pagas`}
                    marginLeft={0}
                    selected={props.currentFilters.status?.includes(FinancialTransactionStatusEnum.PAID)}
                />
            </CardWrapperSCP>

            <CardWrapperSCP>
                <ResumeWidgetCardCP
                    loading={summaryRequest.isAwaiting}
                    icon={<FontAwsomeIconCP type={faClock} color={ThemeAnt.warningColor}/>}
                    onClick={() => props.onChangeFilters({
                        ...props.currentFilters,
                        status: [FinancialTransactionStatusEnum.AWAITING]
                    })}
                    value={MaskUtils.applyMoneyMask(summaryData?.awaitingValue ?? 0)}
                    extra={`${summaryData?.awaitingCount ?? 0} estão em dia`}
                    selected={props.currentFilters.status?.includes(FinancialTransactionStatusEnum.AWAITING)}
                />
            </CardWrapperSCP>

            <CardWrapperSCP>
                <ResumeWidgetCardCP
                    loading={summaryRequest.isAwaiting}
                    icon={<FontAwsomeIconCP type={faExclamationCircle} color={ThemeAnt.errorColor}/>}
                    onClick={() => props.onChangeFilters({
                        ...props.currentFilters,
                        status: [FinancialTransactionStatusEnum.OVERDUE]
                    })}
                    value={MaskUtils.applyMoneyMask(summaryData?.overdueValue ?? 0)}
                    extra={`${summaryData?.overdueCount ?? 0} estão em atraso`}
                    selected={props.currentFilters.status?.includes(FinancialTransactionStatusEnum.OVERDUE)}
                />
            </CardWrapperSCP>

            <CardWrapperSCP>
                <ResumeWidgetCardCP
                    loading={summaryRequest.isAwaiting}
                    icon={<FontAwsomeIconCP type={faEquals} color={ThemeAnt.primaryColor}/>}
                    onClick={() => props.onChangeFilters({
                        ...props.currentFilters,
                        status: undefined
                    })}
                    value={MaskUtils.applyMoneyMask(summaryData?.totalValue ?? 0)}
                    extra={`Em um total de ${summaryData?.totalCount ?? 0}`}
                    marginRight={0}
                />
            </CardWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0;
`
const CardWrapperSCP = styled.div`
    flex: 1;
`
