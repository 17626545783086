import { ImportUpdateBehaviorEnum } from 'main/common/enums/ImportUpdateBehaviorEnum'

/**
 * UTILITARIOS para importacao.
 */
export const ImportUtils = {

    getImportBehaviorLabel: (behavior: ImportUpdateBehaviorEnum): string => {
        switch (behavior) {
            case ImportUpdateBehaviorEnum.OVERWRITE:
                return 'Atualizar campos'
            case ImportUpdateBehaviorEnum.SKIP:
                return 'Pular registros'
            case ImportUpdateBehaviorEnum.UPDATE_BLANK:
                return 'Atualizar quando vazio'
            default:
                return ''
        }
    },
}
