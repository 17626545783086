import styled from 'main/config/theme/styledWithTheme'
import React, { useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ListFromApi } from 'main/common/components/list/ListFromApiCP'
import { SaleQuoteRequests } from 'main/modules/sale/services/sale-quote/SaleQuoteRequests'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ListItemICP } from 'main/common/components/list/inner/ListItemICP'
import { ISaleQuoteResponseDTO } from 'main/modules/sale/services/sale-quote/dtos/response/ISaleQuoteResponseDTO'
import { DrawerSaleQuoteDetailsCP } from 'main/modules/sale/components/drawer-sale-quote-detail/DrawerSaleQuoteDetailsCP'
import { DrawerCreateSaleCP } from 'main/modules/sale/components/drawer-create-sale/DrawerCreateSaleCP'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'

interface ITimelineOpportunityActivitiesCPProps {
    opportunityCode: number
    onUpdateData: (wasSaleCreated: boolean) => void
    canAdd: boolean
}

/**
 * Listagem de orcamentos de uma oportunidade
 */
export function ListSaleQuotes(props: ITimelineOpportunityActivitiesCPProps): JSX.Element {

    const [isSaleQuoteDrawerVisible, setIsSaleQuoteDrawerVisible] = useState<boolean>(false)
    const [isCreateSaleDrawerVisible, setIsCreateSaleDrawerVisible] = useState<boolean>(false)

    const [saleType, setSaleType] = useState<SaleTypeEnum>()
    const [selectedSaleQuoteCode, setSelectedQuoteCode] = useState<number>()
    const [saleQuoteToSale, setSaleQuoteToSale] = useState<ISaleQuoteResponseDTO>()

    return (
        <WrapperSCP>

            <TitleCP underLine={true} textSize={'large'}>
                <FlexCP alignItems={'center'}>
                    <div>
                        Orçamentos
                    </div>
                    <ButtonCP
                        icon={'plus'}
                        type={'link'}
                        onClick={() => {
                            setSelectedQuoteCode(undefined)
                            setSaleQuoteToSale(undefined)

                            setSaleType(SaleTypeEnum.SALE_QUOTE)
                            setIsCreateSaleDrawerVisible(true)
                        }}
                        marginLeft={20}
                        disabled={!props.canAdd}
                        tooltip={!props.canAdd ? 'Mude a etapa da oportunidade para poder adicionar orçamento' : undefined}
                    />
                </FlexCP>

            </TitleCP>

            <ListWrapperSCP>
                <ListFromApi<ISaleQuoteResponseDTO>
                    emptyMessage={!props.canAdd ? 'Mude a etapa da oportunidade para poder adicionar orçamento' : undefined}
                    requestConfig={SaleQuoteRequests.search}
                    customFilters={{ opportunityCode: props.opportunityCode }}
                    renderListItem={(record) => (
                        <ListItemICP
                            title={
                                <FlexCP justifyContent={'space-between'}>
                                    <div>{`#${record.code} - Orçamento feito por ${record.creationUser.name} em ${DateUtils.getFormatted(record.createDate, DateFormatEnum.BR_WITH_TIME_H_M)}`}</div>
                                    <ButtonCP
                                        size={'small'}
                                        icon={'eye'}
                                        onClick={() => {
                                            setSelectedQuoteCode(record.code)
                                            setIsSaleQuoteDrawerVisible(true)
                                        }}
                                    />
                                </FlexCP>
                            }
                        />
                    )}
                />
            </ListWrapperSCP>

            {
                !!selectedSaleQuoteCode &&
                <DrawerSaleQuoteDetailsCP
                    visible={isSaleQuoteDrawerVisible}
                    onClose={() => {
                        setIsSaleQuoteDrawerVisible(false)
                        setSaleQuoteToSale(undefined)
                        setSelectedQuoteCode(undefined)
                    }}
                    onDoSale={(saleQuote) => {
                        setSaleType(SaleTypeEnum.SALE)
                        setSaleQuoteToSale(saleQuote)
                        setIsCreateSaleDrawerVisible(true)

                        setSelectedQuoteCode(undefined)
                        setIsSaleQuoteDrawerVisible(false)
                    }}
                    code={selectedSaleQuoteCode}
                />
            }

            {
                !!saleType &&
                <DrawerCreateSaleCP
                    visible={isCreateSaleDrawerVisible}
                    type={saleType}
                    opportunityCode={props.opportunityCode}
                    saleQuote={saleQuoteToSale}
                    closeDrawer={() => setIsCreateSaleDrawerVisible(false)}
                    onSave={(saleQuoteCode) => {
                        setIsCreateSaleDrawerVisible(false)
                        props.onUpdateData(saleType === SaleTypeEnum.SALE)
                        setSaleQuoteToSale(undefined)

                        if (saleType === SaleTypeEnum.SALE_QUOTE) {
                            setSelectedQuoteCode(saleQuoteCode)
                            setIsSaleQuoteDrawerVisible(true)
                        }
                    }}
                />

            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-bottom: 30px;
`

const ListWrapperSCP = styled.div`
  margin-top: 30px;

  ul {
    padding-left: 0;
  }
`
