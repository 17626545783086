import { ImportUpdateBehaviorEnum } from 'main/common/enums/ImportUpdateBehaviorEnum'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { IsNumber } from 'main/common/validation/decorators/IsNumber'

/**
 * VALIDADOR
 * Define campos / validacao pra form de importacao.
 */
export class ImportFormValidator extends FormModel {

    @IsNumber()
    @IsNotEmpty()
    responsibleCode: number

    @IsNumber()
    @IsNotEmpty()
    sourceCode: number

    @IsIn(Object.values(ImportUpdateBehaviorEnum))
    @IsNotEmpty()
    updateBehavior: ImportUpdateBehaviorEnum

    constructor(initialValues?: Partial<ImportFormValidator>) {
        super()
        Object.assign(this, initialValues)
    }
}
