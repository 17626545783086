import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

interface IArrowIconCPProps extends IIconProps {
    direction: 'up' | 'left' | 'right' | 'down'
    type?: 'default' | 'filled' | 'circle' | 'pointer'
}

/**
 * ICONE: Seta
 * Seta em qualquer 01 das 04 direcoes com possibilidade de estilos diferentes;
 */
export function ArrowIconCP(props: IArrowIconCPProps): JSX.Element {

    let iconName: string

    if (['default', undefined].includes(props.type))    // e.g: 'right'
        iconName = props.direction

    else if (props.type === 'circle')                   // e.g: 'right-circle'
        iconName = `${props.direction}-circle`

    else {
        const concatString = (props.type === 'filled') ? 'caret' : 'arrow'
        iconName = `${concatString}-${props.direction}` // e.g: 'caret-right', 'arrow-right'
    }

    return (
        <IconICP
            iconName={iconName}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
