import React from 'react'
import styled from 'styled-components'

interface ICPProps {
    children: JSX.Element
    stopBlink?: boolean
}

export function BlinkCP(props: ICPProps): JSX.Element {

    if (props.stopBlink)
        return props.children

    return (
        <WrapperSCP>
            {props.children}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
  animation: blink 1s linear infinite;

  @keyframes blink{
      0%{opacity: .2;}
      20%{opacity: .6;}
      40%{opacity: 1;}
      60%{opacity: 1;}
      80%{opacity: .6;}
      100%{opacity: .2;}
  }
`
