import React from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { PersonListScreenHeaderTitleICP } from 'main/modules/people/screens/screen-person-list/screen-content/inner/PersonListScreenHeaderTitleICP'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'

interface IPersonListScreenHeaderICPProps {

    segment: ISegmentListItemResponseDTO
    showSaveButton: boolean
    enableNameChange: boolean

    onSegmentNameChange: (segmentName: string) => void
    onAddPerson: () => void
    onSaveSegmentEdition?: () => void
}

/**
 * COMPONENTE
 * Cabecalho da tela de listagem de pessoas (CRM).
 */
export function PersonListScreenHeaderICP(props: IPersonListScreenHeaderICPProps): JSX.Element {

    function onMktActionClick(type: MarketingAccountTypeEnum): void {
        RoutingHelper.historyPush(`${MarketingRoutes.NEW_SHOT}/${type}?segment=${props.segment.code}`)
    }

    return (
        <HeaderCP
            title={
                <PersonListScreenHeaderTitleICP
                    segment={props.segment}
                    showSaveButton={props.showSaveButton}
                    enableNameChange={props.enableNameChange}
                    onSegmentNameChange={props.onSegmentNameChange}
                    onSaveSegmentEdition={props.onSaveSegmentEdition}
                />
            }
        >
            <ButtonCP
                icon={'notification'}
                onClick={() => onMktActionClick(MarketingAccountTypeEnum.SMS)}
                marginRight={10}
            >
                <TextCP text={'Enviar sms'}/>
            </ButtonCP>

            <ButtonCP
                icon={'notification'}
                onClick={() => onMktActionClick(MarketingAccountTypeEnum.EMAIL)}
                marginRight={10}
            >
                <TextCP text={'Enviar e-mail'}/>
            </ButtonCP>

            <ButtonCP icon={'plus'} onClick={props.onAddPerson}>
                <TextCP text={`Adicionar ${SegmentUtils.getSegmentTypeName(props.segment.type)}`}/>
            </ButtonCP>
        </HeaderCP>
    )
}
