import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'

/**
 * Requests de cupom de desconto
 */
export class VoucherRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/voucher`

    static search = (): RequestConfigTP => ({
        url: `${VoucherRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
    })

}
