import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IconCP } from 'main/common/components/icons/IconCP'

interface IChartSalesFunnelArrowsICPProps {
    mainConversionRate: number
    leadToEvaluationConversionRate: number
    evaluationToSaleConversionRate: number
}

/**
 * Setas e conversoes do funil.
 */
export function ChartSalesFunnelArrowsICP(props: IChartSalesFunnelArrowsICPProps): JSX.Element {

    return (
        <CardsWrapperSCP>
            <ArrowSCP>
                <IconCP antIcon={'arrow-down'}/>
            </ArrowSCP>
            <FunnelConvertionMainSCP>
                <FlexCP alignItems={'center'}>
                    <>{`${Number(props.mainConversionRate).toFixed(0)}%`}</>
                </FlexCP>
            </FunnelConvertionMainSCP>

            <FunnelConvertion1SCP>
                <FlexCP alignItems={'center'}>
                    <IconCP antIcon={'arrow-down'}/>
                    <>{`${Number(props.leadToEvaluationConversionRate).toFixed(0)}%`}</>
                </FlexCP>
            </FunnelConvertion1SCP>

            <FunnelConvertion2SCP>
                <FlexCP alignItems={'center'}>
                    <IconCP antIcon={'arrow-down'}/>
                    <>{`${Number(props.evaluationToSaleConversionRate).toFixed(0)}%`}</>
                </FlexCP>
            </FunnelConvertion2SCP>
        </CardsWrapperSCP>
    )
}

const CardsWrapperSCP = styled.div`

  margin-top: 20px;

  .ant-card {
    height: 115px;
    margin: 0px 0px 5px 20px;
  }

  .ant-card-body {
    padding: 5px 15px !important;
  }
`

const FunnelConvertion1SCP = styled.div`
  background: #f1f1f1;
  position: absolute;
  left: 330px;
  top: 100px;
  padding: 10px;
  font-size: 30px;
  
  i {
    font-size: 60px;
    margin-right: 20px;
  }
`

const FunnelConvertion2SCP = styled.div`
  background: #f1f1f1;
  position: absolute;
  left: 330px;
  top: 220px;
  padding: 10px;
  font-size: 30px;
  
  i {
    font-size: 60px;
    margin-right: 20px;
  }
`

const FunnelConvertionMainSCP = styled.div`
  background: #f1f1f1;
  position: absolute;
  left: 100px;
  top: 170px;
  padding: 10px;
  font-size: 30px;
  
  i {
    font-size: 60px;
    margin-right: 20px;
  }
`

const ArrowSCP = styled.div`
  height: 312px;
  width: 6px;
  background: #545455;
  position: absolute;
  left: 70px;
  top: 20px;
  border: solid 1px #a3a3a3;
  border-bottom: none;
  
  i {
    font-size: 60px;
    margin-right: 20px;
    position: absolute;
    top: 305px;
    left: -28px;
  }
`
