import { FormPersonContactCP } from 'main/modules/people/components/form-person-contact/FormPersonContactCP'
import React, { useEffect, useState } from 'react'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { IMultiActionButtonOption } from 'main/common/components/multi-action-button/inner/IMultiActionButtonOption'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'

interface IPopoverPersonContactCPProps {
    visible: boolean
    children: JSX.Element
    onSave: (conversionType?: OpportunityDrawerActionTP) => void
    onClose: () => void
    entityCode: number
    entity: PersonContactEntityEnum
    activity?: IPersonContactResponseDTO
    additionalSecondaryButtons?: IMultiActionButtonOption[]
}

/**
 * Abre um popover com o formulario de contato com pessoa.
 */
export function PopoverPersonContactCP(props: IPopoverPersonContactCPProps): JSX.Element {

    const [hasAddedSome, setHasAddedSome] = useState<boolean>(false)

    const [activity, setActivity] = useState<IPersonContactResponseDTO>()
    useEffect(() => setActivity(props.activity), [props.activity])

    return (
        <PopOverCP
            visible={props.visible}
            onClose={() => {
                if (hasAddedSome)
                    props.onSave()
                else
                    props.onClose()
            }}
            placement={'right'}
            content={
                <FormPersonContactCP
                    entity={props.entity}
                    entityCode={props.entityCode}
                    onSave={(shouldAddNew, conversionType) => {

                        if (shouldAddNew) {
                            setActivity(undefined)
                            setHasAddedSome(true)
                            return
                        }

                        props.onSave(conversionType)
                    }}
                    personContact={activity}
                />
            }
        >
            { props.children }
        </PopOverCP>
    )
}
