import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IMarketingDestinationResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDestinationResponseDTO'
import { IMarketingSenderResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingSenderResponseDTO'
import React from 'react'
import styled from 'styled-components'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotListContactColumnICPProps {
    totalContacts: number
    destination: IMarketingDestinationResponseDTO
    sender?: IMarketingSenderResponseDTO
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 * Coluna de contatos da listagem de disparos
 */
export function ShotListItemContactColumnICP(props: IShotListContactColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const senderEmail = props.sender?.email
    const senderTxt = `${props.sender?.name ?? 'Remetente'} ${!!senderEmail ? `| ${senderEmail}` : ''}`

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>

            <TotalContactsSCP>
                <h1>{MaskUtils.applyNumberMask(props.totalContacts, 0)}</h1>
                <span>contatos</span>
            </TotalContactsSCP>

            <RowCP>
                <ColumnCP size={6}>
                    <TextCP text={'De:'} strong={true}/>
                </ColumnCP>
                <ColumnCP size={18}>
                    <TextCP text={senderTxt}/>
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={6}>
                    <TextCP text={'Para:'} strong={true}/>
                </ColumnCP>
                <ColumnCP size={18}>
                    <TextCP
                        wrap={true}
                        text={props.semegmentList.find((segment) => segment.code === props.destination.segmentCode)?.name ?? 'Lista de Segmentação não encontrada'}
                    />
                </ColumnCP>
            </RowCP>

        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`
  width: ${props => (props.isSmallDevice ? '100%' : '200px')};
  background: #fff !important;
`

const TotalContactsSCP = styled.div`
  text-align: center;
  padding: 1.2rem 0;
  border-bottom: 1px solid #dedede;
  margin: 0 10px;

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    margin: 1rem 0;
    color: #464646;
  }

`
