export enum GenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NOT_BINARY = 'NOT_BINARY',
    OTHER = 'OTHER'
}

export enum GenderPTEnum {
    MALE = 'Masculino',
    FEMALE = 'Feminino',
    NOT_BINARY = 'Não-binário',
    OTHER = 'Outro'
}
