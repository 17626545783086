/**
 * ENUM
 * Determina formatos de exibição de data/hora utilizados no sistema.
 */
export enum DateFormatEnum {

    BR_WITH_TIME_H_M = 'DD/MM/YYYY - HH:mm',
    BR_WITHOUT_TIME = 'DD/MM/YYYY',
    BR_DAY_MONTH = 'DD/MM',
    BR_MONTH2_YEAR4 = 'MM/YYYY',
    BR_MONTH3_YEAR4 = 'MMM/YYYY',

    US_WITHOUT_TIME = 'YYYY-MM-DD',
    US_WITH_TIME_H_M = 'YYYY-MM-DD - HH:mm',
    US_WITH_TIME_H_M_2 = 'YYYY-MM-DD HH:mm',

    MONTH = 'MMMM',
    MONTH_3 = 'MMM',

    TIME_H_M = 'HH:mm',
    TIME_H_M_S = 'HH:mm:ss',
    YEAR_4 = 'YYYY',

    DATE_WRITTEN_IN_FULL = 'dddd, LL, H:mm',
    DATE_WRITTEN_IN_FULL_WITHOUT_TIME = 'dddd, LL',
}
