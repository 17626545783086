import { IGenericSearchRequestDTO } from 'main/common/dtos/requests/IGenericSearchRequestDTO'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { SystemConfig } from 'main/config/SystemConfig'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { IMarketingAccountSearchRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingAccountSearchRequestDTO'
import { IMarketingDomainSaveRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingDomainSaveRequestDTO'
import { IMarketingDomainSearchRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingDomainSearchRequestDTO'
import { IMarketingLoginRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingLoginRequestDTO'
import { IMarketingRecurrentPlanSearchRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingRecurrentPlanSearchRequestDTO'
import { IMarketingSaveCampaignEmailRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSaveCampaignEmailRequestDTO'
import { IMarketingSaveCampaignSmsRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSaveCampaignSmsRequestDTO'
import { IMarketingScheduleShotRequetDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingScheduleShotRequetDTO'
import { IMarketingSearchSenderRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSearchSenderRequestDTO'
import { IMarketingSearchShotRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSearchShotRequestDTO'
import { IMarketingSenderSaveRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSenderSaveRequestDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'

/**
 * REQUISICOES relacionadas ao modulo de Marketing
 */
export class MarketingRequests {

    private static readonly _MODULE_BASE_SENDER = `${SystemConfig.getInstance().mktBaseUrl}/senders`
    private static readonly _MODULE_BASE_DOMAIN = `${SystemConfig.getInstance().mktBaseUrl}/domains`
    private static readonly _MODULE_BASE_ACCOUNT = `${SystemConfig.getInstance().mktBaseUrl}/accounts`

    private static readonly _ERROR_NO_MKT_ACCESS = 'Usuario nao possui acesso ao modulo marketing'

    static async searchSender(dto?: IMarketingSearchSenderRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_SENDER}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async deleteSender(code: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_SENDER}/${code}`,
            method: HttpMethodEnum.DELETE,
        })
    }

    static async sendSenderTokenEmail(senderCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_SENDER}/${senderCode}/send-email-token`,
            method: HttpMethodEnum.GET,
        })
    }

    static async saveSender(dto?: IMarketingSenderSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_SENDER}`,
            method: HttpMethodEnum.POST,
            params: dto,
        })
    }

    static async updateSender(senderCode: number, dto?: IMarketingSenderSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_SENDER}/${senderCode}`,
            method: HttpMethodEnum.PUT,
            params: dto,
        })
    }

    static validateToken(senderCode: number, senderToken: string, token: string): RequestConfigTP<{ token: string }> {
        return {
            url: `${MarketingRequests._MODULE_BASE_SENDER}/${senderCode}/validate-token`,
            method: HttpMethodEnum.POST,
            params: { token: senderToken },
            headers: [AuthUtils.getBearerAuthHeaderConfig(token)]
        }
    }

    static async getAccountBalance(accountType: MarketingAccountTypeEnum): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_ACCOUNT}/balance/${accountType}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async searchAccount(dto: IMarketingAccountSearchRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_ACCOUNT}`,
            method: HttpMethodEnum.GET,
            params: dto
        })
    }

    static async searchCampaignShot(shotType: MarketingAccountTypeEnum, dto: IMarketingSearchShotRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: MarketingRequests._getCampaignBaseUrl(shotType),
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async getCampaignShot(shotType: MarketingAccountTypeEnum, shotCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/${shotCode}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async deleteCampaignShot(shotType: MarketingAccountTypeEnum, shotCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/${shotCode}`,
            method: HttpMethodEnum.DELETE,
        })
    }

    static async getCampaignShotReports(
        shotType: MarketingAccountTypeEnum,
        shotCode: number,
        reportType: MarketingShotReportTypeEnum,
        dto: IGenericSearchRequestDTO
    ): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/${shotCode}/reports/${reportType}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async saveCampaignShot<ShotTP extends MarketingAccountTypeEnum>(
        shotType: ShotTP,
        dto: ShotTP extends MarketingAccountTypeEnum.EMAIL ? IMarketingSaveCampaignEmailRequestDTO : IMarketingSaveCampaignSmsRequestDTO,
    ): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}`,
            method: HttpMethodEnum.POST,
            params: dto,
        })
    }

    static async updateCampaignShot<ShotTP extends MarketingAccountTypeEnum>(
        shotType: ShotTP,
        shotCode: number,
        dto: ShotTP extends MarketingAccountTypeEnum.EMAIL ? IMarketingSaveCampaignEmailRequestDTO : IMarketingSaveCampaignSmsRequestDTO
    ): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/${shotCode}`,
            method: HttpMethodEnum.PUT,
            params: dto,
        })
    }

    static async scheduleCampaignShot(shotType: MarketingAccountTypeEnum, shotCode: number, dto: IMarketingScheduleShotRequetDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/schedule/${shotCode}`,
            method: HttpMethodEnum.POST,
            params: dto,
        })
    }

    static async cloneCampaignShot(shotType: MarketingAccountTypeEnum, shotCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/clone/${shotCode}`,
            method: HttpMethodEnum.POST,
        })
    }

    static async unscheduleCampaignShot(shotType: MarketingAccountTypeEnum, shotCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._getCampaignBaseUrl(shotType)}/unschedule/${shotCode}`,
            method: HttpMethodEnum.POST,
        })
    }

    static async getDomainsReqConfig(dto?: IMarketingDomainSearchRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: MarketingRequests._MODULE_BASE_DOMAIN,
            method: HttpMethodEnum.GET,
            params: dto
        })
    }

    static async getSaveDomainConfig(dto: IMarketingDomainSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: MarketingRequests._MODULE_BASE_DOMAIN,
            method: HttpMethodEnum.POST,
            params: dto,
        })
    }

    static async getDeleteDomainConfig(domainCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_DOMAIN}/${domainCode}`,
            method: HttpMethodEnum.DELETE,
        })
    }

    static async getValidateDomainConfig(domainCode: number): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${MarketingRequests._MODULE_BASE_DOMAIN}/${domainCode}/validate`,
            method: HttpMethodEnum.POST,
        })
    }

    static async getPlanSearchConfig(dto: IMarketingRecurrentPlanSearchRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequests._getMktRequestConfig({
            url: `${SystemConfig.getInstance().mktBaseUrl}/recurrent-plans`,
            method: HttpMethodEnum.GET,
            params: dto
        })
    }

    private static async _getMktRequestConfig(customConfig: RequestConfigTP): Promise<RequestConfigTP> {

        let error: unknown

        try {

            // if (!PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_MARKETING]))
            //     throw new Error(MarketingRequests._ERROR_NO_MKT_ACCESS)

            const mktToken = await MarketingRequests._getMarketingAuthToken()
            if (!mktToken)
                throw new Error(MarketingRequests._ERROR_NO_MKT_ACCESS)

            return {
                ...customConfig,
                headers: [AuthUtils.getBearerAuthHeaderConfig(mktToken)]
            }

        } catch (exception) {
            const aux = MarketingRequests._handleMktFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    /**
     * Esse metodo so deve ser chamado apos validacao de acesso ao modulo
     * marketing via utilitario aproprieado.
     * @see {PermissionUtils}
     */
    private static async _getMarketingAuthToken(): Promise<string> {

        type RequestReturnTP = IApiReturn<{ token: string }>
        const result = await RequestHelper.runRequest<RequestReturnTP>(MarketingRequests._getLoginConfig())

        const mktToken = result?.data.data?.token
        if (!mktToken)
            return ''

        AuthActions.setDomain(AppStateUtils.getDomainData()!.schema, mktToken)
        return mktToken
    }

    /**
     * Esse metodo so deve ser chamado apos validacao de acesso ao modulo
     * marketing via utilitario aproprieado.
     * @see {PermissionUtils}
     */
    private static _getLoginConfig(): RequestConfigTP {
        let error: any

        try {

            const schema = AppStateUtils.getDomainData()?.schema!

            const dto: IMarketingLoginRequestDTO = {
                slug: schema.slug,
                secret: schema.mktSecret!,
            }

            return {
                url: `${SystemConfig.getInstance().mktBaseUrl}/auth/login`,
                method: HttpMethodEnum.POST,
                params: dto,
                noAuth: true,
            }

        } catch (exception) {
            const aux = MarketingRequests._handleMktFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    private static _handleMktFailure(exception: any): any {
        console.error('FALHA - MarketingRequests: ', exception)
        if (exception.message !== MarketingRequests._ERROR_NO_MKT_ACCESS)
            return exception
        RoutingHelper.historyReplace('default')
    }

    private static _getCampaignBaseUrl(shotType: MarketingAccountTypeEnum): string {
        const shotTypeSegment = (shotType === MarketingAccountTypeEnum.EMAIL) ? 'emails' : 'sms'
        return `${SystemConfig.getInstance().mktBaseUrl}/${shotTypeSegment}/campaign`
    }
}
