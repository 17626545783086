import { ColumnProps } from 'antd/lib/table'
import { StringUtils } from 'main/common/utils/StringUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ISaleSourceReportListItemResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISaleSourceReportListItemResponseDTO'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

/**
 * Utilitario do relatorio de canais de venda.
 */
export const SaleSourcesSyntheticReportUtils = {

    getTableColumns(
        franchiseNames: string[]
    ): Array<ColumnProps<ISaleSourceReportListItemResponseDTO>> {

        const columns: Array<ColumnProps<ISaleSourceReportListItemResponseDTO>> = []

        columns.push({
            title: 'Canal',
            dataIndex: 'source',
            key: 'source',
            render: (text, dto) => dto.sourceName
        })

        // Itera nas franquias para criar uma coluna para cada, caso tenha mais de uma franquia. Senao exibe apenas o valor total
        if (franchiseNames.length > 1) {
            franchiseNames.forEach((franchiseName) => {
                columns.push({
                    title: franchiseName,
                    dataIndex: StringUtils.getSlugStyleString(franchiseName),
                    key: StringUtils.getSlugStyleString(franchiseName),
                    align: 'right',
                    render: (text, dto) => {

                        // Obtem o valor referente a franquia em questao
                        const franchiseReportData = dto.reportData.find(reportData => reportData.franchiseName === franchiseName)
                        return (
                            <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                                <TextCP text={`${franchiseReportData?.percent ?? 0}%`}/>
                                <TextCP text={MaskUtils.applyNumberMask(franchiseReportData?.sales ?? 0, 0)} size={'small'} color={ThemeAnt.gray} marginLeft={5}/>
                            </FlexCP>
                        )
                    }
                })
            })
        }

        columns.push({
            title: 'Total',
            dataIndex: 'total',
            align: 'center',
            key: 'total',
            render: (text, dto) => (
                <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                    <TextCP text={`${dto.currentPeriodTotalPercent}%`}/>
                    <TextCP text={MaskUtils.applyNumberMask(dto.currentPeriodTotalSales, 0)} size={'small'} color={ThemeAnt.gray} marginLeft={5}/>
                    <TextCP
                        text={`(${dto.lastPeriodTotalPercent}%)`}
                        icon={dto.currentPeriodTotalSales > dto.lastPeriodTotalSales ? 'caret-up' : 'caret-down'}
                        size={'small'}
                        color={ThemeAnt.gray}
                        marginLeft={5}
                    />
                </FlexCP>
            )
        })

        return columns
    },

}
