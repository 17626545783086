import React from 'react'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { SaleDetailsPdfHeaderICP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfHeaderICP'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { ISaleQuoteResponseDTO } from 'main/modules/sale/services/sale-quote/dtos/response/ISaleQuoteResponseDTO'
import { SaleDetailsPdfFooterCP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfFooterCP'
import { SaleDetailsPdfItemsICP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfItemsICP'

interface ISaleDetailsPdfCPProps {
    saleQuote: ISaleQuoteResponseDTO
}

/**
 * Componente com resumo do orcamento.
 */
export const SaleQuoteDetailsPdfCP = React.forwardRef((props: ISaleDetailsPdfCPProps, ref: any) => {

    return (
        <SaleDetailsPdfWrapperSCP ref={ref}>
            <SaleDetailsPdfHeaderICP
                type={SaleTypeEnum.SALE_QUOTE}
                code={props.saleQuote.code}
                sellerName={props.saleQuote.saleData.saleBasicData.personSeller.name}
                customerName={props.saleQuote.saleData.saleBasicData.personCustomer.name}
                saleDate={DateUtils.toDate(props.saleQuote.createDate, DateFormatEnum.US_WITH_TIME_H_M)}
                totalInstallments={props.saleQuote.saleData.salePaymentData.saleCharges?.[0].installments.length}
                paymentMethods={props.saleQuote.saleData.salePaymentData.saleCharges?.map((charge) => charge.paymentMethod)}
            />

            <SaleDetailsPdfItemsICP
                saleItems={
                    props.saleQuote.saleData.saleProductData.products.map((product) => ({
                        productRelease: { code: -1, name: product.releaseName },
                        productReleaseSoldValue: product.price,
                        quantity: product.quantity,
                        totalValue: product.quantity * product.price,
                    }))
                }
            />

            <SaleDetailsPdfFooterCP
                type={SaleTypeEnum.SALE_QUOTE}
                totalValue={props.saleQuote.saleData.salePaymentData.totalValue}
                subtotalValue={props.saleQuote.saleData.salePaymentData.originalTotalValue}
            />
        </SaleDetailsPdfWrapperSCP>
    )
})

const SaleDetailsPdfWrapperSCP = styled.div`
  padding: 40px;
`
