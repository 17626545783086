import React, { useEffect, useState } from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import {
    SALES_FUNNEL_SALES_SUBMENT_KEY,
    SalesFunnelScreenUtils
} from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelScreenUtils'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { SalesFunnelSalesViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelSalesViewEnum'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { ISalesFunnelSummaryResponseListItemDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/ISalesFunnelSummaryResponseListItemDTO'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { ISalesFunnelSummaryRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/ISalesFunnelSummaryRequestDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { StringUtils } from 'main/common/utils/StringUtils'
import { OrUndefTP } from 'main/common/types/OrUndefTP'

/**
 * TELA Funil de Vendas
 */
export function ScreenSalesFunnel(): JSX.Element {

    const [selectedUser, setSelectedUser] = useState<OrUndefTP<INameAndCodeResponseDTO>>(
        AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.opportunity?.shouldFilterOpportunitiesByResponsible
            ? { code: AppStateUtils.getLoggedUserData()!.user.code, name: AppStateUtils.getLoggedUserData()!.user.name }
            : undefined
    )

    const getSummaryRequest = useRequest<IGenericListResponseDTO<ISalesFunnelSummaryResponseListItemDTO>>()
    useEffect(onGetSummaryRequestChange, [getSummaryRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Busca dados do menu lateral.
     */
    function init(): void {
        loadSummaryFromApi(selectedUser?.code)
    }

    /**
     * Ao alterar um dado internamente.
     */
    function onDataChanged(user?: INameAndCodeResponseDTO): void {

        if (user?.code === selectedUser?.code)
            return

        setSelectedUser(user)
        loadSummaryFromApi(user?.code)
    }

    /**
     * Busca dados do menu lateral.
     */
    function loadSummaryFromApi(userCode?: number): void {

        const filters: ISalesFunnelSummaryRequestDTO = {
            userResponsibleCode: userCode,
            franchiseCodes: [AppStateUtils.getCurrentFranchise()!.code]
        }
        getSummaryRequest.runRequest(OpportunityRequests.getSalesFunnelSummary(filters))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSummaryRequest, 'Não foi possível obter o resumo'))
            return
    }

    return (
        <LayoutSubmenuCP<SalesFunnelOpportunityViewEnum | SalesFunnelSalesViewEnum>
            defaultSubmenu={SalesFunnelOpportunityViewEnum.LEAD_TODAY}
            menuTitle={'Funil de Vendas'}
            menuSubTitle={!!selectedUser ? `Apenas da(o) ${StringUtils.getFirstName(selectedUser.name)}` : undefined}
            submenuConfigs={
                SalesFunnelScreenUtils.getSubMenuConfigs(
                    getSummaryRequest.responseData?.list ?? [],
                    onDataChanged,
                    selectedUser
                )
            }
            loggedDomain={AppStateUtils.getDomainSlug()}
            defaultOpenKeys={[OpportunityTypeEnum.LEAD, OpportunityTypeEnum.EVALUATION, OpportunityTypeEnum.FOLLOWUP, SALES_FUNNEL_SALES_SUBMENT_KEY]}
            marginVertical={-10}
            loading={getSummaryRequest.isAwaiting}
        />
    )
}
