import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

interface ICPProps {
    expirationDate: string
    paymentDate?: string
}

/**
 * Coluna com o vencimento da transacao, contendo a data original e paga.
 */
export function ColumnTransactionExpirationDateCP(props: ICPProps): JSX.Element {

    return (
        <>
            { DateUtils.getFormatted(props.expirationDate, DateFormatEnum.BR_WITHOUT_TIME) }
            {
                !!props.paymentDate &&
                <FlexCP alignItems={'center'}>
                    <TextCP text={'pago em'} marginRight={5} size={'small'} color={ThemeAnt.gray}/>
                    <TextCP text={DateUtils.getFormatted(props.paymentDate, DateFormatEnum.BR_WITHOUT_TIME)} size={'small'} color={ThemeAnt.gray}/>
                </FlexCP>
            }
        </>
    )

}
