import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { QuickDateEnum } from 'main/common/enums/QuickDateEnum'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/types/InputValueCallbackTP'

const QUICK_DATE_BTN_CONFIGS: Array<{ label: string, value: QuickDateEnum }> = [
    { label: 'Hoje', value: QuickDateEnum.TODAY },
    { label: 'Amanhã', value: QuickDateEnum.TOMORROW },
    // { label: 'Esta Semana', value: QuickDateEnum.CURRENT_WEEK },
    { label: 'Este Mês', value: QuickDateEnum.CURRENT_MONTH },
]

interface IDateComboQuickDateButtonsICPProps {
    onChange: InputValueCallbackTP<QuickDateEnum>
    value?: QuickDateEnum
}

/**
 * COMPONENTE
 * Conjutno de botoes para selecao de datas 'rapidas' genericas.
 */
export function DateComboQuickDateButtonsICP(props: IDateComboQuickDateButtonsICPProps): JSX.Element {
    return (
        <ButtonsWrapperSCP>
            {
                QUICK_DATE_BTN_CONFIGS.map(config => (
                    <ButtonCP
                        key={`quick_date_${config.value}`}
                        ghost={props.value !== config.value}
                        type={'primary'}
                        size={'small'}
                        onClick={() => props.onChange(config.value)}
                    >
                        {config.label}
                    </ButtonCP>
                ))
            }
        </ButtonsWrapperSCP>
    )
}

const ButtonsWrapperSCP = styled.div`
    display: flex;
`
