/**
 * UTILITARIOS do componente da tela de contas bancarias
 */
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'

export const BankAccountScreenContentUtils = {

    getBalanceFooterLabel(type: BankAccountTypeEnum): string {

        switch (type) {

            case BankAccountTypeEnum.BANK_ACCOUNT:
                return 'Saldo nas contas bancárias'

            case BankAccountTypeEnum.CREDIT_CARD:
                return 'Valor em aberto nos cartões de crédito'

            case BankAccountTypeEnum.PAYMENT_ACCOUNT:
                return 'Saldo nas contas de recebimento'

            default:
                return ''
        }

    },

    getChartLabel(type: BankAccountTypeEnum): string {

        switch (type) {

            case BankAccountTypeEnum.BANK_ACCOUNT:
            case BankAccountTypeEnum.PAYMENT_ACCOUNT:
                return 'Saldo nos últimos 12 meses'

            case BankAccountTypeEnum.CREDIT_CARD:
                return 'Valor da fatura nos últimos 12 meses'

            default:
                return ''
        }

    }

}
