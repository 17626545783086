import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SelectDomainCP } from 'main/modules/marketing/components/select-domain/SelectDomainCP'
import { SenderFormValidator } from 'main/modules/marketing/components/sender-modal/inner/SenderFormValidator'
import { IMarketingSenderSaveRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSenderSaveRequestDTO'
import { IMarketingSenderResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingSenderResponseDTO'
import { MarketingSenderStatusEnum } from 'main/modules/marketing/enums/MarketingSenderStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

interface ISenderModalCPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    sender?: IMarketingSenderResponseDTO
}

/**
 * COMPONENTE do conteudo de suporte para modulo de marketing.
 *
 * @author renatofs
 */
export function SenderModalCP(props: ISenderModalCPProps): JSX.Element | null {

    useEffect(initialize, [props.visible])

    const [formValidator, setFormValidator] = useState<SenderFormValidator>((new SenderFormValidator()))
    const formStateManager = useFormStateManager<SenderFormValidator>(formValidator)

    const saveSenderRequest = useRequest<IMarketingSenderResponseDTO>()
    useEffect(onSaveSenderRequestChange, [saveSenderRequest.isAwaiting])

    /**
     * Inicializa modal
     */
    function initialize(): void {

        if (!props.visible)
            return

        if (props.sender) {
            setFormValidator(new SenderFormValidator({
                name: props.sender.name,
                emailPrefix: props.sender.emailPrefix,
                domainCode: props.sender.domainCode
            }))
            return
        }

    }

    /**
     * Salva remetente
     */
    async function saveSender(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const senderDto: IMarketingSenderSaveRequestDTO = {
            name: formValues.name,
            emailPrefix: formValues.emailPrefix,
            domainCode: formValues.domainCode,
            status: MarketingSenderStatusEnum.PENDING_VALIDATION // @TODO Remover e deixar na api
        }

        if (props.sender)
            saveSenderRequest.runRequest(await MarketingRequests.updateSender(props.sender.code, senderDto))
        else
            saveSenderRequest.runRequest(await MarketingRequests.saveSender(senderDto))
    }

    /**
     * Apos reotorno da api de salvar remetente
     */
    function onSaveSenderRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveSenderRequest, 'Ocorreu algum erro ao salvar remetente'))
            return

        NotificationHelper.info('Yes', 'Remetente salvo com sucesso')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Conta de envio'}
            onOk={saveSender}
            onCancel={props.onCancel}
            visible={props.visible}
            actionLoading={saveSenderRequest.isAwaiting}
        >
            <>
                <RowCP>
                    <ColumnCP size={24}>
                        <InputCP
                            label={'Nome do remetente'}
                            formStateManager={formStateManager}
                            fieldName={'name'}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP>
                    <ColumnCP size={11}>
                        <InputCP
                            label={'E-mail'}
                            formStateManager={formStateManager}
                            fieldName={'emailPrefix'}
                            required={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={2}>
                        <TextCP text={'@'} size={'large'} marginTop={20}/>
                    </ColumnCP>
                    <ColumnCP size={11}>
                        <SelectDomainCP
                            label={'Domínio'}
                            formStateManager={formStateManager}
                            fieldName={'domainCode'}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>
            </>

        </ModalCP>
    )
}
