import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { SchedulerReportsRequests } from 'main/modules/reports/services/scheduler-reports/SchedulerReportsRequests'
import { TableAppointmentsUtils } from 'main/modules/scheduler/components/table-appointments/inner/TableAppointmentsUtils'
import { IScheduleReportListItemResponseDTO } from 'main/modules/reports/services/scheduler-reports/dtos/response/IScheduleReportListItemResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { ButtonExportCP } from 'main/modules/app/components/button-export/ButtonExportCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'

interface ICPProps {
    filterStateManager: IFormStateManager<ReportsCommonFiltersFormModel>
    shouldReloadReport: boolean
    onReportLoaded: () => void
}

/**
 * COMPONENTE Tabela de agendamentos
 */
export function TableAppointmentsCP(props: ICPProps): JSX.Element {

    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    useEffect(() => setPagination(TableUtils.getDefaultPagination()), [])

    const scheduleListRequest = useRequest<IGenericListResponseDTO<IScheduleReportListItemResponseDTO>>()
    useEffect(onScheduleListRequestChange, [scheduleListRequest.isAwaiting])

    useEffect(init, [props.shouldReloadReport])

    /**
     * Carrega relatorio.
     */
    function init(): void {

        if (!props.shouldReloadReport)
            return

        const filters = TableAppointmentsUtils.mountFiltersDto(props.filterStateManager.getFormValues()!, pagination)
        scheduleListRequest.runRequest(SchedulerReportsRequests.scheduleList(filters))
    }

    /**
     * Retorno da requisicao.
     */
    function onScheduleListRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(scheduleListRequest, 'Não foi possível obter agendamentos'))
            return
        
        props.onReportLoaded()
    }

    return (
        <>
            <FlexCP justifyContent={'flex-end'} marginRight={20} marginTop={10}>
                <ButtonExportCP
                    requestConfig={() => SchedulerReportsRequests.scheduleListExport(
                        TableUtils.removePaginationAttributes(
                            TableAppointmentsUtils.mountFiltersDto(props.filterStateManager.getFormValues()!, pagination)
                        )
                    )
                    }
                    reportName={'agenda'}
                />
            </FlexCP>
            <TableCPOLD2
                wrappedOnCard={true}
                loading={scheduleListRequest.isAwaiting}
                data={scheduleListRequest.responseData?.list ?? []}
                columns={TableAppointmentsUtils.getColumns()}
                pagination={pagination}
                onPaginationChange={(page) => setPagination({ ...pagination, current: page.current! })}
            />
        </>
    )
}
