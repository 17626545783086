import _ from 'lodash'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { OpportunityBasicDataFormCP } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityBasicDataFormCP'
import { OpportunityFormModel } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityFormModel'
import { IOpportunitySaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySaveRequestDTO'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SelectTagCP } from 'main/modules/admin/components/select-tag/SelectTagCP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AutoCompleteOpportunitySourceCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity-source/AutoCompleteOpportunitySourceCP'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'

const MAXIMUM_CHARACTER_LIMIT = 255

interface IOpportunityDataFormCPProps {
    opportunity?: IOpportunityResponseDTO
    initialData?: ObjectPropsTP<OpportunityFormModel>
    onCancel: () => void
    onSave: (opportunity: IOpportunityResponseDTO, updateSummary?: OpportunityTypeEnum[], updateList?: OpportunityTypeEnum) => void
}

/**
 * COMPONENTE
 * Formulário para criação e edição de uma nova oportunidade
 */
export function OpportunityDataFormCP(props: IOpportunityDataFormCPProps): JSX.Element {

    useEffect(init, [props.opportunity, props.initialData])

    const [formValidator, setFormValidator] = useState<OpportunityFormModel>(new OpportunityFormModel())
    const formStateManager = useFormStateManager<OpportunityFormModel>(formValidator)
    const [selectedTags, setSelectedTags] = useState<number[]>([])

    const opportunitySaveRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onOpportunitySaveRequestChange, [opportunitySaveRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.opportunity && !props.initialData) {
            setFormValidator(new OpportunityFormModel({
                personResponsibleCode: AppStateUtils.getLoggedUserData()!.user.code,
            }))
            return
        }

        if (props.opportunity) {

            // Se ao concluir a edição o personResponsibleCode ou o sourceCode ainda forem strings, manter o code do props.opportunity
            setFormValidator(new OpportunityFormModel({
                name: props.opportunity.personCustomer ? props.opportunity.personCustomer.name : props.opportunity.leadData.name,
                phone: props.opportunity.personCustomer ? props.opportunity.personCustomer.phone : props.opportunity.leadData.phone,
                email: props.opportunity.personCustomer ? props.opportunity.personCustomer.email : props.opportunity.leadData.email,
                personResponsibleCode: props.opportunity.userResponsible.name,
                sourceCode: props.opportunity.source.name,
                source: props.opportunity.source,
                sourcePersonLinkCode: props.opportunity.sourcePersonLink?.code,
                sourcePersonLink: props.opportunity.sourcePersonLink,
                note: props.opportunity.note,
            }))
            setSelectedTags(_.isEmpty(props.opportunity.tags) ? [] : props.opportunity.tags.map(tag => tag.code))

            return
        }

        setFormValidator(new OpportunityFormModel(props.initialData))
    }

    /**
     * Salva os dados da oportunidade.
     */
    async function save(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()
        if (!await formStateManager.validate() || !formValues)
            return

        if (!formValues.email && !formValues.phone)
            return NotificationHelper.error('Ops!', 'Favor preencher E-MAIL ou TELEFONE do lead')

        if (formValues.note && formValues.note.length > MAXIMUM_CHARACTER_LIMIT)
            return NotificationHelper.warning('Atenção!', 'O limite de caracteres por comentário foi ultrapassado')

        if (formValues.source?.hasPersonLink && !formValues.sourcePersonLinkCode)
            return NotificationHelper.error('Ops!', 'Favor selecionar a pessoa indicada')

        const dto: IOpportunitySaveRequestDTO = {
            leadName: formValues.name,
            leadEmail: formValues.email,
            leadPhone: MaskUtils.removeMask(formValues.phone),
            note: formValues.note,
            tags: selectedTags,
            userResponsibleCode: _.isString(formValues.personResponsibleCode) ? props.opportunity!.userResponsible.code : formValues.personResponsibleCode,
            sourceCode: _.isString(formValues.sourceCode) ? props.opportunity!.source.code : formValues.sourceCode,
            sourcePersonLinkCode: formValues.sourcePersonLinkCode,
            personCustomerCode: formValues.personCustomerCode
        }

        if (props.opportunity?.code)
            opportunitySaveRequest.runRequest(OpportunityRequests.updateConfig(props.opportunity.code, dto))
        else
            opportunitySaveRequest.runRequest(OpportunityRequests.createConfig(dto))
    }

    /**
     * Retorno ao salvar oportunidade
     */
    function onOpportunitySaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(opportunitySaveRequest, 'Erro ao salvar o lead.'))
            return

        NotificationHelper.success('Pronto!', 'Salvo com sucesso')
        const updateType = (props.opportunity && props.opportunity.type) ? props.opportunity.type : OpportunityTypeEnum.LEAD

        props.onSave(opportunitySaveRequest.responseData!, [updateType], updateType)
    }

    return (
        <FormWrapperSCP>
            <OpportunityBasicDataFormCP
                formStateManager={formStateManager}
                allowEdition={true}
                loadedOpportunity={props.opportunity}
            />

            <AutocompleteUserCP
                label={'Selecione o vendedor'}
                required={true}
                showLoggedUserAsInitialOption={true}
                formStateManager={formStateManager}
                fieldName={'personResponsibleCode'}
            />

            <AutoCompleteOpportunitySourceCP
                label={'Origem do Lead'}
                required={true}
                fieldName={'sourceCode'}
                sourcePersonLinkFieldName={'sourcePersonLinkCode'}
                formStateManager={formStateManager}
                sourcePersonLinkInitialOption={props.opportunity?.sourcePersonLink ?? props.initialData?.sourcePersonLink}
                onSelectOpportunitySource={(source) => formStateManager.changeFieldValue('source', source)}
            />

            <OtherInfosWrapperSCP>
                <TextCP text={'Áreas/Serviços de Interesse'} icon={'tag'}/>
                <SelectTagCP
                    multiple={true}
                    onChange={(value: number[]): void => setSelectedTags(value)}
                    selected={selectedTags}
                />
            </OtherInfosWrapperSCP>

            <OtherInfosWrapperSCP>
                <TextCP text={'Observações'} icon={'edit'}/>
                <TextAreaCP
                    fieldName={'note'}
                    placeholder={`Limite de ${MAXIMUM_CHARACTER_LIMIT} caracteres por observação`}
                    formStateManager={formStateManager}
                    value={formStateManager.getFieldValue('note')}
                    minRows={4}
                />
            </OtherInfosWrapperSCP>

            <FlexCP justifyContent={'space-between'}>
                <div>
                    {
                        !!props.opportunity &&
                        <ButtonCP
                            type={'ghost'}
                            onClick={props.onCancel}
                        >
                            Cancelar
                        </ButtonCP>
                    }
                </div>
                <ButtonCP
                    type={'primary'}
                    onClick={save}
                    loading={opportunitySaveRequest.isAwaiting}
                >
                    Salvar
                </ButtonCP>
            </FlexCP>
        </FormWrapperSCP>
    )
}

const FormWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
`

const OtherInfosWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .ant-typography {
        font-size: 12px;
        color: #676767;
        text-transform: uppercase;
        font-weight: 600;
    }

    i {
        font-size: 18px;
        color: ${(props): string => props.theme.primaryColor};
    }
`
