import React, { useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerFiltersCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/inner/DrawerFiltersCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IFastFiltersWithDrawerCPProps {
    onClearFilters?: () => void
    onFilter: () => void
    leftContent?: JSX.Element | JSX.Element[]
    rightContent?: JSX.Element | JSX.Element[]
    drawerContent: JSX.Element | JSX.Element[]
    isLoading?: boolean
}

/**
 * Area de filtros + drawer com filtros avancados.
 */
export function FastFiltersWithDrawerCP(props: IFastFiltersWithDrawerCPProps): JSX.Element {

    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

    return (
        <>
            <FlexCP
                justifyContent={'space-between'}
                marginBottom={15}
                alignItems={'center'}
            >
                <FlexCP>
                    <>
                        {' '}
                        {props.leftContent}
                        {' '}
                    </>
                </FlexCP>
                <FlexCP justifyContent={'flex-end'}>
                    <ConditionalRenderCP shouldRender={!!props.rightContent}>
                        {props.rightContent!}
                    </ConditionalRenderCP>
                    <ButtonCP
                        marginLeft={25}
                        icon={'filter'}
                        onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                    >
                        Filtros
                    </ButtonCP>
                </FlexCP>
            </FlexCP>

            <DrawerFiltersCP
                visible={isDrawerVisible}
                onClose={() => setIsDrawerVisible(false)}
                onClearFilters={() => {
                    if (!!props.onClearFilters) {
                        props.onClearFilters()
                        setIsDrawerVisible(false)
                    }
                }}
                onFilter={props.onFilter}
                isLoading={props.isLoading}
            >
                {props.drawerContent}
            </DrawerFiltersCP>
        </>
    )
}
