import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { SystemConfig } from 'main/config/SystemConfig'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { IMarketingLoginRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingLoginRequestDTO'

/**
 * Utils para requisicoes de Marketing
 */
export class MarketingRequestUtils {

    private static readonly _ERROR_NO_MKT_ACCESS = 'Usuario nao possui acesso ao modulo marketing'

    public static async getMktRequestConfig(customConfig: RequestConfigTP): Promise<RequestConfigTP> {

        let error: unknown

        try {

            // if (!PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_MARKETING]))
            //     throw new Error(MarketingRequests._ERROR_NO_MKT_ACCESS)

            const mktToken = await MarketingRequestUtils._getMarketingAuthToken()
            if (!mktToken)
                throw new Error(MarketingRequestUtils._ERROR_NO_MKT_ACCESS)

            return {
                ...customConfig,
                headers: [AuthUtils.getBearerAuthHeaderConfig(mktToken)]
            }

        } catch (exception) {
            const aux = MarketingRequestUtils._handleMktFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    /**
     * Esse metodo so deve ser chamado apos validacao de acesso ao modulo
     * marketing via utilitario aproprieado.
     * @see {PermissionUtils}
     */
    private static async _getMarketingAuthToken(): Promise<string> {

        type RequestReturnTP = IApiReturn<{ token: string }>
        const result = await RequestHelper.runRequest<RequestReturnTP>(MarketingRequestUtils._getLoginConfig())

        const mktToken = result?.data.data?.token
        if (!mktToken)
            return ''

        AuthActions.setDomain(AppStateUtils.getDomainData()!.schema, mktToken)
        return mktToken
    }

    /**
     * Esse metodo so deve ser chamado apos validacao de acesso ao modulo
     * marketing via utilitario aproprieado.
     * @see {PermissionUtils}
     */
    private static _getLoginConfig(): RequestConfigTP {
        let error: any

        try {

            const schema = AppStateUtils.getDomainData()?.schema!

            const dto: IMarketingLoginRequestDTO = {
                slug: schema.slug,
                secret: schema.mktSecret!,
            }

            return {
                url: `${SystemConfig.getInstance().mktBaseUrl}/auth/login`,
                method: HttpMethodEnum.POST,
                params: dto,
                noAuth: true,
            }

        } catch (exception) {
            const aux = MarketingRequestUtils._handleMktFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    private static _handleMktFailure(exception: any): any {
        console.error('FALHA - MarketingRequests: ', exception)
        if (exception.message !== MarketingRequestUtils._ERROR_NO_MKT_ACCESS)
            return exception
        RoutingHelper.historyReplace('default')
    }

}
