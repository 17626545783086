import React, { useEffect, useRef, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { ISaleQuoteResponseDTO } from 'main/modules/sale/services/sale-quote/dtos/response/ISaleQuoteResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { useReactToPrint } from 'react-to-print'
import styled from 'main/config/theme/styledWithTheme'
import { SaleQuoteDetailsPdfCP } from 'main/modules/sale/components/sale-details-pdf/SaleQuoteDetailsPdfCP'
import { SaleQuoteRequests } from 'main/modules/sale/services/sale-quote/SaleQuoteRequests'
import { renderToStaticMarkup } from 'react-dom/server'
import { SaleDetailRawHtml } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailRawHtml'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ISaleQuoteSendByWhtasappRequestDTO } from 'main/modules/sale/services/sale-quote/dtos/request/ISaleQuoteSendByWhtasappRequestDTO'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { TextCP } from 'main/common/components/text/TextCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface ISaleDetailsDrawerCPProps {
    visible: boolean
    code: number
    onClose: () => void
    onDoSale: (saleQuote: ISaleQuoteResponseDTO) => void
}

/**
 * Drawer com detalhes de uma venda.
 */
export function DrawerSaleQuoteDetailsCP(props: ISaleDetailsDrawerCPProps): JSX.Element {

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: `nc-orcamento-${props.code}`,
        content: () => componentRef.current as any,
    })

    const hasWhatsAppModule: boolean = !!AppStateUtils.getCurrentFranchise()?.integrationKeys.uTalk?.token

    const [saleQuote, setSaleQuote] = useState<ISaleQuoteResponseDTO>()

    const sendByWhatsappRequest = useRequest<void>('none')
    useEffect(onSendByWhatsappRequestChange, [sendByWhatsappRequest.isAwaiting])

    /**
     * Envia orcamento por whatsapp
     */
    async function sendByWhatsapp(): Promise<void> {

        if (!saleQuote)
            return

        const reactToHtml = renderToStaticMarkup(<SaleQuoteDetailsPdfCP saleQuote={saleQuote} ref={componentRef}/>)
        const htmlWithTemplate = SaleDetailRawHtml.replace('sale-detail-content-to-substitute', reactToHtml)

        const dto: ISaleQuoteSendByWhtasappRequestDTO = {
            code: props.code,
            html: htmlWithTemplate
        }
        sendByWhatsappRequest.runRequest(SaleQuoteRequests.sendQuoteByWhatsapp(dto))
    }

    /**
     * Apos reotorno da api.
     */
    function onSendByWhatsappRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(sendByWhatsappRequest, 'Ocorreu algun erro ao enviar orçamento por whatsapp'))
            return

        NotificationHelper.info('Yes', 'Orçamento enviado por whatsapp com sucesso')
    }

    return (
        <DrawerCP
            title={'Detalhes da Orçamento'}
            width={700}
            visible={props.visible}
            onClose={props.onClose}
            requestMainEntityConfig={!!props.code ? () => SaleQuoteRequests.getOne(props.code) : undefined}
            onLoadMainEntity={setSaleQuote}
        >
            {
                !!saleQuote &&
                <>
                    <FlexCP justifyContent={'space-between'} marginBottom={20}>
                        <FlexCP>
                            <ButtonCP
                                type={'ghost'}
                                onClick={handlePrint}
                                icon={'printer'}
                                marginRight={5}
                            >
                                Imprimir
                            </ButtonCP>

                            <ButtonCP
                                onClick={sendByWhatsapp}
                                loading={sendByWhatsappRequest.isAwaiting}
                                type={'ghost'}
                                disabled={!hasWhatsAppModule}
                                tooltip={!hasWhatsAppModule ? 'Você não tem o módulo de disparo por whatsapp. Entre em contato com a nossa equipe' : undefined}
                            >
                                <FontAwsomeIconCP type={faWhatsapp}/>
                                <TextCP text={'Enviar por Whatsapp'} marginLeft={5}/>
                            </ButtonCP>
                        </FlexCP>

                        <ButtonCP
                            type={'primary'}
                            icon={'shopping-cart'}
                            onClick={() => props.onDoSale(saleQuote)}
                        >
                            Gerar Venda
                        </ButtonCP>
                    </FlexCP>

                    <PrintAreaWrapperSCP>
                        <SaleQuoteDetailsPdfCP
                            saleQuote={saleQuote}
                            ref={componentRef}
                        />
                    </PrintAreaWrapperSCP>
                </>
            }
        </DrawerCP>
    )
}

const PrintAreaWrapperSCP = styled.div`
  display: grid;
  border: dashed #dedede 1px;
  border-radius: 5px;
  padding: 10px;

  // Precisa tirar o padding que foi inserido para impressao
  // Dessa forma so tira na tela, e na impressao ira ter o padding interno
  div:first-of-type {
    padding: 0;
  }
`
