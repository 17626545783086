import React from 'react'
import styled from 'styled-components'
import { CardCP } from 'main/common/components/card/CardCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

interface IChartSalesFunnelConversionCardICPProps {
    header: JSX.Element
    footer: JSX.Element
    conversionRate: number
}

/**
 * Cards do funil de vendas.
 */
export function ChartSalesFunnelConversionCardICP(props: IChartSalesFunnelConversionCardICPProps): JSX.Element {

    return (
        <FunnelConvertionSCP>
            <CardCP>
                {props.header}
                <FlexCP alignItems={'center'}>
                    <IconICP iconName={'arrow-down'} size={20}/>
                    <TextCP text={`${props.conversionRate}%`} marginLeft={5}/>
                </FlexCP>
                {props.footer}
            </CardCP>
        </FunnelConvertionSCP>
    )
}

const FunnelConvertionSCP = styled.div`
  .ant-card-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; 
  }
`
