import React, { useEffect, useState } from 'react'
import { TreatmentFormRequests } from 'main/modules/admin/services/treatment-form/TreatmentFormRequests'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFormQuestionSaveRequestDTO } from 'main/modules/admin/services/treatment-form/dtos/request/IFormQuestionSaveRequestDTO'
import { FormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/FormQuestionResponseDTO'

interface ICPProps {
    onSave: (data: FormQuestionResponseDTO) => void
    onCancel: () => void
    initialName?: string
    visible: boolean
}

/**
 * Seletor de formulario de questoes de tratamento.
 */
export function ModalFormQuestionCP(props: ICPProps): JSX.Element {

    const [name, setName] = useState<string>()

    const saveFormQuestionRequest = useRequest<FormQuestionResponseDTO>()
    useEffect(onSaveFormQuestionRequestChange, [saveFormQuestionRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.visible)
            return

        setName(props.initialName)
    }

    /**
     * Salva.
     */
    async function saveQuestion(): Promise<void> {

        if (!name) {
            NotificationHelper.error('Ops!', 'Informe o nome do campo')
            return
        }

        const dto: IFormQuestionSaveRequestDTO = {
            title: name,
        }
        saveFormQuestionRequest.runRequest(TreatmentFormRequests.saveFormQuestion(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveFormQuestionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFormQuestionRequest, 'Ocorreu algun erro ao salvar campo'))
            return

        NotificationHelper.info('Yes', 'Campo criado com sucesso')

        const questionResponseDTO = saveFormQuestionRequest.responseData!
        props.onSave(questionResponseDTO)

        setName('')
    }

    return (
        <ModalCP
            title={'Campo de Formulário'}
            visible={props.visible}
            onOk={saveQuestion}
            onCancel={props.onCancel}
            width={300}
            actionLoading={saveFormQuestionRequest.isAwaiting}
        >
            <InputCP
                label={'Nome do Campo'}
                value={name}
                onChange={setName}
                required={true}
            />
        </ModalCP>
    )
}
