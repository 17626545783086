import React from 'react'
import styled from 'styled-components'
import { SaleItemTableCP } from 'main/modules/sale/components/sale-item-table/SaleItemTableCP'
import { SaleItemTableUtils } from 'main/modules/sale/components/sale-item-table/inner/SaleItemTableUtils'
import { ISaleItemsResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleItemsResponseDTO'

interface ICPProps {
    saleItems: ISaleItemsResponseDTO[]
}

/**
 * Cabecalho do PDF da Venda
 */
export function SaleDetailsPdfItemsICP(props: ICPProps): JSX.Element {

    return (
        <GridWrapperSCP>
            <TableWrapperSCP>
                <SaleItemTableCP
                    productList={props.saleItems}
                    columns={SaleItemTableUtils.getSalesItemsColumns()}
                />
            </TableWrapperSCP>
        </GridWrapperSCP>
    )

}

const TableWrapperSCP = styled.div`
    margin: 40px 0;
`

const GridWrapperSCP = styled.div`
    display: grid;
`
