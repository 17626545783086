import { PersonTableContentICP } from 'main/modules/people/components/person-table/inner/components/PersonTableContentICP'
import { IPersonTableCPProps } from 'main/modules/people/components/person-table/inner/IPersonTableCPProps'
import { PersonTableContext } from 'main/modules/people/components/person-table/inner/PersonTableContext'
import React from 'react'

/**
 * COMPONENTE
 */
export function PersonTableCP(props: IPersonTableCPProps): JSX.Element {
    return (
        <PersonTableContext.Provider>
            <PersonTableContentICP
                mustUpdate={props.mustUpdate}
                segmentType={props.segmentType}
                segmentExpression={props.segmentExpression}
                onSelectPerson={props.onSelectPerson}
                onLoadingStateChange={props.onLoadingStateChange}
                openPersonDetailsDrawer={props.openPersonDetailsDrawer}
                openAddJobFunctionModal={props.openAddJobFunctionModal}
            />
        </PersonTableContext.Provider>
    )
}
