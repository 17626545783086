import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { GenderSelectorCP } from 'main/modules/people/components/gender-selector/GenderSelectorCP'
import { PersonFormModel } from 'main/modules/people/components/drawer-person-data/inner/PersonFormModel'
import React from 'react'
import styled from 'styled-components'
import { InputUniqueFieldsPersonCP } from 'main/modules/people/components/input-unique-fields-person/InputUniqueFieldsPersonCP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'

interface IBasicInfoPanelCPProps{
    formStateManager: IFormStateManager<PersonFormModel>
    onSearchPersonResult: (existsInFranchise: boolean, person?: IPersonResponseDTO) => void
    loadedPerson?: IPersonResponseDTO
    initialData?: ObjectPropsTP<PersonFormModel>
}

/**
 * COMPONENTE
 * Campos básicos do cadastro de uma pessoa.
 */
export function BasicInfoPanelCP(props: IBasicInfoPanelCPProps): JSX.Element {

    return (
        <FormWrapperSCP>

            <ColumnCP size={12}>
                <InputUniqueFieldsPersonCP
                    onSearchPersonResult={props.onSearchPersonResult}
                    formStateManager={props.formStateManager}
                    disabled={!!props.loadedPerson?.cpfCnpj}
                    fieldName={'cpfCnpj'}
                    label={'CPF / CNPJ'}
                    type={'cpf'}
                    loadedPerson={props.loadedPerson}
                />
            </ColumnCP>

            <InputCP<PersonFormModel>
                label={'Nome completo'}
                fieldName={'name'}
                formStateManager={props.formStateManager}
                required={true}
            />

            <InputUniqueFieldsPersonCP
                onSearchPersonResult={props.onSearchPersonResult}
                formStateManager={props.formStateManager}
                fieldName={'email'}
                label={'E-mail'}
                type={'email'}
                loadedPerson={props.loadedPerson}
                initialData={props.initialData?.email}
            />

            <TwoColumnsSCP>

                <InputUniqueFieldsPersonCP
                    onSearchPersonResult={props.onSearchPersonResult}
                    formStateManager={props.formStateManager}
                    label={'Telefone'}
                    fieldName={'phone'}
                    type={'phone'}
                    loadedPerson={props.loadedPerson}
                />

                <InputCP<PersonFormModel>
                    label={'RG '}
                    fieldName={'rg'}
                    formStateManager={props.formStateManager}
                />
            </TwoColumnsSCP>

            <TwoColumnsSCP>
                <GenderSelectorCP
                    fieldName={'gender'}
                    formStateManager={props.formStateManager}
                />

                <InputCP<PersonFormModel>
                    label={'Data de nascimento'}
                    fieldName={'birthDate'}
                    formStateManager={props.formStateManager}
                    mask={InputMaskTypeEnum.DATE}
                />
            </TwoColumnsSCP>

            <TextAreaCP
                label={'Notas'}
                formStateManager={props.formStateManager}
                fieldName={'notes'}
                minRows={3}
                maxRows={5}
                value={props.formStateManager.getFieldValue('notes')}
            />

        </FormWrapperSCP>
    )
}
const FormWrapperSCP = styled.div`  
    display: grid;
    grid-template-columns: 1fr;   
    width: 100%;
  
`

const TwoColumnsSCP = styled.div`  
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    width: 100%;
`
