import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { OrNullishTP } from 'main/common/types/OrNullishTP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { LogicOperationTP } from 'main/common/types/LogicOperationTP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

export type FranchiseOrFranchisorTP = 'franchise' | 'franchisor' | 'all'

/**
 * UTILITARIOS: Permissoes de acesso a recursos do sistema.
 */
export class PermissionUtils {

    /** Exibe notificacao padrao tentativa de acesso a rota nao atorizada. */
    static showDefaultDeniedAccessMsg(): void {
        NotificationHelper.warning('Ops!', 'Você não tem permissão para acessar esse recurso.')
    }

    /**
     * Se usuario logado pode ver vendas de outras franquias
     */
    static canViewSaleBetweenFranchises(saleFranchiseCode: number): boolean {

        if (saleFranchiseCode === AppStateUtils.getCurrentFranchise()?.code)
            return true

        // Se nao estiver na franquia da venda, olha a permissao das configs
        return !AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.shouldHideSaleDetaisBetweenFranchises ?? true
    }

    /**
     * Determina se usuario informado tem acesso ao modulo informado.
     */
    static isModuleAvailable(module: ModuleEnum): boolean {

        const userPermissions = AppStateUtils.getCurrentFranchise()?.permissions ?? []
        if (!userPermissions.length)
            return false

        switch (module) {
            case ModuleEnum.MODULE_DASHBOARD:
                return !!AppStateUtils.getUserFranchisor()

            case ModuleEnum.MODULE_MARKETING:
                return userPermissions.includes(PermissionEnum.ROLE_MARKETING)

            case ModuleEnum.MODULE_SCHEDULER:
                return userPermissions.includes(PermissionEnum.ROLE_SCHEDULER)

            case ModuleEnum.MODULE_CRM:
                return userPermissions.includes(PermissionEnum.ROLE_CRM)

            case ModuleEnum.MODULE_FINANCIAL:
                return userPermissions.includes(PermissionEnum.ROLE_FINANCIAL)

            case ModuleEnum.MODULE_ADMIN:
                return userPermissions.includes(PermissionEnum.ROLE_ADMIN)

            case ModuleEnum.MODULE_DOKFY:
                return true

            case ModuleEnum.MODULE_LISOLASER:
                return AppStateUtils.getDomainSlug() === 'lisolaser' || AppStateUtils.getDomainSlug() === 'trial'

            default:
                return false
        }
    }

    /**
     * Determina se usuario informado possui permissoes informadas (logica 'and').
     */
    static arePermissionsGranted(permissions: PermissionEnum[], logic: LogicOperationTP = 'or'): boolean {

        const userPermissions = AppStateUtils.getCurrentFranchise()?.permissions ?? []
        if (!userPermissions.length)
            return false

        for (const requiredPermission of permissions) {

            let hasPermission = userPermissions.includes(requiredPermission)

            if (hasPermission && requiredPermission === PermissionEnum.ROLE_MARKETING)  // Caso especifico: Marketing
                hasPermission = !!AppStateUtils.getDomainData()?.schema.mktSecret

            if (hasPermission && logic === 'or')
                return true

            if (!hasPermission && logic === 'and')
                return false
        }

        return (logic === 'and')
    }

    /**
     * Determina se usuario informado tem acesso a 01 recurso de acordo com a combinacao de exigencias de
     * habilitacao de modulo & permissoes de acesso.
     */
    static hasAccess(
        module: OrNullishTP<ModuleEnum>,
        permissions: OrNullishTP<PermissionEnum[]>,
        franchiseOrFranchisor: FranchiseOrFranchisorTP = 'all',
        logic: LogicOperationTP = 'or',

    ): boolean {

        // Se habilitado apenas para franqueadora, e usuario esta logado como franquia, nao mostra
        if (franchiseOrFranchisor === 'franchisor' && !AppStateUtils.getCurrentFranchise()?.isFranchisor)
            return false

        // Se habilitado apenas para franquia, e usuario esta logado como franqueadora, nao mostra
        if (franchiseOrFranchisor === 'franchise' && AppStateUtils.getCurrentFranchise()?.isFranchisor)
            return false

        const isModuleOk = (!module || PermissionUtils.isModuleAvailable(module))
        const isPermissionsOk = (!permissions || PermissionUtils.arePermissionsGranted(permissions, logic))
        return (isModuleOk && isPermissionsOk)
    }
}
