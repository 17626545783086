import React, { useEffect, useState } from 'react'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ScheduleSessionsFormModel } from 'main/modules/scheduler/components/drawer-schedule-session/inner/ScheduleSessionsFormModel'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { ScheduleRequests } from 'submodules/neritclin-sdk/services/schedule/ScheduleRequests'
import { HasOverlapsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/HasOverlapsRequestDTO'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'

interface ICPProps {
    shouldUpdate?: number
    formStateManager: IFormStateManager<ScheduleSessionsFormModel>
}

/**
 * Alerta informando que o total de imagens do exame
 */
export function AlertOverlapSessionCP (props: ICPProps): JSX.Element {

    const [isSessionOverlaping, setIsSessionOverlaping] = useState<boolean>(false)
    const getHasOverlap = useRequest<IApiReturn<boolean>>('none')
    useEffect(onGetHasOverlapRequestChange, [getHasOverlap.isAwaiting])

    useEffect(init, [props.shouldUpdate])

    /**
     */
    function init(): void {

        if (!props.shouldUpdate)
            return

        const formValues = props.formStateManager.getFormValues()

        if (!formValues)
            return

        const dto: HasOverlapsRequestDTO = {
            professionalCode: +formValues.userCode,
            sessions: formValues.sessions,
        }
        getHasOverlap.runRequest(ScheduleRequests.hasOverlap(dto))
    }

    /**
     */
    function onGetHasOverlapRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getHasOverlap, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const overlaps = getHasOverlap.responseData?.data
        setIsSessionOverlaping(overlaps ?? false)
    }

    if (!isSessionOverlaping)
        return <></>

    return (
        <AlertCP
            message={
                <FlexCP alignItems={'center'} justifyContent={'space-between'}>
                    <TextCP
                        text={'O agendamento está em conflito com outro horário, deseja encaixar no horário disponível?'}
                    />

                    <SwitchCP
                        formStateManager={props.formStateManager}
                        fieldName={'shouldShrink'}
                        isTextInside={true}
                        textInsideChecked={'Sim'}
                        textInsideUnchecked={'Não'}
                    />
                </FlexCP>
            }
            type={'warning'}
            margin={{ bottom: 20, top: 20 }}
        />
    )
}
