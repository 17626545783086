import React, { useState } from 'react'
import styled from 'styled-components'
import { DashboardFranchiseTitleCP } from 'main/modules/dashboard/components/dashboard-franchise-title/DashboardFranchiseTitleCP'
import { WidgetsRowActualEvaluationIndicatorsCP } from 'main/modules/dashboard/components/widgets-row-actual-evaluation-indicators/WidgetsRowActualEvaluationIndicatorsCP'
import { WidgetTableTotalEvaluationByUserCP } from 'main/modules/dashboard/components/widget-table-total-evaluation-by-user/WidgetTableTotalEvaluationByUserCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { TabsFranchiseManagerCP } from 'main/modules/dashboard/components/tabs-franchise-manager/TabsFranchiseManagerCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { WidgetsRowLeadIndicators } from 'main/modules/dashboard/components/widgets-row-actual-lead-indicators/WidgetsRowLeadIndicators'
import { WidgetTableTotalLeadsTodayByUserCP } from 'main/modules/dashboard/components/widget-table-total-leads-today-by-user/WidgetTableTotalLeadsTodayByUserCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { WidgetsRowActualFollowupIndicatorsCP } from 'main/modules/dashboard/components/widgets-row-actual-followup-indicators/WidgetsRowActualFollowupIndicatorsCP'
import { WidgetTableTotalFollowupsTodayByUserCP } from 'main/modules/dashboard/components/widget-table-total-followups-today-by-user/WidgetTableTotalFollowupsTodayByUserCP'

/**
 * Dashboard da técnica de procedimento
 */
export function ScreenContentFranchiseManagerDashboardTabCP(): JSX.Element {

    const [selectedFranchise, setSelectedFranchise] = useState<INameAndCodeResponseDTO>({
        code: AppStateUtils.getCurrentFranchise()!.code,
        name: AppStateUtils.getCurrentFranchise()!.name
    })

    return (
        <WrapperSCP>

            <DashboardFranchiseTitleCP franchise={selectedFranchise} onSelectFranchise={setSelectedFranchise}/>
            <WidgetsRowLeadIndicators franchiseCodes={[selectedFranchise.code]}/>
            <WidgetsRowCP marginBottom={30} marginTop={30}>
                <WidgetTableTotalLeadsTodayByUserCP franchiseCodes={[selectedFranchise.code]}/>
            </WidgetsRowCP>

            <WidgetsRowActualEvaluationIndicatorsCP franchiseCodes={[selectedFranchise.code]}/>
            <WidgetsRowCP marginBottom={30} marginTop={30}>
                <WidgetTableTotalEvaluationByUserCP franchiseCodes={[selectedFranchise.code]}/>
            </WidgetsRowCP>

            <WidgetsRowActualFollowupIndicatorsCP franchiseCodes={[selectedFranchise.code]}/>
            <WidgetsRowCP marginBottom={30} marginTop={30}>
                <WidgetTableTotalFollowupsTodayByUserCP franchiseCodes={[selectedFranchise.code]}/>
            </WidgetsRowCP>

            <TabsFranchiseManagerCP franchiseCodes={[selectedFranchise.code]}/>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
