import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { SystemConfig } from 'main/config/SystemConfig'
import { IBankSearchRequestDTO } from 'main/modules/financial/services/nerit-payments/dtos/request/IBankSearchRequestDTO'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * REQUISICOES no micro servico de pagamentos da NErit
 */
export class NeritPaymentsRequests {

    private static readonly _MODULE_BASE_BANK_ACCOUNT = `${SystemConfig.getInstance().neritPaymentsBaseUrl}/banks`
    private static readonly _MODULE_BASE_CREDIT_CARD = `${SystemConfig.getInstance().neritPaymentsBaseUrl}/credit-cards`
    private static readonly _MODULE_BASE_PAYMENT_PARTNERS = `${SystemConfig.getInstance().neritPaymentsBaseUrl}/payment-partners`

    private static readonly _ERROR_NO_MODOULE_ACCESS = 'Usuario nao possui acesso a NeritPayments'

    static async searchBanks(dto?: IBankSearchRequestDTO): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_BANK_ACCOUNT}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async searchCreditCardFlags(dto?: IBankSearchRequestDTO): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_CREDIT_CARD}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async searchPaymentPartners(dto?: IBankSearchRequestDTO): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_PAYMENT_PARTNERS}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async getBank(accountId: string): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_BANK_ACCOUNT}/${accountId}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async getCreditCard(accountId: string): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_CREDIT_CARD}/${accountId}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async getPaymentPartner(accountId: string): Promise<RequestConfigTP> {
        return await NeritPaymentsRequests._getNeritPaymentsRequestConfig({
            url: `${NeritPaymentsRequests._MODULE_BASE_PAYMENT_PARTNERS}/${accountId}`,
            method: HttpMethodEnum.GET,
        })
    }

    /**
     * Monta as configuracoes, HEADER, para enviar na requisicao.
     */
    private static async _getNeritPaymentsRequestConfig(customConfig: RequestConfigTP): Promise<RequestConfigTP> {

        let error: unknown
        try {
            const token = await NeritPaymentsRequests._getNeritPaymentsAuthToken()
            if (!token)
                throw new Error(NeritPaymentsRequests._ERROR_NO_MODOULE_ACCESS)

            return {
                ...customConfig,
                headers: [AuthUtils.getBearerAuthHeaderConfig(token)]
            }

        } catch (exception) {
            const aux = NeritPaymentsRequests._handleFailure(exception)
            if (aux)
                error = exception
        }

        throw error
    }

    /**
     * Obtem o token de autenticacao para enviar na requisicao principal.
     */
    private static async _getNeritPaymentsAuthToken(): Promise<string | undefined> {

        if (!AppStateUtils.getCurrentFranchise()?.integrationKeys?.neritPayments)
            throw new Error(NeritPaymentsRequests._ERROR_NO_MODOULE_ACCESS)

        const requestConfig: RequestConfigTP = {
            url: `${SystemConfig.getInstance().neritPaymentsBaseUrl}/auth/login`,
            method: HttpMethodEnum.POST,
            params: {
                login: AppStateUtils.getCurrentFranchise()!.integrationKeys.neritPayments!.login,
                secret: AppStateUtils.getCurrentFranchise()!.integrationKeys.neritPayments!.secret
            },
            noAuth: true,
        }

        type RequestReturnTP = IApiReturn<{ token: string }>
        const result = await RequestHelper.runRequest<RequestReturnTP>(requestConfig)

        return result?.data.data?.token
    }

    /**
     * Trata algum erro na preparacao para requisicao da API.
     */
    private static _handleFailure(exception: any): any {
        console.error('FALHA: ', exception)
        if (exception.message !== NeritPaymentsRequests._ERROR_NO_MODOULE_ACCESS)
            return exception
        RoutingHelper.historyReplace('default')
    }

}
