/**
 * ENUM
 * Define metodos HTTP utilizados no app.
 *
 * @author hjcostabr
 */
export enum HttpMethodEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}
