import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { BankAccountFormModel } from 'main/modules/financial/components/drawer-bank-account/inner/BankAccountFormModel'
import { BankAccountUtils } from 'main/modules/financial/utils/BankAccountUtils'
import { BankAccountModalityEnum } from 'main/modules/financial/enums/BankAccountModalityEnum'

import React from 'react'

interface IBankAccountModalitySelectorCPProps {
    formStateManager: IFormStateManager<BankAccountFormModel>
    onSelect?: (modality: BankAccountModalityEnum) => void
}

/**
 * COMPONENTE
 * Seletor de modalidades de conta bancária
 */
export function BankAccountModalitySelectorCP(props: IBankAccountModalitySelectorCPProps): JSX.Element {

    const OPTIONS: SelectOptionTP[] = Object.values(BankAccountModalityEnum).map((modality, i) => ({
        key: `key-modality-${i}`,
        label: BankAccountUtils.getBankAccountModalityLabel(modality),
        value: modality
    }))

    return (
        <SelectCP
            label={'Tipo'}
            onChange={props.onSelect}
            formStateManager={props.formStateManager}
            fieldName={'modality'}
            options={OPTIONS}
            required={true}
        />
    )
}