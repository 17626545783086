import { IThemeProject } from 'submodules/neritclin-components-ui/theme/project/IThemeProject'

export const ThemeProjectNeritClin: IThemeProject = {

    collapsedMenuButtonColor: '#2b698cff',

    primaryButtonBackgroundColor: '#2b698cff',
    primaryButtonFontColor: '#FFF',

    primaryMenuColor: '#fff',
    primaryMenuItemColor: '#2b698cff',

    secondaryMenuColor: '#bbced6',//e2d6e8
    secondaryBorderColor: '#4facc2',//#4facc2
    secondaryMenuItemColor: '#71858e',
    secondaryMenuTitleColor: '#2b698cff',

    headerBackgroundColor: '#2b698cff',
    headerFontColor: '#fff',
    titleBarColor: '#f59873',

    customerPortalHeaderBackgroundColor: '#2b698cff',
    customerPortalHeaderFontColor: '#fff',
    customerPortalTitleBarColor: '#f59873',

    checkedBackgroundColor: '#f59873',
    checkedColor: '#000',

    loginCoverColor: '#f3b900',

    widgetFooterColor: '#fff',

    serviceWorkerBarColor: '#f59873',
    serviceWorkerFontColor: '#000',

    logoMenuUrl: `${process.env.PUBLIC_URL}/logos/white-labels/neritclin/menu-icon.png`,
}
