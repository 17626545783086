import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { DragAndDropListCP } from 'main/common/components/drag-and-drop-list/DragAndDropListCP'
import { DragAndDropListItemICP } from 'main/common/components/drag-and-drop-list/inner/DragAndDropListItemICP'
import { DragAndDropColumnTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropColumnTP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { IFunnelStepSummaryResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepSummaryResponseDTO'
import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import React, { useEffect, useState } from 'react'
import { IFunnelStepResponseDTO } from '../../services/funnel/dtos/response/IFunnelStepResponseDTO'
import { IOpportunityChangeStepRequestDTO } from '../../services/opportunity/dtos/request/IOpportunityChangeStepRequestDTO'
import { ActivityColumnItemICP } from 'main/modules/sales-funnel/components/table-opportunity/inner/ActivityColumnItemICP'
import { OpportunityKanbanUtils } from './inner/OpportunityKanbanUtils'
import { StringUtils } from 'main/common/utils/StringUtils'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunitiesTableUtils } from 'main/modules/sales-funnel/components/table-opportunity/inner/OpportunitiesTableUtils'
import { IFunnelStepSummaryRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelStepSummaryRequestDTO'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'

interface IKanbanOpportunityCPProps {
    onSelectOpportunity: (code: number) => void
    filtersFormStateManager: IFormStateManager<OpportunityFiltersFormModel>
    loading?: boolean
    shouldLoadData: boolean
    onLoadDataChanged: (type: 'dataLoaded' | 'reloadData') => void
}

/**
 * COMPONENTE
 * Tabela de listagem de oportunidades. Deve funcionar para todas as etapas
 */
export function KanbanOpportunityCP(props: IKanbanOpportunityCPProps): JSX.Element {

    const [kanban, setKanban] = useState<Array<DragAndDropColumnTP<IOpportunityListItemResponseDTO>>>([])

    const getOpportunitiesRequest = useRequest<IGenericListResponseDTO<IOpportunityListItemResponseDTO>>()
    useEffect(() => { onGetOpportunitiesChange() }, [getOpportunitiesRequest.isAwaiting])

    const moveStepOpportunityRequest = useRequest<IFunnelStepResponseDTO>()
    useEffect(onMoveStepOpportunityRequestChange, [moveStepOpportunityRequest.isAwaiting])

    useEffect(init, [props.shouldLoadData])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.shouldLoadData)
            return

        loadOpportunities()
    }

    /**
     * Busca oportunidades na api.
     */
    function loadOpportunities(): void {

        const filtersDto = OpportunitiesTableUtils.getFilterDtoFromModel(
            props.filtersFormStateManager.getFormValues()!
        )
        getOpportunitiesRequest.runRequest(OpportunityRequests.searchConfig(filtersDto))
    }

    /**
     * Retorno ao buscar oportundiades.
     */
    async function onGetOpportunitiesChange(): Promise<void> {

        if (!RequestUtils.isValidRequestReturn(getOpportunitiesRequest, 'Não foi possível obter a lista de dominios'))
            return

        // Requisicao para buscar os totalizadores do funil
        const filterDto: IFunnelStepSummaryRequestDTO = {
            userResponsibleCode: props.filtersFormStateManager.getFieldValue('responsibleCode')
        }
        const summaryResult = await RequestHelper.runRequest<IApiReturn<IFunnelStepSummaryResponseDTO[]>>(FunnelRequests.funnelStepsSummaryConfig(filterDto))
        const summaryData = summaryResult.data.data ?? []

        // Agora pode montar o kanban
        setKanban(OpportunityKanbanUtils.dtoToKanbanDataFormat(summaryData, getOpportunitiesRequest.responseData!.list))
        props.onLoadDataChanged('dataLoaded')
    }

    /**
     * Muda uma oportundiade de etapa.
     */
    function moveStepOpportunity(opportunityCode: number, newFunnelStepCode: number): void {

        const dto: IOpportunityChangeStepRequestDTO = { newFunnelStepCode }
        moveStepOpportunityRequest.runRequest(OpportunityRequests.moveStepOpportunity(opportunityCode, dto))
    }

    /**
     * Retorno ao mudar uma oportunidade de etapa.
     */
    function onMoveStepOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(moveStepOpportunityRequest, 'Erro ao mover oportunidade no funil de follow-up.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade movida com sucesso!')
        props.onLoadDataChanged('reloadData')
    }

    return (
        <DragAndDropListCP
            loading={getOpportunitiesRequest.isAwaiting || moveStepOpportunityRequest.isAwaiting || props.loading}
            dataSource={kanban}
            showHeader={true}
            columnWidth={'270px'}
            renderContent={(item, index) => (
                <DragAndDropListItemICP id={item.code} index={index}>
                    <CardCP
                        title={
                            <FlexCP justifyContent={'space-between'}>
                                <>{ StringUtils.getFirstName(item.name) }</>
                                <ButtonCP
                                    type={'link'}
                                    onClick={() => props.onSelectOpportunity(item.code)}
                                    icon={'eye'}
                                    size={'small'}
                                />
                            </FlexCP>
                        }
                        margin={'none'}
                        innerSpacing={'small'}
                        paddingTitle={'5px 10px'}
                    >
                        <TextPersonPhoneCP phone={item.phone}/>
                        <ActivityColumnItemICP
                            onActivitiesActionsDone={() => props.onLoadDataChanged('reloadData')}
                            opportunity={item}
                        />
                    </CardCP>
                </DragAndDropListItemICP>
            )}
            onMove={(item, destCode) => moveStepOpportunity(item.code, destCode)}
        />
    )
}
