import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from 'main/common/validation/ValidationTypes'

/**
 * VALIDATOR
 * Valida grupo de campos onde ao menos um dele deve ser obrigatorio
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_REQUIRED_ONE_OF })
class IsRequiredOneOfConstraint implements ValidatorConstraintInterface {

    validate(value: string, args: ValidationArguments): boolean {
        const validator = new Validator()
        return args.constraints.map(field => args.object[field]).some(val => validator.isNotEmpty(val))
    }

    defaultMessage(args: ValidationArguments): string {
        return `Deve ser preenchido ao menos um dos seguintes campos: ${args.constraints.join(',')}!`
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsRequiredOneOf<TP>(fields: Array<keyof TP>, validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: fields,
            validator: IsRequiredOneOfConstraint
        })
    }
}
