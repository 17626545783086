import React from 'react'
import { AdminViewTP } from 'main/modules/admin/types/AdminViewTP'
import { AdminRoutes } from 'main/modules/admin/AdminRoutes'
import { FranchiseDataScreenContentCP } from 'main/modules/admin/components/franchise-data-screen-content/FranchiseDataScreenContentCP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ScreenContentFranchiseReleaseCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-franchise-release/ScreenContentFranchiseReleaseCP'
import { ScreenContentTreatmentCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-treatment/ScreenContentTreatmentCP'
import { SystemAppereanceScreenContent } from 'main/modules/admin/components/system-appereance-screen-content/SystemAppereanceScreenContent'
import { SaleContractTemplateScreenContentCP } from 'main/modules/admin/components/sale-contract-template-screen-content/SaleContractTemplateScreenContentCP'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { IFranchiseSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'
import { ScreenContentFranchiseWorkScheduleCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-franchise-work-schedule/ScreenContentFranchiseWorkScheduleCP'
import { ScreenContentTreatmentGroupCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-treatment-group/ScreenContentTreatmentGroupCP'
import { ScreenContentProductReleaseCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-product-release/ScreenContentProductReleaseCP'
import { ScreenContentOpportunitySourceCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-opportunity-source/ScreenContentOpportunitySourceCP'
import { ScreenContentTreatmentFormsCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-treatment-forms/ScreenContentTreatmentFormsCP'
import { ScreenContentFranchiseTargetConfigCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-franchise-target-config/ScreenContentFranchiseTargetConfigCP'
import { ScreenContentCategoryCP } from 'main/modules/admin/screens/screen-admin/content/screen-content-financial-category/ScreenContentCategoryCP'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { GerenciaPerguntas } from 'main/modules/admin/components/gerencia-perguntas/GerenciaPerguntas'
import { TelaTag } from '../content/screen-content-franchisor-tag-config/TelaTag'

export const ADMIN_SUB_MENU_FINANCIAL_KEY = 'financial'
export const ADMIN_SUB_MENU_SALE_KEY = 'sale'
export const ADMIN_SUBMENU_SYSTEM_KEY = 'system'
export const ADMIN_SUB_MENU_ATTENDANCE_KEY = 'atendimento'

/**
 * UTILS
 * Reune metodos auxiliares para gestao da tela CONFIGURACOES.
 */
export const AdminScreenUtils = {

    getSubMenuConfigs(
        franchise: IFranchiseResponseDTO,
        onUpdateFranchise: (franchiseToSave: IFranchiseSaveRequestDTO) => void,
        isLoading: boolean,
    ): Array<ScreenSubMenuTP<AdminViewTP>> {
        return [
            {
                key: ADMIN_SUBMENU_SYSTEM_KEY,
                title: 'Sistema',
                items: [
                    {
                        name: 'Dados Cadastrais',
                        view: 'systemAccountView',
                        route: AdminRoutes.SYSTEM_ACCOUNT,
                        contentTitle: 'Dados Cadastrais',
                        content: <FranchiseDataScreenContentCP franchise={franchise} onUpdateFranchise={onUpdateFranchise} loading={isLoading}/>
                    },
                    {
                        name: 'Aparência',
                        view: 'appearenceView',
                        route: AdminRoutes.SYSTEM_APPEARENCE,
                        contentTitle: 'Customização da Tela de Login',
                        content: <SystemAppereanceScreenContent/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Horário de Funcionamento',
                        view: 'workScheduleView',
                        route: AdminRoutes.SYSTEM_WORK_SCHEDULE,
                        contentTitle: 'Horário de Funcionamento da Unidade',
                        content: <ScreenContentFranchiseWorkScheduleCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchise')
                    },
                    {
                        name: 'Metas',
                        view: 'targetsConfig',
                        route: AdminRoutes.SYSTEM_TARGETS,
                        contentTitle: 'Configurações de Metas',
                        content: <ScreenContentFranchiseTargetConfigCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Tags',
                        view: 'tagsConfig',
                        route: AdminRoutes.TAGS,
                        contentTitle: 'Configuração de Tags',
                        content: <TelaTag/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                ],
                enabled: PermissionUtils.hasAccess(null, [PermissionEnum.ROLE_ADMIN])
            },
            {
                key: ADMIN_SUB_MENU_ATTENDANCE_KEY,
                title: 'Serviços',
                items: [
                    {
                        name: 'Grupos de Atendimento',
                        view: 'attendanceGroupView',
                        route: AdminRoutes.ATTENADANCE_GROUP,
                        content: <ScreenContentTreatmentGroupCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Gerência de Perguntas',
                        view: 'perguntasView',
                        route: AdminRoutes.ATTENADANCE_PERGUNTAS,
                        content: <GerenciaPerguntas/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Formulários de Atendimento',
                        view: 'attendanceFormView',
                        route: AdminRoutes.ATTENADANCE_FORMS,
                        content: <ScreenContentTreatmentFormsCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Atendimento',
                        view: 'attendanceJobFunctionView',
                        route: AdminRoutes.ATTENADANCE_JOBS,
                        content: <ScreenContentTreatmentCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Pacotes de Atendimento',
                        view: 'attendanceProductsView',
                        route: AdminRoutes.ATTENADANCE_PRODUCT,
                        content: <ScreenContentProductReleaseCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Pacotes de Atendimento',
                        view: 'attendanceFranchiseProductsView',
                        contentTitle: 'Pacotes de Atendimento da Unidade',
                        route: AdminRoutes.ATTENADANCE_FRANCHISE_PRODUCT,
                        content: <ScreenContentFranchiseReleaseCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchise')
                    },
                ],
                enabled: PermissionUtils.hasAccess(null, [PermissionEnum.ROLE_ADMIN])
            },
            {
                key: ADMIN_SUB_MENU_SALE_KEY,
                title: 'Venda e Pagamento',
                items: [
                    {
                        name: 'Modelo de Contrato',
                        view: 'contractTemplateView',
                        contentTitle: 'Modelo de Contrato',
                        route: AdminRoutes.CONTRACT_TEMPLATE,
                        content: <SaleContractTemplateScreenContentCP franchise={franchise}/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                    {
                        name: 'Origem do Lead / Venda',
                        view: 'opportunitySouce',
                        route: AdminRoutes.OPPORTUNITY_SOURCE,
                        content: <ScreenContentOpportunitySourceCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchise')
                    },
                ],
                enabled: PermissionUtils.hasAccess(null, [PermissionEnum.ROLE_ADMIN])
            },
            {
                key: ADMIN_SUB_MENU_FINANCIAL_KEY,
                title: 'Financeiro',
                items: [
                    {
                        name: 'Plano de Contas',
                        view: 'financialCategoryView',
                        route: AdminRoutes.FINANCIAL_CATEGORY,
                        content: <ScreenContentCategoryCP/>,
                        enabled: PermissionUtils.hasAccess(null, null, 'franchisor')
                    },
                ],
                enabled: PermissionUtils.hasAccess(null, [PermissionEnum.ROLE_ADMIN], 'franchisor')
            },
        ]
    },

}
