/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { DragAndDropColumnTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropColumnTP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { DragAndDropItemTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropItemTP'
import { DragAndDropListUtils } from 'main/common/components/drag-and-drop-list/inner/DragAndDropListUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'

interface IScpProps {
    columnWidth?: string
}

interface IDragAndDropListCPProps<DnDItemTP> extends IScpProps {
    dataSource: Array<DragAndDropColumnTP<DnDItemTP>>
    renderContent: (item: DnDItemTP, index: number) => JSX.Element
    onMove?: (item: DnDItemTP, destinationColumnCode: number) => void
    onListOrderChange?: (reorderedList: Array<DragAndDropItemTP<DnDItemTP>>) => void
    showHeader: boolean
    loading?: boolean
}

/**
 * COMPONENTE de drag and drop
 */
export function DragAndDropListCP<DnDItemTP>(props: IDragAndDropListCPProps<DnDItemTP>): JSX.Element {

    const [listItems, setListItems] = useState<Array<DragAndDropColumnTP<DnDItemTP>>>(props.dataSource)

    useEffect(() => setListItems(props.dataSource), [props.dataSource])

    /**
     * Evento chamando ao soltar uma caixinha.
     */
    function onDragEnd(result: DropResult): void {

        if (!result.destination)
            return

        // Em casos de nao ser kanban e estiver operando apenas uma lista
        if (props.dataSource.length === 1) {

            const items = DragAndDropListUtils.getReorderedList(
                listItems[0].itens,
                result.source.index,
                result.destination.index
            )

            props.dataSource[0].itens = [...items]
            props.onListOrderChange?.(items)

            return
        }

        // Para obter o elemento, pegamos a lista de onde ele saiu, e o achamos pelo indice
        const source = listItems.find((i) => i.columnId === result.source.droppableId)
        const [itemMoved] = source!.itens.splice(result.source.index, 1)

        // Pegamos agora a lista de destino do item para adicionar o elmento
        const dest = listItems.find((i) => i.columnId === result.destination?.droppableId)!
        dest.itens.splice(0, 0, itemMoved)

        props.onMove?.(itemMoved as any, dest.code)
    }

    if (props.loading)
        return <LoadingCP show={true}/>

    if (SystemUtils.isEmpty(props.dataSource))
        return <EmptyCP description={'Nenhum registro encontrado'}/>

    return (
        <WrapperSCP>
            <DragDropContext onDragEnd={onDragEnd}>
                <DragDropColumnsSCP>
                    {
                        listItems.map((ds) => {
                            return (
                                <DragDropContextSCP key={ds.columnId} columnWidth={props.columnWidth}>
                                    <Droppable droppableId={ds.columnId}>
                                        {(provided: DroppableProvided): JSX.Element => (
                                            <>
                                                {
                                                    props.showHeader &&
                                                    <HeaderWrapperSCP>
                                                        <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                                                            <TextCP text={ds.name} size={'large'}/>
                                                            <TextCP text={ds.totalItens} size={'extraLarge'} color={ThemeAnt.primaryColor} strong={true}/>
                                                        </FlexCP>
                                                    </HeaderWrapperSCP>
                                                }
                                                <ListWrapperSCP>
                                                    <div
                                                        style={{ minHeight: props.dataSource.length > 1 ? '400px' : undefined }}
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {
                                                            ds.itens.map((item, index) => props.renderContent(item.item, index))
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                </ListWrapperSCP>
                                            </>
                                        )}
                                    </Droppable>
                                </DragDropContextSCP>
                            )
                        })
                    }
                </DragDropColumnsSCP>
            </DragDropContext>
        </WrapperSCP>
    )
}

const DragDropColumnsSCP = styled.div`
    display: flex;
`

const DragDropContextSCP = styled.div<IScpProps>`
    justify-content: space-between;
    flex-direction: column;
    width: ${props => props.columnWidth ?? '200px'};
`

const ListWrapperSCP = styled.div`
    width: 100%;
`

const HeaderWrapperSCP = styled.div`
    padding: 10px;
    width: 100%;
`

const WrapperSCP = styled.div`
  overflow-x: auto;
`
