import React from 'react'
import { HelpCP } from 'main/common/components/help/HelpCP'
import { IFinancialTransactionChargeData } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionChargeData'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ChargeStatusLabelEnum } from 'submodules/neritclin-sdk/services/sale/charge/enums/ChargeStatusEnum'

interface ICPProps {
    chargeData: IFinancialTransactionChargeData
}

/**
 * Detalhes do status de uma charge.
 */
export function HelpChargeStatusCP(props: ICPProps): JSX.Element {

    return (
        <HelpCP
            align={'flex-start'}
            type={'tooltip'}
            label={ChargeStatusLabelEnum[props.chargeData.chargeStatus]}
            text={
                <>
                    <p>{`Atualizado em: ${DateUtils.getFormatted(props.chargeData.lastUpdateDate, DateFormatEnum.BR_WITH_TIME_H_M)}`}</p>
                    <p>{`Status da Cobrança: ${ChargeStatusLabelEnum[props.chargeData.chargeStatus]}`}</p>
                    <p>{`Observações: ${props.chargeData.statusReason}`}</p>
                </>
            }
        />
    )

}
