import React from 'react'
import styled from 'main/config/theme/styledWithTheme'
import { ResponsiveFunnel } from '@nivo/funnel'
import * as _ from 'lodash'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'

export type FunnelChartDataTP = {
    id: string,
    label: string,
    value: number,
}

interface IScpProps {
    height: number
    width?: number
}

interface IChartFunnelCPProps extends IScpProps{
    data: FunnelChartDataTP[]
    loading?: boolean
}

/**
 * COMPONENTE Grafico de Pizza.
 */
export function ChartFunnelCP(props: IChartFunnelCPProps): JSX.Element {

    if (_.isEmpty(props.data) && !props.loading)
        return <></>

    return (
        <ChartWrapperSCP
            height={props.height}
            width={props.width}
        >
            <LoadingCP show={props.loading ?? false}/>

            {
                !props.loading &&
                <ResponsiveFunnel
                    data={props.data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    shapeBlending={0.65}
                    colors={{ scheme: 'spectral' }}
                    borderColor={{ from: 'color', modifiers: [] }}
                    labelColor={{ from: 'color', modifiers: [['darker', 3]] }}
                    beforeSeparatorLength={100}
                    beforeSeparatorOffset={20}
                    afterSeparatorLength={100}
                    afterSeparatorOffset={20}
                    currentPartSizeExtension={10}
                    currentBorderWidth={6}
                />
            }
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div<IScpProps>`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  overflow-y: hidden;
`
