import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SaleProductDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleProductDataFormModel'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { SaleBasicDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleBasicDataFormModel'
import { ISaleQuoteSaveRequestDTO } from 'main/modules/sale/services/sale-quote/dtos/request/ISaleQuoteSaveRequestDTO'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ISaleQuoteSaleBasicData } from 'main/modules/sale/services/sale-quote/dtos/request/inner/ISaleQuoteSaleBasicData'
import { ISaleQuoteSaleProductData } from 'main/modules/sale/services/sale-quote/dtos/request/inner/ISaleQuoteSaleProductData'
import { ISaleQuoteSalePaymentData } from 'main/modules/sale/services/sale-quote/dtos/request/inner/ISaleQuoteSalePaymentData'

/**
 * Utilitario do processo de criacao e leitura de orcamentos.
 */
export const CreateSaleQuoteUtils = {

    /**
     * Monta DTO do orcamento
     */
    mountSaleQuoteDto(
        basicDataFormStateManager: IFormStateManager<SaleBasicDataFormModel>,
        productDataFormStateManager: IFormStateManager<SaleProductDataFormModel>,
        paymentDataFormStateManager: IFormStateManager<SalePaymentDataFormModel>,
        opportunityCode?: number
    ): ISaleQuoteSaveRequestDTO | undefined {

        if (!opportunityCode) {
            NotificationHelper.error('Ops!', 'É obrigatório informar oportunidade para criar um orçamento')
            return
        }

        const basicDataFormValues = basicDataFormStateManager.getFormValues()
        const productDataFormValues = productDataFormStateManager.getFormValues()
        const paymentDataFormValues = paymentDataFormStateManager.getFormValues()
        if (!basicDataFormValues || !paymentDataFormValues || !productDataFormValues)
            return

        const saleBasicData: ISaleQuoteSaleBasicData = {
            personCustomer: basicDataFormValues.personCustomer,
            source: basicDataFormValues.source,
            personSeller: basicDataFormValues.personSeller,
            personPayer: basicDataFormValues.personPayer,
            saleOrigin: basicDataFormValues.saleOrigin,
            sourcePersonLink: basicDataFormValues.sourcePersonLink,
        }

        const saleProductData: ISaleQuoteSaleProductData = productDataFormValues
        const salePaymentData: ISaleQuoteSalePaymentData = paymentDataFormValues

        return {
            opportunityCode,
            saleData: {
                saleBasicData,
                saleProductData,
                salePaymentData,
            }
        }
    },

    mountBasicFormModel(saleBasicData: ISaleQuoteSaleBasicData): SaleBasicDataFormModel {

        return new SaleBasicDataFormModel({
            personCustomerCode: saleBasicData.personCustomer.code,
            personCustomer: saleBasicData.personCustomer,
            sellerCode: saleBasicData.personSeller.code,
            personSeller: saleBasicData.personSeller,
            sourceCode: saleBasicData.source.code,
            source: saleBasicData.source,
            sourcePersonLinkCode: saleBasicData.sourcePersonLink?.code,
            sourcePersonLink: saleBasicData.sourcePersonLink,
            personPayerCode: saleBasicData.personCustomer.code,
            saleDate: new Date(),
            saleOrigin: saleBasicData.saleOrigin,
        })
    },

    mountProductFormModel(saleProductData: ISaleQuoteSaleProductData): SaleProductDataFormModel {

        return new SaleProductDataFormModel({
            totalValue: saleProductData.totalValue,
            products: saleProductData.products,
        })
    },

    mountPaymentFormModel(salePaymentData: ISaleQuoteSalePaymentData): SalePaymentDataFormModel {

        return new SalePaymentDataFormModel({
            originalTotalValue: salePaymentData.originalTotalValue,
            discountValue: salePaymentData.discountValue,
            totalValue: salePaymentData.totalValue,
            saleCharges: salePaymentData.saleCharges,
            voucherCode: salePaymentData.voucher?.code,
            voucher: salePaymentData.voucher,
        })
    },

}
