import React, { useEffect, useState } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { RankingAttendantWidgetUtils } from 'main/modules/dashboard/components/widgets-row-ranking-attendant/inner/RankingAttendantWidgetUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { ITableIndicatorsRequestWithOrderingDTO } from 'main/modules/dashboard/services/dtos/request/ITableIndicatorsRequestWithOrderingDTO'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { TechnicianRankingIndicatorsOrderingEnum } from 'main/modules/dashboard/services/enums/TechnicianRankingIndicatorsOrderingEnum'
import { ITechnicianRankingIndicatorsListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ITechnicianRankingIndicatorsListItemResponseDTO'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { OrderingEnum } from 'main/common/enums/OrderingEnum'

interface ICPProps {
    franchiseCodes?: number[]
    dateFilters?: IDateRangeFilter
}

/**
 * Componente de tabela que ranking de tecnicas
 */
export function WidgetsRowRankingAttendantCP(props: ICPProps): JSX.Element {

    const [sortCriteria, setSortCriteria] = useState<TechnicianRankingIndicatorsOrderingEnum>(TechnicianRankingIndicatorsOrderingEnum.TOTAL_TREATMENTS_CONCLUDED)
    const [sortOrder, setSortOrder] = useState<OrderingEnum>(OrderingEnum.DESC)

    const getRankingByFranchiseRequest = useRequest<IGenericListResponseDTO<ITechnicianRankingIndicatorsListItemResponseDTO>>()
    useEffect(onGetRankingByFranchiseRequestChange, [getRankingByFranchiseRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes, props.dateFilters, sortOrder, sortCriteria])

    /**
     * Busca dados na api.
     */
    function init(): void {

        const filters: ITableIndicatorsRequestWithOrderingDTO<TechnicianRankingIndicatorsOrderingEnum> = {
            franchiseCodes: props.franchiseCodes,
            dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters),
            orderingCriteria: sortCriteria,
            orderingAscending: sortOrder === OrderingEnum.ASC,
        }

        getRankingByFranchiseRequest.runRequest(DashboardRequests.getAttendantRanking(filters))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetRankingByFranchiseRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getRankingByFranchiseRequest, 'Não foi possível obter ranking de avaliadoras'))
            return
    }

    return (
        <WidgetsRowCP marginTop={20}>
            <CardDashboardWidgetWrapperCP
                title={'Ranking das Técnicas de Procedimento'}
                flex={'1'}
            >
                <TableCPOLD2
                    columns={RankingAttendantWidgetUtils.getTableColumns()}
                    data={getRankingByFranchiseRequest.responseData?.list}
                    loading={getRankingByFranchiseRequest.isAwaiting}
                    sortDirections={[sortOrder]}
                    onSortingChange={(sortingParams) => {
                        setSortOrder(sortingParams.columnKey === sortCriteria ? (sortOrder === OrderingEnum.ASC ? OrderingEnum.DESC : OrderingEnum.ASC) : OrderingEnum.DESC)
                        setSortCriteria(sortingParams.columnKey as TechnicianRankingIndicatorsOrderingEnum)
                    }}
                />
            </CardDashboardWidgetWrapperCP>
        </WidgetsRowCP>
    )
}
