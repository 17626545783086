import React, { useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'

interface ICPProps {
    franchiseCodes: number[]
    onSelectFranchiseCodes: (franchises: number[]) => void
}

/**
 * Titulo para analise de unidades.
 */
export function DashboardMultipleFranchiseTitleCP(props: ICPProps): JSX.Element {

    const [selectedFranchiseCodes, setSelectedFranchiseCodes] = useState<number[]>(props.franchiseCodes)

    return (
        <PersonTitleSCP>
            <FlexCP justifyContent={'center'}>
                <TextCP text={'Indicadores de Performance das unidades:'}/>
            </FlexCP>
            <FlexCP justifyContent={'center'} alignItems={'center'}>
                <FranchiseWrapperSCP>
                    <SelectFranchiseCP
                        placeholder={'Filtre por uma ou mais unidades (deixe vazio para todas unidades)'}
                        onChange={(franchises) => setSelectedFranchiseCodes(Array.isArray(franchises) ? franchises : [franchises])}
                        initialFranchises={props.franchiseCodes}
                        isMultiple={true}
                    />
                </FranchiseWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={() => props.onSelectFranchiseCodes(selectedFranchiseCodes)}
                    marginLeft={10}
                >
                    Filtrar
                </ButtonCP>
            </FlexCP>
        </PersonTitleSCP>
    )
}

const PersonTitleSCP = styled.div`
  margin: 0 0 30px 0;
  
  b {
    color: #f59873;
  }
`

const FranchiseWrapperSCP = styled.div`
    width: 600px;
`
