export enum FinancialCategoryDebitEnum {
    TAX = 'TAX',
    VARIABLE_EXPENSE = 'VARIABLE_EXPENSE',
    FIXED_EXPENSE = 'FIXED_EXPENSE',
    STAFF_EXPENSE = 'STAFF_EXPENSE',
    AMORTIZATION = 'AMORTIZATION',
}

export enum FinancialCategoryDebitLabelEnum {
    TAX = 'Impostos',
    VARIABLE_EXPENSE = 'Despesas Variáveis',
    FIXED_EXPENSE = 'Despesas Fixas',
    STAFF_EXPENSE = 'Gastos com Pessoal',
    AMORTIZATION = 'Amortização',
}
