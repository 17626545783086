import { ValidationTypes as ParentValidationTypes } from 'class-validator'

/**
 * VALIDATION
 * Lista os tipos de Validacao possiveis. Herda os tipos do Class-Validator
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class ValidationTypes extends ParentValidationTypes {

    /** Custom . */
    static readonly IS_REQUIRED = 'isRequired'
    static readonly IS_PHONE_BR = 'isPhoneBR'
    static readonly IS_CELL_PHONE_BR = 'isCellPhoneBR'
    static readonly IS_VALID_CPF = 'isValidCpf'
    static readonly IS_VALID_CNPJ = 'isValidCnpj'
    static readonly IS_VALID_VOTER_REGISTRATION = 'isVoterRegistration'
    static readonly IS_VALID_CNPJ_OR_CPF = 'isValidCnpjOrCpf'

    // eslint-disable-next-line @typescript-eslint/naming-convention
    static IS_REQUIRED_ONE_OF = 'isRequiredOneOf'

    static readonly NESTED_VALIDATION_MESSAGE = 'Valor inválido para o campo'
    static readonly UNACCEPTABLE_FIELD_MESSAGE = 'Campo informado não existe'

    /**
     * ERROS
     * Abaixo, estão os erros principais de validação.
     * Existe um ordem neles, dado que so será retornado o mais prioritário
     *
     * Ordem:
     * 1 - Campos não aceitos
     * 2 - Campos Obrigatórios
     * 3 - Tipo errado do campo
     * 4 - Outros erros não listados
     */

    /** Lista de error de campo não aceito. */
    static readonly unacceptableFieldErrors = [ValidationTypes.WHITELIST]

    /** Lista de erros de campo obrigatorio. */
    static readonly requiredFieldErrors = [ValidationTypes.IS_REQUIRED]

    /** Lista de erros de tipo errado. */
    static readonly wrongTypeFieldErrors = [
        ValidationTypes.IS_ARRAY,
        ValidationTypes.IS_PHONE_BR,
        ValidationTypes.IS_CELL_PHONE_BR,
        ValidationTypes.IS_BOOLEAN,
        ValidationTypes.IS_DATE,
        ValidationTypes.IS_EMAIL,
        ValidationTypes.IS_URL,
        ValidationTypes.IS_NUMBER,
        ValidationTypes.IS_STRING,
        ValidationTypes.IS_VALID_CNPJ,
        ValidationTypes.IS_VALID_CPF,
        ValidationTypes.IS_INT,
        ValidationTypes.MIN,
    ]
}
