import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StatisticCardCP } from 'main/common/components/statistic-card/StatisticCardCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faCashRegister, faChartBar, faMoneyCheckAlt, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import moment from 'moment'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { IBillingPerformanceResponseDTO } from 'main/modules/dashboard/services/dtos/response/IBillingPerformanceResponseDTO'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'

interface IFranchisorTopFranchisesStatisticsCPProps {
    date: Date
    dataFiltrada?:any
    setGrupoA?:any
    setGrupoB?:any
}

/**
 * Componentes estatisticas gerais de numeros de melhores franquias
 */
export function FranchisorTopBillingStatisticsCP(props: IFranchisorTopFranchisesStatisticsCPProps): JSX.Element {

    useEffect(init, [props.dataFiltrada])

    const [billingPerformanceData, setBillingPerformanceData] = useState<IBillingPerformanceResponseDTO>()
    const billingPerformanceRequest = useRequest<IBillingPerformanceResponseDTO>()
    useEffect(onBillingPerformanceRequestChange, [billingPerformanceRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        const requestDto: IDateRangeSearchRequestDTO = {
            beginDate: moment(props.dataFiltrada.beginDate).startOf('day').format('YYYY-MM-DD'),
            endDate: moment(props.dataFiltrada.endDate).endOf('day').format('YYYY-MM-DD'),
        }

        billingPerformanceRequest.runRequest(DashboardRequests.billingPerformanceConfig(requestDto))
    }

    /**
     * Retorno dos dados da api.
     */
    function onBillingPerformanceRequestChange(): void {
 
        if (!RequestUtils.isValidRequestReturn(billingPerformanceRequest, 'Erro obter estatísticas'))
            return
        // console.log("Billing ",billingPerformanceRequest.responseData)
        //@ts-ignore
        if(props['setGrupoA'])props.setGrupoA(billingPerformanceRequest.responseData.grupoA)
        //@ts-ignore
        if(props['setGrupoB'])props.setGrupoB(billingPerformanceRequest.responseData.grupoB)
        setBillingPerformanceData(billingPerformanceRequest.responseData)
    }

    return (
        <WrapperSCP>
            <StatisticCardCP
                title={'Faturamento de todas as franquias'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faCashRegister} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(billingPerformanceData?.totalBilling ?? 0)}
                loading={billingPerformanceRequest.isAwaiting}

            />
            <StatisticCardCP
                title={'Tudo que foi recebido sem considerar Receitas e Despesas'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faCashRegister} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(billingPerformanceData?.totalLiquido ?? 0)}
                loading={billingPerformanceRequest.isAwaiting}

            />
            <StatisticCardCP
                title={'Faturamento médio por franquia'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faChartBar} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(billingPerformanceData?.averageBillingByFranchise ?? 0)}
                loading={billingPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={'Franquia com maior faturamento'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faTrophy} size={'2x'}/>}
                value={billingPerformanceData?.bestBillingPerformanceFranchise.name}
                loading={billingPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={`${billingPerformanceData?.bestBillingPerformanceFranchise?.name ?? ''} faturou:`}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faMoneyCheckAlt} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(billingPerformanceData?.bestBillingPerformanceFranchise.totalBilling ?? 0)}
                loading={billingPerformanceRequest.isAwaiting}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
`
