import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DokfySystemApiEnum } from 'submodules/dokfy-sdk/common/DokfySystemApiEnum'
import { PeopleEmailUpdateRequestDTO } from 'submodules/dokfy-sdk/services/people/dto/request/PeopleEmailUpdateRequestDTO'

export class PeopleRequests {

    public static CONTROLLER_ROOT = 'people'
    public static UPDATE_EMAIL_EP = 'update-email/:code'

    static updateEmail = (code: number, dto: PeopleEmailUpdateRequestDTO): RequestConfigTP<void> => ({
        baseUrlType: DokfySystemApiEnum.DOKFY_API,
        url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPDATE_EMAIL_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

}
