import React, { useState } from 'react'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ChargeStatusEnum } from 'submodules/neritclin-sdk/services/sale/charge/enums/ChargeStatusEnum'
import { ModalChargeRetryCP } from 'main/modules/financial/components/modals/modal-charge-retry/ModalChargeRetryCP'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'

interface ICPProps extends BasicStyleWrapperCPProps {
    charge: IChargeListItemResponseDTO
    financialTransaction?: IFinancialTransactionCreditListItemResponseDTO
    onChangeData: () => void
    appearance?: {
        showText?: boolean
    }
}

/**
 */
export function ButtonChargeRetryCP(props: ICPProps): JSX.Element {

    const [isChargeRetryModalVisible, setIsChargeRetryModalVisible] = useState<boolean>(false)

    let chargeStatus
    if (!!props.financialTransaction) {

        if (![PaymentMethodEnum.CREDIT_CARD_RECURRENT].includes(props.financialTransaction.paymentMethod))
            return <></>

        chargeStatus = props.financialTransaction.chargeData?.chargeStatus

    } else {

        if (!props.charge.chargeData?.paymentMethod || ![PaymentMethodEnum.CREDIT_CARD].includes(props.charge.chargeData?.paymentMethod))
            return <></>

        chargeStatus = props.charge.chargeData.chargeStatus
    }

    if (chargeStatus !== ChargeStatusEnum.DECLINED)
        return <></>

    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_RETRY_CHARGE]} redirect={false}>
            <ButtonCP
                type={'primary'}
                onClick={() => setIsChargeRetryModalVisible(true)}
                icon={'issues-close'}
                tooltip={props.appearance?.showText ? undefined : 'Retentar cobrança'}
                size={'small'}
                marginTop={props.margin?.top}
                marginBottom={props.margin?.bottom}
                marginRight={props.margin?.right}
                marginLeft={props.margin?.left}
            >
                {props.appearance?.showText ? 'Retentar cobrança' : undefined}
            </ButtonCP>

            <ModalChargeRetryCP
                visible={isChargeRetryModalVisible}
                charge={props.charge}
                financialTransaction={props.financialTransaction}
                onCancel={() => setIsChargeRetryModalVisible(false)}
                onSave={() => {
                    setIsChargeRetryModalVisible(false)
                    props.onChangeData()
                }}
            />
        </AccessControlCP>
    )
}
