import React from 'react'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { WidgetsRowRankingConsultantCP } from 'main/modules/dashboard/components/widgets-row-ranking-consultant/WidgetsRowRankingConsultantCP'
import { WidgetsRowRankingAttendantCP } from 'main/modules/dashboard/components/widgets-row-ranking-attendant/WidgetsRowRankingAttendantCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    dateFilters: IDateRangeFilter
    onDateRangeChange: (dateFilters: IDateRangeFilter) => void
    franchiseCodes?: number[]
}

/**
 * Aba de rankings.
 */
export function FranchiseManagerRankingsTabICP(props: ICPProps): JSX.Element {

    return (
        <>
            <FiltersFranchiseDashboardCP dateFilters={props.dateFilters} onDateRangeChange={props.onDateRangeChange}/>

            <WidgetsRowRankingConsultantCP franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            <WidgetsRowRankingAttendantCP franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
        </>
    )
}
