import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { ITreatmentGroupSearchRequestDTO } from 'main/modules/admin/services/treatment/dtos/request/ITreatmentGroupSearchRequestDTO'
import { ITreatmentSaveRequestDTO } from 'main/modules/admin/services/treatment/dtos/request/ITreatmentSaveRequestDTO'
import { ITreatmentGroupSaveRequestDTO } from 'main/modules/admin/services/treatment/dtos/request/ITreatmentGroupSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo de tratamentos.
 */
export class TreatmentRequests {

    private static readonly _MODULE_BASE_TREATMENT = `${SystemConfig.getInstance().apiBaseUrl}/treatments`
    private static readonly _MODULE_BASE_TREATMENT_GROUP = `${SystemConfig.getInstance().apiBaseUrl}/treatment-groups`

    static getTreatment = (code: number): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static searchTreatment = (): RequestConfigTP => ({
        url: TreatmentRequests._MODULE_BASE_TREATMENT,
        method: HttpMethodEnum.GET,
    })

    static saveTreatment = (dto: ITreatmentSaveRequestDTO): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateTreatment = (code: number, dto: ITreatmentSaveRequestDTO): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteTreatment = (code: number): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static searchTreatmentGroup = (dto?: ITreatmentGroupSearchRequestDTO): RequestConfigTP => ({
        url: TreatmentRequests._MODULE_BASE_TREATMENT_GROUP,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getTreatmentGroup = (code: number): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT_GROUP}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static saveTreatmentGroup = (dto: ITreatmentGroupSaveRequestDTO): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT_GROUP}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateTreatmentGroup = (code: number, dto: ITreatmentGroupSaveRequestDTO): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT_GROUP}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteTreatmentGroup = (code: number): RequestConfigTP => ({
        url: `${TreatmentRequests._MODULE_BASE_TREATMENT_GROUP}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
