import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsOptional } from 'class-validator/decorator/decorators'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'

/**
 * Classe de Adição de novo agendamento
 */
export class FormFilterSchedulerFormModel extends FormModel {

    @IsRequired()
    dateInterval: IDateRangeFilter

    @IsOptional()
    userProfessionalCode: number

    @IsNotEmpty()
    customerCode: number

    @IsNotEmpty()
    sessions: ISessionResponseDTO[]

    // Atributos abaixo sao auxiliares e necessarios
    userProfessionalName: string
    customerName: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
