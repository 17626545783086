import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Icon } from 'antd'

interface IUploadButtonICPProps {
    loading: boolean
    disabled?: boolean
    style?: CSSProperties
}

export function UploadButtonICP(props: IUploadButtonICPProps): JSX.Element {

    return (
        <UploadButtonSCP disabled={props.disabled!} style={props.style}>
            {
                !!props.disabled ?
                    <Icon type='stop'/> :
                    <>
                        <Icon type={props.loading ? 'loading' : 'plus'}/>
                        <span>Enviar imagem</span>
                    </>
            }
        </UploadButtonSCP>
    )
}
const UploadButtonSCP = styled.div<{ disabled: boolean }>`
   cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
   background: #f4f4f4;
   width: 230px;
   height: 210px;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content:center;
   flex-direction: column;
   border: 1px #cacaca dashed;
   span{
       font-size: 14px;
       color: #1c2247;
       font-weight: 600;
   }
   .anticon{
    font-size: 35px;
    margin: 10px 0;
   }
`
