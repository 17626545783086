import _ from 'lodash'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IPersonFranchiseResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonFranchiseResponseDTO'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import React, { useEffect, useState } from 'react'
import { PersonFormModel } from 'main/modules/people/components/drawer-person-data/inner/PersonFormModel'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { IPersonSearchByUniqueFieldsRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchByUniqueFieldsRequestDTO'
import { PhoneValidationUtils } from 'main/common/validation/decorators/phone/PhoneValidationUtils'
import { IconCP } from 'main/common/components/icons/IconCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { InputPhoneCP } from 'main/common/components/form-fields/input-phone/InputPhoneCP'

type FieldTP = 'cpf' | 'phone' | 'email'

const CPF_LENGHT = 11
const CNPJ_LENGTH = 14
interface IInputUniqueFieldsPersonCPProps {
    onSearchPersonResult: (existsInFranchise: boolean, person?: IPersonResponseDTO) => void
    formStateManager: IFormStateManager<PersonFormModel>
    disabled?: boolean
    type: FieldTP
    fieldName: keyof PersonFormModel
    label: string
    loadedPerson?: IPersonResponseDTO
    initialData?: string
}

/**
 * COMPONENTE Input de busca por pessoa identificada por CPF, CNPJ ou telefone.
 */
export function InputUniqueFieldsPersonCP(props: IInputUniqueFieldsPersonCPProps): JSX.Element {

    const [isDuplicated, setIsDuplicated] = useState<boolean>(false)

    const searchPersonRequest = useRequest<IPersonFranchiseResponseDTO>()

    useEffect(onRequestChange, [searchPersonRequest.isAwaiting])
    useEffect(() => onBlur(props.initialData), [props.initialData])

    function searchPerson(value: string): void {

        const dto: IPersonSearchByUniqueFieldsRequestDTO = {
            phone: props.type === 'phone' ? value : undefined,
            email: props.type === 'email' ? value : undefined,
            cpfCnpj: props.type === 'cpf' ? value : undefined
        }

        searchPersonRequest.runRequest(PersonRequests.searchByUniqueFields(dto))
    }

    function onRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchPersonRequest, 'Erro ao buscar pessoa.'))
            return

        const resultDto = searchPersonRequest.responseData!
        props.onSearchPersonResult(resultDto.alreadyExists, resultDto.person)

        // Se encontrou uma pessoa e eh diferente da pessoa atual, sinaliza
        const _isDuplicated = (!!resultDto.person && !!props.loadedPerson && resultDto.person.code !== props.loadedPerson.code)
        setIsDuplicated(_isDuplicated)
    }

    function onChange(nextValue?: string): void {

        // Email nao considera onchange, pois chamada sera no onblur
        if (!nextValue || props.type === 'email' || !hasChanged(nextValue))
            return

        if (props.type === 'cpf' && nextValue.length !== CNPJ_LENGTH && nextValue.length !== CPF_LENGHT)
            return

        if (props.type === 'phone' && !PhoneValidationUtils.validateBR(nextValue))
            return

        searchPerson(nextValue)
    }

    function onBlur(mextValue?: string): void {
        if (!!mextValue && props.type === 'email' && hasChanged(mextValue))
            searchPerson(mextValue)
    }

    function hasChanged(nextValue: string): boolean {

        const initialValue = (props.type === 'cpf')
            ? props.loadedPerson?.cpfCnpj
            : (props.type === 'phone' ? props.loadedPerson?.phone : props.loadedPerson?.email)

        return (nextValue !== initialValue)
    }

    if (props.type === 'phone') {
        return (
            <InputPhoneCP
                label={props.label}
                fieldName={props.fieldName}
                onChange={(_.debounce(onChange, 500))}
                formStateManager={props.formStateManager}
                loading={searchPersonRequest.isAwaiting}
                disabled={props.disabled}
            />
        )
    }

    return (
        <InputCP<PersonFormModel>
            label={props.label}
            fieldName={props.fieldName}
            onChange={(_.debounce(onChange, 500))}
            onBlur={onBlur}
            formStateManager={props.formStateManager}
            mask={props.type === 'cpf' ? InputMaskTypeEnum.CPFCNPJ : undefined}
            loading={searchPersonRequest.isAwaiting}
            disabled={props.disabled}
            icon={
                isDuplicated
                    ?
                    <TooltipCP showSpan={true} text={`Você não pode utilizar esse ${props.label}, ele já está sendo utilizado por outra pessoa`}>
                        <IconCP antIcon={'warning'} color={ThemeAnt.errorColor}/>
                    </TooltipCP>
                    :
                    undefined
            }
        />
    )
}
