import React, { useEffect, useState } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import * as _ from 'lodash'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { ISaleSourceAnalyticReportListItemResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISaleSourceAnalyticReportListItemResponseDTO'
import { SessionFormHistoryTableUtils } from 'main/modules/scheduler/components/table-session-form-history/inner/SessionFormHistoryTableUtils'
import { SessionAnswersHistorySessionsTP } from 'main/modules/scheduler/types/SessionAnswersHistorySessionsTP'

interface ITableSessionFormHistoryCPProps {
    data: SessionAnswersHistorySessionsTP[]
}

/**
 * Relatorio de Analitico de Origem das Vendas
 */
export function TableSessionFormHistoryCP(props: ITableSessionFormHistoryCPProps): JSX.Element {

    const [groupedTitles, setGroupedTitles] = useState<string[]>([])

    const getReportRequest = useRequest<IGenericListResponseDTO<ISaleSourceAnalyticReportListItemResponseDTO>>()

    useEffect(init, [props.data])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        // Os titulos das colunas serao as perguntas dos formularios
        const _groupedTitles = new Set(_.flatMapDeep(props.data, data => data.answers).map((ans) => ans.question.name))
        setGroupedTitles(Array.from(_groupedTitles.values()))
    }

    return (
        <TableCPOLD2
            columns={SessionFormHistoryTableUtils.getTableColumns(groupedTitles)}
            data={props.data}
            loading={getReportRequest.isAwaiting}
        />
    )
}
