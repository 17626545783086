import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { ScreenNewShot } from 'main/modules/marketing/screens/screen-new-shot/ScreenNewShot'
import { ScreenShotDetails } from 'main/modules/marketing/screens/screen-shot-details/ScreenShotDetails'
import { ScreenMarketing } from 'main/modules/marketing/screens/screen-marketing/ScreenMarketing'
import React from 'react'
import { FrameZap } from '../screens/iframezap/FrameZap'

MarketingRoutes.ROOT = 'marketing'

MarketingRoutes.MAIN_SCREEN_ROOT = `${MarketingRoutes.ROOT}/principal`


MarketingRoutes.MAIN_SCREEN_SHOT_ROOT = `${MarketingRoutes.MAIN_SCREEN_ROOT}/disparos`
MarketingRoutes.EMAIL_SENT = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/email/enviados`
MarketingRoutes.EMAIL_SCHEDULED = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/email/agendados`
MarketingRoutes.EMAIL_DRAFT = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/email/rascunho`
MarketingRoutes.SMS_SENT = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/sms/enviados`
MarketingRoutes.SMS_SCHEDULED = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/sms/agendados`
MarketingRoutes.SMS_DRAFT = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/sms/rascunho`
MarketingRoutes.ZAP_COMPONENTE = `${MarketingRoutes.MAIN_SCREEN_SHOT_ROOT}/zapmanager`

MarketingRoutes.NEW_SHOT = `${MarketingRoutes.ROOT}/novo-envio`
MarketingRoutes.NEW_SHOT_WITH_PARAM = `${MarketingRoutes.NEW_SHOT}/:shotType/:shotCode?`

MarketingRoutes.SHOT_DETAILS = `${MarketingRoutes.ROOT}/detalhes-envio`
MarketingRoutes.SHOT_DETAILS_WITH_PARAM = `${MarketingRoutes.SHOT_DETAILS}/:shotType/:shotCode`

MarketingRoutes.SENDER_EMAIL_CONFIG = '/sender-email-confirm'
MarketingRoutes.SENDER_EMAIL_CONFIG_WITH_PARAMS = '/sender-email-confirm/:senderCode/:senderToken/:token'

MarketingRoutes.TRANSACTIONAL_ROOT = `${MarketingRoutes.MAIN_SCREEN_ROOT}/automacao`
MarketingRoutes.TRANSACTIONAL_WITH_QUERY = `${MarketingRoutes.TRANSACTIONAL_ROOT}/:type`

MarketingRoutes.CONFIG = `${MarketingRoutes.MAIN_SCREEN_ROOT}/configuracoes`
MarketingRoutes.CONFIG_DOMAIN = `${MarketingRoutes.CONFIG}/dominio`
MarketingRoutes.CONFIG_SENDER = `${MarketingRoutes.CONFIG}/remetentes`
MarketingRoutes.CONFIG_PLAN = `${MarketingRoutes.CONFIG}/plano`

const MARKETING_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.MAIN_SCREEN_ROOT),
        path: MarketingRoutes.TRANSACTIONAL_WITH_QUERY,
        component: ScreenMarketing,
    },
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.MAIN_SCREEN_ROOT),
        path: MarketingRoutes.MAIN_SCREEN_ROOT,
        component: ScreenMarketing,
    },
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.NEW_SHOT),
        path: MarketingRoutes.NEW_SHOT_WITH_PARAM,
        component: ScreenNewShot,
    },
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.SHOT_DETAILS),
        path: MarketingRoutes.SHOT_DETAILS_WITH_PARAM,
        component: ScreenShotDetails,
    },
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.ZAP_COMPONENTE),
        path: MarketingRoutes.SHOT_DETAILS_WITH_PARAM,
        component: FrameZap,
    },
]

/**
 * ROTEADOR Define & manipula rotas do modulo de Marketing.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function MarketingRoutes(): JSX.Element {
    return (
        <AccessControlCP module={ModuleEnum.MODULE_MARKETING}>
            {RoutingHelper.renderRoutingSwitch(MARKETING_ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
