import { ContractVariablesEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/ContractVariablesEnum'

type DocumentVarTP = {
    title: string,
    vars: Array<{
        id: ContractVariablesEnum,
        description: string,
    }>,
}

/**
 * Variaveis existentes para ser utilizada em um contrato
 */
export const SaleContaractVars: DocumentVarTP[] = [
    {
        title: 'Geral',
        vars: [
            {
                id: ContractVariablesEnum.TODAY,
                description: 'Data atual'
            }, {
                id: ContractVariablesEnum.DAY_AND_MONTH_IN_FULL,
                description: 'Data atual com mês por extenso'
            },
        ]
    }, {
        title: 'Contratada',
        vars: [
            {
                id: ContractVariablesEnum.FRANCHISE_NAME,
                description: 'Nome da Unidade'
            }, {
                id: ContractVariablesEnum.FRANCHISE_SOCIAL_NAME,
                description: 'Razão social da contratada'
            }, {
                id: ContractVariablesEnum.FRANCHISE_CNPJ,
                description: 'CNPJ da contratada'
            }, {
                id: ContractVariablesEnum.FRANCHISE_ADDRESS,
                description: 'Endereço completo da contratada'
            }, {
                id: ContractVariablesEnum.FRANCHISE_ADDRESS_CITY,
                description: 'Cidade da contratada'
            }, {
                id: ContractVariablesEnum.FRANCHISE_ADDRESS_UF,
                description: 'Estado da contratada'
            }
        ]
    }, {
        title: 'Contratante',
        vars: [
            {
                id: ContractVariablesEnum.PERSON_CUSTOMER_NAME,
                description: 'Nome completo do contratante'
            }, {
                id: ContractVariablesEnum.PERSON_CUSTOMER_ADDRESS,
                description: 'Endereço completo do contratante'
            }, {
                id: ContractVariablesEnum.PERSON_CUSTOMER_CPF,
                description: 'CPF do contratante'
            }, {
                id: ContractVariablesEnum.PERSON_CUSTOMER_RG,
                description: 'RG do contratante'
            }, {
                id: ContractVariablesEnum.PERSON_CUSTOMER_GENDER,
                description: 'Sexo do contratante'
            }, {
                id: ContractVariablesEnum.PERSON_CUSTOMER_PHONE,
                description: 'Telefone (1) do contratante'
            },
             {
                id: ContractVariablesEnum.IMAGEM_ASSINATURA,
                description: 'Imagem da assinatura da unidade se existir (envie a imagem para o suporte)'
            },
        ]
    }, {
        title: 'Responsável pelo Pagamento',
        vars: [
            {
                id: ContractVariablesEnum.PERSON_PAYER_NAME,
                description: 'Nome completo do pagador'
            }, {
                id: ContractVariablesEnum.PERSON_PAYER_ADDRESS,
                description: 'Endereço completo do pagador'
            }, {
                id: ContractVariablesEnum.PERSON_PAYER_CPF,
                description: 'CPF do pagador'
            }, {
                id: ContractVariablesEnum.PERSON_PAYER_RG,
                description: 'RG do pagador'
            },
        ]
    }, {
        title: 'Venda',
        vars: [
            {
                id: ContractVariablesEnum.SALE_CODE,
                description: 'Código da Venda'
            }, {
                id: ContractVariablesEnum.SALE_DATE,
                description: 'Data do Contrato'
            }, {
                id: ContractVariablesEnum.SALE_ITENS,
                description: 'Itens da Venda'
            }, {
                id: ContractVariablesEnum.SALE_ITENS_WITH_SESSIONS,
                description: 'Itens da Venda com coluna de Sessões'
            }, {
                id: ContractVariablesEnum.SALE_STATUS,
                description: 'Status da Venda'
            }, {
                id: ContractVariablesEnum.SALE_TOTAL_INSTALLMENTS,
                description: 'Número de parcelas'
            }, {
                id: ContractVariablesEnum.SALE_PAYMENT_METHOD,
                description: 'Forma de Pagamento principal'
            }, {
                id: ContractVariablesEnum.SALE_TOTAL_BASE,
                description: 'Valor Bruto'
            }, {
                id: ContractVariablesEnum.SALE_DISCOUNT,
                description: 'Desconto'
            }, {
                id: ContractVariablesEnum.SALE_VALUE,
                description: 'Valor da Venda'
            },
        ]
    }
]
