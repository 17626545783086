import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { FinancialCategoryFormValidator } from 'main/modules/financial/components/modal-financial-category/inner/FinancialCategoryFormValidator'
import { SelectCategoryTypeCP } from 'main/modules/financial/components/select-category-type/SelectCategoryTypeCP'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'
import { FormStateManagerUtils } from 'main/common/form-state-manager/FormStateManagerUtils'
import { FinancialCategoryRequests } from 'main/modules/financial/services/financial-category/FinancialCategoryRequests'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { FinancialCategoryResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial-category/dtos/request/FinancialCategoryResponseDTO'
import { FinancialCategoryUpdateRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial-category/dtos/request/FinancialCategoryUpdateRequestDTO'
import { FinancialCategoryCreateRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial-category/dtos/request/FinancialCategoryCreateRequestDTO'

interface IModalPayFinancialTransactionCPProps {
    visible: boolean
    code?: number
    parentCode?: number
    categoryType: FinancialCategoryTypeEnum
    onCancel: () => void
    onSave: () => void
}

/**
 * Componente de modal com dados para pagamento de uma transacao financeira.
 */
export function ModalFinancialCategoryCP(props: IModalPayFinancialTransactionCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<FinancialCategoryFormValidator>(new FinancialCategoryFormValidator())
    const formStateManager = useFormStateManager<FinancialCategoryFormValidator>(formValidator)

    const saveFinancialCategoryRequest = useRequest('none')
    useEffect(onSaveFinancialCategoryRequestChange, [saveFinancialCategoryRequest.isAwaiting])

    const getFinancialCategoryRequest = useRequest<FinancialCategoryResponseDTO>()
    useEffect(onGetFinancialCategoryRequestChange, [getFinancialCategoryRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        formStateManager.reset(new FinancialCategoryFormValidator({}))

        if (!props.visible)
            return

        if (!!props.code)
            getFinancialCategoryRequest.runRequest(FinancialCategoryRequests.findOne(props.code))
    }

    /**
     * Retorno da requisicao para pegar a transacao financeira.
     */
    function onGetFinancialCategoryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFinancialCategoryRequest, 'Erro ao obter plano de contas'))
            return

        const responseDto = getFinancialCategoryRequest.responseData!
        setFormValidator(new FinancialCategoryFormValidator({
            name: responseDto.name,
            type: responseDto.type,
            isDreRequired: responseDto.isDreRequired,
        }))
    }

    /**
     * Salva o plano de contas.
     */
    async function onSave(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        // Atualiza
        if (!!props.code) {

            const dto: FinancialCategoryUpdateRequestDTO = {
                type: formValues.type,
                newName: formValues.name,
                isDreRequired: formValues.isDreRequired,
            }
            saveFinancialCategoryRequest.runRequest(FinancialCategoryRequests.update(props.code, dto))

            return
        }

        // Cria
        const dto: FinancialCategoryCreateRequestDTO = {
            name: formValues.name,
            parentCategoryCode: props.parentCode ?? getFinancialCategoryRequest.responseData!.parentCategoryCode!,
            category: props.categoryType,
            type: formValues.type,
            isDreRequired: formValues.isDreRequired,
        }
        saveFinancialCategoryRequest.runRequest(FinancialCategoryRequests.create(dto))
    }

    /**
     * Retorno da API ao salvar.
     */
    function onSaveFinancialCategoryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFinancialCategoryRequest, 'Erro ao salvar plano de contas', true))
            return

        NotificationHelper.success('Yes!', 'Plano de contas salvo com sucesso!')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Plano de Contas'}
            width={440}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={onSave}
            loading={getFinancialCategoryRequest.isAwaiting}
            actionLoading={saveFinancialCategoryRequest.isAwaiting}
            destroyOnClose={true}
        >
            <InputCP
                label={'Nome'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'name'}
            />

            <SelectCategoryTypeCP
                label={'Tipo:'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'type'}
                categoryType={props.categoryType}
            />

            <FlexCP margin={{ top: 10 }}>
                <SwitchCP
                    formStateManager={formStateManager}
                    fieldName={'isDreRequired'}
                    isTextInside={false}
                />
                <TextCP text={'É obrigatório para DRE?'} marginLeft={5}/>
            </FlexCP>
            <HelpCP
                text={'Caso seja marcado como obrigatório, ao gerar o relatório de DRE, será exibido uma mensagem informando que faltou despesa nesta categoria'}
                type={'text'}
            />
        </ModalCP>
    )
}
