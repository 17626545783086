import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IOpportunitySourceSaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/request/IOpportunitySourceSaveRequestDTO'
import { IOpportunitySourceSearchRequestDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/request/IOpportunitySourceSearchRequestDTO'

/**
 * REQUISIÇÕES relacionadas a Origem de Oportunidades.
 */
export class OpportunitySourceRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/opportunity-sources`

    static searchOpportunitySources = (dto?: IOpportunitySourceSearchRequestDTO): RequestConfigTP => ({
        url: OpportunitySourceRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOpportunitySource = (code: number): RequestConfigTP => ({
        url: `${OpportunitySourceRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static createOpportunitySource = (dto: IOpportunitySourceSaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunitySourceRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateOpportunitySource = (code: number, dto: IOpportunitySourceSaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunitySourceRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteOpportunitySource = (code: number): RequestConfigTP => ({
        url: `${OpportunitySourceRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
