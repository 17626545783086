import React, { useEffect, useState } from 'react'
import { RadioGroupSchedulerViewModeCP } from 'main/modules/scheduler/components/radio-group-scheduler-view-mode/RadioGroupSchedulerViewModeCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FormFilterCalendarEventsCP } from 'main/modules/scheduler/components/form-filter-calendar-events/FormFilterCalendarEventsCP'
import { CalendarEventsCP } from 'main/modules/scheduler/components/calendar-events/CalendarEventsCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 * Responsável por renderizar a tela do módulo de agenda
 */
export function ScreenCalendar(): JSX.Element {

    const [formValidator] = useState<FormFilterCalendarEventsFormModel>(new FormFilterCalendarEventsFormModel())
    const formStateManager = useFormStateManager<FormFilterCalendarEventsFormModel>(formValidator)

    const [shouldLoadEvents, setShouldLoadEvents] = useState<number>(0)

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        setShouldLoadEvents(TableUtils.getReloadNumber())
    }

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Agenda'}>
                    <RadioGroupSchedulerViewModeCP currentView={'calendar'}/>
                </HeaderCP>
            }
            sider={
                <SiderCP width={275} bgColor={ThemeProject.secondaryMenuColor}>
                    <FormFilterCalendarEventsCP
                        formStateManager={formStateManager}
                        onFilter={() => setShouldLoadEvents(TableUtils.getReloadNumber())}
                        filtersConfig={{
                            showNoShow: true
                        }}
                    />
                </SiderCP>
            }
            content={
                <ContentCP>
                    <CalendarEventsCP
                        shouldLoadEvents={shouldLoadEvents}
                        filtersFormStateManager={formStateManager}
                    />
                </ContentCP>
            }
        />
    )
}
