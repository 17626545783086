import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { CheckboxCP } from 'main/common/components/checkbox/CheckboxCP'
import { WeekDaysEnum } from 'main/common/enums/WeekDaysEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { WeekDayLabelEnum } from 'main/modules/people/enums/WeekDayLabelEnum'

interface ICopyTimeRangeColumnICPProps {
    workSchedule: IWorkSchedule
    onChangeTime: (workSchedule: IWorkSchedule) => void
}

/**
 * Copy o horario de trabalho de uma linha selecionada para os dias selecionados
 */
export function CopyTimeRangeColumnICP(props: ICopyTimeRangeColumnICPProps): JSX.Element {

    const [isVisible, setIsVisible] = useState<boolean>()
    const [weekDayToCopyMap, setWeekDayToCopyMap] = useState<Map<WeekDaysEnum, boolean>>(new Map())

    useEffect(init, [isVisible])

    /**
     * Inicializa
     */
    function init(): void {

        const weekDayMap = new Map()
        weekDayMap.set(WeekDaysEnum.SUNDAY, false)
        weekDayMap.set(WeekDaysEnum.MONDAY, false)
        weekDayMap.set(WeekDaysEnum.TUESDAY, false)
        weekDayMap.set(WeekDaysEnum.WEDNESDAY, false)
        weekDayMap.set(WeekDaysEnum.THURSDAY, false)
        weekDayMap.set(WeekDaysEnum.FRIDAY, false)
        weekDayMap.set(WeekDaysEnum.SATURDAY, false)

        // Remove o dia que ele clicou para copiar
        weekDayMap.delete(props.workSchedule.weekDay)

        setWeekDayToCopyMap(weekDayMap)
    }

    /**
     * Monta componente com os checkbox.
     */
    function getWeekDayCheckboxsCP(): JSX.Element[] {

        const elementCps: JSX.Element[] = []

        // Itera nos dias da semana criando um checkbox para cada dia
        weekDayToCopyMap.forEach((checked, weekDay) => {
            elementCps.push(
                <FlexCP>
                    <CheckboxCP
                        isChecked={!!weekDayToCopyMap.get(weekDay)}
                        onChange={(wasChecked) => onChangeTime(wasChecked, weekDay)}
                    />
                    <TextCP text={WeekDayLabelEnum[weekDay]} marginLeft={5}/>
                </FlexCP>
            )
        })

        return elementCps
    }

    /**
     * Evento ao selecionar um dia para copiar horario.
     */
    function onChangeTime(checked: boolean, weekDay: WeekDaysEnum): void {
        weekDayToCopyMap.set(weekDay, checked)
        props.onChangeTime({
            weekDay,
            beginTime: checked ? props.workSchedule.beginTime : undefined,
            endTime: checked ? props.workSchedule.endTime : undefined,
            beginInterval: checked ? props.workSchedule.beginInterval : undefined,
            endInterval: checked ? props.workSchedule.endInterval : undefined
        })
    }

    return (
        <PopOverCP
            title={'Clonar o horário em?'}
            visible={isVisible}
            onClose={() => setIsVisible(false)}
            destroyOnHide={true}
            content={
                <>
                    { getWeekDayCheckboxsCP() }
                </>
            }
        >
            <ButtonCP
                onClick={() => setIsVisible(true)}
                icon={'copy'}
                type={'ghost'}
                size={'small'}
                marginRight={5}
                disabled={!props.workSchedule.beginTime || !props.workSchedule.endTime}
            />
        </PopOverCP>
    )
}
