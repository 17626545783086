
import React, {  useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { RowCP } from 'main/common/components/grid/RowCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

export function RegistroComissao(props:any):JSX.Element{
    const codigoProduto = props.codigoProduto;
    const codigoFranquia = props.geral.productReleaseFranchise.franchiseCode;
    console.log(codigoProduto,codigoFranquia);
    const valorExecucao =  props.geral?.productReleaseFranchise?.comissao_execucao;
    const valorVenda = props.geral?.productReleaseFranchise?.comissao_venda;
    const [comissaoVenda, setComissaoVenda] = useState(valorVenda);
    const [comissaoExecucao, setComissaoExecucao] = useState(valorExecucao);
    const [carregando, setCarregando] = useState(false);
    
    async function salvarComissoes(){
        setCarregando(true);
        if(comissaoVenda && comissaoExecucao){
            if(parseFloat(comissaoVenda+'')>100 || parseFloat(comissaoExecucao+'')>100){
                NotificationHelper.error('Inválido','Os valores de comissões precisam ser menores que 100%');
                setCarregando(false);
                return;
            }
        }
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/product-releases/salvarComissoes`,
            method: HttpMethodEnum.POST,
            params: {
                comissaoVenda:comissaoVenda && comissaoVenda!='NaN'?comissaoVenda:null,
                comissaoExecucao:comissaoExecucao && comissaoExecucao!='NaN'?comissaoExecucao:null,
                codigoProduto:codigoProduto,
                codigoFranquia:codigoFranquia,
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>setCarregando(false)); 
        
        if(resultado?.data?.data=='sucesso'){
            NotificationHelper.success('Sucesso','Percentuais de comissão salvos com sucesso!');
            props.onEditDone();
            return;
        }
        NotificationHelper.error('Houve um Erro','Houve um erro ao tentar salvar as comissões');
        
    }

    return (
        <WrapperSCP>
            <div className='painel-comissao'>
            <RowCP>
                
                <TitleCP textSize={'large'} underLine={true} marginTop={5}>
                 Comissões do Pacote
                </TitleCP>
                <div className='sub'>
                    Comissão de Venda
                </div>
                <ColumnCP size={9}>
                    <InputCP
                        mask={InputMaskTypeEnum.NUMERIC}
                        value={comissaoVenda}
                        onChange={setComissaoVenda}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <div className='sub'>
                    Comissão de Execução
                </div>
                <ColumnCP size={9}>
                    <InputCP
                        mask={InputMaskTypeEnum.NUMERIC}
                        value={comissaoExecucao}
                        onChange={setComissaoExecucao}
                    />
                </ColumnCP>
                
                
            </RowCP>
            <RowCP>
                <div className='dir-botao'>
                    <ButtonCP
                        onClick={salvarComissoes}
                        type={'primary'}
                        icon={'check'}
                        loading={carregando}
                    
                    >Salvar Comissões</ButtonCP>
                </div>
            </RowCP>
            </div>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  width: 100%;
  .sub{
    color: #2b698cff;
    font-weight: 589;
  }
  .painel-comissao{
    padding-bottom: 11px;
    border-bottom: dashed 1px gray;
    border-top: dashed 1px gray;
    padding-top: 4px;
    margin-top: 7px;
  }
  .dir-botao{
    display:flex;
    justify-content: right;
    margin-right: 10px;
    margin-top: 4px;
  }
`