import React from 'react'
import { SignatureSecurityLevelIconCP } from 'main/modules/documents/components/signature-security-level-icon/SignatureSecurityLevelIconCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DeleteIconCP } from 'main/common/components/icons/DeleteIconCP'
import { DescriptionCP } from 'main/common/components/description/DescriptionCP'
import { DescriptionItemCP } from 'main/common/components/description/description-item/DescriptionItemCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IDokfyPersonResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyPersonResponseDTO'
import styled from 'styled-components'
import { ListItemICP } from 'main/common/components/list/inner/ListItemICP'
import { ListCP } from 'main/common/components/list/ListCP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ButtonChangeDokfyUserEmailCP } from 'main/modules/documents/components/button-change-dokfy-user-email/ButtonChangeDokfyUserEmailCP'

interface ICPProps {
    originalAssigneesData: IPersonResponseDTO[]
    assignees: IDokfyPersonResponseDTO[]
    showRemove: boolean
    onUpdateData: () => void
    onRemoveAssignee?: (index: number) => void
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function ContractAssigneesTableCP(props: ICPProps): JSX.Element {

    return (
        <ListCP<IDokfyPersonResponseDTO>
            dataSource={props.assignees}
            renderItem={(item, index) => (
                <ListItemICP
                    avatar={<SignatureSecurityLevelIconCP secLevel={item.signatureSecurityLevel} showTooltip={true}/>}
                    title={
                        <FlexCP justifyContent={'space-between'}>
                            <div>{item.name}</div>
                            <div>
                                {
                                    props.showRemove &&
                                    <ButtonCP
                                        size={'small'}
                                        onClick={() => {
                                            if (props.onRemoveAssignee)
                                                props.onRemoveAssignee(index)
                                        }}
                                        confirmMsg={'Tem certeza que deseja remover este signatário?'}
                                    >
                                        <DeleteIconCP size={14}/>
                                    </ButtonCP>
                                }
                            </div>
                        </FlexCP>
                    }
                    content={
                        <AssigneeItemSCP>
                            <DescriptionCP>
                                <DescriptionItemCP label={'CPF'}>
                                    <>{ MaskUtils.applyMask(item.cpfCnpj, InputMaskTypeEnum.CPF) }</>
                                </DescriptionItemCP>
                                <DescriptionItemCP label={'E-mail'}>
                                    <FlexCP alignItems={'center'}>
                                        <span>{item.email}</span>
                                        <ButtonChangeDokfyUserEmailCP
                                            originalAssigneeData={props.originalAssigneesData.find((person) => person.cpfCnpj === item.cpfCnpj)}
                                            assignee={item}
                                            onSyncEnd={props.onUpdateData}
                                        />
                                    </FlexCP>
                                </DescriptionItemCP>
                                <DescriptionItemCP>
                                    {`Usuário Dokfy há ${DateUtils.getPresentableDiff(DateUtils.toDate(item.createdDate, DateFormatEnum.US_WITH_TIME_H_M))}`}
                                </DescriptionItemCP>
                            </DescriptionCP>
                        </AssigneeItemSCP>
                    }
                />
            )}
        />
    )
}

const AssigneeItemSCP = styled.div`
  .ant-descriptions-row > td {
      padding-bottom: 0px !important;
  }
`
