import React, { useState } from 'react'
import styled from 'styled-components'
import { StringUtils } from 'main/common/utils/StringUtils'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface ICPProps {
    franchise: INameAndCodeResponseDTO
    onSelectFranchise: (franchise: INameAndCodeResponseDTO) => void
}

/**
 * Titulo para analise de unidade(s).
 */
export function DashboardFranchiseTitleCP(props: ICPProps): JSX.Element {

    const [isUserPopoverVisible, setIsUserPopoverVisible] = useState<boolean>(false)

    return (
        <PersonTitleSCP>
            <TextCP text={'Indicadores de Performance da:'}/>
            <TextCP text={StringUtils.getFirstAndSecondName(props.franchise.name)} strong={true} marginLeft={5}/>

            <ConditionalRenderCP shouldRender={PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_MANAGER])}>
                <PopOverCP
                    content={
                        <SelectFranchiseCP
                            label={'Filtre por uma unidade'}
                            onChange={(selected: any) => {
                                if (!!selected) {
                                    props.onSelectFranchise({ code: selected.value, name: selected.label })
                                    setIsUserPopoverVisible(false)
                                }
                            }}
                            initialFranchises={props.franchise.code}
                            width={300}
                            returnFullOption={true}
                        />
                    }
                    onClose={() => setIsUserPopoverVisible(false)}
                    visible={isUserPopoverVisible}
                >
                    <ButtonCP
                        icon={'edit'}
                        marginLeft={10}
                        type={'link'}
                        onClick={() => setIsUserPopoverVisible(true)}
                    />
                </PopOverCP>
            </ConditionalRenderCP>
        </PersonTitleSCP>
    )
}

const PersonTitleSCP = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;
  
  b {
    color: #f59873;
  }
`
