import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ITechnicianRankingIndicatorsListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ITechnicianRankingIndicatorsListItemResponseDTO'
import { TechnicianRankingIndicatorsOrderingEnum } from 'main/modules/dashboard/services/enums/TechnicianRankingIndicatorsOrderingEnum'

/**
 * UTILITARIOS do widget de dados da tecnica.
 */
export const RankingAttendantWidgetUtils = {

    getTableColumns(): Array<ColumnProps<ITechnicianRankingIndicatorsListItemResponseDTO>> {
        return [
            {
                title: 'Colaborador',
                key: TechnicianRankingIndicatorsOrderingEnum.USER,
                sorter: true,
                render: (text, dto) => dto.user.name
            },
            {
                title: 'Clientes',
                key: TechnicianRankingIndicatorsOrderingEnum.TOTAL_CUSTOMERS_WITH_EVOLUTION,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalCustomersWithEvolution, 2)
            },
            {
                title: 'Procedimentos',
                key: TechnicianRankingIndicatorsOrderingEnum.TOTAL_TREATMENTS_CONCLUDED,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalTreatmentsConcluded, 2)
            },
            {
                title: 'Revendas Cabine',
                key: TechnicianRankingIndicatorsOrderingEnum.TOTAL_SALES_IN_APPLICATION_ROOM,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalResellInApplicationRoom, 0)
            },
            {
                title: 'Valor Revendas',
                key: TechnicianRankingIndicatorsOrderingEnum.SALE_VALUE_IN_APPLICATION_ROOM,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyMoneyMask(dto.resellInApplicationRoomValue)
            },
            {
                title: 'Ticket Médio',
                key: TechnicianRankingIndicatorsOrderingEnum.SALE_VALUE_AVERAGE_IN_APPLICATION_ROOM,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyMoneyMask(dto.resellInApplicationRoomValueAverage)
            },
        ]
    },

}
