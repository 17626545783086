import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { SaleSourcesAnalyticReportUtils } from 'main/modules/reports/components/sale-sources-analytic-report/inner/SaleSourcesAnalyticReportUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { SaleSourcesAnalyticViewTypeButtonICP } from 'main/modules/reports/components/sale-sources-analytic-report/inner/SaleSourcesAnalyticViewTypeButtonICP'
import { DatePeriodGroupTypeEnum } from 'main/modules/reports/services/sale-reports/enums/DatePeriodGroupTypeEnum'
import { styled } from 'main/config/theme/styledWithTheme'
import { ISaleSourcesReportFilterData } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/inner/ISaleSourcesReportFilterData'
import * as _ from 'lodash'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { SaleReportsRequests } from 'main/modules/reports/services/sale-reports/SaleReportsRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ISaleSourcesAnalyticReportFilterRequestDTO } from 'main/modules/reports/services/sale-reports/dtos/requests/ISaleSourcesAnalyticReportFilterRequestDTO'
import { ISaleSourceAnalyticReportListItemResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISaleSourceAnalyticReportListItemResponseDTO'

interface ISaleSourcesAnalyticReportCPProps {
    filterData: ISaleSourcesReportFilterData
}

/**
 * Relatorio de Analitico de Origem das Vendas
 */
export function SaleSourcesAnalyticReportCP(props: ISaleSourcesAnalyticReportCPProps): JSX.Element {

    const [groupedTitles, setGroupedTitles] = useState<string[]>([])
    const [reportGroupBy, setReportGroupBy] = useState<DatePeriodGroupTypeEnum>(DatePeriodGroupTypeEnum.TEN_DAYS)
    const [reportData, setReportData] = useState<ISaleSourceAnalyticReportListItemResponseDTO[]>()

    const getReportRequest = useRequest<IGenericListResponseDTO<ISaleSourceAnalyticReportListItemResponseDTO>>()
    useEffect(onGetReportRequestChange, [getReportRequest.isAwaiting])

    useEffect(init, [props.filterData, reportGroupBy])

    /**
     * Inicializa dados da tela
     */
    function init(): void {

        if (!props.filterData?.dateRange?.beginDate || !props.filterData?.dateRange?.endDate)
            return

        const filters: ISaleSourcesAnalyticReportFilterRequestDTO = {
            beginDate: props.filterData.dateRange.beginDate,
            endDate: props.filterData.dateRange.endDate,
            franchiseCodes: props.filterData?.franchiseCodes,
            userSellerCode: props.filterData?.userSellerCode,
            groupType: reportGroupBy
        }
        getReportRequest.runRequest(SaleReportsRequests.saleSourcesAnalyticReport(filters))
    }

    /**
     * Retorno da requisicao para obter dados do relatorio
     */
    function onGetReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReportRequest, 'Erro obter dados do relatório'))
            return

        const reportResult = getReportRequest.responseData!.list
        setReportData(reportResult)

        // Obtem os valores unicos das franquias
        const _groupedTitles = new Set(_.flatMapDeep(reportResult, data => data.reportGroupedData).map((groupedData) => groupedData.groupBy))
        setGroupedTitles(Array.from(_groupedTitles.values()))
    }

    return (
        <CardCP
            innerSpacing={'none'}
            overflow={'auto'}
            title={
                <FlexCP justifyContent={'space-between'}>
                    <TextCP text={'Detalhamento de canais de venda'}/>
                    <SaleSourcesAnalyticViewTypeButtonICP
                        currentView={reportGroupBy}
                        onChangeView={setReportGroupBy}
                    />
                </FlexCP>
            }
        >
            <TableWrapper>
                <TableCPOLD2
                    columns={SaleSourcesAnalyticReportUtils.getTableColumns(groupedTitles)}
                    data={reportData}
                    loading={getReportRequest.isAwaiting}
                />
            </TableWrapper>
        </CardCP>
    )
}

const TableWrapper = styled.div`
  margin-bottom: 20px;    
`
