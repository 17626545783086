import React from 'react'
import { IBulkOperationResponseDTO } from 'main/modules/admin/services/bulk-operation/responses/IBulkOperationResponseDTO'
import { BulkOperationImportSummaryICP } from 'main/modules/admin/components/table-bulk-operations/inner/BulkOperationImportSummaryICP'
import { TextCP } from 'main/common/components/text/TextCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import styled from 'main/config/theme/styledWithTheme'

interface IImportTableColumnTotalICPProps {
    log: IBulkOperationResponseDTO
}

/**
 * COMPONENTE
 * Coluna de TOTAL da tabela de historico de importacao.
 */
export function BulkOperationTableColumnTotalICP(props: IImportTableColumnTotalICPProps): JSX.Element {

    const total = (props.log.totalRecordsSuccessfully > (props.log.totalRecords ?? 0)) ? props.log.totalRecordsSuccessfully : props.log.totalRecords

    return (
        <PopOverCP
            trigger={'hover'}
            content={<BulkOperationImportSummaryICP log={props.log}/>}
        >
            <PopoverContainerSCP>
                <TextCP size={'small'} text={`${props.log.totalRecordsSuccessfully} / ${total ?? '-'}`}/>
            </PopoverContainerSCP>
        </PopOverCP>
    )
}

const PopoverContainerSCP = styled.div`
    cursor: help;
`
