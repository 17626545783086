import React from 'react'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import { DropdownCP } from 'main/common/components/dropdown/DropdownCP'
import { Menu } from 'antd'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import styled from 'styled-components'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'

interface IStepsDropdownButtonCPProps{
    opportunityCode: number
    funnelSteps: IFunnelStepResponseDTO[]
    moveStepOpportunity: (followUpCode: number, newFunnelStepCode: number) => void
    currentStepName?: string
    loading?: boolean
}

export function StepsDropdownButtonCP(props: IStepsDropdownButtonCPProps): JSX.Element {

    function renderItens(): JSX.Element[] {

        return props.funnelSteps.map((step): JSX.Element => {
            return (
                <Menu.Item key={step.code}>
                    <ButtonCP
                        type={'link'}
                        onClick={() => props.moveStepOpportunity(props.opportunityCode, step.code)}
                    >
                        { `Mover para ${step.name}` }
                    </ButtonCP>
                </Menu.Item>
            )
        })
    }

    return (
        <ButtonWrapperSCP>
            <DropdownCP
                overlay={
                    <Menu>
                        { renderItens() }
                    </Menu>
                }
                type={'ghost'}
                icon={<FontAwsomeIconCP type={faAngleDown}/>}
            >
                {
                    props.loading
                        ?
                        <LoadingCP show={true} size={'1x'}/>
                        :
                        props.currentStepName
                }
            </DropdownCP>
        </ButtonWrapperSCP>
    )
}

const ButtonWrapperSCP = styled.div`
  .ant-btn {
    min-height: 25px !important;
    height: 25px !important;
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
`