import React from 'react'
import { Typography } from 'antd'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { styled } from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { FontSizeTP } from 'main/config/theme/ThemeTypes'

interface IEditableTextCPProps {
    onEditionEnd: (text: string) => void
    color?: string
    children?: string
    size?: FontSizeTP
    disabled?: boolean
}

/**
 * COMPONENTE
 * Exibe texto & permite que o mesmo seja editado no mesmo lugar.
 */
export const EditableTextCP = ComponentUtils.memoizeCp((props: IEditableTextCPProps): JSX.Element => {
    return (
        <WrapperSCP
            color={props.color}
            fontSize={ThemeAnt.fontSizes[props.size ?? 'normal']}
        >
            <Typography.Paragraph editable={(props.disabled !== true) ? { onChange: props.onEditionEnd } : false}>
                {props.children}
            </Typography.Paragraph>
        </WrapperSCP>
    )
})

const WrapperSCP = styled.div<{ color?: string, fontSize: string }>`

    transition: opacity .3s;

    &:hover {

        opacity: .9;

        .ant-typography {
            border-bottom-color: ${props => props.color ?? props.theme.primaryColor}b8;
        }
    }

    .ant-typography {
        color: ${props => props.color ?? props.theme.primaryColor};
        position: relative;
        margin: 0;
        font-size: ${props => props.fontSize};
        border-bottom: 1px solid transparent;
        transition: border .3s;
        padding-bottom: 2px;
    }

    .ant-typography-edit {

        width: 100%;
        position: absolute;
        left: 0;
        margin: 0;
        cursor: text;
        font-size: ${props => props.fontSize};

        i {
            opacity: 0;
        }
    }

    textarea {
        resize: none;
        color: ${props => props.theme.black};
        background-color: ${props => props.theme.white};
    }
`
