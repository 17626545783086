import { ObjKeyTP } from 'main/common/types/ObjKeyTP'
import { CtxFactoryDispatchTP } from 'main/common/context-factory/types/CtxFactoryDispatchTP'

/**
 * HELPER (Context Factory)
 * Auxilia na gestao das actions lancadas em situacoes envolvendo actions / reducers, nos contextos.
 */
export class CtxFactoryActionBuildingHelper<StateTP, ActionTypeTP extends ObjKeyTP> {

    constructor(
        private readonly _state: StateTP,
        private readonly _dispatch: CtxFactoryDispatchTP<StateTP, ActionTypeTP>
    ) {}

    /**
     * Lanca action generica do tipo que simplesmente mescla valor de payload informado com o estado atual.
     *
     * @param payload Valor para sobrescrever o estado atual.
     * @param debugTitle Se definido, habilta log para debug de alteracao de estado identificado por esse valor.
     */
    replaceWithPayload(payload: Partial<StateTP>, debugTitle?: string): void {

        this.dispatch({ type: 'generic', payload })

        if (debugTitle) {
            const debugId = `replaceWithPayload::${debugTitle}`
            console.count(debugId) // eslint-disable-line no-console
            console.log(`${debugId} - `, payload) // eslint-disable-line no-console
        }
    }

    get state(): StateTP {
        return this._state
    }

    get dispatch(): CtxFactoryDispatchTP<StateTP, ActionTypeTP> {
        return this._dispatch
    }
}
