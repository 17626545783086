import * as React from 'react'
import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'

/**
 * TELA de carregamento:
 * Serve como 'placeholder' durante transicoes de tela ou carregamentos.
 *
 * TODO: Fazer ficar bonito
 */
export function LoadingScreen(): JSX.Element {
    return (
        <ContentCP>
            <LoadingOverlayCP show={true}/>
        </ContentCP>
    )
}
