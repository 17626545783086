import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DragAndDropListCP } from 'main/common/components/drag-and-drop-list/DragAndDropListCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { DragAndDropListItemICP } from 'main/common/components/drag-and-drop-list/inner/DragAndDropListItemICP'
import { DragAndDropColumnTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropColumnTP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { FormFormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/inner/FormFormQuestionResponseDTO'

interface ICPProps {
    formQuestions: FormFormQuestionResponseDTO[]
    onChangeQuestionList: (reorderedList: FormFormQuestionResponseDTO[]) => void
    loading?: boolean
}

/**
 * Drag and Drop de questoes de um formulario.
 *
 * ATENCAO: Sempre sera consideracao o item 0 do datasource, pq so tem uma coluna => Nao eh um kanban.
 */
export function DragAndDropDrawerFormQuestionsCP(props: ICPProps): JSX.Element {

    const [dataSource, setDataSource] = useState<Array<DragAndDropColumnTP<FormFormQuestionResponseDTO>>>([])

    useEffect(init, [props.formQuestions])

    /**
     * Inicializa
     */
    function init(): void {

        setDataSource(
            [{
                columnId: 'uq-column',
                code: 1,
                name: 'Coluna',
                totalItens: props.formQuestions.length,
                itens: props.formQuestions.map((question, index) => (
                    {
                        code: question.code ?? question.questionCode,
                        index,
                        order: question.order,
                        item: question,
                    })),
            }]
        )
    }

    /**
     * Ao remover uma questao.
     */
    function onRemove(index: number): void {

        if (!dataSource)
            return

        dataSource[0].itens.splice(index, 1)
        props.onChangeQuestionList(dataSource[0].itens.map((dragAndDropItem) => dragAndDropItem.item))
    }

    return (
        <DragAndDropListCP
            loading={props.loading}
            dataSource={dataSource}
            showHeader={false}
            columnWidth={'100%'}
            renderContent={(item, index) => (
                <DragAndDropListItemICP
                    id={index}
                    index={index}
                >
                    <CardCP
                        innerSpacing={'small'}
                        width={'100%'}
                        margin={'none'}
                    >
                        <FlexCP alignItems={'center'} justifyContent={'space-between'}>
                            <FlexCP alignItems={'center'}>
                                <IconCP antIcon={'drag'}/>
                                <TitleAndTextCP
                                    title={`${index + 1}.`}
                                    text={item.questionTitle}
                                    marginLeft={10}
                                />
                            </FlexCP>
                            <ButtonCP
                                icon={'delete'}
                                size={'small'}
                                onClick={() => onRemove(index)}
                            />
                        </FlexCP>
                    </CardCP>
                </DragAndDropListItemICP>
            )}
            onListOrderChange={(data) => {
                dataSource[0].itens = data
                props.onChangeQuestionList(dataSource[0].itens.map((it) => it.item))
            }}
        />
    )
}
