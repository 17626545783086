import { DatePicker } from 'antd'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FastDateRangeFilterTP, FastFilterICP } from './inner/FastFilterICP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IScpProps {
    showBorder?: boolean
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface IDateRangePickerCPProps extends IScpProps {
    onChange: (dates: IDateRangeFilter, advancedFilterType?: FastDateRangeFilterTP) => void
    value?: IDateRangeFilter
    fastFilter?: FastDateRangeFilterTP
    allowClear?: boolean
    disabledDate?: ((current: moment.Moment | null) => boolean) | undefined
    label?: string
}

/**
 * COMPONENTE
 * Date picker customizado para range de datas (data inicial + data final).
 */
export const DateRangePickerCP = (props: IDateRangePickerCPProps): JSX.Element => {

    const [isPickerOpen, setIsPickerOpen] = useState(false)
    const [dateRangePicker, setDateRangePicker] = useState<RangePickerValue>()

    useEffect(() => formatRangePicker(props.value), [props.value])

    /**
     * Metodo necessario para abstrair o tipo RangeDatePicker, tudo que chega eh convertido para RangeDatePicker
     */
    function formatRangePicker(dates?: IDateRangeFilter): void {

        if (!dates)
            return

        setDateRangePicker(([
            dates.beginDate ? moment(dates.beginDate) : undefined,
            dates.endDate ? moment(dates.endDate) : undefined
        ]) as any)
    }

    /**
     * Ao selecionar uma data no datePicker.
     */
    function onDateRangeSelection(dates: RangePickerValue, dateStrings: [string, string]): void {

        const dateFilter: IDateRangeFilter = {
            beginDate: !!dateStrings[0] ? moment(dateStrings[0], DateFormatEnum.BR_WITHOUT_TIME).toDate() : undefined,
            endDate: !!dateStrings[0] ? moment(dateStrings[1], DateFormatEnum.BR_WITHOUT_TIME).toDate() : undefined
        }

        setIsPickerOpen(false)
        props.onChange(dateFilter, undefined)
    }

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            <ConditionalRenderCP shouldRender={!!props.label}>
                <TextCP
                    text={props.label}
                    marginBottom={5}
                />
            </ConditionalRenderCP>

            <DatePickerContainerSCP showBorder={props.showBorder}>
                <DateRangePickerSCP
                    onOpenChange={(status) => setIsPickerOpen(status)}
                    value={dateRangePicker}
                    onChange={onDateRangeSelection}
                    open={isPickerOpen}
                    format={DateFormatEnum.BR_WITHOUT_TIME}
                    placeholder={['Data inicial', 'Data final']}
                    allowClear={props.allowClear}
                    disabledDate={props.disabledDate}
                    renderExtraFooter={
                        !props.fastFilter
                            ? undefined
                            : () => (
                                <FastFilterICP
                                    onFilterSelection={(t) => {
                                        props.onChange(t)
                                        setIsPickerOpen(false)
                                    }}
                                    currentFilter={props.fastFilter}
                                />
                            )}
                />
            </DatePickerContainerSCP>
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<IScpProps>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`

const DatePickerContainerSCP = styled.div<{ showBorder?: boolean }>`
    height: ${props => props.showBorder ? '48' : '37'}px;
    padding-bottom: 2px;
    border-bottom: ${props => props.showBorder ? 'solid 1px #BBBBBB' : ''};
`

const DateRangePickerSCP = styled(DatePicker.RangePicker)`
    &.ant-calendar-picker{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border: none;
        box-shadow: none;
        .ant-calendar-picker-icon{
            width: 28px;
            height: 28px;
            margin-top: -9px;
            left: 0;
            &:after{
                font-size: 20px;
                color: ${props => props.theme.primaryColor};
            }
        }
        .ant-calendar-picker-input{
            border: none;
            padding-left: 35px;
            color: ${props => props.theme.black};

            .ant-calendar-range-picker-separator{
                margin: 5px;
            }

            &:focus{
                box-shadow: none;
            }

            input::placeholder{
                color: ${props => props.theme.black};
            }
        }
        input{
            border: none;
            width: 40%;
            font-size: .8rem;
            color: ${props => props.theme.black};
        }
    }
    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled){
        border: none;
        box-shadow: none;
    }
`

