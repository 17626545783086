import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { AutoCompleteOpportunitySourceCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity-source/AutoCompleteOpportunitySourceCP'
import { ImportFormValidator } from 'main/modules/sales-funnel/components/modal-lead-import/inner/ImportFormValidator'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'

interface IImportFormICPProps {
    formStateManager: IFormStateManager<ImportFormValidator>
}

/**
 * COMPONENTE
 * Formulario para captar dados a serem enviados junto ao arquivo na requisicao de importacao de leads.
 */
export function ImportFormICP(props: IImportFormICPProps): JSX.Element {
    return (
        <WrapperSCP>
            <AutocompleteUserCP<ImportFormValidator>
                label={'Selecione o vendedor'}
                required={true}
                showLoggedUserAsInitialOption={true}
                formStateManager={props.formStateManager}
                fieldName={'responsibleCode'}
            />

            <AutoCompleteOpportunitySourceCP<ImportFormValidator>
                label={'Canal do lead'}
                required={true}
                fieldName={'sourceCode'}
                formStateManager={props.formStateManager}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`  
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    width: 100%;
`
