import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { EnvironmentEnum } from 'main/common/enums/EnvironmentEnum'
import { DokfySystemApiEnum } from 'submodules/dokfy-sdk/common/DokfySystemApiEnum'
import { DokfyRequests } from 'main/modules/documents/services/dokfy/DokfyRequests'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * Class especifica do projeto com informacoes necessarios para o projeto.
 */
export class NeritFrameworkProjectConfig {

    static APP_VERSION = '20230913.3'
    static PROJECT_NAME = 'VX Clinic'

    private constructor() {}

    static isDev(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.DEV
    }

    static isHom(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.HOM
    }

    static async formatApiBaseUrl(config: RequestConfigTP): Promise<string> {

        if (!config.headers)
            config.headers = []

        let baseurl = config.url

        if (config.baseUrlType === NeritClinSystemApiEnum.NERITCLIN_API)
            baseurl = `${SystemConfig.getInstance().apiBaseUrl}/${config.url}`

        else if (config.baseUrlType === Space4LeadsSystemApiEnum.SPACE4LEADS_API) {

            baseurl = `${SystemConfig.getInstance().space4LeadsApiBaseUrl}/${config.url}`

            const space4LeadsIntegrationKeys = AppStateUtils.getCurrentFranchise()!.integrationKeys.space4Leads

            // Adiciona o HEADER para chamadas que não passaram o on-behalf-of no request. As chamadas de NPS do proprio clin passaram antes o behalf do clin
            if (ArrayUtils.isEmpty(config.headers) && !!space4LeadsIntegrationKeys)
                config.headers.push({ headerName: 'on-behalf-of', headerValue: space4LeadsIntegrationKeys.projectCode.toString() })

            if (!!space4LeadsIntegrationKeys)
                config.headers.push({ headerName: 'Authorization', headerValue: `Bearer ${space4LeadsIntegrationKeys.token}` })

        } else if (config.baseUrlType === DokfySystemApiEnum.DOKFY_API) {

            const token = await DokfyRequests._getDokfyAuthToken()
            if (!token)
                return 'token-invalido'
            config.headers.push(AuthUtils.getBearerAuthHeaderConfig(token))

            baseurl = `${SystemConfig.getInstance().dokfyBaseUrl}/${config.url}`
        }

        return baseurl
    }

    static onUnauthorized(): void {
        NotificationHelper.error('Sem autorização.')
    }

    static notifyApi(type: 'success' | 'error', title: string, msg: any): void {

        if (type === 'error')
            NotificationHelper.error(title, msg)
        else
            NotificationHelper.success(title, msg)
    }

}
