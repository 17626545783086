import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import React from 'react'
import styled from 'styled-components'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'
import {
    FinancialCategoryCreditEnum,
    FinancialCategoryCreditLabelEnum
} from 'main/modules/financial/services/financial-category/enums/FinancialCategoryCreditEnum'
import {
    FinancialCategoryDebitEnum,
    FinancialCategoryDebitLabelEnum
} from 'main/modules/financial/services/financial-category/enums/FinancialCategoryDebitEnum'

interface ICPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP, FinancialCategoryCreditEnum | FinancialCategoryDebitEnum> {
    categoryType: FinancialCategoryTypeEnum
}

/**
 * Componente para select de formas tipos de recorrencia.
 */
export function SelectCategoryTypeCP(props: ICPProps): JSX.Element {

    const OPTIONS: SelectOptionTP[] = Object.values(props.categoryType === FinancialCategoryTypeEnum.CREDIT ? FinancialCategoryCreditEnum : FinancialCategoryDebitEnum)
        .map((item, i) => ({
            key: `key-item-${i}`,
            label: props.categoryType === FinancialCategoryTypeEnum.CREDIT ? FinancialCategoryCreditLabelEnum[item] : FinancialCategoryDebitLabelEnum[item],
            value: item
        }))

    return (
        <SelectWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                label={props.label}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                formStateManager={props.formStateManager}
                selectedOptions={props.value ? [props.value] : undefined}
                fieldName={props.fieldName}
                fontSize={props.fontSize}
                isMultiple={false}
                options={OPTIONS}
                defaultOpen={false}
                onChange={props.onChange}
                width={props.width}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width:100%;
`
