import _ from 'lodash'
import { SubMenuCP } from 'main/common/components/menu/sub-menu/SubMenuCP'
import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { ObjectUtils } from 'main/common/utils/ObjectUtils'
import styled from 'main/config/theme/styledWithTheme'
import { PERSON_LIST_SCREEN_SUB_MENU_COLUMNS } from 'main/modules/people/components/person-list-screen-sider/inner/PersonListScreenSiderSubMenuColumns'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { ISegmentSearchRequestDTO } from 'main/modules/segment/services/dtos/request/ISegmentSearchRequestDTO'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { SegmentRequests } from 'main/modules/segment/services/SegmentRequests'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React, { useEffect, useState } from 'react'

const ITEMS_PER_PAGE = 8

const CLASS_SELECTED_SEGMENT = 'person_segment_selected'
const CLASS_DISABLED = 'person_segment_disabled'

interface IPersonListScreenSiderSubMenuICPProps {
    segmentType: SegmentTypeEnum
    onSearchStateChange: (isLoading: boolean) => void
}

/**
 * COMPONENTE
 * Submenu para exibir listas de segmentação de 01 determinado tipo.
 */
export const PersonListScreenSiderSubMenuICP = ComponentUtils.memoizeCp((props: IPersonListScreenSiderSubMenuICPProps): JSX.Element => {

    const [pagination, setPagination] = useState<TablePaginationTP>()
    const [segmentList, setSegmentList] = useState<ISegmentListItemResponseDTO[]>([])
    const [hasSearchBeenRan, setHasSearchBeenRan] = useState<boolean>(false)

    const screenContext = PersonContext.use()
    const searchRequest = useRequest<IGenericListResponseDTO<ISegmentListItemResponseDTO>>()

    const selectedSegment = screenContext.state.segment
    const isSelected = (selectedSegment?.type === props.segmentType)

    useEffect(init, [])
    useEffect(onSelectedSegmentChange, [selectedSegment])
    useEffect(onSearchRequestChange, [searchRequest.isAwaiting])

    function init(): void {
        if (isSelected)
            updateSegmentsList()
    }

    function updateSegmentsList(page?: number): void {

        const dto: ISegmentSearchRequestDTO = {
            type: props.segmentType,
            page: page ?? 1,
            itemsPerPage: ITEMS_PER_PAGE,
        }

        searchRequest.runRequest(SegmentRequests.getMany(dto))
    }

    function onSelectedSegmentChange(): void {

        if (!segmentList.length || !selectedSegment?.type)
            return

        if (selectedSegment.type !== props.segmentType)
            return screenContext.actions.setTypeToGoTo(selectedSegment.type)

        let isSegmentAdding = true // Determina se lista selecionada deve ser adicionada a este submenu
        let nextSegmentList: ISegmentListItemResponseDTO[] = []

        for (const [i, segment] of segmentList.entries()) {

            if (segment.code !== selectedSegment.code)
                continue

            isSegmentAdding = false
            if (_.isEqual(segment, selectedSegment))
                return // Nada a se fazer: Lista selecionada ja faz parte deste submenu & nao foi alterada

            // Atualiza valor de 01 registro que ja pertence a lista
            nextSegmentList = [...segmentList]
            nextSegmentList[i] = selectedSegment
            break
        }

        if (isSegmentAdding) // Insere novo item na pagina atual
            nextSegmentList = [...segmentList, selectedSegment]

        sortSegmentList(nextSegmentList)
        setSegmentList(nextSegmentList)
    }

    function sortSegmentList(nextSegmentList: ISegmentListItemResponseDTO[]): void {

        if (!selectedSegment)
            return

        if (nextSegmentList.length > (ITEMS_PER_PAGE + 1)) {
            console.warn(`PersonListScreenSiderSubMenuICP.${sortSegmentList.name}: Algo errado nao esta certo...`)
            return
        }

        // Re-ordena listas da pagina atual
        nextSegmentList.sort((seg1, seg2) => {
            if (SegmentUtils.isSpecialSegment(seg1.code))
                return -1
            if (SegmentUtils.isSpecialSegment(seg2.code))
                return 1
            return seg1.name.localeCompare(seg2.name)
        })

        if (nextSegmentList.length <= ITEMS_PER_PAGE)
            return

        // Remove 01 item para preservar limite de itens por pagina (paginacao)
        const isNewSegmentAtLastPosition = (nextSegmentList[ITEMS_PER_PAGE].code === selectedSegment.code)
        if (isNewSegmentAtLastPosition) // Remove penultimo item
            nextSegmentList.splice(ITEMS_PER_PAGE - 1, 1)
        else
            nextSegmentList.pop() // Remove ultimo item
    }

    function onSearchRequestChange(): void {
        props.onSearchStateChange(searchRequest.isAwaiting)
        if (RequestUtils.isValidRequestReturn(searchRequest, 'Erro ao tentar buscar listas de segmentação'))
            onSearchRequestSuccess()
    }

    function onSearchRequestSuccess(): void {

        const searchResponse = searchRequest.responseData
        if (!searchResponse)
            return

        const _segmentList = searchResponse.list ?? []
        setSegmentList(_segmentList)
        updatePagination(searchResponse.total, pagination?.current)
        handleIsSelectedSegmentHere(_segmentList)

        if (!hasSearchBeenRan)
            setHasSearchBeenRan(true)
    }

    function handleIsSelectedSegmentHere(_segmentList: ISegmentListItemResponseDTO[]): void {

        if (!isSelected)
            return

        let _selectedSegment: OrUndefTP<ISegmentListItemResponseDTO>

        if (selectedSegment?.code)
            _selectedSegment = _segmentList.find(segment => (segment.code === selectedSegment.code))

        const defaultSegmentCode = SegmentUtils.getDefaultSegmentCode()
        if (!_selectedSegment && !defaultSegmentCode)
            _selectedSegment = _segmentList.find(segment => (segment.code === defaultSegmentCode))

        if (!_selectedSegment)
            _selectedSegment = _segmentList[0]

        // if (!!_selectedSegment)
        //     selectSegment(_selectedSegment, 2)
    }

    function updatePagination(total?: number, current?: number): void {

        total = total ?? 0
        if (total <= ITEMS_PER_PAGE)
            return setPagination(undefined)

        setPagination({
            total,
            current: current ?? 1,
            pageSize: ITEMS_PER_PAGE,
        })

        if (!!current && current !== pagination?.current)
            updateSegmentsList(current)
    }

    function onTitleClick(): void {
        if (!hasSearchBeenRan)
            updateSegmentsList()
    }

    function selectSegment(_selectedSegment: ISegmentListItemResponseDTO): void {
        if (!screenContext.state.isLoadingSegment)
            screenContext.actions.setSegment(_selectedSegment)
    }

    return (
        <SubMenuCP
            onTitleClick={onTitleClick}
            title={SegmentUtils.getSegmentTypeName(props.segmentType)}
            {...ObjectUtils.getObjectsWithoutSomeProps<IPersonListScreenSiderSubMenuICPProps>(props, ['segmentType'])} // eslint-disable-line react/jsx-props-no-spreading
        >
            { /* Fragment necessário para eliminar warnings. */ }
            { /* eslint-disable-next-line react/jsx-no-useless-fragment */ }
            <>
                <TableContainerSCP className={screenContext.state.isLoadingSegment ? CLASS_DISABLED : ''}>
                    <TableCPOLD2
                        data={segmentList}
                        showHeader={false}
                        showLoadingAsSkeleton={false}
                        loading={searchRequest.isAwaiting}
                        pagination={pagination}
                        columns={PERSON_LIST_SCREEN_SUB_MENU_COLUMNS}
                        onRow={record => ({ onClick: (): void => selectSegment(record) })}
                        rowClassName={(record): string => ((record.code === selectedSegment?.code) ? CLASS_SELECTED_SEGMENT : '')}
                        onPaginationChange={({ total, current }): void => updatePagination(total, current)}
                    />
                </TableContainerSCP>
            </>
        </SubMenuCP>
    )
})

const TableContainerSCP = styled.div`

    margin-left: 30px;
    margin-right: 10px;

    .ant-table {

        font-size: 12px;

        tr,
        td {
            border-bottom: none;
        }

        tr:hover td,
        tr.${CLASS_SELECTED_SEGMENT} {
            color: ${props => props.theme.primaryColor};
            font-weight: bold;
        }
    }

    &:not(.${CLASS_DISABLED}) .ant-table {
        tr:hover td,
        tr.${CLASS_SELECTED_SEGMENT} {
            cursor: pointer;
        }
    }

    &.${CLASS_DISABLED} .ant-table {
        tr:hover td,
        tr.${CLASS_SELECTED_SEGMENT} {
            opacity: .6;
            cursor: wait;
        }
    }
`
