import { LoadingIconCP } from 'main/common/components/icons/LoadingIconCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { FlexCP } from '../flex/FlexCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { HelpTP } from 'submodules/nerit-framework-ui/common/components/help/inner/HelpTP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

const SELECTED_CLASS_NAME = 'selected'

interface IScpProps {
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
}

interface IResumeWidgetCardCPProps extends IScpProps {
    icon: JSX.Element
    value: string
    onClick?: () => void
    extra?: string | JSX.Element
    loading?: boolean
    selected?: boolean
    help?: HelpTP
}

export function ResumeWidgetCardCP(props: IResumeWidgetCardCPProps): JSX.Element {

    return (
        <WrapperSCP
            onClick={props.onClick}
            className={props.selected ? SELECTED_CLASS_NAME : ''}
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 10}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 10}
        >
            <ConditionalRenderCP shouldRender={props.selected ?? false}>
                <FilterIconWrapper>
                    <IconCP antIcon={'filter'}/>
                </FilterIconWrapper>
            </ConditionalRenderCP>

            <ContentSCP>
                <TextCP text={props.value} size={'extraLarge'} strong={true}/>
                <FlexCP alignItems={'center'}>
                    <IconSmallScreenSCP>
                        {
                            props.loading
                                ?
                                <LoadingIconCP/>
                                :
                                props.icon
                        }
                    </IconSmallScreenSCP>
                    <>
                        { props.extra }
                    </>
                    {
                        !!props.help &&
                        <BasicStyleWrapperCP margin={{ left: 10 }}>
                            <HelpCP
                                text={props.help.text}
                                type={props.help.type}
                                showOnlyIcon={true}
                            />
                        </BasicStyleWrapperCP>
                    }
                </FlexCP>
            </ContentSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`
  
    position: relative;
  
    &.${SELECTED_CLASS_NAME} {
      border: solid 1px ${props => props.theme.primaryColor};
    }

    align-items: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    padding: 10px;
`

const ContentSCP = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr; 
`

const IconSmallScreenSCP = styled.div`
    margin-right: 5px;

    svg {
        font-size: 1em !important;
    }
`

const FilterIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0px;
  background: ${props => props.theme.primaryColor};
  padding: 0 2px;
  color: #FFF;
  border-radius: 3px;
`
