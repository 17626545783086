import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { ISessionScheduleRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISessionScheduleRequestDTO'
import { IsOptional } from 'class-validator/decorator/decorators'
import { RecurrencePeriodEnum } from 'main/modules/financial/services/financial-transactions/enums/RecurrencePeriodEnum'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class ScheduleSessionsFormModel extends FormModel {

    @IsRequired()
    userCode: number

    @IsNotEmpty()
    customerCode: number

    @IsNotEmpty()
    sessions: ISessionScheduleRequestDTO[] = []

    @IsOptional()
    comment?: string

    @IsOptional()
    isRecurrent?: boolean

    @IsOptional()
    recurrencePeriod?: RecurrencePeriodEnum

    @IsOptional()
    shouldShrink?: boolean

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
