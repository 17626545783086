import React from 'react'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import styled from 'styled-components'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'

export type OpportunityListEvaluationViewModeTP = 'list' | 'calendar'

interface IOpportunityEvaluationScreenContentICPProps {
    view: SalesFunnelOpportunityViewEnum
    evaluationViewMode: OpportunityListEvaluationViewModeTP
    onChangeEvaluationViewMode: (evaluationViewMode: OpportunityListEvaluationViewModeTP) => void
}

/**
 * Seletor da visao da oportunidade em tabela ou calendario
 */
export function RadioGroupEvaluationViewCP(props: IOpportunityEvaluationScreenContentICPProps): JSX.Element {

    const viewsToShowCalendar = [
        SalesFunnelOpportunityViewEnum.EVALUATION_TODAY,
        SalesFunnelOpportunityViewEnum.EVALUATION_LOST,
        SalesFunnelOpportunityViewEnum.EVALUATION_DELAYED,
        SalesFunnelOpportunityViewEnum.EVALUATION_PLANNED,
        SalesFunnelOpportunityViewEnum.EVALUATION_CONVERTED,
    ]

    if (!viewsToShowCalendar.includes(props.view))
        return <></>

    return (
        <SwitchWrapperSCP>
            <RadioGroupCP<OpportunityListEvaluationViewModeTP>
                selected={props.evaluationViewMode}
                buttonHeight={32}
                buttonWidth={90}
                secondary={true}
                onChange={props.onChangeEvaluationViewMode}
                type={'button'}
                options={[
                    {
                        content: 'Agenda',
                        value: 'calendar'
                    }, {
                        content: 'Lista',
                        value: 'list'
                    }
                ]}
            />
        </SwitchWrapperSCP>
    )
}

const SwitchWrapperSCP = styled.div`
    //width: 100%;
    //display: flex;
    //justify-content: center;  
`
