export enum SaleOriginEnum {
    FACE_TO_FACE = 'FACE_TO_FACE',
    SITE = 'SITE',
    REMOTE = 'REMOTE',
}

export enum SaleOriginLabelEnum {
    FACE_TO_FACE = 'Presencial',
    SITE = 'Site',
    REMOTE = 'Telefone',
}