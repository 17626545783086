import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IFunnelStepSaveRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelStepSaveRequestDTO'
import { IFunnelSaveRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelSaveRequestDTO'
import { IFunnelStepSummaryRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelStepSummaryRequestDTO'

/**
 * REQUISIÇÕES relacionadas ao FUNIL de OPORTUNIDADES.
 *
 * !IMPORTANTE: Por enquanto teremos apenas um FUNIL no sistema, dessa forma a api está tratando e não passamos nenhum código.
 * Quando for necessário ter mais de um, faremos as modificações.
 */
export class FunnelRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/funnels`

    static funnelStepsSummaryConfig = (dto: IFunnelStepSummaryRequestDTO): RequestConfigTP => ({
        url: `${FunnelRequests._MODULE_BASE}/steps/summary`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static searchStepsConfig = (): RequestConfigTP => ({
        url: `${FunnelRequests._MODULE_BASE}/steps`,
        method: HttpMethodEnum.GET,
    })

    static createStepConfig = (dto: IFunnelStepSaveRequestDTO): RequestConfigTP => ({
        url: `${FunnelRequests._MODULE_BASE}/steps`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateConfig = (dto: IFunnelSaveRequestDTO): RequestConfigTP => ({
        url: `${FunnelRequests._MODULE_BASE}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteStep = (stepCode: number): RequestConfigTP => ({
        url: `${FunnelRequests._MODULE_BASE}/steps/${stepCode}`,
        method: HttpMethodEnum.DELETE,
    })

}
