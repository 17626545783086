import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IAppointmentSessionDetailsData } from 'main/modules/scheduler/components/drawer-appointment-session-details/inner/IAppointmentSessionDetailsData'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ModalSessionHistoryCP } from 'main/modules/scheduler/components/modal-session-history/ModalSessionHistoryCP'

interface ICPProps {
    appointmentDetailsData: IAppointmentSessionDetailsData
    onPresenceConfirmation: () => void
}

/**
 * Detalhes de um agendamento
 */
export function AppointmentDateDetailsICP(props: ICPProps): JSX.Element {

    const [isSessionHistoryModalVisible, setIsSessionHistoryModalVisible] = useState<boolean>(false)
    const [isPresenceConfirmed, setIsPresenceConfirmed] = useState<boolean>(props.appointmentDetailsData.hasConfirmed)

    const confirmPresenceRequest = useRequest()
    useEffect(onConfirmPresenceRequestChange, [confirmPresenceRequest.isAwaiting])

    /**
     * Trata mudanca de valor da confirmacao de presenca.
     */
    function onChangePresenceConfirmation(checked: boolean): void {
        setIsPresenceConfirmed(checked)
        confirmPresenceRequest.runRequest(SchedulerRequests.confirmPresence(props.appointmentDetailsData.sessionCodes))
    }

    /**
     * Retorno ao confirmar presenca.
     */
    function onConfirmPresenceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(confirmPresenceRequest, 'Erro alterar status de presença'))
            return

        NotificationHelper.success('Pronto!', 'Status de presença alterado com sucesso!')
        props.onPresenceConfirmation()
    }

    return (
        <>
            <DateDetailsSCP>
                <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                    <TextCP
                        icon={'schedule'}
                        text={`
                    ${DateUtilsOLD.getFormatted(moment(props.appointmentDetailsData.beginDate).toDate(), DateFormatEnum.DATE_WRITTEN_IN_FULL)} às  
                    ${DateUtilsOLD.getFormatted(moment(props.appointmentDetailsData.endDate).toDate(), DateFormatEnum.TIME_H_M)}
                `}
                    />
                    <SwitchCP
                        textOutside={'Presença confirmada '}
                        isChecked={isPresenceConfirmed}
                        onChange={(checked) => onChangePresenceConfirmation(checked)}
                        isTextInside={false}
                        disabled={
                            isPresenceConfirmed ||
                            props.appointmentDetailsData.sessionStatus === SessionStatusEnum.CONCLUDED ||
                            props.appointmentDetailsData.sessionStatus === SessionStatusEnum.NO_SHOW
                        }
                    />
                </FlexCP>
                <FlexCP justifyContent={'space-between'}>
                    {
                        !SystemUtils.isEmpty(props.appointmentDetailsData.sessions) && !!props.appointmentDetailsData.sessions[0].schedule?.scheduleUser &&
                        <TextCP text={`Agendado por ${props.appointmentDetailsData.sessions[0].schedule?.userCreator.name}`} color={ThemeAnt.darkGray} fontStyle={'italic'}/>
                    }
                    <TextCP
                        onClick={() => setIsSessionHistoryModalVisible(true)}
                        text={'Histórico de Sessões'}
                        style={{ textDecoration: 'underline', color: ThemeAnt.primaryColor, cursor: 'pointer' }}
                    />
                </FlexCP>
            </DateDetailsSCP>

            <ModalSessionHistoryCP
                visible={isSessionHistoryModalVisible}
                onCancel={() => setIsSessionHistoryModalVisible(false)}
                customerCode={props.appointmentDetailsData.customer.code}
            />
        </>
    )
}

// Estilo para a área de destaque da data do agendamento
const DateDetailsSCP = styled.div`
  border: 2px dashed ${(props: StyledComponentProps) => props.theme.gray};
  border-radius: 8px;
  padding: 8px;
`
