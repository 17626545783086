import { TextCP } from 'main/common/components/text/TextCP'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { FontSizeTP } from 'main/config/theme/ThemeTypes'
import React from 'react'
import { OpenNewTabIconCP } from 'main/common/components/icons/OpenNewTabIconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'

interface ILinkBoxICPProps {
    link: string
    message?: string
    onCopy?: () => void
    fontSize?: FontSizeTP
    shouldShowOpenNewTab?: boolean
}

/**
 * COMPONENTE Caixa com um link para ser copiado.
 */
export function LinkBoxICP(props: ILinkBoxICPProps): JSX.Element {
    return (
        <WrapperSCP>
            <LinkWrapperSCP>
                <TextCP
                    wrap={true}
                    elipsis={true}
                    copyable={true}
                    onCopy={props.onCopy}
                    text={props.link}
                    size={props.fontSize}
                />

                {
                    !!props.shouldShowOpenNewTab &&
                    <TooltipCP
                        text={'Abrir link em nova aba'}
                    >
                        <OpenNewTabWrapperSCP>
                            <a href={props.link} target={'_blank'} rel={'noopener noreferrer'}>
                                <OpenNewTabIconCP size={12}/>
                            </a>
                        </OpenNewTabWrapperSCP>
                    </TooltipCP>
                }
            </LinkWrapperSCP>

            {
                !!props.message &&
                <MessageSCP>{props.message}</MessageSCP>
            }
        </WrapperSCP>
    )
}

const OpenNewTabWrapperSCP = styled.div`
  padding: 1px 0 0 5px;
`

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LinkWrapperSCP = styled.div`

  display: flex;

    border: 1px dashed;
    padding: 15px;
    border-radius: 4px;
    font-weight: bold;
    transition: color .3s, border-color .3s, background-color .3s;

    &:hover {
        color: ${ThemeAnt.btnPrimaryBg};
        border-color: ${ThemeAnt.black};
        background: rgba(0, 0 , 0, .015);
    }
`

const MessageSCP = styled.p`
    margin-top: 1rem;
    font-size: .8rem;
`
