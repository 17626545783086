import React, { useEffect } from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { MktTransactionalCollpaseItemICP } from 'main/modules/marketing/components/collapse-mkt-transactionals/inner/MktTransactionalCollpaseItemICP'
import { IMarketingTransactionalResponseDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/responses/IMarketingTransactionalResponseDTO'
import {
    TransactionalTriggerMomentTypeEnum,
    TransactionalTriggerMomentTypeLabelEnum
} from 'main/modules/marketing/components/select-transactional-trigger-moment-type/inner/TransactionalTriggerMomentTypeEnum'
import { TagsCP } from 'main/common/components/tag/TagsCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MarketingTransactionalRequests } from 'main/modules/marketing/services/marketing-transactional/MarketingTransactionalRequests'
import { MarketingTransactionalEventEnum } from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'

interface ICPProps {
    mktTransactional: IMarketingTransactionalResponseDTO
    onChangeData: () => void
    onEdit: () => void
    event: MarketingTransactionalEventEnum
}

/**
 * Configuracoes de automacao de marketing.
 */
export function CollapseMktTransactionalsCP(props: ICPProps): JSX.Element {

    const deleteMktTransactionalRequest = useRequest<void>('none')
    useEffect(onDeleteMktTransactionalRequestChange, [deleteMktTransactionalRequest.isAwaiting])

    /**
     * Remove.
     */
    async function deleteMktTransactional(): Promise<void> {
        deleteMktTransactionalRequest.runRequest(await MarketingTransactionalRequests.delete(props.mktTransactional.code))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteMktTransactionalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteMktTransactionalRequest, 'Não foi a automação', true))
            return

        props.onChangeData()
    }

    /**
     * Define o label baseado nas configuracoes.
     */
    function getLabel(transactional: IMarketingTransactionalResponseDTO): string {

        if (transactional.triggerMomentType === TransactionalTriggerMomentTypeEnum.AT_MOMENT)
            return 'No momento do evento'

        if (transactional.triggerMomentQuantity === 0)
            return 'No dia do evento'

        return `${Math.abs(transactional.triggerMomentQuantity)} ${TransactionalTriggerMomentTypeLabelEnum[transactional.triggerMomentType]} ${transactional.triggerMomentQuantity > 0 ? 'depois' : 'antes'}`.toLowerCase()
    }

    /**
     * Obtem array de canais configurados.
     */
    function getChannels(transactional: IMarketingTransactionalResponseDTO): string[] {

        const channels: string[] = []

        if (!!transactional.emailHtml)
            channels.push('E-mail')

        if (!!transactional.smsText)
            channels.push('SMS')

        if (!!transactional.whatsappText)
            channels.push('WhatsApp')

        return channels
    }

    return (
        <CollapseCP
            panels={[{
                title: <TextCP text={getLabel(props.mktTransactional)} wrap={true}/>,
                extra:
                    <FlexCP justifyContent={'flex-end'}>
                        <TagsCP texts={getChannels(props.mktTransactional)}/>
                        <ButtonCP
                            icon={'edit'}
                            type={'ghost'}
                            marginRight={5}
                            marginLeft={15}
                            size={'small'}
                            onClick={props.onEdit}
                        />
                        <ButtonCP
                            icon={'delete'}
                            type={'danger'}
                            size={'small'}
                            confirmMsg={'Você tem certeza que deseja excluir esse evento?'}
                            onClick={deleteMktTransactional}
                            loading={deleteMktTransactionalRequest.isAwaiting}
                        />
                    </FlexCP>,
                content:
                    <MktTransactionalCollpaseItemICP
                        mktTransactional={props.mktTransactional}
                        onSave={props.onChangeData}
                        event={props.event}
                    />
            }]}
        />
    )

}
