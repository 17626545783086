import { ColumnProps } from 'antd/lib/table'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import React from 'react'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputEditableCP } from 'main/common/components/form-fields/input-editable/InputEditableCP'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { ICreateChargeInstallment } from 'main/modules/sale/components/table-create-charge-installments/inner/ICreateChargeInstallment'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import moment, { Moment } from 'moment'

export const CreateChargeInstallmentsTableUtils = {

    getTableColumns(
        totalInstallments: number,
        expirationColumnDisabled: boolean,
        valueColumnDisabled: boolean,
        onChangeData?: (parcelNumberSelected: number, newDate?: Date, newPaymentMethod?: PaymentMethodEnum, newValue?: string, installmentCode?: number) => void,
    ): Array<ColumnProps<ICreateChargeInstallment>> {

        return [
            {
                title: 'Parcela',
                key: 'totalInstallments',
                align: 'center',
                render: (text, parcel) => `${parcel.installmentNumber}/${totalInstallments}`
            },
            {
                title: 'Vencimento',
                key: 'expirationDate',
                align: 'center',
                render: (text, parcel) => (
                    <InputWrapperSCP>
                        <ConditionalRenderCP shouldRender={expirationColumnDisabled}>
                            { DateUtils.getFormatted(parcel.expirationDate, DateFormatEnum.BR_WITHOUT_TIME) }
                        </ConditionalRenderCP>
                        <ConditionalRenderCP shouldRender={!expirationColumnDisabled}>
                            <InputCP
                                value={moment(parcel.expirationDate)}
                                allowClear={false}
                                type={'date'}
                                required={true}
                                onChange={(selectedDate: Moment) => {
                                    if (onChangeData)
                                        onChangeData(parcel.installmentNumber, selectedDate.toDate(), undefined, undefined, parcel.code)
                                }}
                            />
                        </ConditionalRenderCP>
                    </InputWrapperSCP>
                )
            },
            {
                title: 'Valor',
                key: 'value',
                align: 'right',
                width: 150,
                render: (text, parcel) => (
                    <>
                        {
                            valueColumnDisabled
                                ?
                                MaskUtils.applyMoneyMask(parcel.value)
                                :
                                <InputEditableCP
                                    value={parcel.value}
                                    onConfirm={(val) => {
                                        if (onChangeData)
                                            onChangeData(parcel.installmentNumber, undefined, undefined, val, parcel.code)
                                    }}
                                />
                        }
                    </>
                )
            },
        ]
    },

}

const InputWrapperSCP = styled.div`
  display: flex;

  .ant-input, .ant-input:focus {
    border: 0;
    padding: 0;
    display: block;
    text-align: center;
  }

  .ant-table-row-cell-break-word {
    padding-top: 18px;
  }

  .ant-calendar-picker {
    padding-right: 5px;
  }

  .ant-select, .ant-select-selection {
    border-bottom: 0 !important;
  }

  .ant-row, .ant-select {
    margin: 0;
  }

  .ant-form-explain {
    display: contents;
  }
`
