import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import styled from 'styled-components'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ScheduleRequests } from 'submodules/neritclin-sdk/services/schedule/ScheduleRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { SearchScheduleAppointmentsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/SearchScheduleAppointmentsRequestDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { CalendarSummaryResponseDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/responses/CalendarSummaryResponseDTO'

interface ICPProps {
    filtersFormStateManager: IFormStateManager<FormFilterCalendarEventsFormModel>
    shouldLoadSummary: number
}

/**
 */
export function CalendarEventsSummaryICP(props: ICPProps): JSX.Element {

    const [summary, setSummary] = useState<CalendarSummaryResponseDTO[]>()
    const getSummaryRequest = useRequest<ListResponseDTO<CalendarSummaryResponseDTO>>()
    useEffect(onGetSummaryRequestChange, [getSummaryRequest.isAwaiting])

    useEffect(init, [props.filtersFormStateManager.getFieldValue('dateInterval'), props.shouldLoadSummary])

    /**
     */
    function init(): void {

        const formValues = props.filtersFormStateManager.getFormValues()
        if (!formValues)
            return

        const dateInterval: IDateRangeFilter = props.filtersFormStateManager.getFieldValue('dateInterval')
        if (!dateInterval?.beginDate || !dateInterval?.endDate)
            return

        const searchDto: SearchScheduleAppointmentsRequestDTO = {
            dateRange: formValues.dateInterval,
            userCodes: formValues.userCodes,
            treatmentGroupCodes: formValues.treatmentGroupCodes,
            showNoShow: formValues.showNoShow,
        }
        getSummaryRequest.runRequest(ScheduleRequests.caledarSummary(searchDto))
    }

    /**
     */
    function onGetSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSummaryRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setSummary(getSummaryRequest.responseData!.list)
    }

    return (
        <WrapperSCP>
           
            <WidgetsRowCP loading={getSummaryRequest.isAwaiting}>
                {
                    summary?.map((daySummary) => (
                        <CardWidgetOnlyValuesCP
                            main={{
                                title: DateUtils.formatDate(daySummary.date, DateFormatEnum.BR_DAY_MONTH),
                                icon: <IconICP iconName={'calendar'}/>,
                            }}
                            initialValue={daySummary.values}
                        />
                    ))
                }
            </WidgetsRowCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding-left: 10px;
    width: calc(100vw - 350px);
    overflow-x: scroll;

  .card-dashboard-widget {
    margin: 0 5px 0 5px !important;

    .card-dashboard-widget-title-parent {
      display: flex;
      justify-content: center;
      font-weight: bold;
      margin-bottom: 0 !important;
    }
    
    .card-dashboard-widget-footer {
      margin-top: 0 !important;
      
      > div {
        padding: 3px !important;
      }
    }
  }
`
