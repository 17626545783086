import React, { useEffect, useState } from 'react'
import { ChartFunnelCP, FunnelChartDataTP } from 'main/common/components/chart/ChartFunnelCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ChartSalesFunnelRightCardsICP } from 'main/modules/reports/components/chart-sales-funnel/inner/ChartSalesFunnelRightCardsICP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IconICP } from 'main/common/components/icons/inner/IconICP'
import { StatisticCP } from 'main/common/components/statistic/StatisticCP'
import styled from 'main/config/theme/styledWithTheme'
import { ChartSalesFunnelArrowsICP } from 'main/modules/reports/components/chart-sales-funnel/inner/ChartSalesFunnelArrowsICP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ISalesFunnelIndicatorsResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISalesFunnelIndicatorsResponseDTO'
import { SaleReportsRequests } from 'main/modules/reports/services/sale-reports/SaleReportsRequests'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { ISalesFunnelIndicatorsRequestDTO } from 'main/modules/reports/services/sale-reports/dtos/requests/ISalesFunnelIndicatorsRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

interface IChartSalesFunnelCPProps {
    height: number
    formStateManager: IFormStateManager<ReportsCommonFiltersFormModel>
    shouldLoadReport: boolean
    onReportLoaded: () => void
}

/**
 * Grafico do funil de vendas.
 */
export function ChartSalesFunnelCP(props: IChartSalesFunnelCPProps): JSX.Element {

    const [reportData, setReportData] = useState<ISalesFunnelIndicatorsResponseDTO>()
    const [chartData, setChartData] = useState<FunnelChartDataTP[]>([])

    const getReportDataRequest = useRequest<ISalesFunnelIndicatorsResponseDTO>()
    useEffect(onGetReportDataRequestChange, [getReportDataRequest.isAwaiting])

    useEffect(init, [props.shouldLoadReport])

    /**
     * Monta a tela.
     */
    function init(): void {

        if (!props.shouldLoadReport)
            return

        const formValues = props.formStateManager.getFormValues()

        let filtersDto: ISalesFunnelIndicatorsRequestDTO = {}
        if (formValues) {
            filtersDto = {
                dateRange: {
                    beginDate: formValues.dateRange?.beginDate ? DateUtils.getFormatted(formValues.dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
                    endDate: formValues.dateRange?.endDate ? DateUtils.getFormatted(formValues.dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
                },
                franchiseCodes: formValues.franchisesCodes,
                userSellerCode: formValues.userSellerCode
            }
        }

        getReportDataRequest.runRequest(SaleReportsRequests.salesFunnelIndicatorsReport(filtersDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetReportDataRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReportDataRequest, 'Erro obter relatório'))
            return

        const resultDto = getReportDataRequest.responseData!
        setReportData(resultDto)

        // Transforma os dados retornados para montar o grafico
        const _chartData: FunnelChartDataTP[] = [
            {
                id: OpportunityTypeEnum.LEAD.toString(),
                value: resultDto.leadsData.total,
                label: 'Leads'
            },
            {
                id: OpportunityTypeEnum.EVALUATION.toString(),
                value: resultDto.evaluationData.total,
                label: 'Avaliações'
            },
            {
                id: 'sales',
                value: resultDto.salesData.totalValid,
                label: 'Vendas'
            }
        ]

        setChartData(_chartData)
        props.onReportLoaded()
    }

    if (!reportData) {
        return (
            <>
                <LoadingOverlayCP show={getReportDataRequest.isAwaiting}/>
                <EmptyCP description={'Nenhum dado encontrato para os filtros selecionados'}/>
            </>
        )
    }

    return (
        <FlexCP justifyContent={'center'}>
            <div>
                <FunnelCardSCP>
                    <ChartFunnelCP
                        data={chartData}
                        height={props.height}
                        width={500}
                    />
                    <ChartSalesFunnelArrowsICP
                        mainConversionRate={reportData.mainConversionRate}
                        leadToEvaluationConversionRate={reportData.leadToEvaluationConversionRate}
                        evaluationToSaleConversionRate={reportData.evaluationToSaleConversionRate}
                    />
                </FunnelCardSCP>
                <FlexCP>
                    <FooterCardSCP>
                        <StatisticCP
                            value={reportData.salesData.totalValid}
                            label={'Vendas Ativas'}
                            icon={<IconICP iconName={'like'} size={30} color={ThemeAnt.successColor}/>}
                            valueUnit={'vendas'}
                            loading={false}
                            footer={`Valor total: ${MaskUtils.applyMoneyMask(reportData.salesData.totalValidValue)}`}
                        />
                    </FooterCardSCP>
                    <FooterCardSCP>
                        <StatisticCP
                            value={reportData.salesData.totalCancelled}
                            label={'Vendas Canceladas'}
                            icon={<IconICP iconName={'dislike'} size={30} color={ThemeAnt.errorColor}/>}
                            valueUnit={'vendas'}
                            loading={false}
                            footer={`Tx. de Cancelamento: ${reportData.salesData.cancelationRatio}%`}
                        />
                    </FooterCardSCP>
                </FlexCP>
            </div>
            <ChartSalesFunnelRightCardsICP data={reportData}/>
        </FlexCP>
    )
}

const FunnelCardSCP = styled.div`
  position: relative;
`

const FooterCardSCP = styled.div`
    flex: 1;
    
    .ant-card {
        margin: 10px !important;
    }
`
