import React, { useEffect, useState } from 'react'
import { FinancialTransactionFiltersCP } from 'main/modules/financial/components/financial-transaction-filters/FinancialTransactionFiltersCP'
import styled from 'styled-components'
import {
    FinancialTransactionTypeEnum,
    FinancialTransactionTypeLabelEnum
} from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { FinancialTransactionsSummaryHeaderCP } from 'main/modules/financial/components/financial-transactions-summary-header/FinancialTransactionsSummaryHeaderCP'
import { TableFinancialTransactionsCreditCP } from 'main/modules/financial/components/table-financial-transactions-credit/TableFinancialTransactionsCreditCP'
import { TableFinancialTransactionsDebitCP } from 'main/modules/financial/components/table-financial-transactions-debit/TableFinancialTransactionsDebitCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { DrawerFinancialTransactionCP } from 'main/modules/financial/components/drawer-financial-transaction/DrawerFinancialTransactionCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TotalAccountBalanceCP } from 'main/modules/financial/components/total-account-balance/TotalAccountBalanceCP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface IFinancialTransactionsScreenContentCPProps {
    type: FinancialTransactionTypeEnum
}

/**
 * Componente que mostra faturas e filtros
 */
export function ScreenContentFinancialTransactionsCP(props: IFinancialTransactionsScreenContentCPProps): JSX.Element {

    const [isExpenseDrawerVisible, setIsExpenseDrawerVisible] = useState<boolean>(false)
    const [selectedFinancialTransactionCode, setSelectedFinancialTransactionCode] = useState<number>()

    const [filters, setFilters] = useState<IFinancialTransactionSearchRequestDTO>({})
    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)
    const [shouldLoadSummary, setShouldLoadSummary] = useState<boolean>(false)
    const [shouldLoadBalance, setShouldLoadBalance] = useState<boolean>(true)

    useEffect(init, [props.type])

    /**
     * Inicializa a tela.
     */
    function init(): void {
        const currentMonthInterval = DateUtils.getFirstAndLastDayOfMonth()
        setFilters({
            expirateInterval: {
                beginDate: DateUtils.getFormatted(currentMonthInterval.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                endDate: DateUtils.getFormatted(currentMonthInterval.endDate, DateFormatEnum.US_WITHOUT_TIME)
            },
            franchiseCodes: AppStateUtils.getCurrentFranchise()!.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code]
        })

        setShouldLoadSummary(true)
        setShouldLoadReport(true)
    }

    return (
        <>
            <HeaderCP title={`${FinancialTransactionTypeLabelEnum[props.type]}s`}>
                <FlexCP justifyContent={'flex-end'} alignItems={'center'}>
                    <TotalAccountBalanceCP
                        shouldReloadBalance={shouldLoadBalance}
                        onBalanceLoaded={() => setShouldLoadBalance(false)}
                    />

                    <HeaderButtonICP
                        onClick={() => setIsExpenseDrawerVisible(true)}
                        icon={'plus'}
                        label={`Nova ${FinancialTransactionTypeLabelEnum[props.type]}`}
                    />
                </FlexCP>
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <ContentWrapperSCP>
                    <ContentSCP>

                        <FinancialTransactionFiltersCP
                            currentFilters={filters}
                            onChangeFilters={(dto) => {
                                setFilters(dto)
                                setShouldLoadReport(true)
                                setShouldLoadSummary(true)
                            }}
                            showSaleDateFilters={props.type === FinancialTransactionTypeEnum.CREDIT}
                            showPaymentDateFilters={true}
                            showReferenceIntervalFilter={false}
                            showPaymentPartner={props.type === FinancialTransactionTypeEnum.CREDIT}
                            labelPersonFilter={props.type === FinancialTransactionTypeEnum.DEBIT ? 'Fornecedor' : undefined}
                            loading={shouldLoadReport || shouldLoadSummary}
                        />

                        <FinancialTransactionsSummaryHeaderCP
                            transactionsType={props.type}
                            currentFilters={filters}
                            onChangeFilters={(dto) => {
                                setFilters(dto)
                                setShouldLoadReport(true)
                            }}
                            shouldReloadList={shouldLoadSummary}
                            onReportLoaded={() => setShouldLoadSummary(false)}
                        />

                        <ConditionalRenderCP shouldRender={props.type === FinancialTransactionTypeEnum.CREDIT}>
                            <TableFinancialTransactionsCreditCP
                                onSelectTransaction={(code) => {
                                    setIsExpenseDrawerVisible(true)
                                    setSelectedFinancialTransactionCode(code)
                                }}
                                filters={filters}
                                setFilters={setFilters}
                                shouldReloadList={shouldLoadReport}
                                onReportLoaded={() => setShouldLoadReport(false)}
                                onUpdateAnyData={() => {
                                    setShouldLoadBalance(true)
                                    setShouldLoadSummary(true)
                                    setShouldLoadReport(true)
                                }}
                            />
                        </ConditionalRenderCP>

                        <ConditionalRenderCP shouldRender={props.type === FinancialTransactionTypeEnum.DEBIT}>
                            <TableFinancialTransactionsDebitCP
                                filters={filters}
                                setFilters={setFilters}
                                shouldReloadList={shouldLoadReport}
                                onSelectTransaction={(code) => {
                                    setIsExpenseDrawerVisible(true)
                                    setSelectedFinancialTransactionCode(code)
                                }}
                                onReportLoaded={() => setShouldLoadReport(false)}
                                onUpdateAnyData={() => {
                                    setShouldLoadBalance(true)
                                    setShouldLoadSummary(true)
                                    setShouldLoadReport(true)
                                }}
                            />
                        </ConditionalRenderCP>

                    </ContentSCP>
                </ContentWrapperSCP>
            </LayoutSubmenuContentCP>

            <DrawerFinancialTransactionCP
                type={props.type}
                code={selectedFinancialTransactionCode}
                visible={isExpenseDrawerVisible}
                onClose={(dataChanged) => {
                    setShouldLoadReport(dataChanged ?? false)
                    setIsExpenseDrawerVisible(false)
                    setSelectedFinancialTransactionCode(undefined)
                    setShouldLoadBalance(true)
                }}
            />
        </>
    )
}

const ContentSCP = styled.div`
    width: 100%;
`

const ContentWrapperSCP = styled.div`
    margin-top: 25px;
    display: flex;
    overflow-x: auto;
    justify-content: center;
`
