import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'

interface IShotListItemStatisticDataICPProps {
    icon: JSX.Element
    percent: number
    total: number
    label: string
}

/**
 *
 *
 * @author renatofs
 */
export function ShotListItemStatisticDataICP(props: IShotListItemStatisticDataICPProps): JSX.Element {

    return (
        <ColumnSCP>
            <ContentWrapper>
                <IconSCP>
                    {props.icon}
                </IconSCP>
                <PercentageSCP>
                    <h1>{`${Math.ceil(props.percent)}%`}</h1>
                    <span>{`${MaskUtils.applyNumberMask(props.total, 0)} ${props.label}`}</span>
                </PercentageSCP>
            </ContentWrapper>
            <ProgressCP
                percent={props.percent}
                showInfo={false}
            />
        </ColumnSCP>
    )
}

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 200px;
    align-items: center;
`

const ColumnSCP = styled.div`

    display: flex;
    flex-direction: column;
    padding: 10px;
    /* align-items: center; */
    /* border-left: 1px solid #dedede; */
    background: #f5f5f5;
    justify-content: center;

`

const IconSCP = styled.div`
    border: 1px solid #a9a9a9;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

`

const PercentageSCP = styled.div`

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    width: 90%;

    span {
        font-weight: bold;
        flex-wrap: nowrap;
    }

    h1 {
        font-weight: 800;
        border-bottom: 1px solid rgba(222, 222, 222, 0.8);
        /* padding: 30px 0 10px; */
        font-size: 1.4rem;
        margin-bottom: 5px;
        padding-bottom: 5px;
      }
`
