import React from 'react'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { AuthRoutes } from 'main/modules/auth/AuthRoutes'
import { PublicRouter } from 'main/modules/app/routes/PublicRouter'

/**
 * Relacao de Links publicos
 *
 * Acessar o dev-test passando ?publicLinks = true
 */
export function ScreenContentDevTestPublicLinks(): JSX.Element {

    return (
        <ContentCP overflowVertical={true}>
            <WrapperSCP>

                <div>

                    <ButtonCP onClick={() => NeritFrameworkRoutingHelper.openInNewTab(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(`${AuthRoutes.USER_LOGIN}?forceLogin=true`))}>
                        Forçar Login
                    </ButtonCP>

                    <ButtonCP onClick={() => NeritFrameworkRoutingHelper.openInNewTab(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(`${PublicRouter.UPDATE_CHARGE_PAYMENT_DATA.replace(':token', '1243')}`))}>
                        Tela Atualizar Dados de Pagamento
                    </ButtonCP>

                </div>

                <div>
                </div>
            </WrapperSCP>
        </ContentCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  button {
    width: 400px;
    margin-top: 20px;

    span {
      width: 100%;
    }
  }
`
