import React, { useState } from 'react'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { ModalChargeCancellationCP } from 'main/modules/financial/components/modals/modal-charge-cancellation/ModalChargeCancellationCP'
import { ChargeStatusEnum } from 'submodules/neritclin-sdk/services/sale/charge/enums/ChargeStatusEnum'

interface ICPProps extends BasicStyleWrapperCPProps {
    charge: IChargeListItemResponseDTO
    financialTransaction?: IFinancialTransactionCreditListItemResponseDTO
    onChangeData: () => void
    appearance?: {
        showText?: boolean
    }
}

function eVisivel(prop:any){
    console.log("--------------------------------")
    if(prop?.financialTransaction?.bankAccountPartner?.name){
        const v = (prop.financialTransaction.bankAccountPartner.name+'').toLocaleLowerCase();
        console.log(`1-> ${v}`);
        if(v.includes('vindi'))return true;
    }
    if(prop?.financialTransaction?.chargeData?.partnerID){
        const v = (prop.financialTransaction.chargeData.partnerID+'').toLocaleLowerCase();
        console.log(`2-> ${v}`);
        if(v.includes('vindi'))return true;
    }
    if(prop?.charge?.chargeData?.partnerID){
        const v = (prop.charge.chargeData.partnerID+'').toLocaleLowerCase();
        console.log(`3-> ${v}`);
        
        if(v.includes('vindi') )return true;
    }

    console.log("METODO DE SUMIR TRUE ", prop);
    return false;
}

/**
 */
export function ButtonChargeCancellationCP(props: ICPProps): JSX.Element {

    const [isChargeCancellationModalVisible, setIsChargeCancellationModalVisible] = useState<boolean>(false)
    let chargeStatus
    let paymentMethod: PaymentMethodEnum
    if(!eVisivel(props)){// Não renderiza se for Pago Livre
        return <></>
    }
    if (!!props.financialTransaction) {

        if (![PaymentMethodEnum.CREDIT_CARD_RECURRENT].includes(props.financialTransaction.paymentMethod))
            return <></>

        chargeStatus = props.financialTransaction.chargeData?.chargeStatus
        paymentMethod = props.financialTransaction.paymentMethod

    } else {

        if (!props.charge.chargeData?.paymentMethod || ![PaymentMethodEnum.CREDIT_CARD, PaymentMethodEnum.CREDIT_CARD_RECURRENT, PaymentMethodEnum.BANK_BILLET_RECURRENT, PaymentMethodEnum.PIX_RECURRENT].includes(props.charge.chargeData?.paymentMethod))
            return <></>

        chargeStatus = props.charge.chargeData.chargeStatus
        paymentMethod = props.charge.chargeData.paymentMethod
    }

    if (chargeStatus === ChargeStatusEnum.CANCELED || !paymentMethod)
        return <></>

     
    let r:any = props.appearance?.showText
                        ? (paymentMethod === PaymentMethodEnum.CREDIT_CARD ? 'Cancelar / Estornar' : 'alternativo')
                        : undefined
    if(r==='alternativo')r=true;

    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_SALE_CANCELATION]} redirect={false}>
            <ButtonCP
                type={'danger'}
                onClick={() => {
                    
                    setIsChargeCancellationModalVisible(true)
                    
                    }
                }
                icon={'close-circle'}
                tooltip={props.appearance?.showText ? undefined : 'Cancelar / Estornar'}
                size={'small'}
                marginTop={props.margin?.top}
                marginBottom={props.margin?.bottom}
                marginRight={props.margin?.right}
                marginLeft={props.margin?.left}
            >
                {
                    props.appearance?.showText
                        ? (paymentMethod === PaymentMethodEnum.CREDIT_CARD ? 'Cancelar / Estornar' : 'cancelar recorrência')
                        : undefined
                }
            </ButtonCP>

            <ModalChargeCancellationCP
                visible={isChargeCancellationModalVisible}
                charge={props.charge}
                alternativo={r}
                financialTransaction={props.financialTransaction}
                onCancel={() => setIsChargeCancellationModalVisible(false)}
                onSave={() => {
                    setIsChargeCancellationModalVisible(false)
                    props.onChangeData()
                }}
            />
        </AccessControlCP>
    )
}
