import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { FinancialTransactionStatusIconCP } from 'main/modules/financial/components/financial-transaction-status-icon/FinancialTransactionStatusIconCP'
import moment from 'moment'
import React from 'react'
import { IconPaymentMethodCP } from 'main/modules/financial/components/icon-payment-method/IconPaymentMethodCP'
import { IFinancialTransactionsExpenseListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionsExpenseListItemResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ButtonPayFinancialTransactionCP } from 'main/modules/financial/components/button-pay-financial-transaction/ButtonPayFinancialTransactionCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { StringUtils } from 'main/common/utils/StringUtils'

export const FinancialTransactionsDebitTableUtils = {

    getTableColumns(
        onSelectTransaction: (code: number) => void,
        onPersonClick: (personCode: number) => void,
        onUpdateAnyData: () => void
    ): Array<ColumnProps<IFinancialTransactionsExpenseListItemResponseDTO>> {

        return [
            {
                key: 'cr_status',
                align: 'center',
                render: (text, item) => <FinancialTransactionStatusIconCP status={item.status} expirationDate={item.expirationDate}/>
            },
            {
                key: 'pm',
                align: 'center',
                render: (text, item) => <IconPaymentMethodCP paymentMethod={item.paymentMethod}/>
            },
            {
                title: 'Data',
                key: 'cr_expirationdate',
                className: 'dateColumn',
                render: (text, item) => (
                    <>
                        <FlexCP alignItems={'center'}>
                            <>
                                {
                                    item.status === FinancialTransactionStatusEnum.PAID &&
                                    <TextCP text={'pago em'} marginRight={5} size={'small'}/>
                                }
                            </>
                            <>{ moment(item.paymentDate ?? item.expirationDate).format(DateFormatEnum.BR_WITHOUT_TIME) }</>
                        </FlexCP>
                        {
                            item.expirationDate !== item.referenceDate &&
                            <FlexCP alignItems={'center'}>
                                <TextCP text={'referente à'} marginRight={5} size={'small'} fontStyle={'italic'} color={ThemeAnt.gray}/>
                                <TextCP text={moment(item.referenceDate).format(DateFormatEnum.BR_WITHOUT_TIME)} fontStyle={'italic'} color={ThemeAnt.gray} size={'small'}/>
                            </FlexCP>
                        }
                    </>
                )
            },
            {
                title: 'Descrição / Fornecedor',
                key: 'cr_personPayerName',
                render: (text, item) => (
                    <>
                        { item.description }
                        {
                            !!item.personSupplier &&
                            <FlexCP alignItems={'center'}>
                                <TextCP text={`para ${StringUtils.getFirstName(item.personSupplier.name)}`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                                <ButtonCP type={'link'} onClick={() => onPersonClick(item.personSupplier!.code)} icon={'eye'} size={'small'} marginLeft={10} color={ThemeAnt.gray}/>
                            </FlexCP>
                        }
                    </>
                )
            },
            {
                title: 'Valor',
                key: 'cr_value',
                align: 'right',
                render: (text, item) => (
                    <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                        <TextCP text={MaskUtils.applyMoneyMask(item.paidValue ?? item.value)} marginRight={5}/>
                        <>
                            {
                                item.status === FinancialTransactionStatusEnum.PAID &&
                                <TagCP content={'pago'} color={'green'} small={true}/>
                            }
                        </>
                    </FlexCP>
                )
            },
            {
                title: 'Conta',
                key: 'cr_bank',
                render: (text, item) => item.bankAccount.name
            },
            {
                key: 'cr_actions',
                align: 'right',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonPayFinancialTransactionCP
                            financialTransactionCode={item.code}
                            financialTransactionStatus={item.status}
                            onChangePayment={onUpdateAnyData}
                            showText={false}
                        />
                        <ButtonCP
                            type={'primary'}
                            size={'small'}
                            onClick={(): void => onSelectTransaction(item.code)}
                            icon={'eye'}
                            marginLeft={5}
                        />
                    </FlexCP>
                )
            }
        ]
    },

}
