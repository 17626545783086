import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'

interface ILayoutSubmenuContentCPProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Componente LayoutCP para telas que possuem submenu.
 *
 * @author renatofs
 */
export function LayoutSubmenuContentCP(props: ILayoutSubmenuContentCPProps): JSX.Element {

    return (
        <ContentSCP>
            <ContentWrapperSCP>
                {props.children}
            </ContentWrapperSCP>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: center;
`

const ContentWrapperSCP = styled.div`
    width: 70vw;
`
