import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ISalesBySellerReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesBySellerReportFilterRequestDTO'
import { ISalesBySellerReportListItemRespDTO } from 'main/modules/sale/services/sale/dtos/responses/ISalesBySellerReportListItemRespDTO'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { SalesBySellerTableUtils } from 'main/modules/reports/components/table-sales-by-seller/inner/SalesBySellerTableColumns'

interface ITableSalesBySellerCPProps {
    dateFilter: IDateRangeFilter
    franchiseCodes?: number[]
}

/**
 * Tabela de vendas agrupadas por vendedor
 */
export function TableSalesBySellerCP(props: ITableSalesBySellerCPProps): JSX.Element {

    const [salesBySellerList, setSalesBySellerList] = useState<ISalesBySellerReportListItemRespDTO[]>([])

    const salesBySellerRequest = useRequest<IGenericListResponseDTO<ISalesBySellerReportListItemRespDTO>>()
    useEffect(onSalesBySellerRequestChange, [salesBySellerRequest.isAwaiting])

    useEffect(init, [props.dateFilter])

    /**
     * Inicializa os dados.
     */
    function init(): void {

        const requestDto: ISalesBySellerReportFilterRequestDTO = {
            beginDate: moment(props.dateFilter.beginDate).format('YYYY-MM-DD'),
            endDate: moment(props.dateFilter.endDate).format('YYYY-MM-DD'),
            franchiseCodes: props.franchiseCodes
        }

        salesBySellerRequest.runRequest(SaleRequests.getSalesBySellerConfig(requestDto))
    }

    /**
     * Retorno ao buscar os dados na API.
     */
    function onSalesBySellerRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(salesBySellerRequest, 'Erro ao buscar relatórios de venda por vendedor'))
            return

        setSalesBySellerList(salesBySellerRequest.responseData!.list)
    }

    return (
        <TableCPOLD2
            data={salesBySellerList}
            loading={salesBySellerRequest.isAwaiting}
            columns={SalesBySellerTableUtils.getColumns()}
        />
    )
}
