import { List } from 'antd'
import { ListLocale } from 'antd/lib/list'
import React, { CSSProperties, ReactElement } from 'react'
import styled from 'styled-components'
import { IListItemICPProps } from 'common/old-refactory/list/inner/ListItemICPOLD'

interface IListCPProps {
    loading?: boolean
    itemLayout?: 'vertical' | 'horizontal'
    pagination?: boolean
    currentPage?: number
    pageSize?: number
    total?: number
    onPageChange?: (page: number, pageSize?: number) => void
    dataSource: any
    renderItem: (item: any, index: number) => ReactElement<IListItemICPProps>
    style?: CSSProperties
    header?: JSX.Element
    bordered?: boolean
    locale?: ListLocale
}

/**
 * Componente de Lista
 * @param {IListCPProps<T>} props
 *
 * @author guilherme.diniz
 * @author Rafael V.
 */
export function ListCPOLD(props: IListCPProps): JSX.Element {

    return (
        <ListSCP
            header={props.header}
            loading={props.loading}
            bordered={props.bordered}
            style={props.style}
            locale={props.locale}
            itemLayout={props.itemLayout}
            pagination={props.pagination && {
                current: props.currentPage,
                pageSize: props.pageSize,
                onChange: props.onPageChange,
                total: props.total,
                showTotal: (total: number, range) => `Mostrando ${range[0]}-${range[1]} de ${total}`,
            }}
            dataSource={props.dataSource}
            renderItem={props.renderItem}
        />
    )
}

const ListSCP = styled(List)`

    .ant-list-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        min-height: 100px;

        .ant-pagination {
            position: relative;
        }

        .ant-pagination-item, .ant-pagination-item-link {
            border-radius: 100%;
            min-width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            font-size: 15px;
            margin: 0 7px;
            color: #4A5180;
            font-weight: 600;
        }

        .ant-pagination-item-link {
            border: 2px solid;
        }

        .ant-pagination-total-text {
            position: absolute;
            display: inline-flex;
            justify-content: center;
            bottom: -32px;
            margin: 0 auto;
            left: 0;
            right: 0;
            color: #4A5180;
            font-weight: 600;
        }
    }
`
