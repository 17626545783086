import { Icon, Tooltip } from 'antd'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import { WorkScheduleUtils } from 'main/modules/scheduler/utils/WorkScheduleUtils'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { TimePickerWorkRangeCP } from 'main/modules/scheduler/components/time-picker-work-range/TimePickerWorkRangeCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { WorkScheduleValidationTP } from 'main/modules/scheduler/types/WorkScheduleValidationTP'

interface ITimeRangeColumnICPProps {
    workSchedule: IWorkSchedule
    readonly?: boolean
    isInterval: boolean
    onChangeTime?: (workSchedule: IWorkSchedule) => void
}

/**
 * Componente com range de horário de trabalho ou intervalo
 */
export function TimeRangeColumnICP(props: ITimeRangeColumnICPProps): JSX.Element {

    // Obtem moment os horarios
    const workingTimeBegin = DateUtilsOLD.getMomentFromTime(props.workSchedule.beginTime)
    const workingTimeEnd = DateUtilsOLD.getMomentFromTime(props.workSchedule.endTime)
    const workingIntervalBegin = DateUtilsOLD.getMomentFromTime(props.workSchedule.beginInterval)
    const workingIntervalEnd = DateUtilsOLD.getMomentFromTime(props.workSchedule.endInterval)

    // Regras de validacao, horario final menor que inicial e intervalo dentro do horario de trabalho
    let timeValidationData: WorkScheduleValidationTP
    if (props.isInterval)
        timeValidationData = WorkScheduleUtils.isIntervalScheduleValid(workingIntervalBegin, workingIntervalEnd, workingTimeBegin, workingTimeEnd)
    else
        timeValidationData = WorkScheduleUtils.isBeginBeforeEndTime(workingTimeBegin, workingTimeEnd)

    // Se for apenas exibicao e os horarios estiverem em branco, mostra msg informando que horario nao esta definido
    if (props.readonly) {

        let isBlank = false
        if (props.isInterval && (!props.workSchedule.beginInterval || !props.workSchedule.endInterval))
            isBlank = true
        if (!props.isInterval && (!props.workSchedule.beginTime || !props.workSchedule.endTime))
            isBlank = true

        if (isBlank)
            return <TextCP text={'não configurado'} size={'extraSmall'} color={ThemeAnt.gray}/>
    }

    return (
        <>
            <FlexCP alignItems={'center'} justifyContent={'center'}>
                <TimePickerWorkRangeCP
                    time={props.isInterval ? props.workSchedule.beginInterval : props.workSchedule.beginTime}
                    onChangeTime={(time) => {
                        if (props.isInterval)
                            props.workSchedule.beginInterval = time
                        else
                            props.workSchedule.beginTime = time

                        if (props.onChangeTime)
                            props.onChangeTime(props.workSchedule)
                    }}
                    timeValidation={timeValidationData}
                    readonly={props.readonly}
                />
                <TextCP
                    text={'às'}
                    marginRight={5}
                    marginLeft={5}
                />
                <TimePickerWorkRangeCP
                    time={props.isInterval ? props.workSchedule.endInterval : props.workSchedule.endTime}
                    onChangeTime={(time) => {
                        if (props.isInterval)
                            props.workSchedule.endInterval = time
                        else
                            props.workSchedule.endTime = time

                        if (props.onChangeTime)
                            props.onChangeTime(props.workSchedule)
                    }}
                    timeValidation={timeValidationData}
                    readonly={props.readonly}
                />
                <>
                    {
                        !timeValidationData.isCorrect &&
                        <Tooltip placement={'top'} title={timeValidationData.message}>
                            <Icon type={'info-circle'} style={{ color: '#ffc107' }}/>
                        </Tooltip>
                    }
                </>
            </FlexCP>
        </>
    )
}
