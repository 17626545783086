import { History } from 'history'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 */
export class NeritFrameworkRoutingHelper {

    public static history?: History

    /**
     */
    static init(history: History): void {
        NeritFrameworkRoutingHelper.history = history
    }

    /**
     */
    static historyPush(path: string): void {

        if (!NeritFrameworkRoutingHelper.history)
            return

        NeritFrameworkRoutingHelper.history.push(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(path))
    }

    /**
     */
    static historyReplace(path: string, windowReplace?: boolean): void {

        if (!NeritFrameworkRoutingHelper.history)
            return

        const mountedPath = NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(path)

        if (windowReplace)
            window.location.replace(mountedPath)
        else
            NeritFrameworkRoutingHelper.history.replace(mountedPath)
    }

    /**
     *
     */
    static openInNewTab(path: string, considerPathWithCustomerDomain?: boolean): void {

        let url = path
        if (considerPathWithCustomerDomain)
            url = NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(path)

        window.open(url)
    }

    /**
     */
    static mountPathWithCustomerDomain(path: string): string {

        const rootRoute = `/${NeritFrameworkRoutingHelper.getUrlCustomerDomain()}`
        let pathCorrect = StringUtils.stripInitialChars(path, '/')
        pathCorrect = StringUtils.stripRepeatedBegin(`${rootRoute}/${pathCorrect}`, rootRoute)

        return pathCorrect
    }

    /**
     */
    static getQueryParamArray(paramName: string, paramValues?: any[]): string {

        let queryParams = ''
        if (!!paramValues) {
            paramValues.forEach((paramValue) => {
                queryParams = `${queryParams}${paramName}=${paramValue}&`
            })
        }

        return queryParams
    }

    /**
     */
    static getUrlCustomerDomain(): string {
        return  window.location.pathname.split('/')[1]
    }

}
