import React, { useEffect, useState } from 'react'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ImageTP, ViewerImageCP } from 'submodules/nerit-framework-ui/common/components/file-preview/viewer-image/ViewerImageCP'

interface ICPProps {
    files: FileDataTP[]
    selectedAttCode?: number
    size?: ButtonSizeTP
}

/**
 * Botao para visualizar arquivos
 */
export function ButtonFilesPreviewCP(props: ICPProps): JSX.Element {

    const [isModalViewerVisible, setIsModalViewerVisible] = useState<boolean>(false)

    const [viewerImages, setViewerImages] = useState<ImageTP[]>()
    useEffect(init, [props.files])

    /**
     */
    function init(): void {

        const formmated: ImageTP[] = props.files.map((att, index) => ({
            src: att.fileUrl,
            type: att.fileName.split('.').slice(-1)[0],
            key: index
        }))

        setViewerImages(formmated)
    }

    /**
     */
    function onDownload(index: number): void {
        const attachment = props.files[index]
        if (!!attachment)
            window.open(attachment.fileUrl, '_blank')
    }

    return (
        <>
            <ButtonCP
                size={props.size}
                icon={'eye'}
                onClick={() => setIsModalViewerVisible(true)}
            >
                Ver arquivos
            </ButtonCP>

            <ViewerImageCP
                images={viewerImages ?? []}
                onClose={() => setIsModalViewerVisible(false)}
                visible={isModalViewerVisible}
                onDownload={onDownload}
            />
        </>
    )
}
