import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import React, { useEffect, useState } from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { FunnelStepFormCP } from 'main/modules/sales-funnel/components/funnel-steps-data/inner/FunnelStepFormCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFunnelStepSaveRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelStepSaveRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import _ from 'lodash'
import { IFunnelSaveRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelSaveRequestDTO'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

interface IEditStepsCPProps {
    createNewStep?: any
    steps: IFunnelStepResponseDTO[]
    updateStepsList: (closeModal: boolean) => void
    loading?: boolean
}

/**
 * COMPONENT Etapas de um funil de vendas.
 */
export function FunnelStepsDataCP(props: IEditStepsCPProps): JSX.Element {

    const [stepList, setStepList] = useState<IFunnelStepResponseDTO[]>([])
    const [isFormValid, setIsFormValid] = useState<boolean>(true)

    const createStepRequest = useRequest<IFunnelStepSaveRequestDTO>()
    const updateStepsRequest = useRequest<IFunnelStepResponseDTO>()

    useEffect(defineStepList, [props.steps])
    useEffect(onCreateStepRequestChange, [createStepRequest.isAwaiting])
    useEffect(onUpdateStepsRequestChange, [updateStepsRequest.isAwaiting])

    function defineStepList(): void {
        if (_.isEmpty(props.steps))
            return
        setStepList(props.steps)
    }
    function onCreateStep(): void {

        const dto: IFunnelStepSaveRequestDTO = {
            name: `Etapa #${props.steps.length + 1}`,
        }

        createStepRequest.runRequest(FunnelRequests.createStepConfig(dto))
    }

    function onCreateStepRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(createStepRequest, 'Erro ao criar etapa no funil de follow-up.'))
            return
        props.updateStepsList(false)

    }

    function onSaveSteps(): void {

        const dto: IFunnelSaveRequestDTO = {
            steps: (
                stepList.map(step => ({
                    code: step.code,
                    name: step.name,
                    order: +step.order,
                }))
            )
        }

        updateStepsRequest.runRequest(FunnelRequests.updateConfig(dto))

    }

    function onUpdateStepsRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(updateStepsRequest, 'Erro ao editar etapas no funil de follow-up.'))
            return
        props.updateStepsList(true)
    }

    function validateStepsOrder(list: IFunnelStepResponseDTO[]): void {
        const duplicatesRemoved: Set<number> = new Set(list.map(step => step.order))
        const orderList: number[] = [...duplicatesRemoved]

        if (orderList.length !== stepList.length) {
            setIsFormValid(false)
            return
        }

        setIsFormValid(true)
    }

    function onChangeStep(updatedStep: IFunnelStepResponseDTO): void {

        const list: IFunnelStepResponseDTO[] = stepList.filter(step => step.code !== updatedStep.code)
        list.push(updatedStep)
        validateStepsOrder(list)
        setStepList(list)
    }

    return (
        <ContentWrapperSCP>
            <HeaderWrapperSCP>
                <TextCP text={'Etapas do Funil'}/>
                <FontAwsomeIconCP
                    type={faPlusCircle}
                    onClick={onCreateStep}
                    tooltip={'Adicionar etapa'}
                    loading={props.loading}
                    color={ThemeAnt.primaryColor}
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                />
            </HeaderWrapperSCP>

            {
                props.steps.map(item => (
                    <FunnelStepFormCP
                        key={item.code}
                        maxStepsNumber={props.steps.length}
                        step={item}
                        onDeleteStep={() => props.updateStepsList(false)}
                        onChangeStep={onChangeStep}
                        setIsFormValid={setIsFormValid}
                    />
                ))
            }
            <AlertCP message={'Verifique a ordem das etapas'} type={'warning'} show={!isFormValid} showIcon={true}/>
            <FlexCP justifyContent={'flex-end'} marginTop={40}>
                <ButtonCP
                    onClick={onSaveSteps}
                    type={'primary'}
                    loading={updateStepsRequest.isAwaiting}
                >
                    Salvar
                </ButtonCP>
            </FlexCP>

        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div``

const HeaderWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 5fr 1fr;
    column-gap: 15px;
`
