import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SenderModalCP } from 'main/modules/marketing/components/sender-modal/SenderModalCP'
import { SenderTableUtils } from 'main/modules/marketing/components/sender-table/inner/SenderTableUtils'
import { IMarketingSenderSaveRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSenderSaveRequestDTO'
import { IMarketingSenderResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingSenderResponseDTO'
import { MarketingSenderStatusEnum } from 'main/modules/marketing/enums/MarketingSenderStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

interface ISenderTableCPProps {
    isSenderModalVisible: boolean
    onChangeModalVisible: (visible: boolean) => void
}

/**
 * COMPONENTE
 * Tabela de listagem de remetentes de e-mail configurados para uso no modulo
 * de marketing.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function SenderTableCP(props: ISenderTableCPProps): JSX.Element {

    useEffect(() => { defineSenderList() }, [])

    const [selectedSender, setSelectedSender] = useState<IMarketingSenderResponseDTO>()

    const [senderList, setSenderList] = useState<IMarketingSenderResponseDTO[]>([])
    const getSendersRequest = useRequest<IGenericListResponseDTO<IMarketingSenderResponseDTO>>()
    useEffect(onGetSendersRequestChange, [getSendersRequest.isAwaiting])

    const deleteSenderRequest = useRequest<void>(undefined, 'none')
    useEffect(onDeleteSenderRequestChange, [deleteSenderRequest.isAwaiting])

    const resendTokenRequest = useRequest<void>(undefined, 'none')
    useEffect(onResendTokenRequestChange, [resendTokenRequest.isAwaiting])

    const saveSenderRequest = useRequest<IMarketingSenderResponseDTO>()
    useEffect(onSaveSenderRequestChange, [saveSenderRequest.isAwaiting])

    /**
     * Faz a requisicao para buscar remetentes
     */
    async function defineSenderList(): Promise<void> {
        getSendersRequest.runRequest(await MarketingRequests.searchSender())
    }

    /**
     * Retorno da requisicao que busca agendas.
     */
    function onGetSendersRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSendersRequest, 'Não foi possível obter a lista de remetentes'))
            return

        setSenderList(getSendersRequest.responseData!.list)
    }

    function prepareToEditSender(_sender: IMarketingSenderResponseDTO): void {
        setSelectedSender(_sender)
        props.onChangeModalVisible(true)
    }

    async function onChangeStatus(_sender: IMarketingSenderResponseDTO, newStatus: MarketingSenderStatusEnum): Promise<void> {

        // Se o novo status eh pendente de validacao, significa que quer receber o token novamente
        if (newStatus === MarketingSenderStatusEnum.PENDING_VALIDATION) {
            resendTokenRequest.runRequest(await MarketingRequests.sendSenderTokenEmail(_sender.code))
            return
        }

        const _senderUpdateDto: IMarketingSenderSaveRequestDTO = {
            status: newStatus,
            emailPrefix: _sender.emailPrefix,
            domainCode: _sender.domainCode,
            name: _sender.name
        }

        saveSenderRequest.runRequest(await MarketingRequests.updateSender(_sender.code, _senderUpdateDto))
    }

    /**
     * Apos reotorno da api de salvar remetente
     */
    function onSaveSenderRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveSenderRequest, 'Ocorreu algun erro ao salvar remetente'))
            return

        NotificationHelper.info('Status do Remetente', 'Atualizado com sucesso')
        defineSenderList()
    }

    /**
     * Remove remetente
     */
    async function deleteSender(senderCode: number): Promise<void> {
        deleteSenderRequest.runRequest(await MarketingRequests.deleteSender(senderCode))
    }

    /**
     * Retorno da requisicao de deletar sender
     */
    function onDeleteSenderRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteSenderRequest, 'Não foi remover a remetente', true))
            return

        NotificationHelper.info('Sucesso', 'Remetente removido com sucesso')
        defineSenderList()
    }

    /**
     * Retorno da requisicao ao reenviar token
     */
    function onResendTokenRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(resendTokenRequest, 'Não foi possível reenviar o e-mail de confirmação', true))
            return

        NotificationHelper.info('Sucesso', 'Foi enviado um e-mail para ativar este remetente')
    }

    return (
        <>
            <LoadingOverlayCP show={deleteSenderRequest.isAwaiting || resendTokenRequest.isAwaiting || saveSenderRequest.isAwaiting}/>

            <TableCPOLD2<IMarketingSenderResponseDTO>
                columns={SenderTableUtils.getColumnsConfig(
                    prepareToEditSender,
                    deleteSender,
                    onChangeStatus
                )}
                data={senderList}
                loading={getSendersRequest.isAwaiting}
                showLoadingAsSkeleton={true}
                showHeader={true}
            />

            <SenderModalCP
                visible={props.isSenderModalVisible}
                onSave={() => {
                    props.onChangeModalVisible(false)
                    defineSenderList()
                }}
                onCancel={() => {
                    setSelectedSender(undefined)
                    props.onChangeModalVisible(false)
                }}
                sender={selectedSender}
            />
        </>
    )
}
