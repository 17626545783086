export function ok(res:any){
    if(res?.data?.data?.resposta=='ok')return true;
    return false;
}

export function dados(res:any){
    if(ok(res))return res.data.data.dados;
    return null;
}

export function dateDeStamp(stamp:any){
    const comp = (v:any)=>{
        if((v+'').length<2)return '0'+v;
        return v;
    };
    let v = stamp.split('T')[0].split('-');
    return `${comp(v[2])}/${comp(v[1])}/${v[0]}`;
}

export function dateParaSQL(date:any) {
    if(!date)return null;
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
}