import React from 'react'
import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import styled from 'main/config/theme/styledWithTheme'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface IContentAuthCPProps {
    children: React.ReactNode
}

/**
 * Componente para área de conteudo do login.
 *
 * @author renatofs
 */
export function ContentAuthCP(props: IContentAuthCPProps): JSX.Element | null {

    return (
        <ContentCP>
            <ContentWrapperSCP>
                <FormContainerSCP>
                    <LogoSCP>
                        <LogoCP
                            marginBottom={35}
                            imageUrl={AppStateUtils.getDomainData()?.schema?.logoUrl ?? undefined}
                        />
                    </LogoSCP>
                    {props.children}
                </FormContainerSCP>
            </ContentWrapperSCP>
        </ContentCP>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    height: 100%;
    align-items: center;

    .ant-layout {
	    background: #fff;
	}
`

const LogoSCP = styled.div`
    display: flex;
    justify-content: center;
`

const FormContainerSCP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px;
`
