export enum RecurrencePeriodEnum {
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTELY = 'QUARTELY',
    SEMIANNUALLY = 'SEMIANNUALLY',
    ANNUALLY = 'ANNUALLY'
}

export enum RecurrencePeriodLabelEnum {
    WEEKLY = 'Semanalmente',
    MONTHLY = 'Mensalmente',
    QUARTELY = 'Trimestralmente',
    SEMIANNUALLY = 'Semestralmente',
    ANNUALLY = 'Anualmente'
}
