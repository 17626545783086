/**
 * ENUM
 * Define o tipo da oportunidade de acordo com seu estágio no funil de vendas
 */
export enum OpportunityTypeEnum {
    LEAD = 'LEAD',
    EVALUATION = 'EVALUATION',
    FOLLOWUP = 'FOLLOWUP'
}

export enum OpportunityTypeLabelEnum {
    LEAD = 'Lead',
    EVALUATION = 'Avaliação',
    FOLLOWUP = 'Follow-up'
}
