import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import React, { useState } from 'react'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'

interface ICPProps {
    opportunity: IOpportunityResponseDTO
    onEdit(): void
}

/**
 * Botoes dos dados de uma oportunidade
 */
export function OpportunityDataButtonsICP(props: ICPProps): JSX.Element {

    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)

    return (
        <>
            <FlexCP justifyContent={'space-between'}>
                <ButtonCP
                    icon={'edit'}
                    type={'ghost'}
                    onClick={props.onEdit}
                >
                    Editar
                </ButtonCP>

                <ConditionalRenderCP shouldRender={!!props.opportunity.personCustomer}>
                    <ButtonCP
                        icon={'user'}
                        type={'ghost'}
                        marginLeft={10}
                        onClick={() => setIsPersonDrawerVisible(true)}
                    >
                        Dados do cliente
                    </ButtonCP>
                </ConditionalRenderCP>
            </FlexCP>

            <DrawerPersonDetailsCP
                visible={isPersonDrawerVisible}
                onClose={() => setIsPersonDrawerVisible(false)}
                onSave={() => {
                    setIsPersonDrawerVisible(false)
                    props.onEdit()
                }}
                segmentType={SegmentTypeEnum.CUSTOMER}
                personCode={props.opportunity.personCustomer?.code}
            />
        </>
    )
}
