/* eslint-disable */
import { Icon, Skeleton, Table } from 'antd'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { ColumnProps, FilterDropdownProps, PaginationConfig, SorterResult, SortOrder, TableEventListeners, TableRowSelection } from 'antd/lib/table'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { AutoCompleteFilterDropdownICP } from 'common/old-refactory/table/inner/AutoCompleteFilterDropdownICP'
import { ColumnFilterTypeEnum } from 'common/old-refactory/table/inner/ColumnFilterTypesEnum'
import { MultiCheckFilterDropdownICP } from 'common/old-refactory/table/inner/MultiCheckFilterDropdownICP'
import { SearchFilterDropdownICP } from 'common/old-refactory/table/inner/SearchFilterDropdownICP'

export type TablePaginationTP = {
    total: number,
    current: number,
    pageSize: number,
    showTotal?: (total: number, range: [number, number]) => React.ReactNode,
}

export type ColumnFilterTP = {
    type?: ColumnFilterTypeEnum,
    dataIndex: string,
    colKey: string,
    placeholder?: string,
    searchOptionsReq?: any,
    onSearch?: (text: any) => void,
    onReset?: () => void,
    onSelect?: (value: any) => void,
    mask?: any,
    searchColumn?: string,
    options?: any[],
    value?: any,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ITableCPProps<T> {
    data?: T[]
    columns?: Array<ColumnProps<T>>
    pagination?: TablePaginationTP
    onPaginationChange?: (pagination: PaginationConfig) => void
    onSortingChange?: (sorting: SorterResult<T>) => void
    rowKey?: ((record: any) => string) | string
    bordered?: boolean
    rowSelection?: TableRowSelection<T>
    loading?: boolean
    children?: React.ReactNode
    sortDirections?: SortOrder[]
    rowClassName?: (record: T, index: number) => string
    onRow?: (record: unknown, index: number) => TableEventListeners
    skeletonRows?: number
    emptyText?: string
    locale?: { filterConfirm?: string, filterReset?: string, emptyText?: string }
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
    costumizedFilters?: ColumnFilterTP[]
}

/**
 * Tabela com estilo do sistema
 * @TODO Ajustar render com skeleton
 * @TODO Tipar as props dos filtros corretamente
 * @author Lucas Rosa
 * @author kiara.vz
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function TableCPOLD<T = any>(props: ITableCPProps<T>): JSX.Element | null {
    const [columns, setColumns] = useState<Array<ColumnProps<T>>>()
    const [filterValue, setFilterValue] = useState<any>()

    useEffect(renderSkeletonLoading, [props.columns])
    useEffect(renderCostumizedFilters, [props.costumizedFilters])

    function onChangeHandler(pagination: PaginationConfig, filters: Partial<Record<keyof T, string[]>>, sorter: SorterResult<T>): void {
        if (!!props.onPaginationChange)
            props.onPaginationChange(pagination)

        if (!!props.onSortingChange)
            props.onSortingChange(sorter)
    }

    function renderSkeletonLoading() {
        if (!props.columns)
            return

        setColumns(props.columns.map(column => ({
            ...column,
            render: (text, item, index) => {
                if (_.isEmpty(item))
                    return <SkeletonSCP loading={true} paragraph={false} title={true} active={true}/>
                return column.render!(text, item, index)
            }
        })))
    }

    function defineFilterConfig(filter?: ColumnFilterTP) {
        if (!filter)
            return
        const filterOptions = filter.options ? filter.options : []
        switch (filter.type) {
            case ColumnFilterTypeEnum.SEARCH:
                return {
                    filterDropdown: (filterProps: FilterDropdownProps) => (
                        <SearchFilterDropdownICP
                            dataIndex={filter.dataIndex}
                            onReset={filter.onReset!}
                            onSearch={filter.onSearch!}
                            placeholder={filter.placeholder}
                            mask={filter.mask}
                            {...filterProps}
                        />
                    ),
                    filterIcon: () => (<Icon type={'search'} style={{ color: !!filter.value ? '#6d0f99' : undefined }}/>),
                }
            case ColumnFilterTypeEnum.MULTIPLE:
                setFilterValue(filter.value)
                return {
                    filterDropdown: <MultiCheckFilterDropdownICP options={filterOptions as CheckboxOptionType[]} onChange={filter.onSelect!} value={filterValue}/>,
                    filterIcon: () => (<Icon type={'filter'} theme={'filled'} style={{ color: !_.isEmpty(filter.value) ? '#6d0f99' : undefined }}/>),
                }

            case ColumnFilterTypeEnum.SELECT_SEARCH:
                setFilterValue(filter.value)
                return {
                    filterDropdown: <AutoCompleteFilterDropdownICP
                        searchReqConfigGetter={filter.searchOptionsReq}
                        onChange={filter.onSelect!}
                        placeholder={filter.placeholder ?? 'Selecione ...'}
                        onReset={filter.onReset!}
                    />,
                    filterIcon: () => (<Icon type={'filter'} style={{ color: !!filter.value ? '#6d0f99' : undefined }}/>),
                }
            default:
                return
        }
    }

    function renderCostumizedFilters() {
        if (!props.columns || !props.costumizedFilters)
            return

        const columnsConfig = props.columns.map(column => {
            if (_.some(props.costumizedFilters, ['colKey', column.key])) {
                const _filterConfig = _.find(props.costumizedFilters, config => config.colKey === column.key)
                return {
                    ...column,
                    ...defineFilterConfig(_filterConfig)
                }
            } return column
        })
        setColumns(_.flatten(columnsConfig))
    }

    // if (!columns)
    //     return null

    return (

        <TableComponent<T>
            rowSelection={props.rowSelection}
            rowKey={props.rowKey}
            loading={props.loading && { indicator: <LoadingCP/> }}
            dataSource={props.data}
            //  dataSource={props.loading ? _.fill(Array(props.skeletonRows), {} as any) : [...props.data!]}
            columns={columns}
            pagination={props.pagination ? { position: 'bottom', size: 'small', ...props.pagination } : false}
            onChange={onChangeHandler}
            sortDirections={props.sortDirections}
            rowClassName={props.rowClassName}
            size={'small'}
            locale={props.locale ? props.locale : { emptyText: <EmptyCP description={props.emptyText ? props.emptyText : 'Nenhum dado encontrado'}/> }}
            onRow={props.onRow}
            getPopupContainer={props.getPopupContainer}
            tableLayout={'auto'}
        >
            {props.children}
        </TableComponent>

    )
}

const SkeletonSCP = styled(Skeleton)`
    .ant-skeleton-content{
        h3{
            margin: 3px 0;  
        }
}

`

const TableSCP = styled(Table)`
    width: 100%;
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
        border: none;
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-placeholder {
        ${props => (props.bordered ? 'border: 1px solid #e8e8e8;' : 'border: none;')}
        ${props => (props.bordered ? 'border-radius: 10px;' : '')}
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > thead {
        tr {
            th {
                color: ${(props: StyledComponentProps) => props.theme.darkGray};
                margin-bottom: 0;
                font-family: ${(props: StyledComponentProps) => props.theme.textFontFamily};
                font-weight: bold;
                font-size: ${(props: StyledComponentProps) => props.theme.fontSizes.small};
                border: none;
            }
        }
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > tbody {
        tr:hover td {
            background: ${(props: StyledComponentProps) => props.theme.lightGray};
        }
          tr{
              cursor: default;
              margin-bottom: 22px;
            td {
                color: ${(props: StyledComponentProps) => props.theme.darkGray};
                font-family: ${(props: StyledComponentProps) => props.theme.textFontFamily};
                font-weight: normal;
                font-size: ${(props: StyledComponentProps) => props.theme.fontSizes.small}
            }
            &:hover{
                background: ${(props: StyledComponentProps) => props.theme.lightGray};
            }
          }
    }
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
        background: none;
    }
    .ant-table-small > .ant-table-content > .ant-table-body{
        margin: 0;
    }
    .ant-pagination-item-active{
        background:  ${(props: StyledComponentProps) => props.theme.primaryColor};
        border-radius: 100%;
        border: none;
        a{
            color:  #fff;
            &:hover{
                color:  #fff;
            }
        }
        &:hover{
            background:  ${(props: StyledComponentProps) => props.theme.primaryColor};   
        }
    }

`

const TableComponent = TableSCP as any as React.ComponentType as new <TP>() => Table<TP>
