import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import { WeekDayLabelEnum } from 'main/modules/people/enums/WeekDayLabelEnum'
import { TimeRangeColumnICP } from 'main/modules/scheduler/components/table-work-schedule/inner/TimeRangeColumnICP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CopyTimeRangeColumnICP } from 'main/modules/scheduler/components/table-work-schedule/inner/CopyTimeRangeColumnICP'

/**
 * Utilitarios da tabela de horario de trabalho.
 */
export const WorkScheduleTableUtils = {

    getTableColumns(
        hasIntervalColumn: boolean,
        onChangeTime?: (workSchedule: IWorkSchedule) => void,
        readonly?: boolean,
    ): Array<ColumnProps<IWorkSchedule>> {

        const columns: Array<ColumnProps<IWorkSchedule>> = [
            {
                key: 'dayOfWeek',
                align: 'center',
                render: (text, workSchedule) => WeekDayLabelEnum[workSchedule.weekDay]
            },
            {
                title: 'Horário de trabalho',
                key: 'workSchedule',
                align: 'center',
                render: (text, workSchedule) => (
                    <TimeRangeColumnICP
                        isInterval={false}
                        workSchedule={workSchedule}
                        onChangeTime={onChangeTime}
                        readonly={readonly}
                    />
                )
            }
        ]

        if (hasIntervalColumn) {
            columns.push(
                {
                    title: 'Intervalo',
                    key: 'intervalSchedule',
                    align: 'center',
                    render: (text, workSchedule) => (
                        <TimeRangeColumnICP
                            isInterval={true}
                            workSchedule={workSchedule}
                            onChangeTime={onChangeTime}
                            readonly={readonly}
                        />
                    )
                }
            )
        }

        if (!readonly && !!onChangeTime) {
            columns.push(
                {
                    key: 'actions',
                    align: 'center',
                    render: (text, workSchedule) => (
                        <FlexCP alignItems={'flex-end'}>
                            <CopyTimeRangeColumnICP workSchedule={workSchedule} onChangeTime={onChangeTime}/>
                            <ButtonCP
                                onClick={() => {
                                    onChangeTime({ weekDay: workSchedule.weekDay, beginTime: undefined, endTime: undefined, beginInterval: undefined, endInterval: undefined })
                                }}
                                icon={'delete'}
                                type={'danger'}
                                size={'small'}
                            />
                        </FlexCP>
                    )
                }
            )
        }

        return columns
    },

}
