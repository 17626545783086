import React, { useEffect } from 'react'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ISaleContractResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleContractResponseDTO'

interface IContractGenerationContentICPProps {
    saleCode: number
    onGeneratedContract: (contractUrl: string) => void
}

/**
 * Conteudo do modal que ira gerar o contrato
 */
export function ContractGenerationContentICP(props: IContractGenerationContentICPProps): JSX.Element {

    useEffect(init, [props.saleCode])

    const generateContractRequest = useRequest<ISaleContractResponseDTO>()
    useEffect(onGenerateContractRequestChange, [generateContractRequest.isAwaiting])

    /**
     * Inicizaliza a tela
     */
    function init(): void {
        generateContractRequest.runRequest(SaleRequests.generateContractConfig(props.saleCode))
    }

    /**
     * Retorno da requisicao que gera contrato.
     */
    function onGenerateContractRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(generateContractRequest, 'Ocorreu algum gerar o contrato'))
            return

        props.onGeneratedContract(generateContractRequest.responseData!.contractUrl)
    }

    return (
        <WrapperSCP>
            <LoadingOverlayCP
                show={generateContractRequest.isAwaiting}
                text={'... Gerando contrato ...'}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  position: relative;
  height: 100px;
`
