import React from 'react'
import { RoutingHelper } from 'config/RoutingHelper'
import { SchedulerRoutes } from 'main/modules/scheduler/routes/SchedulerRoutes'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'

export type SchedulerViewModeTP = 'calendar' | 'scheduler' | 'attendanceQueue'

interface ICPProps {
    currentView: SchedulerViewModeTP
}

/**
 * Responsável por renderizar a tela do módulo de agenda
 */
export function RadioGroupSchedulerViewModeCP(props: ICPProps): JSX.Element {

    return (
        <RadioGroupCP<SchedulerViewModeTP>
            selected={props.currentView}
            buttonHeight={35}
            buttonWidth={170}
            paddingTop={0}
            secondary={true}
            type={'button'}
            onChange={(view) => {
                switch (view) {
                    case 'scheduler':
                        RoutingHelper.historyPush(SchedulerRoutes.SCHEDULER)
                        break;
                    case 'calendar':
                        RoutingHelper.historyPush(SchedulerRoutes.CALENDAR)
                        break;
                    case 'attendanceQueue':
                    default:
                        RoutingHelper.historyPush(SchedulerRoutes.ATTENDANCE_QUEUE)
                        break;
                }
            }}
            options={[
                {
                    value: 'calendar',
                    content: 'Agenda'
                },
                {
                    value: 'scheduler',
                    content: 'Marcação'
                },
                {
                    value: 'attendanceQueue',
                    content: 'Fila Atendimento',
                    hide: !PermissionUtils.isModuleAvailable(ModuleEnum.MODULE_LISOLASER)
                },
            ]}
        />
    )
}
