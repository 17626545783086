import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import styled from 'main/config/theme/styledWithTheme'
import { PersonContactGroupedTimelineICP } from 'main/modules/people/components/timeline-person-contacts/inner/PersonContactGroupedTimelineICP'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import { ILeadDataResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/ILeadDataResponseDTO'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { PopoverPersonContactCP } from 'main/modules/people/components/popover-person-contact/PopoverPersonContactCP'
import { PersonContactRequests } from 'main/modules/people/services/person-contact/PersonContactRequests'
import { IPersonContactSearchRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSearchRequestDTO'
import { PersonContactEntityFilterEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityFilterEnum'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'

interface ITimelinePersonContactsCPProps {
    entityCode: number
    entityFilter: PersonContactEntityFilterEnum
    opportunityBasicData?: ILeadDataResponseDTO
    onSave?: (conversionType?: OpportunityDrawerActionTP) => void
    showTitle: boolean
}

/**
 * Componente com timeline de interacoes com pessoa.
 */
export function TimelinePersonContactsCP(props: ITimelinePersonContactsCPProps): JSX.Element {

    useEffect(init, [props.entityCode])

    const [activityList, setActivityList] = useState<IPersonContactResponseDTO[]>([])
    const [isPersonContactPopoverVisible, setIsPersonContactPopoverVisible] = useState<boolean>(false)

    const getPersonContactsRequest = useRequest<IGenericListResponseDTO<IPersonContactResponseDTO>>()
    useEffect(onGetPersonContactsRequestChange, [getPersonContactsRequest.isAwaiting])

    /**
     * Inicializa a tela
     */
    function init(): void {

        const dto: IPersonContactSearchRequestDTO = {
            entity: props.entityFilter,
            entityCode: props.entityCode
        }

        getPersonContactsRequest.runRequest(PersonContactRequests.search(dto))
    }

    /**
     * Retorno ao obter lista de interacoes.
     */
    function onGetPersonContactsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonContactsRequest, 'Erro ao buscar as interações'))
            return

        setActivityList(getPersonContactsRequest.responseData!.list)
    }

    /**
     * Ao terminar uma acao do popover.
     */
    function onSave(conversionType?: OpportunityDrawerActionTP): void {
        setIsPersonContactPopoverVisible(false)
        init()
        props.onSave?.(conversionType)
    }

    function renderPopoverToAddNew(): JSX.Element {

        return (
            <PopoverPersonContactCP
                visible={isPersonContactPopoverVisible}
                onSave={onSave}
                onClose={() => setIsPersonContactPopoverVisible(false)}
                entityCode={props.entityCode}
                entity={props.entityFilter === PersonContactEntityFilterEnum.ALL ? PersonContactEntityEnum.PERSON_CONTACT : (props.entityFilter as unknown as PersonContactEntityEnum)}
            >
                {
                    <ButtonCP
                        icon={'plus'}
                        type={props.showTitle ? 'link' : 'primary'}
                        onClick={() => setIsPersonContactPopoverVisible(true)}
                        loading={getPersonContactsRequest.isAwaiting}
                        marginLeft={20}
                        size={props.showTitle ? undefined : 'small'}
                    >
                        {props.showTitle ? '' : 'Nova interação'}
                    </ButtonCP>
                }
            </PopoverPersonContactCP>
        )
    }

    return (
        <WrapperSCP>

            {
                props.showTitle
                    ?
                    <TitleCP
                        underLine={true}
                        textSize={'large'}
                    >
                        <FlexCP alignItems={'center'}>
                            <div>
                                Interações
                            </div>
                            <>{ renderPopoverToAddNew() }</>
                        </FlexCP>
                    </TitleCP>
                    :
                    <FlexCP justifyContent={'flex-end'}>
                        <>{ renderPopoverToAddNew() }</>
                    </FlexCP>
            }

            <PersonContactGroupedTimelineICP
                activities={activityList}
                entityCode={props.entityCode}
                entity={props.entityFilter === PersonContactEntityFilterEnum.ALL ? PersonContactEntityEnum.PERSON_CONTACT : (props.entityFilter as unknown as PersonContactEntityEnum)}
                onActionsDone={onSave}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 30px;
`
