import React from 'react'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'main/common/utils/StringUtils'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { ScreenAdmin } from 'main/modules/admin/screens/screen-admin/ScreenAdmin'

AdminRoutes.ROOT = 'admin'

AdminRoutes.SYSTEM_ROOT = `${AdminRoutes.ROOT}/sistema`
AdminRoutes.SYSTEM_ACCOUNT = `${AdminRoutes.SYSTEM_ROOT}/configuracoes`
AdminRoutes.SYSTEM_APPEARENCE = `${AdminRoutes.SYSTEM_ROOT}/aparencia`
AdminRoutes.SYSTEM_WORK_SCHEDULE = `${AdminRoutes.SYSTEM_ROOT}/horario-funcionamento`
AdminRoutes.SYSTEM_TARGETS = `${AdminRoutes.SYSTEM_ROOT}/metas`
AdminRoutes.TAGS = `${AdminRoutes.SYSTEM_ROOT}/tags`

AdminRoutes.ATTENADANCE_ROOT = `${AdminRoutes.ROOT}/tratamento`
AdminRoutes.ATTENADANCE_PRODUCT = `${AdminRoutes.ATTENADANCE_ROOT}/configuracao-pacotes`
AdminRoutes.ATTENADANCE_FRANCHISE_PRODUCT = `${AdminRoutes.ATTENADANCE_ROOT}/pacotes`
AdminRoutes.ATTENADANCE_GROUP = `${AdminRoutes.ATTENADANCE_ROOT}/grupos-atendimento`
AdminRoutes.ATTENADANCE_PERGUNTAS = `${AdminRoutes.ATTENADANCE_ROOT}/perguntas`
AdminRoutes.ATTENADANCE_JOBS = `${AdminRoutes.ATTENADANCE_ROOT}/atendimentos`
AdminRoutes.ATTENADANCE_FORMS = `${AdminRoutes.ATTENADANCE_ROOT}/formulários`

AdminRoutes.SALE_ROOT = `${AdminRoutes.ROOT}/venda`
AdminRoutes.CONTRACT_TEMPLATE = `${AdminRoutes.SALE_ROOT}/template-contrato`
AdminRoutes.OPPORTUNITY_SOURCE = `${AdminRoutes.SALE_ROOT}/origem-lead`

AdminRoutes.FINANCIAL_ROOT = `${AdminRoutes.ROOT}/financeiro`
AdminRoutes.FINANCIAL_CATEGORY = `${AdminRoutes.FINANCIAL_ROOT}/categorias`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(AdminRoutes.ROOT),
        path: AdminRoutes.ROOT,
        component: ScreenAdmin,
    },
]

/**
 * Componente que define as rotas para acesso ao CRM
 */
export function AdminRoutes(): JSX.Element {

    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_ADMIN]}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
