import { styled } from 'main/config/theme/styledWithTheme'
import * as _ from 'lodash'
import { ExpressionPreviewICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionPreviewICP'
import { ExpressionUnitICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionUnitICP'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React, { useEffect, useState } from 'react'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { IExpressionICPProps } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/IExpressionICPProps'

const ENABLE_DEBUG = false

/**
 * COMPONENTE
 * Barra widget de montagem da expressao para filtragem de 01 lista de segmentacao.
 *
 * NOTE: Flag 'isLoading' (expressionContext) setado 'a forca' pois delay natural (encadeamento de eventos) atrasa sinalizacao 'normal' de carregamento (prejudicando a usabilidade).
 *
 * @see ExpressionContext
 */
export function ExpressionICP(props: IExpressionICPProps): JSX.Element {

    const [outerExpression, setOuterExpression] = useState<SegmentExpressionTP>()

    const screenSize = useScreenSize()
    const expressionContext = ExpressionContext.use()

    useEffect(initInnerExpression, [])
    useEffect(onExpressionPropChange, [props.expression])
    useEffect(onInnerExpressionChange, [expressionContext.state.expression])
    useEffect(() => expressionContext.actions.setSegmentType(props.segmentType), [props.segmentType])
    useEffect(() => expressionContext.actions.setIsLoading(props.isLoading), [props.isLoading])

    function initInnerExpression(): void {
        expressionContext.actions.setIsLoading(true)
        expressionContext.actions.initUnitList(SegmentUtils.getExpressionForEdition(props.expression))
    }

    function onExpressionPropChange(): void {
        const isEqual = ((!!outerExpression && _.isEqual(props.expression, outerExpression)) || (!outerExpression && !props.expression.length))
        if (!isEqual)
            initInnerExpression()
    }

    function onInnerExpressionChange(): void {

        if (!expressionContext.state.expression.length || SegmentUtils.listHasPlaceholder(expressionContext.state.expression))
            return

        let isExpressionReset = false

        if (!!outerExpression) {
            isExpressionReset = SegmentUtils.isExpressionEmpty(expressionContext.state.expression)
            if (!isExpressionReset && !SegmentUtils.isExpressionFullySet(expressionContext.state.expression))
                return
        }

        const nextExpression = isExpressionReset ? [] : SegmentUtils.getExpressionFromExpForEdition(expressionContext.state.expression)
        updateOuterExpression(nextExpression)
    }

    function updateOuterExpression(nextExpression: SegmentExpressionTP): void {

        if (!_.isEqual(nextExpression, outerExpression))
            setOuterExpression(nextExpression)

        if (!_.isEqual(nextExpression, props.expression)) {
            expressionContext.actions.setIsLoading(true)
            props.onChange(nextExpression)
        }
    }

    function render(content: JSX.Element): JSX.Element {
        return !screenSize.smd
            ? content
            : <CollapseCP
                bgColor={'#fff'}
                panels={[{
                    key: 'lista-segmentacao-filtros',
                    title: 'Filtros',
                    content,
                }]}
            />
    }

    return render(
        <WrapperSCP data-tour={'segment-expression'}>
            <AggregatorListContainerSCP>
                {expressionContext.state.expression.map((unit, i) => <ExpressionUnitICP i={i} key={`exp_unit_${unit.id}`} unit={unit}/>)}
            </AggregatorListContainerSCP>

            {
                ENABLE_DEBUG &&
                <ExpressionPreviewICP innerExpression={expressionContext.state.expression} outerExpression={outerExpression ?? []}/>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
`

const AggregatorListContainerSCP = styled.div`
    height: 95px;
    padding: 8px 8px;
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
`
