import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { Popover } from 'antd'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import React from 'react'
import styled from 'styled-components'
import { SystemUtils } from 'main/common/utils/SystemUtils'

interface ITagTruncateColumnItemCPProps {
    tags: string[]
    onClick?: (tag: any) => void
}

/**
 * Componente wrapper para conteúdo da coluna de tags
 * @TODO Criar CP genérico semelhante para receber objetos Nome e Codigo genericos
 */
export function TagTruncateColumnItemCP(props: ITagTruncateColumnItemCPProps): JSX.Element {

    if (SystemUtils.isEmpty(props.tags))
        return <></>

    return (
        <MainTagsWrapperSCP>
            <TagCP color={'purple'} onClick={() => props.onClick?.(props.tags[0])} key={props.tags[0]} content={props.tags[0]}/>

            {
                props.tags.length > 1 &&
                <>
                    {' '}
                    <TagCP color={'purple'} onClick={(): void => props.onClick?.(props.tags[1])} key={props.tags[1]} content={props.tags[1]}/>

                    <Popover
                        trigger={'hover'}
                        placement={'bottom'}
                        content={
                            <TagsContainerWrapperSCP>
                                {props.tags.map(tag => <TagCP color={'purple'} onClick={(): void => props.onClick?.(tag)} key={tag} content={tag}/>)}
                            </TagsContainerWrapperSCP>
                        }
                    >
                        <EllipsesSCP>
                            <FontAwsomeIconCP type={faEllipsisH}/>
                        </EllipsesSCP>
                    </Popover>
                </>
            }
        </MainTagsWrapperSCP>
    )
}

const MainTagsWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const TagsContainerWrapperSCP = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 280px;
    .ant-tag {
        margin: 3px;
        cursor: pointer;
    }
`

const EllipsesSCP = styled.div`
    background: #f9f0ff;
    color: #2b698c;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 23px;
    border: 1px solid #d3adf6;
    cursor: pointer;
`
