import React from 'react'
import { FINANCIAL_SUB_MENU_ACCOUNTS_KEY, FINANCIAL_SUB_MENU_REPORTS_KEY, FINANCIAL_SUBMENU_TRANSACTIONS_KEY, FinancialScreenUtils } from 'main/modules/financial/screens/screen-financial/utils/FinancialScreenUtils'
import { FinancialViewTP } from 'main/modules/financial/types/FinancialViewTP'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * TELA do modulo financeiro
 */
export function ScreenFinancial(): JSX.Element {

    return (
        <LayoutSubmenuCP<FinancialViewTP>
            defaultSubmenu={'ftCredtView'}
            menuTitle={'Financeiro'}
            loggedDomain={AppStateUtils.getDomainSlug()}
            submenuConfigs={FinancialScreenUtils.getSubMenuConfigs()}
            defaultOpenKeys={[FINANCIAL_SUBMENU_TRANSACTIONS_KEY, FINANCIAL_SUB_MENU_ACCOUNTS_KEY, FINANCIAL_SUB_MENU_REPORTS_KEY]}
        />
    )
}
