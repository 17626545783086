import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'

export class NewEmailFormValidator extends FormModel {

    @IsNotEmpty()
    senderCode: number

    @IsNotEmpty()
    segmentCode: number

    @IsNotEmpty()
    subject: string

    @IsNotEmpty()
    franchisesCodes: number[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
