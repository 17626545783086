import React, { useEffect, useState } from 'react'
import * as One from "common/utils/One"
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { SystemConfig } from 'main/config/SystemConfig';
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { Alert } from 'antd';

export function Restantes(props:any):JSX.Element{
    useEffect(()=>{carregarDados();},[]);
    const [carregando,setCarregando] = useState<any>(false);
    const [dados,setDados] = useState<any>();

    async function carregarDados(){
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/obter-dados-sessoes-pessoa`,
            method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                codigoPessoa:props.codigoPessoa,
                schema:AppStateUtils.getDomainSlug()
            }

           
        }).finally(()=>setCarregando(false)); 
        if(One.ok(resultado)){
           const dt = One.dados(resultado);
           console.log(dt);
           if(dt?.sessoes?.length)setDados(dt.sessoes[0]);
        }
    }

    
    // <br />
    // <Alert message="Info Text" type="info" />
    // <br />
    // <Alert message="Warning Text" type="warning" />
    // <br />
    // <Alert message="Error Text" type="error" />

    if(carregando)return <Alert message="Carregando" type="success" />
    if(!dados)return <Alert message="Nenhuma Sessão" type="error" />
    let tipo:string = "success";
    if(dados.aberta<6)tipo = "info";
    if(dados.aberta<4)tipo = "warning";
    if(dados.aberta<3)tipo = "error";
    //@ts-ignore
    return <Alert message={`Restam ${dados.aberta} de ${dados.fechada+dados.aberta} Sessões.`} type={tipo} />
}