import { styled } from 'main/config/theme/styledWithTheme'
import { RightContentICP } from 'main/modules/people/components/person-action-bar/inner/RightContentICP'
import React from 'react'
import { LeftContentICP } from 'main/modules/people/components/person-action-bar/inner/LeftContentICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'

interface IPersonActionBarCPProps {
    isLoading: boolean
    selectedPeople: number[]
    exportarcsv?:()=>void
    carregandoExportacao?:boolean
}

/**
 * COMPONENTE da area de acoes da listagem de pessoas.
 */
export function PersonActionBarCP(props: IPersonActionBarCPProps): JSX.Element {

    return (
        <WrapperSCP isLoading={props.isLoading}>
            <FlexCP justifyContent={ArrayUtils.isEmpty(props.selectedPeople) ? 'flex-end' : 'space-between'}>

                <LeftContentICP selectedPeople={props.selectedPeople}/>
                <RightContentICP exportarcsv={props.exportarcsv} carregandoExportacao={props.carregandoExportacao}/>

            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isLoading: boolean }>`
    margin: 20px 25px;
    transition: opacity .3s;
    opacity: ${props => (props.isLoading ? .3 : 1)};
`
