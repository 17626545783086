import * as _ from 'lodash'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { SaleStatusLabelEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleStatusEnum'

interface ITreatmentSelectCPProps {
    sessionOptions: ISessionResponseDTO[]
    onTreatmentSelected: (selectedService?: ISessionResponseDTO) => void
    disabled?: boolean
}

/**
 * Uma sessão é formada por um conjunto de tratamentos
 */
export function TreatmentSelectCP(props: ITreatmentSelectCPProps): JSX.Element {

    const [options, setOptions] = useState<SelectOptionTP[]>([])
    useEffect(defineTreatmentOptions, [props.sessionOptions])

    function defineTreatmentOptions(): void{

        if (!props.sessionOptions)
            return

        const opt = props.sessionOptions.map((session) => {

            let saleStatusMsg = ''
            if ([SaleStatusEnum.CANCELLED, SaleStatusEnum.DEFAULTING, SaleStatusEnum.PENDING].includes(session.saleStatus))
                saleStatusMsg = `- !! Venda ${SaleStatusLabelEnum[session.saleStatus]} !!`

            return {
                value: session.code,
                key: `${session.code}`,
                label: `${session.treatment.name} (${session.sessionNumber} de ${session.totalSessions}) ${saleStatusMsg}`
            }
        })

        setOptions([...opt])
    }

    function onTreatmentSelect(serviceCode: number): void {
        const selectedService: OrUndefTP<ISessionResponseDTO> = _.find(props.sessionOptions, treat => treat.code === serviceCode)
        props.onTreatmentSelected(selectedService)
    }

    return (
        <SelectCP
            disabled={props.disabled}
            options={options}
            label={'Selecione um serviço'}
            notFoundContent={'Nenhum serviço encontrado'}
            onChange={onTreatmentSelect}
            loading={!props.sessionOptions}
            allowClear={true}
        />
    )
}
