import React, { useEffect, useState } from 'react'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface IPersonAutoCompletePickerCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    onlyWithWorkSchedule?: boolean
    showLoggedUserAsInitialOption?: boolean
    tempo?:any
}

/**
 * Seletor de usuários com autocomplete.
 */
export function AutocompleteUserCP<FModelTP extends FormModel = any>(props: IPersonAutoCompletePickerCPProps<FModelTP>): JSX.Element {

    const [initialOptions, setInitialOptions] = useState<INameAndCodeResponseDTO[]>()

    useEffect(init, [props.initialOptions, props.initialOptions])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.initialOptions)
            setInitialOptions(props.initialOptions)

        if (!props.showLoggedUserAsInitialOption)
            return

        const loggedUserNameAndCode: INameAndCodeResponseDTO = {
            code: AppStateUtils.getLoggedUserData()!.user.code,
            name: AppStateUtils.getLoggedUserData()!.user.name,
        }
        setInitialOptions([loggedUserNameAndCode])
    }

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={UserRequests.searchUsers}
            customFilters={{ onlyWithWorkSchedule: props.onlyWithWorkSchedule }}
            showOptionsOnLoad={true}
            tempo={props.tempo}
            initialOptions={initialOptions}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
