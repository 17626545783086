import { ColumnProps } from 'antd/es/table'
import { TextCP } from 'main/common/components/text/TextCP'
import { DomainStatusCP } from 'main/modules/marketing/components/domain-status/DomainStatusCP'
import { IMarketingDomainConfigResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainConfigResponseDTO'
import React from 'react'

/**
 * Definicao das colunas da tabela de dominios.
 *
 * @author renatofs
 */
export const MKT_DOMAIN_TABLE_COLUMNS: Array<ColumnProps<IMarketingDomainConfigResponseDTO>> = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: any, config: IMarketingDomainConfigResponseDTO) => (
            <DomainStatusCP status={config.status} showUpdateButton={false}/>
        )
    }, {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
        render: (text: any, config: IMarketingDomainConfigResponseDTO) => <TextCP text={config.identifier.toUpperCase()} wrap={true}/>
    }, {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
        render: (text: any, config: IMarketingDomainConfigResponseDTO) => <TextCP text={config.host} wrap={true} copyable={true}/>
    }, {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value',
        render: (text: any, config: IMarketingDomainConfigResponseDTO) => <TextCP text={config.value} copyable={true} wrap={true}/>
    }
]
