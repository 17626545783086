/**
 * UTILITARIOS: Para mansear objetos genericos.
 */
export const ObjectUtils = {

    /** Retorna copia de 01 objeto removendo 01 lista de propriedaades do mesmo. */
    getObjectsWithoutSomeProps<ChildPropsTP> (obj: any, propsToRemove: Array<keyof ChildPropsTP>): {} {

        const clearedObj = { ...obj }

        for (const propToRemove of propsToRemove)
            delete clearedObj[propToRemove]

        return clearedObj
    },

    trimAllAttributesFromObject (obj: any): void {
        Object.keys(obj).forEach(k => obj[k] = typeof obj[k] === 'string' ? (obj[k] as string).trim() : obj[k])
    },
}
