import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetLeadEnum } from 'main/modules/dashboard/services/enums/WidgetLeadEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets total de leads planejados para hoje.
 */
export function WidgetTotalLeadsTodayCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Leads planejados para hoje'}
            help={'Total de LEADs que possui uma atividade a ser realizada hoje'}
            requestConfigGetter={
                DashboardRequests.getLeadWidgets(
                    WidgetLeadEnum.PLANNEDS_TODAY,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
            footerTitle={'Progresso de contato'}
            footerHelp={'Progresso da conclusão das atividades dos LEADs programados para hoje'}
            secondCalc={'ratioBetweenSecondValueAndMainValue'}
        />
    )
}
