import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ShotDetailsReportUtils } from 'main/modules/marketing/components/shot-details-report/inner/ShotDetailsReportUtils'
import { IMarketingShotReportListItemResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingShotReportListItemResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'
import React from 'react'

/**
 * UTILITARIOS do componente de relatorio de disparos
 *
 * @author renatofs
 */
export const ShotDetailsReportTableUtils = {

    /** Gera & retorna configuracao de colunas do componente de tabelas. */
    getTableColumnsConfig(
        reportType: MarketingShotReportTypeEnum,
        shotType: MarketingAccountTypeEnum,
        onSelectPerson: (personCode: number) => void
    ): Array<ColumnProps<IMarketingShotReportListItemResponseDTO>> {

        return [
            {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
                render: (text, report: IMarketingShotReportListItemResponseDTO) => report.personName
            }, {
                title: shotType === MarketingAccountTypeEnum.EMAIL ? 'E-mail' : 'Telefone',
                dataIndex: 'email',
                key: 'email',
                render: (text, report: IMarketingShotReportListItemResponseDTO) => report.personEmail
            }, {
                title: ShotDetailsReportUtils.getColumnNameByReportType(reportType),
                dataIndex: 'reportData',
                key: 'reportData',
                render: (text, report: IMarketingShotReportListItemResponseDTO) => report.reportData
            }, {
                title: '',
                dataIndex: 'act',
                key: 'act',
                align: 'right',
                render: (text, report: IMarketingShotReportListItemResponseDTO) => (
                    <TooltipCP text={'Mais detalhes'}>
                        <ButtonCP size={'small'} type={'primary'} onClick={() => onSelectPerson(report.personCode)}>
                            <FontAwesomeIcon icon={faSearchPlus} color={ThemeAnt.white}/>
                        </ButtonCP>
                    </TooltipCP>
                )
            }
        ]
    }

}
