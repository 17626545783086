import React, { useEffect, useState } from 'react'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { SegmentUnitValueTP } from 'main/modules/segment/types/unit/SegmentUnitValueTP'
import { UnitMainICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitMainICP'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { UnitValuePopoverContentICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValuePopoverContentICP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { AggregatorContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorContext'
import * as _ from 'lodash'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'

interface IUnitICPProps {

    id: string
    onChangePopoverVisibility: () => void
    isLastUnit: boolean

    field?: SegmentUnitFieldsEnum
    operator?: SegmentUnitCompareOperatorsEnum
    value?: SegmentUnitValueTP
}

/**
 * COMPONENTE
 * Componente de montagem de 'unidade' na construcao de filtros de lista de segmentacao.
 */
export const UnitICP = ComponentUtils.memoizeCp((props: IUnitICPProps): JSX.Element => {

    const [showPopover, setShowPopover] = useState<boolean>(false)
    const [enablePopoverExhibition, setEnablePopoverExhibition] = useState<boolean>(true)
    const [isValueFieldDirty, setIsValueFieldDirty] = useState<boolean>(false)

    const expressionContext = ExpressionContext.useState()
    const aggregatorContext = AggregatorContext.use()
    const unitContext = UnitContext.use()

    useEffect(() => unitContext.actions.setId(props.id), [props.id])
    useEffect(() => unitContext.actions.setIsLastUnit(props.isLastUnit), [props.isLastUnit])
    useEffect(() => { onPropsChange() }, [props.field, props.operator, props.value])
    useEffect(onExpressionLoadingChange, [expressionContext.state.isLoading])
    useEffect(onShowPopoverChange, [unitContext.state.isSelected, unitContext.state.isSelecting])
    useEffect(onUnitChange, [unitContext.state.isSelected, unitContext.state.value])

    async function onPropsChange(): Promise<void> {

        if (props.field !== unitContext.state.field)
            unitContext.actions.setField(props.field)

        if (props.operator !== unitContext.state.operator)
            unitContext.actions.setOperator(props.operator)

        if (!haveUnitValueChanged())
            return

        let _valueWithLabel: SegmentUnitValueWithLabelTP[]

        if (!!props.value) {
            unitContext.actions.setIsLoadingValueWithLabel(true)
            _valueWithLabel = await SegmentUtils.getValueWithLabelFromValue(props.value, props.field)
            unitContext.actions.setIsLoadingValueWithLabel(false)
        } else
            _valueWithLabel = []

        unitContext.actions.setValue(_valueWithLabel)
    }

    function onUnitChange(): void {

        const mustUpdate = (
            unitContext.state.isSelected
            && unitContext.state.valueChangingCount
            && (props.field !== unitContext.state.field || props.operator !== unitContext.state.operator || haveUnitValueChanged())
        )

        if (!mustUpdate)
            return

        aggregatorContext.actions.editUnit({
            itemType: 'unit',
            id: props.id,
            field: unitContext.state.field!,
            operator: unitContext.state.operator!,
            value: SegmentUtils.getValueFromValueWithLabel(unitContext.state.value),
        })
    }

    function haveUnitValueChanged(): boolean {
        const currentValue = SegmentUtils.getValueFromValueWithLabel(unitContext.state.value ?? [])
        return !_.isEqual(props.value, currentValue)
    }

    function onExpressionLoadingChange(): void {
        setEnablePopoverExhibition(!expressionContext.state.isLoading)
        if (expressionContext.state.isLoading)
            onShowPopoverChange(false)
    }

    function onShowPopoverChange(enable = enablePopoverExhibition): void {

        const _showPopover = (enable && !unitContext.state.isSelecting && unitContext.state.isSelected)
        if (_showPopover === showPopover)
            return

        setShowPopover(_showPopover)
        props.onChangePopoverVisibility()

        if (!_showPopover)
            setIsValueFieldDirty(false)
    }

    function onFinish(valueWithLabel?: SegmentUnitValueWithLabelTP[]): void {
        setShowPopover(false)
        if (isValueFieldDirty && !!valueWithLabel)
            unitContext.actions.setValue(valueWithLabel)
    }

    return (
        <PopOverCP
            visible={showPopover}
            trigger={'click'}
            placement={'bottom'}
            onClose={onFinish}
            content={
                showPopover &&
                <UnitValuePopoverContentICP
                    onFinish={onFinish}
                    onValueChange={value => setIsValueFieldDirty(!_.isEqual(value, unitContext.state.value))}
                />
            }
        >
            <UnitMainICP isUnitValuePopoverShown={showPopover}/>
        </PopOverCP>
    )
})
