import React, { useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { DrawerProductReleaseCP } from 'main/modules/products/components/drawer-product-release/DrawerProductReleaseCP'
import { TableProductReleaseCP } from 'main/modules/products/components/table-product-release/TableProductReleaseCP'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { AdminRoutes } from 'main/modules/admin/AdminRoutes'

/**
 * Configuracoes de pacotes de antendimento.
 */
export function ScreenContentProductReleaseCP(): JSX.Element {

    const [shouldForceUpdateList, setShouldForceUpdateList] = useState<boolean>(true)
    const [isProductReleaseDrawerVisible, setIsProductReleaseDrawerVisible] = useState<boolean>(false)
    const [selectedProductReleaseCode, setSelectedProductReleaseCode] = useState<number>()

    return (
        <AccessControlCP franchiseOrFranchisor={'franchisor'} routeToReturn={AdminRoutes.ATTENADANCE_FRANCHISE_PRODUCT} redirect={true}>
            <HeaderCP title={'Configuração dos Pacotes de Atendimento'}>
                <HeaderButtonICP
                    onClick={() => {
                        setSelectedProductReleaseCode(undefined)
                        setIsProductReleaseDrawerVisible(true)
                    }}
                    icon={'plus'}
                    label={'Novo Pacote'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableProductReleaseCP
                    shouldReloadReleases={shouldForceUpdateList}
                    onReleasesReloaded={() => setShouldForceUpdateList(false)}
                    onEdit={(code) => {
                        setSelectedProductReleaseCode(code)
                        setIsProductReleaseDrawerVisible(true)
                    }}
                />
            </LayoutSubmenuContentCP>

            <DrawerProductReleaseCP
                visible={isProductReleaseDrawerVisible}
                productReleaseCode={selectedProductReleaseCode}
                onClose={(dataChanged) => {
                    if (dataChanged)
                        setShouldForceUpdateList(true)
                    setIsProductReleaseDrawerVisible(false)
                }}
            />
        </AccessControlCP>
    )
}
