import React from 'react'
import { EditorVarTP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tiny-mce/inner/drawer-editor-vars/inner/EditorVarTP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import styled from 'styled-components'

interface IDrawerSaleContractVarsCPProps {
    vars: EditorVarTP[]
    onClose: () => void
    visible: boolean
}

/**
 * Drawer para listagem de variaveis para contrato de venda.
 */
export function DrawerEditorVarsCP(props: IDrawerSaleContractVarsCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Variáveis Disponíveis'}
            visible={props.visible}
            onClose={props.onClose}
            width={700}
        >
            {
                props.vars.map((varGroup) => (
                    <DescriptionWrapperSCP key={varGroup.title}>
                        <DescriptionCP
                            title={varGroup.title}
                            bordered={true}
                            numberOfColumns={1}
                            items={
                                varGroup.vars.map((varItem) => ({
                                    label: varItem.description,
                                    text: <TextCP copyable={true} text={varItem.id} onCopy={props.onClose}/>,
                                }))
                            }
                        />
                    </DescriptionWrapperSCP>
                ))
            }
        </DrawerCP>
    )
}

const DescriptionWrapperSCP = styled.div`

  .ant-descriptions-title {
    margin: 20px 0px 0px 0px;
  }

`
