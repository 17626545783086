import { ModalCP } from 'main/common/components/modal/ModalCP'
import { ImportUpdateBehaviorEnum } from 'main/common/enums/ImportUpdateBehaviorEnum'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ImportFormICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/ImportFormICP'
import { ModalLeadImportErrorsICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/ModalLeadImportErrorsICP'
import { StepImportBehaviorICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/StepImportBehaviorICP'
import { StepUploadICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/StepUploadICP'
import { ImportFormValidator } from 'main/modules/sales-funnel/components/modal-lead-import/inner/ImportFormValidator'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AlertValidationStatusICP } from './inner/components/AlertValidationStatusICP'
import { UploadStateTP } from './inner/types/UploadStateTP'

interface IModalLeadImportCPProps {
    isVisible: boolean
    onCancel: () => void
    onUploadSuccess: () => void
}

/**
 * COMPONENTE
 * Modal para importacao de arquivo com lista de pessoas.
 */
export function ModalLeadImportCP(props: IModalLeadImportCPProps): JSX.Element {

    const [uploadState, setUploadState] = useState<UploadStateTP>()
    const [validationErrors, setValidationErrors] = useState<ImportValidationErrorsLineTP[]>()
    const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false)

    const [formValidator] = useState<ImportFormValidator>(new ImportFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    useEffect(onVisibilityChange, [props.isVisible])

    function onVisibilityChange(): void {

        /**
         * Resetar estado da ultima importacao todas vez que o modal for reaberto.
         * Exceto se tiver ocorrido falha de validacao da ultima tentativa. Neste caso
         * preservamos a notificacao para o usuario poder consultar novamente, se quiser.
         */

        if (props.isVisible && uploadState !== 'failure')
            setUploadState(undefined)
    }

    function onUploadStateChange(nextState: UploadStateTP, errors?: ImportValidationErrorsLineTP[]): void {
        setUploadState(nextState)
        if (nextState === 'failure')
            setValidationErrors(errors)
    }

    function onCurrentBehaviorChange(nextBehavior: ImportUpdateBehaviorEnum): void {
        formStateManager.changeFieldValue('updateBehavior', nextBehavior)
        formStateManager.validate()
    }

    function onClose(): void {
        if (uploadState === 'success')
            props.onUploadSuccess()
    }

    const isUploadFinished = (!!uploadState && uploadState !== 'ongoing')
    const disableActions = (uploadState === 'ongoing')
    const enableUpload = (formStateManager.isValid && formStateManager.isDirty)

    return (
        <ModalCP
            visible={props.isVisible}
            title={'Importar'}
            onClose={onClose}
            onCancel={props.onCancel}
            disableOutsideClickClosing={true}
            noFooter={uploadState !== 'success'}
            singleButton={true}
            width={630}
            closable={!disableActions}
            top={45}
        >
            <ImportFormICP formStateManager={formStateManager}/>

            <StepImportBehaviorICP
                onBehaviorSelected={onCurrentBehaviorChange}
                currentBehavior={formStateManager.getFieldValue('updateBehavior')}
                disabled={disableActions}
            />

            <StepUploadICP
                onError={() => onUploadStateChange('error')}
                onValidationFailure={errors => onUploadStateChange('failure', errors)}
                onValidationSuccess={() => onUploadStateChange('success')}
                onUploadStart={() => onUploadStateChange('ongoing')}
                disable={!enableUpload}
                tooltip={!enableUpload ? 'Preencha o formulário corretamente para prosseguir' : undefined}
                requestDto={enableUpload ? formStateManager.getFormValues() : undefined}
            />

            {
                isUploadFinished &&
                <AlertContainerSCP>
                    <AlertValidationStatusICP
                        uploadState={uploadState!}
                        onCheckErrorsClick={() => setShowErrorsModal(true)}
                    />
                </AlertContainerSCP>
            }

            <ModalLeadImportErrorsICP
                visible={showErrorsModal}
                errors={validationErrors ?? []}
                onClose={() => setShowErrorsModal(false)}
            />
        </ModalCP>
    )
}

const AlertContainerSCP = styled.div`
    margin-top: 10px;
`
