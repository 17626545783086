import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { PersonBulkOpportunityFormICP } from 'main/modules/people/components/modal-person-bulk-opportunity/inner/PersonBulkOpportunityFormICP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { TableBulkOperations } from 'main/modules/admin/components/table-bulk-operations/TableBulkOperations'
import { BulkOperationEnum } from 'main/modules/admin/services/bulk-operation/enums/BulkOperationEnum'

interface ICPProps {
    visible: boolean
    queryExpression: SegmentExpressionTP
    onClose: () => void
}

/**
 * Componente de modal para poder evoluir sessões
 */
export function ModalPersonBulkOpportunityCP(props: ICPProps): JSX.Element {

    const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false)
    const [shouldLoadHist, setShouldLoadList] = useState<boolean>(true)

    useEffect(init, [props.visible])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.visible)
            return

        setIsHistoryVisible(false)
    }

    return (
        <ModalCP
            title={'Gerar oportunidades para o FUNIL em massa'}
            width={isHistoryVisible ? '98%' : 400}
            top={10}
            visible={props.visible}
            onCancel={props.onClose}
            noFooter={true}
        >
            <FlexCP justifyContent={'flex-end'} marginBottom={30}>
                <ButtonCP
                    onClick={() => setIsHistoryVisible(!isHistoryVisible)}
                    type={'link'}
                >
                    {`${isHistoryVisible ? 'Esconder' : 'Ver'} Histórico`}
                </ButtonCP>
            </FlexCP>

            <FlexCP justifyContent={'space-between'}>

                <LeftWrapperSCP>
                    <PersonBulkOpportunityFormICP
                        queryExpression={props.queryExpression}
                        onSaveOpportunities={() => {
                            setIsHistoryVisible(true)
                            setShouldLoadList(true)
                        }}
                    />
                </LeftWrapperSCP>

                <ConditionalRenderCP shouldRender={isHistoryVisible}>
                    <RightWrapperSCP>
                        <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                            <TitleCP underLine={true} textSize={'normal'}>
                                Histórico
                            </TitleCP>
                            <ButtonCP
                                icon={'reload'}
                                type={'link'}
                                onClick={() => setShouldLoadList(true)}
                            >
                                atualizar
                            </ButtonCP>
                        </FlexCP>

                        <TableBulkOperations
                            type={BulkOperationEnum.CREATE_OPPORTUNITY_BY_EXPRESSION}
                            onListLoaded={() => setShouldLoadList(false)}
                            shouldReloadList={shouldLoadHist}
                        />
                    </RightWrapperSCP>
                </ConditionalRenderCP>
            </FlexCP>
        </ModalCP>
    )
}

const LeftWrapperSCP = styled.div`
  flex: 1;
`

const RightWrapperSCP = styled.div`
  width: 50%;
  border-left: dashed 2px #ecececad;
  margin-left: 20px;
  padding-left: 20px;
`
