import React, { useEffect, useState } from 'react'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ProductReleaseTreatmentFormModel } from 'main/modules/products/components/popover-product-release-treatment/inner/ProductReleaseTreatmentFormModel'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { AutocompleteTreatmentCP } from 'main/modules/admin/components/autocomplete-treatment/AutocompleteTreatmentCP'

interface IPopoverProductReleaseTreatmentCPProps {
    children: JSX.Element
    visible: boolean
    treatmentConfig?: IProductReleaseTreatmentConfigResponseDTO
    onSave: (data: IProductReleaseTreatmentConfigResponseDTO) => void
    onClose: () => void
}

/**
 * Abre um popover com o formulario de configuracoes de atendimento de um pacote de atendimento.
 */
export function PopoverProductReleaseTreatmentCP(props: IPopoverProductReleaseTreatmentCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<ProductReleaseTreatmentFormModel>((new ProductReleaseTreatmentFormModel()))
    const formStateManager = useFormStateManager<ProductReleaseTreatmentFormModel>(formValidator)

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        if (!props.visible)
            return

        if (!props.treatmentConfig) {
            formStateManager.reset(new ProductReleaseTreatmentFormModel({}))
            return
        }

        setFormValidator(new ProductReleaseTreatmentFormModel({
            treatmentCode: props.treatmentConfig.treatment.code,
            treatmentName: props.treatmentConfig.treatment.name,
            totalSessions: props.treatmentConfig.totalSessions,
            duration: props.treatmentConfig.sessionDuration,
            totalPersons: props.treatmentConfig.personPerSession
        }))
    }

    /**
     * Ao clicar em salvar os dados
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        // Trasforma os dados do form para DTO e manda de volta para tela
        const dto: IProductReleaseTreatmentConfigResponseDTO = {
            treatment: {
                code: formValues.treatmentCode,
                name: formValues.treatmentName
            },
            totalSessions: formValues.totalSessions,
            sessionDuration: formValues.duration,
            personPerSession: formValues.totalPersons,
            specificAttendant: true, // Ainda nao estamos utilizando esse atributo, por isso esta fixo
        }
        props.onSave(dto)
    }

    return (
        <PopOverCP
            destroyOnHide={true}
            visible={props.visible}
            onClose={props.onClose}
            placement={'topRight'}
            content={
                <>
                    <RowCP>
                        <ColumnCP size={16}>
                            <AutocompleteTreatmentCP
                                disabled={!!props.treatmentConfig}
                                formStateManager={formStateManager}
                                fieldName={'treatmentCode'}
                                label={'Atendimento'}
                                required={true}
                                returnFullOption={true}
                                onChange={(selected) => {
                                    if (!selected)
                                        return
                                    formStateManager.changeFieldValue('treatmentCode', selected.value)
                                    formStateManager.changeFieldValue('treatmentName', selected.label)
                                }}
                            />
                        </ColumnCP>
                        <ColumnCP size={8}>
                            <InputCP
                                label={'Nº Sessões'}
                                fieldName={'totalSessions'}
                                formStateManager={formStateManager}
                                required={true}
                                type={'number'}
                                min={1}
                            />
                        </ColumnCP>
                    </RowCP>
                    <RowCP>
                        <ColumnCP size={12}>
                            <InputCP
                                label={'Duração (min)'}
                                type={'number'}
                                fieldName={'duration'}
                                formStateManager={formStateManager}
                                required={true}
                                min={1}
                            />
                        </ColumnCP>
                        <ColumnCP size={12}>
                            <InputCP
                                label={'Clientes por sessão'}
                                type={'number'}
                                formStateManager={formStateManager}
                                fieldName={'totalPersons'}
                                required={true}
                                min={1}
                            />
                        </ColumnCP>
                    </RowCP>

                    <FlexCP marginTop={20} justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            onClick={onSave}
                        >
                            Salvar
                        </ButtonCP>
                    </FlexCP>
                </>
            }
        >
            { props.children }
        </PopOverCP>
    )
}
