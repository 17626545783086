import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import styled from 'styled-components'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetEvaluationEnum } from 'main/modules/dashboard/services/enums/WidgetEvaluationEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets das performance de Avaliacoes.
 */
export function WidgetsRowActualEvaluationIndicatorsCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'calendar'}>
                { props.userCode ? 'Status atual das suas Avaliações' : 'Status atual das Avaliações da Unidade' }
            </TitleCP>

            <WidgetsRowCP>
                <CardDashboardWidgetCP
                    title={'Avaliações de hoje'}
                    help={'Minha agenda de avaliações a serem realizadas hoje'}
                    requestConfigGetter={
                        DashboardRequests.getEvaluationWidgets(
                            WidgetEvaluationEnum.PLANNEDS_TODAY,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                    footerTitle={'Taxa de comparecimento'}
                    secondCalc={'ratioBetweenSecondValueAndMainValue'}
                    footerHelp={'Das avaliações agendadas para hoje quantas já foram realizadas'}
                />

                <CardDashboardWidgetCP
                    title={'Agendadas para o mesmo dia'}
                    help={'Avaliações criadas hoje que foram agendadas para hoje mesmo'}
                    requestConfigGetter={
                        DashboardRequests.getEvaluationWidgets(
                            WidgetEvaluationEnum.SCHEDULEDS_TODAY,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />

                <CardDashboardWidgetCP
                    title={'Agendadas para o dia seguinte'}
                    help={'Avaliações criadas hoje que foram agendadas para o dia seguinte'}
                    requestConfigGetter={
                        DashboardRequests.getEvaluationWidgets(
                            WidgetEvaluationEnum.SCHEDULEDS_TOMORROW,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />

                <CardDashboardWidgetCP
                    title={'Agendadas para mais de 48h'}
                    help={'Avaliações criadas hoje que foram agendadas para depois de 48h'}
                    requestConfigGetter={
                        DashboardRequests.getEvaluationWidgets(
                            WidgetEvaluationEnum.SCHEDULEDS_MORE_48HRS,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />
            </WidgetsRowCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 50px;
`