import React from 'react'
import { IBulkOperationResponseDTO } from 'main/modules/admin/services/bulk-operation/responses/IBulkOperationResponseDTO'
import styled from 'main/config/theme/styledWithTheme'
import { TextCP } from 'main/common/components/text/TextCP'

interface IImportSummaryCPProps {
    log: IBulkOperationResponseDTO
}

/**
 * COMPONENTE
 * Tabela resumo da situacao final de 01 importacao.
 */
export function BulkOperationImportSummaryICP(props: IImportSummaryCPProps): JSX.Element {

    return (
        <WrapperSCP>

            <LineSCP>
                <TextCP text={'Total de Registros:'}/>
                <ValueSCP>{props.log.totalRecords}</ValueSCP>
            </LineSCP>

            <LineSCP>
                <TextCP text={'Sucesso:'}/>
                <ValueSCP>{props.log.totalRecordsSuccessfully}</ValueSCP>
            </LineSCP>

            <LineSCP>
                <TextCP text={'Erro ao importar:'}/>
                <ValueSCP>{props.log.totalRecordsWithError}</ValueSCP>
            </LineSCP>

            <LineSCP>
                <TextCP text={'Linhas puladas:'}/>
                <ValueSCP>{props.log.totalRecordsIgnored}</ValueSCP>
            </LineSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    cursor: help;
    min-width: 185px;
`

const LineSCP = styled.div`

    display: flex;
    justify-content: space-between;
    width: 100%;

    &:first-child {
        margin-bottom: 10px;
    }
`

const ValueSCP = styled.div`
    margin-left: 15px;
    font-weight: bold;
    color: ${props => props.theme.primaryColor};
`
