import smsImg from 'main/modules/marketing/components/preview-phone-text/inner/sms-text-mock.png'
import whtasappImg from 'main/modules/marketing/components/preview-phone-text/inner/whatsapp-text-mock.jpeg'
import React from 'react'
import styled from 'styled-components'

export const SMS_INNER_CLASS = 'sms-inner'
export const SMS_IMAGE_CLASS = 'sms-image'
export const SMS_TEXT_BOX_CLASS = 'text-box'

interface ISmsPreviewCPProps {
    text?: string
    type?: 'sms' | 'whatsapp'
}

/**
 * Componente que exibe uma imagem de celular com texto da msg de sms
 */
export function PreviewPhoneTextCP(props: ISmsPreviewCPProps): JSX.Element {

    return (
        <>
            {
                props.type === 'whatsapp'
                    ?
                    <WhatsappPreviewSCP>
                        <div className={SMS_INNER_CLASS}>
                            <div className={SMS_IMAGE_CLASS}>
                                <img src={whtasappImg}/>
                                <div className={SMS_TEXT_BOX_CLASS}>
                                    <p>{props.text}</p>
                                </div>
                            </div>
                        </div>
                    </WhatsappPreviewSCP>
                    :
                    <SmsPreviewSCP>
                        <div className={SMS_INNER_CLASS}>
                            <div className={SMS_IMAGE_CLASS}>
                                <img src={smsImg}/>
                                <div className={SMS_TEXT_BOX_CLASS}>
                                    <p>{props.text}</p>
                                </div>
                            </div>
                        </div>
                    </SmsPreviewSCP>
            }
        </>
    )

}

const WhatsappPreviewSCP = styled.div`
    width: 100%;

    img {
      border-radius: 50px;
    }
  
    .${SMS_INNER_CLASS} {
        display: flex;
        justify-content: center;

        .${SMS_IMAGE_CLASS} {
            position: relative;

            .${SMS_TEXT_BOX_CLASS} {
                position: absolute;
                top: 16%;
                left: 10%;
                width: 70%;
                height: auto;
                border-radius: 10px;
                background-color: #efefef;
                padding: 10px;
                word-break: break-all;
                white-space: normal;
            }
        }
    }
`

const SmsPreviewSCP = styled.div`
    width: 100%;

    .${SMS_INNER_CLASS} {
        display: flex;
        justify-content: center;

        .${SMS_IMAGE_CLASS} {
            position: relative;

            .${SMS_TEXT_BOX_CLASS} {
                position: absolute;
                top: 22%;
                left: 18%;
                width: 223px;
                height: auto;
                border-radius: 10px;
                background-color: #efefef;
                padding: 10px;
                white-space: -moz-pre-wrap !important;
                word-break: break-all;
                white-space: normal;
            }
        }
    }
`
