import { AttendanceConfirmationToken } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/types/AttendanceConfirmationToken'
import { CryptoUtils } from 'submodules/nerit-framework-utils/utils/CryptoUtils'
import { CustomerPortalUserAppointmentResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalUserAppointmentResponseDTO'
import { ChargeChangePaymentDataToken } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/types/ChargeChangePaymentDataToken'

/**
 */
export class CustomerPortalUtils {

    /**
     */
    static generatePresenceConfirmationToken(dto: CustomerPortalUserAppointmentResponseDTO): string {

        const generateToken: AttendanceConfirmationToken = {
            userAppointment: dto
        }

        return CryptoUtils.reversibleEncrypt(JSON.stringify(generateToken))
    }

    /**
     */
    static decryptPresenceConfirmationToken(token?: string): AttendanceConfirmationToken | undefined {

        if (!token)
            return undefined

        try {
            return JSON.parse(CryptoUtils.decrypt(token))

        } catch (e) {
            console.log('Token invalido', e)
            return undefined
        }
    }

    /**
     */
    static generateChangePaymentDataToken(chargeCode: number, bearerToken: string): string {

        const generateToken: ChargeChangePaymentDataToken = {
            chargeCode: chargeCode,
            userBearerToken: bearerToken
        }

        return CryptoUtils.reversibleEncrypt(JSON.stringify(generateToken))
    }

    /**
     */
    static decryptChangePaymentDataToken(token?: string): ChargeChangePaymentDataToken | undefined {

        if (!token)
            return undefined

        try {
            return JSON.parse(CryptoUtils.decrypt(token))

        } catch (e) {
            console.log('Token invalido', e)
            return undefined
        }
    }

}
