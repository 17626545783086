import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class CreditCardDataFormModel extends FormModel {

    @IsRequired()
    cvc: string

    @IsRequired()
    expiry: string

    @IsRequired()
    name: string

    @IsRequired()
    number: string

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
