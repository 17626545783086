import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { TableFinancialTransactionsCreditCP } from 'main/modules/financial/components/table-financial-transactions-credit/TableFinancialTransactionsCreditCP'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { ChargingPaymentMethodSummaryICP } from 'main/modules/financial/screens/screen-financial/content/screen-content-charging/inner/ChargingPaymentMethodSummaryICP'
import { FinancialTransactionFiltersCP } from 'main/modules/financial/components/financial-transaction-filters/FinancialTransactionFiltersCP'
import { ChargingSummaryHeaderICP } from 'main/modules/financial/screens/screen-financial/content/screen-content-charging/inner/ChargingSummaryHeaderICP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * Componente que mostra faturas e filtros
 */
export function ScreenContentChargingCP(): JSX.Element {

    const [filters, setFilters] = useState<IFinancialTransactionSearchRequestDTO>({})
    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)
    const [shouldLoadSummary, setShouldLoadSummary] = useState<boolean>(false)

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        setFilters({
            status: [FinancialTransactionStatusEnum.OVERDUE],
            franchiseCodes: AppStateUtils.getCurrentFranchise()?.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code],
            considerOnlySaleTransactions: true
        })

        setShouldLoadReport(true)
        setShouldLoadSummary(true)
    }

    return (
        <ContentWrapperSCP>
            <ContentSCP>

                <FinancialTransactionFiltersCP
                    currentFilters={filters}
                    onChangeFilters={(dto) => {
                        setFilters(dto)
                        setShouldLoadReport(true)
                        setShouldLoadSummary(true)
                    }}
                    leftContent={
                        <ChargingSummaryHeaderICP
                            filters={filters}
                            shouldReloadSummary={shouldLoadSummary}
                            onSummaryLoaded={() => setShouldLoadSummary(false)}
                        />
                    }
                    showReferenceDateFastFilter={true}
                    showReferenceIntervalFilter={true}
                    showPaymentDateFilters={false}
                    showSaleDateFilters={true}
                    showPaymentPartner={true}
                    loading={shouldLoadReport || shouldLoadSummary}
                />

                <ChargingPaymentMethodSummaryICP
                    filters={filters}
                    onChangePaymentMethod={(_paymentMethod) => {
                        setFilters({
                            ...filters,
                            paymentMethod: _paymentMethod
                        })
                        setShouldLoadReport(true)
                    }}
                    onSummaryLoaded={() => setShouldLoadSummary(false)}
                    shouldReloadSummary={shouldLoadSummary}
                />

                <TableFinancialTransactionsCreditCP
                    filters={filters}
                    setFilters={setFilters}
                    shouldReloadList={shouldLoadReport}
                    onReportLoaded={() => setShouldLoadReport(false)}
                    onUpdateAnyData={() => {
                        setShouldLoadReport(true)
                        setShouldLoadSummary(true)
                    }}
                    showReferenceDate={true}
                    isChargingScreen={true}
                />

            </ContentSCP>
        </ContentWrapperSCP>
    )
}

const ContentSCP = styled.div`
    width: 100%;
`

const ContentWrapperSCP = styled.div`
    margin-top: 25px;
    display: flex;
    overflow-x: auto;
    justify-content: center;
`
