import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { TreatmentFormICP } from 'main/modules/products/components/drawer-treatment/inner/TreatmentFormICP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { TreatmentFormModel } from 'main/modules/products/components/drawer-treatment/inner/TreatmentFormModel'
import { TreatmentRequests } from 'main/modules/admin/services/treatment/TreatmentRequests'
import { ITreatmentSaveRequestDTO } from 'main/modules/admin/services/treatment/dtos/request/ITreatmentSaveRequestDTO'
import { ITreatmentResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentResponseDTO'

interface IDrawerTreatmentCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    treatmentCode?: number
}

/**
 * Tela de listagem de tratamentos.
 */
export function DrawerTreatmentCP(props: IDrawerTreatmentCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<TreatmentFormModel>((new TreatmentFormModel()))
    const formStateManager = useFormStateManager<TreatmentFormModel>(formValidator)
    const [loadedTreatment, setLoadedTreatment] = useState<ITreatmentResponseDTO>()

    const getTreatmentRequest = useRequest<ITreatmentResponseDTO>()
    useEffect(onGetTreatmentRequestChange, [getTreatmentRequest.isAwaiting])

    const saveTreatmentRequest = useRequest<ITreatmentResponseDTO>()
    useEffect(onSaveTreatmentRequestChange, [saveTreatmentRequest.isAwaiting])

    const deleteTreatmentRequest = useRequest<void>('none')
    useEffect(onDeleteTreatmentRequestChange, [deleteTreatmentRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        if (!props.visible)
            return

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        setLoadedTreatment(undefined)
        formStateManager.reset(new TreatmentFormModel({}))

        if (props.treatmentCode)
            return getTreatmentRequest.runRequest(TreatmentRequests.getTreatment(props.treatmentCode))

    }

    /**
     * Retorno da requisicao que obtem o tratamento selecionado.
     */
    function onGetTreatmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getTreatmentRequest, 'Erro obter tratamento'))
            return

        const treatmentResult = getTreatmentRequest.responseData!
        setLoadedTreatment(treatmentResult)

        setFormValidator(new TreatmentFormModel({
            name: treatmentResult.name,
            groupCodes: treatmentResult.groups.map(trtGroup => trtGroup.code),
            formCode: treatmentResult.form?.code,
            minDaysBetweenSessions: treatmentResult.minDaysBetweenSessions,
        }))

    }

    /**
     * Salva tratamento.
     */
    async function saveTreatment(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: ITreatmentSaveRequestDTO = {
            name: formValues.name,
            formCode: formValues.formCode,
            treatmentGroupCodes: formValues.groupCodes,
            minDaysBetweenSessions: formValues.minDaysBetweenSessions,
        }

        if (!!props.treatmentCode)
            saveTreatmentRequest.runRequest(TreatmentRequests.updateTreatment(props.treatmentCode, dto))
        else
            saveTreatmentRequest.runRequest(TreatmentRequests.saveTreatment(dto))
    }

    /**
     * Remover conta.
     */
    function deleteTreatment(): void {

        if (!props.treatmentCode)
            return

        deleteTreatmentRequest.runRequest(TreatmentRequests.deleteTreatment(props.treatmentCode))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteTreatmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteTreatmentRequest, 'Não foi remover o tratamento', true))
            return

        NotificationHelper.info('Pronto', 'O Atendimento foi removido')
        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveTreatmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveTreatmentRequest, 'Ocorreu algun erro ao salvar conta bancaria'))
            return

        NotificationHelper.info('Yes', 'Atendimento salvo com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Atendimento'}
            visible={props.visible}
            onClose={props.onClose}
            footerSpaced={!!props.treatmentCode}
            loading={getTreatmentRequest.isAwaiting}
            footer={
                <>
                    <ConditionalRenderCP shouldRender={!!props.treatmentCode}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteTreatmentRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover este atendimento?'}
                            onClick={deleteTreatment}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveTreatment}
                        loading={saveTreatmentRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }
        >

            <TreatmentFormICP
                formStateManager={formStateManager}
                loadedTreatment={loadedTreatment}
            />

        </DrawerCP>
    )
}
