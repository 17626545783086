import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { TreatmentFormFormModel } from 'main/modules/admin/components/drawer-treatment-form/inner/TreatmentFormFormModel'
import { TreatmentFormResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/TreatmentFormResponseDTO'
import { TreatmentFormRequests } from 'main/modules/admin/services/treatment-form/TreatmentFormRequests'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import styled from 'main/config/theme/styledWithTheme'
import { AutocompleteFormQuestionCP } from 'main/modules/admin/components/autocomplete-form-question/AutocompleteFormQuestionCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { DragAndDropDrawerFormQuestionsCP } from 'main/modules/admin/components/drag-and-drop-form-questions/DragAndDropDrawerFormQuestionsCP'
import { TreatmentFormSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/request/TreatmentFormSaveRequestDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { AnswerTypeEnum } from 'submodules/neritclin-sdk/services/treatment/forms/enums/AnswerTypeEnum'
import { FormFormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/inner/FormFormQuestionResponseDTO'
import { TreatmentFormQuestionSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/request/inner/TreatmentFormQuestionSaveRequestDTO'
import { TreatmentFormsRequests } from 'submodules/neritclin-sdk/services/treatment/forms/TreatmentFormsRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    treatmentFormCode?: number
}

/**
 * Tela de listagem de tratamentos.
 */
export function DrawerTreatmentFormCP(props: ICPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<TreatmentFormFormModel>((new TreatmentFormFormModel()))
    const formStateManager = useFormStateManager<TreatmentFormFormModel>(formValidator)

    const getTreatmentFormRequest = useRequest<TreatmentFormResponseDTO>()
    useEffect(onGetTreatmentFormRequestChange, [getTreatmentFormRequest.isAwaiting])

    const saveTreatmentFormRequest = useRequest<TreatmentFormResponseDTO>()
    useEffect(onSaveTreatmentFormRequestChange, [saveTreatmentFormRequest.isAwaiting])

    const deleteTreatmentFormRequest = useRequest<void>('none')
    useEffect(onDeleteTreatmentFormRequestChange, [deleteTreatmentFormRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        if (!props.visible)
            return

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        formStateManager.reset(new TreatmentFormFormModel())

        if (props.treatmentFormCode)
            return getTreatmentFormRequest.runRequest(TreatmentFormRequests.getOne(props.treatmentFormCode))
    }

    /**
     * Retorno da requisicao que obtem o formulario selecionado.
     */
    function onGetTreatmentFormRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getTreatmentFormRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const treatmentFormResult = getTreatmentFormRequest.responseData!
        setFormValidator(new TreatmentFormFormModel({
            name: treatmentFormResult.name,
            formQuestions: treatmentFormResult.formQuestions
        }))
    }

    /**
     * Salva Formulario.
     */
    async function saveTreatmentForm(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (SystemUtils.isEmpty(formValues.formQuestions)) {
            NotificationHelper.error('Ops!', 'Adicione ao menos uma pergunta no formulário.')
            return
        }

        // Prepara DTO de perguntas
        const questionsDTO: TreatmentFormQuestionSaveRequestDTO[] = formValues.formQuestions.map((formFormQuestion: FormFormQuestionResponseDTO, index: number) => (
            {
                order: index + 1,
                code: formFormQuestion.code,
                questionCode: formFormQuestion.questionCode,
            }
        ))

        const dto: TreatmentFormSaveRequestDTO = {
            name: formValues.name,
            questions: questionsDTO
        }

        if (!!props.treatmentFormCode)
            saveTreatmentFormRequest.runRequest(TreatmentFormsRequests.update(props.treatmentFormCode, dto))
        else
            saveTreatmentFormRequest.runRequest(TreatmentFormsRequests.create(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveTreatmentFormRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveTreatmentFormRequest, 'Ocorreu algun erro ao salvar formulário'))
            return

        NotificationHelper.info('Yes', 'Formulário salvo com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Remover formulario.
     */
    function deleteTreatmentForm(): void {

        if (!props.treatmentFormCode)
            return

        deleteTreatmentFormRequest.runRequest(TreatmentFormRequests.delete(props.treatmentFormCode))
    }

    /**
     *  Retorno da remocao.
     */
    function onDeleteTreatmentFormRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteTreatmentFormRequest, NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE, true))
            return

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Formulário de Atendimento'}
            visible={props.visible}
            onClose={props.onClose}
            footerSpaced={!!props.treatmentFormCode}
            footer={
                <>
                    <ConditionalRenderCP shouldRender={!!props.treatmentFormCode}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteTreatmentFormRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover este formulário?'}
                            onClick={deleteTreatmentForm}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveTreatmentForm}
                        loading={saveTreatmentFormRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }
        >

            <InputCP
                label={'Nome do Formulário'}
                fontSize={'extraLarge'}
                fieldName={'name'}
                formStateManager={formStateManager}
                required={true}
            />

            <CardCP
                title={'Campos'}
                margin={'vertical'}
            >
                <NewFormFieldSCP>
                    <AutocompleteFormQuestionCP
                        label={'Adicione um campo no formulário'}
                        showOptionsOnLoad={true}
                        returnFullOption={true}
                        onChange={(optionSelected) => {
                            const newQuestions: FormFormQuestionResponseDTO[] = [...(formStateManager.getFieldValue('formQuestions') ?? [])]
                            newQuestions.push({
                                code: undefined as any,
                                questionCode: optionSelected.value,
                                order: +(formStateManager.getFieldValue('formQuestions')?.length ?? -1) + 2,
                                questionTitle: optionSelected.label,
                                answerType: AnswerTypeEnum.OPEN,
                            })
                            formStateManager.changeFieldValue('formQuestions', newQuestions)
                        }}
                    />
                </NewFormFieldSCP>
                <DragAndDropDrawerFormQuestionsCP
                    loading={getTreatmentFormRequest.isAwaiting}
                    formQuestions={formStateManager.getFieldValue('formQuestions') ?? []}
                    onChangeQuestionList={(reorderedList) => formStateManager.changeFieldValue('formQuestions', reorderedList)}
                />
            </CardCP>

        </DrawerCP>
    )
}

const NewFormFieldSCP = styled.div`
  margin-bottom: 20px;
`
