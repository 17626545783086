import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DokfySystemApiEnum } from 'submodules/dokfy-sdk/common/DokfySystemApiEnum'

export class DocumentsRequests {

    public static CONTROLLER_ROOT = 'documents'
    public static RESEND_EP = 'resend/:code'

    static resend = (code: number): RequestConfigTP<void> => ({
        baseUrlType: DokfySystemApiEnum.DOKFY_API,
        url: `${DocumentsRequests.CONTROLLER_ROOT}/${DocumentsRequests.RESEND_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
    })

}
