import React from 'react'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import logoImg from 'main/modules/documents/components/dokfy-logo/inner/dokfy-logo.png'

interface IDokfyLogoCPProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

/**
 * COMPONENTE Logo da Dokfy.
 */
export function DokfyLogoCP(props: IDokfyLogoCPProps): JSX.Element {

    return (
        <LogoCP
            marginBottom={props.marginBottom}
            marginTop={props.marginTop}
            marginLeft={props.marginLeft}
            marginRight={props.marginRight}
            align={'center'}
            width={'150px'}
            imageUrl={logoImg}
        />
    )
}
