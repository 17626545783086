import React from 'react'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { RadioOptionTP } from 'main/common/components/form-fields/radio-group/inner/RadioOptionTP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import {
    TransactionalTriggerTypeEnum,
    TransactionalTriggerTypeLabelEnum
} from 'main/modules/marketing/components/select-transactional-trigger-type/inner/TransactionalTriggerTypeEnum'
import { FormModel } from 'main/common/form-state-manager/FormModel'

interface ICPProps<FModelTP extends FormModel = any> {
    fieldName: string
    formStateManager: IFormStateManager<FModelTP>
}

/**
 * Select para o tipo de trigger para disparo de uma automacao.
 */
export function SelectTransactionalTriggerTypeCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element | null {

    const OPTIONS: Array<RadioOptionTP<TransactionalTriggerTypeEnum>> = Object.values(TransactionalTriggerTypeEnum).map((item) => ({
        content: TransactionalTriggerTypeLabelEnum[item],
        value: item
    }))

    return (
        <>
            <TextCP text={'Quando será disparado o evento?'}/>
            <RadioGroupCP
                type={'button'}
                formStateManager={props.formStateManager}
                fieldName={props.fieldName}
                options={OPTIONS}
            />
        </>
    )
}
