import { Table } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { ColumnProps, SorterResult, TableRowSelection } from 'antd/lib/table'
import { TableEventListeners } from 'antd/lib/table/interface'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { OrUndefinedTP } from 'main/common/types/OrUndefinedTP'
import { styled } from 'main/config/theme/styledWithTheme'
import React from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { TablePaginationTP } from 'main/common/components/table/inner/TablePaginationTP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { TableSortAndPageTP } from 'main/common/components/table/inner/TableSortAndPageTP'
import { TableSortTP } from 'main/common/components/table/inner/TableSortTP'
import { OrderingEnum } from 'main/common/enums/OrderingEnum'

interface ICPProps<RowTP> {
    data?: RowTP[]
    columns?: Array<ColumnProps<RowTP>>
    sortAndPage?: TableSortAndPageTP
    onSortOrChangePagination?: (sortAndPage?: TableSortAndPageTP) => void

    rowKey?: (row: RowTP, index: number) => string
    bordered?: boolean
    rowSelection?: TableRowSelection<RowTP>
    loading?: boolean
    children?: React.ReactNode
    rowClassName?: (record: RowTP, index: number) => string
    showHeader?: boolean
    onRow?: (record: RowTP, index: number) => TableEventListeners
    emptyText?: string

    expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
    onExpand?: (expanded: boolean, record: RowTP) => void
    rowKeyGetter?: (row: RowTP, index: number) => string

    wrappedOnCard?: boolean
}

/**
 * Tabela.
 */
export function TableCP<RowTP = any>(props: ICPProps<RowTP>): JSX.Element {

    /**
     * Monta a Key de cada linha.
     */
    function rowKeyGetter(row: RowTP, index: number): string {

        if (!!props.rowKeyGetter)
            return props.rowKeyGetter(row, index)

        const _row = (row as any)

        const defaultRowKey: number | string = (
            ((_row.code ?? _row.key ?? _row.id) as OrUndefinedTP<number | string>)
            ?? `table_${DateUtils.getFormatted(new Date(), DateFormatEnum.US_WITHOUT_TIME)}_${index}`
        )

        return defaultRowKey.toString()
    }

    /**
     * Ao mudar paginacao ou ordenacao.
     */
    function onChange(paginationConfig?: PaginationConfig, sortConfig?: SorterResult<RowTP>): void {

        if (!props.onSortOrChangePagination)
            return

        let sorter: TableSortTP | undefined
        if (!!sortConfig?.columnKey) {
            sorter = {
                column: sortConfig?.columnKey,
                order: sortConfig?.order === 'descend' ? OrderingEnum.DESC : OrderingEnum.ASC,
            }
        }

        let pagination: TablePaginationTP | undefined
        if (!!paginationConfig) {
            pagination = {
                total: paginationConfig.total!,
                current: paginationConfig.current!,
                pageSize: paginationConfig.pageSize!,
                showTotal: paginationConfig.showTotal,
            }
        }

        props.onSortOrChangePagination({
            sort: sorter ?? props.sortAndPage?.sort,
            pagination: pagination ?? props.sortAndPage?.pagination
        })
    }

    function renderTable(): JSX.Element {
        return (
            <Table<RowTP>
                rowSelection={props.rowSelection}
                rowKey={rowKeyGetter}
                dataSource={props.data}
                columns={props.columns}
                pagination={props.sortAndPage?.pagination ? { position: 'bottom', size: 'small', ...props.sortAndPage.pagination } : false}
                onChange={(pagination, filters, sorter) => onChange(pagination, sorter)}
                rowClassName={props.rowClassName}
                size={'small'}
                locale={{ emptyText: <EmptyCP description={props.emptyText ?? 'Nenhum dado encontrado para os filtros selecionados'}/> }}
                loading={props.loading}
                showHeader={props.showHeader !== false}
                onRow={props.onRow}
                expandedRowRender={props.expandedRowRender}
                onExpand={props.onExpand}
                bordered={props.bordered}
            >
                {props.children}
            </Table>
        )
    }

    return (
        <TableSCP>
            {
                props.wrappedOnCard
                    ?
                    <CardCP innerSpacing={'none'} bordered={false}>
                        { renderTable() }
                    </CardCP>
                    : renderTable()
            }
        </TableSCP>
    )
}

const TableSCP = styled.div`

  width: 100%;

  .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > thead {
    tr {
      th {
        color: ${props => props.theme.primaryColor};
        font-weight: bold;
        font-size: 0.8rem;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }

  .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > tbody {
    tr:hover td {
      background: rgba(218, 225, 239, 0.3);
    }
  }

  .ant-table-wrapper .ant-spin-nested-loading .ant-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-table-small {
    .ant-table-content {
      .ant-table-body {
        margin: 0;

        table > .ant-table-tbody > tr > td {
          padding: 6px 6px;
        }
      }
    }
  }
`
