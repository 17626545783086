import { IsOptional } from 'class-validator'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsString } from 'main/common/validation/decorators/IsString'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { IsPhoneBR } from 'main/common/validation/decorators/phone/IsPhoneBR'
import { IsEmail } from 'main/common/validation/decorators/IsEmail'

/**
 * VALIDADOR
 * Campos do form de para criar ou editar um Lead
 */
export class OpportunityFormModel extends FormModel {

    @IsNotEmpty()
    @IsString()
    name: string

    @IsPhoneBR()
    phone?: string

    @IsEmail()
    email?: string

    @IsNotEmpty()
    personResponsibleCode: number

    @IsOptional()
    personCustomerCode: number

    @IsNotEmpty()
    sourceCode: number

    @IsOptional()
    sourcePersonLinkCode: number

    @IsOptional()
    @IsString()
    note: string

    @IsOptional()
    tags: number[]

    /** Auxiliares. */
    source?: IOpportunitySourceResponseDTO
    sourcePersonLink?: INameAndCodeResponseDTO

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
