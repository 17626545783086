import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { TransactionalTriggerTypeEnum } from 'main/modules/marketing/components/select-transactional-trigger-type/inner/TransactionalTriggerTypeEnum'
import { TransactionalTriggerMomentTypeEnum } from 'main/modules/marketing/components/select-transactional-trigger-moment-type/inner/TransactionalTriggerMomentTypeEnum'

export class MktTransactionalConfigFormModel extends FormModel {

    @IsNotEmpty()
    triggerType: TransactionalTriggerTypeEnum

    timeType: TransactionalTriggerMomentTypeEnum
    quantity: number

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
