import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import React from 'react'
import styled from 'styled-components'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { PaymentTypeEnum, PaymentTypeLabelEnum } from 'main/modules/sale/services/sale/enums/PaymentTypeEnum'

interface ISelectPaymentTypeCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP, PaymentMethodEnum> {
    allowClear?: boolean
}

/**
 * Componente para select de tipos de pagamento.
 * OBS: Baseado em enum
 */
export function SelectPaymentTypeCP(props: ISelectPaymentTypeCPProps): JSX.Element {

    const OPTIONS: SelectOptionTP[] = Object.values(PaymentTypeEnum).map((paymentyType, i) => ({
        key: `key-method-${i}`,
        label: PaymentTypeLabelEnum[paymentyType],
        value: paymentyType
    }))

    return (
        <SelectWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                label={props.label}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                formStateManager={props.formStateManager}
                selectedOptions={props.value ? [props.value] : undefined}
                fieldName={props.fieldName}
                fontSize={props.fontSize}
                isMultiple={false}
                allowClear={props.allowClear}
                options={OPTIONS}
                defaultOpen={false}
                onChange={props.onChange}
                width={props.width}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width:100%;
`
