/**
 * Objeto de parametrizacao para componente de grafico de area/linha.
 *
 * @author renatofs
 */
export const LINE_CHART_CONFIG_OPTIONS = {

    getConfig(
        height?: number | string
    ): any {

        return {
            chart: {
                height,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            series: [],
            xaxis: {
                categories: [],
            }
        }
    },

}
