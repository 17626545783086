import { faCheckDouble, faEye, faMousePointer } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ShotListItemStatisticDataICP } from 'main/modules/marketing/components/shot-list-item-mobile/inner/ShotListItemStatisticDataICP'
import { ShotListItemContactColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemContactColumnICP'
import { ShotListItemDateColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemDateColumnICP'
import { ShotListItemEmailInfoColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemEmailInfoColumnICP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import React from 'react'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotEmailListItemDataProps {
    shot: IMarketingCampaignEmailResponseDTO
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 * COMPONENTE
 *
 * @author Lucas Rosa
 */
export function ShotEmailListItemData(props: IShotEmailListItemDataProps): JSX.Element {
    return (
        <>
            <ShotListItemDateColumnICP
                scheduleDate={props.shot.scheduleDate}
                startDate={props.shot.startDate}
                status={props.shot.status}
            />
            <ShotListItemEmailInfoColumnICP
                shot={props.shot}
            />
            <ShotListItemContactColumnICP
                destination={props.shot.destination}
                totalContacts={props.shot.totalContacts}
                semegmentList={props.semegmentList}
            />
            {
                (props.shot.status === MarketingShotStatusEnum.ERROR || props.shot.status === MarketingShotStatusEnum.SENT) &&
                    <>
                        <ShotListItemStatisticDataICP
                            total={props.shot.totalDelivered ?? 0}
                            icon={<FontAwsomeIconCP type={faCheckDouble} color={ThemeAnt.primaryColor}/>}
                            percent={MathUtils.getPercentage(props.shot.totalDelivered, props.shot.totalContacts)}
                            label={'RECEBERAM'}
                        />

                        <ShotListItemStatisticDataICP
                            total={props.shot.totalOpened ?? 0}
                            icon={<FontAwsomeIconCP type={faEye} color={ThemeAnt.primaryColor}/>}
                            percent={MathUtils.getPercentage(props.shot.totalOpened, props.shot.totalDelivered)}
                            label={'VISUALIZARAM'}
                        />
                        <ShotListItemStatisticDataICP
                            total={props.shot.totalClicked ?? 0}
                            icon={<FontAwsomeIconCP type={faMousePointer} color={ThemeAnt.primaryColor}/>}
                            percent={MathUtils.getPercentage(props.shot.totalClicked, props.shot.totalOpened)}
                            label={'CLICARAM'}
                        />

                    </>
            }
        </>
    )
}
