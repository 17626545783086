import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { IAttendanceWorkShiftSearchRequestDTO } from 'main/modules/dashboard/services/dtos/request/IAttendanceWorkShiftSearchRequestDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import moment from 'moment'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { AttendanceByShiftColumns } from 'main/modules/dashboard/components/attendance-by-shift-table/inner/AttendanceByShiftColumns'
import { PaginationConfig } from 'antd/lib/table'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { IAttendanceWorkShiftListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IAttendanceWorkShiftListItemResponseDTO'

interface IAttendanceReportCPProps {
    date: Date
    dataFiltrada?:any
    setAtendimentos?:any
}

/**
 * Componentes do módulo Dashboard
 */
export function AttendanceReportCP(props: IAttendanceReportCPProps): JSX.Element {

    useEffect(init, [props.dataFiltrada])

    const [filters, setFilters] = useState<IAttendanceWorkShiftSearchRequestDTO>()
    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())

    const [attendacesByFranchise, setAttendacesByFranchise] = useState<IAttendanceWorkShiftListItemResponseDTO[]>([])
    const attendancesByFranchiseRequest = useRequest<IGenericListResponseDTO<IAttendanceWorkShiftListItemResponseDTO>>()
    useEffect(onAttendancesByFranchiseRequestChange, [attendancesByFranchiseRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        const requestDto: IAttendanceWorkShiftSearchRequestDTO = {
            beginDate: moment(props.dataFiltrada.beginDate).startOf('day').toDate(),
            endDate: moment(props.dataFiltrada.endDate).endOf('day').toDate(),
            itemsPerPage: 10
        }
        attendancesByFranchiseRequest.runRequest(DashboardRequests.totalAttendanceByWorkshiftAndFranchise(requestDto))
    }

    /**
     * Retorno dos dados da api.
     */
    function onAttendancesByFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(attendancesByFranchiseRequest, 'Erro obter lista do número de atendimentos por franquia'))
            return

        setAttendacesByFranchise(attendancesByFranchiseRequest.responseData!.list)
        setPagination({ ...pagination, total: attendancesByFranchiseRequest.responseData!.total })
        props.setAtendimentos(attendancesByFranchiseRequest.responseData!.list)
    }

    return (
        <CardCP
            title={'Atendimentos por turno'}
            innerSpacing={'none'}
            overflow={'auto'}
        >
            <TableCPOLD2<IAttendanceWorkShiftListItemResponseDTO>
                data={attendacesByFranchise}
                loading={attendancesByFranchiseRequest.isAwaiting}
                columns={AttendanceByShiftColumns()}
                rowKey={(record): string => record.franchise.code.toString()}
                pagination={pagination}
                onPaginationChange={(page: PaginationConfig): void => {
                    setPagination({ ...pagination, current: page.current! })
                    setFilters({ ...filters!, page: page.current })
                }}
            />
        </CardCP>
    )
}
