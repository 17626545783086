import { AlertCP } from 'main/common/components/alert/AlertCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MKT_DOMAIN_TABLE_COLUMNS } from 'main/modules/marketing/components/marketing-domains/inner/MktDomainTableColumns'
import { IMarketingDomainConfigResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainConfigResponseDTO'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface IMarketingDomainConfigsICPProps {
    domainConfigs: IMarketingDomainConfigResponseDTO[]
    domainCode: number
    onDelete: () => void
}

/**
 * Configuracoes de Mkt
 *
 * @author renatofs
 * @author hjcostabr
 */
export function MarketingDomainConfigsICP(props: IMarketingDomainConfigsICPProps): JSX.Element {

    const deleteDomainRequest = useRequest<void>(undefined, 'none')
    useEffect(onDeleteCalendarRequestChange, [deleteDomainRequest.isAwaiting])

    /**
     * Chama remocao de dominio na API.
     */
    async function deleteDomain(): Promise<void> {
        deleteDomainRequest.runRequest(await MarketingRequests.getDeleteDomainConfig(props.domainCode))
    }

    /**
     * Retorno da remocao na api
     */
    function onDeleteCalendarRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteDomainRequest, 'Não foi remover a agenda', true))
            return

        NotificationHelper.info('Domínio removido com sucesso')

        props.onDelete()
    }

    return (
        <PanelContentSCP>
            <TableCPOLD2
                columns={MKT_DOMAIN_TABLE_COLUMNS}
                data={props.domainConfigs}
                showHeader={true}
            />
            <AlertCP
                message={'Instruções'}
                type={'warning'}
                description={
                    <span>
                        Adicione os registros acima no seu servidor de DNS. Geralmente é o mesmo servidor onde está o seu site. Essa etapa é necessária
                        para validar que você é dono do seu domínio e por isso somente vocês podem realizá-la.
                        <br/>
                        Se tiver dúvidas de como realizar essa inserção, envie essas instruções para a pessoa responsável pelo seu site, ela deve conseguir
                        lhe ajudar. Se as dúvidas persistirem, entre em contato com o nosso suporte que tentaremos lhe auxiliar.
                    </span>
                }
            />
            <FooterSCP>
                <PopConfirmCP
                    title={'Você tem certeza que deseja remover esse domínio?'}
                    onConfirm={deleteDomain}
                >
                    <ButtonCP
                        type={'danger'}
                        loading={deleteDomainRequest.isAwaiting}
                    >
                        Remover Domínio
                    </ButtonCP>
                </PopConfirmCP>
            </FooterSCP>
        </PanelContentSCP>
    )
}

const PanelContentSCP = styled.div`
    overflow-x: auto;
    .ant-table-wrapper {
        margin-bottom: 25px;
    }

    .ant-alert-description {
        font-size: 13px;
        color: #757575;
    }

`

const FooterSCP = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`
