import { SystemConfig } from 'main/config/SystemConfig'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { RequestHeaderTP } from 'main/common/request-manager/types/RequestHeaderTP'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'

/**
 * REQUISICOES relacionadas ao modulo de SysAdmin.
 */
export class SysAdminRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/sys-admin`

    static getSchemaDataConfig = (schema: string, authToken?: string): RequestConfigTP => {

        const headers: RequestHeaderTP[] = [AuthUtils.getSchemaHeaderConfig(schema)]
        if (!!authToken)
            headers.push(AuthUtils.getBearerAuthHeaderConfig(authToken))

        return {
            url: `${SysAdminRequests._MODULE_BASE}/schemas/${schema}`,
            method: HttpMethodEnum.GET,
            headers
        }
    }

}
