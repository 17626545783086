import React from 'react'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { TagCP } from 'main/common/components/tag/TagCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { UnitValueOperatorDisplayICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueOperatorDisplayICP'

/**
 * COMPONENTE
 * Mostrador do valor selecionado para 01 unidade de lista de segmentacao, em linguagem natural.
 */
export function UnitValueICP(): JSX.Element | null {

    const unitContext = UnitContext.useState()

    function getReadableValue(uWithValue: SegmentUnitValueWithLabelTP): string {

        if (!!uWithValue.label)
            return uWithValue.label.toString()

        return SegmentUtils.isDateField(unitContext.state.field!)
            ? DateUtils.getFormatted(uWithValue.value as string, DateFormatEnum.BR_WITHOUT_TIME)
            : uWithValue.value.toString()
    }

    if (!unitContext.state.value.length)
        return null

    const valueFieldsCount = SegmentUtils.getUnitValueFieldsCount(unitContext.state.field!, unitContext.state.operator!)

    if (SegmentUtils.isSingleFieldCount(valueFieldsCount))
        return <ValueSCP>{`"${getReadableValue(unitContext.state.value[0])}"`}</ValueSCP>

    if (SegmentUtils.isMultiOptionFieldCount(valueFieldsCount)) {
        return (
            <MultiValueWrapperSCP>
                {
                    unitContext.state.value.map((valueListItem, index) => (
                        <TagCP
                            key={`segment_multioption_key_${index}`}
                            content={(valueListItem.label ?? '').toString()}
                            small={true}
                            color={'navyBlue'}
                            fontStyle={'italic'}
                        />
                    ))
                }
            </MultiValueWrapperSCP>
        )
    }

    if (SegmentUtils.isDoubleFieldCount(valueFieldsCount)) {
        return (
            <>
                <ValueSCP>{`"${getReadableValue(unitContext.state.value[0])}"`}</ValueSCP>
                <UnitValueOperatorDisplayICP>e</UnitValueOperatorDisplayICP>
                <ValueSCP>{`"${getReadableValue(unitContext.state.value[1])}"`}</ValueSCP>
            </>
        )
    }

    return null
}

const ValueSCP = styled.div`
    padding: 3px 5px;
`

const MultiValueWrapperSCP = styled.div`

    margin-left: 3px;
    display: flex;

    .ant-tag {
        cursor: pointer;
    }
`
