import React, { useEffect, useState } from 'react'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { SaleBasicDataFormICP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SaleBasicDataFormICP'
import { SaleBasicDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleBasicDataFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ISaleUpdateRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISaleUpdateRequestDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FormStateManagerUtils } from 'main/common/form-state-manager/FormStateManagerUtils'

interface ISaleDetailsDrawerCPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    sale: ISaleResponseDTO
}

/**
 * Modal para editar os dados básicos de uma venda.
 */
export function ModalSaleEditDataCP(props: ISaleDetailsDrawerCPProps): JSX.Element {

    const [basicDataFormValidator, setBasicDataFormValidator] = useState<SaleBasicDataFormModel>(new SaleBasicDataFormModel())
    const basicDataFormStateManager = useFormStateManager<SaleBasicDataFormModel>(basicDataFormValidator)

    const saveSaleRequest = useRequest()
    useEffect(onSaveSaleRequestChange, [saveSaleRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Busca detalhes da venda.
     */
    function init(): void {

        if (!props.visible)
            return

        setBasicDataFormValidator(new SaleBasicDataFormModel({
            personCustomerCode: props.sale.personCustomer.code,
            personCustomer: props.sale.personCustomer,
            saleDate: DateUtils.toDate(props.sale.date, DateFormatEnum.US_WITH_TIME_H_M),
            saleOrigin: props.sale.origin,
            sourceCode: props.sale.source.code,
            source: props.sale.source,
            sellerCode: props.sale.userSeller.code,
            personSeller: props.sale.userSeller,
            sourcePersonLinkCode: props.sale.sourcePersonLink?.code,
            sourcePersonLink: props.sale.sourcePersonLink,
        }))
    }

    /**
     * Edita os dados da venda.
     */
    async function editSale(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(basicDataFormStateManager))
            return

        const formValues = basicDataFormStateManager.getFormValues()!

        if (formValues.source?.hasPersonLink && !formValues.sourcePersonLinkCode) {
            NotificationHelper.error('Ops!', 'Favor selecionar a pessoa indicada')
            return
        }

        const dto: ISaleUpdateRequestDTO = {
            personSellerCode: formValues.sellerCode,
            sourceCode: formValues.sourceCode,
            saleDate: DateUtils.getFormatted(formValues.saleDate, DateFormatEnum.US_WITH_TIME_H_M),
            origin: formValues.saleOrigin,
            sourcePersonLinkCode: formValues.sourcePersonLinkCode,
        }

        saveSaleRequest.runRequest(SaleRequests.updateSale(props.sale.code, dto))
    }

    /**
     * Retorno da requisicao que atualiza venda
     */
    function onSaveSaleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveSaleRequest, 'Erro obter atualizar venda'))
            return

        NotificationHelper.success('Yes!', 'Dados da venda atualizados com sucesso.')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Editar dados da Venda'}
            visible={props.visible}
            width={600}
            actionLoading={saveSaleRequest.isAwaiting}
            onOk={editSale}
            onCancel={props.onCancel}
            destroyOnClose={true}
        >
            <SaleBasicDataFormICP formStateManager={basicDataFormStateManager}/>
        </ModalCP>
    )
}
