import React from 'react'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { ButtonChargeVirtualLinksCP } from 'main/modules/sale/components/button-charge-virtual-links/ButtonChargeVirtualLinksCP'
import styled from 'main/config/theme/styledWithTheme'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { ButtonMultipleBankBilletDownloadCP } from 'main/modules/sale/components/button-multiple-bankbillet-download/ButtonMultipleBankBilletDownloadCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

type ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
}

interface IButtonMultiplePaymentMethodActionCPProps extends ScpPropsTP {
    charge: IChargeListItemResponseDTO
}

/**
 * Botao com acoes em massa de acordo com a forma de pagamento
 */
export function ButtonMultiplePaymentMethodActionCP(props: IButtonMultiplePaymentMethodActionCPProps): JSX.Element {

    return (
        <WrapperSCP
            marginRight={props.marginRight}
            marginLeft={props.marginLeft}
        >
            {
                !!props.charge.chargeData.chargeURL
                    ?
                    <ButtonChargeVirtualLinksCP
                        chargeUrl={props.charge.chargeData.chargeURL}
                        buttonType={'ghost'}
                        buttonSize={'small'}
                    />
                    :
                    <ConditionalRenderCP shouldRender={props.charge.chargeData.paymentMethod === PaymentMethodEnum.BANK_BILLET}>
                        <ButtonMultipleBankBilletDownloadCP charge={props.charge}/>
                    </ConditionalRenderCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<ScpPropsTP>`
  margin-left: ${props => props.marginLeft ?? 0}px;
  margin-right: ${props => props.marginRight ?? 0}px;
`
