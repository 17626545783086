import React from 'react'
import { FastFiltersWithDrawerCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/FastFiltersWithDrawerCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityAdvancedFiltersCP } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityAdvancedFiltersCP'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { ButtonExportCP } from 'main/modules/app/components/button-export/ButtonExportCP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import styled from 'styled-components'
import { OpportunitiesTableUtils } from 'main/modules/sales-funnel/components/table-opportunity/inner/OpportunitiesTableUtils'

interface ISalesFiltersCPProps {
    formStateManager: IFormStateManager<OpportunityFiltersFormModel>
    onClearFilters: () => void
    onFilter: () => void
    isLoading?: boolean
    leftContent?: JSX.Element | JSX.Element[]
}

/**
 * Filtros da tela de Vendas.
 */
export function OpportunityFiltersCP(props: ISalesFiltersCPProps): JSX.Element {

    return (
        <FiltersSCP>
            <FastFiltersWithDrawerCP
                onFilter={props.onFilter}
                isLoading={props.isLoading}
                onClearFilters={props.onClearFilters}
                leftContent={props.leftContent}
                rightContent={
                    <ButtonExportCP
                        requestConfig={() => OpportunityRequests.export(
                            TableUtils.removePaginationAttributes(OpportunitiesTableUtils.getFilterDtoFromModel(props.formStateManager.getFormValues()!))
                        )}
                        reportName={'funil-vendas'}
                        size={'default'}
                    />
                }
                drawerContent={<OpportunityAdvancedFiltersCP formStateManager={props.formStateManager}/>}
            />
        </FiltersSCP>
    )
}

const FiltersSCP = styled.div`
  margin: 20px 20px 0 20px;
`
