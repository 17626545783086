import React from 'react'
import styled from 'styled-components'

interface IFilterPanelCPProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Painel de filtros que ficará abaixo do Header.
 */
export function FilterPanelCP(props: IFilterPanelCPProps): JSX.Element {

    return (
        <FiltersSCP>
            { props.children }
        </FiltersSCP>
    )
}

const FiltersSCP = styled.div`
  background: #FFF;
  position: absolute;
  width: 100%;
  margin-left: -20px;
  padding: 15px 20px 0px 20px;
  z-index: 1;
`
