import React, { useEffect } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { TotalLeadsTodayByUserTableUtils } from 'main/modules/dashboard/components/widget-table-total-leads-today-by-user/inner/TotalLeadsTodayByUserTableUtils'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ILeadsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ILeadsByUserListItemResponseDTO'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Componente de tabela que contas bancarias
 */
export function WidgetTableTotalLeadsTodayByUserCP(props: ICPProps): JSX.Element {

    const getLeadsByUserRequest = useRequest<IGenericListResponseDTO<ILeadsByUserListItemResponseDTO>>()
    useEffect(onGetLeadsByUserRequestChange, [getLeadsByUserRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes])

    /**
     * Busca dados na api.
     */
    function init(): void {
        getLeadsByUserRequest.runRequest(DashboardRequests.getLeadsByUserData({ franchiseCodes: props.franchiseCodes }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetLeadsByUserRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getLeadsByUserRequest, 'Não foi possível obter o relatorio de leads por usuario'))
            return
    }

    return (
        <CardDashboardWidgetWrapperCP
            title={'Leads para hoje por colaborador'}
            className={'card-dashboard-widget'}
        >
            <TableCPOLD2
                columns={TotalLeadsTodayByUserTableUtils.getTableColumns()}
                data={getLeadsByUserRequest.responseData?.list}
                loading={getLeadsByUserRequest.isAwaiting}
            />
        </CardDashboardWidgetWrapperCP>
    )
}
