import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenerateBilletRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IGenerateBilletRequestDTO'
import React, { useEffect, useState } from 'react'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import moment from 'moment'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { GenerateBilletModalCP } from 'main/modules/financial/components/generate-billet-modal/GenerateBilletModalCP'

interface IButtonBankBilletCPProps {
    financialTransaction: IFinancialTransactionCreditListItemResponseDTO
    onGenerateBankBillet: () => void
}

/**
 * Botoes de acao para um boleto (pega o boleto gerado ou gera um novo)
 */
export function ButtonBankBilletCP(props: IButtonBankBilletCPProps): JSX.Element {

    const [showBilletModal, setShowBilletModal] = useState<boolean>(false)

    const generateBilletReq = useRequest<string>()
    useEffect(onGenerateBilletReqChange, [generateBilletReq.isAwaiting])

    /**
     * Gera boleto
     */
    function generateBillet(): void {

        // verifica se a parcela está vencida. se sim abre modal para gerar 2a via
        // se não gera o boleto com a data de venciemnto original
        if (props.financialTransaction.expirationDate && moment(props.financialTransaction.expirationDate).isBefore(moment().startOf('date')))
            return setShowBilletModal(true)

        const dto: IGenerateBilletRequestDTO = {
            transactionCode: props.financialTransaction.code
        }
        generateBilletReq.runRequest(FinancialTransactionsRequests.generateBillet(dto))
    }

    /**
     * Retorno ao gerar boleto.
     */
    function onGenerateBilletReqChange(): void {

        if (!RequestUtils.isValidRequestReturn(generateBilletReq, 'Erro ao tentar gerar boleto!'))
            return

        window.open(generateBilletReq.responseData, '_blank')
    }

    return (
        <>
            <ButtonCP
                type={props.financialTransaction.chargeData?.chargeURL ? 'ghost' : 'primary'}
                onClick={() => {
                    if (!!props.financialTransaction.chargeData?.chargeURL)
                        RoutingHelper.openInNewTab(props.financialTransaction.chargeData.chargeURL)
                    else
                        generateBillet()
                }}
                icon={'barcode'}
                size={'small'}
                tooltip={props.financialTransaction.chargeData?.chargeURL ? 'Fazer download do boleto' : 'Gerar boleto'}
            />

            <GenerateBilletModalCP
                visible={showBilletModal}
                onCancel={() => setShowBilletModal(false)}
                transactionCode={props.financialTransaction.code}
                onSave={() => {
                    setShowBilletModal(false)
                    props.onGenerateBankBillet()
                }}
            />
        </>
    )
}
