import * as _ from 'lodash'
import { InputTypeTP } from 'main/common/components/form-fields/input/InputCP'
import { IListByCodesRequestDTO } from 'main/common/dtos/requests/IListByCodesRequestDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { StringUtils } from 'main/common/utils/StringUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { GenderEnum } from 'main/modules/people/enums/GenderEnum'
import { MktStatusEnum } from 'main/modules/people/enums/MktStatusEnum'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { PersonUtils } from 'main/modules/people/utils/PersonUtils'
import { ProductReleaseRequests } from 'main/modules/products/services/product-release/ProductReleaseRequests'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { SalesUtils } from 'main/modules/sale/utils/SalesUtils'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { OpportunityUtils } from 'main/modules/sales-funnel/utils/OpportunityUtils'
import { SessionStatusEnum, SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { SegmentLogicOperatorsEnum } from 'main/modules/segment/enums/SegmentLogicOperatorsEnum'
import { SegmentSpecialListCodeEnum } from 'main/modules/segment/enums/SegmentSpecialListCodeEnum'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { ISegmentAggregator } from 'main/modules/segment/interfaces/ISegmentAggregator'
import { ISegmentLogicOperator } from 'main/modules/segment/interfaces/ISegmentLogicOperator'
import { ISegmentUnit } from 'main/modules/segment/interfaces/ISegmentUnit'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { ISegmentSaveResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentSaveResponseDTO'
import { SegmentAggForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggForEditionTP'
import { SegmentAggUnitForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggUnitForEditionTP'
import { SegmentAggUnitTP } from 'main/modules/segment/types/aggregator/SegmentAggUnitTP'
import { SegmentExpForEditionTP } from 'main/modules/segment/types/expression/SegmentExpForEditionTP'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { SegmentPlaceHolderTP } from 'main/modules/segment/types/generic/SegmentPlaceHolderTP'
import { SegmentTypeNameTP } from 'main/modules/segment/types/generic/SegmentTypeNameTP'
import { SegmentOperatorForEditionTP } from 'main/modules/segment/types/SegmentOperatorForEditionTP'
import { SegmentUnitForEditionTP } from 'main/modules/segment/types/unit/SegmentUnitForEditionTP'
import { SegmentUnitValueDateTP } from 'main/modules/segment/types/unit/SegmentUnitValueDateTP'
import { SegmentUnitValueEnumTP } from 'main/modules/segment/types/unit/SegmentUnitValueEnumTP'
import { SegmentUnitValueFieldCountTP } from 'main/modules/segment/types/unit/SegmentUnitValueFieldCountTP'
import { SegmentUnitValueFieldTP } from 'main/modules/segment/types/unit/SegmentUnitValueFieldTP'
import { SegmentUnitValueTP } from 'main/modules/segment/types/unit/SegmentUnitValueTP'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { TagRequests } from 'main/modules/tags/services/TagRequests'

type AutocompleteValueSearchResultTP = IApiReturn<IGenericListResponseDTO<INameAndCodeResponseDTO>>

type SegmentTypeConfigTP = {
    name: SegmentTypeNameTP,
    type: SegmentTypeEnum,
    defaultCode: SegmentSpecialListCodeEnum,
    permissions: PermissionEnum[],
}

export class SegmentUtils {

    /** Contador de id's gerados para identificacao de itens na etapa de edicao de listas de segmentacao. */
    private static _segUnitsCount = 0

    /**
     * Parametriza configuracao de tipos de segmento
     * NOTE: A ordem importa!
     */
    private static readonly _SEGMENT_TYPE_CONFIGS: SegmentTypeConfigTP[] = [
        {
            name: 'Contatos',
            type: SegmentTypeEnum.CUSTOMER,
            defaultCode: SegmentSpecialListCodeEnum.CUSTOMER,
            permissions: [PermissionEnum.ROLE_CRM],
        },
        {
            name: 'Colaboradores',
            type: SegmentTypeEnum.COLLABORATOR,
            defaultCode: SegmentSpecialListCodeEnum.COLLABORATOR,
            permissions: [PermissionEnum.ROLE_ADMIN],
        },
        {
            name: 'Fornecedores',
            type: SegmentTypeEnum.SUPPLIER,
            defaultCode: SegmentSpecialListCodeEnum.SUPPLIER,
            permissions: [PermissionEnum.ROLE_FINANCIAL],
        },
    ]

    /** Determina & retorna label para tipo de segmento. */
    static getSegmentTypeName(type: SegmentTypeEnum, isSlugStyle = false): string {
        const config = this._getDefaultSegmentConfig({ type }, true)

        if (!config)
            return ''

        return isSlugStyle ? StringUtils.getSlugStyleString(config.name) : config.name
    }

    static getSegmentTypeByTypeName(name: SegmentTypeNameTP, shouldGetOrDie?: boolean, isSlugStyle?: true): OrUndefTP<SegmentTypeEnum> {
        const config = this._getDefaultSegmentConfig({ name }, shouldGetOrDie, isSlugStyle)
        return config?.type
    }

    /**
     * Retorna nome, em linguagem natural do tipo de lista de segmentacao padrao para exibicao em tela,
     * dependendo das permissoes do usuario atual.
     */
    static getDefaultSegmentTypeName(isSlugStyle: boolean): string {
        return this.getSegmentTypeName(this.getDefaultSegmentType(true), isSlugStyle)
    }

    static getDefaultSegmentType(): OrUndefTP<SegmentTypeEnum>
    static getDefaultSegmentType(shouldGetOrDie: true): SegmentTypeEnum

    /** Retorna tipo de lista de segmentacao padrao para exibicao em tela, dependendo das permissoes do usuario atual. */
    static getDefaultSegmentType(shouldGetOrDie?: true): OrUndefTP<SegmentTypeEnum> {
        const defaultSegmentConfig = this._getDefaultSegmentConfig({}, shouldGetOrDie)
        return defaultSegmentConfig?.type
    }

    static getDefaultSegmentCode(desiredType?: SegmentTypeEnum): OrUndefTP<number>
    static getDefaultSegmentCode(desiredType: SegmentTypeEnum, shouldGetOrDie: true): number
    static getDefaultSegmentCode(shouldGetOrDie: true): number

    /** Retorna codigo da lista de segmentacao padrao para exibicao em tela, dependendo das permissoes do usuario atual. */
    static getDefaultSegmentCode(param1?: SegmentTypeEnum | true, param2?: boolean): OrUndefTP<number> {

        const isParam1Boolean = (typeof param1 === 'boolean')
        const shouldGetOrDie = (isParam1Boolean ? param1 : param2) as OrUndefTP<true>

        const filter: Partial<SegmentTypeConfigTP> = {
            type: !isParam1Boolean ? (param1 as SegmentTypeEnum) : undefined,
        }

        const defaultSegmentConfig = this._getDefaultSegmentConfig(filter, shouldGetOrDie)
        return defaultSegmentConfig?.defaultCode
    }

    /** Detremina & retorna campos disponiveis para 01 determinado tipo de lista de segmentacao. */
    static getAvailableFieldsBySegmentType(segmentType: SegmentTypeEnum): SegmentUnitFieldsEnum[] {

        const defaultFields = [
            SegmentUnitFieldsEnum.NAME,
            SegmentUnitFieldsEnum.GENDER,
            SegmentUnitFieldsEnum.BIRTH_DATE,
            SegmentUnitFieldsEnum.EMAIL,
            SegmentUnitFieldsEnum.EMAIL_STATUS,
            SegmentUnitFieldsEnum.SMS_STATUS,
            SegmentUnitFieldsEnum.STREET,
            SegmentUnitFieldsEnum.CITY,
            SegmentUnitFieldsEnum.NEIGHBORHOOD,
            SegmentUnitFieldsEnum.STATE,
            SegmentUnitFieldsEnum.TAGS,
            SegmentUnitFieldsEnum.CREATE_DATE,
            SegmentUnitFieldsEnum.CREATOR_USER_CODE,
            SegmentUnitFieldsEnum.CPF_CNPJ,
        ]

        switch (segmentType) {
            case SegmentTypeEnum.CUSTOMER:
                return [
                    ...defaultFields,
                    SegmentUnitFieldsEnum.PRODUCT_BOUGHT,
                    SegmentUnitFieldsEnum.PRODUCT_NOT_BOUGHT,
                    SegmentUnitFieldsEnum.OPPORTUNITY_TYPE,
                    SegmentUnitFieldsEnum.OPPORTUNITY_STATUS,
                    SegmentUnitFieldsEnum.SESSION_STATUS,
                    SegmentUnitFieldsEnum.SALE_STATUS,
                ]

            case SegmentTypeEnum.COLLABORATOR:
            case SegmentTypeEnum.SUPPLIER:
            default:
                return defaultFields
        }
    }

    /** Gera & retorna texto para representar visualmente valor de 01 unidade para campo do tipo data. */
    static getSegmentUnitValueDateLabel(dateValue?: SegmentUnitValueDateTP): string {

        if (!dateValue)
            return ''

        if (dateValue === 'today')
            return 'Hoje'
        if (dateValue === 'tomorrow')
            return 'Amanhã'
        if (dateValue === 'current-week')
            return 'Esta semana'
        if (dateValue === 'current-month')
            return 'Este Mês'

        let label = ''

        if (dateValue.day) {
            if (!dateValue.month)
                return `dia ${dateValue.day}`
            label = dateValue.day.toString()
        }

        if (dateValue.month !== undefined) {
            const monthName = DateUtils.getMonthName(dateValue.month)
            if (!dateValue.day && !dateValue.year)
                return `em ${monthName}`
            label += dateValue.day ? `/${monthName}` : monthName
        }

        if (dateValue.year) {
            if (!dateValue.day && !dateValue.month)
                return `em ${dateValue.year}`
            label += `/${dateValue.year}`
        }

        return label
    }

    /** Determina & retorna label para operador de comparacao entre unidades de filtros de segmento. */
    static getUnitCompareOperatorLabel(operator: SegmentUnitCompareOperatorsEnum): string {
        switch (operator) {
            case SegmentUnitCompareOperatorsEnum.EQUAL:
                return 'Igual à'
            case SegmentUnitCompareOperatorsEnum.GREATER_THAN:
                return 'Maior que'
            case SegmentUnitCompareOperatorsEnum.LESS_THAN:
                return 'Menor que'
            case SegmentUnitCompareOperatorsEnum.GREATER_OR_EQUAL:
                return 'Maior ou igual à'
            case SegmentUnitCompareOperatorsEnum.LESS_OR_EQUAL:
                return 'Menor ou igual à'
            case SegmentUnitCompareOperatorsEnum.BETWEEN:
                return 'Entre'
            case SegmentUnitCompareOperatorsEnum.IN:
                return 'Em'
            case SegmentUnitCompareOperatorsEnum.IN_ALL:
                return 'Em todos'
            case SegmentUnitCompareOperatorsEnum.CONTAINS:
                return 'Contém'
            case SegmentUnitCompareOperatorsEnum.STARTS_WITH:
                return 'Começa com'
            default:
                return ''
        }
    }

    /** Determina & retorna label para operador logico entre agregadores de filtros de segmento. */
    static getLogicOperatorLabel(operator: SegmentLogicOperatorsEnum): string {
        switch (operator) {
            case SegmentLogicOperatorsEnum.AND:
                return 'e'
            case SegmentLogicOperatorsEnum.OR:
                return 'ou'
            default:
                return ''
        }
    }

    /** Determina & retorna label para operadores de filtro de segmento. */
    static getUnitFieldLabel(fieldName: SegmentUnitFieldsEnum): string {
        switch (fieldName) {
            case SegmentUnitFieldsEnum.NAME:
                return 'Nome'
            case SegmentUnitFieldsEnum.GENDER:
                return 'Gênero'
            case SegmentUnitFieldsEnum.BIRTH_DATE:
                return 'Aniversário'
            case SegmentUnitFieldsEnum.EMAIL:
                return 'E-mail'
            case SegmentUnitFieldsEnum.EMAIL_STATUS:
                return 'Status de e-mail'
            case SegmentUnitFieldsEnum.SMS_STATUS:
                return 'Status de SMS'
            case SegmentUnitFieldsEnum.STREET:
                return 'Rua'
            case SegmentUnitFieldsEnum.CITY:
                return 'Cidade'
            case SegmentUnitFieldsEnum.NEIGHBORHOOD:
                return 'Bairro'
            case SegmentUnitFieldsEnum.STATE:
                return 'Estado'
            case SegmentUnitFieldsEnum.TAGS:
                return 'Tag'
            case SegmentUnitFieldsEnum.CREATE_DATE:
                return 'Data de criaçao'
            case SegmentUnitFieldsEnum.PHONE:
                return 'Telefone'
            case SegmentUnitFieldsEnum.CREATOR_USER_CODE:
                return 'Criador'
            case SegmentUnitFieldsEnum.CPF_CNPJ:
                return 'CPF/CNPJ'
            case SegmentUnitFieldsEnum.PRODUCT_BOUGHT:
                return 'Comprou'
            case SegmentUnitFieldsEnum.PRODUCT_NOT_BOUGHT:
                return 'Nao Comprou'
            case SegmentUnitFieldsEnum.OPPORTUNITY_TYPE:
                return 'Oportunidade'
            case SegmentUnitFieldsEnum.OPPORTUNITY_STATUS:
                return 'Status da Oportunidade'
            case SegmentUnitFieldsEnum.SESSION_STATUS:
                return 'Sessao'
            case SegmentUnitFieldsEnum.SALE_STATUS:
                return 'Possui compra com status'
            default:
                return ''
        }
    }

    /** Determina & retorna operadores de comparacao possiveis para 01 determinado campo. */
    static getUnitFieldOperators(field: SegmentUnitFieldsEnum): SegmentUnitCompareOperatorsEnum[] {

        if (field === SegmentUnitFieldsEnum.BIRTH_DATE) {
            return [
                SegmentUnitCompareOperatorsEnum.EQUAL,
                SegmentUnitCompareOperatorsEnum.BETWEEN,
            ]
        }

        if (this.isDateField(field)) {
            return [
                SegmentUnitCompareOperatorsEnum.EQUAL,
                SegmentUnitCompareOperatorsEnum.GREATER_THAN,
                SegmentUnitCompareOperatorsEnum.GREATER_OR_EQUAL,
                SegmentUnitCompareOperatorsEnum.LESS_THAN,
                SegmentUnitCompareOperatorsEnum.LESS_OR_EQUAL,
                SegmentUnitCompareOperatorsEnum.BETWEEN,
            ]
        }

        if (this.isSelectOptionField(field)) {
            const ops = [SegmentUnitCompareOperatorsEnum.EQUAL, SegmentUnitCompareOperatorsEnum.IN]

            // Opcao em todos apenas para tags
            if (field === SegmentUnitFieldsEnum.TAGS)
                ops.push(SegmentUnitCompareOperatorsEnum.IN_ALL)

            return ops
        }

        if (this.isStringField(field))
            return [SegmentUnitCompareOperatorsEnum.EQUAL, SegmentUnitCompareOperatorsEnum.CONTAINS, SegmentUnitCompareOperatorsEnum.STARTS_WITH]

        return []
    }

    /** Retorna contagem de campos apropriada para compor o valor de 01 unidade. */
    static getUnitValueFieldsCount(field: SegmentUnitFieldsEnum, operator: SegmentUnitCompareOperatorsEnum): SegmentUnitValueFieldCountTP {
        return ArrayUtils.getIntersection(this._getUnitValueFieldCountsByUnitField(field), this._getUnitValueFieldCountsByOperator(operator))[0]
    }

    /**
     * Mescla dados de 01 dto item de lista com 01 dto de retorno de operacao 'save':
     * Retorna o resultado no formato de dto de item de lista;
     */
    static getListItemDtoFromSavingDto(savingDto: ISegmentSaveResponseDTO, prevListItemDto: ISegmentListItemResponseDTO): ISegmentListItemResponseDTO {
        return {
            ...prevListItemDto,
            code: savingDto.code,
            name: savingDto.name,
            expression: savingDto.expression,
            total: savingDto.total,
        }
    }

    static isStringField(field: SegmentUnitFieldsEnum): boolean {
        return [
            SegmentUnitFieldsEnum.NAME,
            SegmentUnitFieldsEnum.EMAIL,
            SegmentUnitFieldsEnum.PHONE,
            SegmentUnitFieldsEnum.CPF_CNPJ,
            SegmentUnitFieldsEnum.STREET,
            SegmentUnitFieldsEnum.NEIGHBORHOOD,
            SegmentUnitFieldsEnum.CITY,
            SegmentUnitFieldsEnum.STATE,
        ]
            .includes(field)
    }

    static isDateField(field: SegmentUnitFieldsEnum): boolean {
        return [SegmentUnitFieldsEnum.CREATE_DATE, SegmentUnitFieldsEnum.BIRTH_DATE].includes(field)
    }

    /** Determina se 01 campo eh do tipo 'opcao selecionavel'. */
    static isSelectOptionField(field: SegmentUnitFieldsEnum): boolean {
        return (this.isEnumSelectOptionField(field) || this.isAutocompleteSelectOptionField(field))
    }

    /** Determina se 01 campo eh do tipo 'opcao selecionavel' onde a(s) opcao(es) sao enum(s). */
    static isEnumSelectOptionField(field: SegmentUnitFieldsEnum): boolean {
        return [
            SegmentUnitFieldsEnum.GENDER,
            SegmentUnitFieldsEnum.EMAIL_STATUS,
            SegmentUnitFieldsEnum.SMS_STATUS,
            SegmentUnitFieldsEnum.OPPORTUNITY_TYPE,
            SegmentUnitFieldsEnum.OPPORTUNITY_STATUS,
            SegmentUnitFieldsEnum.SESSION_STATUS,
            SegmentUnitFieldsEnum.SALE_STATUS,
        ]
            .includes(field)
    }

    /** Determina se 01 campo eh do tipo 'opcao selecionavel' com autocomplete. */
    static isAutocompleteSelectOptionField(field: SegmentUnitFieldsEnum): boolean {
        return (
            this.isProductField(field)
            || [SegmentUnitFieldsEnum.CREATOR_USER_CODE, SegmentUnitFieldsEnum.TAGS].includes(field)
        )
    }

    static isProductField(field: SegmentUnitFieldsEnum): boolean {
        return [SegmentUnitFieldsEnum.PRODUCT_BOUGHT, SegmentUnitFieldsEnum.PRODUCT_NOT_BOUGHT].includes(field)
    }

    /**
     * Determina se valor de campo possui 01 campo.
     * NOTE: Operacao deve ser dividida em declaracao + teste para garantir verificacao de tipo das opcoes de contagem.
     */
    static isSingleFieldCount(fieldCount: SegmentUnitValueFieldCountTP): boolean {
        const oneFieldCounts: SegmentUnitValueFieldCountTP[] = ['1-number', '1-enum', '1-string', '1-date']
        return oneFieldCounts.includes(fieldCount)
    }

    /**
     * Determina se valor de campo possui 02 campos.
     * NOTE: Operacao deve ser dividida em declaracao + teste para garantir verificacao de tipo das opcoes de contagem.
     */
    static isDoubleFieldCount(fieldCount: SegmentUnitValueFieldCountTP): boolean {
        const twoFieldCounts: SegmentUnitValueFieldCountTP[] = ['2-dates']
        return twoFieldCounts.includes(fieldCount)
    }

    /** Determina se valor de campo eh do tipo que aceita multiplas opcoes selecionaveis. */
    static isMultiOptionFieldCount(fieldCount: SegmentUnitValueFieldCountTP): boolean {
        return (fieldCount === 'n-values')
    }

    /** Retorna objeto que agrega 01 campo do valor de 01 unidade e 01 label em linguagem natural a partir do tipo & valor desse campo. */
    static async getValueWithLabelFromValue(value: SegmentUnitValueTP, field?: SegmentUnitFieldsEnum): Promise<SegmentUnitValueWithLabelTP[]> {

        if (!!field) {

            if (this.isAutocompleteSelectOptionField(field))
                return await this._getAutocompleteSelectFieldValueWithLabel(field, value)

            if (this.isEnumSelectOptionField(field)) {
                return (value as any[]).map(fieldValue => ({
                    value: fieldValue,
                    label: this._getEnumSelectFieldValueWithLabel(field, fieldValue),
                }))
            }
        }

        return (value as SegmentUnitValueFieldTP[]).map(fieldValue => ({ value: fieldValue }))
    }

    /** Retorna valor de 01 campo de 01 unidade a partir de 01 objeto que agrega campo do valor a 01 label em linguagem natural. */
    static getValueFromValueWithLabel(valueWithLabel: SegmentUnitValueWithLabelTP[]): SegmentUnitValueTP {
        return valueWithLabel.map(vWithLabel => vWithLabel.value) as SegmentUnitValueTP
    }

    /**
     * Determina se 01 expressao de lista de segmentacao, em modo de edicao, se encontra vazia:
     * i.e: Possui 01 unico agregador contendo somente 01 placeholder na lista de unidades
     */
    static isExpressionEmpty(expression: SegmentExpForEditionTP): boolean {
        const firstAggregatorUnits = (expression[0] as SegmentAggForEditionTP)?.units ?? []
        return (expression.length === 1 && firstAggregatorUnits.length === 1 && SegmentUtils.listHasPlaceholder(firstAggregatorUnits))
    }

    /**
     * Avalia 01 expresao de lista de segmentacao, em modo de edicao, & determina se seu valor atual pode ser considerado 'completo':
     * i.e: Pode ser inteiramente convertido para forma transacionada com a api
     */
    static isExpressionFullySet(expression: SegmentExpForEditionTP): boolean {
        return (
            !!expression.length
            && expression.every(expUnit => expUnit.itemType === 'op' || this.isAggregatorFullySet(expUnit as SegmentAggForEditionTP))
        )
    }

    static isAggregatorFullySet(aggregator?: SegmentAggForEditionTP): boolean
    static isAggregatorFullySet(aggregatorUnits: Array<SegmentEditableItemTP<SegmentAggUnitForEditionTP>>): boolean

    /**
     * Avalia 01 agregador de lista de segmentacao, em modo de edicao, & determina se seu valor atual pode ser considerado 'completo':
     * i.e: Pode ser inteiramente convertido para forma transacionada com a api
     */
    static isAggregatorFullySet(param1?: SegmentAggForEditionTP | Array<SegmentEditableItemTP<SegmentAggUnitForEditionTP>>): boolean {
        const aggregatorUnits = Array.isArray(param1) ? param1 : param1?.units
        return !!aggregatorUnits?.length
            ? aggregatorUnits.every(aggUnit => aggUnit.itemType === 'op' || this.isUnitFullySet(aggUnit as SegmentUnitForEditionTP))
            : false
    }

    /**
     * Avalia 01 unidade de lista de segmentacao, em modo de edicao, & determina se seu valor atual pode ser considerado 'completo':
     * i.e: Pode ser inteiramente convertido para forma transacionada com a api
     */
    static isUnitFullySet(unit: SegmentUnitForEditionTP): boolean {
        return (this.isUnitSelected(unit) && this.isUnitValueSet(unit?.value))
    }

    static isUnitSelected(unit?: SegmentUnitForEditionTP): boolean
    static isUnitSelected(field?: SegmentUnitFieldsEnum, operator?: SegmentUnitCompareOperatorsEnum): boolean

    /** Determina se o campo & operador de 01 unidade estao setados. */
    static isUnitSelected(param1?: SegmentUnitForEditionTP | SegmentUnitFieldsEnum, param2?: SegmentUnitCompareOperatorsEnum): boolean {

        const hasUnitObj = (typeof param1 === 'object')
        const field = hasUnitObj ? (param1 as SegmentUnitForEditionTP).field : (param1 as SegmentUnitFieldsEnum)
        const operator = hasUnitObj ? (param1 as SegmentUnitForEditionTP).operator : param2

        return (!!field && !!operator)
    }

    static isUnitValueSet(unit?: SegmentUnitForEditionTP): boolean
    static isUnitValueSet(unitValue?: SegmentUnitValueTP): boolean

    /** Determina se a definicao do valor de 01 unidade esta concluida. */
    static isUnitValueSet(param1?: SegmentUnitForEditionTP | SegmentUnitValueTP): boolean {
        const unitValue = Array.isArray(param1) ? param1 : param1?.value
        return (!!unitValue?.length && !unitValue?.find(uValue => !uValue))
    }

    /** Determina se 01 lista generica de unidades (em modo de edicao) possui algum placeholder (item indefinido). */
    static listHasPlaceholder(units: Array<SegmentEditableItemTP<any>>): boolean {
        return !!units.find(unit => unit.itemType === 'placeholder')
    }

    /** Gera & retorna novo 'placeholder' para inclusao de item numa lista de segmentacao em edicao. */
    static getNewPlaceholder(): SegmentPlaceHolderTP {
        return { itemType: 'placeholder', id: this._getNewExpGenericUnitId() }
    }

    /** Retorna todos os codigos de listas de segmentacao especiais (que nao podem ser editadas/removidas). */
    static getSpecialSegmentCodes(): number[] {
        return Object.values(SegmentSpecialListCodeEnum).filter(segmentCode => !isNaN(segmentCode as any)) as number[]
    }

    static isSpecialSegment(segmentCode: number): boolean {
        return this.getSpecialSegmentCodes().includes(segmentCode)
    }

    static getInputTypeByUnitFieldsCount(valueFieldsCount: SegmentUnitValueFieldCountTP): InputTypeTP {
        switch (valueFieldsCount) {
            case '1-number':
                return 'number'
            case '1-date':
            case '2-dates':
                return 'date'
            case '1-string':
            default:
                return 'text'
        }
    }

    /** Transforma & retorna 01 expressao na forma transacionada com a api na forma apropriada para edicao. */
    static getExpressionForEdition(expression: SegmentExpressionTP): SegmentExpForEditionTP {
        return expression.map(realItem => ((realItem.itemType === 'op') ? this.getOperatorForEdition(realItem) : this.getAggregatorForEdition(realItem)))
    }

    /** Transforma & retorna 01 agregador da forma transacionada com a api, para a forma apropriada para edicao. */
    static getAggregatorForEdition(aggregator: ISegmentAggregator<SegmentAggUnitTP>): SegmentAggForEditionTP {
        return {
            ...aggregator,
            id: this._getNewExpGenericUnitId(),
            units: aggregator.units.map(aggUnit => ({ ...aggUnit, id: this._getNewExpGenericUnitId() }))
        }
    }

    static getOperatorForEdition(operator?: ISegmentLogicOperator): SegmentOperatorForEditionTP {
        return {
            id: this._getNewExpGenericUnitId(),
            itemType: 'op',
            operator: operator?.operator ?? SegmentLogicOperatorsEnum.OR,
        }
    }

    /** Transforma & retorna 01 expressao da forma para edicao, para a forma transacionada com a api. */
    static getExpressionFromExpForEdition(expForEdition: SegmentExpForEditionTP): SegmentExpressionTP {

        const expression = expForEdition
            .map(expUnit => {
                if (expUnit.itemType === 'op')
                    return this.getOperatorFromOperatorForEdition(expUnit)
                if (expUnit.itemType === 'agg')
                    return this.getAggregatorFromAggForEdition(expUnit)
            })
            .filter(expUnit => !!expUnit) as SegmentExpressionTP

        if (_.last(expression)?.itemType !== 'agg')
            expression.pop()

        return expression
    }

    /**
     * Transforma & retorna 01 agregador da forma para edicao, para a forma transacionada com a api.
     * NOTE: A api nao aceita definicao incompleta
     */
    static getAggregatorFromAggForEdition(aggForEdition: SegmentAggForEditionTP): OrUndefTP<ISegmentAggregator<SegmentAggUnitTP>> {

        const units = aggForEdition.units.map((editionUnit) => {
            if (editionUnit.itemType === 'op')
                return this.getOperatorFromOperatorForEdition(editionUnit)
            if (editionUnit.itemType === 'unit')
                return this.getUnitFromUnitForEdition(editionUnit)
        })
            .filter(unit => !!unit) as SegmentAggUnitTP[]

        if (_.last(units)?.itemType !== 'unit')
            units.pop()

        if (!!units.length)
            return { itemType: 'agg', units }
    }

    /**
     * Transoforma & retorna 01 unidade de lista de segmentacao da forma editavel para forma transacionada com a api.
     * NOTE: A api nao aceita definicao incompleta
     */
    static getUnitFromUnitForEdition(unitForEdition: SegmentUnitForEditionTP): OrUndefTP<ISegmentUnit> {
        if (SegmentUtils.isUnitFullySet(unitForEdition)) {
            return {
                itemType: 'unit',
                field: unitForEdition.field,
                operator: unitForEdition.operator,
                value: unitForEdition.value ?? [],
            }
        }
    }

    static getOperatorFromOperatorForEdition(operatorForEdition: SegmentOperatorForEditionTP): ISegmentLogicOperator {
        return { itemType: 'op', operator: operatorForEdition.operator }
    }

    private static _getDefaultSegmentConfig(filter: Partial<SegmentTypeConfigTP>, shouldGetOrDie: true, isSlugStyle?: true): SegmentTypeConfigTP
    private static _getDefaultSegmentConfig(filter: Partial<SegmentTypeConfigTP>, shouldGetOrDie?: boolean, isSlugStyle?: true): OrUndefTP<SegmentTypeConfigTP>
    private static _getDefaultSegmentConfig(filter?: Partial<SegmentTypeConfigTP>): OrUndefTP<SegmentTypeConfigTP>

    /**
     * TODO: ADD Descr...
     */
    private static _getDefaultSegmentConfig(filter?: Partial<SegmentTypeConfigTP>, shouldGetOrDie = false, isSlugStyle = false): OrUndefTP<SegmentTypeConfigTP> {

        const filterProp = Object.keys(filter ?? {}).find(prop => !!filter?.[prop]) as OrUndefTP<keyof SegmentTypeConfigTP>

        for (const config of this._SEGMENT_TYPE_CONFIGS) {

            if (!PermissionUtils.arePermissionsGranted(config.permissions))
                continue

            if (!filterProp)
                return config

            const filterPropValue = filter![filterProp]

            let filterValueToCompare: any = filterPropValue
            let configValueToCompare: any = config[filterProp]

            if (filterProp === 'name') {
                filterValueToCompare = isSlugStyle ? StringUtils.getSlugStyleString(filterPropValue as string) : (filterPropValue as string)?.toLowerCase()
                configValueToCompare = isSlugStyle ? StringUtils.getSlugStyleString(configValueToCompare as string) : (configValueToCompare as string)?.toLowerCase()
            }

            if (filterValueToCompare === configValueToCompare)
                return config
        }

        if (shouldGetOrDie) {
            console.error('Não há permissão de acesso ao CRM')
            return undefined
        }
    }

    /** Gera & retorna id unico para elemento generico de lista de segmentacao (em etapa de edicao). */
    private static _getNewExpGenericUnitId(): string {
        this._segUnitsCount++
        const now = new Date()
        const timeHash = `${DateUtils.getFormatted(now, DateFormatEnum.US_WITHOUT_TIME)}|${DateUtils.getFormatted(now, DateFormatEnum.TIME_H_M_S)}`
        return `${this._segUnitsCount}-${timeHash}`
    }

    /**
     * Retorna objeto que agrega 01 campo do valor de 01 unidade e 01 label em linguagem natural
     * a partir do tipo & valor desse campo para campos de selecao do tipo AUTOCOMPLETE.
     */
    private static async _getAutocompleteSelectFieldValueWithLabel(field: SegmentUnitFieldsEnum, value: SegmentUnitValueTP): Promise<SegmentUnitValueWithLabelTP[]> {

        let configGetter: (dto: IListByCodesRequestDTO) => RequestConfigTP

        switch (field) {
            case SegmentUnitFieldsEnum.CREATOR_USER_CODE:
                configGetter = PersonRequests.search
                break
            case SegmentUnitFieldsEnum.TAGS:
                configGetter = TagRequests.search
                break
            case SegmentUnitFieldsEnum.PRODUCT_BOUGHT:
            case SegmentUnitFieldsEnum.PRODUCT_NOT_BOUGHT:
                configGetter = ProductReleaseRequests.searchNames
                break
            default:
                return []
        }

        const searchConfig = configGetter({ codes: value as number[] })

        try {

            const searchResult = await RequestHelper.runRequest<AutocompleteValueSearchResultTP>(searchConfig)
            const namesList = searchResult.data.data?.list ?? []

            return (value as any[]).map(fieldValue => ({
                value: fieldValue,
                label: namesList.find(namesDto => namesDto.code === fieldValue)?.name
            }))

        } catch (err) {
            console.error(`FALHA - ${SegmentUtils.name}.${SegmentUtils._getAutocompleteSelectFieldValueWithLabel.name}: `, field, searchConfig, err)
            return []
        }
    }

    /**
     * Retorna objeto que agrega 01 campo do valor de 01 unidade e 01 label em linguagem natural a partir do tipo & valor
     * desse campo (para campos de selecao do tipo ENUM).
     */
    private static _getEnumSelectFieldValueWithLabel(field: SegmentUnitFieldsEnum, value: SegmentUnitValueEnumTP): string {
        switch (field) {
            case SegmentUnitFieldsEnum.EMAIL_STATUS:
            case SegmentUnitFieldsEnum.SMS_STATUS:
                return PersonUtils.getMktStatusLabel(value as MktStatusEnum)
            case SegmentUnitFieldsEnum.GENDER:
                return PersonUtils.getGenderLabel(value as GenderEnum)
            case SegmentUnitFieldsEnum.OPPORTUNITY_TYPE:
                return OpportunityUtils.getOpportunityTypeLabel(value as OpportunityTypeEnum)
            case SegmentUnitFieldsEnum.OPPORTUNITY_STATUS:
                return OpportunityUtils.getOpportunityStatusLabel(value as OpportunityStatusEnum)
            case SegmentUnitFieldsEnum.SESSION_STATUS:
                return SessionStatusLabelEnum[value as SessionStatusEnum]
            case SegmentUnitFieldsEnum.SALE_STATUS:
                return SalesUtils.getSaleStatusLabel(value as SaleStatusEnum)
            default:
                return ''
        }
    }

    /** Retorna quais as contagens de campos possiveis para compor o valor de 01 unidade de acordo com o TIPO de campo. */
    private static _getUnitValueFieldCountsByUnitField(field: SegmentUnitFieldsEnum): SegmentUnitValueFieldCountTP[] {

        if (this.isStringField(field))
            return ['1-string']
        if (this.isDateField(field))
            return ['1-date', '2-dates']
        if (this.isEnumSelectOptionField(field))
            return ['n-values', '1-enum']
        if (this.isAutocompleteSelectOptionField(field))
            return ['n-values', '1-number']

        return []
    }

    /** Retorna quais as contagens de campos possiveis para compor o valor de 01 unidade de acordo com o OPERADOR. */
    private static _getUnitValueFieldCountsByOperator(operator: SegmentUnitCompareOperatorsEnum): SegmentUnitValueFieldCountTP[] {
        switch (operator) {
            case SegmentUnitCompareOperatorsEnum.BETWEEN:
                return ['2-dates']
            case SegmentUnitCompareOperatorsEnum.IN:
            case SegmentUnitCompareOperatorsEnum.IN_ALL:
                return ['n-values']
            case SegmentUnitCompareOperatorsEnum.CONTAINS:
                return ['1-string']
            case SegmentUnitCompareOperatorsEnum.STARTS_WITH:
            case SegmentUnitCompareOperatorsEnum.EQUAL:
            case SegmentUnitCompareOperatorsEnum.GREATER_THAN:
            case SegmentUnitCompareOperatorsEnum.LESS_THAN:
            case SegmentUnitCompareOperatorsEnum.GREATER_OR_EQUAL:
            case SegmentUnitCompareOperatorsEnum.LESS_OR_EQUAL:
                return ['1-string', '1-number', '1-date', '1-enum']
            default:
                return []
        }
    }
}
