import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IAddressSaveRequestDTO } from 'main/modules/people/services/person/dtos/request/IAddressSaveRequestDTO'
import { IAddressResponseDTO } from 'main/modules/people/services/person/dtos/response/IAddressResponseDTO'

/**
 * UTILITARIOS
 * Enderecos.
 *
 * @author hjcostabr
 */
export class AddressUtils {

    /** Avalia & informa SE ha campos nao nulos de endereco em 01 objeto. */
    static isAddressDirty(object: any): boolean {

        const addressFieldNames: Array<keyof IAddressSaveRequestDTO> = [
            'zipCode',
            'street',
            'number',
            'complement',
            'neighborhood',
            'city',
            'state',
        ]

        for (const addressField of addressFieldNames) {
            if (!!object[addressField])
                return true
        }
        return false
    }

    /**
     * Gera & retorna 01 dto de cadastro de endereco a partir de 01
     * objeto qualquer.
     */
    static getAddressDtoFromFormData(object: any): IAddressSaveRequestDTO {
        return {
            zipCode: !!object.zipCode ? MaskUtils.removeMask(object.zipCode) : undefined,
            street: object.street,
            number: object.number,
            complement: object.complement,
            neighborhood: object.neighborhood,
            city: object.city,
            state: object.state,
        }
    }

    /**
     * verifica se o endereço possui todos os campos obrigatórios para geração de 1 boleto
     * RUA, BAIRRO, CIDADE, ESTADO e CEP
     */
    static checkIfAddressIsComplete(address?: IAddressResponseDTO): boolean {

        if (!address)
            return false

        if (!address.state || !address.city || !address.neighborhood || !address.street || !address.zipCode)
            return false

        return true
    }

}
