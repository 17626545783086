import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { DrawerOpportunityDetailsCP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/DrawerOpportunityDetailsCP'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { ScreenContentSalesFunnelOpportunityMainContentICP } from 'main/modules/sales-funnel/screens/screen-sales-funnel/screen-content/opportunities/inner/ScreenContentSalesFunnelOpportunityMainContentICP'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ScreenContentSalesFunnelOpportunityHeaderICP } from 'main/modules/sales-funnel/screens/screen-sales-funnel/screen-content/opportunities/inner/ScreenContentSalesFunnelOpportunityHeaderICP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface ICPProps {
    initialUser?: INameAndCodeResponseDTO
    onDataChange: (user?: INameAndCodeResponseDTO) => void
}

/**
 * Conteudo de oportunidade.
 */
export function ScreenContentSalesFunnelOpportunityCP(props: ICPProps): JSX.Element {

    const routeParams = useParams<{ view: SalesFunnelOpportunityViewEnum }>()

    const [formValidator] = useState<OpportunityFiltersFormModel>(new OpportunityFiltersFormModel({
        responsibleCode: props.initialUser?.code,
        responsibleName: props.initialUser?.name,
        type: OpportunityTypeEnum[routeParams.view?.split('_')[0]],
        view: routeParams.view,
    }))
    const formStateManager = useFormStateManager<OpportunityFiltersFormModel>(formValidator)

    const [showOpportunityDetailsDrawer, setShowOpportunityDetailsDrawer] = useState<boolean>(false)
    const [selectedOpportunityConversionType, setSelectedOpportunityConversionType] = useState<OpportunityDrawerActionTP>()
    const [selectedOpportunityCode, setSelectedOpportunityCode] = useState<number>()

    const [shouldLoadData, setShouldLoadData] = useState<boolean>(false)

    useEffect(init, [])
    useEffect(onChangeRoute, [routeParams.view])

    /**
     * Inicializa
     */
    function init(): void {
        setShouldLoadData(true)
    }

    /**
     * Inicializa
     */
    function onChangeRoute(): void {

        // O tipo da oportunidade vem na rota antes do underscore
        const type = OpportunityTypeEnum[routeParams.view.split('_')[0]]
        if (!type)
            return

        formStateManager.changeFieldValue('view', routeParams.view)
        formStateManager.changeFieldValue('type', type)

        setShouldLoadData(true)
    }

    /**
     * Callback ao fechar o drawer de detalhes da oportunidade
     */
    function onOpportunityDrawerClose(hasChangedData?: boolean, hasChangedType?: boolean): void {

        setShowOpportunityDetailsDrawer(false)
        setSelectedOpportunityConversionType(undefined)
        setSelectedOpportunityCode(undefined)

        if (!hasChangedType && !hasChangedData)
            return

        onDataChanged('reloadData')
    }

    /**
     * Ao alterar algum dado interno, aqui sera tratado o que deve ser recarregado
     */
    function onDataChanged(type: 'dataLoaded' | 'reloadData'): void {

        switch (type) {

            case 'dataLoaded':
                setShouldLoadData(false)
                break

            case 'reloadData':

                let respNameAndCode
                if (!!formStateManager.getFieldValue('responsibleCode') && !!formStateManager.getFieldValue('responsibleName'))
                    respNameAndCode = { code: formStateManager.getFieldValue('responsibleCode'), name: formStateManager.getFieldValue('responsibleName') }

                setShouldLoadData(true)
                props.onDataChange(respNameAndCode)
                break

            default:
                break
        }

    }

    return (
        <ContentCP>
            <ScreenContentSalesFunnelOpportunityHeaderICP
                formStateManager={formStateManager}
                view={routeParams.view}
                onAddOpportunity={() => setShowOpportunityDetailsDrawer(true)}
                onShouldReloadData={() => setShouldLoadData(true)}
                onSelectOportunity={(oppCode) => {
                    setSelectedOpportunityCode(oppCode)
                    setShowOpportunityDetailsDrawer(true)
                }}
            />

            <ScreenContentSalesFunnelOpportunityMainContentICP
                formStateManager={formStateManager}
                shouldLoadData={shouldLoadData}
                onSelectOpportunity={(code, actionType) => {
                    setSelectedOpportunityCode(code)
                    setSelectedOpportunityConversionType(actionType)
                    setShowOpportunityDetailsDrawer(true)
                }}
                onLoadDataChanged={onDataChanged}
            />

            <DrawerOpportunityDetailsCP
                visible={showOpportunityDetailsDrawer}
                opportunityCode={selectedOpportunityCode}
                conversionType={selectedOpportunityConversionType}
                onClose={onOpportunityDrawerClose}
            />
        </ContentCP>
    )
}
