import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/InputValueCallbackTP'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import React from 'react'
import { GenderEnum } from '../../enums/GenderEnum'
import { PersonUtils } from '../../utils/PersonUtils'

interface IGenderSelectorCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    onChange?: InputValueCallbackTP<GenderEnum>
}

/**
 * COMPONENTE
 * Seletor unitario de genero.
 *
 * @author hjcostabr
 */
export function GenderSelectorCP<FModelTP extends FormModel = any>(props: IGenderSelectorCPProps<FModelTP>): JSX.Element {
    const OPTIONS: SelectOptionTP[] = Object.values(GenderEnum).map((gender, i) => ({
        key: `key-gender-${i}`,
        label: PersonUtils.getGenderLabel(gender),
        value: gender
    }))
    return (
        <SelectCP<FModelTP>
            label={'Gênero'}
            options={OPTIONS}
            onChange={props.onChange}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}
