import { Dropdown } from 'antd'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { styled } from 'main/config/theme/styledWithTheme'
import React, { CSSProperties } from 'react'

interface IDropdownCPProps {
    icon?: JSX.Element
    overlay: JSX.Element
    type?: 'primary' | 'ghost' | 'dashed'
    children: any
    style?: CSSProperties
}

/**
 * COMPONENTE Dropdown Button com opcoes.
 *
 * @author renatofs
 */
export function DropdownCP(props: IDropdownCPProps): JSX.Element {

    return (
        <DropdownBtnSCP style={props.style}>
            <Dropdown.Button
                overlay={props.overlay}
                type={props.type}
                icon={props.icon}
            >
                {props.children}
            </Dropdown.Button>
        </DropdownBtnSCP>
    )
}

const DropdownBtnSCP = styled.div`

.ant-btn{
    border: none;
    padding: 0 10px;
    min-height: 40px;     
    background: ${(props: StyledComponentProps): string => props.theme.secondaryColor};  
    color: #fff;
    &:hover, &:active, &:focus{
        background: ${(props: StyledComponentProps): string => props.theme.secondaryColor};  
        color: #fff;
    }
    span{
        margin: 0 5px;
    }
    &.ant-btn-danger:not([disabled]) {
        background: ${(props: StyledComponentProps): string => props.theme.dangerColor};
        background-color: ${(props: StyledComponentProps): string => props.theme.dangerColor};
        border-color: ${(props: StyledComponentProps): string => props.theme.dangerColor};
    }
}

.ant-btn-primary {
    background-color: ${(props: StyledComponentProps): string => props.theme.primaryColor};
    border: none;
    transition: background-color 0.20s ease-in;
    :disabled {
        background: ${(props: StyledComponentProps): string => props.theme.lightGray};
        color: ${(props: StyledComponentProps): string => props.theme.gray};
        border: none;
    }
}

.ant-btn-pr
imary:hover:not([disabled]), &.ant-btn-primary:focus:not([disabled]) {
    background: ${(props: StyledComponentProps): string => props.theme.primaryColor};
}

.ant-btn-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.ant-btn-ghost {
    background-color: transparent;
    border: 1px solid ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryColor};
}

.ant-btn .anticon {
    display: flex;
    align-items: center;
}

.ant-btn-loading {

    display: flex;
    align-items: center;

    .anticon-loading {
        color: inherit;
        margin-right: 7px;
    }
}

`
