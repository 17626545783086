import { NpsResponderTypeEnum } from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'

export class SurveyAnswerUtils {

    static getNpsResponderType(score: number): NpsResponderTypeEnum {

        if (score <= 6)
            return NpsResponderTypeEnum.DETRACTOR

        if (score <= 8)
            return NpsResponderTypeEnum.PASSIVE

        return NpsResponderTypeEnum.PROMOTER
    }

}
