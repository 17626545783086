import { ShotEmailListColumnsICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotEmailListColumnsICP'
import { ShotListItemActionsColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemActionsColumnICP'
import { ShotSmsListColumnsICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotSmsListColumnsICP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import React from 'react'
import styled from 'styled-components'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotListItemCPProps {
    showActions: boolean
    shot: IMarketingCampaignEmailResponseDTO | IMarketingCampaignSmsResponseDTO
    shotType: MarketingAccountTypeEnum
    onUpdateList?: () => void
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 * Encapsula listagem de disparos de email e sms
 */
export function ShotListItemCP(props: IShotListItemCPProps): JSX.Element {

    return (
        <ListItemContainerSCP>
            {
                props.shotType === MarketingAccountTypeEnum.EMAIL &&
                <ShotEmailListColumnsICP
                    shot={props.shot as IMarketingCampaignEmailResponseDTO}
                    semegmentList={props.semegmentList}
                />
            }
            {
                props.shotType === MarketingAccountTypeEnum.SMS &&
                <ShotSmsListColumnsICP
                    shot={props.shot as IMarketingCampaignSmsResponseDTO}
                    semegmentList={props.semegmentList}
                />
            }
            {
                props.showActions &&
                <ShotListItemActionsColumnICP
                    onUpdateList={props.onUpdateList}
                    shotCode={props.shot.code}
                    shotType={props.shotType}
                    shotStatus={props.shot.status}
                />
            }
        </ListItemContainerSCP>
    )

}

const ListItemContainerSCP = styled.div`
  display: flex;
`
