import { IsArray, IsOptional } from 'class-validator/decorator/decorators'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { IsEmail } from 'main/common/validation/decorators/IsEmail'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsValidCpfOrCnpj } from 'main/common/validation/decorators/IsValidCpfOrCnpj'
import { GenderEnum } from 'main/modules/people/enums/GenderEnum'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IsPhoneBR } from 'main/common/validation/decorators/phone/IsPhoneBR'

/**
 * VALIDADOR
 * Define validação dos campos do formulário adicionar novo contato
 */
export class PersonFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsValidCpfOrCnpj()
    @IsOptional()
    cpfCnpj?: string

    @IsOptional()
    @IsEmail()
    email?: string

    @IsOptional()
    @IsPhoneBR()
    phone?: string

    @IsOptional()
    birthDate?: string

    @IsOptional()
    @IsIn(Object.values(GenderEnum))
    gender?: GenderEnum

    @IsOptional()
    @IsArray()
    @IsIn(Object.values(SegmentTypeEnum))
    types?: SegmentTypeEnum[]

    @IsOptional()
    @IsArray()
    tags?: number[]

    @IsOptional()
    rg?: string

    @IsOptional()
    notes?: string

    // company

    @IsOptional()
    fantasyName?: string

    @IsOptional()
    socialName?: string

    // address

    @IsOptional()
    zipCode?: string

    @IsOptional()
    street?: string

    @IsOptional()
    number?: string

    @IsOptional()
    complement?: string

    @IsOptional()
    neighborhood?: string

    @IsOptional()
    city?: string

    @IsOptional()
    state?: string

    constructor(initialData: ObjectPropsTP<PersonFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
