/**
 * Objeto de parametrizacao para componente de grafico de area/linha.
 *
 * @author renatofs
 */
export const LINE_CHART_CONFIG_OPTIONS = {

    getConfig(
        height?: number | string
    ): any {

        return {
            chart: {
                height,
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 7,
                curve: 'smooth'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            fill: {
                type: 'gradient',
                colors: ['#E8344D'],
                gradient: {
                    shade: 'dark',
                    gradientToColors: ['#4CCB56'],
                    shadeIntensity: 1,
                    type: 'vertical',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [10]
                },
            },
            yaxis: {
                min: -100,
                max: 100,
                title: {
                    text: 'NPS',
                },
            },
            markers: {
                size: 4,
                colors: ['#FFA41B'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            series: [],
            xaxis: {
                categories: [],
                title: {
                    text: 'mês / ano'
                }
            }
        }
    },

}
