import * as _ from 'lodash'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { SegmentExpUnitForEditionTP } from 'main/modules/segment/types/expression/SegmentExpUnitForEditionTP'
import { SegmentAggUnitForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggUnitForEditionTP'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'

type ListUnitTP = SegmentExpUnitForEditionTP | SegmentAggUnitForEditionTP
type OptionalUnitTP<UnitTP extends ListUnitTP> = SegmentEditableItemTP<UnitTP>
type UnitWithIndexTP<UnitTP extends ListUnitTP> = { unit: OptionalUnitTP<UnitTP>, index: number }

type ListTP<UnitTP extends ListUnitTP> = Array<OptionalUnitTP<UnitTP>>

/**
 * Utilitarios para manipular unidades de listas de segmentacao:
 * e.g: agregadores + operadores (unidades de expressao);
 * e.g: unidades + operadores (unidades de agregadores);
 */
export class ExpressionUnitListUtils<UnitTP extends ListUnitTP> {

    static init<UnitTP extends ListUnitTP>(currentList: ListTP<UnitTP>, nextList: ListTP<UnitTP>): ListTP<UnitTP> {

        /*
            Se a lista atual possuir apenas 01 'placeholder' isso equivale a uma lista vazia e, portanto, NAO devemos reinicia-la novamente.
        */

        const hasSinglePlaceholder = (currentList.length === 1 && SegmentUtils.listHasPlaceholder(currentList))
        if (!nextList.length)
            return hasSinglePlaceholder ? currentList : [SegmentUtils.getNewPlaceholder()]

        return !_.isEqual(currentList, nextList) ? nextList : currentList
    }

    static addUnit<UnitTP extends ListUnitTP>(currentList: ListTP<UnitTP>): ListTP<UnitTP> {
        return [
            ...currentList,
            SegmentUtils.getOperatorForEdition(),
            SegmentUtils.getNewPlaceholder(),
        ] as ListTP<UnitTP>
    }

    static editUnit<UnitTP extends ListUnitTP>(currentList: ListTP<UnitTP>, newValue: OptionalUnitTP<UnitTP>): ListTP<UnitTP> {

        const currentUnit = ExpressionUnitListUtils._getUnitWithIndex(currentList, newValue.id)
        if (!currentUnit || _.isEqual(currentUnit, newValue))
            return currentList

        const nextUnits = [...currentList]
        nextUnits[currentUnit.index] = newValue
        return nextUnits
    }

    static removeUnit<UnitTP extends ListUnitTP>(currentList: ListTP<UnitTP>, unitId: string): ListTP<UnitTP> {

        const unitToRemove = ExpressionUnitListUtils._getUnitWithIndex(currentList, unitId)
        if (!unitToRemove)
            return currentList

        /*
            Remove operador a esquerda SE NAO for 1o item;
            Remove operador a direita SE FOR 1o item;
        */

        const mustRemovePrevOperator = (unitToRemove.index > 0)
        const startIndex = !mustRemovePrevOperator ? unitToRemove.index : (unitToRemove.index - 1)
        const nextUnits = [...currentList]

        nextUnits.splice(startIndex, 2)
        return nextUnits.length ? nextUnits : [SegmentUtils.getNewPlaceholder()]
    }

    private static _getUnitWithIndex<UnitTP extends ListUnitTP>(currentList: ListTP<UnitTP>, unitId: string): OrUndefTP<UnitWithIndexTP<UnitTP>> {
        for (const [index, unit] of currentList.entries()) {
            if (unit.id === unitId)
                return { unit, index }
        }
        return undefined
    }
}
