import React, { useEffect, useState } from 'react'
import { PrivateRouter } from 'main/modules/app/routes/PrivateRouter'
import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import { UserMainMenuCP } from '../user-main-menu/UserMainMenuCP'
import { ChatBalloonCP } from 'main/common/components/chat-balloon/ChatBalloonCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ModalBoxCustomerSurveyCP } from 'submodules/space4leads-components-ui/survey/components/box-customer-survey/ModalBoxCustomerSurveyCP'
import { SystemConfig } from 'main/config/SystemConfig'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'


/**
 * Layout principal da parte interna do sistema
 */
export function UserMainLayoutCP(): JSX.Element {

    const [shouldReload, setShouldReload] = useState<boolean>(false)
    useEffect(onReload, [shouldReload])

    /**
     * Metodo auxiliar ao precisar recarregar a tela
     */
    function onReload(): void {

        if (!shouldReload)
            return

        // Faz uma pausa para recarregar a tela pois alterou a variavel para true <-> false
        SystemUtils.sleep(1000)
        setShouldReload(false)
    }

    if (shouldReload)
        return <LoadingOverlayCP show={true}/>

    return (
        <LayoutCP
            mainLayout={true}
            content={
                <ContentCP>
                    <TopBarHomEnvironmentCP/>
                    <PrivateRouter/>

                    <ChatBalloonCP/>
                    <ModalBoxCustomerSurveyCP
                        person={{
                            name: AppStateUtils.getLoggedUserData()!.user.name,
                            email: AppStateUtils.getLoggedUserData()!.user.email,
                        }}
                        tags={[AppStateUtils.getDomainSlug()!, AppStateUtils.getCurrentFranchise()!.name, ...AppStateUtils.getCurrentFranchise()!.permissions]}
                        timeConfig={{
                            daysBetweenAnswers: 30,
                            daysToFirstAnswer: 5,
                            daysToReopenOnCancel: 5,
                        }}
                        boxType={'bottom'}
                        surveyToken={SystemConfig.getInstance().space4LeadsNeritClinSurveyToken}
                        projectCode={SystemConfig.getInstance().space4LeadsNeritClinProjectCode}
                    />
                </ContentCP>
            }
            sider={
                <SiderCP
                    isMainSider={true}
                    collapsedWidth={0}
                    breakpoint={'md'}
                    width={67}
                >
                    <UserMainMenuCP onChangeCurrentFranchise={() => setShouldReload(true)}/>
                </SiderCP>
            }
        />
    )
}
