import React, { useState } from 'react'
import styled from 'styled-components'
import { AttendanceReportCP } from 'main/modules/dashboard/components/attendance-report/AttendanceReportCP'
import { FranchisorDashboardGeneralStatisticsCP } from 'main/modules/dashboard/components/franchisor-dashboard-general-statistics/FranchisorDashboardGeneralStatisticsCP'
import { FranchisorSalesPerPeriodChartCP } from 'main/modules/dashboard/components/franchisor-sales-per-period-chart/FranchisorSalesPerPeriodChartCP'
import { FranchisorDashboardFiltersCP } from 'main/modules/dashboard/components/franchisor-dashboard-filters/FranchisorDashboardFiltersCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FranchisorTopBillingStatisticsCP } from 'main/modules/dashboard/components/franchisor-top-billing-statistics/FranchisorTopBillingStatisticsCP'
import { FlexOneICP } from 'main/common/components/flex/inner/FlexOneICP'
import { FranchisorTopSaleStatisticsCP } from 'main/modules/dashboard/components/franchisor-top-sale-statistics/FranchisorTopSaleStatisticsCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { TableCP } from 'main/common/components/table/TableCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import moment from 'moment'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { Tooltip } from 'antd'
import { ExcelUtil } from 'main/modules/people/utils/ExcelUtil'





/**
 * Tela do módulo Dashboard
 */
export function ScreenContentFranchisorReport(): JSX.Element {
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
    })
    const [selectedDate, setSelectedDate] = useState<Date>(new Date)
    const [faturamentoA, setFaturamentoA] = useState<any>(null);
    const [faturamentoB, setFaturamentoB] = useState<any>(null);
    const [vendaA, setVendaA] = useState<any>(null);
    const [vendaB, setVendaB] = useState<any>(null);
    const [atendimentos,setAtendimentos] = useState<any>(null);

    function exportarEmExcel (){
        console.log(faturamentoA,faturamentoB,vendaA,vendaB,atendimentos);
        let r = `EXPORTACAO DE DASHBOARD\n\n`;
        r+=`FATURAMENTO POR FRANQUIA COM RECEITA E DESPESA\n`;
        r+=ExcelUtil.excel(faturamentoA,[['FRANQUIA','unidade'],['VALOR','valor']]);
        r+=`\n FATURAMENTO POR FRANQUIA SEM RECEITA E DESPESA\n`;
        r+=ExcelUtil.excel(faturamentoB,[['FRANQUIA','unidade'],['VALOR','valor']]);
        r+=`\n VENDAS POR FRANQUIAS TOTAIS\n`;
        r+=ExcelUtil.excel(vendaA,[['FRANQUIA','unidade'],['VALOR','valor']]);
        r+=`\n VENDAS POR FRANQUIAS CONSOLIDADAS\n`;
        r+=ExcelUtil.excel(vendaB,[['FRANQUIA','unidade'],['VALOR','valor']]);
        r+=`\n ATENDIMENTOS POR TURNO\n`;
        const novos = atendimentos.map((i:any)=>({unidade:i.franchise.name,manha:i.firstWorkShift,tarde:i.secondWorkShift,total:i.totalAttendances}));
        r+=ExcelUtil.excel(novos,[['FRANQUIA','unidade'],['MANHA','manha'],['TARDE','tarde'],['TOTAL','total']]);
        ExcelUtil.salvarTexto('DashBoard.csv',r);
    }

    function mesAtual(){
        setDateFilters({
            beginDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate()
        });
    }
    function mudouDatas(v:any){
        console.log("teste ",v)
        setDateFilters(v);
    }

    const colunasTab:any = [
        {
          title: 'Unidade',
          dataIndex: 'unidade',
          key: 'unidade',
          render:(txt:any,dados:any)=>{return <TUnidade>{dados.unidade}</TUnidade>}
        },
        {
          title: 'Valor',
          dataIndex: 'valor',
          key: 'valor',
          render:(txt:any,dados:any)=>{return <Preco>{MaskUtils.applyMoneyMask(dados.valor)}</Preco>}
        //   render:(txt:any,dados:any)=>{MaskUtils.applyMoneyMask(dados.valor)}
        }
    ]

    return (
        <WrapperSCP>
            <AreaExportar>
                <ButtonCP
                icon={'file-excel'}
                size={'small'}
                onClick={exportarEmExcel}
                loading={false}
                type={'ghost'}
                tooltip={'Clique para exportar os dados em tela para o excel'}
                >
                Exportar .csv
                </ButtonCP>
            </AreaExportar>
            <FranchisorDashboardGeneralStatisticsCP/>

           
            <FiltersWrapperSCP>
                <CardCP>
                    <TituloTempo>
                        <div>
                            Intervalo Temporal dos Dados
                        </div>
                        <ButtonCP icon={'calendar'} onClick={mesAtual} tooltip='Clique para filtrar o mês atual' />
                    </TituloTempo>
                    <DateRangePickerCP
                                
                                value={dateFilters}
                                onChange={mudouDatas}
                                fastFilter={'all'}
                                allowClear={false}
                                showBorder={true}
                    />
                </CardCP>
            </FiltersWrapperSCP>

            <FlexCP marginRight={20} marginLeft={10}>
                <FlexOneICP>
                    <AttendaceReportWrapperSCP>
                        <AttendanceReportCP setAtendimentos={setAtendimentos} date={selectedDate} dataFiltrada={dateFilters}/>
                    </AttendaceReportWrapperSCP>
                </FlexOneICP>
                <FlexOneICP marginTop={20}>
                    <FlexCP>
                        <FlexOneICP>
                            <FranchisorTopBillingStatisticsCP dataFiltrada={dateFilters} setGrupoA={setFaturamentoA} setGrupoB={setFaturamentoB}  date={selectedDate}/>
                        </FlexOneICP>
                        <FlexOneICP>
                            <FranchisorTopSaleStatisticsCP dataFiltrada={dateFilters} setGrupoA={setVendaA} setGrupoB={setVendaB}  date={selectedDate}/>
                        </FlexOneICP>
                    </FlexCP>
                </FlexOneICP>
            </FlexCP>

            <WrapperGridFour>
                <div>

                    {!!faturamentoA && <FundoT>
                        <CardCP>
                        <Tooltip title={`Corresponde a segregação de dados do Cartão [FATURAMENTO DE TODAS AS FRANQUIAS]\n
                            Nesta, é calculado todo o faturamento, incluindo Receitas e Despesas lançadas no módulo financeiro.
                            `}>
                            <TituloBloco>
                                Faturamento Por Franquias
                                <div>
                                    Com Receita/Despesa
                                </div>
                            </TituloBloco>
                        </Tooltip>
                            <TableCP
                            columns={colunasTab}
                            data={faturamentoA ?? []}
                            />
                        </CardCP>
                    </FundoT>}
                </div>
                <div>
                {!!faturamentoB && <FundoT>
                        <CardCP>
                        <Tooltip title={`Corresponde a segregação de dados do Cartão [TUDO QUE FOI RECEBIDO SEM CONSIDERAR RECEITAS e DESPESAS]\n
                            Nesta, é calculado tudo que foi recebido oriundo das vendas dentro do período informado.
                            `}>
                            <TituloBloco>
                                Faturamento Por Franquias
                                <div>
                                    Sem Receita/Despesa
                                </div>
                            </TituloBloco>
                        </Tooltip>
                            <TableCP
                            columns={colunasTab}
                            data={faturamentoB ?? []}
                            />
                        </CardCP>
                    </FundoT>}
                </div>
                <div>{!!vendaA && <FundoT>
                        <CardCP>
                        <Tooltip title={`Corresponde a segregação de dados do Cartão [TOTAL EM VENDAS DE TODAS AS FRANQUIAS]\n
                            Nesta, é calculado tudo que foi vendido no período informado.
                            `}>
                            <TituloBloco>
                                Vendas Por Franquias
                                <div>
                                    Totais
                                </div>
                            </TituloBloco>
                        </Tooltip>
                            <TableCP
                            columns={colunasTab}
                            data={vendaA ?? []}
                            />
                        </CardCP>
                    </FundoT>}</div>
                <div>{!!vendaB && <FundoT>
                        <CardCP>
                        <Tooltip title={`Corresponde a segregação de dados do Cartão [CONSOLIDADO DE VENDAS DE TODAS AS FRANQUIAS]\n
                            Nesta, é calculado todas as vendas consolidadas no período informado.
                            `}>
                            <TituloBloco>
                                Vendas Por Franquias
                                <div>
                                    Consolidadas
                                </div>
                            </TituloBloco>
                        </Tooltip>
                            <TableCP
                            columns={colunasTab}
                            data={vendaB ?? []}
                            />
                        </CardCP>
                    </FundoT>}</div>
            </WrapperGridFour>
            <FranchisorDashboardFiltersCP onChangeDate={setSelectedDate} initialDate={selectedDate}/>
            <FranchisorSalesPerPeriodChartCP date={selectedDate}/>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    overflow-x: auto;
`

const WrapperGridFour = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    justify-content:center;
    gap:4px;
    margin-inline:2px;
`

const AttendaceReportWrapperSCP = styled.div`
    .ant-card {
      height: 542px;
    }
`

const Preco = styled.div`
    font-size: 11px;
    text-align: right;
    font-weight: 605;
`
const TUnidade = styled.div`
    letter-spacing: -0.062em;
    font-weight: 557;
    font-size: 9px;
    line-height: 15px;
    text-transform: uppercase;
`
const TituloBloco = styled.div`
    color: #2b698cff;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 4px solid;
    margin-bottom: 7px;
    font-weight: 799;
    font-size: 16px;
`

const FundoT = styled.div`
    .ant-card {
      margin:0px
      height: 580px;
      overflow-y:auto;
    }
`

const FiltersWrapperSCP = styled.div`
   display: flex;
   margin: 20px 0;
   justify-content: center;
`

const TituloTempo = styled.div`
    font-weight: 637;
    text-transform: uppercase;
    border-bottom: 3px solid #2b698c;
    color: #2b698c;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 54px;
    padding-bottom: 3px;
    flex-direction: row-reverse;
`

const AreaExportar = styled.div`
    display:flex;
    justify-content: flex-end;
    margin-top:4px;
    margin-right: 10px;
`

