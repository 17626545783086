import React, { useState } from 'react'
import { MarketingDomainsCP } from 'main/modules/marketing/components/marketing-domains/MarketingDomainsCP'
import { ModalDomainCP } from 'main/modules/marketing/components/modal-domain/ModalDomainCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'

/**
 * COMPONENTE com as configuracoes de dominios para disparo de marketing
 */
export function ScreenContentMktDomainsCP(): JSX.Element | null {

    const [isDomainModalVisible, setIsDomainModalVisible] = useState<boolean>(false)
    const [mustUpdateDomains, setMustUpdateDomains] = useState<boolean>(true)

    return (
        <>
            <HeaderCP
                title={'Configurações de Domínio'}
            >
                <HeaderButtonICP
                    onClick={() => {
                        setIsDomainModalVisible(true)
                        setMustUpdateDomains(false)
                    }}
                    icon={'plus'}
                    label={'Nova Domínio'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <MarketingDomainsCP mustUpdateDomains={mustUpdateDomains}/>
            </LayoutSubmenuContentCP>

            <ModalDomainCP
                visible={isDomainModalVisible}
                onSave={() => {
                    setIsDomainModalVisible(false)
                    setMustUpdateDomains(true)
                }}
                onCancel={() => {
                    setIsDomainModalVisible(false)
                    setMustUpdateDomains(false)
                }}
            />
        </>
    )
}
