import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { HasOverlapsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/HasOverlapsRequestDTO'
import { ScheduleAppointmentChangeEndTimeRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleAppointmentChangeEndTimeRequestDTO'
import { ScheduleRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleRequestDTO'
import { SearchScheduleAppointmentsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/SearchScheduleAppointmentsRequestDTO'
import { CalendarSummaryResponseDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/responses/CalendarSummaryResponseDTO'

export class ScheduleRequests {

	public static RE_SCHEDULE_EP = 'reschedule'
	public static CONTROLLER_ROOT = 'schedules'
	public static CHANGE_END_TIME_EP = 'change-end-time'
	public static CALENDAR_SUMMARY_EP = 'calendar-summary'
	public static MARK_AS_NO_SHOW_EP = 'mark-as-no-show'
	public static HAS_OVERLAP = 'has-overlap'

	static search = (dto: SearchScheduleAppointmentsRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ScheduleRequests.CONTROLLER_ROOT}`,
		method: HttpMethodEnum.GET,
		params: dto
	})

	static changeEndTime = (dto: ScheduleAppointmentChangeEndTimeRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ScheduleRequests.CONTROLLER_ROOT}/${ScheduleRequests.CHANGE_END_TIME_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static reScheduleSessions = (dto: ScheduleRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ScheduleRequests.CONTROLLER_ROOT}/${ScheduleRequests.RE_SCHEDULE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static caledarSummary = (dto: SearchScheduleAppointmentsRequestDTO): RequestConfigTP<ListResponseDTO<CalendarSummaryResponseDTO>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ScheduleRequests.CONTROLLER_ROOT}/${ScheduleRequests.CALENDAR_SUMMARY_EP}`,
		method: HttpMethodEnum.GET,
		params: dto
	})

	static hasOverlap = (dto: HasOverlapsRequestDTO): RequestConfigTP<boolean> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ScheduleRequests.CONTROLLER_ROOT}/${ScheduleRequests.HAS_OVERLAP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

}
