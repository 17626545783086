import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import React from 'react'
import { IEvaluationsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IEvaluationsByUserListItemResponseDTO'
import { MathUtils } from 'main/common/utils/MathUtils'

/**
 * UTILITARIOS do componente de listagem de total de leads por colaborador.
 */
export const TotalEvaluationByUserTableUtils = {

    getTableColumns(): Array<ColumnProps<IEvaluationsByUserListItemResponseDTO>> {
        return [
            {
                title: 'Colaborador',
                dataIndex: 'user',
                key: 'user',
                render: (text, dto) => dto.user.name
            },
            {
                title: 'Ag. p/ Hoje',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.scheduledToday, 2)
            },
            {
                dataIndex: 'prog-today',
                key: 'prog-today',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação a meta: ${dto.scheduledTodayTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.scheduledToday, dto.scheduledTodayTarget, true)}
                        gradientColor={true}
                    />
                )
            },
            {
                title: 'Ag. p/ amanhã',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.scheduledTomorrow, 2)
            },
            {
                dataIndex: 'prog-tomorrow',
                key: 'prog-tomorrow',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação a meta: ${dto.scheduledTomorrowTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.scheduledTomorrow, dto.scheduledTomorrowTarget, true)}
                        gradientColor={true}
                    />
                )
            },
            {
                title: 'Ag. após 48h',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.scheduledMore48hrs, 2)
            },
            {
                dataIndex: 'prog-aftertomorrow',
                key: 'prog-aftertomorrow',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação a meta: ${dto.scheduledMore48hrsTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.scheduledMore48hrs, dto.scheduledMore48hrsTarget, true)}
                        gradientColor={true}
                    />
                )
            },
            {
                title: 'Total',
                key: 'total',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.scheduledToday + dto.scheduledTomorrow + dto.scheduledMore48hrs, 2)
            },
            {
                key: 'prog-total',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação às metas das 3 avaliações: ${dto.scheduledTodayTarget + dto.scheduledTomorrowTarget + dto.scheduledMore48hrsTarget}`}
                        width={80}
                        percent={
                            MathUtils.getPercentage(
                                (dto.scheduledToday + dto.scheduledTomorrow + dto.scheduledMore48hrs),
                                (dto.scheduledTodayTarget + dto.scheduledTomorrowTarget + dto.scheduledMore48hrsTarget),
                                true
                            )
                        }
                        gradientColor={true}
                    />
                )
            },
        ]
    },

}
