import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IOpportunitySearchRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySearchRequestDTO'
import { IOpportunitySaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySaveRequestDTO'
import { IOpportunityChangeToEvaluationRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityChangeToEvaluationRequestDTO'
import { IOpportunityChangeStepRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityChangeStepRequestDTO'
import { IOpportunityBulkChangeResponsibleRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityBulkChangeResponsibleRequestDTO'
import { ISalesFunnelSummaryRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/ISalesFunnelSummaryRequestDTO'
import { IOpportunitySearchByPhoneOrEmailRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySearchByPhoneOrEmailRequestDTO'
import { IGenericNameAndCodeSearchRequestDTO } from 'main/common/dtos/requests/IGenericNameAndCodeSearchRequestDTO'
import { IOpportunityByExpressionSaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityByExpressionSaveRequestDTO'

/**
 * REQUISIÇÕES relacionadas a Oportunidades.
 */
export class OpportunityRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/opportunities`

    static getOpportuityConfig = (code: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static searchConfig = (dto: IOpportunitySearchRequestDTO): RequestConfigTP => ({
        url: OpportunityRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchByString = (dto: IGenericNameAndCodeSearchRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchByPhoneOrEmail = (dto: IOpportunitySearchByPhoneOrEmailRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/search-by-phone-or-email`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static export = (dto: IOpportunitySearchRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/export`,
        method: HttpMethodEnum.GET,
        params: dto,
        responseType: 'blob',
    })

    static createConfig = (dto: IOpportunitySaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateConfig = (code: number, dto: IOpportunitySaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static changeToEvaluation = (opportunityCode: number, dto: IOpportunityChangeToEvaluationRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/change-to-evaluation`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static changeToFollowUp = (opportunityCode: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/change-to-followup`,
        method: HttpMethodEnum.PUT
    })

    static rollbackOpportunityType = (opportunityCode: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/rollback-type`,
        method: HttpMethodEnum.PUT,
    })

    static moveStepOpportunity = (opportunityCode: number, dto: IOpportunityChangeStepRequestDTO): RequestConfigTP<IOpportunityChangeStepRequestDTO> => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/change-step`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static changeResponsible = (dto: IOpportunityBulkChangeResponsibleRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/change-bulk-responsible`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static markOpportunityAsLost = (opportunityCode: number, archiveReasonCode: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/mark-as-lost`,
        method: HttpMethodEnum.PUT,
        params: { lossReasonCode: archiveReasonCode }
    })

    static unarchiveOpportunity = (opportunityCode: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}/unarchive`,
        method: HttpMethodEnum.PUT,
    })

    static deleteOpportunity = (opportunityCode: number): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/${opportunityCode}`,
        method: HttpMethodEnum.DELETE,
    })

    static getSalesFunnelSummary = (dto: ISalesFunnelSummaryRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/summary`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saveByExpression = (dto: IOpportunityByExpressionSaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunityRequests._MODULE_BASE}/save-by-expression`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}
