import React from 'react'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { TagCPColorTP } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCPColorTP'
import { SaleStatusLabelEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleStatusEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    status: SaleStatusEnum
}

/**
 * Tag para status de uma venda.
 */
export function TagSaleStatusCP(props: ICPProps): JSX.Element {

    let color: OrUndefinedTP<TagCPColorTP | string> = undefined

    switch (props.status) {

        case SaleStatusEnum.OK:
        case SaleStatusEnum.CONCLUDED:
            color = 'green'
            break

        case SaleStatusEnum.CANCELLED:
            color = '#000'
            break

        case SaleStatusEnum.DEFAULTING:
            color = 'red'
            break

        case SaleStatusEnum.PENDING:
            color = 'yellow'
            break

        default:
            break
    }

    return (
        <TagCP
            margin={props.margin}
            small={true}
            color={color}
            content={SaleStatusLabelEnum[props.status]}
        />
    )

}

