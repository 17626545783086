import { EnvironmentEnum } from 'main/common/enums/EnvironmentEnum'

/**
 * Classe de configuracao do sistema.
 * TODO: Rename: 'system' -> 'app' (padronizar nome do arquivo de configuracoes)
 */
export class SystemConfig {

    readonly appName = 'Lumia Clinic'
    readonly defaultTimeZone = 'America/Sao_Paulo'
    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum

    readonly reduxEncryptKey = 'bmVyaXRzZWNyZXRrbXk='
    readonly intercomId = ''
    readonly googleApiKey = ''

    readonly apiBaseUrl: string
    readonly uiBaseUrl: string
    readonly neritPaymentsBaseUrl: string
    readonly mktBaseUrl: string
    readonly dokfyBaseUrl: string

    readonly space4LeadsApiBaseUrl: string
    readonly space4LeadsNeritClinSurveyToken: string
    readonly space4LeadsNeritClinProjectCode: number

    readonly anonymousUserToken: string

    private static _instance: SystemConfig

    private constructor() {
        console.log(EnvironmentEnum);
        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://localhost:3001'
                this.mktBaseUrl = 'https://lumiacrm.com.br/marketing'
                this.dokfyBaseUrl = 'https://lumiacrm.com.br/dokfy'
                this.space4LeadsApiBaseUrl = 'https://app.lumiacrm.com.br/star'
                this.neritPaymentsBaseUrl = 'http://localhost:3003'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                this.space4LeadsNeritClinSurveyToken = ''
                this.space4LeadsNeritClinProjectCode = 0
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://app.lumiacrm.com.br/'
                this.apiBaseUrl = 'https://app.lumiacrm.com.br/api'
                this.mktBaseUrl = 'https://app.lumiacrm.com.br/marketing'
                this.dokfyBaseUrl = 'https://app.lumiacrm.com.br/dokfy'
                this.space4LeadsApiBaseUrl = 'https://app.lumiacrm.com.br/star'
                this.neritPaymentsBaseUrl = 'https://app.lumiacrm.com.br/pagamento/payments'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                this.space4LeadsNeritClinSurveyToken = ''
                this.space4LeadsNeritClinProjectCode = 0
                break

            case EnvironmentEnum.HOM:
            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    static getInstance(): SystemConfig {
        if (!this._instance)
            this._instance = new SystemConfig()
        return this._instance
    }
}
