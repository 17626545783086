import styled from 'main/config/theme/styledWithTheme'
import { OpportunityDataFormCP } from 'main/modules/sales-funnel/components/opportunity-data-form/OpportunityDataFormCP'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { ILeadDataResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/ILeadDataResponseDTO'
import React, { useState } from 'react'
import { OpportunityDataCP } from 'main/modules/sales-funnel/components/opportunity-data/OpportunityDataCP'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { OpportunityFormModel } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityFormModel'

interface IOpportunityBasicInfoCPProps {
    opportunity?: IOpportunityResponseDTO
    initialData?: ObjectPropsTP<OpportunityFormModel>
    setOpportunityBasicData: (data: ILeadDataResponseDTO) => void
    onSave: (opportunity: IOpportunityResponseDTO, updateSummary?: OpportunityTypeEnum[], updateList?: OpportunityTypeEnum) => void
}

/**
 * Exibe as informações básicas de uma oportunidade e controla a exibição do form de edição
 */
export function OpportunityBasicInfoCP(props: IOpportunityBasicInfoCPProps): JSX.Element {

    const [enableEdition, setEnableEdition] = useState<boolean>(false)

    return (
        <WrapperSCP>

            {
                props.opportunity && !enableEdition
                    ?
                    <OpportunityDataCP
                        opportunity={props.opportunity}
                        onEdit={() => setEnableEdition(true)}
                    />
                    :
                    <OpportunityDataFormCP
                        opportunity={props.opportunity}
                        initialData={props.initialData}
                        onCancel={() => setEnableEdition(false)}
                        onSave={(_opportunity, _updateSummary?, _updateList?) => {
                            setEnableEdition(false)
                            props.onSave(_opportunity, _updateSummary, _updateList)
                        }}
                    />
            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    min-height:  85vh;
`
