import React from 'react'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { IBankAccountConfig } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountConfig'
import { BankAccountUtils } from 'main/modules/financial/utils/BankAccountUtils'

interface IBankAccountTypeColumnICPProps {
    bankAccount: IBankAccountResponseDTO
}

/**
 * COMPONENTE
 * Coluna de tipo da conta bancaria.
 */
export function BankAccountTypeColumnICP(props: IBankAccountTypeColumnICPProps): JSX.Element {

    if (props.bankAccount.type !== BankAccountTypeEnum.BANK_ACCOUNT || !props.bankAccount.config)
        return <></>

    return (
        <>
            { BankAccountUtils.getBankAccountModalityLabel((props.bankAccount.config as IBankAccountConfig).modality) }
        </>
    )
}
