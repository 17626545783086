import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'

export class ProductReleaseTreatmentFormModel extends FormModel {

    @IsNotEmpty()
    treatmentCode: number

    @IsNotEmpty()
    totalSessions: number

    @IsNotEmpty()
    duration: number

    @IsNotEmpty()
    totalPersons: number

    // Auxiliar
    treatmentName: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
