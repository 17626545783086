import React from 'react'
import { FranchiseOrFranchisorTP, PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { LogicOperationTP } from 'main/common/types/LogicOperationTP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'

interface IAccessControlCPProps {

    children: React.ReactNode

    logic?: LogicOperationTP
    module?: ModuleEnum
    redirect?: boolean
    permissions?: PermissionEnum[]
    notifyDenial?: boolean
    routeToReturn?: string
    franchiseOrFranchisor?: FranchiseOrFranchisorTP
}

/**
 * COMPONENTE
 * Wrapper que abstrai tratamento generico para restricao de acesso a recursos do sistema dependendo da compatibilidade
 * entre a combinacao de restricoes definidas para o recurso, os modulos habilitados para o schema e as permissoes do usuario atual logado.
 */
export function AccessControlCP(props: IAccessControlCPProps): JSX.Element {

    if (PermissionUtils.hasAccess(props.module, props.permissions, props.franchiseOrFranchisor, props.logic))
        return <>{props.children}</>

    if (props.redirect === false)
        return <></>

    if (props.notifyDenial)
        PermissionUtils.showDefaultDeniedAccessMsg()

    return RoutingHelper.renderRedirect(props.routeToReturn ?? 'default')
}
