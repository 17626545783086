import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ShotListItemContactColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemContactColumnICP'
import { ShotListItemDateColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemDateColumnICP'
import { ShotListItemStatisticColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemStatisticColumnICP'
import { PreviewPhoneTextCP, SMS_IMAGE_CLASS, SMS_TEXT_BOX_CLASS } from 'main/modules/marketing/components/preview-phone-text/PreviewPhoneTextCP'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import React from 'react'
import styled from 'styled-components'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotSmsListColumnsICPProps {
    shot: IMarketingCampaignSmsResponseDTO
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 * Colunas da listagem de disparos de sms
 */
export function ShotSmsListColumnsICP(props: IShotSmsListColumnsICPProps): JSX.Element {

    return (
        <>
            <ShotListItemDateColumnICP
                scheduleDate={props.shot.scheduleDate}
                startDate={props.shot.startDate}
                status={props.shot.status}
            />

            <SmsPreviewWrapperSCP>
                <PreviewPhoneTextCP text={props.shot.content}/>
            </SmsPreviewWrapperSCP>

            <ShotListItemContactColumnICP
                destination={props.shot.destination}
                totalContacts={props.shot.totalContacts}
                semegmentList={props.semegmentList}
            />
            {
                (props.shot.status === MarketingShotStatusEnum.ERROR || props.shot.status === MarketingShotStatusEnum.SENT) &&
                <ShotListItemStatisticColumnICP
                    total={props.shot.totalDelivered ?? 0}
                    icon={<FontAwsomeIconCP type={faEnvelope} color={ThemeAnt.primaryColor}/>}
                    percent={MathUtils.getPercentage(props.shot.totalDelivered, props.shot.totalContacts)}
                    label={'RECEBERAM'}
                />
            }
        </>
    )

}

const SmsPreviewWrapperSCP = styled.div`

  .${SMS_IMAGE_CLASS} img {
    object-fit: cover;
    object-position: -20% 0;
    width: 300px;
    height: 235px;
  }

  .${SMS_TEXT_BOX_CLASS} {
    top: 51% !important;
    width: 190px !important;;
    padding: 5px !important;;
    font-size: 10px;
  }
`
