export enum OpportunityStatusEnum {
    OPEN = 'OPEN',
    LOST = 'LOST',
    CONCLUDED = 'CONCLUDED'
}

export enum OpportunityStatusLabelEnum {
    OPEN = 'Em aberto',
    LOST = 'Perdido',
    CONCLUDED = 'Convertido'
}