import moment, { Moment } from 'moment'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { IBalancePerPeriodListItemResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBalancePerPeriodListItemResponseDTO'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'

/**
 * UTILS
 * Contem metodos auxliares para construcao de 01 grafico dos dashboards.
 */
export class BalancePerPeriodChartUtils {

    private constructor() {}

    /** Itera no intervalo de datas montando array com todas elas baseado na periodicidade (Adiciona meses/dias/etc.). */
    static getXAxisDates(range: RangePickerValue, timeBase: TimeBaseEnum): Moment[] {

        const dates: Moment[] = []
        const endDate = moment(range[1]).endOf(timeBase)
        let nextDate = moment(range[0]).startOf(timeBase)

        while (nextDate < endDate) {
            dates.push(moment(nextDate))
            nextDate = moment(nextDate.add(1, timeBase))
        }

        return dates
    }

    /** Gera & retorna vetor de dados para 'eixo y' (serie) do grafico. */
    static getYAxisCounts(dates: Moment[], byPeriodList: IBalancePerPeriodListItemResponseDTO[]): number[] {

        return dates.map(date => {
            const totalByPeriod = byPeriodList
                .find((period: IBalancePerPeriodListItemResponseDTO) => {
                    const labelDateTime = date.startOf(TimeBaseEnum.DAY).toDate().getTime()
                    const periodDateTime = moment(period.date).startOf(TimeBaseEnum.DAY).toDate()
                        .getTime()
                    return (labelDateTime === periodDateTime)
                })
            return !!totalByPeriod ? totalByPeriod.balance : 0
        })
    }
}
