import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'

/**
 * Divisoria usado no menu
 */
export function MenuDividerICP (): JSX.Element {

    return (
        <MenuDividerSCP/>
    )
}

const MenuDividerSCP = styled.div`
  width: 67px;
  height: 2px;
  margin-bottom: 15px;
`
