import { Icon } from 'antd'
import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'

/**
 * ICONE: e-mail
 *
 * @author hjcostabr
 * @author Stella
 */
export const MailIconCP = (props: IIconProps): JSX.Element => {

    return (
        <Icon
            type={'mail'}
            style={{
                fill: props.color ?? 'inherit',
                color: props.color ?? 'inherit',
                fontSize: props.size ?? '20px',
                ...props.style
            }}
        />
    )
}
