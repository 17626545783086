import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ListCP } from 'main/common/components/list/ListCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerOpportunityDetailsCP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/DrawerOpportunityDetailsCP'
import { PersonOpportunityItemICP } from 'main/modules/people/components/list-person-opportunities/inner/PersonOpportunityItemICP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IPersonOpportunitiesListItemResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonOpportunitiesListItemResponseDTO'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'

interface IListPersonOpportunitiesCPProps {
    person: IPersonResponseDTO
}

/**
 * Componente Lista de oportundiades de uma pessoa.
 */
export function ListPersonOpportunitiesCP(props: IListPersonOpportunitiesCPProps): JSX.Element {

    const [newOpportunityType, setNewOpportunityType] = useState<OpportunityDrawerActionTP>()
    const [isOpportunityDrawerVisible, setIsOpportunityDrawerVisible] = useState<boolean>(false)

    useEffect(init, [props.person])
    const [opportunities, setOpportunities] = useState<IPersonOpportunitiesListItemResponseDTO[]>()
    const [selectedOpportunityCode, setSelectedOpportunityCode] = useState<number>()

    const getOpportunitiesRequest = useRequest<IGenericListResponseDTO<IPersonOpportunitiesListItemResponseDTO>>()
    useEffect(onGetOpportunitiesRequestChange, [getOpportunitiesRequest.isAwaiting])

    /**
     * Inicializa a tela
     */
    function init(): void {
        getOpportunitiesRequest.runRequest(PersonRequests.getOpportunities(props.person.code))
    }

    /**
     * Retorno ao obter lista de atividades
     */
    function onGetOpportunitiesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getOpportunitiesRequest, 'Erro ao buscar oportunidades do cliente'))
            return

        setOpportunities(getOpportunitiesRequest.responseData!.list)
    }

    return (
        <>
            <FlexCP justifyContent={'space-between'}>
                <ButtonCP
                    icon={'plus'}
                    size={'small'}
                    type={'ghost'}
                    onClick={() => {
                        setNewOpportunityType(undefined)
                        setSelectedOpportunityCode(undefined)
                        setIsOpportunityDrawerVisible(true)
                    }}
                >
                    Oportunidade
                </ButtonCP>
                <ButtonCP
                    icon={'plus'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => {
                        setNewOpportunityType('evaluation')
                        setIsOpportunityDrawerVisible(true)
                        setSelectedOpportunityCode(undefined)
                    }}
                >
                    Avaliação
                </ButtonCP>
            </FlexCP>

            <ListContainerSCP>
                <ListCP<IPersonOpportunitiesListItemResponseDTO>
                    itemLayout={'vertical'}
                    bordered={false}
                    dataSource={opportunities}
                    pagination={false}
                    loading={getOpportunitiesRequest.isAwaiting}
                    locale={{ emptyText: 'Nenhuma oportunidade encontrada' }}
                    renderItem={(item) => (
                        <PersonOpportunityItemICP
                            opportunity={item}
                            onSelectOpportunity={() => {
                                setIsOpportunityDrawerVisible(true)
                                setSelectedOpportunityCode(item.code)
                            }}
                        />
                    )}
                />
            </ListContainerSCP>

            <DrawerOpportunityDetailsCP
                visible={isOpportunityDrawerVisible}
                opportunityCode={selectedOpportunityCode}
                conversionType={newOpportunityType}
                initialData={{
                    name: props.person.name,
                    email: props.person.email,
                    phone: props.person.phone,
                    personResponsibleCode: AppStateUtils.getLoggedUserData()!.user.code,
                    personCustomerCode: props.person.code
                }}
                onClose={() => {
                    setIsOpportunityDrawerVisible(false)
                    init()
                }}
            />
        </>
    )
}

const ListContainerSCP = styled.div`
    width: 100%;
    margin-top: 10px
`