import React from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import { WorkScheduleTableUtils } from 'main/modules/scheduler/components/table-work-schedule/inner/WorkScheduleTableUtils'
import styled from 'main/config/theme/styledWithTheme'

interface ITableWeekWorkScheduleCPProps {
    workSchedule: IWorkSchedule[]
    hasIntervalColumn: boolean
    onChangeWorkSchedule?: (workingTimeItem: IWorkSchedule) => void
    readonly?: boolean
    loading?: boolean
}

/**
 * Componente tabela para os seletores de horário de trabalho / de funcionamento.
 */
export function TableWorkScheduleCP(props: ITableWeekWorkScheduleCPProps): JSX.Element {

    return (
        <WrapperSCP hasBorder={!!props.readonly}>
            <TableCPOLD2
                data={props.workSchedule}
                loading={props.loading}
                columns={
                    WorkScheduleTableUtils.getTableColumns(
                        props.hasIntervalColumn,
                        props.onChangeWorkSchedule,
                        props.readonly
                    )
                }
                rowKey={() => 'dayOfWeek'}
            />
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<{ hasBorder: boolean }>`

    .ant-table-tbody > tr > td {
      border: ${props => props.hasBorder ? undefined : 'none !important'};
    }
`
