import * as moment from 'moment'
import * as moment_timezone from 'moment-timezone'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { DateRangeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/DateRangeResponseDTO'
import { DateRangeStringResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/DateRangeStringResponseDTO'
import { DayPeriodEnum } from 'submodules/nerit-framework-utils/utils/date/DayPeriodEnum'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { MonthEnum } from 'submodules/nerit-framework-utils/utils/enums/MonthEnum'
import { QuickDateEnum } from 'submodules/nerit-framework-utils/utils/enums/QuickDateEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { DatePropsTP } from 'submodules/nerit-framework-utils/utils/types/DatePropsTP'
import { DayOfMonthTP } from 'submodules/nerit-framework-utils/utils/types/DayOfMonthTP'
import { OrNullTP } from 'submodules/nerit-framework-utils/utils/types/OrNullTP'

/**
 * Encapsula metodos uteis para manipulacao de datas no sistema
 */
export const DateUtils = {

	/** Transforma uma data, string, de um formato para outro. Ex.: 22/04/1987 para 1987-04-22. */
	transformDateStrFormat (originalFormat: DateFormatEnum, finalFormat: DateFormatEnum, dateStr?: string): string {

		if (!dateStr)
			return ''

		return DateUtils.getFormatted(DateUtils.toDate(dateStr, originalFormat), finalFormat)
	},

	/** Troca a hora da data 1 para a data 2. */
	mergeHour (date1: Date, date2: Date): Date {
		const newDate = new Date()
		newDate.setTime(date1.getTime())
		newDate.setHours(date2.getHours(), date2.getMinutes(), date2.getSeconds())
		return newDate
	},

	/**
	 * Valida 01 string quanto a representar 01 data valida (formato americano).
	 */

	getFormatted (date: string | Date, format: DateFormatEnum): string {
		moment.locale('pt_BR', momentPtBrLocale)
		return moment(date).format(format)
	},

	formatDate (date: any, format: DateFormatEnum): string {

		if (!date)
			return '-'

		return moment(date).format(format)
	},

	/**
	 * Valida 01 string quanto a representar 01 data valida (formato americano).
	 */
	toDate (dateStr: string, dateStrFormat: DateFormatEnum): Date {
		return moment(dateStr, dateStrFormat).toDate()
	},

	/**
	 * Valida 01 string quanto a representar 01 data valida (formato americano).
	 * @deprecated
	 */
	isValidUSADateString (dateString: string): boolean {
		try {
			if (!dateString)
				return true

			if (!(/^\d{4}-\d{2}-\d{2}$/.exec(dateString)))
				return false
			return moment(dateString, DateFormatEnum.US_WITHOUT_TIME).isValid()

		} catch (error) {
			return false
		}
	},

	/** Valida 01 string quanto a representar 01 data valida (formato brasileiro ex.: 22/04/1987). */
	isValidBRDateString (dateString: string): boolean {
		try {
			if (!dateString)
				return true

			if (!dateString.match(/\d{2}\/\d{2}\/\d{4}/))
				return false

			moment_timezone.locale('pt_BR', momentPtBrLocale)
			return moment_timezone(dateString, DateFormatEnum.BR_WITHOUT_TIME, 'pt', true).isValid()

		} catch (error) {
			return false
		}
	},

	/**
	 * Define HORAS & MINUTOS de 01 data.
	 * @deprecated
	 */
	setTime (date: Date, hours: number, minutes: number): Date {
		return moment(date)
			.set(TimeBaseEnum.HOUR, hours)
			.set(TimeBaseEnum.MINUTE, minutes)
			.toDate()
	},

	/**
	 * SOMA valor de tempo a 01 data.
	 */
	add (date: Date, value: number, timeBase: TimeBaseEnum): Date {
		return moment(date).add(value, timeBase).toDate()
	},

	/**
	 * SUBTRAI valor de tempo a 01 data.
	 * @deprecated
	 */
	subtract (date: Date, value: number, timeBase: TimeBaseEnum): Date {
		return moment(date).subtract(value, timeBase).toDate()
	},

	/**
	 * Retorna diferenca entre data informa e data atual, numa determinada unidade de tempo.
	 * @deprecated
	 */
	getTimeFromNow (date: Date, timeBase: TimeBaseEnum): OrNullTP<number> {
		return +moment().diff(moment(date), timeBase)
	},

	/** Retorna diferenca entre data informada e data atual, numa determinada unidade de tempo. */
	getDiff (timeBase: TimeBaseEnum, date: Date, secondDate: Date = new Date()): number {
		return +moment(secondDate).diff(moment(date), timeBase)
	},

	isAfter (date: Date, secondDate: Date = new Date()): boolean {
		date.setHours(0, 0, 0)
		secondDate.setHours(0, 0, 0)
		return DateUtils.getDiff(TimeBaseEnum.DAY, date, secondDate) <= 0
	},

	isAfterTimeBase (date: Date, secondDate: Date, timeBase: TimeBaseEnum): boolean {
		return DateUtils.getDiff(timeBase, date, secondDate) <= 0
	},

	isBefore (date: Date, secondDate: Date = new Date()): boolean {
		date.setHours(0, 0, 0)
		secondDate.setHours(0, 0, 0)
		return DateUtils.getDiff(TimeBaseEnum.DAY, date, secondDate) >= 0
	},

	getDate (params?: DatePropsTP): Date {

		const now = new Date()

		return new Date(
			params?.year ?? now.getFullYear(),
			params?.month ?? now.getMonth(),
			params?.day ?? now.getDate(),
			0,
			0,
			0,
			0,
		)
	},

	/**
	 * @deprecated
	 */
	getDay (date?: Date): DayOfMonthTP {
		return moment(date ?? new Date()).date() as DayOfMonthTP
	},

	/**
	 * @deprecated
	 */
	getMonth (date?: Date): MonthEnum {
		return moment(date ?? new Date()).month()
	},

	getMonthName (month: MonthEnum, format: DateFormatEnum.MONTH | DateFormatEnum.MONTH_3 = DateFormatEnum.MONTH): string {
		return DateUtils.getFormatted(DateUtils.getDate({ month }), format)
	},

	getMonthNumbers (): number[] {
		return Object.values(MonthEnum).map(Number).filter(month => !Number.isNaN(month))
	},

	/**
	 * @deprecated
	 */
	getYear (date?: Date): number {
		return moment(date ?? new Date()).year()
	},

	/** Retorna base de tempo relacionada a 01 id 'rapido' de data. */
	getTimeBaseFromQuickDate (unitDateValue: QuickDateEnum): TimeBaseEnum {
		switch (unitDateValue) {
		case QuickDateEnum.CURRENT_WEEK:
			return TimeBaseEnum.WEEK
		case QuickDateEnum.CURRENT_MONTH:
			return TimeBaseEnum.MONTH
		case QuickDateEnum.TODAY:
		case QuickDateEnum.TOMORROW:
		default:
			return TimeBaseEnum.DAY
		}
	},

	/**
	 * @deprecated
	 */
	getAllDatesInInterval (beginDate: Date, endDate?: Date): Date[] {

		if (!endDate)
			return [beginDate]

		const allDates: Date[] = []
		let currentDate = beginDate
		while (DateUtils.getDiff(TimeBaseEnum.DAY, currentDate, endDate) >= 0) {
			allDates.push(currentDate)
			currentDate = DateUtils.add(currentDate, 1, TimeBaseEnum.DAY)
		}
		return allDates
	},

	getAllDaysInInterval (beginDate: Date, endDate?: Date): Date[] {

		const beginDay = DateUtils.formatDate(beginDate, DateFormatEnum.US_WITHOUT_TIME) as unknown as Date

		if (!endDate)
			return [beginDay]

		const endDay = DateUtils.formatDate(endDate, DateFormatEnum.US_WITHOUT_TIME) as unknown as Date

		const allDates: Date[] = []
		let currentDate = moment(`${beginDay}`).toDate()
		while (DateUtils.getDiff(TimeBaseEnum.DAY, currentDate, endDay) >= 0) {
			allDates.push(currentDate)
			currentDate = DateUtils.add(currentDate, 1, TimeBaseEnum.DAY)
		}
		return allDates.map(date => DateUtils.formatDate(date, DateFormatEnum.US_WITHOUT_TIME) as unknown as Date)
	},

	/** Determinar se valor corresponde a alguma das constantes de 'valor rapido' de data. */
	isQuickDateValue (valueToTest: unknown): boolean {
		return Object.values(QuickDateEnum).includes(valueToTest as QuickDateEnum)
	},

	/** Retorna um número absoluto da diferença entre datas. */
	getDiffNumberBetweenDates (date1: Date, date2: Date, baseTime: TimeBaseEnum, format: DateFormatEnum = DateFormatEnum.US_WITHOUT_TIME): number {
		const d1 = moment(date1, format)
		const d2 = moment(date2, format)
		return Math.abs(+(d1.diff(d2, baseTime).toFixed(1)))
	},

	/** Retorna um número absoluto da diferença entre datas. Ao contrario do metodo getDiffNumberBetweenDates esse aqui nao retorna o valor absoluto. */
	getRawDiffNumberBetweenDates (date1: Date, date2: Date, baseTime: TimeBaseEnum, format: DateFormatEnum = DateFormatEnum.US_WITHOUT_TIME): number {
		const d1 = moment(date1, format)
		const d2 = moment(date2, format)
		return (d1.diff(d2, baseTime, true))
	},

	isValidDate (params: Required<DatePropsTP>): boolean {
		const date = new Date(params.year, params.month, params.day)
		return (!Number.isNaN(+date) && date.getDate() === +params.day)
	},

	getLastDayOfMonth (month: MonthEnum, year?: number): DayOfMonthTP {

		if (month === MonthEnum.DEC)
			return 31

		const nextMonthFirstDay = DateUtils.getDate({
			day: 1,
			month: (month + 1),
			year,
		})

		return DateUtils.getDay(DateUtils.subtract(nextMonthFirstDay, 1, TimeBaseEnum.DAY))
	},

	/**
	 * Converte tempo em minutos para total de dias | horas e minutoes
	 * ATENCAO .... o retorno em dias e horas:minutos sao iguais.
	 */
	convertMinutes (totalMinutes: number): { day: number, hour: number, minute: number } {

		const hour = Math.floor(Math.abs(totalMinutes) / 60)
		const minute = Math.floor(Math.abs(totalMinutes) % 60)

		const day = Math.floor(hour / 24)

		return {
			day: day * (totalMinutes < 0 ? -1 : 1),
			hour: hour * (totalMinutes < 0 ? -1 : 1),
			minute
		}
	},

	ageCalculate (date: Date): string {

		const today = moment()
		const years = today.diff(date, 'year')
		if (years < 15) {
			const months = today.diff(date, 'month') % 12
			if (years < 1)
				return `${months} ${months !== 1 ? 'meses' : 'mês'}`
			return `${years} ${years !== 1 ? 'anos' : 'ano'} e ${months} ${months !== 1 ? 'meses' : 'mês'}`
		}

		return `${years} anos`
	},

	getHourAndMinutesAsString (date?: Date): string | undefined {

		if (!date)
			return undefined

		const minute = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
		const hour = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}`

		return `${hour}:${minute}`
	},

	getYearsFromNow (date: Date): number {
		return moment().diff(moment(date), 'years')
	},

	getMonthsFromNow (date: Date): number {
		return moment().diff(moment(date), 'month')
	},

	getAgeInFullFormat (date: Date): string | undefined {

		if (!date)
			return undefined

		const years = DateUtils.getYearsFromNow(date)
		const months = (DateUtils.getMonthsFromNow(date) % 12)

		const varValue = (years < 15)
			? ` ${years === 1 ? `${years} ano` : `${years} anos`} e ${months === 1 ? `${months} mês` : `${months} meses`}`
			: `${years} anos`

		return varValue

	},

	formatByPeriod (dateRange: DateRangeSearchRequestDTO, period: DayPeriodEnum): DateRangeSearchRequestDTO {

		let beginDate = moment_timezone(dateRange.beginDate)
		let endDate = moment_timezone(dateRange.endDate)

		switch (period) {

		case DayPeriodEnum.MORNING:
			beginDate = beginDate.set('h', 6).set('m', 0)
			endDate = endDate.set('h', 12).set('m', 0)
			break

		case DayPeriodEnum.AFTERNOON:
			beginDate = beginDate.set('h', 12).set('m', 0)
			endDate = endDate.set('h', 18).set('m', 0)
			break

		case DayPeriodEnum.NIGHT:
			beginDate = beginDate.set('h', 18).set('m', 0)
			endDate = endDate.set('h', 23).set('m', 59)
			break

		case DayPeriodEnum.ALL:
		default:
			beginDate = beginDate.set('h', 6).set('m', 0)
			endDate = endDate.set('h', 23).set('m', 59)
			break
		}

		return { beginDate: beginDate.toDate(), endDate: endDate.toDate() }
	},

	/**
	 * Obtem data inicio e data fim do mes do dia passado como parametro, caso nao tenha passado pega TODAY.
	 */
	getFirstAndLastDayOfMonth (date?: Date): DateRangeResponseDTO {

		return {
			beginDate: moment(date).utc().startOf('month').toDate(),
			endDate: moment(date).utc().endOf('month').toDate()
		}
	},
	getMonthlyDateRanges (dateRange: DateRangeResponseDTO): DateRangeStringResponseDTO[] {

		const result: DateRangeStringResponseDTO[] = []
		let currentDate = moment(dateRange.beginDate)

		while (currentDate.isBefore(dateRange.endDate)) {
			const lastDayOfMonth = moment(currentDate).utc().endOf('month')
			const endDate = lastDayOfMonth.isBefore(dateRange.endDate) ? lastDayOfMonth : dateRange.endDate

			result.push({ beginDate: currentDate.toDate().toDateString(), endDate: moment(endDate).utc().endOf('month').toDate().toDateString() })

			currentDate = moment(currentDate).add(1, 'month').startOf('month')
		}

		return result
	}



}
