import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ISalesRankingIndicatorsListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ISalesRankingIndicatorsListItemResponseDTO'
import { SalesRankingIndicatorsOrderingEnum } from 'main/modules/dashboard/services/enums/SalesRankingIndicatorsOrderingEnum'

/**
 * UTILITARIOS do componente de listagem de total de leads por colaborador.
 */
export const RankingConsultantWidgetUtils = {

    getTableColumns(): Array<ColumnProps<ISalesRankingIndicatorsListItemResponseDTO>> {
        return [
            {
                title: 'Colaborador',
                key: SalesRankingIndicatorsOrderingEnum.USER,
                sorter: true,
                render: (text, dto) => dto.reponsiblePersonName
            },
            {
                title: 'Vendas',
                key: SalesRankingIndicatorsOrderingEnum.TOTAL_SALES,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalSales, 2)
            },
            {
                title: 'Valor Vendido',
                key: SalesRankingIndicatorsOrderingEnum.SALE_VALUE,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyMoneyMask(dto.saleValue)
            },
            {
                title: 'Ticket Médio',
                key: SalesRankingIndicatorsOrderingEnum.SALE_VALUE_AVERAGE,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyMoneyMask(dto.saleValueAverage)
            },
            {
                title: 'Pcts p/ Venda',
                key: SalesRankingIndicatorsOrderingEnum.PACKAGES_PER_SALE,
                sorter: true,
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.packagesPerSale, 2)
            },
            {
                title: 'Média Desconto',
                key: SalesRankingIndicatorsOrderingEnum.AVERAGE_DISCOUNT,
                sorter: true,
                align: 'right',
                render: (text, dto) => `${MaskUtils.applyNumberMask(dto.averageDiscount, 2)}%`
            },
        ]
    },

}
