import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StatisticCardCP } from 'main/common/components/statistic-card/StatisticCardCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faChartBar, faMoneyCheckAlt, faShoppingCart, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'
import moment from 'moment'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { ISalesPerformanceResponseDTO } from 'main/modules/dashboard/services/dtos/response/ISalesPerformanceResponseDTO'

interface IFranchisorTopSaleStatisticsCPProps {
    date: Date
    dataFiltrada?:any
    setGrupoA?:any
    setGrupoB?:any
}

/**
 * Componentes estatisticas gerais de numeros de melhores franquias.
 */
export function FranchisorTopSaleStatisticsCP(props: IFranchisorTopSaleStatisticsCPProps): JSX.Element {

    useEffect(init, [props.dataFiltrada])

    const [salesPerformanceData, setSalesPerformanceData] = useState<ISalesPerformanceResponseDTO>()
    const salesPerformanceRequest = useRequest<ISalesPerformanceResponseDTO>()
    useEffect(onSalesPerformanceRequestChange, [salesPerformanceRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        const requestDto: IDateRangeSearchRequestDTO = {
            beginDate: moment(props.dataFiltrada.beginDate).startOf('day').format('YYYY-MM-DD'),
            endDate: moment(props.dataFiltrada.endDate).endOf('day').format('YYYY-MM-DD')
        }

        salesPerformanceRequest.runRequest(DashboardRequests.salesPerformanceConfig(requestDto))
    }

    /**
     * Retorno dos dados da api.
     */
    function onSalesPerformanceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(salesPerformanceRequest, 'Erro obter estatísticas'))
            return
        // console.log("SALE ", salesPerformanceRequest.responseData)
         //@ts-ignore
        if(props['setGrupoA'])props.setGrupoA(salesPerformanceRequest.responseData.grupoA)
        //@ts-ignore
        if(props['setGrupoB'])props.setGrupoB(salesPerformanceRequest.responseData.grupoB)
        setSalesPerformanceData(salesPerformanceRequest.responseData)
    }

    return (
        <WrapperSCP>
            <StatisticCardCP
                title={'Total em vendas de todas franquias'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faShoppingCart} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(salesPerformanceData?.totalSales ?? 0)}
                loading={salesPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={'Consolidado de vendas de todas franquias'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faShoppingCart} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(salesPerformanceData?.totalConsolidado ?? 0)}
                loading={salesPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={'Venda média por franquia'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faChartBar} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(salesPerformanceData?.averageSalesByFranchise ?? 0)}
                loading={salesPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={'Franquia que mais vendeu'}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faTrophy} size={'2x'}/>}
                value={salesPerformanceData?.bestSalesPerformanceFranchise.name}
                loading={salesPerformanceRequest.isAwaiting}
            />
            <StatisticCardCP
                title={`${salesPerformanceData?.bestSalesPerformanceFranchise?.name ?? ''} vendeu:`}
                color={ThemeAnt.primaryColor}
                prefix={<FontAwsomeIconCP type={faMoneyCheckAlt} size={'2x'}/>}
                value={MaskUtils.applyMoneyMask(salesPerformanceData?.bestSalesPerformanceFranchise.totalSales ?? 0)}
                loading={salesPerformanceRequest.isAwaiting}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
   display: flex;
   flex-direction: column;
`
