import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { AutocompleteFormCP } from 'main/modules/admin/components/autocomplete-form/AutocompleteFormCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { TreatmentFormModel } from 'main/modules/products/components/drawer-treatment/inner/TreatmentFormModel'
import { AutocompleteTreatmentGroupCP } from 'main/modules/admin/components/autocomplete-treatment-group/AutocompleteTreatmentGroupCP'
import { ITreatmentResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentResponseDTO'

interface ITreatmentFormICPProps {
    formStateManager: IFormStateManager<TreatmentFormModel>
    loadedTreatment?: ITreatmentResponseDTO
}

/**
 * Tela de listagem de tratamentos.
 */
export function TreatmentFormICP(props: ITreatmentFormICPProps): JSX.Element {

    return (
        <>
            <InputCP
                label={'Atendimento'}
                fontSize={'extraLarge'}
                fieldName={'name'}
                formStateManager={props.formStateManager}
                required={true}
            />

            <InputCP
                label={'Intervalo mínimo entre sessões (em dias)'}
                fieldName={'minDaysBetweenSessions'}
                formStateManager={props.formStateManager}
                hint={{
                    type: 'tooltip',
                    text: 'Caso preenchido, no momento do agendamento será validado se a última sessão foi à X dias da nova data de agendamento.'
                }}
            />

            <AutocompleteTreatmentGroupCP
                formStateManager={props.formStateManager}
                fieldName={'groupCodes'}
                label={'Grupos Vinculados'}
                initialOptions={props.loadedTreatment?.groups}
                isMultiple={true}
            />

            <AutocompleteFormCP
                formStateManager={props.formStateManager}
                fieldName={'formCode'}
                label={'Formulário de Evolução'}
                initialOptions={props.loadedTreatment?.form?.code ? [props.loadedTreatment.form] : undefined}
            />
        </>
    )
}
