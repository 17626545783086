import { FileUploadCP } from 'main/common/components/file-upload/FileUploadCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { HttpStatusEnum } from 'main/common/enums/HttpStatusEnum'
import { MimeTypeEnum } from 'main/common/enums/MimeTypeEnum'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import styled from 'main/config/theme/styledWithTheme'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import { ILeadImportRequestDTO } from 'main/modules/sales-funnel/services/old/ILeadImportRequestDTO'
import { LeadRequests } from 'main/modules/sales-funnel/services/old/LeadRequests'
import React, { useEffect } from 'react'

const CLASS_DISABLED = 'upload_step_disabled'

interface IStepUploadICPProps {

    onUploadStart: () => void
    onValidationSuccess: () => void
    onValidationFailure: (errors: ImportValidationErrorsLineTP[]) => void
    onError: () => void

    requestDto?: ILeadImportRequestDTO
    disable?: boolean
    tooltip?: string
}

/**
 * COMPONENTE
 * Area de upload para card de importacoes.
 */
export function StepUploadICP(props: IStepUploadICPProps): JSX.Element {

    const importRequest = useRequest<void>('none')

    useEffect(onImportRequestChange, [importRequest.isAwaiting])

    const disable = props.disable ?? !props.requestDto

    function onImportRequestChange(): void {

        if (RequestUtils.isRequestSuccess(importRequest, true))
            return props.onValidationSuccess()

        if (!RequestUtils.isRequestConcluded(importRequest))
            return

        const errorDescription = importRequest.error?.data?.errors
        if (importRequest.responseStatus === HttpStatusEnum.BAD_REQUEST && Array.isArray(errorDescription))
            return props.onValidationFailure(errorDescription)

        console.error('FALHA - StepUploadICP.onImportRequestChange: ', importRequest.responseData, importRequest.error)
        props.onError()
    }

    function uploadFile(file: File): void {
        if (!!props.requestDto) {
            importRequest.runRequest(LeadRequests.import(file, props.requestDto))
            props.onUploadStart()
        }
    }

    return (
        <WrapperSCP className={disable ? CLASS_DISABLED : ''}>
            <TooltipCP text={props.tooltip}>
                <UploadTitleSCP>
                    <TextCP
                        wrap={true}
                        strong={true}
                        text={'2. Faça upload do seu arquivo no formato CSV'}
                    />
                </UploadTitleSCP>

                <FileUploadCP
                    multiple={false}
                    disabled={disable}
                    fileTypes={[MimeTypeEnum.CSV_STANDARD]}
                    loading={importRequest.isAwaiting}
                    loadingMsg={'Realizando upload e verificando dados da planilha'}
                    uploadFiles={uploadFile}
                />
            </TooltipCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    &.${CLASS_DISABLED} {

        opacity: .5;
        cursor: not-allowed;
        transition: opacity .3s;

        &:hover {
            opacity: .3;
        }
    }
`

const UploadTitleSCP = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
`
