import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import styled from 'main/config/theme/styledWithTheme'
import { PersonContactRequests } from 'main/modules/people/services/person-contact/PersonContactRequests'
import { PersonContactFormModel } from 'main/modules/people/components/form-person-contact/inner/PersonContactFormModel'
import { SelectPersonContactTypeCP } from 'main/modules/people/components/select-person-contact-type/SelectPersonContactTypeCP'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { TimePickerCP } from 'main/common/components/timepicker/TimePickerCP'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FormPersonContactButtonsICP } from 'main/modules/people/components/form-person-contact/inner/FormPersonContactButtonsICP'
import { PersonContactFormUtils } from 'main/modules/people/components/form-person-contact/inner/PersonContactFormUtils'
import { PersonContactStatusEnum } from 'main/modules/people/services/person-contact/enums/PersonContactStatusEnum'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { OrUndefinedTP } from 'main/common/types/OrUndefinedTP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'

const DEFAULT_END_TIME_MINUTES = 5

interface IFormPersonContactCPProps {
    entityCode: number
    entity: PersonContactEntityEnum
    personContact?: IPersonContactResponseDTO
    onSave: (shouldAddNew: boolean, conversionType?: OpportunityDrawerActionTP) => void
}

/**
 * Formulário de agendamento de um novo contato com pessoa
 */
export function FormPersonContactCP(props: IFormPersonContactCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<PersonContactFormModel>(new PersonContactFormModel())
    const formStateManager = useFormStateManager<PersonContactFormModel>(formValidator)

    const [shouldAddNew, setShouldAddNew] = useState<boolean>(false)
    const [conversionType, setConversionType] = useState<OrUndefinedTP<OpportunityDrawerActionTP>>()

    const saveActivityRequest = useRequest<IPersonContactResponseDTO>()
    useEffect(onSaveActivityRequestChange, [saveActivityRequest.isAwaiting])

    const deleteActivityRequest = useRequest('none')
    useEffect(onDeleteActivityRequestChange, [deleteActivityRequest.isAwaiting])

    useEffect(init, [props.personContact, props.entityCode])

    /**
     * Inicializa.
     */
    function init(): void {
        setShouldAddNew(false)
        setFormValidator(PersonContactFormUtils.initFormModel(props.personContact))
    }

    /**
     * Salva atividade
     */
    async function save(addNew: boolean, _conversionType?: OpportunityDrawerActionTP): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()
        if (!await formStateManager.validate() || !formValues)
            return

        setShouldAddNew(addNew)
        setConversionType(_conversionType)

        const dto = PersonContactFormUtils.mountSaveDto(formValues, props.entityCode, props.entity)

        // Se for para adicionar novo ou tiver um tipo de conversao setada, atividade eh salva como conluida
        if (addNew || !!_conversionType)
            dto.status = PersonContactStatusEnum.COMPLETED

        if (props.personContact)
            saveActivityRequest.runRequest(PersonContactRequests.update(props.personContact.code, dto))
        else
            saveActivityRequest.runRequest(PersonContactRequests.create(dto))
    }

    /**
     * Retorno ao salvar atividade
     */
    function onSaveActivityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveActivityRequest, 'Erro ao salvar atividade'))
            return

        NotificationHelper.success('Pronto!', 'Atividade salva com sucesso.')
        formStateManager.reset(undefined)

        props.onSave(shouldAddNew, conversionType)
    }

    /**
     * Deleta atividade
     */
    function deleteActivity(): void {

        if (!props.personContact)
            return

        deleteActivityRequest.runRequest(PersonContactRequests.delete(props.personContact.code))
    }

    /**
     * Retorno ao remover atividade.
     */
    function onDeleteActivityRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(deleteActivityRequest, 'Erro ao remover atividade.'))
            return

        NotificationHelper.success('Pronto!', 'Atividade removida com sucesso.')
        formStateManager.reset(undefined)
        props.onSave(false)
    }

    return (
        <FormWrapperSCP>
            <RowCP>
                <ColumnCP size={10}>
                    <SelectPersonContactTypeCP
                        fieldName={'type'}
                        formStateManager={formStateManager}
                    />
                </ColumnCP>
                <ColumnCP size={14}>
                    <AutocompleteUserCP
                        label={'Responsável'}
                        formStateManager={formStateManager}
                        fieldName={'responsibleCode'}
                        showLoggedUserAsInitialOption={true}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Dia'}
                        required={true}
                        type={'date'}
                        fieldName={'date'}
                        formStateManager={formStateManager}
                    />
                </ColumnCP>
                <ColumnCP size={6}>
                    <ColumnWrapperSCP>
                        <TimePickerCP
                            formStateManager={formStateManager}
                            fieldName={'beginTime'}
                            initAsNow={true}
                            onChange={(t) => {
                                const defaultEndTime = moment(t)
                                defaultEndTime.add(DEFAULT_END_TIME_MINUTES, 'minutes')
                                formStateManager.changeFieldValue('endTime', defaultEndTime)
                            }}
                        />
                    </ColumnWrapperSCP>
                </ColumnCP>
                <ColumnCP size={6}>
                    <ColumnWrapperSCP>
                        <TimePickerCP
                            formStateManager={formStateManager}
                            fieldName={'endTime'}
                        />
                    </ColumnWrapperSCP>
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={24}>
                    <TextAreaCP
                        formStateManager={formStateManager}
                        label={'Descrição'}
                        minRows={2}
                        fieldName={'description'}
                        required={true}
                        value={formStateManager.getFieldValue('description')}
                    />
                </ColumnCP>
            </RowCP>

            <SwitchSCP>
                <RowCP>
                    <ColumnCP size={24}>
                        <SwitchCP
                            isTextInside={false}
                            formStateManager={formStateManager}
                            fieldName={'isConcluded'}
                            textOutsidePosition={'left'}
                            textOutside={'Atividade foi realizada?'}
                            disabled={props.entity === PersonContactEntityEnum.OPPORTUNITY}
                        />
                    </ColumnCP>
                </RowCP>
            </SwitchSCP>

            <FormPersonContactButtonsICP
                showEvaluationAction={props.entity === PersonContactEntityEnum.OPPORTUNITY}
                onSave={save}
                isSaving={saveActivityRequest.isAwaiting}
                onDelete={deleteActivity}
                isDeleting={deleteActivityRequest.isAwaiting}
                personContact={props.personContact}
            />
        </FormWrapperSCP>
    )
}

const SwitchSCP = styled.div`
  margin-bottom: 20px;
`

const FormWrapperSCP = styled.div`
  min-width: 280px;
  max-width: 400px;
`

const ColumnWrapperSCP = styled.div`
  margin-top: 32px;
`
