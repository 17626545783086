import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { IOpportunityEvaluationScheduleSearchRequestDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/request/IOpportunityEvaluationScheduleSearchRequestDTO'
import { OpportunityEvaluationRequests } from 'main/modules/sales-funnel/services/evaluation/OpportunityEvaluationRequests'
import * as _ from 'lodash'
import { OpportunityEvaluationUtils } from 'main/modules/sales-funnel/utils/OpportunityEvaluationUtils'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { CalendarClinicCP } from 'main/modules/scheduler/components/calendar-clinic/CalendarClinicCP'
import { ScheduleEventUtils } from 'main/modules/scheduler/utils/ScheduleEventUtils'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'

interface ICalendarEvaluationSchedulesCPProps {
    view: SalesFunnelOpportunityViewEnum
}

/**
 * Content da Tela de AGENDAMENTOS do modulo FUNIL DE VENDAS na visão de CALENDÁRIO
 */
export function CalendarEvaluationSchedulesCP(props: ICalendarEvaluationSchedulesCPProps): JSX.Element {

    const [dateRange, setDateRange] = useState<IDateRangeFilter>()
    const [scheduleEvents, setScheduleEvents] = useState<IFullCalendarEvent[]>([])

    const getEvaluationSchedulesRequest = useRequest<IGenericListResponseDTO<IOpportunityEvaluationScheduleResponseDTO>>()
    useEffect(onGetEvaluationSchedulesRequestChange, [getEvaluationSchedulesRequest.isAwaiting])

    useEffect(init, [props.view])
    useEffect(getEvents, [dateRange])

    /**
     * Inicializa
     */
    function init(): void{

        let _beginDate = moment().startOf('day').toDate()
        let _endDate = moment().endOf('day').toDate()

        if (props.view !== SalesFunnelOpportunityViewEnum.EVALUATION_TODAY) {
            _beginDate = moment().startOf('week').toDate()
            _endDate = moment().endOf('week').toDate()
        }

        setDateRange({ beginDate: _beginDate, endDate: _endDate })
    }

    /**
     * Busca as avaliacoes na api.
     */
    function getEvents(): void {

        if (!dateRange?.beginDate || !dateRange?.endDate)
            return

        const searchDto: IOpportunityEvaluationScheduleSearchRequestDTO = {
            beginDate: DateUtils.getFormatted(dateRange?.beginDate, DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(dateRange?.endDate, DateFormatEnum.US_WITHOUT_TIME),
            itemsPerPage: 999,
            page: 1,
        }
        getEvaluationSchedulesRequest.runRequest(OpportunityEvaluationRequests.searchAllSchedulesConfig(searchDto))
    }

    /**
     * Retorno da API com os agendamentos
     */
    function onGetEvaluationSchedulesRequestChange(): void{

        if (!RequestUtils.isValidRequestReturn(getEvaluationSchedulesRequest, 'Não foi possível obter lista de agendamentos.'))
            return

        const evaluationSchedules = getEvaluationSchedulesRequest.responseData!.list
        if (_.isEmpty(evaluationSchedules))
            return setScheduleEvents([])

        const fullCalendarEvents: IFullCalendarEvent[] = evaluationSchedules.map(evaluationSchedule => ({
            id: `${evaluationSchedule.code}`,
            start: evaluationSchedule.beginDate,
            end: evaluationSchedule.endDate,
            title: ScheduleEventUtils.getEvaluationTitle(evaluationSchedule.personCustomer.name, evaluationSchedule.status, evaluationSchedule.presenceConfirmation),
            textColor: '#fff',
            backgroundColor: OpportunityEvaluationUtils.getScreeningStatusColor(evaluationSchedule),
            borderColor: 'transparent',
            extendedProps: {
                code: [evaluationSchedule.opportunityCode]
            }
        }))

        setScheduleEvents(fullCalendarEvents)
    }

    if (!dateRange)
        return <>Intervalo de Datas não definido</>

    return (
        <CalendarClinicCP
            selectedDate={new Date()}
            events={scheduleEvents}
            defaultView={'timeGridWeek'}
            loading={getEvaluationSchedulesRequest.isAwaiting}
            onDateRangeChange={setDateRange}
        />
    )
}
