import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { AddressUtils } from 'main/modules/people/utils/AddressUtils'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'

export const SalesUtils = {

    /** Retorna o significado de cada status que uma compra pode ter */
    getSaleStatusLabel(saleStatus: SaleStatusEnum): string {
        switch (saleStatus) {
            case SaleStatusEnum.OK:
                return 'Em dia'
            case SaleStatusEnum.PENDING:
                return 'Pendente'
            case SaleStatusEnum.DEFAULTING:
                return 'Atrasadas'
            case SaleStatusEnum.CONCLUDED:
                return 'Concluídas'
            case SaleStatusEnum.CANCELLED:
                return 'Canceladas'
            default:
                return ''
        }
    },

    /**
     * Verifica se tem todos os dados obrigatórios para realizar uma venda
     */
    checkIfAllDataIsFilled(person: IPersonResponseDTO): boolean {

        if (!AddressUtils.checkIfAddressIsComplete(person.address))
            return false

        if (!person.phone || !person.email || !person.name || !person.cpfCnpj)
            return false

        return true
    }

}
