import React from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { OpportunityEvaluationUtils } from 'main/modules/sales-funnel/utils/OpportunityEvaluationUtils'
import { StringUtils } from 'main/common/utils/StringUtils'
import { OpportunityScheduleStatusLabelEnum } from 'submodules/neritclin-sdk/services/opportunity-schedule/enums/OpportunityScheduleStatusEnum'

interface IOpportunityEvaluationSchedulesTimelineItemICPProps {
    evaluationSchedule: IOpportunityEvaluationScheduleResponseDTO
    onActionsDone: () => void
    onEditEvaluationSchedule: () => void
    onAddEvaluationSchedule: () => void
}

/**
 * Componente contendo linha do tempo de Atividades de uma OPORTUNIDADE
 */
export function OpportunityEvaluationSchedulesTimelineItemICP(props: IOpportunityEvaluationSchedulesTimelineItemICPProps): JSX.Element {

    return (
        <>
            <FlexCP justifyContent={'space-between'}>

                <TextCP text={`Avaliação em (${DateUtils.getPresentableDateHour(props.evaluationSchedule.beginDate)})`} strong={true}/>

                <FlexCP>
                    <ButtonCP
                        icon={'edit'}
                        type={'ghost'}
                        onClick={props.onEditEvaluationSchedule}
                        marginLeft={20}
                        size={'small'}
                        marginRight={5}
                    />
                </FlexCP>
            </FlexCP>

            <TextCP
                text={`Status: ${OpportunityScheduleStatusLabelEnum[props.evaluationSchedule.status]}`}
                color={OpportunityEvaluationUtils.getScreeningStatusColor(props.evaluationSchedule)}
            />
            <TextCP text={StringUtils.getFirstAndSecondName(props.evaluationSchedule.userResponsible.name)} icon={'user'}/>
            <TextCP text={`Agendado por ${StringUtils.getFirstAndSecondName(props.evaluationSchedule.userCreator.name)}`} color={'#cacaca'} size={'small'}/>
        </>
    )
}
