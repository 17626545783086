import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ProductReleaseFormModel } from 'main/modules/products/components/drawer-product-release/inner/ProductReleaseFormModel'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { TableProductReleaseTreatmentTableCP } from 'main/modules/products/components/table-product-release-treatment/TableProductReleaseTreatmentTableCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopoverProductReleaseTreatmentCP } from 'main/modules/products/components/popover-product-release-treatment/PopoverProductReleaseTreatmentCP'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import * as _ from 'lodash'
import { AutocompleteProductCP } from 'main/modules/products/components/autocomplete-product/AutocompleteProductCP'

interface IProductReleaseFormICPProps {
    formStateManager: IFormStateManager<ProductReleaseFormModel>
}

/**
 * Formulario de um pacote de atendimento.
 */
export function ProductReleaseFormICP(props: IProductReleaseFormICPProps): JSX.Element {

    const [isReleaseTreatmentPopoverVisible, setIsReleaseTreatmentPopoverVisible] = useState<boolean>(false)
    const [treatmentConfigs, setTreatmentConfigs] = useState<IProductReleaseTreatmentConfigResponseDTO[]>(props.formStateManager.getFieldValue('treatmentConfigs') ?? [])

    useEffect(() => setTreatmentConfigs(props.formStateManager.getFieldValue('treatmentConfigs') ?? []), [props.formStateManager.getFieldValue('treatmentConfigs')])

    /**
     * Manipula o array de configuracao de tratamentos caso algum item tenha sofrido edicao ou adicionado um novo.
     */
    function onSaveTreatmentConfigData(treatmentConfigToSave: IProductReleaseTreatmentConfigResponseDTO): void {

        const treatmentConfigsResult: IProductReleaseTreatmentConfigResponseDTO[] = _.clone(treatmentConfigs)

        // So pode ter uma linha por tratamento, entao remove do array caso tenha o tratamento informado
        _.remove(treatmentConfigsResult, (trtConfig) => {
            return trtConfig.treatment.code === treatmentConfigToSave.treatment.code
        })

        // Adiciona as configuracoes
        treatmentConfigsResult.push(treatmentConfigToSave)
        setTreatmentConfigs(treatmentConfigsResult)

        // Adiciona no form os valores
        props.formStateManager.changeFieldValue('treatmentConfigs', treatmentConfigsResult)

        // Fecha popover
        setIsReleaseTreatmentPopoverVisible(false)
    }

    /**
     * Remove uma configuracao de tratamento.
     */
    function removeTreatmentConfig(treatmentConfigToDelete: IProductReleaseTreatmentConfigResponseDTO): void {

        const updatedTreatmentConfigs = _.clone(treatmentConfigs)
        _.remove(updatedTreatmentConfigs, (trtConfig) => {
            return trtConfig.treatment.code === treatmentConfigToDelete.treatment.code
        })

        setTreatmentConfigs(updatedTreatmentConfigs)

        // Atualizar o formState
        props.formStateManager.changeFieldValue('treatmentConfigs', updatedTreatmentConfigs)
    }

    return (
        <>
            <RowCP>
                <ColumnCP size={8}>
                    <AutocompleteProductCP
                        formStateManager={props.formStateManager}
                        fieldName={'productCode'}
                        label={'Categoria'}
                    />
                </ColumnCP>
                <ColumnCP size={16}>
                    <InputCP
                        label={'Nome'}
                        fieldName={'name'}
                        formStateManager={props.formStateManager}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <ColumnCP size={8}>
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Ativo?'}
                        formStateManager={props.formStateManager}
                        fieldName={'active'}
                    />
                </ColumnCP>
                <ColumnCP size={8}>
                    <InputCP
                        label={'Valor mínimo'}
                        fieldName={'minPrice'}
                        formStateManager={props.formStateManager}
                        mask={InputMaskTypeEnum.MONEY}
                        required={true}
                    />
                </ColumnCP>
                <ColumnCP size={8}>
                    <InputCP
                        label={'Valor máximo'}
                        formStateManager={props.formStateManager}
                        fieldName={'maxPrice'}
                        mask={InputMaskTypeEnum.MONEY}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

            
            <TitleCP textSize={'large'} underLine={true} marginTop={30}>
                <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                    <>Atendimentos do pacote</>
                    <PopoverProductReleaseTreatmentCP
                        visible={isReleaseTreatmentPopoverVisible}
                        onClose={() => setIsReleaseTreatmentPopoverVisible(false)}
                        onSave={onSaveTreatmentConfigData}
                    >
                        <ButtonCP
                            marginLeft={10}
                            size={'small'}
                            icon={'plus'}
                            onClick={() => {
                                setIsReleaseTreatmentPopoverVisible(true)
                            }}
                        />
                    </PopoverProductReleaseTreatmentCP>
                </FlexCP>
            </TitleCP>
            
            <TableProductReleaseTreatmentTableCP
                treatments={treatmentConfigs}
                onUpdateTreatmentConfig={onSaveTreatmentConfigData}
                onDeleteTreatmentConfig={removeTreatmentConfig}
            />
        </>
    )
}
