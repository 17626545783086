import React, { useEffect } from 'react'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { PeopleRequests } from 'submodules/dokfy-sdk/services/people/PeopleRequests'
import { PeopleEmailUpdateRequestDTO } from 'submodules/dokfy-sdk/services/people/dto/request/PeopleEmailUpdateRequestDTO'

interface IContractAssigneesTableCPProps {
    originalAssigneeData?: IPersonResponseDTO
    assignee: {
        code: number,
        email: string
    }
    onSyncEnd: () => void
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function ButtonChangeDokfyUserEmailCP(props: IContractAssigneesTableCPProps): JSX.Element {

    const updateDataRequest = useRequest<void>('none')
    useEffect(onUpdateDataRequestChange, [updateDataRequest.isAwaiting])

    /**
     * Remover
     */
    function updateData(): void {

        if (!props.originalAssigneeData)
            return NotificationHelper.error('Ops', 'Signatário não encontrado')

        const dto: PeopleEmailUpdateRequestDTO = {
            newEmail: props.originalAssigneeData.email
        }
        updateDataRequest.runRequest(PeopleRequests.updateEmail(props.assignee.code, dto))
    }

    /**
     *  Retorno da remocao.
     */
    function onUpdateDataRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateDataRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSyncEnd()
    }

    if (!props.originalAssigneeData || props.originalAssigneeData.email === props.assignee.email)
        return <></>

    return (
        <ButtonCP
            marginLeft={10}
            size={'small'}
            icon={'warning'}
            type={'danger'}
            confirmMsg={`Você tem certeza que deseja sincronizar os dados? O novo e-mail será: ${props.originalAssigneeData.email}.`}
            loading={updateDataRequest.isAwaiting}
            onClick={updateData}
        >
            Sincronizar e-mail
        </ButtonCP>
    )
}
