import React from 'react'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faBan, faCheckCircle, faClock, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { SalesUtils } from 'main/modules/sale/utils/SalesUtils'

interface IIFinancialTransactionStatusIconCPProps {
    status: SaleStatusEnum
    showTooltip?: boolean
}

/**
 * Icone para status de uma venda.
 */
export function SaleStatusIconCP(props: IIFinancialTransactionStatusIconCPProps): JSX.Element {

    let icon: JSX.Element | undefined = undefined

    switch (props.status) {

        case SaleStatusEnum.CONCLUDED:
            icon = <FontAwsomeIconCP type={faCheckCircle} color={ThemeAnt.successColor}/>
            break

        case SaleStatusEnum.CANCELLED:
            icon = <FontAwsomeIconCP type={faBan} color={ThemeAnt.black}/>
            break

        case SaleStatusEnum.DEFAULTING:
            icon = <FontAwsomeIconCP type={faThumbsDown} color={ThemeAnt.errorColor}/>
            break

        case SaleStatusEnum.OK:
            icon = <FontAwsomeIconCP type={faThumbsUp} color={ThemeAnt.primaryColor}/>
            break

        case SaleStatusEnum.PENDING:
            icon = <FontAwsomeIconCP type={faClock} color={ThemeAnt.warningColor}/>
            break

        default:
            break
    }

    if (!icon)
        return <></>

    if (!props.showTooltip)
        return icon

    return (
        <TooltipCP
            text={SalesUtils.getSaleStatusLabel(props.status)}
            showSpan={true}
        >
            { icon }
        </TooltipCP>
    )

}

