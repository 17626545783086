import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { IPersonPurchaseListItemResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonPurchaseListItemResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { SaleStatusToNameEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'

interface IPurchaseListItemICPProps {
    puchase: IPersonPurchaseListItemResponseDTO
    onSelectSale: () => void
}

/**
 * Componente item da lista de compras de um cliente
 */
export function PurchaseListItemICP(props: IPurchaseListItemICPProps): JSX.Element {

    /**
     * Renderiza itens da venda.
     */
    function renderSaleItem(): JSX.Element[] {

        return props.puchase.saleItems.map(saleItem => (
            <SaleItemSCP key={saleItem.productRelease.code}>
                <TitleCP key={saleItem.productRelease.code} textSize={'normal'} underLine={true}>
                    {saleItem.productRelease.name}
                </TitleCP>
                {
                    !!saleItem.sessionsData
                        ?
                        <>
                            <TextCP text={'Consumo de Sessões'}/>
                            <ProgressCP percent={MathUtils.getPercentage(saleItem.sessionsData.totalSessionsConcluded, saleItem.sessionsData.totalSessions, true)} showInfo={true}/>
                            <TextCP text={`Realizou ${saleItem.sessionsData.totalSessionsConcluded} / ${saleItem.sessionsData.totalSessions} sessões`} size={'small'} color={'#adadad'}/>
                        </>
                        :
                        <TextCP text={'Nenhuma sessão'}/>
                }
            </SaleItemSCP>
        ))

    }

    return (
        <WrapperSCP>
            <CardCP
                margin={'none'}
                innerSpacing={'small'}
                title={
                    <FlexCP justifyContent={'space-between'}>
                        <FlexCP>
                            <TagCP content={SaleStatusToNameEnum[props.puchase.saleStatus]}/>
                            <TextCP text={`Compra: ${props.puchase.code}`} strong={true}/>
                        </FlexCP>
                        {
                            PermissionUtils.canViewSaleBetweenFranchises(props.puchase.franchise.code) &&
                            <ButtonCP
                                type={'link'}
                                onClick={props.onSelectSale}
                                icon={'eye'}
                            />
                        }
                    </FlexCP>
                }
            >
                <TextWrapperSCP>
                    <TextCP text={`Data: ${DateUtils.getFormatted(props.puchase.saleDate, DateFormatEnum.BR_WITH_TIME_H_M)}`}/>
                    <TextCP text={`Unidade: ${props.puchase.franchise.name}`}/>
                </TextWrapperSCP>

                { renderSaleItem() }
            </CardCP>

        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  margin-bottom: 20px;
`

const TextWrapperSCP = styled.div`
    display:        flex;
    flex-direction: column;
    font-weight:     normal;
    font-size:       13px;
`

const SaleItemSCP = styled.div`
    margin-top: 20px;
`
