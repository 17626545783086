import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { IBulkOperationSearchRequestDTO } from 'main/modules/admin/services/bulk-operation/requests/IBulkOperationSearchRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo de Operacoes em MASSA.
 */
export class BulkOperationRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/bulk-operations`

    static search = (dto?: IBulkOperationSearchRequestDTO): RequestConfigTP => ({
        url: BulkOperationRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
