import { LayoutCP } from 'main/common/components/screen-layout/layout/LayoutCP'
import React, { useState } from 'react'
import { RadioGroupSchedulerViewModeCP } from 'main/modules/scheduler/components/radio-group-scheduler-view-mode/RadioGroupSchedulerViewModeCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FormFilterSchedulerCP } from 'main/modules/scheduler/components/form-filter-scheduler/FormFilterSchedulerCP'
import { FormFilterSchedulerFormModel } from 'main/modules/scheduler/components/form-filter-scheduler/inner/FormFilterSchedulerFormModel'
import { SchedulerByAvailableSlotsCP } from 'main/modules/scheduler/components/scheduler-by-available-slots/SchedulerByAvailableSlotsCP'
import { SiderCP } from 'main/common/components/screen-layout/sider/SiderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'

/**
 * Responsável por renderizar a tela do módulo de agenda
 */
export function ScreenScheduler(): JSX.Element {

    const [formValidator] = useState<FormFilterSchedulerFormModel>((new FormFilterSchedulerFormModel({
        dateInterval: { beginDate: new Date(), endDate: new Date() }
    })))
    const formStateManager = useFormStateManager<FormFilterSchedulerFormModel>(formValidator)

    const [shouldLoadEvents, setShouldLoadEvents] = useState<number>(0)

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Marcação'}>
                    <RadioGroupSchedulerViewModeCP currentView={'scheduler'}/>
                </HeaderCP>
            }
            sider={
                <SiderCP width={275} bgColor={ThemeProject.secondaryMenuColor}>
                    <FormFilterSchedulerCP
                        formStateManager={formStateManager}
                        onSearch={() => setShouldLoadEvents(TableUtils.getReloadNumber())}
                    />
                </SiderCP>
            }
            content={
                <SchedulerByAvailableSlotsCP
                    formStateManager={formStateManager}
                    shouldLoadSlots={shouldLoadEvents}
                />
            }
        />
    )
}
