import { Input } from 'antd'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import React from 'react'
import styled from 'styled-components'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

const MIN_ROWS_DEFAULT = 2

interface ITextAreaCPProps<FModelTP extends FormModel = any> extends IFormItemInputCommonProps<FModelTP> {
    minRows?: number
    maxRows?: number
    placeholder?: string
    disabled?: boolean
}

/**
 * COMPONENTE
 * Input do tipo 'textarea'.
 */
export function TextAreaCP<FModelTP extends FormModel = any>(props: ITextAreaCPProps<FModelTP>): JSX.Element {

    return (
        <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
            label={props.label}
            onChange={props.onChange}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            errorMessage={props.errorMessage}
            noValidation={props.noValidation}
            disabled={props.disabled}
        >
            <TextAreaWrapperSCP>
                <Input.TextArea
                    autosize={{
                        minRows: props.minRows ?? MIN_ROWS_DEFAULT,
                        maxRows: props.maxRows
                    }}
                    placeholder={props.placeholder}
                    defaultValue={props.formStateManager?.getFieldValue(props.fieldName!)}
                    value={props.formStateManager?.getFieldValue(props.fieldName!) ?? props.value}
                    disabled={props.disabled}
                />
            </TextAreaWrapperSCP>
        </FormItemICP>
    )
}

const TextAreaWrapperSCP = styled.div`

    width: 100%;

    .ant-input {
        margin-top: 10px;
    }
`
