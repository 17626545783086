export enum DatePeriodGroupTypeEnum {
    DAILY = 'DAILY',
    TEN_DAYS = 'TEN_DAYS',
    BIWEEKLY = 'BIWEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum DatePeriodGroupTypeLabelEnum {
    DAILY = 'Diário',
    TEN_DAYS = 'Decêndio',
    BIWEEKLY = 'Quinzenal',
    MONTHLY = 'Mensal',
}