import React, { useEffect, useState } from 'react'
import { TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { IconNpsResponderCP } from 'submodules/space4leads-components-ui/survey/components/icon-nps-responder/IconNpsResponderCP'
import { NpsResponderTypeLabelEnum } from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'
import { SurveyAnswersRequests } from 'submodules/space4leads-sdk/services/surveys/survey-answers/SurveyAnswersRequests'
import { SurveyAnswerSearchRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerSearchRequestDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { SurveyAnswerResponseDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/response/SurveyAnswerResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { SurveyAnswerUtils } from 'submodules/space4leads-sdk/services/surveys/survey-answers/utils/SurveyAnswerUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TagsCP } from 'submodules/nerit-framework-ui/common/components/tag/TagsCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'

interface ICPProps {
    surveyCode: number
    reload: number
    tags?: string[]
    dateRange?: DateRangeSearchRequestDTO
}

/**
 * Timeline das respostas.
 */
export function TimelineSurveyAnswersCP(props: ICPProps): JSX.Element {

    const [answers, setAnswers] = useState<SurveyAnswerResponseDTO[]>()
    const getSuveyAnswersRequest = useRequest<ListResponseDTO<SurveyAnswerResponseDTO>>()
    useEffect(onGetSurveyAnswersRequestChange, [getSuveyAnswersRequest.isAwaiting])

    useEffect(init, [props.reload])

    /**
     * Inicializa dados da tela
     */
    function init(): void {

        const dto: SurveyAnswerSearchRequestDTO = {
            surveyCode: props.surveyCode,
            tags: props.tags,
            dateRange: props.dateRange,
        }
        getSuveyAnswersRequest.runRequest(SurveyAnswersRequests.search(dto))
    }

    /**
     * Retorno da requisicao
     */
    function onGetSurveyAnswersRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSuveyAnswersRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setAnswers(getSuveyAnswersRequest.responseData!.list)
    }

    return (
        <TimelineCP
            mode={'alternate'}
            items={
                answers?.map((answer, index) => ({
                    icon: <IconNpsResponderCP type={SurveyAnswerUtils.getNpsResponderType(answer.score)} size={30} considerColorOfType={true}/>,
                    title: `${DateUtils.formatDate(answer.date, DateFormatEnum.BR_WITH_TIME_H_M)} por ${answer.person ? answer.person.name : 'Anônimo'}`,
                    content: (
                        <>
                            {answer.comments ?? 'Sem comentários'}
                            <div>
                                {`Nota: ${answer.score} (${NpsResponderTypeLabelEnum[SurveyAnswerUtils.getNpsResponderType(answer.score)]})`}
                            </div>
                            <FlexCP justifyContent={index % 2 ? 'flex-end' : 'flex-start'}>
                                <TagsCP texts={answer.tags}/>
                            </FlexCP>
                        </>
                    )
                }))
            }
        />
    )

}
