import React, { useEffect, useState } from 'react'
import { AdminViewTP } from 'main/modules/admin/types/AdminViewTP'
import {
    ADMIN_SUB_MENU_ATTENDANCE_KEY, ADMIN_SUB_MENU_FINANCIAL_KEY,
    ADMIN_SUB_MENU_SALE_KEY,
    ADMIN_SUBMENU_SYSTEM_KEY,
    AdminScreenUtils
} from 'main/modules/admin/screens/screen-admin/utils/AdminScreenUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { FranchiseRequests } from 'main/modules/admin/services/franchise/FranchiseRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { LoadingScreen } from 'main/common/screens/LoadingScreen'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IFranchiseSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * TELA de administração do sistema.
 */
export function ScreenAdmin(): JSX.Element {

    const [franchise, setFranchise] = useState<IFranchiseResponseDTO>()

    const getFranchiseRequest = useRequest<IFranchiseResponseDTO>()
    useEffect(onGetFranchiseRequestChange, [getFranchiseRequest.isAwaiting])

    const updateFranchiseRequest = useRequest<IFranchiseResponseDTO>()
    useEffect(onUpdateFranchiseRequestChange, [updateFranchiseRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Busca dados da franquia em que estou logado.
     */
    function init(): void {
        getFranchiseRequest.runRequest(FranchiseRequests.getFranchise())
    }

    /**
     * Retorno ao buscar franquia
     */
    function onGetFranchiseRequestChange(): void {
        if (RequestUtils.isValidRequestReturn(getFranchiseRequest, 'Erro obter dados da franquia'))
            setFranchise(getFranchiseRequest.responseData)
    }

    /**
     * Atualiza dados da franquia.
     */
    async function updateFranchise(franchiseToSave: IFranchiseSaveRequestDTO): Promise<void> {
        updateFranchiseRequest.runRequest(FranchiseRequests.updateFranchise(franchiseToSave))
    }

    /**
     * Retorno ao atualizar os dados da franquia,
     */
    function onUpdateFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateFranchiseRequest, 'Erro ao salvar dados..'))
            return

        NotificationHelper.success('Yes!', 'Dados atualizados com sucesso')
        setFranchise(updateFranchiseRequest.responseData)
    }

    if (!franchise || getFranchiseRequest.isAwaiting)
        return <LoadingScreen/>

    return (
        <LayoutSubmenuCP<AdminViewTP>
            menuTitle={'Configurações'}
            defaultSubmenu={'systemAccountView'}
            loggedDomain={AppStateUtils.getDomainSlug()}
            defaultOpenKeys={[ADMIN_SUBMENU_SYSTEM_KEY, ADMIN_SUB_MENU_ATTENDANCE_KEY, ADMIN_SUB_MENU_SALE_KEY, ADMIN_SUB_MENU_FINANCIAL_KEY]}
            submenuConfigs={
                AdminScreenUtils.getSubMenuConfigs(
                    franchise,
                    updateFranchise,
                    updateFranchiseRequest.isAwaiting
                )
            }
        />
    )
}
