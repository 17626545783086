import React from 'react'
import styled from 'styled-components'
import { IconPresenceConfirmationCP } from 'main/modules/scheduler/components/icons/IconPresenceConfirmationCP'
import { IconAppointmentStatusCP } from 'main/modules/scheduler/components/icons/IconAppointmentStatusCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SessionUtils } from 'main/modules/scheduler/utils/SessionUtils'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faCommentDots, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { SaleStatusEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleStatusEnum'
import { SessionStatusEnum, SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'

export interface ICPProps {
    isPresenceConfirmed: boolean
    status: SessionStatusEnum
    saleStatus: SaleStatusEnum
    scheduledDate?: Date
    comments?: string
}

/**
 * Titulo de um evento do calendario
 */
export function SchedulerEventExtraTitleCP(props: ICPProps): JSX.Element {

    let statusTitle: string = SessionStatusLabelEnum[props.status]
    if (!SessionUtils.isScheduleInFuture(props.scheduledDate))
        statusTitle = 'Sessão ainda não foi evoluída ou cliente não compareceu'

    return (
        <FlexCP alignItems={'center'} justifyContent={'flex-end'}>

            {
                !!props.comments &&
                <WrapperSCP title={props.comments}>
                    <FontAwsomeIconCP type={faCommentDots}/>
                </WrapperSCP>
            }

            {
                props.saleStatus === SaleStatusEnum.DEFAULTING &&
                <WrapperSCP title={'Inadimplente'}>
                    <FontAwsomeIconCP type={faDollarSign} color={'red'}/>
                </WrapperSCP>
            }

            <WrapperSCP title={props.isPresenceConfirmed ? 'Presença confirmada' : 'Presença ainda não foi confirmada'}>
                <IconPresenceConfirmationCP isPresenceConfirmed={props.isPresenceConfirmed} hidePresenceNotConfirmed={true}/>
            </WrapperSCP>

            <WrapperSCP title={statusTitle}>
                <IconAppointmentStatusCP status={props.status} scheduledDate={props.scheduledDate}/>
            </WrapperSCP>
        </FlexCP>
    )
}

const WrapperSCP = styled.div`
    margin: 0 2px;
`
