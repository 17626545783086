import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import styled from 'styled-components'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { CardsProjectsNpsCP } from 'submodules/space4leads-components-ui/report/components/cards-projetcs-nps/CardsProjectsNpsCP'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 */
export function ScreenContentFranchisorNpsReportCP(): JSX.Element {

    const [reload, setReload] = useState<number>(1)

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    useEffect(() => setReload(TableUtils.getReloadNumber()), [dateFilters])

    const space4LeadsIntegrationKeys = AppStateUtils.getCurrentFranchise()!.integrationKeys.space4Leads
    if (!space4LeadsIntegrationKeys) {
        return (
            <WelcomeContentCP
                message={'Configurações de NPS incorretas'}
                icon={<IconICP iconName={'experiment'}/>}
            />
        )
    }

    return (
        <>
            <HeaderCP title={'Pesquisa de Satisfação (NPS) - Comparativo entre Unidades'}/>

            <FlexCP justifyContent={'center'}>
                <CardCP innerSpacing={'small'}>
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={setDateFilters}
                        fastFilter={'all'}
                        allowClear={false}
                    />
                </CardCP>
            </FlexCP>

            <ContentWrapperSCP>
                <ContentSCP>
                    <CardsProjectsNpsCP
                        accountCode={space4LeadsIntegrationKeys.accountCode}
                        dateRange={dateFilters}
                        reload={reload}
                    />
                </ContentSCP>
            </ContentWrapperSCP>
        </>
    )

}

const ContentSCP = styled.div`
  width: 100%;
`

const ContentWrapperSCP = styled.div`
  margin-top: 20px;
  display: flex;
  overflow-x: auto;
  overflow-y: scroll;
  justify-content: center;
`
