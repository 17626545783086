import EventApi from '@fullcalendar/core/api/EventApi'
import View from '@fullcalendar/core/View'
import dayGridPlugin from '@fullcalendar/daygrid'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { IFullCalendarDateInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarDateInfo'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { IFullCalendarEventInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventInfo'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IAttendantDisplay } from 'main/modules/scheduler/interfaces/IAttendantDisplay'
import { IBusinessTime } from 'main/modules/scheduler/interfaces/IBusinessTime'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { IFullCalendarEventDropInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventDropInfo'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { SelectSlotDurationCP } from 'main/common/components/full-calendar/inner/select-slot-duration/SelectSlotDurationCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { IFullCalendarEventResize } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventResize'
import { BlinkCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BlinkCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ThemeProjectCommon } from 'submodules/neritclin-components-ui/theme/project/white-labels/ThemeProjectCommon'

export type FullCalendarViewTP = 'timeGridDay' | 'timeGridWeek' | 'resourceTimeGridDay' | 'listWeek' | 'dayGridMonth' | 'customDaysView' | 'customDaysView2'

interface IFullCalendarCPProps {
    events: IFullCalendarEvent[]
    defaultView: FullCalendarViewTP
    resources?: IAttendantDisplay[]
    onDateClick?: (dateInfo: IFullCalendarDateInfo) => void
    onEventClick?: (eventInfo: IFullCalendarEventInfo) => void
    onEventDrop?: (eventDropInfo: IFullCalendarEventDropInfo) => void
    onEventResize?: (eventResizeInfo: IFullCalendarEventResize) => void

    onDateRangeChange?: (dateRange: IDateRangeFilter) => void
    slotLabelInterval?: string
    slotDuration?: string

    minTime?: string
    maxTime?: string
    loading?: boolean
    hideHeader?: boolean
    selectedDate?: Date
    businessHours?: IBusinessTime[]
    eventRender?: ((arg: { isMirror: boolean, isStart: boolean, isEnd: boolean, event: EventApi, el: HTMLElement, view: View }) => void)
    eventMouseEnter?: ((arg: { el: HTMLElement, event: EventApi, jsEvent: MouseEvent, view: View }) => void)

    canScheduleOverlap?: boolean
    showSlotDurationSelect?: boolean
    headerRight?: FullCalendarViewTP[]
}

/**
 * Componente FullCalendar para exibicao do calendario.
 */
export function FullCalendarCP(props: IFullCalendarCPProps): JSX.Element {
    const calendarRef = useRef<FullCalendar>(null)

    const [headerRight, setHeaderRight] = useState<string>(props.headerRight?.join(', ') ?? 'timeGridDay, timeGridWeek,customDaysView ')
    const [headerLeft, setHeaderLeft] = useState<string>('prev, datePickerButton, next')
    const [view, setView] = useState<string>()

    const [slotDuration, setSlotDuration] = useState<string>(props.slotDuration ?? '00:05')

    useEffect(init, [props.selectedDate, props.resources, props.defaultView])

   

    /**
     * Inicializa.
     */
    function init(): void {

        if (!!calendarRef.current) {
            const calendarApi = calendarRef.current.getApi()

            // Importante para que o FullCalendar não fique preso na exibição do businessHours de "hoje"
            if (!!props.selectedDate)
                calendarApi.gotoDate(props.selectedDate)

            props.resources?.forEach((res) => {
                calendarApi.addResource(res)
            })
        }

        setView(props.defaultView)

        if (props.hideHeader) {
            setHeaderRight('')
            setHeaderLeft('')
        }
    }

    if (!view)
        return <BlinkCP><TextCP text={'... Carregando Agenda ...'}/></BlinkCP>

    return (
        <CalendarWrapperSCP
            canScheduleOverlap={props.canScheduleOverlap ?? true}
            showSlotDurationSelect={props.showSlotDurationSelect ?? false}
        >
          
            <LoadingOverlayCP show={!!props.loading}/>

            <ConditionalRenderCP shouldRender={!!props.showSlotDurationSelect}>
                <SelectSlotDurationCP
                    onChange={setSlotDuration}
                    value={slotDuration}
                    topPosition={props.hideHeader ? '-35px' : '15px'}
                />
            </ConditionalRenderCP>
            
            <FullCalendar
                minTime={props.minTime ?? '08:00:00'}
                maxTime={props.maxTime ?? '23:00:00'}
                dateClick={props.onDateClick}
                eventClick={props.onEventClick}
                eventDrop={props.onEventDrop as any}
                editable={!!props.onEventDrop || !!props.onEventResize}
                slotLabelInterval={props.slotLabelInterval ?? '00:15'}
                slotDuration={slotDuration}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
                defaultView={view}
                
                nowIndicator={true}
                businessHours={props.businessHours}
                events={props.events}
                eventRender={props.eventRender}
                eventMouseEnter={props.eventMouseEnter}
                eventResize={props.onEventResize}
                eventDurationEditable={!!props.onEventResize}
                resources={props.resources}
                resourceOrder={'title'}
                ref={calendarRef}
                height={'parent'}
                views={{customDaysView: {
                  type: 'timeGrid',
                  duration: { days: 4 },  // Configura o intervalo de dias para mostrar
                  buttonText: '4 Dias'  // Texto do botão para a visualização
                },customDaysView2: {
                  type: 'timeGrid',
                  duration: { days: 5 },  // Configura o intervalo de dias para mostrar
                  buttonText: '5 Dias'  // Texto do botão para a visualização
                }}
          }
                allDaySlot={false}
                datesRender={(dateInfo) => {
                    if (!!props.onDateRangeChange) {
                        props.onDateRangeChange({
                            beginDate: dateInfo.view?.currentStart,
                            endDate: dateInfo.view?.currentEnd
                        })
                    }
                }}
                header={!props.hideHeader && {
                    left: headerLeft,
                    center: 'title',
                    right: headerRight,
                }}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short',
                }}
                locale={{
                    code: 'pt-br',
                    buttonText: {
                        prev: 'Anterior',
                        next: 'Próximo',
                        today: 'Hoje',
                        month: 'Mês',
                        week: 'Semana',
                        day: 'Dia',
                        list: 'Lista'
                    },
                    weekLabel: 'Sm',
                    allDayText: 'dia inteiro',
                    eventLimitText(n: string) {
                        return `mais +${n}`
                    },
                    noEventsMessage: 'Não há eventos para mostrar'
                }}
            />
        </CalendarWrapperSCP>
    )
}

const CalendarWrapperSCP = styled.div<{ canScheduleOverlap: boolean, showSlotDurationSelect: boolean }>`

  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  .fc-view-container {
    background: #fff;
    margin: 20px;
  }

  .taskPopover {
    .ant-popover-inner .ant-popover-inner-content {
      padding: 0;
    }
  }

  .fc-now-indicator {
    border-color: ${ThemeAnt.primaryColor};
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active,
  .fc-button:not(:disabled) {
    background-color: ${ThemeAnt.primaryColor};
    border-color: ${ThemeAnt.primaryColor};
    color: white;
  }

  .fc-header-toolbar {
    padding: 20px 20px 0 20px;
  }

  .fc-view, .fc-resourceTimeGridDay-view, .fc-timeGrid-view, .fc.fc-ltr.fc-unthemed {
    width: 100%;
  }

  .fc-list-heading td {
    padding: 10px;
    background: #FCE6D6 !important;
  }

  .fc-list-item td {
    padding: 7px 10px;
    border: none;
    border-bottom: solid 1px #dddddd;
  }

  .fc-event {
    z-index: -9999;
    display: block;

    .fc-title {
      white-space: normal;
      font-size: 10px;
    }

  }

  .fc-ltr .fc-time-grid .fc-event-container {
    margin: 1px;
  }

  .fc-ltr .fc-time-grid .fc-event-container {
    margin-right: ${props => props.canScheduleOverlap ? '20px;' : undefined}
  }

  .fc-view {
    overflow-x: scroll;
  }

  .fc-view > table {
    width: auto;
    /* min-width: 150%; */
    border: none;
    border-collapse: collapse;
    table-layout: fixed;
    z-index: 999;

    .fc-head-container {
      border-top: none;
    }
  }

  .fc-license-message {
    display: none
  }

  .fc-scroller, .fc-time-grid-container {
    white-space: nowrap;
    height: 66vh !important;
    overflow-x: visible !important;
    overflow-y: scroll !important;
  }

  .fc-widget-header {
    border: none;
  }

  .fc-body > tr > td.fc-widget-content:first-child {
    border: none;
  }

  .fc-day {
    border-left: none !important;
  }

  .fc-today {
    background: #fff !important;
  }

  .fc-day-header {
    height: 50px;
    vertical-align: middle;
    border-bottom: solid 1px #dddddd;
  }

  .fc-left {
    margin-left: ${props => props.showSlotDurationSelect ? '100px' : undefined};
  }
  
  .fc-axis {
    width: 50px !important;
    text-align: center !important;
    border: none !important;
    background: #FCE6D6;
    color: #CFA57F;
    font-weight: 900;
    font-size: 13px;

    &:hover {
      background-color: #FCE6D6 !important;
    }
  }

  .fc-slats td {
    height: 2rem !important;

    &:hover {
      background-color: rgba(252, 230, 214, 0.3);
    }

    &.fc-widget-content {
      border-left: none !important;
      z-index: 99999;
    }
  }

  th.fc-resource-cell {
    height: 4rem;
    vertical-align: middle;
    color: #2b698c;
    font-weight: 900;
    font-size: 15px;
  }

  th.fc-axis {
    border-bottom: 1px solid #FCE2D0 !important;
  }

  th:nth-of-type(2) {
    border-left: none !important;
  }

  .fc-row table {
    border-bottom: none;
  }

  tr[data-time]:last-of-type {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .fc-nonbusiness {
    background-color: ${ThemeProjectCommon.schedulerNonBusinessHour};
    background-image: ${ThemeProjectCommon.schedulerNonBusinessHourBackgroundExtra};
    color: #989699;
    border: 1px #fff solid;
    cursor: not-allowed;
    margin-right: ${props => props.canScheduleOverlap ? '10px;' : undefined}
  }

  .unavailable {
    background-color: ${ThemeProjectCommon.schedulerUnavailable};
    background-image: ${ThemeProjectCommon.schedulerUnavailableBackgroundExtra};
    color: #989699;
    border: 1px #fff solid;
    cursor: not-allowed;
  }

  .event-alert {
    padding: 3px;
    border: 3px ${ThemeAnt.errorColor} solid !important;
  }
`
