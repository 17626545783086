import { LayoutCP } from 'main/common/components/screen-layout/layout/LayoutCP'
import React, { useEffect, useState } from 'react'
import { RadioGroupSchedulerViewModeCP } from 'main/modules/scheduler/components/radio-group-scheduler-view-mode/RadioGroupSchedulerViewModeCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { SiderCP } from 'main/common/components/screen-layout/sider/SiderCP'
import { FormFilterCalendarEventsCP } from 'main/modules/scheduler/components/form-filter-calendar-events/FormFilterCalendarEventsCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { TableAttendanceQueuesCP } from 'main/modules/scheduler/components/table-attendance-queues/TableAttendanceQueuesCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'

/**
 * Tela de exibicao dos agendamentos que fizeram checkin
 */
export function ScreenAttendanceQueue(): JSX.Element {

    const [formValidator] = useState<FormFilterCalendarEventsFormModel>((new FormFilterCalendarEventsFormModel({
        dateInterval: { beginDate: new Date(), endDate: new Date() }
    })))
    const formStateManager = useFormStateManager<FormFilterCalendarEventsFormModel>(formValidator)

    const [shouldLoadEvents, setShouldLoadEvents] = useState<number>(0)

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        setShouldLoadEvents(TableUtils.getReloadNumber())
    }

    return (
        <AccessControlCP module={ModuleEnum.MODULE_LISOLASER}>
            <LayoutCP
                header={
                    <HeaderCP title={'Fila de Atendimento'}>
                        <RadioGroupSchedulerViewModeCP currentView={'attendanceQueue'}/>
                    </HeaderCP>
                }
                sider={
                    <SiderCP width={275} bgColor={ThemeProject.secondaryMenuColor}>
                        <FormFilterCalendarEventsCP
                            formStateManager={formStateManager}
                            onFilter={() => setShouldLoadEvents(TableUtils.getReloadNumber())}
                            filtersConfig={{ hideEvaluation: true, hideTreatmentGroup: true }}
                        />
                    </SiderCP>
                }
                content={
                    <ContentCP overflowVertical={true}>
                        <TableAttendanceQueuesCP
                            filterStateManager={formStateManager}
                            shouldReloadReport={shouldLoadEvents}
                        />
                    </ContentCP>
                }
            />
        </AccessControlCP>
    )
}
