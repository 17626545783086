import React from 'react'
import { OrArrayTP } from 'main/common/types/OrArrayTP'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/types/InputValueCallbackTP'
import { SelectFullOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { MonthEnum } from 'main/common/enums/MonthEnum'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ISelectCPProps, SelectCP } from 'main/common/components/form-fields/select/SelectCP'

type ValueTP = OrArrayTP<MonthEnum>

interface IMonthPickerCPProps<FModelTP extends FormModel = any> extends Omit<ISelectCPProps<FModelTP>, 'options'> {
    value?: ValueTP
    onChange?: InputValueCallbackTP<ValueTP>
    exhibitionFormat?: DateFormatEnum.MONTH | DateFormatEnum.MONTH_3
}

/**
 * COMPONENTE
 * Seletor mes(s) do ano.
 */
export function MonthPickerCP<FModelTP extends FormModel = any>(props: IMonthPickerCPProps): JSX.Element {

    function onChange(nextValue?: ValueTP): void {
        if (nextValue !== undefined)
            props.onChange?.(props.isMultiple ? (nextValue as number[]).map(Number) : +nextValue)
    }

    return (
        <SelectCP<FModelTP>
            options={
                DateUtils.getMonthNumbers().map<SelectFullOptionTP>(monthNumber => ({
                    value: monthNumber,
                    label: DateUtils.getMonthName(monthNumber, props.exhibitionFormat)
                }))
            }
            selectedOptions={props.selectedOptions}
            loading={props.loading}
            onSearch={props.onSearch}
            onChange={onChange}
            onFormSubmit={props.onFormSubmit}
            isMultiple={props.isMultiple}
            returnFullOption={props.returnFullOption}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            label={props.label}
            required={props.required}
            width={props.width}
            value={props.value}
            fontSize={props.fontSize}
            disabled={props.disabled}
            notFoundContent={props.notFoundContent}
            onBlur={props.onBlur}
            allowClear={props.allowClear}
        />
    )
}
