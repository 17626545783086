import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FilterDropdownProps } from 'antd/es/table'

interface ICPProps {
    children: JSX.Element
    filterProps: FilterDropdownProps
    onSearch: () => void
}

/**
 * Area generica de filtros das colunas das tabelas.
 */
export function TableFilterICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            { props.children }

            <FlexCP justifyContent={'flex-end'}>
                <ButtonCP
                    type={'primary'}
                    onClick={() => {
                        props.onSearch()
                        props.filterProps.confirm?.()
                    }}
                >
                    Filtrar
                </ButtonCP>
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
  width: 300px;
`