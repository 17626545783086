export enum PermissionEnum {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_CRM = 'ROLE_CRM',
    ROLE_SCHEDULER = 'ROLE_SCHEDULER',
    BLOQUEAR_HORARIOS = 'BLOQUEAR_HORARIOS',

    ROLE_MARKETING = 'ROLE_MARKETING',

    ROLE_FINANCIAL = 'ROLE_FINANCIAL',
    ROLE_CHANGE_PAYMENT_METHOD = 'ROLE_CHANGE_PAYMENT_METHOD',
    ROLE_RETRY_CHARGE = 'ROLE_RETRY_CHARGE',
    ROLE_SALE_CANCELATION = 'ROLE_SALE_CANCELATION',
    ROLE_SALE_REFUND = 'ROLE_SALE_REFUND',
    VER_VENDAS_OUTRAS_UNIDADES = 'VER_VENDAS_OUTRAS_UNIDADES',
    DESPESAS = 'DESPESAS',
    

    ROLE_DASHBOARD_CONSULTANT = 'ROLE_DASHBOARD_CONSULTANT',
    ROLE_DASHBOARD_ATTENDANT = 'ROLE_DASHBOARD_ATTENDANT',
    ROLE_DASHBOARD_MANAGER = 'ROLE_DASHBOARD_MANAGER',
}
