import _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ModalChangeOpportunityResponsibleCP } from 'main/modules/sales-funnel/components/change-responsible-modal/ModalChangeOpportunityResponsibleCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IOpportunityTableActionButtonsICPProps {
    rowSelection?: string[]
    onDataChanged: () => void
}

/**
 * Componente Acoes em massa da tabela de oportunidades
 */
export function OpportunityTableActionButtonsICP(props: IOpportunityTableActionButtonsICPProps): JSX.Element {

    const [showChangeResponsibleModal, setShowChangeResponsibleModal] = useState<boolean>(false)

    return (
        <ConditionalRenderCP shouldRender={!_.isEmpty(props.rowSelection)}>

            <ActionButtonWrapperSCP>
                <ButtonCP
                    onClick={() => setShowChangeResponsibleModal(true)}
                    icon={'user'}
                    size={'small'}
                    type={'ghost'}
                >
                    Mudar responsável
                </ButtonCP>
            </ActionButtonWrapperSCP>

            <ModalChangeOpportunityResponsibleCP
                showModal={showChangeResponsibleModal}
                closeModal={() => setShowChangeResponsibleModal(false)}
                selectedOpportunities={_.map(props.rowSelection, eachKey => +eachKey)}
                onActionsDone={() => {
                    setShowChangeResponsibleModal(false)
                    props.onDataChanged()
                }}
            />
        </ConditionalRenderCP>
    )
}

const ActionButtonWrapperSCP = styled.div`
  margin-left: 20px;
`
