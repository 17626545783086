/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { TextCP } from 'main/common/components/text/TextCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import styled from 'styled-components'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

interface IReceiptToPrintICPProps {
    financialTransaction: IFinancialTransactionResponseDTO
}

/**
 * HTML do Recibo de despesa.
 */
export const ReceiptToPrintICP = React.forwardRef((props: IReceiptToPrintICPProps, ref: any) => {

    return (
        <SaleDetailsPdfWrapperSCP ref={ref}>
            <HeaderWrapperSCP>
                <FlexCP justifyContent={'space-between'}>
                    <LogoCP
                        marginTop={0}
                        marginBottom={0}
                        imageUrl={AppStateUtils.getDomainData()!.schema.logoUrl}
                    />
                    <AddressSCP>
                        <TextCP text={AppStateUtils.getCurrentFranchise()!.fantasyName} strong={true}/>
                        <ConditionalRenderCP shouldRender={!!AppStateUtils.getCurrentFranchise()?.address}>
                            <TextCP text={`${AppStateUtils.getCurrentFranchise()!.address?.city ?? ''} / ${AppStateUtils.getCurrentFranchise()!.address?.state ?? ''}`}/>
                            <TextCP text={`${AppStateUtils.getCurrentFranchise()!.address?.street ?? ''}, ${AppStateUtils.getCurrentFranchise()!.address?.number ?? ''} - ${AppStateUtils.getCurrentFranchise()!.address?.complement ?? ''}`}/>
                        </ConditionalRenderCP>
                    </AddressSCP>
                </FlexCP>
            </HeaderWrapperSCP>

            <ContentSCP>
                <h1>
                    Recibo
                </h1>

                <p>
                    Recebi(emos) de <b>{AppStateUtils.getCurrentFranchise()!.fantasyName}</b>, a quantia de <b>{MaskUtils.applyMoneyMask(Math.abs(props.financialTransaction.paidValue))}</b>
                    , em <b>{DateUtils.getFormatted(props.financialTransaction.paymentDate, DateFormatEnum.BR_WITHOUT_TIME)}</b> correspondente a {props.financialTransaction.description}
                    , e para clareza firmo(amos) o presente na cidade de {AppStateUtils.getCurrentFranchise()!.address?.city ?? ''}.
                </p>

                <SignatureSCP>
                    <TextCP text={props.financialTransaction.personSupplier?.name ?? 'Fornecedor'}/>
                </SignatureSCP>
            </ContentSCP>

            <FooterSCP>
                Recibo gerado em {DateUtils.getFormatted(new Date(), DateFormatEnum.BR_WITH_TIME_H_M)}
            </FooterSCP>
        </SaleDetailsPdfWrapperSCP>
    )
})

const SaleDetailsPdfWrapperSCP = styled.div`
  border-radius: 5px;
  padding: 50px;
`

const ContentSCP = styled.div`

  margin: 100px 0;
  
  h1 {
    text-align: center;
    margin: 100px;
  }

  p {
    text-align: center;
  }
`

const FooterSCP = styled.div`
  font-style: italic;
  page-break-after:auto;
`

const AddressSCP = styled.div`
  text-align: right;
`

const SignatureSCP = styled.div`
  text-align: center;
  border-top: solid 1px #adadad;
  margin: 100px 140px 0 140px;
  padding: 10px;  
`

const HeaderWrapperSCP = styled.div`
`
