import React, { useEffect, useState } from 'react'
import { RcFile } from 'antd/lib/upload'
import { FileUploaderCP } from 'main/common/components/file-uploader/FileUploaderCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import styled from 'styled-components'

export interface IAvatarUploadCPProps {
    onChange: (imgFile?: RcFile) => void
    imgUrl?: string
    disabled?: boolean
    instructions: boolean
    width?: string
    height?: string
}
const MAX_FILE_SIZE = 5

/**
 * COMPONENTE
 * Upload de imagem
 *
 * @author kiara.vz
 */
export function AvatarUploadCP(props: IAvatarUploadCPProps): JSX.Element {

    const [imgFile, setImageFile] = useState<RcFile>()
    const [imgUrl, setImgUrl] = useState<string>()
    const [showUploadLoading, setUploadLoading] = useState(false)

    useEffect(() => props.onChange(imgFile), [imgFile])
    useEffect(() => setImgUrl(props.imgUrl), [])

    function validateImage(file: RcFile): boolean {

        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }

        const allowedTypes = ['image/jpeg', 'image/png']
        if (!allowedTypes.includes(file.type)) {
            NotificationHelper.error('Apenas JPEG e PNG são permitidos')
            return false
        }

        const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE
        if (!isLt5M) {
            NotificationHelper.error('Somente é permitido arquivos menores que 5 MB')
            return false
        }

        getBase64(file, imageUrl => {
            setUploadLoading(true)
            setImgUrl(imageUrl)
        })
        return false
    }

    function getBase64(img: RcFile, callback: (result: any) => void) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    return (
        <WrapperSCP>
            <FileUploaderCP
                imageUrl={imgUrl}
                fileTypes={'.png'}
                multiple={false}
                disabled={props.disabled}
                showUploadList={false}
                loading={showUploadLoading}
                onChange={(info) => setImageFile(info.file)}
                beforeUpload={validateImage}
                instructions={props.instructions ? 'A imagem deve estar no formato .JPG e não deve conter mais de 5MB.' : undefined}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div``