import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetFollowUpEnum } from 'main/modules/dashboard/services/enums/WidgetFollowUpEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de total de leads abertos que tem atividade atrasada.
 */
export function WidgetTotalFollowupsDelayedCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Follow-ups atrasados'}
            help={'Total de Follow-ups que possui atividades em atraso'}
            isZeroTarget={true}
            requestConfigGetter={
                DashboardRequests.getFollowupWidgets(
                    WidgetFollowUpEnum.DELAYEDS,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
        />
    )
}
