import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { BasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ChargeRetryFormModel } from 'main/modules/financial/components/modals/modal-charge-retry/inner/ChargeRetryFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChargeRequests } from 'submodules/neritclin-sdk/services/sale/charge/ChargeRequests'
import { ChargeRetryRequestDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/request/ChargeRetryRequestDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

interface ICPProps extends BasicModalCPProps {
    charge: IChargeListItemResponseDTO
    financialTransaction?: IFinancialTransactionCreditListItemResponseDTO
}

/**
 */
export function ModalChargeRetryCP(props: ICPProps): JSX.Element {

    const [formValidator] = useState<ChargeRetryFormModel>(new ChargeRetryFormModel())
    const formStateManager = useFormStateManager<ChargeRetryFormModel>(formValidator)

    const chargeRetryRequest = useRequest<void>('none')
    useEffect(onChargeRetryRequestChange, [chargeRetryRequest.isAwaiting])

    /**
     */
    async function retryCharge(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: ChargeRetryRequestDTO = {
            chargeCode: props.charge.code,
            financialTransactionCode: props.financialTransaction?.code,
            paymentMethod: formValues.paymentMethod
        }
        chargeRetryRequest.runRequest(ChargeRequests.chargeRetry(dto))
    }

    /**
     */
    function onChargeRetryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(chargeRetryRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            title={'Retentativa de Cobrança'}
            width={440}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={retryCharge}
            actionLoading={chargeRetryRequest.isAwaiting}
        >

            {
                !!props.financialTransaction
                    ?
                    <>
                        <FlexCP justifyContent={'center'}>
                            <RadioGroupCP
                                type={'button'}
                                formStateManager={formStateManager}
                                fieldName={'paymentMethod'}
                                buttonWidth={120}
                                options={[
                                    {
                                        value: PaymentMethodEnum.CREDIT_CARD_RECURRENT,
                                        content: 'Cartão Atual',
                                        hide: props.financialTransaction?.paymentMethod !== PaymentMethodEnum.CREDIT_CARD_RECURRENT,
                                    },
                                    {
                                        value: PaymentMethodEnum.PIX,
                                        content: 'PIX',
                                    },
                                    {
                                        value: PaymentMethodEnum.BANK_BILLET,
                                        content: 'Boleto',
                                    },
                                ]}
                            />
                        </FlexCP>

                        <HelpCP
                            marginTop={20}
                            type={'text'}
                            text={'Selecione a forma de pagamento para a re-tentativa de cobrança. Para retentar cobrar no cartão original, escolha "Cartão Atual"'}
                        />
                    </>
                    :
                    <AlertCP
                        type={'warning'}
                        message={'Iremos fazer uma nova tentativa de cobrança no cartão de crédito informado no momento da compra.'}
                    />
            }
        </ModalCP>
    )
}
