import React, { useEffect } from 'react'
import { FranchiseRequests } from 'main/modules/admin/services/franchise/FranchiseRequests'
import { IImageSaveResponseDTO } from 'main/modules/people/services/person/dtos/response/IImageSaveResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { AvatarUploadCP } from 'main/common/components/file-uploader/AvatarUploadCP'
import styled from 'main/config/theme/styledWithTheme'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

/**
 * Customizacao de aparencia do sistema
 *
 * @author Rafael V.
 */
export function SystemAppereanceScreenContent(): JSX.Element {

    const uploadImageRequest = useRequest<IImageSaveResponseDTO>()
    useEffect(onUploadImageRequestChange, [uploadImageRequest.isAwaiting])

    /**
     * Requisicao de upload
     */
    function uploadImage(imageType: 'cover' | 'logo', file?: File): void {

        if (!file)
            return

        if (imageType === 'cover')
            uploadImageRequest.runRequest(FranchiseRequests.uploadFranchiseCover(file))

        uploadImageRequest.runRequest(FranchiseRequests.uploadFranchiseLogo(file))
    }

    /**
     * Retorno da API
     */
    function onUploadImageRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(uploadImageRequest, 'Ops! Ocorreu algum erro ao salvar a imagem'))
            return

        NotificationHelper.success('Yes!', 'Imagem alterada com sucesso')
    }

    return (
        <>
            <CardCP title={'Logo'}>
                <CardContentSCP>
                    <AvatarUploadCP
                        onChange={(file) => uploadImage('logo', file)}
                        instructions={false}
                        imgUrl={AppStateUtils.getDomainData()?.schema.logoUrl}
                    />
                </CardContentSCP>
            </CardCP>
            <CardCP title={'Imagem da Capa'}>
                <CardContentSCP>
                    <AvatarUploadCP
                        onChange={(file) => uploadImage('cover', file)}
                        instructions={false}
                        imgUrl={AppStateUtils.getDomainData()?.schema.coverUrl}
                    />
                </CardContentSCP>
            </CardCP>
        </>
    )
}

const CardContentSCP = styled.div`
  text-align: center;
`