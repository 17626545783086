import React from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import styled from 'styled-components'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'

interface IBasicInfoICPProps {
    name: string
    socialName?: string
    fantasyName?: string
    mainImageUrl?: string
    cpfCnpj: string
    phone?: string
    email?: string
    editAction: () => void
}

export function BasicInfoICP(props: IBasicInfoICPProps): JSX.Element {
    return (
        <MainInfoWrapper>

            <TitleCP
                textSize={'large'}
                onClick={props.editAction}
            >
                {props.name ?? props.fantasyName ?? props.socialName}
            </TitleCP>

            {
                props.cpfCnpj &&
                <TitleAndTextCP title={'CPF/CNPJ: '} text={MaskUtils.applyMask(props.cpfCnpj, InputMaskTypeEnum.CPFCNPJ)}/>
            }

            {
                props.phone &&
                <TitleAndTextCP title={'Telefone: '} text={<TextPersonPhoneCP phone={props.phone}/>}/>
            }

            {
                props.email &&
                <div className='caixa-email'>
                    <div className='label-email'>Email:</div>
                    <div className='cp-texto-email'>{props.email}</div>
                </div>
            }

        </MainInfoWrapper>
    )
}

const MainInfoWrapper = styled.div`
  margin-bottom: 30px;
  line-height: 25px;
  .caixa-email{
    max-width: 232px;
  }
  .label-email{
    font-weight: 700;
  }

  .cp-texto-email{
    font-size: 13px;
    line-height: 17px;
  }

`
