import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de taxa de revenda.
 */
export function WidgetTotalResaleCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Quantidade de Revendas na cabine'}
            help={'Número de vendas com a origem da venda: Revenda de Cabine'}
            footerTitle={'Taxa de revenda'}
            footerHelp={'Taxa entre vendas realizadas pelo número de clientes atendidos'}
            secondCalc={'ratioBetweenMainValueAndSecondValue'}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.RESELL_IN_APPLICATION_ROOM,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
        />
    )
}
