export enum MarketingTransactionalEventEnum {
    SESSION_SCHEDULE = 'SESSION_SCHEDULE',
    SESSION_ALERT = 'SESSION_ALERT',
    SESSION_NOSHOW = 'SESSION_NOSHOW',

    EVALUATION_SCHEDULE = 'EVALUATION_SCHEDULE',
    EVALUATION_ALERT = 'EVALUATION_ALERT',
    EVALUATION_WITHOUT_SALE = 'EVALUATION_WITHOUT_SALE',
    EVALUATION_WITH_FREE_SALE = 'EVALUATION_WITH_FREE_SALE',
    EVALUATION_WITH_SALE = 'EVALUATION_WITH_SALE',
    EVALUATION_NO_SHOW = 'EVALUATION_NO_SHOW',

    SALE_DEFAULTING = 'SALE_DEFAULTING',

    PERSON_BIRTHDAY = 'PERSON_BIRTHDAY',
}

export enum MarketingTransactionalEventLabelEnum {
    SESSION_SCHEDULE = 'Sessão Agendada',
    SESSION_ALERT = 'Aviso de Sessão',
    SESSION_NOSHOW = 'No-Show em Sessão',

    EVALUATION_SCHEDULE = 'Avaliação Agendada',
    EVALUATION_ALERT = 'Aviso de Avaliação',
    EVALUATION_WITHOUT_SALE = 'Avaliação sem Venda',
    EVALUATION_WITH_FREE_SALE = 'Avaliação com Gratuidade',
    EVALUATION_WITH_SALE = 'Avaliação com Venda',
    EVALUATION_NO_SHOW = 'No-Show Avaliação',

    SALE_DEFAULTING = 'Cliente Inadimplente',

    PERSON_BIRTHDAY = 'Aniversário',
}
