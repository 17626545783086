import React, { useEffect, useState } from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { TableSaleInstallmentsCP } from 'main/modules/sale/components/table-sale-installments/TableSaleInstallmentsCP'
import { ButtonMultiplePaymentMethodActionCP } from 'main/modules/sale/components/button-multiple-payment-method-action/ButtonMultiplePaymentMethodActionCP'
import { TagChargeStatusCP } from 'main/modules/financial/components/tag-charge-status/TagChargeStatusCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import * as _ from 'lodash'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ButtonChargeRetryCP } from 'main/modules/financial/components/buttons/button-charge-retry/ButtonChargeRetryCP'
import { ButtonChargeCancellationCP } from 'main/modules/financial/components/buttons/button-charge-cancellation/ButtonChargeCancellationCP'
import { ButtonChargeHistoryCP } from 'main/modules/financial/components/buttons/button-charge-history/ButtonChargeHistoryCP'

interface ICollapseSaleChargesCPProps {
    saleCode: number
    sale?: ISaleResponseDTO // Para mostrar NFe em cada parcela sera necessario passar a venda inteira
    onLoadCharges?: (charges: IChargeListItemResponseDTO[]) => void
    onChangePayment: () => void
    shouldLoadCharges: boolean
    hasSaleNfe: boolean
}

/**
 * Collapse das cobrancas de uma venda
 */
export function CollapseSaleChargesCP(props: ICollapseSaleChargesCPProps): JSX.Element {

    const [charges, setCharges] = useState<IChargeListItemResponseDTO[]>([])
    const getChargesRequest = useRequest<ListResponseDTO<IChargeListItemResponseDTO>>()
    useEffect(onGetChargesRequestChange, [getChargesRequest.isAwaiting])

    const [nfes, setNfes] = useState<NFeResponseDTO<NFeCustomArgsTP>[]>()
    const searchNFesRequest = useRequest<ListResponseDTO<NFeResponseDTO<NFeCustomArgsTP>>>()
    useEffect(onSearchNFesRequestChange, [searchNFesRequest.isAwaiting])

    useEffect(init, [props.saleCode, props.shouldLoadCharges])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.shouldLoadCharges)
            return

        // Carrega as cobrancas
        getChargesRequest.runRequest(SaleRequests.getCharges(props.saleCode))
        loadNfes()
    }

    /**
     * Carrega as NFes das parcelas
     */
    function loadNfes(): void {

        // Carrega as notas de cada parcela, caso o sistema seja configurado para gerar NFe por parcela
        if (!AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.shouldIssueNFEByInstallment)
            return

        searchNFesRequest.runRequest(SaleRequests.searchSaleInstallmentsNfe(props.saleCode))
    }

    /**
     * Retorno da requisicao para obter as cobrancas da venda.
     */

    function escondeParcelaDeUmReal(massa:any){
        massa.forEach((ob:any)=>{
            if(ob?.chargeData?.paymentMethod=='CREDIT_CARD_RECURRENT'){
                if(ob?.financialTransactions[0]?.value==1){
                    console.log("HOUVE MASCARAÇÂO DE PARCELA DE 1 REAL");
                    ob.financialTransactions = ob.financialTransactions.filter((i:any)=>i.value!=1);
                    ob.financialTransactions.forEach((i:any)=>{
                        i.installmentNumber = i.installmentNumber-1;
                        i.totalInstallments = i.totalInstallments-1;
                    });
                }
            }
        });
    }

    function onGetChargesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChargesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const responseDto = getChargesRequest.responseData?.list ?? []
        if(AppStateUtils.getDomainSlug()!=='lisolaser')escondeParcelaDeUmReal(responseDto);//esconde a parcela de 1 real quando ela é a primeira e o metodo é cartão recorrente
        setCharges(responseDto)

        if (props.onLoadCharges)
            props.onLoadCharges(responseDto)
    }

    /**
     * Retorno ao buscar dados da venda na api.
     */
    function onSearchNFesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn({ request: searchNFesRequest, shouldReportFailure: false }, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setNfes(searchNFesRequest.responseData?.list)
    }

    return (
        <CollapseCP
            overflow={'auto'}
            loading={getChargesRequest.isAwaiting || searchNFesRequest.isAwaiting}
            bordered={true}
            panels={
                charges.map((charge) => (
                    {
                        key: `key-${charge.code}`,
                        title:
                            <FlexCP>
                                <TextCP text={PaymentMethodLabelEnum[charge.chargeData.paymentMethod!]}/>
                                <TextCP
                                    text={`${MaskUtils.applyMoneyMask(_.sumBy(charge.financialTransactions, 'value'))} dividido em ${charge.financialTransactions.length}x`}
                                    fontStyle={'italic'}
                                    marginLeft={15}
                                    size={'small'}
                                    color={ThemeAnt.gray}
                                />
                            </FlexCP>,
                        extra:
                            <FlexCP justifyContent={'flex-end'}>
                                <ConditionalRenderCP shouldRender={!!charge.chargeData.chargeStatus}>
                                    <TagChargeStatusCP status={charge.chargeData.chargeStatus!}/>
                                </ConditionalRenderCP>
                            </FlexCP>,
                        content: (
                            <>
                                <FlexCP justifyContent={'center'} marginBottom={20}>
                                    <ButtonChargeRetryCP
                                        charge={charge}
                                        margin={{ left: 5 }}
                                        onChangeData={() => getChargesRequest.runRequest(SaleRequests.getCharges(props.saleCode))}
                                        appearance={{ showText: true }}
                                    />
                                    <ButtonChargeCancellationCP
                                        charge={charge}
                                        margin={{ left: 5 }}
                                        onChangeData={() => getChargesRequest.runRequest(SaleRequests.getCharges(props.saleCode))}
                                        appearance={{ showText: true }}
                                    />

                                    <ButtonMultiplePaymentMethodActionCP
                                        charge={charge}
                                        marginLeft={5}
                                        marginRight={5}
                                    />

                                    

                                    <ButtonChargeHistoryCP
                                        chargeCode={charge.code}
                                        margin={{ left: 20 }}
                                        appearance={{ showText: true }}
                                    />
                                </FlexCP>

                                <TableSaleInstallmentsCP
                                    onUpdateAnyData={props.onChangePayment}
                                    charge={charge}
                                    onReloadNFe={loadNfes}
                                    nfeData={{
                                        hasSaleNfe: props.hasSaleNfe,
                                        nfes: nfes,
                                        sale: props.sale
                                    }}
                                />
                            </>
                        )
                    }
                ))
            }
        />
    )
}
