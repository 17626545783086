import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import React, { useEffect, useState } from 'react'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IFinancialTransactionsExpenseListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionsExpenseListItemResponseDTO'
import { FinancialTransactionsDebitTableUtils } from 'main/modules/financial/components/table-financial-transactions-debit/inner/FinancialTransactionsDebitTableUtils'
import * as _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { ModalPayMultipleFinancialTransactionCP } from 'main/modules/financial/components/modal-pay-multiple-financial-transaction/ModalPayMultipleFinancialTransactionCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ModalEditMultipleFinancialTransactionCP } from 'main/modules/financial/components/modal-edit-multiple-financial-transaction/ModalEditMultipleFinancialTransactionCP'
import { ButtonExportCP } from 'main/modules/app/components/button-export/ButtonExportCP'

interface ITableFinancialTransactionsDebitCPProps {
    filters: IFinancialTransactionSearchRequestDTO
    setFilters: (filters: IFinancialTransactionSearchRequestDTO) => void
    shouldReloadList?: boolean
    onSelectTransaction: (code: number) => void
    onReportLoaded: () => void
    onUpdateAnyData: () => void
}

/**
 * Componente de tabela que lista as receitas.
 */
export function TableFinancialTransactionsDebitCP(props: ITableFinancialTransactionsDebitCPProps): JSX.Element {

    const [isMultipleEditModalVisible, setIsMultipleEditModalVisible] = useState<boolean>(false)
    const [isMultiplePayModalVisible, setIsMultiplePayModalVisible] = useState<boolean>(false)

    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)
    const [selectedPersonCode, setSelectedPersonCode] = useState<number>()

    const [financialTransactions, setFinancialTransactions] = useState<IFinancialTransactionsExpenseListItemResponseDTO[]>([])
    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    const searchTransactionsRequest = useRequest<IGenericListResponseDTO<IFinancialTransactionsExpenseListItemResponseDTO>>()
    useEffect(onSearchTransactionsRequestChange, [searchTransactionsRequest.isAwaiting])

    useEffect(init, [props.shouldReloadList])
    useEffect(loadFinancialTransactions, [pagination.current])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldReloadList)
            return

        loadFinancialTransactions()
    }

    /**
     * Inicializa
     */
    function loadFinancialTransactions(): void {

        const searchDto: IFinancialTransactionSearchRequestDTO = {
            ...props.filters,
            itemsPerPage: TableUtils.getDefaultPagination().pageSize,
            page: pagination.current
        }

        searchTransactionsRequest.runRequest(FinancialTransactionsRequests.searchDebitTransactionsConfig(searchDto))
    }

    /**
     * Retorno da busca por depesas.
     */
    function onSearchTransactionsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchTransactionsRequest, 'Erro ao buscar despesas!'))
            return

        setFinancialTransactions(searchTransactionsRequest.responseData!.list)
        setPagination({ ...pagination, total: searchTransactionsRequest.responseData!.total })
        props.onReportLoaded()
    }

    /**
     * Ao selecionar uma pessoa.
     */
    function onSelectPerson(code: number): void {
        setSelectedPersonCode(code)
        setIsPersonDrawerVisible(true)
    }

    return (
        <>
            <FlexCP justifyContent={'space-between'} marginTop={20}>
                <FlexCP>
                    <ConditionalRenderCP shouldRender={!SystemUtils.isEmpty(selectedRowKeys)}>
                        <ButtonCP
                            size={'small'}
                            icon={'edit'}
                            marginRight={10}
                            onClick={() => setIsMultipleEditModalVisible(true)}
                        >
                            Editar despesa(s)
                        </ButtonCP>
                        <ButtonCP
                            size={'small'}
                            icon={'audit'}
                            type={'ghost'}
                            onClick={() => setIsMultiplePayModalVisible(true)}
                        >
                            Marcar como pago
                        </ButtonCP>
                    </ConditionalRenderCP>
                </FlexCP>
                <ButtonExportCP
                    requestConfig={() => FinancialTransactionsRequests.exportExpense(TableUtils.removePaginationAttributes(props.filters))}
                    reportName={'despesas'}
                />
            </FlexCP>

            <CardCP innerSpacing={'none'} margin={'vertical'}>
                <TableCPOLD2<IFinancialTransactionsExpenseListItemResponseDTO>
                    data={financialTransactions}
                    loading={searchTransactionsRequest.isAwaiting}
                    columns={FinancialTransactionsDebitTableUtils.getTableColumns(props.onSelectTransaction, onSelectPerson, props.onUpdateAnyData)}
                    rowKey={(record) => record.code.toString()}
                    rowSelection={{
                        onChange: (selectedKeys) => setSelectedRowKeys(_.map(selectedKeys, eachKey => eachKey.toString())),
                        columnWidth: 40,
                        selectedRowKeys,
                    }}
                    pagination={pagination}
                    onPaginationChange={(page): void => {
                        setPagination({ ...pagination, current: page.current! })
                        props.setFilters({ ...props.filters, page: page.current })
                    }}
                />
            </CardCP>

            <ModalPayMultipleFinancialTransactionCP
                visible={isMultiplePayModalVisible}
                financialTransactionCodes={_.map(selectedRowKeys, eachKey => +eachKey)}
                onClose={() => setIsMultiplePayModalVisible(false)}
                onSave={() => {
                    setIsMultiplePayModalVisible(false)
                    props.onUpdateAnyData()
                    setSelectedRowKeys([])
                }}
            />

            <ModalEditMultipleFinancialTransactionCP
                visible={isMultipleEditModalVisible}
                financialTransactionCodes={_.map(selectedRowKeys, eachKey => +eachKey)}
                onClose={() => setIsMultipleEditModalVisible(false)}
                onSave={() => {
                    setIsMultipleEditModalVisible(false)
                    props.onUpdateAnyData()
                    setSelectedRowKeys([])
                }}
            />

            {
                selectedPersonCode &&
                <DrawerPersonDetailsCP
                    visible={isPersonDrawerVisible}
                    personCode={selectedPersonCode}
                    segmentType={SegmentTypeEnum.CUSTOMER}
                    onSave={() => setIsPersonDrawerVisible(false)}
                    onClose={() => setIsPersonDrawerVisible(false)}
                />
            }
        </>
    )
}
