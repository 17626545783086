import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { MktTransactionalConfigFormModel } from 'main/modules/marketing/components/modal-mkt-transactional-config/inner/MktTransactionalConfigFormModel'
import {
    MarketingTransactionalEventEnum,
    MarketingTransactionalEventLabelEnum
} from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'
import { FormStateManagerUtils } from 'main/common/form-state-manager/FormStateManagerUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SelectTransactionalTriggerTypeCP } from 'main/modules/marketing/components/select-transactional-trigger-type/SelectTransactionalTriggerTypeCP'
import { SelectTransactionalTriggerMomentTypeCP } from 'main/modules/marketing/components/select-transactional-trigger-moment-type/SelectTransactionalTriggerMomentTypeCP'
import {
    TransactionalTriggerTypeEnum,
    TransactionalTriggerTypeLabelEnum
} from 'main/modules/marketing/components/select-transactional-trigger-type/inner/TransactionalTriggerTypeEnum'
import {
    TransactionalTriggerMomentTypeEnum,
    TransactionalTriggerMomentTypeLabelEnum
} from 'main/modules/marketing/components/select-transactional-trigger-moment-type/inner/TransactionalTriggerMomentTypeEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IMarketingTransactionalResponseDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/responses/IMarketingTransactionalResponseDTO'
import { MarketingTransactionalRequests } from 'main/modules/marketing/services/marketing-transactional/MarketingTransactionalRequests'
import { IMarketingTransactionalSaveRequestDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/request/IMarketingTransactionalSaveRequestDTO'

interface ICPProps {
    visible: boolean
    type: MarketingTransactionalEventEnum
    onSave: () => void
    onCancel: () => void
    code?: number
}

/**
 * COMPONENTE modal para configuracao de uma ocorrencia do mkt transacional.
 */
export function ModalMktTransactionalConfigCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<MktTransactionalConfigFormModel>((new MktTransactionalConfigFormModel()))
    const formStateManager = useFormStateManager<MktTransactionalConfigFormModel>(formValidator)

    const getMktTransactionalRequest = useRequest<IMarketingTransactionalResponseDTO>()
    useEffect(onGetMktTransactionalRequestChange, [getMktTransactionalRequest.isAwaiting])

    const saveMktTransactionalRequest = useRequest<IMarketingTransactionalResponseDTO>()
    useEffect(onSaveMktTransactionalRequestChange, [saveMktTransactionalRequest.isAwaiting])

    useEffect(() => { init() }, [props.visible])

    /**
     * Inicializ.
     */
    async function init(): Promise<void> {

        formStateManager.reset(new MktTransactionalConfigFormModel({ timeType: TransactionalTriggerMomentTypeEnum.HOUR }))
        if (!props.visible)
            return

        if (props.code)
            getMktTransactionalRequest.runRequest(await MarketingTransactionalRequests.findOne(props.code))
    }

    /**
     * Retorno da requisicao para obter as configuracoes
     */
    function onGetMktTransactionalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getMktTransactionalRequest, 'Erro ao obter configurações da automação'))
            return

        const dtoResult = getMktTransactionalRequest.responseData!

        // Define o trigger type baseado se a quantidade eh positiva ou negativa. Caso seja zero eh o dia do evento
        let triggerType = TransactionalTriggerTypeEnum.EXACT_DAY
        if (dtoResult.triggerMomentQuantity > 0)
            triggerType = TransactionalTriggerTypeEnum.AFTER
        else if (dtoResult.triggerMomentQuantity < 0)
            triggerType = TransactionalTriggerTypeEnum.BEFORE

        setFormValidator(new MktTransactionalConfigFormModel({
            quantity: Math.abs(dtoResult.triggerMomentQuantity),
            triggerType: dtoResult.triggerMomentType === TransactionalTriggerMomentTypeEnum.AT_MOMENT ? TransactionalTriggerTypeEnum.AT_MOMENT : triggerType,
            timeType: dtoResult.triggerMomentType
        }))
    }

    /**
     * Salva
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        if ((formValues.triggerType === TransactionalTriggerTypeEnum.BEFORE || formValues.triggerType === TransactionalTriggerTypeEnum.AFTER)
            && (!formValues.quantity || !formValues.timeType)) {
            NotificationHelper.error('Ops', 'Preencha todos os campos para continuar')
            return
        }

        // Define a quantidade baseado no trigger type. Caso seja after apenas pega o valor pois sera positivo
        let quantityConsideringTriggerType = formValues.quantity
        if (formValues.triggerType === TransactionalTriggerTypeEnum.EXACT_DAY || formValues.triggerType === TransactionalTriggerTypeEnum.AT_MOMENT)
            quantityConsideringTriggerType = 0
        else if (formValues.triggerType === TransactionalTriggerTypeEnum.BEFORE)
            quantityConsideringTriggerType *= -1

        const dto: IMarketingTransactionalSaveRequestDTO = {
            isActive: true,
            event: props.type,
            triggerMomentType: formValues.triggerType === TransactionalTriggerTypeEnum.AT_MOMENT ? TransactionalTriggerMomentTypeEnum.AT_MOMENT : formValues.timeType,
            triggerMomentQuantity: quantityConsideringTriggerType
        }

        if (!!props.code)
            saveMktTransactionalRequest.runRequest(await MarketingTransactionalRequests.update(props.code, dto))
        else
            saveMktTransactionalRequest.runRequest(await MarketingTransactionalRequests.create(dto))
    }

    /**
     * Apos reotorno da api de salvar.
     */
    function onSaveMktTransactionalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveMktTransactionalRequest, 'Ocorreu algun erro ao salvar'))
            return

        NotificationHelper.info('Yes', 'Automação salva com sucesso')

        formStateManager.reset(undefined)
        props.onSave()
    }

    return (
        <ModalCP
            title={MarketingTransactionalEventLabelEnum[props.type]}
            onOk={save}
            onCancel={props.onCancel}
            visible={props.visible}
            actionLoading={saveMktTransactionalRequest.isAwaiting}
            width={400}
            destroyOnClose={true}
            loading={getMktTransactionalRequest.isAwaiting}
        >

            <FlexCP justifyContent={'center'} marginBottom={20}>
                <div>
                    <SelectTransactionalTriggerTypeCP
                        formStateManager={formStateManager}
                        fieldName={'triggerType'}
                    />
                </div>
            </FlexCP>

            {
                formStateManager.getFieldValue('triggerType') === TransactionalTriggerTypeEnum.EXACT_DAY &&
                <TextCP
                    text={'Será enviado às 6:00 da manhã no dia do evento.'}
                    fontStyle={'italic'}
                    marginTop={20}
                    wrap={true}
                    color={ThemeAnt.gray}
                />
            }

            {
                formStateManager.getFieldValue('triggerType') === TransactionalTriggerTypeEnum.AT_MOMENT &&
                <TextCP
                    text={'Será enviado logo após realizado o evento.'}
                    fontStyle={'italic'}
                    marginTop={20}
                    wrap={true}
                    color={ThemeAnt.gray}
                />
            }

            {
                (formStateManager.getFieldValue('triggerType') === TransactionalTriggerTypeEnum.AFTER ||
                formStateManager.getFieldValue('triggerType') === TransactionalTriggerTypeEnum.BEFORE) &&
                <RowCP>
                    <ColumnCP size={10}>
                        <InputCP
                            label={`Nº de ${TransactionalTriggerMomentTypeLabelEnum[formStateManager.getFieldValue('timeType')]?.toLowerCase()}`}
                            formStateManager={formStateManager}
                            fieldName={'quantity'}
                            required={true}
                            type={'number'}
                        />
                    </ColumnCP>
                    <ColumnCP size={14}>
                        <SelectTransactionalTriggerMomentTypeCP
                            formStateManager={formStateManager}
                            fieldName={'timeType'}
                            extraLabel={TransactionalTriggerTypeLabelEnum[formStateManager.getFieldValue('triggerType')]?.toLowerCase()}
                        />
                    </ColumnCP>
                </RowCP>
            }
        </ModalCP>
    )
}
