import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { OpportunityEvaluationRequests } from 'main/modules/sales-funnel/services/evaluation/OpportunityEvaluationRequests'
import React, { useEffect } from 'react'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { MultiActionButtonCP } from 'main/common/components/multi-action-button/MultiActionButtonCP'

export type EvaluationActionTP = 'save' | 'markAsDone' | 'markAsNoShow'

interface ICPProps {
    code?: number
    onSave: (action: EvaluationActionTP) => void
    onDelete: () => void
    isSaving?: boolean
}

/**
 * Botoes do Drawer para agendar uma avaliação.
 */
export function EvaluationDrawerButtonsICP(props: ICPProps): JSX.Element {

    const deleteEvaluationRequest = useRequest<void>('none')
    useEffect(onDeleteEvaluationRequestChange, [deleteEvaluationRequest.isAwaiting])

    /**
     * Remover agendamento.
     */
    function deleteEvaluation(): void {

        if (!props.code)
            return

        deleteEvaluationRequest.runRequest(OpportunityEvaluationRequests.delete(props.code))
    }

    /**
     *  Retorno da remocao de agendamento.
     */
    function onDeleteEvaluationRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteEvaluationRequest, 'Não foi remover a avaliação', true))
            return

        NotificationHelper.success('Pronto!', 'Avaliação removida')
        props.onDelete()
    }

    return (
        <>
            <ConditionalRenderCP shouldRender={!!props.code}>
                <ButtonCP
                    onClick={deleteEvaluation}
                    type={'danger'}
                    loading={deleteEvaluationRequest.isAwaiting}
                    confirmMsg={'Você tem certeza que deseja remover essa avaliação?'}
                >
                    Remover
                </ButtonCP>
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={!props.code}>
                <ButtonCP
                    onClick={() => props.onSave('save')}
                    type={'primary'}
                    loading={props.isSaving}
                >
                    Salvar
                </ButtonCP>
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={!!props.code}>
                <MultiActionButtonCP
                    type={'primary'}
                    loading={props.isSaving}
                    options={[
                        {
                            label: 'Marcar como Realizado',
                            onClick: () => props.onSave('markAsDone')
                        },
                        {
                            label: 'Não compareceu',
                            onClick: () => props.onSave('markAsNoShow')
                        },
                        {
                            label: 'Apenas salvar alterações',
                            onClick: () => props.onSave('save')
                        },
                    ]}
                />
            </ConditionalRenderCP>
        </>
    )
}
