import { TitleCP } from 'main/common/components/title/TitleCP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { SystemConfig } from 'main/config/SystemConfig';
import * as One from "common/utils/One"
import React, { useEffect, useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP';
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP';
import { TableCP } from 'main/common/components/table/TableCP';
import { ModalCP } from 'main/common/components/modal/ModalCP';
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP';
interface IAnamnese {
    entityCode:number
}
export function AnamnesesPerson(props: IAnamnese): JSX.Element {
    const [carregando,setCarregando] = useState(false);
    const [carregandoRespostas,setCarregandoRespostas] = useState(false);
    const [visaoResposta,setVisaoResposta] = useState(false);
    const [respostas,setRespostas] = useState<any>(null);
    const [anamneses,setAnamneses] = useState<any>(null);
    
    useEffect(()=>{
        carregarDados();
    },[]);

    const colunasTabela = [
        
        {
          title: 'Nº Sessão',
          dataIndex: 'codigodasessao',
          key: 'codigodasessao',
        },
        {
          title: 'Formulário',
          dataIndex: 'formulario',
          key: 'formulario',
         
        },
        {
            title: 'Data de Realização',
            dataIndex: 'dia',
            key: 'dia',
           
          },
        {
            title:'Ações',
            key: 'actions',
            render: (text:any, item:any) => (
                <FlexCP justifyContent={'flex-end'}>
                    <ButtonCP
                        type={'primary'}
                        icon={'eye'}
                        size={'small'}
                        loading={carregandoRespostas}
                        onClick={()=>{carregarRespostas(item.codigodasessao)}}
                    />
                </FlexCP>
            )
        }
      ];

    async function carregarRespostas(codigo:any){
        console.log("Tentando carregar respostas ", codigo);
        setCarregandoRespostas(true);
        const parametros = { url: `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms/obterRespostasPorSessao`,
        method: HttpMethodEnum.POST,
            params: {
                schema:AppStateUtils.getDomainSlug(),
                codigosessao:codigo
            }
        }

        console.log("Parametros ", parametros);

        const resultado:any = await RequestHelper.runRequest(parametros).finally(()=>{setCarregandoRespostas(false);});
        console.log("Resultado vindo ", resultado);
        if(One.ok(resultado)){
            const res = One.dados(resultado);
            console.log("Respostas ",res);
            // if(res && res.length)res.forEach((r:any)=>r.dia = One.dateDeStamp(r.datarealizacaosessao))
            setRespostas(res);
            setVisaoResposta(true);
            console.log(respostas)
        }

        setCarregandoRespostas(false);
    }

    async function carregarDados(){
        setCarregando(true);
        const parametros = { url: `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms/obterAnamneses`,
        method: HttpMethodEnum.POST,
            params: {
                schema:AppStateUtils.getDomainSlug(),
                codigoPessoa:props.entityCode
            }
        }

        console.log("Parametros ", parametros);

        const resultado:any = await RequestHelper.runRequest(parametros).finally(()=>{});
        console.log("Resultado vindo ", resultado);
        if(One.ok(resultado)){
            const res = One.dados(resultado);
            console.log("Perguntas ",res);
            if(res && res.length)res.forEach((r:any)=>r.dia = One.dateDeStamp(r.datarealizacaosessao))
            setAnamneses(res);
            console.log(anamneses)
        }

        setCarregando(false);
    }

    function Respostas (){
        if(!respostas || !respostas.length) return <></>;
        return (
            <>
                {respostas.map((r:any)=>{
                   return <TitleAndTextCP
                    title={r.pergunta}
                    text={r.resposta}
                    ></TitleAndTextCP>
                })}
            </>
        )
    }

    return (
        <>
            <TitleCP>
                Histórico de Anamneses do Cliente
            </TitleCP>
           
            <LayoutSubmenuContentCP>
                <TableCP
                columns={colunasTabela}
                data={anamneses ?? []}
                loading={carregando}
                />
            </LayoutSubmenuContentCP>

            <ModalCP visible={visaoResposta}
                okText='Ok'
                onClose={()=>{setVisaoResposta(false)}}
                onOk={()=>{setVisaoResposta(false)}}
                title='Anamnese'
            >

               <Respostas/> 

            </ModalCP>
        </>
    )

}