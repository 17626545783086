import React, { useState } from 'react'
import styled from 'styled-components'
import { TabsFranchiseManagerCP } from 'main/modules/dashboard/components/tabs-franchise-manager/TabsFranchiseManagerCP'
import { DashboardMultipleFranchiseTitleCP } from 'main/modules/dashboard/components/dashboard-multiple-franchise-title/DashboardMultipleFranchiseTitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetTableLeadsByFranchiseCP } from 'main/modules/dashboard/components/widget-table-leads-by-franchise/WidgetTableLeadsByFranchiseCP'
import { WidgetTableEvaluationsByFranchiseCP } from 'main/modules/dashboard/components/widget-table-evaluations-by-franchise/WidgetTableEvaluationsByFranchiseCP'
import { WidgetTableFollowupsByFranchiseCP } from 'main/modules/dashboard/components/widget-table-followups-by-franchise/WidgetTableFollowupsByFranchiseCP'

/**
 * Dashboard da técnica de procedimento
 */
export function ScreenContentMultipleFranchiseManagerDashboardTabCP(): JSX.Element {

    const [selectedFranchiseCodes, setSelectedFranchiseCodes] = useState<number[]>([])

    return (
        <WrapperSCP>

            <DashboardMultipleFranchiseTitleCP franchiseCodes={selectedFranchiseCodes} onSelectFranchiseCodes={setSelectedFranchiseCodes}/>

            <WidgetsRowCP>
                <WidgetTableLeadsByFranchiseCP franchiseCodes={selectedFranchiseCodes}/>
            </WidgetsRowCP>
            <WidgetsRowCP marginTop={20}>
                <WidgetTableEvaluationsByFranchiseCP franchiseCodes={selectedFranchiseCodes}/>
            </WidgetsRowCP>
            <WidgetsRowCP marginTop={20}>
                <WidgetTableFollowupsByFranchiseCP franchiseCodes={selectedFranchiseCodes}/>
            </WidgetsRowCP>
            

            <TabsFranchiseManagerCP franchiseCodes={selectedFranchiseCodes}/>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
