import { Button, Tooltip } from 'antd'
import { ButtonType } from 'antd/lib/button'
import React, { CSSProperties, useState } from 'react'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'

interface IButtonCPProps {
    label?: string | JSX.Element
    type?: ButtonType
    action: () => void
    loading?: boolean
    disabled?: boolean
    style?: CSSProperties
    icon?: string | JSX.Element
    showConfirmDialog?: boolean
    showPopover?: boolean
    popoverTitle?: string
    confirmTitle?: string
}

/**
 * Componente Wrapper de Botao
 * @param {IButtonCPProps} props
 *
 * @author renatofs
 */
export function ButtonCPOLD(props: IButtonCPProps) {

    const [hasConfirmed, setHasConfirmed] = useState<any>()

    return (
        <>
            {
                !!props.showConfirmDialog &&
                <PopConfirmCP title={props.confirmTitle ?? ''} onConfirm={() => setHasConfirmed(props.action)} disabled={props.disabled}>
                    <ButtonSCP
                        type={props.type}
                        style={props.style}
                        onClick={hasConfirmed}
                        loading={props.loading}
                        disabled={props.disabled}
                    >
                        {props.label}
                        {props.icon}
                    </ButtonSCP>
                </PopConfirmCP>
            }
            {
                !!props.showPopover &&
                <Tooltip title={props.popoverTitle}>
                    <ButtonSCP
                        type={props.type}
                        style={props.style}
                        onClick={props.action}
                        loading={props.loading}
                        disabled={props.disabled}
                    >
                        {props.label}
                        {props.icon}
                    </ButtonSCP>
                </Tooltip>

            }
            {
                !props.showPopover && !props.showConfirmDialog &&
                <ButtonSCP
                    type={props.type}
                    style={props.style}
                    onClick={props.action}
                    loading={props.loading}
                    disabled={props.disabled}
                >
                    {props.label}
                    {props.icon}
                </ButtonSCP>
            }
        </>
    )

}

const ButtonSCP = styled(Button) <{ type?: ButtonType }>`
        &.ant-btn{
            border: none;
            padding: 0 10px;
            min-height: 40px;     
            background: ${(props: StyledComponentProps) => props.theme.secondaryColor};  
            color: #fff;
            &:hover, &:active, &:focus{
                background: ${(props: StyledComponentProps) => props.theme.secondaryColor};  
                color: #fff;
            }
            span{
                margin: 0 5px;
            }
            &.ant-btn-danger:not([disabled]) {
                background: ${(props: StyledComponentProps) => props.theme.dangerColor};
                background-color: ${(props: StyledComponentProps) => props.theme.dangerColor};
                border-color: ${(props: StyledComponentProps) => props.theme.dangerColor};
            }
        }
       
        &.ant-btn-primary {
            background-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
            border: none;
            transition: background-color 0.20s ease-in;
            :disabled {
                background: ${(props: StyledComponentProps) => props.theme.lightGray};
                color: ${(props: StyledComponentProps) => props.theme.gray};
                border: none;
            }
        }
        &.ant-btn-primary:hover:not([disabled]), &.ant-btn-primary:focus:not([disabled]) {
            background: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
           &.ant-btn-icon-only {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.ant-btn-ghost {
            border-color:${(props: StyledComponentProps) => props.theme.gray};
            background: ${(props: StyledComponentProps) => props.theme.gray};  
        }
        &.ant-btn .anticon {
            display: flex;
            align-items: center;
        }
        &.ant-btn-loading {

            display: flex;
            align-items: center;

            .anticon-loading {
                color: inherit;
                margin-right: 7px;
            }
        }
`
