import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FormFormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/inner/FormFormQuestionResponseDTO'
import { FormQuestionAnswerSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/form-answers/request/inner/FormQuestionAnswerSaveRequestDTO'
import { ColumnCP, ColumnSizeTP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { Checkbox } from 'antd'
import { Radio } from 'antd';
import styled from 'styled-components'

interface ICPProps {
    questions: FormFormQuestionResponseDTO[]
    answers: FormQuestionAnswerSaveRequestDTO[]
    appearance?: {
        fieldColumnSize?: ColumnSizeTP
    }
    tipoTexto?:string
}

/**
 * Componente wrapper para conteúdo do drawer de detalhes do cliente
 */
export function FormSessionFieldICP(props: ICPProps): JSX.Element {
    const multiplo = Object.create(null);

    function mudouCheck(v:any,cur:any,loa:any){
        if(v.target.checked){
            multiplo[v.target.value.ordem+'-'+v.target.value.id] = v.target.value.resposta; 
        }else{
            delete multiplo[v.target.value.ordem+'-'+v.target.value.id];
        }
        const novoValor = Object.values(multiplo).join(',');
        onChangeValue(cur.code, novoValor, loa?.sessionAnswerCode)
    }
    /**
     * Ao digitar valor no campo.
     */
    function onChangeValue(formFormQuestionCode: number, newVal: string, sessionAnswerCode?: number): void {

        const currFormAnswer = props.answers?.find((currentFormAnswer) => currentFormAnswer.formFormQuestionCode === formFormQuestionCode)

        if (!!currFormAnswer) {
            currFormAnswer.sessionAnswerValue = newVal
            return
        }

        props.answers.push({
            formFormQuestionCode,
            sessionAnswerValue: newVal,
            sessionAnswerCode
        })
    }

    return (
        <RowCP>
            {
                props.questions.map((currentFormFormQuestion) => {
                    const loadedFormAnswer = props.answers?.find((formQuestion) => formQuestion.formFormQuestionCode === currentFormFormQuestion.code)
                    return (
                        <ColumnCP size={props.appearance?.fieldColumnSize ?? 12}>
                            {currentFormFormQuestion.answerType=='OPEN' && <InputCP
                                type={props.tipoTexto == 'normal'?'text':'textArea'}
                                key={`key-${currentFormFormQuestion.code}`}
                                value={loadedFormAnswer?.sessionAnswerValue}
                                label={currentFormFormQuestion.questionTitle}
                                onChange={(newVal) => onChangeValue(currentFormFormQuestion.code, newVal, loadedFormAnswer?.sessionAnswerCode)}
                            />}
                            {
                                currentFormFormQuestion.answerType=='MULTIPLE_OPTION' && 
                                <ItemQ>
                                    <div>
                                        {currentFormFormQuestion.questionTitle}
                                    </div>
                                    {
                                        currentFormFormQuestion?.alternativas?.respostas.map((resp:any)=>{
                                            return <Checkbox onChange={(v:any)=>{
                                                mudouCheck(v,currentFormFormQuestion,loadedFormAnswer);
                                            }
                                            } key={`k-${resp.resposta}`} value={resp}>{resp.resposta}</Checkbox>
                                        })
                                    }
                                   
                                </ItemQ>
                            }

{                               // @ts-ignore
                                currentFormFormQuestion.answerType=='UNIQUE_OPTION' && 
                                <ItemQ>
                                    <div>
                                        {currentFormFormQuestion.questionTitle}
                                    </div>
                                    <Radio.Group onChange={(newVal) => onChangeValue(currentFormFormQuestion.code, newVal?.target?.value?.resposta, loadedFormAnswer?.sessionAnswerCode)}>
                                        {
                                            currentFormFormQuestion?.alternativas?.respostas.map((resp:any)=>{
                                                return <Radio key={`k-${resp.resposta}`} value={resp}>{resp.resposta}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                   
                                </ItemQ>
                            }


                        </ColumnCP>
                    )
                })
            }
        </RowCP>
    )
}


const ItemQ = styled.div`
    height: 72px;
`