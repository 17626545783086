import { StyledComponentProps } from 'main/config/theme/Theme'
import { InputTextAreaEditableCP } from 'main/common/components/input-text-area-editable/InputTextAreaEditableCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { AppointmentDetailsHeaderCP } from 'main/modules/scheduler/components/appointment-details-header/AppointmentDetailsHeaderCP'
import { AppointmentsDetailsTableCP } from 'main/modules/scheduler/components/appointments-details-table/AppointmentsDetailsTableCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IAppointmentSessionDetailsData } from 'main/modules/scheduler/components/drawer-appointment-session-details/inner/IAppointmentSessionDetailsData'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ISaveCommentRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISaveCommentRequestDTO'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { SessionUtils } from 'main/modules/scheduler/utils/SessionUtils'
import { NotesCP } from 'main/modules/app/components/notes/NotesCP'
import { StopWatchAttendanceCP } from 'main/modules/scheduler/components/stop-watch-attendance/StopWatchAttendanceCP'
import { AppointmentDateDetailsICP } from 'main/modules/scheduler/components/appointment-details/inner/AppointmentDateDetailsICP'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { AnamnesesPerson } from 'main/modules/people/components/grupo-anamneses/AnamnesesPerson'
import { Restantes } from 'main/modules/people/components/sessoes-restantes/Restantes'
// import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
// import { FlexCP } from 'main/common/components/flex/FlexCP'

interface IAppointmentDetailsCPProps {
    appointmentDetailsData: IAppointmentSessionDetailsData
    onAnswerSession: (session: ISessionResponseDTO) => void
    onSelectSale: (saleCode: number) => void
    onPresenceConfirmation: () => void
    onCancelSession: (existsRemainingSession: boolean) => void
    attendanceStopWatch: {
        attendanceStartTime?: Date
        onStartAttendance: () => void
        onFinishAttendance: () => void
    }
    sessions: ISessionResponseDTO[]
    onChangeSessions: (sessions: ISessionResponseDTO[]) => void
}

/**
 * Detalhes de um agendamento
 */
export function AppointmentDetailsCP(props: IAppointmentDetailsCPProps): JSX.Element {
    const [visaoAnamnese,setVisaoAnamnese] = useState(false);
    const [isTextAreaInitialVisible, setIsTextAreaInitialVisible] = useState<boolean>(false)

    const saveCommentRequest = useRequest()
    useEffect(onSaveCommentRequestChange, [saveCommentRequest.isAwaiting])

    useEffect(changeTextAreaVisible, [props.appointmentDetailsData])

    /**
     * Reseta o estado do componente sempre que mudar o agendamento. Evita bugs
     */
    function changeTextAreaVisible(): void {
        setIsTextAreaInitialVisible(false)
    }

    // function mostrarAnamnese(){
    //     console.log("Dados ",props.appointmentDetailsData.customer);
    //     setVisaoAnamnese(true);
    // }

    /**
     * Salva o comentario.
     */
    function onConfirmComment(valueEntered: string): void {

        props.appointmentDetailsData.observation = valueEntered

        const dto: ISaveCommentRequestDTO = {
            sessionCodes: props.appointmentDetailsData.sessionCodes,
            comment: valueEntered
        }
        saveCommentRequest.runRequest(SchedulerRequests.saveComment(dto))
    }

    /**
     * Retorno ao salvar um comentario.
     */
    function onSaveCommentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveCommentRequest, 'Erro salvar comentário'))
            return

        NotificationHelper.success('Pronto!', 'Comentário salvo com sucesso!')
    }

    /**
     * Evento ao cancelar uma unica sessao
     */
    function onCancelSession(rowIndex: number): void {

        // Remove a sessao do array
        const sessionsUpdated = [...props.sessions]
        sessionsUpdated.splice(rowIndex, 1)
        props.onChangeSessions(sessionsUpdated)

        props.onCancelSession(!SystemUtils.isEmpty(sessionsUpdated))
    }

    return (
        <>
            <Restantes codigoPessoa={props.appointmentDetailsData.customer.code}></Restantes>
            <AppointmentDetailsHeaderCP
                customer={props.appointmentDetailsData.customer}
                attendantName={props.appointmentDetailsData.attendantName}
                marginBottom={30}
            />

                <DrawerCP
                    title={'Histórico de Anamneses'}
                    visible={visaoAnamnese}
                    onClose={() => setVisaoAnamnese(false)}
                    width={740}
                    >
                        <AnamnesesPerson entityCode={props.appointmentDetailsData.customer.code} />
                </DrawerCP>
                    
                    {/* <FlexCP justifyContent={'flex-end'}>
                        <TextCP
                            onClick={mostrarAnamnese}
                            text={'Registro Completo de Anamneses do Cliente'}
                            style={{ textDecoration: 'underline', color: ThemeAnt.primaryColor, cursor: 'pointer' }}
                        />
                    </FlexCP> */}

            <AppointmentDateDetailsICP
                appointmentDetailsData={props.appointmentDetailsData}
                onPresenceConfirmation={props.onPresenceConfirmation}
            />

                
            <AlertCP
                message={'As sessões não foram evoluídas ou cliente não compareceu!'}
                type={'warning'}
                show={props.appointmentDetailsData.sessionStatus === SessionStatusEnum.SCHEDULED && !SessionUtils.isScheduleInFuture(props.appointmentDetailsData.endDate)}
                showIcon={true}
                marginTop={20}
            />

            <AppointmentsDetailsTableWrapperSCP>
                <AppointmentsDetailsTableCP
                    sessions={props.sessions}
                    onAnswerSession={props.onAnswerSession}
                    onCancelSession={onCancelSession}
                    onSelectSale={props.onSelectSale}
                    hideAnswerAction={!props.attendanceStopWatch.attendanceStartTime}
                />
            </AppointmentsDetailsTableWrapperSCP>

            <StopWatchAttendanceCP
                status={props.appointmentDetailsData.sessionStatus}
                sessionCodes={props.sessions.map((session) => session.code)}
                onStartAttendance={props.attendanceStopWatch.onStartAttendance}
                onFinishAttendance={props.attendanceStopWatch.onFinishAttendance}
            />

            <NoteDetailsSCP>
                <TextCP text={'Observações da Sessão'} size={'large'}/>
                <InputTextAreaEditableCP
                    value={props.appointmentDetailsData.observation}
                    isTextAreaInitialVisible={isTextAreaInitialVisible}
                    changeTextAreaVisibleCallback={(visible: boolean) => setIsTextAreaInitialVisible(visible)}
                    onConfirm={(valueEntered: string) => onConfirmComment(valueEntered)}
                    emptyValueLabel={'Adicionar comentário'}
                    rows={4}
                />
            </NoteDetailsSCP>

            <NotesCP
                title={<TextCP text={'Notas do Cliente:'} size={'large'}/>}
                notes={props.appointmentDetailsData.customer.note}
            />
        </>
    )
}

const NoteDetailsSCP = styled.div`
  && {
    color: ${(props: StyledComponentProps) => props.theme.darkGray};
    font-family: ${(props: StyledComponentProps) => props.theme.textFontFamily};
    font-size: ${(props: StyledComponentProps) => props.theme.fontSizes.small};
    padding-bottom: 10px;
    padding-top: 10px;
  }
`

const AppointmentsDetailsTableWrapperSCP = styled.div`
  padding: 28px 8px 28px 8px;
`
