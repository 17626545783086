import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
    icon: JSX.Element
    text: string
}

/**
 * Forma de exibir icones na legenda.
 */
export function CalendarEventsSubtiteIconICP(props: ICPProps): JSX.Element {

    return (
        <FlexCP alignItems={'center'} margin={{bottom: 5}}>
            {props.icon}
            <TextSCP>{props.text}</TextSCP>
        </FlexCP>
    )
}

const TextSCP = styled.i`
  white-space: nowrap;
  margin-right: 15px;
  margin-left: 5px;
`
