import React from 'react'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetTotalFollowupsOpenCP } from 'main/modules/dashboard/components/widget-total-followups-open/WidgetTotalFollowupsOpenCP'
import styled from 'styled-components'
import { WidgetTotalFollowupsTodayCP } from 'main/modules/dashboard/components/widget-total-followups-today/WidgetTotalFollowupsTodayCP'
import { WidgetTotalFollowupsDelayedCP } from 'main/modules/dashboard/components/widget-total-followups-delayed/WidgetTotalFollowupsDelayedCP'
import { WidgetTotalFollowupsWithoutActivityCP } from 'main/modules/dashboard/components/widget-total-followups-without-activities/WidgetTotalFollowupsWithoutActivityCP'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Linha com performance atual das oportunidades em followup
 */
export function WidgetsRowActualFollowupIndicatorsCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'filter'}>
                Status atual dos Follow-ups a serem realizados
            </TitleCP>
            <WidgetsRowCP>
                <WidgetTotalFollowupsOpenCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
                <WidgetTotalFollowupsTodayCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
                <WidgetTotalFollowupsDelayedCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
                <WidgetTotalFollowupsWithoutActivityCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
            </WidgetsRowCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 50px;
`
