/* eslint-disable */
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

export interface IAutoCompleteFilterDropdownICPProps<ResDtoTP> {
    searchReqConfigGetter: (dto?: any) => RequestConfigTP<ResDtoTP>
    reqDto?: ResDtoTP
    placeholder: string
    onChange: (value: any) => void
    onReset: () => void
}

/**
 * Dropdown com filtro estilo
 * @author kiara.vz
 */
export function AutoCompleteFilterDropdownICP<ResDtoTP = any>(props: IAutoCompleteFilterDropdownICPProps<ResDtoTP>): JSX.Element {

    const [options, setOptions] = useState<SelectOptionTP[]>([])

    const searchRequest = useRequest<IGenericListResponseDTO<ResDtoTP>>()

    useEffect(getOptions, [])
    useEffect(onSearchRequestChange, [searchRequest.isAwaiting])

    function getOptions(): void{
        if (!props.searchReqConfigGetter)
            return
        searchRequest.runRequest(props.searchReqConfigGetter(props.reqDto))
    }

    function onSearchRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(searchRequest, 'Erro ao buscar dados do filtro'))
            return

        const opt = searchRequest.responseData!.list.map((item: any) => ({ value: item.code, key: `${item.code}`, label: `${item.name}` }))
        setOptions([...opt])
    }

    return (
        <FilterDropdownWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                options={options}
                onChange={props.onChange}
            />
            <ButtonCP
                onClick={props.onReset}
            >
                Limpar
            </ButtonCP>
        </FilterDropdownWrapperSCP>
    )
}

const FilterDropdownWrapperSCP = styled.div`
    padding: 10px 20px;
    min-width: 280px;   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
