import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'

interface ISaleSourcesSyntheticReportWrapperICPProps {
    chartCP: JSX.Element
    tableCP: JSX.Element
    totalFranchises: number
}

/**
 * Wrapper para exibir o grafico e o relario, gerencia em que posicao da tela exibe baseado no numero de franquias.
 */
export function SaleSourcesSyntheticReportWrapperICP(props: ISaleSourcesSyntheticReportWrapperICPProps): JSX.Element {

    if (props.totalFranchises < 3) {
        return (
            <FlexCP justifyContent={'center'} flexWrap={'wrap'}   marginBottom={20}>
                <>{props.chartCP}</>
                <>{props.tableCP}</>
            </FlexCP>
        )
    }

    return (
        <FlexCP justifyContent={'flex-start'} flexWrap={'wrap'}  marginBottom={20}>
            <>{ props.chartCP }</>
            <>{ props.tableCP }</>
        </FlexCP>
    )
}
