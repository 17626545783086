import React from 'react'
import styled from 'styled-components'
import CheckboxGroup, { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { StyledComponentProps } from 'main/config/theme/Theme'

export interface ICheckboxGroupCPProps {
    options: CheckboxOptionType[]
    value?: any
    onChange: (value: any) => void
    disabled?: boolean
    name?: string
}

/**
 * Checkbox group generico
 * @param {ICheckboxGroupCPProps}props
 * @author kiara.vz
 */
export function CheckboxGroupCP(props: ICheckboxGroupCPProps): JSX.Element {
    return (
        <CheckboxGroupSCP
            options={props.options}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            name={props.name}
        />
    )
}

const CheckboxGroupSCP = styled(CheckboxGroup)`
    .ant-checkbox:hover .ant-checkbox-inner,  .ant-checkbox-wrapper:hover .ant-checkbox-inner{ 
     border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
    }
    
    span{
        font-size: 12px;
        color: ${(props: StyledComponentProps) => props.theme.darkGray};
    }
    .ant-checkbox-checked::after,   .ant-checkbox-input:focus + .ant-checkbox-inner{
        border: 1px solid ${(props: StyledComponentProps) => props.theme.primaryColor};
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
    }
   
`
