import React from 'react'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { AutocompleteValueTP } from 'main/common/components/form-fields/autocomplete-picker/inner/AutocompleteValueTP'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/types/InputValueCallbackTP'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { TagAutocompletePickerCP } from 'main/modules/tags/components/tag-autocomplete-picker/TagAutocompletePickerCP'
import { ProductReleaseAutocompletePickerCP } from 'main/modules/products/components/product-release-autocomplete-picker/ProductReleaseAutocompletePickerCP'

interface IUnitValueFieldAutocompleteICPCPProps {
    fieldLabel: string
    onChange: InputValueCallbackTP
    valueForInput: AutocompleteValueTP
    width: number
    isMultiple: boolean
    valueWithLabel: Array<SegmentUnitValueWithLabelTP<true>>
}

/**
 * COMPONENTE
 * Select para determinacao de 01 campo do tipo autocomplete para compor o valor de 01 unidade.
 */
export function UnitValueFieldAutocompleteICP(props: IUnitValueFieldAutocompleteICPCPProps): JSX.Element | null {

    const unitContext = UnitContext.use()

    if (!SegmentUtils.isUnitSelected(unitContext.state.field, unitContext.state.operator))
        return null

    const field = unitContext.state.field!

    const valueForAutocomplete = props.valueWithLabel
        .filter(singleValue => !!singleValue)
        .map(singleValue => ({
            code: singleValue!.value,
            name: singleValue!.label
        } as INameAndCodeResponseDTO))

    if (field === SegmentUnitFieldsEnum.CREATOR_USER_CODE) {
        return (
            <AutocompletePersonCP
                label={props.fieldLabel}
                isMultiple={props.isMultiple}
                onChange={props.onChange}
                returnFullOption={true}
                width={props.width}
                value={props.valueForInput}
                initialOptions={valueForAutocomplete}
                noValidation={true}
                considerUser={true}
            />
        )
    }

    if (field === SegmentUnitFieldsEnum.TAGS) {
        return (
            <TagAutocompletePickerCP
                isMultiple={props.isMultiple}
                onChange={props.onChange}
                returnFullOption={true}
                width={props.width}
                value={props.valueForInput}
                initialOptions={valueForAutocomplete}
                noValidation={true}
            />
        )
    }

    if (SegmentUtils.isProductField(field)) {
        return (
            <ProductReleaseAutocompletePickerCP
                isMultiple={props.isMultiple}
                onChange={props.onChange}
                returnFullOption={true}
                width={props.width}
                value={props.valueForInput}
                initialOptions={valueForAutocomplete}
                noValidation={true}
            />
        )
    }

    return null
}
