import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'

export class BankAccountWithdrawFormModel extends FormModel {

    @IsNotEmpty()
    date: Date

    @IsNotEmpty()
    value: string

    @IsNotEmpty()
    bankAccountSourceCode: number

    @IsNotEmpty()
    bankAccountTargetCode: number

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
