import React from 'react'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { OrArrayTP } from 'main/common/types/OrArrayTP'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/types/InputValueCallbackTP'
import { MonthEnum } from 'main/common/enums/MonthEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ISelectCPProps, SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'

type ValueTP = OrArrayTP<number>

interface IDayOfMonthPickerCPProps<FModelTP extends FormModel = any> extends Omit<ISelectCPProps<FModelTP>, 'options'> {
    value?: ValueTP
    onChange?: InputValueCallbackTP<ValueTP>
    month?: MonthEnum
    year?: number
}

/**
 * COMPONENTE
 * Seletor de dia(s) de 01 mes.
 */
export function DayOfMonthPickerCP<FModelTP extends FormModel = any>(props: IDayOfMonthPickerCPProps): JSX.Element {

    const maxDay = !!props.month ? DateUtils.getLastDayOfMonth(props.month, props.year) : 31

    function onChange(nextValue?: ValueTP): void {
        if (nextValue)
            props.onChange?.(props.isMultiple ? (nextValue as number[]).map(Number) : +nextValue)
    }

    return (
        <SelectCP<FModelTP>
            options={ArrayUtils.getRange(maxDay, 1).map(String)}
            selectedOptions={props.selectedOptions}
            loading={props.loading}
            onSearch={props.onSearch}
            onChange={onChange}
            onFormSubmit={props.onFormSubmit}
            isMultiple={props.isMultiple}
            returnFullOption={props.returnFullOption}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            label={props.label}
            required={props.required}
            width={props.width}
            value={props.value}
            fontSize={props.fontSize}
            disabled={props.disabled}
            notFoundContent={props.notFoundContent}
            onBlur={props.onBlur}
            allowClear={props.allowClear}
        />
    )
}
