import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import moment from 'moment'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { StopWatchCP } from 'submodules/nerit-framework-ui/common/components/clock/StopWatchCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

interface ISessionFormModalHeaderICPProps {
    session: ISessionResponseDTO
    onShowHistoryClick: () => void
    isHistoryVisible: boolean
    initTime?: Date
}

/**
 * Header do modal de evoluir sessoes.
 */
export function SessionFormModalHeaderICP(props: ISessionFormModalHeaderICPProps): JSX.Element {

    return (
        <>
            <FlexCP justifyContent={'space-between'}>
                <StopWatchCP
                    initialTimeInMS={props.initTime ? DateUtils.getDiff(TimeBaseEnum.SECOND, props.initTime) * 1000 : 0}
                    shouldStart={true}
                    reset={props.session.code}
                    appearance={{
                        hideSeconds: true,
                        actionButtonMode: 'none',
                    }}
                    width={200}
                    fontSize={45}
                />
                <HeaderSCP>
                    <TextCP
                        icon={'thunderbolt'}
                        text={`${props.session.treatment.name} (${props.session.sessionNumber} de ${props.session.totalSessions})`}
                    />
                    <ConditionalRenderCP shouldRender={!!props.session.schedule}>
                        <TextCP
                            icon={'schedule'}
                            text={
                                `${DateUtilsOLD.getFormatted(moment(props.session.schedule!.beginDate).toDate(), DateFormatEnum.DATE_WRITTEN_IN_FULL)} 
                        às 
                        ${DateUtilsOLD.getFormatted(moment(props.session.schedule!.endDate).toDate(), DateFormatEnum.TIME_H_M)}`
                            }
                        />
                    </ConditionalRenderCP>
                </HeaderSCP>
            </FlexCP>

            <FlexCP justifyContent={'flex-end'}>
                <ButtonCP
                    onClick={props.onShowHistoryClick}
                    type={'link'}
                >
                    {`${props.isHistoryVisible ? 'Esconder' : 'Ver'} Histórico`}
                </ButtonCP>
            </FlexCP>
        </>
    )
}

const HeaderSCP = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  flex: 1;
  margin-left: 10px;
  border: 2px dashed ${(props: StyledComponentProps) => props.theme.gray};
  border-radius: 8px;
  padding: 8px;
`
