import { SegmentExpForEditionTP } from 'main/modules/segment/types/expression/SegmentExpForEditionTP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { ContextFactory } from 'main/common/context-factory/ContextFactory'
import { ExpressionUnitListUtils } from 'main/modules/segment/components/segment-expression-bar/inner/ExpressionUnitListUtils'
import { SegmentExpUnitForEditionTP } from 'main/modules/segment/types/expression/SegmentExpUnitForEditionTP'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'

type ActionTypeTP =
    | 'setSegmentType' | 'setIsLoading' | 'setHoveredAggregator'
    | 'initUnitList' | 'addUnit' | 'editUnit' | 'removeUnit'

type StateTP = {
    segmentType: SegmentTypeEnum,
    expression: SegmentExpForEditionTP, // Resultado parcial atual da expressao em edicao
    isLoading: boolean,
    hoveredAggregatorIdx?: number, // Indice do agregador sobre o qual o mouse esta passando no momento
}

const INITIAL_STATE: StateTP = {
    segmentType: SegmentTypeEnum.CUSTOMER,
    expression: [],
    isLoading: false,
}

const actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP> = actionHelper => ({

    setSegmentType: (segmentType: SegmentTypeEnum) => actionHelper.replaceWithPayload({ segmentType }),
    setIsLoading: (isLoading: boolean) => actionHelper.replaceWithPayload({ isLoading }),
    setHoveredAggregator: (i?: number) => actionHelper.replaceWithPayload({ hoveredAggregatorIdx: i }),

    addUnit: () => actionHelper.replaceWithPayload({ expression: ExpressionUnitListUtils.addUnit(actionHelper.state.expression) }),
    removeUnit: (id: string) => actionHelper.replaceWithPayload({ expression: ExpressionUnitListUtils.removeUnit(actionHelper.state.expression, id) }),

    initUnitList: (units: SegmentExpForEditionTP) => actionHelper.replaceWithPayload({
        expression: ExpressionUnitListUtils.init(actionHelper.state.expression, units)
    }),

    editUnit: (nextValue: SegmentExpUnitForEditionTP) => actionHelper.replaceWithPayload({
        expression: ExpressionUnitListUtils.editUnit(actionHelper.state.expression, nextValue)
    }),
})

/**
 * CONTEXTO: Expressao de lista de segmentacao
 * Provider + Hook para acesso ao contexto de estado global de 01 expressao de lista de segmentacao em edicao.
 *
 * @see SegmentExpressionBarCP
 */
export const ExpressionContext = ContextFactory.getOne<StateTP, ActionTypeTP>({
    contextName: 'Espressao de Lista de Segmentacao',
    initialStateGetter: () => INITIAL_STATE,
    actionBuilder,
})
