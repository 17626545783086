import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IFinancialCategorySearchRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategorySearchRequestDTO'
import { IFinancialCategoryNamesSearchRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategoryNamesSearchRequestDTO'
import { IFinancialCategoryUpdateBulkRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategoryUpdateBulkRequestDTO'
import { IFinancialCategorySaveRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategorySaveRequestDTO'
import { IFinancialCategoryUpdateRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategoryUpdateRequestDTO'

/**
 * Requests de plano de contas
 */
export class FinancialCategoryRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/financial-categories`

    static findOne = (code: number): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static search = (dto: IFinancialCategorySearchRequestDTO): RequestConfigTP => ({
        url: FinancialCategoryRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static names = (dto: IFinancialCategoryNamesSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static reorderCategories = (dto: IFinancialCategoryUpdateBulkRequestDTO): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}/update-bulk`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static create = (dto: IFinancialCategorySaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IFinancialCategoryUpdateRequestDTO): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${FinancialCategoryRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
