import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

export class ChargeRetryFormModel extends FormModel {

    @IsRequired()
    paymentMethod: PaymentMethodEnum.PIX | PaymentMethodEnum.BANK_BILLET | PaymentMethodEnum.CREDIT_CARD_RECURRENT

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
