import { TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'

/**
 * Utilitarios gerais do componente de tabela
 */
export const TableUtils = {

    getDefaultPagination(pageSize?: number): TablePaginationTP {
        return {
            current: 1,
            pageSize: pageSize ?? 50,
            total: 0,
            showTotal: (total, range) => `Mostrando ${range.join('-')} de ${total}`
        }
    },

    removePaginationAttributes(filterDto: any): any {

        delete filterDto.itemsPerPage
        delete filterDto.page

        return filterDto
    },

}
