import React from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    children: React.ReactNode
    loading?: boolean
}

/**
 * Wrappper para uma linha de widgets.
 */
export function WidgetsRowCP(props: ICPProps): JSX.Element {

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <WidgetsContentSCP>
                <LoadingOverlayCP show={props.loading ?? false}/>

                {props.children}
            </WidgetsContentSCP>
        </BasicStyleWrapperCP>
    )
}

const WidgetsContentSCP = styled.div`

  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
  .card-dashboard-widget {
    flex: 1;
  }
`
