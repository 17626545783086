import { validate, ValidationError } from 'class-validator'
import { IFormValidator } from './IFormValidator'

/**
 * VALIDADOR
 * Classe base a ser extendida para criar validadores de campos de
 * para formulario.
 *
 * @author hjcostabr
 */
export abstract class GenericFormValidator implements IFormValidator {

    protected constructor(initialData?: any) {
        if (!!initialData)
            Object.assign(this, initialData)
    }

    /**
     * @inheritDoc
     */
    public async validate(): Promise<ValidationError[]> {
        return validate(this)
    }
}
