import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from 'main/common/validation/ValidationTypes'

/**
 * DECORATOR de VALIDACAO
 * Valida SE valor informado eh 01 numero.
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export function IsNumber(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsNumberConstraint,
        })
    }
}

@ValidatorConstraint({ name: ValidationTypes.IS_NUMBER })
class IsNumberConstraint implements ValidatorConstraintInterface {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validate(value: string, args: ValidationArguments): boolean {
        const validator = new Validator()
        return validator.isNumber(value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defaultMessage(args: ValidationArguments): string {
        return 'Deve ser um número'
    }
}
