import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { SystemConfig } from 'main/config/SystemConfig';
import React, { useEffect, useState } from 'react'
import * as One from "common/utils/One"
import { TableCP } from 'main/common/components/table/TableCP';
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP';
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP';
import { FlexCP } from 'main/common/components/flex/FlexCP';
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { ModalCP } from 'main/common/components/modal/ModalCP';
import { InputCP } from 'main/common/components/form-fields/input/InputCP';
import { NotificationHelper } from 'main/common/helpers/NotificationHelper';
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP';
import { Radio } from 'antd';
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP';
import styled from 'styled-components';


const DIC = {
    'OPEN':'ABERTA',
    'UNIQUE_OPTION':'OPCAO_UNICA',
    'MULTIPLE_OPTION':'OPCAO_MULTIPLA'
}



export function GerenciaPerguntas(): JSX.Element {
    console.log(DIC);
    const [limpeza, setLimpeza] = useState(true);
    const [perguntas,setPerguntas] = useState(null);
    const [valido,setValido] = useState(false);
    const [novaResposta,setNovaResposta] = useState<any>(null);
    const [carregando,setCarregando] = useState(false);
    const [editando,setEditando] = useState(false);
    const [perguntaEditando, setPerguntaEditando] = useState<any>(null);
    const [perguntaNova, setPerguntaNova] = useState<any>(null);
    const [criando,setCriando] = useState(false);
    useEffect(()=>{carregarPerguntas()},[]);

    const colunasPerguntas = [
        {
          title: '#',
          dataIndex: 'fqu_code',
          key: 'fqu_code',
        },
        {
          title: 'Pergunta',
          dataIndex: 'fqu_title',
          key: 'fqu_title',
         
        },
        {
          title: 'Tipo',
          dataIndex: 'fqu_answertype',
          key: 'fqu_answertype',
          render:(text:any,item:any)=>DIC[text]
         
        },
        {
            title:'Ações',
            key: 'actions',
            render: (text:any, item:any) => (
                <FlexCP justifyContent={'flex-end'}>
                    <ButtonCP
                        type={'primary'}
                        icon={'edit'}
                        size={'small'}
                        onClick={() =>onEdit(item)}
                    />
                </FlexCP>
            )
        }
      ];

    function onEdit(it:any){
        setEditando(true);
        setPerguntaEditando(JSON.parse(JSON.stringify(it)));
        setValido(true);
        console.log("Editando ", it);
    }

    function clone(v:any){
        return JSON.parse(JSON.stringify(v));
    }

    function eValido(perg:any){
        if(!perg || !perg.fqu_title){
            setValido(false);
            return;
        }

        if(!perg['fqu_answertype']){
            setValido(false);
            return;
        }

        if(perg['fqu_answertype']!=='OPEN' && (!perg['alternativas'] || perg.alternativas.respostas.length<2 )){
            setValido(false);
            return;
        }
        setValido(true);
    }

    async function salvarEdicaoPergunta(){
        console.log("Salvando edicação ", perguntaEditando)
        eValido(perguntaEditando);

        const parametros = { url: `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms/perguntasUpdate`,
        method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getUserFranchisor()?.code,
                schema:AppStateUtils.getDomainSlug(),
                pergunta:perguntaEditando.fqu_title,
                tipo:perguntaEditando.fqu_answertype,
                codigoPergunta:perguntaEditando.fqu_code,
                alternativas:JSON.stringify(perguntaEditando.alternativas)
            }
        }
        const resultado:any = await RequestHelper.runRequest(parametros).finally(()=>{});
        if(One.ok(resultado)){
            NotificationHelper.success("YES, aleterações salvas com sucesso!");
            carregarPerguntas();
            return;
        }
        NotificationHelper.error('Houve algum problema ao tentar salvar a pergunta');
    }

    function novaPergunta(){
        setPerguntaNova({fqu_title:'',fqu_answertype:'OPEN'});
        setValido(false);
        setCriando(true);
    }

    async function salvarNovaPergunta(){
        console.log("Salvando Nova Pergunta ", perguntaNova)
        eValido(perguntaNova);

        const parametros = { url: `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms/perguntasInserir`,
        method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getUserFranchisor()?.code,
                schema:AppStateUtils.getDomainSlug(),
                pergunta:perguntaNova.fqu_title,
                alternativas:perguntaNova['alternativas']?JSON.stringify(perguntaNova.alternativas):null,
                tipo:perguntaNova.fqu_answertype,
                codigoPergunta:null
            }
        }
        const resultado:any = await RequestHelper.runRequest(parametros).finally(()=>{});
        if(One.ok(resultado)){
            NotificationHelper.success("YES, pergunta inserida com sucesso!");
            carregarPerguntas();
            return;
        }
        NotificationHelper.error('Houve algum problema ao tentar salvar a pergunta');
    }

    async function carregarPerguntas(){
        setCarregando(true);
        const parametros = { url: `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms/perguntas`,
        method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getUserFranchisor()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
        }

        console.log("Parametros ", parametros);

        const resultado:any = await RequestHelper.runRequest(parametros).finally(()=>{});
        console.log("Resultado vindo ", resultado);
        if(One.ok(resultado)){
            console.log("Perguntas ",One.dados(resultado));
            setPerguntas(One.dados(resultado));
        }

        setCarregando(false);
        
    }

    function removerResposta(pergunta:any,id:number){
        if(pergunta?.alternativas?.respostas){
            pergunta.alternativas.respostas = pergunta.alternativas.respostas.filter((i:any)=>i.id!==id);
            setPerguntaEditando(clone(pergunta));
        }
        eValido(pergunta);
    }

    function limpar(){
        setLimpeza(false);
        setNovaResposta("");
        setTimeout(()=>{
            setLimpeza(true);
        },400);
    }

    return (
        <>
            <HeaderCP title={'Gerência de Perguntas'}>
                <HeaderButtonICP
                    onClick={novaPergunta}
                    icon={'plus'}
                    label={'Nova Pergunta'}
                />
            </HeaderCP>
            <LayoutSubmenuContentCP>
                <TableCP
                columns={colunasPerguntas}
                data={perguntas ?? []}
                loading={carregando}
                />
            </LayoutSubmenuContentCP>

            <ModalCP visible={criando}
                okText='Inserir'
                onClose={()=>{setCriando(false)}}
                destroyOnClose={true}
                onOk={salvarNovaPergunta}
                closable={false}
                noFooter={!valido}//Utilizar como validador de formulario
                title='Nova Pergunta'
            >
                <InputCP
                    label={'Pergunta'}
                    value={!!perguntaNova?perguntaNova.fqu_title:''}
                    onChange={(v)=>{
                        perguntaNova.fqu_title = v;
                        eValido(perguntaNova);
                    }}
                />

                <Radio.Group onChange={(v)=>{
                    if(!!perguntaNova){
                        perguntaNova.fqu_answertype = v.target.value;
                        if(perguntaNova.fqu_answertype=='OPEN')delete perguntaNova.alternativas;
                        setNovaResposta('');
                        setPerguntaNova(clone(perguntaNova));
                        eValido(perguntaNova);
                    }
                }}  value={!!perguntaNova?perguntaNova.fqu_answertype:'OPEN'}>
                    <Radio value={'OPEN'}>ABERTA</Radio>
                    <Radio value={'UNIQUE_OPTION'}>OPCAO_UNICA</Radio>
                    <Radio value={'MULTIPLE_OPTION'}>OPCAO_MULTIPLA</Radio>
                </Radio.Group>

                <ConditionalRenderCP  key={'unico_opcao'} shouldRender={!!perguntaNova && (perguntaNova.fqu_answertype=='UNIQUE_OPTION' || perguntaNova.fqu_answertype=='MULTIPLE_OPTION') }>
                   <FlexCP alignItems='center' justifyContent='center' >
                        {
                            limpeza && 
                            <InputCP
                                label={'Digite a Resposta'}
                                value={novaResposta}
                                allowClear={true}
                                
                                onChange={(v)=>{
                                    console.log("Mudando ", v);
                                    setNovaResposta(v)
                                    eValido(perguntaNova);
                                }}
                            />
                        }

                        <ButtonCP key={'bt_x'} icon='save' disabled={!novaResposta} type='dashed'
                         tooltip='Clique para adicionar a resposta'
                         onClick={()=>{
                            if(!perguntaNova['alternativas'])perguntaNova.alternativas = {gerador:1,id:1,respostas:[]};
                            perguntaNova.alternativas.respostas.push({id:perguntaNova.alternativas.id,ordem:perguntaNova.alternativas.gerador,resposta:clone(novaResposta)});
                            perguntaNova.alternativas.gerador++; 
                            perguntaNova.alternativas.id++; 
                            limpar();
                            eValido(perguntaNova);
                         }}
                         >ADD</ButtonCP>
                    </FlexCP>
                    <FlexCP flexDirection='column'>
                         {!perguntaNova?.alternativas?.respostas?'Cadastre pelo menos uma resposta':''}
                         {
                            perguntaNova?.alternativas?.respostas.map((r:any)=>(
                            <ItemResposta key={r.ordem+'item_resposta'}>
                                <div>
                                     <ButtonCP key={r.ordem+'btt'} onClick={()=>{
                                        removerResposta(perguntaNova,r.id);
                                     }} icon='delete'  type='dashed'>
                                        Remover
                                     </ButtonCP>
                                </div>
                                <div>
                                    {r.ordem}
                                </div>
                                <div>
                                    {r.resposta}
                                </div>
                            </ItemResposta>
                            ))
                         }
                    </FlexCP>
                </ConditionalRenderCP>
            </ModalCP>

            <ModalCP visible={editando}
                okText='Salvar Alteração'
                onClose={()=>{setEditando(false)}}
                onOk={salvarEdicaoPergunta}
                title='Edição de Pergunta'
                noFooter={!valido}
            >   
                <InputCP
                label={'Pergunta'}
                value={!!perguntaEditando?perguntaEditando.fqu_title:''}
                onChange={(v)=>{
                    perguntaEditando.fqu_title = v;
                    eValido(perguntaEditando);
                }}
                />

                <Radio.Group onChange={(v)=>{
                    if(!!perguntaEditando){
                        perguntaEditando.fqu_answertype = v.target.value;
                        if(perguntaEditando.fqu_answertype=='OPEN')delete perguntaEditando.alternativas;
                        setNovaResposta('');
                       setPerguntaEditando(clone(perguntaEditando));
                       eValido(perguntaEditando);
                    }
                }}  value={!!perguntaEditando?perguntaEditando.fqu_answertype:'OPEN'}>
                    <Radio value={'OPEN'}>ABERTA</Radio>
                    <Radio value={'UNIQUE_OPTION'}>OPCAO_UNICA</Radio>
                    <Radio value={'MULTIPLE_OPTION'}>OPCAO_MULTIPLA</Radio>
                </Radio.Group>

                <ConditionalRenderCP  key={'unico_opcao'} shouldRender={!!perguntaEditando && (perguntaEditando.fqu_answertype=='UNIQUE_OPTION' || perguntaEditando.fqu_answertype=='MULTIPLE_OPTION') }>
                   <FlexCP alignItems='center' justifyContent='center' >
                        
                        {
                            limpeza && 
                            <InputCP
                            label={'Digite a Resposta'}
                            value={novaResposta}
                            allowClear={true}
                            onChange={(v)=>{
                                console.log("Mudando ", v);
                                setNovaResposta(v);
                                eValido(perguntaEditando);
                            }}
                            />
                        }

                        <ButtonCP key={'bt_x'} icon='save' disabled={!novaResposta} type='dashed'
                         tooltip='Clique para adicionar a resposta'
                         onClick={()=>{
                            if(!perguntaEditando['alternativas'])perguntaEditando.alternativas = {gerador:1,id:1,respostas:[]};
                            perguntaEditando.alternativas.respostas.push({id:perguntaEditando.alternativas.id,ordem:perguntaEditando.alternativas.gerador,resposta:clone(novaResposta)});
                            perguntaEditando.alternativas.gerador++; 
                            perguntaEditando.alternativas.id++; 
                            limpar();
                            eValido(perguntaEditando);
                         }}
                         >ADD</ButtonCP>
                    </FlexCP>
                    <FlexCP flexDirection='column'>
                         {!perguntaEditando?.alternativas?.respostas?'Cadastre pelo menos uma resposta':''}
                         {
                            perguntaEditando?.alternativas?.respostas.map((r:any)=>(
                            <ItemResposta key={r.ordem+'item_resposta'}>
                                <div>
                                     <ButtonCP key={r.ordem+'btt'} onClick={()=>{
                                        removerResposta(perguntaEditando,r.id);
                                     }} icon='delete'  type='dashed'>
                                        Remover
                                     </ButtonCP>
                                </div>
                                <div>
                                    {r.ordem}
                                </div>
                                <div>
                                    {r.resposta}
                                </div>
                            </ItemResposta>
                            ))
                         }
                    </FlexCP>
                </ConditionalRenderCP>
                
            </ModalCP>
        </>
    )

}

const ItemResposta = styled.div`
    display:flex;
    gap:10px;
    font-weight:740;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid;
    text-transform: uppercase;
    font-size: 13px;
`