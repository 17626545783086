import React from 'react'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { ButtonBankBilletCP } from 'main/modules/sale/components/button-bank-billet/ButtonBankBilletCP'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { IChargeData } from 'main/modules/financial/services/financial-transactions/interfaces/IChargeData'

interface IButtonUniquePaymentMethodActionCPProps {
    financialTransaction: IFinancialTransactionCreditListItemResponseDTO
    onUpdateAnyData: () => void
    chargeData?: IChargeData
}

/**
 * Botao com acoes de acordo com a forma de pagamento
 */
export function ButtonUniquePaymentMethodActionCP(props: IButtonUniquePaymentMethodActionCPProps): JSX.Element {

    // Por enquanto apenas boleto tem acao de forma de pagamento para cada parcela
    if (props.financialTransaction.paymentMethod !== PaymentMethodEnum.BANK_BILLET)
        return <></>

    // Se for boleto mas tiver link na charge, o link eh unico para todas as parcelas
    if (!!props.chargeData?.chargeURL)
        return <></>

    return (
        <ButtonBankBilletCP
            financialTransaction={props.financialTransaction}
            onGenerateBankBillet={props.onUpdateAnyData}
        />
    )
}
