import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'

export class BankAccountWithdrawFiltersFormModel extends FormModel {

    franchisesCodes?: number[]
    dateRange?: IDateRangeSearchRequestDTO
    valueMin?: string
    valueMax?: string
    bankAccountSourceCode?: number
    bankAccountTargetCode?: number

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
