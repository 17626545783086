import React from 'react'
import styled from 'styled-components'
import { CardCP } from 'main/common/components/card/CardCP'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { ChartSalesFunnelConversionCardICP } from 'main/modules/reports/components/chart-sales-funnel/inner/ChartSalesFunnelConversionCardICP'
import { ISalesFunnelIndicatorsResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISalesFunnelIndicatorsResponseDTO'
import { MaskUtils } from 'main/common/utils/MaskUtils'

interface IChartSalesFunnelRightCardsICPProps {
    data: ISalesFunnelIndicatorsResponseDTO
}

/**
 * Cards laterais do funil.
 */
export function ChartSalesFunnelRightCardsICP(props: IChartSalesFunnelRightCardsICPProps): JSX.Element {

    return (
        <CardsWrapperSCP>
            <ChartSalesFunnelConversionCardICP
                header={<TitleAndTextCP title={`${MaskUtils.applyNumberMask(props.data.leadsData.total, 0)}`} text={'LEADs'} size={'large'}/>}
                conversionRate={props.data.leadsData.activitiesRate}
                footer={<TitleAndTextCP title={`${MaskUtils.applyNumberMask(props.data.leadsData.totalContacted, 0)}`} text={'contatados'}/>}
            />
            <ChartSalesFunnelConversionCardICP
                header={<TitleAndTextCP title={`${MaskUtils.applyNumberMask(props.data.evaluationData.total, 0)}`} text={'Avaliações'} size={'large'}/>}
                conversionRate={props.data.evaluationData.conversionRate}
                footer={<TitleAndTextCP title={`${MaskUtils.applyNumberMask(props.data.evaluationData.totalDone, 0)}`} text={'realizadas'}/>}
            />
            <CardCP>
                <TitleAndTextCP title={`${MaskUtils.applyNumberMask(props.data.salesData.totalValid, 0)}`} text={'Vendas'} marginBottom={5} size={'large'} align={'center'}/>
                <TitleAndTextCP title={'Valor Total:'} text={MaskUtils.applyMoneyMask(props.data.salesData.totalValidValue)} invertBold={true}/>
                <TitleAndTextCP title={'Média de Pacotes por venda:'} text={MaskUtils.applyNumberMask(props.data.salesData.productReleasePerSale, 2)} invertBold={true}/>
                <TitleAndTextCP title={'Ticket Médio:'} text={MaskUtils.applyMoneyMask(props.data.salesData.averagePrice)} invertBold={true}/>
            </CardCP>
        </CardsWrapperSCP>
    )
}

const CardsWrapperSCP = styled.div`

  margin-top: 20px;

  .ant-card {
    height: 115px;
    margin: 0 0 5px 20px;
  }

  .ant-card-body {
    padding: 5px 15px !important;
  }
`
