import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import {
    RecurrencePeriodEnum,
    RecurrencePeriodLabelEnum
} from 'main/modules/financial/services/financial-transactions/enums/RecurrencePeriodEnum'
import React from 'react'
import styled from 'styled-components'

interface IRecurrenceSelectorCPProps {
    disabled?: boolean
    required?: boolean
    label?: string
    placeholder?: string
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    onChange?: (value: RecurrencePeriodEnum) => void
    fontSize?: 'small' | 'title' | 'extraSmall' | 'normal' | 'large' | 'extraLarge' | undefined
    value?: RecurrencePeriodEnum
}

/**
 * Componente para select de RECORRENCIA
 * @see RecurrencePeriodEnum
 */
export function SelectRecurrenceCP(props: IRecurrenceSelectorCPProps): JSX.Element {
    const OPTIONS: SelectOptionTP[] = Object.values(RecurrencePeriodEnum).map((recurrence, i) => ({
        key: `key-recurrence-${i}`,
        label: RecurrencePeriodLabelEnum[recurrence],
        value: recurrence
    }))

    return (
        <SelectWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                label={props.label}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                formStateManager={props.formStateManager}
                fieldName={props.fieldName}
                fontSize={props.fontSize}
                isMultiple={false}
                options={OPTIONS}
                defaultOpen={false}
                onChange={props.onChange}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width:100%;
`
