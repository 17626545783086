import { DatePropsTP } from 'main/common/types/DatePropsTP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateComboCpOutputTP } from 'main/common/components/form-fields/date-combo/inner/DateComboCpOutputTP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'

const MIN_YEAR = 1900

/**
 * UTILITARIOS para componente.
 * @see DateComboCP
 */
export const DateComboCpUtils = {

    /** Valida preenchimento & retorna msg de erro (em caso de falha). */
    getValidationError(date?: DateComboCpOutputTP, isRequired = false): OrUndefTP<string> {

        if (DateUtils.isQuickDateValue(date))
            return

        // Data ter ao menos um campo preenchido, caso seja obrigatorio
        const hasSomeProp = (!!date && Object.values(date).some(dateValue => !!dateValue))
        if (!hasSomeProp)
            return isRequired ? 'Selecione uma data' : undefined

        // Ano deve estar entre limites inferior & superior (caso esteja preenchido)
        date = (date as DatePropsTP)

        if (date.year) {
            if (date.year < MIN_YEAR)
                return `Informe um ano maior ou igual a ${MIN_YEAR}`
            if (date.year > DateUtils.getYear(new Date()))
                return 'Informe um ano menor ou igual ao atual'
        }

        // Caso mes & dia estejam simultaneamente definidos deve-se verificar validade da combinacao
        if (!date.day || !date.month)
            return

        const auxDate: DatePropsTP = { ...date }
        if (!date.year)
            auxDate.year = DateUtils.getYear()

        if (!DateUtils.isValidDate(auxDate as Required<DatePropsTP>))
            return 'Informe uma data válida'
    },
}
