import React, { useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { PdfViewerCP } from 'main/common/components/pdf-viewer/PdfViewerCP'
import { LinkHtmlCP } from 'main/common/components/link-html/LinkHtmlCP'
import { DownloadIconCP } from 'main/common/components/icons/DownloadIconCP'

interface IContractGenerationResultContentICPProps {
    contractUrl: string
    onSendToDokfy: () => void
}

/**
 * Conteudo do modal na visao de um contrato gerado.
 */
export function ContractGenerationResultContentICP(props: IContractGenerationResultContentICPProps): JSX.Element {

    const [isPdfViewerVisible, setIsPdfViewerVisible] = useState<boolean>(false)

    return (
        <>
            <FallbackScreenContentCP
                status={'success'}
                title={'Contrato Gerado com Sucesso!'}
                message={'O que deseja fazer agora?'}
                bottomContent={
                    <ButtonsWrapperSCP>
                        <ButtonCP
                            type={'ghost'}
                            icon={'eye'}
                            size={'large'}
                            onClick={() => setIsPdfViewerVisible(true)}
                        >
                            Visualizar Contrato
                        </ButtonCP>
                        <LinkHtmlCP
                            type={'ghost'}
                            href={props.contractUrl}
                            size={'large'}
                        >
                            <DownloadIconCP style={{ marginRight: '10px' }}/>
                            <>Fazer download do Contrato</>
                        </LinkHtmlCP>
                        <AccessControlCP module={ModuleEnum.MODULE_DOKFY}>
                            <ButtonCP
                                type={'primary'}
                                icon={'file-protect'}
                                size={'large'}
                                onClick={props.onSendToDokfy}
                            >
                                Solicitar assinatura eletrônica
                            </ButtonCP>
                        </AccessControlCP>
                    </ButtonsWrapperSCP>
                }
            />

            <PdfViewerCP
                file={props.contractUrl}
                paginationType={'scroll'}
                viewType={'modal'}
                visible={isPdfViewerVisible}
                onSetVisibleFalse={() => setIsPdfViewerVisible(false)}
            />
        </>
    )

}

const ButtonsWrapperSCP = styled.div`

  button, a {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

`
