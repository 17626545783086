import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SelectFullOptionReturnTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionReturnTP'
import { SelectFullOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { CheckIconCP } from 'main/common/components/icons/CheckIconCP'
import { CloseIconCP } from 'main/common/components/icons/CloseIconCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { SegmentUnitValueFieldTP } from 'main/modules/segment/types/unit/SegmentUnitValueFieldTP'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React, { useEffect, useState } from 'react'
import { UnitValueFieldInputIndexTP } from 'main/modules/segment/types/unit/UnitValueFieldInputIndexTP'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { SegmentUnitValueDateTP } from 'main/modules/segment/types/unit/SegmentUnitValueDateTP'
import { UnitValueFieldICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueFieldICP'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { DatePropsTP } from 'main/common/types/DatePropsTP'

type ValueWithLabelTP = Array<SegmentUnitValueWithLabelTP<true>>

interface IUnitValueContentICPProps {
    onFinish: (value?: SegmentUnitValueWithLabelTP[]) => void
    onValueChange: (value?: ValueWithLabelTP) => void
}

/**
 * COMPONENTE
 * Conteudo do popover de definicao de valor para 01 unidade de lista de segmentacao.
 */
export function UnitValuePopoverContentICP(props: IUnitValueContentICPProps): JSX.Element | null {

    const [valueWithLabel, setValueWithLabel] = useState<ValueWithLabelTP>([])
    const [isThereInnerValidationErr, setIsThereInnerValidationErr] = useState<boolean>(false)

    const unitContext = UnitContext.useState()

    useEffect(() => setValueWithLabel(unitContext.state.value ?? []), [unitContext.state.value])
    useEffect(() => props.onValueChange(valueWithLabel), [valueWithLabel])

    if (!SegmentUtils.isUnitSelected(unitContext.state.field, unitContext.state.operator))
        return null

    const field = unitContext.state.field!
    const operator = unitContext.state.operator!
    const valueFieldsCount = SegmentUtils.getUnitValueFieldsCount(field, operator)
    const show2Fields = SegmentUtils.isDoubleFieldCount(valueFieldsCount)

    function onValueChange(inputIndex: UnitValueFieldInputIndexTP, newValue: SegmentUnitValueFieldTP | SelectFullOptionReturnTP, isValid: boolean): void {

        setIsThereInnerValidationErr(!isValid)

        // Select de varios valores
        if (valueFieldsCount === 'n-values') {

            const multipleValueWithLabel = (newValue as SelectFullOptionTP[])
                .map((option): SegmentUnitValueWithLabelTP => ({
                    value: option.value,
                    label: option.label
                }))

            return setValueWithLabel(multipleValueWithLabel)
        }

        // Campo(s) de valor unitario
        const singleValueWithLabel = getUnitSingleValueWithLabel(newValue)
        let nextValue = [...valueWithLabel] as ValueWithLabelTP

        if (inputIndex === 0)
            nextValue[0] = singleValueWithLabel
        else if (valueWithLabel.length === 0)
            nextValue = [undefined, singleValueWithLabel]
        else
            nextValue[1] = singleValueWithLabel

        setValueWithLabel(nextValue)
    }

    function getUnitSingleValueWithLabel(unitSingleValue: SegmentUnitValueFieldTP | SelectFullOptionReturnTP): SegmentUnitValueWithLabelTP {

        let value: SegmentUnitValueFieldTP
        let label: string | number

        if (SegmentUtils.isSelectOptionField(field)) {
            value = (unitSingleValue as SelectFullOptionTP).value
            label = (unitSingleValue as SelectFullOptionTP).label

        } else if (SegmentUtils.isDateField(field)) {
            value = unitSingleValue as SegmentUnitValueDateTP
            label = SegmentUtils.getSegmentUnitValueDateLabel(unitSingleValue as SegmentUnitValueDateTP)

        } else {
            value = unitSingleValue as SegmentUnitValueFieldTP
            label = unitSingleValue as (string | number)
        }

        return { value, label }
    }

    function onFinish(cancelled = false): void {

        if (cancelled)
            return props.onFinish()

        if (isValueValid())
            props.onFinish(valueWithLabel as SegmentUnitValueWithLabelTP[])
    }

    function isValueValid(): boolean {

        if (isThereInnerValidationErr)
            return false

        // Valida preenchimento completo
        const isFulfilled = (typeof valueWithLabel[0] === 'object' && (!show2Fields || (typeof valueWithLabel[1] === 'object')))
        if (!isFulfilled)
            return false

        const isDateRange = (SegmentUtils.isDateField(field) && operator === SegmentUnitCompareOperatorsEnum.BETWEEN)
        if (!isDateRange)
            return isFulfilled

        // Valida compatibilidade entre datas numa faixa de datas
        const valueAtPlace1 = valueWithLabel[0]?.value as DatePropsTP
        const valueAtPlace2 = valueWithLabel[1]?.value as DatePropsTP

        if (Object.keys(valueAtPlace1).length !== Object.keys(valueAtPlace2).length)
            return false

        const isThereAnyUnmatchedProp = Object.keys(valueAtPlace1).some(dateProp => (
            (!!valueAtPlace1[dateProp] && !valueAtPlace2[dateProp]) || (!valueAtPlace1[dateProp] && !!valueAtPlace2[dateProp])
        ))

        return !isThereAnyUnmatchedProp
    }

    return (
        <WrapperSCP>
            <InputsWrapperSCP>
                <UnitValueFieldICP index={0} valueWithLabel={valueWithLabel} onValueChange={onValueChange}/>

                {
                    show2Fields &&
                    <UnitValueFieldICP index={1} valueWithLabel={valueWithLabel} onValueChange={onValueChange}/>
                }
            </InputsWrapperSCP>

            <ButtonsContainerSCP>
                <ButtonCP
                    type={'primary'}
                    size={'small'}
                    onClick={() => onFinish()}
                    tooltip={'Concluir'}
                >
                    <CheckIconCP size={14}/>
                </ButtonCP>

                <ButtonCP
                    type={'danger'}
                    size={'small'}
                    marginLeft={4}
                    onClick={() => onFinish(true)}
                    tooltip={'Cancelar'}
                >
                    <CloseIconCP size={14}/>
                </ButtonCP>
            </ButtonsContainerSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 10px -5px -2px 0px;
    flex-direction: column;
`

const InputsWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
`

const ButtonsContainerSCP = styled.div`
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;
`
