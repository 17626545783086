import {
    ScreenSubMenuItemTP,
    ScreenSubMenuTP
} from 'main/common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { NewShotButtonCP } from 'main/modules/marketing/components/new-shot-button/NewShotButtonCP'
import { ShotListScreenContentCP } from 'main/modules/marketing/components/shot-list-screen-content/ShotListScreenContentCP'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { MarketingViewTP } from 'main/modules/marketing/screens/screen-marketing/inner/MarketingViewTP'
import React from 'react'
import { ScreenContentMktDomainsCP } from 'main/modules/marketing/screens/screen-marketing/screen-content/screen-content-mkt-domains/ScreenContentMktDomainsCP'
import { ScreenContentMktSendersCP } from 'main/modules/marketing/screens/screen-marketing/screen-content/screen-content-mkt-senders/ScreenContentMktSendersCP'
import { ScreenContentMktAccountCP } from 'main/modules/marketing/screens/screen-marketing/screen-content/screen-content-mkt-account/ScreenContentMktAccountCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import {
    MarketingTransactionalEventEnum,
    MarketingTransactionalEventLabelEnum
} from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'
import { ScreenContentMktTransactionalCP } from 'main/modules/marketing/screens/screen-marketing/screen-content/screen-content-mkt-transactional/ScreenContentMktTransactionalCP'
import { FrameZap } from '../../iframezap/FrameZap'

export const MARKETING_SUBMENU_EMAIL_KEY = 'email'
export const MARKETING_SUBMENU_SMS_KEY = 'sms'
export const MARKETING_SUBMENU_CONFIG_KEY = 'config'
export const MARKETING_SUBMENU_TRANSACTIONAL_KEY = 'transactional'
export const ZAP_SUBMENU_KEY = 'zapkey';

/**
 * UTILS
 * Reune metodos auxiliares para gestao da tela Listagem de Disparos.
 */
export const MarketingScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<MarketingViewTP>> {

        return [
            {
                key: MARKETING_SUBMENU_EMAIL_KEY,
                title: 'Envios por E-mail',
                items: [
                    {
                        name: 'Enviados',
                        view: 'emailSent',
                        route: MarketingRoutes.EMAIL_SENT,
                        contentTitle: 'E-mails Enviados',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.EMAIL} shotStatus={[MarketingShotStatusEnum.SENT, MarketingShotStatusEnum.SENDING, MarketingShotStatusEnum.ERROR]}/>,
                    },
                    {
                        name: 'Agendados',
                        view: 'emailScheduled',
                        route: MarketingRoutes.EMAIL_SCHEDULED,
                        contentTitle: 'E-mails Agendados',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.EMAIL} shotStatus={[MarketingShotStatusEnum.SCHEDULED]}/>
                    },
                    {
                        name: 'Rascunho',
                        view: 'emailDraft',
                        route: MarketingRoutes.EMAIL_DRAFT,
                        contentTitle: 'E-mails em Edição',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.EMAIL} shotStatus={[MarketingShotStatusEnum.DRAFT]}/>
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_MARKETING])
            },
            {
                key: MARKETING_SUBMENU_SMS_KEY,
                title: 'Envios por SMS',
                items: [
                    {
                        name: 'Enviados',
                        view: 'smsSent',
                        route: MarketingRoutes.SMS_SENT,
                        contentTitle: 'SMS Enviados',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.SMS} shotStatus={[MarketingShotStatusEnum.SENT, MarketingShotStatusEnum.SENDING, MarketingShotStatusEnum.ERROR]}/>
                    },
                    {
                        name: 'Agendados',
                        view: 'smsScheduled',
                        route: MarketingRoutes.SMS_SCHEDULED,
                        contentTitle: 'SMS Agendados',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.SMS} shotStatus={[MarketingShotStatusEnum.SCHEDULED]}/>
                    },
                    {
                        name: 'Rascunho',
                        view: 'smsDraft',
                        route: MarketingRoutes.SMS_DRAFT,
                        contentTitle: 'SMS em Edição',
                        contentTitleChildren: <NewShotButtonCP/>,
                        content: <ShotListScreenContentCP shotType={MarketingAccountTypeEnum.SMS} shotStatus={[MarketingShotStatusEnum.DRAFT]}/>
                    },
                ],
                
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_MARKETING])
            },
            {
                key: ZAP_SUBMENU_KEY,
                title: 'Integração WhatsApp',
                items: [
                    {
                        name: 'Gerenciador',
                        view: 'zapView',
                        route: MarketingRoutes.ZAP_COMPONENTE,
                        contentTitle: 'Gerenciador da Integracao com whatsapp',
                        //  contentTitleChildren:<FrameZap/>,
                        content: <FrameZap/>
                    }
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_MARKETING])
            },
            {
                key: MARKETING_SUBMENU_TRANSACTIONAL_KEY,
                title: 'Automações',
                items: this.getTransactionalSubmenuItens(),
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_MARKETING, [PermissionEnum.ROLE_MARKETING, PermissionEnum.ROLE_ADMIN], 'franchisor')
            },
            {
                key: MARKETING_SUBMENU_CONFIG_KEY,
                title: 'Configurações',
                items: [
                    {
                        name: 'Domínio',
                        view: 'configDomain',
                        route: MarketingRoutes.CONFIG_DOMAIN,
                        content: <ScreenContentMktDomainsCP/>
                    },
                    {
                        name: 'Remetentes de E-mail',
                        view: 'configSender',
                        route: MarketingRoutes.CONFIG_SENDER,
                        content: <ScreenContentMktSendersCP/>
                    },
                    {
                        name: 'Meu Plano',
                        view: 'configPlan',
                        route: MarketingRoutes.CONFIG_PLAN,
                        contentTitle: 'Dados do Plano',
                        content: <ScreenContentMktAccountCP/>
                    },
                ],
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_MARKETING, [PermissionEnum.ROLE_MARKETING, PermissionEnum.ROLE_ADMIN], 'franchisor')
            },
        ]
    },

    /**
     * Monta os itens do submenu das automacoes
     */
    getTransactionalSubmenuItens(): Array<ScreenSubMenuItemTP<MarketingViewTP>> {

        const submenuItens: Array<ScreenSubMenuItemTP<MarketingViewTP>> = []
        Object.values(MarketingTransactionalEventEnum).forEach((type) => {

            submenuItens.push({
                name: MarketingTransactionalEventLabelEnum[type],
                view: type,
                route: `${MarketingRoutes.TRANSACTIONAL_ROOT}/${type}`,
                content: <ScreenContentMktTransactionalCP/>
            })
        })

        return submenuItens
    },

}
