import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/InputValueCallbackTP'
import { IInputCPProps, InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import React from 'react'

interface IInputPhoneCPProps<FModelTP extends FormModel> extends Omit<IInputCPProps<FModelTP>, 'type' | 'min' | 'max' | 'mask'> {
    clearMask?: boolean // padrao: true
}

/**
 * COMPONENTE: Input para telefone.
 * NOTE: Quando controlado via 'form state manager' valor nao eh determinado diretamente pela prop 'value'
 *
 * TODO: 19/11/2020 - Simplificar / Melhorar isso
 * TODO: 19/11/2020 - Verificar necessidade de limitar quantidade de caracteres no input
 */
export function InputPhoneCP<FModelTP extends FormModel = any>(props: IInputPhoneCPProps<FModelTP>): JSX.Element {

    function onPutValueOut(newValue?: string, handler?: InputValueCallbackTP<string, true>): void {
        handler?.(props.clearMask ? newValue?.replace(/\D/g, '') : newValue)
    }

    return (
        <InputCP
            type={'tel'}
            mask={InputMaskTypeEnum.PHONE}
            value={props.value}
            onChange={value => onPutValueOut(value, props.onChange)}
            onBlur={value => onPutValueOut(value, props.onBlur)}
            onFormSubmit={props.onFormSubmit}
            required={props.required}
            label={props.label}
            disabled={props.disabled}
            width={props.width}
            fontSize={props.fontSize}
            errorMessage={props.errorMessage}
            noValidation={props.noValidation}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            debug={props.debug}
            icon={props.icon}
            loading={props.loading}
            autoFocus={props.autoFocus}
            prefix={props.prefix}
            placeholder={props.placeholder}
        />
    )
}
