import React from 'react'
import { WidgetsRowSalesFlowIndicators } from 'main/modules/dashboard/components/widgets-row-sales-flow-indicators/WidgetsRowSalesFlowIndicators'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetTotalResaleCP } from 'main/modules/dashboard/components/widget-total-resale/WidgetTotalResaleCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    dateFilters: IDateRangeFilter
    onDateRangeChange: (dateFilters: IDateRangeFilter) => void
    franchiseCodes?: number[]
}

/**
 * Aba de produtividade.
 */
export function FranchiseManagerPerformanceTabICP(props: ICPProps): JSX.Element {

    return (
        <>
            <FiltersFranchiseDashboardCP dateFilters={props.dateFilters} onDateRangeChange={props.onDateRangeChange}/>
            <WidgetsRowSalesFlowIndicators franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            <WidgetsRowCP marginTop={20}>
                <WidgetTotalResaleCP franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            </WidgetsRowCP>
        </>
    )
}
