import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { IFranchiseSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'
import { IFranchiseContractTemplateSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseContractTemplateSaveRequestDTO'
import { IWorkSchedulesSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkSchedulesSaveRequestDTO'
import { IFranchiseConfigSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseConfigSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo Admin (PEOPLE)
 */
export class FranchiseRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/franchises`

    static getFranchise = (): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
    })

    static updateFranchise = (dto: IFranchiseSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static uploadFranchiseLogo = (file: File): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)

        return ({
            url: `${FranchiseRequests._MODULE_BASE}/upload-logo`,
            method: HttpMethodEnum.PUT,
            params: formData
        })
    }

    static uploadFranchiseCover = (file: File): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)

        return ({
            url: `${FranchiseRequests._MODULE_BASE}/upload-cover`,
            method: HttpMethodEnum.PUT,
            params: formData
        })
    }

    static saveContractTemplate = (dto: IFranchiseContractTemplateSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/update-contract-template`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getWorkSchedules = (): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/work-schedule`,
        method: HttpMethodEnum.GET,
    })

    static updateWorkScheduleConfig = (dto: IWorkSchedulesSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/work-schedule`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getFranchiseConfig = (): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/franchise-config`,
        method: HttpMethodEnum.GET,
    })

    static updateFranchiseConfig = (dto: IFranchiseConfigSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/franchise-config`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

}
