import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator/decorator/decorators'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'

export class FormFilterCalendarEventsFormModel extends FormModel {

    @IsRequired()
    dateInterval: IDateRangeFilter

    @IsOptional()
    showNoShow?: boolean

    @IsOptional()
    showEvaluations?: boolean

    @IsOptional()
    userCodes?: number[]

    @IsOptional()
    treatmentGroupCodes?: number[]

    constructor(initialData?: Partial<FormFilterCalendarEventsFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
