export const SaleDetailRawHtml = `
    
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
<html>
    <head>
        <META http-equiv="Content-Type" content="text/html; charset=utf-8">
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700" rel="stylesheet">

        <style type="text/css">
        
            body {
                font-family: Montserrat, sans-serif;
                line-height: 30px;
            }

            .PrintAreaWrapperSCP {
                display: grid;
                border: dashed #dedede 1px;
                border-radius: 5px;
                padding: 10px;
                margin: 10px;
                div:first-of-type {
                    padding: 0;
                }
            }
            
            .header-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .header-2 {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            
            .header-3 {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
            }
            
            .n-title-text {
                display: flex;
            }
            
            table {
                width: 100%;
                margin: 30px 0;
            }
            
            .footer-1 {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                margin-bottom: 30px;
            }
        </style>
</head>

<body style="margin: 0; padding: 0">
    <div class="PrintAreaWrapperSCP">
        sale-detail-content-to-substitute
    </div>
</body>

`
