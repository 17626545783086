import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { FinancialTransactionStatusIconCP } from 'main/modules/financial/components/financial-transaction-status-icon/FinancialTransactionStatusIconCP'
import React from 'react'
import { TextSaleItemsProductReleaseNamesCP } from 'main/modules/sale/components/text-sale-items-product-release-names/TextSaleItemsProductReleaseNamesCP'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IconPaymentMethodCP } from 'main/modules/financial/components/icon-payment-method/IconPaymentMethodCP'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ButtonUniquePaymentMethodActionCP } from 'main/modules/sale/components/button-unique-payment-method-action/ButtonUniquePaymentMethodActionCP'
import { ButtonPayFinancialTransactionCP } from 'main/modules/financial/components/button-pay-financial-transaction/ButtonPayFinancialTransactionCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ColumnTransactionValueCP } from 'main/modules/financial/components/column-transaction-value/ColumnTransactionValueCP'
import { ColumnTransactionExpirationDateCP } from 'main/modules/financial/components/column-transaction-expiration-date/ColumnTransactionExpirationDateCP'

export const FinancialTransactionsCreditTableUtils = {

    getCreditTableColumns(
        onSelectTransaction: (financialTransaction: IFinancialTransactionCreditListItemResponseDTO) => void,
        onPersonClick: (personCode: number) => void,
        onUpdateAnyData: () => void,
        showReferenceDate = false
    ): Array<ColumnProps<IFinancialTransactionCreditListItemResponseDTO>> {
        return [
            {
                key: 'cr_status',
                align: 'center',
                render: (text, item) => <FinancialTransactionStatusIconCP status={item.status} expirationDate={item.expirationDate}/>
            },
            {
                key: 'pm',
                align: 'center',
                render: (text, item) => <IconPaymentMethodCP paymentMethod={item.paymentMethod}/>
            },
            {
                title: showReferenceDate ? 'Data de Referência' : 'Data',
                key: 'cr_expirationdate',
                className: 'dateColumn',
                render: (text, item) => <ColumnTransactionExpirationDateCP expirationDate={showReferenceDate ? item.referenceDate : item.expirationDate} paymentDate={item.paymentDate}/>
            },
            {
                title: 'Cliente / Descrição',
                key: 'cr_personPayerName',
                render: (text, item) => (
                    <>
                        <TextCP text={StringUtils.getFirstAndSecondName(item.personPayer.name)} onClick={() => onPersonClick(item.personPayer.code)}/>
                        {
                            !!item.saleCode
                                ?
                                    <TextSaleItemsProductReleaseNamesCP
                                    textColor={ThemeAnt.gray}
                                    characterLimit={30}
                                    fontStyle={'italic'}
                                    saleItemsProductReleaseNames={item.saleItemProductReleases?.map(releaseNameAndCode => releaseNameAndCode.name) ?? []}
                                />
                                :
                                <TextCP text={item.description} fontStyle={'italic'} color={ThemeAnt.gray}/>
                        }
                    </>
                )
            },
            {
                key: 'cr_installment',
                render: (text, item) => (
                    <ConditionalRenderCP shouldRender={!!item.saleCode}>
                        <FlexCP>
                            <div style={{ width: '40px' }}>
                                <ProgressCP percent={MathUtils.getPercentage(item.installmentNumber, item.totalInstallments, true)} showInfo={false}/>
                            </div>
                            <TextCP text={`${item.installmentNumber}/${item.totalInstallments}`} key={item.code} marginLeft={5}/>
                        </FlexCP>
                    </ConditionalRenderCP>
                )
            },
            {
                title: 'Valor',
                key: 'cr_value',
                align: 'right',
                render: (text, item) => <ColumnTransactionValueCP value={item.value} paidValue={item.paidValue}/>
            },
            {
                title: 'Conta',
                key: 'cr_bank',
                render: (text, item) => (
                    <>
                        { item.bankAccount.name }
                        {
                            item.bankAccountPartner.code !== item.bankAccount.code &&
                            <TextCP text={item.bankAccountPartner.name} size={'small'} color={ThemeAnt.gray}/>
                        }
                    </>
                )
            },
            {
                key: 'cr_actions',
                align: 'center',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonUniquePaymentMethodActionCP
                            financialTransaction={item}
                            onUpdateAnyData={onUpdateAnyData}
                            chargeData={item.chargeData}
                        />
                        <ButtonPayFinancialTransactionCP
                            financialTransactionCode={item.code}
                            financialTransactionStatus={item.status}
                            onChangePayment={onUpdateAnyData}
                            showText={false}
                            marginLeft={5}
                        />
                        <ButtonCP
                            type={'primary'}
                            onClick={(): void => onSelectTransaction(item)}
                            icon={'eye'}
                            size={'small'}
                            marginLeft={5}
                        />
                    </FlexCP>
                )
            }
        ]
    },

}
