import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import styled from 'main/config/theme/styledWithTheme'
import React from 'react'

interface ITextPersonPhoneCPProps {
    phone?: string
}

/**
 * COMPONENTE
 */
export function TextPersonPhoneCP(props: ITextPersonPhoneCPProps): JSX.Element {

    /**
     * Abri link para enviar msg whatsapp web.
     */
    function sendWhatsapp(): void {
        window.open(`https://api.whatsapp.com/send?phone=55${props.phone!}&text=&source=&data=&app_absent=`, 'blank')
    }

    if (!props.phone)
        return <></>

    return (
        <FlexCP>
            <IconWrapper onClick={sendWhatsapp}>
                { MaskUtils.applyMask(props.phone, InputMaskTypeEnum.PHONE) }
                <TooltipCP text={'Enviar Whatsapp'} showSpan={true}>
                    <FontAwsomeIconCP type={faWhatsapp} color={'green'}/>
                </TooltipCP>
            </IconWrapper>
        </FlexCP>
    )
}

const IconWrapper = styled.div`
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  
  svg {
    margin-left: 8px;
  }
`
