import React from 'react'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { styled } from 'main/config/theme/styledWithTheme'

interface IUnitActionButtonICPProps {

    onClick: () => void
    tooltip: string
    icon: JSX.Element
    btnClassName: string
    rightOffset: number

    hiddenButton?: boolean
    className?: string
}

/**
 * COMPONENTE
 * Botao de acao associado a 01 unidade de 01 expressao de lista de segmentacao.
 */
export function UnitActionButtonICP(props: IUnitActionButtonICPProps): JSX.Element {
    return (
        <ButtonWrapperSCP
            rightOffset={props.rightOffset}
            hiddenButton={props.hiddenButton}
            className={props.className}
        >
            <TooltipCP text={props.tooltip} placement={'bottom'}>
                <IconWrapperSCP className={props.btnClassName} onClick={props.onClick}>
                    {props.icon}
                </IconWrapperSCP>
            </TooltipCP>
        </ButtonWrapperSCP>
    )
}

const ButtonWrapperSCP = styled.div<{ rightOffset: number, hiddenButton?: boolean }>`

    position: absolute;
    right: ${props => props.rightOffset}px;
    display: flex;
    align-items: center;
    padding-left: ${props => (!!props.hiddenButton ? '12px' : '9px')};
    top: 0;
    height: 100%;

    background: ${props => (!!props.hiddenButton
        ? 'linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .5), rgba(255, 255, 255, .7), rgba(255, 255, 255, .9), rgba(255, 255, 255, 1))'
        : 'unset')
};
`

const IconWrapperSCP = styled.div`

    cursor: pointer;

    i {
        line-height: 0;
    }
`
