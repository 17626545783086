import React, { useEffect, useState } from 'react'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { SmsTextAreaCP } from 'main/modules/marketing/components/sms-textarea/SmsTextAreaCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { SendBulkMessageFormModel } from 'main/modules/people/components/modal-send-bulk-message/inner/SendBulkMessageFormModel'
import { MarketingChannelEnum } from 'main/modules/marketing/enums/MarketingChannelEnum'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FormStateManagerUtils } from 'main/common/form-state-manager/FormStateManagerUtils'
import { IPersonContactSendBulkMessageSaveRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSendBulkMessageSaveRequestDTO'
import { PersonContactRequests } from 'main/modules/people/services/person-contact/PersonContactRequests'

interface ICPProps {
    visible: boolean
    onClose: () => void
    selectedPeople: number[]
}

/**
 * Conteudo da barra de acoes da area de listagem de pessoas.
 */
export function ModalSendBullkMessageCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<SendBulkMessageFormModel>(new SendBulkMessageFormModel())
    const formStateManager = useFormStateManager<SendBulkMessageFormModel>(formValidator)

    const sendBulkMessageRequest = useRequest<void>('none')
    useEffect(onSendBulkMessageRequestChange, [sendBulkMessageRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa.
     */
    function init(): void {
        setFormValidator(new SendBulkMessageFormModel({
            channel: MarketingChannelEnum.WHATSAPP
        }))
    }

    /**
     * Faz o envio
     */
    async function sendMessage(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        const dto: IPersonContactSendBulkMessageSaveRequestDTO = {
            personCodes: props.selectedPeople,
            channel: formValues.channel,
            text: formValues.text
        }

        sendBulkMessageRequest.runRequest(PersonContactRequests.sendBulkMessage(dto))
    }

    /**
     * Apos retorno da api de salvar
     */
    function onSendBulkMessageRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(sendBulkMessageRequest, 'Ocorreu algun erro ao enviar as mensagens'))
            return

        NotificationHelper.info('Yes', 'Mensagens enviadas com sucesso')
        formStateManager.reset(undefined)
        props.onClose()
    }

    return (
        <ModalCP
            title={'Enviar mensagem'}
            visible={props.visible}
            onCancel={props.onClose}
            onOk={sendMessage}
            okText={'Enviar'}
            destroyOnClose={true}
            actionLoading={sendBulkMessageRequest.isAwaiting}
        >

            { /* Por enquanto é fixo, até o Backend aceitar */ }
            {/*<FlexCP justifyContent={'center'} marginBottom={20}>*/}
            {/*    <RadioGroupMessageChannelCP*/}
            {/*        options={[MarketingChannelEnum.WHATSAPP, MarketingChannelEnum.SMS]}*/}
            {/*        selectedChannel={formStateManager.getFieldValue('channel')}*/}
            {/*        onChange={(newChannel) => formStateManager.changeFieldValue('channel', newChannel)}*/}
            {/*    />*/}
            {/*</FlexCP>*/}

            <SmsTextAreaCP
                formStateManager={formStateManager}
                fieldName={'text'}
                label={'Mensagem'}
                considerCharLimit={formStateManager.getFieldValue('channel') === MarketingChannelEnum.SMS}
            />

            <AlertCP
                marginTop={40}
                message={`Será enviada mensagem para ${props.selectedPeople.length} contatos. Essa ação não poderá ser interrompida.`}
                type={'warning'}
            />
        </ModalCP>
    )

}
