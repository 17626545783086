import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ScpProps {
    width?: number
    fontSize?: number
}

interface ICPProps extends ScpProps {
    shouldStart: boolean
    reset?: number
    initialTimeInMS?: number
    appearance?: {
        hideSeconds?: boolean
        actionButtonMode: 'toogle' | 'default' | 'none'
        tootleAction?: {
            startLabel: string
            startAction: () => void
            finishLabel: string
            finishAction: () => void
            loading?: boolean
        }
    }
}

/**
 * Cronometro.
 */
export function StopWatchCP(props: ICPProps): JSX.Element {

    const [time, setTime] = useState(props.initialTimeInMS ?? 0);
    useEffect(() => setTime(props.initialTimeInMS ?? 0), [props.reset])

    const [isRunning, setIsRunning] = useState<boolean>(props.shouldStart);

    useEffect(() => {
        let interval: any
        if (isRunning) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 10);
            }, 10);
        } else if (!isRunning) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRunning]);

    return (
        <WrapperSCP width={props.width}>
            <NumbersSCP>
                <MinutesSCP fontSize={props.fontSize}>
                    {("0" + Math.floor((time / 60000) % 60)).slice(-2)}
                </MinutesSCP>
                <span>:{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                {
                    !props.appearance?.hideSeconds &&
                    <span>:{("0" + ((time / 10) % 100)).slice(-2)}</span>
                }
            </NumbersSCP>

            {
                props.appearance?.actionButtonMode === 'default' &&
                <div>
                    <button onClick={() => setIsRunning(true)}>Start</button>
                    <button onClick={() => setIsRunning(false)}>Stop</button>
                    <button onClick={() => setTime(0)}>Reset</button>
                </div>
            }

            {
                props.appearance?.actionButtonMode === 'toogle' && !!props.appearance.tootleAction &&
                <FlexCP justifyContent={'center'} margin={{ bottom: 30 }}>
                    <ButtonCP
                        type={isRunning ? 'danger' : 'primary'}
                        loading={props.appearance.tootleAction.loading}
                        size={'large'}
                        onClick={() => {
                            if (isRunning) {
                                props.appearance!.tootleAction!.finishAction()
                                setTime(0)
                            } else
                                props.appearance!.tootleAction!.startAction()
                            setIsRunning(!isRunning)
                        }}
                        confirmMsg={isRunning ? 'Você tem certeza que deseja encerrar?' : undefined}
                    >
                        { isRunning ? props.appearance.tootleAction.finishLabel : props.appearance.tootleAction.startLabel }
                    </ButtonCP>
                </FlexCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.width ?? '300'}px;
  border: dashed 2px #c5c5c5;
  border-radius: 5px;
`

const NumbersSCP = styled.div`
  font-size: 30px;
`

const MinutesSCP = styled.span<{ fontSize?: number }>`
  font-size: ${props => props.fontSize ?? '60' }px;
`

