import * as React from 'react'
import styled from 'styled-components'
import { HttpStatusEnum } from 'main/common/enums/HttpStatusEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

/**
 * Pagina 404 - Pagina nao encontrada.
 */
export function Error404Screen(): JSX.Element {
    return (
        <>
            <FallbackScreenContentCP
                status={HttpStatusEnum.NOT_FOUND}
                title={'Oops! Página não encontrada!'}
                bottomContent={
                    <ButtonWrapperSCP>
                        <ButtonCP
                            type={'primary'}
                            icon={'home'}
                            onClick={() => RoutingHelper.historyReplace('default')}
                        >
                            Voltar ao início
                        </ButtonCP>
                    </ButtonWrapperSCP>
                }
            />
        </>
    )
}

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
`
