import { ColumnProps } from 'antd/lib/table'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { IScheduleReportListItemResponseDTO } from 'main/modules/reports/services/scheduler-reports/dtos/response/IScheduleReportListItemResponseDTO'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import React from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { StringUtils } from 'main/common/utils/StringUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import {
    ScheduleReportItemStatusEnum,
    ScheduleReportItemStatusLabelEnum
} from 'main/modules/reports/services/scheduler-reports/enums/ScheduleReportItemStatusEnum'
import { IScheduleReportRequestDTO } from 'main/modules/reports/services/scheduler-reports/dtos/request/IScheduleReportRequestDTO'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { BasicReportItemTypeEnum } from 'main/modules/reports/services/scheduler-reports/enums/BasicReportItemTypeEnum'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faComment, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

/**
 * UTILITARIOS do componente de listagem de agendamentos.
 */
export const TableAppointmentsUtils = {

    getColumns(): Array<ColumnProps<IScheduleReportListItemResponseDTO>> {

        return [
            {
                title: 'Data',
                key: 'date',
                render: (text, item) => DateUtils.getFormatted(item.beginDate, DateFormatEnum.BR_WITHOUT_TIME)
            },
            {
                title: 'Horário',
                key: 'hour',
                render: (text, item) => (
                    <TextCP
                        text={`${DateUtils.getFormatted(item.beginDate, DateFormatEnum.TIME_H_M)} às ${DateUtils.getFormatted(item.endDate, DateFormatEnum.TIME_H_M)}`}
                    />
                )
            },
            {
                key: 'presence',
                render: (text, item) => item.isPresenceConfirmed ? <FontAwsomeIconCP type={faUserCheck} tooltip={'Presença confirmada'} color={ThemeAnt.successColor}/> : undefined
            },
            {
                title: 'Cliente',
                key: 'customer',
                render: (text, item) => (
                    <>
                        <FlexCP>
                            <TextCP text={StringUtils.getFirstAndSecondName(item.customer.name ?? '')} marginRight={10}/>
                            <TextPersonPhoneCP phone={item.customer.phone}/>
                        </FlexCP>
                        {
                            item.type === BasicReportItemTypeEnum.SESSION && !!item.sessionData
                                ?
                                <FlexCP>
                                    <TextCP text={`Sessão de ${item.sessionData.treatment.name}`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                                    <TextCP marginLeft={5} text={`(${item.sessionData.sessionNumber}/${item.sessionData.totalSessions})`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                                </FlexCP>
                                :
                                <TextCP text={'Avaliação'} fontStyle={'italic'} color={ThemeAnt.gray}/>
                        }
                    </>
                )
            },
            {
                title: 'Responsável',
                key: 'responsible',
                render: (text, item) => StringUtils.getFirstAndSecondName(item.userResponsible.name)
            },
            { title: 'Status',
                key: 'status',
                render: (text, item) => (
                    <TagCP
                        content={ScheduleReportItemStatusLabelEnum[item.status]}
                        color={item.status === ScheduleReportItemStatusEnum.CONCLUDED ? ThemeAnt.successColor : ThemeAnt.warningColor}
                    />
                ) },
            { key: 'dsc',
                render: (text, item) => (
                    <ConditionalRenderCP shouldRender={!!item.description}>
                        <FontAwsomeIconCP type={faComment} tooltip={item.description}/>
                    </ConditionalRenderCP>
                ) },
        ]
    },

    /**
     * Monta os DTO dos filtros com base no formModel.
     */
    mountFiltersDto(filterModel: ReportsCommonFiltersFormModel, pagination: TablePaginationTP): IScheduleReportRequestDTO {
        return {
            franchiseCodes: filterModel.franchisesCodes,
            scheduleUserCode: filterModel.userAttendanceCode,
            beginDate: DateUtils.getFormatted(filterModel.dateRange?.beginDate ?? new Date(), DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(filterModel.dateRange?.endDate ?? new Date(), DateFormatEnum.US_WITHOUT_TIME),
            showEvaluations: filterModel.showEvaluations,
            status: filterModel.scheduleStatus,
            page: pagination.current,
            itemsPerPage: pagination.pageSize,
        }
    },

}
