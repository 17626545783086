import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DomainStatusCP } from 'main/modules/marketing/components/domain-status/DomainStatusCP'
import { MarketingDomainConfigsICP } from 'main/modules/marketing/components/marketing-domains/inner/MarketingDomainConfigsICP'
import { IMarketingDomainResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainResponseDTO'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as _ from 'lodash'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'

interface IMarketingDomainsCPProps {
    mustUpdateDomains: boolean
}

/**
 * Configuracoes de Mkt
 */
export function MarketingDomainsCP(props: IMarketingDomainsCPProps): JSX.Element {

    useEffect(() => { initialize() }, [props.mustUpdateDomains])

    const [domainList, setDomainList] = useState<IMarketingDomainResponseDTO[]>([])
    const getDomainsRequest = useRequest<IGenericListResponseDTO<IMarketingDomainResponseDTO>>()
    useEffect(onGetDomainsRequestChange, [getDomainsRequest.isAwaiting])

    /**
     * Inicializa dados da tela
     */
    async function initialize(): Promise<void> {

        if (!props.mustUpdateDomains)
            return

        await defineDomains()
    }

    /**
     * Busca dominios na api
     */
    async function defineDomains(): Promise<void> {
        getDomainsRequest.runRequest(await MarketingRequests.getDomainsReqConfig())
    }

    /**
     * Retorno da requisicao que busca dominios.
     */
    function onGetDomainsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getDomainsRequest, 'Não foi possível obter a lista de dominios'))
            return

        setDomainList(getDomainsRequest.responseData!.list)
    }

    return (
        <WrapperSCP>

            {
                _.isEmpty(domainList) &&
                <EmptyCP description={'Nenhum domínio cadastrado ainda'}/>
            }

            <CollapseCP
                loading={getDomainsRequest.isAwaiting}
                defaultPanelKeys={['panel=0']}
                panels={
                    domainList.map(domain => (
                        {
                            title: (<TextCP text={`Domínio: ${domain.name}`} wrap={true}/>),
                            extra: <DomainStatusCP status={domain.status} showUpdateButton={true} domainCode={domain.code} onValidateDomain={defineDomains}/>,
                            content: <MarketingDomainConfigsICP domainConfigs={domain.configs || []} domainCode={domain.code} onDelete={defineDomains}/>
                        }
                    ))
                }
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    width: 100%;
    padding: 20px;

    .ant-collapse-item {
      background: #fff;
      margin-top: 20px;
      border-radius: 4px !important;
    }

`
