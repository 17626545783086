import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FinancialTransactionRequests } from 'submodules/neritclin-sdk/services/financial/financial/FinancialTransactionRequests'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { ScreenFileUploadEnum, ScreenFileUploadLabelEnum } from 'main/modules/app/screens/screen-upload-files/inner/ScreenFileUploadEnum'

export type UploadFileScreenConfigTP = {
    typeName: string
    requestsConfig: {
        searchRequestConfigTP: (code: number) => RequestConfigTP
        deleteRequestConfigTP?: (dto: any) => RequestConfigTP
        uploadRequestConfigTP?: (code: number, formData: FormData) => RequestConfigTP
    }
}

/**
 */
export class ScreenUploadFileUtils {

    /**
     */
    static getScreenConfigByType(type: ScreenFileUploadEnum): UploadFileScreenConfigTP | undefined {

        switch (type) {

            case ScreenFileUploadEnum.FINANCIAL_TRANSACTION_DEBIT:
                return {
                    typeName: ScreenFileUploadLabelEnum[type],
                    requestsConfig: {
                        searchRequestConfigTP: FinancialTransactionRequests.searchFiles,
                        deleteRequestConfigTP: FinancialTransactionRequests.deleteFile,
                        uploadRequestConfigTP: FinancialTransactionRequests.uploadFile,
                    }
                }

            case ScreenFileUploadEnum.SALE:
                return {
                    typeName: ScreenFileUploadLabelEnum[type],
                    requestsConfig: {
                        searchRequestConfigTP: SaleRequests.searchFiles,
                        deleteRequestConfigTP: SaleRequests.deleteFile,
                        uploadRequestConfigTP: SaleRequests.uploadFile,
                    }
                }

            default:
                return undefined
        }

        return undefined
    }

}
