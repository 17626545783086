import React from 'react'
import styled from 'styled-components'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { SaleDetailsPdfHeaderICP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfHeaderICP'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { SaleDetailsPdfFooterCP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfFooterCP'
import { SaleDetailsPdfItemsICP } from 'main/modules/sale/components/sale-details-pdf/inner/SaleDetailsPdfItemsICP'

interface ISaleDetailsPdfCPProps {
    sale: ISaleResponseDTO
}

/**
 * Componente com resumo de uma venda.
 * Usado para exibir detalhes de uma compra e como modelo para gerar um PDF.
 *
 * OBS: Há nesse componente alguns CSS inline porque isso facilitou, nesse caso, a organização do código.
 * Do contrário, seriam muitas styled.divs com pouco aproveitamento.
 */
export const SaleDetailsPdfCP = React.forwardRef((props: ISaleDetailsPdfCPProps, ref: any) => {

    return (
        <SaleDetailsPdfWrapperSCP ref={ref}>
            <SaleDetailsPdfHeaderICP
                type={SaleTypeEnum.SALE}
                code={props.sale.code}
                sellerName={props.sale.userSeller.name}
                status={props.sale.status}
                customerName={props.sale.personCustomer.name}
                saleDate={DateUtils.toDate(props.sale.date, DateFormatEnum.US_WITH_TIME_H_M)}
                totalInstallments={props.sale.installmentsQuantity}
                paymentMethods={props.sale.paymentMethods}
            />

            <SaleDetailsPdfItemsICP saleItems={props.sale.saleItems}/>

            <SaleDetailsPdfFooterCP
                type={SaleTypeEnum.SALE}
                totalValue={props.sale.saleTotalValuesData.totalValue}
                subtotalValue={props.sale.saleTotalValuesData.subtotalValue}
                totalPaidValue={props.sale.saleTotalValuesData.paidValue}
                balanceValue={props.sale.saleTotalValuesData.balance}
            />
        </SaleDetailsPdfWrapperSCP>
    )
})

const SaleDetailsPdfWrapperSCP = styled.div`  

  padding: 40px;

    display: grid;
    row-gap: 40px;

    .ant-typography {
        color: black;
        margin: 0;
    }
`
