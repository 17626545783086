import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetSessionEnum } from 'main/modules/dashboard/services/enums/WidgetSessionEnum'
import { WidgetCreatedLeadsCP } from 'main/modules/dashboard/components/widget-created-leads/WidgetCreatedLeadsCP'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets das performance de atendimentos.
 */
export function AttendantDashboardActualAttendancePerformanceICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'calendar'}>
                Agenda e Evoluções atuais
            </TitleCP>

            <WidgetsRowCP marginBottom={30}>
                <WidgetCreatedLeadsCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={{ beginDate: new Date(), endDate: new Date() }} extraTitle={'hoje'}/>
                <CardDashboardWidgetCP
                    title={'Clientes agendados para hoje'}
                    help={'Número de clientes agendados para avaliação hoje'}
                    requestConfigGetter={
                        DashboardRequests.getSessionWidgets(
                            WidgetSessionEnum.PLANNED_CUSTOMERS_TODAY,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />
                <CardDashboardWidgetCP
                    title={'Procedimentos agendados para hoje'}
                    help={'Número de procedimentos agendados para avaliação hoje. Um cliente pode ter mais de um procedimento agendado'}
                    requestConfigGetter={
                        DashboardRequests.getSessionWidgets(
                            WidgetSessionEnum.PLANNED_TREATMENTS_TODAY,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />
            </WidgetsRowCP>

            <WidgetsRowCP marginBottom={30}>
                <CardDashboardWidgetCP
                    title={'Clientes não evoluídos'}
                    help={'Número de clientes que possui sessões que não foram evoluídas'}
                    requestConfigGetter={
                        DashboardRequests.getSessionWidgets(
                            WidgetSessionEnum.CUSTOMERS_WITHOUT_EVOLUTION,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />

                <CardDashboardWidgetCP
                    title={'Tratamentos não evoluídos'}
                    help={'Número de procedimentos que não foram evoluídos'}
                    requestConfigGetter={
                        DashboardRequests.getSessionWidgets(
                            WidgetSessionEnum.TREATMENTS_WITHOUT_EVOLUTION,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                        )
                    }
                />
            </WidgetsRowCP>
        </>
    )
}
