import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISalesPerPeriodReportFilterRequestDTO } from 'main/modules/dashboard/services/dtos/request/ISalesPerPeriodReportFilterRequestDTO'
import { IAttendanceWorkShiftSearchRequestDTO } from 'main/modules/dashboard/services/dtos/request/IAttendanceWorkShiftSearchRequestDTO'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'
import { IActualIndicatorsRequestDTO } from 'main/modules/dashboard/services/dtos/request/IActualIndicatorsRequestDTO'
import { WidgetLeadEnum } from 'main/modules/dashboard/services/enums/WidgetLeadEnum'
import { WidgetEvaluationEnum } from 'main/modules/dashboard/services/enums/WidgetEvaluationEnum'
import { WidgetFollowUpEnum } from 'main/modules/dashboard/services/enums/WidgetFollowUpEnum'
import { WidgetOpportunityPerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetOpportunityPerformanceEnum'
import { IPerformanceIndicatorsRequestDTO } from 'main/modules/dashboard/services/dtos/request/IPerformanceIndicatorsRequestDTO'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { WidgetSessionEnum } from 'main/modules/dashboard/services/enums/WidgetSessionEnum'
import { WidgetSessionPerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSessionPerformanceEnum'
import { ITableIndicatorsRequestDTO } from 'main/modules/dashboard/services/dtos/request/ITableIndicatorsRequestDTO'
import { IOppsByFranchiseRequestDTO } from 'main/modules/dashboard/services/dtos/request/IOppsByFranchiseRequestDTO'
import { IEvaluationsByFranchiseRequestDTO } from 'main/modules/dashboard/services/dtos/request/IEvaluationsByFranchiseRequestDTO'
import { ITableIndicatorsRequestWithOrderingDTO } from 'main/modules/dashboard/services/dtos/request/ITableIndicatorsRequestWithOrderingDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo Dashboard
 */
export class DashboardRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/dashboard`

    static totalAttendanceByWorkshiftAndFranchise = (dto: IAttendanceWorkShiftSearchRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/attendance-work-shift-by-franchise`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static totalFranchises = (): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/total-franchises`,
        method: HttpMethodEnum.GET,
    })

    static totalCustomers = (): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/total-customers`,
        method: HttpMethodEnum.GET,
    })

    static billingPerformanceConfig = (dto: IDateRangeSearchRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/billing-performance`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static salesPerformanceConfig = (dto: IDateRangeSearchRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/sales-performance`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static salesPerPeriodConfig = (dto: ISalesPerPeriodReportFilterRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/sales-per-period`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getMap = (dto?: IDateRangeSearchRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/map`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getLeadWidgets = (type: WidgetLeadEnum, dto: IActualIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/leads/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getEvaluationWidgets = (type: WidgetEvaluationEnum, dto: IActualIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/evaluations/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getFollowupWidgets = (type: WidgetFollowUpEnum, dto: IActualIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/follow-ups/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSessionWidgets = (type: WidgetSessionEnum, dto: IActualIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/sessions/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOpportunityPerformanceWidgets = (type: WidgetOpportunityPerformanceEnum, dto: IPerformanceIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/opportunity-performance/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSalePerformanceWidgets = (type: WidgetSalePerformanceEnum, dto: IPerformanceIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/sale-performance/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSessionPerformanceWidgets = (type: WidgetSessionPerformanceEnum, dto: IPerformanceIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/widgets/session-performance/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getLeadsByUserData = (dto: ITableIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/leads-by-user`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getFollowupsByUserData = (dto: ITableIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/followups-by-user`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getEvaluationsByUserData = (dto: ITableIndicatorsRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/evaluations-by-user`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getLeadsByFranchise = (dto: IOppsByFranchiseRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/leads-by-franchise`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getEvaluationsByFranchise = (dto: IEvaluationsByFranchiseRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/evaluations-by-franchise`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getFollowupsByFranchise = (dto: IOppsByFranchiseRequestDTO): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/follow-ups-by-franchise`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getConsultantRanking = (dto: ITableIndicatorsRequestWithOrderingDTO<any>): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/seller-ranking`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getAttendantRanking = (dto: ITableIndicatorsRequestWithOrderingDTO<any>): RequestConfigTP => ({
        url: `${DashboardRequests._MODULE_BASE}/tables/technician-ranking`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
