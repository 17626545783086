import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { TableInnerCollaboratorICP } from 'main/modules/people/components/person-table/inner/components/TableInnerCollaboratorICP'
import { TableInnerPersonICP } from 'main/modules/people/components/person-table/inner/components/TableInnerPersonICP'
import { IPersonTableCPProps } from 'main/modules/people/components/person-table/inner/IPersonTableCPProps'
import { PersonTableContext } from 'main/modules/people/components/person-table/inner/PersonTableContext'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import React, { useEffect } from 'react'

/**
 * COMPONENTE
 * Controla as tabelas com registros de pessoas.
 */
export const PersonTableContentICP = ComponentUtils.memoizeCp((props: IPersonTableCPProps): JSX.Element => {

    const tableContext = PersonTableContext.use()

    // Sincroniza o context do CRM (geral) com o context da tabela (exclusivo)
    useEffect(() => tableContext.actions.setMustUpdate(props.mustUpdate), [props.mustUpdate])
    useEffect(() => tableContext.actions.setSegmentType(props.segmentType), [props.segmentType])
    useEffect(() => tableContext.actions.setSegmentExpression(props.segmentExpression), [props.segmentExpression])

    // Sincroniza o context da tabela (exclusivo) com context o do CRM (geral)
    useEffect(() => _openPersonDetailsDrawer(tableContext.state.showPersonDetailsDrawer), [tableContext.state.showPersonDetailsDrawer])
    useEffect(() => _openAddJobFunctionModal(tableContext.state.showAddJobFunctionModal), [tableContext.state.showAddJobFunctionModal])
    useEffect(() => props.onLoadingStateChange(tableContext.state.isLoading), [tableContext.state.isLoading])
    useEffect(() => props.onSelectPerson(tableContext.state.selectedPersonCode), [tableContext.state.selectedPersonCode])

    /*
        As functions abaixo são necessárias p/ resetar o context da tabela.
        Do contrário será possível, por exemplo, abrir drawers uma única vez
    */

    function _openPersonDetailsDrawer(showPersonDetailsDrawer: boolean): void {
        if (showPersonDetailsDrawer) {
            props.openPersonDetailsDrawer()
            tableContext.actions.setShowPersonDetailsDrawer(false)
        }
    }

    function _openAddJobFunctionModal(addJobFunctionModal: boolean): void {
        if (addJobFunctionModal) {
            props.openAddJobFunctionModal()
            tableContext.actions.setShowAddJobFunctionModal(false)
        }
    }

    if (!tableContext.state.segmentType)
        return <LoadingCP/>

    return tableContext.state.segmentType === SegmentTypeEnum.COLLABORATOR ? <TableInnerCollaboratorICP/> : <TableInnerPersonICP/>

})
