import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { OpportunityFormModel } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityFormModel'
import React from 'react'
import { InputOpportunityUniqueFieldsCP } from 'main/modules/sales-funnel/components/input-opportunity-unique-fields/InputOpportunityUniqueFieldsCP'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'

interface IOpportunityDataFormCPProps {
    formStateManager: IFormStateManager<OpportunityFormModel>
    allowEdition: boolean
    loadedOpportunity?: IOpportunityResponseDTO
}

/**
 * COMPONENTE
 * Wrapper para campos básicos da criação de um novo lead
 */
export function OpportunityBasicDataFormCP(props: IOpportunityDataFormCPProps): JSX.Element {
    return (
        <>
            <InputCP
                placeholder={'Nome do lead *'}
                fontSize={'extraLarge'}
                required={true}
                icon={<FontAwsomeIconCP type={faUserCircle} color={ThemeAnt.primaryColor} size={'1x'}/>}
                prefix={true}
                fieldName={'name'}
                formStateManager={props.formStateManager}
                disabled={!props.allowEdition}
            />

            <InputOpportunityUniqueFieldsCP
                placeholder={'Telefone'}
                disabled={!props.allowEdition}
                fieldName={'phone'}
                formStateManager={props.formStateManager}
                loadedOpportunity={props.loadedOpportunity}
                type={'phone'}
            />

            <InputOpportunityUniqueFieldsCP
                placeholder={'E-mail'}
                disabled={!props.allowEdition}
                fieldName={'email'}
                formStateManager={props.formStateManager}
                loadedOpportunity={props.loadedOpportunity}
                type={'email'}
            />
        </>
    )
}
