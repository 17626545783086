import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { ISearchProductReleaseRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'
import { IProductReleaseResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseResponseDTO'
import { ColumnFilterTP } from 'common/old-refactory/table/TableCPOLD'
import { ColumnFilterTypeEnum } from 'common/old-refactory/table/inner/ColumnFilterTypesEnum'

/**
 * Utils da tabela de product releases
 */
export const ProductReleaseTableUtils = {

    getTableColumns(
        setFilters: React.Dispatch<React.SetStateAction<ISearchProductReleaseRequestDTO>>,
        filters: ISearchProductReleaseRequestDTO,
        onEdit: (code: number) => void
    ): Array<ColumnProps<IProductReleaseResponseDTO>> {

        return [
            {
                title: 'Cód.',
                key: 'code',
                render: (text, item) => item.code
            },
            {
                title: 'Categoria',
                key: 'cat',
                render: (text, item) => item.product.name
            },
            {
                title: 'Pacote',
                key: 'name',
                render: (text, item) => item.name
            },
            {
                title: 'Atendimentos',
                key: 'service',
                render: (text, item) => {
                    return item.treatmentsConfig.map((service: IProductReleaseTreatmentConfigResponseDTO) => (
                        <TagCP
                            key={service.treatment.name}
                            color={'purple'}
                            onClick={() => setFilters({ ...filters, treatment: service.treatment.name })}
                            content={`${service.totalSessions} x ${service.treatment.name}`}
                        />
                    ))
                },
            },
            {
                key: 'actions',
                align: 'right',
                render: (text, item) => (
                    <ButtonCP
                        type={'primary'}
                        icon={'edit'}
                        size={'small'}
                        onClick={() => onEdit(item.code)}
                    />
                )
            }
        ]
    },

    getTableFilters(
        setFilters: React.Dispatch<React.SetStateAction<ISearchProductReleaseRequestDTO>>,
        filters: ISearchProductReleaseRequestDTO
    ): ColumnFilterTP[] {

        return [
            {
                type: ColumnFilterTypeEnum.SEARCH,
                dataIndex: 'name',
                colKey: 'name',
                onSearch: (text) => setFilters({ ...filters, page: 1, searchString: text }),
                onReset: () => setFilters({ ...filters, page: 1, treatment: '' }),
                placeholder: 'Filtrar pacotes'
            },
            {
                type: ColumnFilterTypeEnum.SEARCH,
                dataIndex: 'service',
                colKey: 'service',
                onSearch: (text) => setFilters({ ...filters, page: 1, treatment: text }),
                onReset: () => setFilters({ ...filters, page: 1, treatment: '' }),
                placeholder: 'Filtrar Atendimentos',
            }
        ]
    },

}
