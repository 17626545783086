import { AppStateUtils } from "main/common/utils/AppStateUtils"
import React, { useEffect, useState } from "react";
import axios from 'axios'
import { notification } from 'antd';
import { Button } from 'antd'


export function Boleto(): JSX.Element {
    const [boleto,setBoleto] = useState(null);
    useEffect(() => {
        const slug = AppStateUtils.getDomainSlug();
        const franchise = AppStateUtils.getCurrentFranchise();
        const dt = {schema:slug,id_unidade:franchise?.code};
        axios.post('https://financeiro.lumiacrm.com.br/api/buscarCobrancasAbertoPorUnidade',dt).then(response => {
            const dados = response.data;
            if(dados.length > 0){
                setBoleto(dados[0].pdf);
            }else{
                setBoleto(null);
            }
        }).catch(error => {
            console.log("BOLETO",error);
            setBoleto(null);
        });
    },[]);
    if(!boleto)return <></> ;
    const caminho: string = boleto+'';
    const abrirBoleto = () =>{
        window.open(caminho,'_blank');
    }
    notification.config({
        placement: 'bottomRight',
      });
    
    notification.open({
        message: 'Boleto em aberto',
        description: (
          <div>
            <b>Você possui um boleto em aberto.</b>
            <div style={{'textAlign':'right','marginTop':'12px'}}>
                <Button type="primary" onClick={abrirBoleto}>Gerar Boleto</Button>
            </div>
          </div>
        ),
        duration: 8,
      });
    return (
        <>
        </>
    )

}