import { ContextFactory } from 'main/common/context-factory/ContextFactory'
import { CtxFactoryReducerTP } from 'main/common/context-factory/types/CtxFactoryReducerTP'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import * as _ from 'lodash'

type ActionTypeTP = | 'setId' | 'setField' | 'setOperator' | 'setValue' | 'setIsLastUnit' | 'setIsLoadingValueWithLabel' | 'setIsSelecting'

type StateTP = {

    id: string,
    value: SegmentUnitValueWithLabelTP[],
    valueChangingCount: number,

    isLastUnit: boolean,
    isLoadingValueWithLabel: boolean,
    isSelected: boolean,
    isSelecting: boolean,

    field?: SegmentUnitFieldsEnum,
    operator?: SegmentUnitCompareOperatorsEnum,
}

const INITIAL_STATE: StateTP = {
    id: '',
    value: [],
    valueChangingCount: 0,
    isLastUnit: false,
    isLoadingValueWithLabel: false,
    isSelected: false,
    isSelecting: false,
}

const reducer: CtxFactoryReducerTP<StateTP, ActionTypeTP> = (currentState, action) => {

    const field = action.payload?.field ?? currentState.field
    const operator = action.payload?.operator ?? currentState.operator

    return {
        ...currentState,
        ...action.payload,
        field,
        operator,
        isSelected: SegmentUtils.isUnitSelected(field, operator),
    }
}

const actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP> = actionHelper => ({

    setId: (id: string) => actionHelper.replaceWithPayload({ id }),
    setIsSelecting: (isSelecting: boolean) => actionHelper.replaceWithPayload({ isSelecting }),
    setField: (field: SegmentUnitFieldsEnum) => actionHelper.replaceWithPayload({ field }),
    setOperator: (operator: SegmentUnitCompareOperatorsEnum) => actionHelper.replaceWithPayload({ operator }),
    setIsLastUnit: (isLastUnit: boolean) => actionHelper.replaceWithPayload({ isLastUnit }),
    setIsLoadingValueWithLabel: (isLoadingValueWithLabel: boolean) => actionHelper.replaceWithPayload({ isLoadingValueWithLabel }),

    setValue: (value: SegmentUnitValueWithLabelTP[]) => actionHelper.replaceWithPayload({
        value,
        valueChangingCount: (actionHelper.state.valueChangingCount + Number(!_.isEqual(value, actionHelper.state.value)))
    }),
})

/**
 * CONTEXTO: Agregador de expressao de lista de segmentacao
 * Provider + Hook para acesso ao contexto de estado global de 01 unidade de expressao de lista de segmentacao.
 *
 * @see AggregatorICP
 */
export const UnitContext = ContextFactory.getOne<StateTP, ActionTypeTP>({
    contextName: 'Unidade (Lista de Segmentacao)',
    initialStateGetter: () => INITIAL_STATE,
    actionBuilder,
    reducer,
    enableDebug: false,
})
