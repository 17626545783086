import React, { useEffect, useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { CardCP } from 'main/common/components/card/CardCP'
import { ChartLineOrBarCP, ChartSeriesTP } from 'main/common/components/chart/ChartLineOrBarCP'
import { BankAccountScreenContentUtils } from 'main/modules/financial/screens/screen-financial/content/screen-content-bank-account/inner/BankAccountScreenContentUtils'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import moment from 'moment'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IBalancePerPeriodListItemResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBalancePerPeriodListItemResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IBalancePerPeriodFilterRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IBalancePerPeriodFilterRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { BankAccountRequests } from 'main/modules/financial/services/bank-account/BankAccountRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { BalancePerPeriodChartUtils } from 'main/modules/financial/components/balance-per-period-chart/inner/BalancePerPeriodChartUtils'
import { TextCP } from 'main/common/components/text/TextCP'

const FILTER_DEFAULT_BEGIN: moment.Moment = moment().startOf(TimeBaseEnum.MONTH).add(-6, TimeBaseEnum.MONTH)
const FILTER_DEFAULT_END: moment.Moment = moment()

interface IBalancePerPeriodChartCPProps {
    type: BankAccountTypeEnum
}

/**
 * COMPONENTE
 * Grafico de saldo na conta nos ultimos meses
 */
export function BalancePerPeriodChartCP(props: IBalancePerPeriodChartCPProps): JSX.Element {

    const screensize = useScreenSize()

    const [dateRange] = useState<RangePickerValue>([FILTER_DEFAULT_BEGIN, FILTER_DEFAULT_END])
    const [graphSeries, setGraphSeries] = useState<ChartSeriesTP[]>([])
    const [graphXAxis, setGraphXAxis] = useState<string[]>([])

    const [balancePerPeriod, setBalancePerPeriod] = useState<IBalancePerPeriodListItemResponseDTO[]>()
    const balancePerPeriodRequest = useRequest<IGenericListResponseDTO<IBalancePerPeriodListItemResponseDTO>>()

    useEffect(defineBalancePerPeriod, [props.type, dateRange])
    useEffect(onBalancePerPeriodRequestChange, [balancePerPeriodRequest.isAwaiting])
    useEffect(prepareChartData, [dateRange, balancePerPeriod])

    /**
     * Req. para buscar saldo.
     */
    function defineBalancePerPeriod(): void {

        const [beginDate, endDate] = dateRange
        if (!beginDate || !endDate)
            return

        const filtersDto: IBalancePerPeriodFilterRequestDTO = {
            timeBase: TimeBaseEnum.MONTH,
            beginDate: DateUtils.getFormatted(beginDate.toDate(), DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(endDate.toDate(), DateFormatEnum.US_WITHOUT_TIME),
        }

        balancePerPeriodRequest.runRequest(BankAccountRequests.balancePerPeriodConfig(props.type, filtersDto))
    }

    /**
     * Retorno da busca de saldo.
     */
    function onBalancePerPeriodRequestChange(): void {
        if (RequestUtils.isValidRequestReturn(balancePerPeriodRequest, 'Erro saldo nos ultimos meses'))
            setBalancePerPeriod(balancePerPeriodRequest.responseData?.list ?? [])
    }

    function prepareChartData(): void {

        // Monta eixo X iterando sobre o intervalo de datas selecionado
        const xAxisDates = BalancePerPeriodChartUtils.getXAxisDates(dateRange, TimeBaseEnum.MONTH)
        const dateFormat = DateFormatEnum.BR_MONTH3_YEAR4
        setGraphXAxis(xAxisDates.map((date) => date.format(dateFormat)))

        setGraphSeries([{
            name: 'Total de Cadastros no Mês',
            data: BalancePerPeriodChartUtils.getYAxisCounts(xAxisDates, balancePerPeriod ?? [])
        }])
    }

    return (
        <CardCP
            innerSpacing={'none'}
            title={
                <HeaderSCP isSmallDevice={screensize.smd}>
                    <TextCP text={BankAccountScreenContentUtils.getChartLabel(props.type)}/>
                </HeaderSCP>
            }
            isLoading={balancePerPeriodRequest.isAwaiting}
        >
            <ChartLineOrBarCP
                xAxis={graphXAxis}
                ySeries={graphSeries}
                type={'line'}
                height={'150px'}
            />
        </CardCP>
    )
}

const HeaderSCP = styled.div<{ isSmallDevice: boolean }>`
    display: ${props => !props.isSmallDevice ? 'flex' : ''};
    justify-content: space-between;
`

