import React from 'react'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { SelectBankCP } from 'main/modules/financial/components/select-bank/SelectBankCP'
import { SelectCreditCardFlagCP } from 'main/modules/financial/components/select-credit-card-flag/SelectCreditCardFlagCP'
import { SelectPaymentPartnerCP } from 'main/modules/financial/components/select-payment-partner/SelectPaymentPartnerCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { IFormModelValidator } from 'main/common/interfaces/IFormModelValidator'

interface IBankAccountDrawerCPProps<FModelTP extends IFormModelValidator> {
    formStateManager: IFormStateManager<FModelTP>
    type: BankAccountTypeEnum
}

/**
 * Drawer de dados de uma conta bancaria
 */
export function SelectBankByTypeCP<FModelTP extends IFormModelValidator>(props: IBankAccountDrawerCPProps<FModelTP>): JSX.Element {

    return (
        <>
            {
                props.type === BankAccountTypeEnum.BANK_ACCOUNT &&
                <SelectBankCP
                    formStateManager={props.formStateManager}
                />
            }
            {
                props.type === BankAccountTypeEnum.CREDIT_CARD &&
                <SelectCreditCardFlagCP
                    formStateManager={props.formStateManager}
                />
            }
            {
                props.type === BankAccountTypeEnum.PAYMENT_ACCOUNT &&
                <SelectPaymentPartnerCP
                    formStateManager={props.formStateManager}
                />
            }
        </>
    )
}
