import { ModalCP } from 'main/common/components/modal/ModalCP'
import React from 'react'
import { CollapseSessionFormHistoryCP } from 'main/modules/scheduler/components/collapse-session-form-history/CollapseSessionFormHistoryCP'

interface IModalSessionFormCPProps {
    visible: boolean
    onCancel: () => void
    customerCode: number
}

/**
 * Componente de modal com historico de sessoes de um cliente
 */
export function ModalSessionHistoryCP(props: IModalSessionFormCPProps): JSX.Element {

    return (
        <ModalCP
            title={'Histórico de sessões já realizadas'}
            visible={props.visible}
            onCancel={props.onCancel}
            noFooter={true}
            width={'90vw'}
        >
            <CollapseSessionFormHistoryCP personCode={props.customerCode}/>
        </ModalCP>
    )
}
