import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { SystemConfig } from 'main/config/SystemConfig'
import React from 'react'
import Intercom from 'react-intercom'
import { EnvironmentEnum } from 'main/common/enums/EnvironmentEnum'

/**
 * Encapsula dados para integracao com intercom
 */
export function IntercomCP(): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUserData()
    const domainData = AppStateUtils.getDomainData()

    if (SystemConfig.getInstance().environment === EnvironmentEnum.DEV)
        return <></>

    return (
        <Intercom
            appID={SystemConfig.getInstance().intercomId}
            name={loggedUser?.user.name}
            email={loggedUser?.user.email}
            user_id={!!loggedUser ? `${domainData?.schema.slug}.${loggedUser?.user.email || ''}` : undefined}
            produto_vx={'VX Clinic'}
            company={domainData ? {
                id: domainData.schema.slug,
                schema: domainData.schema.slug,
                name: domainData.schema.name,
                plan: domainData.schema.plan.name
            } : undefined}
        />
    )
}
