import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import React from 'react'
export function FrameZap():JSX.Element{
    const codigoFranquia = AppStateUtils.getCurrentFranchise()?.code;
    const schema = AppStateUtils.getDomainSlug();
    if(!codigoFranquia || !schema)return <div>Não pode ser carregado</div>

    return (
        <iframe src={`https://whats.lumiacrm.com.br/#/whatsCadastro/${codigoFranquia}/${schema}`} style={{height:'calc(100vh - 105px)',border: '0',width: 'calc(100% + 60px)',marginLeft:'-30px'}}></iframe>
    )
}