import React, { useState } from 'react'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ModalSendBullkMessageCP } from 'main/modules/people/components/modal-send-bulk-message/ModalSendBullkMessageCP'

interface ICPProps {
    selectedPeople: number[]
}

/**
 * Conteudo da barra de acoes da area de listagem de pessoas.
 */
export function LeftContentICP(props: ICPProps): JSX.Element {

    const [isSendMessageModalVisible, setIsSendMessageModalVisible] = useState<boolean>(false)

    if (ArrayUtils.isEmpty(props.selectedPeople))
        return <></>

    return (
        <FlexCP>
            <TextCP text={`${props.selectedPeople.length} selecionadas`} size={'large'}/>

            <ButtonCP
                type={'dashed'}
                marginLeft={10}
                tooltip={'Enviar mensagem para os contatos selecionados'}
                icon={'message'}
                onClick={() => setIsSendMessageModalVisible(true)}
            >
                Enviar mensagem
            </ButtonCP>

            <ModalSendBullkMessageCP
                selectedPeople={props.selectedPeople}
                visible={isSendMessageModalVisible}
                onClose={() => setIsSendMessageModalVisible(false)}
            />
        </FlexCP>
    )

}
