import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { OpportunitySourceFormICP } from 'main/modules/products/components/drawer-opportunity-source/inner/OpportunitySourceFormICP'
import { OpportunitySourceFormModel } from 'main/modules/products/components/drawer-opportunity-source/inner/OpportunitySourceFormModel'
import { IOpportunitySourceSaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/request/IOpportunitySourceSaveRequestDTO'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { OpportunitySourceRequests } from 'main/modules/sales-funnel/services/opportunity-source/OpportunitySourceRequests'

interface IDrawerTreatmentCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    opportunitySourceCode?: number
}

/**
 * Tela de listagem de tratamentos.
 */
export function DrawerOpportunitySourceCP(props: IDrawerTreatmentCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<OpportunitySourceFormModel>((new OpportunitySourceFormModel()))
    const formStateManager = useFormStateManager<OpportunitySourceFormModel>(formValidator)

    const getSourceRequest = useRequest<IOpportunitySourceResponseDTO>()
    useEffect(onGetSourceRequestChange, [getSourceRequest.isAwaiting])

    const saveSourceRequest = useRequest<IOpportunitySourceResponseDTO>()
    useEffect(onSaveSourceRequestChange, [saveSourceRequest.isAwaiting])

    const deleteSourceRequest = useRequest<void>('none')
    useEffect(onDeleteSourceRequestChange, [deleteSourceRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        formStateManager.reset(new OpportunitySourceFormModel({}))
        if (!props.visible)
            return

        if (props.opportunitySourceCode) {
            getSourceRequest.runRequest(OpportunitySourceRequests.getOpportunitySource(props.opportunitySourceCode))
            return
        }

        setFormValidator(new OpportunitySourceFormModel({
            isActive: true,
            hasDetail: false,
        }))
    }

    /**
     * Retorno da requisicao que obtem o origem selecionada.
     */
    function onGetSourceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSourceRequest, 'Erro obter origem do lead'))
            return

        const responseDTO = getSourceRequest.responseData!
        setFormValidator(new OpportunitySourceFormModel({
            name: responseDTO.name,
            isActive: responseDTO.isActive,
            hasDetail: responseDTO.hasPersonLink,
        }))
    }

    /**
     * Salva origem do lead.
     */
    async function saveSource(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: IOpportunitySourceSaveRequestDTO = {
            name: formValues.name,
            isActive: formValues.isActive ?? false,
            hasPersonLink: formValues.hasDetail ?? false,
        }

        if (!!props.opportunitySourceCode)
            saveSourceRequest.runRequest(OpportunitySourceRequests.updateOpportunitySource(props.opportunitySourceCode, dto))
        else
            saveSourceRequest.runRequest(OpportunitySourceRequests.createOpportunitySource(dto))
    }

    /**
     * Remover conta.
     */
    function deleteSource(): void {

        if (!props.opportunitySourceCode)
            return

        deleteSourceRequest.runRequest(OpportunitySourceRequests.deleteOpportunitySource(props.opportunitySourceCode))
    }

    /**
     *  Retorno da remocao de origem do LEAD.
     */
    function onDeleteSourceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteSourceRequest, 'Não foi possível remover a orgiem do LEAD', true))
            return

        NotificationHelper.info('Pronto', 'Origem do LEAD foi removida')
        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveSourceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveSourceRequest, 'Ocorreu algun erro ao salvar origem do LEAD'))
            return

        NotificationHelper.info('Yes', 'Origem do LEAD salvo com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Origem do LEAD / Venda'}
            visible={props.visible}
            width={300}
            onClose={props.onClose}
            loading={getSourceRequest.isAwaiting}
            footerSpaced={!!props.opportunitySourceCode}
            footer={
                <>
                    <ConditionalRenderCP shouldRender={!!props.opportunitySourceCode}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteSourceRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover este atendimento?'}
                            onClick={deleteSource}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveSource}
                        loading={saveSourceRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }
        >

            <OpportunitySourceFormICP
                formStateManager={formStateManager}
            />

        </DrawerCP>
    )
}
