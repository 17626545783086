import { ModalCP } from 'main/common/components/modal/ModalCP'
import React from 'react'
import { FinancialTransactionRequests } from 'submodules/neritclin-sdk/services/financial/financial/FinancialTransactionRequests'
import { ListFilesFromApiCP } from 'submodules/nerit-framework-ui/common/components/file/list-files-from-api/ListFilesFromApiCP'
import { SystemConfig } from 'main/config/SystemConfig'
import { PublicRouter } from 'main/modules/app/routes/PublicRouter'
import { PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION, PARAM_SCREEN_UPLOAD_FILES_CODE, PARAM_SCREEN_UPLOAD_FILES_TYPE } from 'main/modules/app/screens/screen-upload-files/ScreenUploadFiles'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ScreenFileUploadEnum } from 'main/modules/app/screens/screen-upload-files/inner/ScreenFileUploadEnum'

interface ICPProps {
    visible: boolean
    financialTransactionCode: number
    onClose: () => void
}

/**
 * Componente de modal com arquivos.
 */
export function ModalFinancialTransactionFilesCP(props: ICPProps): JSX.Element {

    /**
     * Monta a url de upload quando leitura do qrcode
     */
    function getUploadByQrCodeUrl(): string {

        let url = `${SystemConfig.getInstance().uiBaseUrl}${PublicRouter.UPLOAD_FILES}`

        url = `${url}?${PARAM_SCREEN_UPLOAD_FILES_CODE}=${props.financialTransactionCode}`
        url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION}=${AppStateUtils.getLoggedUserData()!.token}`
        url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_TYPE}=${ScreenFileUploadEnum.FINANCIAL_TRANSACTION_DEBIT}`

        return url
    }

    return (
        <ModalCP
            title={'Arquivos'}
            width={600}
            visible={props.visible}
            onCancel={props.onClose}
            noFooter={true}
        >
            <ListFilesFromApiCP
                entityCode={props.financialTransactionCode}
                requestsConfig={{
                    searchRequestConfigTP: FinancialTransactionRequests.searchFiles,
                    deleteRequestConfigTP: FinancialTransactionRequests.deleteFile,
                    uploadRequestConfigTP: FinancialTransactionRequests.uploadFile,
                }}
                qrCode={{
                    visible: true,
                    url: getUploadByQrCodeUrl()
                }}
            />
        </ModalCP>
    )
}
