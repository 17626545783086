import React from 'react'
import { FileIconCP } from 'main/common/components/icons/FileIconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import styled from 'main/config/theme/styledWithTheme'
import { DokfySignatureSecurityLevelEnum } from 'main/modules/documents/services/dokfy/enums/DokfySignatureSecurityLevelEnum'
import { DokfySignatureSecurityLevelUtils } from 'main/modules/documents/services/dokfy/utils/DokfySignatureSecurityLevelUtils'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
}

interface IAssigneeTableCPProps extends _ScpPropsTP {
    secLevel: DokfySignatureSecurityLevelEnum
    showTooltip: boolean
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function SignatureSecurityLevelIconCP(props: IAssigneeTableCPProps): JSX.Element | null {

    function getIcon(): JSX.Element {

        let antIconName: 'check' | 'verified' | 'add' | undefined
        switch (props.secLevel) {

            case DokfySignatureSecurityLevelEnum.INVITE:
                antIconName = 'add'
                break

            case DokfySignatureSecurityLevelEnum.SIMPLE_REGISTER:
                antIconName = 'check'
                break

            case DokfySignatureSecurityLevelEnum.FULL_REGISTER:
                antIconName = 'verified'
                break

            default:
                break

        }

        if (!antIconName)
            return <></>

        return <FileIconCP type={antIconName}/>
    }

    return (
        <WrapperSCP
            marginRight={props.marginRight ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            {
                !!props.showTooltip
                    ?
                    <TooltipCP
                        text={DokfySignatureSecurityLevelUtils.getSignatureSecurityLevelDescription(DokfySignatureSecurityLevelEnum.SIMPLE_REGISTER)}
                    >
                        { getIcon() }
                    </TooltipCP>
                    :
                    getIcon()
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `0 ${props.marginRight!}px 0 ${props.marginLeft!}px`};
`
