import { Tabs } from 'antd'
import styled from 'main/config/theme/styledWithTheme'
import * as React from 'react'
import { TabItemTP } from 'main/common/components/tabs/inner/TabItemTP'

interface ITabsCPProps {
    tabs: TabItemTP[]
    defaultActiveKey?: string
    type?: 'line' | 'card' | 'editable-card'
    tabPosition?: 'top' | 'right' | 'bottom' | 'left'
    extraContent?: JSX.Element
}

/**
 * Componente de abas customizado para o projeto.
 */
export function TabsCP(props: ITabsCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <Tabs
                type={props.type}
                defaultActiveKey={props.defaultActiveKey}
                tabPosition={props.tabPosition}
                animated={false} // AntD esta com bug quando tem animacao e conteudo dinamico, por isso passando sempre fasle
                tabBarExtraContent={props.extraContent}
            >
                {
                    props.tabs.filter(tab => !tab.hide).map((tab: TabItemTP) => (
                        <Tabs.TabPane tab={tab.title} key={tab.key} disabled={tab.disabled}>
                            {tab.content}
                        </Tabs.TabPane>
                    ))
                }
            </Tabs>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
  .ant-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav {
    .ant-tabs-ink-bar {
      background: ${props => props.theme.primaryColor};
      height: 3px;
    }

    .ant-tabs-tab {
      color: ${props => props.theme.black};
      font-weight: 500;
    }

    .ant-tabs-tab-active {
      color: ${props => props.theme.primaryColor};
      font-weight: 500;
    }
  }

  .ant-tabs .ant-tabs-extra-content .ant-tabs-new-tab {
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.normalColor};
    color: ${props => props.theme.black};
    border-radius: 3px;
  }

  .ant-tabs-extra-content {
    margin: 0;
  }

  .ant-tabs-bar {
    margin: 0;
  }
  
  .ant-tabs-content {
    margin-top: 20px;
  }
  
  .ant-tabs-extra-content {
    margin: 20px;
  }
`
