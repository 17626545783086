import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import {
    SalesFunnelSalesViewEnum,
    SalesFunnelSalesViewLabelEnum
} from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelSalesViewEnum'
import { SalesFiltersCP } from 'main/modules/sales-funnel/components/sales-filters/SalesFiltersCP'
import { SalesSummaryHeaderCP } from 'main/modules/sale/components/sales-summary-header/SalesSummaryHeaderCP'
import { TableSalesCP } from 'main/modules/sale/components/table-sales/TableSalesCP'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import moment from 'moment'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    initialUser?: INameAndCodeResponseDTO
    onDataChange: (user?: INameAndCodeResponseDTO) => void
}

/**
 * COMPONENTE Conteudo de vendas.
 */
export function ScreenContentSalesFunnelSalesCP(props: ICPProps): JSX.Element {

    const routeParams = useParams<{ view: SalesFunnelSalesViewEnum }>()

    const [formValidator] = useState<SalesFiltersFormModel>((new SalesFiltersFormModel({
        userSellerCode: props.initialUser?.code,
        userSellerName: props.initialUser?.name,
        considerPppAsSale: routeParams.view === SalesFunnelSalesViewEnum.SALES_ALL,
        status: routeParams.view === SalesFunnelSalesViewEnum.SALES_DELAYED ? SaleStatusEnum.DEFAULTING : undefined,
        dateRange: getDateIntervalByView(),
    })))
    const formStateManager = useFormStateManager<SalesFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)
    const [shouldLoadSummary, setShouldLoadSummary] = useState<boolean>(false)

    useEffect(onChangeRoute, [routeParams.view])

    /**
     * Ao mudar a roda
     */
    function onChangeRoute(): void {

        formStateManager.changeFieldValue('considerPppAsSale', routeParams.view === SalesFunnelSalesViewEnum.SALES_ALL)
        formStateManager.changeFieldValue('status', routeParams.view === SalesFunnelSalesViewEnum.SALES_DELAYED ? SaleStatusEnum.DEFAULTING : undefined)
        formStateManager.changeFieldValue('dateRange', getDateIntervalByView())

        setShouldLoadReport(true)
        setShouldLoadSummary(true)
    }

    /**
     * Retorna o DateRange baseado no view da Rota.
     */
    function getDateIntervalByView(): IDateRangeFilter {

        const view: SalesFunnelSalesViewEnum = routeParams.view

        let beginDateFormatted: Date | undefined
        let endDateFormatted: Date | undefined
        switch (view) {
            case SalesFunnelSalesViewEnum.SALES_TODAY:
                beginDateFormatted = moment().startOf('day').toDate()
                endDateFormatted = moment().endOf('day').toDate()
                break

            case SalesFunnelSalesViewEnum.SALES_MONTH:
                beginDateFormatted = moment().startOf('month').toDate()
                endDateFormatted = moment().endOf('month').toDate()
                break

            case SalesFunnelSalesViewEnum.SALES_ALL:
            case SalesFunnelSalesViewEnum.SALES_DELAYED:
            default:
                beginDateFormatted = undefined
                endDateFormatted = undefined
                break

        }

        return { beginDate: beginDateFormatted, endDate: endDateFormatted }
    }

    /**
     * Ao alterar algum dado interno, aqui sera tratado o que deve ser recarregado
     */
    function onDataChanged(type: 'dataLoaded' | 'reloadData'): void {

        if (type === 'dataLoaded') {
            setShouldLoadReport(false)
            return
        }

        setShouldLoadReport(true)
        setShouldLoadSummary(true)

        let respNameAndCode
        if (!!formStateManager.getFieldValue('userSellerCode') && !!formStateManager.getFieldValue('userSellerName'))
            respNameAndCode = { code: formStateManager.getFieldValue('userSellerCode'), name: formStateManager.getFieldValue('userSellerName') }

        props.onDataChange(respNameAndCode)
    }

    return (
        <ContentCP>
            <HeaderCP title={SalesFunnelSalesViewLabelEnum[routeParams.view]}/>

            <MainContentWrapperSCP>
                <FiltersSCP>
                    <SalesFiltersCP
                        formStateManager={formStateManager}
                        onFilter={() => onDataChanged('reloadData')}
                        onClearFilters={() => onDataChanged('reloadData')}
                        isLoading={shouldLoadReport}
                    />
                </FiltersSCP>

                <SalesSummaryHeaderCP
                    view={routeParams.view}
                    formStateManagerFilters={formStateManager}
                    onChangeStatus={() => setShouldLoadReport(true)}
                    onLoadData={() => setShouldLoadSummary(false)}
                    shouldLoadData={shouldLoadSummary}
                />

                <TableSalesCP
                    filtersFormStateManager={formStateManager}
                    mustUpdateSalesTable={shouldLoadReport}
                    onLoadDataChanged={() => setShouldLoadReport(false)}
                />
            </MainContentWrapperSCP>
        </ContentCP>
    )
}

const MainContentWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    @media(max-width: 1280px){
        width: 73vw;
    }
`

const FiltersSCP = styled.div`
  margin: 20px 20px 0 20px;
`
