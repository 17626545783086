import React from 'react'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { HelpCP } from 'main/common/components/help/HelpCP'

interface ICPProps {
    referenceDate: string
}

/**
 * Coluna com a data de referencia da transacao.
 */
export function ColumnTransactionReferenceDateCP(props: ICPProps): JSX.Element {

    return (
        <>
            { DateUtils.getFormatted(props.referenceDate, DateFormatEnum.BR_WITHOUT_TIME) }
            <HelpCP
                text={'Data original de vencimento da fatura. Caso a forma de pagamento seja cartão de crédito, esta data é o vencimento para o cliente ' +
                'e a data de vencimento será acrescida de 30 dias, período de repasse.'}
                type={'tooltip'}
                align={'flex-start'}
            />
        </>
    )

}
