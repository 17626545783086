/**
 * ENUM
 * Status de uma venda
 */
export enum SaleStatusEnum {

    /** Rêm parcelas pagas (PAID) e parcelas em aberto(UNPAID) não vencidas. */
    OK = 'OK',

    /** Quando todas parcelas da venda não estão pagas (UNPAID) e essas parcelas não estão atrasadas. */
    PENDING = 'PENDING',

    /** Caso tenha parcelas não pagas (UNPAID) e atrasadas. */
    DEFAULTING = 'DEFAULTING',

    /** Quando todas as parcelas estão pagas (PAID). */
    CONCLUDED = 'CONCLUDED',

    /** É uma venda cancelada, não precisa mais ser faturada. */
    CANCELLED = 'CANCELLED'
}

/**
 * Labels do Enum de status de venda.
 */
export enum SaleStatusToNameEnum {
    OK = 'Em dia',
    PENDING = '1º Pagamento Pendente',
    DEFAULTING = 'Inadimplente',
    CONCLUDED = 'Pago',
    CANCELLED = 'Cancelado'
}