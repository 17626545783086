import { faCheckDouble, faClock, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { IMultiActionButtonOption } from 'main/common/components/multi-action-button/inner/IMultiActionButtonOption'
import { MultiActionButtonCP } from 'main/common/components/multi-action-button/MultiActionButtonCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React from 'react'

interface IReportActionsBtnGroupCPProps {
    onSendShotNow: () => void
    onScheduleShot: () => void
    onSaveDraft: () => void
    disabled?: boolean
}

/**
 * COMPONENTE Botao com acoes principais relativas de envio de disparo.
 *
 * @author renatofs
 */
export function NewShotActionsBtnGroupCP(props: IReportActionsBtnGroupCPProps): JSX.Element {

    const screenSize = useScreenSize()

    const options: IMultiActionButtonOption[] = [
        {
            icon: <FontAwsomeIconCP type={faCheckDouble} color={ThemeAnt.primaryColor}/>,
            label: screenSize.smd ? 'Enviar' : 'Enviar agora',
            onClick: props.onSendShotNow
        },
        {
            icon: <FontAwsomeIconCP type={faClock} color={ThemeAnt.primaryColor}/>,
            label: 'Agendar Envio',
            onClick: props.onScheduleShot
        },
        {
            icon: <FontAwsomeIconCP type={faPencilAlt} color={ThemeAnt.primaryColor}/>,
            label: 'Salvar Rascunho',
            onClick: props.onSaveDraft
        }
    ]

    return <MultiActionButtonCP options={options} disabled={props.disabled}/>
}
