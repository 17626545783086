import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'

export class PaymentConfigRequests {

	public static CONTROLLER_ROOT = 'payment-configs'
	public static FIND_ACTIVE_PAYMENT_METHODS_EP = 'find-active-payment-methods'


	static findActivePaymentMethods = (): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${PaymentConfigRequests.CONTROLLER_ROOT}/${PaymentConfigRequests.FIND_ACTIVE_PAYMENT_METHODS_EP}`,
		method: HttpMethodEnum.GET,
	})
}
