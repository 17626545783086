/**
 * Objeto de parametrizacao para componente de grafico de barras generico.
 */
export const BAR_STACK_CHART_CONFIG_OPTIONS = {
    chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        height: 150,
        width: '100%',
        toolbar: {
            show: false,
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
        }
    },
    stroke: {
        width: 2,
        colors: ['#fff']
    },
    series: [],
    tooltip: {
        y: {
            formatter: (val: any) => {
                return `${String(val)} leads`
            }
        }
    },
    fill: {
        opacity: 1
    },
    yaxis: {
        show: false
    },
    legend: {
        show: false
    }
}
