import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import React from 'react'
import { TreeFinancialCategoryCP } from 'main/modules/financial/components/tree-financial-category/TreeFinancialCategoryCP'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'
import styled from 'main/config/theme/styledWithTheme'

/**
 * Tela de configuracao dos plano de contas.
 */
export function ScreenContentCategoryCP(): JSX.Element {

    return (
        <>
            <HeaderCP title={'Plano de Contas'}/>

            <LayoutSubmenuContentCP>

                <WrapperSCP>
                    <TreeFinancialCategoryCP categoryType={FinancialCategoryTypeEnum.DEBIT}/>
                    <TreeFinancialCategoryCP categoryType={FinancialCategoryTypeEnum.CREDIT}/>
                </WrapperSCP>

            </LayoutSubmenuContentCP>
        </>
    )
}

const WrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
`
