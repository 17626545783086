export enum TransactionalTriggerTypeEnum {
    BEFORE = 'BEFORE',
    AT_MOMENT = 'AT_MOMENT',
    EXACT_DAY = 'EXACT_DAY',
    AFTER = 'AFTER'
}

export enum TransactionalTriggerTypeLabelEnum {
    BEFORE = 'Antes',
    AT_MOMENT = 'Na hora',
    EXACT_DAY = 'No dia',
    AFTER = 'Depois'
}
