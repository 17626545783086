import React from 'react'
import styled from 'styled-components'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface ICPProps extends IScpProps {
    dateFilters?: IDateRangeFilter
    onDateRangeChange: (dateFilters: IDateRangeFilter) => void
    dateFiltersCompareTo?: IDateRangeFilter
    onDateRangeCompareToChange?: (dateFilters: IDateRangeFilter) => void
    showCompareToDate?: boolean
    showDivider?: boolean
}

/**
 * Filtros da tela de transacao financeira.
 */
export function FiltersFranchiseDashboardCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >

            <ConditionalRenderCP shouldRender={props.showDivider ?? false}>
                <DividerSCP/>
            </ConditionalRenderCP>

            <FilterWrapperSCP>
                <DateWrapperSCP>
                    <DateRangePickerCP
                        value={props.dateFilters}
                        onChange={props.onDateRangeChange}
                        marginBottom={10}
                        fastFilter={'all'}
                        showBorder={true}
                        label={'Período dos indicadores abaixo'}
                    />
                </DateWrapperSCP>
                {
                    props.showCompareToDate && !!props.onDateRangeCompareToChange &&
                    <DateWrapperSCP style={{ marginLeft: '40px' }}>
                        <DateRangePickerCP
                            value={props.dateFiltersCompareTo}
                            onChange={props.onDateRangeCompareToChange}
                            marginBottom={10}
                            fastFilter={'all'}
                            showBorder={true}
                            label={'Comparar com'}
                        />
                    </DateWrapperSCP>
                }
            </FilterWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`

const DividerSCP = styled.div`
  border-top: solid 1px #e8e8e8;
`

const FilterWrapperSCP = styled.div`
  padding: 20px 10px 40px 10px;
  display: flex;
  justify-content: center;
`

const DateWrapperSCP = styled.div`
  width: 280px;
`