import React, { useEffect, useState } from 'react'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import * as _ from 'lodash'
import { OpportunitySourceRequests } from 'main/modules/sales-funnel/services/opportunity-source/OpportunitySourceRequests'
import { SystemUtils } from 'main/common/utils/SystemUtils'

interface IAutoCompletePickerOpportunitySourceCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    sourcePersonLinkFieldName?: keyof FModelTP
    sourcePersonLinkInitialOption?: INameAndCodeResponseDTO
    disableSourcePersonLink?: boolean
    onSelectOpportunitySource?: (opportunitySourceFullOpt: IOpportunitySourceResponseDTO) => void
}

/**
 * COMPONENTE
 * Seletor de fontes de uma oportunidade com autocomplete.
 */
export function AutoCompleteOpportunitySourceCP<FModelTP extends FormModel = any>(props: IAutoCompletePickerOpportunitySourceCPProps<FModelTP>): JSX.Element {

    const [loadedOptions, setLoadedOptions] = useState<IOpportunitySourceResponseDTO[]>()
    const [selectedOption, setSelectedOption] = useState<IOpportunitySourceResponseDTO>()

    useEffect(init, [loadedOptions])

    /**
     * Inicializa.
     */
    function init(): void {

        // Precaucao de sincronismo dos valores setados no FSM, pois um depende do outro
        SystemUtils.sleep(10)

        if (!props.fieldName || !props.formStateManager)
            return

        const fieldValue = props.formStateManager?.getFieldValue(props.fieldName) ?? (props.initialOptions ? props.initialOptions[0] : undefined)
        if (!fieldValue)
            return

        let fullOptFound
        // @FIXME - renato - 24/06/21 - Por algum motivo o valor inicial do campo é retornado com o label do campo e nao o codigo, por isso buscamos pelo nome
        // @FIXME - analisar esse comportamento do formStateManager. Deveria ter retornado aqui o codigo selecionado e nao o nome
        // @FIXME - quando eh passado o initialOpts o retorno eh o codigo mesmo, quando eh utilizado o que esta no FSM ai vem a string
        if (_.isString(fieldValue))
            fullOptFound = _.find(loadedOptions, (_selectedOptionFind: IOpportunitySourceResponseDTO) => _selectedOptionFind.name === fieldValue)
        else
            fullOptFound = onSelectSource(fieldValue)

        if (fullOptFound)
            setSelectedOption(fullOptFound)
    }

    /**
     * Ao selecionar uma origem do lead.
     */
    function onSelectSource(code: number): void {
        const fullOptFound = _.find(loadedOptions, (_selectedOptionFind: IOpportunitySourceResponseDTO) => _selectedOptionFind.code === code)
        setSelectedOption(fullOptFound)

        if (props.onSelectOpportunitySource && !!fullOptFound)
            props.onSelectOpportunitySource(fullOptFound)
    }

    return (
        <>
            <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
                requestConfigGetter={OpportunitySourceRequests.searchOpportunitySources}
                customFilters={{ onlyActive: true }}
                onChange={onSelectSource}
                onLoadOptions={options => setLoadedOptions(options)}
                showOptionsOnLoad={true}
                // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
                {...props/* eslint-disable-line react/jsx-props-no-spreading */}
            />

            {
                !!selectedOption && selectedOption.hasPersonLink && !!props.sourcePersonLinkFieldName &&
                <AutocompletePersonCP
                    label={`Selecione a pessoa (${selectedOption.name})`}
                    considerCustomer={true}
                    considerUser={true}
                    formStateManager={props.formStateManager}
                    fieldName={props.sourcePersonLinkFieldName}
                    initialOptions={props.sourcePersonLinkInitialOption ? [props.sourcePersonLinkInitialOption] : undefined}
                    required={true}
                    disabled={props.disableSourcePersonLink && !!props.sourcePersonLinkInitialOption}
                />
            }
        </>
    )
}
