import { PaymentMethodEnum, PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

/**
 * UTILITARIOS do financeiro
 */
export const FinancialUtils = {

    getPaymentMethodsLabel(paymentMethods: PaymentMethodEnum[]): string {

        // Muda os valores para labels e depois retorna os valores separados por vírgula
        return paymentMethods
            .map((value: PaymentMethodEnum) => PaymentMethodLabelEnum[value])
            .join(', ')
    },

}
