import { OpportunityBasicInfoCP } from 'main/modules/sales-funnel/components/opportunity-basic-info/OpportunityBasicInfoCP'
import { TimelinePersonContactsCP } from 'main/modules/people/components/timeline-person-contacts/TimelinePersonContactsCP'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { ILeadDataResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/ILeadDataResponseDTO'
import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { DrawerEvaluationDataCP } from 'main/modules/sales-funnel/components/drawer-evaluation-data/DrawerEvaluationDataCP'
import { OpportunityDetailsDrawerButtonsICP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDetailsDrawerButtonsICP'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { DrawerCreateSaleCP } from 'main/modules/sale/components/drawer-create-sale/DrawerCreateSaleCP'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { TimelineOpportunityEvaluationSchedulesCP } from 'main/modules/sales-funnel/components/timeline-opportunity-evaluation-schedules/TimelineOpportunityEvaluationSchedulesCP'
import { PersonContactEntityFilterEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityFilterEnum'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { OpportunityFormModel } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityFormModel'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ListSaleQuotes } from 'main/modules/sales-funnel/components/list-sale-quotes/ListSaleQuotes'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { EvaluationDoneActionTP } from 'main/modules/sales-funnel/components/modal-evaluation-confirm/ModalEvaluationConfirmCP'

interface IOpportunityDetailsDrawerCPProps {
    opportunityCode?: number
    initialData?: ObjectPropsTP<OpportunityFormModel>
    conversionType?: OpportunityDrawerActionTP
    visible: boolean
    onClose: (hasChangedData?: boolean, hasChangedType?: boolean) => void
}

/**
 * Componente wrapper para conteúdo do drawer detalhes da oportunidade
 */
export function DrawerOpportunityDetailsCP(props: IOpportunityDetailsDrawerCPProps): JSX.Element {

    useEffect(init, [props.visible])

    // Variaveis que irao gerenciar quais informacoes forma alterados, para enviar via callback
    const [hasChangedData, setHasChangedData] = useState<boolean>(false)
    const [hasChangedType, setHasChangedType] = useState<boolean>(false)

    const [conversionActionSelected, setConversionActionSelected] = useState<OrUndefTP<OpportunityDrawerActionTP>>(props.conversionType)
    useEffect(() => setConversionActionSelected(props.conversionType), [props.conversionType])

    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)
    const [showEvaluationFormDrawer, setShowEvaluationFormDrawer] = useState<boolean>(false)
    const [isSaleDetailsDrawerVisible, setIsSaleDetailsDrawerVisible] = useState<boolean>(false)

    const [isCreateSaleDrawerVisible, setIsCreateSaleDrawerVisible] = useState<boolean>(false)

    const [opportunityBasicData, setOpportunityBasicData] = useState<ILeadDataResponseDTO>()
    const [evaluationScheduleCode, setEvaluationScheduleCode] = useState<number>()

    const [opportunity, setOpportunity] = useState<IOpportunityResponseDTO>()
    const getOpportunityRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onGetOpportunityRequestChange, [getOpportunityRequest.isAwaiting])

    const changeOpportunityTypeRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onChangeOpportunityTypeRequestChange, [changeOpportunityTypeRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.visible)
            return

        setOpportunity(undefined)
        setHasChangedType(false)
        setHasChangedData(false)

        if (props.opportunityCode)
            loadOpportunityFromApi(props.opportunityCode)
    }

    /**
     * Carrega oportunidade da API.
     */
    function loadOpportunityFromApi(opportunityCode: number): void {
        getOpportunityRequest.runRequest(OpportunityRequests.getOpportuityConfig(opportunityCode))
    }

    /**
     * Retorno da api com os dados da oportunidade.
     */
    function onGetOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getOpportunityRequest, 'Erro ao buscar os dados da oportunidade.'))
            return

        const opportunityResult = getOpportunityRequest.responseData!
        setOpportunity(opportunityResult)
        setOpportunityBasicData(opportunityResult.leadData)

        // Se passou um conversionType e esta aqui, siginifica que passou uma oportunidade e que executar uma acao imediata nela
        // Mas se a variavel de estado estiver em branco, significa que a acao foi executada e nao precisa fazer novamente
        if (!!props.conversionType && !!conversionActionSelected)
            onConversionClick(props.conversionType, opportunityResult)
    }

    /**
     * Ao salvar uma avaliacao
     */
    function onEvaluationSave(action?: EvaluationDoneActionTP): void {

        setShowEvaluationFormDrawer(false)
        setConversionActionSelected(undefined)

        if (action === 'doSale') {
            onCreateSale()
            return
        }

        setHasChangedType(true)
        setHasChangedData(true)

        loadOpportunityFromApi(opportunity!.code)
    }

    /**
     * Callback ao criar uma venda.
     */
    function onCreateSale(): void {

        setIsCreateSaleDrawerVisible(false)
        setIsSaleDetailsDrawerVisible(true)

        setHasChangedType(true)
        setHasChangedData(true)

        loadOpportunityFromApi(opportunity!.code)
    }

    /**
     * Evento ao clicar em uma acao de conversao de LEAD. Eh necessario criar a pessoa para entao prosseguir.
     */
    function changeToEvaluation(personCreated: IPersonResponseDTO): void {

        if (!personCreated) {
            NotificationHelper.error('Ops', 'Não foi possível obter a pessoa selecionada')
            return
        }

        // Fecha drawer de pessoa
        setIsPersonDrawerVisible(false)

        // Chama a api para alterar o tipo, mover no funil
        if (conversionActionSelected === 'evaluation')
            changeOpportunityTypeRequest.runRequest(OpportunityRequests.changeToEvaluation(opportunity!.code, { personCustomerCode: personCreated.code }))
    }

    /**
     * Evento ao mudar uma pessoa de etapa (para avaliacao / followup)
     */
    function onChangeOpportunityTypeRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changeOpportunityTypeRequest, 'Erro ao mudar oportunidade de etapa.'))
            return

        setOpportunity(changeOpportunityTypeRequest.responseData)
        setHasChangedType(true)

        // Agora sim abre drawer que foi selecionado antes de vincular a pessoa
        executeActionByConversionClick(conversionActionSelected!)
    }

    /**
     * Ao clicar em uma acao de conversao de LEAD -> Avalicao | Venda.
     */
    function onConversionClick(action: OpportunityDrawerActionTP, _opportunity: IOpportunityResponseDTO): void {

        // Se tiver o cliente, ja executa a acao. Caso contrario deve abrir o drawer para vincular pessoa
        if (!!_opportunity?.personCustomer) {
            executeActionByConversionClick(action)
            return
        }

        setConversionActionSelected(action)
        setIsPersonDrawerVisible(true)
    }

    /**
     * Executa um metodo baseado no que ele clicou para converter o LEAD -> Avaliacao ou Venda
     */
    function executeActionByConversionClick(action: OpportunityDrawerActionTP): void {

        if (action === 'evaluation')
            return setShowEvaluationFormDrawer(true)

        if (action === 'sale')
            return setIsCreateSaleDrawerVisible(true)

    }

    /**
     * Callback ao salvar uma oportunidade
     */
    function onSaveOpportunityData(opportunitySaved: IOpportunityResponseDTO): void {

        setOpportunity(opportunitySaved)
        setHasChangedData(true)

        // Se esta criando avisa que mudou o tipo
        if (!props.opportunityCode)
            setHasChangedType(true)

        // Se criou uma nova oportunidade e foi passado o conversionType para saber o que faz em seguida
        if (!!props.conversionType && !props.opportunityCode)
            executeActionByConversionClick(props.conversionType)
    }

    return (
        <DrawerCP
            title={opportunity ? 'Detalhes de uma Oportunidade' : 'Novo LEAD'}
            visible={props.visible}
            width={opportunity ? 800 : 350}
            onClose={() => props.onClose(hasChangedData, hasChangedType)}
            loading={getOpportunityRequest.isAwaiting}
        >
            <FlexCP justifyContent={'space-between'}>

                <LeftSideSCP showBorder={!!opportunity}>
                    <OpportunityBasicInfoCP
                        opportunity={opportunity}
                        initialData={props.initialData}
                        setOpportunityBasicData={setOpportunityBasicData}
                        onSave={onSaveOpportunityData}
                    />
                </LeftSideSCP>

                {
                    !!opportunity &&
                    <RightSideSCP>
                        <OpportunityDetailsDrawerButtonsICP
                            opportunity={opportunity}
                            onSaleDetailsClick={() => setIsSaleDetailsDrawerVisible(true)}
                            onConversionActionClick={(actionType) => onConversionClick(actionType, opportunity)}
                            onArchiveOpportunity={() => props.onClose(hasChangedData, true)}
                            onMoveOpportunityToFollowup={() => props.onClose(hasChangedData, true)}
                            onRollbackOpportunity={() => loadOpportunityFromApi(opportunity.code)}
                        />

                        <ListSaleQuotes
                            canAdd={!!opportunity.personCustomer}
                            opportunityCode={opportunity.code}
                            onUpdateData={(wasSaleCreated) => {
                                loadOpportunityFromApi(opportunity.code)
                                setIsSaleDetailsDrawerVisible(wasSaleCreated)
                            }}
                        />

                        {
                            opportunity?.type !== OpportunityTypeEnum.LEAD &&
                            <TimelineOpportunityEvaluationSchedulesCP
                                opportunity={opportunity}
                                onSelectEvaluationSchedule={(_evaluationScheduleCode) => {
                                    setShowEvaluationFormDrawer(true)
                                    setEvaluationScheduleCode(_evaluationScheduleCode)
                                }}
                                onAddEvaluationSchedule={() => {
                                    setEvaluationScheduleCode(undefined)
                                    setShowEvaluationFormDrawer(true)
                                }}
                                onUpdateEvaluation={() => setHasChangedData(true)}
                            />
                        }

                        <TimelinePersonContactsCP
                            opportunityBasicData={opportunityBasicData}
                            entityCode={opportunity.code}
                            entityFilter={PersonContactEntityFilterEnum.OPPORTUNITY}
                            onSave={(actionType) => {
                                setHasChangedData(true)
                                if (actionType)
                                    onConversionClick(actionType, opportunity)
                            }}
                            showTitle={true}
                        />
                    </RightSideSCP>
                }
            </FlexCP>

            {
                opportunity &&
                <>
                    <DrawerEvaluationDataCP
                        onSave={onEvaluationSave}
                        opportunityCode={opportunity.code}
                        visible={showEvaluationFormDrawer}
                        onClose={() => {
                            setShowEvaluationFormDrawer(false)
                            loadOpportunityFromApi(opportunity.code)
                        }}
                        evaluationScheduleCode={evaluationScheduleCode}
                    />

                    <DrawerPersonDataCP
                        visible={isPersonDrawerVisible}
                        onClose={() => setIsPersonDrawerVisible(false)}
                        onSave={changeToEvaluation}
                        segmentType={SegmentTypeEnum.CUSTOMER}
                        initialData={opportunity?.leadData}
                        customHeader={
                            <FallbackScreenContentCP
                                status={'success'}
                                message={'Oba! Agora que você irá prosseguir com o LEAD precisamos completar o cadastro antes de prosseguir'}
                            />
                        }
                    />

                    <DrawerCreateSaleCP
                        visible={isCreateSaleDrawerVisible}
                        type={SaleTypeEnum.SALE}
                        opportunityCode={opportunity?.code}
                        closeDrawer={() => setIsCreateSaleDrawerVisible(false)}
                        onSave={onCreateSale}
                    />

                    {
                        isSaleDetailsDrawerVisible && opportunity?.saleCode &&
                        <DrawerSaleDetailsCP
                            saleCode={opportunity?.saleCode}
                            visible={isSaleDetailsDrawerVisible}
                            onClose={() => setIsSaleDetailsDrawerVisible(false)}
                        />
                    }
                </>
            }
        </DrawerCP>
    )

}

const LeftSideSCP = styled.div<{ showBorder: boolean }>`
    border-right: ${props => (props.showBorder ? 'dashed 1px #dedede' : '')};
    padding-right: 20px;
    width: 320px;
`

const RightSideSCP = styled.div`
    width: 450px;
    padding: 0 20px;
`
