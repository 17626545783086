import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { TableOpportunitySourceCP } from 'main/modules/admin/components/table-opportunity-source/TableOpportunitySourceCP'
import { DrawerOpportunitySourceCP } from 'main/modules/products/components/drawer-opportunity-source/DrawerOpportunitySourceCP'

/**
 * Tela de listagem de origem de oportunidade/venda.
 */
export function ScreenContentOpportunitySourceCP(): JSX.Element {

    const [shouldForceUpdateList, setShouldForceUpdateList] = useState<boolean>(true)
    const [isSourceDrawerVisible, setIsSourceDrawerVisible] = useState<boolean>(false)
    const [selectedSourceCode, setSelectedSourceCode] = useState<number>()

    return (
        <>
            <HeaderCP title={'Origem do Lead / Venda'}>
                <HeaderButtonICP
                    onClick={() => {
                        setSelectedSourceCode(undefined)
                        setIsSourceDrawerVisible(true)
                    }}
                    icon={'plus'}
                    label={'Nova Origem de Venda'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableOpportunitySourceCP
                    forceLoadList={shouldForceUpdateList}
                    onListLoaded={() => setShouldForceUpdateList(false)}
                    onEdit={(code) => {
                        setIsSourceDrawerVisible(true)
                        setSelectedSourceCode(code)
                    }}
                />
            </LayoutSubmenuContentCP>

            <DrawerOpportunitySourceCP
                visible={isSourceDrawerVisible}
                opportunitySourceCode={selectedSourceCode}
                onClose={(dataChanged) => {
                    if (dataChanged)
                        setShouldForceUpdateList(true)
                    setIsSourceDrawerVisible(false)
                }}
            />
        </>
    )
}
