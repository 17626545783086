import _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { SchemaIconCP } from 'main/common/components/icons/SchemaIconCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { StringUtils } from 'main/common/utils/StringUtils'
import styled from 'main/config/theme/styledWithTheme'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { IAuthUserFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserFranchiseResponseDTO'
import React, { useEffect, useState } from 'react'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IFranchiseSelectCPProps {
    disabled?: boolean
    onChangeCurrentFranchise: () => void
}

/**
 * COMPONENTE Select de Franquias
 */
export function SelectCurrentFranchiseCP(props: IFranchiseSelectCPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
    const currentFranchise = AppStateUtils.getCurrentFranchise()
    const [franchiseSelectOptions, setFranchiseSelectOptions] = useState<SelectOptionTP[]>([])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        let options: SelectOptionTP[] = []

        // Adiciona Franqueadora
        if (AppStateUtils.getUserFranchisor()) {
            options.push({
                value: AppStateUtils.getUserFranchisor()!.code,
                key: `franchisor-${AppStateUtils.getUserFranchisor()!.code}`,
                label: `Franqueadora - ${AppStateUtils.getUserFranchisor()!.name}`
            })
        }

        // Adiciona Franquias
        if (!SystemUtils.isEmpty(AppStateUtils.getUserFranchises())) {
            const franchiseOpts = AppStateUtils.getUserFranchises()!.map((userFranchise: IAuthUserFranchiseResponseDTO) => ({
                value: userFranchise.code,
                key: `franchise-${userFranchise.code}`,
                label: userFranchise.name
            }))

            options = _.concat(options, franchiseOpts)
        }

        setFranchiseSelectOptions(options)
    }

    /**
     * Ao selecionar uma fraquia.
     */
    function onSelectFranchise(selectedFranchiseCode: number): void {

        if (!selectedFranchiseCode)
            return

        const franchise = AppStateUtils.getUserFranchises()?.find(_franchise => (_franchise.code === selectedFranchiseCode))

        if (franchise)
            AuthActions.setCurrentFranchise(franchise)
        else
            AuthActions.setCurrentFranchise(AppStateUtils.getUserFranchisor()!)

        setIsPopoverVisible(false)
        props.onChangeCurrentFranchise()
    }

    return (
        <ContentWrapperSCP>
            <PopOverCP
                trigger={'click'}
                placement={'right'}
                visible={isPopoverVisible}
                onClose={(): void => setIsPopoverVisible(false)}
                content={
                    <>
                        <TitleSCP>
                            <SchemaIconCP/>
                            Alterar unidade
                        </TitleSCP>

                        <ContainerWrapperSCP>
                            <SelectCP
                                value={currentFranchise!.code}
                                options={franchiseSelectOptions}
                                placeholder={'Selecione uma unidade'}
                                notFoundContent={'Nenhuma franquia encontrada.'}
                                onChange={onSelectFranchise}
                                loading={!currentFranchise}
                                disabled={props.disabled}
                                width={300}
                            />
                        </ContainerWrapperSCP>
                    </>
                }
            >
                <ButtonCP
                    tooltip={`Você está logado na unidade: ${currentFranchise!.name}`}
                    tooltipPlacement={'topLeft'}
                    onClick={(): void => setIsPopoverVisible(true)}
                    type={'link'}
                >
                    <div className='estilo-iniciais'>
                        <div className='iniciais'>
                            {StringUtils.getInitials(currentFranchise!.name)}
                        </div>
                    </div>
                </ButtonCP>
            </PopOverCP>
        </ContentWrapperSCP>
    )
}

const TitleSCP = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    i {
        margin-right: 7px;
    }
`

const ContentWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${ThemeProject.primaryMenuItemColor};

    .estilo-iniciais{
        max-width:30px;
        position:relative;
        height: 12px;
        font-weight: 700;
        font-family: system-ui;
    }

    .iniciais{
        position: absolute;
        top: -5px;
        left: -25px;
        letter-spacing: -0.12em;
        font-size: 12px;
        width: 56px;
        text-align: center;
    }
`

const ContainerWrapperSCP = styled.div`
    .ant-form-explain {
        display: none;
    }
`
