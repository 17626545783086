import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { MenuCP } from 'main/common/components/menu/menu/MenuCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
// import { SiderCP } from 'main/common/components/screen-layout/sider/SiderCP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PersonListScreenSiderSubMenuICP } from 'main/modules/people/components/person-list-screen-sider/inner/PersonListScreenSiderSubMenuICP'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

const _getSegmentSubMenuKey = (segmentType?: SegmentTypeEnum): string => `person_list_screen_sider_submenu_${segmentType ?? ''}`

/**
 * COMPONENTE
 * Representa o conteúdo da lateral esquerda da tela CRM (pessoas).
 */
export const PersonListScreenSiderCP = ComponentUtils.memoizeCp(true, (): JSX.Element => {

    const [onGoingSearches, setOnGoingSearches] = useState<number>(0)
    const [defaultOpenedSegType, setDefaultOpenedSegType] = useState<SegmentTypeEnum>()

    const screenContext = PersonContext.use()

    const currentType = screenContext.state.segment?.type
    useEffect(syncDefaultOpenedMenu, [currentType])

    const isDefaultOpenedMenuUpToDate = (!!currentType && currentType === defaultOpenedSegType)

    function syncDefaultOpenedMenu(): void {
        if (currentType && !isDefaultOpenedMenuUpToDate)
            setDefaultOpenedSegType(currentType)
    }

    function onSearchStateChange(isLoading: boolean): void {
        const nextOngoingSearches = onGoingSearches + (isLoading ? 1 : -1)
        setOnGoingSearches(nextOngoingSearches)
        if (nextOngoingSearches >= 0)
            screenContext.actions.setIsLoadingSegment(nextOngoingSearches > 0)
    }

    // Determina menus a serem exibidos
    const subMenuOptions: SegmentTypeEnum[] = []

    if (PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_CRM]))
        subMenuOptions.push(SegmentTypeEnum.CUSTOMER)
    if (PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_ADMIN]))
        subMenuOptions.push(SegmentTypeEnum.COLLABORATOR)
    if (PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL]))
        subMenuOptions.push(SegmentTypeEnum.SUPPLIER)

    return (
        <SiderCP width={275} bgColor={ThemeProject.secondaryMenuColor}>
            <ContentWrapperSCP>
                {
                    !isDefaultOpenedMenuUpToDate &&
                    <LoadingCP/>
                }

                {
                    isDefaultOpenedMenuUpToDate &&
                    <MenuCP dataTour={'person-submenu'} defaultOpenKeys={[_getSegmentSubMenuKey(currentType)]}>
                        <HeaderCP title={'Segmentações'} noBackground={true} titleSize={'large'}/>

                        {
                            !!screenContext.state.segment?.type &&
                            subMenuOptions.map(segmentType => (
                                <PersonListScreenSiderSubMenuICP
                                    key={_getSegmentSubMenuKey(segmentType)}
                                    segmentType={segmentType}
                                    onSearchStateChange={onSearchStateChange}
                                />
                            ))
                        }
                    </MenuCP>
                }
            </ContentWrapperSCP>
        </SiderCP>
    )
})

const ContentWrapperSCP = styled.div`
    flex: 1;
    overflow: hidden auto;
`
