import { Dropdown } from 'antd'
import { ButtonSize } from 'antd/es/button/button'
import { LoadingIconCP } from 'main/common/components/icons/LoadingIconCP'
import { IMultiActionButtonOption } from 'main/common/components/multi-action-button/inner/IMultiActionButtonOption'
import React from 'react'
import styled from 'styled-components'
import { ArrowIconCP } from 'main/common/components/icons/ArrowIconCP'
import { MultiActionButtonItemICP } from 'main/common/components/multi-action-button/inner/MultiActionButtonItemICP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

interface IMultiActionButtonCPProps {
    options: IMultiActionButtonOption[]
    disabled?: boolean
    size?: ButtonSize
    loading?: boolean
    type?: 'primary' | 'ghost' | 'dashed'
}

/**
 * COMPONENTE
 * Botão com opções múltiplas de ação:
 * A opcao 01 eh considerada a acao principal.
 */
export function MultiActionButtonCP(props: IMultiActionButtonCPProps): JSX.Element | null {

    // Extrai a primeira opcao que sera o botao principal
    const mainItem = props.options[0]
    const dropdownItems = (props.options.length > 1) ? props.options.slice(1) : []

    if (SystemUtils.isEmpty(dropdownItems)) {
        return (
            <ButtonCP
                type={props.type ?? 'primary'}
                loading={props.loading}
                size={props.size}
                onClick={mainItem.onClick}
            >
                { mainItem.label }
            </ButtonCP>
        )
    }

    return (
        <WrapperSCP>
            <Dropdown.Button
                size={props.size}
                type={props.type}
                onClick={mainItem.onClick}
                disabled={props.disabled}
                icon={<ArrowIconCP direction={'down'} size={14}/>}
                overlay={<MultiActionButtonItemICP items={dropdownItems}/>}
            >
                <MainContentSCP>
                    <IconWrapperSCP>
                        {props.loading ? <LoadingIconCP/> : mainItem.icon}
                    </IconWrapperSCP>

                    {mainItem.label}
                </MainContentSCP>
            </Dropdown.Button>
        </WrapperSCP>
    )
}

const MainContentSCP = styled.div`
    margin: 0 8px;
    display: flex;
    align-items: center;
`
const IconWrapperSCP = styled.div`
    margin-right: 5px;
    display: flex;
`

const WrapperSCP = styled.div`

    .ant-dropdown-button {

      height: 40px;
      line-height: 40px;
      
        &.ant-btn-group {
            display: flex;
        }

        .ant-btn:not(.ant-dropdown-trigger) {
            padding: 0;
        }

        .ant-btn.ant-dropdown-trigger {
            padding: 0;
        }

        .ant-btn[disabled] {
            opacity: .6;
        }
    }
`
