import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import React, { useState } from 'react'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'

interface IFinancialTransactionAdvancedFiltersCPProps {
    currentFilters: IFinancialTransactionSearchRequestDTO
    onChangeFilters: (dto: IFinancialTransactionSearchRequestDTO) => void
    showPaymentDateFilters: boolean
    showSaleDateFilters: boolean
    showPaymentPartner: boolean
    labelPersonFilter?: string
    showReferenceIntervalFilter: boolean
}

/**
 * Filtros avancado, mais filtros, da tela financeira.
 */
export function FinancialTransactionAdvancedFiltersCP(props: IFinancialTransactionAdvancedFiltersCPProps): JSX.Element {

    const [referenceDate, setReferenceDate] = useState<IDateRangeFilter>(props.currentFilters.referenceInterval as IDateRangeFilter)
    const [expirationDate, setExpirationDate] = useState<IDateRangeFilter>(props.currentFilters.expirateInterval as IDateRangeFilter)
    const [paymentDate, setPaymentDate] = useState<IDateRangeFilter>(props.currentFilters.paymentInterval as IDateRangeFilter)
    const [saleDate, setSaleDate] = useState<IDateRangeFilter>(props.currentFilters.paymentInterval as IDateRangeFilter)

    return (
        <>
            <SelectFranchiseCP
                value={props.currentFilters.franchiseCodes}
                isMultiple={true}
                onChange={(franchises) => {
                    props.onChangeFilters({
                        ...props.currentFilters,
                        franchiseCodes: franchises as number[]
                    })
                }}
                initialFranchises={props.currentFilters.franchiseCodes}
            />

            <ConditionalRenderCP shouldRender={props.showReferenceIntervalFilter}>
                <DateRangePickerCP
                    label={'Data de Referência'}
                    value={referenceDate}
                    onChange={(dates) => {
                        setReferenceDate(dates)
                        props.onChangeFilters({
                            ...props.currentFilters,
                            referenceInterval: {
                                beginDate: DateUtils.getFormatted(dates.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                                endDate: DateUtils.getFormatted(dates.endDate, DateFormatEnum.US_WITHOUT_TIME)
                            }
                        })
                    }}
                    fastFilter={'all'}
                    showBorder={true}
                    marginBottom={20}
                />
            </ConditionalRenderCP>

            <DateRangePickerCP
                label={'Data de Vencimento'}
                value={expirationDate}
                onChange={(dates) => {
                    setExpirationDate(dates)
                    props.onChangeFilters({
                        ...props.currentFilters,
                        expirateInterval: {
                            beginDate: DateUtils.getFormatted(dates.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                            endDate: DateUtils.getFormatted(dates.endDate, DateFormatEnum.US_WITHOUT_TIME)
                        }
                    })
                }}
                fastFilter={'all'}
                showBorder={true}
                marginBottom={20}
            />

            <ConditionalRenderCP shouldRender={props.showPaymentDateFilters}>
                <DateRangePickerCP
                    label={'Data de Pagamento'}
                    value={paymentDate}
                    onChange={(dates) => {
                        setPaymentDate(dates)
                        props.onChangeFilters({
                            ...props.currentFilters,
                            paymentInterval: {
                                beginDate: DateUtils.getFormatted(dates.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                                endDate: DateUtils.getFormatted(dates.endDate, DateFormatEnum.US_WITHOUT_TIME)
                            }
                        })
                    }}
                    fastFilter={'all'}
                    showBorder={true}
                    marginBottom={20}
                />
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={props.showSaleDateFilters}>
                <DateRangePickerCP
                    label={'Data da Venda'}
                    value={saleDate}
                    onChange={(dates) => {
                        setSaleDate(dates)
                        props.onChangeFilters({
                            ...props.currentFilters,
                            saleInterval: {
                                beginDate: DateUtils.getFormatted(dates.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                                endDate: DateUtils.getFormatted(dates.endDate, DateFormatEnum.US_WITHOUT_TIME)
                            }
                        })
                    }}
                    fastFilter={'all'}
                    showBorder={true}
                    marginBottom={20}
                />
            </ConditionalRenderCP>

            <FlexCP alignItems={'space-between'}>
                <InputCP
                    label={'Valor Mín.'}
                    mask={InputMaskTypeEnum.MONEY}
                    marginRight={10}
                    value={props.currentFilters.valueRange?.valueMin}
                    onChange={(val) => {
                        if (props.currentFilters.valueRange) {
                            props.currentFilters.valueRange.valueMin = val
                            return
                        }
                        props.currentFilters.valueRange = {
                            valueMin: val
                        }
                    }}
                />
                <InputCP
                    label={'Valor Max.'}
                    mask={InputMaskTypeEnum.MONEY}
                    marginRight={10}
                    value={props.currentFilters.valueRange?.valueMax}
                    onChange={(val) => {
                        if (props.currentFilters.valueRange) {
                            props.currentFilters.valueRange.valueMax = val
                            return
                        }
                        props.currentFilters.valueRange = {
                            valueMax: val
                        }
                    }}
                />
            </FlexCP>

            <AutocompletePersonCP
                label={props.labelPersonFilter ?? 'Cliente'}
                considerCustomer={true}
                considerSupplier={true}
                value={props.currentFilters.personCode}
                allowClear={true}
                onChange={(selectedOption) => {
                    props.onChangeFilters({
                        ...props.currentFilters,
                        personCode: selectedOption
                    })
                }}
            />

            <SelectPaymentMethodCP
                label={'Forma de Pagamento'}
                value={props.currentFilters.paymentMethod}
                allowClear={true}
                onChange={(pm) => props.onChangeFilters({
                    ...props.currentFilters,
                    paymentMethod: pm
                })}
            />

            <AutocompleteBankAccountCP
                value={props.currentFilters.bankAccountCode}
                label={'Conta Bancária'}
                allowClear={true}
                onChange={(code) => props.onChangeFilters({
                    ...props.currentFilters,
                    bankAccountCode: code
                })}
            />

            <ConditionalRenderCP shouldRender={props.showPaymentPartner}>
                <AutocompleteBankAccountCP
                    value={props.currentFilters.paymentPartnerCode}
                    label={'Meio de Pagamento'}
                    allowClear={true}
                    type={BankAccountTypeEnum.PAYMENT_ACCOUNT}
                    onChange={(code) => props.onChangeFilters({
                        ...props.currentFilters,
                        paymentPartnerCode: code
                    })}
                />
            </ConditionalRenderCP>
        </>
    )
}
