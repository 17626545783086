import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { IUserLoginRequestDTO } from 'main/modules/auth/services/dtos/request/IUserLoginRequestDTO'
import { IPasswordResetRequestDTO } from 'main/modules/auth/services/dtos/request/IPasswordResetRequestDTO'
import { SystemConfig } from 'main/config/SystemConfig'

/**
 * REQUISICOES
 * Define parametrizacao para requisicoes relacionadas ao modulo de AUTENTICACAO.
 */
export class AuthRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/auth`

    private constructor() {}

    /** Gera & retorna parametros para 01 requisicao de LOGIN de usuario de sistema. */
    static login = (schema: string, dto: IUserLoginRequestDTO): RequestConfigTP<IUserLoginRequestDTO> => ({
        url: `${AuthRequests._MODULE_BASE}/login`,
        method: HttpMethodEnum.POST,
        params: dto,
        noAuth: true,
        headers: [AuthUtils.getSchemaHeaderConfig(schema)]
    })

    /** Gera & retorna parametros para 01 requisicao de RECUPERAR SENHA. */
    static recoverPassword = (schema: string, dto: IPasswordResetRequestDTO): RequestConfigTP<IPasswordResetRequestDTO> => ({
        url: `${AuthRequests._MODULE_BASE}/forgot-password`,
        method: HttpMethodEnum.PUT,
        params: dto,
        noAuth: true,
        headers: [AuthUtils.getSchemaHeaderConfig(schema)]
    })

}
