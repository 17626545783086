import React, { useEffect, useState } from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import 'react-credit-cards/es/styles-compiled.css'
import { CreditCardDataCP } from 'main/modules/financial/components/credit-card-data/CreditCardDataCP'
import { CreditCardDataFormModel } from 'main/modules/financial/components/credit-card-data/inner/CreditCardDataFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ChargeRequests } from 'submodules/neritclin-sdk/services/sale/charge/ChargeRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ChargePaymentDataUpdateRequestDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/request/ChargePaymentDataUpdateRequestDTO'

interface ICPProps {
    onSave: () => void
    chargeCode: number
}

/**
 */
export function ScreenContentUpdateChargePaymentData(props: ICPProps): JSX.Element {

    const [formModel] = useState<CreditCardDataFormModel>(new CreditCardDataFormModel())
    const formStateManager = useFormStateManager<CreditCardDataFormModel>(formModel)

    const updateChargePaymentDataRequest = useRequest<void>('none')
    useEffect(onUpdateChargePaymentDataRequestChange, [updateChargePaymentDataRequest.isAwaiting])

    /**
     */
    async function updateData(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        if (formValues.number.length < 16) {
            NotificationHelper.error('Ops', 'Preencha o número do cartão corretamente')
            return
        }

        if (formValues.cvc.length < 3) {
            NotificationHelper.error('Ops', 'Preencha o código de segurança corretamente')
            return
        }

        if (formValues.expiry.length < 5) {
            NotificationHelper.error('Ops', 'Preencha a validade corretamente')
            return
        }

        const dto: ChargePaymentDataUpdateRequestDTO = {
            chargeCode: props.chargeCode,
            number: formValues.number,
            creditCardCompany: 'TODO',
            name: formValues.name,
            expiry: formValues.expiry,
            cvc: formValues.cvc,
        }
        updateChargePaymentDataRequest.runRequest(ChargeRequests.updatePaymentData(dto))
    }

    /**
     */
    function onUpdateChargePaymentDataRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateChargePaymentDataRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ContentCP>
            <BasicStyleWrapperCP margin={{ top: 10, left: 20, right: 20 }}>

                <CreditCardDataCP
                    formStateManager={formStateManager}
                />

                <FlexCP justifyContent={'center'} margin={{ top: 20 }}>
                    <ButtonCP
                        type={'primary'}
                        icon={'credit-card'}
                        size={'large'}
                        loading={updateChargePaymentDataRequest.isAwaiting}
                        onClick={updateData}
                    >
                        Salvar
                    </ButtonCP>
                </FlexCP>

            </BasicStyleWrapperCP>
        </ContentCP>
    )
}
