import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { TableTreatmentFormsCP } from 'main/modules/admin/components/table-treatment-forms/TableTreatmentFormsCP'
import { DrawerTreatmentFormCP } from 'main/modules/admin/components/drawer-treatment-form/DrawerTreatmentFormCP'

/**
 * Tela de listagem de formulários de evolucoes dos tratamentos.
 */
export function ScreenContentTreatmentFormsCP(): JSX.Element {

    const [shouldForceUpdateList, setShouldForceUpdateList] = useState<boolean>(true)
    const [isTreatmentFormDrawerVisible, setIsTreatmentFormDrawerVisible] = useState<boolean>(false)
    const [selectedTreatmentFormCode, setSelectedTreatmentFormCode] = useState<number>()

    return (
        <>
            <HeaderCP title={'Formulários dos Tratamentos'}>
                <HeaderButtonICP
                    onClick={() => {
                        setSelectedTreatmentFormCode(undefined)
                        setIsTreatmentFormDrawerVisible(true)
                    }}
                    icon={'plus'}
                    label={'Novo Formulário'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableTreatmentFormsCP
                    forceLoadList={shouldForceUpdateList}
                    onListLoaded={() => setShouldForceUpdateList(false)}
                    onEdit={(code) => {
                        setIsTreatmentFormDrawerVisible(true)
                        setSelectedTreatmentFormCode(code)
                    }}
                />
            </LayoutSubmenuContentCP>

            <DrawerTreatmentFormCP
                visible={isTreatmentFormDrawerVisible}
                treatmentFormCode={selectedTreatmentFormCode}
                onClose={(dataChanged) => {
                    if (dataChanged)
                        setShouldForceUpdateList(true)
                    setIsTreatmentFormDrawerVisible(false)
                }}
            />
        </>
    )
}
