import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { IBulkOperationResponseDTO } from 'main/modules/admin/services/bulk-operation/responses/IBulkOperationResponseDTO'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { BulkOperationTableColumnTotalICP } from 'main/modules/admin/components/table-bulk-operations/inner/BulkOperationTableColumnTotalICP'
import { TagBulkOperationStatusCP } from 'main/modules/admin/components/tag-bulk-operation-status/TagBulkOperationStatusCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

export const BulkOperationTableUtils = {

    getTableColumns(
        onShowLogs: (record: IBulkOperationResponseDTO) => void
    ): Array<ColumnProps<IBulkOperationResponseDTO>> {

        return [
            {
                title: 'Data',
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => (
                    <TextCP size={'small'} text={DateUtils.getFormatted(record.creationDate, DateFormatEnum.BR_WITH_TIME_H_M)}/>
                )
            },
            {
                title: 'Responsável',
                dataIndex: 'person',
                key: 'person',
                render: (text, record) => <TextCP size={'small'} text={record.userCreator.name}/>
            },
            {
                title: 'Status',
                colSpan: 2,
                dataIndex: 'total',
                key: 'total',
                align: 'center',
                render: (text, record) => <BulkOperationTableColumnTotalICP log={record}/>
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => <TagBulkOperationStatusCP status={record.status}/>
            },
            { dataIndex: 'act',
                key: 'act',
                render: (text, record) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            tooltip={'Ver log de erros'}
                            icon={'file-search'}
                            onClick={() => onShowLogs(record)}
                            size={'small'}
                            type={'ghost'}
                        />
                    </FlexCP>
                ) },
        ]
    }

}
