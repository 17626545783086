import React from 'react'
import { PlusIconCP } from 'main/common/components/icons/PlusIconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { styled } from 'main/config/theme/styledWithTheme'

const SHOULD_HIDE_BUTTON = true

interface IAggregatorAddingButtonICPProps {
    onClick: () => void
    disable: boolean
}

/**
 * COMPONENTE
 * Botao de adicionar novo agregador a 01 expressao de filtros de segmentacao.
 */
export function AggregatorAddingButtonICP(props: IAggregatorAddingButtonICPProps): JSX.Element | null {

    // Por enquanto esta funcionalidade esta desabilitada
    if (SHOULD_HIDE_BUTTON)
        return <></>

    return (
        <TooltipCP text={props.disable ? 'Adicionar Grupo de Filtros' : ''} placement={'right'}>
            <WrapperSCP onClick={!props.disable ? props.onClick : undefined} disabled={props.disable}>
                <PlusIconCP size={14} type={'default'} style={{ marginRight: 3 }}/>
                Grupo
            </WrapperSCP>
        </TooltipCP>
    )
}

const WrapperSCP = styled.div<{ disabled: boolean }>`

    color: ${props => props.theme.primaryColor};
    display: flex;
    font-weight: bold;
    font-size: .75rem;
    transition: color .3s,opacity .3s;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => (props.disabled ? .5 : 1)};

    :hover {
        color: ${props => props.theme.infoColor};
        opacity: ${props => (props.disabled ? .5 : .8)};
    }
`
