import { FileDeleteRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/FileDeleteRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { SaleChargeUpdateRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleChargeUpdateRequestDTO'
import { SaleGenerateNfeRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleGenerateNfeRequestDTO'
import { SaleSaveRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSaveRequestDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'

export class SaleRequests {

	public static CONTROLLER_ROOT = 'sales'
	public static CREATE_SALE_EP = ''
	public static UPDATE_CHARGES_EP = 'update-charges'
	public static SUMMARY_EP = 'summary'
	public static SUMMARY_REPORT_EP = 'summary-report'
	public static SALEVALUE_BY_PAYMENT_METHOD_EP = 'by-payment-method'
	public static CREATE_NFE_EP = 'create-nfe'
	public static GET_SALE_NFE_EP = 'get-sale-nfe/:saleCode'
	public static SEARCH_SALE_INSTALLMENTS_NFE_EP = 'search-sale-installments-nfe/:saleCode'
	public static UPLOAD_FILE_EP = 'upload-file/:saleCode'
	public static DELETE_FILE_EP = 'delete-file'
	public static SEARCH_FILES_EP = 'search-files/:saleCode'
	public static GET_SESSIONS_EP = ':saleCode/sessions'

	static updateCharges = (dto: SaleChargeUpdateRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.UPDATE_CHARGES_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static createSale = (dto: SaleSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.CREATE_SALE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static createNFe = (dto: SaleGenerateNfeRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.CREATE_NFE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static getSaleNfe = (saleCode: number): RequestConfigTP<NFeResponseDTO<NFeCustomArgsTP>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.GET_SALE_NFE_EP.replace(':saleCode', saleCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static searchSaleInstallmentsNfe = (saleCode: number): RequestConfigTP<NFeResponseDTO<NFeCustomArgsTP>[]> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.SEARCH_SALE_INSTALLMENTS_NFE_EP.replace(':saleCode', saleCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static getCharges = (saleCode: number): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${saleCode}/charges`,
		method: HttpMethodEnum.GET,
	})

	static searchFiles = (saleCode: number): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.SEARCH_FILES_EP.replace(':saleCode', saleCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static deleteFile = (dto: FileDeleteRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.DELETE_FILE_EP}`,
		method: HttpMethodEnum.DELETE,
		params: dto
	})

	static uploadFile = (saleCode: number, formData: any): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.UPLOAD_FILE_EP.replace(':saleCode', saleCode.toString())}`,
		method: HttpMethodEnum.POST,
		params: formData
	})

    static getSessions = (saleCode: number): RequestConfigTP => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${SaleRequests.CONTROLLER_ROOT}/${SaleRequests.GET_SESSIONS_EP.replace(':saleCode', saleCode.toString())}`,
        method: HttpMethodEnum.GET,
    })
}
