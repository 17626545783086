import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { TagCP } from 'main/common/components/tag/TagCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TreatmentFormResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/TreatmentFormResponseDTO'
import styled from 'main/config/theme/styledWithTheme'

/**
 * Utils da tabela dos forms de tratamento.
 */
export const TreatmentFormsTableUtils = {

    getTableColumns(
        onEdit: (code: number) => void,
        onRemove: (code: number) => void,
    ): Array<ColumnProps<TreatmentFormResponseDTO>> {

        return [
            {
                key: 'title',
                title: 'Nome do Formulário',
                render: (text, item) => item.name
            },
            {
                key: 'treatments',
                title: 'Tratamentos Vinculados',
                render: (text, item) => {
                    return (
                        <TagWrapperSCP>
                            {
                                item.treatments?.map((treatment: INameAndCodeResponseDTO) => (
                                    <TagCP
                                        key={`key-${treatment.code}`}
                                        color={'purple'}
                                        content={treatment.name}
                                    />
                                ))
                            }
                        </TagWrapperSCP>
                    )
                }
            },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEdit(item.code)}
                            marginRight={5}
                        />
                        <ButtonCP
                            type={'danger'}
                            icon={'delete'}
                            size={'small'}
                            confirmMsg={'Você tem certeza que deseja remover este formulário?'}
                            onClick={() => onRemove(item.code)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

}

const TagWrapperSCP = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  .ant-tag {
    margin: 3px;
  }
`
