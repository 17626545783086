import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChartSalesFunnelCP } from 'main/modules/reports/components/chart-sales-funnel/ChartSalesFunnelCP'
import { DrawerReportCommonFiltersCP } from 'main/modules/reports/components/reports-common-filters/DrawerReportCommonFiltersCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import moment from 'moment'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'

/**
 * Relatorio de Indicadores do Pipeline de Vendas.
 */
export function ScreenContentFunnelIndicatorsReportCP(): JSX.Element {

    const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(false)
    const [formValidator, setFormValidator] = useState<ReportsCommonFiltersFormModel>((new ReportsCommonFiltersFormModel()))
    const formStateManager = useFormStateManager<ReportsCommonFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)

    useEffect(() => { init() }, [])

    /**
     * Inicializa definindo os filtros iniciais.
     */
    async function init(): Promise<void> {

        setFormValidator(new ReportsCommonFiltersFormModel({
            dateRange: {
                beginDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate()
            },
            franchisesCodes: AppStateUtils.getCurrentFranchise()!.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code]
        }))

        // Necessario para o sincronismo entre setar os filtros default e chamar a query com os filtros setadas
        await SystemUtils.sleep(10)
        setShouldLoadReport(true)
    }

    return (
        <>
            <HeaderCP title={'Principais Indicadores do Pipeline de Vendas'}>
                <HeaderButtonICP
                    onClick={() => setIsFilterDrawerVisible(!isFilterDrawerVisible)}
                    icon={'filter'}
                    label={'Filtros'}
                />
            </HeaderCP>

            <ContentWrapperSCP>
                <ContentSCP>
                    <ChartSalesFunnelCP
                        height={400}
                        formStateManager={formStateManager}
                        shouldLoadReport={shouldLoadReport}
                        onReportLoaded={() => setShouldLoadReport(false)}
                    />
                </ContentSCP>
            </ContentWrapperSCP>

            <DrawerReportCommonFiltersCP
                formStateManager={formStateManager}
                fieldsConfig={{ showSeller: true }}
                visible={isFilterDrawerVisible}
                onClearFilters={() => setFormValidator(new ReportsCommonFiltersFormModel({}))}
                onFilter={() => setShouldLoadReport(true)}
                onClose={() => setIsFilterDrawerVisible(false)}
                loading={shouldLoadReport}
            />
        </>
    )
}

const ContentSCP = styled.div`
  width: 100%;
`

const ContentWrapperSCP = styled.div`
    display: flex;
    overflow-x: auto;
    overflow: hidden;
    justify-content: center;
`
