import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { ScheduleReportItemStatusEnum } from 'main/modules/reports/services/scheduler-reports/enums/ScheduleReportItemStatusEnum'

export class ReportsCommonFiltersFormModel extends FormModel {

    dateRange?: IDateRangeFilter
    franchisesCodes?: number[]
    userSellerCode?: number
    userAttendanceCode?: number
    scheduleStatus: ScheduleReportItemStatusEnum
    showEvaluations: boolean
    showConsolidated?: boolean

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
