import { SystemConfig } from 'main/config/SystemConfig'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { ITagSearchRequestDTO } from '../dtos/requests/ITagSearchRequestDTO'

const MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/tags`

/**
 * REQUISICOES
 * Define parametros para requisicoes relacionadas a TAGS.
 */
export const TagRequests = {

    search: (dto?: ITagSearchRequestDTO): RequestConfigTP => ({
        url: MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto,
    }),

    atualizar: (dto: any): RequestConfigTP => ({
        url: `${MODULE_BASE}/${dto.code}`,
        method: HttpMethodEnum.PUT,
        params: {name:dto.name},
    }),
    salvar: (dto: any): RequestConfigTP => ({
        url: `${MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: {name:dto.name},
    }),

}
