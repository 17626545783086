import React, { useEffect } from 'react'
import { TimelineCP } from 'main/common/components/timeline/TimelineCP'
import { IconICP } from 'main/common/components/icons/inner/IconICP'
import styled from 'styled-components'
import { CollapseMktTransactionalsCP } from 'main/modules/marketing/components/collapse-mkt-transactionals/CollapseMktTransactionalsCP'
import { MarketingTransactionalEventEnum } from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IMarketingTransactionalResponseDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/responses/IMarketingTransactionalResponseDTO'
import { MarketingTransactionalRequests } from 'main/modules/marketing/services/marketing-transactional/MarketingTransactionalRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface ICPProps {
    event: MarketingTransactionalEventEnum
    onEdit: (code: number) => void
    mustUpdateData: boolean
    onLoadTransactionals: () => void
}

/**
 * Timeline das automacoes de mkt.
 */
export function TimelineMktTransactionalsCP(props: ICPProps): JSX.Element {

    const searchMktTransactionalsRequest = useRequest<IGenericListResponseDTO<IMarketingTransactionalResponseDTO>>()
    useEffect(onSearchMktTransactionalsRequestChange, [searchMktTransactionalsRequest.isAwaiting])

    useEffect(() => { init() }, [props.mustUpdateData])

    /**
     * Inicializa.
     */
    async function init(): Promise<void> {

        if (!props.mustUpdateData)
            return

        await loadDataFromApi()
    }

    /**
     * Inicializa.
     */
    async function loadDataFromApi(): Promise<void> {
        searchMktTransactionalsRequest.runRequest(await MarketingTransactionalRequests.search({ event: props.event }))
    }

    /**
     * Retorno da requisicao que busca contas.
     */
    function onSearchMktTransactionalsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchMktTransactionalsRequest, 'Não foi possível obter os eventos'))
            return

        props.onLoadTransactionals()
    }

    return (
        <WrapperSCP>
            <TimelineCP
                loading={searchMktTransactionalsRequest.isAwaiting}
                items={
                    searchMktTransactionalsRequest.responseData?.list?.map((transactional) => ({
                        dot:
                            <IconICP
                                iconName={'clock-circle'}
                                color={(!transactional.smsText && !transactional.whatsappText && !transactional.whatsappText) ? ThemeAnt.errorColor : undefined}
                            />,
                        content:
                            <WrapperTimelineItemSCP>
                                <CollapseMktTransactionalsCP
                                    mktTransactional={transactional}
                                    onChangeData={loadDataFromApi}
                                    onEdit={() => props.onEdit(transactional.code)}
                                    event={props.event}
                                />
                            </WrapperTimelineItemSCP>
                    })) ?? []
                }
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;

  .ant-timeline-item-head {
    background: none;
    padding-right: 10px;
    margin-top: 10px;
  }

  .ant-timeline-item-tail {
    top: 31px;
    left: 0;
  }
`

const WrapperTimelineItemSCP = styled.div`
  width: 100%;

  .ant-collapse-item {
    background: #fff;
    margin-top: 20px;
    border-radius: 4px !important;
  }
`
