import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { BankAccountWithdrawFiltersFormModel } from 'main/modules/financial/components/bank-account-transfer-filters/inner/BankAccountWithdrawFiltersFormModel'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'

interface ISalesAdvancedFiltersCPProps {
    formStateManager: IFormStateManager<BankAccountWithdrawFiltersFormModel>
}

/**
 * Filtros avancados, mais filtros, da tela de vendas.
 */
export function BankAccountTransferAdvancedFiltersCP(props: ISalesAdvancedFiltersCPProps): JSX.Element {

    return (
        <>
            <SelectFranchiseCP
                isMultiple={true}
                formStateManager={props.formStateManager}
            />

            <AutocompleteBankAccountCP
                formStateManager={props.formStateManager}
                label={'Conta Origem'}
                fieldName={'bankAccountSourceCode'}
                required={true}
            />

            <AutocompleteBankAccountCP
                formStateManager={props.formStateManager}
                label={'Conta Destino'}
                fieldName={'bankAccountTargetCode'}
                required={true}
            />

            <FlexCP alignItems={'space-between'}>
                <InputCP
                    label={'Valor Mín.'}
                    mask={InputMaskTypeEnum.MONEY}
                    formStateManager={props.formStateManager}
                    fieldName={'valueMin'}
                    marginRight={10}
                />
                <InputCP
                    label={'Valor Máx.'}
                    mask={InputMaskTypeEnum.MONEY}
                    formStateManager={props.formStateManager}
                    fieldName={'valueMax'}
                />
            </FlexCP>
        </>
    )
}
