import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { BankAccountTypeColumnICP } from 'main/modules/financial/components/table-bank-account/inner/BankAccountTypeColumnICP'
import { NumberCP } from 'main/common/components/number/NumberCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { BankAccountUtils } from 'main/modules/financial/utils/BankAccountUtils'
import { BankAccountNameColumnICP } from 'main/modules/financial/components/table-bank-account/inner/BankAccountNameColumnICP'
import { IconBankAccountLogoCP } from 'main/modules/financial/components/icon-bank-account-logo/IconBankAccountLogoCP'

/**
 * UTILITARIOS do componente de listagem de contas bancarias.
 */
export const BankAccountTableUtils = {

    getTableColumnsConfig(type: BankAccountTypeEnum, onSelectBankAccount: (code: number) => void): Array<ColumnProps<IBankAccountResponseDTO>> {
        return [
            {
                title: '',
                dataIndex: 'logo',
                key: 'logo',
                render: (text, dto) => <IconBankAccountLogoCP bankLogoUrl={dto.bankLogoUrl}/> //<img src={dto.bankLogoUrl} height={50}/>
            },
            {
                title: BankAccountUtils.getBankAccountTypeLabel(type),
                dataIndex: 'bank',
                key: 'bank',
                render: (text, dto) => <BankAccountNameColumnICP bankAccount={dto}/>
            },
            {
                title: type === BankAccountTypeEnum.BANK_ACCOUNT ? 'Tipo de conta' : '',
                dataIndex: 'type',
                key: 'type',
                render: (text, dto) => <BankAccountTypeColumnICP bankAccount={dto}/>
            },
            {
                title: 'Saldo',
                dataIndex: 'balance',
                key: 'balance',
                render: (text, dto) => <NumberCP value={dto.balance} size={'large'} strong={true}/>
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: (text, dto) => !dto.isActive ? <TagCP key={'status'} color={'red'} content={'Encerrada'}/> : <></>
            },
            {
                title: '',
                dataIndex: 'act',
                key: 'act',
                align: 'right',
                render: (text, dto) => (
                    <ButtonCP
                        size={'small'}
                        type={'primary'}
                        icon={'edit'}
                        tooltip={'Detalhes'}
                        onClick={() => onSelectBankAccount(dto.code)}
                    />
                )
            }
        ]
    },

}
