import React, { useState } from 'react'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalChargeHistoryCP } from 'main/modules/financial/components/modals/modal-charge-history/ModalChargeHistoryCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    chargeCode: number
    appearance?: {
        showText?: boolean
    }
}

/**
 */
export function ButtonChargeHistoryCP(props: ICPProps): JSX.Element {

    const [isChargeHistoryModalVisible, setIsChargeHistoryModalVisible] = useState<boolean>(false)

    return (
        <>
            <ButtonCP
                type={'ghost'}
                onClick={() => setIsChargeHistoryModalVisible(true)}
                icon={'history'}
                tooltip={props.appearance?.showText ? undefined : 'Histórico de eventos'}
                size={'small'}
                marginTop={props.margin?.top}
                marginBottom={props.margin?.bottom}
                marginRight={props.margin?.right}
                marginLeft={props.margin?.left}
            >
                {props.appearance?.showText ? 'Histórico de eventos' : undefined}
            </ButtonCP>

            <ModalChargeHistoryCP
                visible={isChargeHistoryModalVisible}
                chargeCode={props.chargeCode}
                onCancel={() => setIsChargeHistoryModalVisible(false)}
            />
        </>
    )
}
