import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { BulkOperationEnum } from 'main/modules/admin/services/bulk-operation/enums/BulkOperationEnum'
import { BulkOperationRequests } from 'main/modules/admin/services/bulk-operation/BulkOperationRequests'
import { TableCP } from 'main/common/components/table/TableCP'
import { BulkOperationTableUtils } from 'main/modules/admin/components/table-bulk-operations/inner/BulkOperationTableUtils'
import { IBulkOperationResponseDTO } from 'main/modules/admin/services/bulk-operation/responses/IBulkOperationResponseDTO'
import { ModalBulkOperationsLog } from 'main/modules/admin/components/modal-bulk-operations-log/ModalBulkOperationsLog'

interface IBankAccountTableCPProps {
    type: BulkOperationEnum
    shouldReloadList: boolean
    onListLoaded: () => void
}

/**
 * Componente de tabela que contas bancarias
 */
export function TableBulkOperations(props: IBankAccountTableCPProps): JSX.Element {

    const [isLogModalVisible, setIsLogModalVisible] = useState<boolean>(false)
    const [selectedBulkOperation, setSelectedBulkOperation] = useState<IBulkOperationResponseDTO>()

    const searchBulkOperationsRequest = useRequest<IGenericListResponseDTO<IBulkOperationResponseDTO>>()
    useEffect(onSearchBulkOperationsRequestChange, [searchBulkOperationsRequest.isAwaiting])

    useEffect(init, [props.shouldReloadList])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldReloadList)
            return

        searchBulkOperationsRequest.runRequest(BulkOperationRequests.search({ operation: props.type }))
    }

    /**
     * Retorno da requisicao.
     */
    function onSearchBulkOperationsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchBulkOperationsRequest, 'Não foi possível obter log das operações em massa'))
            return

        props.onListLoaded()
    }

    /**
     * Ao clicar para mostrar logs.
     */
    function showLogs(record: IBulkOperationResponseDTO): void {

        setIsLogModalVisible(true)
        setSelectedBulkOperation(record)
    }

    return (
        <>
            <TableCP
                columns={BulkOperationTableUtils.getTableColumns(
                    showLogs
                )}
                data={searchBulkOperationsRequest.responseData?.list ?? []}
                loading={searchBulkOperationsRequest.isAwaiting}
            />

            <ModalBulkOperationsLog
                visible={isLogModalVisible}
                onClose={() => setIsLogModalVisible(false)}
                bulkOperation={selectedBulkOperation}
            />
        </>
    )
}
