import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IPersonContactSaveRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSaveRequestDTO'
import { IPersonContactSearchRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSearchRequestDTO'
import { IPersonContactSendBulkMessageSaveRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSendBulkMessageSaveRequestDTO'

/**
 * REQUISIÇÕES relacionadas a Contatos de uma PESSOA / OPORTUNIDADE.
 */
export class PersonContactRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/person-contacts`

    static create = (dto: IPersonContactSaveRequestDTO): RequestConfigTP => ({
        url: PersonContactRequests._MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IPersonContactSaveRequestDTO): RequestConfigTP => ({
        url: `${PersonContactRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static markAsDone = (code: number): RequestConfigTP => ({
        url: `${PersonContactRequests._MODULE_BASE}/${code}/mark-as-done`,
        method: HttpMethodEnum.PUT,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${PersonContactRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static search = (dto: IPersonContactSearchRequestDTO): RequestConfigTP => ({
        url: `${PersonContactRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static sendBulkMessage = (dto: IPersonContactSendBulkMessageSaveRequestDTO): RequestConfigTP => ({
        url: `${PersonContactRequests._MODULE_BASE}/send-bulk-message`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}

