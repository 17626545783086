import React, { useEffect, useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { StopWatchCP } from 'submodules/nerit-framework-ui/common/components/clock/StopWatchCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { StartOrFinishAttendanceRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/StartOrFinishAttendanceRequestDTO'
import { SessionsRequests } from 'submodules/neritclin-sdk/services/sessions/SessionsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import styled from 'styled-components'

interface ICPProps {
    status: SessionStatusEnum
    sessionCodes: number[]
    onStartAttendance: () => void
    onFinishAttendance: () => void
}



/**
 * Detalhes de um agendamento
 */
export function StopWatchAttendanceCP(props: ICPProps): JSX.Element {
    const [iniciado,setIniciado] = useState<boolean>(false);
    const [startStopAttendance, setStartStopAttendance] = useState<'start' | 'finish'>()
    const startStopAttendanceRequest = useRequest<void>('none')
    useEffect(onStartStopAttendanceRequestChange, [startStopAttendanceRequest.isAwaiting])

    /**
     * Iniciar ou Termina atendimento.
     */
    function startFinishAttendance(mode: 'start' | 'finish'): void {

        const dto: StartOrFinishAttendanceRequestDTO = {
            sessionCodes: props.sessionCodes,
        }

        console.log("O DTO ", dto)
        setStartStopAttendance(mode)

        if (mode === 'start'){
            localStorage.removeItem('sessao_atual_atendimento');
            startStopAttendanceRequest.runRequest(SessionsRequests.startAttendance(dto))
        }else{
            startStopAttendanceRequest.runRequest(SessionsRequests.finishAttendance(dto))
        }
    }

    /**
     * Retorno api
     */
    function onStartStopAttendanceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(startStopAttendanceRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE))
            return

        if (startStopAttendance === 'start'){
            props.onStartAttendance()
            
        }else{
            props.onFinishAttendance()
        }
    }
    
    let finalizar = false;
    let valor:any = localStorage.getItem('sessao_atual_atendimento'); // pelo menos uma sessão foi evoluida
    if(valor)valor = parseInt(valor);
    if(props.sessionCodes.includes(valor))finalizar = true;

    return (
        <>
        <FlexCP justifyContent={'center'} marginBottom={30} >
            
            <StopWatchCP
                shouldStart={false}
                appearance={{
                    hideSeconds: true,
                    actionButtonMode: 'toogle',
                    tootleAction: {
                        startLabel: `${props.status === SessionStatusEnum.IN_PROGRESS ? 'Reiniciar' : 'Iniciar'} Atendimento`,
                        finishLabel: finalizar?'Finalizar Atendimento':'Cancelar',
                        startAction: () => {
                            localStorage.removeItem('sessao_atual_atendimento');
                            setIniciado(true);
                            startFinishAttendance('start');
                        },
                        finishAction: () => {
                            localStorage.removeItem('sessao_atual_atendimento');
                            setIniciado(false);
                            startFinishAttendance('finish');
                        },
                        loading: startStopAttendanceRequest.isAwaiting
                    }
                }}
            />
            
        </FlexCP>
        <ConditionalRenderCP shouldRender={!finalizar && iniciado} key={1} >
                
        <CampoOrientacao>
            <div className='indicativo'>
               Para finalizar o atendimento é necessário que pelo menos uma sessão seja evoluída
            </div>
        </CampoOrientacao>
    
        </ConditionalRenderCP>
        </>
    )
}

const CampoOrientacao = styled.div`
    position:relative;
    .indicativo{
        font-weight: 600;
        text-transform: uppercase;
        font-size: 11px;
        text-align: center;
        position: relative;
        bottom: 27px;
        color: #d1423d;
    }
    
`
