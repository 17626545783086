import moment from 'moment'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IPersonContactSaveRequestDTO } from 'main/modules/people/services/person-contact/dtos/request/IPersonContactSaveRequestDTO'
import { PersonContactStatusEnum } from 'main/modules/people/services/person-contact/enums/PersonContactStatusEnum'
import { PersonContactFormModel } from 'main/modules/people/components/form-person-contact/inner/PersonContactFormModel'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PersonContactTypeEnum } from 'main/modules/people/services/person-contact/enums/PersonContactTypeEnum'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'

export const PERSON_CONTACT_DEFAULT_END_TIME_MINUTES = 5

/**
 * Utilitario do formulario de interacao com pessoa.
 */
export const PersonContactFormUtils = {

    /**
     * Monta o DTO para salvar uma interacao a partir do form.
     */
    mountSaveDto(
        formValues: PersonContactFormModel,
        entityCode: number,
        entity: PersonContactEntityEnum,
    ): IPersonContactSaveRequestDTO {

        // Ajusta as datas de acordo com horario de inicio e termino
        const beginDate = moment(formValues.date).toDate()
        beginDate.setHours(formValues.beginTime.hour(), formValues.beginTime.minute(), 0)

        const endDate = moment(formValues.date).toDate()
        endDate.setHours(formValues.endTime.hour(), formValues.endTime.minute(), 0)

        return {
            type: formValues.type,
            description: formValues.description,
            beginDate: DateUtils.getFormatted(beginDate, DateFormatEnum.US_WITH_TIME_H_M),
            endDate: DateUtils.getFormatted(endDate, DateFormatEnum.US_WITH_TIME_H_M),
            userResponsibleCode: formValues.responsibleCode,
            status: formValues.isConcluded ? PersonContactStatusEnum.COMPLETED : PersonContactStatusEnum.IN_PROGRESS,
            entity,
            entityCode,
        }
    },

    /**
     * Inicializa os dados do form seja com valores padrao, ou com dados para edicao.
     */
    initFormModel(dto?: IPersonContactResponseDTO): PersonContactFormModel {

        if (!dto) {
            const defaultEndTime = moment(new Date())
            defaultEndTime.add(PERSON_CONTACT_DEFAULT_END_TIME_MINUTES, 'minutes')

            return new PersonContactFormModel({
                responsibleCode: AppStateUtils.getLoggedUserData()!.user.code,
                type: PersonContactTypeEnum.WHATSAPP,
                date: new Date(),
                beginTime: moment(new Date()),
                endTime: defaultEndTime,
                isConcluded: false,
                description: ''
            })
        }

        const beginDateFormatted = DateUtils.toDate(dto.beginDate, DateFormatEnum.US_WITH_TIME_H_M)
        const endDateFormatted = DateUtils.toDate(dto.endDate, DateFormatEnum.US_WITH_TIME_H_M)
        return new PersonContactFormModel({
            type: dto.type,
            description: dto.description,
            isConcluded: dto.status === PersonContactStatusEnum.COMPLETED,
            responsibleCode: dto.userResponsible.code,
            date: beginDateFormatted,
            beginTime: moment(beginDateFormatted),
            endTime: moment(endDateFormatted),
        })
    }

}
