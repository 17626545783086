import React, { useEffect, useState } from 'react'
import { TableBankAccountCP } from 'main/modules/financial/components/table-bank-account/TableBankAccountCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { StatisticCP } from 'main/common/components/statistic/StatisticCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { BankAccountScreenContentUtils } from 'main/modules/financial/screens/screen-financial/content/screen-content-bank-account/inner/BankAccountScreenContentUtils'
import {
    BankAccountTypeEnum,
    BankAccountTypeLabelEnum,
    BankAccountTypePluralLabelEnum
} from 'main/modules/financial/enums/BankAccountTypeEnum'
import { BalancePerPeriodChartCP } from 'main/modules/financial/components/balance-per-period-chart/BalancePerPeriodChartCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { BankAccountRequests } from 'main/modules/financial/services/bank-account/BankAccountRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { DrawerBankAccountCP } from 'main/modules/financial/components/drawer-bank-account/DrawerBankAccountCP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'

interface IBankAccountScreenContentCPProps {
    type: BankAccountTypeEnum
}

/**
 * COMPONENTE
 * Conteudo da Tela de contas bancarias
 */
export function ScreenContentBankAccountCP(props: IBankAccountScreenContentCPProps): JSX.Element {

    const [shouldLoadList, setShouldLoadList] = useState<boolean>(true)
    const [isBankAccountDrawerVisible, setIsBankAccountDrawerVisible] = useState<boolean>(false)
    const [selectedBankAccounCode, setSelectedBankAccountCode] = useState<number>()

    const [balance, setBalance] = useState<number>()
    const balanceRequest = useRequest('none')

    useEffect(init, [props.type])
    useEffect(onBalanceRequestChange, [balanceRequest.isAwaiting])

    /**
     * Inicializa os dados da tela.
     */
    function init(): void {
        setShouldLoadList(true)
        balanceRequest.runRequest(BankAccountRequests.balanceConfig({ type: props.type }))
    }

    /**
     * Retorno da busca de saldo.
     */
    function onBalanceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(balanceRequest, 'Erro ao obter saldo saldo'))
            return

        setBalance((balanceRequest.responseData as any).data)
    }

    return (
        <>
            <HeaderCP title={BankAccountTypePluralLabelEnum[props.type]}>
                <HeaderButtonICP
                    onClick={() => {
                        setIsBankAccountDrawerVisible(true)
                        setSelectedBankAccountCode(undefined)
                    }}
                    icon={'plus'}
                    label={`Nova ${BankAccountTypeLabelEnum[props.type]}`}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <BalanceSCP>
                    <StatisticCP
                        value={balance}
                        label={'Saldo Total'}
                        icon={<FontAwsomeIconCP type={faBalanceScale}/>}
                        loading={balanceRequest.isAwaiting}
                        valueUnit={'reais'}
                        footer={BankAccountScreenContentUtils.getBalanceFooterLabel(props.type)}
                        forceDecimal={true}
                    />
                </BalanceSCP>

                <TableBankAccountCP
                    onSelectBankAccount={(code) => {
                        setSelectedBankAccountCode(code)
                        setIsBankAccountDrawerVisible(true)
                    }}
                    type={props.type}
                    shouldReloadList={shouldLoadList}
                    onListLoaded={() => setShouldLoadList(false)}
                />

                <BalancePerPeriodChartCP type={props.type}/>
            </LayoutSubmenuContentCP>

            <DrawerBankAccountCP
                visible={isBankAccountDrawerVisible}
                onClose={() => {
                    setSelectedBankAccountCode(undefined)
                    setIsBankAccountDrawerVisible(false)
                    init()
                    setShouldLoadList(true)
                }}
                bankAccountCode={selectedBankAccounCode}
                type={props.type}
            />
        </>
    )
}

const BalanceSCP = styled.div`
  display: flex;
  justify-content: flex-end;
`
