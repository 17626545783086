/**
 * ENUM
 * Define bases de tempo.
 *
 * OBSERVACAO: Manter valores em lowercase para compatibilidade com moment.js!
 *
 * @author guilherme.diniz
 * @author Lucas Rosa
 * @author hjcostabr
 */
export enum TimeBaseEnum {
    SECOND = 'second',
    MINUTE = 'minute',
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}
