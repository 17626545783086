import React from 'react'
import "photoviewer/dist/photoviewer.min.css"
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { PdfMozillaViewer } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-mozilla-viewer/PdfMozillaViewer'

interface ICPProps {
    visible: boolean
    fileName: string
    fileSrc: string
    onClose: () => void
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ModalPdfViewerCP(props: ICPProps): JSX.Element {

    return (
        <ModalCP
            title={props.fileName}
            onCancel={props.onClose}
            noFooter={true}
            visible={props.visible}
            width={1000}
            top={10}
        >
            <PdfMozillaViewer
                pdfSrc={props.fileSrc}
                zoomOption={'page-fit'}
            />
        </ModalCP>
    )
}
