import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import React from 'react'
import { ScreenSalesFunnel } from 'main/modules/sales-funnel/screens/screen-sales-funnel/ScreenSalesFunnel'

SalesFunnelRoutes.ROOT = 'funil-de-vendas'
SalesFunnelRoutes.ROOT_WITH_QUERY = '/funil-de-vendas/:view'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(SalesFunnelRoutes.ROOT),
        path: SalesFunnelRoutes.ROOT_WITH_QUERY,
        component: ScreenSalesFunnel,
    },
    {
        key: StringUtils.getSlugStyleString(SalesFunnelRoutes.ROOT),
        path: SalesFunnelRoutes.ROOT,
        component: ScreenSalesFunnel,
    },
]

/**
 * Componente que define as rotas para acesso as telas do FUNIL DE VENDAS
 */
export function SalesFunnelRoutes(): JSX.Element {

    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_CRM]}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

