import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator'
import { ValidationTypes } from 'main/common/validation/ValidationTypes'

/**
 * DECORATOR
 * Validador de campo para telefone em formatos brasileiros.
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export function IsPhoneBR(mask?: boolean, validationOptions?: ValidationOptions) {    // eslint-disable-line @typescript-eslint/naming-convention
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [mask],
            validator: IsPhoneBRConstraint
        })
    }
}

@ValidatorConstraint({ name: ValidationTypes.IS_PHONE_BR })
class IsPhoneBRConstraint implements ValidatorConstraintInterface {     // eslint-disable-line @typescript-eslint/naming-convention

    validate(value: string, args: ValidationArguments): boolean {

        const shouldValidateWithMask = (args.constraints[0] !== false)
        const shouldValidateWithoutMask = (args.constraints[0] !== true)

        return (!value ||
            (shouldValidateWithMask && /^\(\d{2}\)(\s*:?)(9\d{4}-\d{4}|[1-6]\d{3}-\d{4})$/.test(value))
            || (shouldValidateWithoutMask && /^\d{2}(9\d{8}|[1-6]\d{7})$/.test(value))
        )
    }

    defaultMessage(): string {
        return 'Telefone inválido'
    }
}
