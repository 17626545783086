import * as _ from 'lodash'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { ReduxHelper } from 'main/common/redux/helpers/ReduxHelper'
import { IReduxState } from 'main/common/redux/interfaces/IReduxState'
import { UserDataTP } from 'main/common/redux/types/UserDataTP'
import { RequestHelper as RequestHelperOLD } from 'main/common/request-manager/RequestHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AuthUtils } from 'main/common/utils/AuthUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ISchemaResponseDTO } from 'main/modules/app/services/dtos/response/ISchemaResponseDTO'
import { AuthRoutes } from 'main/modules/auth/AuthRoutes'
import { IAuthUserFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserFranchiseResponseDTO'
import { IAuthUserResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserResponseDTO'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'

/**
 * ACTIONS
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas ao modulo de autenticacao.
 */
export class AuthActions {

    static readonly TYPE_UPDATE_DOMAIN = 'update_domain'
    static readonly TYPE_UPDATE_USER = 'update_user'
    static readonly TYPE_UPDATE_CURRENT_FRANCHISE = 'update_current_franchise'

    private static _handlingUnauthorizedError = false

    private constructor() { }

    /** Encapsula procedimento completo de 'logar' 01 usuario do sistama. */
    static login(user: IAuthUserResponseDTO, token: string): void {

        // Se nao tiver franqueadora nem franquias, nao loga
        if (SystemUtils.isEmpty(user?.franchises) && !user?.franchisor) {
            NotificationHelper.error('Ops!', 'Você não tem acesso a nenhuma unidade')
            return AuthActions.logout()
        }

        // Define usuario logado
        const userPayload: UserDataTP = { token, user }

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: userPayload,
        })

        // Define franquia atual
        const franchisePayload: IAuthUserFranchiseResponseDTO = user?.franchisor ? user.franchisor : user.franchises[0]

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_FRANCHISE,
            payload: franchisePayload,
        })

        this.refreshLoginData(token)
    }

    /** Atualiza dados do cliente (schema). */
    static setDomain(schema: ISchemaResponseDTO, mktToken?: string): void {

        RequestHelperOLD.addDefaultHeader('schema', schema.slug)
        RequestHelper.addDefaultHeader('schema', schema.slug)

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_DOMAIN,
            payload: { schema, mktToken }
        })

    }

    static setCurrentFranchise(franchise: IAuthUserFranchiseResponseDTO): void {

        RequestHelperOLD.addDefaultHeader('on-behalf-of', franchise.code.toString())
        RequestHelper.addDefaultHeader('on-behalf-of', franchise.code.toString())

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_FRANCHISE,
            payload: franchise
        })
    }

    /** Encapsula procedimento completo de 'deslogar'. */
    static logout(): void {

        RoutingHelper.setDefaultRoute('')

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: null,
        })

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_FRANCHISE,
            payload: null,
        })

        this.refreshLoginData(null)
        RoutingHelper.historyReplace(AuthRoutes.USER_LOGIN)
    }

    /** Action para tratamento erro 401 (nao autorizado) em requisicoes. */
    static onUnauthorizedRequestResponse(): void {

        if (AuthActions._handlingUnauthorizedError)
            return

        const currentState: IReduxState = ReduxHelper.getInstance().store.getState()

        if (!_.isEmpty(currentState.userData)) {
            AuthActions._handlingUnauthorizedError = true
            const durationSeconds = 6
            NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', durationSeconds)
            setTimeout(() => AuthActions._handlingUnauthorizedError = false, (durationSeconds - 1) * 100)
        }

        AuthActions.logout()
    }

    /**
     * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as
     * com o conteudo mantido no estado global da aplicacao (redux).
     */
    static refreshLoginData(token: string | null): void {
        if (token && AppStateUtils.getCurrentFranchise()) {
            RequestHelperOLD.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
            RequestHelperOLD.addDefaultHeader('on-behalf-of', AppStateUtils.getCurrentFranchise()!.code.toString())
            RequestHelper.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
            RequestHelper.addDefaultHeader('on-behalf-of', AppStateUtils.getCurrentFranchise()!.code.toString())
        }
    }
}
