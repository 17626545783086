import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { ResumeWidgetCardCP } from 'main/common/components/resume-widget-card/ResumeWidgetCardCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFinancialTransactionsSummaryRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionsSummaryRequestDTO'
import { IFinancialTransactionSummaryResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionSummaryResponseDTO'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { FinancialTransactionRequests } from 'submodules/neritclin-sdk/services/financial/financial/FinancialTransactionRequests'

interface IChargingSummaryHeaderICPProps {
    filters: IFinancialTransactionSearchRequestDTO
    shouldReloadSummary: boolean
    onSummaryLoaded: () => void
}

/**
 * Cabecalho com widgets da tela de cobranca.
 */
export function ChargingSummaryHeaderICP(props: IChargingSummaryHeaderICPProps): JSX.Element {

    useEffect(init, [props.shouldReloadSummary])

    const [summaryData, setSummaryData] = useState<IFinancialTransactionSummaryResponseDTO>()
    const summaryRequest = useRequest<IFinancialTransactionSummaryResponseDTO>()
    useEffect(onSummaryRequestChange, [summaryRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldReloadSummary)
            return

        const searchDto: IFinancialTransactionsSummaryRequestDTO = {
            bankAccountCode: props.filters.bankAccountCode,
            expirateInterval: props.filters.expirateInterval,
            paymentMethod: props.filters.paymentMethod,
            personCode: props.filters.personCode,
            searchString: props.filters.searchString,
            type: FinancialTransactionTypeEnum.CREDIT,
            paymentInterval: props.filters.paymentInterval,
            paymentPartnerCode: props.filters.paymentPartnerCode,
            saleInterval: props.filters.saleInterval,
            valueRange: props.filters.valueRange,
            considerOnlySaleTransactions: true,
            referenceInterval: props.filters.referenceInterval
        }
        summaryRequest.runRequest(FinancialTransactionRequests.summaryBillingDashboardFinancialTransactions(searchDto))
    }

    /**
     * Retorno dos dados da API
     */
    function onSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(summaryRequest, 'Ocorreu algun erro ao obter resumo movimentações'))
            return

        setSummaryData(summaryRequest.responseData)
        props.onSummaryLoaded()
    }

    return (
        <WrapperSCP>
            <ResumeWidgetCardCP
                loading={summaryRequest.isAwaiting}
                icon={<FontAwsomeIconCP type={faExclamationCircle} color={ThemeAnt.errorColor}/>}
                value={MaskUtils.applyMoneyMask(summaryData?.overdueValue ?? 0)}
                extra={`${summaryData?.overdueCount ?? 0} estão atrasadas`}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display:flex;
  width:100%;
  justify-content: center;
  flex-direction: row;
  padding: 15px;
`
