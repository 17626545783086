import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import styled from 'main/config/theme/styledWithTheme'
import React, { useState } from 'react'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { AutoCompleteOpportunityCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity/AutoCompleteOpportunityCP'

interface ICPProps {
    onSelectOportunity: (code: number) => void
}

/**
 * COMPONENTE de acoes rapidas de oportunidade.
 */
export function ButtonSearchOpportunityCP(props: ICPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

    return (
        <>
            <ContentWrapperSCP>
                <PopOverCP
                    title={'Busque por um LEAD em todo o FUNIL'}
                    trigger={'click'}
                    placement={'bottom'}
                    visible={isPopoverVisible}
                    onClose={(): void => setIsPopoverVisible(false)}
                    content={
                        // Colocando o Visible para exibir ou nao o conteudo para que seja destruido o componetne ao fechar,
                        // assim elimina problema de nao poder selecionar pela segunda vez
                        <ConditionalRenderCP shouldRender={isPopoverVisible}>
                            <ContainerWrapperSCP>
                                <AutoCompleteOpportunityCP
                                    label={'Digite o Nome / E-mail / Tel.'}
                                    onChange={(opportunity) => {
                                        setIsPopoverVisible(false)
                                        props.onSelectOportunity(opportunity)
                                    }}
                                    width={300}
                                />
                            </ContainerWrapperSCP>
                        </ConditionalRenderCP>
                    }
                >
                    <ButtonCP
                        onClick={(): void => setIsPopoverVisible(true)}
                        type={'dashed'}
                        icon={'search'}
                    >
                        Buscar
                    </ButtonCP>
                </PopOverCP>
            </ContentWrapperSCP>
        </>
    )
}

const ContentWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${(props): string => props.theme.primaryColor};
`

const ContainerWrapperSCP = styled.div`
    .ant-form-explain {
        display: none;
    }
`

