import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'

/**
 * UTILITARIOS do componente de relatorio de disparos
 *
 * @author renatofs
 */
export class ShotDetailsReportUtils {

    static getColumnNameByReportType(reportType: MarketingShotReportTypeEnum): string {
        switch (reportType) {
            case MarketingShotReportTypeEnum.UNSUBSCRIBES:
                return 'Motivo'

            case MarketingShotReportTypeEnum.CLICKS:
                return 'Link'

            case MarketingShotReportTypeEnum.VIEWS:
                return 'Visualizações'

            default:
                return ''
        }
    }

}
