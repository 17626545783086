import { CardCP } from 'main/common/components/card/CardCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { NewShotButtonCP } from 'main/modules/marketing/components/new-shot-button/NewShotButtonCP'
import { TAB_CONFIG_LIST_EMAIL } from 'main/modules/marketing/components/shot-details-report/inner/TabConfigListEmail'
import { TAB_CONFIG_LIST_SMS } from 'main/modules/marketing/components/shot-details-report/inner/TabConfigListSMS'
import { ShotDetailsReportCP } from 'main/modules/marketing/components/shot-details-report/ShotDetailsReportCP'
import { ShotListItemMobileCP } from 'main/modules/marketing/components/shot-list-item-mobile/ShotListItemMobileCP'
import { ShotListItemCP } from 'main/modules/marketing/components/shot-list-item/ShotListItemCP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { ShotDetailsScreenRouteParamsTP } from 'main/modules/marketing/types/ShotDetailsScreenRouteParamsTP'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { SegmentRequests } from 'main/modules/segment/services/SegmentRequests'

/**
 * TELA de detalhes de um disparo.
 */
export function ScreenShotDetails(): JSX.Element {

    const routeParams = useParams<ShotDetailsScreenRouteParamsTP>()
    const tabConfigList = (routeParams.shotType === MarketingAccountTypeEnum.EMAIL) ? TAB_CONFIG_LIST_EMAIL : TAB_CONFIG_LIST_SMS
    const screenSize = useScreenSize()

    const [shot, setShot] = useState<IMarketingCampaignEmailResponseDTO | IMarketingCampaignSmsResponseDTO>()
    const getShotRequest = useRequest<IMarketingCampaignEmailResponseDTO | IMarketingCampaignSmsResponseDTO>()
    useEffect(onGetShotRequestChange, [getShotRequest.isAwaiting])

    const getSegmentDataReguest = useRequest<ISegmentListItemResponseDTO>()
    useEffect(onGetSegmentDataRequestChange, [getSegmentDataReguest.isAwaiting])

    useEffect(() => { init() }, [])

    /**
     * Inicializa.
     */
    async function init(): Promise<void> {

        if (!routeParams.shotCode || !routeParams.shotType)
            return

        getShotRequest.runRequest(await MarketingRequests.getCampaignShot(routeParams.shotType, +routeParams.shotCode))
    }

    /**
     * Apos chamada de Request para obter disparo, caso tenha passado na URL.
     */
    function onGetShotRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getShotRequest, 'Erro obter disparo'))
            return

        const shotResponse = getShotRequest.responseData!
        setShot(shotResponse)
        getSegmentDataReguest.runRequest(SegmentRequests.getOne(shotResponse.destination.segmentCode))
    }

    /**
     * Retorno ao obter a lista de segmentacao utilizada.
     */
    function onGetSegmentDataRequestChange(): void{

        if (!RequestUtils.isValidRequestReturn(getSegmentDataReguest, 'Erro obter dados do destinatário'))
            return

    }

    return (
        <>
            <HeaderCP title={'Detalhes do Envio'}>
                <NewShotButtonCP/>
            </HeaderCP>

            <ContentSCP>
                <LoadingOverlayCP show={getShotRequest.isAwaiting || getSegmentDataReguest.isAwaiting}/>
                <ShotListSCP>
                    {
                        shot && screenSize.smd &&
                        <ShotListItemMobileCP
                            showActions={false}
                            shot={shot}
                            shotType={routeParams.shotType}
                            semegmentList={getSegmentDataReguest.responseData ? [getSegmentDataReguest.responseData] : []}
                        />
                    }
                    {
                        shot && !screenSize.smd &&
                        <ShotListItemCP
                            shotType={routeParams.shotType}
                            shot={shot}
                            showActions={false}
                            semegmentList={getSegmentDataReguest.responseData ? [getSegmentDataReguest.responseData] : []}
                        />
                    }
                </ShotListSCP>

                <CardCP>
                    <TabsCP
                        tabPosition={screenSize.smd ? 'top' : 'left'}
                        defaultActiveKey={'delivered'}
                        tabs={
                            tabConfigList.map(config => ({
                                key: config.key,
                                title: config.title,
                                content: (
                                    <ShotDetailsReportCP
                                        shotType={routeParams.shotType}
                                        shotCode={+routeParams.shotCode}
                                        title={config.description}
                                        reportType={config.reportType}
                                    />
                                )
                            }))
                        }
                    />
                </CardCP>
            </ContentSCP>
        </>
    )
}

const ShotListSCP = styled.div`
  justify-content: center;
  display: flex;
  padding: 30px 20px 20px 20px;
`

const ContentSCP = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
`
