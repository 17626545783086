import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React from 'react'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'

interface IShotListDateColumnICPProps {
    startDate?: string
    scheduleDate?: string
    status: MarketingShotStatusEnum
}

/**
 * Coluna com status e datas do disparo.
 */
export function ShotListItemDateColumnICP(props: IShotListDateColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    let dateStr: string
    switch (props.status) {

        case MarketingShotStatusEnum.ERROR:
        case MarketingShotStatusEnum.SENDING:
        case MarketingShotStatusEnum.SENT:
            dateStr = props.startDate!
            break

        case MarketingShotStatusEnum.SCHEDULED:
            dateStr = props.scheduleDate!
            break

        default:
            break
    }

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <DateIconContainerSCP>
                <FontAwsomeIconCP type={faEnvelope} color={ThemeAnt.primaryColor}/>
                <span>
                    {' '}
                    {MarketingUtils.getShotStatusLabel(props.status)}
                    {' '}
                </span>
            </DateIconContainerSCP>

            {
                (props.status === MarketingShotStatusEnum.SCHEDULED || props.status === MarketingShotStatusEnum.ERROR || props.status === MarketingShotStatusEnum.SENT) &&
                <DatesContainerSCP isSmallDevice={screenSize.smd}>
                    <span>
                        {' '}
                        {props.status === MarketingShotStatusEnum.SCHEDULED ? 'Agendado para' : 'Enviado em'}
                        {' '}
                    </span>
                    <h4>
                        {' '}
                        {DateUtilsOLD.getFormatted(dateStr!, DateFormatEnum.BR_DAY_MONTH)}
                        {' '}
                    </h4>
                    <span>
                        {' '}
                        { `às ${DateUtilsOLD.getFormatted(dateStr!, DateFormatEnum.TIME_H_M)} de ${DateUtils.getFormatted(dateStr!, DateFormatEnum.YEAR_4)}` }
                        {' '}
                    </span>
                </DatesContainerSCP>
            }

        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`
    width: ${props => (props.isSmallDevice ? '100%' : '120px')};
    border-right: 1px solid #dedede;
    background: #f5f5f5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const DatesContainerSCP = styled.div<{ isSmallDevice: boolean }>`

    padding: 25px;
    flex-direction: column;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 10px;
    background: #fff;
    width: 100%;

    h4 {
        font-size: 1.7rem;
        margin-bottom: 0;
    }

    span {
        font-size: .7rem;
    }

    p {
        margin: 20px 0 10px;
    }
`

const DateIconContainerSCP = styled.div`

    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    align-items: center;
    padding: 20px 0;
    border-top-left-radius: 10px;

    span {
        text-transform: capitalize;
        margin-top: .5rem;
        font-weight: 700;
        font-style: italic;
        font-size: 0.8rem;
    }
`
