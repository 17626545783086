import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { TreatmentRequests } from 'main/modules/admin/services/treatment/TreatmentRequests'
import { ITreatmentResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentResponseDTO'
import { TreatmentGroupFormModel } from 'main/modules/products/components/modal-treatment-group/inner/TreatmentGroupFormModel'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ITreatmentGroupSaveRequestDTO } from 'main/modules/admin/services/treatment/dtos/request/ITreatmentGroupSaveRequestDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'

interface IModalTreatmentGroupCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    treatmentGroupCode?: number
}

/**
 * Tela de listagem de tratamentos.
 */
export function ModalTreatmentGroupCP(props: IModalTreatmentGroupCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<TreatmentGroupFormModel>((new TreatmentGroupFormModel()))
    const formStateManager = useFormStateManager<TreatmentGroupFormModel>(formValidator)

    const getTreatmentGroupRequest = useRequest<ITreatmentResponseDTO>()
    useEffect(onGetTreatmentGroupRequestChange, [getTreatmentGroupRequest.isAwaiting])

    const saveTreatmentGroupRequest = useRequest<ITreatmentResponseDTO>()
    useEffect(onSaveTreatmentGroupRequestChange, [saveTreatmentGroupRequest.isAwaiting])

    const deleteTreatmentGroupRequest = useRequest<void>('none')
    useEffect(onDeleteTreatmentGroupRequestChange, [deleteTreatmentGroupRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        formStateManager.reset(new TreatmentGroupFormModel({}))

        if (!props.visible)
            return

        if (props.treatmentGroupCode)
            return getTreatmentGroupRequest.runRequest(TreatmentRequests.getTreatmentGroup(props.treatmentGroupCode))

    }

    /**
     * Retorno da requisicao que obtem o grupo de tratamento selecionado.
     */
    function onGetTreatmentGroupRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getTreatmentGroupRequest, 'Erro obter grupo de tratamento'))
            return

        const treatmentResult = getTreatmentGroupRequest.responseData!
        setFormValidator(new TreatmentGroupFormModel({
            name: treatmentResult.name,
        }))

    }

    /**
     * Salva grupo de tratamento.
     */
    async function saveTreatmentGroup(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: ITreatmentGroupSaveRequestDTO = {
            name: formValues.name,
        }

        if (!!props.treatmentGroupCode)
            saveTreatmentGroupRequest.runRequest(TreatmentRequests.updateTreatmentGroup(props.treatmentGroupCode, dto))
        else
            saveTreatmentGroupRequest.runRequest(TreatmentRequests.saveTreatmentGroup(dto))
    }

    /**
     * Remover grupo de tratamento.
     */
    function deleteTreatment(): void {

        if (!props.treatmentGroupCode)
            return

        deleteTreatmentGroupRequest.runRequest(TreatmentRequests.deleteTreatmentGroup(props.treatmentGroupCode))
    }

    /**
     *  Retorno da remocao de grupo de tratamento.
     */
    function onDeleteTreatmentGroupRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteTreatmentGroupRequest, 'Não foi remover o grupo de tratamento', true))
            return

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveTreatmentGroupRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveTreatmentGroupRequest, 'Ocorreu algun erro ao salvar grupo de tratamento'))
            return

        NotificationHelper.info('Yes', 'Grupo de Atendimento salvo com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <ModalCP
            title={'Grupo de Atendimento'}
            visible={props.visible}
            width={300}
            destroyOnClose={true}
            onClose={props.onClose}
            loading={getTreatmentGroupRequest.isAwaiting}
            footer={
                <FlexCP justifyContent={!!props.treatmentGroupCode ? 'space-between' : 'flex-end'}>
                    <ConditionalRenderCP shouldRender={!!props.treatmentGroupCode}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteTreatmentGroupRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover este grupo?'}
                            onClick={deleteTreatment}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveTreatmentGroup}
                        loading={saveTreatmentGroupRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </FlexCP>
            }
        >

            <InputCP
                label={'Nome'}
                fieldName={'name'}
                formStateManager={formStateManager}
                required={true}
            />

        </ModalCP>
    )
}
