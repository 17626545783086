import React, { useEffect, useState } from 'react'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { SaleSourcesSyntheticReportCP } from 'main/modules/reports/components/sale-sources-synthetic-report/SaleSourcesSyntheticReportCP'
import moment from 'moment'
import { WidgetTotalSaleValueCP } from 'main/modules/dashboard/components/widget-total-sale-value/WidgetTotalSaleValueCP'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { ListSalesSummaryCP } from 'main/modules/financial/components/sales-summary/ListSalesSummaryCP'
import { ListSalesByPaymentMethodCP } from 'main/modules/financial/components/sales-by-payment-method/ListSalesByPaymentMethodCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    dateFilters: IDateRangeFilter
    onDateRangeChange: (dateFilters: IDateRangeFilter) => void
    franchiseCodes?: number[]
}

/**
 * Aba de indicadores de vendas
 */
export function FranchiseManagerSaleIndicatorsTabICP(props: ICPProps): JSX.Element {

    const [dateFiltersCompareTo, setDateFiltersCompareTo] = useState<IDateRangeFilter>({ beginDate: new Date(), endDate: new Date() })

    useEffect(init, [props.dateFilters])

    /**
     * Inicializa.
     */
    function init(): void {

        let startDateCompareTo = undefined
        if (!!props.dateFilters.beginDate)
            startDateCompareTo = moment(props.dateFilters.beginDate).clone().subtract(1, 'month')

        let endDateCompareTo = undefined
        if (!!props.dateFilters.beginDate)
            endDateCompareTo = moment(props.dateFilters.endDate).clone().subtract(1, 'month')

        setDateFiltersCompareTo({
            beginDate: startDateCompareTo?.toDate(),
            endDate: endDateCompareTo?.toDate()
        })
    }

    return (
        <>
            <FiltersFranchiseDashboardCP
                dateFilters={props.dateFilters}
                onDateRangeChange={props.onDateRangeChange}
                dateFiltersCompareTo={dateFiltersCompareTo}
                onDateRangeCompareToChange={setDateFiltersCompareTo}
                showCompareToDate={true}
            />

            <WidgetsRowCP>
                <WidgetTotalSaleValueCP franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetTotalSaleValueCP titleExtra={'(período anterior)'} franchiseCodes={props.franchiseCodes} dateFilters={dateFiltersCompareTo}/>
            </WidgetsRowCP>

            <WidgetsRowCP marginTop={20}>
                <CardDashboardWidgetWrapperCP title={'Análise das Vendas'} flex={'1'} maxWidth={300}>
                    <ListSalesSummaryCP dateFilter={props.dateFilters} franchiseCodes={props.franchiseCodes}/>
                </CardDashboardWidgetWrapperCP>
                <CardDashboardWidgetWrapperCP title={'Análise das Vendas (período anterior)'} flex={'1'} maxWidth={300}>
                    <ListSalesSummaryCP dateFilter={dateFiltersCompareTo} franchiseCodes={props.franchiseCodes}/>
                </CardDashboardWidgetWrapperCP>
            </WidgetsRowCP>

            <WidgetsRowCP marginTop={20}>
                <ListSalesByPaymentMethodCP dateFilter={props.dateFilters} franchiseCodes={props.franchiseCodes} showChart={true}/>
            </WidgetsRowCP>
            
            <WidgetsRowCP marginTop={30}>
                <SaleSourcesSyntheticReportCP filterData={{
                    dateRange: props.dateFilters,
                    franchiseCodes: props.franchiseCodes
                }}/>
            </WidgetsRowCP>
        </>
    )
}
