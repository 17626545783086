import { AppointmentDetailsCP } from 'main/modules/scheduler/components/appointment-details/AppointmentDetailsCP'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { AppointmentDetailsActionsICP } from 'main/modules/scheduler/components/drawer-appointment-session-details/inner/AppointmentDetailsActionsICP'
import { ModalSessionFormCP } from 'main/modules/scheduler/components/modal-session-form/ModalSessionFormCP'
import { IAppointmentSessionDetailsData } from 'main/modules/scheduler/components/drawer-appointment-session-details/inner/IAppointmentSessionDetailsData'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface IDrawerAppointmentSessionDetailsCPProps {
    visible: boolean
    onClose: (wasDataChanged: boolean) => void
    appointmentDetailsData: IAppointmentSessionDetailsData
}

/**
 * COMPONENTE Drawer responsável por mostrar os detalhes de um agendamento de sessoes.
 */
export function DrawerAppointmentSessionDetailsCP(props: IDrawerAppointmentSessionDetailsCPProps): JSX.Element {

    const [attendanceStartTime, setAttendanceStartTime] = useState<Date>()

    const [appointmentDetailsData, setAppointmentDetailsData] = useState<IAppointmentSessionDetailsData>(props.appointmentDetailsData)
    const [sessions, setSessions] = useState<ISessionResponseDTO[]>(props.appointmentDetailsData.sessions)

    const [isModalFormVisible, setIsModalFormVisible] = useState<boolean>(false)
    const [isSaleDrawerVisible, setIsSaleDrawerVisible] = useState<boolean>(false)

    const [wasDataChanged, setWasDataChanged] = useState<boolean>(false)

    const [sessionToConclude, setSessionToConclude] = useState<ISessionResponseDTO>()
    const [saleCode, setSaleCode] = useState<number>()

    useEffect(init, [props.appointmentDetailsData])

    /**
     */
    function init(): void {
        setAppointmentDetailsData(props.appointmentDetailsData)
        setSessions(props.appointmentDetailsData.sessions)
    }

    return (
        <>
            <DrawerCP
                title={'Detalhes do Agendamento'}
                visible={props.visible}
                onClose={() => props.onClose(wasDataChanged)}
                footerSpaced={true}
                width={740}
                footer={
                    <AppointmentDetailsActionsICP
                        appointmentStartDate={appointmentDetailsData.beginDate}
                        sessionStatus={appointmentDetailsData.sessionStatus}
                        sessionCodes={sessions.map((session) => session.code)}
                        onActionDone={() => props.onClose(true)}
                    />
                }
            >
                <AppointmentDetailsCP
                    appointmentDetailsData={appointmentDetailsData}
                    onCancelSession={(existsRemainingSession: boolean) => {
                        if (existsRemainingSession)
                            setWasDataChanged(true)
                        else
                            props.onClose(true)
                    }}
                    onAnswerSession={(session) => {
                        setSessionToConclude(session)
                        setIsModalFormVisible(true)
                    }}
                    onSelectSale={(_saleCode) => {
                        setSaleCode(_saleCode)
                        setIsSaleDrawerVisible(true)
                    }}
                    attendanceStopWatch={{
                        attendanceStartTime: attendanceStartTime,
                        onStartAttendance: () => setAttendanceStartTime(new Date()),
                        onFinishAttendance: () => {
                            props.onClose(wasDataChanged)
                            NotificationHelper.success('Yes!', 'Atendimento finalizado com sucesso')
                        },
                    }}
                    onPresenceConfirmation={() => setWasDataChanged(true)}
                    sessions={sessions}
                    onChangeSessions={setSessions}
                />
            </DrawerCP>
                    
            {
                !!sessionToConclude && appointmentDetailsData &&
                <ModalSessionFormCP
                    visible={isModalFormVisible}
                    session={sessionToConclude}
                    nextSessions={sessions}
                    onCancel={() => setSessionToConclude(undefined)}
                    initTime={attendanceStartTime}
                    onSave={() => {
                        setAppointmentDetailsData({...appointmentDetailsData})
                        setIsModalFormVisible(false)
                        setWasDataChanged(true)
                    }}
                />
            }

            {
                saleCode &&
                <DrawerSaleDetailsCP
                    visible={isSaleDrawerVisible}
                    onClose={() => setIsSaleDrawerVisible(false)}
                    saleCode={saleCode}
                />
            }
        </>
    )

}
