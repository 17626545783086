import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { FormFormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/inner/FormFormQuestionResponseDTO'

export class TreatmentFormFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    formQuestions: FormFormQuestionResponseDTO[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
