import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ScpProps {
    color: string
    backgroundExtra?: string
    borderColor?: string
}

interface ICPProps extends ScpProps {
    text: string
}

/**
 * Forma de exibir a cor na legenda.
 */
export function CalendarEventsSubtiteColorICP(props: ICPProps): JSX.Element {

    return (
        <FlexCP alignItems={'center'} margin={{bottom: 5}}>
            <ColorSCP
                color={props.color}
                backgroundExtra={props.backgroundExtra}
                borderColor={props.borderColor}
            />
            <TextSCP>{props.text}</TextSCP>
        </FlexCP>
    )
}

const ColorSCP = styled.div<ScpProps>`
  background-color: ${props => props.color};
  background-image: ${props => props.backgroundExtra ?? undefined};
  border: ${props => props.borderColor ? `solid 4px ${props.borderColor}` : undefined};
  height: 18px;
  width: 18px;
  margin-right: 5px;
  border-radius: 20px;
`

const TextSCP = styled.i`
  white-space: nowrap;
  margin-right: 15px;
`
