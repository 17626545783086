import React, { useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { MultiActionButtonCP } from 'main/common/components/multi-action-button/MultiActionButtonCP'

interface IButtonSaveExpenseCPProps {
    code?: number
    onSave: (changeAllOnGrouped: boolean, uploadFiles?: boolean) => void
    isGroupedExpense: boolean
    loading: boolean
}

/**
 * Botao de salvar uma despesa
 */
export function ButtonSaveFinancialTransactionICP(props: IButtonSaveExpenseCPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

    return (
        <>
            <ConditionalRenderCP shouldRender={props.isGroupedExpense}>
                <PopOverCP
                    visible={isPopoverVisible}
                    onClose={() => setIsPopoverVisible(false)}
                    title={'Você está editando uma despesa que se repete, o que deseja fazer?'}
                    placement={'topLeft'}
                    content={
                        <FlexCP justifyContent={'space-between'}>
                            <ButtonCP
                                type={'ghost'}
                                onClick={() => {
                                    setIsPopoverVisible(false)
                                    props.onSave(true)
                                }}
                                loading={props.loading}
                            >
                                Editar esta e todas futuras
                            </ButtonCP>
                            <ButtonCP
                                type={'primary'}
                                onClick={() => {
                                    setIsPopoverVisible(false)
                                    props.onSave(false)
                                }}
                                loading={props.loading}
                            >
                                Editar apenas esta
                            </ButtonCP>
                        </FlexCP>
                    }
                >
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setIsPopoverVisible(true)}
                        loading={props.loading}
                    >
                        Salvar
                    </ButtonCP>
                </PopOverCP>
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={!props.isGroupedExpense}>
                {
                    !!props.code
                        ?
                        <ButtonCP
                            type={'primary'}
                            onClick={() => props.onSave(false)}
                            loading={props.loading}
                        >
                            Salvar
                        </ButtonCP>
                        :
                        <MultiActionButtonCP
                            loading={props.loading}
                            type={'primary'}
                            options={[
                                {
                                    label: 'Salvar',
                                    onClick: () => props.onSave(false),
                                },
                                {
                                    label: 'Criar e anexar arquivos',
                                    onClick: () => props.onSave(false, true),
                                },
                            ]}
                        />
                }
            </ConditionalRenderCP>
        </>
    )
}
