import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'

export class TreatmentGroupFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
