import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { ModalSessionFormCP } from 'main/modules/scheduler/components/modal-session-form/ModalSessionFormCP'
import { SessionsTableUtils } from 'main/modules/scheduler/components/table-sessions/inner/SessionsTableUtils'
import React, { useState } from 'react'
import { ISessionResponseDTO } from '../../services/scheduler/dtos/response/ISessionResponseDTO'
import { SessionResponseDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/responses/SessionResponseDTO'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import * as One from "common/utils/One"
import { ModalCP } from 'main/common/components/modal/ModalCP'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

interface ITableSessionsCPProps {
    sessions: SessionResponseDTO[]
    onUpdateSession: () => void
}

const mapaStatus = {
    'CONCLUDED':'MARCOU REALIZADO',
    'SCHEDULED':'AGENDOU',
    'NO_SHOW':'CLIENTE NAO COMPARECEU',
    'CREATED':'CRIADO',
    'CANCEL':'CANCELADO'
}

/**
 * COMPONENTE Tabela que exibe sessoes
 */
export function TableSessionsCP(props: ITableSessionsCPProps): JSX.Element {

    const [selectedSession, setSelectedSession] = useState<ISessionResponseDTO>()
    const [isModalFormVisible, setIsModalFormVisible] = useState<boolean>(false)
    const [carregando, setCarregando] = useState<boolean>(false)
    const [historico,setHistorico] = useState<any>(null);
    const [historicoVisivel,setHistoricoVisivel] = useState<boolean>(false);
    /**
     * Ao selecionar uma sessao.
     */
    function openSessionForm(sessionCode: ISessionResponseDTO): void {
        setSelectedSession(sessionCode)
        setIsModalFormVisible(true)
    }

    async function abrirModalHistorico(sessionCode: ISessionResponseDTO){
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/obter-historico-sessoes`,
            method: HttpMethodEnum.POST,
            params: {
                codigoSessao:sessionCode.code,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>setCarregando(false)); 

        if(One.ok(resultado)){
            console.log(One.dados(resultado),carregando);
            setHistorico(One.dados(resultado));
            setHistoricoVisivel(true);
        }
    }

    return (
        <>
            <TableCPOLD2
                data={props.sessions}
                columns={SessionsTableUtils.getColumnsConfig(openSessionForm,abrirModalHistorico,carregando, true, true, true)}
            />

            {
                !!selectedSession &&
                <ModalSessionFormCP
                    visible={isModalFormVisible}
                    session={selectedSession}
                    onCancel={() => setIsModalFormVisible(false)}
                    onSave={() => {
                        setIsModalFormVisible(false)
                        props.onUpdateSession()
                    }}
                />
            }

            {
                !!historico && 
                    <ModalCP 
                        title={'Histórico de Movimentações da Sessão'}
                        closable={true}
                        width={'650px'}
                        visible={historicoVisivel}
                        noFooter={true}
                        onCancel={() => setHistoricoVisivel(false)}
                    >
                        <ListaH>
                            <div className='conteudo'>
                            {
                                historico.map((v:any)=>{
                                    return (
                                                <section className='o-grid'>
                                                    <div className={'hr'}>{DateUtils.getFormatted(v.createdate,DateFormatEnum.BR_WITH_TIME_H_M)}</div>
                                                    <div className='user'>{v.username}</div>
                                                    <div className='agendou'>{mapaStatus[v.status]}</div>
                                                </section>
                                    )
                                })
                            }
                            </div>
                        </ListaH>
                    
                    </ModalCP>
                
            }
        </>
    )
}

const ListaH = styled.div`
    .o-grid{
        display:grid;
        grid-template-columns:1fr 2fr 1fr;
        gap:5px;
        border-bottom: 1px dashed gray;
    }

    .user{
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-size: 12px;
        font-weight: 524;
        font-family: system-ui;
    }
    .hr{
        font-weight: 565;
        font-size: 15px;
        letter-spacing: -0.091em
    }
    .agendou{
        font-weight: 605;
        letter-spacing: -0.048em;
        color: black;
        
        border-radius: 9px;
        padding: 2px;
        text-align: center;
        width: 100%;
    }

    .conteudo{
        max-height:450px;
        overflow-y:scroll;
    }
`;