import React from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DescriptionCP } from 'main/common/components/description/DescriptionCP'
import { DescriptionItemCP } from 'main/common/components/description/description-item/DescriptionItemCP'
import { SaleContaractVars } from 'main/modules/sale/components/drawer-sale-contract-vars/inner/SaleContaractVars'
import { styled } from 'main/config/theme/styledWithTheme'

interface ICPProps {
    onClose: () => void
    visible: boolean
}

/**
 * Drawer para listagem de variaveis para contrato de venda.
 */
export function DrawerSaleContractVarsCP(props: ICPProps): JSX.Element {

    function getVarCP(): JSX.Element[] {

        return SaleContaractVars.map(varGroup => {
            return (
                <DescriptionWrapperSCP key={varGroup.title}>
                    <DescriptionCP title={varGroup.title}>
                        {
                            varGroup.vars.map(varItem => {
                                return (
                                    <DescriptionItemCP key={varItem.id} label={varItem.description}>
                                        <TextCP copyable={true} text={varItem.id} onCopy={props.onClose}/>
                                    </DescriptionItemCP>
                                )
                            })
                        }
                    </DescriptionCP>
                </DescriptionWrapperSCP>
            )
        })

    }

    return (
        <DrawerCP
            title={'Variáveis Disponíveis'}
            visible={props.visible}
            onClose={props.onClose}
            width={550}
        >
            { getVarCP() }
        </DrawerCP>
    )
}

const DescriptionWrapperSCP = styled.div`

  .ant-descriptions-title {
    margin: 20px 0px 0px 0px;
  }
  
  .ant-descriptions-row > td {
    padding-bottom: 0px !important;
  }

`
