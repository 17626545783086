import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import React from 'react'

interface IHeaderButtonICPProps {
    onClick: () => void
    icon: string
    label?: string
    tooltip?: string
}

/**
 * COMPONENTE
 * Botao principal dos headers das paginas
 */
export function HeaderButtonICP(props: IHeaderButtonICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <ButtonCP
            onClick={props.onClick}
            icon={props.icon}
            marginLeft={10}
            tooltip={props.tooltip}
        >
            {
                !screenSize.smd && props.label &&
                <TextCP text={props.label} strong={true}/>
            }
        </ButtonCP>
    )
}
