import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { AutoCompleteOpportunitySourceCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity-source/AutoCompleteOpportunitySourceCP'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { AutocompleteTagCP } from 'main/modules/admin/components/autocomplete-tag/AutocompleteTagCP'
import { PersonBulkOpportunityFormModel } from 'main/modules/people/components/modal-person-bulk-opportunity/inner/PersonBulkOpportunityFormModel'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FormStateManagerUtils } from 'main/common/form-state-manager/FormStateManagerUtils'
import { IOpportunityByExpressionSaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityByExpressionSaveRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'

interface ICPProps {
    queryExpression: SegmentExpressionTP
    onSaveOpportunities: () => void
}

/**
 * Header do modal de evoluir sessoes.
 */
export function PersonBulkOpportunityFormICP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<PersonBulkOpportunityFormModel>(new PersonBulkOpportunityFormModel())
    const formStateManager = useFormStateManager<PersonBulkOpportunityFormModel>(formValidator)

    const saveOpportunitiesRequest = useRequest<IBankAccountResponseDTO>()
    useEffect(onSaveOpportunitiesRequestChange, [saveOpportunitiesRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {

        setFormValidator(new PersonBulkOpportunityFormModel({
            shouldSkipIfOpen: true
        }))
    }

    /**
     * Salva conta bancaria
     */
    async function saveOpportunities(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        const dto: IOpportunityByExpressionSaveRequestDTO = {
            expression: props.queryExpression,
            sourceCode: formValues.sourceCode,
            userResponsibleCode: formValues.personResponsibleCode,
            shouldSkipIfOpen: formValues.shouldSkipIfOpen,
            tags: formValues.tags,
            note: formValues.notes,
        }

        saveOpportunitiesRequest.runRequest(OpportunityRequests.saveByExpression(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveOpportunitiesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveOpportunitiesRequest, 'Ocorreu algun erro ao salvar conta bancaria'))
            return

        NotificationHelper.info('Yes', 'Conta bancária salva com sucesso')

        formStateManager.reset(undefined)
        props.onSaveOpportunities()
    }

    return (
        <WrapperSCP>
            <AlertCP
                marginBottom={30}
                type={'warning'}
                message={'Serão criadas oportunidades para os contatos exibidos. Confira atentamente os parâmetros abaixo, esta ação não pode ser desfeita e pode inserir muitas oportunidades no seu funil.'}
            />

            <AutoCompleteOpportunitySourceCP
                label={'Origem do Lead'}
                required={true}
                fieldName={'sourceCode'}
                sourcePersonLinkFieldName={'sourcePersonLinkCode'}
                formStateManager={formStateManager}
            />

            <AutocompleteUserCP
                label={'Selecione o vendedor'}
                required={true}
                showLoggedUserAsInitialOption={true}
                formStateManager={formStateManager}
                fieldName={'personResponsibleCode'}
            />

            <AutocompleteTagCP
                label={'Áreas/Serviços de Interesse'}
                formStateManager={formStateManager}
                fieldName={'tags'}
            />

            <TextAreaCP
                formStateManager={formStateManager}
                fieldName={'notes'}
                label={'Observações'}
                value={formStateManager.getFieldValue('notes')}
            />

            <SwitchCP
                marginTop={30}
                isTextInside={false}
                textOutside={'Pular repetidos?'}
                formStateManager={formStateManager}
                fieldName={'shouldSkipIfOpen'}
                hint={{ 'type': 'tooltip', text: 'Se estiver marcado para pular, ao encontrar uma oportunidade em aberto para o contato nada será feito. Caso contrário será criado uma oportunidade mesmo tendo outra em aberto' }}
            />

            <FlexCP justifyContent={'center'} marginTop={30}>
                <ButtonCP
                    confirmMsg={'Você tem certeza que deseja criar todas as oportunidades?'}
                    type={'primary'}
                    loading={saveOpportunitiesRequest.isAwaiting}
                    onClick={saveOpportunities}
                >
                    {'Gerar oportunidades'}
                </ButtonCP>
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
