import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { momentPtBrLocale } from 'main/common/utils/date/moment-pt-br-locale'
import moment, { Moment } from 'moment'

/**
 * UTILITÁRIOS para manipulação de datas.
 */
export class DateUtilsOLD { // eslint-disable-line @typescript-eslint/naming-convention

    static getFormatted(date: string | Date, format: DateFormatEnum): string {
        moment.locale('pt_BR', momentPtBrLocale)
        return moment(date).format(format)
    }

    /** Transforma string data. */
    static toDate(dateStr: string, dateStrFormat: DateFormatEnum): Date {
        return moment(dateStr, dateStrFormat).toDate()
    }

    /** Define HORAS & MINUTOS de 01 data. */
    static setTime(date: Date, hours: number, minutes: number): Date {
        return moment(date)
            .set(TimeBaseEnum.HOUR, hours)
            .set(TimeBaseEnum.MINUTE, minutes)
            .toDate()
    }

    /** SOMA valor de tempo a 01 data. */
    static add(date: Date, value: number, timeBase: TimeBaseEnum): Date {
        return moment(date).add(value, timeBase).toDate()
    }

    /** Retorna hora & minutos extraidos de 01 string que venha na forma [hora]:[minutos]. */
    static getHourAndMinutes(timeStr: string): { hour: number, minutes: number } {

        let timeArray: OrUndefTP<[number, number]>
        if (/^((2[0-3])|([01][0-9])):([0-5][0-9])$/.test(timeStr))
            timeArray = timeStr.split(':').map(timeUnit => +timeUnit) as [number, number]

        return {
            hour: timeArray?.[0] ?? 0,
            minutes: timeArray?.[1] ?? 0,
        }
    }

    static getMomentFromTime(timeStr?: string): Moment | undefined {
        return timeStr ? moment(`${timeStr}`, 'HH:mm') : undefined
    }
}
