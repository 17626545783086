import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'

export class SenderFormValidator extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    emailPrefix: string

    @IsNotEmpty()
    domainCode: number

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
