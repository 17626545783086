import React from 'react'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { PersonContactTypeEnum, PersonContactTypeLabelEnum } from 'main/modules/people/services/person-contact/enums/PersonContactTypeEnum'

/**
 * Select para tipos de atividade de interacao
 */
export function SelectPersonContactTypeCP(props: IFormItemCommonProps): JSX.Element {

    const OPTIONS: SelectOptionTP[] = Object.values(PersonContactTypeEnum).map((actType, i) => ({
        key: `key-act-${i}`,
        label: PersonContactTypeLabelEnum[actType],
        value: actType
    }))

    return (
        <SelectCP
            options={OPTIONS}
            label={'Contato via'}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
        />
    )
}
