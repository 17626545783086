import React, { CSSProperties } from 'react'
import { Upload } from 'antd'
import styled from 'styled-components'
import { UploadButtonICP } from './inner/UploadButtonICP'
import { RcFile } from 'antd/lib/upload'

interface IFileUploaderCPProps {
    onChange: (info: any) => void
    beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean | Promise<any>
    customRequest?: (option: object) => void
    imageUrl?: string
    fileTypes?: string
    loading: boolean
    instructions?: string
    multiple?: boolean
    showUploadList?: boolean
    disabled?: boolean
    style?: CSSProperties
}

/**
 * Componente responsável por fazer o upload de imagem
 * Utiliza handlers customizados para fazer este upload.
 * @author kiara.vz
 */
export function FileUploaderCP(props: IFileUploaderCPProps): JSX.Element {

    return (
        <FileUploaderWrapperSCP>
            <Upload
                beforeUpload={props.beforeUpload}
                customRequest={props.customRequest}
                disabled={props.disabled}
                onChange={props.onChange}
                accept={props.fileTypes}
                multiple={props.multiple}
                showUploadList={props.showUploadList}>
                {
                    props.imageUrl ?
                        <img src={props.imageUrl} alt='avatar' style={{ width: '100%' }}/> :
                        <UploadButtonICP style={props.style} disabled={props.disabled} loading={props.loading}/>
                }
            </Upload>
            <InstructionsSCP>
                {props.instructions}
            </InstructionsSCP>
        </FileUploaderWrapperSCP>
    )
}

const InstructionsSCP = styled(Upload)`
.ant-upload-select-text{
    span{
        font-size: 12px;
    }
    margin-top: 10px;
}
`
const FileUploaderWrapperSCP = styled.div`
        cursor: pointer;

`