import React, { useEffect, useState } from 'react'
import { UnitActionButtonICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitActionButtonICP'
import { CloseIconCP } from 'main/common/components/icons/CloseIconCP'
import { PlusIconCP } from 'main/common/components/icons/PlusIconCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { TransientValueTrimmer } from 'main/common/transient-value-trimmer/TransientValueTrimmer'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { CssHoverUtils } from 'main/common/utils/CssHoverUtils'
import { AggregatorContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorContext'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { CascaderOptionTP } from 'main/common/components/cascader/inner/CascaderOptionTP'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { CascaderCP } from 'main/common/components/cascader/CascaderCP'
import { UnitValueDisplayICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueDisplayICP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'

interface IUnitMainICPProps {
    isUnitValuePopoverShown: boolean
}

/**
 * COMPONENTE
 * Exibe valores atuais que compoem 01 unidade de lista de segmentacao em linguagem natural & controla edicao da mesma.
 */
export function UnitMainICP(props: IUnitMainICPProps): JSX.Element {

    const [disableButtons, setDisableButtons] = useState<boolean>(false)
    const [isRemoveBtnHovered, setIsRemoveBtnHovered] = useState<boolean>(false)

    const [hoverRemoveBtnTrimmer] = useState<TransientValueTrimmer<boolean>>(
        new TransientValueTrimmer<boolean>({ delay: 250, onFinish: setIsRemoveBtnHovered })
    )

    const expressionContext = ExpressionContext.useState()
    const aggregatorContext = AggregatorContext.use()
    const unitContext = UnitContext.use()

    // NOTE: Variavel necessaria para deternminar habilitacao do cascader (questoes de sincronismo)
    const disableValueChangeTrigger = (expressionContext.state.isLoading || props.isUnitValuePopoverShown)
    useEffect(() => setDisableButtons(disableValueChangeTrigger), [disableValueChangeTrigger])

    const removeBtnRef = React.createRef<HTMLDivElement>()

    function onFilterChange(selectionValues: string[]): void {

        const field = selectionValues[0] as SegmentUnitFieldsEnum
        const operator = selectionValues[1] as SegmentUnitCompareOperatorsEnum

        let isSelectionChanged = false

        if (field !== unitContext.state.field) {
            isSelectionChanged = true
            unitContext.actions.setField(field)
        }

        if (operator !== unitContext.state.operator) {
            isSelectionChanged = true
            unitContext.actions.setOperator(operator)
        }

        if (isSelectionChanged && !!unitContext.state.value.length)
            unitContext.actions.setValue([])
    }

    function getFieldOptions(): CascaderOptionTP[] {
        return SegmentUtils.getAvailableFieldsBySegmentType(expressionContext.state.segmentType)
            .map(fieldName => ({
                value: fieldName,
                label: SegmentUtils.getUnitFieldLabel(fieldName),
                children: SegmentUtils.getUnitFieldOperators(fieldName).map(operator => ({
                    value: operator,
                    label: SegmentUtils.getUnitCompareOperatorLabel(operator)
                }))
            }))
    }

    const showRemoveBtn = (!disableButtons && (aggregatorContext.state.units.length > 1 || unitContext.state.isSelected))
    const isFullySet = (unitContext.state.isSelected && SegmentUtils.isUnitValueSet(SegmentUtils.getValueFromValueWithLabel(unitContext.state.value)))

    const showAddBtn = (
        !disableButtons
        && !isRemoveBtnHovered
        && !aggregatorContext.state.isRemoveBtnHovered
        && !expressionContext.state.isLoading
        && isFullySet
        && unitContext.state.isLastUnit
    )

    return (
        <WrapperSCP
            hasPlaceholder={SegmentUtils.listHasPlaceholder(aggregatorContext.state.units)}
            selected={isFullySet}
            disabled={disableButtons}
        >
            <FiltersAreaSCP>
                <TooltipCP text={(unitContext.state.isSelected && !disableButtons) ? 'Alterar' : ''} placement={'bottom'}>
                    <CascaderCP
                        options={getFieldOptions()}
                        onChange={onFilterChange}
                        disabled={disableValueChangeTrigger}
                        value={unitContext.state.isSelected ? [unitContext.state.field!, unitContext.state.operator!] : undefined}
                        onPopupVisibleChange={isVisible => unitContext.actions.setIsSelecting(isVisible)}
                        selectionDisplay={<UnitValueDisplayICP/>}
                    />
                </TooltipCP>

                {
                    showRemoveBtn &&
                    <RemoveButtonWrapperSCP
                        ref={removeBtnRef}
                        onMouseEnter={() => hoverRemoveBtnTrimmer.onChange(CssHoverUtils.isHovered(removeBtnRef))}
                        onMouseLeave={() => hoverRemoveBtnTrimmer.onChange(CssHoverUtils.isHovered(removeBtnRef))}
                    >
                        <UnitActionButtonICP
                            onClick={() => aggregatorContext.actions.removeUnit(unitContext.state.id)}
                            tooltip={'Remover Filtro'}
                            btnClassName={CLASS_ACTION_BTN}
                            rightOffset={5}
                            icon={<CloseIconCP size={SIZE_ACTION_BTN} type={'circle'}/>}
                            hiddenButton={true}
                            className={CLASS_ACTION_BTN_WRAPPER}
                        />
                    </RemoveButtonWrapperSCP>
                }
            </FiltersAreaSCP>

            {
                showAddBtn &&
                <UnitActionButtonICP
                    onClick={aggregatorContext.actions.addUnit as (() => void)}
                    tooltip={'Adicionar grupo de filtros'}
                    btnClassName={CLASS_ACTION_BTN}
                    rightOffset={-21}
                    icon={<PlusIconCP size={SIZE_ACTION_BTN} type={'circle'}/>}
                />
            }
        </WrapperSCP>
    )
}

const CLASS_ACTION_BTN = 'unit_action_button'
const CLASS_ACTION_BTN_WRAPPER = 'unit_action_remove_btn_wrapper'
const SIZE_ACTION_BTN = 15

type WrapperPropsTP = {
    selected: boolean,
    disabled: boolean,
    hasPlaceholder: boolean,
}

const WrapperSCP = styled.div<WrapperPropsTP>`

    background: ${props => props.theme.white};
    border: 1px solid ${props => (props.selected ? props.theme.primaryColor : 'lightblue')};
    border-radius: 4px;
    font-size: .68rem;
    position: relative;
    height: 30px;
    margin: 2px 0;

    > div:hover {

        .${CLASS_ACTION_BTN_WRAPPER} {
            opacity: 1;
        }

        a {
            opacity: ${props => (!props.hasPlaceholder ? .5 : 1)};
            cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
        }
    }

    .${CLASS_ACTION_BTN_WRAPPER} {
        opacity: 0;
        transition: opacity .2s;
    }

    .${CLASS_ACTION_BTN} {
        color: ${props => props.theme.primaryColor};
        opacity: .8;
    }
    .${CLASS_ACTION_BTN}:hover {
        color: ${props => props.theme.processingColor};
        opacity: 1;
    }
`

const FiltersAreaSCP = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
`

const RemoveButtonWrapperSCP = styled.div`
    ${CssHoverUtils.getCssForHoverControl()}
`
