import React, { CSSProperties } from 'react'
import { Input } from 'antd'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { TextArea } = Input

interface IInputTextAreaCPProps {
    value?: string
    rows?: number
    style?: CSSProperties
    onChange: (value: string) => void
    autoSize?: boolean
}

/**
 * Componente de TextArea
 * @param {ITextCPProps} props
 *
 * @author renatofs
 */
export function InputTextAreaCP(props: IInputTextAreaCPProps): JSX.Element {

    return (
        <TextArea
            rows={props.rows}
            style={props.style}
            defaultValue={props.value}
            autosize={props.autoSize && { minRows: 1, maxRows: 8 }}
            onChange={(e: any) => props.onChange(e.target.value)}
        />
    )

}
