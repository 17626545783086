import { FormModel } from 'main/common/form-state-manager/FormModel'
import React, { useEffect, useState } from 'react'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { TreatmentFormRequests } from 'main/modules/admin/services/treatment-form/TreatmentFormRequests'
import { ModalFormQuestionCP } from 'main/modules/admin/components/modal-form-question/ModalFormQuestionCP'
import { FormQuestionResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/FormQuestionResponseDTO'

interface IAutocompleteBankAccountCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
}

/**
 * Seletor de formulario de questoes de tratamento.
 */
export function AutocompleteFormQuestionCP<FModelTP extends FormModel = any>(props: IAutocompleteBankAccountCPProps<FModelTP>): JSX.Element {

    const [isNewQuestionModalVisible, setIsNewQuestionModalVisible] = useState<boolean>(false)
    const [selectedName, setSelectedName] = useState<string>()
    const [initialOptions, setInitialOptions] = useState<INameAndCodeResponseDTO[]>()
    const [shouldClearSearchStr, setShouldClearSearchStr] = useState<boolean>(true)

    useEffect(() => setInitialOptions(props.initialOptions), [props.initialOptions])

    /**
     * Ao clicar em adicionar.
     */
    function onAdd(name: string): void {
        setSelectedName(name)
        setIsNewQuestionModalVisible(true)
    }

    /**
     * Retorno ao adicionar.
     */
    function onQuestionAdded(question: FormQuestionResponseDTO): void {

        setIsNewQuestionModalVisible(false)
        if (props.formStateManager && props.fieldName)
            props.formStateManager?.changeFieldValue(props.fieldName, question.code)

        const addedQuestion: INameAndCodeResponseDTO = {
            code: question.code,
            name: question.title
        }

        setInitialOptions([addedQuestion])
        setShouldClearSearchStr(true)

        props.onChange?.({ label: addedQuestion.name, value: addedQuestion.code })
    }

    return (
        <>
            <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
                requestConfigGetter={TreatmentFormRequests.searchFormQuestionsNameAndCode}
                showOptionsOnLoad={true}
                initialOptions={initialOptions}
                mustClearSearch={shouldClearSearchStr}
                // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
                {...props/* eslint-disable-line react/jsx-props-no-spreading */}
                onAdding={onAdd}
            />

            <ModalFormQuestionCP
                visible={isNewQuestionModalVisible}
                onSave={onQuestionAdded}
                onCancel={() => setIsNewQuestionModalVisible(false)}
                initialName={selectedName}
            />
        </>
    )
}
