import React, { useEffect, useState } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { SaleSessionsTabICP } from 'main/modules/sale/components/drawer-sale-details/inner/SaleSessionsTabICP'
import { SaleDataTabICP } from 'main/modules/sale/components/drawer-sale-details/inner/SaleDataTabICP'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { SaleFilesTabICP } from 'main/modules/sale/components/drawer-sale-details/inner/SaleFilesTabICP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'

interface ISaleDetailsDrawerCPProps {
    visible: boolean
    onClose: () => void
    saleCode: number
}

/**
 * Drawer com detalhes de uma venda.
 */
export function DrawerSaleDetailsCP(props: ISaleDetailsDrawerCPProps): JSX.Element {

    useEffect(init, [props.visible, props.saleCode])

    const [sale, setSale] = useState<ISaleResponseDTO>()

    const getSaleRequest = useRequest<ISaleResponseDTO>()
    useEffect(onGetSaleRequestChange, [getSaleRequest.isAwaiting])

    /**
     * Busca detalhes da venda.
     */
    function init(): void {

        if (!props.visible)
            return

        getSaleRequest.runRequest(SaleRequests.getSaleDetail(props.saleCode))
    }

    /**
     * Retorno API de detalhes da venda.
     */
    function onGetSaleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSaleRequest, 'Erro ao buscar descrição da venda'))
            return

        setSale(getSaleRequest.responseData)
    }

    return (
        <DrawerCP
            title={'Detalhes da venda'}
            width={900}
            visible={props.visible}
            onClose={() => {
                setSale(undefined)
                props.onClose()
            }}
            loading={getSaleRequest.isAwaiting}
        >
            {
                !!sale && !PermissionUtils.canViewSaleBetweenFranchises(sale.franchise.code) &&
                <AlertCP
                    message={'Essa compra foi realizada em outra unidade por isso os detalhes não pode ser exibidos na unidade atual'}
                    type={'error'}
                />
            }

            {
                !!sale && PermissionUtils.canViewSaleBetweenFranchises(sale.franchise.code) &&
                <>
                    <AlertCP message={'!! Venda cancelada !!'} type={'error'} marginBottom={20} show={sale.status === SaleStatusEnum.CANCELLED}/>

                    <TabsCP
                        tabs={[
                            {
                                title: 'Dados da Venda',
                                key: 'saleData',
                                content: <SaleDataTabICP sale={sale} onChangeSaleData={init}/>
                            },
                            {
                                title: 'Detalhamento das Sessões',
                                key: 'sessions',
                                content: <SaleSessionsTabICP saleCode={props.saleCode}/>
                            },
                            {
                                title: 'Arquivos',
                                key: 'files',
                                content: <SaleFilesTabICP saleCode={props.saleCode}/>
                            },
                        ]}
                    />
                </>
            }
        </DrawerCP>
    )
}
