import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { FormAnswersSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/form-answers/request/FormAnswersSaveRequestDTO'
import { ISaveCommentRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISaveCommentRequestDTO'
import { IScheduleBlockIntervalRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IScheduleBlockIntervalRequestDTO'
import { ISearchAvailableSlotsRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISearchAvailableSlotsRequestDTO'
import { IUserAttendanceScheduleSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IUserAttendanceScheduleSaveRequestDTO'
import { ScheduleRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleRequestDTO'

export default class SchedulerRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/schedules`

    static searchAvailableSlots = (dto: ISearchAvailableSlotsRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/available-slots`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getUserAttendanceSchedule = (code: number): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/user-attendance-schedule/${code}`,
        method: HttpMethodEnum.GET,
    })

    static deleteUserAttendanceSchedule = (code: number): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/user-attendance-schedule/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static createAppointment = (dto: ScheduleRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static createUnavailableSlot = (dto: IScheduleBlockIntervalRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/block-interval`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateUserAttendanceSchedule = (code: number, dto: IUserAttendanceScheduleSaveRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/user-attendance-schedule/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static cancelAppointments = (sessionCodes: number[]): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}`,
        method: HttpMethodEnum.DELETE,
        params: { codes: sessionCodes }
    })

    static markAsConcluded = (sessionCodes: number[]): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/mark-as-concluded`,
        method: HttpMethodEnum.PUT,
        params: { codes: sessionCodes }
    })

    static markAsNoShow = (sessionCodes: number[]): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/mark-as-no-show`,
        method: HttpMethodEnum.PUT,
        params: { codes: sessionCodes }
    })

    static confirmPresence = (sessionCodes: number[]): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/confirm-presence`,
        method: HttpMethodEnum.PUT,
        params: { codes: sessionCodes }
    })

    static saveComment = (dto: ISaveCommentRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/save-comment`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getSessionForm = (sessionCode: number): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/${sessionCode}/answers`,
        method: HttpMethodEnum.GET,

    })

    static getSessionAnswersHistory = (personCode: number): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/person-session-answers-history/${personCode}`,
        method: HttpMethodEnum.GET,

    })

    static saveSessionAnswers = (sessionCode: number, dto: FormAnswersSaveRequestDTO): RequestConfigTP => ({
        url: `${SchedulerRequests._MODULE_BASE}/${sessionCode}/answers`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}
