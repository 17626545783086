import { StyledComponentProps } from 'main/config/theme/Theme'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AddressPanelCP } from 'main/modules/people/components/address-panel/AddressPanelCP'
import { GenderSelectorCP } from 'main/modules/people/components/gender-selector/GenderSelectorCP'
import { PersonImageUploaderCP } from 'main/modules/people/components/person-image-uploader/PersonImageUploaderCP'
import { IImageSaveResponseDTO } from 'main/modules/people/services/person/dtos/response/IImageSaveResponseDTO'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { PersonFormUtils } from 'main/modules/people/components/drawer-person-data/inner/PersonFormUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { ProfileFormModel } from './inner/ProfileFormModel'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IPersonSaveRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSaveRequestDTO'

/**
 * Card com detalhes do usuario logado e drawer para edicao dos dados.
 */
export function LoggedUserDataCardCP(): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProfileFormModel>(new ProfileFormModel({}))
    const formStateManager = useFormStateManager<ProfileFormModel>(formValidator)

    const [imageUrl, setimageUrl] = useState<string>()

    const saveImageRequest = useRequest<IImageSaveResponseDTO>()
    useEffect(onUploadImageRequestChange, [saveImageRequest.isAwaiting])

    const savePersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

    const getPersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    useEffect(initialize, [])

    /**
     * Inicializa Tela.
     */
    function initialize(): void {
        getPersonRequest.runRequest(PersonRequests.getOne(AppStateUtils.getLoggedUserData()!.user.code))
    }

    /**
     * Retorno ao buscar pessoa.
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro ao buscar dados da pessoa logada'))
            return

        const resultPerson = getPersonRequest.responseData!
        const profileModel = PersonFormUtils.getFormDataFromResponseDto(resultPerson)
        setFormValidator(new ProfileFormModel(profileModel))

        setimageUrl(resultPerson.mainImageUrl)
    }

    /**
     * Salva edição dos dados da pessoa
     */
    async function savePerson(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()
        if (!await formStateManager.validate() || !formValues)
            return NotificationHelper.error('Por favor preencha os campos corretamente')

        const dto: IPersonSaveRequestDTO = {
            name: formValues.name,
            cpfCnpj: formValues.cpfCnpj,
            email: formValues.email,
            phone: formValues.phone,
            gender: formValues.gender,
            birthDate: formValues.birthDate ? DateUtils.transformDateStrFormat(formValues.birthDate, DateFormatEnum.BR_WITHOUT_TIME, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            address: {
                street: formValues.street,
                neighborhood: formValues.neighborhood,
                complement: formValues.complement,
                number: formValues.number,
                zipCode: formValues.zipCode,
                city: formValues.city,
                state: formValues.state
            }
        }

        savePersonRequest.runRequest(PersonRequests.update(AppStateUtils.getLoggedUserData()!.user.code, dto))
    }

    /**
     * Retorno ao salvar pessoa.
     */
    function onSavePersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePersonRequest, 'Erro ao salvar dados.'))
            return

        NotificationHelper.success('Pronto!', 'Seus dados foram atualizados com sucesso')
    }

    /**
     * Faz upload da foto da pessoa.
     */
    function uploadImage(file?: File): void {

        if (!file)
            return

        saveImageRequest.runRequest(PersonRequests.uploadAvatar(AppStateUtils.getLoggedUserData()!.user.code, file))
    }

    /**
     * Retorno de upload do avatar
     */
    function onUploadImageRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveImageRequest, 'Erro ao salvar imagem'))
            return

        setimageUrl(saveImageRequest.responseData!.url)
    }

    return (
        <ContentWrapperSCP>
            <CardCP title={'Meus dados'} isLoading={getPersonRequest.isAwaiting || saveImageRequest.isAwaiting}>

                <RowCP spaceBetweenCols={16}>

                    <ColumnCP size={10}>
                        <PersonImageUploaderCP
                            updateImgFile={uploadImage}
                            imgUrl={imageUrl}
                        />
                    </ColumnCP>

                    <ColumnCP size={14}>
                        <RowCP spaceBetweenCols={16}>
                            <ColumnCP size={24}>
                                <InputCP<ProfileFormModel>
                                    fieldName={'name'}
                                    formStateManager={formStateManager}
                                    label={'Nome '}
                                    required={true}
                                />
                            </ColumnCP>
                        </RowCP>

                        <RowCP spaceBetweenCols={16}>
                            <ColumnCP size={24}>
                                <InputCP<ProfileFormModel>
                                    label={'E-mail'}
                                    fieldName={'email'}
                                    required={true}
                                    formStateManager={formStateManager}
                                />
                            </ColumnCP>
                        </RowCP>

                        <RowCP spaceBetweenCols={8}>
                            <ColumnCP size={12}>
                                <InputCP<ProfileFormModel>
                                    label={'CPF'}
                                    fieldName={'cpfCnpj'}
                                    formStateManager={formStateManager}
                                    required={true}
                                    mask={InputMaskTypeEnum.CPF}
                                />
                            </ColumnCP>
                            <ColumnCP size={12}>
                                <InputCP<ProfileFormModel>
                                    label={'Celular'}
                                    fieldName={'phone'}
                                    required={true}
                                    formStateManager={formStateManager}
                                    mask={InputMaskTypeEnum.PHONE}

                                />
                            </ColumnCP>
                        </RowCP>

                        <RowCP spaceBetweenCols={16}>
                            <ColumnCP size={14}>
                                <InputCP<ProfileFormModel>
                                    label={'Data Nascimento'}
                                    fieldName={'birthDate'}
                                    formStateManager={formStateManager}
                                    mask={InputMaskTypeEnum.DATE}
                                />
                            </ColumnCP>
                            <ColumnCP size={10}>
                                <GenderSelectorCP
                                    fieldName={'gender'}
                                    formStateManager={formStateManager}
                                />
                            </ColumnCP>
                        </RowCP>

                    </ColumnCP>
                </RowCP>

                <AddressWrapperSCP>
                    <TitleCP
                        textSize={'small'}
                        underLine={true}
                    >
                        Endereço
                    </TitleCP>

                    <AddressPanelCP formStateManager={formStateManager}/>
                </AddressWrapperSCP>

                <BtnContainerSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={savePerson}
                        loading={savePersonRequest.isAwaiting}
                        disabled={AppStateUtils.getLoggedUserData()!.user.code === 1}
                    >
                        Salvar
                    </ButtonCP>
                </BtnContainerSCP>
            </CardCP>
        </ContentWrapperSCP>

    )
}

const ContentWrapperSCP = styled.div`

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 22px;
    .ant-input {
        &:hover {
            border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
    
        &:active {
            border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
    
        &:focus {
            border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
    }
  
`
const BtnContainerSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 7px;
`
const AddressWrapperSCP = styled.div`
    margin-top: 30px;

    h3 {
        margin: 0 0 25px 7px;
    }
`
