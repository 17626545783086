import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { IAttendanceWorkShiftListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IAttendanceWorkShiftListItemResponseDTO'

/**
 * Colunas da tabela que lista o número de atendimentos por franquia
 * @TODO Espaços em branco por margin
 */
export function AttendanceByShiftColumns(): Array<ColumnProps<IAttendanceWorkShiftListItemResponseDTO>> {

    const FRANCHISE_SERVICE_NUMBER_COLUMNS: Array<ColumnProps<IAttendanceWorkShiftListItemResponseDTO>> = [

        {
            title: 'Franquia',
            key: 'franchiseName',
            align: 'left',
            width: '55%',
            render: (text, item): JSX.Element => {
                return (
                    <span>
                        {' '}
                        {item.franchise.name}
                        {' '}
                    </span>
                )
            },
        },
        {
            title: 'Manhã',
            key: 'workShiftAttendanceFirst',
            align: 'center',
            width: '15%',
            render: (text, item): JSX.Element => {
                return (
                    <span>
                        {' '}
                        {item.firstWorkShift}
                        {' '}
                    </span>
                )
            },
        },
        {
            title: 'Tarde',
            key: 'workShiftAttendanceSecond',
            align: 'center',
            width: '15%',
            render: (text, item): JSX.Element => {
                return (
                    <span>
                        {' '}
                        {item.secondWorkShift}
                        {' '}
                    </span>
                )
            },
        },
        {
            title: 'Total',
            key: 'totalAttendance',
            align: 'center',
            width: '15%',
            render: (text, item): JSX.Element => {
                return (
                    <span>
                        {' '}
                        {item.totalAttendances}
                        {' '}
                    </span>
                )
            },
        }

    ]

    return FRANCHISE_SERVICE_NUMBER_COLUMNS
}

