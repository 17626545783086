import React, { useEffect, useState } from 'react'
import { TreeCP } from 'main/common/components/tree/TreeCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IFinancialCategoryResponseDTO } from 'main/modules/financial/services/financial-category/response/IFinancialCategoryResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { TreeItemTP } from 'main/common/components/tree/inner/TreeItemTP'
import { CardCP } from 'main/common/components/card/CardCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import styled from 'main/config/theme/styledWithTheme'
import { ModalFinancialCategoryCP } from 'main/modules/financial/components/modal-financial-category/ModalFinancialCategoryCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FinancialCategoryTreeUtils } from 'main/modules/financial/components/tree-financial-category/inner/FinancialCategoryTreeUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FinancialCategoryRequests } from 'main/modules/financial/services/financial-category/FinancialCategoryRequests'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'
import { IFinancialCategoryUpdateItemRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategoryUpdateItemRequestDTO'

interface ICPProps {
    categoryType: FinancialCategoryTypeEnum
}

/**
 * Arvore de plano de contas.
 */
export function TreeFinancialCategoryCP(props: ICPProps): JSX.Element {

    const [selectedFinancialCategoryCode, setSelectedFinancialCategoryCode] = useState<number>()
    const [selectedFinancialCategoryParentCode, setSelectedFinancialCategoryParentCode] = useState<number>()

    const [isFinancialCategoryModalVisible, setIsFinancialCategoryModalVisible] = useState<boolean>(false)
    const [hasChangedOrder, setHasChangedOrder] = useState<boolean>(false)

    const [categoryTreeOptions, setCategoryTreeOptions] = useState<Array<TreeItemTP<IFinancialCategoryResponseDTO>>>([])

    const getCategoriesRequest = useRequest<IGenericListResponseDTO<IFinancialCategoryResponseDTO>>()
    useEffect(onGetCategoriesRequestChange, [getCategoriesRequest.isAwaiting])

    const saveReorderedCategoriesRequest = useRequest('none')
    useEffect(onSaveReorderedCategoriesRequestChange, [saveReorderedCategoriesRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        getCategoriesRequest.runRequest(FinancialCategoryRequests.search({ category: props.categoryType }))
    }

    /**
     * Retorno da api com os dados.
     */
    function onGetCategoriesRequestChange(): void{

        if (!RequestUtils.isValidRequestReturn(getCategoriesRequest, 'Ops! Erro ao categorias de despesa'))
            return

        // Itera primeiro nos raizes
        const treeItems: TreeItemTP[] = []
        FinancialCategoryTreeUtils.mountTreeItem(
            getCategoriesRequest.responseData!.list,
            treeItems,
            (code) => {
                setSelectedFinancialCategoryCode(code)
                setSelectedFinancialCategoryParentCode(undefined)
                setIsFinancialCategoryModalVisible(true)
            },
            (code) => {
                setSelectedFinancialCategoryCode(undefined)
                setSelectedFinancialCategoryParentCode(code)
                setIsFinancialCategoryModalVisible(true)
            },
            init,
            null
        )
        setCategoryTreeOptions(treeItems)
    }

    /**
     * Retorno apos reordar um item.
     */
    function onReorderTree(treeItemsReordered: TreeItemTP[]): void {

        const reOrganizedOrder: TreeItemTP[] = []
        FinancialCategoryTreeUtils.reorderTree(
            treeItemsReordered,
            reOrganizedOrder,
            (code) => {
                setSelectedFinancialCategoryCode(code)
                setSelectedFinancialCategoryParentCode(undefined)
                setIsFinancialCategoryModalVisible(true)
            },
            (code) => {
                setSelectedFinancialCategoryCode(undefined)
                setSelectedFinancialCategoryParentCode(code)
                setIsFinancialCategoryModalVisible(true)
            },
            init
        )

        setCategoryTreeOptions(reOrganizedOrder)
        setHasChangedOrder(true)
    }

    /**
     * Salva conta bancaria
     */
    async function saveReorderedCategories(): Promise<void> {

        const dtoReordered: IFinancialCategoryUpdateItemRequestDTO[] = []
        FinancialCategoryTreeUtils.mountReorderDto(categoryTreeOptions, dtoReordered)

        saveReorderedCategoriesRequest.runRequest(FinancialCategoryRequests.reorderCategories({ categories: dtoReordered }))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveReorderedCategoriesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveReorderedCategoriesRequest, 'Ocorreu algun erro ao reordenar plano de contas', true))
            return

        NotificationHelper.info('Yes!', 'Plano de contas ordenado com sucesso')
        setHasChangedOrder(false)
        init()
    }

    return (
        <WrapperSCP>
            <CardCP innerSpacing={'small'}>
                <TreeCP
                    onChange={onReorderTree}
                    items={categoryTreeOptions}
                    isLoading={getCategoriesRequest.isAwaiting}
                    defaultExpandedKeys={getCategoriesRequest.responseData?.list?.map((category) => category.code.toString())}
                />

                <ConditionalRenderCP shouldRender={hasChangedOrder}>
                    <FlexCP marginTop={30} justifyContent={'flex-end'} marginBottom={10}>
                        <ButtonCP
                            onClick={saveReorderedCategories}
                            type={'primary'}
                            loading={saveReorderedCategoriesRequest.isAwaiting}
                        >
                            Salvar ordernação
                        </ButtonCP>
                    </FlexCP>
                </ConditionalRenderCP>
            </CardCP>

            <ModalFinancialCategoryCP
                code={selectedFinancialCategoryCode}
                parentCode={selectedFinancialCategoryParentCode}
                categoryType={props.categoryType}
                visible={isFinancialCategoryModalVisible}
                onCancel={() => {
                    setIsFinancialCategoryModalVisible(false)
                    setSelectedFinancialCategoryCode(undefined)
                }}
                onSave={() => {
                    setIsFinancialCategoryModalVisible(false)
                    setSelectedFinancialCategoryCode(undefined)
                    init()
                }}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  .ant-tree-node-content-wrapper {
    height: 40px !important;
  }

  .ant-tree-node-content-wrapper:hover::before {
    height: 40px !important;
    background: #f5f5f5 !important;
    border-radius: 5px;
  }
`
