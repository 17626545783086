import React, { useState } from 'react'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ScreenContentFranchisorReport } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchisor/inner/content/ScreenContentFranchisorReport'
import { FranchisorDashboardViewModeTP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchisor/inner/FranchisorDashboardViewModeTP'
import { ScreenContentFranchisesMap } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchisor/inner/content/ScreenContentFranchisesMap'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'

/**
 * Tela do módulo Dashboard
 */
export function FranchisorDashboardScreen(): JSX.Element {

    const [view, setView] = useState<FranchisorDashboardViewModeTP>('reportView')

    return (
        <>
            <HeaderCP title={'Painel do Franqueador'}>
                <RadioGroupCP<FranchisorDashboardViewModeTP>
                    selected={view}
                    buttonHeight={35}
                    buttonWidth={140}
                    paddingTop={0}
                    secondary={true}
                    onChange={setView}
                    type={'button'}
                    options={[
                        {
                            value: 'mapView',
                            content: 'Mapa'
                        },
                        {
                            value: 'reportView',
                            content: 'Indicadores'
                        }
                    ]}
                />
            </HeaderCP>

            <ConditionalRenderCP shouldRender={view === 'mapView'}>
                <ScreenContentFranchisesMap/>
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={view === 'reportView'}>
                <ScreenContentFranchisorReport/>
            </ConditionalRenderCP>
        </>
    )
}
