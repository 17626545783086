import React, { useEffect, useState } from 'react'
import { TableProductReleaseFranchiseCP } from 'main/modules/products/components/table-product-release-franchise/TableProductReleaseFranchiseCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ProductReleaseFranchiseRequests } from 'main/modules/products/services/product-release-franchise/ProductReleaseFranchiseRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import styled from 'styled-components'
import { Alert } from 'antd'
import { AdminRoutes } from 'main/modules/admin/AdminRoutes'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'

/**
 * Configuracoes de pacotes de antendimento de uma franquia.
 */
export function ScreenContentFranchiseReleaseCP(): JSX.Element {

    const [totalUnsetFranchiseReleases, setTotalUnsetFranchiseReleases] = useState<number>(0)
    const [shouldFilterUnsetReleases, setShouldFilterUnsetReleases] = useState<boolean>(false)

    const getTotalFranchiseReleaseUnsetRequest = useRequest('none')

    useEffect(init, [shouldFilterUnsetReleases])
    useEffect(onGetTotalFranchiseReleaseUnsetRequestChange, [getTotalFranchiseReleaseUnsetRequest.isAwaiting])

    function init(): void {
        getTotalFranchiseReleaseUnsetRequest.runRequest(ProductReleaseFranchiseRequests.countUnsetProductReleasesFranchise())
    }

    function onGetTotalFranchiseReleaseUnsetRequestChange(): void {
        if (RequestUtils.isValidRequestReturn(getTotalFranchiseReleaseUnsetRequest, 'Erro ao buscar lista com pacotes de atendimento'))
            setTotalUnsetFranchiseReleases((getTotalFranchiseReleaseUnsetRequest.responseData as any).data)
    }

    return (
        <AccessControlCP franchiseOrFranchisor={'franchise'} routeToReturn={AdminRoutes.ATTENADANCE_PRODUCT}>
            <ContentWrapperSCP>
                {
                    totalUnsetFranchiseReleases > 0 &&
                    <ProductReleaseWrapperSCP>
                        <AlertWrapperSCP onClick={(): void => setShouldFilterUnsetReleases(true)}>
                            <AlertSCP
                                message={`Você tem ${totalUnsetFranchiseReleases} pacote(s) ainda não configurado(s). Clique para filtrar.`}
                                type={'warning'}
                                showIcon={true}
                                closable={true}
                            />
                        </AlertWrapperSCP>
                    </ProductReleaseWrapperSCP>
                }

                <TableProductReleaseFranchiseCP shouldFilterUnsetReleases={shouldFilterUnsetReleases} onUpdate={init}/>
            </ContentWrapperSCP>
        </AccessControlCP>
    )
}

const ContentWrapperSCP = styled.div`
  margin: 20px 0;
`

const ProductReleaseWrapperSCP = styled.div`
    width: 95%;
    margin: 20px;
    cursor: pointer;
`

const AlertSCP = styled(Alert)`
    &.ant-alert {
        margin: 1em 0; 
    }
`

const AlertWrapperSCP = styled.div``
