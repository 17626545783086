import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]

    hideSalesConversion?: boolean
}

/**
 * Widgets de indicadores gerais de performance de venda.
 */
export function WidgetTotalSale(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Vendas realizadas'}
            help={'Total de vendas realizadas no período'}
            footerTitle={props.hideSalesConversion ? undefined : 'Taxa de conversão'}
            footerHelp={'Percentual das avaliações realizadas convertidas em vendas'}
            secondCalc={'ratioBetweenMainValueAndSecondValue'}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.SALES_REGISTERED,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
        />
    )
}
