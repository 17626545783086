/**
 * ENUM
 * Coordenadas geograficas especificas.
 */
export enum CoordinatesEnum {

    MIN_LAT = -90,
    MAX_LAT = 90,

    MIN_LON = -180,
    MAX_LON = 180,

    BR_MIN_LAT = -33.4507,  // Chuí / RS
    BR_MAX_LAT = 5.1619,    // Nascente do rio Aliã / RO

    BR_MIN_LON = -73.5926,              // Nascente do Rio Moa / AC
    BR_MAX_LON = -28.50,                // Arquipelago Martim Vaz (extremo leste absoluto)
    BR_MAX_LON_CONTINENT = -34.4734,    // Ponta do Seixas / PB (extremo leste da parte continental)
}
