import React, { useState } from 'react'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { PopoverProductReleaseTreatmentCP } from 'main/modules/products/components/popover-product-release-treatment/PopoverProductReleaseTreatmentCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IProductReleaseTreatmentTableActionsColumnICPProps {
    treatmentConfig: IProductReleaseTreatmentConfigResponseDTO
    onUpdateTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void
    onDeleteTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void
}

/**
 * Componente de tabela que lista as os produtos de uma fatura
 */
export function ProductReleaseTreatmentTableActionsColumnICP(props: IProductReleaseTreatmentTableActionsColumnICPProps): JSX.Element {

    const [isReleaseTreatmentPopoverVisible, setIsReleaseTreatmentPopoverVisible] = useState<boolean>(false)

    return (
        <FlexCP justifyContent={'flex-end'}>
            <ConditionalRenderCP shouldRender={!!props.onUpdateTreatmentConfig}>
                <PopoverProductReleaseTreatmentCP
                    visible={isReleaseTreatmentPopoverVisible}
                    onClose={() => setIsReleaseTreatmentPopoverVisible(false)}
                    onSave={(modifiedTreatmentConfig) => {
                        props.onUpdateTreatmentConfig!(modifiedTreatmentConfig)
                        setIsReleaseTreatmentPopoverVisible(false)
                    }}
                    treatmentConfig={props.treatmentConfig}
                >
                    <ButtonCP
                        size={'small'}
                        icon={'edit'}
                        type={'primary'}
                        onClick={() => setIsReleaseTreatmentPopoverVisible(true)}
                    />
                </PopoverProductReleaseTreatmentCP>
            </ConditionalRenderCP>
            <ConditionalRenderCP shouldRender={!!props.onDeleteTreatmentConfig}>
                <ButtonCP
                    size={'small'}
                    icon={'delete'}
                    type={'danger'}
                    onClick={() => props.onDeleteTreatmentConfig!(props.treatmentConfig)}
                    marginLeft={5}
                />
            </ConditionalRenderCP>
        </FlexCP>
    )
}
