import { ColumnProps } from 'antd/lib/table'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { SaleItemTableItemTP } from 'main/modules/sale/components/sale-item-table/inner/types/SaleItemTableItemTP'
import React from 'react'

interface ISaleItemTableCPProps {
    productList?: SaleItemTableItemTP[]
    columns: Array<ColumnProps<any>>
}

/**
 * Componente de tabela que lista as os produtos de uma fatura
 *
 * @todo RENATO -> acoplamento muito grande ProductListTableItemTP
 */
export function SaleItemTableCP(props: ISaleItemTableCPProps): JSX.Element {

    return (
        <TableCPOLD2<SaleItemTableItemTP>
            data={props.productList}
            columns={props.columns}
            rowKey={(record): string => record.toString()}
        />
    )
}
