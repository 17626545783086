import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { BankAccountTableUtils } from 'main/modules/financial/components/table-bank-account/inner/BankAccountTableUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { BankAccountRequests } from 'main/modules/financial/services/bank-account/BankAccountRequests'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'

interface IBankAccountTableCPProps {
    onSelectBankAccount: (code: number) => void
    type: BankAccountTypeEnum
    shouldReloadList: boolean
    onListLoaded: () => void
}

/**
 * Componente de tabela que contas bancarias
 */
export function TableBankAccountCP(props: IBankAccountTableCPProps): JSX.Element {

    useEffect(() => { defineBankAccounts() }, [props.shouldReloadList])

    const [bankAccounts, setBankAccounts] = useState<IBankAccountResponseDTO[]>()
    const searchBankAccountRequest = useRequest<IGenericListResponseDTO<IBankAccountResponseDTO>>()
    useEffect(onSearchBankAccountRequestChange, [searchBankAccountRequest.isAwaiting])

    /**
     * Busca as contas bancarias cadastradas.
     */
    function defineBankAccounts(): void {

        if (!props.shouldReloadList)
            return

        searchBankAccountRequest.runRequest(BankAccountRequests.searchConfig({ type: props.type }))
    }

    /**
     * Retorno da requisicao que busca contas.
     */
    function onSearchBankAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchBankAccountRequest, 'Não foi possível obter as contas bancarias'))
            return

        setBankAccounts(searchBankAccountRequest.responseData!.list)
        props.onListLoaded()
    }

    return (
        <CardCP>
            <TableCPOLD2
                columns={BankAccountTableUtils.getTableColumnsConfig(props.type, props.onSelectBankAccount)}
                data={bankAccounts}
                loading={searchBankAccountRequest.isAwaiting}
            />
        </CardCP>
    )
}
