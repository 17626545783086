import { FormWrapperCP } from 'main/common/components/form-wrapper/FormWrapperCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { AddressPanelCP } from 'main/modules/people/components/address-panel/AddressPanelCP'
import { BasicInfoPanelCP } from 'main/modules/people/components/basic-info-panel/BasicInfoPanelCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IPersonSaveRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSaveRequestDTO'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { PersonFormUtils } from 'main/modules/people/components/drawer-person-data/inner/PersonFormUtils'
import { PersonUtils } from 'main/modules/people/utils/PersonUtils'
import React, { useEffect, useState } from 'react'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { PersonFormModel } from 'main/modules/people/components/drawer-person-data/inner/PersonFormModel'

interface IPersonDataFormCPProps {
    onSave: (person: IPersonResponseDTO) => void
    initialData?: ObjectPropsTP<PersonFormModel>
    personCode?: number
    segmentType: SegmentTypeEnum
}

/**
 * COMPONENTE Form de cadastro de pessoa/usuário
 */
export function PersonDataFormICP(props: IPersonDataFormCPProps): JSX.Element {

    useEffect(init, [props.personCode, props.initialData])

    const [formModelValidator, setFormModelValidator] = useState<PersonFormModel>(new PersonFormModel({}))
    const formStateManager = useFormStateManager<PersonFormModel>(formModelValidator)

    const [loadedPerson, setLoadedPerson] = useState<IPersonResponseDTO>()

    const getPersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    const saveRequest = useRequest<IPersonResponseDTO>()
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.personCode)
            return getPersonRequest.runRequest(PersonRequests.getOne(props.personCode))

        if (props.initialData)
            return setFormModelValidator(new PersonFormModel(props.initialData as any))

        setFormModelValidator(new PersonFormModel({}))
    }

    /**
     * Retorno da API ao buscar por uma pessoa
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro ao buscar dados da pessoa.'))
            return

        const personData = getPersonRequest.responseData
        Object.assign(personData, { types: [PersonUtils.getPersonTypeBySegment(props.segmentType)] })

        setLoadedPerson(personData)
        setFormModelValidator(new PersonFormModel(PersonFormUtils.getFormDataFromResponseDto(personData!)))
    }

    /**
     * Ao submeter formulario
     */
    async function onSubmit(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await PersonFormUtils.validateForm<PersonFormModel>(formStateManager, props.segmentType))
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return NotificationHelper.error('Ops!', 'Erro ao preencher formulário')

        // Monta DTO a partir dos valores do formulario
        const dto: IPersonSaveRequestDTO = PersonFormUtils.getRequestDtoFromFormData(formValues, props.segmentType === SegmentTypeEnum.COLLABORATOR)
        if (props.segmentType !== SegmentTypeEnum.COLLABORATOR)
            Object.assign(dto, { types: [PersonUtils.getPersonTypeBySegment(props.segmentType)] })

        // Cria ou atualiza
        if (loadedPerson)
            saveRequest.runRequest(PersonRequests.update(loadedPerson.code, dto))
        else
            saveRequest.runRequest(PersonRequests.create(dto))
    }

    /**
     * Retorno da API que salva pessoa
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, 'Erro ao realizar cadastro!'))
            return

        props.onSave(saveRequest.responseData!)
    }

    /**
     * Callback dos eventos ao buscar pessoa pelo CPF/Email/Telefone.
     */
    function onPersonSearchReturn(existsInFranchise: boolean, personFound?: IPersonResponseDTO): void {

        if (!personFound)
            return

        // Se ja tiver editando uma pessoa e encontrou outra, nao atualiza os dados
        if (loadedPerson)
            return

        setFormModelValidator(new PersonFormModel(PersonFormUtils.getFormDataFromResponseDto(personFound)))
        setLoadedPerson(personFound)
    }

    return (
        <FormWrapperCP
            onSubmit={onSubmit}
            isLoading={saveRequest.isAwaiting || getPersonRequest.isAwaiting}
            panelMainInfoCP={
                <BasicInfoPanelCP
                    formStateManager={formStateManager}
                    onSearchPersonResult={onPersonSearchReturn}
                    loadedPerson={loadedPerson}
                    initialData={props.initialData}
                />
            }
            collapsiblePanelCPs={[
                {
                    title: 'Endereço',
                    key: 'address',
                    content: <AddressPanelCP formStateManager={formStateManager}/>
                }
            ]}
            defaultCollapsiblePanelKeys={['basicData', 'address']}
            showDeleteButton={false}
            shouldDisableSubmit={getPersonRequest.isAwaiting}
        />
    )
}
