import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IScheduleReportRequestDTO } from 'main/modules/reports/services/scheduler-reports/dtos/request/IScheduleReportRequestDTO'

/**
 * Requests de relatorios da agenda
 */
export class SchedulerReportsRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/schedules/reports`

    static scheduleList = (dto: IScheduleReportRequestDTO): RequestConfigTP => ({
        url: `${SchedulerReportsRequests._MODULE_BASE}/basic-data`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static scheduleListExport = (dto: IScheduleReportRequestDTO): RequestConfigTP => ({
        url: `${SchedulerReportsRequests._MODULE_BASE}/basic-data/export`,
        method: HttpMethodEnum.GET,
        responseType: 'blob',
        params: dto
    })

}