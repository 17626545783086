import { MonthPickerCP } from 'main/common/components/month-picker/MonthPickerCP'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FastFiltersWithDrawerCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/FastFiltersWithDrawerCP'
import { FinancialTransactionAdvancedFiltersCP } from 'main/modules/financial/components/financial-transaction-filters/inner/FinancialTransactionAdvancedFiltersCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'

type ReferenceDateTP = 'd30' | 'd60' | 'd90' | 'all'

interface IFinancialTransactionFiltersCPProps {
    onChangeFilters: (dto: IFinancialTransactionSearchRequestDTO) => void
    currentFilters: IFinancialTransactionSearchRequestDTO
    leftContent?: JSX.Element
    showPaymentDateFilters: boolean
    showSaleDateFilters: boolean
    showPaymentPartner: boolean
    showReferenceDateFastFilter?: boolean
    showReferenceIntervalFilter: boolean
    labelPersonFilter?: string
    loading?: boolean
}

/**
 * Filtros da tela de transacao financeira.
 */
export function FinancialTransactionFiltersCP(props: IFinancialTransactionFiltersCPProps): JSX.Element {

    // Guarda essa instancia dos filtros em estado desse compontente, pois so ira filtrar quando clicar no botao
    const [filters, setFilters] = useState<IFinancialTransactionSearchRequestDTO>(props.currentFilters)
    useEffect(() => setFilters(props.currentFilters), [props.currentFilters])

    const [referenceDateFastFilter, setReferenceDateFastFilter] = useState<ReferenceDateTP>('all')

    /**
     *
     */
    function onChangeReferenceDateFastFilter(value: ReferenceDateTP): void {

        setReferenceDateFastFilter(value)

        let subtract = 0
        switch (value) {
            case 'd30':
                subtract = 30
                break
            case 'd60':
                subtract = 60
                break
            case 'd90':
                subtract = 90
                break
            default:
                subtract = 1
        }
        const _filters = {
            ...filters,
            referenceInterval: {
                beginDate: undefined,
                endDate: DateUtils.getFormatted(DateUtils.subtract(new Date(), subtract, TimeBaseEnum.DAY), DateFormatEnum.US_WITHOUT_TIME)
            }
        }
        setFilters(_filters)
        props.onChangeFilters(_filters)

    }

    return (
        <FastFiltersWithDrawerCP
            onFilter={() => props.onChangeFilters(filters)}
            isLoading={props.loading}
            onClearFilters={() => {
                setFilters({})
                props.onChangeFilters({})
            }}
            leftContent={
                props.leftContent ?
                    props.leftContent
                    :
                    <MonthPickerCP
                        size={'large'}
                        allowClear={false}
                        value={moment(props.currentFilters.expirateInterval?.beginDate)}
                        onChange={(dateStr) => {
                            const dateRange = DateUtils.getFirstAndLastDayOfMonth(new Date(dateStr))
                            const _filters = {
                                ...filters,
                                expirateInterval: {
                                    beginDate: DateUtils.getFormatted(dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                                    endDate: DateUtils.getFormatted(dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME)
                                }
                            }
                            setFilters(_filters)
                            props.onChangeFilters(_filters)
                        }}
                    />
            }
            rightContent={
                <FlexCP>

                    {
                        props.showReferenceDateFastFilter &&
                        <RadioGroupCP<ReferenceDateTP>
                            options={[
                                { content: 'Todos', value: 'all' },
                                { content: 'D+30', value: 'd30' },
                                { content: 'D+60', value: 'd60' },
                                { content: 'D+90', value: 'd90' },
                            ]}
                            type={'button'}
                            buttonWidth={80}
                            selected={referenceDateFastFilter}
                            onChange={onChangeReferenceDateFastFilter}
                            margin={{ right: 20 }}
                        />
                    }

                    <InputCP
                        placeholder={'Descrição / Fornecedor / ...'}
                        width={230}
                        noValidation={true}
                        marginTop={0}
                        marginBottom={0}
                        onChange={(strEntered) => setFilters({
                            ...filters,
                            searchString: strEntered
                        })}
                    />
                    <ButtonCP
                        marginLeft={10}
                        icon={'search'}
                        tooltip={'Buscar'}
                        type={'ghost'}
                        onClick={() => props.onChangeFilters(filters)}
                    />
                </FlexCP>
            }
            drawerContent={
                <FinancialTransactionAdvancedFiltersCP
                    currentFilters={filters}
                    onChangeFilters={setFilters}
                    showPaymentDateFilters={props.showPaymentDateFilters}
                    showSaleDateFilters={props.showSaleDateFilters}
                    showPaymentPartner={props.showSaleDateFilters}
                    labelPersonFilter={props.labelPersonFilter}
                    showReferenceIntervalFilter={props.showReferenceIntervalFilter}
                />
            }
        />
    )
}
