// import { CardCP } from 'main/common/components/card/CardCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
// import { AccountBalanceStatisticsCP } from 'main/modules/marketing/components/account-balance-statistics/AccountBalanceStatisticsCP'
// import { EmailEditorCP } from 'main/modules/marketing/components/email-editor/EmailEditorCP'
import { NewShotActionsBtnGroupCP } from 'main/modules/marketing/components/new-shot-actions-button-group/NewShotActionsBtnGroupCP'
import { NewShotConfirmModalCP } from 'main/modules/marketing/components/new-shot-confirm-modal/NewShotConfirmModalCP'
import { NewEmailFormValidator } from 'main/modules/marketing/components/new-shot-email-data/inner/NewEmailFormValidator'
// import { NewShotEmailDataCP } from 'main/modules/marketing/components/new-shot-email-data/NewShotEmailDataCP'
import { NewSmsFormValidator } from 'main/modules/marketing/components/new-shot-sms-data/inner/NewSmsFormValidator'
// import { NewShotSmsDataCP } from 'main/modules/marketing/components/new-shot-sms-data/NewShotSmsDataCP'
// import { PreviewPhoneTextCP } from 'main/modules/marketing/components/preview-phone-text/PreviewPhoneTextCP'
import { ICountPeopleToShootRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/ICountPeopleToShootRequestDTO'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { ShotActionTypeTP } from 'main/modules/marketing/types/ShotActionTypeTP'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { SegmentRequests } from 'main/modules/segment/services/SegmentRequests'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface INewCampaingShotScreenContentProps {
    initialSegmentCode?: number
    type: MarketingAccountTypeEnum
}

/**
 * TELA do passo a passo para novo disparo de campanha (SMS/EMAIL)
 */
export function NewCampaingShotScreenContent(props: INewCampaingShotScreenContentProps): JSX.Element {

    const currentFranchise = AppStateUtils.getCurrentFranchise()
    const screenSize = useScreenSize()

    const [segmentCode, setSegmentCode] = useState<number>()
    const [segmentName, setSegmentName] = useState<string>('')
    const [franchisesCodes, setFranchisesCodes] = useState<number[]>([currentFranchise!.code])
    const [actionType, setActionType] = useState<ShotActionTypeTP>()
    const [smsShot, setSmsShot] = useState<IMarketingCampaignSmsResponseDTO >()
    const [emailShot, setEmailShot] = useState<IMarketingCampaignEmailResponseDTO>()
    const [accountBalance, setAccountBalance] = useState<number>()
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [totalPersonsValidToShot, setTotalPersonsValidToShot] = useState<number>()
    const [shouldExportHtml, setShouldExportHtml] = useState<boolean>(false)

    const [smsFormValidator, setSmsFormValidator] = useState<NewSmsFormValidator>(new NewSmsFormValidator())
    const [emailFormValidator, setEmailFormValidator] = useState<NewEmailFormValidator>(new NewEmailFormValidator())

    const getShotRequest = useRequest<IMarketingCampaignSmsResponseDTO | IMarketingCampaignEmailResponseDTO>()
    const saveShotRequest = useRequest<IMarketingCampaignSmsResponseDTO | IMarketingCampaignEmailResponseDTO>()
    const countPeopleToShootReq = useRequest<number>()

    const smsFormStateManager = useFormStateManager<NewSmsFormValidator>(smsFormValidator)
    const emailFormStateManager = useFormStateManager<NewEmailFormValidator>(emailFormValidator)

    const { shotCode } = useParams()

    console.log(setFranchisesCodes, shouldExportHtml,setAccountBalance);

    useEffect(() => { defineGetShotRequest() }, [])
    useEffect(onActionTypeChange, [actionType])
    useEffect(defineTotalPersonsToShot, [segmentCode, franchisesCodes])
    useEffect(onInitialSegmentCodeChange, [props.initialSegmentCode])

    useEffect(onGetShotRequestChange, [getShotRequest.isAwaiting])
    useEffect(onSaveShotRequestChange, [saveShotRequest.isAwaiting])
    useEffect(onCountPeopleReqChange, [countPeopleToShootReq.isAwaiting])

    function onInitialSegmentCodeChange(): void {
        if (!!props.initialSegmentCode) {
            setSegmentCode(props.initialSegmentCode)
            smsFormStateManager.changeFieldValue('segmentCode', props.initialSegmentCode)
            emailFormStateManager.changeFieldValue('segmentCode', props.initialSegmentCode)
        }
    }

    /** Request para obter disparo, caso tenha passado na URL. */
    async function defineGetShotRequest(): Promise<void> {
        if (!!shotCode)
            getShotRequest.runRequest(await MarketingRequests.getCampaignShot(props.type, +shotCode))
    }

    /** Apos chamada de Request para obter disparo, caso tenha passado na URL. */
    function onGetShotRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getShotRequest, 'Não conseguimos encontrar o sms informado para edição'))
            return

        if (!getShotRequest.responseData)
            return

        if (getShotRequest.responseData.status !== MarketingShotStatusEnum.DRAFT) {
            NotificationHelper.error('Ops', 'Não é possível editar um disparo que já tenha sido enviado ou está agendado')
            return RoutingHelper.historyReplace(MarketingRoutes.MAIN_SCREEN_ROOT)
        }

        // Atualiza valores do formulario
        if (props.type === MarketingAccountTypeEnum.SMS) {
            const returnShot = getShotRequest.responseData as IMarketingCampaignSmsResponseDTO

            setSmsFormValidator(new NewSmsFormValidator({
                to: returnShot.destination,
                content: returnShot.content
            }))

            setSmsShot(returnShot)
        }

        if (props.type === MarketingAccountTypeEnum.EMAIL) {
            const returnShot = getShotRequest.responseData as IMarketingCampaignEmailResponseDTO

            setEmailFormValidator(new NewEmailFormValidator({
                senderCode: returnShot.sender!.code,
                to: returnShot.destination,
                subject: returnShot.subject
            }))

            setEmailShot(returnShot)
        }
    }

    /** Evento quando o actionType muda, ou seja, quando clica no botao de acao da tela. */
    function onActionTypeChange(): void {
        if (!actionType)
            return

        if (props.type === MarketingAccountTypeEnum.SMS)
            saveShot()

        if (props.type === MarketingAccountTypeEnum.EMAIL)
            setShouldExportHtml(true)
    }

    /** Prepara e valida dados do FORM para entao chamar a API. */
    async function saveShot(htmlContent?: string, jsonContent?: any): Promise<void> {

        const smsFormValues = smsFormStateManager.getFormValues()
        const emailFormValues = emailFormStateManager.getFormValues()

        smsFormStateManager.setConsiderAllErrors(true)
        emailFormStateManager.setConsiderAllErrors(true)

        if (props.type === MarketingAccountTypeEnum.SMS) {
            if (!await smsFormStateManager.validate())
                return setActionType(undefined)
        }

        if (props.type === MarketingAccountTypeEnum.EMAIL) {
            // Volta variavel para estado original e poder pegar a mudanca de valor novamente
            setShouldExportHtml(false)

            if (!await emailFormStateManager.validate())
                return setActionType(undefined)
        }

        const searchSegmentResult = await RequestHelper.runRequest<IApiReturn<ISegmentListItemResponseDTO>>(SegmentRequests.getOne(segmentCode!))
        const segment = searchSegmentResult?.data.data
        setSegmentName(segment!.name)

        if (!segment || totalPersonsValidToShot === 0) {
            NotificationHelper.error('Ops!', 'Não encontramos a lista de segmentação ou o número de contatos selecionados é zero')
            return setActionType(undefined)
        }

        if (props.type === MarketingAccountTypeEnum.SMS && !!smsFormValues) {
            const dto = {
                content: smsFormValues.content,
                totalContacts: totalPersonsValidToShot!,
                destination: {
                    segmentCode: smsFormValues.segmentCode,
                    franchises: franchisesCodes
                }
            }
            const requestConfig = !!smsShot?.code
                ? await MarketingRequests.updateCampaignShot(props.type, smsShot.code, dto)
                : await MarketingRequests.saveCampaignShot(props.type, dto)
            saveShotRequest.runRequest(requestConfig)
        }

        if (props.type === MarketingAccountTypeEnum.EMAIL && !!emailFormValues && !!htmlContent) {
            const dto = {
                subject: (emailFormValues).subject,
                htmlContent,
                jsonContent,
                totalContacts: totalPersonsValidToShot!,
                senderCode: (emailFormValues).senderCode,
                destination: {
                    segmentCode: emailFormValues.segmentCode,
                    franchises: franchisesCodes
                }
            }

            const requestConfig = !!emailShot?.code
                ? await MarketingRequests.updateCampaignShot(props.type, emailShot.code, dto)
                : await MarketingRequests.saveCampaignShot(props.type, dto)
            saveShotRequest.runRequest(requestConfig)
        }

    }

    /** Retorno do Request que salva o disparo na API. */
    function onSaveShotRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveShotRequest, 'Ocorreu algun erro ao salvar SMS')) {

            if (!saveShotRequest.isAwaiting)
                setActionType(undefined)

            return
        }

        if (props.type === MarketingAccountTypeEnum.EMAIL)
            setEmailShot(saveShotRequest.responseData as IMarketingCampaignEmailResponseDTO)

        if (props.type === MarketingAccountTypeEnum.SMS)
            setSmsShot(saveShotRequest.responseData as IMarketingCampaignSmsResponseDTO)

        if (actionType === 'sendNow' || actionType === 'schedule')
            setShowConfirmModal(true)

        else if (actionType === 'saveDraft') {

            // Reseta variavel para monitorar a alteracao da variavel novamente
            // Como o salvar rascunho fica na mesma tela, eh necessario resetar
            setActionType(undefined)
            NotificationHelper.success('Sucesso', 'Dados do envio salvo com sucesso como Rascunho')
        }

    }

    /** Ao concluir agendamento de disparo. */
    function onCancelConfirmModal(): void {
        setActionType(undefined)
        setShowConfirmModal(false)
    }

    /** Request para obter o total de pessoas aptas para receber disparo de SMS. */
    function defineTotalPersonsToShot(): void {

        if (!segmentCode || !franchisesCodes.length)
            return

        smsFormStateManager.changeFieldValue('franchisesCodes', franchisesCodes)
        emailFormStateManager.changeFieldValue('franchisesCodes', franchisesCodes)

        const filters: ICountPeopleToShootRequestDTO = {
            payload: {
                segmentCode,
                franchises: franchisesCodes,
            },
            type: props.type
        }

        countPeopleToShootReq.runRequest(PersonRequests.countPeopleToShoot(filters))
    }

    function onCountPeopleReqChange(): void {
        if (!!RequestUtils.isValidRequestReturn(countPeopleToShootReq, 'Erro total de contatos da lista selecionada'))
            setTotalPersonsValidToShot(countPeopleToShootReq.responseData)

    }

    const isInsufficientBalance = (
        accountBalance !== undefined
        && totalPersonsValidToShot !== undefined
        && (accountBalance - totalPersonsValidToShot <= 0)
    )

    const hasShot = smsShot ?? emailShot

    return (
        <>
            <LoadingOverlayCP show={saveShotRequest.isAwaiting}/>

            <HeaderCP title={`Novo envio de ${MarketingUtils.getShotTypeLabel(props.type)}`}>
                <SendingActionWrapperSCP>
                    <ConditionalRenderCP shouldRender={isInsufficientBalance}>
                        <TextCP
                            text={'!! Saldo insuficiente !!'}
                            color={ThemeAnt.errorColor}
                            marginRight={10}
                        />
                    </ConditionalRenderCP>

                    <NewShotActionsBtnGroupCP
                        onSendShotNow={() => setActionType('sendNow')}
                        onSaveDraft={() => setActionType('saveDraft')}
                        onScheduleShot={() => setActionType('schedule')}
                        disabled={isInsufficientBalance || !totalPersonsValidToShot || !accountBalance}
                    />
                </SendingActionWrapperSCP>
            </HeaderCP>

            <ContentContainerSCP>
                <LeftWrapperSCP isSmallDevice={screenSize.smd}>
                    {/* <CardCP>
                        {
                            props.type === MarketingAccountTypeEnum.SMS ?
                                <NewShotSmsDataCP
                                    formStateManager={smsFormStateManager}
                                    onChangeSegment={setSegmentCode}
                                    onChangeFranchises={setFranchisesCodes}
                                />
                                :
                                <NewShotEmailDataCP
                                    formStateManager={emailFormStateManager}
                                    onChangeSegment={setSegmentCode}
                                    onChangeFranchises={setFranchisesCodes}
                                />
                        }
                    </CardCP> */}

                    {/* <CardCP title={'Saldo'}>
                        <AccountBalanceStatisticsCP
                            accountType={props.type}
                            totalToSend={totalPersonsValidToShot ?? 0}
                            loading={countPeopleToShootReq.isAwaiting}
                            onBalanceCaught={balance => setAccountBalance(balance)}
                        />
                    </CardCP> */}
                </LeftWrapperSCP>

                {
                    !screenSize.smd && <MensagemManutencao>Serviço em Manutenção</MensagemManutencao>
                    // <RightWrapperSCP>
                    //     {
                    //         props.type === MarketingAccountTypeEnum.SMS ?
                    //             <PreviewPhoneTextCP text={smsFormStateManager.getFieldValue('content')}/>
                    //             :
                    //             <EmailEditorCP
                    //                 jsonContent={emailShot?.jsonContent}
                    //                 onExportContent={saveShot}
                    //                 shouldExportHtml={shouldExportHtml}
                    //             />
                    //     }
                    // </RightWrapperSCP>
                }
            </ContentContainerSCP>

            {
                hasShot &&
                <NewShotConfirmModalCP
                    actionType={actionType}
                    visible={showConfirmModal}
                    shot={props.type === MarketingAccountTypeEnum.SMS ? smsShot! : emailShot!}
                    shotType={props.type}
                    segmentName={segmentName}
                    onConfirmSend={() => RoutingHelper.historyPush(MarketingRoutes.MAIN_SCREEN_ROOT)}
                    onCancel={onCancelConfirmModal}
                />
            }
        </>
    )
}

const ContentContainerSCP = styled.div`
    display: flex;
`
const LeftWrapperSCP = styled.div<{ isSmallDevice: boolean }>`
    width: ${props => (props.isSmallDevice ? '100%' : '30%')};
`

// const RightWrapperSCP = styled.div`
//     width: 70%;
// `

const SendingActionWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
`

const MensagemManutencao = styled.div`
    font-size: 38px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 411;
`
