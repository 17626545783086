import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsDateString } from 'main/common/validation/decorators/IsDateString'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { PersonContactTypeEnum } from 'main/modules/people/services/person-contact/enums/PersonContactTypeEnum'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { Moment } from 'moment'

/**
 * VALIDADOR
 * Define validacao dos campos do formulario adicionar nova atividade para oportunidade
 */
export class PersonContactFormModel extends FormModel {

    @IsNotEmpty()
    @IsIn(Object.values(PersonContactTypeEnum))
    type: PersonContactTypeEnum

    @IsNotEmpty()
    isConcluded: boolean

    @IsNotEmpty()
    @IsDateString()
    date: Date

    @IsNotEmpty()
    beginTime: Moment

    @IsNotEmpty()
    endTime: Moment

    @IsNotEmpty()
    responsibleCode: number

    @IsNotEmpty()
    description: string

    constructor(initialData?: ObjectPropsTP<PersonContactFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
