import React from 'react'
import { ScreenSubMenuTP } from 'main/common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { FinancialViewTP } from 'main/modules/financial/types/FinancialViewTP'
import { FinancialRoutes } from 'main/modules/financial/routes/FinancialRoutes'
import { ScreenContentBankAccountCP } from 'main/modules/financial/screens/screen-financial/content/screen-content-bank-account/ScreenContentBankAccountCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { ScreenContentFinancialTransactionsCP } from 'main/modules/financial/screens/screen-financial/content/screen-content-financial-transactions/ScreenContentFinancialTransactionsCP'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { ScreenContentChargingCP } from 'main/modules/financial/screens/screen-financial/content/screen-content-charging/ScreenContentChargingCP'
import { BankAccountWithdrawScreenContentCP } from 'main/modules/financial/screens/screen-financial/content/screen-content-bank-account-withdraw/BankAccountWithdrawScreenContentCP'

export const FINANCIAL_SUBMENU_TRANSACTIONS_KEY = 'transactions'
export const FINANCIAL_SUB_MENU_REPORTS_KEY = 'reports'
export const FINANCIAL_SUB_MENU_ACCOUNTS_KEY = 'bank'

/**
 * UTILS
 * Reune metodos auxiliares para gestao da tela de Financeiro.
 */
export const FinancialScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<FinancialViewTP>> {
        return [
            {
                key: FINANCIAL_SUBMENU_TRANSACTIONS_KEY,
                title: 'Movimentações',
                items: [
                    {
                        name: '(+) Receitas',
                        view: 'ftCredtView',
                        route: FinancialRoutes.TRANSACTIONS_CREDIT,
                        content: <ScreenContentFinancialTransactionsCP type={FinancialTransactionTypeEnum.CREDIT}/>
                    },
                    
                    {
                        name: '(-) Despesas',
                        view: 'ftDebitView',
                        enabled:PermissionUtils.arePermissionsGranted([PermissionEnum.DESPESAS])?true:false,
                        route: FinancialRoutes.TRANSACTIONS_DEBIT,
                        content: <ScreenContentFinancialTransactionsCP type={FinancialTransactionTypeEnum.DEBIT}/>
                    },
                    {
                        name: 'Transferência entre contas',
                        view: 'banckAccountTransferView',
                        route: FinancialRoutes.WITHDRAWS,
                        content: <BankAccountWithdrawScreenContentCP/>
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
            },
            {
                key: FINANCIAL_SUB_MENU_ACCOUNTS_KEY,
                title: 'Contas',
                items: [
                    {
                        name: 'Contas Bancárias',
                        view: 'bankAccountView',
                        route: FinancialRoutes.BANK_ACCOUNT,
                        content: <ScreenContentBankAccountCP type={BankAccountTypeEnum.BANK_ACCOUNT}/>
                    }, {
                        name: 'Cartões de Crédito',
                        view: 'creditCardAccountView',
                        route: FinancialRoutes.CREDIT_CARD,
                        content: <ScreenContentBankAccountCP type={BankAccountTypeEnum.CREDIT_CARD}/>
                    }, {
                        name: 'Meios de Pagamento',
                        view: 'paymentPartnerView',
                        route: FinancialRoutes.PAYMENT_ACCOUNT,
                        content: <ScreenContentBankAccountCP type={BankAccountTypeEnum.PAYMENT_ACCOUNT}/>
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
            },
            {
                key: FINANCIAL_SUB_MENU_REPORTS_KEY,
                title: 'Relatórios',
                items: [
                    {
                        name: 'Painel de Cobrança',
                        view: 'chargingAllView',
                        contentTitle: 'Painel de Cobrança',
                        route: FinancialRoutes.CHARGING,
                        content: <ScreenContentChargingCP/>
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
            },
        ]
    },

}

