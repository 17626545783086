import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { TableSessionsCP } from 'main/modules/scheduler/components/table-sessions/TableSessionsCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { SaleAndSessionsResponseDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/responses/SaleAndSessionsResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ISaleSessionsTabICPProps {
    saleCode: number
}

/**
 * Componente com dados da aba de sessoes, sessoes da venda.
 */
export function SaleSessionsTabICP(props: ISaleSessionsTabICPProps): JSX.Element {

    useEffect(init, [])

    const [saleItemSessions, setSaleItemSessions] = useState<SaleAndSessionsResponseDTO>()

    const getSaleItemSessionsRequest = useRequest<SaleAndSessionsResponseDTO>()
    useEffect(onGetSaleItemSessionsRequestChange, [getSaleItemSessionsRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {
        getSaleItemSessionsRequest.runRequest(SaleRequests.getSessions(props.saleCode))
    }

    /**
     * Retorno da requisicao para obter as sessoes
     */
    function onGetSaleItemSessionsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSaleItemSessionsRequest, 'Erro obter sessões da compra'))
            return

        setSaleItemSessions(getSaleItemSessionsRequest.responseData)
    }

    if (!saleItemSessions)
        return <LoadingOverlayCP show={true}/>

    return (
        <WrapperSCP>
            <TitleCP
                underLine={true}
                textSize={'normal'}
            >
                Consumo geral de sessões
            </TitleCP>
            <ProgressCP
                percent={MathUtils.getPercentage(saleItemSessions.sessionsSummary.totalSessionsConcluded, saleItemSessions.sessionsSummary.totalSessions, true)}
                showInfo={true}
                descriptionText={`Realizou ${saleItemSessions.sessionsSummary.totalSessionsConcluded} / ${saleItemSessions.sessionsSummary.totalSessions} sessões`}
            />

            <TitleCP
                underLine={true}
                textSize={'normal'}
                marginTop={40}
            >
                Detalhamento das sessões por atendimento
            </TitleCP>
            <CollapseWrapperSCP>
                <CollapseCP
                    loading={getSaleItemSessionsRequest.isAwaiting}
                    panels={
                        saleItemSessions.items?.map(saleItem => (
                            {
                                title: (
                                    <FlexCP alignItems={'center'}>
                                        <TitleCP textSize={'normal'} underLine={true}>
                                            { saleItem.productRelease.name }
                                        </TitleCP>
                                    </FlexCP>
                                ),
                                extra: (
                                    <>
                                        <ProgressCP percent={MathUtils.getPercentage(saleItem.sessionSummary.totalSessionsConcluded, saleItem.sessionSummary.totalSessions, true)} showInfo={true}/>
                                        <TextCP text={`Realizou ${saleItem.sessionSummary.totalSessionsConcluded} / ${saleItem.sessionSummary.totalSessions} sessões`} size={'small'} color={'#adadad'}/>
                                    </>
                                ),
                                content: (
                                    <>
                                        <TableSessionsCP
                                            sessions={saleItem.sessions}
                                            onUpdateSession={init}
                                        />
                                    </>
                                )
                            }
                        ))
                    }
                />
            </CollapseWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    padding: 20px;
`

const CollapseWrapperSCP = styled.div`

    width: 100%;

    .ant-collapse-item {
      background: #f7f7f7;
      margin-top: 20px;
      border-radius: 4px !important;
    }

`
