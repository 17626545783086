import React from 'react'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'

interface ISalesAdvancedFiltersCPProps {
    formStateManager: IFormStateManager<SalesFiltersFormModel>
}

/**
 * Filtros avancados, mais filtros, da tela de vendas.
 */
export function SalesAdvancedFiltersCP(props: ISalesAdvancedFiltersCPProps): JSX.Element {

    return (
        <>
            <AutocompletePersonCP
                label={'Cliente'}
                considerCustomer={true}
                considerSupplier={true}
                formStateManager={props.formStateManager}
                fieldName={'personCode'}
                allowClear={true}
            />

            <AutocompletePersonCP
                label={'Vendedor'}
                considerUser={true}
                formStateManager={props.formStateManager}
                fieldName={'userSellerCode'}
                allowClear={true}
                returnFullOption={true}
                showOptionsOnLoad={true}
                onChange={(selected) => {
                    if (!!selected)
                        props.formStateManager.changeFieldValue('userSellerName', selected.label)
                }}
            />

            <SelectPaymentMethodCP
                label={'Forma de Pagamento'}
                formStateManager={props.formStateManager}
                fieldName={'paymentMethod'}
                allowClear={true}
            />

            <FlexCP alignItems={'space-between'}>
                <InputCP
                    label={'Valor Mín.'}
                    mask={InputMaskTypeEnum.MONEY}
                    formStateManager={props.formStateManager}
                    fieldName={'valueMin'}
                    marginRight={10}
                />
                <InputCP
                    label={'Valor Máx.'}
                    mask={InputMaskTypeEnum.MONEY}
                    formStateManager={props.formStateManager}
                    fieldName={'valueMax'}
                />
            </FlexCP>
        </>
    )
}
