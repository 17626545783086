import React, { useEffect, useState } from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IInputCPProps, InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'

interface IInputEditableCPProps<FModelTP extends FormModel> extends IInputCPProps<FModelTP> {
    onConfirm: (val: string) => void
}

/**
 * Campo INPUT que mostra o text e abre para edicao quando clica em cima.
 */
export function InputEditableCP<FModelTP extends FormModel = any>(props: IInputEditableCPProps<FModelTP>): JSX.Element {

    const [isEditingValue, setIsEditingValue] = useState<boolean>()
    const [value, setValue] = useState<string>(MaskUtils.applyMoneyMask(props.value))

    useEffect(() => setValue(MaskUtils.applyMoneyMask(props.value)), [props.value])

    return (
        <>
            {
                isEditingValue &&
                <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                    <InputCP
                        value={MaskUtils.applyMoneyMask(props.value)}
                        mask={InputMaskTypeEnum.MONEY}
                        type={'text'}
                        onChange={setValue}
                    />
                    <ButtonCP
                        icon={'check'}
                        onClick={() => {
                            setIsEditingValue(false)
                            props.onConfirm(value ?? '0')
                        }}
                        size={'small'}
                        marginLeft={5}
                        marginBottom={10}
                    />
                    <ButtonCP
                        icon={'close'}
                        type={'ghost'}
                        onClick={() => {
                            setIsEditingValue(false)
                        }}
                        size={'small'}
                        marginLeft={5}
                        marginBottom={10}
                    />
                </FlexCP>
            }
            {
                !isEditingValue &&
                <div
                    onClick={() => setIsEditingValue(true)}
                >
                    { value }
                </div>
            }
        </>
    )
}
