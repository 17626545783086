import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import React, { useEffect, useState } from 'react'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { BankAccountWithdrawTableUtils } from 'main/modules/financial/components/table-bank-account-withdraw/inner/BankAccountWithdrawTableUtils'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { BankAccountWithdrawFiltersFormModel } from 'main/modules/financial/components/bank-account-transfer-filters/inner/BankAccountWithdrawFiltersFormModel'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IBankAccountWithdrawSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankAccountWithdrawSearchRequestDTO'
import { IBankAccountWithdrawResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IBankAccountWithdrawResponseDTO'

interface ITableBankAccountWithdrawCPProps {
    filtersModel: IFormStateManager<BankAccountWithdrawFiltersFormModel>
    shouldReloadList?: boolean
    onSelect: (code: number) => void
    onReportLoaded: () => void
    onUpdateAnyData: () => void
}

/**
 * Componente de tabela que lista transferencia entre contas
 */
export function TableBankAccountWithdrawCP(props: ITableBankAccountWithdrawCPProps): JSX.Element {

    const [bankAccountWithdraws, setBankAccountWithdraws] = useState<IBankAccountWithdrawResponseDTO[]>([])

    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const searchWithdrawsRequest = useRequest<IGenericListResponseDTO<IBankAccountWithdrawResponseDTO>>()
    useEffect(onSearchWithdrawsRequestChange, [searchWithdrawsRequest.isAwaiting])

    useEffect(init, [props.shouldReloadList, pagination.current])

    /**
     * Inicializa
     */
    function init(): void {

        const filterValues = props.filtersModel.getFormValues()
        const dto: IBankAccountWithdrawSearchRequestDTO = {
            franchiseCodes: filterValues?.franchisesCodes,
            bankAccountSourceCode: filterValues?.bankAccountSourceCode,
            bankAccountTargetCode: filterValues?.bankAccountTargetCode,
            itemsPerPage: TableUtils.getDefaultPagination().pageSize,
            page: pagination.current
        }

        if (!!filterValues?.valueMin || filterValues?.valueMax) {
            dto.valueRange = {
                valueMin: filterValues?.valueMin,
                valueMax: filterValues?.valueMax
            }
        }

        if (!!filterValues?.dateRange?.beginDate || filterValues?.dateRange?.endDate) {
            dto.dateInterval = {
                beginDate: filterValues?.dateRange?.beginDate ? DateUtils.getFormatted(filterValues.dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
                endDate: filterValues?.dateRange?.endDate ? DateUtils.getFormatted(filterValues.dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            }
        }

        searchWithdrawsRequest.runRequest(FinancialTransactionsRequests.searchBankAccountWithdraws(dto))
    }

    /**
     * Retorno da busca por depesas.
     */
    function onSearchWithdrawsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchWithdrawsRequest, 'Erro ao buscar despesas!'))
            return

        setBankAccountWithdraws(searchWithdrawsRequest.responseData!.list)
        setPagination({ ...pagination, total: searchWithdrawsRequest.responseData!.total })
        props.onReportLoaded()
    }

    return (
        <>
            <TableCPOLD2
                data={bankAccountWithdraws}
                loading={searchWithdrawsRequest.isAwaiting}
                columns={BankAccountWithdrawTableUtils.getTableColumns(props.onSelect)}
                rowKey={(record) => record.code.toString()}
                pagination={pagination}
                onPaginationChange={(page) => setPagination({ ...pagination, current: page.current! })}
            />
        </>
    )
}
