export enum FinancialTransactionStatusEnum {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    OVERDUE = 'OVERDUE',
    AWAITING = 'AWAITING',
}

export enum FinancialTransactionStatusLabelEnum {
    PAID = 'Pago',
    UNPAID = 'Em aberto',
    OVERDUE = 'Atrasado',
    AWAITING = 'Em aberto',
}
