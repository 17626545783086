import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsIn, ValidateIf } from 'class-validator'
import { RecurrencePeriodEnum } from 'main/modules/financial/services/financial-transactions/enums/RecurrencePeriodEnum'
import { FinancialTransactionGroupTypeEnum } from 'main/modules/financial/services/financial-transactions/enums/FinancialTransactionGroupTypeEnum'

export class FinancialTransactionGroupFormModel extends FormModel {

    @IsNotEmpty()
    isRecurrent: boolean

    @ValidateIf((obj) => obj.isRecurrent)
    @IsNotEmpty()
    @IsIn(Object.values(FinancialTransactionGroupTypeEnum))
    type?: FinancialTransactionGroupTypeEnum

    // Abaixo caso seja parcelado
    @ValidateIf((obj) => obj.isRecurrent && obj.type === FinancialTransactionGroupTypeEnum.INSTALLMENT)
    @IsNotEmpty()
    totalInstallments?: number

    // Abaixo caso seja recorrente
    @ValidateIf((obj) => obj.isRecurrent && obj.type === FinancialTransactionGroupTypeEnum.RECURRENT)
    @IsNotEmpty()
    @IsIn(Object.values(RecurrencePeriodEnum))
    recurrencyPeriod?: RecurrencePeriodEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
