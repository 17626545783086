import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { IFollowUpsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IFollowUpsByUserListItemResponseDTO'

/**
 * UTILITARIOS do componente de listagem de total de followups por colaborador.
 */
export const TotalFollowupsTodayByUserTableUtils = {

    getTableColumns(): Array<ColumnProps<IFollowUpsByUserListItemResponseDTO>> {
        return [
            {
                title: 'Colaborador',
                dataIndex: 'user',
                key: 'user',
                render: (text, dto) => dto.user.name
            },
            {
                title: 'Followups p/ hoje',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.followUpsPlannedToday, 2)
            },
            {
                title: 'Meta',
                key: 'target',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.followUpsPlannedTodayTarget, 2)
            },
            { dataIndex: 'prog',
                key: 'prog',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação a meta: ${dto.followUpsPlannedTodayTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.followUpsPlannedToday, dto.followUpsPlannedTodayTarget, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'Concluídos',
                key: 'concluded',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.followUpsConcludedToday, 2)
            },
            { key: 'prog-2',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={'Progresso de realização das atividades'}
                        width={80}
                        percent={MathUtils.getPercentage(dto.followUpsPlannedToday, dto.followUpsConcludedToday, true)}
                        gradientColor={true}
                    />
                ) },
        ]
    },

}
