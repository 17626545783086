import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { TimePickerCP } from 'main/common/components/timepicker/TimePickerCP'
import moment, { Moment } from 'moment'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IScheduleBlockIntervalRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IScheduleBlockIntervalRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import { TextCP } from 'main/common/components/text/TextCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IUserAttendanceScheduledResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendanceScheduledResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IUserAttendanceScheduleSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IUserAttendanceScheduleSaveRequestDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

interface IBlockIntervalModalCPProps {
    visible: boolean
    onClose: () => void
    onActionDone: () => void
    userAttendanceScheduleCode?: number
    initialTime?: Moment
    userProfessionalCode: number
}

/**
 * Componente de modal para poder bloquear um horário especifico de atendimento de um colaborador
 */
export function BlockIntervalModalCP(props: IBlockIntervalModalCPProps): JSX.Element {

    const [blockReason, setBlockReason] = useState<string>()
    const [initTime, setInitTime] = useState<moment.Moment>()
    const [endTime, setEndTime] = useState<moment.Moment>()
    const [podeBloquear, setPodeBloquear] = useState<boolean>(false);
    useEffect(init, [props.visible])

    const getBlockIntervalRequest = useRequest<IUserAttendanceScheduledResponseDTO>()
    useEffect(onGetBlockIntervalRequestChange, [getBlockIntervalRequest.isAwaiting])

    const saveBlockIntervalRequest = useRequest<IScheduleBlockIntervalRequestDTO>()
    useEffect(onSaveBlockIntervalRequestChange, [saveBlockIntervalRequest.isAwaiting])

    const deleteUnavailableSlotRequest = useRequest<void>('none')
    useEffect(onDeleteUnavailableSlotRequestChange, [deleteUnavailableSlotRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {
        if(AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.BLOQUEAR_HORARIOS) || AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN) ){
            setPodeBloquear(true);
        }else{
            setPodeBloquear(false);
        }
        setBlockReason(undefined)
        setInitTime(props.initialTime)
        setEndTime(moment(props.initialTime).add(30, 'minutes'))

        if (!props.visible)
            return

        if (props.userAttendanceScheduleCode) {
            getBlockIntervalRequest.runRequest(SchedulerRequests.getUserAttendanceSchedule(props.userAttendanceScheduleCode))
            return
        }

    }

    /**
     * Retorno da API quando precisa buscar um horario
     */
    function onGetBlockIntervalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getBlockIntervalRequest, 'Erro ao buscar o horário'))
            return

        const responseData = getBlockIntervalRequest.responseData!

        setBlockReason(responseData.description)
        setInitTime(moment(DateUtils.toDate(responseData.beginDate, DateFormatEnum.US_WITH_TIME_H_M)))
        setEndTime(moment(DateUtils.toDate(responseData.endDate, DateFormatEnum.US_WITH_TIME_H_M)))
    }

    /**
     * Salva bloqueio de horario
     */
    function onSave(): void {

        if (!blockReason)
            return NotificationHelper.error('Ops!', 'Preencha o Motivo')

        if (!endTime)
            return NotificationHelper.error('Ops!', 'Preencha o horário final')

        if (!moment(props.initialTime).isBefore(endTime))
            return NotificationHelper.error('Ops!', 'Horário final deve ser posterior ao horário inicial')

        if (!!props.userAttendanceScheduleCode) {

            const dto: IUserAttendanceScheduleSaveRequestDTO = {
                beginDate: moment(props.initialTime).format('YYYY-MM-DD HH:mm'),
                endDate: moment(endTime).format('YYYY-MM-DD HH:mm'),
                comment: blockReason
            }
            saveBlockIntervalRequest.runRequest(SchedulerRequests.updateUserAttendanceSchedule(props.userAttendanceScheduleCode, dto))

            return
        }

        const dto: IScheduleBlockIntervalRequestDTO = {
            professionCode: props.userProfessionalCode,
            beginDate: moment(props.initialTime).format('YYYY-MM-DD HH:mm'),
            endDate: moment(endTime).format('YYYY-MM-DD HH:mm'),
            description: blockReason
        }
        saveBlockIntervalRequest.runRequest(SchedulerRequests.createUnavailableSlot(dto))
    }

    /**
     * Retorno da API ao salvar bloqueio de horario
     */
    function onSaveBlockIntervalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveBlockIntervalRequest, 'Erro ao salvar bloqueio de horário'))
            return

        NotificationHelper.success('Yes!', 'Horário bloqueado com sucesso!')
        props.onActionDone()
    }

    /**
     * Remover.
     */
    function deleteUnavailableSlot(): void {

        if (!props.userAttendanceScheduleCode)
            return

        deleteUnavailableSlotRequest.runRequest(SchedulerRequests.deleteUserAttendanceSchedule(props.userAttendanceScheduleCode))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteUnavailableSlotRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteUnavailableSlotRequest, 'Não foi remover o horário bloqueado', true))
            return

        NotificationHelper.success('Pronto!', 'Horário desbloqueado com sucesso!')
        props.onActionDone()
    }

    return (
        <ModalCP
            title={'Bloquear horário'}
            width={300}
            visible={props.visible}
            onCancel={props.onClose}
            loading={getBlockIntervalRequest.isAwaiting}
            destroyOnClose={true}
            footer={
                <FlexCP justifyContent={!!props.userAttendanceScheduleCode ? 'space-between' : 'flex-end'}>
                    <ConditionalRenderCP shouldRender={!!props.userAttendanceScheduleCode && podeBloquear}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteUnavailableSlotRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover?'}
                            onClick={deleteUnavailableSlot}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ConditionalRenderCP shouldRender={podeBloquear}>
                        <ButtonCP
                            type={'primary'}
                            onClick={onSave}
                            loading={saveBlockIntervalRequest.isAwaiting}
                        >
                            Salvar
                        </ButtonCP>
                    </ConditionalRenderCP>
                </FlexCP>
            }
        >
            <InputCP
                label={'Motivo'}
                value={blockReason}
                onChange={setBlockReason}
                required={true}
            />

            <FlexCP
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={30}
            >
                <TimePickerCP
                    disabled={true}
                    value={initTime}
                    size={'large'}
                    margin={'0'}
                    width={'100px'}
                />
                <TextCP text={' às '}/>
                <TimePickerCP
                    value={endTime}
                    onChange={setEndTime}
                    size={'large'}
                    allowClear={false}
                    margin={'0'}
                    width={'100px'}
                />
            </FlexCP>

        </ModalCP>
    )
}
