import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { StatisticCP } from 'main/common/components/statistic/StatisticCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IFunnelStepSummaryResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepSummaryResponseDTO'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFunnelStepSummaryRequestDTO } from 'main/modules/sales-funnel/services/funnel/dtos/request/IFunnelStepSummaryRequestDTO'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'

const SELECTED_STEP_CLASS_NAME = 'selected-step'

interface IStatisticsFollowUpCPProps {
    selectedStepCode?: number
    onChangeFunnelStep: (selectedFunnelStep: number) => void
    shouldLoadData: boolean
    formStateManager: IFormStateManager<OpportunityFiltersFormModel>
}

/**
 * Estatísticos do funil de follow-up
 */
export function StatisticsFollowUpCP(props: IStatisticsFollowUpCPProps): JSX.Element {

    const funnelStepSummaryDataRequest = useRequest<IFunnelStepSummaryResponseDTO[]>()
    useEffect(onFunnelStepSummaryDataRequestChange, [funnelStepSummaryDataRequest.isAwaiting])

    useEffect(loadFollowupSummary, [props.shouldLoadData])

    /**
     * Inicializa.
     */
    function loadFollowupSummary(): void {

        const filterDto: IFunnelStepSummaryRequestDTO = {
            userResponsibleCode: props.formStateManager.getFieldValue('responsibleCode')
        }
        funnelStepSummaryDataRequest.runRequest(FunnelRequests.funnelStepsSummaryConfig(filterDto))
    }

    /**
     * Retorno da API ao buscar dados de cada etapa.
     */
    function onFunnelStepSummaryDataRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(funnelStepSummaryDataRequest, 'Erro ao buscar dados estatísticos de followup'))
            return
    }

    return (
        <StatisticsFollowUpWrapperSCP>
            {
                funnelStepSummaryDataRequest.responseData?.map((stepSummary, index) => (
                    <>
                        <ContentWrapperSCP
                            className={props.selectedStepCode === stepSummary.step.code ? SELECTED_STEP_CLASS_NAME : ''}
                            onClick={() => props.onChangeFunnelStep(stepSummary.step.code)}
                        >
                            <StatisticCP
                                label={stepSummary.step.name}
                                value={stepSummary.totalOpportunities}
                                icon={<IconCP antIcon={props.selectedStepCode === stepSummary.step.code ? 'eye' : 'environment'}/>}
                                valueUnit={'oport.'}
                                color={ThemeAnt.primaryColor}
                            />
                        </ContentWrapperSCP>
                        {
                            index < ((funnelStepSummaryDataRequest.responseData?.length ?? 0) - 1) &&
                            <IconContentWrapperSCP>
                                <FontAwsomeIconCP type={faArrowRight} size={'2x'}/>
                            </IconContentWrapperSCP>
                        }
                    </>

                ))
            }
        </StatisticsFollowUpWrapperSCP>
    )
}

const StatisticsFollowUpWrapperSCP = styled.div`
    padding: 20px;
    display: flex;
    justify-content: stretch;
    min-width: 100%;
`

const ContentWrapperSCP = styled.div`
    flex: 1;
    cursor: pointer;
    
    &.${SELECTED_STEP_CLASS_NAME} .ant-card-body::before{
        content: ''; 
        width: 5px;
        height: 100%;
        border-radius: 8px 0 0 8px;
        top: 0;
        left: 0;
        position: absolute;
        background: ${props => props.theme.primaryColor};
    }
`

const IconContentWrapperSCP = styled.div`
    display: flex;
    align-items: center;
`
