import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { ProductReleaseTreatmentTableActionsColumnICP } from 'main/modules/products/components/table-product-release-treatment/inner/ProductReleaseTreatmentTableActionsColumnICP'
import { TextCP } from 'main/common/components/text/TextCP'

/**
 * Utils da tabela de cargos e funcoes
 */
export const ProductReleaseTreatmentTableUtils = {

    getTableColumns(
        onUpdateTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void,
        onDeleteTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void
    ): Array<ColumnProps<IProductReleaseTreatmentConfigResponseDTO>> {
        return [
            {
                title: 'Nome',
                key: 'name',
                render: (text, item) => item.treatment.name
            },
            {
                title: 'Nº de Sessões',
                key: 'quantity',
                render: (text, item) => (
                    <>
                        <TextCP text={`${item.totalSessions} sessão(s) (${item.sessionDuration}min)`}/>
                        <TextCP text={`${item.personPerSession} pessoa(s) por sessão`} size={'small'} color={'#ADADAD'}/>
                    </>
                )
            },
            {
                key: 'acts',
                align: 'center',
                render: (text, item) => (
                    <ProductReleaseTreatmentTableActionsColumnICP
                        treatmentConfig={item}
                        onUpdateTreatmentConfig={onUpdateTreatmentConfig}
                        onDeleteTreatmentConfig={onDeleteTreatmentConfig}
                    />
                )
            },
        ]
    },

}
