import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { FunnelStepsDataCP } from 'main/modules/sales-funnel/components/funnel-steps-data/FunnelStepsDataCP'
import styled from 'styled-components'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'

interface IStepsSettingModalCPProps {
    visible: boolean
    onCancel: () => void
    onSave: (closeModal: boolean) => void
}

/**
 * Modal de configuração das etapas do funil na etapa de Follow-UP
 */
export function StepsSettingModalCP(props: IStepsSettingModalCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [funnelSteps, setFunnelSteps] = useState<IFunnelStepResponseDTO[]>([])
    const [hasChanged, setHasChanged] = useState<boolean>(false)

    const getStepsRequest = useRequest<IGenericListResponseDTO<IFunnelStepResponseDTO>>()
    useEffect(onGetStepsRequestChange, [getStepsRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.visible)
            return

        getStepsRequest.runRequest(FunnelRequests.searchStepsConfig())
    }

    /**
     * Retorno ao buscar as etapas na API
     */
    function onGetStepsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getStepsRequest, 'Não foi possível obter lista com as etapas do funil de follow-up.'))
            return

        setFunnelSteps(getStepsRequest.responseData!.list)

        if (hasChanged) {
            setHasChanged(false)
            props.onSave(true)
        }

    }

    return (
        <ModalCP
            title={'Configurar etapas do funil'}
            width={'400px'}
            visible={props.visible}
            noFooter={true}
            onCancel={(): void => props.onCancel()}
            loading={getStepsRequest.isAwaiting}
        >
            <ContentWrapperSCP>
                <FunnelStepsDataCP
                    steps={funnelSteps}
                    updateStepsList={(closeModal: boolean) => {
                        setHasChanged(closeModal)
                        init()
                    }}
                    loading={getStepsRequest.isAwaiting}
                />
            </ContentWrapperSCP>
        </ModalCP>
    )

}

const ContentWrapperSCP = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;    
    min-height: 250px;
`
