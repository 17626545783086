import React, { useEffect, useState } from 'react'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { SalePaymentStepICP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SalePaymentStepICP'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import * as _ from 'lodash'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { CreateChargeUtils } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeUtils'
import { SaleChargeRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/inner/SaleChargeRequestDTO'
import { SaleChargeUpdateRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleChargeUpdateRequestDTO'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface IModalEditSaleChargesCPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    charges: IChargeListItemResponseDTO[]
    saleCode: number
    saleDate: Date
}

/**
 * Modal para editar parcelas de uma venda.
 */
export function ModalEditSaleChargesCP(props: IModalEditSaleChargesCPProps): JSX.Element {

    const [paymentDataFormValidator, setPaymentDataFormValidator] = useState<SalePaymentDataFormModel>(new SalePaymentDataFormModel())
    const paymentDataFormStateManager = useFormStateManager<SalePaymentDataFormModel>(paymentDataFormValidator)

    const [canSave, setCanSave] = useState<boolean>(false)

    const updateChargesRequest = useRequest<void>('none')
    useEffect(onUpdateChargesRequestChange, [updateChargesRequest.isAwaiting])

    useEffect(init, [props.visible, props.charges])

    /**
     * Busca detalhes da venda.
     */
    function init(): void {

        if (!props.visible)
            return

        const salesCharges = props.charges.map((_charge) => ({
            code: _charge.code,
            paymentMethod: _charge.chargeData.paymentMethod,
            paymentType: _charge.chargeData.type,
            authNumber: _charge.chargeData.authorizationNumber,
            nsu: _charge.chargeData.nsu,
            hasGracePeriod: _charge.chargeData.hasGracePeriod,
            installments: _charge.financialTransactions.map((installment) => ({
                installmentNumber: installment.installmentNumber,
                expirationDate: DateUtils.toDate(installment.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
                value: installment.value,
                isPaid: installment.status === FinancialTransactionStatusEnum.PAID,
                code: installment.code,
            }))
        }))

        setPaymentDataFormValidator(new SalePaymentDataFormModel({
            totalValue: CreateChargeUtils.getChargeInstallmentsSum(props.charges),
            saleCharges: salesCharges,
        }))
    }

    /**
     * Edita os dados da venda.
     */
    function editSale(): void {

        const paymentDataFormValues = paymentDataFormStateManager.getFormValues()
        if (!paymentDataFormValues)
            return

        // Monta DTO das cobrancas
        const charges: SaleChargeRequestDTO[] = []
        _.each(paymentDataFormValues.saleCharges, (_charge: ISaleCharge) => {

            const installments = _charge.installments.map((installment) => ({
                installmentNumber: installment.installmentNumber,
                expirationDate: installment.expirationDate,
                value: installment.value,
                code: installment.code,
            }))

            const chargeItem: SaleChargeRequestDTO = {
                code: _charge.code,
                paymentAccountCode: _charge.paymentAccountCode,
                paymentMethod: _charge.paymentMethod,
                paymentType: _charge.paymentType,
                authNumber: _charge.authNumber as any,
                nsu: _charge.nsu,
                installments,
                hasGracePeriod: _charge.hasGracePeriod,
            }

            charges.push(chargeItem)
        })

        const updateChargeDto: SaleChargeUpdateRequestDTO = {
            saleCode: props.saleCode,
            charges
        }
        updateChargesRequest.runRequest(SaleRequests.updateCharges(updateChargeDto))
    }

    /**
     * Retorno da requisicao que atualiza venda
     */
    function onUpdateChargesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateChargesRequest, 'Erro obter atualizar parcelas e cobranças', 'Dados atualizados com sucesso.', true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            title={'Editar dados Parcelas e Cobranças'}
            visible={props.visible}
            width={600}
            top={10}
            actionLoading={updateChargesRequest.isAwaiting}
            onOk={editSale}
            okButtonProps={{ disabled: !canSave }}
            onCancel={props.onCancel}
            destroyOnClose={true}
        >
            {
                paymentDataFormStateManager.getFieldValue('saleCharges') &&
                <SalePaymentStepICP
                    formStateManager={paymentDataFormStateManager}
                    canGoNext={setCanSave}
                    isEditingSaleCharges={true}
                    saleDate={props.saleDate}
                />
            }
        </ModalCP>
    )
}
