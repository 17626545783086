import React from 'react'
import styled from 'styled-components'
import { ListFilesFromApiCP } from 'submodules/nerit-framework-ui/common/components/file/list-files-from-api/ListFilesFromApiCP'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { SystemConfig } from 'main/config/SystemConfig'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PublicRouter } from 'main/modules/app/routes/PublicRouter'
import { PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION, PARAM_SCREEN_UPLOAD_FILES_CODE, PARAM_SCREEN_UPLOAD_FILES_TYPE } from 'main/modules/app/screens/screen-upload-files/ScreenUploadFiles'
import { ScreenFileUploadEnum } from 'main/modules/app/screens/screen-upload-files/inner/ScreenFileUploadEnum'

interface ICPProps {
    saleCode: number
}

/**
 * Componente com dados da aba de arquivos de uma venda.
 */
export function SaleFilesTabICP(props: ICPProps): JSX.Element {

    /**
     * Monta a url de upload quando leitura do qrcode
     */
    function getUploadByQrCodeUrl(): string {

        let url = `${SystemConfig.getInstance().uiBaseUrl}${PublicRouter.UPLOAD_FILES}`

        url = `${url}?${PARAM_SCREEN_UPLOAD_FILES_CODE}=${props.saleCode}`
        url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION}=${AppStateUtils.getLoggedUserData()!.token}`
        url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_TYPE}=${ScreenFileUploadEnum.SALE}`

        return url
    }

    return (
        <WrapperSCP>
            <ListFilesFromApiCP
                entityCode={props.saleCode}
                requestsConfig={{
                    searchRequestConfigTP: SaleRequests.searchFiles,
                    deleteRequestConfigTP: SaleRequests.deleteFile,
                    uploadRequestConfigTP: SaleRequests.uploadFile,
                }}
                qrCode={{
                    visible: true,
                    url: getUploadByQrCodeUrl()
                }}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
`
