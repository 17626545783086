import { NpsResponderTypeEnum } from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'
import { NPS_COLORS } from 'submodules/space4leads-components-ui/survey/components/buttons-survey-score/inner/SurveyButtonScoreColors'

/**
 * Utiliario geral de nps.
 */
export const NpsUtils = {

    getColorByReponderType(type: NpsResponderTypeEnum): string {

        if (type === NpsResponderTypeEnum.DETRACTOR)
            return NPS_COLORS[0]

        if (type === NpsResponderTypeEnum.PASSIVE)
            return NPS_COLORS[6]

        return NPS_COLORS[9]
    },

}
