import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetFollowUpEnum } from 'main/modules/dashboard/services/enums/WidgetFollowUpEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de total de oportunidades em followup.
 */
export function WidgetTotalFollowupsOpenCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Follow-ups'}
            help={'Total de Oportunidades que estão em follow-up'}
            requestConfigGetter={
                DashboardRequests.getFollowupWidgets(
                    WidgetFollowUpEnum.OPENS,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
        />
    )
}
