import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import * as _ from 'lodash'
import { IFunnelStepSummaryResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepSummaryResponseDTO'
import { DragAndDropColumnTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropColumnTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { DragAndDropItemTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropItemTP'

/**
 * Utils do kanban de oportunidades.
 */
export const OpportunityKanbanUtils = {

    /**
     * Transforma o DTO de oportunidades em um kanban por etapa do funil.
     */
    dtoToKanbanDataFormat(funnelStepsData: IFunnelStepSummaryResponseDTO[], opportunities?: IOpportunityListItemResponseDTO[]): Array<DragAndDropColumnTP<IOpportunityListItemResponseDTO>> {

        if (!opportunities || _.isEmpty(opportunities))
            return []

        // Monta as colunas do kanban, que neste caso sao as etapas do funil
        const listAsKanban: Array<DragAndDropColumnTP<IOpportunityListItemResponseDTO>> = funnelStepsData.map((stepData) => ({
            columnId: StringUtils.getSlugStyleString(stepData.step.name),
            code: stepData.step.code,
            name: stepData.step.name,
            totalItens: stepData.totalOpportunities,
            itens: []
        }))

        // Itera nas opotunidades colocando cada uma na etapa correta
        let orderCount = 0
        opportunities.forEach((opportunity) => {

            // Mapeia valores do DTO com os necessarios do tipo do kanban
            const valueToInsert: DragAndDropItemTP<IOpportunityListItemResponseDTO> = {
                code: opportunity.code,
                index: orderCount,
                order: orderCount,
                item: opportunity
            }

            // Busca o step na lista para adicionar o elemento
            const stepFounded = listAsKanban.find((i) => i.columnId === StringUtils.getSlugStyleString(opportunity.folowUpData?.funnelStep?.name))
            if (!stepFounded)
                return

            stepFounded.itens.push(valueToInsert)
            orderCount++
        })

        return listAsKanban
    }

}
