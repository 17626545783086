export const ThemeProjectCommon = {

    schedulerSessionAwaiting: 'rgba(0, 82, 204, 0.9)',
    schedulerSessionScheduled: 'rgba(105, 184, 175, 0.9)',
    schedulerSessionCheckinDone: 'rgba(243, 185, 0, 0.9)',
    schedulerSessionInProgress: 'rgba(196, 26, 26, 0.9)',
    schedulerSessionConcluded: 'rgba(42, 196, 131, 0.9)',
    schedulerSessionNoShow: 'rgba(248, 131, 131, 0.9)',
    schedulerSessionNotMarkedAsDone: 'rgba(243, 156, 18, 0.9)',
    schedulerPresenceConfirmed: 'rgba(142, 68, 173, 0.9)',
    schedulerEvaluation: 'rgba(52, 73, 94, 0.9)',
    schedulerUnavailable: 'rgba(253, 191, 191, 0.52)',
    schedulerUnavailableBackgroundExtra: 'repeating-linear-gradient(120deg, transparent 5px, transparent 8px, rgba(240, 240, 240, .9) 10px, rgba(240, 240, 240, .9) 13px)',
    schedulerNonBusinessHour: '#676767',
    schedulerNonBusinessHourBackgroundExtra: 'repeating-linear-gradient(120deg, transparent 5px, transparent 8px, rgba(240, 240, 240, .9) 10px, rgba(240, 240, 240, .9) 13px)',

}
