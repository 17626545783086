import React from 'react'
import { IProductReleaseTreatmentConfigResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseTreatmentConfigResponseDTO'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { ProductReleaseTreatmentTableUtils } from 'main/modules/products/components/table-product-release-treatment/inner/ProductReleaseTreatmentTableUtils'

interface IServicesListTableCPProps {
    treatments: IProductReleaseTreatmentConfigResponseDTO[]
    onUpdateTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void
    onDeleteTreatmentConfig?: (treatmentConfig: IProductReleaseTreatmentConfigResponseDTO) => void
}

/**
 * Componente de tabela que lista as os produtos de uma fatura
 */
export function TableProductReleaseTreatmentTableCP(props: IServicesListTableCPProps): JSX.Element {

    return (
        <TableCPOLD2<IProductReleaseTreatmentConfigResponseDTO>
            data={props.treatments}
            columns={ProductReleaseTreatmentTableUtils.getTableColumns(
                props.onUpdateTreatmentConfig,
                props.onDeleteTreatmentConfig
            )}
        />
    )
}
