import React, { useEffect } from 'react'
import styled from 'styled-components'
import { StatisticCardCP } from 'main/common/components/statistic-card/StatisticCardCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faStore, faUsers } from '@fortawesome/free-solid-svg-icons'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ITotalFranchisesResponseDTO } from 'main/modules/dashboard/services/dtos/response/ITotalFranchisesResponseDTO'
import { ITotalCustomersResponseDTO } from 'main/modules/dashboard/services/dtos/response/ITotalCustomersResponseDTO'
import { MaskUtils } from 'main/common/utils/MaskUtils'

/**
 * Barra superior com estatisticas gerais de uma franqueadora
 */
export function FranchisorDashboardGeneralStatisticsCP(): JSX.Element {

    const getTotalFranchisesRequest = useRequest<ITotalFranchisesResponseDTO>()
    const getTotalCustomersRequest = useRequest<ITotalCustomersResponseDTO>()

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        getTotalFranchisesRequest.runRequest(DashboardRequests.totalFranchises())
        getTotalCustomersRequest.runRequest(DashboardRequests.totalCustomers())
    }

    return (
        <WrapperSCP>
            <StatisticsWrapperSCP/>

            <StatisticsWrapperSCP>
                <StatisticCardCP
                    title={'No. de franquias'}
                    color={ThemeAnt.primaryColor}
                    prefix={<FontAwsomeIconCP type={faStore} size={'2x'}/>}
                    value={MaskUtils.applyNumberMask(getTotalFranchisesRequest.responseData?.totalFranchises ?? 0, 0)}
                    loading={getTotalFranchisesRequest.isAwaiting}
                />
            </StatisticsWrapperSCP>

            <StatisticsWrapperSCP>
                <StatisticCardCP
                    title={'Total de clientes'}
                    color={ThemeAnt.primaryColor}
                    prefix={<FontAwsomeIconCP type={faUsers} size={'2x'}/>}
                    value={MaskUtils.applyNumberMask(getTotalCustomersRequest?.responseData?.totalCustomers ?? 0, 0)}
                    loading={getTotalCustomersRequest.isAwaiting}
                />
            </StatisticsWrapperSCP>

            

            <StatisticsWrapperSCP/>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px;
`

const StatisticsWrapperSCP = styled.div`
  flex: 1;
`
