import React, { useEffect, useState } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { AutoCompleteCancelationReasonCP } from 'main/modules/sales-funnel/components/autocomplete-cancelation-reason/AutoCompleteCancelationReasonCP'
import { CancelationReasonTypeEnum } from 'main/modules/sales-funnel/services/cancelation-reason/enums/CancelationReasonTypeEnum'
import { ButtonTypeTP } from 'main/common/components/button/inner/ButtonTypeTP'
import { ButtonSizeTP } from 'main/common/components/button/inner/ButtonSizeTP'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
}

interface ICPProps extends IScpProps {
    opportunityCode: number
    showIcon?: boolean
    showText?: boolean
    onArchive: () => void
    buttonType: ButtonTypeTP
    buttonSize?: ButtonSizeTP
}

/**
 * Botao para arquivar uma oportunidade com popover da reason
 */
export function ButtonArchiveOpportunityCP(props: ICPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>()

    const [lossReasonCode, setLossReasonCode] = useState<number>()

    const archiveOpportunityRequest = useRequest<string>()
    useEffect(archiveOpportunityRequestChange, [archiveOpportunityRequest.isAwaiting])

    /**
     * Arquiva uma oportunidade.
     */
    function archiveOpportunity(): void {

        if (!lossReasonCode) {
            NotificationHelper.warning('Ops!', 'Informe qual o motivo para arquivar')
            return
        }

        archiveOpportunityRequest.runRequest(OpportunityRequests.markOpportunityAsLost(props.opportunityCode, lossReasonCode))
    }

    /**
     * Retorno da api ao arquivar uma oportunidade.
     */
    function archiveOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(archiveOpportunityRequest, 'Erro ao tentar arquivar oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade arquivada com sucesso')
        setIsPopoverVisible(false)
        props.onArchive()
    }

    return (
        <PopOverCP
            title={'Qual o motivo para arquivar a oportunidade?'}
            onClose={() => setIsPopoverVisible(false)}
            content={
                <>
                    <AutoCompleteCancelationReasonCP
                        label={'Selecione o motivo'}
                        type={CancelationReasonTypeEnum.OPPORTUNITY}
                        onChange={setLossReasonCode}
                        required={true}
                    />

                    <FlexCP justifyContent={'space-between'} marginTop={15}>
                        <ButtonCP
                            type={'ghost'}
                            onClick={() => setIsPopoverVisible(false)}
                        >
                            Cancelar
                        </ButtonCP>
                        <ButtonCP
                            type={'danger'}
                            icon={props.showIcon ? 'container' : undefined}
                            onClick={archiveOpportunity}
                            loading={archiveOpportunityRequest.isAwaiting}
                        >
                            Arquivar
                        </ButtonCP>
                    </FlexCP>
                </>
            }
            visible={isPopoverVisible}
            destroyOnHide={true}
        >
            <FlexCP justifyContent={'flex-end'}>
                <ButtonCP
                    type={props.buttonType}
                    icon={props.showIcon ? 'container' : undefined}
                    onClick={() => setIsPopoverVisible(true)}
                    loading={archiveOpportunityRequest.isAwaiting}
                    marginRight={props.marginRight}
                    marginLeft={props.marginLeft}
                    size={props.buttonSize}
                >
                    { props.showText ? 'Arquivar' : undefined}
                </ButtonCP>
            </FlexCP>
        </PopOverCP>
    )

}
