export enum ChargeStatusEnum {
    CREATED = 'CREATED', // Quando ja criamos a ordem e enviamos ao parceiro.
    CANCELED = 'CANCELED', // Ordem de cobranca cancelada.
    CHARGED = 'CHARGED', // Ordem foi cobrada, ou seja, o cartao da pessoa passou corretamente.
    DECLINED = 'DECLINED', // Cobranca nao foi aceita, ou seja o cartao da pessoa nao passou.
    SCHEDULED = 'SCHEDULED', // Cobranca agendada para ser realizada no futuro.
    PAID = 'PAID', // Ordem foi paga, dinheiro ja esta na conta. Obs: Diferente de CHARGED que apenas cobrado com sucesso e nao necessariamente o dinheiro ja caiu. Cartao demora 30 dias por exemplo.
    REFUND = 'REFUND', // Ordem estornada.
}

export enum ChargeStatusLabelEnum {
    CREATED = 'Registrada',
    CANCELED = 'Cancelada',
    CHARGED = 'Cobrada',
    DECLINED = 'Declinada',
    SCHEDULED = 'Agendada',
    PAID = 'Aprovada',
    REFUND = 'Estornada', // Ordem estornada.
}
