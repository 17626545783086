import { RefObject } from 'react'
import { css } from 'main/config/theme/styledWithTheme'
import { FlattenSimpleInterpolation } from 'styled-components'

/**
 * Classe auxiliar para deteccao de evento 'hover' empregando metodologia baseada em css.
 *
 * - Escolhemos uma propriedade css qualquer (dummy) e um valor especifico para a mesma;
 * - Aplicamos css, no elemento a ser analisado, que inclui a propriedade 'dummy' sempre que o mouse passar sobre ele;
 * - Toda vez que o elemento analisado possuir essa propriedade com esse valor interpretamos que o mouse esta sobre ele;
 *
 * Justificativa: A resposta via css para o evento 'hover' eh mais precisa que a do javascript 'onHover()';
 */
export class CssHoverUtils {

    /** Propriedade usada para determinar posicao do mouse em relacao ao elemento via css. */
    private static readonly _CSS_PROP_NAME = 'scroll-behavior'

    /**
     * Valor da propriedade que sera interpretado como se o mouse estiver sobre o elemento inspecionado.
     * NOTE: Nao tem nenhum efeito pratico em termos de estilo
     */
    private static readonly _CSS_PROP_VALUE = 'smooth'

    private constructor() {}

    /** Determina se mouse esta sobre um elemento identificado por sua referencia. */
    static isHovered(elementRef: RefObject<HTMLElement>): boolean {

        const element = elementRef?.current
        if (!element)
            return false

        const detectionValue = window.getComputedStyle(element).getPropertyValue(this._CSS_PROP_NAME)
        return (detectionValue === this._CSS_PROP_VALUE)
    }

    /** Retorna CSS generico a ser aplicado num elemento qualquer para realizar controle de hover via css. */
    static getCssForHoverControl(): FlattenSimpleInterpolation {
        return css`
            &:hover {
                ${CssHoverUtils._CSS_PROP_NAME}: ${CssHoverUtils._CSS_PROP_VALUE}
            }
        `
    }
}
