import React, { useEffect, useState } from 'react'
import * as One from "common/utils/One"
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { SystemConfig } from 'main/config/SystemConfig';
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { TableCP } from 'main/common/components/table/TableCP';
import { CardCP } from 'main/common/components/card/CardCP';
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { TitleCP } from 'main/common/components/title/TitleCP';
import { MaskUtils } from 'main/common/utils/MaskUtils';
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum';
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP';
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum';
import { FlexCP } from 'main/common/components/flex/FlexCP';
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP';
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP';
import styled from 'styled-components';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'






export function RelatorioSessoesTela():JSX.Element{
    useEffect(()=>{carregarDados();},[]);
    const [carregando,setCarregando] = useState<any>(false);
    const [dados,setDados] = useState<any>([]);
    const [codigoCliente,setCodigoCliente] = useState<any>();
    const [mostrarDetalhe,setMostrarDetalhe] = useState(false);
   
    const colunasExecucao = [
        {
          title: 'Codigo ',
          dataIndex: 'idPessoa',
          key: 'idPessoa',
        },
        {
            title: 'Dados do Cliente',
            dataIndex: 'dadosc',
            key: 'dadosc',
            render:(txt:any,dados:any)=><ButtonCP icon='eye' tooltip='Clique para ver os dados do cliente' onClick={()=>{
                setCodigoCliente(dados.idPessoa);
                setMostrarDetalhe(true);
            }}></ButtonCP>
        },
        {
            title: 'Sessões Restantes',
            dataIndex: 'sessoes',
            key: 'sessoes',
            render:(txt:any,dados:any)=><div>{dados.aberta+'/'+(dados.fechada+dados.aberta)}</div>
        },
        {
          title: 'Nome do Cliente',
          dataIndex: 'nome',
          key: 'nome',
          render:(txt:any,dados:any)=> (dados.nome+'').toLocaleUpperCase()
        },
        {
          title: 'Telefone',
          dataIndex: 'tel',
          key: 'tel',
          render:(txt:any,dados:any)=>{
            return (
            <FlexCP>
                <IconWrapper onClick={()=>{
                   
                        window.open(`https://api.whatsapp.com/send?phone=55${dados.telefone!}&text=&source=&data=&app_absent=`, 'blank')
                }}>
                    { MaskUtils.applyMask(dados.telefone, InputMaskTypeEnum.PHONE) }
                    <TooltipCP text={'Enviar Whatsapp'} showSpan={true}>
                        <FontAwsomeIconCP type={faWhatsapp} color={'green'}/>
                    </TooltipCP>
                </IconWrapper>
            </FlexCP>
            )
          }
        },
        
      ];

    async function carregarDados(){
        
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/obter-dados-sessoes`,
            method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }

           
        }).finally(()=>setCarregando(false)); 
        if(One.ok(resultado)){
           console.log(One.dados(resultado));
           setDados(One.dados(resultado)['sessoes']);
        }
    }

    return (
       <>
       <TitleCP textSize='normal' underLine={true} >Lista de Clientes que possuem menos de 3 sessões para o término dos tratamentos</TitleCP>
        <CardCP>
            <TableCP
                columns={colunasExecucao}
                data={dados ?? []}
                loading={carregando}
            />
        </CardCP>
       <DrawerPersonDetailsCP
        visible={mostrarDetalhe}
        personCode={codigoCliente}
        onClose={()=>{setMostrarDetalhe(false)}}
        onSave={()=>{setMostrarDetalhe(false)}}
        segmentType={SegmentTypeEnum.CUSTOMER}
        ></DrawerPersonDetailsCP>
       </>
    )
}

const IconWrapper = styled.div`
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  
  svg {
    margin-left: 8px;
  }
`