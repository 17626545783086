import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FinancialCategoryCreditEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryCreditEnum'
import { FinancialCategoryDebitEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryDebitEnum'
import { IsOptional } from 'class-validator'

export class FinancialCategoryFormValidator extends GenericFormValidator {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    type: FinancialCategoryCreditEnum | FinancialCategoryDebitEnum

    @IsOptional()
    isDreRequired: boolean

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
