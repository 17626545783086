import { IRadioOptionProps, RadioGroupCPOLD } from 'common/old-refactory/radio-group/RadioGroupCPOLD'
import { SalesPerPeriodChartUtils } from 'main/modules/dashboard/components/franchisor-sales-per-period-chart/inner/SalesPerPeriodChartUtils'
import React from 'react'
import styled from 'styled-components'
import { SalesPerPeriodReportTypeEnum } from 'main/modules/dashboard/services/enums/SalesPerPeriodReportTypeEnum'

interface IChartViewTypeButtonCPProps {
    currentView: SalesPerPeriodReportTypeEnum
    onChangeView: (view: SalesPerPeriodReportTypeEnum) => void
    disabled?: boolean
}

/**
 * Botoes para mudar visão do grafico de faturamento
 */
export function ChartViewTypeButtonCP(props: IChartViewTypeButtonCPProps): JSX.Element {

    function getViewOptions(): IRadioOptionProps[] {

        return Object.keys(SalesPerPeriodReportTypeEnum).map(item => ({
            value: item,
            content: SalesPerPeriodChartUtils.getViewTypeOptionLabel(item as SalesPerPeriodReportTypeEnum)
        }))

    }

    return (
        <ContentSCP>
            <RadioGroupCPOLD
                onChange={props.onChangeView}
                size={'small'}
                type={'button'}
                selected={props.currentView}
                options={getViewOptions()}
            />
        </ContentSCP>
    )
}

const ContentSCP = styled.div`

`
