import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import React from 'react'
import { SaleChargesTableUtils } from 'main/modules/sale/components/table-sale-charges/inner/SaleChargesTableUtils'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'

interface ITableSaleChargesProps {
    charges: ISaleCharge[]
    onEdit?: (saleCharge: ISaleCharge, index: number) => void
    onDelete?: (rowIndex: number) => void
}

/**
 * Tabela das das cobrancas de uma venda.
 */
export function TableSaleCharges(props: ITableSaleChargesProps): JSX.Element {

    return (
        <TableCPOLD2
            bordered={true}
            data={props.charges}
            showHeader={false}
            emptyText={'Nenhuma forma de pagamento encontrada, clique no botão abaixo para adicionar.'}
            columns={SaleChargesTableUtils.getTableColumns(
                props.onEdit,
                props.onDelete
            )}
        />
    )
}
