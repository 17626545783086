import React, { useEffect } from 'react'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import * as _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { IBankBilletDownloadRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankBilletDownloadRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import FileSaver from 'file-saver'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'

interface IButtonMultipleBankBilletDownloadCPProps {
    charge: IChargeListItemResponseDTO
}

/**
 * Botao para download de multiplos boletos
 */
export function ButtonMultipleBankBilletDownloadCP(props: IButtonMultipleBankBilletDownloadCPProps): JSX.Element {

    const generateMultipleBankBilletsRequest = useRequest<Blob>('none')
    useEffect(onGenerateMultipleBankBilletsRequestChange, [generateMultipleBankBilletsRequest.isAwaiting])

    /**
     * Gera multiplos boletos bancarios
     */
    function generateBankBillets(): void {

        const allNonPaidBankBilletsFinancialTransactionsCodes = props.charge.financialTransactions
            .filter(fit => fit.status !== FinancialTransactionStatusEnum.PAID && fit.paymentMethod === PaymentMethodEnum.BANK_BILLET)
            .map(financialTransaction => financialTransaction.code)

        const dto: IBankBilletDownloadRequestDTO = {
            finacialTransactionCodes: allNonPaidBankBilletsFinancialTransactionsCodes
        }

        generateMultipleBankBilletsRequest.runRequest(FinancialTransactionsRequests.generateMultipleBankBillets(dto))
    }

    /**
     *  Retorno da requicao que pede para gerar varios boletos.
     */
    function onGenerateMultipleBankBilletsRequestChange(): void {

        if (generateMultipleBankBilletsRequest.isAwaiting || !generateMultipleBankBilletsRequest.wasTried)
            return

        if (!generateMultipleBankBilletsRequest.isSuccess || !generateMultipleBankBilletsRequest.responseData) {
            console.error('FALHA - ActionsColumn.onDownloadRequestChange: ', generateMultipleBankBilletsRequest.responseData, generateMultipleBankBilletsRequest.error)
            return RequestUtils.showDefaultErrorNotification(generateMultipleBankBilletsRequest.error, 'Não foi possível obter os boletos')

        }

        FileSaver.saveAs(generateMultipleBankBilletsRequest.responseData, `boletos_cobranca_${props.charge.code}.zip`)
    }

    if (_.isEmpty(props.charge.financialTransactions))
        return <></>

    return (
        <ButtonCP
            loading={generateMultipleBankBilletsRequest.isAwaiting}
            onClick={generateBankBillets}
            type={'ghost'}
            icon={'barcode'}
            tooltip={'Fazer download de todos os boletos'}
        />
    )
}
