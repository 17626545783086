import React, { useEffect, useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { DayOfMonthPickerCP } from 'main/common/components/form-fields/day-of-month-picker/DayOfMonthPickerCP'
import { QuickDateEnum } from 'main/common/enums/QuickDateEnum'
import { MonthPickerCP } from 'main/common/components/form-fields/month-picker/MonthPickerCP'
import { MonthEnum } from 'main/common/enums/MonthEnum'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { DayOfMonthTP } from 'main/common/types/DayOfMonthTP'
import * as _ from 'lodash'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { DateComboQuickDateButtonsICP } from 'main/common/components/form-fields/date-combo/inner/DateComboQuickDateButtonsICP'
import { DatePropsTP } from 'main/common/types/DatePropsTP'
import { DateComboCpUtils } from 'main/common/components/form-fields/date-combo/inner/DateComboCpUtils'
import { DateComboCpOutputTP } from 'main/common/components/form-fields/date-combo/inner/DateComboCpOutputTP'
import { DateUtils } from 'main/common/utils/date/DateUtils'

type InnerFieldTP = QuickDateEnum | 'day' | 'month' | 'year'

interface IDateComboCPProps {
    onChange: (isValid: boolean, date?: DateComboCpOutputTP) => void
    enableQuickDate?: boolean
    initialValue?: DateComboCpOutputTP
    isRequired?: boolean
    label?: string
}

/**
 * COMPONENTE
 * Definicao de datas com preenchimento manual de campos individuais & opcoes de 'filtros rapidos'.
 */
export function DateComboCP(props: IDateComboCPProps): JSX.Element {

    const [day, setDay] = useState<DayOfMonthTP>()
    const [month, setMonth] = useState<number>()
    const [year, setYear] = useState<number>()

    const [errMsg, setErrMsg] = useState<string>()
    const [isValid, setIsValid] = useState<boolean>(true)
    const [outputValue, setOutputValue] = useState<DateComboCpOutputTP>()

    useEffect(onInitialValueChange, [props.initialValue])
    useEffect(() => props.onChange(isValid, outputValue), [outputValue])

    function onInitialValueChange(): void {

        const isInvalidValue = (!!props.initialValue && !!DateComboCpUtils.getValidationError(props.initialValue, props.isRequired))
        if (isInvalidValue || _.isEqual(outputValue, props.initialValue))
            return

        setIsValid(!!props.initialValue)
        setOutputValue(props.initialValue)

        if (!props.initialValue || DateUtils.isQuickDateValue(props.initialValue)) {
            setDay(undefined)
            setMonth(undefined)
            setYear(undefined)
            return
        }

        const _initialValue = props.initialValue as DatePropsTP

        if (_initialValue.day)
            setDay(_initialValue.day)
        if (_initialValue.month)
            setMonth(_initialValue.month)
        if (_initialValue.year)
            setYear(_initialValue.year)
    }

    function onInnerChange(field: InnerFieldTP, value?: DayOfMonthTP | number | MonthEnum, shouldReportFailure = true): void {

        const [nextOutputValue, dateObj] = getParsedInnerValues(field, value)
        const _errMsg = DateComboCpUtils.getValidationError(nextOutputValue, props.isRequired)

        setIsValid(!_errMsg)
        setOutputValue(nextOutputValue)
        setDay(dateObj.day)
        setMonth(dateObj.month)
        setYear(dateObj.year)

        if (shouldReportFailure || !_errMsg)
            setErrMsg(_errMsg)
    }

    function getParsedInnerValues(field: InnerFieldTP, value?: DayOfMonthTP | number | MonthEnum): [DateComboCpOutputTP, DatePropsTP] {

        if (DateUtils.isQuickDateValue(field))
            return [field as QuickDateEnum, {}]

        // Atualiza data composta por campos individuais
        const nextDateObj: DatePropsTP = { day, month, year }

        switch (field) {
            case 'day':
                nextDateObj.day = value as DayOfMonthTP
                break
            case 'month':
                nextDateObj.month = value
                break
            case 'year':
                nextDateObj.year = value
                break
            // no default
        }

        return [nextDateObj, nextDateObj]
    }

    return (
        <WrapperSCP>
            <InputsWrapperSCP applyBigMargin={!props.enableQuickDate}>
                <DayOfMonthPickerCP
                    label={'Dia'}
                    width={70}
                    year={year}
                    month={month}
                    value={day}
                    onChange={_day => onInnerChange('day', _day as number)}
                    allowClear={true}
                />

                <MonthPickerCP
                    label={'Mês'}
                    width={70}
                    value={month}
                    onChange={_month => onInnerChange('month', _month as MonthEnum)}
                    exhibitionFormat={DateFormatEnum.MONTH_3}
                    allowClear={true}
                />

                <InputCP
                    label={'Ano'}
                    width={75}
                    type={'number'}
                    maxlength={4}
                    value={year}
                    onBlur={_year => onInnerChange('year', _year as number)}
                    onChange={_year => onInnerChange('year', _year as number, _year?.length >= 4)}
                />

                {
                    !!errMsg &&
                    <ErrosMsgSCP>{errMsg}</ErrosMsgSCP>
                }

                {
                    (!!props.label && !errMsg) &&
                    <LowerLabelSCP>{props.label}</LowerLabelSCP>
                }
            </InputsWrapperSCP>

            {
                !!props.enableQuickDate &&
                <DateComboQuickDateButtonsICP
                    onChange={onInnerChange}
                    value={DateUtils.isQuickDateValue(outputValue) ? (outputValue as QuickDateEnum) : undefined}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
`

const InputsWrapperSCP = styled.div<{ applyBigMargin: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    margin: ${props => props.applyBigMargin ? '2px 0px -10px 0' : '-10px 0px 3px 0'};
    position: relative;
`

const LowerLabelSCP = styled.div`
    position: absolute;
    bottom: 10px;
    left: 5px;
    font-size: ${ThemeAnt.fontSizes.small};
    color: ${props => props.theme.primaryColor};
`

const ErrosMsgSCP = styled.div`
    left: 0;
    right: 0;
    bottom: 4px;
    display: flex;
    line-height: 1;
    position: absolute;
    justify-content: center;
    color: ${props => props.theme.errorColor};
    font-size: ${ThemeAnt.fontSizes.small};
`
