import React from 'react'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import styled from 'styled-components'

interface ICProps {
    title: string
    value: number
    help?: string
    valueMask?: InputMaskTypeEnum.MONEY | string
}

export function CardWidgetFooterOnlyValueICP(props: ICProps): JSX.Element {

    return (
        <WrapperSCP>
            <FlexCP alignItems={'center'} justifyContent={'space-between'}>
                <FlexCP>
                    <TextCP text={props.title}/>
                    {
                        !!props.help &&
                        <HelpCP
                            showOnlyIcon={true}
                            marginTop={0}
                            marginBottom={0}
                            marginLeft={10}
                            text={props.help}
                            type={'tooltip'}
                        />
                    }
                </FlexCP>
                <TextCP
                    text={props.valueMask === InputMaskTypeEnum.MONEY ? MaskUtils.applyMoneyMask(props.value) : `${MaskUtils.applyNumberMask(props.value, 2)}${props.valueMask ?? ''}`}
                    size={'large'}
                />
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  border-top: solid 1px #F1F1F1;
  padding: 10px;
`
