import { Drawer } from 'antd'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React, { useEffect, useState } from 'react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DrawerFooterICP } from 'submodules/nerit-framework-ui/common/components/drawer/inner/DrawerFooterICP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'

type _AntPropsTP = {
    title?: string | JSX.Element,
    visible?: boolean,
    footer?: JSX.Element,
    onClose?: () => void,
    loading?: boolean,
    width?: number | string,
    height?: number | string,
    closable?: boolean,
    maskClosable?: boolean,
    placement?: 'left' | 'right' | 'bottom' | 'top',
    showMask?: boolean,
}

type _ScpPropsTP = {
    rightPadding?: number,
    titleUnderline?: boolean,
    footerSpaced?: boolean,
}

interface IDrawerCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> {}

/**
 * COMPONENTE: Drawer generico.
 */
export function DrawerCP(props: IDrawerCPProps): JSX.Element {

    const [showDrawer, setShowDrawer] = useState<boolean>()
    const screenSize = useScreenSize()

    useEffect(handleOpen, [props.visible])

    function handleOpen(): void {
        if (!!props.visible)
            IntercomUtils.setIntercomVisibility(false)
        setShowDrawer(!!props.visible)
    }

    function handleClose(): void {
        IntercomUtils.setIntercomVisibility(true)
        if (!!props.onClose)
            props.onClose()
        else
            setShowDrawer(false)
    }

    if (!props.visible)
        return <></>

    return (
        <WrapperSCP rightPadding={props.rightPadding}>
            <Drawer
                visible={showDrawer}
                placement={props.placement ?? 'right'}
                closable={props.closable ?? true}
                destroyOnClose={true}
                onClose={handleClose}
                maskClosable={props.maskClosable ?? props.closable ?? true}
                mask={props.showMask}
                zIndex={10}
                width={screenSize.smd ? '100%' : (props.width ?? 450)}
                title={props.title}
                height={props.height}
            >

                <ContentWrapperSCP>

                    <LoadingOverlayCP show={props.loading ?? false}/>

                    { props.children }
                </ContentWrapperSCP>

                {
                    props.footer &&
                    <DrawerFooterICP footerSpaced={props.footerSpaced}>
                        { props.footer }
                    </DrawerFooterICP>
                }
            </Drawer>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

    .ant-drawer-title {
         font-size: 20px;
    }

    .ant-drawer-content {
        height: 100vh;
    }
    .ant-drawer-wrapper-body {
        overflow: hidden !important;
        display: flex;
        flex-direction: column;
    }

    .ant-drawer-header {
        position: relative;
        color: rgba(1, 1, 2, 0.65);
        background: ${props => props.theme.white};
        border-bottom: none;
        border-radius: 4px 4px 0 0;
    }
    .ant-drawer-body {
        margin-right: ${props => (props.rightPadding ? props.rightPadding : 0)}px;
        overflow: hidden;
        height: 100%;
        padding: 0 24px;
    }
    .anticon{
        color: ${props => props.theme.black};
    }
`

const ContentWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`
