import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

/**
 * ICONE: Schema
 *
 * @author hjcostabr
 */
export function SchemaIconCP(props: IIconProps): JSX.Element {
    return (
        <IconICP
            iconName={'shop'}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
