import React from 'react'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'

export type OpportunityListFollowupViewModeTP = 'list' | 'kanban'

interface IOpportunityEvaluationScreenContentICPProps {
    view: SalesFunnelOpportunityViewEnum
    followupViewMode: OpportunityListFollowupViewModeTP
    onChangeFollowupViewMode: (followupViewMode: OpportunityListFollowupViewModeTP) => void
}

/**
 * Seletor da visao da oportunidade em tabela ou kanban
 */
export function RadioGroupFollowupViewCP(props: IOpportunityEvaluationScreenContentICPProps): JSX.Element {

    const viewsToShowKanban = [
        SalesFunnelOpportunityViewEnum.FOLLOWUP_TODAY,
        SalesFunnelOpportunityViewEnum.FOLLOWUP_DELAYED,
        SalesFunnelOpportunityViewEnum.FOLLOWUP_PLANNED,
        SalesFunnelOpportunityViewEnum.FOLLOWUP_WITHOUT_ACTIVITY,
        SalesFunnelOpportunityViewEnum.FOLLOWUP_ALL,
    ]

    if (!viewsToShowKanban.includes(props.view))
        return <></>

    return (
        <RadioGroupCP<OpportunityListFollowupViewModeTP>
            selected={props.followupViewMode}
            buttonHeight={32}
            buttonWidth={90}
            secondary={true}
            onChange={props.onChangeFollowupViewMode}
            type={'button'}
            options={[
                {
                    content: 'Pipeline',
                    value: 'kanban'
                }, {
                    content: 'Lista',
                    value: 'list'
                }
            ]}
        />
    )
}
