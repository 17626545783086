import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IMarketingBalanceResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingBalanceResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IAccountBalanceStatisticsCPProps {
    accountType: MarketingAccountTypeEnum
    totalToSend: number
    loading: boolean
    onBalanceCaught?: (balance: number) => void
}

/**
 * Widgets com cards de saldo
 *
 * @author renatofs
 * @author hjcostabr
 */
export function AccountBalanceStatisticsCP(props: IAccountBalanceStatisticsCPProps): JSX.Element {

    const [accountBalance, setAccountBalance] = useState<number>()

    const accountBalanceRequest = useRequest<IMarketingBalanceResponseDTO>()

    useEffect(onAccountBalanceRequestChange, [accountBalanceRequest.isAwaiting])
    useEffect(() => { getAccountBalanceConfig() }, [props.accountType])

    const isLoading = (accountBalanceRequest.isAwaiting || props.loading)

    function onAccountBalanceRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(accountBalanceRequest, 'Erro obter saldo da conta'))
            return

        const _accountBalance = accountBalanceRequest.responseData?.balance ?? 0
        setAccountBalance(_accountBalance)

        if (!!props.onBalanceCaught)
            props.onBalanceCaught(_accountBalance)
    }

    async function getAccountBalanceConfig(): Promise<void> {
        accountBalanceRequest.runRequest(await MarketingRequests.getAccountBalance(props.accountType))
    }

    function renderInfo(): JSX.Element {
        return (
            <>
                <RowCP align={'middle'}>
                    <ColumnCP size={6} centerContent={true}>
                        <TextCP
                            text={MaskUtils.applyNumberMask(accountBalance ?? 0, 0)}
                            size={'large'}
                        />
                    </ColumnCP>

                    <ColumnCP size={2} centerContent={true}>
                        <TextCP text={'-'} size={'extraLarge'}/>
                    </ColumnCP>

                    <ColumnCP size={6} centerContent={true}>
                        <TextCP
                            text={MaskUtils.applyNumberMask(props.totalToSend, 0)}
                            size={'large'}
                        />
                    </ColumnCP>

                    <ColumnCP size={2} centerContent={true}>
                        <TextCP text={'='} size={'large'}/>
                    </ColumnCP>

                    <ColumnCP size={8} centerContent={true}>
                        <TextCP
                            text={!!accountBalance ? MaskUtils.applyNumberMask((accountBalance - props.totalToSend), 0) : ' ... '}
                            strong={true}
                            size={'large'}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP>
                    <ColumnCP size={8}>
                        <TextCP text={'atual'} size={'small'}/>
                    </ColumnCP>

                    <ColumnCP size={8}>
                        <TextCP text={'pessoas'} size={'small'}/>
                    </ColumnCP>

                    <ColumnCP size={8}>
                        <TextCP text={'após envio'} size={'small'}/>
                    </ColumnCP>
                </RowCP>
            </>
        )
    }

    return (
        <WrapperSCP>
            <LoadingOverlayCP show={isLoading}/>

            {
                !isLoading && renderInfo()
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    line-height: 1.2;
`
