import React, { useEffect, useState } from 'react'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { FileResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/FileResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { FileDeleteRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/FileDeleteRequestDTO'
import PhotoViewer from 'photoviewer'
import "photoviewer/dist/photoviewer.min.css"
import { ModalPdfViewerCP } from 'submodules/nerit-framework-ui/common/components/file-preview/modal-pdf-viewer/ModalPdfViewerCP'

interface ICPProps {
    entityCode: number
    file: FileDataTP
    onFileDeleted: () => void
    requestsConfig: {
        deleteRequestConfigTP?: (dto: any) => RequestConfigTP
    }
    permissions?: {
        canRemove: boolean
    }
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ListItemFileICP(props: ICPProps): JSX.Element {

    const [isPdfModalVisible, setIsPdfModalVisible] = useState<boolean>(false)

    const deleteFileRequest = useRequest<void>('none')
    useEffect(onDeleteFileRequestChange, [deleteFileRequest.isAwaiting])

    /**
     */
    function onShowFile(): void {

        if (props.file.fileName.endsWith('pdf')) {
            setIsPdfModalVisible(true)
            return
        }

        new PhotoViewer(
            [{ src: props.file.fileUrl, title: props.file.fileName }],
            {
                progressiveLoading: true,
                multiInstances: false,
            }
        )
    }


    /**
     * Exclui um arquivo.
     */
    function deleteFile(file: FileResponseDTO): void {

        if (!props.requestsConfig.deleteRequestConfigTP)
            return

        // Se tiver codigo, deleta pelo codigo
        if (!!file.fileCode) {
            deleteFileRequest.runRequest(props.requestsConfig.deleteRequestConfigTP(file.fileCode))
            return
        }

        const dto: FileDeleteRequestDTO = {
            code: props.entityCode,
            fileUrl: file.fileUrl
        }
        deleteFileRequest.runRequest(props.requestsConfig.deleteRequestConfigTP(dto))
    }

    /**
     * Retorno do upload de arquivos.
     */
    function onDeleteFileRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteFileRequest, 'Ocorreu algum erro ao fazer o remover do arquivo', 'Arquivo removido com sucesso', true))
            return

        props.onFileDeleted()
    }

    return (
        <>
            <FlexCP justifyContent={'space-between'} alignItems={'center'} margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>
                <FlexCP
                    alignItems={'center'}
                    onClick={onShowFile}
                >
                    <IconICP iconName={'file'} style={{ marginRight: '10px' }} size={24}/>
                    <div>
                        <TextCP text={StringUtils.limitString(props.file.fileName, 25)}/>
                        <TextCP
                            text={`enviado em ${DateUtils.getFormatted(props.file.creationDate, DateFormatEnum.BR_WITHOUT_TIME)} por ${StringUtils.getFirstName(props.file.userCreator.name)}`}
                            marginRight={10}
                            fontStyle={'italic'}
                            size={'extraSmall'}
                            color={'#adadad'}
                        />
                    </div>
                </FlexCP>

                <div>
                    <ButtonCP
                        size={'small'}
                        type={'primary'}
                        marginLeft={5}
                        icon={'eye'}
                        onClick={onShowFile}
                    />

                    <ButtonCP
                        size={'small'}
                        marginTop={5}
                        marginLeft={5}
                        icon={'download'}
                        onClick={() => window.open(props.file.fileUrl, '_blank')}
                    />
                    {
                        (!props.permissions || props.permissions?.canRemove) && !!props.requestsConfig.deleteRequestConfigTP &&
                        <ButtonCP
                            size={'small'}
                            marginLeft={5}
                            icon={'delete'}
                            type={'danger'}
                            onClick={() => deleteFile(props.file)}
                            loading={deleteFileRequest.isAwaiting}
                            marginTop={5}
                            confirmMsg={'Tem certeza que deseja remover esse arquivo?'}
                        />
                    }
                </div>
            </FlexCP>

            <ModalPdfViewerCP
                visible={isPdfModalVisible}
                fileName={props.file.fileName}
                fileSrc={props.file.fileUrl}
                onClose={() => setIsPdfModalVisible(false)}
            />
        </>
    )
}
