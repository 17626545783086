import { CardCP } from 'main/common/components/card/CardCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { SenderTableCP } from 'main/modules/marketing/components/sender-table/SenderTableCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'

/**
 * COMPONENTE para exibicao dos remetentes cadastrados.
 */
export function ScreenContentMktSendersCP(): JSX.Element | null {

    const [isSenderModalVisible, setIsSenderModalVisible] = useState<boolean>(false)

    return (
        <>
            <HeaderCP
                title={'Remetendes de E-mail'}
            >
                <HeaderButtonICP
                    icon={'plus'}
                    label={'Novo remetente'}
                    onClick={() => setIsSenderModalVisible(true)}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <CardCP dataTour={'mkt-remetentes'}>
                    <SenderTableCP
                        isSenderModalVisible={isSenderModalVisible}
                        onChangeModalVisible={(visible) => setIsSenderModalVisible(visible)}
                    />
                </CardCP>
            </LayoutSubmenuContentCP>
        </>
    )
}
