import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { SelectRecurrenceCP } from 'main/modules/financial/components/select-recurrence/SelectRecurrenceCP'
import { FinancialTransactionGroupFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionGroupFormModel'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FinancialTransactionGroupTypeEnum } from 'main/modules/financial/services/financial-transactions/enums/FinancialTransactionGroupTypeEnum'
import { SelectRecurrenceTypeCP } from 'main/modules/financial/components/select-recurrence-type/SelectRecurrenceTypeCP'

interface IExpenseRecurrencyFormICPProps {
    formStateManager: IFormStateManager<FinancialTransactionGroupFormModel>
    canEditValues: boolean
}

/**
 * Dados de formulario para marcacao de recorrencia
 */
export function FinancialTransactionRecurrencyFormICP(props: IExpenseRecurrencyFormICPProps): JSX.Element {

    return (
        <CardCP
            title={
                <SwitchCP
                    textOutside={'É recorrente / parcelado?'}
                    textInsideChecked={'Sim'}
                    formStateManager={props.formStateManager}
                    fieldName={'isRecurrent'}
                    isTextInside={false}
                    marginLeft={20}
                    marginRight={10}
                    disabled={!props.canEditValues}
                />
            }
            paddingTitle={'0px'}
            margin={'vertical'}
            innerSpacing={props.formStateManager.getFieldValue('isRecurrent') ? undefined : 'none'}
        >
            {
                props.formStateManager.getFieldValue('isRecurrent') &&
                <RowCP>
                    <ColumnCP size={10}>
                        <SelectRecurrenceTypeCP
                            formStateManager={props.formStateManager}
                            fieldName={'type'}
                            label={'Tipo:'}
                            required={true}
                            disabled={!props.canEditValues}
                        />
                    </ColumnCP>
                    <ConditionalRenderCP shouldRender={props.formStateManager.getFieldValue('type') === FinancialTransactionGroupTypeEnum.INSTALLMENT}>
                        <ColumnCP size={8}>
                            <InputCP
                                formStateManager={props.formStateManager}
                                fieldName={'totalInstallments'}
                                type={'number'}
                                label={'Nº de parcelas:'}
                                min={1}
                                required={true}
                                disabled={!props.canEditValues}
                            />
                        </ColumnCP>
                    </ConditionalRenderCP>
                    <ConditionalRenderCP shouldRender={props.formStateManager.getFieldValue('type') === FinancialTransactionGroupTypeEnum.RECURRENT}>
                        <ColumnCP size={10}>
                            <SelectRecurrenceCP
                                formStateManager={props.formStateManager}
                                fieldName={'recurrencyPeriod'}
                                label={'Repete-se:'}
                                required={true}
                                disabled={!props.canEditValues}
                            />
                        </ColumnCP>
                    </ConditionalRenderCP>
                </RowCP>
            }
        </CardCP>
    )
}
