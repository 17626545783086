import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import React from 'react'
import styled from 'styled-components'

interface IActionsColumnICPProps {
    onEdit: () => void
    onRemove: () => void
}

/**
 * COMPONENTE
 * Coluna de acoes em 01 item da tabela de listagem de remetentes de e-mail.
 */
export function ActionsColumnICP(props: IActionsColumnICPProps): JSX.Element {
    return (
        <ButtonSpacingSCP>
            <TooltipCP text={'Editar'}>
                <ButtonCP
                    size={'small'}
                    type={'primary'}
                    onClick={props.onEdit}
                    icon={'edit'}
                />
            </TooltipCP>

            <PopConfirmCP
                title={'Deseja realmente excluir essa conta?'}
                onConfirm={props.onRemove}
            >
                <ButtonCP
                    size={'small'}
                    type={'danger'}
                    icon={'delete'}
                />
            </PopConfirmCP>
        </ButtonSpacingSCP>
    )
}

const ButtonSpacingSCP = styled.div`

    margin: 0 3px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 2px;
    }
`
