import React from 'react'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { TextCP } from 'main/common/components/text/TextCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface ICPProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Area para impressao
 */
export const PrintAreaCP = React.forwardRef((props: ICPProps, ref: any) => {

    return (
        <div ref={ref}>
            <HeaderSCP>
                <FlexCP justifyContent={'space-between'}>
                    <LogoCP
                        marginTop={0}
                        marginBottom={0}
                        imageUrl={AppStateUtils.getDomainData()!.schema.logoUrl}
                    />
                    <div>
                        <TextCP text={AppStateUtils.getCurrentFranchise()!.fantasyName} strong={true}/>
                        {
                            AppStateUtils.getCurrentFranchise()!.address &&
                            <>
                                <TextCP text={`${AppStateUtils.getCurrentFranchise()!.address?.city ?? ''} / ${AppStateUtils.getCurrentFranchise()!.address?.state ?? ''}`}/>
                                <TextCP
                                    wrap={true}
                                    text={`${AppStateUtils.getCurrentFranchise()!.address?.street ?? ''}, ${AppStateUtils.getCurrentFranchise()!.address?.number ?? ''} - ${AppStateUtils.getCurrentFranchise()!.address?.complement ?? ''}`}
                                />
                            </>
                        }
                        <TextCP text={`Impresso em ${DateUtils.getFormatted(new Date(), DateFormatEnum.BR_WITH_TIME_H_M)}`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                    </div>
                </FlexCP>
            </HeaderSCP>

            { props.children }
        </div>
    )
})

const HeaderSCP = styled.div`
  margin: 30px 40px 60px 40px;
  @media screen {
    display: none;
  }
`

