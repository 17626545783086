import React from 'react'
import { ISearchProductReleaseRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'
import { ColumnFilterTP } from 'common/old-refactory/table/TableCPOLD'
import { ColumnFilterTypeEnum } from 'common/old-refactory/table/inner/ColumnFilterTypesEnum'

interface IProductReleaseTableFilters {
    setFilters: React.Dispatch<React.SetStateAction<ISearchProductReleaseRequestDTO>>
    filters: ISearchProductReleaseRequestDTO
}

export function ProductReleaseFranchiseTableFilters(props: IProductReleaseTableFilters): ColumnFilterTP[] {
    return [
        {
            type: ColumnFilterTypeEnum.SEARCH,
            dataIndex: 'name',
            colKey: 'name',
            onSearch: (text: string) => props.setFilters({ ...props.filters, page: 1, searchString: text }),
            onReset: () => props.setFilters({ ...props.filters, page: 1, treatment: '' }),
            placeholder: 'Filtrar pacotes'
        },
        {
            type: ColumnFilterTypeEnum.SEARCH,
            dataIndex: 'service',
            colKey: 'service',
            onSearch: (text: string) => props.setFilters({ ...props.filters, page: 1, treatment: text }),
            onReset: () => props.setFilters({ ...props.filters, page: 1, treatment: '' }),
            placeholder: 'Filtrar Atendimentos',
        }
    ]
}
