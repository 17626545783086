import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'
import { CheckboxCP } from 'main/common/components/checkbox/CheckboxCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { PayMultipleFinancialTransactionFormModel } from 'main/modules/financial/components/modal-pay-multiple-financial-transaction/inner/PayMultipleFinancialTransactionFormModel'
import styled from 'styled-components'
import { IFinancialTransactionMultiplePaymentRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionMultiplePaymentRequestDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'

interface IModalPayFinancialTransactionCPProps {
    visible: boolean
    financialTransactionCodes: number[]
    onClose: () => void
    onSave: () => void
}

/**
 * Componente de modal com dados para pagamento de multiplas transacoes financeiras.
 */
export function ModalPayMultipleFinancialTransactionCP(props: IModalPayFinancialTransactionCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<PayMultipleFinancialTransactionFormModel>(new PayMultipleFinancialTransactionFormModel())
    const formStateManager = useFormStateManager<PayMultipleFinancialTransactionFormModel>(formValidator)

    const payRequest = useRequest<void>('none')
    useEffect(onPayRequestChange, [payRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        setFormValidator(new PayMultipleFinancialTransactionFormModel({
            onExpirationDate: true,
            sameBankAccount: true,
            samePaymentMethod: true,
            paidDate: new Date(),
        }))
    }

    /**
     * Paga a transacation Financeira.
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (!formValues.onExpirationDate && !formValues.paidDate)
            return NotificationHelper.error('Ops!', 'Preencha a data de pagamento')

        if (!formValues.samePaymentMethod && !formValues.paymentMethod)
            return NotificationHelper.error('Ops!', 'Preencha a forma de pagamento')

        if (!formValues.sameBankAccount && !formValues.bankAccountCode)
            return NotificationHelper.error('Ops!', 'Preencha a data de pagamento')

        if (SystemUtils.isEmpty(props.financialTransactionCodes))
            return NotificationHelper.error('Ops!', 'Nenhuma fatura selecionada para pagamento')

        const dto: IFinancialTransactionMultiplePaymentRequestDTO = {
            financialTransactionCodes: props.financialTransactionCodes,
            paymentDate: formValues.onExpirationDate ? undefined : DateUtils.getFormatted(formValues.paidDate, DateFormatEnum.US_WITHOUT_TIME),
            paymentMethod: formValues.samePaymentMethod ? undefined : formValues.paymentMethod,
            bankAccountCode: formValues.sameBankAccount ? undefined : formValues.bankAccountCode,
        }
        payRequest.runRequest(FinancialTransactionsRequests.payMultiple(dto))
    }

    /**
     * Retorno da API ao salvar pagar.
     */
    function onPayRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(payRequest, 'Erro ao pagar faturas', true))
            return

        NotificationHelper.success('Yes!', 'Faturas pagas com sucesso!')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Pagar Faturas'}
            width={340}
            top={10}
            visible={props.visible}
            onCancel={props.onClose}
            onOk={onSave}
            okText={'Pagar'}
            actionLoading={payRequest.isAwaiting}
        >
            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('onExpirationDate', checked)}
                    isChecked={formStateManager.getFieldValue('onExpirationDate')}
                >
                    <>No dia de vencimento</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={!formStateManager.getFieldValue('onExpirationDate')}>
                    <FieldFormSCP>
                        <InputCP
                            label={'Dt. de Pagamento'}
                            required={true}
                            type={'date'}
                            formStateManager={formStateManager}
                            fieldName={'paidDate'}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>

            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('samePaymentMethod', checked)}
                    isChecked={formStateManager.getFieldValue('samePaymentMethod')}
                >
                    <>Com a forma de pagamento original</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={!formStateManager.getFieldValue('samePaymentMethod')}>
                    <FieldFormSCP>
                        <SelectPaymentMethodCP
                            label={'Forma de pagamento'}
                            formStateManager={formStateManager}
                            fieldName={'paymentMethod'}
                            required={true}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>

            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('sameBankAccount', checked)}
                    isChecked={formStateManager.getFieldValue('sameBankAccount')}
                >
                    <>Na mesma conta bancária</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={!formStateManager.getFieldValue('sameBankAccount')}>
                    <FieldFormSCP>
                        <AutocompleteBankAccountCP
                            formStateManager={formStateManager}
                            label={'Conta Bancária'}
                            fieldName={'bankAccountCode'}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>
        </ModalCP>
    )
}

const FieldSCP = styled.div`
  margin-bottom: 10px;
`

const FieldFormSCP = styled.div`
  margin-bottom: 30px;
  margin-left: 20px;
`
