import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChartLineOrBarCP, ChartSeriesTP } from 'main/common/components/chart/ChartLineOrBarCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import moment from 'moment'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { SalesPerPeriodChartUtils } from 'main/modules/dashboard/components/franchisor-sales-per-period-chart/inner/SalesPerPeriodChartUtils'
import { ISalesPerPeriodReportFilterRequestDTO } from 'main/modules/dashboard/services/dtos/request/ISalesPerPeriodReportFilterRequestDTO'
import { ChartViewTypeButtonCP } from 'main/modules/dashboard/components/franchisor-sales-per-period-chart/inner/ChartViewTypeButtonCP'
import { ISalesPerPeriodReportListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ISalesPerPeriodReportListItemResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SalesPerPeriodReportTypeEnum } from 'main/modules/dashboard/services/enums/SalesPerPeriodReportTypeEnum'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface IFranchisorSalesPerPeriodChartCPProps {
    date: Date
}

/**
 * COMPONENTE
 * Card contentdo o gráfico de faturamento
 */
export function FranchisorSalesPerPeriodChartCP(props: IFranchisorSalesPerPeriodChartCPProps): JSX.Element {

    const [reportType, setReport] = useState<SalesPerPeriodReportTypeEnum>(SalesPerPeriodReportTypeEnum.TOTAL)
    const [dateRange, setDateRange] = useState<IDateRangeFilter>()
    useEffect(init, [props.date, reportType])

    const [graphSeries, setGraphSeries] = useState<ChartSeriesTP[]>([])
    const [graphXAxis, setGraphXAxis] = useState<string[]>([])

    const salesPerPeriodRequest = useRequest<IGenericListResponseDTO<ISalesPerPeriodReportListItemResponseDTO>>()
    useEffect(onBillingPerPeriodRequestChange, [salesPerPeriodRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {

        // Intervalo ser de 12 meses aonteriores a data selecionada
        const beginDate = moment(props.date).startOf('month').add(-12, 'month')
        const _dateRange: IDateRangeFilter = {
            beginDate: beginDate.toDate(),
            endDate: moment(props.date).endOf('month').toDate(),
        }
        setDateRange(_dateRange)

        // Chama API
        const filtersDto: ISalesPerPeriodReportFilterRequestDTO = {
            type: reportType,
            timeBase: TimeBaseEnum.MONTH,
            beginDate: _dateRange.beginDate!,
            endDate: _dateRange.endDate!,
        }

        salesPerPeriodRequest.runRequest(DashboardRequests.salesPerPeriodConfig(filtersDto))
    }

    /**
     * Retorno da API com os dados
     */
    function onBillingPerPeriodRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(salesPerPeriodRequest, 'Erro obter dados do gráfico de vendas'))
            return

        const salePerPeriodResult = salesPerPeriodRequest.responseData!.list

        // Monta eixo X iterando sobre o intervalo de datas selecionado
        const xAxisDates = SalesPerPeriodChartUtils.getXAxisDates(dateRange!, TimeBaseEnum.MONTH)
        const dateFormat = DateFormatEnum.BR_MONTH3_YEAR4
        setGraphXAxis(xAxisDates.map((date) => date.format(dateFormat)))

        setGraphSeries([{
            name: 'Total vendido por mês',
            data: SalesPerPeriodChartUtils.getYAxisCounts(xAxisDates, salePerPeriodResult)
        }])
    }

    return (
        <WrapperSCP>
            <CardCP
                innerSpacing={'none'}
                title={
                    <FlexCP justifyContent={'space-between'}>
                        <TextCP text={SalesPerPeriodChartUtils.getChartLabel(reportType)}/>
                        <ChartViewTypeButtonCP
                            currentView={reportType}
                            onChangeView={setReport}
                        />
                    </FlexCP>
                }
                isLoading={salesPerPeriodRequest.isAwaiting}
            >
                <ChartLineOrBarCP
                    xAxis={graphXAxis}
                    ySeries={graphSeries}
                    type={'bar'}
                    height={'250px'}
                />
            </CardCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
    margin: 10px;   
`
