import React from 'react'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { TextCP } from 'main/common/components/text/TextCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { EditableTextCP } from 'main/common/components/editable-text/EditableTextCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IHeaderTitleICPProps {
    segment: ISegmentListItemResponseDTO
    showSaveButton: boolean
    enableNameChange: boolean
    onSegmentNameChange: (nextName: string) => void
    onSaveSegmentEdition?: () => void
}

/**
 * COMPONENTE
 * Titulo para cabecalho da tela de listagem de pessoas.
 */
export const PersonListScreenHeaderTitleICP = ComponentUtils.memoizeCp((props: IHeaderTitleICPProps): JSX.Element => {

    function onSegmentNameEditionEnd(nextName: string): void {
        if (nextName !== props.segment.name)
            props.onSegmentNameChange(nextName)
    }

    return (
        <WrapperSCP>
            {
                <EditableTextCP
                    color={ThemeProject.headerFontColor}
                    onEditionEnd={onSegmentNameEditionEnd}
                    size={'extraLarge'}
                    disabled={!props.enableNameChange}
                >
                    {props.segment.name}
                </EditableTextCP>
            }

            {
                props.showSaveButton &&
                <ButtonCP
                    onClick={props.onSaveSegmentEdition}
                    type={'link'}
                    size={'small'}
                    tooltip={'Salvar alterações'}
                >
                    <TextCP text={'Salvar'} size={'extraSmall'} color={ThemeAnt.tertiaryColor}/>
                </ButtonCP>
            }
        </WrapperSCP>
    )
})

const WrapperSCP = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
`
