import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DrawerReportCommonFiltersCP } from 'main/modules/reports/components/reports-common-filters/DrawerReportCommonFiltersCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { TableDreCP } from 'main/modules/financial/components/table-dre/TableDreCP'
import moment from 'moment'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 * Relatorio financeiro, por enquanto so o DRE.
 */
export function ScreenContentFinancialReportCP(): JSX.Element {

    const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(false)

    const [formValidator, setFormValidator] = useState<ReportsCommonFiltersFormModel>((new ReportsCommonFiltersFormModel()))
    const formStateManager = useFormStateManager<ReportsCommonFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<number>()

    useEffect(() => { init() }, [])

    /**
     * Inicializa definindo os filtros iniciais.
     */
    async function init(): Promise<void> {

        setFormValidator(new ReportsCommonFiltersFormModel({
            dateRange: {
                beginDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate()
            },
            franchisesCodes: AppStateUtils.getCurrentFranchise()!.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code]
        }))

        // Necessario para o sincronismo entre setar os filtros default e chamar a query com os filtros setadas
        await SystemUtils.sleep(10)
        setShouldLoadReport(TableUtils.getReloadNumber())
    }

    return (
        <>
            <HeaderCP title={'DRE'}>
                <HeaderButtonICP
                    onClick={() => setIsFilterDrawerVisible(!isFilterDrawerVisible)}
                    icon={'filter'}
                    label={'Filtros'}
                />
            </HeaderCP>

            <ContentWrapperSCP>
                <ContentSCP>
                    <TableDreCP
                        filterStateManager={formStateManager}
                        shouldReloadReport={shouldLoadReport}
                    />
                </ContentSCP>
            </ContentWrapperSCP>

            <DrawerReportCommonFiltersCP
                formStateManager={formStateManager}
                fieldsConfig={{
                    showConsolidatedFilter: true
                }}
                visible={isFilterDrawerVisible}
                onClearFilters={() => setFormValidator(new ReportsCommonFiltersFormModel({}))}
                onFilter={() => setShouldLoadReport(TableUtils.getReloadNumber())}
                onClose={() => setIsFilterDrawerVisible(false)}
            />
        </>
    )
}

const ContentSCP = styled.div`
  width: 100%;
`

const ContentWrapperSCP = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: center;
`
