import { SelectCurrentFranchiseCP } from 'main/modules/auth/components/select-current-franchise/SelectCurrentFranchiseCP'
import { MenuLogoCP } from 'main/common/components/menu/menu-logo/MenuLogoCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import React from 'react'
import { OmniButtonCP } from 'main/modules/app/components/omni-button/OmniButtonCP'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { PersonRoutes } from 'main/modules/people/routes/PersonRoutes'
import { UserMenuUtils } from 'main/modules/user/components/user-main-menu/inner/UserMenuUtils'
import { MenuUserMainCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-user-main/MenuUserMainCP'
import { MenuDividerICP } from 'submodules/nerit-framework-ui/common/components/menu/menu-user-main/inner/MenuDividerICP'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { ProductAnnounceCP } from 'main/modules/app/components/product-announce/ProductAnnounceCP'

interface ICPProps {
    onChangeCurrentFranchise: () => void
}

/**
 * Menu lateral do sistema.
 */
export function UserMainMenuCP(props: ICPProps): JSX.Element {
   
    return (
        <MenuUserMainCP
            menuTop={{
                topContent: (
                    <>
                        <MenuLogoCP/>
                        <SelectCurrentFranchiseCP onChangeCurrentFranchise={props.onChangeCurrentFranchise}/>
                    </>
                ),
                options: UserMenuUtils.getMenuOptionsTop(),
            }}
            menuBottom={{
                options: UserMenuUtils.getMenuOptionsBottom(),
                bottomContent: (
                    <>
                    
                        <OmniButtonCP/>
                        <ProductAnnounceCP valor={props} />
                        <MenuDividerICP/>
                        <ProfileMenuItemCP
                            profileImage={AppStateUtils.getLoggedUserData()?.user.photoUrl}
                            optionTitle={AppStateUtils.getLoggedUserData()?.user.name ?? 'Usuário'}
                            profileOptions={[
                                {
                                    text: 'Editar Perfil',
                                    action: (): void => RoutingHelper.historyPush(PersonRoutes.PROFILE)
                                },
                                {
                                    text: 'Sair',
                                    action: (): void => {
                                        AuthActions.logout()
                                    }
                                }
                            ]}
                        />
                    </>
                )
            }}
        />
    )
}
