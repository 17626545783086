import React, { useEffect, useState } from 'react'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { TagRequests } from 'main/modules/tags/services/TagRequests'
import { OrArrayTP } from 'main/common/types/OrArrayTP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface ITagAutocompletePickerCPProps<FModelTP extends FormModel = any>
    extends Omit<IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP>, 'label'> {}

/**
 * COMPONENTE
 * Seletor de tags com autocomplete.
 */
export function TagAutocompletePickerCP<FModelTP extends FormModel = any>(props: ITagAutocompletePickerCPProps<FModelTP>): JSX.Element {

    const [initialOptions, setInitialOptions] = useState<INameAndCodeResponseDTO[]>()
    const [value, setValue] = useState<OrArrayTP<number>>()
    const [shouldClearSearchStr, setShouldClearSearchStr] = useState<boolean>(false)

    useEffect(() => setInitialOptions(props.initialOptions), [props.initialOptions])
    useEffect(() => setValue(props.value), [props.value])

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            label={'Tags'}
            requestConfigGetter={TagRequests.search}
            initialOptions={initialOptions}
            returnFullOption={props.returnFullOption}
            onChange={props.onChange}
            isMultiple={props.isMultiple}
            value={value}
            searchItems={props.searchItems}
            searchPage={props.searchPage}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            onFormSubmit={props.onFormSubmit}
            fontSize={props.fontSize}
            disabled={props.disabled}
            mustClearSearch={shouldClearSearchStr}
            onSearchCleared={() => setShouldClearSearchStr(false)}
        />
    )
}
