/* eslint-disable */
import { Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { TreeItemTP } from 'main/common/components/tree/inner/TreeItemTP'

export type TreeEventPropsTP = {
    itemKey: string,
    isSelected: boolean,
    itemPosition?: string, // String representando posicao. e.g: 0-1-2 (3o filho do 2o filho do 1o filho)
}

interface ITreeCPProps {
    items: TreeItemTP[]
    isLoading?: boolean
    onSelect?: (selectedKeys: string[], eventProps: TreeEventPropsTP) => void
    onItemClick?: (eventProps: TreeEventPropsTP) => void
    onExpansionChange?: (isExpanding: boolean, expandedKeys: string[], eventProps: TreeEventPropsTP) => void
    onChange?: (items: TreeItemTP[]) => void
    defaultExpandedKeys?: string[]
}

/**
 * TODO: 2021-07-05 - ADD Descricao
 */
export function TreeCP(props: ITreeCPProps): JSX.Element {

    const [treeItens, setTreeItens] = useState<TreeItemTP[]>(props.items)
    useEffect(() => setTreeItens(props.items), [props.items])

    /**
     * Renderiza um item da arvore.
     */
    function renderItem(item: TreeItemTP): JSX.Element {

        return (
            <Tree.TreeNode
                key={item.key}
                title={item.title}
                isLeaf={!item.children?.length}
                selectable={item.selectable}
            >
                {item.children?.map(renderItem)}
            </Tree.TreeNode>
        )
    }

    function onDrop(info: any) {
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data: any, key: any, callback: any) => {
            data.forEach((item: any, index: any, arr: any) => {
                if (item.key === key) {
                    return callback(item, index, arr);
                }
                if (item.children) {
                    return loop(item.children, key, callback);
                }
            });
        };
        const data = [...treeItens];

        // Find dragObject
        let dragObj: any;
        loop(data, dragKey, (item: any, index: any, arr: any) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                item.children.push(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar: any;
            let i: any;
            loop(data, dropKey, (item: any, index: any, arr: any) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar!.splice(i, 0, dragObj);
            } else {
                ar!.splice(i + 1, 0, dragObj);
            }
        }

        setTreeItens(data)
        props.onChange?.(data)
    }

    if (props.isLoading)
        return <LoadingCP show={true}/>

    if (ArrayUtils.isEmpty(treeItens))
        return <EmptyCP/>

    return (
        <Tree.DirectoryTree
            treeData={treeItens}
            showLine={false}
            showIcon={false}
            defaultExpandedKeys={props.defaultExpandedKeys}
            draggable={true}
            blockNode={true}
            onDrop={onDrop}
            selectable={false}
        >
            {props.items.map(renderItem)}
        </Tree.DirectoryTree>
    )
}
