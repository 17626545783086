import React from 'react'
import styled from 'styled-components'

import { CheckboxGroupCP } from 'main/common/components/checkbox/CheckboxGroupCP'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'

export interface IMultiCheckFilterDropdownICPProps {
    options: CheckboxOptionType[]
    onChange: (value: string) => void
    value: any[] | undefined
}

/**
 * Dropdown com filtro estilo checkbox group para colunas de uma tabela
 * @author kiara.vz
 */
export function MultiCheckFilterDropdownICP(props: IMultiCheckFilterDropdownICPProps): JSX.Element {

    return (
        <FilterDropdownWrapperSCP>
            <CheckboxGroupCP
                value={props.value}
                options={props.options}
                onChange={props.onChange}
            />
        </FilterDropdownWrapperSCP>
    )
}

const FilterDropdownWrapperSCP = styled.div`
    padding: 20px;
    max-width: 280px;
  
  
`

