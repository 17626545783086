import _ from 'lodash'
import {
    PersonContactStatusEnum,
    PersonContactStatusLabelEnum
} from 'main/modules/people/services/person-contact/enums/PersonContactStatusEnum'
import moment from 'moment'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'

const NOW = moment()
const TODAY = NOW.clone().startOf('day')

export const PersonContactUtils = {

    getActivityStatusColor(activity: IPersonContactResponseDTO): string {

        if (activity.status === PersonContactStatusEnum.COMPLETED)
            return '#52a999'

        if (moment(activity.endDate).isBefore(NOW))
            return '#e48150'

        if (NOW.isBetween(activity.beginDate, activity.endDate) || moment(activity.beginDate).isAfter(NOW))
            return '#52a999'

        return '#c3c3c3'

    },

    getActivityStatusName(activity: IPersonContactResponseDTO): string {

        if (activity.status === PersonContactStatusEnum.COMPLETED)
            return PersonContactStatusLabelEnum.COMPLETED

        if (moment(activity.endDate).isAfter(NOW))
            return PersonContactStatusLabelEnum.IN_PROGRESS

        return 'Atrasado'
    },

    groupActivitiesByStatus(activities: IPersonContactResponseDTO[]): any {
        return (
            _.groupBy(activities, item => {
                if (moment(item.endDate).isBefore(NOW) && item.status === PersonContactStatusEnum.IN_PROGRESS)
                    return 'ATRASADO'

                if (item.status === PersonContactStatusEnum.COMPLETED)
                    return 'CONCLUÍDA'

                if (NOW.isBetween(item.beginDate, item.endDate))
                    return 'EM ANDAMENTO'

                if (moment(item.beginDate).isSame(TODAY, 'd'))
                    return 'HOJE'

                if (moment(item.beginDate).isAfter(NOW))
                    return 'PLANEJADO'

                return `${moment(item.endDate).format('DD/MM')}`
            })
        )
    },

}
