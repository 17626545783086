import React from 'react'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 * Logo no cabeçalho do menu.
 */
export function MenuLogoCP(): JSX.Element {

    return (
        <MenuLogoSCP>
            <img
                height={40}
                src={ThemeProject.logoMenuUrl}
                alt={'Lumia'}
            />
        </MenuLogoSCP>
    )
}

const MenuLogoSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
`
