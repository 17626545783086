import { IsOptional } from 'class-validator'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'

export class PersonBulkOpportunityFormModel extends FormModel {

    @IsNotEmpty()
    personResponsibleCode: number

    @IsNotEmpty()
    sourceCode: number

    @IsNotEmpty()
    shouldSkipIfOpen: boolean

    @IsOptional()
    sourcePersonLinkCode: number

    @IsOptional()
    notes?: string

    @IsOptional()
    tags: number[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
