import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface
} from 'class-validator'
import { isValid as isValidCnpj } from '@fnando/cnpj'
import { isValid as isValidCpf } from '@fnando/cpf'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida Cpnj ou Cpf
 *
 * @author renato.assis
 */
@ValidatorConstraint({ name: ValidationTypes.IS_VALID_CNPJ_OR_CPF })
class IsValidCnpjOrCpfConstraint implements ValidatorConstraintInterface {

    /**
   * @inheritDoc
   */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validate(value: string, args: ValidationArguments): boolean {
        return isValidCnpj(value) || isValidCpf(value)
    }

    /**
   * @inheritDoc
   */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defaultMessage(args: ValidationArguments): string {
        return 'Cnpj ou Cpf Inválido!'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function IsValidCpfOrCnpj(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsValidCnpjOrCpfConstraint
        })
    }
}
