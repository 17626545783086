import React, { useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'

interface IButtonRemoveExpenseCPProps {
    onDelete: (deleteAllOnGrouped: boolean) => void
    isGroupedExpense: boolean
    loading: boolean
}

/**
 * Botao de remover uma despesa
 */
export function ButtonRemoveFinancialTransactionICP(props: IButtonRemoveExpenseCPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

    return (
        <>
            <ConditionalRenderCP shouldRender={props.isGroupedExpense}>
                <PopOverCP
                    visible={isPopoverVisible}
                    onClose={() => setIsPopoverVisible(false)}
                    title={'Você está removendo uma despesa que se repete, o que deseja fazer?'}
                    placement={'top'}
                    content={
                        <FlexCP justifyContent={'space-between'}>
                            <ButtonCP
                                type={'danger'}
                                onClick={() => {
                                    setIsPopoverVisible(false)
                                    props.onDelete(false)
                                }}
                                loading={props.loading}
                            >
                                Remover apenas esta
                            </ButtonCP>
                            <ButtonCP
                                type={'ghost'}
                                onClick={() => {
                                    setIsPopoverVisible(false)
                                    props.onDelete(true)
                                }}
                                loading={props.loading}
                            >
                                Remover esta e todas futuras
                            </ButtonCP>
                        </FlexCP>
                    }
                >
                    <ButtonCP
                        type={'danger'}
                        onClick={() => setIsPopoverVisible(true)}
                        loading={props.loading}
                    >
                        Remover
                    </ButtonCP>
                </PopOverCP>
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={!props.isGroupedExpense}>
                <ButtonCP
                    type={'danger'}
                    onClick={() => props.onDelete(false)}
                    loading={props.loading}
                >
                    Remover
                </ButtonCP>
            </ConditionalRenderCP>
        </>
    )
}
