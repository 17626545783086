import { Icon, Steps } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IWizardSteps } from 'main/common/components/wizard/inner/IWizardSteps'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Step } = Steps

interface IWizardCPProps {
    steps: IWizardSteps[]
    action: () => void
    loading?: boolean
    showBackButton?: boolean
}

/**
 * Componente de Passo a passo
 */
export function WizardCP(props: IWizardCPProps): JSX.Element {

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

    async function next(customAction?: () => Promise<boolean> | boolean): Promise<void> {

        let canGoNext = true
        if (customAction)
            canGoNext = await customAction()

        if (canGoNext)
            setCurrentStepIndex(currentStepIndex + 1)
    }

    function prev(): void {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const currentStep = props.steps[currentStepIndex]
    return (
        <WizardContainerSCP>

            <LoadingOverlayCP show={props.loading || false}/>

            <Steps current={currentStepIndex}>
                {props.steps.map(item => (
                    <Step key={item.key} title={item.title} icon={item.icon && <Icon type={item.icon}/>}/>
                ))}
            </Steps>

            <ContentSCP>
                {currentStep.content}
            </ContentSCP>

            <FlexCP justifyContent={'flex-end'} marginTop={10}>
                {
                    (props.showBackButton || props.showBackButton === undefined) && currentStepIndex > 0 &&
                    <ButtonCP
                        marginRight={8}
                        onClick={() => prev()}
                    >
                        Voltar
                    </ButtonCP>
                }

                {currentStepIndex < props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => { next(currentStep.nextAction) }}
                    >
                        Próximo
                    </ButtonCP>
                )}

                {currentStepIndex === props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => {
                            props.action()
                        }}
                    >
                        Confirmar
                    </ButtonCP>
                )}
            </FlexCP>

        </WizardContainerSCP>
    )

}

const WizardContainerSCP = styled.div`
    margin: 10px 0
`

const ContentSCP = styled.div`
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    padding: 10px;
`
