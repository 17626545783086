import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IProductReleaseTreatmentConfigSaveRequestDTO } from 'main/modules/products/services/product-release/dtos/request/IProductReleaseTreatmentConfigSaveRequestDTO'

export class ProductReleaseFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    productCode: number

    @IsNotEmpty()
    active: boolean

    @IsNotEmpty()
    minPrice: string

    @IsNotEmpty()
    maxPrice: string

    @IsNotEmpty()
    treatmentConfigs: IProductReleaseTreatmentConfigSaveRequestDTO

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
