
/** Atencao, em caso de adicionar novos metodos de pagamento, se atentar a classe SaleUtils para atualizar as variaveis de contrato. */
export enum PaymentMethodEnum {
    MONEY = 'MONEY',
    BANK_BILLET = 'BANK_BILLET',
    BANK_BILLET_RECURRENT = 'BANK_BILLET_RECURRENT',
    CREDIT_CARD = 'CREDIT_CARD',
    CREDIT_CARD_RECURRENT = 'CREDIT_CARD_RECURRENT',
    CHECK = 'CHECK',
    DEPOSIT = 'DEPOSIT',
    DEBIT = 'DEBIT',
    PIX = 'PIX',
    PIX_RECURRENT = 'PIX_RECURRENT',
    PAYMENT_BOOK = 'PAYMENT_BOOK', // Carnê
}

export enum PaymentMethodLabelEnum {
    MONEY = 'Dinheiro',
    BANK_BILLET = 'Boleto',
    BANK_BILLET_RECURRENT = 'Boleto recorrente',
    CREDIT_CARD = 'Cartão de crédito',
    CREDIT_CARD_RECURRENT = 'Cartão de crédito recorrente',
    CHECK = 'Cheque',
    DEPOSIT = 'Depósito',
    DEBIT = 'Débito',
    PIX = 'Pix',
    PIX_RECURRENT = 'Pix recorrente',
    PAYMENT_BOOK = 'Carnê', // Carnê
}
