import React from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { ReportsViewTP } from 'main/modules/reports/screens/screen-reports/inner/ReportsViewTP'
import {
    FINANCIAL_REPORTS_SUBMENU_KEY,
    INDICATORS_REPORTS_SUBMENU_KEY,
    ReportsScreenUtils,
    SALES_REPORTS_SUBMENU_KEY,
    SCHEDULERS_REPORTS_SUBMENU_KEY
} from 'main/modules/reports/screens/screen-reports/inner/ReportsScreenUtils'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * TELA do modulo Relatorios
 */
export function ScreenReports(): JSX.Element {

    return (
        <LayoutSubmenuCP<ReportsViewTP>
            defaultSubmenu={'npsReportView'}
            menuTitle={'Relatórios'}
            loggedDomain={AppStateUtils.getDomainSlug()}
            submenuConfigs={ReportsScreenUtils.getSubMenuConfigs()}
            defaultOpenKeys={[INDICATORS_REPORTS_SUBMENU_KEY, SALES_REPORTS_SUBMENU_KEY, SCHEDULERS_REPORTS_SUBMENU_KEY, FINANCIAL_REPORTS_SUBMENU_KEY]}
        />
    )
}
