import { SystemConfig } from 'main/config/SystemConfig'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { ILeadImportRequestDTO } from 'main/modules/sales-funnel/services/old/ILeadImportRequestDTO'

export class LeadRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/leads`

    /** Retorna parametros importar leads. */
    static import = (file: File, dto: ILeadImportRequestDTO): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)
        for (const prop of Object.keys(dto))
            formData.append(prop, dto[prop])

        return {
            url: `${LeadRequests._MODULE_BASE}/import`,
            method: HttpMethodEnum.POST,
            params: formData,
            headers: [{
                headerName: 'Content-Type',
                headerValue: 'multipart/form-data',
            }]
        }
    }
}