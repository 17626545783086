import { ColumnProps } from 'antd/lib/table'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { MathUtils } from 'main/common/utils/MathUtils'
import { IMarketingAccountResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingAccountResponseDTO'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React from 'react'
import { DateUtils } from 'main/common/utils/date/DateUtils'

/**
 * UTILITARIOS
 * Encapsula funcoes uteis para componente de tabela de listagem de accountos de mkt.
 */
export const MktAccountTableUtils = {

    getColumnsConfig(): Array<ColumnProps<IMarketingAccountResponseDTO>> {
        return [
            {
                dataIndex: 'type',
                key: 'type',
                render: (text, account) => <TagCP key={'type'} content={MarketingUtils.getShotTypeLabel(account.type)}/>
            },
            {
                title: 'Vigência',
                dataIndex: 'date',
                key: 'date',
                render: (text, account) => (
                    <>
                        {
                            `${DateUtilsOLD.getFormatted(account.initialDate, DateFormatEnum.BR_WITHOUT_TIME)} - ${DateUtils.getFormatted(account.endDate, DateFormatEnum.BR_WITHOUT_TIME)}`
                        }
                    </>
                )
            },
            {
                title: 'Saldo',
                dataIndex: 'balance',
                key: 'balance',
                align: 'right',
                render: (text, account) => (
                    <>
                        {`${MaskUtils.applyNumberMask(account.used, 0)} / `}
                        <b>{`${MaskUtils.applyNumberMask(account.purchased!, 0)}`}</b>
                    </>
                )
            },
            {
                dataIndex: 'progress',
                key: 'progress',
                width: 180,
                render: (text, account) => <ProgressCP percent={MathUtils.getPercentage(account.used, account.purchased, true)} showInfo={true}/>
            },
            {
                dataIndex: 'extra',
                key: 'extra',
                align: 'right',
                render: (text, account) => (
                    <>
                        {
                            account.extra &&
                            <TagCP key={'extra'} color={'darkOrange'} content={'Extra'}/>
                        }
                    </>
                )
            }
        ]
    },
}
