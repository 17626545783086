import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ListFilesFromApiCP } from 'submodules/nerit-framework-ui/common/components/file/list-files-from-api/ListFilesFromApiCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useLocation } from 'react-router-dom'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { ScreenUploadFileUtils } from 'main/modules/app/screens/screen-upload-files/inner/ScreenUploadFileUtils'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ScreenFileUploadEnum } from 'main/modules/app/screens/screen-upload-files/inner/ScreenFileUploadEnum'

export const PARAM_SCREEN_UPLOAD_FILES_CODE = 'e'
export const PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION = 'a'
export const PARAM_SCREEN_UPLOAD_FILES_TYPE = 't'

/**
 * Tela para anexo de documentos em um exame.
 */
export function ScreenUploadFiles(): JSX.Element {

    const routeLocation = useLocation()
    const params = new URLSearchParams(routeLocation.search)

    const entityCodeParam = params.get(PARAM_SCREEN_UPLOAD_FILES_CODE)
    const tokenParam = params.get(PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION)
    const typeParam = params.get(PARAM_SCREEN_UPLOAD_FILES_TYPE)

    if (!entityCodeParam || !tokenParam || !typeParam)
       return <ScreenResultCP status={'500'} title={'Parametrização inválida'}/>

    const screenConfig = ScreenUploadFileUtils.getScreenConfigByType(typeParam as ScreenFileUploadEnum)
    if (!screenConfig)
        return <ScreenResultCP status={'500'} title={'Parametrização inválida. Erro ao montar configuração de tela'}/>

    AuthActions.refreshLoginData(tokenParam)

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Anexar arquivos de ${screenConfig.typeName}`}
                    bgColor={ThemeProject.headerBackgroundColor}
                    color={ThemeProject.checkedColor}
                />
            }
            content={
                <ContentCP>
                    <BasicStyleWrapperCP margin={{ top: 10, left: 20, right: 20 }}>
                        <ListFilesFromApiCP
                            entityCode={+entityCodeParam}
                            requestsConfig={{
                                searchRequestConfigTP: screenConfig.requestsConfig.searchRequestConfigTP,
                                uploadRequestConfigTP: screenConfig.requestsConfig.uploadRequestConfigTP,
                                deleteRequestConfigTP: screenConfig.requestsConfig.deleteRequestConfigTP,
                            }}
                        />
                    </BasicStyleWrapperCP>
                </ContentCP>
            }
            footer={
                <FlexCP justifyContent={'center'} margin={{ top: 50 }}>
                    <LogoCP
                        marginBottom={35}
                        imageUrl={AppStateUtils.getDomainData()?.schema?.logoUrl ?? undefined}
                        width={'150px'}
                    />
                </FlexCP>
            }
            backgroundColor={'#FFF'}
        />
    )
}
