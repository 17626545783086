import React, { useEffect } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { EvaluationsByFranchiseOrderingEnum } from 'main/modules/dashboard/services/enums/EvaluationsByFranchiseOrderingEnum'
import { IEvaluationsByFranchiseListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IEvaluationsByFranchiseListItemResponseDTO'
import { EvaluationsByFranchiseTableUtils } from 'main/modules/dashboard/components/widget-table-evaluations-by-franchise/inner/EvaluationsByFranchiseTableUtils'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Componente de tabela de evolucoes por unidade
 */
export function WidgetTableEvaluationsByFranchiseCP(props: ICPProps): JSX.Element {

    const getEvaluationsByFranchiseRequest = useRequest<IGenericListResponseDTO<IEvaluationsByFranchiseListItemResponseDTO>>()
    useEffect(onGetEvaluationsByFranchiseRequestChange, [getEvaluationsByFranchiseRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes])

    /**
     * Busca dados na api.
     */
    function init(): void {
        getEvaluationsByFranchiseRequest.runRequest(DashboardRequests.getEvaluationsByFranchise({
            franchiseCodes: props.franchiseCodes,
            orderingCriteria: EvaluationsByFranchiseOrderingEnum.PLANNEDS_TODAY,
            orderingAscending: false,
        }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEvaluationsByFranchiseRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getEvaluationsByFranchiseRequest, 'Não foi possível obter sumário de evoluções por unidade'))
            return
    }

    return (
        <CardDashboardWidgetWrapperCP
            title={'Avaliações por unidade'}
            className={'card-dashboard-widget'}
            flex={'1'}
        >
            <TableCPOLD2
                columns={EvaluationsByFranchiseTableUtils.getTableColumns()}
                data={getEvaluationsByFranchiseRequest.responseData?.list}
                loading={getEvaluationsByFranchiseRequest.isAwaiting}
            />
        </CardDashboardWidgetWrapperCP>
    )
}
