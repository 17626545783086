import { GenderEnum } from 'main/modules/people/enums/GenderEnum'
import { MktStatusEnum } from 'main/modules/people/enums/MktStatusEnum'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'

/**
 * UTILS Para gestão de pessoas
 */
export const PersonUtils = {

    getMktStatusLabel(emailStatus: MktStatusEnum): string {
        switch (emailStatus) {
            case MktStatusEnum.VALID:
                return 'Válido'

            case MktStatusEnum.INVALID:
                return 'Inválido'

            case MktStatusEnum.UNCHECKED:
                return 'Não validado'

            default:
                return ''
        }
    },

    getPersonTypeBySegment(segment: SegmentTypeEnum): SegmentTypeEnum {
        switch (segment) {
            case SegmentTypeEnum.CUSTOMER:
                return SegmentTypeEnum.CUSTOMER
            case SegmentTypeEnum.COLLABORATOR:
                return SegmentTypeEnum.COLLABORATOR
            case SegmentTypeEnum.SUPPLIER:
                return SegmentTypeEnum.SUPPLIER
            default:
                return SegmentTypeEnum.CUSTOMER
        }
    },

    getSegmentTypeLabel(segment: SegmentTypeEnum): string {
        switch (segment) {
            case SegmentTypeEnum.CUSTOMER:
                return 'cliente'
            case SegmentTypeEnum.COLLABORATOR:
                return 'colaborador'
            case SegmentTypeEnum.SUPPLIER:
                return 'fornecedor'
            default:
                return 'cadastro'
        }
    },

    getGenderLabel(gender: GenderEnum): string {
        switch (gender) {
            case GenderEnum.MALE:
                return 'Masculino'

            case GenderEnum.FEMALE:
                return 'Feminino'

            case GenderEnum.NOT_BINARY:
                return 'Não binário'

            case GenderEnum.OTHER:
            default:
                return 'Outro'
        }
    },
}
