import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetFollowUpEnum } from 'main/modules/dashboard/services/enums/WidgetFollowUpEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets total de leads planejados para hoje.
 */
export function WidgetTotalFollowupsTodayCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Follow-ups planejados para hoje'}
            help={'Total de Oportunidades em Follow-up que possui uma atividade a ser realizada hoje'}
            requestConfigGetter={
                DashboardRequests.getFollowupWidgets(
                    WidgetFollowUpEnum.PLANNEDS_TODAY,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
            footerTitle={'Progresso de contato'}
            footerHelp={'Progresso da conclusão das atividades dos Follow-ups programados para hoje'}
            secondCalc={'ratioBetweenSecondValueAndMainValue'}
        />
    )
}
