import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetCreatedLeadsCP } from 'main/modules/dashboard/components/widget-created-leads/WidgetCreatedLeadsCP'
import { WidgetTotalSaleValueCP } from 'main/modules/dashboard/components/widget-total-sale-value/WidgetTotalSaleValueCP'
import { WidgetSaleProductReleaseAverageCP } from 'main/modules/dashboard/components/widget-sale-productrelase-average/WidgetSaleProductReleaseAverageCP'
import { WidgetAverageDiscountCP } from 'main/modules/dashboard/components/widget-average-discount/WidgetAverageDiscountCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetOpportunityPerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetOpportunityPerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { WidgetTotalSale } from 'main/modules/dashboard/components/widget-total-sale/WidgetTotalSale'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de indicadores gerais de performance de venda.
 */
export function WidgetsRowSalesFlowIndicators(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'shopping-cart'}>
                Performance do processo de vendas no período
            </TitleCP>

            <WidgetsRowCP>
                <WidgetCreatedLeadsCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>

                <IconCP antIcon={'arrow-right'} marginTop={63}/>
                <CardDashboardWidgetCP
                    title={'Leads interagidos'}
                    help={'Total de leads que tiveram interação no período'}
                    requestConfigGetter={
                        DashboardRequests.getOpportunityPerformanceWidgets(
                            WidgetOpportunityPerformanceEnum.LEADS_CONTACTED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                    secondCalc={'onlyValue'}
                    footerTitle={'Total de interações'}
                    footerHelp={'Total de atividades realizadas no período'}
                />

                <IconCP antIcon={'arrow-right'} marginTop={63}/>
                <CardDashboardWidgetCP
                    title={'Avaliações agendadas'}
                    help={'Total de avaliações agendadas no período'}
                    footerTitle={'Taxa de agendamento'}
                    footerHelp={'Percentual de avaliações agendadas sobre total de leads interagidos'}
                    secondCalc={'ratioBetweenMainValueAndSecondValue'}
                    requestConfigGetter={
                        DashboardRequests.getOpportunityPerformanceWidgets(
                            WidgetOpportunityPerformanceEnum.EVALUATIONS_REGISTERED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />

                <IconCP antIcon={'arrow-right'} marginTop={63}/>
                <CardDashboardWidgetCP
                    title={'Avaliações realizadas'}
                    help={'Total de avaliações realizadas no período'}
                    footerTitle={'Taxa de comparecimento'}
                    footerHelp={'Percentual das avaliação que foram concluídas'}
                    secondCalc={'ratioBetweenMainValueAndSecondValue'}
                    requestConfigGetter={
                        DashboardRequests.getOpportunityPerformanceWidgets(
                            WidgetOpportunityPerformanceEnum.EVALUATIONS_CONCLUDED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />

            </WidgetsRowCP>

            <WidgetsRowCP marginTop={30}>
                <WidgetTotalSale franchiseCodes={props.franchiseCodes} userCode={props.userCode} dateFilters={props.dateFilters}/>
                <WidgetTotalSaleValueCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetSaleProductReleaseAverageCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetAverageDiscountCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            </WidgetsRowCP>

            <WidgetsRowCP marginTop={30}>
                <CardDashboardWidgetCP
                    title={'Vendas canceladas'}
                    help={'Das vendas realizadas no período, o número que foi cancelada cancelada'}
                    footerTitle={'Taxa de cancelamento'}
                    footerHelp={'Das vendas realizadas no período, qual o percentual delas que foi cancelada'}
                    secondCalc={'ratioBetweenMainValueAndSecondValue'}
                    requestConfigGetter={
                        DashboardRequests.getSalePerformanceWidgets(
                            WidgetSalePerformanceEnum.SALES_CANCELED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />

                <CardDashboardWidgetCP
                    title={'Avaliações criadas'}
                    help={'Total de avaliações únicas criadas no período pelo usuário selecionado'}
                    footerTitle={'Taxa de conclusão'}
                    footerHelp={'Das avaliações criadas por esse usuário, quantas foram concluidas? (independe de quem atendeu)'}
                    secondCalc={'ratioBetweenSecondValueAndMainValue'}
                    requestConfigGetter={
                        DashboardRequests.getOpportunityPerformanceWidgets(
                            WidgetOpportunityPerformanceEnum.EVALUATIONS_CREATED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />
            </WidgetsRowCP>
        </>
    )
}
