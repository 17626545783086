import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { ModalReceiptCP } from 'main/modules/financial/components/modal-receipt/ModalReceiptCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FinancialTransactionFormICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/forms/FinancialTransactionFormICP'
import { FinancialTransactionDrawerUtils } from 'main/modules/financial/components/drawer-financial-transaction/inner/utils/FinancialTransactionDrawerUtils'
import { FinancialTransactionGroupFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionGroupFormModel'
import { RecurrencePeriodEnum } from 'main/modules/financial/services/financial-transactions/enums/RecurrencePeriodEnum'
import { ButtonSaveFinancialTransactionICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/buttons/ButtonSaveFinancialTransactionICP'
import { ButtonRemoveFinancialTransactionICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/buttons/ButtonRemoveFinancialTransactionICP'
import { FinancialTransactionTypeEnum, FinancialTransactionTypeLabelEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { ModalFinancialTransactionFilesCP } from 'main/modules/financial/components/modal-financial-transaction-files/ModalFinancialTransactionFilesCP'

interface IExpenseDrawerCPDrawerCPProps {
    type: FinancialTransactionTypeEnum
    code?: number
    visible: boolean
    onClose: (dataChanged?: boolean) => void
}

/**
 * Drawer para receitas e despesas
 */
export function DrawerFinancialTransactionCP(props: IExpenseDrawerCPDrawerCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<FinancialTransactionFormModel>((new FinancialTransactionFormModel()))
    const formStateManager = useFormStateManager<FinancialTransactionFormModel>(formValidator)

    const [formRecurrenceGroupValidator, setFormRecurrenceGroupValidator] = useState<FinancialTransactionGroupFormModel>((new FinancialTransactionGroupFormModel()))
    const formRecurrenceGroupStateManager = useFormStateManager<FinancialTransactionGroupFormModel>(formRecurrenceGroupValidator)

    const [isReceiptModalVisible, setIsReceiptModalVisible] = useState<boolean>(false)
    const [isFileModalVisible, setIsFileModalVisible] = useState<boolean>(false)
    const [shouldUploadFilesAfterSave, setShouldUploadFilesAfterSave] = useState<boolean>(false)

    const [loadedFinancialTransation, setLoadedFinancialTransation] = useState<IFinancialTransactionResponseDTO>()
    const getFinancialTransactionRequest = useRequest<IFinancialTransactionResponseDTO>()
    useEffect(onGetFinancialTransactionRequestChange, [getFinancialTransactionRequest.isAwaiting])

    const saveFinancialTransactionRequest = useRequest<IFinancialTransactionResponseDTO>()
    useEffect(onSaveFinancialTransactionRequestChange, [saveFinancialTransactionRequest.isAwaiting])

    const deleteRequest = useRequest<void>('none')
    useEffect(onDeleteRequestChange, [deleteRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa
     */
    function init(): void{

        setFormValidator(new FinancialTransactionFormModel())
        setFormRecurrenceGroupValidator(new FinancialTransactionGroupFormModel())
        setLoadedFinancialTransation(undefined)
        if (!props.visible)
            return

        setFormValidator(new FinancialTransactionFormModel({
            expirationDate: new Date(),
            values: [{}],
            isPaid: false
        }))
        setFormRecurrenceGroupValidator(new FinancialTransactionGroupFormModel({
            isRecurrent: false,
            recurrencyPeriod: RecurrencePeriodEnum.MONTHLY
        }))

        if (props.code)
            getFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.findOne(props.code))
    }

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetFinancialTransactionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFinancialTransactionRequest, 'Erro obter detalhes da despesa'))
            return

        const dto = getFinancialTransactionRequest.responseData!
        setLoadedFinancialTransation(dto)

        setFormValidator(FinancialTransactionDrawerUtils.getFormModelFromDto(dto))
        setFormRecurrenceGroupValidator(FinancialTransactionDrawerUtils.getRecurrenceGroupFormModelFromDto(dto))
    }

    /**
     * Salva despesa
     */
    async function save(changeAllOnGrouped: boolean, uploadFiles?: boolean): Promise<void> {

        const formValues = formStateManager.getFormValues()
        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate() || !formValues)
            return

        // Se for parcelado seta o recurrencePeriod como mensal, por enquanto sera fixo
        const formRecurrenceGroupValues = formRecurrenceGroupStateManager.getFormValues()
        formRecurrenceGroupStateManager.setConsiderAllErrors(true)
        if (!await formRecurrenceGroupStateManager.validate() || !formRecurrenceGroupValues)
            return

        const dto = FinancialTransactionDrawerUtils.getSaveDtoFromFormModel(props.type, formValues, formRecurrenceGroupValues)

        // Caso tenha retornado undefined deu algum erro, apenas nao deve continuar
        if (!dto)
            return

        const code = props.code ?? loadedFinancialTransation?.code
        if (!code) {
            setShouldUploadFilesAfterSave(uploadFiles ?? false)
            saveFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.create(dto))
            return
        }

        // Se for update, verifica se esta alterando todas do grupo ou apenas a atual
        if (changeAllOnGrouped)
            saveFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.updateGroup(code, dto))
        else
            saveFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.update(code, dto))
    }

    /**
     * Retorno da API ao salvar despesa.
     */
    function onSaveFinancialTransactionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFinancialTransactionRequest, 'Erro ao salvar despesa'))
            return

        NotificationHelper.success('Pronto', 'Despesa salva com sucesso')

        // Se pediu para anexar arquivos
        if (shouldUploadFilesAfterSave) {
            getFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.findOne(saveFinancialTransactionRequest.responseData!.code))
            setIsFileModalVisible(true)
            setShouldUploadFilesAfterSave(false)
        }

        props.onClose(true)
    }

    /**
     * Remover despesa.
     */
    function deleteFinancialTransaction(deleteAllOnGrouped: boolean): void {

        if (!props.code)
            return

        if (deleteAllOnGrouped)
            deleteRequest.runRequest(FinancialTransactionsRequests.deleteGroupExpense(props.code))
        else
            deleteRequest.runRequest(FinancialTransactionsRequests.delete(props.code))
    }

    /**
     * Retorno da remocao de despesa.
     */
    function onDeleteRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteRequest, 'Não foi possível remover a despesa', true))
            return

        NotificationHelper.success('Pronto!', 'Despesa removida com sucesso')
        props.onClose(true)
    }

    return (
        <>
            <DrawerCP
                title={FinancialTransactionTypeLabelEnum[props.type]}
                visible={props.visible}
                width={700}
                onClose={props.onClose}
                footerSpaced={true}
                loading={getFinancialTransactionRequest.isAwaiting}
                footer={
                    <>
                        <FlexCP>
                            <ConditionalRenderCP shouldRender={!!props.code}>
                                <ButtonRemoveFinancialTransactionICP
                                    onDelete={deleteFinancialTransaction}
                                    isGroupedExpense={!!loadedFinancialTransation && !!loadedFinancialTransation.group}
                                    loading={deleteRequest.isAwaiting}
                                />
                            </ConditionalRenderCP>
                            <ButtonCP
                                onClick={() => setIsReceiptModalVisible(true)}
                                type={'ghost'}
                                marginLeft={5}
                                disabled={!formStateManager.getFieldValue('isPaid')}
                                tooltip={!formStateManager.getFieldValue('isPaid') ? 'Marque a fatura como paga para imprimir o recibo' : undefined}
                            >
                                Imprimir Recibo
                            </ButtonCP>
                            {
                                !!loadedFinancialTransation &&
                                <ButtonCP
                                    onClick={() => setIsFileModalVisible(true)}
                                    type={'ghost'}
                                    marginLeft={5}
                                >
                                    Arquivos
                                </ButtonCP>
                            }
                        </FlexCP>

                        <ButtonSaveFinancialTransactionICP
                            code={props.code}
                            onSave={save}
                            isGroupedExpense={!!loadedFinancialTransation && !!loadedFinancialTransation.group}
                            loading={saveFinancialTransactionRequest.isAwaiting}
                        />
                    </>
                }
            >
                <FinancialTransactionFormICP
                    type={props.type}
                    formStateManager={formStateManager}
                    formRecurrenceGroupStateManager={formRecurrenceGroupStateManager}
                    loadedFinancialTransaction={loadedFinancialTransation}
                    canEditValues={!props.code || (!!props.code && !loadedFinancialTransation?.group)}
                />
            </DrawerCP>

            {
                !!loadedFinancialTransation &&
                <>
                    <ModalReceiptCP
                        visible={isReceiptModalVisible}
                        financialTransaction={loadedFinancialTransation}
                        onClose={() => setIsReceiptModalVisible(false)}
                    />

                    <ModalFinancialTransactionFilesCP
                        visible={isFileModalVisible}
                        financialTransactionCode={loadedFinancialTransation.code}
                        onClose={() => setIsFileModalVisible(false)}
                    />
                </>
            }
        </>
    )
}
