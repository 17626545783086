export enum TransactionalTriggerMomentTypeEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    AT_MOMENT = 'AT_MOMENT',
}

export enum TransactionalTriggerMomentTypeLabelEnum {
    HOUR = 'horas',
    DAY = 'dias',
}
