import { FileDeleteRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/FileDeleteRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { FinancialTransactionSearchRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/request/FinancialTransactionSearchRequestDTO'
import { FinancialTransactionSummaryByPaymentMethodRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/request/FinancialTransactionSummaryByPaymentMethodRequestDTO'
import { FinancialTransactionSummaryRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/request/FinancialTransactionSummaryRequestDTO'
import { FinancialTransactionCreditListItemResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/response/FinancialTransactionCreditListItemResponseDTO'
import { FinancialTransactionSummaryByPaymentMethodListItemResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/response/FinancialTransactionSummaryByPaymentMethodListItemResponseDTO'
import { FinancialTransactionSummaryResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/response/FinancialTransactionSummaryResponseDTO'

export class FinancialTransactionRequests {

    public static CONTROLLER_ROOT = 'financial-transactions'
    public static UPLOAD_FILE_EP = 'add-upload-file/:financialTransactionCode'
    public static DELETE_FILE_EP = 'delete-file'
    public static SEARCH_FILES_EP = 'search-files/:financialTransactionCode'
    public static SUMMARY_EP = 'summary'
    public static SUMMARY_BILLING_DASHBOARD_EP = 'summary-billing-dashboard'
    public static SUMMARY_BILLING_DASHBOARD_BY_PAYMENT_METHOD_EP = 'summary-billing-dashboard-by-payment-method'
    public static SUMMARY_BILLING_DASHBOARD_CREDITS_EP = 'summary-billing-dashboard-credits'

    static uploadFile = (code: number, formData: FormData): RequestConfigTP => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.UPLOAD_FILE_EP.replace(':financialTransactionCode', code.toString())}`,
        method: HttpMethodEnum.POST,
        params: formData,
        avoidParamTransformer: true,
    })

    static deleteFile = (dto: FileDeleteRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.DELETE_FILE_EP}`,
        method: HttpMethodEnum.DELETE,
        params: dto,
    })

    static searchFiles = (code: number): RequestConfigTP => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.SEARCH_FILES_EP.replace(':financialTransactionCode', code.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static summaryBillingDashboardFinancialTransactions = (dto: FinancialTransactionSummaryRequestDTO): RequestConfigTP<FinancialTransactionSummaryResponseDTO> => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.SUMMARY_BILLING_DASHBOARD_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static summaryBillingDashboardByPaymentMethod = (dto: FinancialTransactionSummaryByPaymentMethodRequestDTO): RequestConfigTP<ListResponseDTO<FinancialTransactionSummaryByPaymentMethodListItemResponseDTO>> => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.SUMMARY_BILLING_DASHBOARD_BY_PAYMENT_METHOD_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getBillingDashboardCredits = (dto: FinancialTransactionSearchRequestDTO): RequestConfigTP<ListResponseDTO<FinancialTransactionCreditListItemResponseDTO>> => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionRequests.CONTROLLER_ROOT}/${FinancialTransactionRequests.SUMMARY_BILLING_DASHBOARD_CREDITS_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
