import React from 'react'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetCreatedLeadsCP } from 'main/modules/dashboard/components/widget-created-leads/WidgetCreatedLeadsCP'
import { WidgetTotalSaleValueCP } from 'main/modules/dashboard/components/widget-total-sale-value/WidgetTotalSaleValueCP'
import { WidgetSaleProductReleaseAverageCP } from 'main/modules/dashboard/components/widget-sale-productrelase-average/WidgetSaleProductReleaseAverageCP'
import { WidgetAverageDiscountCP } from 'main/modules/dashboard/components/widget-average-discount/WidgetAverageDiscountCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetTotalResaleCP } from 'main/modules/dashboard/components/widget-total-resale/WidgetTotalResaleCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { WidgetTotalSale } from 'main/modules/dashboard/components/widget-total-sale/WidgetTotalSale'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]

    hideSalesConversion?: boolean
}

/**
 * Widgets de indicadores gerais de performance.
 */
export function AttendantDashboardActualSaleFlowPerformanceICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'shopping-cart'}>
                Performance do processo de vendas no período
            </TitleCP>

            <WidgetsRowCP marginBottom={20}>
                <WidgetCreatedLeadsCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetTotalResaleCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetTotalSale
                    franchiseCodes={props.franchiseCodes}
                    userCode={props.userCode}
                    dateFilters={props.dateFilters}
                    hideSalesConversion={props.hideSalesConversion}
                />
                <WidgetTotalSaleValueCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            </WidgetsRowCP>

            <WidgetsRowCP marginBottom={30}>
                <WidgetSaleProductReleaseAverageCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
                <WidgetAverageDiscountCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
            </WidgetsRowCP>
        </>
    )
}
