import React from 'react'
import { TableCP } from 'main/common/components/table/TableCP'
import { IBulkOperationResponseDTO } from 'main/modules/admin/services/bulk-operation/responses/IBulkOperationResponseDTO'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { IBulkLogItem } from 'main/modules/admin/services/bulk-operation/responses/inner/IBulkLogItem'

interface IBankAccountTableCPProps {
    visible: boolean
    onClose: () => void
    bulkOperation?: IBulkOperationResponseDTO
}

/**
 * Modal com o log de uma operacao em massa.
 */
export function ModalBulkOperationsLog(props: IBankAccountTableCPProps): JSX.Element {

    return (
        <ModalCP
            title={'Logs'}
            top={0}
            width={'100%'}
            visible={props.visible}
            onCancel={props.onClose}
            noFooter={true}
        >
            <TableCP<IBulkLogItem>
                data={props.bulkOperation?.logs}
                columns={[
                    {
                        title: 'Cód. Contato',
                        key: 'cod',
                        render: (text, record) => record.recordPK
                    },
                    {
                        title: 'Erro',
                        key: 'error',
                        render: (text, record) => record.text
                    }
                ]}
            />
        </ModalCP>
    )
}
