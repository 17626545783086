import React, { useEffect, useState } from 'react'
import { ContractAssigneesTableCP } from 'main/modules/documents/components/contract-assignees-table/ContractAssigneesTableCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { IDokfyPersonResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyPersonResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DokfyRequests } from 'main/modules/documents/services/dokfy/DokfyRequests'
import { HttpStatusEnum } from 'main/common/enums/HttpStatusEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IDokfyPersonSaveRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyPersonSaveRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { DokfyLogoCP } from 'main/modules/documents/components/dokfy-logo/DokfyLogoCP'
import { DokfySignatureSecurityLevelEnum } from 'main/modules/documents/services/dokfy/enums/DokfySignatureSecurityLevelEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PdfViewerCP } from 'main/common/components/pdf-viewer/PdfViewerCP'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { IDokfyCreateDocumentRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyCreateDocumentRequestDTO'
import { IDokfyAssigneeRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyAssigneeRequestDTO'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { IApiReturn } from 'main/common/interfaces/IApiReturn'
import { FileUtils } from 'main/common/utils/FileUtils'
import { IDokfyDocumentResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyDocumentResponseDTO'
import { IDokfyNeritClinCustomArgs } from 'main/modules/documents/services/dokfy/utils/IDokfyNeritClinCustomArgs'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'

interface IContractSendToDokfyContentICP {
    sale: ISaleResponseDTO
    contractUrl: string
    onSendToDokfy: (document: IDokfyDocumentResponseDTO) => void
    shouldAddSellerAsAssignee: boolean
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function ContractSendToDokfyContentICP(props: IContractSendToDokfyContentICP): JSX.Element {

    const [assignees, setAssignees] = useState<IDokfyPersonResponseDTO[]>([])
    const [isAddingAssignees, setIsAddingAssignees] = useState<boolean>(false)

    const [isPdfViewerVisible, setIsPdfViewerVisible] = useState<boolean>(false)

    const createDocumentRequest = useRequest<IDokfyDocumentResponseDTO>()
    useEffect(onCreateDocumentRequestChange, [createDocumentRequest.isAwaiting])

    useEffect(() => { init() }, [])

    /**
     * Inicializa adicionando as partes da venda como signatarios do documento
     */
    async function init(): Promise<void> {
        console.log("props contratox ",props.sale)
        
        const _assignees: IDokfyPersonResponseDTO[] = []
        setIsAddingAssignees(true)

        // Adiciona como signatário o responsavel pela compra
        if (props.shouldAddSellerAsAssignee) {
            const isAddReponsibleSuccess = await addPerson(props.sale.userSeller.cpfCnpj, props.sale.userSeller.name, props.sale.userSeller.email,props.sale.userSeller.phone+'', _assignees)
            if (!isAddReponsibleSuccess)
                NotificationHelper.error('Ops!', 'Ocorreu algum erro ao adicionar o Vendedor como signatário')
        }

        // Adiciona como signatário o responsavel pela compra
        const isAddCustomerSuccess = await addPerson(props.sale.personCustomerPayer.cpfCnpj, props.sale.personCustomerPayer.name, props.sale.personCustomerPayer.email,props.sale.personCustomerPayer.phone+'', _assignees)
        if (!isAddCustomerSuccess)
            NotificationHelper.error('Ops!', 'Ocorreu algum erro ao adicionar o Vendedor como signatário')

        setAssignees([..._assignees])
        setIsAddingAssignees(false)
    }

    /**
     * Adiciona uma pessoa como signatario. Busca no doffy pelo CPF, e faz o pre cadastro caso ainda nao seja usuario.
     */
    async function addPerson(cpf: string, name: string, email: string, phone: string, _assignees: IDokfyPersonResponseDTO[]): Promise<boolean> {

        try {

            // Busca pessoa para ver se existe na Dokfy
            const searchPersonResponseResult = await RequestHelper.runRequest<IApiReturn<IDokfyPersonResponseDTO>>(await DokfyRequests.getPersonByCpf(cpf))
            const searchPersonResult = searchPersonResponseResult?.data.data

            // Se encontrou a pessoa adiciona ela na lista de signatarios
            if (searchPersonResult) {
                _assignees.push(searchPersonResult)
                return true
            }

        } catch (e) {

            // Se nao encontrou, sera lancada uma excessao 404
            // Se nao encontrou, faz o pre cadastro dela na dokfy
            if ([HttpStatusEnum.NOT_FOUND].includes(e.status)) {
                const createPersonDto: IDokfyPersonSaveRequestDTO = {
                    cpfCnpj: cpf,
                    name,
                    cellPhone:phone,
                    email,
                    ipAddress: await RequestUtils.getUserIp()
                }
                console.log("Criando pessoa DTO ", createPersonDto);
                const createPersonResponseResult = await RequestHelper.runRequest<IApiReturn<IDokfyPersonResponseDTO>>(await DokfyRequests.getPersonInviteConfig(createPersonDto))
                const createPersonResult = createPersonResponseResult?.data.data

                // Se encontrou a pessoa adiciona ela na lista de signatarios
                if (createPersonResult) {
                    _assignees.push(createPersonResult)
                    return true
                }

            }

        }

        return false
    }

    /**
     * Confirma criacao de documento
     */
    async function createDocument(): Promise<void> {

        // Monta lista de signatarios
        const assigneesDTO: IDokfyAssigneeRequestDTO[] = assignees.map((_assignee, index) => {
            return {
                order: index + 1,
                personCode: _assignee.code
            }
        })

        // Custom args salvo na DOKFY para poder pegar o contrato de uma venda
        const customArgs: IDokfyNeritClinCustomArgs = {
            schema: AppStateUtils.getDomainSlug()!,
            franchiseCode: props.sale.franchise.code,
            saleCode: props.sale.code
        }

        const dto: IDokfyCreateDocumentRequestDTO = {
            documentName: `contrato_venda_${props.sale.code}`,
            securityLevel: DokfySignatureSecurityLevelEnum.SIMPLE_REGISTER,
            hasSignOrder: false,
            assignees: assigneesDTO,
            signatureMessage: 'Favor assinar o contrato de Prestação de Serviço',
            customArgs: JSON.stringify(customArgs)
        }

        const file = await FileUtils.getFile(props.contractUrl, `contrato_venda_${props.sale.code}.pdf`)

        createDocumentRequest.runRequest(await DokfyRequests.createDocumentConfig(file, dto))
    }

    /**
     * Apos reotorno da api de salvar pessoa
     */
    function onCreateDocumentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(createDocumentRequest, 'Ocorreu algun erro ao enviar documento para assinatura'))
            return

        props.onSendToDokfy(createDocumentRequest.responseData!)
    }

    return (
        <>
            <DokfyLogoCP
                marginTop={0}
                marginBottom={30}
            />

            <LoadingOverlayCP
                show={isAddingAssignees}
                text={'Adicionando signatários'}
            />

            <TitleCP textSize={'normal'}>
                Signatários:
            </TitleCP>

            <ContractAssigneesTableCP
                originalAssigneesData={[props.sale.personCustomerPayer]}
                assignees={assignees}
                showRemove={false}
                onUpdateData={init}
            />

            <TextCP
                style={{ margin: '20px 0 40px 0' }}
                text={'Confira todas as informações do contrato antes de enviar para assinatura.'}
                wrap={true}
            />

            <FlexCP
                justifyContent={'space-between'}
            >
                <FlexCP>
                    <ButtonCP
                        type={'ghost'}
                        icon={'eye'}
                        onClick={() => setIsPdfViewerVisible(true)}
                        tooltip={'Visualizar Contrato'}
                    />
                    <ButtonCP
                        type={'ghost'}
                        icon={'download'}
                        onClick={() => window.open(props.contractUrl, '_blank')}
                        tooltip={'Download do Contrato'}
                        marginLeft={5}
                    />
                </FlexCP>
                <ButtonCP
                    type={'primary'}
                    icon={'edit'}
                    onClick={createDocument}
                    loading={createDocumentRequest.isAwaiting}
                    disabled={assignees.length === 0}
                >
                    Enviar para assinatura
                </ButtonCP>
            </FlexCP>

            <PdfViewerCP
                file={props.contractUrl}
                paginationType={'scroll'}
                viewType={'modal'}
                visible={isPdfViewerVisible}
                onSetVisibleFalse={() => setIsPdfViewerVisible(false)}
            />
        </>
    )
}
