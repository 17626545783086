import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { RoutingHelper } from 'config/RoutingHelper'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import React from 'react'
import styled from 'styled-components'

/**
 * Botoes para novo disparo, Email e SMS
 *
 * @author renatofs
 */
export function NewShotButtonCP(): JSX.Element {
    return (
        <ContentSCP data-tour={'new-shot-btns'}>
            <HeaderButtonICP
                icon={'mail'}
                label={'Enviar E-mail'}
                onClick={() => RoutingHelper.historyPush(`${MarketingRoutes.NEW_SHOT}/${MarketingAccountTypeEnum.EMAIL}`)}
            />
            <HeaderButtonICP
                icon={'mobile'}
                label={'Enviar SMS'}
                onClick={() => RoutingHelper.historyPush(`${MarketingRoutes.NEW_SHOT}/${MarketingAccountTypeEnum.SMS}`)}
            />
        </ContentSCP>
    )
}

const ContentSCP = styled.div`

    display: flex;

    button {
        margin-left: 10px;
    }
`
