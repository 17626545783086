import React, { useEffect } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { LeadsByFranchiseTableUtils } from 'main/modules/dashboard/components/widget-table-leads-by-franchise/inner/LeadsByFranchiseTableUtils'
import { OppsByFranchiseOrderingEnum } from 'main/modules/dashboard/services/enums/OppsByFranchiseOrderingEnum'
import { ILeadsByFranchiseListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ILeadsByFranchiseListItemResponseDTO'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Componente de tabela que contas bancarias
 */
export function WidgetTableLeadsByFranchiseCP(props: ICPProps): JSX.Element {

    const getLeadsByFranchiseRequest = useRequest<IGenericListResponseDTO<ILeadsByFranchiseListItemResponseDTO>>()
    useEffect(onGetLeadsByFranchiseRequestChange, [getLeadsByFranchiseRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes])

    /**
     * Busca dados na api.
     */
    function init(): void {
        getLeadsByFranchiseRequest.runRequest(DashboardRequests.getLeadsByFranchise({
            franchiseCodes: props.franchiseCodes,
            orderingCriteria: OppsByFranchiseOrderingEnum.PLANNEDS_TODAY,
            orderingAscending: false,
        }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetLeadsByFranchiseRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getLeadsByFranchiseRequest, 'Não foi possível obter sumário de leads por unidade'))
            return
    }

    return (
        <CardDashboardWidgetWrapperCP
            title={'Leads por unidade'}
            className={'card-dashboard-widget'}
            flex={'1'}
        >
            <TableCPOLD2
                columns={LeadsByFranchiseTableUtils.getTableColumns()}
                data={getLeadsByFranchiseRequest.responseData?.list}
                loading={getLeadsByFranchiseRequest.isAwaiting}
            />
        </CardDashboardWidgetWrapperCP>
    )
}
