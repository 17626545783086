import { RowCP } from 'main/common/components/grid/RowCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import React from 'react'
import styled from 'styled-components'

interface IShotListInfoColumnICPProps {
    shot: IMarketingCampaignEmailResponseDTO
}

/**
 * Coluna da imagem, remetente e assunto
 */
export function ShotListItemEmailInfoColumnICP(props: IShotListInfoColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <MailInfoSCP>
                {
                    props.shot.status !== MarketingShotStatusEnum.DRAFT &&
                    <img src={props.shot.imagePreview}/>
                }
            </MailInfoSCP>

            <RowCP>
                <TextCP text={'Assunto:'} strong={true}/>
                <TextCP text={props.shot.subject} wrap={true}/>
            </RowCP>
        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`
    width: 50%;
    
    background: #fff;
    padding: 10px;

    .ant-typography {
        font-size: 12px;
    }
`

const MailInfoSCP = styled.div`
    text-align: center;

    img {
        height: 140px;
    }
`
