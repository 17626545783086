import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

type _CheckIconTypeTP = 'default' | 'circle' | 'box'

interface ICheckIconCPProps extends IIconProps {
    type?: _CheckIconTypeTP
}

/**
 * ICONE: 'Checar'
 *
 * @author hjcostabr
 */
export function CheckIconCP(props: ICheckIconCPProps): JSX.Element {

    let iconName: string

    switch (props.type) {
        case 'circle':
            iconName = 'check-circle'
            break

        case 'box':
            iconName = 'check-square'
            break

        case 'default':
        default:
            iconName = 'check'
            break
    }

    return (
        <IconICP
            iconName={iconName}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
