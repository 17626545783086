import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISaleQuoteSaveRequestDTO } from 'main/modules/sale/services/sale-quote/dtos/request/ISaleQuoteSaveRequestDTO'
import { ISaleQuoteSearchRequestDTO } from 'main/modules/sale/services/sale-quote/dtos/request/ISaleQuoteSearchRequestDTO'
import { ISaleQuoteSendByWhtasappRequestDTO } from 'main/modules/sale/services/sale-quote/dtos/request/ISaleQuoteSendByWhtasappRequestDTO'

export class SaleQuoteRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/sale-quotes`

    static create = (dto: ISaleQuoteSaveRequestDTO): RequestConfigTP => ({
        url: `${SaleQuoteRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static search = (dto: ISaleQuoteSearchRequestDTO): RequestConfigTP => ({
        url: `${SaleQuoteRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOne = (code: number): RequestConfigTP => ({
        url: `${SaleQuoteRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static sendQuoteByWhatsapp = (dto: ISaleQuoteSendByWhtasappRequestDTO): RequestConfigTP => ({
        url: `${SaleQuoteRequests._MODULE_BASE}/send-by-whatsapp`,
        method: HttpMethodEnum.POST,
        params: dto
    })

}
