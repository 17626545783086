import React from 'react'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { FranchisorDashboardScreen } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchisor/FranchisorDashboardScreen'
import { ScreenDashboardFranchiseCP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/ScreenDashboardFranchiseCP'

/**
 * Tela do módulo Dashboard
 */
export function ScreenDashboardCP(): JSX.Element {

    if (AppStateUtils.getCurrentFranchise()?.isFranchisor)
        return <FranchisorDashboardScreen/>

    return <ScreenDashboardFranchiseCP/>
}
