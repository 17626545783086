import React from 'react'
import styled from 'styled-components'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface ICPProps extends IScpProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Wrappper para uma linha de widgets.
 */
export function WidgetsRowCP(props: ICPProps): JSX.Element {

    return (
        <WidgetsContentSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            {props.children}
        </WidgetsContentSCP>
    )
}

const WidgetsContentSCP = styled.div<IScpProps>`
  
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
  
  .card-dashboard-widget {
    flex: 1;
  }
`
