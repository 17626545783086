import React from 'react'
import { SegmentUnitFieldsEnum } from 'main/modules/segment/enums/SegmentUnitFieldsEnum'
import { GenderEnum } from 'main/modules/people/enums/GenderEnum'
import { MktStatusEnum } from 'main/modules/people/enums/MktStatusEnum'
import { SelectFullOptionReturnTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionReturnTP'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { PersonUtils } from 'main/modules/people/utils/PersonUtils'
import { SegmentUnitValueEnumTP } from 'main/modules/segment/types/unit/SegmentUnitValueEnumTP'
import { UnitValueFieldInputIndexTP } from 'main/modules/segment/types/unit/UnitValueFieldInputIndexTP'
import { OrArrayTP } from 'main/common/types/OrArrayTP'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { OpportunityUtils } from 'main/modules/sales-funnel/utils/OpportunityUtils'
import { SalesUtils } from 'main/modules/sale/utils/SalesUtils'
import { SessionStatusEnum, SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'

interface IUnitValueFieldEnumICPProps<EnumTP> {
    onChange: (value?: SelectFullOptionReturnTP) => void
    inputIndex: UnitValueFieldInputIndexTP
    label: string
    isMultiple: boolean
    value: OrArrayTP<EnumTP>
}

/**
 * COMPONENTE
 * <select> para valor de 01 unidade de filtro de lista de segmentacao.
 */
export function UnitValueFieldEnumICP<EnumTP>(props: IUnitValueFieldEnumICPProps<EnumTP>): JSX.Element | null {

    const unitContext = UnitContext.useState()

    if (!SegmentUtils.isUnitSelected(unitContext.state.field, unitContext.state.operator))
        return null

    function getOptions(): SelectOptionTP[] {

        let values: SegmentUnitValueEnumTP[]
        let labelGetter: Function

        switch (unitContext.state.field!) {
            case SegmentUnitFieldsEnum.GENDER:
                values = Object.values(GenderEnum)
                labelGetter = PersonUtils.getGenderLabel
                break
            case SegmentUnitFieldsEnum.EMAIL_STATUS:
            case SegmentUnitFieldsEnum.SMS_STATUS:
                values = Object.values(MktStatusEnum)
                labelGetter = PersonUtils.getMktStatusLabel
                break
            case SegmentUnitFieldsEnum.OPPORTUNITY_TYPE:
                values = Object.values(OpportunityTypeEnum)
                labelGetter = OpportunityUtils.getOpportunityTypeLabel
                break
            case SegmentUnitFieldsEnum.OPPORTUNITY_STATUS:
                values = Object.values(OpportunityStatusEnum)
                labelGetter = OpportunityUtils.getOpportunityStatusLabel
                break
            case SegmentUnitFieldsEnum.SESSION_STATUS:
                values = Object.values(SessionStatusEnum)
                labelGetter = (session: SessionStatusEnum) => SessionStatusLabelEnum[session]
                break
            case SegmentUnitFieldsEnum.SALE_STATUS:
                values = Object.values(SaleStatusEnum)
                labelGetter = SalesUtils.getSaleStatusLabel
                break
            default:
                values = []
                break
        }

        const keyPrefix = `select_op_enum_${props.inputIndex}_`

        return values.map((enumItem, index): SelectOptionTP => ({
            key: `${keyPrefix}_${index}`,
            value: enumItem,
            label: labelGetter(enumItem)
        }))
    }

    return (
        <SelectCP
            options={getOptions()}
            label={props.label}
            value={props.value}
            isMultiple={props.isMultiple}
            onChange={props.onChange}
            returnFullOption={true}
            width={170}
            noValidation={true}
        />
    )
}
