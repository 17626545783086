import React from 'react'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { WidgetTotalLeadsOpenCP } from 'main/modules/dashboard/components/widget-total-leads-open/WidgetTotalLeadsOpenCP'
import { WidgetTotalLeadsTodayCP } from 'main/modules/dashboard/components/widget-total-leads-today/WidgetTotalLeadsTodayCP'
import { WidgetTotalLeadsDelayedCP } from 'main/modules/dashboard/components/widget-total-leads-delayed/WidgetTotalLeadsDelayedCP'
import { WidgetTotalLeadsWithoutActivityCP } from 'main/modules/dashboard/components/widget-total-leads-without-activities/WidgetTotalLeadsWithoutActivityCP'
import { WidgetCreatedLeadsCP } from 'main/modules/dashboard/components/widget-created-leads/WidgetCreatedLeadsCP'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Linha com performance atual dos leads
 */
export function WidgetsRowLeadIndicators(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'message'}>
                { props.userCode ? 'Status atual dos seus Leads' : 'Status atual dos Leads da Unidade' }
            </TitleCP>
            <WidgetsRowCP>
                <WidgetTotalLeadsOpenCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
                <WidgetCreatedLeadsCP userCode={props.userCode} franchiseCodes={props.franchiseCodes} dateFilters={{ beginDate: new Date(), endDate: new Date() }} extraTitle={'hoje'}/>
                <WidgetTotalLeadsTodayCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
            </WidgetsRowCP>
            <WidgetsRowCP marginTop={20}>
                <WidgetTotalLeadsDelayedCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
                <WidgetTotalLeadsWithoutActivityCP userCode={props.userCode} franchiseCodes={props.franchiseCodes}/>
            </WidgetsRowCP>
        </>
    )
}
