import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { CardCP } from 'main/common/components/card/CardCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TotalAccountBalanceCP } from 'main/modules/financial/components/total-account-balance/TotalAccountBalanceCP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { BankAccountTransferFiltersCP } from 'main/modules/financial/components/bank-account-transfer-filters/BankAccountTransferFiltersCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { BankAccountWithdrawFiltersFormModel } from 'main/modules/financial/components/bank-account-transfer-filters/inner/BankAccountWithdrawFiltersFormModel'
import { TableBankAccountWithdrawCP } from 'main/modules/financial/components/table-bank-account-withdraw/TableBankAccountWithdrawCP'
import { DrawerBankAccountWithdrawCP } from 'main/modules/financial/components/drawer-bank-account-withdraw/DrawerBankAccountWithdrawCP'

/**
 * Tela de transferencias entre contas bancarias da empresa
 */
export function BankAccountWithdrawScreenContentCP(): JSX.Element {

    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
    const [selectedWithdrawCode, setSelectedWithdrawCode] = useState<number>()

    const [isLoading, setIsLoading] = useState<boolean>()
    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)
    const [shouldLoadBalance, setShouldLoadBalance] = useState<boolean>(true)

    const [formValidator, setFormValidator] = useState<BankAccountWithdrawFiltersFormModel>((new BankAccountWithdrawFiltersFormModel()))
    const formStateManager = useFormStateManager<BankAccountWithdrawFiltersFormModel>(formValidator)

    useEffect(init, [])
    useEffect(() => setIsLoading(shouldLoadReport), [shouldLoadReport])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        const currentMonthInterval = DateUtils.getFirstAndLastDayOfMonth()
        setFormValidator(new BankAccountWithdrawFiltersFormModel({
            dateRange: {
                beginDate: DateUtils.getFormatted(currentMonthInterval.beginDate, DateFormatEnum.US_WITHOUT_TIME),
                endDate: DateUtils.getFormatted(currentMonthInterval.endDate, DateFormatEnum.US_WITHOUT_TIME)
            },
        }))

        setShouldLoadReport(true)
        setShouldLoadBalance(true)
    }

    return (
        <>
            <HeaderCP title={'Transferência entre Contas Bancárias'}>
                <FlexCP justifyContent={'flex-end'} alignItems={'center'}>
                    <TotalAccountBalanceCP
                        shouldReloadBalance={shouldLoadBalance}
                        onBalanceLoaded={() => setShouldLoadBalance(false)}
                    />

                    <HeaderButtonICP
                        onClick={() => setIsDrawerVisible(true)}
                        icon={'swap'}
                        label={'Nova transferência'}
                    />
                </FlexCP>
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <ContentWrapperSCP>
                    <ContentSCP>

                        <BankAccountTransferFiltersCP
                            formStateManager={formStateManager}
                            isLoading={isLoading}
                            onClearFilters={init}
                            onFilter={() => setShouldLoadReport(true)}
                        />

                        <CardCP innerSpacing={'none'} margin={'vertical'}>
                            <TableBankAccountWithdrawCP
                                filtersModel={formStateManager}
                                shouldReloadList={shouldLoadReport}
                                onSelect={(code) => {
                                    setIsDrawerVisible(true)
                                    setSelectedWithdrawCode(code)
                                }}
                                onReportLoaded={() => setShouldLoadReport(false)}
                                onUpdateAnyData={() => {
                                    setShouldLoadBalance(true)
                                }}
                            />
                        </CardCP>

                    </ContentSCP>
                </ContentWrapperSCP>
            </LayoutSubmenuContentCP>

            <DrawerBankAccountWithdrawCP
                code={selectedWithdrawCode}
                visible={isDrawerVisible}
                onClose={(dataChanged) => {
                    setShouldLoadReport(dataChanged ?? false)
                    setIsDrawerVisible(false)
                    setSelectedWithdrawCode(undefined)
                    setShouldLoadBalance(true)
                }}
            />
        </>
    )
}

const ContentSCP = styled.div`
    width: 100%;
`

const ContentWrapperSCP = styled.div`
    margin-top: 25px;
    display: flex;
    overflow-x: auto;
    justify-content: center;
`
