import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

/**
 * ICONE
 * Animacao de carregamento.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 * @author Stella
 */
export function LoadingIconCP(props: IIconProps): JSX.Element {
    return <IconICP iconName={'loading'} {...props}/>
}
