import { ButtonCP } from 'main/common/components/button/ButtonCP'
import React, { useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import { ModalPersonBulkOpportunityCP } from 'main/modules/people/components/modal-person-bulk-opportunity/ModalPersonBulkOpportunityCP'

interface ICSV {
    exportarcsv?:()=>void
    carregandoExportacao?:boolean
}

/**
 * COMPONENTE
 * Conteudo da barra de acoes da area de listagem de pessoas.
 */
export function RightContentICP(props:ICSV): JSX.Element {
    const [isBulkOpportunityModalVisible, setIsBulkOpportunityModalVisible] = useState<boolean>(false)
    const screenContext = PersonContext.use()

    return (
        <>
            <FlexCP justifyContent={'flex-end'}>
                <ButtonCP
                    onClick={() => setIsBulkOpportunityModalVisible(true)}
                    icon={'experiment'}
                    type={'dashed'}
                    marginRight={5}
                >
                    Criar oportunidades
                </ButtonCP>
                <ButtonCP
                    onClick={() => props.exportarcsv?props?.exportarcsv():''}
                    icon={'table'}
                    type={'dashed'}
                    tooltip='Exporta os registros para o excel'
                    marginRight={5}
                    loading={props.carregandoExportacao}
                >
                   Exportar CSV
                </ButtonCP>
                <ButtonCP
                    type={'dashed'}
                    icon={'filter'}
                    onClick={(): void => screenContext.actions.setMustUpdatePeopleList(true)}
                >
                    Limpar filtros
                </ButtonCP>
                
            </FlexCP>

            <ModalPersonBulkOpportunityCP
                visible={isBulkOpportunityModalVisible}
                queryExpression={screenContext.state.expression}
                onClose={() => setIsBulkOpportunityModalVisible(false)}
            />
        </>
    )
}
