import * as _ from 'lodash'
import { IFullCalendarDateInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarDateInfo'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { IFullCalendarEventInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventInfo'
import { IAppointmentDetails } from 'main/modules/scheduler/interfaces/IAppointmentDetails'
import { IAttendantDisplay } from 'main/modules/scheduler/interfaces/IAttendantDisplay'
import { IUserAttendancesResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendancesResponseDTO'
import { ScheduleEventUtils } from 'main/modules/scheduler/utils/ScheduleEventUtils'
import React, { useEffect, useState } from 'react'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import moment from 'moment/moment'
import { IFullCalendarEventDropInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventDropInfo'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { CalendarClinicCP } from 'main/modules/scheduler/components/calendar-clinic/CalendarClinicCP'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { IFullCalendarEventResize } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventResize'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ScheduleAppointmentChangeEndTimeRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleAppointmentChangeEndTimeRequestDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ScheduleRequests } from 'submodules/neritclin-sdk/services/schedule/ScheduleRequests'
import { CalendarEventsSubtitleCP } from 'main/modules/scheduler/components/calendar-events-subtitle/CalendarEventsSubtitleCP'
import styled from 'styled-components'
import { CalendarEventsSummaryICP } from 'main/modules/scheduler/components/calendar-events/inner/CalendarEventsSummaryICP'

interface ISchedulerCalendarCPProps {
    userAttendances: IUserAttendancesResponseDTO[]
    evaluations: IOpportunityEvaluationScheduleResponseDTO[]
    selectedAttendantCode?: number
    onSelectDateToSchedule: (data: IScheduleSlotInitialData) => void
    onSelectSessions: (appointmentDetails: IAppointmentDetails) => void
    onSelectEvaluation: (evaluationCode: number) => void
    onSelectBlockInterval: (data: IScheduleSlotInitialData) => void
    reloadEvents: () => void
    loading?: boolean
    filtersFormStateManager: IFormStateManager<FormFilterCalendarEventsFormModel>
    shouldLoadSummary: number
}

/**
 * Representa o conteúdo do componente de Calendário da agenda.
 */
export function CalendarEventsInnerICP(props: ISchedulerCalendarCPProps): JSX.Element {

    // O FullCalendarCP entende 'resources'. 'resources' para o sistema são atendentes.
    const [attendantList, setAttendantList] = useState<IAttendantDisplay[]>([])
    const [fullcalendarEvents, setFullCalendarEvents] = useState<IFullCalendarEvent[]>()

    const reScheduleSessionsRequest = useRequest<void>('none')
    useEffect(onReScheduleRequestChange, [reScheduleSessionsRequest.isAwaiting])

    useEffect(defineSessions, [props.userAttendances])
    useEffect(defineEvaluations, [props.evaluations])

    /**
     * Chamado pela atualização dos dados da agenda (appointmentsList). Extrai deles os recursos e os eventos separadamente
     */
    function defineSessions(): void {

        // Separa da lista o que eh avaliacao do que eh sessao
        const evaluationEvents = _.filter(fullcalendarEvents, (event) => {
            return event.extendedProps?.type === 'evaluation'
        })

        const isWeekView = ScheduleEventUtils.isWeekView(props.filtersFormStateManager.getFieldValue('dateInterval'))

        const userScheduledSessions = _.flatten(props.userAttendances.map((s) => ScheduleEventUtils.groupNearestAppointmentsByCustomer(s, isWeekView)))
        const userScheduledBlockeds = ScheduleEventUtils.mountUnavailableEventsDataByUser(props.userAttendances)

        setAttendantList(props.userAttendances.map((s) => ScheduleEventUtils.getAttendantConfig(s)))

        // Na visao semanal devemos criar os horarios desabilitados para os dias/horas que a clinica esta fechada
        // Este tratamento nao eh feito no diario, pois no diario eh considerar o start/end bussinessHour do funcionario por dia
        let franchiseBlockedOnWeekView: IFullCalendarEvent[] = []
        if (isWeekView)
            franchiseBlockedOnWeekView = ScheduleEventUtils.mountUnavailableEventsDataByFranchise(props.filtersFormStateManager.getFieldValue('dateInterval'))

        // Resultado com os eventos
        const allEvents: IFullCalendarEvent[] = _.concat(evaluationEvents, userScheduledSessions, userScheduledBlockeds, franchiseBlockedOnWeekView)
        setFullCalendarEvents(allEvents)
    }

    /**
     * Monta as avaliacoes
     */
    function defineEvaluations(): void {

        const sessionEvents = _.filter(fullcalendarEvents, (event) => {
            return event.extendedProps?.type !== 'evaluation'
        })

        if (_.isEmpty(props.evaluations)) {
            // Como nao eh para exibir as avaliacoes, deixamos a lista de eventos apenas as sessoes
            return setFullCalendarEvents(sessionEvents)
        }

        const evaluationsCalendarEvents = ScheduleEventUtils.mountEvaluationsEventsData(props.evaluations)

        // No resultado final teremos as avaliacoes e sessoes
        const allEvents = _.concat(evaluationsCalendarEvents, sessionEvents)
        setFullCalendarEvents(allEvents)
    }

    /**
     * Clicar em uma data vazia do calendário é entendido como pedido para 'marcar um novo agendamento'.
     * O ScreenContentSchedulerCP vai controla isso após receber o appointmentData.
     */
    function onDateClick(dateInfo: IFullCalendarDateInfo): void {

        if (!dateInfo) {
            NotificationHelper.error('Ops', 'Informação de data inválida')
            return
        }

        // O atendende resposavel vira do evento (caso a agenda esteja por recurso) ou do selecionado caso tenha filtrado por um
        let selectedAttendant: OrUndefinedTP<NameAndCodeResponseDTO> = undefined

        if (!!dateInfo.resource?.id)
            selectedAttendant = { code: dateInfo.resource.id, name: dateInfo.resource.title }
        else if (!!props.selectedAttendantCode)
            selectedAttendant = props.userAttendances.find((userAtt) => userAtt.user.code === props.selectedAttendantCode)?.user

        let selectedInitialTime = moment(dateInfo.date)

        // Se nao teve selecionado, abre o drawer de agendamento sem essa info
        if (!selectedAttendant) {
            props.onSelectDateToSchedule({ initialTime: selectedInitialTime })
            return
        }

        // Faz as validacoes caso nao seja permitido sobrescrever os horarios
        if (AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.schedule?.shouldValidateOverlaps) {
            selectedInitialTime = ScheduleEventUtils.checkIntervalForAppointmentOverlap(dateInfo.date, selectedAttendant.code, fullcalendarEvents ?? [])

            if (!ScheduleEventUtils.checkBusinessHours(dateInfo.date, selectedAttendant.code, attendantList) || !selectedInitialTime) {
                NotificationHelper.error('Ops!', 'O horário selecionado não está habilitado para o usuário')
                return
            }
        }

        props.onSelectDateToSchedule({
            initialTime: selectedInitialTime,
            userProfessional: selectedAttendant,
        })
    }

    /**
     * Clicar em uma data NÃO vazia (clicar em um Event) do calendário é entendido como pedido para 'ver detalhes agendamento'.
     * o ScreenContentSchedulerCP vai controla isso após receber o appointmentDetailsData.
     */
    function onEventClick(eventInfo: IFullCalendarEventInfo): void {

        if (!PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_SCHEDULER]))
            return

        // Verifica se eh horario bloqueado
        if (eventInfo.event.extendedProps.type === 'unavailable') {
            const unavailableAppointmentDetails = ScheduleEventUtils.getUnavailableAppointmentDetails(eventInfo.event, props.userAttendances)
            if (!!unavailableAppointmentDetails?.userAttendanceScheduleCode)
                props.onSelectBlockInterval(unavailableAppointmentDetails)

            return
        }

        // Verifica se eh avaliacao
        if (eventInfo.event.extendedProps.type === 'evaluation')
            return props.onSelectEvaluation(eventInfo.event.extendedProps.code[0])

        // Monta os dados do evento
        const appointmentDetailsData = ScheduleEventUtils.mountAppointmentDetails(eventInfo.event, props.userAttendances)
        if (!appointmentDetailsData)
            return

        props.onSelectSessions(appointmentDetailsData)
    }

    /**
     * Ao aumentar ou diminuir um evento
     */
    function onResize(eventResizeInfo: IFullCalendarEventResize): void {

        const originalEndTime = eventResizeInfo.prevEvent.end
        const newEndTime = eventResizeInfo.event.end

        if (!originalEndTime || !newEndTime) {
            NotificationHelper.error('Ops', 'Erro ao obter novo horário')
            return
        }

        const dto: ScheduleAppointmentChangeEndTimeRequestDTO = {
            sessionCodes: eventResizeInfo.event.extendedProps.code,
            endTimeDelta: DateUtils.getDiff(TimeBaseEnum.MINUTE, originalEndTime, newEndTime),
        }
        reScheduleSessionsRequest.runRequest(ScheduleRequests.changeEndTime(dto))
    }

    /**
     * Ao realizar pegar um evento e mudar de hora ou profissional.
     */
    function onEventDrop(eventDropInfo: IFullCalendarEventDropInfo): void {

        const requestoDto = ScheduleEventUtils.mountRescheduleEventDto(eventDropInfo, props.userAttendances)
        if (!requestoDto)
            return

        reScheduleSessionsRequest.runRequest(ScheduleRequests.reScheduleSessions(requestoDto))
    }

    /**
     * Horários alterados com sucesso.
     */
    function onReScheduleRequestChange(): void {

        if (reScheduleSessionsRequest.isAwaiting || !reScheduleSessionsRequest.wasTried)
            return

        if (!RequestUtils.isValidRequestReturn(reScheduleSessionsRequest, 'Ops! Ocorreu algum erro ao alterar horário')) {
            setFullCalendarEvents(undefined)
            props.reloadEvents()
            return
        }

        if (reScheduleSessionsRequest.isSuccess)
            NotificationHelper.success('Pronto!', 'Horário alterado com sucesso')
    }

    /**
     * Quando for alterado a visao ou alterado o range de datas, informa o FM e recarrega.
     */
    function onChangeDateRange(dateRange: IDateRangeFilter): void {

        // O FullCalendar mando um dia a mais às 00:00. Tira um minuto para deixar no ultimo dia correto
        dateRange.endDate = DateUtils.add(dateRange.endDate!, -1, TimeBaseEnum.MINUTE)

        props.filtersFormStateManager.changeFieldValue('dateInterval', dateRange)
        props.reloadEvents()
    }

    if (!fullcalendarEvents)
        return <></>

    return (
        <ContentSCP>
            <CalendarClinicCP
                headerRight={['resourceTimeGridDay', 'timeGridWeek','customDaysView','customDaysView2']}
                hideHeader={false}
                onDateRangeChange={onChangeDateRange}
                events={fullcalendarEvents}
                resources={attendantList}
                selectedDate={ScheduleEventUtils.isWeekView(props.filtersFormStateManager.getFieldValue('dateInterval'))
                    ? props.filtersFormStateManager.getFieldValue('dateInterval')
                    : props.filtersFormStateManager.getFieldValue('dateInterval')?.beginDate
                }
                defaultView={AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.schedule?.shouldOpenInWeekRange === true ? 'timeGridWeek' : 'resourceTimeGridDay'}
                onDateClick={onDateClick}
                onEventClick={onEventClick}
                onEventDrop={onEventDrop}
                onEventResize={AppStateUtils.getCurrentFranchise()!.paramsConfig?.businessRulesConfig?.schedule?.shouldEnableEndTimeChange ? onResize : undefined}
                loading={reScheduleSessionsRequest.isAwaiting || props.loading}
            />
            <CalendarEventsSummaryICP
                filtersFormStateManager={props.filtersFormStateManager}
                shouldLoadSummary={props.shouldLoadSummary}
            />

            <CalendarEventsSubtitleCP/>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
    overflow-x: auto;
    justify-content: center;
`
