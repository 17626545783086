import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { AppointmentDetailsTableUtils } from 'main/modules/scheduler/components/appointments-details-table/inner/AppointmentDetailsTableUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import styled from 'styled-components'

interface IAppointmentsDetailsTableCPProps {
    sessions: ISessionResponseDTO[]
    onAnswerSession: (session: ISessionResponseDTO) => void
    onCancelSession: (rowIndex: number) => void
    onSelectSale: (saleCode: number) => void
    hideAnswerAction?: boolean
}

/**
 * COMPONENTE de tabela que exibe detalhes de um agendamento, em geral, ou das sessões que irão ser consumidas em um agendamento
 */
export function AppointmentsDetailsTableCP(props: IAppointmentsDetailsTableCPProps): JSX.Element {

    const [sessionToCancelRowIndex, setSessionToCancelRowIndex] = useState<number>()

    const cancelSessionRequest = useRequest<void>('none')
    useEffect(onCancelSessionRequestChange, [cancelSessionRequest.isAwaiting])

    /**
     * Remover sessao.
     */
    function cancelSession(code: number, rowIndex: number): void {
        setSessionToCancelRowIndex(rowIndex)
        cancelSessionRequest.runRequest(SchedulerRequests.cancelAppointments([code]))
    }

    /**
     *  Retorno da remocao de sessao.
     */
    function onCancelSessionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(cancelSessionRequest, 'Não foi cancelar o agendamento', true))
            return

        NotificationHelper.success('Pronto!', 'Sessão cancelada com sucesso')
        props.onCancelSession(sessionToCancelRowIndex!)
    }

    return (
        <WrapperSCP>
            <TableCPOLD2
                data={props.sessions}
                loading={cancelSessionRequest.isAwaiting}
                columns={AppointmentDetailsTableUtils.getDetailsTableColumnsConfig(
                    props.onAnswerSession,
                    cancelSession,
                    props.onSelectSale,
                    props.hideAnswerAction
                )}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  .ant-table {
    overflow: scroll;
  }
`
