import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import React from 'react'
import { SelectRecurrenceCP } from 'main/modules/financial/components/select-recurrence/SelectRecurrenceCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ScheduleSessionsFormModel } from 'main/modules/scheduler/components/drawer-schedule-session/inner/ScheduleSessionsFormModel'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { HelpCP } from 'main/common/components/help/HelpCP'

interface IAppointmentDetailsCPProps {
    formStateManager: IFormStateManager<ScheduleSessionsFormModel>
}

/**
 * Detalhes de um agendamento
 */
export function SwitchRecurrenceAppointmentCP(props: IAppointmentDetailsCPProps): JSX.Element {

    return (
        <>
            <RowCP>
                <ColumnCP size={12}>
                    <SwitchCP
                        textOutside={'Agendar sessões recorrentes?'}
                        formStateManager={props.formStateManager}
                        fieldName={'isRecurrent'}
                        isTextInside={false}
                        hint={{ type: 'tooltip', text: 'Todas as sessões subsequentes dos tratamentos selecionados serão agendadas automaticamente, segundo a periodicidade informada.' }}
                    />
                </ColumnCP>

                <ColumnCP size={12}>
                    <ConditionalRenderCP shouldRender={props.formStateManager.getFieldValue('isRecurrent')}>
                        <SelectRecurrenceCP
                            label={'Repetir:'}
                            formStateManager={props.formStateManager}
                            fieldName={'recurrencePeriod'}
                        />
                    </ConditionalRenderCP>
                </ColumnCP>
            </RowCP>

            {
                props.formStateManager.getFieldValue('isRecurrent') && !!props.formStateManager.getFieldValue('recurrencePeriod') &&
                <HelpCP
                    text={'Serão agendadas as sessões seubsequentes de cada tratamento selecionado no mesmo dia da semana (ex.: segunda). A cada semana ou a cada mês e aí por diante...'}
                    type={'text'}
                />
            }
        </>
    )
}
