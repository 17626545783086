import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { PrivateAccessVerifierCP } from 'main/modules/app/components/app/inner/PrivateAccessVerifierCP'
import { AuthRoutes } from 'main/modules/auth/AuthRoutes'
import { LoginScreen } from 'main/modules/auth/screens/LoginScreen'
import { RecoverPasswordScreen } from 'main/modules/auth/screens/RecoverPasswordScreen'
import { ScreenUploadFiles } from 'main/modules/app/screens/screen-upload-files/ScreenUploadFiles'
import { ScreenUpdateChargePaymentData } from 'main/modules/financial/screens/screen-update-charge-payment-data/ScreenUpdateChargePaymentData'

PublicRouter.UPLOAD_FILES = `/upload-files`
PublicRouter.UPDATE_CHARGE_PAYMENT_DATA = `/update-payment-data/:token?`

PublicRouter.PUBLIC_ROUTES_CONFIG = [
    {
        path: AuthRoutes.USER_LOGIN,
        component: LoginScreen,
        noSchema: true,
        exact: true,
    },
    {
        path: AuthRoutes.RECOVER_PWD,
        component: RecoverPasswordScreen,
        noSchema: true,
        exact: true,
    },
    {
        path: PublicRouter.UPLOAD_FILES,
        component: ScreenUploadFiles,
        noSchema: true,
        exact: true,
    },
    {
        path: PublicRouter.UPDATE_CHARGE_PAYMENT_DATA,
        component: ScreenUpdateChargePaymentData,
        noSchema: true,
        exact: true,
    },
    {
        key: 'verifier',
        path: 'root',
        component: PrivateAccessVerifierCP,
    },

] as RouteConfigTP[]

/**
 * Define rotas que podem ser acessadas sem realizar login.
 */
export function PublicRouter(): JSX.Element {

    return RoutingHelper.renderPublicRoutingSwitch()
}
