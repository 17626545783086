import React, { useEffect, useState } from 'react'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { UploadFileCP } from 'submodules/nerit-framework-ui/common/components/file/upload-file/UploadFileCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ListCP } from 'submodules/nerit-framework-ui/common/components/list/ListCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonFilesPreviewCP } from 'submodules/nerit-framework-ui/common/components/file-preview/button-files-preview/ButtonFilesPreviewCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import "photoviewer/dist/photoviewer.min.css"
import { ListItemFileICP } from 'submodules/nerit-framework-ui/common/components/file/list-files-from-api/inner/ListItemFileICP'

interface ICPProps extends BasicStyleWrapperCPProps {
    entityCode: number
    showReloadButton?: boolean
    requestsConfig: {
        searchRequestConfigTP: (code: number) => RequestConfigTP
        deleteRequestConfigTP?: (dto: any) => RequestConfigTP
        uploadRequestConfigTP?: (code: number, formData: FormData) => RequestConfigTP
        customUploadParamenter?: {
            parameterName: string
            parameterValue: any
        }
    }
    maxMbSize?: number
    qrCode?: {
        visible: boolean
        url: string
    }
    showCaptureFromClipboard?: boolean
    permissions?: {
        canUpload: boolean
        canRemove: boolean
    }
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ListFilesFromApiCP(props: ICPProps): JSX.Element {

    const [isUploading, setIsUploading] = useState<boolean>(false)

    const getFilesRequest = useRequest<ListResponseDTO<FileDataTP>>()
    useEffect(onGetFilesRequestChange, [getFilesRequest.isAwaiting])

    useEffect(init, [props.entityCode])

    /**
     * Busca arquivos.
     */
    function init(): void {
        getFilesRequest.runRequest(props.requestsConfig.searchRequestConfigTP(props.entityCode))
    }

    /**
     * Retorno API de arquivos
     */
    function onGetFilesRequestChange(): void {

        RequestUtils.isValidRequestReturn(getFilesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)
    }

    /**
     * Faz upload do arquivo.
     */
    function uploadFile(file: File): void {

        if (!props.requestsConfig.uploadRequestConfigTP)
            return

        setIsUploading(true)

        const formData = new FormData()
        formData.append('file', file)

        if (!!props.requestsConfig.customUploadParamenter)
            formData.append(props.requestsConfig.customUploadParamenter.parameterName, props.requestsConfig.customUploadParamenter.parameterValue)

        const requestCofigTP = props.requestsConfig.uploadRequestConfigTP(props.entityCode, formData)
        requestCofigTP.avoidParamTransformer = true

        // Faz a chamada dessa forma pois em multiplos nao funciona via useRequest
        RequestHelper.runRequest(requestCofigTP).then(() => {
            init()
            setIsUploading(false)
        }).catch((request) => RequestUtils.handleError(request, request.data?.data?.errors)
        ).finally(() => setIsUploading(false))
    }

    return (
        <BasicStyleWrapperCP margin={props.margin ?? { top: 20 }}>
            {
                (!props.permissions || props.permissions?.canUpload) && !!props.requestsConfig.uploadRequestConfigTP &&
                <BasicStyleWrapperCP margin={{ bottom: 20 }}>
                    <UploadFileCP
                        uploadFiles={uploadFile}
                        loading={isUploading}
                        maxMbSize={props.maxMbSize ?? 10}
                        qrCode={props.qrCode}
                        multiple={true}
                        showCaptureFromClipboard={props.showCaptureFromClipboard}
                    />
                </BasicStyleWrapperCP>
            }

            <FlexCP
                justifyContent={ArrayUtils.isEmpty(getFilesRequest.responseData?.list) ? 'flex-end' : 'space-between'}
                margin={{ bottom: 10 }}
            >
                {
                    !ArrayUtils.isEmpty(getFilesRequest.responseData?.list) &&
                    <ButtonFilesPreviewCP
                        size={'small'}
                        files={getFilesRequest.responseData!.list}
                    />
                }

                <ButtonCP
                    size={'small'}
                    type={'ghost'}
                    icon={'reload'}
                    onClick={init}
                    loading={getFilesRequest.isAwaiting}
                />
            </FlexCP>

            <ListCP<FileDataTP>
                loading={getFilesRequest.isAwaiting}
                dataSource={getFilesRequest.responseData?.list}
                renderItem={(item) => (
                    <ListItemFileICP
                        entityCode={props.entityCode}
                        file={item}
                        onFileDeleted={init}
                        requestsConfig={props.requestsConfig}
                    />
                )}
            />

        </BasicStyleWrapperCP>
    )
}
