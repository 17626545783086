import React, { useEffect, useState } from 'react'
import { ModalPayFinancialTransactionCP } from 'main/modules/financial/components/modal-pay-financial-transaction/ModalPayFinancialTransactionCP'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IButtonPayFinancialTransactionCPProps {
    financialTransactionCode: number
    financialTransactionStatus: FinancialTransactionStatusEnum
    onChangePayment: () => void
    showText: boolean
    marginLeft?: number
    marginRight?: number
}

/**
 * Componente de modal com dados para pagamento de uma transacao financeira.
 */
export function ButtonPayFinancialTransactionCP(props: IButtonPayFinancialTransactionCPProps): JSX.Element {

    const [isPayInstallmentModalVisible, setIsPayInstallmentModalVisible] = useState<boolean>(false)

    const unPayRequest = useRequest('none')
    useEffect(onUnPayRequestChange, [unPayRequest.isAwaiting])

    /**
     * Requisicao para despagar uma fatura.
     */
    function unPay(): void {
        unPayRequest.runRequest(FinancialTransactionsRequests.unPay(props.financialTransactionCode))
    }

    /**
     * Retorno da requisicao para despagar a transacao financeira.
     */
    function onUnPayRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(unPayRequest, 'Erro ao marcar transação como em aberto'))
            return

        NotificationHelper.success('Pronto!', 'Transação marcada como em aberto')
        props.onChangePayment()
    }

    return (
        <>
            <ConditionalRenderCP shouldRender={props.financialTransactionStatus !== FinancialTransactionStatusEnum.PAID}>
                <ButtonCP
                    type={'ghost'}
                    onClick={() => setIsPayInstallmentModalVisible(true)}
                    marginRight={props.marginRight}
                    marginLeft={props.marginLeft}
                    icon={'audit'}
                    tooltip={!props.showText ? 'Marcar como pago' : undefined}
                    size={'small'}
                >
                    { props.showText ? 'Pagar' : undefined }
                </ButtonCP>
            </ConditionalRenderCP>
            <ConditionalRenderCP shouldRender={props.financialTransactionStatus === FinancialTransactionStatusEnum.PAID}>
                <ButtonCP
                    type={'danger'}
                    onClick={unPay}
                    marginRight={props.marginRight}
                    marginLeft={props.marginLeft}
                    icon={'disconnect'}
                    confirmMsg={'Deseja realmente cancelar o pagamento da transação e marcar como em aberto?'}
                    size={'small'}
                    loading={unPayRequest.isAwaiting}
                />
            </ConditionalRenderCP>

            <ModalPayFinancialTransactionCP
                visible={isPayInstallmentModalVisible}
                financialTransactionCode={props.financialTransactionCode}
                onClose={() => setIsPayInstallmentModalVisible(false)}
                onSave={() => {
                    setIsPayInstallmentModalVisible(false)
                    props.onChangePayment()
                }}
            />
        </>
    )
}
