import { ShotListCP } from 'main/modules/marketing/components/shot-list/ShotListCP'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import React from 'react'
import styled from 'styled-components'

interface IShotListScreenContentCPProps {
    shotType: MarketingAccountTypeEnum
    shotStatus: MarketingShotStatusEnum[]
}

/**
 * Tabs para seperacao de disparos de mkt por tipo.
 */
export function ShotListScreenContentCP(props: IShotListScreenContentCPProps): JSX.Element {

    return (
        <ContentSCP>
            <ShotListCP
                shotType={props.shotType}
                shotStatuses={props.shotStatus}
            />
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
  padding: 20px;
  max-width: 100%;
`
