import { FormModel } from 'main/common/form-state-manager/FormModel'
import React, { useState } from 'react'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { VoucherRequests } from 'main/modules/sale/services/voucher/VoucherRequests'
import { IVoucherResponseDTO } from 'main/modules/sale/services/voucher/dtos/response/IVoucherResponseDTO'

interface IAutocompleteBankAccountCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    onSelectVoucher: (voucher?: IVoucherResponseDTO) => void
}

/**
 * Seletor de cupons de desconto
 */
export function SelectVoucherCP<FModelTP extends FormModel = any>(props: IAutocompleteBankAccountCPProps<FModelTP>): JSX.Element {

    const [vouchers, setVouchers] = useState<IVoucherResponseDTO[]>()

    /**
     * Ao selecionar um voucher
     */
    function onSelectVoucher(selectedVoucherCode: number): void {
        props.onSelectVoucher(vouchers?.find((voucher) => voucher.code === selectedVoucherCode))
    }

    return (
        <AutocompletePickerCP
            requestConfigGetter={VoucherRequests.search}
            showOptionsOnLoad={true}
            onLoadOptions={setVouchers}
            onChange={onSelectVoucher}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
