import React from 'react'
import { faUniversity } from '@fortawesome/free-solid-svg-icons'
import { AvatarCP } from 'main/common/components/avatar/AvatarCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { styled } from 'main/config/theme/styledWithTheme'

interface IIconBankAccountLogoCPProps {
    bankLogoUrl?: string
    showBorder?: boolean
}

/**
 * Logo do banco ou icone padrao
 */
export function IconBankAccountLogoCP(props: IIconBankAccountLogoCPProps): JSX.Element {

    return (
        <IconSCP showBorder={props.showBorder}>
            {
                props.bankLogoUrl
                    ?
                    <AvatarCP
                        src={props.bankLogoUrl}
                        size={40}
                        shape={'square'}
                    />
                    :
                    <FontAwsomeIconCP type={faUniversity} size={'2x'}/>
            }
        </IconSCP>
    )
}

const IconSCP = styled.div<{ showBorder?: boolean }>`
    text-align: center;
    border: ${props => props.showBorder ? 'dashed 1px #adadad' : ''};
    padding: 7px 10px;
    margin-right: 10px;
`
