import React from 'react'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    isChecked: boolean
    onChangeChecked: (checked: boolean) => void
    text: string
}

/**
 * COMPONENTE que exibe uma permissao
 */
export function UserSubPermissionsDataICP(props: ICPProps): JSX.Element {

    return (
        <FlexCP margin={{ left: 15 }} alignItems={'center'}>
            <SwitchCP
                isTextInside={false}
                isChecked={props.isChecked}
                onChange={props.onChangeChecked}
            />
            <TextCP text={props.text} marginLeft={5}/>
        </FlexCP>
    )
}
