import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { ChargeRetryRequestDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/request/ChargeRetryRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ChargeHistoryResponseDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/response/ChargeHistoryResponseDTO'
import { ChargePaymentDataUpdateRequestDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/request/ChargePaymentDataUpdateRequestDTO'

export class ChargeRequests {

	public static CONTROLLER_ROOT = 'sale-charge'
	public static CHARGE_RETRY_EP = 'charge-retry'
	public static CHARGE_CANCELLATION_EP = 'charge-cancellation'
	public static SEARCH_CHARGE_HISTORY_EP = 'search-charge-history/:chargeCode'
	public static UPDATE_PAYMENT_DATA_EP = 'update-payment-data'

	static chargeRetry = (dto: ChargeRetryRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ChargeRequests.CONTROLLER_ROOT}/${ChargeRequests.CHARGE_RETRY_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static chargeCancellation = (dto: any): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ChargeRequests.CONTROLLER_ROOT}/${ChargeRequests.CHARGE_CANCELLATION_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static searchChargeHistory = (chargeCode: number): RequestConfigTP<ListResponseDTO<ChargeHistoryResponseDTO>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ChargeRequests.CONTROLLER_ROOT}/${ChargeRequests.SEARCH_CHARGE_HISTORY_EP.replace(':chargeCode', chargeCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static updatePaymentData = (dto: ChargePaymentDataUpdateRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${ChargeRequests.CONTROLLER_ROOT}/${ChargeRequests.UPDATE_PAYMENT_DATA_EP}`,
		method: HttpMethodEnum.PUT,
        params: dto,
	})
}
