import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { TableDreUtils } from 'main/modules/financial/components/table-dre/inner/TableDreUtils'
import { TableCP } from 'main/common/components/table/TableCP'
import { FinancialTransactionReportRequests } from 'submodules/neritclin-sdk/services/financial/financial-transactions-report/FinancialTransactionReportRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ProfitAndLossStatementResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/response/ProfitAndLossStatementResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICPProps {
    filterStateManager: IFormStateManager<ReportsCommonFiltersFormModel>
    shouldReloadReport?: number
}

/**
 * Tabela do DRE
 */
export function TableDreCP(props: ICPProps): JSX.Element {

    // Mapa com os valores. Chave 1 eh a categoria, chave 2 eh o mes
    const [reportData, setReportData] = useState<Map<string, Map<string, number | string>>>()
    const [consolidatedLabel, setConsolidatedLabel] = useState<string>()
    const getReportRequest = useRequest<ProfitAndLossStatementResponseDTO[]>()
    useEffect(onGetReportRequestChange, [getReportRequest.isAwaiting])

    useEffect(init, [props.shouldReloadReport])

    /**
     * Inicializa dados da tela
     */
    function init(): void {

        if (!props.shouldReloadReport)
            return

        const filters = TableDreUtils.mountFiltersDto(props.filterStateManager.getFormValues()!)
        if (!filters)
            return

        getReportRequest.runRequest(FinancialTransactionReportRequests.dre(filters))
    }

    /**
     * Retorno da requisicao para obter dados do relatorio.
     */
    function onGetReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        if (props.filterStateManager.getFieldValue('showConsolidated'))
            setConsolidatedLabel(`${DateUtils.formatDate(props.filterStateManager.getFieldValue('dateRange').beginDate, DateFormatEnum.BR_EXT_MONTH_YEAR)} ~ ${DateUtils.formatDate(props.filterStateManager.getFieldValue('dateRange').endDate, DateFormatEnum.BR_EXT_MONTH_YEAR)}`)
        else
            setConsolidatedLabel(undefined)


        // Mapa com os valores. Chave 1 eh a categoria, chave 2 eh o mes
        const dreMap = new Map<string, Map<string, number>>()

        const dtoResult = getReportRequest.responseData!
        dtoResult.forEach((result) => {
            const dateRangeKey = DateUtils.formatDate(result.dateRange.beginDate, DateFormatEnum.BR_EXT_MONTH_YEAR)
            addtoMap('(+) Receita Bruta', dateRangeKey, result.grossRevenue, dreMap)
            addtoMap('(-) Despesas Fixas', dateRangeKey, -result.fixedExpenses, dreMap)
            addtoMap('(-) Despesas Váriáveis', dateRangeKey, -result.variableExpenses, dreMap)
            addtoMap('(-) Despesas com pessoal', dateRangeKey, -result.staffExpenses, dreMap)
            addtoMap('(=) Resultado Bruto (sem imposto)', dateRangeKey, result.profitBeforeTax, dreMap)
            addtoMap('(-) Impostos', dateRangeKey, -result.taxes, dreMap)
            addtoMap('(=) Resultado Líquido', dateRangeKey, result.netProfit, dreMap)
            addtoMap('', dateRangeKey, result.message, dreMap)
        })
        setReportData(dreMap)
    }

    /**
     */
    function addtoMap(key1: string, key2: string, value: number | string, map: Map<string, Map<string, number | string>>): void {

        const valueOfKey1 = map.get(key1)
        if (valueOfKey1) {
            valueOfKey1.set(key2, value)

        } else {
            const map2 = new Map<string, number | string>()
            map2.set(key2, value)
            map.set(key1, map2)
        }

    }

    if (!reportData)
        return <LoadingOverlayCP show={true}/>

    return (
        <>
            <TableCP
                wrappedOnCard={true}
                loading={getReportRequest.isAwaiting}
                data={Array.from(reportData.entries()).map((entry) => entry[0])}
                columns={TableDreUtils.getTableColumns(
                    reportData,
                    consolidatedLabel
                )}
            />
        </>
    )
}
