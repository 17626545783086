import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PurchaseListItemICP } from 'main/modules/people/components/list-person-purchase/inner/PurchaseListItemICP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IPersonPurchaseListItemResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonPurchaseListItemResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { IGenericSearchRequestDTO } from 'main/common/dtos/requests/IGenericSearchRequestDTO'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { ListCP } from 'main/common/components/list/ListCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerCreateSaleCP } from 'main/modules/sale/components/drawer-create-sale/DrawerCreateSaleCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

interface IListPersonPurchaseCPProps {
    person: INameAndCodeResponseDTO
}

/**
 * Componente Lista de compras de uma pessoa.
 */
export function ListPersonPurchaseCP(props: IListPersonPurchaseCPProps): JSX.Element {

    const [isSaleDrawerVisible, setIsSaleDrawerVisible] = useState<boolean>(false)
    const [isCreateSaleDrawerVisible, setIsCreateSaleDrawerVisible] = useState<boolean>(false)

    useEffect(init, [props.person])
    const [personPurchases, setPersonPurchases] = useState<IPersonPurchaseListItemResponseDTO[]>()
    const [saleCode, setSaleCode] = useState<number>()

    const getPersonPurchasesRequest = useRequest<IGenericListResponseDTO<IPersonPurchaseListItemResponseDTO>>()
    useEffect(onGetPersonPurchasesRequestChange, [getPersonPurchasesRequest.isAwaiting])

    /**
     * Inicializa a tela
     */
    function init(): void {

        const searchDto: IGenericSearchRequestDTO = {
            page: 1,
            itemsPerPage: 99
        }

        getPersonPurchasesRequest.runRequest(PersonRequests.purchasesConfig(props.person.code, searchDto))
    }

    /**
     * Retorno ao obter lista de atividades
     */
    function onGetPersonPurchasesRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getPersonPurchasesRequest, 'Erro ao buscar compras dos cliente'))
            return
        console.log("DADOS DO USUARIO",AppStateUtils.getLoggedUserData())
        console.log("COMPRAS", getPersonPurchasesRequest.responseData!.list)
        if(AppStateUtils.getLoggedUserData()?.user?.email=='suporte@neritclin.com.br'){
            console.log("E SUPORTE TOMA!");
            setPersonPurchases(getPersonPurchasesRequest.responseData!.list);
            return;
        }
        if(!AppStateUtils.getLoggedUserData()?.user?.franchisor?.isFranchisor && !AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.VER_VENDAS_OUTRAS_UNIDADES)){
            console.log("VISAO REDUZIDA")
            let lista = getPersonPurchasesRequest.responseData!.list;
            lista = lista.filter((i:any)=>i.franchise.code==AppStateUtils.getCurrentFranchise()?.code);
            setPersonPurchases(lista);
            return;
        }
        console.log("VISAO COMPLETA")
        setPersonPurchases(getPersonPurchasesRequest.responseData!.list)
    }


    return (
        <>
            <FlexCP justifyContent={'flex-end'}>
                <ButtonCP
                    icon={'plus'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => setIsCreateSaleDrawerVisible(true)}
                >
                    Nova venda
                </ButtonCP>
            </FlexCP>

            <ListContainerSCP>
                <ListCP<IPersonPurchaseListItemResponseDTO>
                    itemLayout={'vertical'}
                    bordered={false}
                    dataSource={personPurchases}
                    pagination={false}
                    loading={getPersonPurchasesRequest.isAwaiting}
                    locale={{ emptyText: 'Nenhuma compra realizada' }}
                    renderItem={(item) => (
                        <PurchaseListItemICP
                            puchase={item}
                            onSelectSale={() => {
                                setSaleCode(item.code)
                                setIsSaleDrawerVisible(true)
                            }}
                        />
                    )}
                />
            </ListContainerSCP>

            {
                saleCode &&
                <DrawerSaleDetailsCP
                    visible={isSaleDrawerVisible}
                    saleCode={saleCode}
                    onClose={() => setIsSaleDrawerVisible(false)}
                />
            }

            <DrawerCreateSaleCP
                type={SaleTypeEnum.SALE}
                visible={isCreateSaleDrawerVisible}
                onSave={(_saleCode) => {
                    init()
                    setIsCreateSaleDrawerVisible(false)
                    setSaleCode(_saleCode)
                    setIsSaleDrawerVisible(true)
                }}
                closeDrawer={() => setIsCreateSaleDrawerVisible(false)}
                customer={props.person}
            />

        </>
    )
}

const ListContainerSCP = styled.div`
    width: 100%;
    margin-top: 10px
`
