import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { MathUtils } from 'main/common/utils/MathUtils'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import React from 'react'
import { ILeadsByFranchiseListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ILeadsByFranchiseListItemResponseDTO'

/**
 * UTILITARIOS da tabela de leads por unidade.
 */
export const LeadsByFranchiseTableUtils = {

    getTableColumns(): Array<ColumnProps<ILeadsByFranchiseListItemResponseDTO>> {
        return [
            {
                title: 'Unidade',
                dataIndex: 'franchise',
                render: (text, dto) => dto.franchise.name
            },
            {
                title: 'Total leads',
                dataIndex: 'total',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalOpen, 2)
            },
            {
                title: 'Captados Hoje',
                dataIndex: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalRegisteredToday, 2)
            },
            { key: 'prog-cap-today',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={`Captação de leads por dia em relação à meta de: ${dto.registeredTarget}`}
                        percent={MathUtils.getPercentage(dto.totalRegisteredToday, dto.registeredTarget, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'p/ Hoje',
                dataIndex: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalPlannedToday, 2)
            },
            { key: 'prog-contato',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={`Progresso de realização das atividades planejadas para hoje. Total já realizado: ${dto.totalContactedToday}`}
                        percent={MathUtils.getPercentage(dto.totalContactedToday, dto.totalPlannedToday, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'Atrasados',
                key: 'delayed',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalDelayed, 2)
            },
            {
                title: 'Sem Atividade',
                key: 'act',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalWithoutActivities, 2)
            },
        ]
    },

}
