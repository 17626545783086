/**
 * Determina os possíveis operadores de comparação usados nos filtros de segmentação:
 * OBS: Usados entre >>> UNIDADES.
 */
export enum SegmentUnitCompareOperatorsEnum {
    EQUAL = '=',
    GREATER_THAN = '>',
    LESS_THAN = '<',
    GREATER_OR_EQUAL = '>=',
    LESS_OR_EQUAL = '<=',
    BETWEEN = 'BETWEEN',
    IN = 'IN',
    CONTAINS = 'CONTAINS',
    STARTS_WITH = 'STARTS_WITH',
    IN_ALL = 'IN_ALL',
}
