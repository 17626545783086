export enum PersonContactTypeEnum {
    LETTER = 'LETTER',
    EMAIL = 'EMAIL',
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    PHONE = 'PHONE',
    PERSONAL = 'PERSONAL',
    SMS = 'SMS',
    TELEGRAM = 'TELEGRAM',
    TIKTOK = 'TIKTOK',
    TWITTER = 'TWITTER',
    WEBSITE = 'WEBSITE',
    WHATSAPP = 'WHATSAPP',
}

export enum PersonContactTypeLabelEnum {
    EMAIL = 'E-mail',
    FACEBOOK = 'Facebook',
    INSTAGRAM = 'Instagram',
    LETTER = 'Correio',
    PERSONAL = 'Pessoal',
    PHONE = 'Ligação',
    SMS = 'SMS',
    TELEGRAM = 'Telegram',
    TIKTOK = 'Tiktok',
    TWITTER = 'Twitter',
    WEBSITE = 'Website',
    WHATSAPP = 'Whatsapp',
}
