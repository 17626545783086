import React, { useEffect, useState } from 'react'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { ContractGenerationResultContentICP } from 'main/modules/documents/components/contract-modal/inner/ContractGenerationResultContentICP'
import { ContractGenerationContentICP } from 'main/modules/documents/components/contract-modal/inner/ContractGenerationContentICP'
import { ContractSendToDokfyContentICP } from 'main/modules/documents/components/contract-modal/inner/ContractSendToDokfyContentICP'
import { DokfyResultContentICP } from 'main/modules/documents/components/contract-modal/inner/DokfyResultContentICP'
import { IDokfyDocumentResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyDocumentResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DokfyRequests } from 'main/modules/documents/services/dokfy/DokfyRequests'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { HttpStatusEnum } from 'main/common/enums/HttpStatusEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IDokfyNeritClinCustomArgs } from 'main/modules/documents/services/dokfy/utils/IDokfyNeritClinCustomArgs'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IDokfyDocumentSearchRequestDTO } from 'main/modules/documents/services/dokfy/dtos/requests/IDokfyDocumentSearchRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'

type ContractModalViewTP = 'generateContractVW' | 'contractGenerationResultVW' | 'sendToDokfyVW' | 'dokfyResultVW'

interface IContractModalCPProps {
    sale: ISaleResponseDTO
    visible: boolean
    onClose: () => void
}

/**
 * Modal de geracao de contrato para uma venda
 */
export function ContractModalCP(props: IContractModalCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [contractUrl, setContractUrl] = useState<string>()

    const [currentView, setCurrentView] = useState<ContractModalViewTP>()
    const [dokfyDocument, setDokfyDocument] = useState<IDokfyDocumentResponseDTO>()

    const getDokfyDocumentByCodeRequest = useRequest<IDokfyDocumentResponseDTO>()
    useEffect(onGetDokfyDocumentByCodeRequestChange, [getDokfyDocumentByCodeRequest.isAwaiting])

    const getDokfyDocumentsBySaleCodeRequest = useRequest<IGenericListResponseDTO<IDokfyDocumentResponseDTO>>()
    useEffect(onGetDokfyDocumentsBySaleCodeRequestChange, [getDokfyDocumentsBySaleCodeRequest.isAwaiting])

    /**
     * Inicializa os dados da tela
     */
    function init(): void {

        if (!props.visible)
            return

        setContractUrl(props.sale.contractURL)

        // Verifica se a venda ja teve contrato gerados
        if (!!props.sale.contractURL) {

            // Se teve contrato gerado, verifica se foi enviado a dokfy ou nao
            if (PermissionUtils.hasAccess(ModuleEnum.MODULE_DOKFY, null)) {
                getDokfyDocumentFromApi(props.sale.code, 'saleCode')
                return
            }

            setCurrentView('contractGenerationResultVW')
            return
        }

        setCurrentView('generateContractVW')
    }

    /**
     * Busca um documento na Dokfy
     */
    async function getDokfyDocumentFromApi(code: number, codeType: 'saleCode' | 'documentCode'): Promise<void> {

        if (codeType === 'documentCode') {
            getDokfyDocumentByCodeRequest.runRequest(await DokfyRequests.getDocumentConfig(code))
            return
        }

        // Custom args salvo na DOKFY para poder pegar o contrato de uma venda
        const customArgs: IDokfyNeritClinCustomArgs = {
            schema: AppStateUtils.getDomainSlug()!,
            franchiseCode: props.sale.franchise.code,
            saleCode: props.sale.code
        }

        const dto: IDokfyDocumentSearchRequestDTO = {
            customArgs: JSON.stringify(customArgs)
        }

        getDokfyDocumentsBySaleCodeRequest.runRequest(await DokfyRequests.getDocumentSearchConfig(dto))
    }

    /**
     * Retorno da requisicao para pegar documento passada como parametro
     */
    function onGetDokfyDocumentByCodeRequestChange(): void {

        if (getDokfyDocumentByCodeRequest.isAwaiting || !getDokfyDocumentByCodeRequest.wasTried)
            return

        const isSuccess = (getDokfyDocumentByCodeRequest.isSuccess && !!getDokfyDocumentByCodeRequest.responseData)
        if (!isSuccess) {

            // Se o retorno foi de nao encontrado, temos que cadastrar a pessoa
            if ([HttpStatusEnum.NOT_FOUND].includes(getDokfyDocumentByCodeRequest.responseStatus!)) {
                setCurrentView('contractGenerationResultVW')
                return
            }

            // Qualquer outro erro foi inesperado
            NotificationHelper.error('Ocorreu algum erro ao buscar documento na Dokfy')
            return
        }

        setCurrentView('dokfyResultVW')
        setDokfyDocument(getDokfyDocumentByCodeRequest.responseData)
    }

    /**
     * Retorno da requisicao para pegar documento da venda.
     */
    function onGetDokfyDocumentsBySaleCodeRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getDokfyDocumentsBySaleCodeRequest, 'Ocorreu algum erro ao buscar contrato da venda na Dokfy'))
            return

        const dokfyDocumentResult = getDokfyDocumentsBySaleCodeRequest.responseData?.list[0]
        if (!dokfyDocumentResult) {
            setCurrentView('sendToDokfyVW')
            return
        }

        setDokfyDocument(dokfyDocumentResult)
        setCurrentView('dokfyResultVW')
    }

    return (
        <ModalCP
            title={'Contrato'}
            visible={props.visible}
            noFooter={true}
            top={10}
            onClose={props.onClose}
            destroyOnClose={true}
        >

            <LoadingOverlayCP
                show={getDokfyDocumentByCodeRequest.isAwaiting || getDokfyDocumentsBySaleCodeRequest.isAwaiting}
                text={'... Buscando contrato na Dokfy ...'}
            />

            {
                currentView === 'generateContractVW' &&
                <ContractGenerationContentICP
                    saleCode={props.sale.code}
                    onGeneratedContract={(_contractUrl) => {
                        setContractUrl(_contractUrl)
                        setCurrentView('contractGenerationResultVW')
                    }}
                />
            }

            {
                currentView === 'contractGenerationResultVW' && !!contractUrl &&
                <ContractGenerationResultContentICP
                    contractUrl={contractUrl}
                    onSendToDokfy={() => setCurrentView('sendToDokfyVW')}
                />
            }

            {
                currentView === 'sendToDokfyVW' && !!contractUrl &&
                <ContractSendToDokfyContentICP
                    sale={props.sale}
                    contractUrl={contractUrl}
                    shouldAddSellerAsAssignee={false}
                    onSendToDokfy={(document) => {
                        setDokfyDocument(document)
                        setCurrentView('dokfyResultVW')
                    }}
                />
            }

            {
                currentView === 'dokfyResultVW' && dokfyDocument &&
                <DokfyResultContentICP
                    document={dokfyDocument}
                    sale={props.sale}
                    loading={getDokfyDocumentByCodeRequest.isAwaiting}
                    onRefreshDocument={() => { getDokfyDocumentFromApi(dokfyDocument?.code, 'documentCode') }}
                />
            }

        </ModalCP>
    )

}
