import React, { useEffect, useState } from 'react'
import { ChargeRequests } from 'submodules/neritclin-sdk/services/sale/charge/ChargeRequests'
import { ChargeHistoryResponseDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/response/ChargeHistoryResponseDTO'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
    visible: boolean
    onCancel: () => void
    chargeCode: number
}

/**
 */
export function ModalChargeHistoryCP(props: ICPProps): JSX.Element {

    const [shouldLoadTable, setShouldLoadTable] = useState<number>()
    useEffect(retryCharge, [props.visible, props.chargeCode])

    /**
     */
    function retryCharge(): void {

        if (!props.visible)
            return

        setShouldLoadTable(TableUtils.getReloadNumber())
    }

    return (
        <ModalCP
            title={'Eventos da cobrança'}
            width={800}
            visible={props.visible}
            onCancel={props.onCancel}
            noFooter={true}
        >

            <TableFromApiCP<ChargeHistoryResponseDTO>
                shouldLoadData={shouldLoadTable}
                apiConfig={{
                    requestConfigTP: () => ChargeRequests.searchChargeHistory(props.chargeCode)
                }}
                columns={[
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITH_TIME_H_M)
                    },
                    {
                        title: 'Descrição',
                        render: (text, record) => record.comments
                    },
                ]}
            />

        </ModalCP>
    )
}
