import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de indicadores de venda.
 */
export function WidgetAverageDiscountCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Desconto médio concedido'}
            help={'Média de desconto concedido por venda'}
            valueMask={'%'}
            isInvertedProgress={true}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.AVERAGE_DISCOUNT,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
        />
    )
}
