import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import React, { useEffect } from 'react'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ButtonCPOLD } from 'common/old-refactory/button/ButtonCPOLD'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'

interface IFunnelStepFormCPProps {
    setIsFormValid: (isValid: boolean) => void
    step: IFunnelStepResponseDTO
    maxStepsNumber: number
    onChangeStep: (formValues: IFunnelStepResponseDTO) => void
    onDeleteStep: () => void
}

export function FunnelStepFormCP(props: IFunnelStepFormCPProps): JSX.Element {

    const deleteStepRequest = useRequest(undefined, 'none')
    useEffect(onDeleteStepRequestChange, [deleteStepRequest.isAwaiting])

    function onDeleteStep(): void {
        deleteStepRequest.runRequest(FunnelRequests.deleteStep(props.step.code))
    }

    function onDeleteStepRequestChange(): void {

        if (!deleteStepRequest.isSuccess && deleteStepRequest.error?.data.status === 403)
            return NotificationHelper.error('Ainda há oportunidades vinculadas a esta etapa.', 'Mova-as para remove-la!')

        if (!RequestUtils.isValidRequestReturn(deleteStepRequest, 'Erro ao remover etapa no funil de follow-up.'))
            return

        props.onDeleteStep()
    }

    function onChangeNameField(value: string): void {

        if (!value)
            props.setIsFormValid(false)

        props.onChangeStep({
            ...props.step,
            name: value
        })
    }

    function onChangeOrderField(value: number): void {

        if (!value)
            props.setIsFormValid(false)

        props.onChangeStep({
            ...props.step,
            order: +value
        })
    }

    return (
        <ItemWrapperSCP>
            <SelectCP
                placeholder={'Ordem'}
                value={props.step.order}
                onChange={onChangeOrderField}
                fieldName={'order'}
                options={Array.from(Array(props.maxStepsNumber), (val, i) => `${i + 1}`)}
            />
            <InputCP
                placeholder={'Etapa'}
                value={props.step.name}
                onChange={onChangeNameField}
            />
            <ButtonCPOLD
                showConfirmDialog={true}
                confirmTitle={'Você tem certeza que deseja excluir essa etapa do funil?'}
                action={onDeleteStep}
                label={<FontAwsomeIconCP type={faTrash} style={{ cursor: 'pointer' }}/>}
            />
        </ItemWrapperSCP>
    )
}

const ItemWrapperSCP = styled.div`
    display: grid;
    grid-template-columns: 1.3fr 3fr 1fr;
    column-gap: 15px;
   

    .ant-btn {
        align-self: center;
        min-height: 0px;
        padding: 0 5px;
        box-shadow: none;
        color: ${ThemeAnt.primaryColor} !important;
        background-color: transparent !important;
    }

    .ant-form-explain {
        min-height: 0px !important;
    }
`
