import React, { useState } from 'react'
import styled from 'styled-components'
import { SaleSourcesSyntheticReportCP } from 'main/modules/reports/components/sale-sources-synthetic-report/SaleSourcesSyntheticReportCP'
import { SaleSourcesAnalyticReportCP } from 'main/modules/reports/components/sale-sources-analytic-report/SaleSourcesAnalyticReportCP'
import { ISaleSourcesReportFilterData } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/inner/ISaleSourcesReportFilterData'
import { SaleSourceReportFiltersICP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/inner/SaleSourceReportFiltersICP'

/**
 * Relatorio de Origem das Vendas
 */
export function ScreenContentSaleSourceReportCP(): JSX.Element {

    const [filterData, setFilterData] = useState<ISaleSourcesReportFilterData>()

    /**
     * Ao mudar qualquer filtro, reseta o objeto para os componentes enxergarem a alteração.
     */
    function onChangeAnyFilter(changedFilterData: ISaleSourcesReportFilterData): void {

        // Tem que clonar o filtro atual para que os componentes enxerguem modifiacao
        setFilterData({
            franchiseCodes: changedFilterData.franchiseCodes,
            dateRange: changedFilterData.dateRange,
            userSellerCode: changedFilterData.userSellerCode
        })
    }

    return (
        <>
            <SaleSourceReportFiltersICP onChangeReportData={onChangeAnyFilter}/>

            <ContentWrapperSCP>
                <ContentSCP>
                    {
                        filterData &&
                        <>
                            <SaleSourcesSyntheticReportCP filterData={filterData}/>
                            <SaleSourcesAnalyticReportCP filterData={filterData}/>
                        </>
                    }
                </ContentSCP>
            </ContentWrapperSCP>
        </>
    )
}

const ContentSCP = styled.div`
    width: 100%;
`

const ContentWrapperSCP = styled.div`
    margin-top: 130px;
    display: flex;
    overflow-x: auto;
    justify-content: center;
`