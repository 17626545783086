import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { MarketingChannelEnum } from 'main/modules/marketing/enums/MarketingChannelEnum'

export class SendBulkMessageFormModel extends FormModel {

    @IsNotEmpty()
    text: string

    @IsNotEmpty()
    channel: MarketingChannelEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
