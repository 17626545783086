import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useRef } from 'react'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { useReactToPrint } from 'react-to-print'
import { ReceiptToPrintICP } from 'main/modules/financial/components/modal-receipt/inner/ReceiptToPrintICP'

interface IModalPayFinancialTransactionCPProps {
    visible: boolean
    financialTransaction: IFinancialTransactionResponseDTO
    onClose: () => void
}

/**
 * Componente de modal recibo da despesa.
 */
export function ModalReceiptCP(props: IModalPayFinancialTransactionCPProps): JSX.Element {

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: `nc-recibo-${props.financialTransaction.code}`,
        content: () => componentRef.current as any,
    })

    return (
        <ModalCP
            title={'Recibo'}
            width={800}
            visible={props.visible}
            onCancel={props.onClose}
            onOk={handlePrint}
            okText={'Imprimir'}
            top={5}
        >
            <ReceiptToPrintICP
                ref={componentRef}
                financialTransaction={props.financialTransaction}
            />
        </ModalCP>
    )
}
