import React from 'react'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { TextCP } from 'main/common/components/text/TextCP'

interface IBankAccountNameColumnICPProps {
    bankAccount: IBankAccountResponseDTO
}

/**
 * COMPONENTE
 * Coluna de nome da conta bancaria.
 */
export function BankAccountNameColumnICP(props: IBankAccountNameColumnICPProps): JSX.Element {

    return (
        <>
            <TextCP text={props.bankAccount.name}/>
            <TextCP text={props.bankAccount.bankName} strong={true}/>
        </>
    )
}
