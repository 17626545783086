import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DrawerEditorVarsCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tiny-mce/inner/drawer-editor-vars/DrawerEditorVarsCP'
import { EditorVarTP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tiny-mce/inner/drawer-editor-vars/inner/EditorVarTP'
import styled from 'styled-components'

const API_KEY = 'ewlreyb3bjbb09j6lag43p79jbk1ijstxa30bfmqz4zfak28'

interface ICPProps {
    vars?: EditorVarTP[]
    initialEditorContent?: string
    onEditorContentChange: (e: any) => void
    onSetup?: (editor: any) => void
    height?: number
}

/**
 * Editor TinyMCE.
 */
export function TinyMceCP(props: ICPProps): JSX.Element {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isVarsDrawerVisible, setIsVarsDrawerVisible] = useState<boolean>(false)

    /**
     * Ao abrir o iniciar.
     */
    function onSetup(editor: any): void {

        if (!!props.vars) {
            editor.ui.registry.addButton('showVars', {
                text: 'VAR',
                onAction() {
                    setIsVarsDrawerVisible(true)
                }
            })
        }

        if (!!props.onSetup)
            props.onSetup(editor)
    }

    return (
        <WrapperSCP>
            <LoadingOverlayCP show={isLoading}/>
            <Editor
                apiKey={API_KEY}
                onInit={() => setIsLoading(false)}
                initialValue={props.initialEditorContent}
                init={{
                    height: props.height ?? 500,
                    menubar: false,
                    branding: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help',
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | addLogo | showVars | \
                        code',
                    setup: onSetup
                }}
                onEditorChange={props.onEditorContentChange}
            />

            {
                !!props.vars &&
                <DrawerEditorVarsCP
                    vars={props.vars}
                    onClose={() => setIsVarsDrawerVisible(false)}
                    visible={isVarsDrawerVisible}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    width: 100%;
`
