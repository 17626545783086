import React, { useEffect, useState } from 'react'
import { FastFiltersWithDrawerCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/FastFiltersWithDrawerCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { BankAccountWithdrawFiltersFormModel } from 'main/modules/financial/components/bank-account-transfer-filters/inner/BankAccountWithdrawFiltersFormModel'
import { BankAccountTransferAdvancedFiltersCP } from 'main/modules/financial/components/bank-account-transfer-filters/inner/BankAccountTransferAdvancedFiltersCP'

interface ISalesFiltersCPProps {
    formStateManager: IFormStateManager<BankAccountWithdrawFiltersFormModel>
    onClearFilters: () => void
    onFilter: () => void
    isLoading?: boolean
}

/**
 * Filtros da tela de Transferencia entre contas.
 */
export function BankAccountTransferFiltersCP(props: ISalesFiltersCPProps): JSX.Element {

    // Mantem as datas em estado aqui local pq senao nao o componente de datas nao gerencia
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    useEffect(() => setDateFilters(props.formStateManager.getFieldValue('dateRange')), [props.formStateManager.getFieldValue('dateRange')])

    /**
     * Ao mudar as datas
     */
    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
        props.formStateManager.changeFieldValue('dateRange', dates)
        props.onFilter()
    }

    return (
        <FastFiltersWithDrawerCP
            onFilter={props.onFilter}
            isLoading={props.isLoading}
            onClearFilters={props.onClearFilters}
            drawerContent={<BankAccountTransferAdvancedFiltersCP formStateManager={props.formStateManager}/>}
            leftContent={
                <DateRangePickerCP
                    value={dateFilters}
                    onChange={onChangeDates}
                    marginBottom={10}
                    fastFilter={'all'}
                    allowClear={true}
                    showBorder={true}
                />
            }
        />
    )
}
