/**
 * ENUM
 * Dias da semana
 */
export enum WeekDaysEnum {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}
