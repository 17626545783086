import React, { useEffect, useState } from 'react'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'main/modules/reports/components/reports-common-filters/inner/ReportsCommonFiltersFormModel'
import { DrawerFiltersCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/inner/DrawerFiltersCP'
import { IReportCommonFiltersConfig } from 'main/modules/reports/components/reports-common-filters/inner/IReportCommonFiltersConfig'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import {
    ScheduleReportItemStatusEnum,
    ScheduleReportItemStatusLabelEnum
} from 'main/modules/reports/services/scheduler-reports/enums/ScheduleReportItemStatusEnum'

interface IReportsCommonFiltersCPProps {
    formStateManager: IFormStateManager<ReportsCommonFiltersFormModel>
    fieldsConfig?: IReportCommonFiltersConfig
    onClearFilters: () => void
    onFilter: () => void
    onClose: () => void
    visible: boolean
    loading?: boolean
}

/**
 * Filtros Comuns dos relatorios.
 */
export function DrawerReportCommonFiltersCP(props: IReportsCommonFiltersCPProps): JSX.Element {

    // Mantem as datas em estado aqui local pq senao nao o componente de datas nao gerencia
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()

    useEffect(init, [props.formStateManager.getFieldValue('dateRange')])

    /**
     * Inicializa.
     */
    function init(): void {
        setDateFilters(props.formStateManager.getFieldValue('dateRange'))
    }

    /**
     * Ao mudar as datas
     */
    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
        props.formStateManager.changeFieldValue('dateRange', dates)
    }

    return (
        <DrawerFiltersCP
            visible={props.visible}
            onClose={props.onClose}
            onFilter={props.onFilter}
            onClearFilters={props.onClearFilters}
            isLoading={props.loading}
        >
            <ConditionalRenderCP shouldRender={AppStateUtils.getUserFranchises()!.length > 1}>
                <SelectFranchiseCP
                    isMultiple={true}
                    formStateManager={props.formStateManager}
                />
            </ConditionalRenderCP>

            <DateRangePickerCP
                value={dateFilters}
                onChange={onChangeDates}
                fastFilter={'all'}
                allowClear={true}
                showBorder={true}
                marginBottom={20}
            />

            <>

                {
                    props.fieldsConfig?.showConsolidatedFilter &&
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Consolidar resultado?'}
                        formStateManager={props.formStateManager}
                        fieldName={'showConsolidated'}
                        marginTop={20}
                        marginBottom={30}
                    />
                }

                {
                    props.fieldsConfig?.showSeller &&
                    <AutocompleteUserCP
                        label={'Vendedor'}
                        formStateManager={props.formStateManager}
                        fieldName={'userSellerCode'}
                    />
                }

                {
                    props.fieldsConfig?.showUserAttendance &&
                    <AutocompleteUserCP
                        label={'Responsável pelo Atendimento'}
                        formStateManager={props.formStateManager}
                        fieldName={'userAttendanceCode'}
                    />
                }

                {
                    props.fieldsConfig?.showEvaluationsFilter &&
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Mostrar Avaliações'}
                        formStateManager={props.formStateManager}
                        fieldName={'showEvaluations'}
                        marginTop={20}
                        marginBottom={30}
                    />
                }

                {
                    props.fieldsConfig?.showScheduleStatus &&
                    <SelectCP
                        label={'Status do Agendamento'}
                        formStateManager={props.formStateManager}
                        fieldName={'scheduleStatus'}
                        allowClear={true}
                        options={
                            Object.values(ScheduleReportItemStatusEnum).map((status, i) => ({
                                key: `key-${i}`,
                                label: ScheduleReportItemStatusLabelEnum[status],
                                value: status
                            }))
                        }
                    />
                }
            </>
        </DrawerFiltersCP>
    )
}
