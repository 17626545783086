import React, { useState } from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { OpportunityAttemptEnum, OpportunityAttemptLabelEnum } from 'main/modules/sales-funnel/services/opportunity/enums/OpportunityAttemptEnum'

interface IOpportunityAdvancedFiltersCPProps {
    formStateManager: IFormStateManager<OpportunityFiltersFormModel>
}

/**
 * Filtros avancados, mais filtros, da tela de vendas.
 */
export function OpportunityAdvancedFiltersCP(props: IOpportunityAdvancedFiltersCPProps): JSX.Element {

    const [createDate, setCreateDate] = useState<IDateRangeFilter>()

    return (
        <>
            <AutocompletePersonCP
                label={'Responsável'}
                considerUser={true}
                formStateManager={props.formStateManager}
                fieldName={'responsibleCode'}
                allowClear={true}
                showOptionsOnLoad={true}
                shouldShowAdminUser={true}
                returnFullOption={true}
                onChange={(selected) => {
                    if (!!selected)
                        props.formStateManager.changeFieldValue('responsibleName', selected.label)

                }}
            />

            <DateRangePickerCP
                label={'Data de Criação'}
                value={createDate}
                onChange={(dates) => {
                    setCreateDate(dates)
                    props.formStateManager.changeFieldValue('createDateRange', dates)
                }}
                fastFilter={'all'}
                showBorder={true}
                marginBottom={20}
            />

            <AutocompletePersonCP
                label={'Criado por'}
                considerUser={true}
                formStateManager={props.formStateManager}
                fieldName={'userCodeCreator'}
                allowClear={true}
                showOptionsOnLoad={true}
                shouldShowAdminUser={true}
            />

            <AutocompletePersonCP
                label={'Responsável pela Avaliação'}
                considerUser={true}
                formStateManager={props.formStateManager}
                fieldName={'userCodeEvaluationResponsible'}
                allowClear={true}
                showOptionsOnLoad={true}
                shouldShowAdminUser={true}
            />

            <RadioGroupCP<OpportunityAttemptEnum | undefined>
                buttonHeight={32}
                buttonWidth={90}
                secondary={true}
                type={'vertical-radio'}
                label={'Quais LEADs devemos buscar?'}
                formStateManager={props.formStateManager}
                fieldName={'attempt'}
                options={[
                    {
                        content: 'Todos',
                        value: undefined,
                    }, {
                        content: OpportunityAttemptLabelEnum.FIRST_ATTEMPT_ONLY,
                        value: OpportunityAttemptEnum.FIRST_ATTEMPT_ONLY,
                        tooltip: 'Irá buscar apenas LEADs que nunca tiveram nenhuma oportunidade anteriormente'
                    }, {
                        content: OpportunityAttemptLabelEnum.SECOND_AND_NEXT_ATTEMPTS_ONLY,
                        value: OpportunityAttemptEnum.SECOND_AND_NEXT_ATTEMPTS_ONLY,
                        tooltip: 'Irá buscar LEADs que já tiveram outra oportunidade cadastrada anteriormente, ou seja, nova tentativa de venda'
                    }
                ]}
            />
        </>
    )
}
