import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { ThemeProjectCommon } from 'submodules/neritclin-components-ui/theme/project/white-labels/ThemeProjectCommon'

/**
 * Reúne métodos auxiliares para gestão de sessões.
 */
export const SessionUtils = {

    getSessionColor(sessionStatus: SessionStatusEnum, wasPresenceConfirmed?: boolean, scheduledDate?: Date): string {

        switch (sessionStatus) {

            case SessionStatusEnum.AWAITING:
                return ThemeProjectCommon.schedulerSessionAwaiting

            case SessionStatusEnum.IN_PROGRESS:
                return ThemeProjectCommon.schedulerSessionInProgress

            case SessionStatusEnum.CHECK_IN_DONE:
                return ThemeProjectCommon.schedulerSessionCheckinDone

            case SessionStatusEnum.CONCLUDED:
                return ThemeProjectCommon.schedulerSessionConcluded

            case SessionStatusEnum.NO_SHOW:
                return ThemeProjectCommon.schedulerSessionNoShow

            case SessionStatusEnum.SCHEDULED:

                if (!SessionUtils.isScheduleInFuture(scheduledDate))
                    return ThemeProjectCommon.schedulerSessionNotMarkedAsDone

                if (wasPresenceConfirmed)
                    return ThemeProjectCommon.schedulerPresenceConfirmed

                return ''

            default:
                return ''
        }
    },

    /** Retorna o label apropriado para cada tipo de status */
    getTreatmentLabel(session: SessionStatusEnum): string {
        switch (session) {
            case SessionStatusEnum.SCHEDULED:
                return 'Agendado'
            case SessionStatusEnum.AWAITING:
                return 'Aguardando agendamento'
            case SessionStatusEnum.CONCLUDED:
                return 'Atendimento concluído'
            default:
                return 'Cliente não compareceu'
        }
    },

    isScheduleInFuture(scheduledDate?: Date): boolean {

        if (!!scheduledDate && DateUtils.getDiff(TimeBaseEnum.MINUTE, new Date(), scheduledDate) < 0)
            return false

        return true
    },
}
