import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { EvaluationDoneActionTP } from 'main/modules/sales-funnel/components/modal-evaluation-confirm/ModalEvaluationConfirmCP'
import { ValidateIf } from 'class-validator'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

/**
 * VALIDADOR
 * Faz a validação dos campos do formulario de confirmacao
 */
export class EvaluationConfirmFormModel extends FormModel {

    @IsNotEmpty()
    actionType: EvaluationDoneActionTP

    @ValidateIf(
        () => !!AppStateUtils.getCurrentFranchise()?.paramsConfig?.fieldsConfig?.opportunity?.isTagRequiredOnEvaluationDone,
        { message: 'Informe ao menos uma tag de interesse' }
    )
    @IsNotEmpty()
    tagCodes: INameAndCodeResponseDTO[]

    @ValidateIf((obj) => obj.actionType === 'archive')
    @IsNotEmpty()
    archiveReasonCode: number

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }

}
