import { MaskUtils } from 'main/common/utils/MaskUtils'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { ISalesBySellerReportListItemRespDTO } from 'main/modules/sale/services/sale/dtos/responses/ISalesBySellerReportListItemRespDTO'
import { ColumnProps } from 'antd/lib/table'

export const SalesBySellerTableUtils = {

    getColumns(): Array<ColumnProps<ISalesBySellerReportListItemRespDTO>> {

        return [
            {
                title: 'Vendedor',
                key: 'vendor',
                render: (text, item) => { return item.reponsiblePersonName },
            }, {
                title: 'Valor Bruto',
                key: 'gross',
                align: 'right',
                render: (text, item) => { return MaskUtils.applyMoneyMask(`${item.grossValue}`) },
            }, {
                title: 'Desconto',
                key: 'discount',
                align: 'right',
                render: (text, item) => { return MaskUtils.applyMoneyMask(`${item.discountValue}`) },
            }, {
                title: 'Valor Líquido',
                key: 'net',
                align: 'right',
                render: (text, item) => { return MaskUtils.applyMoneyMask(`${item.netValue}`) },
            }, {
                title: 'Qtd. Vendas',
                key: 'totalSales',
                align: 'right',
                render: (text, item) => { return item.totalSales },
            }, {
                title: 'Qtd. Pacotes',
                key: 'totalPkg',
                align: 'right',
                render: (text, item) => { return item.totalPackages },
            }, {
                title: 'PPV',
                key: 'ppv',
                align: 'right',
                render: (text, item) => { return MoneyUtils.format(item.packagesPerSale) },
            }, {
                title: 'Ticket Médio',
                key: 'saleAverage',
                align: 'right',
                render: (text, item) => { return MaskUtils.applyMoneyMask(`${item.saleValueAverage}`) },
            },
        ]
    }

}
