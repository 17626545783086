import React from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'

interface IDrawerMoreFiltersCPProps {
    children: JSX.Element | JSX.Element[]
    visible: boolean
    onClearFilters: () => void
    onFilter: () => void
    onClose: () => void
    isLoading?: boolean
}

/**
 * Drawer com os filtros avancados.
 */
export function DrawerFiltersCP(props: IDrawerMoreFiltersCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Filtros avançados'}
            visible={props.visible}
            footerSpaced={true}
            onClose={props.onClose}
            placement={'left'}
            width={341}
            showMask={false}
            maskClosable={false}
            footer={
                <>
                    {
                        !!props.onClearFilters &&
                        <ButtonCP
                            type={'ghost'}
                            onClick={props.onClearFilters}
                            loading={props.isLoading}
                        >
                            Limpar
                        </ButtonCP>
                    }
                    <ButtonCP
                        type={'primary'}
                        onClick={props.onFilter}
                        loading={props.isLoading}
                    >
                        Filtrar
                    </ButtonCP>
                </>
            }
        >

            { props.children }

        </DrawerCP>
    )
}
