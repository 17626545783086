import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { PersonUtils } from 'main/modules/people/utils/PersonUtils'
import React from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { PersonFormModel } from 'main/modules/people/components/drawer-person-data/inner/PersonFormModel'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonDataFormICP } from 'main/modules/people/components/drawer-person-data/inner/PersonDataFormICP'

interface IDrawerPersonDataCPProps {
    initialData?: ObjectPropsTP<PersonFormModel>
    personCode?: number
    visible: boolean
    onClose: () => void
    onSave: (person: IPersonResponseDTO) => void
    segmentType: SegmentTypeEnum
    customHeader?: JSX.Element
    closable?: boolean
}

/**
 * COMPONENTE
 * Drawer de cadastro de nova pessoa
 */
export function DrawerPersonDataCP(props: IDrawerPersonDataCPProps): JSX.Element {

    return (
        <DrawerCP
            title={`${props.personCode ? 'Editar' : 'Novo'} ${PersonUtils.getSegmentTypeLabel(props.segmentType)}`}
            visible={props.visible}
            onClose={props.onClose}
            closable={props.closable ?? true}
        >

            { props.customHeader }

            <PersonDataFormICP
                initialData={props.initialData}
                personCode={props.personCode}
                onSave={props.onSave}
                segmentType={props.segmentType}
            />
        </DrawerCP>
    )
}
