import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP';
import { FlexCP } from 'main/common/components/flex/FlexCP';
import { InputCP } from 'main/common/components/form-fields/input/InputCP';
import { ModalCP } from 'main/common/components/modal/ModalCP';
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP';
import { TableCP } from 'main/common/components/table/TableCP';
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { RequestUtils } from 'main/common/request-manager/RequestUtils';
import { useRequest } from 'main/common/request-manager/use-request/UseRequest';
import { ITagResponseDTO } from 'main/modules/people/services/person/dtos/response/ITagResponseDTO';
import { TagRequests } from 'main/modules/tags/services/TagRequests';
import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP';

export function TelaTag(): JSX.Element {
    const tagsRequest = useRequest<IGenericListResponseDTO<ITagResponseDTO>>();
    const [tags,setTags] = useState<any>(null);
    const [novaTag,setNovaTag] = useState<any>({code:null,name:null});
    const [valido,setValido] = useState<boolean>(false);
    const [visaoModal,setVisaoModal] = useState<boolean>(false);

    useEffect(onTagsRequestChange, [tagsRequest.isAwaiting])
    useEffect(()=>{
        getTagList();
    },[]);

    const colunasTag = [
        {
          title: '#',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: 'TAG',
          dataIndex: 'name',
          key: 'name',
         
        },
       
        {
            title:'Ações',
            key: 'actions',
            render: (text:any, item:any) => (
                <FlexCP justifyContent={'flex-end'}>
                    <ButtonCP
                        type={'primary'}
                        icon={'edit'}
                        size={'small'}
                        onClick={() =>onEdit(item)}
                    />
                </FlexCP>
            )
        }
      ];


    function onEdit(item:any){
        console.log("Editando ", item);
        setNovaTag({...item});
        setVisaoModal(true);
        validar();
    }

    function criarNovaTag(){
        setNovaTag({code:null,name:null});
        setVisaoModal(true);
        validar();
    }


    function validar(){
        if(!novaTag){
            setValido(false);
            return;
        }
        if(!novaTag['name']){
            setValido(false);
            return;
        }
        setValido(true);
    }

    function getTagList(): void {
            tagsRequest.runRequest(TagRequests.search())
    }

    function onTagsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(tagsRequest, 'Erro ao buscar tags'))
            return
        console.log(tags,tagsRequest.responseData);
        setTags(tagsRequest?.responseData?.list);
    }

    async function salvarTag(){
        console.log("Salvando tag");
        if(novaTag.code)await RequestHelper.runRequest(TagRequests.atualizar(novaTag));
        if(!novaTag.code)await RequestHelper.runRequest(TagRequests.salvar(novaTag));
        getTagList();
    }

    return (
        <>
            <HeaderCP title={'Relação de TAGS da Franquia'}>
                <HeaderButtonICP
                    onClick={criarNovaTag}
                    icon={'plus'}
                    label={'Nova Tag'}
                />
            </HeaderCP>
            <ModalCP visible={visaoModal}
                okText='Salvar'
                onClose={()=>{setVisaoModal(false)}}
                onOk={salvarTag}
                title='Configuração de Tag'
                noFooter={!valido}
            > 
            <ConditionalRenderCP key={'8ewjao'} shouldRender={visaoModal}>
                <InputCP
                label={'Nome da TAG'}
                value={novaTag.name}
                onChange={(v)=>{
                    setNovaTag({...novaTag,name:v});
                    validar();
                }}
                />
            </ConditionalRenderCP>
            </ModalCP>

 
            <TableCP
                columns={colunasTag}
                data={tags ?? []}
                loading={tagsRequest.isAwaiting}
                />
        </>
    )

}
