export enum SalesFunnelOpportunityViewEnum {
    LEAD_TODAY = 'LEAD_TODAY',
    LEAD_PLANNED = 'LEAD_PLANNED',
    LEAD_DELAYED = 'LEAD_DELAYED',
    LEAD_WITHOUT_ACTIVITY = 'LEAD_WITHOUT_ACTIVITY',
    LEAD_ALL = 'LEAD_ALL',
    LEAD_CONVERTED = 'LEAD_CONVERTED',
    LEAD_NUNCA_COMPRARAM = 'LEAD_NUNCA_COMPRARAM',
    LEAD_LOST = 'LEAD_LOST',

    EVALUATION_TODAY = 'EVALUATION_TODAY',
    EVALUATION_PLANNED = 'EVALUATION_PLANNED',
    EVALUATION_DELAYED = 'EVALUATION_DELAYED',
    EVALUATION_WITHOUT_ACTIVITY = 'EVALUATION_WITHOUT_ACTIVITY',
    EVALUATION_ALL = 'EVALUATION_ALL',
    EVALUATION_CONVERTED = 'EVALUATION_CONVERTED',
    EVALUATION_LOST = 'EVALUATION_LOST',

    FOLLOWUP_TODAY = 'FOLLOWUP_TODAY',
    FOLLOWUP_PLANNED = 'FOLLOWUP_PLANNED',
    FOLLOWUP_DELAYED = 'FOLLOWUP_DELAYED',
    FOLLOWUP_WITHOUT_ACTIVITY = 'FOLLOWUP_WITHOUT_ACTIVITY',
    FOLLOWUP_ALL = 'FOLLOWUP_ALL',
    FOLLOWUP_CONVERTED = 'FOLLOWUP_CONVERTED',
    FOLLOWUP_LOST = 'FOLLOWUP_LOST',
}

export enum SalesFunnelOpportunityViewLabelEnum {
    LEAD_TODAY = 'Hoje',
    LEAD_PLANNED = 'Futuros',
    LEAD_DELAYED = 'Atrasados',
    LEAD_WITHOUT_ACTIVITY = 'Sem Atividade',
    LEAD_ALL = 'Todos em aberto',
    LEAD_NUNCA_COMPRARAM = 'Nunca Compraram',
    LEAD_CONVERTED = 'Convertidos',
    LEAD_LOST = 'Perdidos',

    EVALUATION_TODAY = 'Agendadas para Hoje',
    EVALUATION_PLANNED = 'Futuras',
    EVALUATION_DELAYED = 'Atrasadas (sem evolução)',
    EVALUATION_WITHOUT_ACTIVITY = 'Sem Agendamento',
    EVALUATION_ALL = 'Todos em aberto',
    EVALUATION_CONVERTED = 'Convertidas',
    EVALUATION_LOST = 'Perdidas',

    FOLLOWUP_TODAY = 'Hoje',
    FOLLOWUP_PLANNED = 'Futuros',
    FOLLOWUP_DELAYED = 'Atrasados',
    FOLLOWUP_WITHOUT_ACTIVITY = 'Sem Atividade',
    FOLLOWUP_ALL = 'Todos em aberto',
    FOLLOWUP_CONVERTED = 'Convertidos',
    FOLLOWUP_LOST = 'Perdidos',
}
