import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { NPSDashboardRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/NPSDashboardRequestDTO'
import { SurveySaveRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/SurveySaveRequestDTO'
import { SurveyScorePerPeriodRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/SurveyScorePerPeriodRequestDTO'

export class SurveysRequests {

	public static CONTROLLER_ROOT = 'surveys'
	public static GET_NPS_EP = 'nps'
	public static FIND_BY_TOKEN_EP = 'find-by-token/:token'
	public static CSAT_NAMES_EP = 'csat/names'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static DASHBOARD_NPS_EP = 'nps-dashboard'
	public static DASHBOARD_NPS_ACCOUNT_EP = 'nps-dashboard-account'
	public static NPS_PER_PERIOD_EP = 'nps-score-per-period'
	public static NPS_DASHBOARD_EP = 'nps-dashboard'
	public static NPS_SCORE_PER_PERIOD_EP = 'nps-score-per-period'

	static create = (dto: SurveySaveRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static update = (code: number, dto: SurveySaveRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static findByToken = (token: string, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.FIND_BY_TOKEN_EP.replace(':token', token)}`,
		method: HttpMethodEnum.GET,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
	})

	static getNps = (projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.GET_NPS_EP}`,
		method: HttpMethodEnum.GET,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
	})

	static csatNames = (): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.CSAT_NAMES_EP}`,
		method: HttpMethodEnum.GET,
	})

	static getNpsDashboard = (dto: NPSDashboardRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.DASHBOARD_NPS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
	})

	static getNpsDashboardAccount = (dto: NPSDashboardRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.DASHBOARD_NPS_ACCOUNT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
	})

	static getNpsPerPeriod = (dto: SurveyScorePerPeriodRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveysRequests.CONTROLLER_ROOT}/${SurveysRequests.NPS_PER_PERIOD_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
	})

}
