import * as _ from 'lodash'
import { OuterSpacingTP } from 'main/common/types/OuterSpacingTP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import React from 'react'

/**
 * UTILITÁRIOS para manipular componentes React.
 */
export class ComponentUtils {

    static memoizeCp(noProps: boolean, component: React.FunctionComponent): React.FunctionComponent
    static memoizeCp<PropsTP>(component: React.FunctionComponent<PropsTP>): React.FunctionComponent<PropsTP>

    /** Unifica estrategia de memoizacao de componentes, na aplicacao. */
    static memoizeCp<PropsTP>(
        param1?: boolean | React.FunctionComponent,
        param2?: React.FunctionComponent<PropsTP>
    ): React.FunctionComponent<PropsTP> {

        const isFirstParamBool = (typeof param1 === 'boolean')
        const hasNoProps = SystemUtils.nvl(isFirstParamBool, (param1 as boolean), false)
        const component = !isFirstParamBool ? param1 : param2

        if (!component)
            throw new Error(`Parametrizacao invalida em '${ComponentUtils.name}.${ComponentUtils.memoizeCp.name}'`)

        const arePropsEqual = (hasNoProps !== true) ? ComponentUtils.arePropsEqual : undefined
        return React.memo(component as React.FunctionComponent<any>, arePropsEqual)
    }

    /** Unifica estrategia de comparacao de props para memoizacao padrao de componentes, na aplicacao. */
    static arePropsEqual<PropsTP>(prevProps: PropsTP, nextProps: PropsTP): boolean {
        return _.isEqual(prevProps, nextProps)
    }

    /** Retorna valor para atribuir a propriedade css 'margin' calculado de maneira padronizada. */
    static getMarginCssValue(defaultMarginSize: number, marginValue?: OuterSpacingTP | number | string): string {

        if (!marginValue || marginValue === 'normal')
            return `${defaultMarginSize}px`
        if (marginValue === 'none')
            return '0'
        if (marginValue === 'horizontal')
            return `0 ${defaultMarginSize}px`
        if (marginValue === 'vertical')
            return `${defaultMarginSize}px 0`

        return (typeof marginValue === 'string') ? marginValue : `${marginValue}px`
    }
}
