import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import { WorkScheduleUtils } from 'main/modules/scheduler/utils/WorkScheduleUtils'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TableWorkScheduleCP } from 'main/modules/scheduler/components/table-work-schedule/TableWorkScheduleCP'
import { ModalWorkScheduleCP } from 'main/modules/scheduler/components/modal-work-schedule/ModalWorkScheduleCP'
import { IWorkScheduleResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IWorkScheduleResponseDTO'
import { IWorkSchedulesSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkSchedulesSaveRequestDTO'
import { FranchiseRequests } from 'main/modules/admin/services/franchise/FranchiseRequests'
import { CardCP } from 'main/common/components/card/CardCP'
import { styled } from 'main/config/theme/styledWithTheme'

export function ScreenContentFranchiseWorkScheduleCP(): JSX.Element {

    const [isEditWorkScheduleModalVisible, setIsEditWorkScheduleModalVisible] = useState<boolean>(false)
    const [workSchedules, setWorkSchedules] = useState<IWorkScheduleResponseDTO[]>([])

    useEffect(init, [])

    const getFranchiseWorkSchedulesRequest = useRequest<IWorkScheduleResponseDTO[]>()
    useEffect(onGetFranchiseWorkSchedulesRequestChange, [getFranchiseWorkSchedulesRequest.isAwaiting])

    const updateWorkScheduleRequest = useRequest<'none'>()
    useEffect(onUpdateWorkScheduleRequestChange, [updateWorkScheduleRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {
        getFranchiseWorkSchedulesRequest.runRequest(FranchiseRequests.getWorkSchedules())
    }

    /**
     * Retorno ao obter horarios.
     */
    function onGetFranchiseWorkSchedulesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFranchiseWorkSchedulesRequest, 'Erro ao buscar horário de funcionamento'))
            return

        setWorkSchedules(getFranchiseWorkSchedulesRequest.responseData!)
    }

    /**
     * Atualiza com os horarios informados.
     */
    function updateWorkSchedule(newWorkSchedule: IWorkSchedule[]): void {

        const franchiseWorkSchedule = WorkScheduleUtils.transformWorkScheduleToDto(newWorkSchedule)
        const dto: IWorkSchedulesSaveRequestDTO = {
            workSchedule: franchiseWorkSchedule ?? []
        }

        updateWorkScheduleRequest.runRequest(FranchiseRequests.updateWorkScheduleConfig(dto))
    }

    /**
     * Retorno da API ao salvar horario de funcionamento.
     */
    function onUpdateWorkScheduleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateWorkScheduleRequest, 'Erro ao salvar horário de funcionamento'))
            return

        setIsEditWorkScheduleModalVisible(false)
        NotificationHelper.success('Pronto', 'Horários atualizados com sucesso.')

        init()
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <CardCP innerSpacing={'none'}>
                    <TableWorkScheduleCP
                        hasIntervalColumn={false}
                        workSchedule={WorkScheduleUtils.transformDtoToWorkSchedule(workSchedules)}
                        readonly={true}
                        loading={getFranchiseWorkSchedulesRequest.isAwaiting}
                    />
                </CardCP>

                <FlexCP justifyContent={'flex-end'} marginTop={20}>
                    <ButtonCP
                        onClick={() => setIsEditWorkScheduleModalVisible(true)}
                        type={'primary'}
                        marginRight={20}
                    >
                        Alterar horário de funcionamento
                    </ButtonCP>
                </FlexCP>
            </ContentWrapper>

            <ModalWorkScheduleCP
                title={'Editar Horário de Funcionamento'}
                visible={isEditWorkScheduleModalVisible}
                hasIntervalColumn={false}
                workSchedule={workSchedules || []}
                onCancel={() => setIsEditWorkScheduleModalVisible(false)}
                onSave={updateWorkSchedule}
                loading={updateWorkScheduleRequest.isAwaiting}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const ContentWrapper = styled.div`
  width: 400px;
`
