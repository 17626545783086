import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Médica de pacotes por venda.
 */
export function WidgetSaleProductReleaseAverageCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Média de pacotes por venda'}
            help={'Qual o número médio de pacotes vendidos em cada venda'}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.AVERAGE_PACKAGES_PER_SALE,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
            footerTitle={'Ticket médio'}
            secondCalc={'onlyValue'}
            secondValueMask={InputMaskTypeEnum.MONEY}
        />
    )
}
