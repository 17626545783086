import { Select } from 'antd'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { OrUndefinedTP } from 'main/common/types/OrUndefinedTP'
import React from 'react'

/**
 * UTILITARIOS auxiliares para componente de selecao.
 *
 * @see SelectCPU
 * @author hjcostabr
 */
export const SelectCPUtils = {

    /** Retorna definicao de 01 opcao da lista de opcoes informada identificada pelo seu valor (se houver). */
    getOptionByValue(options: SelectOptionTP[], singleValue?: number): OrUndefinedTP<SelectOptionTP> {
        return options.find(option => (typeof option === 'object' && option.value === singleValue))
    },

    renderOption(option: SelectOptionTP): JSX.Element {

        let key
        let value
        let label

        if (typeof option !== 'string') {
            value = option.value
            label = option.label || option.value
            key = option.key ?? option.value

        } else {
            key = option
            value = option
            label = option
        }

        return (
            <Select.Option key={key} value={value}>
                {label}
            </Select.Option>
        )
    },

}
