import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SaleTableUtils } from 'main/modules/sale/components/table-sales/inner/SaleTableUtils'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import React, { useEffect, useState } from 'react'
import { ISaleListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleListItemResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { CollapseSaleChargesCP } from 'main/modules/sale/components/collapse-sale-charges/CollapseSaleChargesCP'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonExportCP } from 'main/modules/app/components/button-export/ButtonExportCP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { TableSortAndPageTP } from 'main/common/components/table/inner/TableSortAndPageTP'
import { TableCP } from 'main/common/components/table/TableCP'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'

interface ISalesTableCPProps {
    filtersFormStateManager?: IFormStateManager<SalesFiltersFormModel>
    mustUpdateSalesTable: boolean
    onLoadDataChanged?: (type: 'dataLoaded' | 'reloadData') => void
}

/**
 * Tabela para listagem de vendas
 */
export function TableSalesCP(props: ISalesTableCPProps): JSX.Element {

    const [selectedSaleCode, setSelectedSaleCode] = useState<number>()
    const [showSaleDetailsDrawer, setShowSaleDetailsDrawer] = useState<boolean>(false)
    const [shouldLoadCharges, setShouldLoadCharges] = useState<boolean>(false)

    const [sortAndPage, setSortAndPage] = useState<OrUndefTP<TableSortAndPageTP>>()
    const getSalesListRequest = useRequest<IGenericListResponseDTO<ISaleListItemResponseDTO>>()
    useEffect(onGetSalesListRequestChange, [getSalesListRequest.isAwaiting])

    useEffect(init, [props.mustUpdateSalesTable])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.mustUpdateSalesTable)
            return

        const defaultSortAndOrder: TableSortAndPageTP = {
            pagination: TableUtils.getDefaultPagination()
        }
        setSortAndPage(defaultSortAndOrder)

        getSalesFromApi(defaultSortAndOrder)
    }

    /**
     * Obtem as oportunidades da API.
     */
    function getSalesFromApi(_sortAndPage?: TableSortAndPageTP): void {

        const filtersDto = SaleTableUtils.getFilterDtoFromModel(
            _sortAndPage,
            props.filtersFormStateManager?.getFormValues(),
        )
        getSalesListRequest.runRequest(SaleRequests.getSales(filtersDto))
    }

    /**
     * Retorno ao obter lista de vendas,
     */
    function onGetSalesListRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSalesListRequest, 'Não foi possível obter lista de vendas.'))
            return

        sortAndPage!.pagination!.total = getSalesListRequest.responseData?.total ?? 0
        props.onLoadDataChanged?.('dataLoaded')
    }

    /**
     * Ao selecionar uma venda.
     */
    function onSelectSale(saleCode: number): void {
        setSelectedSaleCode(saleCode)
        setShowSaleDetailsDrawer(true)
    }

    const [codigoCliente,setCodigoCliente] = useState<any>(null);
    const [mostrarDetalhe,setMostrarDetalhe] = useState(false);
    function onSelectCliente(customerCode: number):void{
        setCodigoCliente(customerCode);
        setMostrarDetalhe(true);
    }

    

    return (
        <>
            <FlexCP justifyContent={'flex-end'} marginTop={20} marginRight={20}>
                <ButtonExportCP
                    requestConfig={() => SaleRequests.salesExport(
                        TableUtils.removePaginationAttributes(SaleTableUtils.getFilterDtoFromModel(undefined, props.filtersFormStateManager?.getFormValues()))
                    )}
                    reportName={'vendas'}
                />
            </FlexCP>

            <DrawerPersonDetailsCP
                visible={mostrarDetalhe}
                personCode={codigoCliente}
                onClose={()=>{setMostrarDetalhe(false)}}
                onSave={()=>{setMostrarDetalhe(false)}}
                segmentType={SegmentTypeEnum.CUSTOMER}
            ></DrawerPersonDetailsCP>

            <TableCP
                data={getSalesListRequest.responseData?.list}
                loading={getSalesListRequest.isAwaiting}
                columns={SaleTableUtils.getColumns(onSelectSale,onSelectCliente)}
                expandedRowRender={(sale) => (
                    
                    <CollapseSaleChargesCP
                        saleCode={sale.code}
                        onChangePayment={() => {
                            getSalesFromApi()
                            setShouldLoadCharges(true)
                        }}
                        shouldLoadCharges={shouldLoadCharges}
                        onLoadCharges={() => setShouldLoadCharges(false)}
                        hasSaleNfe={true} // Passa fixo true para nao permitir gerar NFe de Parcela por Aqui
                    />
                )}
                onExpand={() => setShouldLoadCharges(true)}
                sortAndPage={sortAndPage}
                onSortOrChangePagination={(_sortAndPage) => {
                    setSortAndPage(_sortAndPage)
                    getSalesFromApi(_sortAndPage)
                }}
                wrappedOnCard={true}
            />

            {
                !!selectedSaleCode &&
                <DrawerSaleDetailsCP
                    saleCode={selectedSaleCode}
                    visible={showSaleDetailsDrawer}
                    onClose={() => {
                        setShowSaleDetailsDrawer(false)
                        props.onLoadDataChanged?.('reloadData')
                    }}
                />
            }
        </>
    )
}
