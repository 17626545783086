import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { IconPaymentMethodCP } from 'main/modules/financial/components/icon-payment-method/IconPaymentMethodCP'
import { TextCP } from 'main/common/components/text/TextCP'
import * as _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { CreateSaleUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

export const SaleChargesTableUtils = {

    getTableColumns(
        onEdit?: (saleCharge: ISaleCharge, index: number) => void,
        onDelete?: (code: number) => void,
    ): Array<ColumnProps<ISaleCharge>> {
        return [
            {
                key: 'pm',
                render: (text, charge) => (
                    <FlexCP justifyContent={'center'}>
                        <IconPaymentMethodCP paymentMethod={charge.paymentMethod} size={32}/>
                    </FlexCP>
                )
            },
            {
                key: 'details',
                render: (text, charge) => (
                    <>
                        <TextCP text={PaymentMethodLabelEnum[charge.paymentMethod]} strong={true}/>
                        <TextCP text={`Valor total: ${MaskUtils.applyMoneyMask(_.sumBy(charge.installments, 'value'))} dividido em ${charge.installments.length}x`} fontStyle={'italic'}/>

                        <ConditionalRenderCP shouldRender={CreateSaleUtils.hasPaidInstallments(charge.installments)}>
                            <TextCP
                                text={`Valor total Pago: ${MaskUtils.applyMoneyMask(_.sumBy(_.filter(charge.installments, { isPaid: true }), 'value'))}`}
                                fontStyle={'italic'}
                                color={ThemeAnt.successColor}
                            />
                        </ConditionalRenderCP>
                    </>
                )
            },
            {
                key: 'actions',
                align: 'right',
                render: (text, charge, index) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ConditionalRenderCP shouldRender={!!onEdit}>
                            <ButtonCP
                                onClick={() => onEdit!(charge, index)}
                                icon={'edit'}
                                type={'ghost'}
                                marginRight={5}
                            />
                        </ConditionalRenderCP>
                        <ConditionalRenderCP shouldRender={!!onDelete && !CreateSaleUtils.hasPaidInstallments(charge.installments)}>
                            <ButtonCP
                                onClick={() => onDelete!(index)}
                                icon={'delete'}
                                type={'danger'}
                                confirmMsg={'Tem certeza que deseja remover essa forma de pagamento?'}
                            />
                        </ConditionalRenderCP>
                        <ConditionalRenderCP shouldRender={!!onDelete && CreateSaleUtils.hasPaidInstallments(charge.installments)}>
                            <ButtonCP
                                onClick={() => onDelete!(index)}
                                icon={'close'}
                                type={'danger'}
                                confirmMsg={'Tem certeza que deseja remover as parcelas não pagas dessa forma de pagamento?'}
                                tooltip={'Em cobranças com faturas pagas serão excluídas apenas as parcelas que não estão pagas'}
                            />
                        </ConditionalRenderCP>
                    </FlexCP>
                )
            },
        ]
    },

}
