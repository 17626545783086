import { AnyObjTP } from 'main/common/types/AnyObjTP'
import { Empty, Skeleton } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { LoadingCP } from 'main/common/components/loading/LoadingCP'

export interface ICPProps<ListItemTP> {
    requestConfig: (searchDto: any) => RequestConfigTP
    renderListItem: (record: ListItemTP, index: number) => React.ReactNode
    searchString?: string
    customFilters?: AnyObjTP
    onFinishRendering?: () => void
    shouldRenderItem?: (item: ListItemTP) => boolean
    forceLoad?: boolean
    onLoadData?: () => void
    emptyMessage?: string
}

/**
 * Componente de Lista com retorno da API.
 */
export function ListFromApi<ModelTP>(props: ICPProps<ModelTP>): JSX.Element {

    const [searchString, setSearchString] = useState<string>('')
    const [customFilters, setCustomFilters] = useState<AnyObjTP>()

    const searchRequest = useRequest<IGenericListResponseDTO<ModelTP>>()
    useEffect(onSearchRequestChange, [searchRequest.isAwaiting])

    useEffect(init, [props.forceLoad])
    useEffect(onFiltersChange, [props.searchString, props.customFilters])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.forceLoad === false)
            return

        setSearchString(props.searchString || '')
        setCustomFilters(props.customFilters)

        // Por enquanto esse tipo de lista nao tem paginacao.
        const searchDto = {
            itemsPerPage: 9999,
            page: 1,
            ...(props.customFilters || {})
        }

        searchRequest.runRequest(props.requestConfig(searchDto))
    }

    /**
     * Retorno da busca.
     */
    function onSearchRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchRequest, 'Erro ao buscar dados'))
            return

        props.onLoadData?.()
    }

    function shouldRunSearch(): boolean {

        if (searchRequest.isAwaiting)
            return false

        if (searchString !== props.searchString)
            return true

        const isStateFiltersEmpty = _.isEmpty(customFilters)
        const isPropsFiltersEmpty = _.isEmpty(props.customFilters)

        return (
            (isStateFiltersEmpty && !isPropsFiltersEmpty)
            || (!isStateFiltersEmpty && isPropsFiltersEmpty)
            || (!isStateFiltersEmpty && !isPropsFiltersEmpty && !_.isEqual(isStateFiltersEmpty, isPropsFiltersEmpty))
        )
    }

    function onFiltersChange(): void {
        if (shouldRunSearch())
            init()
    }

    return (
        <>
            <LoadingCP show={searchRequest.isAwaiting}/>

            {
                !searchRequest.responseData?.list.length &&
                <Empty description={props.emptyMessage ?? 'Nenhum item a ser exibido'}/>
            }

            <ListWrapperSCP>
                {
                    searchRequest.responseData?.list.map((record: ModelTP, i: number) => {

                        if (i === ((searchRequest.responseData?.list.length ?? 0) - 1) && !!props.onFinishRendering) {
                            const callback: Function = props.onFinishRendering // Usa copia para preservar ate apos o delay
                            setTimeout(() => callback(), 300)
                        }

                        return (
                            <Skeleton loading={searchRequest.isAwaiting} active={true} avatar={true} key={`${i}`}>
                                {props.renderListItem(record, i)}
                            </Skeleton>
                        )
                    })
                }
            </ListWrapperSCP>
        </>
    )
}

const ListWrapperSCP = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
`
