import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { ShotEmailListItemData } from 'main/modules/marketing/components/shot-list-item-mobile/inner/ShotEmailListItemDataICP'
import { ShotListItemActionsColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemActionsColumnICP'
import { ShotSmsListColumnsICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotSmsListColumnsICP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import React from 'react'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotListItemMobileCPProps {
    showActions: boolean
    shot: IMarketingCampaignEmailResponseDTO | IMarketingCampaignSmsResponseDTO
    shotType: MarketingAccountTypeEnum
    onUpdateList?: () => void
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 * COMPONENTE
 * Listagem de disparos de emails e sms para telas mobile
 */
export function ShotListItemMobileCP(props: IShotListItemMobileCPProps): JSX.Element {
    return (
        <ColumnCP size={24}>
            {
                props.shotType === MarketingAccountTypeEnum.EMAIL &&
                    <ShotEmailListItemData
                        shot={props.shot as IMarketingCampaignEmailResponseDTO}
                        semegmentList={props.semegmentList}
                    />
            }
            {
                props.shotType === MarketingAccountTypeEnum.SMS &&
                    <ShotSmsListColumnsICP
                        shot={props.shot as IMarketingCampaignSmsResponseDTO}
                        semegmentList={props.semegmentList}
                    />
            }
            {
                props.showActions &&
                <ShotListItemActionsColumnICP
                    onUpdateList={props.onUpdateList}
                    shotCode={props.shot.code}
                    shotType={props.shotType}
                    shotStatus={props.shot.status}
                />
            }
        </ColumnCP>
    )
}
