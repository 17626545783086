import React from 'react'
import { IInputCPProps, InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { FilterDropdownProps } from 'antd/es/table'
import { TableFilterICP } from 'main/common/components/table/filter/TableFilterICP'

interface ICPProps<FModelTP extends FormModel> extends IInputCPProps<FModelTP> {
    onSearch: () => void
    filterProps: FilterDropdownProps
}

/**
 * Filtro para uma coluna de tabela.
 */
export function TableInputFilterCP<FModelTP extends FormModel>(props: ICPProps<FModelTP>): JSX.Element {

    return (
        <TableFilterICP
            filterProps={props.filterProps}
            onSearch={props.onSearch}
        >
            <InputCP {...props /* eslint-disable-line react/jsx-props-no-spreading */}/>
        </TableFilterICP>
    )
}
