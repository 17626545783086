import React from 'react'
import styled from 'styled-components'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SaleStatusEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleStatusEnum'
import { TagSaleStatusCP } from 'main/modules/sale/components/tag-sale-status/TagSaleStatusCP'

export interface ICPProps {
    saleStatus: SaleStatusEnum
    treatments: INameAndCodeResponseDTO[]
}

/**
 * Descricao de um evento do calendario
 */
export function SchedulerEventExtraDescriptionCP(props: ICPProps): JSX.Element {

    return (
        <>
            {
                [SaleStatusEnum.DEFAULTING, SaleStatusEnum.PENDING, SaleStatusEnum.CANCELLED].includes(props.saleStatus) &&
                <div title={'Este é o status da compra'}>
                    <TagSaleStatusCP
                        margin={{ top: 5 }}
                        status={props.saleStatus}
                    />
                </div>
            }

            <TreatmentsWrapperSCP>
                <ul>
                    {
                        props.treatments.map((treatment, index) => (
                            <li key={`key${index}`}>
                                { treatment.name }
                            </li>
                        ))
                    }
                </ul>
            </TreatmentsWrapperSCP>
        </>
    )
}

const TreatmentsWrapperSCP = styled.div`
  margin: 5px 0 0 -20px;
  font-style: italic;
`
