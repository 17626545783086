import { Select } from 'antd'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import React, { useEffect, useState } from 'react'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { SelectCPUtils } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/SelectCPUtils'
import { InputValueCallbackTP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/types/InputValueCallbackTP'
import { SelectFullGroupedOptionTP, SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import * as _ from 'lodash'
import { OptionProps } from 'antd/lib/select'
import styled from 'styled-components'

const ALL_OPTION = 'all'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    options: SelectOptionTP[] | SelectFullGroupedOptionTP[]
    grouped?: boolean
    selectedOptions?: SelectOptionTP[]
    isMultiple?: boolean
    loading?: boolean
    defaultOpen?: boolean
    onSearch?: InputValueCallbackTP<string>
    onClose?: () => void
    onDeselect?: InputValueCallbackTP<any>
    returnFullOption?: boolean
    notFoundContent?: string | JSX.Element
    placeholder?: string | JSX.Element
    maxTagCount?: number
    labelInValue?: boolean
    allowClear?: boolean
    disabled?: boolean
    filterOption?: boolean | ((inputValue: string, option: React.ReactElement<OptionProps>) => boolean)
    multiple?: {
        showSelectAll?: boolean
        clearSearchTextOnSelect?: boolean
    }
}

/**
 * Input de multipla escolha para formularios.
 * OBSERVACAO: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 */
export function SelectCP<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {

    const [value, setValue] = useState<any>()
    useEffect(() => setValue(props.value), [props.value])

    /**
     */
    function handleChange(_valueParam: any): void {

        if (!props.onChange)
            return

        if (_valueParam === undefined)
            return props.onChange(undefined)

        if (props.isMultiple && _valueParam.includes(ALL_OPTION)) {

            // Implementar para os agrupados, caso deseje
            if (props.grouped)
                return

            props.onChange((props.options as SelectOptionTP[]).map((option) => {
                return typeof option === 'string' ? option : option.value
            }))
            return
        }

        const valueArr = Array.isArray(_valueParam) ? _valueParam : [_valueParam]
        const selectedOptions = !!props.returnFullOption ? valueArr.map(_value => getOptionByValue(_value)) : valueArr
        props.onChange(!!props.isMultiple ? selectedOptions : selectedOptions[0])
    }

    /**
     */
    function getOptionByValue(singleValue?: number): OrUndefinedTP<SelectOptionTP> {

        const options: SelectOptionTP[] = props.grouped
            ? _.flattenDeep((props.options as SelectFullGroupedOptionTP[]).map((optionGroup) => optionGroup.options))
            : props.options as SelectOptionTP[]

        let option = SelectCPUtils.getOptionByValue(options, singleValue)

        if (!option && !!props.selectedOptions)
            option = SelectCPUtils.getOptionByValue(props.selectedOptions, singleValue)

        return option
    }

    return (
        <SelectWrapperSCP>
            <FormItemICP<FModelTP, IFormItemCommonProps>
                label={props.label}
                onChange={handleChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                value={value}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
                hint={props.hint}
                marginTop={props.marginTop}
                marginRight={props.marginRight}
                marginBottom={props.marginBottom}
                marginLeft={props.marginLeft}
            >
                <Select
                    autoClearSearchValue={props.multiple?.clearSearchTextOnSelect}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    optionFilterProp={'children'}
                    mode={!!props.isMultiple ? 'multiple' : 'default'}
                    loading={props.loading}
                    allowClear={props.allowClear}
                    labelInValue={props.labelInValue}
                    onDeselect={props.onDeselect}
                    filterOption={props.filterOption ?? true}
                    showSearch={true}
                    dropdownMatchSelectWidth={false}
                    defaultOpen={props.defaultOpen}
                    maxTagCount={props.maxTagCount}
                    notFoundContent={props.notFoundContent ?? 'Nenhum item disponível'}
                    onSearch={props.onSearch}
                    onDropdownVisibleChange={(isVisible): void => {
                        if (!isVisible && !!props.onClose)
                            props.onClose()
                    }}
                >
                    {
                        props.multiple?.showSelectAll &&
                        <Select.Option
                            key={ALL_OPTION}
                            value={ALL_OPTION}
                        >
                            --- Selecionar Todos ---
                        </Select.Option>
                    }
                    {
                        props.grouped
                            ?
                            (props.options as SelectFullGroupedOptionTP[]).map((group) => (

                                <Select.OptGroup
                                    label={group.groupName}
                                >
                                    {
                                        group.options.map((opt) => (
                                                <Select.Option
                                                    key={typeof opt === 'string' ? opt : opt.key}
                                                    value={typeof opt === 'string' ? opt : opt.value}
                                                >
                                                    {typeof opt === 'string' ? opt : opt.label}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select.OptGroup>
                            ))
                            :
                            (props.options as SelectOptionTP[]).map((opt) => (
                                <Select.Option
                                    key={typeof opt === 'string' ? opt : opt.key}
                                    value={typeof opt === 'string' ? opt : opt.value}
                                >
                                    {typeof opt === 'string' ? opt : opt.label}
                                </Select.Option>
                            ))
                    }
                </Select>
            </FormItemICP>
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width: 100%;
`
