import React, { useEffect } from 'react'
import styled from 'styled-components'

/**
 */
export function ProductAnnounceCP(props:any): JSX.Element {
    
    useEffect(init, [props]);

    /**
     */
    function init(): void {
        console.log("RODOU A FUNCAO RENDER", (window as any).noticeable);
        const timer = setTimeout(()=>{
            (window as any).noticeable?.reset('WIYcg7YbOlOClkllfXCW').then((r:any)=>{
                (window as any).noticeable?.render('widget', 'WIYcg7YbOlOClkllfXCW')
            });
        },2000);
        console.log(timer);
    }


    return (
        <WrapperSCP>
            <div id='noticeable-widget'/>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-left: 18px;
  margin-top: 16px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
`
