import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { IsOptional } from 'class-validator/decorator/decorators'
import { IOpportunitySourceResponseDTO } from 'main/modules/sales-funnel/services/opportunity-source/dtos/response/IOpportunitySourceResponseDTO'
import { Moment } from 'moment'

export class SaleBasicDataFormModel extends FormModel {

    @IsNotEmpty()
    personCustomerCode: number

    @IsNotEmpty()
    personPayerCode: number

    @IsNotEmpty()
    sellerCode: number

    @IsNotEmpty()
    sourceCode: number

    @IsOptional()
    sourcePersonLinkCode?: number

    @IsNotEmpty()
    saleDate: Moment

    @IsNotEmpty()
    saleOrigin: SaleStatusEnum

    /*
     * Auxiliares
     */
    personCustomer?: INameAndCodeResponseDTO
    personPayer?: INameAndCodeResponseDTO
    source?: IOpportunitySourceResponseDTO
    sourcePersonLink?: INameAndCodeResponseDTO
    personSeller?: INameAndCodeResponseDTO

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
