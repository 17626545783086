/**
 * ENUM
 * Códigos de listas de segmentação 'especiais':
 * Listas padrão, fixas, que nao podem ser removidas;
 */
export enum SegmentSpecialListCodeEnum {
    CUSTOMER = 1,
    COLLABORATOR,
    SUPPLIER,
}
