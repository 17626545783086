import React from 'react'
import styled from 'main/config/theme/styledWithTheme'
import { ClockIconCP } from 'main/common/components/icons/ClockIconCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'
import { CheckIconCP } from 'main/common/components/icons/CheckIconCP'
import { CloseIconCP } from 'main/common/components/icons/CloseIconCP'
import { DokfyDocumentStatusEnum } from 'main/modules/documents/services/dokfy/enums/DokfyDocumentStatusEnum'

const FONT_SIZE = 14

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface IDocumentStatusCPProps extends _ScpPropsTP {
    status: DokfyDocumentStatusEnum
}

/**
 * COMPONENTE status de um documento.
 */
export function DocumentStatusCP(props: IDocumentStatusCPProps): JSX.Element | null {

    let icon: JSX.Element
    let text: string
    let color: TagCPColorTP
    switch (props.status) {

        case DokfyDocumentStatusEnum.SIGNED:
            icon = <CheckIconCP size={FONT_SIZE}/>
            text = 'Documento Assinado'
            color = 'blue'
            break

        case DokfyDocumentStatusEnum.AWAITING_SIGNATURE:
            icon = <ClockIconCP size={FONT_SIZE}/>
            text = 'Ag. Assinaturas'
            color = 'orange'
            break

        case DokfyDocumentStatusEnum.DECLINED:
            icon = <CloseIconCP size={FONT_SIZE}/>
            text = 'Documento Recusado'
            color = 'red'
            break

        default:
            icon = <></>
            text = ''
            color = 'orange'
            break
    }

    return (
        <WrapperSCP
            marginRight={props.marginRight ?? 0}
            marginLeft={props.marginLeft ?? 0}
            marginTop={props.marginTop ?? 0}
            marginBottom={props.marginBottom ?? 0}
        >
            <TagCP
                color={color}
                key={'doc-tag'}
                content={
                    <FlexCP alignItems={'center'}>
                        {icon}
                        <>{ text }</>
                    </FlexCP>
                }
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    
    i {
      margin-right: 5px;
    }
`
