import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISaleSourcesSyntheticReportFilterRequestDTO } from 'main/modules/reports/services/sale-reports/dtos/requests/ISaleSourcesSyntheticReportFilterRequestDTO'

/**
 * Requests de relatorios de venda
 */
export class SaleReportsRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/sales/reports`

    static saleSourcesSyntheticReport = (dto: ISaleSourcesSyntheticReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleReportsRequests._MODULE_BASE}/source-synthetic`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saleSourcesAnalyticReport = (dto: ISaleSourcesSyntheticReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleReportsRequests._MODULE_BASE}/source-analytic`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static salesFunnelIndicatorsReport = (dto: ISaleSourcesSyntheticReportFilterRequestDTO): RequestConfigTP => ({
        url: `${SaleReportsRequests._MODULE_BASE}/funnel-indicators`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}