import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { OpportunityAttemptEnum } from 'main/modules/sales-funnel/services/opportunity/enums/OpportunityAttemptEnum'

export class OpportunityFiltersFormModel extends FormModel {

    // Baseado no que é escolhido do lado esquerdo
    view: SalesFunnelOpportunityViewEnum
    type: OpportunityTypeEnum

    // Inputs do usuario
    searchString?: string
    name?: string
    phone?: string
    email?: string
    sourceCode?: number
    tags?: number[]
    funnelStepCode?: number
    responsibleCode?: number
    responsibleName?: string // Auxiliar para guardar o nome
    createDateRange?: IDateRangeFilter
    userCodeCreator?: number
    userCodeEvaluationResponsible?: number
    attempt?: OpportunityAttemptEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
