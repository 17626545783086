import React from 'react'
import {
    ScreenSubMenuItemTP,
    ScreenSubMenuTP
} from 'main/common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { SalesFunnelRoutes } from 'main/modules/sales-funnel/routes/SalesFunnelRoutes'
import { ScreenContentSalesFunnelOpportunityCP } from 'main/modules/sales-funnel/screens/screen-sales-funnel/screen-content/opportunities/ScreenContentSalesFunnelOpportunityCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import {
    SalesFunnelOpportunityViewEnum,
    SalesFunnelOpportunityViewLabelEnum
} from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { OpportunityTypeEnum, OpportunityTypeLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import {
    SalesFunnelSalesViewEnum,
    SalesFunnelSalesViewLabelEnum
} from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelSalesViewEnum'
import { ScreenContentSalesFunnelSalesCP } from 'main/modules/sales-funnel/screens/screen-sales-funnel/screen-content/sales/ScreenContentSalesFunnelSalesCP'
import { ISalesFunnelSummaryResponseListItemDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/ISalesFunnelSummaryResponseListItemDTO'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'

export const SALES_FUNNEL_SALES_SUBMENT_KEY = 'SALES_FUNNEL_SALES_SUBMENT_KEY'

/**
 * Reune metodos auxiliares para gestao da tela de Funil de Vendas.
 */
export const SalesFunnelScreenUtils = {

    /**
     * Obtem a configuracao de submenu
     */
    getSubMenuConfigs(
        viewsByType: ISalesFunnelSummaryResponseListItemDTO[],
        onDataChange: (user?: INameAndCodeResponseDTO) => void,
        initialUser?: INameAndCodeResponseDTO,
    ): Array<ScreenSubMenuTP<SalesFunnelOpportunityViewEnum | SalesFunnelSalesViewEnum>> {

        const submenuResult: Array<ScreenSubMenuTP<SalesFunnelOpportunityViewEnum | SalesFunnelSalesViewEnum>> = []

        // Submenu de oportunidades. Itera nos tipos de oportunidade criando um submenu para cada (LEAD, AVAL, FOLLOWUP)
        Object.values(OpportunityTypeEnum).forEach((opportunityType) => {
            submenuResult.push(
                {
                    key: opportunityType,
                    title: OpportunityTypeLabelEnum[opportunityType],
                    items: SalesFunnelScreenUtils.getOpportunitySubMenuByViewConfigs(opportunityType, viewsByType, onDataChange, initialUser),
                    enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_CRM])
                },
            )
        })

        // Submenu de vendas
        submenuResult.push(
            {
                key: SALES_FUNNEL_SALES_SUBMENT_KEY,
                title: 'Vendas',
                items: SalesFunnelScreenUtils.getSaleSubMenuByViewConfigs(viewsByType, onDataChange, initialUser),
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_CRM])
            },
        )

        return submenuResult
    },

    /**
     * Monta o submenu de um tipo de oportunidade
     */
    getOpportunitySubMenuByViewConfigs(
        opportunityType: OpportunityTypeEnum,
        views: ISalesFunnelSummaryResponseListItemDTO[],
        onDataChange: (user?: INameAndCodeResponseDTO) => void,
        initialUser?: INameAndCodeResponseDTO,
    ): Array<ScreenSubMenuItemTP<SalesFunnelOpportunityViewEnum>> {

        const submenuItens: Array<ScreenSubMenuItemTP<SalesFunnelOpportunityViewEnum>> = []
        Object.values(SalesFunnelOpportunityViewEnum).filter((leadView) => leadView.startsWith(opportunityType)).forEach((view) => {
            const foundView = views.find((_view) => _view.viewType === view)
            submenuItens.push({
                name:
                    <FlexCP justifyContent={'space-between'}>
                        <TextCP text={SalesFunnelOpportunityViewLabelEnum[view]}/>
                        <TextCP text={MaskUtils.applyNumberMask(foundView?.viewTotal ?? 0, 0)}/>
                    </FlexCP>,
                view,
                route: `${SalesFunnelRoutes.ROOT}/${view}`,
                content: <ScreenContentSalesFunnelOpportunityCP onDataChange={onDataChange} initialUser={initialUser}/>
            })
        })

        return submenuItens
    },

    /**
     * Monta o submenu de um tipo de venda
     */
    getSaleSubMenuByViewConfigs(
        views: ISalesFunnelSummaryResponseListItemDTO[],
        onDataChange: (user?: INameAndCodeResponseDTO) => void,
        initialUser?: INameAndCodeResponseDTO,
    ): Array<ScreenSubMenuItemTP<SalesFunnelSalesViewEnum>> {

        const submenuItens: Array<ScreenSubMenuItemTP<SalesFunnelSalesViewEnum>> = []
        Object.values(SalesFunnelSalesViewEnum).forEach((view) => {

            const foundView = views.find((_view) => _view.viewType === view)
            submenuItens.push({
                name:
                    <FlexCP justifyContent={'space-between'}>
                        <TextCP text={SalesFunnelSalesViewLabelEnum[view]}/>
                        <TextCP text={MaskUtils.applyNumberMask(foundView?.viewTotal ?? 0, 0)}/>
                    </FlexCP>,
                view,
                route: `${SalesFunnelRoutes.ROOT}/${view}`,
                content: <ScreenContentSalesFunnelSalesCP onDataChange={onDataChange} initialUser={initialUser}/>
            })
        })

        return submenuItens
    },

    /**
     * Limpa os filtros da tela.
     */
    clearFilters(filterStateManager: IFormStateManager<OpportunityFiltersFormModel>): void {
        filterStateManager.changeFieldValue('searchString', undefined)
        filterStateManager.changeFieldValue('name', undefined)
        filterStateManager.changeFieldValue('phone', undefined)
        filterStateManager.changeFieldValue('email', undefined)
        filterStateManager.changeFieldValue('sourceCode', undefined)
        filterStateManager.changeFieldValue('tags', undefined)
        filterStateManager.changeFieldValue('funnelStepCode', undefined)
        filterStateManager.changeFieldValue('responsibleCode', undefined)
        filterStateManager.changeFieldValue('responsibleName', undefined)
        filterStateManager.changeFieldValue('createDateRange', undefined)
        filterStateManager.changeFieldValue('userCodeCreator', undefined)
        filterStateManager.changeFieldValue('userCodeEvaluationResponsible', undefined)
    }
}

