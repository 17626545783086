import { ButtonCP } from 'main/common/components/button/ButtonCP'
import React, { useEffect } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { ButtonNFeCP } from 'main/modules/sale/components/button-nfe/ButtonNFeCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { MultiActionButtonCP } from 'main/common/components/multi-action-button/MultiActionButtonCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

interface ISaleDetailsButtonsICPProps {
    sale: ISaleResponseDTO
    onGenerateContract: () => void
    onChangeSaleData: () => void
    onPrint?: () => void
    onEditSale: () => void
    onEditCharges: () => void
    onLoadNfe?: (nfe?: NFeResponseDTO<NFeCustomArgsTP>) => void
}

/**
 * COMPONENTE Botoes de acao do drawer
 */
export function SaleDetailsButtonsICP(props: ISaleDetailsButtonsICPProps): JSX.Element {
    let botaoEdicao = false;
    
    let render = props.sale.saleTotalValuesData.totalValue > 0;

    if(AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_FINANCIAL) || AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN))botaoEdicao = true;
    const cancelSaleRequest = useRequest<void>('none')
    useEffect(onCancelSaleRequestChange, [cancelSaleRequest.isAwaiting])

    const updateSaleStatusRequest = useRequest<void>('none')
    useEffect(onUpdateSaleStatusRequestChange, [updateSaleStatusRequest.isAwaiting])

    /**
     * Cancelar venda.
     */
    function cancelSale(): void {
        cancelSaleRequest.runRequest(SaleRequests.cancelSale(props.sale.code))
    }

    /**
     * Retorno do cancelamento da venda.
     */
    function onCancelSaleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(cancelSaleRequest, 'Não foi possível cancelar essa venda', true))
            return

        NotificationHelper.success('Pronto!', 'Venda cancelada com sucesso')
        props.onChangeSaleData()
    }

    /**
     * Atualizar status da venda.
     */
    function updateSaleStatus(): void {
        updateSaleStatusRequest.runRequest(SaleRequests.updateSaleStatus(props.sale.code))
    }

    /**
     * Retorno do atualizar status da venda.
     */
    function onUpdateSaleStatusRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateSaleStatusRequest, 'Não foi possível atualizar status da venda', true))
            return

        NotificationHelper.success('Pronto!', 'Status da venda atualizada com sucesso')
        props.onChangeSaleData()
    }

    return (
        <>
            <FlexCP justifyContent={'space-between'} marginBottom={20}>
                <FlexCP>
                    <ButtonCP
                        type={'ghost'}
                        onClick={props.onPrint}
                        icon={'printer'}
                        marginRight={5}
                    >
                        Imprimir
                    </ButtonCP>
                    <ConditionalRenderCP shouldRender={render}>
                        <ButtonNFeCP
                            sale={props.sale}
                            viewMode={'saleNfe'}
                            onLoadNfe={props.onLoadNfe}
                        />
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        icon={'file-protect'}
                        onClick={props.onGenerateContract}
                        marginLeft={5}
                    >
                        Contrato
                    </ButtonCP>
                </FlexCP>

                <FlexCP justifyContent={'flex-end'}>
                <ConditionalRenderCP shouldRender={botaoEdicao}>
                    <MultiActionButtonCP
                            loading={updateSaleStatusRequest.isAwaiting}
                            disabled={props.sale.status === SaleStatusEnum.CANCELLED}
                            options={[
                                {
                                    label: 'Editar Venda',
                                    icon: <IconCP antIcon={'edit'}/>,
                                    onClick: props.onEditSale,
                                },
                                {
                                    icon: <IconCP antIcon={'ordered-list'}/>,
                                    label: 'Editar Parcelamento',
                                    onClick: props.onEditCharges
                                },
                                {
                                    label: 'Atualizar Status da Venda',
                                    icon: <IconCP antIcon={'reload'}/>,
                                    onClick: updateSaleStatus
                                },
                            ]}
                        />
                </ConditionalRenderCP>
                    
                    <ConditionalRenderCP shouldRender={props.sale.status !== SaleStatusEnum.CANCELLED && botaoEdicao}>
                        <ButtonCP
                            type={'danger'}
                            icon={'stop'}
                            onClick={cancelSale}
                            marginLeft={5}
                            confirmMsg={'Você tem certeza que deseja cancelar essa venda?'}
                            loading={cancelSaleRequest.isAwaiting}
                        />
                    </ConditionalRenderCP>
                </FlexCP>
            </FlexCP>
        </>
    )
}
