import React, { useEffect, useRef, useState } from 'react'
import { ContractModalCP } from 'main/modules/documents/components/contract-modal/ContractModalCP'
import { SaleDetailsButtonsICP } from 'main/modules/sale/components/drawer-sale-details/inner/SaleDetailsButtonsICP'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { SaleDetailsPdfCP } from 'main/modules/sale/components/sale-details-pdf/SaleDetailsPdfCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { useReactToPrint } from 'react-to-print'
import { ModalSaleEditDataCP } from 'main/modules/sale/components/modal-sale-edit-data/ModalSaleEditDataCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { CollapseSaleChargesCP } from 'main/modules/sale/components/collapse-sale-charges/CollapseSaleChargesCP'
import { ModalEditSaleChargesCP } from 'main/modules/sale/components/modal-edit-sale-charges/ModalEditSaleChargesCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'

interface ISaleDetailsDrawerCPProps {
    sale: ISaleResponseDTO
    onChangeSaleData: () => void
}

/**
 */
export function SaleDataTabICP(props: ISaleDetailsDrawerCPProps): JSX.Element {

    const [isSaleEditModalVisible, setIsSaleEditModalVisible] = useState<boolean>(false)
    const [isSaleEditChargesModalVisible, setIsSaleEditChargesModalVisible] = useState<boolean>(false)
    const [isContractModalVisible, setIsContractModalVisible] = useState<boolean>(false)

    const [saleNfe, setSaleNfe] = useState<NFeResponseDTO<NFeCustomArgsTP>>()
    const [charges, setCharges] = useState<IChargeListItemResponseDTO[]>([])

    const [shouldLoadCharges, setShouldLoadCharges] = useState<boolean>(true)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: `nc-venda-${props.sale.code}`,
        content: () => componentRef.current as any,
    })

    useEffect(() => setShouldLoadCharges(true), [props.sale])

    return (
        <>
            <>
                <SaleDetailsButtonsICP
                    sale={props.sale}
                    onGenerateContract={() => setIsContractModalVisible(true)}
                    onChangeSaleData={props.onChangeSaleData}
                    onPrint={handlePrint}
                    onEditSale={() => setIsSaleEditModalVisible(true)}
                    onEditCharges={() => setIsSaleEditChargesModalVisible(true)}
                    onLoadNfe={setSaleNfe}
                />

                <TitleCP textSize={'normal'} underLine={true}>
                    Detalhes das Parcelas
                </TitleCP>
                {
                    props.sale.installmentsQuantity === 0
                        ?
                        <TextCP text={'Não possui parcelas, valor da venda é R$ 0,00'}/>
                        :
                        <CollapseSaleChargesCP
                            saleCode={props.sale.code}
                            sale={props.sale}
                            onLoadCharges={(_charges) => {
                                setCharges(_charges)
                                setShouldLoadCharges(false)
                            }}
                            onChangePayment={() => {
                                props.onChangeSaleData()
                                setShouldLoadCharges(true)
                            }}
                            shouldLoadCharges={shouldLoadCharges}
                            hasSaleNfe={!!saleNfe}
                        />
                }

                <TitleCP
                    textSize={'normal'}
                    underLine={true}
                    marginTop={40}
                >
                    Resumo da Venda
                </TitleCP>
                <PrintAreaWrapperSCP>
                    <SaleDetailsPdfCP
                        sale={props.sale}
                        ref={componentRef}
                    />
                </PrintAreaWrapperSCP>
            </>

            <ContractModalCP
                sale={props.sale}
                visible={isContractModalVisible}
                onClose={() => setIsContractModalVisible(false)}
            />

            <ModalSaleEditDataCP
                visible={isSaleEditModalVisible}
                onCancel={() => setIsSaleEditModalVisible(false)}
                onSave={() => {
                    setIsSaleEditModalVisible(false)
                    setShouldLoadCharges(true)
                }}
                sale={props.sale}
            />

            {
                !SystemUtils.isEmpty(charges) &&
                <ModalEditSaleChargesCP
                    visible={isSaleEditChargesModalVisible}
                    onSave={() => {
                        setIsSaleEditChargesModalVisible(false)
                        setShouldLoadCharges(true)
                        props.onChangeSaleData()
                    }}
                    onCancel={() => {
                        setIsSaleEditChargesModalVisible(false)
                        setShouldLoadCharges(true)
                    }}
                    charges={charges}
                    saleCode={props.sale.code}
                    saleDate={DateUtils.toDate(props.sale.date, DateFormatEnum.US_WITHOUT_TIME)}
                />
            }
        </>
    )
}

const PrintAreaWrapperSCP = styled.div`
  display: grid;
  border: dashed #dedede 1px;
  border-radius: 5px;
  padding: 10px;

  // Precisa tirar o padding que foi inserido para impressao
  // Dessa forma so tira na tela, e na impressao ira ter o padding interno
  div:first-of-type {
    padding: 0;
  }
`
