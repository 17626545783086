import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import FileSaver from 'file-saver'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { ButtonSizeTP } from 'main/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
    requestConfig: () => RequestConfigTP | undefined
    reportName: string
    size?: ButtonSizeTP
}

/**
 * Botoes para exportar uma tabela
 */
export function ButtonExportCP(props: ICPProps): JSX.Element {

    const generateReportRequest = useRequest<Blob>('none')
    useEffect(onGenerateReportRequestChange, [generateReportRequest.isAwaiting])

    /**
     * Gera relatorio.
     */
    function generateReport(): void {
        generateReportRequest.runRequest(props.requestConfig())
    }

    /**
     * Retorno ao gerar o relatorio
     */
    function onGenerateReportRequestChange(): void {

        if (generateReportRequest.isAwaiting || !generateReportRequest.wasTried)
            return

        if (!generateReportRequest.isSuccess || !generateReportRequest.responseData) {
            console.error('FALHA - ActionsColumn.onDownloadRequestChange: ', generateReportRequest.responseData, generateReportRequest.error)
            return RequestUtils.showDefaultErrorNotification(generateReportRequest.error, 'Falha ao tentar realizar download')
        }

        FileSaver.saveAs(generateReportRequest.responseData, `nc_${props.reportName}.csv`)
    }

    return (
        <ButtonCP
            icon={'file-excel'}
            size={props.size ?? 'small'}
            onClick={generateReport}
            loading={generateReportRequest.isAwaiting}
            type={'ghost'}
        >
            Exportar .csv
        </ButtonCP>
    )
}
