import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { FranchiseManagerSaleIndicatorsTabICP } from 'main/modules/dashboard/components/tabs-franchise-manager/inner/FranchiseManagerSaleIndicatorsTabICP'
import { FranchiseManagerPerformanceTabICP } from 'main/modules/dashboard/components/tabs-franchise-manager/inner/FranchiseManagerPerformanceTabICP'
import { FranchiseManagerQualityTabICP } from 'main/modules/dashboard/components/tabs-franchise-manager/inner/FranchiseManagerQualityTabICP'
import { FranchiseManagerRankingsTabICP } from 'main/modules/dashboard/components/tabs-franchise-manager/inner/FranchiseManagerRankingsTabICP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PrintAreaCP } from 'main/common/components/print-area-cp/PrintAreaCP'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Dashboard da técnica de procedimento
 */
export function TabsFranchiseManagerCP(props: ICPProps): JSX.Element {

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({ beginDate: moment().startOf('month').toDate(), endDate: new Date() })

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: 'nc-dashboard',
        content: () => componentRef.current as any,
    })

    return (
        <WrapperSCP>

            <TabsCP
                tabs={[
                    {
                        title: 'Indicadores de Vendas',
                        key: 'sale-compare',
                        content:
                            <PrintAreaCP ref={componentRef}>
                                <FranchiseManagerSaleIndicatorsTabICP franchiseCodes={props.franchiseCodes} dateFilters={dateFilters} onDateRangeChange={setDateFilters}/>
                            </PrintAreaCP>
                    },
                    {
                        title: 'Performance',
                        key: 'performance',
                        content:
                            <PrintAreaCP ref={componentRef}>
                                <FranchiseManagerPerformanceTabICP franchiseCodes={props.franchiseCodes} dateFilters={dateFilters} onDateRangeChange={setDateFilters}/>
                            </PrintAreaCP>
                    },
                    {
                        title: 'Qualidade Técnica',
                        key: 'feedback',
                        content:
                            <PrintAreaCP ref={componentRef}>
                                <FranchiseManagerQualityTabICP franchiseCodes={props.franchiseCodes} dateFilters={dateFilters} onDateRangeChange={setDateFilters}/>
                            </PrintAreaCP>
                    },
                    {
                        title: 'Rankings',
                        key: 'rankings',
                        content:
                            <PrintAreaCP ref={componentRef}>
                                <FranchiseManagerRankingsTabICP franchiseCodes={props.franchiseCodes} dateFilters={dateFilters} onDateRangeChange={setDateFilters}/>
                            </PrintAreaCP>
                    },
                ]}
                extraContent={
                    <ButtonCP
                        onClick={handlePrint}
                        icon={'printer'}
                    />
                }
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 50px;
`
