import React from 'react'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { ExpressionICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionICP'
import { IExpressionICPProps } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/IExpressionICPProps'

/**
 * COMPONENTE
 * Barra widget de montagem da expressao para filtragem de 01 lista de segmentacao:
 * Este componente funciona como wrapper para inserir o contexto da expressao.
 *
 * @see ExpressionContext
 */
export function SegmentExpressionBarCP(props: IExpressionICPProps): JSX.Element {
    return (
        <ExpressionContext.Provider>
            <ExpressionICP
                onChange={props.onChange}
                expression={props.expression}
                segmentType={props.segmentType}
                isLoading={props.isLoading}
            />
        </ExpressionContext.Provider>
    )
}
