import { Avatar, List, Skeleton } from 'antd'
import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Item } = List

export interface IListItemICPProps {
    extra?: string | JSX.Element
    loading?: boolean
    style?: React.CSSProperties
    hoverable?: boolean
    actions?: JSX.Element[]
    avatar?: string | JSX.Element
    title?: string | JSX.Element
    description?: string | JSX.Element
    children?: JSX.Element
}

/**
 * Componente Item de uma Lista
 * @param {IListItemCPProps} props
 *
 * @author guilherme.diniz
 */
function ListItemICPOLD(props: IListItemICPProps): JSX.Element {

    function getAvatar(): JSX.Element | undefined {

        if (!props.avatar)
            return

        if (typeof props.avatar === 'string')
            return <Avatar src={props.avatar} size={200} shape={'square'}/>

        return props.avatar
    }

    return (
        <Item
            actions={!props.loading ? props.actions : []}
            extra={!props.loading && props.extra}
            style={props.style}
        >
            <Skeleton loading={props.loading} active={true} avatar={{ size: 200 }}>
                <Item.Meta
                    avatar={getAvatar()}
                    title={props.title}
                    description={props.description}
                />
                {props.children}
            </Skeleton>
        </Item>
    )
}

export default ListItemICPOLD