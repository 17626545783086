import { OrReadonlyTP } from '../types/OrReadonlyTP'
import * as _ from 'lodash'

/**
 * UTILS
 * Encapsula logica auxiliar util para manipulacao de vetores.
 */
export const ArrayUtils = {

    /** Retorno numero do ultimo indice valido num vetor. */
    getLastIndex(array: OrReadonlyTP<any[]>): number {
        return !!array.length ? (array.length - 1) : 0
    },

    /** Transforma vetor de strings numericas num vetor de numeros. */
    toNumberList(array: string[]): number[] {
        return array.map(item => +item).filter(item => !isNaN(item))
    },

    /** Transforma vetor de numeros num vetor de strings numericas. */
    toStringList(array: number[]): string[] {
        return array.map(item => item.toString())
    },

    /** Gera & retorna vetor de numeros inteiros compreendidos entre os limites superior & inferior (padrao = 0) estabelecidos. */
    getRange(max: number, min?: number): number[] {
        const range = Array.from(new Array(max + 1).keys())
        return min ? range.map(i => i + min) : range
    },

    /** Extrai & retorna intercessao entre 02 vetores (i.e: lista de itens presentes em ambos). */
    getIntersection<TP>(array1: TP[], array2: TP[]): TP[] {
        return array1.filter(value => array2.includes(value)) || []
    },

    /** Se esta vazio. */
    isEmpty(value: unknown): boolean {
        return ((typeof value !== 'object' && !value)
            || (typeof value === 'object' && _.isEmpty(value))
        )
    },

}
