import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { Moment } from 'moment'
import { IsDateString } from 'main/common/validation/decorators/IsDateString'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

export class PayFinancialTransactionFormValidator extends GenericFormValidator {

    @IsNotEmpty()
    paidValue: number

    @IsNotEmpty()
    @IsDateString()
    paidDate: Moment

    @IsNotEmpty()
    @IsIn(Object.values(PaymentMethodEnum))
    paymentMethod: PaymentMethodEnum

    @IsNotEmpty()
    bankAccountCode: number

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
