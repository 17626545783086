import React, { useEffect, useState } from 'react'
import { MapCP } from 'main/common/components/map/MapCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IFranciseMapListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IFranciseMapListItemResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { MapPinTP } from 'main/common/components/map/inner/MapPinTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'

interface IMapFranchisesCPProps {
    dateRange?: IDateRangeFilter
}

/**
 * Mapa das unidades
 */
export function MapFranchisesCP(props: IMapFranchisesCPProps): JSX.Element {

    useEffect(init, [props.dateRange])

    const [franchises, setFranchises] = useState<IFranciseMapListItemResponseDTO[]>()
    const getFranchisesMapRequest = useRequest<IGenericListResponseDTO<IFranciseMapListItemResponseDTO>>()
    useEffect(onGetFranchisesRequestChange, [getFranchisesMapRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        const dto: IDateRangeSearchRequestDTO = {
            beginDate: DateUtils.getFormatted(props.dateRange?.beginDate, DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(props.dateRange?.endDate, DateFormatEnum.US_WITHOUT_TIME)
        }

        getFranchisesMapRequest.runRequest(DashboardRequests.getMap(dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetFranchisesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFranchisesMapRequest, 'Erro obter mapa das unidades'))
            return

        setFranchises(getFranchisesMapRequest.responseData!.list)
    }

    /**
     * Obtem componente com os ponstos do mapa.
     */
    function getPinsCP(franchisesMap: IFranciseMapListItemResponseDTO[]): MapPinTP[] {

        const pins: MapPinTP[] = []

        franchisesMap.forEach((item) => {

            if (!item.franchise.address?.latitude || !item.franchise.address?.longitude)
                return

            pins.push({
                lat: Number(item.franchise.address.latitude),
                lng: Number(item.franchise.address.longitude),
                text: `${StringUtils.getInitials(item.franchise.name)} (${item.saleValueRank}º)`,
                popOverTitle: item.franchise.name,
                popOverContent: <>{`Ranking de vendas no período: ${item.saleValueRank}º`}</>,
            })

        })

        return pins
    }

    return (
        <MapCP
            pins={getPinsCP(franchises ?? [])}
            isLoading={getFranchisesMapRequest.isAwaiting}
        />
    )
}
