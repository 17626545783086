/**
 * Tipos de conta bancaria.
 * Existem apenas 3 tipos para facilitar o manuseio da criacao e uso das contas bancarias.
 *  - BANK_ACCOUNT que eh uma conta bancaria tradicional, com agencia e numero, como Bradesco, Banco do Brasil, etc...
 *  - PAYMENT_ACCOUNT que representa apenas um meio de recebimento de dinheiro, como TECNOSPEED e JUNO por exemplo.
 *  - CREDIT_CARD que representa um cartao de credito.
 */
export enum BankAccountTypeEnum {
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT',
    CREDIT_CARD = 'CREDIT_CARD'
}

export enum BankAccountTypeLabelEnum {
    BANK_ACCOUNT = 'Conta Bancária',
    PAYMENT_ACCOUNT = 'Meio de Pagamento',
    CREDIT_CARD = 'Cartão de Crédito'
}

export enum BankAccountTypePluralLabelEnum {
    BANK_ACCOUNT = 'Contas Bancárias',
    PAYMENT_ACCOUNT = 'Meios de Pagamento',
    CREDIT_CARD = 'Cartões de Crédito'
}
