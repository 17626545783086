import React from 'react'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { SchedulerRoutes } from 'main/modules/scheduler/routes/SchedulerRoutes'
import { ScreenDashboardCP } from 'main/modules/dashboard/screens/screen-dashboard/ScreenDashboardCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

DashboardRoutes.ROOT = 'dashboard'

const DASHBOARD_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(DashboardRoutes.ROOT),
        path: DashboardRoutes.ROOT,
        component: ScreenDashboardCP,
    },
]

/**
 * ROTEADOR
 * Define & manipula rotas do modulo de DASHBOARD.
 */
export function DashboardRoutes(): JSX.Element {

    return (
        <AccessControlCP
            routeToReturn={SchedulerRoutes.CALENDAR}
            permissions={
                AppStateUtils.getCurrentFranchise()?.isFranchisor
                    ? undefined
                    : [PermissionEnum.ROLE_DASHBOARD_MANAGER, PermissionEnum.ROLE_DASHBOARD_CONSULTANT, PermissionEnum.ROLE_DASHBOARD_ATTENDANT]
            }
        >
            { RoutingHelper.renderRoutingSwitch(DASHBOARD_ROUTES_CONFIG) }
        </AccessControlCP>
    )
}
