import _ from 'lodash'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/InputValueCallbackTP'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ProductReleaseFranchiseRequests } from 'main/modules/products/services/product-release-franchise/ProductReleaseFranchiseRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO } from 'main/modules/products/services/product-release-franchise/dtos/response/IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO'

interface IProductsSelectCPProps {
    hasPrice: boolean
    multiple?: boolean
    disabled?: boolean
    required?: boolean
    maxTagCount?: number
    label?: string
    placeholder?: string
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    onProductListChange?: (product?: IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO[]) => void
    onChange?: InputValueCallbackTP<number[] | number>
    fontSize?: 'small' | 'title' | 'extraSmall' | 'normal' | 'large' | 'extraLarge' | undefined
    initialOptions?: IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO[]
}

/**
 * Componente para select de releases de uma franquia.
 */
export function SelectProductReleaseFranchiseCP(props: IProductsSelectCPProps): JSX.Element {

    const [selectedProductsCode, setSelectedProductsCode] = useState<number[]>([])
    const [productsOptions, setProductsOptions] = useState<SelectOptionTP[]>()

    const productsRequest = useRequest<IGenericListResponseDTO<IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO>>()
    useEffect(onProductsRequestChange, [productsRequest.isAwaiting])

    useEffect(init, [])
    useEffect(updateProductsList, [selectedProductsCode])

    /**
     * Inicializa componente.
     */
    function init(): void {

        if (!!props.initialOptions)
            setSelectedProductsCode(props.initialOptions.map((prd) => prd.code))

        if (props.disabled || productsOptions)
            return

        productsRequest.runRequest(ProductReleaseFranchiseRequests.searchProductReleasesFranchiseNameAndPrice())
    }

    /**
     * Retorno da api que busca os produtos
     */
    function onProductsRequestChange(): void {
        if (!productsRequest.responseData || _.isEmpty(productsRequest.responseData.list))
            return

        const options = productsRequest.responseData.list.map(product => ({
            value: product.code,
            key: `${product.code}`,
            label: product.release.name
        }))

        setProductsOptions(options)
    }

    /**
     * Ao selecionar um produto.
     */
    function onSelectProduct(_selectedProduct: number[]): void {

        setSelectedProductsCode(_selectedProduct)

        if (props.multiple && props.onChange && _.isArray(selectedProductsCode))
            props.onChange(selectedProductsCode.filter((val: any) => typeof val === 'number'))
    }

    /**
     * Define a lista de produtos
     */
    function updateProductsList(): void {

        if (!productsRequest.responseData)
            return

        const productsList = selectedProductsCode.map(code => (
            productsRequest.responseData!.list.find(product => product.code === code)
        ))

        if (productsList && props.onProductListChange)
            props.onProductListChange(_.compact(productsList))
    }

    return (
        <SelectWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                label={props.label}
                maxTagCount={props.maxTagCount}
                value={selectedProductsCode}
                isMultiple={props.multiple}
                disabled={props.disabled}
                required={props.required}
                formStateManager={props.formStateManager}
                fieldName={props.fieldName}
                fontSize={props.fontSize}
                options={props.disabled ? [] : productsOptions ?? []}
                onChange={onSelectProduct}
                loading={productsRequest.isAwaiting}
                filterOption={true}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width: 100%;
`
