import React from 'react'
import { LogicOperatorICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/logic-operator/LogicOperatorICP'
import { AggregatorICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorICP'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { SegmentExpUnitForEditionTP } from 'main/modules/segment/types/expression/SegmentExpUnitForEditionTP'
import { SegmentAggForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggForEditionTP'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { AggregatorContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorContext'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { SegmentUnitForEditionTP } from 'main/modules/segment/types/unit/SegmentUnitForEditionTP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'

interface IExpressionUnitICPProps {
    i: number
    unit: SegmentEditableItemTP<SegmentExpUnitForEditionTP>
}

/**
 * COMPONENTE
 * Item de 01 expressao numa lista de segmentacaco.
 */
export const ExpressionUnitICP = ComponentUtils.memoizeCp((props: IExpressionUnitICPProps): JSX.Element => {

    const expressionContext = ExpressionContext.use()

    if (props.unit.itemType === 'op') {
        return (
            <LogicOperatorICP
                onChange={operator => expressionContext.actions.editUnit(operator)}
                operator={props.unit}
                horizontalMargin={7}
                hide={expressionContext.state.hoveredAggregatorIdx === (props.i - 1)}
                disabled={expressionContext.state.isLoading}
            />
        )
    }

    const isFirstAgg = (props.i === 0)
    const aggUnits = (props.unit as SegmentAggForEditionTP).units ?? []
    const firstAggFirstUnit = (expressionContext.state.expression[0] as SegmentAggForEditionTP)?.units?.[0]

    return (
        <AggregatorContext.Provider>
            <AggregatorICP
                aggregator={props.unit as SegmentAggForEditionTP}
                onMouse={mouseIn => expressionContext.actions.setHoveredAggregator(mouseIn ? props.i : undefined)}
                enableRemove={!isFirstAgg || aggUnits.length > 1 || SegmentUtils.isUnitSelected(firstAggFirstUnit as SegmentUnitForEditionTP)}
            />
        </AggregatorContext.Provider>
    )
})
