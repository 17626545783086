import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'

export type RefundTP = 'full' | 'partial'

export class ChargeCancellationFormModel extends FormModel {

    @IsOptional()
    refundType: RefundTP

    @IsOptional()
    refundValue: number

    @IsRequired()
    reason: string

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
