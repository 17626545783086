import { Icon } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { ActivityColumnItemICP } from 'main/modules/sales-funnel/components/table-opportunity/inner/ActivityColumnItemICP'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import { IOpportunitySearchRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySearchRequestDTO'
import React from 'react'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { OpportunitySearchOrderingEnum } from 'main/modules/sales-funnel/services/opportunity/enums/OpportunitySearchOrderingEnum'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityTableActionColumnICP } from 'main/modules/sales-funnel/components/table-opportunity/inner/OpportunityTableActionColumnICP'
import { OrderingEnum } from 'main/common/enums/OrderingEnum'
import { TableInputFilterCP } from 'main/common/components/table/filter/TableInputFilterCP'
import { TagTruncateColumnItemCP } from 'main/modules/people/components/tag-truncate-column-item/TagTruncateColumnItemCP'
import { TableFilterICP } from 'main/common/components/table/filter/TableFilterICP'
import { AutocompleteTagCP } from 'main/modules/admin/components/autocomplete-tag/AutocompleteTagCP'
import { AutoCompleteOpportunitySourceCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity-source/AutoCompleteOpportunitySourceCP'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { TableSortAndPageTP } from 'main/common/components/table/inner/TableSortAndPageTP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'

/**
 * UTILS
 * Reúne métodos úteis para montagem do componente de tabela de oportunidades LEADS.
 */
export const OpportunitiesTableUtils = {

    getTableColumns(
        onSelectOpportunity: (code: number, conversionType?: OpportunityDrawerActionTP) => void,
        formStateManager: IFormStateManager<OpportunityFiltersFormModel>,
        onOpportunityDataChanged: () => void,
        onFilter: () => void,
        funnelStepsData?: IFunnelStepResponseDTO[],
    ): Array<ColumnProps<IOpportunityListItemResponseDTO>> {

        return [
            {
                title: 'Nome',
                key: OpportunitySearchOrderingEnum.NAME,
                sorter: true,
                filterIcon: () => <Icon type={'search'}/>,
                filterDropdown: (filterProps) => (
                    <TableInputFilterCP filterProps={filterProps} label={'Filtre pelo nome'} formStateManager={formStateManager} fieldName={'name'} onSearch={onFilter}/>
                ),
                render: (text, opportunity) => (
                    <>
                        <div>{opportunity.name}</div>
                        <FlexCP>
                            <ConditionalRenderCP shouldRender={opportunity.hasFreeSale}>
                                <TagCP small={true} content={'Compra Gratuita'} color={'lightBlue'}/>
                            </ConditionalRenderCP>
                            <ConditionalRenderCP shouldRender={opportunity.hasSaleWithValue}>
                                <TagCP small={true} content={'Comprou'} color={'green'}/>
                            </ConditionalRenderCP>
                        </FlexCP>
                    </>
                ),
            },
            {
                title: 'Próx. atividade',
                key: OpportunitySearchOrderingEnum.NEXT_ACTIVITY,
                sorter: true,
                render: (text, opportunity) => (
                    <ActivityColumnItemICP
                        opportunity={opportunity}
                        onActivitiesActionsDone={(conversionType) => {
                            if (!!conversionType)
                                onSelectOpportunity(opportunity.code, conversionType)
                            onOpportunityDataChanged()
                        }}
                    />
                )
            },
            {
                title: 'Tags',
                key: 'tags',
                width: '100px',
                filterIcon: () => <Icon type={'filter'}/>,
                filterDropdown: (filterProps) => (
                    <TableFilterICP filterProps={filterProps} onSearch={onFilter}>
                        <AutocompleteTagCP label={'Filtre pelas áreas de interesse'} showOptionsOnLoad={true} formStateManager={formStateManager} fieldName={'tags'} allowClear={true}/>
                    </TableFilterICP>
                ),
                render: (text, opportunity) => <TagTruncateColumnItemCP tags={opportunity.tags?.map(tag => tag.name) ?? []}/>,
            },
            {
                title: 'Fonte',
                key: OpportunitySearchOrderingEnum.SOURCE,
                sorter: true,
                filterIcon: () => <Icon type={'filter'}/>,
                filterDropdown: (filterProps) => (
                    <TableFilterICP filterProps={filterProps} onSearch={onFilter}>
                        <AutoCompleteOpportunitySourceCP label={'Filtre pela origem'} formStateManager={formStateManager} fieldName={'sourceCode'} allowClear={true}/>
                    </TableFilterICP>
                ),
                render: (text, opportunity): string => opportunity.source.name,
            },
            {
                title: 'Responsável',
                key: OpportunitySearchOrderingEnum.RESPONSIBLE,
                sorter: true,
                filterIcon: () => <Icon type={'filter'}/>,
                filterDropdown: (filterProps) => (
                    <TableFilterICP filterProps={filterProps} onSearch={onFilter}>
                        <AutocompleteUserCP label={'Filtre pelo responsável'} formStateManager={formStateManager} fieldName={'responsibleCode'} allowClear={true}/>
                    </TableFilterICP>
                ),
                render: (text, opportunity) => StringUtils.getFirstName(opportunity.userResponsible.name),
            },
            {
                title: 'Criado em',
                key: OpportunitySearchOrderingEnum.CREATION_DATE,
                sorter: true,
                align: 'center',
                render: (text, opportunity) => (
                    <>
                        <div>{StringUtils.getFirstName(opportunity.userCreator?opportunity.userCreator.name:'')}</div>
                        <div>{DateUtils.getFormatted(opportunity.creationDate, DateFormatEnum.BR_WITHOUT_TIME)}</div>
                        <TextCP text={`às ${DateUtils.getFormatted(opportunity.creationDate, DateFormatEnum.TIME_H_M)}`} color={ThemeAnt.gray}/>
                    </>
                ),
            },
            {
                key: 'icos',
                align: 'center',
                render: (text, opportunity) => (
                    <ConditionalRenderCP shouldRender={!!opportunity.note}>
                        <TooltipCP text={opportunity.note} showSpan={true}>
                            <IconCP antIcon={'message'}/>
                        </TooltipCP>
                    </ConditionalRenderCP>
                ),
            },
            {
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (text, opportunity) => (
                    <OpportunityTableActionColumnICP
                        opportunity={opportunity}
                        onSelectOpportunity={onSelectOpportunity}
                        onOpportunityDataChanged={onOpportunityDataChanged}
                        funnelStepsData={funnelStepsData}
                    />
                )
            }
        ]
    },

    /**
     * Obtem o requestFilterDTO para o modelo de filtros.
     */
    getFilterDtoFromModel(filterValues: OpportunityFiltersFormModel, sortAndPage?: TableSortAndPageTP): IOpportunitySearchRequestDTO {

        return {
            searchType: filterValues.view,
            franchiseCodes: [AppStateUtils.getCurrentFranchise()!.code],
            name: filterValues.name,
            phone: filterValues.phone,
            email: filterValues.email,
            sourceCode: filterValues.sourceCode,
            responsibleCode: filterValues.responsibleCode,
            tags: filterValues.tags,
            funnelStepCode: filterValues.funnelStepCode,
            userCodeCreator: filterValues.userCodeCreator,
            userCodeEvaluationResponsible: filterValues.userCodeEvaluationResponsible,
            createDateRange: DtoUtils.convertToDateRangeDto(filterValues.createDateRange),
            page: sortAndPage?.pagination?.current,
            itemsPerPage: sortAndPage?.pagination?.pageSize,
            orderingCriteria: sortAndPage?.sort?.column,
            orderingAscending: sortAndPage?.sort?.order === OrderingEnum.ASC,
            attempt: filterValues.attempt,
        }
    }

}
