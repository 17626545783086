import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

interface IExpensePaymentFormICPProps {
    formStateManager: IFormStateManager<FinancialTransactionFormModel>
}

/**
 * Dados de formulario para marcacao de recorrencia
 */
export function FinancialTransactionPaymentFormICP(props: IExpensePaymentFormICPProps): JSX.Element {

    /**
     * Ao mudar se fatura esta paga.
     */
    function onChangeIsPaid(isPaidChecked: boolean): void {

        props.formStateManager.changeFieldValue('isPaid', isPaidChecked)

        // Se estiver escondendo nao faz nada
        if (!isPaidChecked) {
            props.formStateManager.changeFieldValue('paidValue', undefined)
            props.formStateManager.changeFieldValue('paymentDate', undefined)
            return
        }

        // Atualiza os campos com valores padroes
        if (!props.formStateManager.getFieldValue('paymentDate'))
            props.formStateManager.changeFieldValue('paymentDate', new Date())

        if (!props.formStateManager.getFieldValue('paidValue')) {

            let sum = 0
            props.formStateManager.getFieldValue('values').forEach((a: any) => {
                sum = sum + (+MoneyUtils.convertToFloat(a.value).toFixed(2))
            })
            props.formStateManager.changeFieldValue('paidValue', MaskUtils.applyNumberMask(Number(sum.toFixed(2)), 2))
        }
    }

    return (
        <CardCP
            title={
                <SwitchCP
                    textOutside={'Paga?'}
                    textInsideChecked={'Sim'}
                    onChange={onChangeIsPaid}
                    formStateManager={props.formStateManager}
                    fieldName={'isPaid'}
                    isTextInside={false}
                    marginLeft={20}
                />
            }
            paddingTitle={'0px'}
            margin={'vertical'}
            innerSpacing={props.formStateManager.getFieldValue('isPaid') ? undefined : 'none'}
        >
            <ConditionalRenderCP shouldRender={props.formStateManager.getFieldValue('isPaid')}>
                <RowCP>
                    <ColumnCP size={10}>
                        <InputCP
                            formStateManager={props.formStateManager}
                            fieldName={'paymentDate'}
                            label={'Data Pagamento'}
                            type={'date'}
                            required={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={8}>
                        <InputCP
                            type={'text'}
                            label={'Valor pago'}
                            mask={InputMaskTypeEnum.MONEY}
                            formStateManager={props.formStateManager}
                            fieldName={'paidValue'}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>
            </ConditionalRenderCP>
        </CardCP>
    )
}
