import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { DrawerOpportunityDetailsCP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/DrawerOpportunityDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import React, { useEffect, useState } from 'react'
import { DrawerCreateSaleCP } from 'main/modules/sale/components/drawer-create-sale/DrawerCreateSaleCP'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import FileSaver from 'file-saver'
import { DrawerFinancialTransactionCP } from 'main/modules/financial/components/drawer-financial-transaction/DrawerFinancialTransactionCP'
import { DrawerProductReleaseCP } from 'main/modules/products/components/drawer-product-release/DrawerProductReleaseCP'
import { ModalPayMultipleFinancialTransactionCP } from 'main/modules/financial/components/modal-pay-multiple-financial-transaction/ModalPayMultipleFinancialTransactionCP'
import { DrawerEvaluationDataCP } from 'main/modules/sales-funnel/components/drawer-evaluation-data/DrawerEvaluationDataCP'
import { DrawerTreatmentFormCP } from 'main/modules/admin/components/drawer-treatment-form/DrawerTreatmentFormCP'
import { ModalEvaluationConfirmCP } from 'main/modules/sales-funnel/components/modal-evaluation-confirm/ModalEvaluationConfirmCP'
import { FormPersonContactCP } from 'main/modules/people/components/form-person-contact/FormPersonContactCP'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { CardCP } from 'main/common/components/card/CardCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { ModalEditSaleChargesCP } from 'main/modules/sale/components/modal-edit-sale-charges/ModalEditSaleChargesCP'
import { CollapseSaleChargesCP } from 'main/modules/sale/components/collapse-sale-charges/CollapseSaleChargesCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { ModalPersonBulkOpportunityCP } from 'main/modules/people/components/modal-person-bulk-opportunity/ModalPersonBulkOpportunityCP'
import { ModalSendBullkMessageCP } from 'main/modules/people/components/modal-send-bulk-message/ModalSendBullkMessageCP'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { DrawerSaleQuoteDetailsCP } from 'main/modules/sale/components/drawer-sale-quote-detail/DrawerSaleQuoteDetailsCP'
import { ModalFinancialTransactionFilesCP } from 'main/modules/financial/components/modal-financial-transaction-files/ModalFinancialTransactionFilesCP'
import { DrawerBankAccountCP } from 'main/modules/financial/components/drawer-bank-account/DrawerBankAccountCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { FormSessionAnswerCP } from 'main/modules/scheduler/components/form-session-answer/FormSessionAnswerCP'
import { useLocation } from 'react-router-dom'
import { ScreenContentDevTestPublicLinks } from 'main/modules/app/screens/screen-dev/content/ScreenContentDevTestPublicLinks'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function DevTestScreen(): JSX.Element {

    const routeLocation = useLocation()
    const params = new URLSearchParams(routeLocation.search)

    const [isVisible] = useState<boolean>(false)
    const [charges, setCharges] = useState<IChargeListItemResponseDTO[]>([])

    const customRequest = useRequest<Blob>('none')
    useEffect(onCustomRequestChange, [customRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!isVisible)
            return

    }

    function onCustomRequestChange(): void {

        if (customRequest.isAwaiting || !customRequest.wasTried)
            return

        if (!customRequest.isSuccess || !customRequest.responseData) {
            console.error('FALHA - ActionsColumn.onDownloadRequestChange: ', customRequest.responseData, customRequest.error)
            return RequestUtils.showDefaultErrorNotification(customRequest.error, 'Falha ao tentar realizar download')

        }

        FileSaver.saveAs(customRequest.responseData, 'teste.zip')
    }

    function doNothing(): void {
        NotificationHelper.warning('Do Nothing')
    }

    if (!!params.get('publicLinks'))
        return <ScreenContentDevTestPublicLinks/>

    return (
        <>
            <ConditionalRenderCP shouldRender={false}>
                <CardCP>
                    <FormPersonContactCP onSave={doNothing} entity={PersonContactEntityEnum.OPPORTUNITY} entityCode={536}/>
                </CardCP>
            </ConditionalRenderCP>
            <ModalCP visible={false} width={'98%'} top={10}>
                <FormSessionAnswerCP sessionCode={140} onSaveAnswers={doNothing} initTime={new Date()}/>
            </ModalCP>

            <DrawerBankAccountCP visible={false} onClose={doNothing} type={BankAccountTypeEnum.PAYMENT_ACCOUNT} bankAccountCode={103}/>

            <DrawerPersonDataCP visible={false} onClose={doNothing} onSave={doNothing} segmentType={SegmentTypeEnum.CUSTOMER} personCode={110}/>
            <DrawerPersonDetailsCP visible={false} onClose={doNothing} onSave={doNothing} segmentType={SegmentTypeEnum.COLLABORATOR} personCode={4793}/>

            <DrawerCreateSaleCP
                visible={false}
                type={SaleTypeEnum.SALE}
                customer={{ code: 413, name: 'Cecilia' }}
                // opportunityCode={90830}
                closeDrawer={doNothing}
                onSave={doNothing}
            />
            <DrawerSaleDetailsCP visible={true} onClose={doNothing} saleCode={13965}/>
            <DrawerSaleQuoteDetailsCP visible={false} onClose={doNothing} onDoSale={doNothing} code={104}/>

            <DrawerOpportunityDetailsCP visible={false} onClose={doNothing} opportunityCode={82337}/>
            <DrawerEvaluationDataCP visible={false} onClose={doNothing} onSave={doNothing} evaluationScheduleCode={30347}/>
            <ModalEvaluationConfirmCP visible={false} onSave={doNothing} opportunityCode={90830} onCancel={doNothing}/>

            <DrawerFinancialTransactionCP visible={false} onClose={doNothing} type={FinancialTransactionTypeEnum.DEBIT}/>
            <DrawerFinancialTransactionCP visible={false} onClose={doNothing} type={FinancialTransactionTypeEnum.CREDIT} code={undefined}/>
            <ModalFinancialTransactionFilesCP visible={false} financialTransactionCode={6887} onClose={doNothing}/>

            <ModalPayMultipleFinancialTransactionCP visible={false} financialTransactionCodes={[123]} onClose={doNothing} onSave={doNothing}/>

            <DrawerProductReleaseCP visible={false} onClose={doNothing}/>

            <ModalPersonBulkOpportunityCP visible={false} queryExpression={undefined as any} onClose={doNothing}/>
            <ModalSendBullkMessageCP visible={false} selectedPeople={[100]} onClose={doNothing}/>

            <DrawerTreatmentFormCP visible={false} onClose={doNothing}/>

            <ModalEditSaleChargesCP visible={false} onCancel={doNothing} onSave={doNothing} saleDate={new Date()} saleCode={40160} charges={charges}/>
            <ConditionalRenderCP shouldRender={false}>
                <CollapseSaleChargesCP saleCode={40161} onLoadCharges={setCharges} onChangePayment={doNothing} shouldLoadCharges={true} hasSaleNfe={false}/>
            </ConditionalRenderCP>
        </>
    )
}
