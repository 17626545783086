import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { IBalancePerPeriodFilterRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IBalancePerPeriodFilterRequestDTO'
import { IBankAccountSaveRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IBankAccountSaveRequestDTO'
import { IBankAccountSearchRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IBankAccountSearchRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo FINANCEIRO - CONTAS BANCARIAS
 */
export class BankAccountRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/bank-accounts`

    static searchConfig = (dto: IBankAccountSearchRequestDTO): RequestConfigTP => ({
        url: BankAccountRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOneConfig = (code: number): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static balancePerPeriodConfig = (type: BankAccountTypeEnum, dto: IBalancePerPeriodFilterRequestDTO): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/balance-per-period/${type}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static balanceConfig = (dto?: IBankAccountSearchRequestDTO): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/balance`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saveConfig = (dto: IBankAccountSaveRequestDTO): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateConfig = (code: number, dto: IBankAccountSaveRequestDTO): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteConfig = (code: number): RequestConfigTP => ({
        url: `${BankAccountRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
