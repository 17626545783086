import { MarketingContentVarsConfigTP } from 'main/modules/marketing/types/MarketingContentVarsConfigTP'

export const OpportunityScheduleVariablesTP: MarketingContentVarsConfigTP[] = [
    {
        label: 'Nome',
        value: '#nome##'
    },
    {
        label: 'Unidade',
        value: '#unidade##'
    },
    {
        label: 'Telefone da Unidade',
        value: '#telefone_unidade##'
    },
    {
        label: 'Horário da Avaliação',
        value: '#data_hora_avaliacao##'
    },
]
