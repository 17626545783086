import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import React from 'react'
import styled from 'styled-components'

const OPTIONS: SelectOptionTP[] =
    [
        { key: 'key-5', label: '5m', value: '00:05' },
        { key: 'key-15', label: '15m', value: '00:15' },
        { key: 'key-30', label: '30m', value: '00:30' },
        { key: 'key-45', label: '45m', value: '00:45' },
        { key: 'key-60', label: '1h', value: '01:00' },
    ]

interface ISelectSlotDurationCPProps {
    onChange: (value: string) => void
    value?: string
    topPosition?: string
}

/**
 * Componente para select de Duration de Tempo.
 */
export function SelectSlotDurationCP(props: ISelectSlotDurationCPProps): JSX.Element {

    return (
        <SelectWrapperSCP topPosition={props.topPosition}>
            <SelectCP
                options={OPTIONS}
                onChange={props.onChange}
                value={props.value}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div<{ topPosition?: string }>`
  width: 70px;
  position: absolute;
  z-index: 9;
  top: ${props => props.topPosition};
  left: 25px;
`
