import React from 'react'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ScreenContentFranchisorNpsReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-nps-report/inner/ScreenContentFranchisorNpsReportCP'
import { ScreenContentFranchiseNpsReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-nps-report/inner/ScreenContentFranchiseNpsReportCP'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

/**
 * Mostra NPS da Unidade ou de TODAS unidades
 */
export function ScreenContentNpsReportCP(): JSX.Element {

    if (!AppStateUtils.getCurrentFranchise()!.integrationKeys.space4Leads) {
        return (
            <WelcomeContentCP
                message={'O seu plano não possui NPS ou não está configurado'}
                extra={<>Fale com o suporte ;)</>}
                icon={<IconICP iconName={'experiment'}/>}
            />
        )
    }

    return (
        <>
            {
                !!AppStateUtils.getCurrentFranchise()?.isFranchisor
                    ?
                    <ScreenContentFranchisorNpsReportCP/>
                    :
                    <ScreenContentFranchiseNpsReportCP/>
            }
        </>
    )
}
