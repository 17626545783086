import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import { FinancialTransactionValuesFormICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/forms/inner/FinancialTransactionValuesFormICP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { FinancialTransactionRecurrencyFormICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/forms/inner/FinancialTransactionRecurrencyFormICP'
import { FinancialTransactionGroupFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionGroupFormModel'
import { FinancialTransactionPaymentFormICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/forms/inner/FinancialTransactionPaymentFormICP'
import { FinancialTransactionAdditionalDataFormICP } from 'main/modules/financial/components/drawer-financial-transaction/inner/components/forms/inner/FinancialTransactionAdditionalDataFormICP'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'

interface IExpenseFormICPProps {
    type: FinancialTransactionTypeEnum
    loadedFinancialTransaction?: IFinancialTransactionResponseDTO
    formStateManager: IFormStateManager<FinancialTransactionFormModel>
    formRecurrenceGroupStateManager: IFormStateManager<FinancialTransactionGroupFormModel>
    canEditValues: boolean
}

/**
 * Dados do formulario de despesa
 */
export function FinancialTransactionFormICP(props: IExpenseFormICPProps): JSX.Element {

    return (
        <>
            <RowCP>
                <ColumnCP size={8}>
                    <InputCP
                        formStateManager={props.formStateManager}
                        fieldName={'expirationDate'}
                        label={'Vencimento'}
                        type={'date'}
                        required={true}
                    />
                </ColumnCP>
                <ColumnCP size={16}>
                    <AutocompletePersonCP
                        label={'Fornecedor / Cliente'}
                        required={true}
                        formStateManager={props.formStateManager}
                        fieldName={'suplierCode'}
                        initialOptions={props.loadedFinancialTransaction?.personSupplier ? [props.loadedFinancialTransaction.personSupplier] : undefined}
                        considerUser={true}
                        considerCustomer={true}
                        considerSupplier={true}
                        segmentTypeToAdd={SegmentTypeEnum.SUPPLIER}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={12}>
                    <SelectPaymentMethodCP
                        label={'Forma de pagamento'}
                        formStateManager={props.formStateManager}
                        fieldName={'paymentMethod'}
                        required={true}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <AutocompleteBankAccountCP
                        formStateManager={props.formStateManager}
                        label={'Conta Bancária'}
                        fieldName={'bankCode'}
                        required={true}
                        initialOptions={props.loadedFinancialTransaction?.bank ? [props.loadedFinancialTransaction.bank] : undefined}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        formStateManager={props.formStateManager}
                        fieldName={'description'}
                        label={'Descrição'}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

            <FinancialTransactionValuesFormICP formStateManager={props.formStateManager} type={props.type}/>
            <FinancialTransactionRecurrencyFormICP formStateManager={props.formRecurrenceGroupStateManager} canEditValues={props.canEditValues}/>
            <FinancialTransactionPaymentFormICP formStateManager={props.formStateManager}/>

            <FinancialTransactionAdditionalDataFormICP formStateManager={props.formStateManager} isEdit={!!props.loadedFinancialTransaction}/>
        </>
    )
}
