import { ReducersMapObject } from 'redux'
import { ReduxUtils } from 'main/common/redux/ReduxUtils'
import { DomainDataTP } from 'main/common/redux/types/DomainDataTP'
import { AuthActions } from 'main/modules/auth/AuthActions'
import { UserDataTP } from 'main/common/redux/types/UserDataTP'
import { IReduxState } from 'main/common/redux/interfaces/IReduxState'
import { IAuthUserFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserFranchiseResponseDTO'

/**
 * REDUX
 * Determina mapa de reducers para gestao de estado da aplicacao via redux.
 */
export const Reducers: ReducersMapObject<IReduxState> = {
    userData: ReduxUtils.getPayloadReplacingReducer<UserDataTP>(AuthActions.TYPE_UPDATE_USER),
    domainData: ReduxUtils.getPayloadReplacingReducer<DomainDataTP>(AuthActions.TYPE_UPDATE_DOMAIN),
    currentFranchise: ReduxUtils.getPayloadReplacingReducer<IAuthUserFranchiseResponseDTO>(AuthActions.TYPE_UPDATE_CURRENT_FRANCHISE),
}
