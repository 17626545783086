import React from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import {
    TransactionalTriggerMomentTypeEnum,
    TransactionalTriggerMomentTypeLabelEnum
} from 'main/modules/marketing/components/select-transactional-trigger-moment-type/inner/TransactionalTriggerMomentTypeEnum'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import styled from 'main/config/theme/styledWithTheme'

interface ICPProps<FModelTP extends FormModel = any> {
    fieldName: string
    formStateManager: IFormStateManager<FModelTP>
    extraLabel?: string
}

/**
 * Select para o tipo de trigger para disparo de uma automacao.
 */
export function SelectTransactionalTriggerMomentTypeCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element | null {

    const OPTIONS: SelectOptionTP[] = Object.values(TransactionalTriggerMomentTypeEnum)
        .filter((item) => item !== TransactionalTriggerMomentTypeEnum.AT_MOMENT)
        .map((item) => ({
            label: `${TransactionalTriggerMomentTypeLabelEnum[item]} ${props.extraLabel ?? ''}`,
            value: item
        }))

    return (
        <WrapperSCP>
            <SelectCP
                options={OPTIONS}
                formStateManager={props.formStateManager}
                fieldName={props.fieldName}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-top: 21px;    
`
