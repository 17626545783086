import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import React, { ReactText, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

export interface ISearchFilterDropdownCPProps {
    selectedKeys?: React.Key[] | string
    setSelectedKeys?: (selectedKeys: string[]) => void
    confirm?: () => void
    clearFilters?: (selectedKeys: string[]) => void
    onSearch: (selectedKeys?: ReactText[] | string) => void
    onReset?: () => void
    dataIndex?: string
    placeholder?: string
    mask?: InputMaskTypeEnum
}

/**
 * Dropdown com filtro de colunas de uma tabela
 */
export function SearchFilterDropdownICP(props: ISearchFilterDropdownCPProps): JSX.Element {

    const [searchString, setSearchString] = useState< React.Key[] | string>('')
    useEffect(setSearchText, [props.selectedKeys])

    function setSearchText(): void{
        if (!props.selectedKeys)
            return
        setSearchString(props.selectedKeys)
    }

    function onReset(): void {
        if (searchString.length <= 1)
            return
        props.onReset?.()
        setSearchString('')
    }

    return (
        <FilterDropdownWrapperSCP>
            <InputCP
                label={props.placeholder}
                value={searchString}
                onChange={props.setSelectedKeys}
                onFormSubmit={(): void => props.onSearch(props.selectedKeys)}
                type={'text'}
                mask={props.mask}
            />

            <FlexCP justifyContent={!!props.onReset ? 'space-between' : 'flex-end'}>
                {
                    !!props.onReset &&
                    <ButtonCP
                        onClick={onReset}
                        type={'ghost'}
                    >
                        Limpar
                    </ButtonCP>
                }
                <ButtonCP
                    type={'primary'}
                    onClick={() => props.onSearch(props.selectedKeys)}
                >
                    Filtrar
                </ButtonCP>
            </FlexCP>
        </FilterDropdownWrapperSCP>
    )
}

const FilterDropdownWrapperSCP = styled.div`
    padding:20px;
`