import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetOpportunityPerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetOpportunityPerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
    extraTitle?: string
}

/**
 * Widgets de leads cadastrados.
 */
export function WidgetCreatedLeadsCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={`Leads captados ${props.extraTitle ?? ''}`}
            help={'Total de leads cadastrados no período'}
            requestConfigGetter={
                DashboardRequests.getOpportunityPerformanceWidgets(
                    WidgetOpportunityPerformanceEnum.LEADS_REGISTERED,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
        />
    )
}
