import { ContextFactory } from 'main/common/context-factory/ContextFactory'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'

type ActionTypeTP =
    | 'setShowPersonDetailsDrawer'
    | 'setShowAddJobFunctionModal'
    | 'setIsLoading'
    | 'setMustUpdate'
    | 'setSegmentType'
    | 'setSegmentExpression'
    | 'setSelectedPersonCode'
    | 'showPermissions'

type StateTP = {

    showPersonDetailsDrawer: boolean,
    showAddJobFunctionModal: boolean,

    isLoading: boolean,
    mustUpdate: boolean,

    segmentExpression: SegmentExpressionTP,

    /*
        Contador de vezes que o evento de exibicao de permissoes de 01 usuario foi invocada:
        Utiliza-se 01 contador para permitir captura do evento de incremento. Esse evento leve ao disparo do callback
        externo referente a essa acao.
    */
    permissionsExhibitionRequestCount: number,

    segmentType?: SegmentTypeEnum,
    permissionsUserCode?: number,
    selectedPersonCode?: number,
}

const INITIAL_STATE: StateTP = {

    showPersonDetailsDrawer: false,
    showAddJobFunctionModal: false,

    isLoading: false,
    mustUpdate: false,

    segmentExpression: [],
    permissionsExhibitionRequestCount: 0,
}

const actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP> = actionHelper => ({

    setShowPersonDetailsDrawer: (showPersonDetailsDrawer: boolean): void => actionHelper.replaceWithPayload({ showPersonDetailsDrawer }),
    setShowAddJobFunctionModal: (showAddJobFunctionModal: boolean): void => actionHelper.replaceWithPayload({ showAddJobFunctionModal }),

    setIsLoading: (isLoading: boolean): void => actionHelper.replaceWithPayload({ isLoading }),
    setMustUpdate: (mustUpdate: boolean): void => actionHelper.replaceWithPayload({ mustUpdate }),

    setSegmentType: (segmentType: SegmentTypeEnum): void => actionHelper.replaceWithPayload({ segmentType }),
    setSegmentExpression: (segmentExpression: SegmentExpressionTP): void => actionHelper.replaceWithPayload({ segmentExpression }),
    setSelectedPersonCode: (selectedPersonCode?: number): void => actionHelper.replaceWithPayload({ selectedPersonCode }),

    showPermissions: (userCode: number): void => actionHelper.replaceWithPayload({
        permissionsUserCode: userCode,
        permissionsExhibitionRequestCount: (actionHelper.state.permissionsExhibitionRequestCount + 1)
    }),
})

/**
 * CONTEXTO: Tabela de listagem de pessoas.
 * Provider + Hook para acesso ao contexto de estado global da 01 expressão em edição.
 *
 * @see PersonTableCP
 */
export const PersonTableContext = ContextFactory.getOne<StateTP, ActionTypeTP>({
    contextName: 'Tabela de Pessoas',
    initialStateGetter: () => INITIAL_STATE,
    actionBuilder,
})
