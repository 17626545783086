import React from 'react'
import { Empty } from 'antd'
import styled from 'styled-components'

interface IEmptyCPProps {
    description?: string
}

/**
 * Componente de resultados vazios do sistema
 * @author Lucas Rosa
 */
export function EmptyCP(props: IEmptyCPProps): JSX.Element {
    return (
        <EmptyWrapperSCP>
            <Empty
                // image={Empty.PRESENTED_IMAGE_DEFAULT}
                description={props.description ? props.description : 'Nenhum dado encontrado'}
            />
        </EmptyWrapperSCP>

    )
}

const EmptyWrapperSCP = styled.div`
    min-height: 10em;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`