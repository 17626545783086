import React from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { Statistic } from 'antd'
import styled from 'main/config/theme/styledWithTheme'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'

const SELECTED_CLASS_NAME = 'selected'

interface IStatisticCPProps {
    value?: number
    label?: string
    icon?: JSX.Element
    valueUnit?: string
    color?: string
    loading?: boolean
    footer?: string | JSX.Element
    smallInternalSpacing?: boolean
    margin?: string
    onClick?: () => void
    selected?: boolean
    forceDecimal?: boolean
}

/**
 * COMPONENTE
 * Widgets para dashboards.
 */
export function StatisticCP(props: IStatisticCPProps): JSX.Element | null {

    let formattedValue: string | number = ''
    if (props.value)
        formattedValue = props.forceDecimal ? props.value.toFixed(2) : props.value

    return (
        <WrapperSCP
            className={props.selected ? SELECTED_CLASS_NAME : ''}
            isClickable={!!props.onClick}
        >
            <CardCP innerSpacing={'small'} margin={props.margin}>
                <StatisticSCP
                    onClick={props.onClick}
                >
                    <Statistic
                        title={props.label}
                        value={formattedValue}
                        valueStyle={{ color: props.color }}
                        prefix={
                            (props.loading === undefined || !props.loading)
                                ? props.icon
                                : <LoadingOverlayCP show={props.loading || false}/>
                        }
                        decimalSeparator={','}
                        groupSeparator={'.'}
                        suffix={props.valueUnit}
                    />
                </StatisticSCP>

                {
                    props.footer && !props.loading &&
                    <FooterSCP>
                        {props.footer}
                    </FooterSCP>
                }

            </CardCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isClickable: boolean }>`

    cursor: ${props => props.isClickable ? 'pointer' : undefined};

  &.${SELECTED_CLASS_NAME} .ant-card-body::before{
        content: ''; 
        width: 5px;
        height: 100%;
        border-radius: 8px 0 0 8px;
        top: 0;
        left: 0;
        position: absolute;
        background: ${props => props.theme.primaryColor};
    }

`

const StatisticSCP = styled.div`

    .ant-statistic-content-prefix {
        margin-right: 20px;
    }

    .ant-statistic-content-suffix {
        font-size: 12px;
    }
`

const FooterSCP = styled.div`
    margin: 5px 0 -5px 0;
    color: #adadad;
    font-size: 12px;
`
