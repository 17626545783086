import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { ISearchAvailableSlotsResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISearchAvailableSlotsResponseDTO'
import { StringUtils } from 'main/common/utils/StringUtils'
import { IFullCalendarEventInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventInfo'
import { ISearchAvailableSlotsRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISearchAvailableSlotsRequestDTO'
import { DrawerScheduleSessionCP } from 'main/modules/scheduler/components/drawer-schedule-session/DrawerScheduleSessionCP'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { CalendarClinicCP } from 'main/modules/scheduler/components/calendar-clinic/CalendarClinicCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormFilterSchedulerFormModel } from 'main/modules/scheduler/components/form-filter-scheduler/inner/FormFilterSchedulerFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

interface ISchedulerScreenContentCPProps {
    formStateManager: IFormStateManager<FormFilterSchedulerFormModel>
    shouldLoadSlots: number
}

/**
 * Conteúdo da tela do módulo de agenda
 */
export function SchedulerByAvailableSlotsCP(props: ISchedulerScreenContentCPProps): JSX.Element {

    const [isScheduleSessionDrawerVisible, setIsScheduleSessionDrawerVisible] = useState<boolean>(false)
    const [scheduleSessionInitialData, setScheduleSessionInitialData] = useState<IScheduleSlotInitialData>()
    const [fullcalendarEvents, setFullCalendarEvents] = useState<IFullCalendarEvent[]>()

    const searchAvailableSlotsRequest = useRequest<ISearchAvailableSlotsResponseDTO[]>()
    useEffect(onSearchAvailableSlotsRequestChange, [searchAvailableSlotsRequest.isAwaiting])

    useEffect(() => { init() }, [props.shouldLoadSlots])

    /**
     * Inicializa informacoes.
     */
    async function init(): Promise<void> {

        if (!props.shouldLoadSlots)
            return

        if (!await FormStateManagerUtils.validateRequiredFields(props.formStateManager))
            return

        const formValues = props.formStateManager.getFormValues()!
        const searchDto: ISearchAvailableSlotsRequestDTO = {
            beginDate: DateUtils.getFormatted(formValues.dateInterval.beginDate, DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(formValues.dateInterval.endDate, DateFormatEnum.US_WITHOUT_TIME),
            customerCode: formValues.customerCode,
            sessionCodes: formValues.sessions.map((session: ISessionResponseDTO) => session.code),
            userCode: formValues.userProfessionalCode
        }
        searchAvailableSlotsRequest.runRequest(SchedulerRequests.searchAvailableSlots(searchDto))
    }

    /**
     * Retorno ao buscar agendamentos.
     */
    function onSearchAvailableSlotsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchAvailableSlotsRequest, 'Erro ao buscar agendamentos.'))
            return

        if (!searchAvailableSlotsRequest.responseData)
            return setFullCalendarEvents([])

        // Monta lista com os eventos no formato do calendario
        const fullCalendarEvs: IFullCalendarEvent[] = []
        searchAvailableSlotsRequest.responseData.forEach((byProfessionSlot) => {
            byProfessionSlot.availableSlots.forEach((slot, idx) => {
                fullCalendarEvs.push({
                    id: `${byProfessionSlot.userProfessional.code}_${idx}`,
                    start: slot.beginDate,
                    end: slot.endDate,
                    title: `Horário Livre - ${StringUtils.getFirstAndSecondName(byProfessionSlot.userProfessional.name)}`,
                    extendedProps: {
                        user: byProfessionSlot.userProfessional
                    }
                })
            })
        })

        setFullCalendarEvents(fullCalendarEvs)
    }

    /**
     * O evento aqui é um horario disponvel para agendamento, trata os dados e chama callback com as informacoes.
     */
    function onEventClick(eventInfo: IFullCalendarEventInfo): void {

        setScheduleSessionInitialData({
            initialTime: moment(eventInfo.event.start),
            sessions: props.formStateManager.getFieldValue('sessions'),
            customer: new NameAndCodeResponseDTO(props.formStateManager.getFieldValue('customerCode'), props.formStateManager.getFieldValue('customerName')),
            userProfessional: {
                code: eventInfo.event.extendedProps.user.code,
                name: eventInfo.event.extendedProps.user.name,
            }
        })
        setIsScheduleSessionDrawerVisible(true)
    }

    /**
     * Quando for alterado a visao ou alterado o range de datas, informa o FM e recarrega.
     */
    function onChangeDateRange(dateRange: IDateRangeFilter): void {

        // O FullCalendar mando um dia a mais às 00:00. Tira um minuto para deixar no ultimo dia correto
        dateRange.endDate = DateUtils.add(dateRange.endDate!, -1, TimeBaseEnum.MINUTE)

        props.formStateManager.changeFieldValue('dateInterval', dateRange)
        init()
    }

    return (
        <>
            <CalendarClinicCP
                events={fullcalendarEvents ?? []}
                selectedDate={props.formStateManager.getFieldValue('dateInterval')?.beginDate}
                defaultView={'timeGridDay'}
                onEventClick={onEventClick}
                loading={searchAvailableSlotsRequest.isAwaiting}
                slotDuration={'00:15'}
                slotLabelInterval={'00:30'}
                onDateRangeChange={onChangeDateRange}
            />

            {
                scheduleSessionInitialData &&
                <DrawerScheduleSessionCP
                    visible={isScheduleSessionDrawerVisible}
                    scheduleSessionInitialData={scheduleSessionInitialData}
                    showBlockIntervalButton={false}
                    onClose={(dataChanged) => {
                        setIsScheduleSessionDrawerVisible(false)
                        if (dataChanged)
                            SystemUtils.sleep(1000).then(() => window.location.reload())
                    }}
                />
            }

        </>
    )
}
