import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import { ISaleListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleListItemResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { SaleStatusIconCP } from 'main/modules/sale/components/sale-status-icon/SaleStatusIconCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { TextSaleItemsProductReleaseNamesCP } from 'main/modules/sale/components/text-sale-items-product-release-names/TextSaleItemsProductReleaseNamesCP'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IconPaymentMethodCP } from 'main/modules/financial/components/icon-payment-method/IconPaymentMethodCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { TextCP } from 'main/common/components/text/TextCP'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { TableSortAndPageTP } from 'main/common/components/table/inner/TableSortAndPageTP'
import { SaleSearchRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSearchRequestDTO'

/**
 * Colunas da tabela com listas de vendas.
 */
export const SaleTableUtils = {

    /**
     * Obtem as colunas da tabela.
     */
    getColumns(
        onSelectSale: (saleCode: number) => void, 
        onSelectCliente: (customerCode: number) =>void
    ): Array<ColumnProps<ISaleListItemResponseDTO>> {

        return [
            {
                key: 'cr_status',
                align: 'center',
                render: (text, record) => <SaleStatusIconCP status={record.status} showTooltip={true}/>
            },
            {
                title: 'Data',
                key: 'saleDate',
                dataIndex: 'saleDate',
                render: (text, record) => (
                    <>
                        { DateUtils.getFormatted(record.date, DateFormatEnum.BR_WITHOUT_TIME) }
                        <TextCP text={`às ${DateUtils.getFormatted(record.date, DateFormatEnum.TIME_H_M)}`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                    </>
                )
            },
            {
                title: 'Cliente',
                key: 'customerName',
                dataIndex: 'customerName',
                render: (text, record) => (
                    <AgrupadorCelular>
                        <ButtonCP
                                    tooltip={'Clique para exibir os detalhes do cliente'}
                                    type={'primary'}
                                    icon={'info'}
                                    onClick={() => onSelectCliente(record.personCustomer.code)}
                                    size={'small'}
                        />
                        <div>
                         <CodigoVenda>Venda: {record.code}</CodigoVenda>   
                         {record.personCustomer.name}
                        </div>
                    </AgrupadorCelular>)
            },
            {
                title: 'Descrição',
                key: 'cr_saleitems',
                render: (text, item) => (
                    <>
                        <TextSaleItemsProductReleaseNamesCP
                            characterLimit={40}
                            saleItemsProductReleaseNames={item.releases.map(releaseNameAndCode => releaseNameAndCode.name)}
                        />
                        <TextCP text={`por ${StringUtils.getFirstAndSecondName(item.userSeller.name)}`} fontStyle={'italic'} color={ThemeAnt.gray}/>
                    </>
                )
            },
            {
                title: 'Valor',
                key: 'totalValue',
                dataIndex: 'totalValue',
                align: 'center',
                render: (text, record) => (
                    <>
                        {
                            MaskUtils.applyMoneyMask(record.totalValue)
                        }
                        <ProgressCP
                            percent={MathUtils.getPercentage((record.installmentData.paidCount + record.installmentData.defaultingCount), record.installmentData.installmentsCount, true)}
                            secondPercent={MathUtils.getPercentage(record.installmentData.paidCount, record.installmentData.installmentsCount, true)}
                            showInfo={false}
                            width={70}
                            descriptionText={`${record.installmentData.paidCount}/${record.installmentData.installmentsCount}`}
                            primaryColor={ThemeAnt.errorColor}
                            secondColor={ThemeAnt.successColor}
                            tooltip={
                                <>
                                    <TextCP text={`Total de parcelas: ${record.installmentData.installmentsCount} (${MaskUtils.applyMoneyMask(record.totalValue)})`}/>
                                    <TextCP text={`Parcelas quitadas: ${record.installmentData.paidCount} (${MaskUtils.applyMoneyMask(record.installmentData.paidValue)})`}/>
                                    <TextCP text={`Parcelas a vencer: ${record.installmentData.okCount} (${MaskUtils.applyMoneyMask(record.installmentData.okValue)})`}/>
                                    <TextCP text={`Parcelas atrasadas: ${record.installmentData.defaultingCount} (${MaskUtils.applyMoneyMask(record.installmentData.defaultingValue)})`}/>
                                </>
                            }
                        />
                    </>
                )
            },
            {
                key: 'source',
                dataIndex: 'source',
                title: 'Origem',
                render: (text, record) => record.source?.name
            },
            {
                key: 'pms',
                dataIndex: 'pms',
                render: (text, record) => (
                    <PaymentMethodsWrapperSCP>
                        { record.paymentMethods.map(pm => <IconPaymentMethodCP key={pm} paymentMethod={pm}/>) }
                    </PaymentMethodsWrapperSCP>
                )
            },
            {
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (text, record): JSX.Element => {
                    return (
                        <>
                            
                                
                            
                            <ButtonCP
                                tooltip={'Ver detalhes da venda'}
                                type={'primary'}
                                onClick={() => onSelectSale(record.code)}
                                icon={'eye'}
                                size={'small'}
                            />
                        </>
                    )
                }
            },
        ]
    },

    /**
     * Obtem o requestFilterDTO para o modelo de filtros.
     */
    getFilterDtoFromModel(
        sortAndPage?: TableSortAndPageTP,
        filterValues?: SalesFiltersFormModel
    ): SaleSearchRequestDTO {

        const filtersDto: SaleSearchRequestDTO = {
            page: sortAndPage?.pagination?.current,
            itemsPerPage: sortAndPage?.pagination?.pageSize,
            status: filterValues?.status,
            searchString: filterValues?.searchString,
            personCustomerCode: filterValues?.personCode,
            paymentMethods: filterValues?.paymentMethod ? [filterValues.paymentMethod] : undefined,
            userSellerCode: filterValues?.userSellerCode,
            shouldShowCancelled: filterValues?.showCancelled ?? false,
            considerPppAsSale: filterValues?.considerPppAsSale ?? true,
        }

        if (!!filterValues?.valueMin || filterValues?.valueMax) {
            filtersDto.saleValueRange = {
                valueMin: filterValues?.valueMin ? MoneyUtils.convertToFloat(filterValues?.valueMin) : undefined,
                valueMax: filterValues?.valueMax ? MoneyUtils.convertToFloat(filterValues?.valueMax) : undefined
            }
        }

        if (!!filterValues?.dateRange?.beginDate || filterValues?.dateRange?.endDate) {

            filtersDto.saleDateInterval = {
                beginDate: filterValues?.dateRange?.beginDate ? DateUtils.getFormatted(filterValues.dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
                endDate: filterValues?.dateRange?.endDate ? DateUtils.getFormatted(filterValues.dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            }
        }

        return filtersDto
    }

}

const PaymentMethodsWrapperSCP = styled.div`
  i {
    margin-right: 5px;
  }
`

const AgrupadorCelular = styled.div`
  display:flex;
  gap:2px;
  justify-content:left;
  align-itens:center;
`

const CodigoVenda = styled.div`
    font-weight: 613;
    letter-spacing: -0.091em;
    font-size: 11px;
    margin-bottom: -9px;
    color: rgb(43,105,140);
    position: relative;
    bottom: 3px;
`
