import _ from 'lodash'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ITagResponseDTO } from 'main/modules/people/services/person/dtos/response/ITagResponseDTO'
import { TagRequests } from 'main/modules/tags/services/TagRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ITagSelectCPProps {
    onChange: (value: number[]) => void
    multiple?: boolean
    selected?: number[]
    onlyOptions?: ITagResponseDTO[] | undefined
    isRequired?: boolean
}

/**
 * Componente select de TAGS
 */
export function SelectTagCP(props: ITagSelectCPProps): JSX.Element {

    const [tagOptions, setTagOptions] = useState<SelectOptionTP[]>()
    const [selectedTags, setSelectedTags] = useState<number[]>([])

    const tagsRequest = useRequest<IGenericListResponseDTO<ITagResponseDTO>>()

    useEffect(getTagList, [])
    useEffect(onTagsRequestChange, [tagsRequest.isAwaiting])
    useEffect(formatSelected, [tagOptions])

    function getTagList(): void {
        if (!tagsRequest.responseData)
            tagsRequest.runRequest(TagRequests.search())
    }

    function onTagsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(tagsRequest, 'Erro ao buscar tags'))
            return

        let options = tagsRequest.responseData!.list.map(j => ({ value: j.code, key: `${j.code}`, label: j.name }))

        if (props.onlyOptions && props.onlyOptions !== undefined)
            options = props.onlyOptions.map(j => ({ value: j.code, key: `${j.code}`, label: j.name }))

        setTagOptions(options)
    }

    function formatSelected(): void {
        if (!tagOptions || _.isEmpty(tagOptions))
            return

        if (props.selected)
            setSelectedTags(props.selected.map(opt => opt))
    }

    function onSelectTags(_selectedTags: number[]): void {
        props.onChange(_selectedTags)
    }

    return (
        <SelectWrapperSCP>
            <SelectCP
                disableSelectAll={true}
                placeholder={'Selecione as tags'}
                value={selectedTags}
                isMultiple={true}
                options={tagOptions ?? []}
                defaultOpen={false}
                onChange={onSelectTags}
                required={props.isRequired}
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div``
