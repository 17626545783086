import React from 'react'
import { IconICP } from 'main/common/components/icons/inner/IconICP'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'

/**
 * ICONE: download
 */
export const DownloadIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'download'} {...props}/>
}
