import React from 'react'
import { TextCP } from '../text/TextCP'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'

interface IResumeTextItemCPProps {
    itemTitle: string
    itemText: string
}

/**
 * Componente para exibir texto.
 *
 */
export function ResumeTextItemCP(props: IResumeTextItemCPProps): JSX.Element {
    return (
        <RowSCP>
            <TextSCP
                style={{ fontWeight: 'bold' }}
                text={`${props.itemTitle} `}
            />
            <TextSCP
                text={`\u00A0${props.itemText} `}
            />
        </RowSCP>
    )
}
const TextSCP = styled(TextCP)`
    background: ${(props: StyledComponentProps) => props.theme.secondaryColor};
`

const RowSCP = styled.div`
    display: flex;
`
