import { CardCP } from 'main/common/components/card/CardCP'
import { MktAccountTableCP } from 'main/modules/marketing/components/mkt-account-table/MktAccountTableCP'
import { MktPlanTableCP } from 'main/modules/marketing/components/mkt-plan-table/MktPlanTableCP'
import React from 'react'

/**
 * COMPONENTE com as contas de marketing
 */
export function ScreenContentMktAccountCP(): JSX.Element {

    return (
        <>
            <CardCP title={'Plano ativo'} dataTour={'mkt-plan'}>
                <MktPlanTableCP/>
            </CardCP>

            <CardCP title={'Histórico'} dataTour={'mkt-history'}>
                <MktAccountTableCP/>
            </CardCP>
        </>
    )
}
