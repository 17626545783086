import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import React, { useEffect, useState } from 'react'
import { CreateChargeInstallmentsTableUtils } from 'main/modules/sale/components/table-create-charge-installments/inner/CreateChargeInstallmentsTableUtils'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { ICreateChargeInstallment } from 'main/modules/sale/components/table-create-charge-installments/inner/ICreateChargeInstallment'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface ISaleItemTableCPProps {
    isEditingCharge: boolean
    chargeInstallments: ICreateChargeInstallment[]
    paymentMethod: PaymentMethodEnum
    onChangeData?: (parcelNumberSelected: number, newDate?: Date, newPaymentMethod?: PaymentMethodEnum, newValue?: string, installmentCode?: number) => void
}

/**
 * Tabela das parcelas de uma cobranca de uma nova venda.
 */
export function TableCreateChargeInstallments(props: ISaleItemTableCPProps): JSX.Element {

    const [isExpirationColumnDisabled, setIsExpirationColumnDisabled] = useState<boolean>()
    const [isValueColumnDisabled, setIsValueColumnDisabled] = useState<boolean>()

    useEffect(init, [props.paymentMethod])

    /**
     * Inicializa dados.
     */
    function init(): void {

        switch (props.paymentMethod) {
//aqui é o lugar
            case PaymentMethodEnum.CREDIT_CARD:
                if(AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.editarParcelasDeCartao){
                    console.log("Pode editar :)")
                    setIsExpirationColumnDisabled(false)
                    setIsValueColumnDisabled(true)
                    break
                }
            case PaymentMethodEnum.CREDIT_CARD_RECURRENT:
            case PaymentMethodEnum.PIX_RECURRENT:
            case PaymentMethodEnum.BANK_BILLET_RECURRENT:
                setIsExpirationColumnDisabled(!props.isEditingCharge)
                setIsValueColumnDisabled(true)
                break

            case PaymentMethodEnum.DEBIT:
            case PaymentMethodEnum.PIX:
            case PaymentMethodEnum.DEPOSIT:
            case PaymentMethodEnum.MONEY:
            case PaymentMethodEnum.BANK_BILLET:
            case PaymentMethodEnum.PAYMENT_BOOK:
            case PaymentMethodEnum.CHECK:
                setIsExpirationColumnDisabled(false)
                setIsValueColumnDisabled(props.isEditingCharge)
                break

            default:
                break
        }

    }

    if (isValueColumnDisabled === undefined || isExpirationColumnDisabled === undefined)
        return <></>

    return (
        <TableCPOLD2
            bordered={true}
            data={props.chargeInstallments}
            columns={CreateChargeInstallmentsTableUtils.getTableColumns(
                props.chargeInstallments.length,
                isExpirationColumnDisabled,
                isValueColumnDisabled,
                props.onChangeData
            )}
        />
    )
}
