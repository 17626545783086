import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsOptional } from 'class-validator'

export class FinancialTransactionValueFormModel extends FormModel {

    @IsOptional()
    code: number

    @IsNotEmpty()
    categoryCode: number

    @IsOptional()
    description?: string

    @IsNotEmpty()
    value: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
