import { LoadingCP } from 'main/common/components/loading/LoadingCP'
import { HttpStatusEnum } from 'main/common/enums/HttpStatusEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestHelper as RequestHelperOLD } from 'main/common/request-manager/RequestHelper'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PublicRouter } from 'main/modules/app/routes/PublicRouter'
import { AuthActions } from 'main/modules/auth/AuthActions'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

/**
 * TODO: ADD Descricao
 */
export function AppInitializerCP(): JSX.Element {

    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [isValidated, setIsValidated] = useState<boolean>(false)

    const routeHistory = useHistory()
    const location = useLocation()

    useEffect(init, [])
    useEffect(() => { validateRoute() }, [location.pathname, isInitialized])

    function init(): void {

        NeritFrameworkRoutingHelper.init(routeHistory)
        RoutingHelper.init(routeHistory, PublicRouter.PUBLIC_ROUTES_CONFIG)

        // Add tratamento para expiracao de token de login
        RequestHelperOLD.addErrorCustomAction({
            action: AuthActions.onUnauthorizedRequestResponse,
            httpStatus: HttpStatusEnum.UNAUTHORIZED
        })

        // Add tratamento para schema invalido
        RequestHelperOLD.addErrorCustomAction({
            action: () => RoutingHelper.handleInvalidSchema(),
            httpStatus: HttpStatusEnum.FORBIDDEN
        })

        // Reforca definicao do header padrao de identificacao do dominio
        const domainSchema = AppStateUtils.getDomainSlug()
        if (domainSchema) {
            RequestHelperOLD.addDefaultHeader('schema', domainSchema)
            RequestHelper.addDefaultHeader('schema', domainSchema)
        }

        setIsInitialized(true)
    }

    async function validateRoute(): Promise<void> {
        if (isInitialized) {
            await RoutingHelper.validateRoute(location.pathname)
            setIsValidated(true)
        }
    }

    return isValidated ? <PublicRouter/> : <LoadingCP/>
}
