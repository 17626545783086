import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISearchUsersRequestDTO } from 'main/modules/user/services/dtos/request/ISearchUsersRequestDTO'
import { ISearchUserByExpressionRequestDTO } from 'main/modules/user/services/dtos/request/ISearchUserByExpressionRequestDTO'
import { IUserChangePasswordRequestDTO } from 'main/modules/user/services/dtos/request/IUserChangePasswordRequestDTO'
import { IUserChangePermissionsRequestDTO } from 'main/modules/user/services/dtos/request/IUserChangePermissionsRequestDTO'
import { IUserTreatmentGroupsSaveRequestDTO } from 'main/modules/user/services/dtos/request/IUserTreatmentGroupsSaveRequestDTO'
import { IWorkSchedulesSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkSchedulesSaveRequestDTO'

/**
 * REQUISICOES relacionadas ao modulo de Usuario
 */
export class UserRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/users`

    static searchUsers= (dto: ISearchUsersRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getUserConfig = (userCode: number): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}`,
        method: HttpMethodEnum.GET,
    })

    static changePasswordConfig = (userCode: number, dto: IUserChangePasswordRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/change-password`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static changePermissionConfig = (userCode: number, dto: IUserChangePermissionsRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/change-permissions`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getTreatmentGroupsConfig = (userCode: number,): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/treatment-groups`,
        method: HttpMethodEnum.GET,
    })

    static updateTreatmentGroupsConfig = (userCode: number, dto: IUserTreatmentGroupsSaveRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/update-treatment-groups`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static searchUserByExpressionConfig = (dto: ISearchUserByExpressionRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/search-by-expression`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static getWorkSchedules = (userCode: number): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/work-schedule`,
        method: HttpMethodEnum.GET,
    })

    static updateWorkScheduleConfig = (userCode: number, dto: IWorkSchedulesSaveRequestDTO): RequestConfigTP => ({
        url: `${UserRequests._MODULE_BASE}/${userCode}/work-schedule`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

}
