import { faBan, faEquals } from '@fortawesome/free-solid-svg-icons'
import { ResumeWidgetCardCP } from 'main/common/components/resume-widget-card/ResumeWidgetCardCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React, { useEffect, useState } from 'react'
import { SaleStatusIconCP } from 'main/modules/sale/components/sale-status-icon/SaleStatusIconCP'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'
import { ISaleSummaryResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleSummaryResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SalesFunnelSalesViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelSalesViewEnum'
import { SaleSummaryRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSummaryRequestDTO'

interface ICPProps {
    view: SalesFunnelSalesViewEnum
    onChangeStatus: () => void
    formStateManagerFilters: IFormStateManager<SalesFiltersFormModel>
    shouldLoadData: boolean
    onLoadData: () => void
}

/**
 */
export function SalesSummaryHeaderCP(props: ICPProps): JSX.Element {

    const [summaryData, setSummaryData] = useState<ISaleSummaryResponseDTO>()

    const summaryRequest = useRequest<ISaleSummaryResponseDTO>()
    useEffect(onSummaryRequestChange, [summaryRequest.isAwaiting])

    useEffect(init, [props.shouldLoadData])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldLoadData)
            return

        const filterValues = props.formStateManagerFilters.getFormValues()
        const dto: SaleSummaryRequestDTO = {
            searchString: filterValues?.searchString,
            personCustomerCode: filterValues?.personCode,
            paymentMethods: filterValues?.paymentMethod ? [filterValues.paymentMethod] : undefined,
            userSellerCode: filterValues?.userSellerCode,
            considerPppAsSale: props.view === SalesFunnelSalesViewEnum.SALES_ALL
        }

        if (!!filterValues?.valueMin || filterValues?.valueMax) {
            dto.saleValueRange = {
                valueMin: filterValues?.valueMin,
                valueMax: filterValues?.valueMax
            }
        }

        if (!!filterValues?.dateRange?.beginDate || filterValues?.dateRange?.endDate) {
            dto.saleDateInterval = {
                beginDate: filterValues?.dateRange?.beginDate ? DateUtils.getFormatted(filterValues.dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
                endDate: filterValues?.dateRange?.endDate ? DateUtils.getFormatted(filterValues.dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            }
        }

        summaryRequest.runRequest(SaleRequests.summaryConfig(dto))
    }

    /**
     * Retorno dos dados da API
     */
    function onSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(summaryRequest, 'Ocorreu algun erro ao obter indicadores de venda'))
            return

        setSummaryData(summaryRequest.responseData)
        props.onLoadData()
    }

    /**
     * Ao clicar em um status.
     */
    function onSelectStatus(status?: SaleStatusEnum): void {
        props.formStateManagerFilters.changeFieldValue('status', status)
        props.onChangeStatus()
    }

    return (
        <>
            <WrapperSCP>
                {
                    props.view === SalesFunnelSalesViewEnum.SALES_ALL &&
                    <>
                        <CardWrapperSCP>
                            <ResumeWidgetCardCP
                                loading={summaryRequest.isAwaiting}
                                icon={<SaleStatusIconCP status={SaleStatusEnum.CONCLUDED}/>}
                                onClick={() => onSelectStatus(SaleStatusEnum.CONCLUDED)}
                                value={MaskUtils.applyMoneyMask(summaryData?.concludedValue ?? 0)}
                                extra={`${summaryData?.concludedCount ?? 0} pagas`}
                                selected={props.formStateManagerFilters.getFieldValue('status') === SaleStatusEnum.CONCLUDED}
                            />
                        </CardWrapperSCP>

                        <CardWrapperSCP>
                            <ResumeWidgetCardCP
                                loading={summaryRequest.isAwaiting}
                                icon={<SaleStatusIconCP status={SaleStatusEnum.OK}/>}
                                onClick={() => onSelectStatus(SaleStatusEnum.OK)}
                                value={MaskUtils.applyMoneyMask(summaryData?.okValue ?? 0)}
                                extra={`${summaryData?.okCount ?? 0} em dia`}
                                selected={props.formStateManagerFilters.getFieldValue('status') === SaleStatusEnum.OK}
                            />
                        </CardWrapperSCP>
                    </>
                }

                {
                    [SalesFunnelSalesViewEnum.SALES_ALL, SalesFunnelSalesViewEnum.SALES_MONTH, SalesFunnelSalesViewEnum.SALES_TODAY].includes(props.view)  &&
                    <CardWrapperSCP>
                        <ResumeWidgetCardCP
                            loading={summaryRequest.isAwaiting}
                            icon={<SaleStatusIconCP status={SaleStatusEnum.PENDING}/>}
                            onClick={() => onSelectStatus(SaleStatusEnum.PENDING)}
                            value={MaskUtils.applyMoneyMask(summaryData?.pendingValue ?? 0)}
                            extra={`${summaryData?.pendingCount ?? 0} ppp`}
                            selected={props.formStateManagerFilters.getFieldValue('status') === SaleStatusEnum.PENDING}
                            help={{ type: 'tooltip', text: 'Primeiro Pagamento Pendente: vendas que não tiveram o pagamento (cobrança no meio de pagamento com sucesso) da primeira parcela.' }}
                        />
                    </CardWrapperSCP>
                }

                {
                    [SalesFunnelSalesViewEnum.SALES_ALL, SalesFunnelSalesViewEnum.SALES_DELAYED].includes(props.view)  &&
                    <CardWrapperSCP>
                        <ResumeWidgetCardCP
                            loading={summaryRequest.isAwaiting}
                            icon={<SaleStatusIconCP status={SaleStatusEnum.DEFAULTING}/>}
                            onClick={() => onSelectStatus(SaleStatusEnum.DEFAULTING)}
                            value={MaskUtils.applyMoneyMask(summaryData?.defaultingValue ?? 0)}
                            extra={`${summaryData?.defaultingCount ?? 0} em atraso`}
                            selected={props.formStateManagerFilters.getFieldValue('status') === SaleStatusEnum.DEFAULTING}
                        />
                    </CardWrapperSCP>
                }

                {
                    [SalesFunnelSalesViewEnum.SALES_ALL, SalesFunnelSalesViewEnum.SALES_MONTH, SalesFunnelSalesViewEnum.SALES_TODAY].includes(props.view) &&
                    <CardWrapperSCP>
                        <ResumeWidgetCardCP
                            loading={summaryRequest.isAwaiting}
                            icon={
                                props.view === SalesFunnelSalesViewEnum.SALES_ALL
                                    ? <FontAwsomeIconCP type={faEquals} color={ThemeAnt.primaryColor}/>
                                    : <SaleStatusIconCP status={SaleStatusEnum.CONCLUDED}/>
                            }
                            onClick={() => onSelectStatus()}
                            value={MaskUtils.applyMoneyMask(summaryData?.salesValue ?? 0)}
                            extra={`${summaryData?.salesCount ?? 0} vendas`}
                            selected={!props.formStateManagerFilters.getFieldValue('status')}
                            help={{
                                type: 'tooltip',
                                text: props.view === SalesFunnelSalesViewEnum.SALES_ALL
                                    ? 'Total de vendas do período, considerando todos os status'
                                    : 'Total de vendas que já realizaram o primeiro pagamento (ou foram cobrados no meio de pagamento com sucesso)'
                            }}
                        />
                    </CardWrapperSCP>
                }
            </WrapperSCP>

            <ConditionalRenderCP shouldRender={!!summaryData && summaryData.cancelledCount > 0}>
                <FlexCP
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginTop={props.formStateManagerFilters.getFieldValue('showCancelled') ? 10 : 0}
                    marginLeft={15}
                    marginRight={20}
                >
                    <CancelledWrapperSCP>
                        <ConditionalRenderCP shouldRender={props.formStateManagerFilters.getFieldValue('showCancelled')}>
                            <CardWrapperSCP>
                                <ResumeWidgetCardCP
                                    loading={summaryRequest.isAwaiting}
                                    icon={<FontAwsomeIconCP type={faBan} color={ThemeAnt.errorColor}/>}
                                    onClick={() => onSelectStatus(SaleStatusEnum.CANCELLED)}
                                    value={MaskUtils.applyMoneyMask(summaryData?.cancelledValue ?? 0)}
                                    extra={`${summaryData?.cancelledCount ?? 0} canceladas`}
                                    selected={props.formStateManagerFilters.getFieldValue('status') === SaleStatusEnum.CANCELLED}
                                />
                            </CardWrapperSCP>
                        </ConditionalRenderCP>
                    </CancelledWrapperSCP>
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Existem vendas canceladas no período, deseja exibir?'}
                        textOutsidePosition={'left'}
                        formStateManager={props.formStateManagerFilters}
                        onChange={() => props.onChangeStatus()}
                        fieldName={'showCancelled'}
                    />
                </FlexCP>
            </ConditionalRenderCP>
        </>
    )
}

const WrapperSCP = styled.div`
  display:flex;
  width:100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px;
`

const CardWrapperSCP = styled.div`
    flex: 1;
`

const CancelledWrapperSCP = styled.div`
`
