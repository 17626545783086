import { MimeTypeUtils } from 'main/common/utils/MimeTypeUtils'
import { MimeTypeEnum } from 'main/common/enums/MimeTypeEnum'

/**
 * UTILITARIOS para manipular arquivos.
 *
 * @author hjcostabr
 */
export const FileUtils = {

    /** Avalia metadados disponiveis para determinar se tipo de 01 arquivo eh CSV. */
    isCsv(file: Blob, filename?: string): boolean {

        const fileType = file?.type
        if (!!fileType && MimeTypeUtils.isCsvMimeType(fileType))
            return true

        if (!!filename)
            return (fileType === MimeTypeEnum.TEXT && /(.*)\.csv$/.test(filename))

        return false
    },

    async toBase64(file: File | Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = error => reject(error)
        })
    },

    /**
     * Obtem o Arquivo de uma url
     */
    async getFile(fileUrl: string, fileName: string): Promise<File> {

        const _file = fetch(fileUrl)
            .then(async (e) => {
                return e.blob()
            })
            .then((blob) => {
                return new File([blob], fileName)
            })

        return _file
    }

}
