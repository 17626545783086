import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import 'moment/locale/pt-br'
import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ToogleCheckinRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/ToogleCheckinRequestDTO'
import { SessionsRequests } from 'submodules/neritclin-sdk/services/sessions/SessionsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { ToogleCheckinTP } from 'submodules/neritclin-sdk/services/sessions/dtos/ToogleCheckinTP'

interface ICPProps {
    sessionCodes: number[]
    sessionStatus: SessionStatusEnum
    onDone: () => void
}

/**
 * Botao para fazer checkin
 */
export function ButtonToogleCheckinCP(props: ICPProps): JSX.Element {

    const toogleCheckinRequest = useRequest<void>('none')
    useEffect(onToogleCheckinRequestChange, [toogleCheckinRequest.isAwaiting])

    /**
     * Marca como nao compareceu.
     */
    function toogleCheckin(action: ToogleCheckinTP): void {

        const dto: ToogleCheckinRequestDTO = {
            sessionCodes: props.sessionCodes,
            action
        }
        toogleCheckinRequest.runRequest(SessionsRequests.toogleCheckin(dto))
    }

    /**
     * Retorno ao marcar como nao compareceu.
     */
    function onToogleCheckinRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(toogleCheckinRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onDone()
    }

    return (
        <AccessControlCP module={ModuleEnum.MODULE_LISOLASER} redirect={false}>
            {
                props.sessionStatus === SessionStatusEnum.SCHEDULED &&
                <ButtonCP
                    type={'primary'}
                    onClick={() => toogleCheckin('doCheckin')}
                    loading={toogleCheckinRequest.isAwaiting}
                >
                    Fazer check-in
                </ButtonCP>
            }
            {
                props.sessionStatus === SessionStatusEnum.CHECK_IN_DONE &&
                <ButtonCP
                    type={'danger'}
                    onClick={() => toogleCheckin('undoCheckin')}
                    loading={toogleCheckinRequest.isAwaiting}
                >
                    Desfazer check-in
                </ButtonCP>
            }
        </AccessControlCP>
    )
}
