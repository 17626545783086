import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ITagResponseDTO } from 'main/modules/people/services/person/dtos/response/ITagResponseDTO'
import { PersonFormModel } from 'main/modules/people/components/drawer-person-data/inner/PersonFormModel'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { AddressUtils } from 'main/modules/people/utils/AddressUtils'
import { IPersonSaveRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSaveRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'

/**
 * UTILITÁRIOS
 * Reune metodos uteis para manipular formulario de * cadastro de pessoa.
 */
export const PersonFormUtils = {

    /** Tratamento generico para validacao de formularios de cadsatro de pessoa. */
    async validateForm<FModelTP extends PersonFormModel>(formStateManager: IFormStateManager<FModelTP>, segmentType: SegmentTypeEnum): Promise<boolean> {

        const isValid = await formStateManager.validate()
        if (!isValid) {
            NotificationHelper.error('Ops!', 'Preencha todos os dados corretamente')
            return false
        }

        // Validacao especifica para cadastro de memmbro da equipe
        const formValues = formStateManager.getFormValues()
        if (!formValues) {
            console.error('FALHA - PersonUtils.validateForm: ', 'Erro no formulário')
            return false
        }

        if (!formValues.phone && !formValues.email && !formValues.cpfCnpj) {
            NotificationHelper.error('Ops!', 'Informe AO MENOS: e-mail / telefone celular / cpf.')
            return false
        }

        if (segmentType === SegmentTypeEnum.COLLABORATOR && !(formValues as PersonFormModel)?.email) {
            NotificationHelper.error('Ops!', 'Informe um e-mail válido para prosseguir')
            return false
        }

        return true
    },

    /**
     * Gera & retorna valores para formulário de cadastro de pessoa
     * a partir de 01 dto de dados de pessoa recebido da api.
     */
    getFormDataFromResponseDto(dto: IPersonResponseDTO): ObjectPropsTP<PersonFormModel> {

        return {
            name: dto.name,
            gender: dto.gender,
            rg: dto.rg,
            birthDate: !!dto.birthDate ? DateUtilsOLD.getFormatted(dto.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
            cpfCnpj: dto.cpfCnpj,
            email: dto.email,
            phone: dto.phone,
            fantasyName: dto.fantasyName,
            socialName: dto.socialName,
            zipCode: dto.address?.zipCode,
            street: dto.address?.street,
            number: dto.address?.number,
            complement: dto.address?.complement,
            neighborhood: dto.address?.neighborhood,
            city: dto.address?.city,
            state: dto.address?.state,
            tags: (dto.tags ?? []).map((tag: ITagResponseDTO) => tag.code),
        }
    },

    /**
     * Gera & retorna valores para o DTO pelo formulário de cadastro de pessoa
     */
    getRequestDtoFromFormData(formModel: PersonFormModel, isUser?: boolean): IPersonSaveRequestDTO {

        return {
            isUser: !!isUser,
            name: formModel.name,
            rg: formModel.rg,
            birthDate: formModel.birthDate ? DateUtils.transformDateStrFormat(formModel.birthDate, DateFormatEnum.BR_WITHOUT_TIME, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            gender: formModel.gender,
            cpfCnpj: formModel.cpfCnpj ? MaskUtils.removeMask(formModel.cpfCnpj) : undefined,
            email: formModel.email,
            phone: !!formModel.phone ? MaskUtils.removeMask(formModel.phone) : undefined,
            address: AddressUtils.getAddressDtoFromFormData(formModel),
            fantasyName: formModel.fantasyName,
            socialName: formModel.socialName,
            types: formModel.types,
            note: formModel.notes,
        }
    },
}
