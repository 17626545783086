import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import React from 'react'
import styled from 'styled-components'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons/faCalendarWeek'
import { OpportunityEvaluationUtils } from 'main/modules/sales-funnel/utils/OpportunityEvaluationUtils'

interface ITextOpportunityEvaluationScheduleDescriptionCPProps {
    evaluationSchedule: IOpportunityEvaluationScheduleResponseDTO
    showIcon?: boolean
    showDate?: boolean
}

/**
 * Componente contendo descricao de um agendamento de avaliacao
 */
export function TextOpportunityEvaluationScheduleDescriptionCP(props: ITextOpportunityEvaluationScheduleDescriptionCPProps): JSX.Element {

    const color = OpportunityEvaluationUtils.getScreeningStatusColor(props.evaluationSchedule)
    const title = ' Avaliação '
    const dateTime = props.showDate ? ` (${DateUtils.getPresentableDateHour(props.evaluationSchedule.beginDate)})` : ''

    return (
        <ActivityWrapperSCP color={color}>
            {
                props.showIcon &&
                <FontAwsomeIconCP type={faCalendarWeek}/>
            }
            { title }
            { dateTime }
        </ActivityWrapperSCP>
    )
}

const ActivityWrapperSCP = styled.span<{ color: string }>`
    font-weight: 400;
    word-wrap: break-word;
    width: 100%;
`
