import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'
import { MenuCP } from 'submodules/nerit-framework-ui/common/components/menu/menu/MenuCP'
import { useLocation } from 'react-router-dom'
import { MenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item/MenuItemCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { SubMenuCP } from 'submodules/nerit-framework-ui/common/components/menu/sub-menu/SubMenuCP'

interface ICPProps {
    menuTop: {
        topContent: JSX.Element
        options: MenuItemTP[]
    }
    menuBottom: {
        bottomContent: JSX.Element
        options: MenuItemTP[]
    }
    onSelectMenuItem?: () => void
}

/**
 * Sider do sistema para usuários da clínica
 */
export function MenuUserMainCP (props: ICPProps): JSX.Element {

    const routeLocation = useLocation()

    /**
     * Renderiza os itens do menu.
     */
    function renderMenuItems(options: MenuItemTP[]): JSX.Element[] {
        return options
            .filter(option => option.enabled === undefined || option.enabled)
            .map(option => {

                if (!option.menuItemsChildren) {

                    return (
                        <MenuItemCP
                            key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
                            tooltipTitle={option.renderType === 'text' ? undefined : option.title}
                            isActive={routeLocation.pathname.startsWith(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(option.route))}
                            onClick={() => {
                                NeritFrameworkRoutingHelper.historyPush(option.route)
                                if (!!props.onSelectMenuItem)
                                    props.onSelectMenuItem()
                            }}
                            marginVertical={option.renderType === 'text' ? 0 : 2}
                            align={option.renderType === 'text' ? 'left' : 'center'}
                            disabled={option.disabled}
                        >
                            {
                                option.renderType === 'text'
                                    ?
                                    <>
                                        {
                                            !!option.icon
                                                ?
                                                <FlexCP justifyContent={'space-between'}>
                                                    <>{option.icon}</>
                                                    <TextCP text={option.title} color={ThemeProject.secondaryMenuItemColor} marginLeft={10}/>
                                                </FlexCP>
                                                :
                                                <TextCP text={option.title} color={ThemeProject.secondaryMenuItemColor}/>
                                        }
                                    </>
                                    :
                                    option.icon
                            }
                        </MenuItemCP>
                    )
                }

                return (
                    <SubMenuCP
                        key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
                        title={option.icon}
                        onTitleClick={() => {
                            NeritFrameworkRoutingHelper.historyPush(option.route)
                            if (!!props.onSelectMenuItem)
                                props.onSelectMenuItem()
                        }}
                        hideArrow={true}
                        padding={'0 20px'}
                        isActive={routeLocation.pathname.startsWith(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(option.route))}
                    >
                        <SubmenuTitleSCP>{option.title}</SubmenuTitleSCP>
                        { renderMenuItems(option.menuItemsChildren) }
                    </SubMenuCP>
                )
            })
    }

    return (
        <MenuContainerSCP>
            <MenuCP
                mode={'vertical'}
                selectable={false}
                inlineCollapsed={false}
            >
                { props.menuTop.topContent }
                { renderMenuItems(props.menuTop.options) }
            </MenuCP>

            <MenuCP
                mode={'vertical'}
                selectable={false}
                inlineCollapsed={false}
            >
                { renderMenuItems(props.menuBottom.options) }
                { props.menuBottom.bottomContent }
            </MenuCP>
        </MenuContainerSCP>
    )
}

const MenuContainerSCP = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;

  li.ant-menu-item {
    padding: 0 !important;
  }

  .ant-menu-submenu-title {
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      opacity: 0.5;
    }
  }

  .anticon {
    margin: 0 !important;
  }
`

const SubmenuTitleSCP = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: solid 1px ${ThemeProject.titleBarColor};
`
