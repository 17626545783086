import React from 'react'
import { AuthRoutes } from 'main/modules/auth/AuthRoutes'
import { RoutingHelper } from 'config/RoutingHelper'
import { LoginFormCP } from 'main/modules/auth/components/user-login-form/LoginFormCP'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { IntercomCP } from 'main/common/components/intercom/IntercomCP'

/**
 * TELA: Login.
 */
export function LoginScreen(): JSX.Element | null {

    return (
        <LayoutImageSiderCP
            sider={{ width: '60%', imageUrl: AppStateUtils.getDomainData()?.schema?.coverUrl ?? `${process.env.PUBLIC_URL}/images/login-cover.png` }}
            content={{ logoUrl: AppStateUtils.getDomainData()?.schema?.logoUrl ?? `${process.env.PUBLIC_URL}/logo-sistema.png`, logoWidth: 250 }}
        >
            <ContentCP>
                <FormWrapperSCP>
                    <LoginFormCP
                        onLoginSuccess={() => RoutingHelper.historyReplace('default')}
                        onRecoverPassword={() => RoutingHelper.historyPush(AuthRoutes.RECOVER_PWD)}
                    />
                </FormWrapperSCP>
            </ContentCP>

            <IntercomCP/>
        </LayoutImageSiderCP>
    )
}

const FormWrapperSCP = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`
