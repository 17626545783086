import React, { useEffect, useState } from 'react'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'

interface IPersonAutoCompletePickerCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    considerUser?: boolean
    considerCustomer?: boolean
    considerSupplier?: boolean
    segmentTypeToAdd?: SegmentTypeEnum
    shouldShowAdminUser?: boolean
}

/**
 * COMPONENTE
 * Seletor de pessoas com autocomplete.
 */
export function AutocompletePersonCP<FModelTP extends FormModel = any>(props: IPersonAutoCompletePickerCPProps<FModelTP>): JSX.Element {

    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)
    const [selectedName, setSelectedName] = useState<string>()
    const [initialOptions, setInitialOptions] = useState<INameAndCodeResponseDTO[]>()
    const [shouldClearSearchStr, setShouldClearSearchStr] = useState<boolean>(true)

    useEffect(() => setInitialOptions(props.initialOptions), [props.initialOptions])

    /**
     * Ao clicar em adicionar nova pessoa.
     */
    function onAdd(name: string): void {
        setSelectedName(name)
        setIsPersonDrawerVisible(true)
    }

    /**
     * Retorno ao adicionar pessoa.
     */
    function onPersonAdded(person: IPersonResponseDTO): void {

        setIsPersonDrawerVisible(false)
        if (props.formStateManager && props.fieldName)
            props.formStateManager?.changeFieldValue(props.fieldName, person.code)

        const addedPerson: INameAndCodeResponseDTO = {
            code: person.code,
            name: person.name
        }

        setInitialOptions([addedPerson])
        setShouldClearSearchStr(true)
    }

    return (
        <>
            <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
                requestConfigGetter={PersonRequests.searchByNameConfig}
                customFilters={{
                    considerUser: props.considerUser,
                    considerCustomer: props.considerCustomer,
                    considerSupplier: props.considerSupplier,
                    shouldShowAdminUser: props.shouldShowAdminUser,
                }}
                onAdding={props.segmentTypeToAdd ? onAdd : undefined}
                initialOptions={initialOptions}
                mustClearSearch={shouldClearSearchStr}
                onSearchCleared={() => setShouldClearSearchStr(false)}
                filterOption={props.filterOption}
                allowClear={props.allowClear}

                // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
                {...props/* eslint-disable-line react/jsx-props-no-spreading */}
            />

            {
                props.segmentTypeToAdd &&
                <DrawerPersonDataCP
                    visible={isPersonDrawerVisible}
                    onClose={() => setIsPersonDrawerVisible(false)}
                    onSave={onPersonAdded}
                    segmentType={props.segmentTypeToAdd}
                    initialData={{ name: selectedName }}
                />
            }
        </>
    )
}
