import { faAt, faPhone, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TextCP } from 'main/common/components/text/TextCP'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import React from 'react'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import * as _ from 'lodash'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { OpportunityTypeLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { OpportunityStatusLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { OpportunityDataButtonsICP } from 'main/modules/sales-funnel/components/opportunity-data/inner/OpportunityDataButtonsICP'

interface IOpportunityDataCPProps {
    opportunity: IOpportunityResponseDTO
    onEdit(): void
}

/**
 * Exibe os dados de uma oportunidade
 */
export function OpportunityDataCP(props: IOpportunityDataCPProps): JSX.Element {

    return (
        <>
            <OpportunityDataButtonsICP
                opportunity={props.opportunity}
                onEdit={props.onEdit}
            />

            <BasicInfoWrapperSCP>
                <NameWrapperSCP>
                    <FontAwsomeIconCP type={faUserCircle} color={ThemeAnt.primaryColor} size={'1x'}/>
                    { props.opportunity.leadData.name }
                </NameWrapperSCP>

                <ConditionalRenderCP shouldRender={!!props.opportunity.leadData.phone}>
                    <ContactWrapperSCP>
                        <FlexCP>
                            <FontAwsomeIconCP type={faPhone} color={ThemeAnt.primaryColor}/>
                            <TextPersonPhoneCP phone={props.opportunity.leadData.phone}/>
                        </FlexCP>
                    </ContactWrapperSCP>
                </ConditionalRenderCP>

                <ConditionalRenderCP shouldRender={!!props.opportunity.leadData.email}>
                    <ContactWrapperSCP>
                        <FontAwsomeIconCP type={faAt} color={ThemeAnt.primaryColor}/>
                        {props.opportunity.leadData.email}
                    </ContactWrapperSCP>
                </ConditionalRenderCP>
            </BasicInfoWrapperSCP>

            <OtherInfosWrapperSCP>
                <TextCP text={'Responsável:'} icon={'user'} strong={true}/>
                { props.opportunity.userResponsible.name }
            </OtherInfosWrapperSCP>

            <ConditionalRenderCP shouldRender={!_.isEmpty(props.opportunity.tags)}>
                <OtherInfosWrapperSCP>
                    <TextCP text={'Áreas/Serviços de Interesse:'} icon={'tag'} strong={true}/>
                    <TagsWrapperSCP>
                        {
                            props.opportunity.tags.map(tag => <TagCP key={tag.code.toString()} color={'purple'} content={tag.name}/>)
                        }
                    </TagsWrapperSCP>
                </OtherInfosWrapperSCP>
            </ConditionalRenderCP>

            <OtherInfosWrapperSCP>
                <TextCP text={'Origem do lead:'} icon={'funnel-plot'} strong={true}/>
                <TextCP text={props.opportunity.source.name}/>
                {
                    !!props.opportunity.sourcePersonLink &&
                    <TextCP text={`por ${props.opportunity.sourcePersonLink.name}`} fontStyle={'italic'}/>
                }
            </OtherInfosWrapperSCP>

            <ConditionalRenderCP shouldRender={!!props.opportunity.note}>
                <OtherInfosWrapperSCP>
                    <TextCP text={'Observações:'} icon={'edit'}/>
                    <NotesWrapperSCP>{props.opportunity.note}</NotesWrapperSCP>
                </OtherInfosWrapperSCP>
            </ConditionalRenderCP>

            <OtherInfosWrapperSCP>
                <TextCP text={'Etapa:'} strong={true}/>
                { OpportunityTypeLabelEnum[props.opportunity.type] }
            </OtherInfosWrapperSCP>

            <OtherInfosWrapperSCP>
                <TextCP text={'Status:'} strong={true}/>
                { OpportunityStatusLabelEnum[props.opportunity.status] }
            </OtherInfosWrapperSCP>
        </>
    )
}

const BasicInfoWrapperSCP = styled.div`
    margin-top: 1em;
    position: relative;
    svg {
        margin-right: 5px;
    }
`
const NameWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    font-size: 25px;
`
const ContactWrapperSCP = styled.div`
    margin: 10px 5px;
`
const OtherInfosWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    .ant-typography {
        font-size: 12px;
        color: #676767;
    }
    i {
        font-size: 18px;
        color: ${(props): string => props.theme.primaryColor};
    }
`

const TagsWrapperSCP = styled.div`
    display: flex;
    flex-wrap: wrap;
    .ant-tag {
        margin: 5px;
    }
`

const NotesWrapperSCP = styled.div`
    background-color: #fff7b4;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
`
