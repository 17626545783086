import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface ITagTreatmentGroupCPProps {
    treatmentGroups: INameAndCodeResponseDTO[]
}

/**
 * Tags de cargos.
 */
export function TagTreatmentGroupCP(props: ITagTreatmentGroupCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <FlexCP flexWrap={'wrap'}>
                {
                    props.treatmentGroups.map(tag => <TagCP color={'purple'} key={tag.name} content={tag.name}/>)
                }
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  line-height: 30px;
`
