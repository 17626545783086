import React from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'

interface ICPprops {
    value: JSON
    onChange: (json: JSON) => void
}

/**
 * Editor de JSON.
 */
export function EditorJsonCP(props: ICPprops): JSX.Element {

    if (!props.value)
        return <></>

    return (
        <Editor
            value={props.value}
            onChange={props.onChange}
            search={false}
        />
    )
}
