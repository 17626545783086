import React, { useEffect } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { TotalEvaluationByUserTableUtils } from 'main/modules/dashboard/components/widget-table-total-evaluation-by-user/inner/TotalEvaluationByUserTableUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IEvaluationsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IEvaluationsByUserListItemResponseDTO'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Componente de tabela que contas bancarias
 */
export function WidgetTableTotalEvaluationByUserCP(props: ICPProps): JSX.Element {

    const getEvaluationsByUserRequest = useRequest<IGenericListResponseDTO<IEvaluationsByUserListItemResponseDTO>>()
    useEffect(onGetEvaluationsByUserRequestChange, [getEvaluationsByUserRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes])

    /**
     * Busca dados na api.
     */
    function init(): void {
        getEvaluationsByUserRequest.runRequest(DashboardRequests.getEvaluationsByUserData({ franchiseCodes: props.franchiseCodes }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEvaluationsByUserRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getEvaluationsByUserRequest, 'Não foi possível obter evoluções'))
            return
    }

    return (
        <CardDashboardWidgetWrapperCP
            title={'Avaliações agendadas por colaborador'}
            className={'card-dashboard-widget'}
        >
            <TableCPOLD2
                columns={TotalEvaluationByUserTableUtils.getTableColumns()}
                data={getEvaluationsByUserRequest.responseData?.list}
                loading={getEvaluationsByUserRequest.isAwaiting}
            />
        </CardDashboardWidgetWrapperCP>
    )
}
