import React from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { SessionUtils } from 'main/modules/scheduler/utils/SessionUtils'
import { TagCP } from 'main/common/components/tag/TagCP'
import { SessionStatusEnum, SessionStatusLabelEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'

interface ITagSessionStatusCPProps {
    status: SessionStatusEnum
}

/**
 * COMPONENTE Tag de status da Sessao
 */
export function TagSessionStatusCP(props: ITagSessionStatusCPProps): JSX.Element {

    return (
        <TagCP
            key={props.status}
            color={SessionUtils.getSessionColor(props.status)}
            content={<TextCP text={SessionStatusLabelEnum[props.status]}/>}
        />
    )
}
