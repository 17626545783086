import React, { useState } from 'react'
import styled from 'styled-components'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { DashboardUserTitleCP } from 'main/modules/dashboard/components/dashboard-user-title/DashboardUserTitleCP'
import { AttendantDashboardActualAttendancePerformanceICP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/attendant-dashboard/inner/AttendantDashboardActualAttendancePerformanceICP'
import { AttendantDashboardActualSaleFlowPerformanceICP } from 'main/modules/dashboard/screens/screen-dashboard/screen-dashboard-franchise/content/attendant-dashboard/inner/AttendantDashboardActualSaleFlowPerformanceICP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { WidgetsRowAttendancePerformanceICP } from 'main/modules/dashboard/components/widgets-row-attendance-performance/WidgetsRowAttendancePerformanceICP'
import moment from 'moment'

/**
 * Dashboard da técnica de procedimento
 */
export function ScreenContentAttendantDashboardTabCP(): JSX.Element {

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({ beginDate: moment().startOf('month').toDate(), endDate: new Date() })
    const [selectedUser, setSelectedUser] = useState<INameAndCodeResponseDTO>(AppStateUtils.getLoggedUserData()!.user)

    return (
        <WrapperSCP>

            <DashboardUserTitleCP user={selectedUser} onSelectUser={setSelectedUser}/>
            <AttendantDashboardActualAttendancePerformanceICP userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}/>

            <FiltersFranchiseDashboardCP dateFilters={dateFilters} onDateRangeChange={setDateFilters}/>
            <AttendantDashboardActualSaleFlowPerformanceICP
                userCode={selectedUser.code}
                franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}
                dateFilters={dateFilters}
                hideSalesConversion={true}
            />
            <WidgetsRowAttendancePerformanceICP userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]} dateFilters={dateFilters}/>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
