/**
 * ENUM: Modulos do sistema.
 */
export enum ModuleEnum {
    MODULE_DASHBOARD = 'MODULE_DASHBOARD',
    MODULE_SCHEDULER = 'MODULE_SCHEDULER',
    MODULE_CRM = 'MODULE_CRM',
    MODULE_FINANCIAL = 'MODULE_FINANCIAL',
    MODULE_ADMIN = 'MODULE_ADMIN',
    MODULE_MARKETING = 'MODULE_MARKETING',
    MODULE_DOKFY = 'MODULE_DOKFY',

    MODULE_LISOLASER = 'MODULE_LISOLASER',
}
