export enum ScheduleReportItemStatusEnum {
    SCHEDULED = 'SCHEDULED',
    CONCLUDED = 'CONCLUDED',
    CANCELED = 'CANCELED'
}

export enum ScheduleReportItemStatusLabelEnum {
    SCHEDULED = 'Agendado',
    CONCLUDED = 'Realizado',
    CANCELED = 'Cancelado'
}
