import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { DatePicker, Icon } from 'antd'
import styled from 'styled-components'
import { ButtonCPOLD } from 'common/old-refactory/button/ButtonCPOLD'
import { StyledComponentProps } from 'main/config/theme/Theme'

interface IDateRangePickerCPProps {
    onChange: (date: string) => void
    value: Moment
    placeholder?: string
    allowClear?: boolean
    size?: 'large' | 'small' | 'default'
}

/**
 * COMPONENTE
 * Month picker customizado para filtro de mes
 */
export const MonthPickerCP = (props: IDateRangePickerCPProps): JSX.Element => {

    const [currentValue, setCurrentValue] = useState<Moment>(props.value)
    useEffect(() => { onDateRangeSelection(currentValue) }, [currentValue])

    function onDateRangeSelection(date: Moment | null): void {
        if (!date)
            return
        setCurrentValue(date)
        props.onChange(moment(date).format())
    }

    function renderMonthCell(date: Moment): JSX.Element {
        const isCurrent = moment(date).format('M') === moment().format('M')

        return (
            <MonthCellSCP isCurrent={isCurrent}>
                {moment(date).format('MMM')}
            </MonthCellSCP>
        )
    }

    return (
        <DatePickerContainerSCP>
            <ButtonCPOLD
                action={() => setCurrentValue(moment(currentValue).subtract(1, 'M'))}
                label={<Icon type={'left'}/>}
                style={{ borderBottomRightRadius: '0px' }}
                type={'primary'}
            />
            <DatePickerSCP
                size={props.size}
                allowClear={props.allowClear}
                value={currentValue}
                onChange={onDateRangeSelection}
                format={'MMM/YYYY'}
                placeholder={'Selecione o mês'}
                monthCellContentRender={(date) => renderMonthCell(date)}
            />
            <ButtonCPOLD
                action={() => setCurrentValue(moment(currentValue).add(1, 'M'))}
                label={<Icon type={'right'}/>}
                style={{ borderBottomLeftRadius: '0px' }}
                type={'primary'}
            />
        </DatePickerContainerSCP>
    )

}

const MonthCellSCP = styled.div<{ isCurrent: boolean }>`
    font-size: 14px;
    font-weight: 600;
    text-decoration: ${props => (props.isCurrent ? 'underline' : 'unset')};
`

const DatePickerSCP = styled(DatePicker.MonthPicker)`
    
    max-width: 100px;
   i{
       display: none
    }
   .ant-calendar-picker-input.ant-input{
       border: none;
       background: #fff;
       border: 1px solid ${(props: StyledComponentProps) => props.theme.gray};
       border-radius: 0;
       font-size: 14px;
       font-weight:600;
       &:hover{
        background: #f4f4f4;
        cursor: pointer;
       }
   }
   .ant-input:hover, .ant-calendar-picker-input:hover{
    border-color: #f59873;
    border-right-width: 1px !important;
   }
   &.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled){
    border-color: #f59873;
   }
   .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month{
        background: #f59873;
   }

`

const DatePickerContainerSCP = styled.div`
    display: flex;

   .ant-btn{
        height: 40px;
        border: none;
  
        .ant-icon{
            font-weight:800;
        }
        &:hover, &:active, &:focus{
            background: ${(props: StyledComponentProps) => props.theme.primaryColor};
            color: #fff;
        }
    }

`
