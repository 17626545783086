import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { AccountBalanceStatisticsCP } from 'main/modules/marketing/components/account-balance-statistics/AccountBalanceStatisticsCP'
import { ScheduleShotFormValidator } from 'main/modules/marketing/components/new-shot-confirm-modal/inner/ScheduleShotFormValidator'
import { ShotSendingChecklistItemTP } from 'main/modules/marketing/components/new-shot-confirm-modal/inner/ShotSendingChecklistItemTP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { IMarketingDestinationResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDestinationResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { ShotActionTypeTP } from 'main/modules/marketing/types/ShotActionTypeTP'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React from 'react'

/**
 * UTILITARIOS para modal de confirmacao de disparo de marketing.
 *
 * @author renatofs
 * @author hjcostabr
 */
export class NewShotConfirmModalUtils {

    private static readonly _CHECK_ICON = <FontAwesomeIcon icon={faCheckCircle} color={ThemeAnt.successColor} size={'2x'}/>
    private static readonly _EXCLAMATION_ICON = <FontAwesomeIcon icon={faExclamationCircle} color={ThemeAnt.warningColor} size={'2x'}/>

    static getEmailTableColumnsConfig(
        shot: IMarketingCampaignEmailResponseDTO,
        formStateManager: IFormStateManager<ScheduleShotFormValidator>,
        segmentName: string,
        actionType?: ShotActionTypeTP
    ): ShotSendingChecklistItemTP[] {
        return [
            {
                avatar: this._CHECK_ICON,
                title: 'Quem será o remetente do e-mail?',
                value: `${shot.sender!.name} | ${shot.sender!.email}`
            },
            this._getReceiverListItem(MarketingAccountTypeEnum.EMAIL, shot.destination, segmentName),
            {
                avatar: this._CHECK_ICON,
                title: 'Qual é o assunto do e-mail?',
                value: shot.subject
            },
            this._getBalanceListItem(MarketingAccountTypeEnum.EMAIL, shot.totalContacts),
            this._getScheduleListItem(formStateManager, actionType),
        ]
    }

    static getSmsTableColumnsConfig(shot: IMarketingCampaignSmsResponseDTO, formStateManager: IFormStateManager<ScheduleShotFormValidator>, segmentName: string, actionType?: ShotActionTypeTP): ShotSendingChecklistItemTP[] {
        return [
            this._getReceiverListItem(MarketingAccountTypeEnum.SMS, shot.destination, segmentName),
            {
                avatar: this._CHECK_ICON,
                title: 'Qual é a mensagem?',
                value: shot.content
            },
            this._getBalanceListItem(MarketingAccountTypeEnum.SMS, shot.totalContacts),
            this._getScheduleListItem(formStateManager, actionType),
        ]
    }

    private static _getReceiverListItem(type: MarketingAccountTypeEnum, destination: IMarketingDestinationResponseDTO, segmentName: string): ShotSendingChecklistItemTP {
        return {
            avatar: this._CHECK_ICON,
            title: `Para quem você está enviando este ${(type === MarketingAccountTypeEnum.EMAIL) ? 'e-mail' : 'SMS'}?`,
            value: MarketingUtils.getDestinationName(destination, segmentName)
        }
    }

    private static _getBalanceListItem(type: MarketingAccountTypeEnum, totalContacts: number): ShotSendingChecklistItemTP {
        return {
            avatar: this._CHECK_ICON,
            title: 'O saldo previsto da sua conta após o envio será de',
            value: <AccountBalanceStatisticsCP accountType={type} totalToSend={totalContacts} loading={false}/>
        }
    }

    private static _getScheduleListItem(formStateManager: IFormStateManager<ScheduleShotFormValidator>, actionType?: ShotActionTypeTP): ShotSendingChecklistItemTP {
        return {
            avatar: (actionType === 'sendNow') ? this._CHECK_ICON : this._EXCLAMATION_ICON,
            title: (actionType === 'sendNow') ? 'Quando será disparado?' : undefined,
            value: (actionType === 'sendNow')
                ? <TextCP text={'Agora'} strong={true}/>
                : (
                    <RowCP spaceBetweenCols={10}>
                        <ColumnCP size={12}>
                            <InputCP
                                type={'date'}
                                label={'Data'}
                                formStateManager={formStateManager}
                                fieldName={'date'}
                                required={true}
                            />
                        </ColumnCP>
                        <ColumnCP size={8}>
                            <InputCP
                                label={'Horário'}
                                formStateManager={formStateManager}
                                fieldName={'hour'}
                                required={true}
                                mask={InputMaskTypeEnum.HOUR}
                            />
                        </ColumnCP>
                    </RowCP>
                )
        }
    }
}
