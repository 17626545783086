
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { InputCP } from 'main/common/components/form-fields/input/InputCP';
import { ColumnCP } from 'main/common/components/grid/ColumnCP';
import { RowCP } from 'main/common/components/grid/RowCP';
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum';
import { NotificationHelper } from 'main/common/helpers/NotificationHelper';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { SystemConfig } from 'main/config/SystemConfig';
import React, {  useEffect, useState } from 'react'
import styled from 'styled-components';
export function RegistroComissaoParticular(props:any):JSX.Element{
    useEffect(()=>{
        carregarComissoes();
    },[]);
    const [carregando, setCarregando] = useState(false);
    const [comissaoVenda, setComissaoVenda] = useState(null);
    const [comissaoExecucao, setComissaoExecucao] = useState(null);
    console.log(props.idPessoa,setCarregando);
    const perc = (v:any)=>((parseFloat(v+'')).toFixed(0))
    
    async function carregarComissoes(){
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/product-releases/carregarComissoesParticular`,
            method: HttpMethodEnum.POST,
            params: {
                codigoPessoa:props.idPessoa,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>setCarregando(false)); 
        if(resultado?.data?.data?.length){
            let cvenda:any = perc(resultado.data.data[0].comissao_venda);
            let cexecucao:any = perc(resultado.data.data[0].comissao_execucao);
            setComissaoVenda(cvenda);
            setComissaoExecucao(cexecucao);
        }
        return;
    }

    async function salvarComissoes(){
        setCarregando(true);
        if(comissaoVenda && comissaoExecucao){
            if(parseFloat(comissaoVenda+'')>100 || parseFloat(comissaoExecucao+'')>100){
                NotificationHelper.error('Inválido','Os valores de comissões precisam ser menores que 100%');
                setCarregando(false);
                return;
            }
        }

        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/product-releases/salvarComissoesParticular`,
            method: HttpMethodEnum.POST,
            params: {
                comissaoVenda:comissaoVenda && comissaoVenda!='NaN'?comissaoVenda:null,
                comissaoExecucao:comissaoExecucao && comissaoExecucao!='NaN'?comissaoExecucao:null,
                codigoPessoa:props.idPessoa,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>setCarregando(false)); 

        if(resultado?.data?.data=='sucesso'){
            NotificationHelper.success('Sucesso','Percentuais de comissão salvos com sucesso!');
            return;
        }
        NotificationHelper.error('Houve um Erro','Houve um erro ao tentar salvar as comissões');
    }
    return (
        <WrapperSCP>
            <p>
                Os valores registrados aqui serão utilizados como base no cálculo do relatório de comissões, este que leva em 
                consideração vendas de pacotes e a execução de sessões. Os valores são percentuais e devem ser inferior a 100.
            </p>
            <p>
                As comissões particulares serão consideradas preferencialmente sobre as definidas nos produtos.
            </p>
        <div className='painel-comissao'>
            <RowCP>
                
                
                <div className='sub'>
                    Comissão de Venda
                </div>
                <ColumnCP size={9}>
                    <InputCP
                        mask={InputMaskTypeEnum.NUMERIC}
                        value={comissaoVenda}
                        onChange={setComissaoVenda}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <div className='sub'>
                    Comissão de Execução
                </div>
                <ColumnCP size={9}>
                    <InputCP
                        mask={InputMaskTypeEnum.NUMERIC}
                        value={comissaoExecucao}
                        onChange={setComissaoExecucao}
                    />
                </ColumnCP>
                
                
            </RowCP>
            <RowCP>
                <div className='dir-botao'>
                    <ButtonCP
                        onClick={salvarComissoes}
                        type={'primary'}
                        icon={'check'}
                        loading={carregando}
                    
                    >Salvar Comissões</ButtonCP>
                </div>
            </RowCP>
            </div>
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div`
  width: 100%;
  .sub{
    color: #2b698cff;
    font-weight: 589;
  }
  .painel-comissao{
    padding-bottom: 11px;
    border-bottom: dashed 1px gray;
    border-top: dashed 1px gray;
    padding-top: 4px;
    margin-top: 7px;
  }
  .dir-botao{
    display:flex;
    justify-content: right;
    margin-right: 10px;
    margin-top: 4px;
  }
`