export enum PersonReferralPhaseEnum {
    LEAD = 'LEAD',
    EVALUATION = 'EVALUATION',
    EVALUATION_DONE = 'EVALUATION_DONE',
    SALE = 'SALE'
}

export enum PersonReferralPhaseLabelEnum {
    LEAD = 'Lead',
    EVALUATION = 'Avaliação Agendada',
    EVALUATION_DONE = 'Avaliação Realizada',
    SALE = 'Comprou'
}