import { PaginationConfig } from 'antd/lib/table'
import { TableCPOLD, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { ISearchProductReleaseRequestDTO } from '../../services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'
import { CardCP } from 'main/common/components/card/CardCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IProductReleaseResponseDTO } from 'main/modules/products/services/product-release/dtos/response/IProductReleaseResponseDTO'
import { ProductReleaseTableUtils } from 'main/modules/products/components/table-product-release/inner/ProductReleaseTableUtils'
import { ProductReleaseRequests } from 'main/modules/products/services/product-release/ProductReleaseRequests'

const DEFAULT_PAGINATION: TablePaginationTP = {
    current: 1,
    pageSize: 50,
    total: 0,
    showTotal: (total, range) => `Mostrando ${range.join('-')} de ${total}`
}

const DEFAULT_FILTERS: ISearchProductReleaseRequestDTO = {
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}

interface IProductReleaseListTableCPProps {
    shouldReloadReleases: boolean
    onReleasesReloaded: () => void
    onEdit: (code: number) => void
}

/**
 * Componente de tabela que lista os pacotes de atendimento de uma franquia
 */
export function TableProductReleaseCP(props: IProductReleaseListTableCPProps): JSX.Element {

    const [productReleases, setProductReleases] = useState<IProductReleaseResponseDTO[]>([])
    const [filters, setFilters] = useState<ISearchProductReleaseRequestDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<TablePaginationTP>(DEFAULT_PAGINATION)

    useEffect(init, [props.shouldReloadReleases])
    useEffect(loadReleases, [filters])

    const searchProductReleaseRequest = useRequest<IGenericListResponseDTO<IProductReleaseResponseDTO>>()
    useEffect(onSearchProductReleaseRequestChange, [searchProductReleaseRequest.isAwaiting])

    /**
     * Obtem releases
     */
    function init(): void {

        if (!props.shouldReloadReleases)
            return

        loadReleases()
    }

    /**
     * Carrega releases da API.
     */
    function loadReleases(): void {
        const v = ProductReleaseRequests.search(filters);
        searchProductReleaseRequest.runRequest(v)
    }

    /**
     * Retorno da API com as releases
     */
    function onSearchProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchProductReleaseRequest, 'Erro ao buscar lista com pacotes de atendimento'))
            return

        console.log("RECEBEU DADOS ", searchProductReleaseRequest.responseData!.list);    
        setProductReleases(searchProductReleaseRequest.responseData!.list)
        setPagination({
            ...pagination,
            total: searchProductReleaseRequest.responseData!.total || 0
        })

        props.onReleasesReloaded()
    }

    /**
     * Limpa filtros
     */
    function clearFilters(): void {
        setFilters({ ...DEFAULT_FILTERS })
        setPagination(DEFAULT_PAGINATION)
    }

    return (
        <>
            <FlexCP
                justifyContent={'flex-end'}
                marginRight={20}
            >
                <ButtonCP
                    marginTop={30}
                    type={'link'}
                    size={'small'}
                    onClick={clearFilters}
                    icon={'filter'}
                >
                    Limpar filtros
                </ButtonCP>
            </FlexCP>

            <CardCP innerSpacing={'none'}>

                <TableCPOLD
                    data={productReleases}
                    columns={ProductReleaseTableUtils.getTableColumns(
                        setFilters,
                        filters,
                        props.onEdit
                    )}
                    loading={searchProductReleaseRequest.isAwaiting}
                    costumizedFilters={ProductReleaseTableUtils.getTableFilters(setFilters, filters)}
                    pagination={pagination}
                    locale={{
                        filterConfirm: 'Filtrar',
                        filterReset: 'Limpar',
                        emptyText: 'Sem resultados para os filtros selecionados'
                    }}
                    onPaginationChange={(page: PaginationConfig): void => {
                        setPagination({ ...pagination, current: page.current! })
                        setFilters({ ...filters, page: page.current })
                    }}
                />
            </CardCP>
        </>
    )
}
