import React, { useEffect, useState } from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { TableSessionFormHistoryCP } from 'main/modules/scheduler/components/table-session-form-history/TableSessionFormHistoryCP'
import { ISessionAnswersHistoryResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionAnswersHistoryResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'

interface ICollapseSessionFormHistoryCPProps {
    personCode: number
}

/**
 * Componente collapse que mostra o historico das evolucoes das sessoes.
 * Os formularios preenchidos de cada sessao.
 */
export function CollapseSessionFormHistoryCP(props: ICollapseSessionFormHistoryCPProps): JSX.Element {

    useEffect(init, [props.personCode])

    const [sessionAnswersHistory, setSessionAnswersHistory] = useState<ISessionAnswersHistoryResponseDTO[]>([])

    const getSessionAnswersHistoryRequest = useRequest<IGenericListResponseDTO<ISessionAnswersHistoryResponseDTO>>()
    useEffect(onGetSessionAnswersHistoryRequestChange, [getSessionAnswersHistoryRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {
        getSessionAnswersHistoryRequest.runRequest(SchedulerRequests.getSessionAnswersHistory(props.personCode))
    }

    /**
     * Retorno da requisicao para obter as sessoes
     */
    function onGetSessionAnswersHistoryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSessionAnswersHistoryRequest, 'Erro obter histórico de sessões'))
            return

        setSessionAnswersHistory(getSessionAnswersHistoryRequest.responseData?.list ?? [])
    }

    return (
        <CollapseCP
            overflow={'auto'}
            loading={getSessionAnswersHistoryRequest.isAwaiting}
            bordered={true}
            panels={
                sessionAnswersHistory.map(sessionHistory => (
                    {
                        key: `key-${sessionHistory.treatment.code}`,
                        title: sessionHistory.treatment.name,
                        extra: <TextCP text={`${sessionHistory.sessionAnswers.length} sessões realizadas`} size={'small'} color={'#adadad'}/>,
                        content: (
                            <TableSessionFormHistoryCP data={sessionHistory.sessionAnswers}/>
                        )
                    }
                ))
            }
        />
    )
}
