import _ from 'lodash'
import { SelectProductReleaseFranchiseCP } from 'main/modules/products/components/select-product-release-franchise/SelectProductReleaseFranchiseCP'
import { ISaleProductItem } from 'main/modules/sale/components/sale-item-table/inner/interfaces/ISaleProductItem'
import { SaleItemTableUtils } from 'main/modules/sale/components/sale-item-table/inner/SaleItemTableUtils'
import React, { useEffect, useState } from 'react'
import { TextCP } from 'main/common/components/text/TextCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO } from 'main/modules/products/services/product-release-franchise/dtos/response/IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SaleProductDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleProductDataFormModel'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { IVoucherResponseDTO } from 'main/modules/sale/services/voucher/dtos/response/IVoucherResponseDTO'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { styled } from 'main/config/theme/styledWithTheme'
import { CreateSaleUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleUtils'
import { PopoverPercentageValueCalculator } from 'main/modules/sale/components/popover-percentage-value-calculator/PopoverPercentageValueCalculator'
import { SelectVoucherCP } from 'main/modules/sale/components/select-voucher/SelectVoucherCP'

interface ISaleProductStepICPProps {
    formStateManager: IFormStateManager<SaleProductDataFormModel>
    paymentFormStateManager: IFormStateManager<SalePaymentDataFormModel>
    canGoNext: (isValid: boolean) => void
}

export function SaleProductStepICP(props: ISaleProductStepICPProps): JSX.Element {

    const [productsTableList, setProductsTableList] = useState<ISaleProductItem[]>(props.formStateManager.getFieldValue('products') ?? [])
    useEffect(updateFormManagerValues, [productsTableList])

    /**
     * Evento ao adicionar ou remover um produto do seletor de produtos.
     */
    function onProductListChanged(products?: IReleaseFranchiseNameAndCodeAndPriceListItemResponseDTO[]): void {

        if (!products)
            return setProductsTableList([])

        const list: ISaleProductItem[] = products.map(product => ({
            code: product.code,
            price: product.price,
            quantity: 1,
            releaseName: product.release.name,
            total: product.price
        }))
        setProductsTableList(list)
    }

    /**
     * Ao mudar a quantidade de um produto.
     */
    function onQuantityChange(newQuantity: number, productCode?: number): void {
        if(!newQuantity)newQuantity = 1;
        if (!productCode)
            return

        setProductsTableList(productsTableList.map(product => {
            if (product.code === productCode) {
                product.quantity = newQuantity
                product.total = product.quantity * product.price
                return product
            }
            return product
        }))
    }

    /**
     * Atualiza FormStateManager sempre que a lista de produtos alterar
     */
    function updateFormManagerValues(): void {

        props.formStateManager.changeFieldValue('products', productsTableList ?? [])

        if (_.isEmpty(productsTableList)) {
            props.formStateManager.changeFieldValue('totalValue', 0)
            props.paymentFormStateManager.changeFieldValue('totalValue', 0)
            return props.canGoNext(false)
        }

        const totalValueCalculated: number = _.sumBy(productsTableList, (product): number => (product.total))
        props.formStateManager.changeFieldValue('totalValue', totalValueCalculated)
        props.paymentFormStateManager.changeFieldValue('originalTotalValue', totalValueCalculated)

        // Atualiza valores totais, chamando o proprio desconto ja inserido
        applyDiscount(
            props.paymentFormStateManager.getFieldValue('voucher'),
            props.paymentFormStateManager.getFieldValue('discountValue')
        )

        props.canGoNext(true)
    }

    /**
     * Aplica um desconto
     */
    function applyDiscount(voucher?: IVoucherResponseDTO, discountEntered?: string): void {

        const totalValue = props.formStateManager.getFieldValue('totalValue')
        // Verifica se eh undefined pq zero deve continuar o metodo, apenas sai se nao tiver preenchido
        if (totalValue === undefined)
            return

        let discountValueCalculatted = 0

        if (!!voucher) {
            discountValueCalculatted = CreateSaleUtils.calculateVoucherValue(voucher, totalValue)
            props.paymentFormStateManager.changeFieldValue('voucher', voucher)

        } else if (!!discountEntered)
            discountValueCalculatted = MoneyUtils.convertToFloat(discountEntered)

        // Valor final
        let finalTotalValue = totalValue - discountValueCalculatted

        // Se o valor do desconto for maior que o valor da compra, define o valor máximo de desconto o valor total
        if (finalTotalValue < 0) {
            discountValueCalculatted = totalValue
            finalTotalValue = 0
        }

        props.paymentFormStateManager.changeFieldValue('discountValue', MaskUtils.applyMoneyMask(discountValueCalculatted))
        props.paymentFormStateManager.changeFieldValue('totalValue', Number(finalTotalValue.toFixed(2)))
    }

    return (
        <>
            <SelectProductReleaseFranchiseCP
                hasPrice={true}
                label={'Selecione os pacotes desejados ...'}
                multiple={true}
                required={true}
                onProductListChange={onProductListChanged}
                initialOptions={props.formStateManager.getFieldValue('products')}
            />

            <TableCPOLD2
                data={productsTableList}
                columns={SaleItemTableUtils.getSalesDataColumns(onQuantityChange)}
            />

            <FlexCP marginTop={30} justifyContent={'flex-end'}>
                <TextCP text={`Subtotal: ${MaskUtils.applyMoneyMask(props.formStateManager.getFieldValue('totalValue'))}`} strong={true}/>
            </FlexCP>

            <FlexCP justifyContent={'flex-end'}>
                <div>
                    <FlexCP marginTop={20} alignItems={'center'}>
                        <TextCP text={'Desconto:'} strong={true} marginRight={20}/>
                        <SelectVoucherCP
                            label={'Cupom de desconto'}
                            formStateManager={props.paymentFormStateManager}
                            fieldName={'voucherCode'}
                            onSelectVoucher={(voucher) => applyDiscount(voucher)}
                            width={210}
                            allowClear={true}
                        />
                        <TextCP text={' ou '} marginRight={20} marginLeft={20}/>
                        <InputWrapperSCP>
                            <FlexCP>
                                <InputCP
                                    label={'Valor'}
                                    formStateManager={props.paymentFormStateManager}
                                    fieldName={'discountValue'}
                                    mask={InputMaskTypeEnum.MONEY}
                                    onChange={(value) => applyDiscount(undefined, value)}
                                    marginRight={5}
                                />
                                <PopoverPercentageValueCalculator
                                    onClose={(val) => props.paymentFormStateManager.changeFieldValue('discountValue', MaskUtils.applyMoneyMask(val))}
                                    totalValue={props.paymentFormStateManager.getFieldValue('totalValue')}
                                />
                            </FlexCP>
                        </InputWrapperSCP>
                    </FlexCP>
                    <TextCP
                        strong={true}
                        size={'large'}
                        text={`Total: ${MaskUtils.applyMoneyMask(props.paymentFormStateManager.getFieldValue('totalValue'))}`}
                        style={{ textAlign: 'right' }}
                    />
                </div>
            </FlexCP>
        </>
    )
}

const InputWrapperSCP = styled.div`
  width: 150px;
`
