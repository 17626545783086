import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'main/common/utils/StringUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { PersonRoutes } from 'main/modules/people/routes/PersonRoutes'
import { ScreenScheduler } from 'main/modules/scheduler/screens/screen-scheduler/ScreenScheduler'
import React from 'react'
import { ScreenCalendar } from 'main/modules/scheduler/screens/screen-calendar/ScreenCalendar'
import { ScreenAttendanceQueue } from 'main/modules/scheduler/screens/screen-attendance-queue/ScreenAttendanceQueue'

SchedulerRoutes.ROOT = 'agenda'
SchedulerRoutes.CALENDAR = `${SchedulerRoutes.ROOT}/agendamentos`
SchedulerRoutes.SCHEDULER = `${SchedulerRoutes.ROOT}/marcacao`
SchedulerRoutes.ATTENDANCE_QUEUE = `${SchedulerRoutes.ROOT}/fila-atendimento`

/**
 * Componente que define as rotas de acesso para a tela de agenda
 */
export function SchedulerRoutes(): JSX.Element {

    return (
        <AccessControlCP
            permissions={[PermissionEnum.ROLE_SCHEDULER]}
            routeToReturn={PersonRoutes.ROOT}
        >
            {
                RoutingHelper.renderRoutingSwitch([
                {
                    key: StringUtils.getSlugStyleString(SchedulerRoutes.CALENDAR),
                    path: SchedulerRoutes.CALENDAR,
                    component: ScreenCalendar,
                },
                {
                    key: StringUtils.getSlugStyleString(SchedulerRoutes.SCHEDULER),
                    path: SchedulerRoutes.SCHEDULER,
                    component: ScreenScheduler,
                },
                {
                    key: StringUtils.getSlugStyleString(SchedulerRoutes.ATTENDANCE_QUEUE),
                    path: SchedulerRoutes.ATTENDANCE_QUEUE,
                    component: ScreenAttendanceQueue,
                },
            ])}
        </AccessControlCP>
    )
}
