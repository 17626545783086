import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { styled } from 'main/config/theme/styledWithTheme'
import { EvaluationFormModel } from 'main/modules/sales-funnel/components/drawer-evaluation-data/inner/EvaluationFormModel'
import { IOpportunityEvaluationScheduleSaveRequestDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/request/IOpportunityEvaluationScheduleSaveRequestDTO'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { OpportunityEvaluationRequests } from 'main/modules/sales-funnel/services/evaluation/OpportunityEvaluationRequests'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AppointmentDetailsHeaderCP } from 'main/modules/scheduler/components/appointment-details-header/AppointmentDetailsHeaderCP'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { EvaluationDoneActionTP, ModalEvaluationConfirmCP } from 'main/modules/sales-funnel/components/modal-evaluation-confirm/ModalEvaluationConfirmCP'
import { EvaluationActionTP, EvaluationDrawerButtonsICP } from 'main/modules/sales-funnel/components/drawer-evaluation-data/inner/EvaluationDrawerButtonsICP'
import { EvaluationFormDataICP } from 'main/modules/sales-funnel/components/drawer-evaluation-data/inner/EvaluationFormDataICP'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { OpportunityScheduleTypeEnum } from 'main/modules/sales-funnel/services/evaluation/enums/OpportunityScheduleTypeEnum'
import { OpportunityScheduleStatusEnum } from 'submodules/neritclin-sdk/services/opportunity-schedule/enums/OpportunityScheduleStatusEnum'

interface IDrawerEvaluationDataCPProps {
    visible: boolean
    onClose: () => void
    onSave: (action?: EvaluationDoneActionTP) => void
    opportunityCode?: number
    evaluationScheduleCode?: number
}

/**
 * Drawer para agendar uma avaliação.
 */
export function DrawerEvaluationDataCP(props: IDrawerEvaluationDataCPProps): JSX.Element {

    const [formModelValidator, setFormModelValidator] = useState<EvaluationFormModel>(new EvaluationFormModel({}))
    const formStateManager = useFormStateManager<EvaluationFormModel>(formModelValidator)

    const [isEvaluationConfirmModalVisible, setIsEvaluationConfirmModalVisible] = useState<boolean>(false)

    const [actionType, setActionType] = useState<EvaluationActionTP>()
    const saveEvaluationScheduleRequest = useRequest<IOpportunityEvaluationScheduleSaveRequestDTO>()
    useEffect(onSaveEvaluationScheduleRequestChange, [saveEvaluationScheduleRequest.isAwaiting])

    const [loadedEvaluation, setLoadedEvaluation] = useState<IOpportunityEvaluationScheduleResponseDTO>()
    const getEvaluationScheduleRequest = useRequest<IOpportunityEvaluationScheduleResponseDTO>()
    useEffect(onGetEvaluationScheduleRequestChange, [getEvaluationScheduleRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        if (!props.opportunityCode && !props.evaluationScheduleCode)
            return NotificationHelper.error('Ops!', 'Não foi informado o código da oportunidade ou código da avaliação. Avise o administrador do sistema')

        if (props.evaluationScheduleCode)
            return getEvaluationScheduleRequest.runRequest(OpportunityEvaluationRequests.getScheduleConfig(props.evaluationScheduleCode))

        // Caso chegou aqui devemos instanciar para uma nova avaliacao
        setFormModelValidator(new EvaluationFormModel({
            status: OpportunityScheduleStatusEnum.SCHEDULED,
            scheduleType: OpportunityScheduleTypeEnum.IN_PERSON,
        }))
    }

    /**
     * Retorno da API ao buscar por uma pessoa
     */
    function onGetEvaluationScheduleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEvaluationScheduleRequest, 'Erro ao buscar dados da avaliação.'))
            return

        const evaluationScheduleResult = getEvaluationScheduleRequest.responseData!
        setLoadedEvaluation(evaluationScheduleResult)

        const dateFormatted = DateUtils.toDate(evaluationScheduleResult.beginDate, DateFormatEnum.US_WITH_TIME_H_M)
        setFormModelValidator(new EvaluationFormModel({
            responsibleCode: evaluationScheduleResult.userResponsible.code,
            notes: evaluationScheduleResult.notes,
            date: dateFormatted,
            isDone: evaluationScheduleResult.status === OpportunityScheduleStatusEnum.COMPLETED,
            presenceConfirmation: evaluationScheduleResult.presenceConfirmation,
            status: evaluationScheduleResult.status,
            beginTime: moment(evaluationScheduleResult.beginDate),
            endTime: moment(evaluationScheduleResult.endDate),
            scheduleType: evaluationScheduleResult.type,
        }))
    }

    /**
     * Chama API para criar agendamento
     */
    function onSaveEvaluation(clickedActionType: EvaluationActionTP): void {

        setActionType(clickedActionType)

        formStateManager.setConsiderAllErrors(true)
        if (!formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (!formValues.beginTime || !formValues.endTime)
            return NotificationHelper.error('Ops!', 'Selecione os horário de início e término da avalização')

        // Ajusta as datas de acordo com horario de inicio e termino
        const beginDate = moment(formValues.date).toDate()
        beginDate.setHours(formValues.beginTime.hour(), formValues.beginTime.minute(), 0)

        const endDate = moment(formValues.date).toDate()
        endDate.setHours(formValues.endTime.hour(), formValues.endTime.minute(), 0)

        // Define o status de acordo com o que foi clicado
        let status: OpportunityScheduleStatusEnum = formValues.status ?? OpportunityScheduleStatusEnum.SCHEDULED
        if (clickedActionType === 'markAsDone')
            status = OpportunityScheduleStatusEnum.COMPLETED
        if (clickedActionType === 'markAsNoShow')
            status = OpportunityScheduleStatusEnum.NO_SHOW

        // Obtem dto para request
        const saveRequestDto: IOpportunityEvaluationScheduleSaveRequestDTO = {
            userResponsibleCode: formValues.responsibleCode,
            beginDate: DateUtils.getFormatted(beginDate, DateFormatEnum.US_WITH_TIME_H_M),
            endDate: DateUtils.getFormatted(endDate, DateFormatEnum.US_WITH_TIME_H_M),
            notes: formValues.notes,
            presenceConfirmation: formValues.presenceConfirmation ?? false,
            type: formValues.scheduleType,
            status,
        }

        if (props.evaluationScheduleCode)
            saveEvaluationScheduleRequest.runRequest(OpportunityEvaluationRequests.updateScheduleConfig(props.evaluationScheduleCode, saveRequestDto))
        else
            saveEvaluationScheduleRequest.runRequest(OpportunityEvaluationRequests.createScheduleConfig(props.opportunityCode!, saveRequestDto))
    }

    /**
     * Retorno ao criar o agendamento.
     */
    function onSaveEvaluationScheduleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveEvaluationScheduleRequest, 'Erro ao salvar avaliação!'))
            return

        // Caso tenha concluido abre o modal para informar qual processo seguir
        if (actionType === 'markAsDone') {
            setIsEvaluationConfirmModalVisible(true)
            return
        }

        props.onSave()
        NotificationHelper.success('Pronto!', 'Avaliação agendada com sucesso')
    }

    return (
        <>
            <DrawerCP
                title={'Avaliação'}
                visible={props.visible}
                onClose={props.onClose}
                footerSpaced={!!props.evaluationScheduleCode}
                loading={getEvaluationScheduleRequest.isAwaiting}
                footer={
                    <EvaluationDrawerButtonsICP
                        onSave={onSaveEvaluation}
                        onDelete={props.onSave}
                        isSaving={saveEvaluationScheduleRequest.isAwaiting}
                        code={props.evaluationScheduleCode}
                    />
                }
            >
                {
                    !!loadedEvaluation?.personCustomer &&
                    <HeaderDataSCP>
                        <AppointmentDetailsHeaderCP customer={loadedEvaluation.personCustomer}/>
                    </HeaderDataSCP>
                }

                <EvaluationFormDataICP formStateManager={formStateManager}/>

                {
                    !!loadedEvaluation &&
                    <TitleAndTextCP
                        marginTop={40}
                        title={'Agendado por:'}
                        text={StringUtils.getFirstAndSecondName(loadedEvaluation.userCreator.name)}
                    />
                }
            </DrawerCP>

            {
                (!!props.opportunityCode || !!loadedEvaluation?.opportunityCode) &&
                <ModalEvaluationConfirmCP
                    opportunityCode={(props.opportunityCode ?? loadedEvaluation?.opportunityCode)!}
                    onSave={(action) => {
                        props.onSave(action)
                        setIsEvaluationConfirmModalVisible(false)
                    }}
                    onCancel={() => {
                        props.onSave()
                        setIsEvaluationConfirmModalVisible(false)
                    }}
                    visible={isEvaluationConfirmModalVisible}
                />
            }
        </>
    )
}

const HeaderDataSCP = styled.div`
  border: 2px dashed ${(props: StyledComponentProps) => props.theme.gray};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 20px;
`
