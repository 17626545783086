import { TextCP } from 'main/common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MathUtils } from 'main/common/utils/MathUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IWidgetResponseDTO } from 'main/modules/dashboard/services/dtos/response/IWidgetResponseDTO'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { CardDashboardWidgetRateFooterICP, } from 'main/modules/dashboard/components/card-dashboard-widget/inner/CardDashboardWidgetRateFooterICP'

interface IScpProps {
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
}

interface ICPProps extends IScpProps {
    title: string
    requestConfigGetter?: RequestConfigTP
    valueMask?: InputMaskTypeEnum.MONEY | string
    isZeroTarget?: boolean
    loading?: boolean
    help?: string
    footerHelp?: string
    footerTitle?: string
    secondCalc?: 'ratioBetweenSecondValueAndMainValue' | 'ratioBetweenMainValueAndSecondValue' | 'onlyValue'
    secondValueMask?: InputMaskTypeEnum.MONEY | string
    isInvertedProgress?: boolean
    maxWidth?: number
}

export function CardDashboardWidgetCP(props: ICPProps): JSX.Element {

    const [secondCalc, setSecondCal] = useState<'ratioBetweenSecondValueAndSecondTarget' | 'ratioBetweenSecondValueAndMainValue' | 'ratioBetweenMainValueAndSecondValue' | 'onlyValue'>()
    const [widgetData, setWidgetData] = useState<IWidgetResponseDTO>()
    const widgetRequest = useRequest<IWidgetResponseDTO>()
    useEffect(onWidgetRequestChange, [widgetRequest.isAwaiting])

    useEffect(init, [props.requestConfigGetter])

    /**
     * Busca valor do widget na API.
     */
    function init(): void {

        if (!props.requestConfigGetter)
            return

        widgetRequest.runRequest(props.requestConfigGetter)
    }

    /**
     * Retorno da requisicao para pegar valores do widget.
     */
    function onWidgetRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(widgetRequest, 'Erro ao obter valores do widget'))
            return

        setWidgetData(widgetRequest.responseData)

        if (!!widgetRequest.responseData?.secondValue && !!widgetRequest.responseData.secondTarget)
            setSecondCal('ratioBetweenSecondValueAndSecondTarget')
        else if (!!props.secondCalc)
            setSecondCal(props.secondCalc)
    }

    /**
     * Formata um valor baseado no tipo da mascara.
     */
    function getFormattedValue(value: number): string {
        return props.valueMask === InputMaskTypeEnum.MONEY
            ? MaskUtils.applyMoneyMask(value)
            : `${MaskUtils.applyNumberMask(value, 2)}${props.valueMask ?? ''}`
    }

    /**
     * Obtem o valor secundario do widget dependendo de como o mesmo deve ser calculado
     */
    function getWidgetSecondaryValue(): number | undefined {

        if (!widgetData)
            return

        // Para cada tipo de calculo retorna um valor

        // Se sera utilizado apenas o valor secundario, retorna ele mesmo
        if (secondCalc === 'onlyValue')
            return widgetData.secondValue

        // Se eh sobre o valor secundario, retorna ele
        if (secondCalc === 'ratioBetweenSecondValueAndMainValue' || secondCalc === 'ratioBetweenSecondValueAndSecondTarget')
            return widgetData.secondValue

        // Caso contrario é sobre o valor principal, retorna ele
        return widgetData.value
    }

    /**
     * Obtem o valor da meta secundaria do widget dependendo de como o mesmo deve ser calculado.
     */
    function getWidgetSecondaryTarget(): number | undefined {

        if (!widgetData)
            return

        // Para cada tipo de calculo retorna um valor

        // Se sera utilizado apenas o valor secundario, o valor da meta eh ignorado, pois eh configuracao de apenas valor
        if (secondCalc === 'onlyValue')
            return undefined

        // Se eh sob o valor principal
        if (secondCalc === 'ratioBetweenSecondValueAndMainValue')
            return widgetData.value

        // Se eh sob o second target informado
        if (secondCalc === 'ratioBetweenSecondValueAndSecondTarget')
            return widgetData.secondTarget

        // Caso contrario eh sobre o proprio valor2
        return widgetData?.secondValue
    }

    return (
        <CardDashboardWidgetWrapperCP
            className={'card-dashboard-widget'}
            maxWidth={props.maxWidth ?? 300}
            title={props.title}
            footer={
                widgetData?.secondValue !== undefined && !!props.footerTitle
                    ?
                    <CardDashboardWidgetRateFooterICP
                        title={props.footerTitle}
                        help={props.footerHelp}
                        value={getWidgetSecondaryValue() ?? 0}
                        target={getWidgetSecondaryTarget()}
                        type={secondCalc === 'ratioBetweenSecondValueAndSecondTarget' ? 'target' : 'ratio'}
                        valueMask={props.secondValueMask}
                    />
                    :
                    undefined
            }
            help={props.help}
            loading={widgetRequest.isAwaiting}
        >
            <>
                {
                    !!widgetData && widgetData.value !== undefined &&
                    <>
                        <ValueSCP color={props.isZeroTarget && widgetData.value > 0 ? ThemeAnt.errorColor : undefined}>
                            { getFormattedValue(widgetData.value) }
                        </ValueSCP>
                        <>
                            {
                                !!widgetData?.target &&
                                <>
                                    <ProgressCP
                                        percent={MathUtils.getPercentage(widgetData.value, widgetData.target, true)}
                                        gradientColor={true}
                                        invertGradientColor={props.isInvertedProgress}
                                        size={'big'}
                                    />
                                    <TextCP
                                        text={`Meta: ${getFormattedValue(widgetData.target)}`}
                                        color={ThemeAnt.gray}
                                        marginTop={5}
                                    />
                                </>
                            }
                        </>
                    </>
                }
            </>
        </CardDashboardWidgetWrapperCP>
    )
}

const ValueSCP = styled.div<{ color?: string }>`
  font-size: 30px;
  color: ${props => props.color};
`
