import React from 'react'
import { Slider } from 'antd'
import { SliderMarks, SliderValue } from 'antd/lib/slider'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'

interface ISliderInputCPProps {
    minValue?: number
    maxValue?: number
    step?: number
    disabled?: boolean
    onChange?: (value: SliderValue) => void
    value?: number
    marks?: SliderMarks
    range?: boolean
    tooltipVisible?: boolean
    tipFormatter?: ((value: number) => JSX.Element) | null | undefined
}

export function SliderInputCP(props: ISliderInputCPProps) {
    return (
        <SliderWrapperSCP>
            <Slider
                min={props.minValue}
                max={props.maxValue}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
                step={props.step}
                marks={props.marks}
                range={props.range}
                tooltipVisible={props.tooltipVisible}
                tipFormatter={props.tipFormatter}
            />
        </SliderWrapperSCP>
    )
}
const SliderWrapperSCP = styled.div`
    .ant-slider{
        margin: 24px 6px 10px;
        &:hover{
           .ant-slider-track {
                background-color: ${(props: StyledComponentProps) => props.theme.lightPrimary};
            }
            .ant-slider-handle:not(.ant-tooltip-open){
                border-color: ${(props: StyledComponentProps) => props.theme.lightPrimary};        
            }
        }
    }
    .ant-slider-handle{
        border: 2px solid ${(props: StyledComponentProps) => props.theme.lightPrimary};        
        &:focus, &:hover{
            border: 2px solid ${(props: StyledComponentProps) => props.theme.primaryColor};        
        }
    }
    .ant-slider-track{
        background-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        &:focus, &:hover{
            background-color: ${(props: StyledComponentProps) => props.theme.lightPrimary};      
        }
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
        border: 2px solid ${(props: StyledComponentProps) => props.theme.lightPrimary};        
    }
    .ant-slider-mark-text{
        font-size: 11px;
      
    }
    .ant-slider-dot-active{
        border-color: ${(props: StyledComponentProps) => props.theme.lightPrimary};
    }

`
