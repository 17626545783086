import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { FontSizeTP } from 'main/config/theme/ThemeTypes'

interface ITitleAndTextCPProps {
    title: string
    text: string | JSX.Element
    align?: 'center'
    invertBold?: boolean

    size?: FontSizeTP
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

/**
 * COMPONTENTE Exibe um text em negrito como titulo e texto normal ao lado.
 */
export function TitleAndTextCP(props: ITitleAndTextCPProps): JSX.Element {

    return (
        <FlexCP
            justifyContent={props.align}
            marginTop={props.marginTop}
            marginRight={props.marginRight}
            marginBottom={props.marginBottom}
            marginLeft={props.marginLeft}
            flexWrap='wrap'
            className={'n-title-text'}
        >
            <TextCP text={props.title} strong={!props.invertBold} marginRight={5} size={props.size}/>
            {
                typeof props.text === 'string'
                    ? <TextCP text={props.text}  size={props.size} strong={props.invertBold}/>
                    : props.text
            }
        </FlexCP>
    )
}
