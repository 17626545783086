import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { OpportunityStatusLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { OpportunityTypeLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { IPersonOpportunitiesListItemResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonOpportunitiesListItemResponseDTO'
import * as _ from 'lodash'

interface IPersonOpportunityItemICPProps {
    opportunity: IPersonOpportunitiesListItemResponseDTO
    onSelectOpportunity: () => void
}

/**
 * Componente item da lista de oportunidades de um cliente
 */
export function PersonOpportunityItemICP(props: IPersonOpportunityItemICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <CardCP
                margin={'none'}
                innerSpacing={'small'}
                title={
                    <FlexCP justifyContent={'space-between'}>
                        <FlexCP>
                            <TagCP content={OpportunityStatusLabelEnum[props.opportunity.status]}/>
                            <TextCP text={`Oportunidade: ${props.opportunity.code}`} strong={true}/>
                        </FlexCP>
                        <ButtonCP
                            type={'link'}
                            onClick={props.onSelectOpportunity}
                            icon={'eye'}
                        />
                    </FlexCP>
                }
            >
                <TextWrapperSCP>
                    <TextCP text={`Data: ${DateUtils.getFormatted(props.opportunity.creationDate, DateFormatEnum.BR_WITH_TIME_H_M)}`}/>
                    <TextCP text={`Vendedor: ${props.opportunity.userResponsible.name}`}/>
                    <TextCP text={`Etapa: ${OpportunityTypeLabelEnum[props.opportunity.type] as string}`}/>
                    { _.map(props.opportunity.tags, (tag) => <TagCP key={`tag-${tag.code}`} content={tag.name}/>) }
                </TextWrapperSCP>

            </CardCP>
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  margin-bottom: 20px;
`

const TextWrapperSCP = styled.div`
    display:        flex;
    flex-direction: column;
    font-weight:     normal;
    font-size:       13px;
`
