import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'

interface IExpenseAdditionalDataFormICPProps {
    formStateManager: IFormStateManager<FinancialTransactionFormModel>
    isEdit: boolean
}

/**
 * Secao com dados adicionais do mercado.
 */
export function FinancialTransactionAdditionalDataFormICP(props: IExpenseAdditionalDataFormICPProps): JSX.Element {

    return (
        <CollapseCP
            panels={[
                {
                    title: 'Dados adicionais',
                    content: (
                        <RowCP>
                            <ColumnCP size={8}>
                                <InputCP
                                    formStateManager={props.formStateManager}
                                    fieldName={'referenceDate'}
                                    label={'Data de Ref.'}
                                    type={'date'}
                                    allowClear={false}
                                    hint={{
                                        type: 'tooltip',
                                        text: 'Corresponde à data de "compra" dessa despesa, reprogramando o vencimento essa data deveria se manter inalterada. Se ficar em branco ao criar, será utilizada a data de vencimento.'
                                    }}
                                />
                            </ColumnCP>
                            <ColumnCP size={10}>
                                <InputCP
                                    formStateManager={props.formStateManager}
                                    fieldName={'nfeNumber'}
                                    label={'Notas'}
                                />
                            </ColumnCP>
                        </RowCP>
                    ),
                }
            ]}
        />
    )
}
