import _ from 'lodash'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { PhoneValidationUtils } from 'main/common/validation/decorators/phone/PhoneValidationUtils'
import { IconCP } from 'main/common/components/icons/IconCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { InputPhoneCP } from 'main/common/components/form-fields/input-phone/InputPhoneCP'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { OpportunityFormModel } from 'main/modules/sales-funnel/components/opportunity-data-form/inner/OpportunityFormModel'
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IOpportunitySearchByPhoneOrEmailRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySearchByPhoneOrEmailRequestDTO'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'

type FieldTP = 'phone' | 'email'

interface ICPProps {
    formStateManager: IFormStateManager<OpportunityFormModel>
    disabled?: boolean
    type: FieldTP
    fieldName: keyof OpportunityFormModel
    label?: string
    loadedOpportunity?: IOpportunityResponseDTO
    initialData?: string
    placeholder?: string
}

/**
 * COMPONENTE Input de busca por oportunidade aberta identificada email ou telefone.
 */
export function InputOpportunityUniqueFieldsCP(props: ICPProps): JSX.Element {

    const duplicatedMessage = `Já existe uma oportunidade ABERTA com este ${(props.label ?? props.placeholder) as string}`
    const [isDuplicated, setIsDuplicated] = useState<boolean>(false)

    const searchOpportunityRequest = useRequest<IGenericListResponseDTO<INameAndCodeResponseDTO>>()

    useEffect(onRequestChange, [searchOpportunityRequest.isAwaiting])
    useEffect(() => onBlur(props.initialData), [props.initialData])

    /**
     * Busca oportundiade em aberto para um dos campos informados.
     */
    function searchOpportunity(value: string): void {

        const dto: IOpportunitySearchByPhoneOrEmailRequestDTO = {
            phone: props.type === 'phone' ? value : undefined,
            email: props.type === 'email' ? value : undefined,
            status: OpportunityStatusEnum.OPEN,
        }
        searchOpportunityRequest.runRequest(OpportunityRequests.searchByPhoneOrEmail(dto))
    }

    /**
     * Retorno API.
     */
    function onRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchOpportunityRequest, 'Erro ao buscar oportunidade.'))
            return

        const opportunitiesResponse = searchOpportunityRequest.responseData!.list

        // Se nao encontrou ninguem, nao esta duplicado
        if (SystemUtils.isEmpty(opportunitiesResponse)) {
            setIsDuplicated(false)
            return
        }

        // Se nao estiver editando e econtrou alguem, esta duplicado
        if (!props.loadedOpportunity) {
            setIsDuplicated(true)
            return
        }

        // Por fim, apenas seta como duplicado caso tenha encontrado, e se o codigo do que esta editando esta presente nos carregaos
        const oppsWithDifferentCodes = opportunitiesResponse.filter((opp) => opp.code !== props.loadedOpportunity?.code)
        setIsDuplicated(oppsWithDifferentCodes.length > 0)
    }

    /**
     * Ao alterar valor do campo.
     */
    function onChange(nextValue?: string): void {

        // Email nao considera onchange, pois chamada sera no onblur
        if (!nextValue || props.type === 'email' || !hasChanged(nextValue))
            return

        if (props.type === 'phone' && !PhoneValidationUtils.validateBR(nextValue))
            return

        searchOpportunity(nextValue)
    }

    /**
     * OnBlur do campo.
     */
    function onBlur(nextValue?: string): void {
        if (!!nextValue && props.type === 'email' && hasChanged(nextValue))
            searchOpportunity(nextValue)
    }

    /**
     * Auxiliar para ver se o valor do campo foi alterado.
     */
    function hasChanged(nextValue: string): boolean {

        const initialValue = props.type === 'phone'
            ? props.loadedOpportunity?.personCustomer?.phone
            : props.loadedOpportunity?.personCustomer?.email

        return (nextValue !== initialValue)
    }

    if (props.type === 'phone') {
        return (
            <InputPhoneCP
                label={props.label}
                fieldName={props.fieldName}
                onChange={(_.debounce(onChange, 500))}
                formStateManager={props.formStateManager}
                loading={searchOpportunityRequest.isAwaiting}
                disabled={props.disabled}
                prefix={true}
                icon={
                    isDuplicated
                        ?
                        <TooltipCP showSpan={true} text={duplicatedMessage}>
                            <IconCP antIcon={'warning'} color={ThemeAnt.errorColor}/>
                        </TooltipCP>
                        :
                        <FontAwsomeIconCP type={faPhone} color={ThemeAnt.primaryColor}/>
                }
                placeholder={props.placeholder}
                errorMessage={isDuplicated ? duplicatedMessage : undefined}
            />
        )
    }

    return (
        <InputCP<OpportunityFormModel>
            placeholder={props.placeholder}
            label={props.label}
            fieldName={props.fieldName}
            onChange={(_.debounce(onChange, 500))}
            onBlur={onBlur}
            formStateManager={props.formStateManager}
            loading={searchOpportunityRequest.isAwaiting}
            type={'email'}
            disabled={props.disabled}
            prefix={true}
            errorMessage={isDuplicated ? duplicatedMessage : undefined}
            icon={
                isDuplicated
                    ?
                    <TooltipCP showSpan={true} text={duplicatedMessage}>
                        <IconCP antIcon={'warning'} color={ThemeAnt.errorColor}/>
                    </TooltipCP>
                    :
                    <FontAwsomeIconCP type={faAt} color={ThemeAnt.primaryColor}/>
            }
        />
    )
}
