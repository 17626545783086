import React, { useState } from 'react'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { LinkBoxICP } from 'main/common/components/popup-link-box/inner/LinkBoxICP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ButtonTypeTP } from 'main/common/components/button/inner/ButtonTypeTP'
import { ButtonSizeTP } from 'main/common/components/button/inner/ButtonSizeTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
    chargeUrl: string
    buttonType: ButtonTypeTP
    buttonSize?: ButtonSizeTP
    hideText?: boolean
}

/**
 * Botao com POPOVER para exibir links de pagamento de uma fatura
 */
export function ButtonChargeVirtualLinksCP(props: ICPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
    
    return (
        <PopOverCP
            visible={isPopoverVisible}
            content={
                props.chargeUrl
                    ?
                    <LinkBoxICP
                        link={props.chargeUrl}
                        onCopy={() => setIsPopoverVisible(false)}
                        fontSize={'small'}
                        shouldShowOpenNewTab={true}
                    />
                    :
                    <TextCP text={'Nenhum link disponível'}/>
            }
            onClose={() => setIsPopoverVisible(false)}
            placement={'bottom'}
        >
            <ButtonCP
                type={props.buttonType}
                onClick={() => setIsPopoverVisible(true)}
                icon={'link'}
                size={props.buttonSize}
            >
                { props.hideText ? undefined : 'Link de Pag.' }
            </ButtonCP>
        </PopOverCP>
    )
}
