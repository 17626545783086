import { ITimelineItem, TimelineCP } from 'main/common/components/timeline/TimelineCP'
import { PersonContactUtils } from 'main/modules/people/utils/PersonContactUtils'
import React from 'react'
import styled from 'styled-components'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import { PersonContactTimelineItemICP } from 'main/modules/people/components/timeline-person-contacts/inner/PersonContactTimelineItemICP'
import { TextActivityDescriptionCP } from 'main/modules/sales-funnel/components/text-activity-description/TextActivityDescriptionCP'
import * as _ from 'lodash'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import {
    PersonContactStatusEnum,
    PersonContactStatusLabelEnum
} from 'main/modules/people/services/person-contact/enums/PersonContactStatusEnum'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'

interface IOpportunityActivityGroupedTimelineICPProps {
    activities: IPersonContactResponseDTO[]
    onActionsDone: (conversionType?: OpportunityDrawerActionTP) => void
    entityCode: number
    entity: PersonContactEntityEnum
}

/**
 * Componente contendo linha do tempo de Atividades de uma OPORTUNIDADE
 */
export function PersonContactGroupedTimelineICP(props: IOpportunityActivityGroupedTimelineICPProps): JSX.Element {

    /**
     * Metodo principal para renderizar a timeline de atividades agrupadas pelo codigo.
     */
    function renderTimelineGrouped(): JSX.Element[] {

        if (_.isEmpty(props.activities))
            return [<EmptyCP key={'empty'} description={'Nenhuma atividade encontrada'}/>]

        return Object.values(PersonContactStatusEnum).map((status) => {
            return renderTimelineGroupedBlock(
                PersonContactStatusLabelEnum[status],
                props.activities.filter((item) => item.status === status)
            )
        })
    }

    /**
     * Auxiliar com o conteudo de cada bloco de timeline
     */
    function renderTimelineGroupedBlock(groupTitle: string, activitiesGrouped: IPersonContactResponseDTO[]): JSX.Element {

        // Monta os itens da timeline
        const _timelineItens: ITimelineItem[] = activitiesGrouped.map(activity => ({
            color: PersonContactUtils.getActivityStatusColor(activity),
            dot: <TextActivityDescriptionCP activity={activity}/>,
            position: 'left',
            content: (
                <PersonContactTimelineItemICP
                    activity={activity}
                    onActionsDone={props.onActionsDone}
                    entityCode={props.entityCode}
                    entity={props.entity}
                />
            )
        }))

        // Retorna elementos com cada item
        return (
            <WrapperSCP>
                <TaskHeaderSCP>
                    { groupTitle }
                </TaskHeaderSCP>
                <TimelineCP
                    items={_timelineItens}
                />
            </WrapperSCP>
        )
    }

    return (
        <>
            { renderTimelineGrouped() }
        </>
    )

}

const TaskHeaderSCP = styled.div`
    font-weight: 600;
    font-size: 12px;
    padding: 3px;
    margin: 25px 0 15px 0;
`

const WrapperSCP = styled.div`
  
  margin-top: 30px;
  
  .ant-timeline {
    padding: 0 10px;
  }
`