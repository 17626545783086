import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { PersonReferralsTP } from 'main/modules/people/services/person/types/PersonReferralsTP'
import {
    PersonReferralPhaseEnum,
    PersonReferralPhaseLabelEnum
} from 'main/modules/people/services/person/enums/PersonReferralPhaseEnum'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import styled from 'main/config/theme/styledWithTheme'

interface IPersonOpportunityItemICPProps {
    referral: PersonReferralsTP
}

/**
 * Componente item da lista de oportunidades de um cliente
 */
export function PersonReferralItemICP(props: IPersonOpportunityItemICPProps): JSX.Element {

    let color: TagCPColorTP
    let icon: string | undefined = undefined
    switch (props.referral.referralPhase) {

        case PersonReferralPhaseEnum.LEAD:
            color = 'darkOrange'
            break

        case PersonReferralPhaseEnum.EVALUATION:
            color = 'lightBlue'
            break

        case PersonReferralPhaseEnum.EVALUATION_DONE:
            color = 'blue'
            icon = 'flag'
            break

        case PersonReferralPhaseEnum.SALE:
            color = 'green'
            icon = 'star'
            break

        default:
            color = 'red'
            break
    }

    return (
        <WrapperSCP>
            <TextCP text={props.referral.referral.name} strong={true}/>
            <TagCP
                content={
                    <FlexCP alignItems={'center'}>
                        <ConditionalRenderCP shouldRender={!!icon}>
                            <IconCP antIcon={icon!}/>
                        </ConditionalRenderCP>
                        <TextCP text={PersonReferralPhaseLabelEnum[props.referral.referralPhase]} marginLeft={5}/>
                    </FlexCP>
                }
                color={color}
            />
        </WrapperSCP>

    )
}

const WrapperSCP = styled.div`
  margin-bottom: 20px;
  padding: 20px 10px;
  border: solid 1px #E8E8E8;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`
