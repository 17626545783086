import { ContextFactory } from 'main/common/context-factory/ContextFactory'
import { ExpressionUnitListUtils } from 'main/modules/segment/components/segment-expression-bar/inner/ExpressionUnitListUtils'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { SegmentAggUnitForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggUnitForEditionTP'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'

type ActionTypeTP = 'initUnitList' | 'addUnit' | 'editUnit' | 'removeUnit' | 'setIsRemoveBtnHovered'

type StateTP = {
    units: Array<SegmentEditableItemTP<SegmentAggUnitForEditionTP>>,
    isRemoveBtnHovered: boolean,
}

const INITIAL_STATE: StateTP = { units: [], isRemoveBtnHovered: false }

const actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP> = actionHelper => ({

    setIsRemoveBtnHovered: (isRemoveBtnHovered: boolean) => actionHelper.replaceWithPayload({ isRemoveBtnHovered }),
    addUnit: () => actionHelper.replaceWithPayload({ units: ExpressionUnitListUtils.addUnit(actionHelper.state.units) }),
    removeUnit: (id: string) => actionHelper.replaceWithPayload({ units: ExpressionUnitListUtils.removeUnit(actionHelper.state.units, id) }),

    initUnitList: (units: Array<SegmentEditableItemTP<SegmentAggUnitForEditionTP>>) =>
        actionHelper.replaceWithPayload({ units: ExpressionUnitListUtils.init(actionHelper.state.units, units) }),

    editUnit: (nextValue: SegmentEditableItemTP<SegmentAggUnitForEditionTP>) =>
        actionHelper.replaceWithPayload({ units: ExpressionUnitListUtils.editUnit(actionHelper.state.units, nextValue) }),
})

/**
 * CONTEXTO: Agregador de expressao de lista de segmentacao
 * Provider + Hook para acesso ao contexto de estado global de 01 agregador de expressao de lista de segmentacao em edicao.
 * @see AggregatorICP
 */
export const AggregatorContext = ContextFactory.getOne<StateTP, ActionTypeTP>({
    contextName: 'Agregador (Lista de Segmentacao)',
    actionBuilder,
    initialStateGetter: () => INITIAL_STATE,
})
