import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ImportUpdateBehaviorEnum } from 'main/common/enums/ImportUpdateBehaviorEnum'
import { ImportUtils } from 'main/common/utils/ImportUtils'
import styled from 'main/config/theme/styledWithTheme'
import React from 'react'

interface IStepImportBehaviorICPProps {
    onBehaviorSelected: (behavior: ImportUpdateBehaviorEnum) => void
    currentBehavior?: ImportUpdateBehaviorEnum
    disabled?: boolean
}

/**
 * COMPONENTE
 * Area de definicao da acao a ser tomada em caso de deteccao de registros duplicados durante importacao de pessoas.
 */
export function StepImportBehaviorICP(props: IStepImportBehaviorICPProps): JSX.Element {
    return (
        <>
            <TitleSCP>
                <TextCP
                    wrap={true}
                    strong={true}
                    text={'1. O que fazer quando encontrarmos uma pessoa com o mesmo e-mail ou CPF?'}
                />
            </TitleSCP>

            <SelectionWrapperSCP>
                <RadioGroupCP
                    selected={props.currentBehavior}
                    onChange={props.onBehaviorSelected}
                    buttonHeight={26}
                    disabled={props.disabled}
                    type={'button'}
                    options={[
                        {
                            value: ImportUpdateBehaviorEnum.OVERWRITE,
                            content: <TextCP text={ImportUtils.getImportBehaviorLabel(ImportUpdateBehaviorEnum.OVERWRITE)}/>,
                            tooltip: 'Se a pessoa já existir, atualiza todos os campos dela'
                        },
                        {
                            value: ImportUpdateBehaviorEnum.UPDATE_BLANK,
                            content: <TextCP text={ImportUtils.getImportBehaviorLabel(ImportUpdateBehaviorEnum.UPDATE_BLANK)}/>,
                            tooltip: 'Se a pessoa já existir, atualiza apenas os campos que estiver em branco atualmente'
                        },
                        {
                            value: ImportUpdateBehaviorEnum.SKIP,
                            content: <TextCP text={ImportUtils.getImportBehaviorLabel(ImportUpdateBehaviorEnum.SKIP)}/>,
                            tooltip: 'Se a pessoa já existir, não faz nada. Pula para a próxima linha'
                        }
                    ]}
                />
            </SelectionWrapperSCP>
        </>
    )
}

const TitleSCP = styled.div`
    margin-bottom: 10px;
`

const SelectionWrapperSCP = styled.div`
    width: 100%;
    padding-top: 5px;
    display: flex;
    justify-content: center;
`
