import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'

export class ScheduleShotFormValidator extends FormModel {

    @IsNotEmpty()
    date: Date

    @IsNotEmpty()
    hour: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
