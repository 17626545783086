import { TreeItemTP } from 'main/common/components/tree/inner/TreeItemTP'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { FinancialCategoryTreeTitleItemICP } from 'main/modules/financial/components/tree-financial-category/inner/FinancialCategoryTreeTitleItemICP'
import React from 'react'
import { IFinancialCategoryResponseDTO } from 'main/modules/financial/services/financial-category/response/IFinancialCategoryResponseDTO'
import { IFinancialCategoryUpdateItemRequestDTO } from 'main/modules/financial/services/financial-category/request/IFinancialCategoryUpdateItemRequestDTO'

/**
 * UTILS da arvore de plano de contas
 */
export const FinancialCategoryTreeUtils = {

    /**
     * Reordena a arvore
     * @param treeItemsToIterate Arvore 'filha' que sera iterada, utilizada para a recursividade.
     * @param treeItems Arvore final que sera atualizada por referencia
     * @param onEditCategory Funcao ao editar um plano de contas.
     * @param onAddCategory Funcao ao criar um plano de contas abaixo do selecionado.
     * @param onDelete
     * @param fullOrder Order total acumulada.
     */
    reorderTree(
        treeItemsToIterate: Array<TreeItemTP<IFinancialCategoryResponseDTO>>,
        treeItems: Array<TreeItemTP<IFinancialCategoryResponseDTO>>,
        onEditCategory: (code: number) => void,
        onAddCategory: (code: number) => void,
        onDelete: () => void,
        fullOrder?: string
    ): void {

        treeItemsToIterate.forEach((treeItem, index) => {

            // Monta o numero acumulado com o do pai
            let orderStr = !!fullOrder ? `${fullOrder}.` : ''
            orderStr += `${index + 1}`

            // Chama recursivo
            let childrenOrganized: TreeItemTP[] | undefined
            if (!ArrayUtils.isEmpty(treeItem.children)) {
                childrenOrganized = []
                FinancialCategoryTreeUtils.reorderTree(treeItem.children!, childrenOrganized, onEditCategory, onAddCategory, onDelete, orderStr)
            }

            treeItems.push({
                title:
                    <FinancialCategoryTreeTitleItemICP
                        category={treeItem.data}
                        showAddButton={true}
                        onAdd={() => onAddCategory(treeItem.data.code)}
                        onEdit={() => onEditCategory(treeItem.data.code)}
                        onRemove={onDelete}
                    />,
                key: treeItem.key,
                selectable: false,
                data: treeItem.data,
                children: childrenOrganized,
            })
        })

    },

    /**
     * Monta o item da arvore baseado no retorno da API.
     * @param financialCategories Plano de contas vindo da API.
     * @param treeItems Arvore final que sera atualizada por referencia
     * @param onEditCategory Funcao ao editar um plano de contas.
     * @param onAddCategory Funcao ao criar um plano de contas abaixo do selecionado.
     * @param onDelete
     * @param parentCode Codigo do pai, utilizado na recursividade.
     */
    mountTreeItem(
        financialCategories: IFinancialCategoryResponseDTO[],
        treeItems: Array<TreeItemTP<IFinancialCategoryResponseDTO>>,
        onEditCategory: (code: number) => void,
        onAddCategory: (code: number) => void,
        onDelete: () => void,
        parentCode: number | null,
    ): void {

        financialCategories.filter((category) => category.parentCategoryCode === parentCode).forEach((category) => {

            // Chama recursivo
            const childrenOrganized: TreeItemTP[] = []
            FinancialCategoryTreeUtils.mountTreeItem(financialCategories, childrenOrganized, onEditCategory, onAddCategory, onDelete, category.code)

            treeItems.push({
                title:
                    <FinancialCategoryTreeTitleItemICP
                        category={category}
                        showAddButton={true}
                        onAdd={() => onAddCategory(category.code)}
                        onEdit={() => onEditCategory(category.code)}
                        onRemove={onDelete}
                    />,
                key: `${category.code}`,
                selectable: false,
                data: category,
                children: childrenOrganized,
            })
        })
    },

    /**
     * Formata a arvore para salvar reordenacao
     * @param treeItemsToIterate Arvore 'filha' que sera iterada, utilizada para a recursividade.
     * @param dtoReordered Arvore final que sera atualizada por referencia
     * @param parentCode Codigo do pai.
     * @param fullOrder Order total acumulada.
     */
    mountReorderDto(
        treeItemsToIterate: TreeItemTP[],
        dtoReordered: IFinancialCategoryUpdateItemRequestDTO[],
        parentCode?: number,
        fullOrder?: string
    ): void {

        treeItemsToIterate.forEach((treeItem, index) => {

            // Monta o numero acumulado com o do pai
            let orderStr = !!fullOrder ? `${fullOrder}.` : ''
            orderStr += `${index + 1}`

            // Chama recursivo
            if (!ArrayUtils.isEmpty(treeItem.children))
                FinancialCategoryTreeUtils.mountReorderDto(treeItem.children!, dtoReordered, treeItem.data.code, orderStr)

            dtoReordered.push({
                code: treeItem.data.code,
                order: orderStr,
                parentCode,
            })

        })

    },

}
