import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MktPlanTableUtils } from 'main/modules/marketing/components/mkt-plan-table/inner/MktPlanTableUtils'
import { IMarketingRecurrentPlanResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingRecurrentPlanResponseDTO'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

/**
 * COMPONENTE da listagem de plano de marketing.
 *
 * @author renatofs
 */
export function MktPlanTableCP(): JSX.Element | null {

    useEffect(() => { definePlans() }, [])

    const [plans, setPlans] = useState<IMarketingRecurrentPlanResponseDTO[]>()
    const getPlansRequest = useRequest<IGenericListResponseDTO<IMarketingRecurrentPlanResponseDTO>>()
    useEffect(onGetPlansRequestChange, [getPlansRequest.isAwaiting])

    /**
     * Faz a requisicao para buscar planos
     */
    async function definePlans(): Promise<void> {
        getPlansRequest.runRequest(await MarketingRequests.getPlanSearchConfig({ active: true }))
    }

    /**
     * Retorno da requisicao que busca planos.
     */
    function onGetPlansRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPlansRequest, 'Não foi possível obter planos'))
            return

        setPlans(getPlansRequest.responseData!.list)
    }

    return (
        <TableCPOLD2
            columns={MktPlanTableUtils.getColumnsConfig()}
            data={plans}
            loading={getPlansRequest.isAwaiting}
            showLoadingAsSkeleton={true}
            showHeader={true}
        />
    )
}
