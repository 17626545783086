import { ModalCP } from 'main/common/components/modal/ModalCP'
import { TableValidationErrorsICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/TableValidationErrorsICP'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import React from 'react'

interface IModalLeadImportErrorsICPProps {
    visible: boolean
    errors: ImportValidationErrorsLineTP[]
    onClose: () => void
}

/**
 * COMPONENTE
 * Modal para exibicao de falhas de validacao em arquivos .csv para importacao .
 */
export function ModalLeadImportErrorsICP(props: IModalLeadImportErrorsICPProps): JSX.Element {
    return (
        <ModalCP
            visible={props.visible}
            title={'Inconsistências do arquivo'}
            onOk={props.onClose}
            onClose={props.onClose}
            singleButton={true}
            width={'700px'}
            top={40}
            height={450}
        >
            <TableValidationErrorsICP errors={props.errors}/>
        </ModalCP>
    )
}
