import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { ISaleProductItem } from 'main/modules/sale/components/sale-item-table/inner/interfaces/ISaleProductItem'

export class SaleProductDataFormModel extends GenericFormValidator {

    totalValue: number

    products: ISaleProductItem[]

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
