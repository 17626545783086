import { MarketingContentVarsConfigTP } from 'main/modules/marketing/types/MarketingContentVarsConfigTP'

export const SessionVariablesTP: MarketingContentVarsConfigTP[] = [
    {
        label: 'Nome',
        value: '#nome##'
    },
    {
        label: 'Unidade',
        value: '#unidade##'
    },
    {
        label: 'Telefone da Unidade',
        value: '#telefone_unidade##'
    },
    {
        label: 'Horário da Sessão',
        value: '#data_hora_sessao##'
    },
    {
        label: 'Link de Confirmação de Presença',
        value: '#url_confirmacao_presenca##'
    },
]
