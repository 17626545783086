import { faCheckDouble, faEye, faMousePointer } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ShotListItemContactColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemContactColumnICP'
import { ShotListItemDateColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemDateColumnICP'
import { ShotListItemEmailInfoColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemEmailInfoColumnICP'
import { ShotListItemStatisticColumnICP } from 'main/modules/marketing/components/shot-list-item/inner/ShotListItemStatisticColumnICP'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import React from 'react'
import styled from 'styled-components'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotEmailListColumnsICPProps {
    shot: IMarketingCampaignEmailResponseDTO
    semegmentList: INameAndCodeResponseDTO[]
}

/**
 *
 */
export function ShotEmailListColumnsICP(props: IShotEmailListColumnsICPProps): JSX.Element {

    return (
        <>
            <ShotListItemDateColumnICP
                scheduleDate={props.shot.scheduleDate}
                startDate={props.shot.startDate}
                status={props.shot.status}
            />
            <WrapperSCP>
                <ShotListItemEmailInfoColumnICP
                    shot={props.shot}
                />
                <ShotListItemContactColumnICP
                    destination={props.shot.destination}
                    totalContacts={props.shot.totalContacts}
                    semegmentList={props.semegmentList}
                />
            </WrapperSCP>

            {
                (props.shot.status === MarketingShotStatusEnum.ERROR || props.shot.status === MarketingShotStatusEnum.SENT) &&
                <>
                    <ShotListItemStatisticColumnICP
                        total={props.shot.totalDelivered ?? 0}
                        icon={<FontAwsomeIconCP type={faCheckDouble} color={ThemeAnt.primaryColor}/>}
                        percent={MathUtils.getPercentage(props.shot.totalDelivered, props.shot.totalContacts)}
                        label={'RECEBERAM'}
                    />

                    <ShotListItemStatisticColumnICP
                        total={props.shot.totalOpened ?? 0}
                        icon={<FontAwsomeIconCP type={faEye} color={ThemeAnt.primaryColor}/>}
                        percent={MathUtils.getPercentage(props.shot.totalOpened, props.shot.totalDelivered)}
                        label={'VISUALIZARAM'}
                    />
                    <ShotListItemStatisticColumnICP
                        total={props.shot.totalClicked ?? 0}
                        icon={<FontAwsomeIconCP type={faMousePointer} color={ThemeAnt.primaryColor}/>}
                        percent={MathUtils.getPercentage(props.shot.totalClicked, props.shot.totalOpened)}
                        label={'CLICARAM'}
                    />
                </>
            }
        </>
    )

}

const WrapperSCP = styled.div`
  display: flex;
  width: 400px;
`
