import { NewCampaingShotScreenContent } from 'main/modules/marketing/components/new-campaing-shot-screen-content/NewCampaingShotScreenContent'
import { NewShotScreenRouteParamsTP } from 'main/modules/marketing/types/NewShotScreenRouteParamsTP'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

/** Nome do parametro de query para codigo do segmento. */
ScreenNewShot.SEGMENT_CODE_PARAM = 'segment'

/**
 * TELA do passo a passo para novo disparo.
 *
 * @todo: Criar hook 'useQueryParams'
 *
 * @author renatofs
 * @author hjcostabr
 */
export function ScreenNewShot(): JSX.Element {

    const [segmentCode, setSegmentCode] = useState<number>()

    const routeParams = useParams<NewShotScreenRouteParamsTP>()
    const routeLocation = useLocation()

    useEffect(catchSegmentCode, [])

    function catchSegmentCode(): void {
        const segmentCodeParam = new URLSearchParams(routeLocation.search).get(ScreenNewShot.SEGMENT_CODE_PARAM)
        if (segmentCodeParam)
            setSegmentCode(+segmentCodeParam)
    }

    return (
        <ContentSCP>
            <NewCampaingShotScreenContent
                initialSegmentCode={segmentCode}
                type={routeParams.shotType}
            />
        </ContentSCP>
    )

}

const ContentSCP = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
`
