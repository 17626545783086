import { ModalCP } from 'main/common/components/modal/ModalCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IOpportunityBulkChangeResponsibleRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunityBulkChangeResponsibleRequestDTO'
import React, { useEffect, useState } from 'react'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'

interface ICPProps {
    showModal: boolean
    closeModal: () => void
    selectedOpportunities: number[]
    onActionsDone: () => void
}

/**
 * Modal de configuração das etapas do funil na etapa de Follow-UP
 */
export function ModalChangeOpportunityResponsibleCP(props: ICPProps): JSX.Element {

    const [selectedUserCode, setSelectedUserCode] = useState<number>()

    const changeResponsibleRequest = useRequest()
    useEffect(onChangeResponsibleRequestChange, [changeResponsibleRequest.isAwaiting])

    /**
     * Atualiza o responsavel.
     */
    function updateResponsible(): void{

        if (!selectedUserCode)
            return

        const dto: IOpportunityBulkChangeResponsibleRequestDTO = {
            newPersonResponsible: selectedUserCode,
            opportunityCodes: props.selectedOpportunities
        }
        changeResponsibleRequest.runRequest(OpportunityRequests.changeResponsible(dto))
    }

    /**
     * Retorno da API.
     */
    function onChangeResponsibleRequestChange(): void{

        if (!RequestUtils.isValidRequestReturn(changeResponsibleRequest, 'Erro ao atribuir novo responsável'))
            return

        NotificationHelper.success('Pronto!', 'Responsável alterado com sucesso!')
        props.onActionsDone()
    }

    return (
        <ModalCP
            title={'Atribuir novo responsável'}
            width={400}
            visible={props.showModal}
            onCancel={props.closeModal}
            onOk={updateResponsible}
            actionLoading={changeResponsibleRequest.isAwaiting}
        >
            <AutocompleteUserCP
                label={'Selecione o novo responsável'}
                onChange={setSelectedUserCode}
            />
        </ModalCP>
    )
}
