import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { TreatmentFormResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/TreatmentFormResponseDTO'
import { TreatmentFormRequests } from 'main/modules/admin/services/treatment-form/TreatmentFormRequests'
import { TreatmentFormsTableUtils } from 'main/modules/admin/components/table-treatment-forms/inner/TreatmentFormsTableUtils'
import { TableTreatmentFormsRowExpandedICP } from 'main/modules/admin/components/table-treatment-forms/inner/TableTreatmentFormsRowExpandedICP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

interface ICPProps {
    onEdit: (code: number) => void
    forceLoadList: boolean
    onListLoaded: () => void
}

/**
 * Listagem de cargos e funcoes.
 */
export function TableTreatmentFormsCP(props: ICPProps): JSX.Element {

    useEffect(init, [props.forceLoadList])

    const [treatmentForms, setTreatmentForms] = useState<TreatmentFormResponseDTO[]>()

    const searchTreatmentFormsRequest = useRequest<IGenericListResponseDTO<TreatmentFormResponseDTO>>()
    useEffect(onSearchTreatmentFormsRequestChange, [searchTreatmentFormsRequest.isAwaiting])

    const deleteBankAccountRequest = useRequest<void>('none')
    useEffect(onDeleteTreatmentFormRequestChange, [deleteBankAccountRequest.isAwaiting])

    /**
     * Obtem listagem dos formularios.
     */
    function init(): void {

        if (!props.forceLoadList)
            return

        getTreatmentForms()
    }

    /**
     * Obtem lista de cargos da API.
     */
    function getTreatmentForms(): void {
        searchTreatmentFormsRequest.runRequest(TreatmentFormRequests.search())
    }

    /**
     * Retorno da requisicao.
     */
    function onSearchTreatmentFormsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchTreatmentFormsRequest, 'Não foi possível obter lista dos formulários.'))
            return

        setTreatmentForms(searchTreatmentFormsRequest.responseData!.list)
        props.onListLoaded()
    }

    /**
     * Remover.
     */
    function deleteTreatmentForm(code: number): void {
        deleteBankAccountRequest.runRequest(TreatmentFormRequests.delete(code))
    }

    /**
     *  Retorno da remocao.
     */
    function onDeleteTreatmentFormRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteBankAccountRequest, 'Não foi remover a conta bancária', true))
            return

        NotificationHelper.success('Pronto!', 'Formulário removido')
        getTreatmentForms()
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableCPOLD2<TreatmentFormResponseDTO>
                data={treatmentForms}
                loading={searchTreatmentFormsRequest.isAwaiting || deleteBankAccountRequest.isAwaiting}
                columns={TreatmentFormsTableUtils.getTableColumns(props.onEdit, deleteTreatmentForm)}
                expandedRowRender={(item) => <TableTreatmentFormsRowExpandedICP treatmentForm={item}/>}
            />
        </CardCP>
    )
}
