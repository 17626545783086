import { IOpportunityEvaluationScheduleSaveRequestDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/request/IOpportunityEvaluationScheduleSaveRequestDTO'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IOpportunityEvaluationScheduleSearchRequestDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/request/IOpportunityEvaluationScheduleSearchRequestDTO'

/**
 * REQUISICOES relacionadas ao FUNIL DE VENDAS -> AVALIAÇÕES
 */
export class OpportunityEvaluationRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/opportunities`

    static getScheduleConfig = (evaluationScheduleCode: number): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/evaluations/schedules/${evaluationScheduleCode}`,
        method: HttpMethodEnum.GET,
    })

    static searchSchedulesConfig = (opportunityCode: number, dto?: IOpportunityEvaluationScheduleSearchRequestDTO): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/${opportunityCode}/evaluations/schedules`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchAllSchedulesConfig = (dto: IOpportunityEvaluationScheduleSearchRequestDTO): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/evaluations/schedules`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static createScheduleConfig = (opportunityCode: number, dto: IOpportunityEvaluationScheduleSaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/${opportunityCode}/evaluations/schedules`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static updateScheduleConfig = (evaluationScheduleCode: number, dto: IOpportunityEvaluationScheduleSaveRequestDTO): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/evaluations/schedules/${evaluationScheduleCode}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static markAsDoneConfig = (evaluationScheduleCode: number): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/evaluations/schedules/${evaluationScheduleCode}/mark-as-done`,
        method: HttpMethodEnum.PUT,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${OpportunityEvaluationRequests._MODULE_BASE}/evaluations/schedules/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}

