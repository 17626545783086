import React from 'react'
import { PhotoProvider, PhotoSlider } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { faDownload, faSearchMinus, faSearchPlus, faUndo } from '@fortawesome/free-solid-svg-icons'
import { PdfMozillaViewer } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-mozilla-viewer/PdfMozillaViewer'

export type ImageTP = {
    src: string,
    key: number,
    type: string,
}

interface ICPProps {
    images: ImageTP[]
    visible: boolean
    onClose: () => void
    currentIndex?: number
    onDownload: (index: number) => void
}

/**
 */
export function ViewerImageCP(props: ICPProps): JSX.Element {

    return (
        <PhotoProvider>
            <PhotoSlider
                toolbarRender={({ rotate, onRotate, scale, onScale, index }) => {

                    const isPdf = props.images[index].type.toLowerCase() === 'pdf'
                    return (
                        <>
                            <FontAwsomeIconCP type={faDownload} size={'lg'} style={{ marginRight: 10 }} onClick={() => props.onDownload(index)}/>
                            {
                                !isPdf &&
                                <>
                                    <FontAwsomeIconCP type={faUndo} size={'lg'} style={{ marginRight: 10 }} onClick={() => onRotate(rotate + 90)}/>
                                    <FontAwsomeIconCP type={faSearchPlus} size={'lg'} style={{ marginRight: 10 }} onClick={() => onScale(scale + 1)}/>
                                    <FontAwsomeIconCP type={faSearchMinus} size={'lg'} style={{ marginRight: 20 }} onClick={() => onScale(scale - 1)}/>
                                </>
                            }
                        </>
                    )
                }}
                images={props.images}
                visible={props.visible}
                brokenElement={(element) => {

                    // Se der errado entende que é PDF
                    return (
                        <div style={{ width: '1000px' }}>
                            <PdfMozillaViewer
                                pdfSrc={element.src}
                            />
                        </div>
                )}}
                onClose={() => props.onClose()}
            />
        </PhotoProvider>
    )
}
