import moment, { Moment } from 'moment'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { ISalesPerPeriodReportListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ISalesPerPeriodReportListItemResponseDTO'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { SalesPerPeriodReportTypeEnum } from 'main/modules/dashboard/services/enums/SalesPerPeriodReportTypeEnum'

/**
 * UTILITARIO
 * @todo getXAxisDates e getYAxisCounts iguais as ja existente no arquivo BalancePerPeriodChartUtils. Unificar
 */
export const SalesPerPeriodChartUtils = {

    /** Itera no intervalo de datas montando array com todas elas baseado na periodicidade (Adiciona meses/dias/etc.). */
    getXAxisDates(range: IDateRangeFilter, timeBase: TimeBaseEnum): Moment[] {

        const dates: Moment[] = []
        const endDate = moment(range.endDate)
        let nextDate = moment(range.beginDate)

        while (moment(nextDate).isBefore(endDate)) {
            dates.push(moment(nextDate))
            nextDate = moment(nextDate.add(1, timeBase))
        }

        return dates
    },

    /** Gera & retorna vetor de dados para 'eixo y' (serie) do grafico. */
    getYAxisCounts(dates: Moment[], byPeriodList: ISalesPerPeriodReportListItemResponseDTO[]): number[] {

        return dates.map(date => {
            const totalByPeriod = byPeriodList
                .find((period: ISalesPerPeriodReportListItemResponseDTO) => {
                    const labelDateTime = date.startOf(TimeBaseEnum.DAY).toDate().getTime()
                    const periodDateTime = moment(period.date).startOf(TimeBaseEnum.DAY).toDate()
                        .getTime()
                    return (labelDateTime === periodDateTime)
                })
            return !!totalByPeriod ? totalByPeriod.balance : 0
        })
    },

    getChartLabel(view: SalesPerPeriodReportTypeEnum): string {

        switch (view) {
            case SalesPerPeriodReportTypeEnum.TOTAL:
                return 'Total de Vendas'

            case SalesPerPeriodReportTypeEnum.AVERAGE:
                return 'Média vendida por franquia'

            default:
                return ''
        }

    },

    getViewTypeOptionLabel(view: SalesPerPeriodReportTypeEnum): string {

        switch (view) {
            case SalesPerPeriodReportTypeEnum.TOTAL:
                return 'Soma'

            case SalesPerPeriodReportTypeEnum.AVERAGE:
                return 'Média'

            default:
                return ''
        }
    }

}