import { Icon } from 'antd'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'
import { TimePickerCP } from 'main/common/components/timepicker/TimePickerCP'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { styled } from 'main/config/theme/styledWithTheme'
import { EvaluationFormModel } from 'main/modules/sales-funnel/components/drawer-evaluation-data/inner/EvaluationFormModel'
import moment from 'moment'
import React from 'react'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { OpportunityScheduleTypeEnum, OpportunityScheduleTypeLabelEnum } from 'main/modules/sales-funnel/services/evaluation/enums/OpportunityScheduleTypeEnum'

const DEFAULT_EVALUATION_DURATION = 15

interface ICPProps {
    formStateManager: IFormStateManager<EvaluationFormModel>
}

/**
 * Drawer para agendar uma avaliação.
 */
export function EvaluationFormDataICP(props: ICPProps): JSX.Element {

    return (
        <>
            <FlexCP justifyContent={'center'} marginBottom={40}>
                <RadioGroupCP<OpportunityScheduleTypeEnum>
                    formStateManager={props.formStateManager}
                    fieldName={'scheduleType'}
                    buttonHeight={32}
                    buttonWidth={180}
                    secondary={true}
                    type={'button'}
                    options={
                        Object.values(OpportunityScheduleTypeEnum).map((scheduleType) => ({
                            content: OpportunityScheduleTypeLabelEnum[scheduleType],
                            value: scheduleType,
                        }))
                    }
                />
            </FlexCP>

            <AutocompleteUserCP
                showOptionsOnLoad={true}
                formStateManager={props.formStateManager}
                fieldName={'responsibleCode'}
                label={'Avaliadora'}
                required={true}
            />

            <FlexCP justifyContent={'space-between'}>
                <InputCP
                    label={'Data'}
                    required={true}
                    type={'date'}
                    fieldName={'date'}
                    formStateManager={props.formStateManager}
                />
                <TimeWrapperSCP>
                    <TimePickerCP
                        formStateManager={props.formStateManager}
                        fieldName={'beginTime'}
                        onChange={(time) => {
                            if (!!time)
                                props.formStateManager.changeFieldValue('endTime', moment(time).add(DEFAULT_EVALUATION_DURATION, TimeBaseEnum.MINUTE))
                        }}
                        suffixIcon={<Icon type={'clock-circle'}/>}
                    />
                </TimeWrapperSCP>
                <TimeWrapperSCP>
                    <TimePickerCP
                        margin={'0px'}
                        formStateManager={props.formStateManager}
                        fieldName={'endTime'}
                        suffixIcon={<Icon type={'clock-circle'}/>}
                    />
                </TimeWrapperSCP>
            </FlexCP>

            <TextAreaCP
                minRows={5}
                maxRows={5}
                label={'Observações'}
                fieldName={'notes'}
                value={props.formStateManager.getFieldValue('notes')}
                formStateManager={props.formStateManager}
            />

            <SwitchCP
                isTextInside={false}
                textOutside={'Presença confirmada'}
                formStateManager={props.formStateManager}
                fieldName={'presenceConfirmation'}
                marginTop={30}
            />
        </>
    )
}

const TimeWrapperSCP = styled.div`
  margin-top: 32px;
`
