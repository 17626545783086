import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import React, { useEffect, useState } from 'react'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { TagTreatmentGroupCP } from 'main/modules/admin/components/tag-treatment-group/TagTreatmentGroupCP'
import styled from 'styled-components'
import { ModalUserTreatmentGroupCP } from 'main/modules/people/components/modal-user-treatment-group/ModalUserTreatmentGroupCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { TextCP } from 'main/common/components/text/TextCP'
import * as _ from 'lodash'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IUserTreatmentGroupsICPProps {
    userCode: number
}

export function UserTreatmentGroupsICP(props: IUserTreatmentGroupsICPProps): JSX.Element {

    const [showJobFunctionModal, setShowJobFunctionModal] = useState<boolean>(false)
    const [shouldUpdate, setShouldUpdate] = useState<boolean>()

    useEffect(init, [props.userCode, shouldUpdate])

    const userTreatmentGroupsRequest = useRequest<IGenericListResponseDTO<INameAndCodeResponseDTO>>()
    useEffect(onUserTreatmentGroupsRequestChange, [userTreatmentGroupsRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void{

        if (!props.userCode)
            return

        userTreatmentGroupsRequest.runRequest(UserRequests.getTreatmentGroupsConfig(props.userCode))
    }

    /**
     * Retorno da chamada da API que busca jobfunctions do usuario
     */
    function onUserTreatmentGroupsRequestChange(): void {
        setShouldUpdate(false)
        if (!RequestUtils.isValidRequestReturn(userTreatmentGroupsRequest, 'Ops! Erro ao buscar atendimentos'))
            return
    }

    return (
        <>
            <TagWrapperSCP>
                <TitleCP
                    loading={userTreatmentGroupsRequest.isAwaiting}
                    onClick={() => setShowJobFunctionModal(true)}
                    textSize={'normal'}
                    underLine={true}
                >
                    Atendimentos
                </TitleCP>

                <TextCP
                    text={'Nenhum atendimento vinculado'}
                    shouldRender={userTreatmentGroupsRequest.responseData && _.isEmpty(userTreatmentGroupsRequest.responseData.list)}
                />

                {
                    userTreatmentGroupsRequest.responseData &&
                    <TagTreatmentGroupCP treatmentGroups={userTreatmentGroupsRequest.responseData.list}/>
                }

            </TagWrapperSCP>

            <ModalUserTreatmentGroupCP
                visible={showJobFunctionModal}
                onCancelModal={() => setShowJobFunctionModal(false)}
                userCode={props.userCode}
                onSave={() => {
                    setShowJobFunctionModal(false)
                    init()
                }}
            />
        </>
    )
}

const TagWrapperSCP = styled.div`
  margin-bottom: 30px;
`
