export enum ContractVariablesEnum {
    TODAY = '##data_hoje##',
    DAY_AND_MONTH_IN_FULL = '##data_atual_mes_extenso##',
    SALE_DATE = '##data_do_contrato##',
    FRANCHISE_NAME = '##nome_contratada##',
    FRANCHISE_SOCIAL_NAME = '##razao_social_contratada##',
    FRANCHISE_ADDRESS = '##endereco_contratada##',
    FRANCHISE_ADDRESS_CITY = '##cidade_contratada##',
    FRANCHISE_ADDRESS_UF = '##uf_contratada##',
    FRANCHISE_CNPJ = '##cpfcnpj_contratada##',
    PERSON_PAYER_NAME = '##nome_pagador##',
    PERSON_PAYER_ADDRESS = '##endereco_pagador##',
    PERSON_PAYER_CPF = '##cpfcnpj_pagador##',
    PERSON_PAYER_RG = '##rg_pagador##',
    PERSON_CUSTOMER_NAME = '##nome_contratante##',
    PERSON_CUSTOMER_ADDRESS = '##endereco_contratante##',
    PERSON_CUSTOMER_CPF = '##cpfcnpj_contratante##',
    IMAGEM_ASSINATURA = '##imagem_assinatura##',
    PERSON_CUSTOMER_RG = '##rg_contratante##',
    PERSON_CUSTOMER_PHONE = '##telefone1_contratante##',
    PERSON_CUSTOMER_GENDER = '##sexo_contratante##',
    SALE_CODE = '##codigo_venda##',
    SALE_ITENS = '##itens_venda##',
    SALE_ITENS_WITH_SESSIONS = '##itens_venda_com_sessao##',
    SALE_STATUS = '##status_venda##',
    SALE_TOTAL_INSTALLMENTS = '##numero_parcelas##',
    SALE_PAYMENT_METHOD = '##forma_pagamento##',
    SALE_VALUE = '##valor_venda##',
    SALE_TOTAL_BASE='##valor_total_base##',
    SALE_DISCOUNT='##valor_desconto##',
    
}

