import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IEvaluationsByFranchiseListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IEvaluationsByFranchiseListItemResponseDTO'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import React from 'react'

/**
 * UTILITARIOS da tabela de evolucoes por unidade.
 */
export const EvaluationsByFranchiseTableUtils = {

    getTableColumns(): Array<ColumnProps<IEvaluationsByFranchiseListItemResponseDTO>> {
        return [
            {
                title: 'Unidade',
                dataIndex: 'franchise',
                render: (text, dto) => dto.franchise.name
            },
            {
                title: 'Agenda de Hoje',
                dataIndex: 'total',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalPlannedToday, 2)
            },
            { key: 'prog-con-today',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={`Taxa em relação ao comparecimento e conclusão dos agendamentos hoje. Total já realizado hoje: ${dto.totalConcludedToday}`}
                        percent={MathUtils.getPercentage(dto.totalConcludedToday, dto.totalPlannedToday, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'p/ Hoje',
                dataIndex: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalScheduledToday, 2)
            },
            {
                title: 'p/ Amanhã',
                key: 'delayed',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalScheduledTomorrow, 2)
            },
            {
                title: 'p/ + 48hrs',
                key: 'act',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalScheduledMore48Hrs, 2)
            },
            {
                title: 'Total Agendado Hoje',
                dataIndex: 'total',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalScheduledToday + dto.totalScheduledTomorrow + dto.totalScheduledMore48Hrs, 2)
            },
            { key: 'prog-aftertomorrow',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={`Taxa de todos os agendamentos criados hoje em relação à meta de: ${dto.totalScheduledTarget}`}
                        percent={MathUtils.getPercentage((dto.totalScheduledToday + dto.totalScheduledTomorrow + dto.totalScheduledMore48Hrs), dto.totalScheduledTarget, true)}
                        gradientColor={true}
                    />
                ) },
        ]
    },

}
