import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IScpProps {
    isSecondaryMenu?: boolean
}

interface ICPProps extends IScpProps {
    isActive?: boolean
}

/**
 * Barra no menu indicando qual eh o menu atual.
 */
export function BarActiveMenuItemCP(props: ICPProps): JSX.Element {

    if (!props.isActive)
        return <></>

    return <ActiveItemBarSCP isSecondaryMenu={props.isSecondaryMenu}/>
}

const ActiveItemBarSCP = styled.div<IScpProps>`
  background-color: ${props => props.isSecondaryMenu ? ThemeProject.secondaryMenuItemColor : ThemeProject.primaryMenuItemColor};
  width: 3px;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
`
