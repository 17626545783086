import * as _ from 'lodash'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import { IWorkScheduleRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkScheduleRequestDTO'
import moment from 'moment'
import { IWorkScheduleResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IWorkScheduleResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { WorkScheduleValidationTP } from 'main/modules/scheduler/types/WorkScheduleValidationTP'
import { WorkScheduleTypeEnum } from 'main/modules/scheduler/enums/WorkScheduleTypeEnum'

/**
 * Utilitario de horarios de trabalho
 */
export const WorkScheduleUtils = {

    /**
     * Agrupa os horarios de trabalho pelo dia da semana
     * e retorna um array ordenado com horários dos 7 dias no formato
     * {
     *    weekDay: 1
     *    beginTime: "09:00"
     *    endTime: "21:00"
     *    beginInterval: "12:00"
     *    endInterval: "13:00"
     * }
     */
    transformDtoToWorkSchedule(workSchedule: IWorkScheduleResponseDTO[]): IWorkSchedule[] {

        const scheduleByWeekday = _.groupBy(workSchedule, 'weekDay')

        const daysNotIncluded = _.without([0, 1, 2, 3, 4, 5, 6], ...workSchedule.map(w => w.weekDay))

        const missingWeekDays = daysNotIncluded.map((d): IWorkSchedule => { return { weekDay: d, beginTime: undefined, endTime: undefined, beginInterval: undefined, endInterval: undefined } })

        const workScheduleForTable = Object.entries(scheduleByWeekday).map(([weekDay, times]): IWorkSchedule => {
            const availableTime = _.find(times, time => time.type === 'AVAILABLE')
            const unavailableTime = _.find(times, time => time.type === 'UNAVAILABLE')

            if (!availableTime)
                return { weekDay: 0, beginTime: '', endTime: '', beginInterval: '', endInterval: '' }
            if (!unavailableTime)
                return { weekDay: +weekDay, beginTime: availableTime.beginTime, endTime: availableTime.endTime, beginInterval: '', endInterval: '' }

            return {
                weekDay: +weekDay,
                beginTime: availableTime.beginTime,
                endTime: availableTime.endTime,
                beginInterval: unavailableTime.beginTime,
                endInterval: unavailableTime.endTime,
            }
        })

        return workScheduleForTable.concat(missingWeekDays).sort((a, b) => a.weekDay - b.weekDay)
    },

    /**
     * Reagrupa a lista de horarios de trabalho vindos da tabela de ediçao
     * retornando o formato padrão:
     * {
     *      beginTime: "09:00"
     *      endTime: "21:00"
     *      type: "AVAILABLE"
     *      weekDay: 1
     *  }
     */
    transformWorkScheduleToDto(workSchedules: IWorkSchedule[]): IWorkScheduleRequestDTO[] {

        const wsMapped: IWorkScheduleRequestDTO[] = []
        workSchedules.forEach((_workSchedule) => {

            if (!_workSchedule || !_workSchedule.beginTime || !_workSchedule.endTime)
                return

            if (_workSchedule.beginInterval && _workSchedule.endInterval) {
                wsMapped.push({
                    weekDay: +_workSchedule.weekDay,
                    beginTime: _workSchedule.beginInterval,
                    endTime: _workSchedule.endInterval,
                    type: WorkScheduleTypeEnum.UNAVAILABLE
                })
            }

            wsMapped.push({
                weekDay: +_workSchedule.weekDay,
                beginTime: _workSchedule.beginTime,
                endTime: _workSchedule.endTime,
                type: WorkScheduleTypeEnum.AVAILABLE
            })

        })

        return _.filter(wsMapped, r => r !== null)
    },

    /**
     * Verifica se horario final eh maior que inicial e valida se intervalo esta dentro do horario de trabalho, informado msg de erro de validacao.
     */
    isIntervalScheduleValid(workingIntervalBegin?: moment.Moment, workingIntervalEnd?: moment.Moment, workingTimeBegin?: moment.Moment, workingTimeEnd?: moment.Moment): WorkScheduleValidationTP {

        if (!workingIntervalBegin && !workingIntervalEnd)
            return { isCorrect: true, message: '' }

        // Valida se o intervalo tem inicio e fim valido
        const isValidWorkinInterval = WorkScheduleUtils.isBeginBeforeEndTime(workingIntervalBegin, workingIntervalEnd)
        if (!isValidWorkinInterval.isCorrect)
            return isValidWorkinInterval

        const defaultErrorMsg = 'Intervalo deve estar dentro do horário de trabalho'

        // Valida se preencheu todas os horarios de trabalho
        if (!workingTimeBegin || !workingTimeEnd)
            return { isCorrect: false, message: defaultErrorMsg }

        // Valida se intervalo esta dentro do horario de trabalho
        const isCorrect = moment(workingIntervalBegin).isBetween(workingTimeBegin, workingTimeEnd) && moment(workingIntervalEnd).isBetween(workingTimeBegin, workingTimeEnd)
        return { isCorrect, message: isCorrect ? '' : defaultErrorMsg }
    },

    /**
     * Verifica se horario final eh maior que inicial, informado msg de erro de validacao.
     */
    isBeginBeforeEndTime(beginTime?: moment.Moment, endTime?: moment.Moment): WorkScheduleValidationTP {

        if (!beginTime && !endTime)
            return { isCorrect: true, message: '' }

        const defaultErrorMsg = 'Horário inicial deve ser anterior ao horário final'

        // Se preencheu o final mas nao o inicial
        if (!beginTime && endTime)
            return { isCorrect: false, message: defaultErrorMsg }

        // Retorna invalida se horario final for maior que inicial
        const isCorrect = moment(beginTime).isBefore(endTime)
        return { isCorrect, message: isCorrect ? '' : defaultErrorMsg }
    },

    /**
     * Verifica se todos os horarios estao validos.
     */
    isAllWorkScheduleValid(workSchedules: IWorkSchedule[], validateInterval: boolean): boolean {

        let isValid: boolean = true

        workSchedules.forEach((_workSchedule) => {

            let timeValidationData

            // Valida as regras de intervalo, horario inicio e horario fim
            if (validateInterval) {

                // Valida horarios de trabalho e intervalo
                timeValidationData = WorkScheduleUtils.isIntervalScheduleValid(
                    DateUtils.getMomentFromTime(_workSchedule.beginInterval),
                    DateUtils.getMomentFromTime(_workSchedule.endInterval),
                    DateUtils.getMomentFromTime(_workSchedule.beginTime),
                    DateUtils.getMomentFromTime(_workSchedule.endTime)
                )

            } else {

                // Valida apenas horario de trabalho
                timeValidationData = WorkScheduleUtils.isBeginBeforeEndTime(
                    DateUtils.getMomentFromTime(_workSchedule.beginTime),
                    DateUtils.getMomentFromTime(_workSchedule.endTime)
                )
            }

            // Verifica se uma das datas foi preenchida e outra nao
            if ((_workSchedule.beginInterval && !_workSchedule.endInterval) || (!_workSchedule.beginInterval && _workSchedule.endInterval)
                || (_workSchedule.beginTime && !_workSchedule.endTime) || (!_workSchedule.beginTime && _workSchedule.endTime))
                isValid = false

            if (!timeValidationData.isCorrect)
                isValid = false

        })

        return isValid
    }

}
