import { PersonContactUtils } from 'main/modules/people/utils/PersonContactUtils'
import React from 'react'
import styled from 'styled-components'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import * as _ from 'lodash'

interface ITextActivityDescriptionCPProps {
    activity: IPersonContactResponseDTO
    showTitle?: boolean
    showDate?: boolean
    showFullTitle?: boolean
}

/**
 * Componente contendo linha do tempo de Atividades de uma OPORTUNIDADE
 */
export function TextActivityDescriptionCP(props: ITextActivityDescriptionCPProps): JSX.Element {

    const color = PersonContactUtils.getActivityStatusColor(props.activity)
    const dateTime = props.showDate ? `${DateUtils.getPresentableDateHour(props.activity.beginDate)}` : ''

    let title = ''
    if (props.showTitle)
        title = props.showFullTitle ? props.activity.description : ` ${_.truncate(props.activity.description, { length: 10, omission: ' ...' })}`

    return (
        <ActivityWrapperSCP color={color}>
            { dateTime }
            { title }
        </ActivityWrapperSCP>
    )
}

const ActivityWrapperSCP = styled.span<{ color: string }>`
    font-weight: 400;
    word-wrap: break-word;
    width: 100%;
`
