export enum SalesFunnelSalesViewEnum {
    SALES_TODAY = 'SALES_TODAY',
    SALES_MONTH = 'SALES_MONTH',
    SALES_DELAYED = 'SALES_DELAYED',
    SALES_ALL = 'SALES_ALL',
}

export enum SalesFunnelSalesViewLabelEnum {
    SALES_TODAY = 'Vendas do dia',
    SALES_MONTH = 'Vendas do mês',
    SALES_DELAYED = 'Vendas em atraso',
    SALES_ALL = 'Todas as vendas',
}
