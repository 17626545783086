import { FormModel } from 'main/common/form-state-manager/FormModel'

export class TransactionalMessagesFormModel extends FormModel {

    sendSms: boolean
    sendWhatsapp: boolean
    sendEmail: boolean

    smsMessage: string
    whatsappMessage: string
    emailMessageJson: string
    emailMessageHtml: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
