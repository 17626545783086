import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { ILeadsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/ILeadsByUserListItemResponseDTO'
import { MathUtils } from 'main/common/utils/MathUtils'

/**
 * UTILITARIOS do componente de listagem de total de leads por colaborador.
 */
export const TotalLeadsTodayByUserTableUtils = {

    getTableColumns(): Array<ColumnProps<ILeadsByUserListItemResponseDTO>> {
        return [
            {
                title: 'Colaborador',
                dataIndex: 'user',
                key: 'user',
                render: (text, dto) => dto.user.name
            },
            {
                title: 'Captados hoje',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.leadsRegisteredToday, 2)
            },
            { key: 'target',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo de captação em relação a meta: ${dto.leadsRegisteredTodayTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.leadsRegisteredToday, dto.leadsRegisteredTodayTarget, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'Planej. Hoje',
                dataIndex: 'today',
                key: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.leadsPlannedToday, 2)
            },
            { key: 'target',
                render: (text, dto) => (
                    <ProgressCP
                        tooltip={`Comparativo em relação a meta: ${dto.leadsPlannedTodayTarget}`}
                        width={80}
                        percent={MathUtils.getPercentage(dto.leadsPlannedToday, dto.leadsPlannedTodayTarget, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'Concluídos',
                key: 'concluded',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.leadsConcludedToday, 2)
            },
            { key: 'prog-2',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        percent={MathUtils.getPercentage(dto.leadsConcludedToday, dto.leadsPlannedToday, true)}
                        gradientColor={true}
                    />
                ) },
        ]
    },

}
