import { RoutingHelper } from 'config/RoutingHelper'
import { DevTestScreen } from 'main/modules/app/screens/screen-dev/DevTestScreen'
import { Error404Screen } from 'main/common/screens/Error404Screen'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { AdminRoutes } from 'main/modules/admin/AdminRoutes'
import { DashboardRoutes } from 'main/modules/dashboard/routes/DashboardRoutes'
import { FinancialRoutes } from 'main/modules/financial/routes/FinancialRoutes'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { PersonRoutes } from 'main/modules/people/routes/PersonRoutes'
import { PersonProfileScreen } from 'main/modules/people/screens/PersonProfileScreen'
import { SalesFunnelRoutes } from 'main/modules/sales-funnel/routes/SalesFunnelRoutes'
import { SchedulerRoutes } from 'main/modules/scheduler/routes/SchedulerRoutes'
import { useEffect } from 'react'
import { ReportsRoutes } from 'main/modules/reports/routes/ReportsRoutes'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

PrivateRouter.DEV_TEST = 'dev-test'

const PRIVATE_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(DashboardRoutes.ROOT),
        path: DashboardRoutes.ROOT,
        component: DashboardRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(PersonRoutes.PROFILE),
        path: PersonRoutes.PROFILE,
        component: PersonProfileScreen,
    },
    {
        key: StringUtils.getSlugStyleString(AdminRoutes.ROOT),
        path: AdminRoutes.ROOT,
        component: AdminRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(PersonRoutes.ROOT),
        path: PersonRoutes.ROOT,
        component: PersonRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(MarketingRoutes.ROOT),
        path: MarketingRoutes.ROOT,
        component: MarketingRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(SchedulerRoutes.ROOT),
        path: SchedulerRoutes.ROOT,
        component: SchedulerRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(SalesFunnelRoutes.ROOT),
        path: SalesFunnelRoutes.ROOT,
        component: SalesFunnelRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(FinancialRoutes.ROOT),
        path: FinancialRoutes.ROOT,
        component: FinancialRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(ReportsRoutes.ROOT),
        path: ReportsRoutes.ROOT,
        component: ReportsRoutes,
    },
    {
        key: 'dev-test',
        path: PrivateRouter.DEV_TEST,
        component: DevTestScreen,
    },
    {
        key: 'nerit-clin',
        component: Error404Screen,
    },
]

/**
 * Define rotas que so podem ser acessadas apos realizar login.
 */
export function PrivateRouter(): JSX.Element {

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        RoutingHelper.setDefaultRoute(AppStateUtils.getCurrentFranchise()?.isFranchisor ? DashboardRoutes.ROOT : SchedulerRoutes.CALENDAR)
    }

    return RoutingHelper.renderRoutingSwitch(PRIVATE_ROUTES_CONFIG)
}
