import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import styled from 'main/config/theme/styledWithTheme'
import React, { useEffect, useState } from 'react'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { OpportunityEvaluationRequests } from 'main/modules/sales-funnel/services/evaluation/OpportunityEvaluationRequests'
import { ITimelineItem, TimelineCP } from 'main/common/components/timeline/TimelineCP'
import { OpportunityEvaluationUtils } from 'main/modules/sales-funnel/utils/OpportunityEvaluationUtils'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { OpportunityEvaluationSchedulesTimelineItemICP } from 'main/modules/sales-funnel/components/timeline-opportunity-evaluation-schedules/inner/OpportunityEvaluationSchedulesTimelineItemICP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { OpportunityScheduleStatusEnum } from 'submodules/neritclin-sdk/services/opportunity-schedule/enums/OpportunityScheduleStatusEnum'

interface ITimelineOpportunityActivitiesCPProps {
    opportunity: IOpportunityResponseDTO
    onSelectEvaluationSchedule: (evaluationScheduleCode: number) => void
    onAddEvaluationSchedule: () => void
    onUpdateEvaluation: () => void
}

/**
 * Componente com o conteúdo de detalhes das atividades de uma oportunidade
 */
export function TimelineOpportunityEvaluationSchedulesCP(props: ITimelineOpportunityActivitiesCPProps): JSX.Element {

    const [shouldShowAddButton, setShouldShowAddButton] = useState<boolean>(false)
    const [evaluationScheduleTimelineItens, setEvaluationScheduleTimelineItens] = useState<ITimelineItem[]>([])

    const getEvaluationSchedulesRequest = useRequest<IGenericListResponseDTO<IOpportunityEvaluationScheduleResponseDTO>>()
    useEffect(onGetEvaluationSchedulesRequestChange, [getEvaluationSchedulesRequest.isAwaiting])

    useEffect(init, [props.opportunity])

    /**
     * Inicializa a tela
     */
    function init(): void {
        getEvaluationSchedulesRequest.runRequest(OpportunityEvaluationRequests.searchSchedulesConfig(props.opportunity.code))
    }

    /**
     * Retorno ao obter lista de atividades
     */
    function onGetEvaluationSchedulesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEvaluationSchedulesRequest, 'Erro ao buscar agendamentos de avaliação da oportunidade'))
            return

        const evaluationSchedulesResult = getEvaluationSchedulesRequest.responseData!.list
        defineAddButtonVisibility(evaluationSchedulesResult)

        const _timelineItens: ITimelineItem[] = evaluationSchedulesResult.map(evaluationSchedule => ({
            color: OpportunityEvaluationUtils.getScreeningStatusColor(evaluationSchedule),
            dot: <FontAwsomeIconCP type={faCalendar}/>,
            position: 'left',
            content: (
                <OpportunityEvaluationSchedulesTimelineItemICP
                    evaluationSchedule={evaluationSchedule}
                    onActionsDone={() => {
                        init()
                        props.onUpdateEvaluation()
                    }}
                    onAddEvaluationSchedule={props.onAddEvaluationSchedule}
                    onEditEvaluationSchedule={() => props.onSelectEvaluationSchedule(evaluationSchedule.code)}
                />
            )
        }))
        setEvaluationScheduleTimelineItens(_timelineItens)
    }

    /**
     * Define se o botao de adicionar avaliacoes pode ser exibido ou nao
     */
    function defineAddButtonVisibility(evaluations: IOpportunityEvaluationScheduleResponseDTO[]): void {

        const openEvaluations = evaluations.filter((evaluation) => evaluation.status === OpportunityScheduleStatusEnum.SCHEDULED)

        // Se nao tiver nenhuma em aberto sempre mostra o botao
        if (SystemUtils.isEmpty(openEvaluations)) {
            setShouldShowAddButton(true)
            return
        }

        // Caso contrario mostra apenas quando eh permitido cadastrar mais de uma avaliacao ao mesmo tempo
        setShouldShowAddButton(!AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.opportunity?.shouldDisableMultipleEvaluations)
    }

    return (
        <WrapperSCP>

            <TitleCP
                underLine={true}
                textSize={'large'}
            >
                <FlexCP alignItems={'center'}>
                    <div>
                        Avaliações agendadas
                    </div>
                    <ButtonCP
                        icon={'plus'}
                        type={'link'}
                        onClick={props.onAddEvaluationSchedule}
                        loading={getEvaluationSchedulesRequest.isAwaiting}
                        marginLeft={20}
                        disabled={!shouldShowAddButton}
                        tooltip={shouldShowAddButton ? undefined : 'Só pode existir uma avaliação agendada por LEAD. Finalize a atual para agendar uma nova'}
                    />
                </FlexCP>

            </TitleCP>

            <TimelineWrapperSCP>
                <TimelineCP
                    items={evaluationScheduleTimelineItens}
                />
            </TimelineWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-bottom: 30px;
`

const TimelineWrapperSCP = styled.div`
  margin-top: 30px;
`
