import { ContextFactory } from 'main/common/context-factory/ContextFactory'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'
import { CtxFactoryReducerTP } from 'main/common/context-factory/types/CtxFactoryReducerTP'
import { ActionTP } from 'main/common/types/ActionTP'
import { PersonContextActionTypeTP } from 'main/modules/people/contexts/person-context/PersonContextActionTypeTP'
import { PersonContextStateTP } from 'main/modules/people/contexts/person-context/PersonContextStateTP'
import { PersonContextUtils } from 'main/modules/people/contexts/person-context/PersonContextUtils'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'

type ContextActionTP = ActionTP<PersonContextActionTypeTP, PersonContextStateTP>

const INITIAL_STATE: PersonContextStateTP = {

    isLoadingPeople: false,
    isLoadingSegment: false,
    mustUpdatePeopleList: false,

    showPersonDetailsDrawer: false,
    showAddPersonDrawer: false,
    showAddJobFunctionModal: false,

    expression: []
}

const reducer: CtxFactoryReducerTP<PersonContextStateTP, PersonContextActionTypeTP> = (currentState, action) => {

    const payload = action.payload

    switch (action.type) {

        case 'setIsLoadingPeople':
        case 'setIsLoadingSegment':
        case 'setMustUpdatePeopleList':
            return PersonContextUtils.getFlagState(currentState, action as ContextActionTP)

        case 'generic':
        default:
            return {
                ...currentState,
                ...payload,
                expression: payload?.segment?.expression ?? payload?.expression ?? currentState.expression ?? [],
            }
    }
}

const actionBuilder: CtxFactoryActionBuilderTP<PersonContextStateTP, PersonContextActionTypeTP> = actionHelper => ({

    setShowAddPersonDrawer: (showAddPersonDrawer: boolean): void => actionHelper.replaceWithPayload({ showAddPersonDrawer }),
    setShowPersonDetailsDrawer: (showPersonDetailsDrawer: boolean): void => actionHelper.replaceWithPayload({ showPersonDetailsDrawer }),
    setShowAddJobFunctionModal: (showAddJobFunctionModal: boolean): void => actionHelper.replaceWithPayload({ showAddJobFunctionModal }),

    setIsLoadingPeople: (isLoadingPeople: boolean): void => actionHelper.dispatch({ type: 'setIsLoadingPeople', payload: { isLoadingPeople } }),
    setIsLoadingSegment: (isLoadingSegment: boolean): void => actionHelper.dispatch({ type: 'setIsLoadingSegment', payload: { isLoadingSegment } }),
    setMustUpdatePeopleList: (mustUpdatePeopleList: boolean): void => actionHelper.dispatch({ type: 'setMustUpdatePeopleList', payload: { mustUpdatePeopleList } }),

    setExpression: (expression: SegmentExpressionTP): void => actionHelper.replaceWithPayload({ expression }),
    setSelectedPersonCode: (selectedPersonCode?: number): void => actionHelper.replaceWithPayload({ selectedPersonCode }),

    setSegment: (segment: ISegmentListItemResponseDTO): void => actionHelper.dispatch({ type: 'setSegment', payload: { segment } }),
    setTypeToGoTo: (typeToGoTo: SegmentTypeEnum): void => actionHelper.replaceWithPayload({ typeToGoTo }),
    clearTypeToGoTo: (): void => actionHelper.replaceWithPayload({ typeToGoTo: undefined }),
})

/**
 * CONTEXTO: Tela de Listagem de pessoas
 * Provider + Hook para acesso ao contexto de estado global da tela.
 *
 * @see PersonListScreen
 */
export const PersonContext = ContextFactory.getOne<PersonContextStateTP, PersonContextActionTypeTP>({
    contextName: 'Listagem de Pessoas',
    initialStateGetter: () => INITIAL_STATE,
    actionBuilder,
    reducer,
})
