import { HttpMethodEnum } from "main/common/enums/HttpMethodEnum";
import { RequestHelper } from "main/common/request-manager/RequestHelper";
import { AppStateUtils } from "main/common/utils/AppStateUtils";
import { SystemConfig } from "main/config/SystemConfig";
import React, { useEffect, useState } from "react"
import * as One from "common/utils/One"
import { RowCP } from "main/common/components/grid/RowCP";
import { ColumnCP } from "main/common/components/grid/ColumnCP";
import { IDateRangeFilter } from "main/common/components/date-range-picker/inner/IDateRangeFilter";
import moment from "moment";
import { DateRangePickerCP } from "main/common/components/date-range-picker/DateRangePickerCP";
import styled from "styled-components";
import { TableCP } from "main/common/components/table/TableCP";
import { MaskUtils } from "main/common/utils/MaskUtils";
import { StatisticCP } from "main/common/components/statistic/StatisticCP";
import { CardCP } from "main/common/components/card/CardCP";
import { SelectCP } from "main/common/components/form-fields/select/SelectCP";
import { TextCP } from "main/common/components/text/TextCP";
import { ButtonCP } from "main/common/components/button/ButtonCP";
import { notification } from 'antd';
import { ExcelUtil } from "main/modules/people/utils/ExcelUtil";


notification.config({
    placement: 'bottomRight',
  });

let id = 1;
function gerarVenda(o:any){
    const res = Object.create(null);
    res.id = id;
    res.data = o.dia;
    res.dia = One.dateDeStamp(o.dia);
    res.vendedor = {id:o.vendedor,nome:o.nomevendedor};
    res.numeroVenda = o.idvenda;
    res.valor = parseFloat(o.preco);
    res.comissao = o.comissao;
    id++;
    return res;
}

function gerarExecucao(o:any){
    const res = Object.create(null);
    res.id = id;
    res.data = o.dia;
    res.cliente = o.cliente;
    res.dia = One.dateDeStamp(o.dia);
    res.codigoVenda = o.codigovenda;
    res.executor = {id:o.prs_code, nome:o.nome};
    res.comissao = o.comissao;
    res.produto = (o.produto+'').toUpperCase();
    id++;
    console.log("Execucao gerada ", res);
    return res;
}

const colunasVenda = [
    {
      title: 'Data',
      dataIndex: 'dia',
      key: 'dia',
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendedor.nome',
      key: 'vendedor',
    },
    {
        title: 'Nº da Venda',
        dataIndex: 'numeroVenda',
        key: 'numeroVenda',
      },
    {
        title: 'Valor da Venda',
        dataIndex: 'valor',
        key: 'valor',
        render:(txt:any,dados:any)=>MaskUtils.applyMoneyMask(dados.valor)
      },
    {
      title: 'Comissão',
      dataIndex: 'comissao',
      key: 'comissao',
      render:(txt:any,dados:any)=><Preco>{MaskUtils.applyMoneyMask(dados.comissao)}</Preco>
    },
  ];
const colunasExecucao = [
    {
      title: 'Data',
      dataIndex: 'dia',
      key: 'dia',
    },
    {
      title: 'Executor',
      dataIndex: 'executor.nome',
      key: 'executor',
      render:(txt:any,dados:any)=><BlocoVenda>{dados.executor.nome}</BlocoVenda>
    },
    {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        render:(txt:any,dados:any)=><BlocoCliente>{dados.cliente}</BlocoCliente>
    },
    {
        title: 'Nº da Venda',
        dataIndex: 'codigoVenda',
        key: 'codigoVenda',
      },
    {
        title: 'Produto',
        dataIndex: 'produto',
        key: 'produto',
        render:(txt:any,dados:any)=><BlocoProduto>{dados.produto}</BlocoProduto>
      },
    {
      title: 'Comissão',
      dataIndex: 'comissao',
      key: 'comissao',
      render:(txt:any,dados:any)=><Preco>{MaskUtils.applyMoneyMask(dados.comissao)}</Preco>
    },
  ];

let vendasGeradas:any = [];
let execucoesGeradas:any = [];


export function RelatorioComissaoTela():JSX.Element{
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
    })
    const [semDados, setSemDados] = useState(true);
    const [vendas,setVendas] = useState([]);
    const [totais,setTotais] = useState({totalVendas:0,comissaoVendas:0,totalExecucao:0,comissaoExecucao:0});
    const [execucoes,setExecucoes] = useState([]);
    const [pessoas,setPessoas] = useState([]);
    const [totalComisao,setTotalComissao] = useState(0);
    const [carregando, setCarregando] = useState(false);
    const franquia = AppStateUtils.getCurrentFranchise();
    useEffect(()=>{carregarDados();},[dateFilters]);
    
    function calcular(ven:any,exec:any){
        console.log("Calculando ", exec);
        let t = 0.00;
        const tven = ven.reduce((acu:any,atu:any)=>acu+=atu.comissao,0);
        const texec =  exec.reduce((acu:any,atu:any)=>acu+=atu.comissao,0);
        t+=texec;
        t+=tven;
        t = parseFloat(t.toFixed(2));
        totais.comissaoExecucao = texec;
        totais.comissaoVendas = tven;
        setTotais(totais);
        setTotalComissao(t);
    }

    function mudouDatas(n:any){
        setDateFilters(n);
    }

    function mudouAgente(ag:any){
        if(!ag){
            setVendas(vendasGeradas);
            ordenarPorDia(execucoesGeradas);
            setExecucoes(execucoesGeradas);
            calcular(vendasGeradas,execucoesGeradas);
            return;
        }
        const tempV = vendasGeradas.filter((v:any)=>v.vendedor.id==ag);
        const tempE = execucoesGeradas.filter((v:any)=>v.executor.id==ag);
        setVendas(tempV);
        ordenarPorDia(tempE);
        setExecucoes(tempE);
        calcular(tempV,tempE);
    }

    function processarResultado(dados:any){
        console.log("DADOS RECEBIDOS ", dados);
       
        const temp = {totalVendas:0,comissaoVendas:0,totalExecucao:0,comissaoExecucao:0};
        vendasGeradas = [];
        execucoesGeradas = [];
        if(!dados){
            setVendas([]);
            setExecucoes([]);
            calcular([],[]);
            return;
        }
            
        dados.vendas.forEach((v:any)=>{
            if(v.comissao_do_vendedor=='NaN')v.comissao_do_vendedor = null;
        })
        const mapaComissaoSessao = Object.create(null);//as comissoes da secao por produto
        const mapaVendas = Object.create(null);
        dados.vendas.forEach((v:any)=>{
            if(!mapaVendas[v.idvenda])mapaVendas[v.idvenda]={...v, sessoes:[]}
           // if(v.comissao_execucao_produto)mapaVendas[v.idvenda].comissao_execucao_produto = v.comissao_execucao_produto;
            if(v.comissao_venda_produto)mapaVendas[v.idvenda].comissao_venda_produto = v.comissao_venda_produto;
            mapaComissaoSessao[v.iditemvenda]=v.comissao_execucao_produto;
        });
        if(dados['sessoes']){
            dados.sessoes.forEach((s:any)=>{
                s.comissao_execucao_produto = mapaComissaoSessao[s.codigoitem];
                if(mapaComissaoSessao[s.codigoitem])mapaVendas[s.codigovenda].tem = true;
                if(mapaVendas[s.codigovenda])mapaVendas[s.codigovenda].sessoes.push(s);
            })
        }
        const filtrados = (Object.values(mapaVendas)).filter((m:any)=>{
            for(let i = 0; i<m.sessoes.length;i++){
                m.sessoes[i].comissao_execucao_produto = mapaComissaoSessao[m.sessoes[i].codigoitem];
                if(m.sessoes[i].comissao_execucao || m.sessoes[i].comissao_venda || m.sessoes[i].comissao_execucao_produto)return m;
            }
            if(m.comissao_do_vendedor || m.comissao_execucao_produto || m.comissao_venda_produto || m['tem'])return m;
        })
        const vendas:any = [];
        const execucoes:any = [];
        const pes:any = Object.create(null);


        filtrados.forEach((f:any)=>{
            if(f.comissao_do_vendedor){
                f.comissao = parseFloat((parseFloat(f.preco)*(parseFloat(f.comissao_do_vendedor)/100)).toFixed(2));
                vendas.push(gerarVenda(f));
                temp.comissaoVendas+=f.comissao;
                pes[f.vendedor]={value:f.vendedor,label:f.nomevendedor,key:f.vendedor};
            }else if(f.comissao_venda_produto){
                f.comissao = parseFloat((parseFloat(f.preco)*(parseFloat(f.comissao_venda_produto)/100)).toFixed(2));
                vendas.push(gerarVenda(f));
                temp.comissaoVendas+=f.comissao;
                pes[f.vendedor]={value:f.vendedor,label:f.nomevendedor,key:f.vendedor};
            }
        });

        dados['sessoes'].forEach((s:any)=>{
            if(s.comissao_execucao){
                s.comissao = parseFloat(((parseFloat(s.preco)/parseInt(s.sessoes))*(parseFloat(s.comissao_execucao)/100)).toFixed(2));
                execucoes.push(gerarExecucao(s));
                temp.comissaoExecucao+=s.comissao;
                pes[s.prs_code]={value:s.prs_code,label:s.nome,key:s.prs_code};
            }else if(s.comissao_execucao_produto){
                s.comissao = parseFloat(((parseFloat(s.preco)/parseInt(s.sessoes))*(parseFloat(s.comissao_execucao_produto)/100)).toFixed(2));
                execucoes.push(gerarExecucao(s));
                temp.comissaoExecucao+=s.comissao;
                pes[s.prs_code]={value:s.prs_code,label:s.nome,key:s.prs_code};
            }
        });
        
        temp.totalVendas = vendas.length;
        temp.totalExecucao = execucoes.length;
        setTotais(temp);
        const asPessoas:any = Object.values(pes).sort((a:any,b:any)=>a.label.localeCompare(b.label));
        vendasGeradas = vendas;
        execucoesGeradas = execucoes;
        calcular(vendas,execucoes);
        setPessoas(asPessoas);
        setVendas(vendasGeradas.map((v:any)=>v));
        let exec = execucoesGeradas.map((v:any)=>v);
        ordenarPorDia(exec);
        setExecucoes(exec);
    }

    

    function exportarCSV(){
        if((!vendas && !execucoes) || (!vendas.length && !execucoes.length) ){
            notification.open({
                message: 'Sem Dados',
                description: (
                  <div>
                    <b>Não há dados para serem exportados.</b>
                    
                  </div>
                ),
                duration: 3,
              });
            return
        }
        console.log("Vendas e Execucoes", vendas,execucoes);
        let r = `EXPORTACAO DE COMISSOES\n TOTAL COMISSAO;${ExcelUtil.VALOR(totalComisao)}\n`;
        if(vendas && vendas.length){
            vendas.forEach((v:any)=>v.vendedorNome = v.vendedor.nome);
            r+=`RELACAO DE VENDAS\n`;
            r+= ExcelUtil.excel(vendas,[['DATA','dia'],['Vendedor','vendedorNome'],['VENDA','numeroVenda'],['VALOR VENDA','valor'],['COMISSAO','comissao']]);
            r+=`\n`;
        }
        if(execucoes && execucoes.length){
            execucoes.forEach((v:any)=>v.executorNome = v.executor.nome);
            r+=`RELACAO DE EXECUCOES\n`;
            r+=ExcelUtil.excel(execucoes,[['DATA','dia'],['EXECUTOR','executorNome'],['CLIENTE','cliente'],['VENDA','codigoVenda'],['PRODUTO','produto'],['COMISSAO','comissao']]);
            r+=`\n`;
        }
        
        ExcelUtil.salvarTexto('comissoes.CSV',r);
    }

    function ordenarPorDia(i:any){
        i.sort((a:any,b:any)=>{
            return (a.dia+'').localeCompare((b.dia+''));
        })
    }

    async function carregarDados(){
        setSemDados(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/obter-dados-comissoes`,
            method: HttpMethodEnum.POST,
            params: {
                de:One.dateParaSQL(dateFilters?.beginDate),
                ate:One.dateParaSQL(dateFilters?.endDate),
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>setCarregando(false)); 

        if(One.ok(resultado)){
            processarResultado(One.dados(resultado));
            setSemDados(false);
            return;
        }
        setSemDados(false);
    }

    function BlocoVenda(){
        if(!vendas.length)return <></>
        return (
            <>
            <TextCP text={'Relação de Comissão Por Vendas'} strong={true} size={'large'} />
            <TableCP
            columns={colunasVenda}
            data={vendas ?? []}
            loading={semDados}
        />

        <div className="indicador">
            <div className="item-indicador">
                <StatisticCP
                        value={vendas.length}
                        label={'Quantidade de Vendas'}
                        valueUnit={'vendas'}
                        loading={semDados}
                        footer={`Total Comissão Vendas: ${MaskUtils.applyMoneyMask(totais.comissaoVendas)}`}
                        
                />
            </div>
        </div>
        </>
        )
    }

    function BlocoExecucao(){
        if(!execucoes.length)return <></>
        return (
            <>
                <TextCP text={'Relação de Comissão Por Execuções'} strong={true} size={'large'} />
                <TableCP
                columns={colunasExecucao}
                data={execucoes ?? []}
                loading={semDados}
                />
                <div className="indicador">
                    <div className="item-indicador">
                        <StatisticCP
                                value={execucoes.length}
                                label={'Quantidade de Execuções'}
                                valueUnit={'execuções'}
                                loading={semDados}
                                footer={`Total Comissão Execuções: ${MaskUtils.applyMoneyMask(totais.comissaoExecucao)}`}
                        />
                    </div>
                </div>
            </>
        )
    }
    

    return (
        <AreaFormatada>
             <CardCP
            innerSpacing={'none'}
            overflow={'auto'}
            
            >
                <div>
                    <div className="titulo-franquia">
                        <div>Unidade</div>
                        {franquia?.code} - {franquia?.name}
                    </div>
                    {carregando?'Carregando... ':''}
                </div>
                

            </CardCP>
            <RowCP spaceBetweenCols={20} flex={true}  justify="end">
                    <ButtonCP
                            icon={'table'}
                            size={'small'}
                            type={'primary'}
                            tooltip={'Clique para exportar em excel'}
                            onClick={() => exportarCSV()}
                        >
                            Exportar
                    </ButtonCP>
                </RowCP>
            <RowCP spaceBetweenCols={20} align={'middle'}>

                <ColumnCP size={8}>
                    <DateRangePickerCP
                        label={'Intervalo de Tempo'}
                        value={dateFilters}
                        onChange={mudouDatas}
                        fastFilter={'all'}
                        allowClear={false}
                        showBorder={true}
                    />
                </ColumnCP>
                <ColumnCP size={4}>
                <div className="seletor-agente">
                    <SelectCP<any>
                        label={'Agente'}
                        options={pessoas}
                        onChange={mudouAgente}
                        allowClear={true}
                        fieldName={'label'}
                    />
                </div>    
                
                </ColumnCP>
                <ColumnCP size={5}>
                    <div className="item-indicador">
                        <StatisticCP
                                value={totalComisao}
                                label={'Total de Comissão a Pagar'}
                                loading={semDados}
                                valueUnit={'reais'}
                        />
                    </div>
                </ColumnCP>
                
            </RowCP>
            <CardCP
            innerSpacing={'normal'}
            
            >
            
                <BlocoVenda/>
                <BlocoExecucao/>
            
                
            </CardCP>
        </AreaFormatada>
    )
}

const BlocoVenda = styled.div`
    font-weight: 775;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
`
const Preco = styled.div`
    text-align:right;
    font-family: system-ui;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.048em;
`

const BlocoProduto = styled.div`
    font-Size: 9px;
`

const BlocoCliente = styled.div`
    font-weight: 475;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
`

const AreaFormatada = styled.div`
    .titulo-franquia{
        text-align:center;
        font-weight: 645;
        font-size: 20px;
        letter-spacing: -0.048em;
    }

    .indicador{
        display:flex;
        justify-content:flex-end;
    }

    .seletor-agente{
        position: relative;
        top: 14px;
    }

    .item-indicador{
        max-width: 421px;
        width: 300px;
    }
    
`