import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { TextCP } from 'main/common/components/text/TextCP'
import { IGenericSearchRequestDTO } from 'main/common/dtos/requests/IGenericSearchRequestDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ShotDetailsReportTableUtils } from 'main/modules/marketing/components/shot-details-report/inner/ShotDetailsReportTableUtils'
import { IMarketingShotReportListItemResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingShotReportListItemResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ITEMS_PER_PAGE = 100

interface IShotDetailsReportCPProps {
    reportType: MarketingShotReportTypeEnum
    title: string
    shotCode: number
    shotType: MarketingAccountTypeEnum
}

/**
 * Relatorio analitico de um envio.
 *
 * @author renatofs
 */
export function ShotDetailsReportCP(props: IShotDetailsReportCPProps): JSX.Element {

    const [personCode, setPersonCode] = useState<number>()
    const [showDrawer, setShowDrawer] = useState<boolean>(false)

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [reports, setReports] = useState<IMarketingShotReportListItemResponseDTO[]>()

    const reportRequest = useRequest<IGenericListResponseDTO<IMarketingShotReportListItemResponseDTO>>()

    useEffect(() => { runReportRequest() }, [props.reportType])
    useEffect(onRunReportRequestChange, [reportRequest.isAwaiting])
    useEffect(() => { runReportRequest() }, [currentPage])

    async function runReportRequest(): Promise<void> {

        const dto: IGenericSearchRequestDTO = {
            itemsPerPage: ITEMS_PER_PAGE,
            page: currentPage
        }

        const config = await MarketingRequests.getCampaignShotReports(props.shotType, props.shotCode, props.reportType, dto)
        reportRequest.runRequest(config)
    }

    function onRunReportRequestChange(): void {
        if (RequestUtils.isValidRequestReturn(reportRequest, 'Ocorreu algum erro ao obter relatório'))
            setReports(reportRequest.responseData?.list ?? [])
    }

    function onSelectPerson(_personCode: number): void {
        setPersonCode(_personCode)
        setShowDrawer(true)
    }

    return (
        <>
            <TextWrapperSCP>
                <TextCP text={props.title}/>
                <TextCP strong={true} text={`(${reportRequest.responseData ? MaskUtils.applyNumberMask(reportRequest.responseData.total, 0) : '-'} pessoas)`}/>
            </TextWrapperSCP>

            <TableCPOLD2
                columns={ShotDetailsReportTableUtils.getTableColumnsConfig(props.reportType, props.shotType, onSelectPerson)}
                data={reports}
                loading={reportRequest.isAwaiting}
                showHeader={true}
                onPaginationChange={({ current }) => setCurrentPage(current ?? 1)}
                pagination={{
                    total: reportRequest.responseData?.total ?? 0,
                    current: currentPage,
                    pageSize: ITEMS_PER_PAGE,
                }}
            />

            <DrawerPersonDetailsCP
                visible={showDrawer}
                personCode={personCode}
                segmentType={SegmentTypeEnum.CUSTOMER}
                onClose={() => setShowDrawer(false)}
                onSave={() => undefined}
            />
        </>
    )
}

const TextWrapperSCP = styled.div`
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    
    strong {
      margin-left: 10px;
    }
`
