import _ from 'lodash'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IAuthUserFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserFranchiseResponseDTO'
import React, { useEffect, useState } from 'react'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/InputValueCallbackTP'

interface ISelectFranchiseCPProps <FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    onChange?: (franchises: number[] | number) => void
    onDeselect?: InputValueCallbackTP<any>
    isMultiple?: boolean
    initialFranchises?: number | number[]
    returnFullOption?: boolean
}

/**
 * COMPONENTE Seletor franquias.
 */
export function SelectFranchiseCP<FModelTP extends FormModel = any>(props: ISelectFranchiseCPProps): JSX.Element {

    const [initialFranchise, setInitialFrancise] = useState<number | number[] | undefined>(props.initialFranchises)
    const [franchiseSelectOptions, setFranchiseSelectOptions] = useState<SelectOptionTP[]>([])

    useEffect(init, [])

    /**
     * Inicializa com as opções de franquia do usuário logado.
     */
    function init(): void {

        if (_.isEmpty(AppStateUtils.getUserFranchises()))
            return

        // Adiciona Franquias
        const franchiseOpts = AppStateUtils.getUserFranchises()!.map((userFranchise: IAuthUserFranchiseResponseDTO) => ({
            value: userFranchise.code,
            key: `franchise-${userFranchise.code}`,
            label: userFranchise.name
        }))

        setFranchiseSelectOptions(franchiseOpts)

        if (initialFranchise && props.formStateManager && props.formStateManager.getFieldValue('franchisesCodes'))
            setInitialFrancise(props.formStateManager.getFieldValue('franchisesCodes'))
    }

    return (
        <SelectCP
            label={!!props.placeholder ? undefined : `Selecione ${props.isMultiple ? 'uma ou mais unidades' : 'a unidade'}`}
            placeholder={props.placeholder}
            allowClear={true}
            value={initialFranchise}
            options={franchiseSelectOptions}
            fieldName={'franchisesCodes'}
            formStateManager={props.formStateManager}
            isMultiple={props.isMultiple}
            notFoundContent={'Nenhuma franquia encontrada.'}
            onChange={props.onChange}
            returnFullOption={props.returnFullOption}
            onBlur={props.onBlur}
            onDeselect={props.onDeselect}
        />
    )

}
