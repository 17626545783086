import { AvatarCP } from 'main/common/components/avatar/AvatarCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { SessionCustomerDataTP } from 'main/modules/scheduler/services/scheduler/types/SessionCustomerDataTP'

export interface ICPProps {
    customer: SessionCustomerDataTP
    attendantName?: string
    marginBottom?: number
}

/**
 * COMPONENTE Responsável por organizar os dados existentes no cabeçalho do drawer que exibe os detalhes de um agendamento
 */
export function AppointmentDetailsHeaderCP(props: ICPProps): JSX.Element {
    return (
        <FlexCP
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            marginBottom={props.marginBottom}
        >

            <FlexCP alignItems={'center'}>
                <AvatarCP size={60} name={props.customer.name}/>

                <GridWrapperSCP>
                    <TextCP text={'Cliente:'} strong={true}/>
                    <TextCP text={props.customer.name}/>
                    <TextPersonPhoneCP phone={props.customer.phone}/>
                </GridWrapperSCP>
            </FlexCP>

            {
                !!props.attendantName &&
                <GridWrapperSCP>
                    <TextCP text={'Profissional:'} strong={true}/>
                    <TextCP text={props.attendantName}/>
                </GridWrapperSCP>
            }
        </FlexCP>
    )
}

const GridWrapperSCP = styled.div`
    padding: 5px;
`
