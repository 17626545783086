import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { TreatmentTableUtils } from 'main/modules/admin/components/table-treatment/inner/TreatmentTableUtils'
import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { ITreatmentResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentResponseDTO'
import { TreatmentRequests } from 'main/modules/admin/services/treatment/TreatmentRequests'

interface ITableTreatmentCPProps {
    onEdit: (code: number) => void
    forceLoadList: boolean
    onListLoaded: () => void
}

/**
 * Listagem de cargos e funcoes.
 */
export function TableTreatmentCP(props: ITableTreatmentCPProps): JSX.Element {

    useEffect(init, [props.forceLoadList])

    const [treatments, setTreatments] = useState<ITreatmentResponseDTO[]>()

    const searchTreatmentsRequest = useRequest<IGenericListResponseDTO<ITreatmentResponseDTO>>()
    useEffect(onSearchTreatmentsRequestChange, [searchTreatmentsRequest.isAwaiting])

    /**
     * Obtem listagem de cargos e funcoes.
     */
    function init(): void {

        if (!props.forceLoadList)
            return

        getTreatments()
    }

    /**
     * Obtem lista de cargos da API.
     */
    function getTreatments(): void {
        searchTreatmentsRequest.runRequest(TreatmentRequests.searchTreatment())
    }

    /**
     * Retorno da requisicao de cargos e funcoes.
     */
    function onSearchTreatmentsRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(searchTreatmentsRequest, 'Não foi possível obter lista de tratamentos.'))
            return

        setTreatments(searchTreatmentsRequest.responseData!.list)
        props.onListLoaded()
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableCPOLD2
                data={treatments}
                loading={searchTreatmentsRequest.isAwaiting}
                columns={TreatmentTableUtils.getTableColumns(props.onEdit)}
                rowKey={(record): string => `${record.code}`}
            />
        </CardCP>
    )
}
