import React from 'react'
import styled from 'styled-components'
import { TableProductReleaseTreatmentTableCP } from 'main/modules/products/components/table-product-release-treatment/TableProductReleaseTreatmentTableCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { IProductReleaseFranchiseResponseDTO } from 'main/modules/products/services/product-release-franchise/dtos/response/IProductReleaseFranchiseResponseDTO'
import { ResumeTextItemCP } from 'main/common/components/resume-text-item/ResumeTextItemCP'
import { PriceRangeSliderInputCP } from 'main/modules/products/components/price-range-slider-input/PriceRangeSliderInputCP'
import { RegistroComissao } from '../product-release-comissoes/RegistroComissao'

interface IProductReleaseDetailsCPProps {
    onEdiDone: () => void
    productReleaseFranchise: IProductReleaseFranchiseResponseDTO
}

/**
 * Conteúdo do drawer de detalhes de um pacote de atendimento
 */
export function ProductReleaseDetailsCP(props: IProductReleaseDetailsCPProps): JSX.Element {

    return (
        <ContentWrapperSCP>
            {
                props.productReleaseFranchise.code &&
                <ResumeTextItemCP
                    itemTitle={'Código: '}
                    itemText={`#${props.productReleaseFranchise.code}`}
                />
            }

            <ResumeTextItemCP
                itemTitle={'Nome: '}
                itemText={props.productReleaseFranchise.productRelease.name}
            />

            <TitleCP textSize={'large'} underLine={true} marginTop={30}>
                Valor
            </TitleCP>
            <PriceRangeSliderInputCP
                maxValue={props.productReleaseFranchise.productRelease.maxPriceValue}
                minValue={props.productReleaseFranchise.productRelease.minPriceValue}
                hasPrice={props.productReleaseFranchise.price !== undefined}
                value={props.productReleaseFranchise.price ?? props.productReleaseFranchise.productRelease.minPriceValue}
                productReleaseCode={props.productReleaseFranchise.productRelease.code}
                productCode={props.productReleaseFranchise.code}
                onEditDone={props.onEdiDone}
            />

            <RegistroComissao 
                 codigoProduto={props.productReleaseFranchise.productRelease.code}
                 geral={props}
                 onEditDone={props.onEdiDone}
            />
            

            <TitleCP textSize={'large'} underLine={true} marginTop={40}>
                Atendimentos do pacote
            </TitleCP>
            <TableProductReleaseTreatmentTableCP treatments={props.productReleaseFranchise.productRelease.treatmentsConfig}/>

        </ContentWrapperSCP>
    )
}
const ContentWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0;
    width: 100%;
`
