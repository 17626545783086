import React from 'react'
import { TreatmentFormResponseDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/response/TreatmentFormResponseDTO'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'

interface ICPProps {
    treatmentForm: TreatmentFormResponseDTO
}

/**
 * Linha quando expandida da tabela de formularios.
 */
export function TableTreatmentFormsRowExpandedICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP underLine={true} textSize={'normal'}>
                Campos do Formulário
            </TitleCP>
            {
                props.treatmentForm.formQuestions?.map((question) => (
                    <TitleAndTextCP
                        title={`${question.order}`}
                        text={question.questionTitle}
                    />
                ))
            }
            {
                SystemUtils.isEmpty(props.treatmentForm.formQuestions) &&
                <EmptyCP description={'Nenhum campo cadastrado neste formulário'}/>
            }
        </>
    )
}
