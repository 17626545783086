import React, { useEffect, useState } from 'react'
import { SelectFranchiseCP } from 'main/modules/app/components/select-franchise/SelectFranchiseCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ISaleSourcesReportFilterData } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/inner/ISaleSourcesReportFilterData'
import { FilterPanelCP } from 'main/common/components/screen-layout/filters/filter-panel/FilterPanelCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import moment from 'moment'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'

interface ISaleSourceReportFiltersICPProps {
    onChangeReportData: (updatedReportData: ISaleSourcesReportFilterData) => void
}

/**
 * Filtros do Relatorio de Origem das Vendas
 */
export function SaleSourceReportFiltersICP(props: ISaleSourceReportFiltersICPProps): JSX.Element {

    // Mantem as datas em estado aqui local pq senao nao o componente de datas nao gerencia
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    const [filterData, setFilterData] = useState<ISaleSourcesReportFilterData>({})

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        const initialDate = {
            beginDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate()
        }
        setDateFilters(initialDate)

        const filtersData = {
            dateRange: initialDate,
            franchiseCodes: AppStateUtils.getCurrentFranchise()!.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code]
        }
        setFilterData(filtersData)

        props.onChangeReportData(filtersData)
    }

    /**
     * Ao mudar as datas
     */
    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
        filterData.dateRange = dates
        props.onChangeReportData(filterData)
    }

    return (
        <FilterPanelCP>
            <RowCP spaceBetweenCols={20} align={'middle'}>

                <ColumnCP size={8}>
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={onChangeDates}
                        fastFilter={'all'}
                        allowClear={false}
                        showBorder={true}
                    />
                </ColumnCP>

                <ConditionalRenderCP shouldRender={AppStateUtils.getUserFranchises()!.length > 1}>
                    <ColumnCP size={10}>
                        <SelectFranchiseCP
                            isMultiple={true}
                            onChange={(franchises) => {
                                if (!franchises)
                                    return
                                filterData.franchiseCodes = Array.isArray(franchises) ? franchises : [franchises]
                                props.onChangeReportData(filterData)
                            }}
                            initialFranchises={AppStateUtils.getCurrentFranchise()!.isFranchisor ? undefined : [AppStateUtils.getCurrentFranchise()!.code]}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>

                <ColumnCP size={6}>
                    <AutocompleteUserCP
                        label={'Vendedor'}
                        onChange={(seller) => {
                            filterData.userSellerCode = seller
                            props.onChangeReportData(filterData)
                        }}
                    />
                </ColumnCP>
            </RowCP>
        </FilterPanelCP>
    )
}
