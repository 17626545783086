

export class ExcelUtil {

    static excel(dt:any,campos:any){
        let r = '';
        campos.forEach((c:any)=>r+=`${c[0]?c[0]+';':''}`);
        r+='\n';
        dt.forEach((reg:any)=>{
          campos.forEach((c:any)=>{
            if(c[0]){
              let v = reg[c[1]];
              if(ExcelUtil.isFloat(v))v = ExcelUtil.VALOR(v);
              if(!ExcelUtil.isFloat(v))v = ExcelUtil.TEXTO(v);
              r+=`${v};`
            }
          });
          r+='\n';
        });
        return r;
      }

      static isFloat(input:any) {
        return !isNaN(parseFloat(input));
      }

      static TEXTO = (v:any)=>{
        if(!v || v=='null')return '';
        return ExcelUtil.removerAcentos((v+'').toUpperCase()).trim();
      }
      static VALOR = (v:any)=>{
        return (v + '').replace('.', ',').trim();
      }

      static removerAcentos(s:any) {
        if (!s) return '';
        return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      }

      static salvarTexto(filename:any, data:any) {
        let blob = new Blob([data], { type: 'text/csv' });
        if (window.navigator['msSaveOrOpenBlob']) {
          window.navigator['msSaveBlob'](blob, filename);
        }
        else {
          const elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      }
}