import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { TagFinancialTransactionStatusCP } from 'main/modules/financial/components/tag-financial-transaction-status/TagFinancialTransactionStatusCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { ButtonUniquePaymentMethodActionCP } from 'main/modules/sale/components/button-unique-payment-method-action/ButtonUniquePaymentMethodActionCP'
import { ButtonPayFinancialTransactionCP } from 'main/modules/financial/components/button-pay-financial-transaction/ButtonPayFinancialTransactionCP'
import { ColumnTransactionValueCP } from 'main/modules/financial/components/column-transaction-value/ColumnTransactionValueCP'
import { ColumnTransactionExpirationDateCP } from 'main/modules/financial/components/column-transaction-expiration-date/ColumnTransactionExpirationDateCP'
import { ColumnTransactionReferenceDateCP } from 'main/modules/financial/components/column-transaction-reference-date/ColumnTransactionReferenceDateCP'
import { HelpChargeStatusCP } from 'main/modules/sale/components/charge-status/HelpChargeStatusCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ButtonNFeCP } from 'main/modules/sale/components/button-nfe/ButtonNFeCP'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'
import { ButtonChargeRetryCP } from 'main/modules/financial/components/buttons/button-charge-retry/ButtonChargeRetryCP'
import { ButtonChargeVirtualLinksCP } from 'main/modules/sale/components/button-charge-virtual-links/ButtonChargeVirtualLinksCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { ButtonChargeCancellationCP } from 'main/modules/financial/components/buttons/button-charge-cancellation/ButtonChargeCancellationCP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

export const SaleInstallmentsTableUtils = {
    
    getInstallmentsTableColumns(
        onUpdateAnyData: () => void,
        charge: IChargeListItemResponseDTO,
        onReloadNFe?: () => void,
        nfeData?: {
            hasSaleNfe: boolean
            sale?: ISaleResponseDTO,
            nfes?: NFeResponseDTO<NFeCustomArgsTP>[],
        },
    ): Array<ColumnProps<IFinancialTransactionCreditListItemResponseDTO>> {

        return [
            {
                key: 'installmentNumber',
                align: 'center',
                render: (text, installment) => `${installment.installmentNumber}/${charge.financialTransactions.length}`
            },
            {
                key: 'status',
                render: (text, installment) => (
                    <>
                        <TagFinancialTransactionStatusCP status={installment.status} expirationDate={DateUtils.toDate(installment.expirationDate, DateFormatEnum.US_WITHOUT_TIME)}/>
                        {
                            !!installment.chargeData &&
                            <HelpChargeStatusCP chargeData={installment.chargeData}/>
                        }
                    </>
                )
            },
            {
                title: 'Dt. Referência',
                key: 'refDate',
                render: (text, installment) => <ColumnTransactionReferenceDateCP referenceDate={installment.referenceDate}/>
            },
            {
                title: 'Vencimento',
                key: 'expirationDate',
                render: (text, installment) => <ColumnTransactionExpirationDateCP expirationDate={installment.expirationDate} paymentDate={installment.paymentDate}/>
            },
            {
                title: 'Valor',
                key: 'value',
                align: 'right',
                render: (text, installment) => <ColumnTransactionValueCP value={installment.value} paidValue={installment.paidValue}/>
            },
            {
                key: 'actions',
                render: (text, installment) => {
                    if(AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_FINANCIAL) || AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN) ){
                        return (
                            <FlexCP justifyContent={'flex-end'}>
                                {
                                    installment.chargeData?.chargeURL &&
                                    <ButtonChargeVirtualLinksCP
                                        chargeUrl={installment.chargeData?.chargeURL}
                                        buttonType={'ghost'}
                                        buttonSize={'small'}
                                        hideText={true}
                                    />
                                }
                                <ButtonChargeRetryCP
                                    charge={charge}
                                    financialTransaction={installment}
                                    onChangeData={onUpdateAnyData}
                                    margin={{ right: 5, left: 5 }}
                                />
                                <ButtonChargeCancellationCP
                                    charge={charge}
                                    financialTransaction={installment}
                                    onChangeData={onUpdateAnyData}
                                    margin={{ right: 5, left: 5 }}
                                />
                                <ButtonPayFinancialTransactionCP
                                    financialTransactionCode={installment.code}
                                    financialTransactionStatus={installment.status}
                                    onChangePayment={onUpdateAnyData}
                                    showText={true}
                                    marginRight={5}
                                    marginLeft={5}
                                />
                                <ButtonUniquePaymentMethodActionCP
                                    financialTransaction={installment}
                                    onUpdateAnyData={onUpdateAnyData}
                                    chargeData={charge.chargeData}
                                />
                                <>
                                    {
                                        !(AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.ignorarCartaoSemRecorrencia && installment?.paymentMethod == 'CREDIT_CARD') &&
                                        AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.shouldIssueNFEByInstallment &&
                                        !nfeData?.hasSaleNfe && !!nfeData?.sale &&
                                        <ButtonNFeCP
                                            sale={nfeData.sale}
                                            viewMode={'installmentNfe'}
                                            nfe={nfeData.nfes?.find((nfe) => nfe.customArgs?.financialTransactionCode === installment.code)}
                                            size={'small'}
                                            financialTransactionCode={installment.code}
                                            onReloadNfe={onReloadNFe}
                                        />
                                    }
                                </>
                            </FlexCP>
                        )
                    }else{
                        return <></>
                    }
                    
                        }
            },
        ]
    },
}
