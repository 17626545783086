import { styled } from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React from 'react'
import { MapPinTP } from 'main/common/components/map/inner/MapPinTP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'

type PinTextScpPropsTP = { isLong: boolean }
type WrapperScpPropsTP = Pick<MapPinTP, 'color'> & PinTextScpPropsTP & { cursorPointer: boolean }

/**
 * COMPONENTE
 * Marcador do mapa.
 *
 * NOTE: Coordenadas devem ser props do componente para cumprir especificacao da biblioteca de mapas sem causar erro de tipagem.
 */
export function PinICP(props: MapPinTP): JSX.Element {

    const isLong = (props.text.length > 2)

    const pinCP = (
        <WrapperSCP
            color={props.color}
            cursorPointer={!!props.popOverContent || !!props.onClick}
            onClick={props.onClick}
            isLong={isLong}
        >
            <PinTextSCP isLong={isLong}>{props.text}</PinTextSCP>
        </WrapperSCP>
    )

    return (
        !props.popOverContent
            ?
            pinCP
            :
            <PopOverCP trigger={'hover'} title={props.popOverTitle} content={props.popOverContent}>
                {pinCP}
            </PopOverCP>
    )
}

const WrapperSCP = styled.div<WrapperScpPropsTP>`

    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    background: ${props => (props.color ?? ThemeAnt.primaryColor)};
    border: 1px solid ${ThemeAnt.white};
    border-radius: 50px;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: ${props => (props.cursorPointer ? 'pointer' : 'default')};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .8;

    &:hover {
        z-index: 1;
    }
`

const PinTextSCP = styled.span<PinTextScpPropsTP>`
    margin: 2px 0;
    font-weight: bold;
    font-size: ${props => (props.isLong ? 11 : 13)}px;
    color: white;
`
