import React from 'react'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faBan, faCheckDouble, faClock, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { SessionUtils } from 'main/modules/scheduler/utils/SessionUtils'

interface ICPProps {
    status: SessionStatusEnum
    scheduledDate?: Date
}

/**
 * Icone para status de um agendamento.
 */
export function IconAppointmentStatusCP(props: ICPProps): JSX.Element {

    let icon: JSX.Element = <></>

    switch (props.status) {

        case SessionStatusEnum.CONCLUDED:
            icon = <FontAwsomeIconCP type={faCheckDouble}/>
            break

        case SessionStatusEnum.NO_SHOW:
            icon = <FontAwsomeIconCP type={faUserSlash}/>
            break

        case SessionStatusEnum.AWAITING:
            icon = <FontAwsomeIconCP type={faClock}/>
            break

        case SessionStatusEnum.SCHEDULED:

            if (SessionUtils.isScheduleInFuture(props.scheduledDate))
                icon = <FontAwsomeIconCP type={faClock}/>
            else
                icon = <FontAwsomeIconCP type={faBan}/>

            break

        default:
            break
    }

    return icon
}

