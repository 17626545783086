import React from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunitySourceFormModel } from 'main/modules/products/components/drawer-opportunity-source/inner/OpportunitySourceFormModel'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'

interface IOpportunitySourceFormICPProps {
    formStateManager: IFormStateManager<OpportunitySourceFormModel>
}

/**
 * Tela de listagem de tratamentos.
 */
export function OpportunitySourceFormICP(props: IOpportunitySourceFormICPProps): JSX.Element {

    return (
        <>
            <InputCP
                label={'Nome'}
                fontSize={'extraLarge'}
                fieldName={'name'}
                formStateManager={props.formStateManager}
                required={true}
            />

            <SwitchCP
                isTextInside={false}
                textOutside={'Ativo?'}
                formStateManager={props.formStateManager}
                fieldName={'isActive'}
                marginBottom={20}
            />

            <SwitchCP
                isTextInside={false}
                textOutside={'Solicitar Detalhes?'}
                formStateManager={props.formStateManager}
                fieldName={'hasDetail'}
                marginBottom={20}
                hint={{ type: 'text', text: 'Ao selecionar essa opção, na hora da venda/lead será solicitado selecionar uma pessoa (ex.: indicado, parceria, influencer, etc.)' }}
            />
        </>
    )
}
