import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import styled from 'main/config/theme/styledWithTheme'
import { LINE_CHART_CONFIG_OPTIONS } from 'main/common/components/chart/inner/LineChartConfigOptions'
import { BAR_CHART_CONFIG_OPTIONS } from 'main/common/components/chart/inner/BarChartConfigOptions'

export type ChartSeriesTP = {
    data: number[],
    name: string,
}

interface IScpProps {
    height?: string
}

interface IChartLineOrBarCPProps extends IScpProps{
    xAxis: string[]
    ySeries: ChartSeriesTP[]
    type: 'line' | 'bar'
}

/**
 * COMPONENTE Grafico de Linha ou Barra.
 */
export function ChartLineOrBarCP(props: IChartLineOrBarCPProps): JSX.Element {

    const chartRef = useRef<any>(null)
    const chartElement = useRef<any>(null)

    useEffect(onMount, [])
    useEffect(updateChartData, [props.ySeries, props.xAxis])

    function onMount(): void {
        chartElement.current = new ApexCharts(chartRef.current, props.type === 'line' ? LINE_CHART_CONFIG_OPTIONS.getConfig(props.height) : BAR_CHART_CONFIG_OPTIONS)
        chartElement.current.render()
    }

    function updateChartData(): void {

        if (!props.ySeries || !chartElement.current)
            return

        chartElement.current.updateSeries(props.ySeries)
        chartElement.current.updateOptions({
            xaxis: { categories: props.xAxis }
        })
    }

    return (
        <ChartWrapperSCP>
            <div ref={chartRef}/>
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
`
