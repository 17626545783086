import { RequestHeaderTP } from 'main/common/request-manager/types/RequestHeaderTP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * UTILITARIOS para lidar com AUTENTICACAO.
 */
export const AuthUtils = {

    /**
     * Retorna valor a ser utilizado para configurar header 'authorization' para
     * autenticaco do tipo 'Bearer'.
     */
    getBearerAuthHeaderValue(token: string): string {
        return `Bearer ${token}`
    },

    /**
     * Retorna config para determinar 01 cabecalho de autenticacao do tipo 'bearer'
     * numa requisicao gerida com mecanismo de gestao de requisicoes http da aplicacao.
     */
    getBearerAuthHeaderConfig(token: string): RequestHeaderTP {
        return {
            headerName: 'Authorization',
            headerValue: AuthUtils.getBearerAuthHeaderValue(token),
        }
    },

    /**
     * Retorna config para determinar 01 cabecalho definindo o parametro 'schema'
     * numa requisicao gerida com mecanismo de gestao de requisicoes http da aplicacao.
     */
    getSchemaHeaderConfig(schema: string): RequestHeaderTP {
        return {
            headerName: 'schema',
            headerValue: schema,
        }
    },

    isUserLogged(): boolean {
        return (
            !!AppStateUtils.getCurrentFranchise()
            && !!AppStateUtils.getLoggedUserData()
            && !!AppStateUtils.getDomainData()
        )
    }
}
