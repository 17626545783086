import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableTreatmentCP } from 'main/modules/admin/components/table-treatment/TableTreatmentCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { DrawerTreatmentCP } from 'main/modules/products/components/drawer-treatment/DrawerTreatmentCP'

/**
 * Tela de listagem de tratamentos.
 */
export function ScreenContentTreatmentCP(): JSX.Element {

    const [shouldForceUpdateList, setShouldForceUpdateList] = useState<boolean>(true)
    const [isTreatmentDrawerVisible, setIsTreatmentDrawerVisible] = useState<boolean>(false)
    const [selectedTreatmentCode, setSelectedTreatmentCode] = useState<number>()

    return (
        <>
            <HeaderCP title={'Atendimentos'}>
                <HeaderButtonICP
                    onClick={() => {
                        setSelectedTreatmentCode(undefined)
                        setIsTreatmentDrawerVisible(true)
                    }}
                    icon={'plus'}
                    label={'Novo Atendimento'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableTreatmentCP
                    forceLoadList={shouldForceUpdateList}
                    onListLoaded={() => setShouldForceUpdateList(false)}
                    onEdit={(code) => {
                        setIsTreatmentDrawerVisible(true)
                        setSelectedTreatmentCode(code)
                    }}
                />
            </LayoutSubmenuContentCP>

            <DrawerTreatmentCP
                visible={isTreatmentDrawerVisible}
                treatmentCode={selectedTreatmentCode}
                onClose={(dataChanged) => {
                    if (dataChanged)
                        setShouldForceUpdateList(true)
                    setIsTreatmentDrawerVisible(false)
                }}
            />
        </>
    )
}
