import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { DescriptionCP } from 'main/common/components/description/DescriptionCP'
import { DescriptionItemCP } from 'main/common/components/description/description-item/DescriptionItemCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IDokfyDocumentAssigneeResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyDocumentAssigneeResponseDTO'
import { ListCP } from 'main/common/components/list/ListCP'
import { ListItemICP } from 'main/common/components/list/inner/ListItemICP'
import { DocumentStatusCP } from 'main/modules/documents/components/document-status/DocumentStatusCP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonChangeDokfyUserEmailCP } from 'main/modules/documents/components/button-change-dokfy-user-email/ButtonChangeDokfyUserEmailCP'

interface IDocumentAssigneesTableCPProps {
    assignees: IDokfyDocumentAssigneeResponseDTO[]
    originalAssigneesData: IPersonResponseDTO[]
    onUpdateData: () => void
}

/**
 * Tabela de signatarios de um documento ja enviado para assinatura.
 */
export function DocumentAssigneesTableCP(props: IDocumentAssigneesTableCPProps): JSX.Element {

    return (
        <ListCP<IDokfyDocumentAssigneeResponseDTO>
            dataSource={props.assignees}
            renderItem={(assignee) => (
                <ListItemICP
                    title={assignee.person.name}
                    content={
                        <AssigneeItemSCP>
                            <DescriptionCP>
                                <DescriptionItemCP label={'CPF'}>
                                    <>{ MaskUtils.applyMask(assignee.person.cpfCnpj, InputMaskTypeEnum.CPF) }</>
                                </DescriptionItemCP>
                                <DescriptionItemCP label={'E-mail'}>
                                    <span>{assignee.person.email}</span>
                                </DescriptionItemCP>
                            </DescriptionCP>
                            <FlexCP justifyContent={'space-between'} marginTop={10}>
                                <ButtonChangeDokfyUserEmailCP
                                    originalAssigneeData={props.originalAssigneesData.find((person) => person.cpfCnpj === assignee.person.cpfCnpj)}
                                    assignee={assignee.person}
                                    onSyncEnd={props.onUpdateData}
                                />
                                <DocumentStatusCP status={assignee.signed}/>
                            </FlexCP>
                        </AssigneeItemSCP>
                    }
                />
            )}
        />
    )
}

const AssigneeItemSCP = styled.div`
  .ant-descriptions-row > td {
      padding-bottom: 0px !important;
  }
`
