import { FormModel } from 'main/common/form-state-manager/FormModel'
import React from 'react'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { TreatmentFormRequests } from 'main/modules/admin/services/treatment-form/TreatmentFormRequests'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
}

/**
 * COMPONENTE de Seletor de um Formulario para evolucao de sessao.
 */
export function AutocompleteFormCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={TreatmentFormRequests.searchNameAndCode}
            showOptionsOnLoad={true}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}