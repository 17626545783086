import React from 'react'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    isChecked: boolean
    onChangeChecked: (checked: boolean) => void
    title: string
    description: string
}

/**
 * COMPONENTE que exibe uma permissao
 */
export function UserPermissionsDataICP(props: ICPProps): JSX.Element {

    return (
        <BasicStyleWrapperCP margin={props.margin ?? { bottom: 15 }}>
            <FlexCP alignItems={'center'} marginBottom={10}>
                <SwitchCP
                    isTextInside={false}
                    isChecked={props.isChecked}
                    onChange={props.onChangeChecked}
                />

                <TitleCP
                    underLine={true}
                    marginLeft={15}
                    marginBottom={0}
                    textSize={'normal'}
                >
                    {props.title}
                </TitleCP>
            </FlexCP>

            { props.description }
        </BasicStyleWrapperCP>

    )
}
