/**
 * ENUM
 * Define valores para critério de ordenação em requisições de listas.
 *
 * @author hjcostabr
 */
export enum OrderingEnum {
    ASC = 'ascend',
    DESC = 'descend'
}
