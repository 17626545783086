import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import styled from 'main/config/theme/styledWithTheme'
import React, { useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { DrawerOpportunityDetailsCP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/DrawerOpportunityDetailsCP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 * COMPONENTE de acoes rapidas como registrar um cliente/venda/etc.
 */
export function OmniButtonCP(): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
    const [isNewPersonDrawerVisible, setIsNewPersonDrawerVisible] = useState<boolean>(false)
    const [isNewLeadDrawerVisible, setIsNewLeadDrawerVisible] = useState<boolean>(false)
    const [isPersonDetailsDrawerVisible, setIsPersonDetailsDrawerVisible] = useState<boolean>(false)
    const [personCode, setPersonCode] = useState<number>()

    function onCreatePerson(personCreated: IPersonResponseDTO): void {
        setIsNewPersonDrawerVisible(false)
        setPersonCode(personCreated.code)
        setIsPersonDetailsDrawerVisible(true)
    }

    return (
        <>
            <ContentWrapperSCP>
                <PopOverCP
                    trigger={'click'}
                    placement={'right'}
                    visible={isPopoverVisible}
                    onClose={(): void => setIsPopoverVisible(false)}
                    content={
                        // Colocando o Visible para exibir ou nao o conteudo para que seja destruido o componetne ao fechar,
                        // assim elimina problema de nao poder selecionar pela segunda vez
                        <ConditionalRenderCP shouldRender={isPopoverVisible}>
                            <ContainerWrapperSCP>
                                <AutocompletePersonCP
                                    label={'Busque por Nome / CPF / E-mail / Tel.'}
                                    considerUser={true}
                                    considerCustomer={true}
                                    considerSupplier={true}
                                    filterOption={false}
                                    onChange={(_personCode) => {
                                        if(_personCode){
                                            setIsPopoverVisible(false)
                                            setIsPersonDetailsDrawerVisible(true)
                                            setPersonCode(_personCode)
                                        }
                                    }}
                                    width={300}
                                />
                            </ContainerWrapperSCP>

                            <FlexCP justifyContent={'space-between'} marginTop={30}>
                                <ButtonCP
                                    icon={'plus'}
                                    tooltip={'Adicione como LEAD caso a pessoa ainda passará pelo fluxo de vendas.'}
                                    type={'ghost'}
                                    onClick={() => {
                                        setIsPopoverVisible(false)
                                        setIsNewLeadDrawerVisible(true)
                                    }}
                                >
                                    LEAD
                                </ButtonCP>
                                <ButtonCP
                                    icon={'plus'}
                                    tooltip={'Adicione como CLIENTE caso você já queira registrar uma venda.'}
                                    type={'primary'}
                                    onClick={() => {
                                        setIsPopoverVisible(false)
                                        setIsNewPersonDrawerVisible(true)
                                    }}
                                >
                                    Cliente
                                </ButtonCP>
                            </FlexCP>
                        </ConditionalRenderCP>
                    }
                >
                    <ButtonCP
                        tooltip={'Ações Rápidas'}
                        tooltipPlacement={'topLeft'}
                        onClick={(): void => setIsPopoverVisible(true)}
                        type={'link'}
                    >
                        <FontAwsomeIconCP type={faSearch} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>
                    </ButtonCP>
                </PopOverCP>
            </ContentWrapperSCP>

            <DrawerPersonDataCP
                visible={isNewPersonDrawerVisible}
                onClose={() => setIsNewPersonDrawerVisible(false)}
                onSave={onCreatePerson}
                segmentType={SegmentTypeEnum.CUSTOMER}
            />

            <DrawerPersonDetailsCP
                visible={isPersonDetailsDrawerVisible}
                personCode={personCode}
                onClose={() => setIsPersonDetailsDrawerVisible(false)}
                onSave={() => setIsPersonDetailsDrawerVisible(false)}
                segmentType={SegmentTypeEnum.CUSTOMER}
            />

            <DrawerOpportunityDetailsCP
                visible={isNewLeadDrawerVisible}
                onClose={() => setIsNewLeadDrawerVisible(false)}
            />
        </>
    )
}

const ContentWrapperSCP = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const ContainerWrapperSCP = styled.div`
    .ant-form-explain {
        display: none;
    }
`

