import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { IUserAttendancesResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendancesResponseDTO'
import { SearchScheduleAppointmentsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/SearchScheduleAppointmentsRequestDTO'
import { ScheduleRequests } from 'submodules/neritclin-sdk/services/schedule/ScheduleRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import * as _ from 'lodash'
import { ScheduleEventUtils } from 'main/modules/scheduler/utils/ScheduleEventUtils'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import styled from 'styled-components'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { TableAttendanceQueuesUtils } from 'main/modules/scheduler/components/table-attendance-queues/inner/TableAttendanceQueuesUtils'
import { DrawerAppointmentSessionDetailsCP } from 'main/modules/scheduler/components/drawer-appointment-session-details/DrawerAppointmentSessionDetailsCP'
import { IAppointmentDetails } from 'main/modules/scheduler/interfaces/IAppointmentDetails'
import { EventApi } from '@fullcalendar/core/api/EventApi'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ThemeProjectCommon } from 'submodules/neritclin-components-ui/theme/project/white-labels/ThemeProjectCommon'
import { TableTopBarICP } from 'submodules/nerit-framework-ui/common/components/table/inner/TableTopBarICP'

interface ICPProps {
    filterStateManager: IFormStateManager<FormFilterCalendarEventsFormModel>
    shouldReloadReport: number
}

/**
 * COMPONENTE Tabela de agendamentos
 */
export function TableAttendanceQueuesCP(props: ICPProps): JSX.Element {

    const [isEventModalVisible, setIsEventModalVisible] = useState<boolean>(false)
    const [selectedEvent, setSelectedEvent] = useState<IAppointmentDetails>()

    const [events, setEvents] = useState<IFullCalendarEvent[]>()

    const [userAttendances, setUserAttendances] = useState<IUserAttendancesResponseDTO[]>([])
    const calendarEventsRequest = useRequest<IUserAttendancesResponseDTO[]>()
    useEffect(onCalendarEventsRequestChange, [calendarEventsRequest.isAwaiting])

    useEffect(init, [props.shouldReloadReport])

    /**
     * Carrega relatorio.
     */
    function init(): void {

        if (!props.shouldReloadReport)
            return

        setEvents(undefined)
        setUserAttendances([])
        const formValues = props.filterStateManager.getFormValues()!

        const searchDto: SearchScheduleAppointmentsRequestDTO = {
            dateRange: formValues.dateInterval,
            userCodes: formValues.userCodes,
            treatmentGroupCodes: formValues.treatmentGroupCodes
        }
        calendarEventsRequest.runRequest(ScheduleRequests.search(searchDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onCalendarEventsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(calendarEventsRequest, 'Não foi possível obter agendamentos'))
            return

        const result = calendarEventsRequest.responseData!
        setUserAttendances(result)

        const calendarEvents = _.flatten(result.map((responseDto) => ScheduleEventUtils.groupNearestAppointmentsByCustomer(responseDto, false)))
        const orderedEvents: IFullCalendarEvent[] = [
            ... calendarEvents.filter((evt) => evt.id == SessionStatusEnum.IN_PROGRESS),
            ... calendarEvents.filter((evt) => evt.id == SessionStatusEnum.CHECK_IN_DONE),
            ... calendarEvents.filter((evt) => evt.id == SessionStatusEnum.SCHEDULED),
            ... calendarEvents.filter((evt) => evt.id == SessionStatusEnum.CONCLUDED),
        ]
        setEvents(orderedEvents)
    }

    /**
     * Ao clicar para editar um evento.
     */
    function onEditEvent(event: IFullCalendarEvent): void {

        if (!event.resourceId)
            return NotificationHelper.error('Ops!', 'Código do responsável não encontrado')

        const appointmentDetailsData = ScheduleEventUtils.mountAppointmentDetails(event as EventApi, userAttendances, +event.resourceId)
        setSelectedEvent(appointmentDetailsData)

        setIsEventModalVisible(true)
    }

    return (
        <WrapperSCP>
            <TableTopBarICP
                wrappedOnCard={true}
                showReloadButton={true}
                loading={calendarEventsRequest.isAwaiting}
                onClick={init}
            />

            <TableCP
                wrappedOnCard={true}
                loading={calendarEventsRequest.isAwaiting}
                data={events}
                rowClassName={(record) => record.id}
                columns={TableAttendanceQueuesUtils.getColumns(
                    onEditEvent,
                    (item) => TableAttendanceQueuesUtils.getUserAttendant(userAttendances, item.resourceId)?.name
                )}
            />

            {
                selectedEvent &&
                <DrawerAppointmentSessionDetailsCP
                    visible={isEventModalVisible}
                    appointmentDetailsData={selectedEvent}
                    onClose={(wasDataChanged) => {
                        setSelectedEvent(undefined)
                        setIsEventModalVisible(false)

                        if (wasDataChanged)
                            init()
                    }}
                />
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  tr.${SessionStatusEnum.CONCLUDED} {
    background: ${ThemeProjectCommon.schedulerSessionConcluded};
    color: #fff;
  }

  tr.${SessionStatusEnum.CHECK_IN_DONE} {
    background: ${ThemeProjectCommon.schedulerSessionCheckinDone};
    color: #fff;
  }

  tr.${SessionStatusEnum.IN_PROGRESS} {
    background: ${ThemeProjectCommon.schedulerSessionInProgress};
    color: #fff;
  }
`
