import React, { useState } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { CreditCardDataFormModel } from 'main/modules/financial/components/credit-card-data/inner/CreditCardDataFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
    formStateManager: IFormStateManager<CreditCardDataFormModel>
}

/**
 */
export function CreditCardDataCP(props: ICPProps): JSX.Element {

    const [fieldFocus, setFieldFocus] = useState<any>()

    return (
        <>
            <Cards
                focused={fieldFocus}
                cvc={props.formStateManager.getFieldValue('cvc') ?? ''}
                expiry={props.formStateManager.getFieldValue('expiry') ?? ''}
                name={props.formStateManager.getFieldValue('name') ?? ''}
                number={props.formStateManager.getFieldValue('number') ?? ''}
            />

            <FlexCP justifyContent={'center'} margin={{ top: 10 }}>
                <FormWrapperSCP>
                    <InputCP
                        label={'Número do Cartão'}
                        formStateManager={props.formStateManager}
                        fieldName={'number'}
                        onFocus={() => setFieldFocus('number')}
                        maxlength={16}
                    />

                    <InputCP
                        label={'Nome (como aparece no cartão)'}
                        formStateManager={props.formStateManager}
                        onFocus={() => setFieldFocus('name')}
                        fieldName={'name'}
                    />

                    <RowCP>
                        <ColumnCP size={16}>
                            <InputCP
                                label={'Validade'}
                                formStateManager={props.formStateManager}
                                fieldName={'expiry'}
                                onFocus={() => setFieldFocus('expire')}
                                maxlength={5}
                                keepMask={true}
                                mask={InputMaskTypeEnum.DATE_MM_YY}
                            />
                        </ColumnCP>
                        <ColumnCP size={8}>
                            <InputCP
                                label={'CVC'}
                                formStateManager={props.formStateManager}
                                maxlength={3}
                                fieldName={'cvc'}
                                onFocus={() => setFieldFocus('cvc')}
                            />
                        </ColumnCP>
                    </RowCP>

                </FormWrapperSCP>
            </FlexCP>
        </>
    )
}

const FormWrapperSCP = styled.div`
    width: 290px;
`

