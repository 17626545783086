import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { HelpCP } from 'main/common/components/help/HelpCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import styled from 'main/config/theme/styledWithTheme'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'

interface ICProps {
    title: string
    value: number
    target?: number
    help?: string
    type: 'ratio' | 'target'
    valueMask?: InputMaskTypeEnum.MONEY | string
}

export function CardDashboardWidgetRateFooterICP(props: ICProps): JSX.Element {

    return (
        <WrapperSCP>
            <FlexCP alignItems={'center'} justifyContent={!!props.help ? 'space-between' : undefined}>
                <TextCP text={props.title}/>
                {
                    !!props.help &&
                    <HelpCP
                        showOnlyIcon={true}
                        marginTop={0}
                        marginBottom={0}
                        text={props.help}
                        type={'tooltip'}
                    />
                }
            </FlexCP>

            {
                props.target !== undefined
                    ?
                    <>
                        <ProgressCP
                            percent={MathUtils.getPercentage(props.value, props.target, true)}
                            backgroudColorArea={'#FFF'}
                            gradientColor={true}
                        />
                        {
                            props.type === 'target'
                                ?
                                <FlexCP justifyContent={'space-between'}>
                                    <TextCP
                                        text={`Feito: ${MaskUtils.applyNumberMask(props.value, 2)}`}
                                        color={ThemeAnt.gray}
                                    />
                                    <TextCP
                                        text={`Meta: ${MaskUtils.applyNumberMask(props.target, 2)}%`}
                                        color={ThemeAnt.gray}
                                    />
                                </FlexCP>
                                :
                                <FlexCP justifyContent={'space-between'}>
                                    <TextCP
                                        text={`Feito: ${MaskUtils.applyNumberMask(props.value, 2)}`}
                                        color={ThemeAnt.gray}
                                    />
                                    <TextCP
                                        text={`Total: ${MaskUtils.applyNumberMask(props.target, 2)}`}
                                        color={ThemeAnt.gray}
                                    />
                                </FlexCP>
                        }
                    </>
                    :
                    <TextCP text={props.valueMask === InputMaskTypeEnum.MONEY ? MaskUtils.applyMoneyMask(props.value) : MaskUtils.applyNumberMask(props.value, 2)} color={ThemeAnt.gray}/>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  border-top: solid 1px #F1F1F1;
  padding: 10px;
`
