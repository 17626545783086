import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { MarketingSenderStatusEnum } from 'main/modules/marketing/enums/MarketingSenderStatusEnum'
import React from 'react'

interface IStatusColumnICPProps {
    onChangeStatus: (newStatus: MarketingSenderStatusEnum) => void
    status: MarketingSenderStatusEnum
}

/**
 * COMPONENTE
 * Coluna de acoes em 01 item da tabela de listagem de remetentes de e-mail.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function StatusColumnICP(props: IStatusColumnICPProps): JSX.Element {
    return (
        <>
            {
                props.status === MarketingSenderStatusEnum.PENDING_VALIDATION &&
                <ButtonCP
                    icon={'reload'}
                    size={'small'}
                    tooltip={'Reenviar e-mail de ativação'}
                    onClick={() => props.onChangeStatus(MarketingSenderStatusEnum.PENDING_VALIDATION)}
                >
                    Reenviar
                </ButtonCP>
            }
            {
                props.status === MarketingSenderStatusEnum.ACTIVE &&
                <ButtonCP
                    size={'small'}
                    icon={'poweroff'}
                    onClick={() => props.onChangeStatus(MarketingSenderStatusEnum.INACTIVE)}
                >
                    Desativar
                </ButtonCP>
            }
            {
                props.status === MarketingSenderStatusEnum.INACTIVE &&
                <ButtonCP
                    icon={'check'}
                    size={'small'}
                    onClick={() => props.onChangeStatus(MarketingSenderStatusEnum.ACTIVE)}
                >
                    Ativar
                </ButtonCP>
            }
        </>
    )
}
