import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { PersonListScreen } from 'main/modules/people/screens/screen-person-list/PersonListScreen'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React from 'react'

PersonRoutes.ROOT = 'pessoas'
PersonRoutes.ROOT_WITH_QUERY = `${PersonRoutes.ROOT}/:segmentType`
PersonRoutes.PROFILE = '/perfil'

const PERSON_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: PersonRoutes.ROOT,
        path: PersonRoutes.ROOT_WITH_QUERY,
        component: PersonListScreen,
    },
    {
        key: PersonRoutes.ROOT,
        path: PersonRoutes.ROOT,
        redirect: `${PersonRoutes.ROOT}/${SegmentUtils.getDefaultSegmentTypeName(true)}`,
    },
]

/**
 * Componente que define as rotas para acesso ao CRM
 */
export function PersonRoutes(): JSX.Element {
    return (
        <AccessControlCP
            module={ModuleEnum.MODULE_CRM}
            routeToReturn={PersonRoutes.PROFILE}
        >
            {RoutingHelper.renderRoutingSwitch(PERSON_ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
