import React, { useState } from 'react'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { DashboardUserTitleCP } from 'main/modules/dashboard/components/dashboard-user-title/DashboardUserTitleCP'
import { WidgetsRowActualEvaluationIndicatorsCP } from 'main/modules/dashboard/components/widgets-row-actual-evaluation-indicators/WidgetsRowActualEvaluationIndicatorsCP'
import { WidgetsRowSalesFlowIndicators } from 'main/modules/dashboard/components/widgets-row-sales-flow-indicators/WidgetsRowSalesFlowIndicators'
import { WidgetsRowLeadIndicators } from 'main/modules/dashboard/components/widgets-row-actual-lead-indicators/WidgetsRowLeadIndicators'
import { WidgetsRowActualFollowupIndicatorsCP } from 'main/modules/dashboard/components/widgets-row-actual-followup-indicators/WidgetsRowActualFollowupIndicatorsCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import moment from 'moment'

/**
 * Dashboard da consultora avaliadora
 */
export function ScreenContentConsultantDashboardTabCP(): JSX.Element {

    const [selectedUser, setSelectedUser] = useState<INameAndCodeResponseDTO>(AppStateUtils.getLoggedUserData()!.user)
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({ beginDate: moment().startOf('month').toDate(), endDate: new Date() })

    return (
        <>
            <DashboardUserTitleCP user={selectedUser} onSelectUser={setSelectedUser}/>
            <WidgetsRowLeadIndicators userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}/>
            <WidgetsRowActualEvaluationIndicatorsCP userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}/>
            <WidgetsRowActualFollowupIndicatorsCP userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}/>

            <FiltersFranchiseDashboardCP
                dateFilters={dateFilters}
                onDateRangeChange={setDateFilters}
                marginTop={80}
                showDivider={true}
            />
            <WidgetsRowSalesFlowIndicators userCode={selectedUser.code} franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]} dateFilters={dateFilters}/>
        </>
    )
}
