import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IDateRangeSearchRequestDTO } from 'main/common/dtos/requests/IDateRangeSearchRequestDTO'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

export class SalesFiltersFormModel extends FormModel {

    dateRange?: IDateRangeSearchRequestDTO
    status?: SaleStatusEnum
    searchString?: string
    personCode?: number
    paymentMethod?: PaymentMethodEnum
    showCancelled?: boolean

    considerPppAsSale: boolean

    userSellerCode?: number
    userSellerName?: string

    valueMin?: string
    valueMax?: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
