import { ColumnProps } from 'antd/lib/table'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'

/**
 * Colunas da Tabela de Lista de Segmento.
 */
export const PERSON_LIST_SCREEN_SUB_MENU_COLUMNS: Array<ColumnProps<ISegmentListItemResponseDTO>> = [
    {
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        render: (text, record: ISegmentListItemResponseDTO): string => record.name
    },
    {
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        render: (text, record: ISegmentListItemResponseDTO): number => record.total
    }
]
