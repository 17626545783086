import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TableOpportunityCP } from 'main/modules/sales-funnel/components/table-opportunity/TableOpportunityCP'
import { OpportunityFiltersCP } from 'main/modules/sales-funnel/components/opportunity-filters/OpportunityFiltersCP'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { CalendarEvaluationSchedulesCP } from 'main/modules/sales-funnel/components/calendar-evaluation-schedules/CalendarEvaluationSchedulesCP'
import { OpportunityListEvaluationViewModeTP, RadioGroupEvaluationViewCP } from 'main/modules/sales-funnel/components/radio-group-evaluation-view/RadioGroupEvaluationViewCP'
import { KanbanOpportunityCP } from 'main/modules/sales-funnel/components/kanban-opportunity/KanbanOpportunityCP'
import { OpportunityListFollowupViewModeTP, RadioGroupFollowupViewCP } from 'main/modules/sales-funnel/components/radio-group-followup-view/RadioGroupFollowupViewCP'
import { StatisticsFollowUpCP } from 'main/modules/sales-funnel/components/statistics-follow-up/StatisticsFollowUpCP'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { SalesFunnelScreenUtils } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelScreenUtils'

interface ICPProps {
    formStateManager: IFormStateManager<OpportunityFiltersFormModel>
    shouldLoadData: boolean
    onLoadDataChanged: (type: 'dataLoaded' | 'reloadData') => void
    onSelectOpportunity: (code: number, conversionType?: OpportunityDrawerActionTP) => void
}

/**
 * Conteudo de oportunidade.
 */
export function ScreenContentSalesFunnelOpportunityMainContentICP(props: ICPProps): JSX.Element {

    const [evaluationVewMode, setEvaluationVewMode] = useState<OpportunityListEvaluationViewModeTP>('list')
    const [followupVewMode, setFollowupVewMode] = useState<OpportunityListFollowupViewModeTP>('kanban')

    useEffect(onChangeFollowupView, [props.formStateManager.getFieldValue('view')])
    useEffect(() => {
        if (props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP)
            props.onLoadDataChanged('reloadData')
    }, [followupVewMode])

    /**
     * Ao mudar a visao do funil.
     */
    function onChangeFollowupView(): void {

        if (props.formStateManager.getFieldValue('type') !== OpportunityTypeEnum.FOLLOWUP)
            return

        // Se a visao for convertido ou perdido, deve ir para visao tabela
        const viewsToForceList = [SalesFunnelOpportunityViewEnum.FOLLOWUP_CONVERTED, SalesFunnelOpportunityViewEnum.FOLLOWUP_LOST]
        if (viewsToForceList.includes(props.formStateManager.getFieldValue('view')))
            setFollowupVewMode('list')
    }

    return (
        <MainContentWrapperSCP>

            <OpportunityFiltersCP
                formStateManager={props.formStateManager}
                onFilter={() => props.onLoadDataChanged('reloadData')}
                onClearFilters={() => {
                    SalesFunnelScreenUtils.clearFilters(props.formStateManager)
                    props.onLoadDataChanged('reloadData')
                }}
                isLoading={props.shouldLoadData}
                leftContent={
                    <>
                        {
                            props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.EVALUATION &&
                            <RadioGroupEvaluationViewCP
                                view={props.formStateManager.getFieldValue('view')}
                                evaluationViewMode={evaluationVewMode}
                                onChangeEvaluationViewMode={setEvaluationVewMode}
                            />
                        }
                        {
                            props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP &&
                            <RadioGroupFollowupViewCP
                                view={props.formStateManager.getFieldValue('view')}
                                followupViewMode={followupVewMode}
                                onChangeFollowupViewMode={setFollowupVewMode}
                            />
                        }
                    </>
                }
            />

            {
                (props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP && followupVewMode === 'list') &&
                <StatisticsFollowUpCP
                    selectedStepCode={props.formStateManager.getFieldValue('funnelStepCode')}
                    onChangeFunnelStep={(funnelStep) => {
                        props.formStateManager.changeFieldValue('funnelStepCode', funnelStep)
                        props.onLoadDataChanged('reloadData')
                    }}
                    shouldLoadData={props.shouldLoadData}
                    formStateManager={props.formStateManager}
                />
            }

            {
                (
                    props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.LEAD ||
                    (props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.EVALUATION && evaluationVewMode === 'list') ||
                    (props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP && followupVewMode === 'list')
                ) &&
                <TableOpportunityCP
                    shouldLoadData={props.shouldLoadData}
                    onLoadDataChanged={(dataChanged) => {
                        props.onLoadDataChanged(dataChanged)
                    }}
                    onSelectOpportunity={props.onSelectOpportunity}
                    filtersFormStateManager={props.formStateManager}
                />
            }

            {
                props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.EVALUATION && evaluationVewMode === 'calendar' &&
                <CalendarEvaluationSchedulesCP view={props.formStateManager.getFieldValue('view')}/>
            }

            {
                props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP && followupVewMode === 'kanban' &&
                <KanbanOpportunityCP
                    onLoadDataChanged={(dataChanged) => {
                        props.onLoadDataChanged(dataChanged)
                    }}
                    shouldLoadData={props.shouldLoadData}
                    onSelectOpportunity={props.onSelectOpportunity}
                    filtersFormStateManager={props.formStateManager}
                />
            }
        </MainContentWrapperSCP>
    )
}

const MainContentWrapperSCP = styled.div`
    width:73vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    @media(max-width: 1280px){
        width: 73vw;
    }
`

// const Quadro = styled.div`
//     overflow-x:auto;
//     overflow-y: auto;
// `
