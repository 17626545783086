import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IMarketingTransactionalSaveRequestDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/request/IMarketingTransactionalSaveRequestDTO'
import { IMarketingTransactionalMessageSaveRequestDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/request/IMarketingTransactionalMessageSaveRequestDTO'
import { IMarketingTransactionalSearchRequestDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/request/IMarketingTransactionalSearchRequestDTO'
import { MarketingRequestUtils } from 'main/modules/marketing/services/utils/MarketingRequestUtils'

/**
 * Requests das automacoes de marketing
 */
export class MarketingTransactionalRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().mktBaseUrl}/transactionals`

    static async findOne(code: number): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}/${code}`,
            method: HttpMethodEnum.GET,
        })
    }

    static async search(dto: IMarketingTransactionalSearchRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}`,
            method: HttpMethodEnum.GET,
            params: dto,
        })
    }

    static async create(dto: IMarketingTransactionalSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}`,
            method: HttpMethodEnum.POST,
            params: dto
        })
    }

    static async update(code: number, dto: IMarketingTransactionalSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}/${code}`,
            method: HttpMethodEnum.PUT,
            params: dto
        })
    }

    static async updateMessage(code: number, dto: IMarketingTransactionalMessageSaveRequestDTO): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}/${code}/update-message`,
            method: HttpMethodEnum.PUT,
            params: dto
        })
    }

    static async delete(code: number): Promise<RequestConfigTP> {
        return await MarketingRequestUtils.getMktRequestConfig({
            url: `${MarketingTransactionalRequests._MODULE_BASE}/${code}`,
            method: HttpMethodEnum.DELETE,
        })
    }

}
