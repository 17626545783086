import React, { useState, useEffect } from 'react'

import { styled } from 'main/config/theme/styledWithTheme'
import { SegmentLogicOperatorsEnum } from 'main/modules/segment/enums/SegmentLogicOperatorsEnum'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { InputValueCallbackTP } from 'main/common/components/form-fields/inner/types/InputValueCallbackTP'
import { SegmentOperatorForEditionTP } from 'main/modules/segment/types/operator/SegmentOperatorForEditionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'

const operators = Object.values(SegmentLogicOperatorsEnum)

const LOGIC_OPERATOR_OPTIONS: SelectOptionTP[] = operators.map((operator: SegmentLogicOperatorsEnum, index: number) => {
    return {
        key: `select_logic_op_${index}`,
        value: operator,
        label: SegmentUtils.getLogicOperatorLabel(operator)
    }
})

interface ILogicOperatorCPProps {
    onChange: InputValueCallbackTP<SegmentOperatorForEditionTP>
    operator: SegmentOperatorForEditionTP
    horizontalMargin: number
    disabled: boolean
    hide?: boolean
}

/**
 * COMPONENTE
 * Componente de selecao de operador logico na montagem de filtros de lista de segmentacao.
 */
export function LogicOperatorICP(props: ILogicOperatorCPProps): JSX.Element {

    const [enableEdition, setEnableEdition] = useState<boolean>(false)
    const [logicOperator, setLogicOperator] = useState<SegmentOperatorForEditionTP>(props.operator)

    useEffect(() => setLogicOperator(props.operator), [props.operator])
    useEffect(() => props.onChange(logicOperator), [logicOperator])

    function onOperatorChange(nextOperator?: SegmentLogicOperatorsEnum): void {
        setEnableEdition(false)
        if (!!nextOperator)
            setLogicOperator({ ...logicOperator, operator: nextOperator })
    }

    return (
        <WrapperSCP horizontalMargin={props.horizontalMargin} hide={props.hide}>
            {
                enableEdition
                    ? <SelectContainerSCP>
                        <SelectCP
                            value={logicOperator.operator}
                            options={LOGIC_OPERATOR_OPTIONS}
                            defaultOpen={true}
                            onChange={onOperatorChange}
                            onClose={() => setEnableEdition(false)}
                        />
                    </SelectContainerSCP>
                    : <TooltipCP text={(!props.hide && !props.disabled) ? 'Alterar' : ''}>
                        <OperatorLabelSCP onClick={() => setEnableEdition(!props.disabled)} disabled={!!props.disabled}>
                            {SegmentUtils.getLogicOperatorLabel(logicOperator.operator)}
                        </OperatorLabelSCP>
                    </TooltipCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ horizontalMargin: number, hide?: boolean }>`
    cursor: pointer;
    margin: 0 ${props => props.horizontalMargin}px;
    opacity: ${props => (!!props.hide ? 0 : 1)};
    pointer-events: ${props => (!!props.hide ? 'none' : 'unset')};
`

const SelectContainerSCP = styled.div`

    div.ant-form-item {
        border: 1px solid ${props => props.theme.primaryColor};
        margin: 0;
        height: 35px;
        overflow: hidden;
        border-radius: 4px;
        padding-right: 2px;
    }

    div.ant-select-selection.ant-select-selection--single {
        border: 0 none;
    }
`

const OperatorLabelSCP = styled.div<{ disabled: boolean }>`

    border: 1px solid ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
    height: 27px;
    width: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-style: italic;
    transition: opacity .3s;
    font-size: .8rem;
    padding-right: 1px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'inherit')};

    :hover {
        opacity: .6;
    }
`
