import moment, { Moment } from 'moment'
import { ICreateChargeInstallment } from 'main/modules/sale/components/table-create-charge-installments/inner/ICreateChargeInstallment'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { CreateChargeFormModel } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeFormModel'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import * as _ from 'lodash'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { PaymentConfigRulesResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/inner/PaymentConfigRulesResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

export const CreateChargeUtils = {

    /**
     * Monta a tabeela de parcelas de acordo com as informacoes da cobranca.
     */
    mountInstallmentsTable(
        formStateManager: IFormStateManager<CreateChargeFormModel>,
        saleDate: Date
    ): ICreateChargeInstallment[] | undefined {

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (!formValues.paymentMethod || formValues.totalInstallments <= 0 || !formValues.totalValue)
            return

        let currentInstallmentCount = 1
        const list: ICreateChargeInstallment[] = []

        // Obtem a data correta da primeira parcela
        const firstInstallmentDate: Moment = moment(formValues.firstInstallmentDate ?? saleDate).clone()

        // Total a ser parcelado e numero de parcelas
        let totalToParcel: number = MoneyUtils.convertToFloat(formValues.totalValue)
        let totalInstallments: number = formValues.totalInstallments

        // Valor padrao
        let shouldConsiderGraceAs1 = false

        // Se tiver carencia no primeiro pagamento, cria a primeira parcela de R$1,00 e divide o restante
        const shouldConsiderGracePeriod: boolean = formValues.paymentMethod === PaymentMethodEnum.CREDIT_CARD_RECURRENT && formValues.hasGracePeriod
        if (shouldConsiderGracePeriod) {

            // Para a VINDI a carencia conta como uma parcela de 1,00
            shouldConsiderGraceAs1 = (formValues.paymentRule as PaymentConfigRulesResponseDTO)?.rules.hasGracePeriodValue ?? false

            if (shouldConsiderGraceAs1) {
                const gracePeriodValue = 1.00
                totalToParcel -= gracePeriodValue

                const installmentDate = firstInstallmentDate.clone()
                list.push({
                    installmentNumber: 1,
                    expirationDate: installmentDate.toDate(),
                    value: gracePeriodValue,
                })

            }

            firstInstallmentDate.add(1, 'M')
        }

        // Total de parcelas a serem considerados na divisao
        let totalInstallmentsToDivide: number = formValues.totalInstallments
        let installmentValue = totalToParcel / totalInstallmentsToDivide

        // Validar os valores para verificar se nao tem dizima
        // Arredonda o valor e fixa em 2 casas decimais
        installmentValue = Number((Math.floor(installmentValue * 100) / 100).toFixed(2))

        // Guarda a diferenca entre a soma das parcelas com o valor total. Considera o total de parcelas original
        const difVal = totalToParcel - (installmentValue * totalInstallmentsToDivide)

        while (currentInstallmentCount <= totalInstallments) {

            // Caso tenha o valor da diferenca sera acrscido na primeira parcela
            // Se tiver carencia, sera aplicado na segunda
            let currentParcelValue = installmentValue
            if (difVal > 0 && currentInstallmentCount === 1)
                currentParcelValue += difVal

            const installmenteCountToAdd = (shouldConsiderGracePeriod && shouldConsiderGraceAs1) ? 1 : 0
            const installmentDate = firstInstallmentDate.clone()
            list.push({
                installmentNumber: currentInstallmentCount + installmenteCountToAdd,
                expirationDate: installmentDate.add(currentInstallmentCount - 1, 'M').toDate(),
                value: Number(currentParcelValue.toFixed(2)),
            })
            currentInstallmentCount++
        }

        return list
    },

    /**
     * Obtem o somatorio das parcelas de uma cobranca.
     */
    getInstallmentsSum(chargeInstallments?: ICreateChargeInstallment[]): number {

        if (!chargeInstallments)
            return 0

        return MoneyUtils.getNumber(_.sumBy(chargeInstallments, 'value'))
    },

    /**
     * Obtem o somatorio das parcelas de uma cobranca.
     */
    getChargeInstallmentsSum(charges: IChargeListItemResponseDTO[]): number {

        if (!charges)
            return 0

        let sum: number = 0

        _.each(charges, (charge: IChargeListItemResponseDTO) => {
            sum += _.sumBy(charge.financialTransactions, 'value')
        })

        return MoneyUtils.getNumber(sum)
    },

    /**
     * Valida as informacoes do paymentconfig.
     */
    checkPaymentConfigRules(
        installments: ICreateChargeInstallment[],
        partnerConfig?: PaymentConfigRulesResponseDTO
    ): boolean {

        if (!partnerConfig || ArrayUtils.isEmpty(installments))
            return true

        // Valida se primeira parcela esta de acorda com as regras de pagamento
        // Neste momento se as datas minimas e maximas de pagamento estao sendo respeitadas
        const firstInstallmentDate = installments[0].expirationDate

        let isValid = true
        if (!!partnerConfig.rules?.minDaysToExpire) {
            const minDate = DateUtils.add(new Date(), partnerConfig.rules.minDaysToExpire, TimeBaseEnum.DAY)
            isValid = isValid && DateUtils.isAfter(firstInstallmentDate, minDate)
        }

        if (!!partnerConfig.rules?.maxDaysToExpire) {
            const maxDate = DateUtils.add(new Date(), partnerConfig.rules.maxDaysToExpire, TimeBaseEnum.DAY)
            isValid = isValid && DateUtils.isBefore(firstInstallmentDate, maxDate)
        }

        return isValid
    },

}
