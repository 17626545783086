import { DreReportRequestDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/request/DreReportRequestDTO'
import { ProfitAndLossStatementResponseDTO } from 'submodules/neritclin-sdk/services/financial/financial/dtos/response/ProfitAndLossStatementResponseDTO'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'

export class FinancialTransactionReportRequests {

	public static CONTROLLER_ROOT = 'financial-transactions/reports'
	public static PROFIT_AND_LOSS_STATEMENT_EP = 'profit-and-loss-statement'

    static dre = (dto: DreReportRequestDTO): RequestConfigTP<ProfitAndLossStatementResponseDTO[]> => ({
        baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
        url: `${FinancialTransactionReportRequests.CONTROLLER_ROOT}/${FinancialTransactionReportRequests.PROFIT_AND_LOSS_STATEMENT_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
