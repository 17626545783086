import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { MultiActionButtonCP } from 'main/common/components/multi-action-button/MultiActionButtonCP'
import { IMultiActionButtonOption } from 'main/common/components/multi-action-button/inner/IMultiActionButtonOption'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import { PersonContactStatusEnum } from 'main/modules/people/services/person-contact/enums/PersonContactStatusEnum'

interface IFormPersonContactButtonsICPProps {
    onSave: (addNew: boolean, conversionType?: OpportunityDrawerActionTP) => void
    isSaving?: boolean
    onDelete: () => void
    isDeleting?: boolean
    showEvaluationAction: boolean
    personContact?: IPersonContactResponseDTO
}

/**
 * BOTOES do Formulário de agendamento de um novo contato com pessoa
 */
export function FormPersonContactButtonsICP(props: IFormPersonContactButtonsICPProps): JSX.Element {

    // Adiciona o botao principal de salvar para o componente de multiplos botoes
    const multipleActionButtons: IMultiActionButtonOption[] = []

    // Tem uma disposicao diferente entre a criacao e edicao
    if (!!props.personContact?.code) {

        if (props.personContact.status === PersonContactStatusEnum.IN_PROGRESS) {
            multipleActionButtons.push(
                {
                    label: 'Concluir e criar nova atividade',
                    onClick: () => props.onSave(true)
                },
            )
        }

        multipleActionButtons.push(
            {
                label: 'Atualizar dados',
                icon: <IconCP antIcon={'edit'}/>,
                onClick: () => props.onSave(false)
            },
        )

    } else {

        multipleActionButtons.push(
            {
                label: 'Criar atividade',
                onClick: () => props.onSave(false)
            },
            {
                label: 'Criar, marcar como concluída e criar nova',
                icon: <IconCP antIcon={'plus'}/>,
                onClick: () => props.onSave(true)
            },
        )

    }

    if (props.showEvaluationAction && props.personContact?.status === PersonContactStatusEnum.IN_PROGRESS) {
        multipleActionButtons.push({
            icon: <IconCP antIcon={'calendar'}/>,
            label: 'Concluir e agendar avaliação',
            onClick: () => props.onSave(false, 'evaluation')
        })
    }

    return (
        <FlexCP justifyContent={'space-between'} alignItems={'center'}>
            <div>
                <ConditionalRenderCP shouldRender={!!props.personContact?.code}>
                    <ButtonCP
                        icon={'delete'}
                        type={'danger'}
                        confirmMsg={'Deseja mesmo excluir essa atividade?'}
                        onClick={props.onDelete}
                        loading={props.isDeleting}
                    />
                </ConditionalRenderCP>
            </div>
            <MultiActionButtonCP
                type={'primary'}
                loading={props.isSaving}
                options={multipleActionButtons}
            />
        </FlexCP>
    )
}
