import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { MonthPickerCP } from 'main/common/components/month-picker/MonthPickerCP'

interface IReportFiltersCPProps {
    onChangeDate: (date: Date) => void
    initialDate: Date
}

/**
 * Filtros do relatorio da franqueadora
 */
export function FranchisorDashboardFiltersCP(props: IReportFiltersCPProps): JSX.Element {

    return (
        <FiltersWrapperSCP>
            <MonthPickerCP
                size={'large'}
                allowClear={false}
                value={moment(props.initialDate)}
                onChange={(dateStr) => {
                    props.onChangeDate(moment(dateStr).toDate())
                }}
            />
        </FiltersWrapperSCP>
    )

}

const FiltersWrapperSCP = styled.div`
   display: flex;
   margin: 20px 0;
   justify-content: center;
`

