import { TablePaginationTP } from 'common/old-refactory/table/TableCPOLD'
import _ from 'lodash'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { CardCP } from 'main/common/components/card/CardCP'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { PersonTableContext } from 'main/modules/people/components/person-table/inner/PersonTableContext'
import { PersonTableUtils } from 'main/modules/people/components/person-table/inner/PersonTableUtils'
import { IPersonSearchRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchRequestDTO'
import { ISearchPersonByExpressionRequestDTO } from 'main/modules/people/services/person/dtos/request/ISearchPersonByExpressionRequestDTO'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { PersonActionBarCP } from 'main/modules/people/components/person-action-bar/PersonActionBarCP'
import { ExcelUtil } from 'main/modules/people/utils/ExcelUtil'

const DEFAULT_FILTERS: IPersonSearchRequestDTO = {
    itemsPerPage: TableUtils.getDefaultPagination().pageSize,
    page: 1,
}

/**
 * COMPONENTE
 * Parte interna da tabela do CRM para clientes e fornecedores.
 */
export const TableInnerPersonICP = ComponentUtils.memoizeCp((): JSX.Element => {

    const tableContext = PersonTableContext.use()
    const [selectedPeople, setSelectedPeople] = useState<number[]>([])
    const [carregandoE,setCarregandoE] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const [filters, setFilters] = useState<IPersonSearchRequestDTO>(DEFAULT_FILTERS)
    const [personList, setPersonList] = useState<IPersonResponseDTO[]>([])

    const searchPeopleRequest = useRequest<IGenericListResponseDTO<IPersonResponseDTO>>('api-return', '[people-search (list)]')
    const procurador = useRequest<IGenericListResponseDTO<IPersonResponseDTO>>('api-return', '[people-search (list)]')

    const runSearchDebounced = _.debounce(runPeopleSearch, 150)
    useEffect(onSearchRequestChange, [searchPeopleRequest.isAwaiting])
    useEffect(onProcurador, [procurador.isAwaiting])
    useEffect(() => runSearchDebounced(true), [tableContext.state.segmentExpression])
    useEffect(() => runSearchDebounced(true), [tableContext.state.segmentType])
    useEffect(() => runSearchDebounced(false), [filters])

    useEffect(onShouldUpdateListChange, [tableContext.state.mustUpdate])

    function runPeopleSearch(clearFilters: boolean): void {

        if (clearFilters) {
            setPagination(TableUtils.getDefaultPagination())
            setFilters(DEFAULT_FILTERS)
        }

        const searchDto: ISearchPersonByExpressionRequestDTO = {
            ...filters,
            types: [tableContext.state.segmentType!],
            expression: tableContext.state.segmentExpression,
        }

        searchPeopleRequest.runRequest(PersonRequests.searchByExpression(searchDto))
    }

    function onProcurador():void{
        if (!RequestUtils.isValidRequestReturn(procurador, 'Erro ao tentar listar pessoas')){
            setCarregandoE(false);
            return
        }

        // Atualiza listagem / estado
        setCarregandoE(false);
        const lista = procurador.responseData?.list;
        if(lista && lista.length){
            lista.forEach((li:any)=>{
                li.name = ExcelUtil.TEXTO(li.name);
                if(li['address']){
                    li.rua = li.address['street'];
                    li.cidade = li.address['city'];
                    li.estado = li.address['state'];
                    li.cep = li.address['zipCode'];
                    li.bairro = li.address['neighborhood'];
                    li.complemento = li.address['complement'];
                    li.numero = li.address['number'];
                }
            });
            let r = `EXPORTACAO DE CONTATOS\n`;
            r+=ExcelUtil.excel(lista,[
                ['CODIGO','code'],
                ['NOME','name'],
                ['EMAIL','email'],
                ['TELEFONE','phone'],
                ['CPF-CNPJ','cpfCnpj'],
                ['CEP','cep'],
                ['RUA','rua'],
                ['NUMERO','numero'],
                ['COMPLEMENTO','complemento'],
                ['BAIRRO','bairro'],
                ['CIDADE','cidade'],
                ['UF','estado']

            ]);
            ExcelUtil.salvarTexto('Exportacao_contatos.csv',r);
        }
    }

    function runBusca(){
        console.log(filters, 'Filtros');
        const searchDto: ISearchPersonByExpressionRequestDTO = {
            
            types: [tableContext.state.segmentType!],
            expression: tableContext.state.segmentExpression,
        }

        procurador.runRequest(PersonRequests.searchByExpression(searchDto))
    }

    function onSearchRequestChange(): void {

        tableContext.actions.setIsLoading(searchPeopleRequest.isAwaiting)
        if (!RequestUtils.isValidRequestReturn(searchPeopleRequest, 'Erro ao tentar listar pessoas'))
            return

        // Atualiza listagem / estado
        const isSuccess = RequestUtils.isRequestSuccess(searchPeopleRequest, false)
        updatePeopleList(isSuccess)
    }

    function onShouldUpdateListChange(): void {
        if (tableContext.state.mustUpdate)
            runSearchDebounced(true)
    }

    function updatePeopleList(isSuccess: boolean): void {
        const nextPersonList = SystemUtils.nvl<IPersonResponseDTO[]>(isSuccess, searchPeopleRequest.responseData?.list, [])!
        const totalPeople = SystemUtils.nvl<number>(isSuccess, searchPeopleRequest.responseData?.total, 0)!
        setPagination({ ...pagination, total: totalPeople })
        setPersonList(nextPersonList)
    }

    function viewPersonDetails(personCode: number): void {
        tableContext.actions.setShowPersonDetailsDrawer(true)
        tableContext.actions.setSelectedPersonCode(personCode)
    }

    function exportarcsv(){
        setCarregandoE(true);
        runBusca();
    }

    return (
        <>
            <PersonActionBarCP
                isLoading={tableContext.state.isLoading}
                selectedPeople={selectedPeople}
                exportarcsv={exportarcsv}
                carregandoExportacao={carregandoE}
            />

            <CardCP innerSpacing={'none'}>
                <TableWrapperSCP data-tour={'person-table'}>
                    <TableCPOLD2<IPersonResponseDTO>
                        data={personList}
                        columns={PersonTableUtils.getPersonTableColumnsConfig(viewPersonDetails, setFilters, filters)}
                        pagination={pagination}
                        loading={tableContext.state.isLoading}
                        rowKey={(person): string => `${person.code}`}
                        onPaginationChange={(page): void => {
                            setPagination({ ...pagination, current: page.current ?? 1 })
                            setFilters({ ...filters, page: page.current })
                        }}
                        rowSelection={{
                            selectedRowKeys: ArrayUtils.toStringList(selectedPeople),
                            onChange: (selected) => setSelectedPeople(ArrayUtils.toNumberList(selected as string[]))
                        }}
                    />
                </TableWrapperSCP>
            </CardCP>
        </>
    )
})

const TableWrapperSCP = styled.div`

    .ant-table {
        font-size: 12px;
    }

    .ant-typography {
        margin: 0;
        font-size: ${(props: StyledComponentProps): string => props.theme.fontSizes.small};
    }
`
