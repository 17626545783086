import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFormAnswerResponseDTO } from 'main/modules/scheduler/services/session-form-answer/dtos/response/IFormAnswerResponseDTO'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { FormSessionFieldICP } from 'main/modules/scheduler/components/form-session-answer/inner/FormSessionFieldICP'
import { FormAnswersSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/form-answers/request/FormAnswersSaveRequestDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import * as _ from 'lodash'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { FormQuestionAnswerSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/form-answers/request/inner/FormQuestionAnswerSaveRequestDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ColumnSizeTP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { Checkbox } from 'antd'

export type SessionFormActionTP = 'save' | 'saveAndGoNext'

interface ICPProps {
    sessionCode: number
    sessionStatus?: SessionStatusEnum
    showNextButton?: boolean
    onSaveAnswers: (actionType: SessionFormActionTP) => void
    initTime: Date
    appearance?: {
        fieldColumnSize?: ColumnSizeTP
    }
}

/**
 * Componente wrapper para conteúdo do drawer de detalhes do cliente
 */
export function FormSessionAnswerCP(props: ICPProps): JSX.Element {
    const [actionType, setActionType] = useState<SessionFormActionTP>('save')
    const [tipoTexto, setTipoTexto] = useState<string>('normal');
    const [loadedFormAnswer, setLoadedFormAnswer] = useState<IFormAnswerResponseDTO>()
    const [formAnswersToSave, setFormAnswersToSave] = useState<FormQuestionAnswerSaveRequestDTO[]>([])

    const getSessionFormAnswerRequest = useRequest<IFormAnswerResponseDTO>()
    useEffect(onGetSessionFormAnswerRequestChange, [getSessionFormAnswerRequest.isAwaiting])

    const saveFormAnswersRequest = useRequest<IFormAnswerResponseDTO>()
    useEffect(onSaveFormAnsersRequestChange, [saveFormAnswersRequest.isAwaiting])

    useEffect(init, [props.sessionCode])

    /**
     * Inicializa.
     */
    function init(): void {
        
        // Reseta o campo
        setLoadedFormAnswer(undefined)

        // Carrega da API
        getSessionFormAnswerRequest.runRequest(SchedulerRequests.getSessionForm(props.sessionCode))
    }

    /**
     * Retorno ao buscar as configuracoes do form e respostas.
     */
    function onGetSessionFormAnswerRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSessionFormAnswerRequest, 'Erro ao buscar campos do formulário'))
            return
        
        const formAnswersResponseDto = getSessionFormAnswerRequest.responseData
        setLoadedFormAnswer(formAnswersResponseDto)

        // Itera nas respostas retornadas para montar o objeto que será utilizado para salvar as respostas
        const answersFormatted = formAnswersResponseDto?.answers?.map((currentAnswer) => ({
            formFormQuestionCode: currentAnswer.formFormQuestionCode,
            sessionAnswerCode: currentAnswer.sessionAnswerCode,
            sessionAnswerValue: currentAnswer.sessionAnswerValue,
            sessionAnswerComment: currentAnswer.sessionAnswerComment
        }))

        // Se ja tem resposta, mostra as respostas carregadas
        if (!SystemUtils.isEmpty(answersFormatted)) {
            setFormAnswersToSave(answersFormatted!)
            return
        }

        // Tenta pegar do proprio formToAnswer pois se clicou em salvar e ir para proxima, guarda o atual para ja vir preenchido
        setFormAnswersToSave(formAnswersToSave ?? [])
    }

    /**
     * Salva respostas informadas.
     */
    function saveAnswers(selectedActionType: SessionFormActionTP): void {

        setActionType(selectedActionType)

        const saveRequestDto: FormAnswersSaveRequestDTO = {
            answers: formAnswersToSave,
            durationInSeconds: DateUtils.getDiff(TimeBaseEnum.SECOND, props.initTime)
        }
        
        saveFormAnswersRequest.runRequest(SchedulerRequests.saveSessionAnswers(props.sessionCode, saveRequestDto))
        console.log("tentou salvar localstorage" , props.sessionCode)
        localStorage.setItem('sessao_atual_atendimento',JSON.stringify(props.sessionCode));
    }

    /**
     * Retorno ao salvar respostas do Form
     */
    function onSaveFormAnsersRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFormAnswersRequest, 'Erro ao enviar salvar respostas')){
          // localStorage.removeItem('sessao_atual_atendimento');
            return
        }

        NotificationHelper.success(NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE)
       
        // Se for apenas para salvar, já limpa os campos preenchidos.
        // Pois se for salvar e ir para proximo, ele pega os valores já informados para preencher o próximo
        if (actionType === 'save')
            setFormAnswersToSave([])

        props.onSaveAnswers(actionType)
    }

    function mudarTipoDeTexto(valor: boolean){
        console.log("Mudando texto ", valor);
        if(valor)setTipoTexto('caixa');
        if(!valor)setTipoTexto('normal');
    }

    if (getSessionFormAnswerRequest.isAwaiting)
        return <LoadingOverlayCP show={true}/>

    if (!loadedFormAnswer)
        return <EmptyCP description={'Sem dados de formulário cadastrados'}/>

    return (
        <FormWrapperSCP>
            <SeletorTipoTexto>
                <Checkbox onChange={(v:any)=>{mudarTipoDeTexto(v.target.checked)}}/>        
                Usar áreas de texto nos campos de pergunta aberta.
            </SeletorTipoTexto>
            <FieldsWrapperSCP>
                <FormSessionFieldICP
                    questions={loadedFormAnswer.questions}
                    answers={formAnswersToSave}
                    appearance={props.appearance}
                    tipoTexto={tipoTexto}
                />
            </FieldsWrapperSCP>

            {
                loadedFormAnswer &&
                <>
                    {
                        !!loadedFormAnswer.answers && !SystemUtils.isEmpty(loadedFormAnswer.answers) &&
                        <TitleAndTextCP
                            title={'Responsável:'}
                            text={_.uniq(loadedFormAnswer.answers.map((answer) => StringUtils.getFirstAndSecondName(answer.userCreator.name))).join(',')}
                            marginTop={20}
                            marginBottom={20}
                        />
                    }

                    <ButtonWrapperSCP>
                        <ButtonCP
                            onClick={() => saveAnswers('save')}
                            type={'ghost'}
                            loading={saveFormAnswersRequest.isAwaiting}
                        >
                            Salvar
                        </ButtonCP>
                        {
                            props.showNextButton && props.sessionStatus !== SessionStatusEnum.CONCLUDED &&
                            <ButtonCP
                                onClick={() => saveAnswers('saveAndGoNext')}
                                type={'primary'}
                                loading={saveFormAnswersRequest.isAwaiting}
                            >
                                Salvar e ir para o próximo
                            </ButtonCP>
                        }
                    </ButtonWrapperSCP>
                </>
            }
        </FormWrapperSCP>
    )
}

const FormWrapperSCP = styled.div`
    margin-top: 2em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .ant-divider-horizontal.ant-divider-with-text-left{
        margin-top: 0;
        margin-bottom: 25px;
    }
`

const FieldsWrapperSCP = styled.div`

  .ant-form-item-label label {
    font-size: 10px !important;
    overflow: hidden;
    width: 100%;
    text-align: left;
    white-space: normal;
    line-height: 15px;;
  }
`

const SeletorTipoTexto = styled.div`
    padding: 5px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    bottom: 20px;
    color: gray;
    border-bottom: 2px solid;
    letter-spacing: -0.048em;
`

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    margin-top: 2em;

    .ant-btn {
        margin-left: 1em;
    }
`
