import React, { useEffect, useState } from 'react'
import { SmsTextAreaCP } from 'main/modules/marketing/components/sms-textarea/SmsTextAreaCP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { TransactionalMessagesFormModel } from 'main/modules/marketing/components/collapse-mkt-transactionals/inner/TransactionalMessagesFormModel'
import { MktTransactionalCollpaseTitleItemICP } from 'main/modules/marketing/components/collapse-mkt-transactionals/inner/MktTransactionalCollpaseTitleItemICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IMarketingTransactionalMessageSaveRequestDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/request/IMarketingTransactionalMessageSaveRequestDTO'
import { IMarketingTransactionalResponseDTO } from 'main/modules/marketing/services/marketing-transactional/dtos/responses/IMarketingTransactionalResponseDTO'
import { MarketingTransactionalRequests } from 'main/modules/marketing/services/marketing-transactional/MarketingTransactionalRequests'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import styled from 'main/config/theme/styledWithTheme'
import { TinyMceCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tiny-mce/TinyMceCP'
import { MarketingTransactionalEventEnum } from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'
import { OpportunityScheduleVariablesTP } from 'main/modules/marketing/types/OpportunityScheduleVariablesTP'
import { MarketingContentVarsConfigTP } from 'main/modules/marketing/types/MarketingContentVarsConfigTP'
import { MARKETING_CONTENT_VARS } from 'main/modules/marketing/config/MarketingContentVarsConfig'
import { SessionVariablesTP } from 'main/modules/marketing/types/SessionVariablesTP'

interface ICPProps {
    mktTransactional: IMarketingTransactionalResponseDTO
    onSave: () => void
    event: MarketingTransactionalEventEnum
}

/**
 * Conteudo de cada painel do collapse.
 *
 * @TODO Renato - O editor de email dnd nao funcionou, deixei comentado o anterior pois provisoriamente utilizaremos o tiny
 */
export function MktTransactionalCollpaseItemICP(props: ICPProps): JSX.Element {

    const [formModel, setFormModel] = useState<TransactionalMessagesFormModel>((new TransactionalMessagesFormModel()))
    const formStateManager = useFormStateManager<TransactionalMessagesFormModel>(formModel)

    // const [shouldExportHtml, setShouldExportHtml] = useState<boolean>(false)

    const saveMktTransactionalRequest = useRequest<IBankAccountResponseDTO>()
    useEffect(onSaveMktTransactionalRequestChange, [saveMktTransactionalRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        setFormModel(new TransactionalMessagesFormModel({
            sendSms: !!props.mktTransactional.smsText,
            sendWhatsapp: !!props.mktTransactional.whatsappText,
            sendEmail: !!props.mktTransactional.emailHtml,
            smsMessage: props.mktTransactional.smsText,
            whatsappMessage: props.mktTransactional.whatsappText,
            emailMessageJson: props.mktTransactional.emailJson,
            emailMessageHtml: props.mktTransactional.emailHtml,
        }))

    }

    /**
     * Salva as mensagens.
     */
    async function save(): Promise<void> {

        const formValues = formStateManager.getFormValues()!

        // Se tiver email, devemos setar a variavel para exportar o html e json
        // Depois de exportado ele volta para esse metodo com o html preenchido
        // if (formValues.sendEmail && SystemUtils.isEmpty(formValues.emailMessageHtml)) {
        //     // Ao setar a variavel de exportacao, o effect cuidara de obter o retorno do editor
        //     // setShouldExportHtml(true)
        //     return
        // }

        if (formValues.sendWhatsapp && SystemUtils.isEmpty(formValues.whatsappMessage)) {
            NotificationHelper.error('Ops!', 'Preenche o conteúdo da mensagem de Whatsapp')
            return
        }

        if (formValues.sendSms && SystemUtils.isEmpty(formValues.smsMessage)) {
            NotificationHelper.error('Ops!', 'Preenche o conteúdo da mensagem de SMS')
            return
        }

        if (formValues.sendEmail && SystemUtils.isEmpty(formValues.emailMessageHtml)) {
            NotificationHelper.error('Ops!', 'Preenche o conteúdo do e-mail')
            return
        }

        const dto: IMarketingTransactionalMessageSaveRequestDTO = {
            emailHtml: formValues.sendEmail ? formValues.emailMessageHtml : null,
            // emailJson: null,
            // emailJson: formValues.sendEmail ? formValues.emailMessageJson : undefined,
            smsText: formValues.sendSms ? formValues.smsMessage : null,
            whatsappText: formValues.sendWhatsapp ? formValues.whatsappMessage : null,
        }

        saveMktTransactionalRequest.runRequest(await MarketingTransactionalRequests.updateMessage(props.mktTransactional.code, dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveMktTransactionalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveMktTransactionalRequest, 'Ocorreu algun erro ao salvar'))
            return

        NotificationHelper.info('Yes', 'Dados salvos com sucesso')
        props.onSave()
    }

    /**
     * Retorno do component para guardar os valores do editor.
     */
    // function onExportEmailFromEditor(htmlContent?: string, jsonContent?: any): void {
    //
    //     formStateManager.changeFieldValue('emailMessageHtml', htmlContent)
    //     formStateManager.changeFieldValue('emailMessageJson', jsonContent)
    //     setShouldExportHtml(false)
    //
    //     // Agora pode salvar
    //     save()
    // }

    /**
     * Obtem as variaveis corretas do autocomplete, baseado no evento selecionado.
     */
    function getCorrectTextVars(): MarketingContentVarsConfigTP[] {

        const eventPrefix = props.event.split('_')[0]

        if (eventPrefix === 'EVALUATION')
            return OpportunityScheduleVariablesTP

        if (eventPrefix === 'SESSION')
            return SessionVariablesTP

        return MARKETING_CONTENT_VARS
    }

    return (
        <>
            <MktTransactionalCollpaseTitleItemICP
                title={'WhatsApp'}
                formStateManager={formStateManager}
                switchFieldName={'sendWhatsapp'}
                content={
                    <SmsTextAreaCP
                        formStateManager={formStateManager}
                        fieldName={'whatsappMessage'}
                        label={'Mensagem enviada por Whatsapp'}
                        considerCharLimit={false}
                        mentionOptions={getCorrectTextVars()}
                    />
                }
            />

            <MktTransactionalCollpaseTitleItemICP
                title={'SMS'}
                formStateManager={formStateManager}
                switchFieldName={'sendSms'}
                content={
                    <SmsTextAreaCP
                        formStateManager={formStateManager}
                        fieldName={'smsMessage'}
                        label={'Mensagem enviada por SMS'}
                        showPercentage={true}
                        considerCharLimit={true}
                        mentionOptions={getCorrectTextVars()}
                    />
                }
            />

            <MktTransactionalCollpaseTitleItemICP
                title={'E-mail'}
                formStateManager={formStateManager}
                switchFieldName={'sendEmail'}
                content={
                    <EditorWrapper>
                        <TinyMceCP
                            onEditorContentChange={(content) => formStateManager.changeFieldValue('emailMessageHtml', content)}
                            initialEditorContent={formStateManager.getFieldValue('emailMessageHtml')}
                        />

                        {/*<EmailEditorCP*/}
                        {/*    jsonContent={formStateManager.getFieldValue('emailMessageJson')}*/}
                        {/*    onExportContent={onExportEmailFromEditor}*/}
                        {/*    shouldExportHtml={shouldExportHtml}*/}
                        {/*/>*/}
                    </EditorWrapper>
                }
            />

            <div>
                <BlocoLegenda>
                <div className='titulo'>
                    Variáveis Disponíveis
                </div>
                    <ItemLegenda>
                        <div>
                            Nome do Cliente
                        </div>
                        <div className='pesado'>
                            ##nome##
                        </div>
                    </ItemLegenda>
                    <ItemLegenda>
                        <div>
                            Nome da Unidade de Atendimento
                        </div>
                        <div className='pesado'>
                            ##unidade##
                        </div>
                    </ItemLegenda>
                    <ItemLegenda>
                        <div>
                            Telefone da Unidade de Atendimento
                        </div>
                        <div className='pesado'>
                            ##telefone_unidade##
                        </div>
                    </ItemLegenda>
                    <ItemLegenda>
                        <div>
                            Data e Hora da Sessão
                        </div>
                        <div className='pesado'>
                            ##data_hora_sessao##
                        </div>
                    </ItemLegenda>
                    <ItemLegenda>
                        <div>
                            Link de confirmação da sessão
                        </div>
                        <div className='pesado'>
                            ##url_confirmacao_presenca##
                        </div>
                    </ItemLegenda>
                </BlocoLegenda>
           
            </div>

            <FlexCP marginTop={20} justifyContent={'flex-end'}>
                <ButtonCP
                    onClick={save}
                    type={'primary'}
                    loading={saveMktTransactionalRequest.isAwaiting}
                >
                    Salvar
                </ButtonCP>
            </FlexCP>
        </>
    )

}

const EditorWrapper = styled.div`
  .editor-btn {
    right: 16px;
    bottom: 79px;
  }
`
const BlocoLegenda = styled.div`
    max-width:500px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding:5px;
    .pesado{
        font-weight: 686;
    }
    .titulo{
        font-weight: 726;
        text-transform: uppercase;
        font-size: 17px;
        border-bottom: 8px solid gray;
    }
`
const ItemLegenda = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: dotted 1px black;
`
