import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { IGenericSearchRequestDTO } from 'main/common/dtos/requests/IGenericSearchRequestDTO'
import { IGenericNameAndCodeSearchRequestDTO } from 'main/common/dtos/requests/IGenericNameAndCodeSearchRequestDTO'
import { IFormQuestionSaveRequestDTO } from 'main/modules/admin/services/treatment-form/dtos/request/IFormQuestionSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo de Formulario.
 */
export class TreatmentFormRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/treatment-forms`
    private static readonly _FORM_QUESTION_MODULE_BASE = `${TreatmentFormRequests._MODULE_BASE}/questions`

    static getOne = (code: number): RequestConfigTP => ({
        url: `${TreatmentFormRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static search = (dto?: IGenericSearchRequestDTO): RequestConfigTP => ({
        url: TreatmentFormRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchNameAndCode = (dto?: IGenericNameAndCodeSearchRequestDTO): RequestConfigTP => ({
        url: `${TreatmentFormRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${TreatmentFormRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static searchFormQuestionsNameAndCode = (dto?: IGenericNameAndCodeSearchRequestDTO): RequestConfigTP => ({
        url: `${TreatmentFormRequests._FORM_QUESTION_MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saveFormQuestion = (dto: IFormQuestionSaveRequestDTO): RequestConfigTP => ({
        url: TreatmentFormRequests._FORM_QUESTION_MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto
    })

}
