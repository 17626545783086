import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ISaleSourceAnalyticReportListItemResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISaleSourceAnalyticReportListItemResponseDTO'

/**
 * Utilitario do relatorio analitico de canais de venda
 */
export const SaleSourcesAnalyticReportUtils = {

    getTableColumns(
        grupedTitles: string[]
    ): Array<ColumnProps<ISaleSourceAnalyticReportListItemResponseDTO>> {

        const columns: Array<ColumnProps<ISaleSourceAnalyticReportListItemResponseDTO>> = []

        columns.push({
            title: 'Canal',
            dataIndex: 'source',
            key: 'source',
            render: (text, dto) => dto.sourceName
        })

        // Itera nos agrupamentos para montar o conjunto de colunas dinamico
        if (grupedTitles.length > 1) {
            grupedTitles.forEach((currentTitle) => {
                columns.push({
                    title: currentTitle,
                    children: [
                        {
                            title: 'Vendas',
                            dataIndex: 'sales',
                            key: 'sales',
                            align: 'right',
                            render: (text, dto) => {
                                const reportData = dto.reportGroupedData.find(data => data.groupBy === currentTitle)
                                return MaskUtils.applyNumberMask(reportData?.totalPaidSales ?? 0, 0)
                            }
                        },
                        {
                            title: 'Grátis',
                            dataIndex: 'sales',
                            key: 'sales',
                            align: 'right',
                            render: (text, dto) => {
                                const reportData = dto.reportGroupedData.find(data => data.groupBy === currentTitle)
                                return MaskUtils.applyNumberMask(reportData?.totalFreeSales ?? 0, 0)
                            }
                        },
                        {
                            title: 'Total',
                            dataIndex: 'sales',
                            key: 'sales',
                            align: 'right',
                            render: (text, dto) => {
                                const reportData = dto.reportGroupedData.find(data => data.groupBy === currentTitle)
                                const total = (reportData?.totalFreeSales ?? 0) + (reportData?.totalPaidSales ?? 0)
                                return <b>{ MaskUtils.applyNumberMask(total, 0)}</b>
                            }
                        },
                        {
                            title: 'Vendido',
                            dataIndex: 'sales',
                            key: 'sales',
                            align: 'right',
                            render: (text, dto) => {
                                const reportData = dto.reportGroupedData.find(data => data.groupBy === currentTitle)
                                return <b>{ MaskUtils.applyMoneyMask(reportData?.totalSalesValue ?? 0) }</b>
                            }
                        },
                    ]
                })
            })
        }

        columns.push({
            title: 'Total no Período',
            children: [
                {
                    title: 'Vendas',
                    dataIndex: 'sales',
                    key: 'sales',
                    align: 'right',
                    render: (text, dto) => MaskUtils.applyNumberMask(dto.totalPaidSales ?? 0, 0)
                },
                {
                    title: 'Grátis',
                    dataIndex: 'sales',
                    key: 'sales',
                    align: 'right',
                    render: (text, dto) => MaskUtils.applyNumberMask(dto.totalFreeSales ?? 0, 0)
                },
                {
                    title: 'Total',
                    dataIndex: 'sales',
                    key: 'sales',
                    align: 'right',
                    render: (text, dto) => <b>{ MaskUtils.applyNumberMask(dto.totalFreeSales + dto.totalPaidSales, 0) }</b>
                },
                {
                    title: 'Vendido',
                    dataIndex: 'sales',
                    key: 'sales',
                    align: 'right',
                    render: (text, dto) => <b>{ MaskUtils.applyMoneyMask(dto.totalSalesValue ?? 0) }</b>
                },
            ]
        })

        return columns
    },
}
