import React, { useEffect, useState } from 'react'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { IBankAccountWithdrawResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IBankAccountWithdrawResponseDTO'
import { BankAccountWithdrawFormModel } from 'main/modules/financial/components/drawer-bank-account-withdraw/inner/BankAccountWithdrawFormModel'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IBankAccountWithdrawSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankAccountWithdrawSaveRequestDTO'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'

interface IDrawerBankAccountTransferCPProps {
    visible: boolean
    onClose: (dataChanged: boolean) => void
    code?: number
}

/**
 * Drawer de dados de transferencia conta bancaria
 */
export function DrawerBankAccountWithdrawCP(props: IDrawerBankAccountTransferCPProps): JSX.Element {

    useEffect(initialize, [props.visible])

    const [formValidator, setFormValidator] = useState<BankAccountWithdrawFormModel>((new BankAccountWithdrawFormModel()))
    const formStateManager = useFormStateManager<BankAccountWithdrawFormModel>(formValidator)

    const [loadedBankAccountTransfer, setLoadedBankAccountTransfer] = useState<IBankAccountWithdrawResponseDTO>()
    const getBankAccountTransferRequest = useRequest<IBankAccountWithdrawResponseDTO>()
    useEffect(onGetBankAccountTransferRequestChange, [getBankAccountTransferRequest.isAwaiting])

    const saveBankAccounTransferRequest = useRequest<IBankAccountResponseDTO>()
    useEffect(onSaveBankAccountTransferRequestChange, [saveBankAccounTransferRequest.isAwaiting])

    const deleteBankAccountTransferRequest = useRequest<void>('none')
    useEffect(onDeleteBankAccountTrasnferRequestChange, [deleteBankAccountTransferRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function initialize(): void {

        setFormValidator(new BankAccountWithdrawFormModel({}))
        if (!props.visible)
            return

        if (props.code) {
            getBankAccountTransferRequest.runRequest(FinancialTransactionsRequests.getBankAccountWithdraw(props.code))
            return
        }

        setLoadedBankAccountTransfer(undefined)
        setFormValidator(new BankAccountWithdrawFormModel({
            date: new Date()
        }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetBankAccountTransferRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getBankAccountTransferRequest, 'Erro obter transferência'))
            return

        const responseDTO = getBankAccountTransferRequest.responseData
        if (!responseDTO)
            return

        setLoadedBankAccountTransfer(responseDTO)

        setFormValidator(new BankAccountWithdrawFormModel({
            date: DateUtils.toDate(responseDTO.date, DateFormatEnum.US_WITH_TIME_H_M),
            value: MaskUtils.applyMoneyMask(responseDTO.value),
            bankAccountSourceCode: responseDTO.bankAccountSource.code,
            bankAccountTargetCode: responseDTO.bankAccountTarget.code
        }))

    }

    /**
     * Salva transferencia
     */
    async function save(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: IBankAccountWithdrawSaveRequestDTO = {
            date: DateUtils.getFormatted(formValues.date, DateFormatEnum.US_WITH_TIME_H_M),
            value: MoneyUtils.convertToFloat(formValues.value),
            bankAccountSourceCode: formValues.bankAccountSourceCode,
            bankAccountTargetCode: formValues.bankAccountTargetCode,
        }

        if (!!props.code)
            saveBankAccounTransferRequest.runRequest(FinancialTransactionsRequests.updateBankAccountWithdraw(props.code, dto))
        else
            saveBankAccounTransferRequest.runRequest(FinancialTransactionsRequests.createBankAccountWithdraw(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveBankAccountTransferRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveBankAccounTransferRequest, 'Ocorreu algun erro ao salvar conta bancaria'))
            return

        NotificationHelper.info('Yes', 'Transferência salva com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Remover.
     */
    function deleteWithdraw(): void {

        if (!props.code)
            return

        deleteBankAccountTransferRequest.runRequest(FinancialTransactionsRequests.deleteBankAccountWithdraw(props.code))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteBankAccountTrasnferRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteBankAccountTransferRequest, 'Não foi remover a transferência', true))
            return

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Transferência entre Contas'}
            width={350}
            visible={props.visible}
            loading={getBankAccountTransferRequest.isAwaiting}
            onClose={() => props.onClose(false)}
            footerSpaced={!!props.code}
            footer={
                <>
                    {
                        props.code &&
                        <ButtonCP
                            type={'danger'}
                            loading={deleteBankAccountTransferRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover esta transferência?'}
                            onClick={deleteWithdraw}
                        >
                            Remover
                        </ButtonCP>
                    }
                    <ButtonCP
                        type={'primary'}
                        onClick={save}
                        loading={saveBankAccounTransferRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }

        >
            <AutocompleteBankAccountCP
                formStateManager={formStateManager}
                label={'Conta Origem'}
                fieldName={'bankAccountSourceCode'}
                required={true}
                initialOptions={!!loadedBankAccountTransfer ? [loadedBankAccountTransfer.bankAccountSource] : undefined}
            />

            <FlexCP justifyContent={'center'}>
                <IconCP antIcon={'arrow-down'}/>
            </FlexCP>

            <AutocompleteBankAccountCP
                formStateManager={formStateManager}
                label={'Conta Destino'}
                fieldName={'bankAccountTargetCode'}
                required={true}
                initialOptions={!!loadedBankAccountTransfer ? [loadedBankAccountTransfer.bankAccountTarget] : undefined}
            />

            <InputCP
                label={'Data'}
                required={true}
                type={'date'}
                fieldName={'date'}
                formStateManager={formStateManager}
            />

            <InputCP
                width={170}
                label={'Valor'}
                formStateManager={formStateManager}
                fieldName={'value'}
                type={'text'}
                mask={InputMaskTypeEnum.MONEY}
            />

        </DrawerCP>
    )
}
