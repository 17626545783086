import { PaginationConfig } from 'antd/lib/table'
import _ from 'lodash'
import { TableCPOLD, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ProductReleaseFranchiseRequests } from 'main/modules/products/services/product-release-franchise/ProductReleaseFranchiseRequests'
import React, { useEffect, useState } from 'react'
import { ISearchProductReleaseRequestDTO } from '../../services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'
import { ProductReleaseDetailsCP } from '../product-release-details/ProductReleaseDetailsCP'
import { ProductReleaseFranchiseTableColumns } from 'main/modules/products/components/table-product-release-franchise/inner/ProductReleaseFranchiseTableColumns'
import { ProductReleaseFranchiseTableFilters } from 'main/modules/products/components/table-product-release-franchise/inner/ProductReleaseFranchiseTableFilters'
import { CardCP } from 'main/common/components/card/CardCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IProductReleaseFranchiseResponseDTO } from 'main/modules/products/services/product-release-franchise/dtos/response/IProductReleaseFranchiseResponseDTO'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'

const DEFAULT_PAGINATION: TablePaginationTP = {
    current: 1,
    pageSize: 50,
    total: 0,
    showTotal: (total, range) => `Mostrando ${range.join('-')} de ${total}`
}

const DEFAULT_FILTERS: ISearchProductReleaseRequestDTO = {
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    page: 1
}

interface IProductReleaseListTableCPProps {
    shouldFilterUnsetReleases: boolean
    onUpdate?: () => void
}

/**
 * Componente de tabela que lista os pacotes de atendimento de uma franquia
 */
export function TableProductReleaseFranchiseCP(props: IProductReleaseListTableCPProps): JSX.Element {

    const [filters, setFilters] = useState<ISearchProductReleaseRequestDTO>(DEFAULT_FILTERS)
    const [pagination, setPagination] = useState<TablePaginationTP>(DEFAULT_PAGINATION)
    const [selectedRelease, setSelectedRelease] = useState<IProductReleaseFranchiseResponseDTO>()
    const [shouldOpenDrawer, setShouldOpenDrawer] = useState<boolean>(false)

    const searchProductReleaseRequest = useRequest<IGenericListResponseDTO<IProductReleaseFranchiseResponseDTO>>()

    useEffect(getProductReleaseList, [filters])
    useEffect(filterUnsetReleases, [props.shouldFilterUnsetReleases])
    useEffect(onSearchProductReleaseRequestChange, [searchProductReleaseRequest.isAwaiting])

    function filterUnsetReleases(): void {
        if (props.shouldFilterUnsetReleases)
            setFilters({ ...filters, hasPrice: false })
    }

    function getProductReleaseList(update?: boolean): void {

        if (update) {
            setShouldOpenDrawer(false)

            if (props.onUpdate)
                props.onUpdate()
        }

        searchProductReleaseRequest.runRequest(ProductReleaseFranchiseRequests.searchProductReleasesFranchise(filters))
    }

    function onSearchProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchProductReleaseRequest, 'Erro ao buscar lista com pacotes de atendimento'))
            return

        if (searchProductReleaseRequest.responseData && _.isEmpty(searchProductReleaseRequest.responseData.list))
            NotificationHelper.warning('Ops!', 'Sua franqueadora ainda não definiu um intervalo de preço para nenhum pacote de atendimento.')

        setPagination({
            ...pagination,
            total: searchProductReleaseRequest.responseData!.total || 0
        })
    }

    function clearFilters(): void {
        setFilters({ ...DEFAULT_FILTERS })
        setPagination(DEFAULT_PAGINATION)
    }

    function editProductRelease(release: IProductReleaseFranchiseResponseDTO): void {
        setShouldOpenDrawer(true)
        setSelectedRelease(release)
    }

    return (
        <>
            <FlexCP
                justifyContent={'flex-end'}
                marginRight={20}
            >
                <ButtonCP
                    type={'link'}
                    size={'small'}
                    onClick={clearFilters}
                    icon={'filter'}
                >
                    Limpar filtros
                </ButtonCP>
            </FlexCP>

            <CardCP innerSpacing={'none'}>

                <TableCPOLD<IProductReleaseFranchiseResponseDTO>
                    data={searchProductReleaseRequest.responseData ? searchProductReleaseRequest.responseData.list : []}
                    columns={ProductReleaseFranchiseTableColumns({ setFilters, filters, editProductRelease })}
                    loading={searchProductReleaseRequest.isAwaiting}
                    skeletonRows={10}
                    costumizedFilters={ProductReleaseFranchiseTableFilters({ setFilters, filters })}
                    rowKey={(record): string => record.productRelease.code}
                    emptyText={'Nenhum resultado para os filtros selecionados'}
                    pagination={pagination}
                    locale={{
                        filterConfirm: 'Filtrar',
                        filterReset: 'Limpar',
                        emptyText: 'Sem resultados para os filtros selecionados'
                    }}
                    onPaginationChange={(page: PaginationConfig): void => {
                        setPagination({ ...pagination, current: page.current! })
                        setFilters({ ...filters, page: page.current })
                    }}
                />
            </CardCP>

            <DrawerCP
                onClose={() => setShouldOpenDrawer(false)}
                visible={shouldOpenDrawer}
                title={'Pacote de Atendimento'}
            >
                <ProductReleaseDetailsCP
                    productReleaseFranchise={selectedRelease!}
                    onEdiDone={(): void => getProductReleaseList(true)}
                />
            </DrawerCP>
        </>
    )
}
