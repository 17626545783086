import React from 'react'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { AutocompleteTreatmentGroupCP } from 'main/modules/admin/components/autocomplete-treatment-group/AutocompleteTreatmentGroupCP'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { CalendarCP } from 'main/common/components/calendar/CalendarCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ScheduleEventUtils } from 'main/modules/scheduler/utils/ScheduleEventUtils'

interface IScreenSiderSchedulerCPProps {
    formStateManager: IFormStateManager<FormFilterCalendarEventsFormModel>
    onFilter: () => void
    filtersConfig?: {
        hideEvaluation?: boolean
        hideTreatmentGroup?: boolean
        showNoShow?: boolean
    }
}

/**
 * Representa os filtros da agenda. Select de profissionais e cargo/funções da clínica.
 */
export function FormFilterCalendarEventsCP(props: IScreenSiderSchedulerCPProps): JSX.Element {

    /**
     * Ao selecionar um dia no filtro
     */
    function onSelectDate(date: Date): void {

        // Verifica se a agenda esta no modo diario ou semanal para setar o intervalo correto das datas
        const dateInterval: IDateRangeFilter =  props.formStateManager.getFieldValue('dateInterval')

        if (ScheduleEventUtils.isWeekView(dateInterval))
            props.formStateManager.changeFieldValue('dateInterval', DateUtils.getFirstAndLastDayOfWeek(date))
        else
            props.formStateManager.changeFieldValue('dateInterval', { beginDate: date, endDate: date })

        props.onFilter()
    }

    return (
        <WrapperSCP>
            <CalendarCP
                currentDate={props.formStateManager.getFieldValue('dateInterval')}
                onChangeSelectedDate={onSelectDate}
            />

            <FiltersWrapperSCP>
                <TitleCP underLine={true} marginBottom={30}>
                    Filtros
                </TitleCP>

                {
                    props.filtersConfig?.showNoShow &&
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Exibir no-shows'}
                        marginBottom={20}
                        formStateManager={props.formStateManager}
                        fieldName={'showNoShow'}
                    />
                }

                {
                    !props.filtersConfig?.hideEvaluation &&
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Exibir avaliações'}
                        marginBottom={20}
                        formStateManager={props.formStateManager}
                        fieldName={'showEvaluations'}
                    />
                }

                {
                    !props.filtersConfig?.hideTreatmentGroup &&
                    <AutocompleteTreatmentGroupCP
                        isMultiple={true}
                        label={'Grupo de tratamento'}
                        formStateManager={props.formStateManager}
                        fieldName={'treatmentGroupCodes'}
                    />
                }

                <AutocompleteUserCP
                    label={'Profissional'}
                    isMultiple={true}
                    onlyWithWorkSchedule={true}
                    formStateManager={props.formStateManager}
                    fieldName={'userCodes'}
                />

                <FlexCP justifyContent={'flex-end'}>
                    <ButtonCP
                        marginTop={20}
                        type={'primary'}
                        onClick={props.onFilter}
                    >
                        Filtrar
                    </ButtonCP>
                </FlexCP>
            </FiltersWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  overflow: auto;
`


const FiltersWrapperSCP = styled.div`
  margin: 20px 10px 0 10px
`
