import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { FormAnswersSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/form-answers/request/FormAnswersSaveRequestDTO'
import { ICountPeopleToShootRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/ICountPeopleToShootRequestDTO'
import { IPersonSaveRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSaveRequestDTO'
import { ISearchPersonByExpressionRequestDTO } from 'main/modules/people/services/person/dtos/request/ISearchPersonByExpressionRequestDTO'
import { IPersonSearchRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchRequestDTO'
import { IPersonWithSessionsAvailableRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IPersonWithSessionsAvailableRequestDTO'
import { IGenericSearchRequestDTO } from 'main/common/dtos/requests/IGenericSearchRequestDTO'
import { IPersonSearchByNameRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchByNameRequestDTO'
import { IPersonSearchByUniqueFieldsRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchByUniqueFieldsRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo CRM (PEOPLE)
 */
export class PersonRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/people`

    static search = (dto: IPersonSearchRequestDTO): RequestConfigTP => ({
        url: PersonRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchByNameConfig = (dto: IPersonSearchByNameRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchByUniqueFields = (dto: IPersonSearchByUniqueFieldsRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/search-by-unique-fields`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOne = (personCode: number): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}`,
        method: HttpMethodEnum.GET,
    })

    static create = (dto: IPersonSaveRequestDTO): RequestConfigTP => ({
        url: PersonRequests._MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (personCode: number, dto: IPersonSaveRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getClients = (dto: IPersonWithSessionsAvailableRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/search-by-person-with-sessions-available`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static uploadAvatar = (personCode: number, file: File): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)

        return ({
            url: `${PersonRequests._MODULE_BASE}/${personCode}/upload-avatar`,
            method: HttpMethodEnum.POST,
            params: formData
        })
    }

    static savePersonAnswers = (personCode: number, dto: FormAnswersSaveRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}/answers`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static countPeopleToShoot = (dto: ICountPeopleToShootRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/count-people-to-shoot/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    /** Procura clientes ou fornecedores de acordo com um filtro de lista de segmentação. */
    static searchByExpression = (dto: ISearchPersonByExpressionRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/search-by-expression/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static purchasesConfig = (personCode: number, dto: IGenericSearchRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}/purchases`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOpportunities = (personCode: number): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}/opportunities`,
        method: HttpMethodEnum.GET,
    })

    static getReferrals = (personCode: number): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}/referrals`,
        method: HttpMethodEnum.GET,
    })
}
