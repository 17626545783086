import { ModalCP } from 'main/common/components/modal/ModalCP'
import { TableWorkScheduleCP } from 'main/modules/scheduler/components/table-work-schedule/TableWorkScheduleCP'
import { IWorkSchedule } from 'main/modules/scheduler/interfaces/IWorkSchedule'
import React, { useEffect, useState } from 'react'
import { IWorkScheduleResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IWorkScheduleResponseDTO'
import { IWorkScheduleRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkScheduleRequestDTO'
import { WorkScheduleUtils } from 'main/modules/scheduler/utils/WorkScheduleUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

interface IModalWorkScheduleCPProps {
    workSchedule: IWorkScheduleResponseDTO[]
    hasIntervalColumn: boolean
    visible: boolean
    onCancel: () => void
    onSave: (newWorkSchedule: IWorkScheduleRequestDTO[]) => void
    title: string
    loading: boolean
}

/**
 * Modal que definie horario de funcionamento / trabalho.
 */
export function ModalWorkScheduleCP(props: IModalWorkScheduleCPProps): JSX.Element {

    const [workScheduleModified, setWorkScheduleModified] = useState<IWorkSchedule[]>(props.workSchedule)
    useEffect(init, [props.visible, props.workSchedule])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.visible)
            return

        setWorkScheduleModified(WorkScheduleUtils.transformDtoToWorkSchedule(props.workSchedule))
    }

    /**
     * Recebe da tabela os dados do horario que está sendo editado.
     * Verifica qual dia da semana deve ser modificado e mescla com as informações já existentes
     */
    function onEditWorkingTime(newWorkingTime: IWorkSchedule): void {

        const schedules: IWorkSchedule[] = []

        // Percorre a tabela atual de horarios, adicionando o horario novo e clononando os horarios ja inseridos
        workScheduleModified.forEach((_workSchedule) => {

            if (_workSchedule.weekDay === newWorkingTime.weekDay)
                return schedules.push(newWorkingTime)

            schedules.push(_workSchedule)
        })

        setWorkScheduleModified(schedules)
    }

    /**
     * Recebe da tabela os dados do horario que está sendo editado.
     * Verifica qual dia da semana deve ser modificado e mescla com as informações já existentes
     */
    function onSave(): void {

        if (!WorkScheduleUtils.isAllWorkScheduleValid(workScheduleModified, props.hasIntervalColumn))
            return NotificationHelper.error('Ops!', 'Corrija as datas inválidas antes de prosseguir')

        const workScheduleDto = WorkScheduleUtils.transformWorkScheduleToDto(workScheduleModified)
        props.onSave(workScheduleDto ?? [])
    }

    return (

        <ModalCP
            title={props.title}
            visible={props.visible}
            onOk={onSave}
            onCancel={props.onCancel}
            width={props.hasIntervalColumn ? 680 : 430}
            loading={props.loading}
        >
            <TableWorkScheduleCP
                hasIntervalColumn={props.hasIntervalColumn}
                workSchedule={workScheduleModified}
                onChangeWorkSchedule={(params) => onEditWorkingTime(params)}
            />
        </ModalCP>
    )
}
