import { IsBoolean, IsOptional } from 'class-validator/decorator/decorators'
import { IsString } from 'main/common/validation/decorators/IsString'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsDateString } from 'main/common/validation/decorators/IsDateString'
import { Moment } from 'moment'
import { OpportunityScheduleTypeEnum } from 'main/modules/sales-funnel/services/evaluation/enums/OpportunityScheduleTypeEnum'
import { OpportunityScheduleStatusEnum } from 'submodules/neritclin-sdk/services/opportunity-schedule/enums/OpportunityScheduleStatusEnum'

export class EvaluationFormModel extends FormModel {

    @IsNotEmpty()
    responsibleCode: number

    @IsNotEmpty()
    @IsDateString()
    date: Date

    @IsOptional()
    @IsString()
    notes: string

    @IsOptional()
    @IsBoolean()
    presenceConfirmation: boolean

    @IsNotEmpty()
    status: OpportunityScheduleStatusEnum

    @IsNotEmpty()
    beginTime: Moment

    @IsNotEmpty()
    endTime: Moment

    @IsNotEmpty()
    scheduleType: OpportunityScheduleTypeEnum

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }

}
