import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import { WorkScheduleUtils } from 'main/modules/scheduler/utils/WorkScheduleUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TableWorkScheduleCP } from 'main/modules/scheduler/components/table-work-schedule/TableWorkScheduleCP'
import { ModalWorkScheduleCP } from 'main/modules/scheduler/components/modal-work-schedule/ModalWorkScheduleCP'
import { IWorkScheduleResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IWorkScheduleResponseDTO'
import { IWorkSchedulesSaveRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkSchedulesSaveRequestDTO'
import { IWorkScheduleRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IWorkScheduleRequestDTO'

interface IUserWorkScheduleCPProps {
    userCode: number
}

export function UserWorkScheduleCP(props: IUserWorkScheduleCPProps): JSX.Element {

    const [isEditWorkScheduleModalVisible, setIsEditWorkScheduleModalVisible] = useState<boolean>(false)
    const [workSchedules, setWorkSchedules] = useState<IWorkScheduleResponseDTO[]>([])

    useEffect(init, [props.userCode])

    const getUserWorkSchedulesRequest = useRequest<IWorkScheduleResponseDTO[]>()
    useEffect(onGetUserWorkSchedulesRequestChange, [getUserWorkSchedulesRequest.isAwaiting])

    const updateWorkScheduleRequest = useRequest<'none'>()
    useEffect(onUpdateWorkScheduleRequestChange, [updateWorkScheduleRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {
        getUserWorkSchedulesRequest.runRequest(UserRequests.getWorkSchedules(props.userCode))
    }

    /**
     * Retorno ao obter horarios.
     */
    function onGetUserWorkSchedulesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getUserWorkSchedulesRequest, 'Erro ao buscar horário de trabalho'))
            return

        setWorkSchedules(getUserWorkSchedulesRequest.responseData!)
    }

    /**
     * Atualiza com os horarios informados.
     */
    function updateWorkSchedule(newWorkSchedule: IWorkScheduleRequestDTO[]): void {

        const dto: IWorkSchedulesSaveRequestDTO = {
            workSchedule: newWorkSchedule ?? []
        }

        updateWorkScheduleRequest.runRequest(UserRequests.updateWorkScheduleConfig(props.userCode, dto))
    }

    /**
     * Retorno da API ao salvar horario de trabalho.
     */
    function onUpdateWorkScheduleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateWorkScheduleRequest, 'Erro ao salvar horário de trabalho'))
            return

        setIsEditWorkScheduleModalVisible(false)
        NotificationHelper.success('Pronto', 'Horários atualizados com sucesso.')
        init()
    }

    return (
        <WrapperSCP>

            <TableWorkScheduleCP
                workSchedule={WorkScheduleUtils.transformDtoToWorkSchedule(workSchedules)}
                hasIntervalColumn={true}
                readonly={true}
                loading={getUserWorkSchedulesRequest.isAwaiting}
            />

            <FlexCP justifyContent={'flex-end'} marginTop={20}>
                <ButtonCP
                    onClick={(): void => setIsEditWorkScheduleModalVisible(true)}
                    type={'primary'}
                >
                    Alterar horário de trabalho
                </ButtonCP>
            </FlexCP>

            <ModalWorkScheduleCP
                title={'Editar Horário de Trabalho'}
                hasIntervalColumn={true}
                visible={isEditWorkScheduleModalVisible}
                workSchedule={workSchedules || []}
                onCancel={() => setIsEditWorkScheduleModalVisible(false)}
                onSave={updateWorkSchedule}
                loading={updateWorkScheduleRequest.isAwaiting}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-top: 25px;
`
