import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { OpportunityStatusEnum, OpportunityStatusLabelEnum } from '../../enums/OpportunityStatusEnum'

interface ITagOpportunityStatusCPProps {
    status: OpportunityStatusEnum
}

/**
 * COMPONENTE Tag de status da Sessao
 */
export function TagOpportunityStatusCP(props: ITagOpportunityStatusCPProps): JSX.Element {

    let color: TagCPColorTP

    switch (props.status) {

        case OpportunityStatusEnum.CONCLUDED:
            color = 'green'
            break

        case OpportunityStatusEnum.LOST:
            color = 'red'
            break

        case OpportunityStatusEnum.OPEN:
        default:
            color = 'yellow'
    }

    return (
        <TagCP
            key={props.status}
            color={color}
            content={<TextCP text={OpportunityStatusLabelEnum[props.status]}/>}
        />
    )
}
