import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'

interface IUnitValueOperatorDisplayICPProps {
    children: string
}

/**
 * COMPONENTE
 * Mostrador para operador dentro de expressoes de lista de segmentacao.
 * e.g: Operador entre agregadores ou entre unidades
 */
export function UnitValueOperatorDisplayICP(props: IUnitValueOperatorDisplayICPProps): JSX.Element {
    return <OperatorSCP>{props.children}</OperatorSCP>
}

const OperatorSCP = styled.div`
    font-style: italic;
    padding: 3px;

    &:nth-child(2) {
        padding-left: 6px;
    }
`
