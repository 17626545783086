import React from 'react'
import styled from 'main/config/theme/styledWithTheme'
import { SiderCP } from 'main/common/components/screen-layout/sider/SiderCP'
import bgImg from 'main/modules/auth/components/auth-screen-cover/inner/auth-cover.jpg'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * COMPONENTE
 * Imagem de fundo nas telas do modulo de AUTENTICACAO.
 */
export function AuthScreenCoverCP(): JSX.Element {

    const hasCoverImg = !!AppStateUtils.getDomainData()?.schema?.coverUrl
    const coverBck = AppStateUtils.getDomainData()?.schema?.coverUrl ?? bgImg

    return (
        <SiderCP width={'60%'}>
            <WrapperSCP>
                <CoverSCP imgUrl={coverBck}>
                    {
                        !hasCoverImg &&
                        <ColorSCP/>
                    }
                </CoverSCP>
                <BarSCP/>
            </WrapperSCP>
        </SiderCP>
    )
}

const WrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const CoverSCP = styled.div<{ imgUrl: string }>`
    height: 100%;
    background-image: url(${props => props.imgUrl});
    background-position: center;
    background-size: cover;
    display: flex;
`

const BarSCP = styled.div`
    height: 60%;
    width: 5px;
    background-color: ${props => props.theme.primaryColor};
    position: absolute;
    right: -2px;
    top: 20%;
`
const ColorSCP = styled.div`
    background: radial-gradient(#4082f6, #06327f);
    opacity: 0.7;
    flex: 1;
`
