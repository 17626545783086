import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import React, { useEffect, useState } from 'react'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { ResumeWidgetByPaymentMethodICP } from 'main/modules/financial/screens/screen-financial/content/screen-content-charging/inner/ResumeWidgetByPaymentMethodICP'
import { styled } from 'main/config/theme/styledWithTheme'
import { IFinancialTransactionSummaryByPaymentMethodRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSummaryByPaymentMethodRequestDTO'
import { IFinancialTransactionSummaryByPaymentMethodListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionSummaryByPaymentMethodListItemResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import * as _ from 'lodash'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { FinancialTransactionRequests } from 'submodules/neritclin-sdk/services/financial/financial/FinancialTransactionRequests'

interface IChargingPaymentMethodSummaryICPProps {
    filters: IFinancialTransactionSearchRequestDTO
    onChangePaymentMethod: (paymentMethod: PaymentMethodEnum) => void
    shouldReloadSummary: boolean
    onSummaryLoaded: () => void
}

export function ChargingPaymentMethodSummaryICP(props: IChargingPaymentMethodSummaryICPProps): JSX.Element {

    useEffect(init, [props.shouldReloadSummary])

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodEnum>()

    const [summaryData, setSummaryData] = useState<IFinancialTransactionSummaryByPaymentMethodListItemResponseDTO[]>()
    const summaryRequest = useRequest<IGenericListResponseDTO<IFinancialTransactionSummaryByPaymentMethodListItemResponseDTO>>()
    useEffect(onSummaryRequestChange, [summaryRequest.isAwaiting])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.shouldReloadSummary)
            return

        const searchDto: IFinancialTransactionSummaryByPaymentMethodRequestDTO = {
            status: [FinancialTransactionStatusEnum.OVERDUE],
            franchiseCodes: props.filters.franchiseCodes,
            bankAccountCode: props.filters.bankAccountCode,
            expirateInterval: props.filters.expirateInterval,
            paymentMethod: props.filters.paymentMethod,
            personCode: props.filters.personCode,
            searchString: props.filters.searchString,
            paymentInterval: props.filters.paymentInterval,
            paymentPartnerCode: props.filters.paymentPartnerCode,
            saleInterval: props.filters.saleInterval,
            valueRange: props.filters.valueRange,
            considerOnlySaleTransactions: true,
            referenceInterval: props.filters.referenceInterval
        }
        summaryRequest.runRequest(FinancialTransactionRequests.summaryBillingDashboardByPaymentMethod(searchDto))
    }

    /**
     * Retorno dos dados da API
     */
    function onSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(summaryRequest, 'Ocorreu algun erro ao obter resumo por forma de pagamento'))
            return

        setSummaryData(summaryRequest.responseData!.list)
        props.onSummaryLoaded()
    }

    /**
     * Obtem o valor da forma de pagamento escolhida
     */
    function getValueByPaymentMethod(type: 'value' | 'count', _paymentMethod: PaymentMethodEnum): number | undefined {

        const filteredByPM: IFinancialTransactionSummaryByPaymentMethodListItemResponseDTO[] = _.filter(summaryData, { paymentMethod: _paymentMethod })

        if (_.isEmpty(filteredByPM))
            return undefined

        // Como estamos iterando por forma de pagamento, sera retornado apenas um valor entao podemos pegar o get(0)
        if (type === 'count')
            return filteredByPM[0].count

        return filteredByPM[0].value
    }

    /**
     * Ao filtrar por uma forma de pagamento.
     */
    function onSelectPaymentMethod(_paymentMethod: PaymentMethodEnum): void {
        setSelectedPaymentMethod(_paymentMethod)
        props.onChangePaymentMethod(_paymentMethod)
    }

    return (
        <WrapperSCP>
            {
                Object.values(PaymentMethodEnum).map((paymentMethod, index) => {
                    return (
                        <ResumeWidgetByPaymentMethodICP
                            key={`key-${index}`}
                            loading={summaryRequest.isAwaiting}
                            onSelectPaymentMethod={() => onSelectPaymentMethod(paymentMethod)}
                            paymentMethod={paymentMethod}
                            value={getValueByPaymentMethod('value', paymentMethod)}
                            valueCount={getValueByPaymentMethod('count', paymentMethod)}
                            isSelected={selectedPaymentMethod === paymentMethod}
                        />
                    )
                })
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  display:flex;
  flex-flow: row wrap;
  width:100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px;
`
