import React from 'react'
import { ObjKeyTP } from 'main/common/types/ObjKeyTP'
import { CtxFactoryActionBuilderTP } from 'main/common/context-factory/types/CtxFactoryActionBuilderTP'
import { CtxFactoryContextTP } from 'main/common/context-factory/types/CtxFactoryContextTP'
import { CtxFactoryDispatchTP } from 'main/common/context-factory/types/CtxFactoryDispatchTP'
import { CtxFactoryReducerTP } from 'main/common/context-factory/types/CtxFactoryReducerTP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { CtxFactoryHookTP } from 'main/common/context-factory/types/CtxFactoryHookTP'
import { CtxFactoryActionBuildingHelper } from 'main/common/context-factory/CtxFactoryActionBuildingHelper'

type ConfigTP<StateTP, ActionTypeTP extends ObjKeyTP> = {
    contextName: string,
    initialStateGetter: () => StateTP,
    actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP>,
    reducer?: CtxFactoryReducerTP<StateTP, ActionTypeTP>,
    enableDebug?: boolean,
}

/**
 * GERADOR de CONTEXTO
 * Esta classe encapsula a geracao automatizada de 01 contexto [1] + 01 hook padronizado para gestao do mesmo.
 *
 * TODO: Havera realmente a necessidade de se utilizar 01 getter para estado inicial?
 *
 * - O resultado gerado por esta classe eh uma implementacao baseada em [2];
 * - O resultado gerado eh composto pela combinacao de 02 contextos;
 * - O resultado gerado possui estado (formato analogo ao do 'redux') (state + dispatch). Para isso requer 01 'reducer' [3];
 * - O reducer, assim como as actions, pode ser passado via parametro & deve possuir formato padronizado;
 *
 * @see [1] https://reactjs.org/docs/hooks-reference.html#usecontext
 * @see [2] https://kentcdodds.com/blog/how-to-use-react-context-effectively#conclusion
 * @see [3] https://reactjs.org/docs/hooks-reference.html#usereducer
 */
export class ContextFactory { // eslint-disable-line @typescript-eslint/naming-convention

    static getOne<StateTP, ActionTypeTP extends ObjKeyTP>(config: ConfigTP<StateTP, ActionTypeTP>): CtxFactoryContextTP<StateTP, ActionTypeTP> {

        const stateNestedContext = React.createContext<OrUndefTP<StateTP>>(undefined)
        stateNestedContext.displayName = `${config.contextName}: State`

        const dispatchNestedContext = React.createContext<OrUndefTP<CtxFactoryDispatchTP<StateTP, ActionTypeTP>>>(undefined)
        dispatchNestedContext.displayName = `${config.contextName}: Dispatch`

        return {
            Provider: ContextFactory._getProvider(stateNestedContext, dispatchNestedContext, config.initialStateGetter, config.reducer), // eslint-disable-line @typescript-eslint/naming-convention
            use: ContextFactory._getMainHook(config.contextName, stateNestedContext, dispatchNestedContext, config.actionBuilder),
            useState: ContextFactory._getUseStateHook(config.contextName, stateNestedContext),
            enableDebug: !!config.enableDebug,
        }
    }

    private static _getProvider<StateTP, ActionTypeTP extends ObjKeyTP>(
        stateNestedContext: React.Context<any>,
        dispatchNestedContext: React.Context<any>,
        initialStateGetter: () => StateTP,
        reducer?: CtxFactoryReducerTP<StateTP, ActionTypeTP>,

    ): React.FunctionComponent<React.PropsWithChildren<{}>> {

        return (props: React.PropsWithChildren<{}>): JSX.Element => {

            const [state, dispatch] = React.useReducer(reducer ?? ContextFactory._getDefaultReducer(), initialStateGetter())

            return (
                <stateNestedContext.Provider value={state}>
                    <dispatchNestedContext.Provider value={dispatch}>
                        {props.children}
                    </dispatchNestedContext.Provider>
                </stateNestedContext.Provider>
            )
        }
    }

    private static _getMainHook<StateTP, ActionTypeTP extends ObjKeyTP>(
        contextName: string,
        stateNestedContext: React.Context<OrUndefTP<StateTP>>,
        dispatchNestedContext: React.Context<OrUndefTP<CtxFactoryDispatchTP<StateTP, ActionTypeTP>>>,
        actionBuilder: CtxFactoryActionBuilderTP<StateTP, ActionTypeTP>,

    ): () => CtxFactoryHookTP<StateTP, ActionTypeTP> {

        return (): CtxFactoryHookTP<StateTP, ActionTypeTP> => {

            const state = ContextFactory._getUseStateHook(contextName, stateNestedContext)().state
            const dispatch = React.useContext(dispatchNestedContext)

            if (!dispatch)
                throw new Error(`Contexto ${contextName} inacessivel ou nao inicializado`)

            return {
                state,
                actions: actionBuilder(new CtxFactoryActionBuildingHelper<StateTP, ActionTypeTP>(state, dispatch))
            }
        }
    }

    /**
     * NOTE: Funcao 'useContext' soh pode ser chamada de dentro de 01 componente funcional
     */
    private static _getUseStateHook<StateTP, ActionTypeTP extends ObjKeyTP, HookActionsTP extends ObjKeyTP>(
        contextName: string,
        stateNestedContext: React.Context<OrUndefTP<StateTP>>,

    ): () => { state: StateTP } {

        return (): { state: StateTP } => {
            const state = React.useContext(stateNestedContext)
            if (!state)
                throw new Error(`Contexto "${contextName}" inacessivel ou nao inicializado`)
            return { state }
        }
    }

    /**
     * Gera & retorna uma funcao generica que serve como um reducer trivial que sempre atualiza o estado anterior mesclando com
     * qualquer payload recebido.
     */
    private static _getDefaultReducer<StateTP, ActionTypeTP extends ObjKeyTP>(): CtxFactoryReducerTP<StateTP, ActionTypeTP> {
        return (currentState, action) => ({ ...currentState, ...action.payload })
    }
}
