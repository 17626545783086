import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutSubmenuContentCP } from 'main/common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import {
    MarketingTransactionalEventEnum,
    MarketingTransactionalEventLabelEnum
} from 'main/modules/marketing/screens/screen-marketing/inner/MarketingTransactionalEventEnum'
import { useParams } from 'react-router'
import { ModalMktTransactionalConfigCP } from 'main/modules/marketing/components/modal-mkt-transactional-config/ModalMktTransactionalConfigCP'
import { TimelineMktTransactionalsCP } from 'main/modules/marketing/components/timeline-mkt-transactionals/TimelineMktTransactionalsCP'

/**
 * Configuracoes de automacao de marketing.
 */
export function ScreenContentMktTransactionalCP(): JSX.Element {

    const routeParams = useParams<{ type: MarketingTransactionalEventEnum }>()

    const [isTransactionalConfigModalVisible, setIsTransactionalConfigModalVisible] = useState<boolean>(false)
    const [mktTransactionalCode, setMktTransactionalCode] = useState<number>()

    const [mustUpdateMktTransactionals, setMustUpdateMktTransactionals] = useState<boolean>(true)
    useEffect(() => setMustUpdateMktTransactionals(true), [routeParams.type])

    return (
        <>
            <HeaderCP title={`Automação para: ${MarketingTransactionalEventLabelEnum[routeParams.type]}`}>
                <HeaderButtonICP
                    onClick={() => {
                        setIsTransactionalConfigModalVisible(true)
                        setMktTransactionalCode(undefined)
                    }}
                    icon={'plus'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TimelineMktTransactionalsCP
                    event={routeParams.type}
                    mustUpdateData={mustUpdateMktTransactionals}
                    onLoadTransactionals={() => setMustUpdateMktTransactionals(false)}
                    onEdit={(code) => {
                        setMktTransactionalCode(code)
                        setIsTransactionalConfigModalVisible(true)
                    }}
                />
            </LayoutSubmenuContentCP>

            <ModalMktTransactionalConfigCP
                type={routeParams.type}
                code={mktTransactionalCode}
                visible={isTransactionalConfigModalVisible}
                onSave={() => {
                    setIsTransactionalConfigModalVisible(false)
                    setMustUpdateMktTransactionals(true)
                }}
                onCancel={() => {
                    setIsTransactionalConfigModalVisible(false)
                    setMustUpdateMktTransactionals(false)
                }}
            />
        </>
    )
}
