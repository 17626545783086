/**
 * ENUM
 * Campos que podem compor filtros de lista de segmentação.
 */
export enum SegmentUnitFieldsEnum {

    // Campos genericos
    CREATE_DATE = 'createDate',
    BIRTH_DATE = 'birthDate',
    NAME = 'name',
    GENDER = 'gender',
    EMAIL = 'email',
    EMAIL_STATUS = 'emailStatus',
    SMS_STATUS = 'smsStatus',
    PHONE = 'phone',
    STREET = 'street',
    CITY = 'city',
    NEIGHBORHOOD = 'neighborhood',
    STATE = 'state',
    TAGS = 'tags',
    CREATOR_USER_CODE = 'creatorUserCode',
    CPF_CNPJ = 'cpfCnpj',

    // Campos para 'clientes'
    PRODUCT_BOUGHT = 'productBought',
    PRODUCT_NOT_BOUGHT = 'productNotBought',
    OPPORTUNITY_TYPE = 'opportunityType',
    OPPORTUNITY_STATUS = 'opportunityStatus',
    SESSION_STATUS = 'sessionStatus',
    SALE_STATUS = 'saleStatus',
}
