import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IUserTreatmentGroupsSaveRequestDTO } from 'main/modules/user/services/dtos/request/IUserTreatmentGroupsSaveRequestDTO'
import { AutocompleteTreatmentGroupCP } from 'main/modules/admin/components/autocomplete-treatment-group/AutocompleteTreatmentGroupCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IModalUserJobFunctionDataCPProps {
    visible: boolean
    onCancelModal: () => void
    onSave: () => void
    userCode?: number
}

/**
 * Modal para adicionar funções de trabalho a um colaborador
 */
export function ModalUserTreatmentGroupCP(props: IModalUserJobFunctionDataCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [selectedTreatmentGroups, setSelectedTreatmentGroups] = useState<number[]>([])

    const userTreatmentGroupsRequest = useRequest<IGenericListResponseDTO<INameAndCodeResponseDTO>>()
    useEffect(onUserTreatmentGroupsRequestChange, [userTreatmentGroupsRequest.isAwaiting])

    const addUserTreatmentGroupsRequest = useRequest('none')
    useEffect(onAddUserTreatmentGroupsRequestChange, [addUserTreatmentGroupsRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.visible || !props.userCode)
            return

        userTreatmentGroupsRequest.runRequest(UserRequests.getTreatmentGroupsConfig(props.userCode))
    }

    /**
     * Retorno da API que busca quais atendimentos usuario faz.
     */
    function onUserTreatmentGroupsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(userTreatmentGroupsRequest, 'Ops! Erro ao buscar atendimentos'))
            return

        setSelectedTreatmentGroups(userTreatmentGroupsRequest.responseData!.list.map(tgg => tgg.code))
    }

    /**
     * Ao salvar.
     */
    function onFormSubmit(): void {

        if (!userTreatmentGroupsRequest.responseData || !props.userCode)
            return

        const dto: IUserTreatmentGroupsSaveRequestDTO = {
            treamentGroupCodes: selectedTreatmentGroups
        }

        addUserTreatmentGroupsRequest.runRequest(UserRequests.updateTreatmentGroupsConfig(props.userCode, dto))
    }

    /**
     * Retorno da API ao salvar.
     */
    function onAddUserTreatmentGroupsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(addUserTreatmentGroupsRequest, 'Ops! Erro ao adicionar atendimentos', true))
            return

        NotificationHelper.success('Atendimentos adicionadas com sucesso!')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Atendimentos Permitidos'}
            visible={props.visible}
            onCancel={props.onCancelModal}
            onOk={onFormSubmit}
            loading={userTreatmentGroupsRequest.isAwaiting}
            actionLoading={addUserTreatmentGroupsRequest.isAwaiting}
        >
            <AutocompleteTreatmentGroupCP
                label={'Grupos de atendimento permitidos'}
                isMultiple={true}
                onChange={setSelectedTreatmentGroups}
                value={selectedTreatmentGroups}
            />
        </ModalCP>
    )
}