import React, { useEffect, useState } from 'react'
import { CardCP } from 'main/common/components/card/CardCP'
import { ChartPieCP, PieChartSeriesTP } from 'main/common/components/chart/ChartPieCP'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { ISaleSourceReportListItemResponseDTO } from 'main/modules/reports/services/sale-reports/dtos/responses/ISaleSourceReportListItemResponseDTO'
import { SaleSourcesSyntheticReportUtils } from 'main/modules/reports/components/sale-sources-synthetic-report/inner/SaleSourcesSyntheticReportUtils'
import * as _ from 'lodash'
import { ISaleSourcesReportFilterData } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/inner/ISaleSourcesReportFilterData'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { SaleReportsRequests } from 'main/modules/reports/services/sale-reports/SaleReportsRequests'
import { ISaleSourcesSyntheticReportFilterRequestDTO } from 'main/modules/reports/services/sale-reports/dtos/requests/ISaleSourcesSyntheticReportFilterRequestDTO'
import { StringUtils } from 'main/common/utils/StringUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { SaleSourcesSyntheticReportWrapperICP } from 'main/modules/reports/components/sale-sources-synthetic-report/inner/SaleSourcesSyntheticReportWrapperICP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ISaleSourcesSyntheticReportCPProps {
    filterData: ISaleSourcesReportFilterData
}

/**
 * Relatorio de Origem das Vendas
 */
export function SaleSourcesSyntheticReportCP(props: ISaleSourcesSyntheticReportCPProps): JSX.Element {

    const [chartData, setChartData] = useState<PieChartSeriesTP[]>([])
    const [franchiseNames, setFranchiseNames] = useState<string[]>([])
    const [reportData, setReportData] = useState<ISaleSourceReportListItemResponseDTO[]>()

    const getReportRequest = useRequest<IGenericListResponseDTO<ISaleSourceReportListItemResponseDTO>>()
    useEffect(onGetReportRequestChange, [getReportRequest.isAwaiting])

    useEffect(init, [props.filterData?.dateRange, props.filterData?.userSellerCode, props.filterData?.franchiseCodes])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        const filters: ISaleSourcesSyntheticReportFilterRequestDTO = {
            beginDate: props.filterData?.dateRange?.beginDate,
            endDate: props.filterData?.dateRange?.endDate,
            franchiseCodes: props.filterData?.franchiseCodes,
            userSellerCode: props.filterData?.userSellerCode
        }
        getReportRequest.runRequest(SaleReportsRequests.saleSourcesSyntheticReport(filters))
    }

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReportRequest, 'Erro obter dados do relatório'))
            return

        const reportResult = getReportRequest.responseData!.list
        setReportData(reportResult)

        // Transforma os dados retornados para montar o grafico
        const _chartData: PieChartSeriesTP[] = []
        reportResult.forEach((currentSource) => {

            // Monta dado para cada fonte do lead
            _chartData.push({
                id: StringUtils.getSlugStyleString(currentSource.sourceName),
                label: currentSource.sourceName,
                value: _.sumBy(currentSource.reportData, data => data.sales), // Para cada fonte soma os valores que estao agrupados por franquia
            })
        })
        setChartData(_chartData)

        // Obtem os valores unicos das franquias
        const groupedTitles = new Set(_.flatMapDeep(reportResult, data => data.reportData).map((dataByFranchise) => dataByFranchise.franchiseName))
        setFranchiseNames(Array.from(groupedTitles.values()))
    }

    return (
        <SaleSourcesSyntheticReportWrapperICP
            totalFranchises={franchiseNames.length}
            chartCP={
                <FlexCP justifyContent={'center'} marginBottom={20}>
                    <ChartPieCP
                        height={300}
                        width={300}
                        series={chartData}
                        loading={getReportRequest.isAwaiting}
                    />
                </FlexCP>
            }
            tableCP={
                <CardCP innerSpacing={'none'} width={'90vw'} overflow={'scroll'}>
                    <TableCPOLD2
                        columns={SaleSourcesSyntheticReportUtils.getTableColumns(franchiseNames)}
                        data={reportData}
                        loading={getReportRequest.isAwaiting}
                    />
                </CardCP>
            }
        />
    )
}
