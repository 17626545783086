import React, { useState } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { SaleInstallmentsTableUtils } from 'main/modules/sale/components/table-sale-installments/inner/SaleInstallmentsTableUtils'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ModalPayMultipleFinancialTransactionCP } from 'main/modules/financial/components/modal-pay-multiple-financial-transaction/ModalPayMultipleFinancialTransactionCP'
import * as _ from 'lodash'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'

interface ITableSaleInstallmentsCPProps {
    charge: IChargeListItemResponseDTO
    nfeData?: {
        hasSaleNfe: boolean
        sale?: ISaleResponseDTO,
        nfes?: NFeResponseDTO<NFeCustomArgsTP>[],
    }
    onUpdateAnyData: () => void
    onReloadNFe?: () => void
}

/**
 * Exibe uma listagem com todas as parcelas de uma compra.
 */
export function TableSaleInstallmentsCP(props: ITableSaleInstallmentsCPProps): JSX.Element {

    const [isMultiplePayModalVisible, setIsMultiplePayModalVisible] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    return (
        <>
            <ConditionalRenderCP shouldRender={!SystemUtils.isEmpty(selectedRowKeys)}>
                <FlexCP marginLeft={12}>
                    <ButtonCP
                        size={'small'}
                        icon={'audit'}
                        onClick={() => setIsMultiplePayModalVisible(true)}
                    >
                        Marcar como pago
                    </ButtonCP>
                </FlexCP>
            </ConditionalRenderCP>

            <TableCPOLD2
                data={props.charge.financialTransactions}
                columns={SaleInstallmentsTableUtils.getInstallmentsTableColumns(
                    props.onUpdateAnyData,
                    props.charge,
                    props.onReloadNFe,
                    props.nfeData,
                )}
                rowKey={(record) => record.code.toString()}
                rowSelection={{
                    onChange: (selectedKeys) => setSelectedRowKeys(_.map(selectedKeys, eachKey => eachKey.toString())),
                    columnWidth: 40,
                }}
            />

            <ModalPayMultipleFinancialTransactionCP
                visible={isMultiplePayModalVisible}
                financialTransactionCodes={_.map(selectedRowKeys, eachKey => +eachKey)}
                onClose={() => setIsMultiplePayModalVisible(false)}
                onSave={() => {
                    setIsMultiplePayModalVisible(false)
                    props.onUpdateAnyData()
                }}
            />
        </>
    )
}
