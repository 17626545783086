import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import { OpportunitiesTableUtils } from 'main/modules/sales-funnel/components/table-opportunity/inner/OpportunitiesTableUtils'
import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import React, { useEffect, useState } from 'react'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import * as _ from 'lodash'
import { OpportunityTableActionButtonsICP } from 'main/modules/sales-funnel/components/table-opportunity/inner/OpportunityTableActionButtonsICP'
import { FunnelRequests } from 'main/modules/sales-funnel/services/funnel/FunnelRequests'
import { OrderingEnum } from 'main/common/enums/OrderingEnum'
import { OpportunitySearchOrderingEnum } from 'main/modules/sales-funnel/services/opportunity/enums/OpportunitySearchOrderingEnum'
import { TableCP } from 'main/common/components/table/TableCP'
import { TableSortAndPageTP } from 'main/common/components/table/inner/TableSortAndPageTP'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { OrUndefTP } from 'main/common/types/OrUndefTP'

interface ICPProps {
    filtersFormStateManager: IFormStateManager<OpportunityFiltersFormModel>
    onSelectOpportunity: (code: number, conversionType?: OpportunityDrawerActionTP) => void
    shouldLoadData: boolean
    onLoadDataChanged: (type: 'dataLoaded' | 'reloadData') => void
}

/**
 * COMPONENTE
 * Tabela de listagem de oportunidades. Deve funcionar para todas as etapas
 */
export function TableOpportunityCP(props: ICPProps): JSX.Element {

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [sortAndPage, setSortAndPage] = useState<OrUndefTP<TableSortAndPageTP>>()

    const searchOpportunityRequest = useRequest<IGenericListResponseDTO<IOpportunityListItemResponseDTO>>()
    useEffect(onSearchOpportunityRequestChange, [searchOpportunityRequest.isAwaiting])

    const getFollowupStepsRequest = useRequest<IGenericListResponseDTO<IFunnelStepResponseDTO>>()
    useEffect(onGetFollowupRequestChange, [getFollowupStepsRequest.isAwaiting])

    useEffect(init, [props.shouldLoadData])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.shouldLoadData)
            return

        const defaultSortAndOrder: TableSortAndPageTP<OpportunitySearchOrderingEnum> = {
            sort: { column: OpportunitySearchOrderingEnum.NEXT_ACTIVITY, order: OrderingEnum.DESC },
            pagination: sortAndPage?.pagination ?? TableUtils.getDefaultPagination()
        }
        setSortAndPage(defaultSortAndOrder)

        // Se for tabela de followup busca as etapas
        if (props.filtersFormStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP)
            getFollowupStepsRequest.runRequest(FunnelRequests.searchStepsConfig())

        getOpportunitiesFromApi(defaultSortAndOrder)
    }

    /**
     * Obtem as oportunidades da API.
     */
    function getOpportunitiesFromApi(_sortAndPage?: TableSortAndPageTP): void {

        const filtersDto = OpportunitiesTableUtils.getFilterDtoFromModel(
            props.filtersFormStateManager.getFormValues()!,
            _sortAndPage
        )
        searchOpportunityRequest.runRequest(OpportunityRequests.searchConfig(filtersDto))
    }

    /**
     * Retorno da requisicao que busca contas.
     */
    function onSearchOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchOpportunityRequest, 'Não foi possível obter as contas bancarias'))
            return

        sortAndPage!.pagination!.total = searchOpportunityRequest.responseData?.total ?? 0
        props.onLoadDataChanged('dataLoaded')
    }

    /**
     * Retorno da api para buscar etapas do funil
     */
    function onGetFollowupRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getFollowupStepsRequest, 'Não foi possível obter lista com as etapas do funil de follow-up.'))
            return
    }

    return (
        <>
            <OpportunityTableActionButtonsICP
                rowSelection={selectedRowKeys}
                onDataChanged={() => props.onLoadDataChanged('reloadData')}
            />

            <TableCP
                wrappedOnCard={true}
                columns={
                    OpportunitiesTableUtils.getTableColumns(
                        props.onSelectOpportunity,
                        props.filtersFormStateManager,
                        () => props.onLoadDataChanged('reloadData'),
                        () => getOpportunitiesFromApi(sortAndPage),
                        getFollowupStepsRequest.responseData?.list,
                    )
                }
                data={searchOpportunityRequest.responseData?.list ?? []}
                loading={searchOpportunityRequest.isAwaiting}
                sortAndPage={sortAndPage}
                onSortOrChangePagination={(_sortAndPage) => {
                    setSortAndPage(_sortAndPage)
                    getOpportunitiesFromApi(_sortAndPage)
                }}
                rowKey={(record) => record.code.toString()}
                rowSelection={{
                    onChange: (selectedKeys) => setSelectedRowKeys(_.map(selectedKeys, eachKey => eachKey.toString())),
                    columnWidth: 40,
                }}
            />
        </>
    )
}

