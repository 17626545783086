import React from 'react'
import styled from 'styled-components'

interface IAppointmentDetailsCPProps {
    notes?: string
    title?: JSX.Element
}

/**
 * Componente para exibição de Notas.
 */
export function NotesCP(props: IAppointmentDetailsCPProps): JSX.Element {

    if (!props.notes)
        return <></>

    return (
        <>
            { props.title }
            <NotesContentWrapperSCP>{props.notes}</NotesContentWrapperSCP>
        </>
    )
}

const NotesContentWrapperSCP = styled.div`
    background-color: #fff7b4;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    max-width:240px;
`
