export enum SaleStatusEnum {

    /**
     * é ok quando não é nenhum dos casos acima, ou seja, têm parcelas pagas(PAID)
     * e parcelas em aberto(UNPAID) não vencidas
     */
    OK = 'OK',

    /**
     *  é pendente quando todas parcelas da venda não estão pagas(UNPAID)
     *  e essas parcelas tem a data de vencimento maior que hoje.
     */
    PENDING = 'PENDING',

    /**
     *é inadiplente caso tenha parcelas não pagas(UNPAID)
     * e a data de vencimento é menor que hoje, ou seja, parcelas atrasadas
     */
    DEFAULTING = 'DEFAULTING',

    /** é concluída quando todas as parcelas estão pagas(PAID) */
    CONCLUDED = 'CONCLUDED',

    /** é uma venda cancelada, não precisa mais ser faturada. */
    CANCELLED = 'CANCELLED'
}

export enum SaleStatusLabelEnum {
    OK = 'Em dia',
    PENDING = '1º Pagamento Pendente',
    DEFAULTING = 'Inadimplente',
    CONCLUDED = 'Pago',
    CANCELLED = 'Cancelado'
}
