import { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'
import { ISaleProductItem } from 'main/modules/sale/components/sale-item-table/inner/interfaces/ISaleProductItem'
import { ISaleItemsResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleItemsResponseDTO'

export const SaleItemTableUtils = {

    getSalesDataColumns(
        onQuantityChange?: (newQuantity: number, productCode?: number) => void
    ): Array<ColumnProps<ISaleProductItem>> {
        return [
            {
                title: 'Nome',
                key: 'productName',
                render: (product: ISaleProductItem): string => product.releaseName
            },
            {
                title: 'Qtde.',
                key: 'quantity',
                align: 'center',
                render: (product: ISaleProductItem): JSX.Element => (
                    <InputWrapperSCP>
                        <InputCP
                            value={product.quantity}
                            type={'number'}
                            required={true}
                            min={1}
                            disabled={!onQuantityChange}
                            onChange={(newQuantity): void => {
                                if (onQuantityChange)
                                    onQuantityChange(+newQuantity, product.code)
                                if(newQuantity==0){
                                    newQuantity = 1;
                                    // @ts-ignore
                                    onQuantityChange(newQuantity, product.code)
                                }
                            }}
                        />
                    </InputWrapperSCP>
                )
            },
            {
                title: 'Valor',
                key: 'value',
                align: 'right',
                width: 120,
                render: (product: ISaleProductItem): string => MaskUtils.applyMoneyMask(_.toString(product.price))
            },
            {
                title: 'Total',
                key: 'total',
                align: 'right',
                width: 120,
                render: (product: ISaleProductItem): string => MaskUtils.applyMoneyMask(_.toString((product.total)))
            }
        ]
    },

    getSalesItemsColumns(): Array<ColumnProps<ISaleItemsResponseDTO>> {
        return [
            {
                title: 'Nome',
                key: 'productName',
                render: (text, item) => item.productRelease.name
            },
            {
                title: 'Qtde.',
                key: 'quantity',
                align: 'center',
                render: (text, item) => item.quantity
            },
            {
                title: 'Valor',
                key: 'value',
                align: 'right',
                render: (text, item) => MaskUtils.applyMoneyMask(item.productReleaseSoldValue)
            },
            {
                title: 'Total',
                key: 'total',
                align: 'right',
                render: (text, item) => MaskUtils.applyMoneyMask(item.totalValue)
            }
        ]
    },

}

const InputWrapperSCP = styled.div`
    display: flex;

    .ant-input, .ant-input:focus {
        border: 0px;
        padding: 0px;
        display: block;
        text-align: center;
    }
    
    .ant-table-row-cell-break-word {
        padding-top: 18px;
    }
    
    .ant-calendar-picker {
        padding-right: 5px;
    }

    .ant-select, .ant-select-selection {
        border-bottom: 0px !important;
    }
    
    .ant-row, .ant-select {
        margin: 0px;
    }
    
    .ant-form-explain {
        display: contents;
    }
`
