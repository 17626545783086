import { ColumnProps } from 'antd/lib/table'
import { TagCP } from 'main/common/components/tag/TagCP'
import { ActionsColumnICP } from 'main/modules/marketing/components/sender-table/inner/ActionsColumnICP'
import { StatusColumnICP } from 'main/modules/marketing/components/sender-table/inner/StatusColumnICP'
import { IMarketingSenderResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingSenderResponseDTO'
import { MarketingSenderStatusEnum } from 'main/modules/marketing/enums/MarketingSenderStatusEnum'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React from 'react'

/**
 * UTILITARIOS
 * Encapsula funcoes uteis para componente de tabela de listagem de remetentes de e-mail.
 * @see SenderTableCP
 */
export const SenderTableUtils = {

    /** Gera & retorna configuracao para colunas da tabela de listagem de remetentes de e-mail. */
    getColumnsConfig(
        onEdit: (sender: IMarketingSenderResponseDTO) => void,
        onRemove: (senderCode: number) => void,
        onStatusChange: (sender: IMarketingSenderResponseDTO, newStatus: MarketingSenderStatusEnum) => void

    ): Array<ColumnProps<IMarketingSenderResponseDTO>> {
        return [
            {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
                render: (text, sender: IMarketingSenderResponseDTO) => sender.name
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                render: (text, sender: IMarketingSenderResponseDTO) => sender.email
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, sender: IMarketingSenderResponseDTO) => (
                    <TagCP
                        key={'status'}
                        color={sender.status === MarketingSenderStatusEnum.ACTIVE ? 'green' : undefined}
                        content={MarketingUtils.getSenderStatusEnumLabel(sender.status)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actStatus',
                key: 'actStatus',
                render: (text, sender: IMarketingSenderResponseDTO) => <StatusColumnICP status={sender.status} onChangeStatus={(newStatus) => onStatusChange(sender, newStatus)}/>
            },
            {
                title: '',
                dataIndex: 'act',
                key: 'act',
                align: 'right',
                render: (text, sender: IMarketingSenderResponseDTO) => <ActionsColumnICP onEdit={() => onEdit(sender)} onRemove={() => onRemove(sender.code)}/>
            }
        ]
    },
}
