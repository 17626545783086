import React from 'react'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { IconICP } from 'main/common/components/icons/inner/IconICP'
import { PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

interface IIconPaymentMethodCPProps {
    paymentMethod: PaymentMethodEnum
    size?: number
}

/**
 * Icone de acordo com o meio de pagamento
 */
export function IconPaymentMethodCP(props: IIconPaymentMethodCPProps): JSX.Element {

    let icon: JSX.Element | undefined

    switch (props.paymentMethod) {

        case PaymentMethodEnum.BANK_BILLET:
            icon = <IconICP size={props.size} iconName={'barcode'}/>
            break

        case PaymentMethodEnum.CREDIT_CARD:
        case PaymentMethodEnum.CREDIT_CARD_RECURRENT:
        case PaymentMethodEnum.DEBIT:
            icon = <IconICP size={props.size} iconName={'credit-card'}/>
            break

        case PaymentMethodEnum.CHECK:
            icon = <IconICP size={props.size} iconName={'wallet'}/>
            break

        case PaymentMethodEnum.DEPOSIT:
            icon = <IconICP size={props.size} iconName={'bank'}/>
            break

        case PaymentMethodEnum.MONEY:
            icon = <IconICP size={props.size} iconName={'dollar'}/>
            break

        case PaymentMethodEnum.PIX:
            icon = <IconICP size={props.size} iconName={'qrcode'}/>
            break

        default:
            icon = undefined
            break
    }

    if (!icon)
        return <></>

    return (
        <TooltipCP
            text={PaymentMethodLabelEnum[props.paymentMethod]}
            showSpan={true}
        >
            { icon }
        </TooltipCP>
    )
}
