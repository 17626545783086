import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { WidgetsNpsScore } from 'submodules/space4leads-components-ui/report/components/widgets-nps-score/WidgetsNpsScore'
import styled from 'styled-components'
import { WidgetNpsPerPeriod } from 'submodules/space4leads-components-ui/report/components/widget-nps-per-period/WidgetNpsPerPeriod'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { TimelineSurveyAnswersCP } from 'submodules/space4leads-components-ui/report/components/timeline-survey-answers/TimelineSurveyAnswersCP'
import { WrapperNpsCP } from 'submodules/space4leads-components-ui/survey/components/wrapper-nps/WrapperNpsCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 */
export function ScreenContentFranchiseNpsReportCP(): JSX.Element {

    const [nps, setNps] = useState<SurveyResponseDTO>()
    const [reload, setReload] = useState<number>(1)

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    useEffect(() => setReload(TableUtils.getReloadNumber()), [dateFilters])

    return (
        <WrapperNpsCP onGetNps={setNps}>
            <HeaderCP title={'Pesquisa de Satisfação (NPS) da Unidade'}/>

            <FlexCP justifyContent={'center'}>
                <CardCP innerSpacing={'small'}>
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={setDateFilters}
                        fastFilter={'all'}
                        allowClear={false}
                    />
                </CardCP>
            </FlexCP>

            {
                !!nps &&
                <ContentWrapperSCP>
                    <ContentSCP>
                        <WidgetsNpsScore
                            type={'project'}
                            reload={reload}
                            filters={{
                                dateRange: dateFilters
                            }}
                        />
                        <WidgetNpsPerPeriod
                            reload={reload}
                            dateRange={dateFilters}
                        />

                        <CardCP title={'Últimas respostas'}>
                            <TimelineSurveyAnswersCP
                                surveyCode={nps!.code}
                                reload={reload}
                                dateRange={dateFilters}
                            />
                        </CardCP>
                    </ContentSCP>
                </ContentWrapperSCP>
            }

            {
                !nps &&
                <WelcomeContentCP
                    message={'Configurações de NPS incorretas'}
                    icon={<IconICP iconName={'experiment'}/>}
                />
            }
        </WrapperNpsCP>
    )
}

const ContentSCP = styled.div`
  width: 100%;
`

const ContentWrapperSCP = styled.div`
  margin-top: 20px;
  display: flex;
  overflow-x: auto;
  overflow-y: scroll;
  justify-content: center;
`
