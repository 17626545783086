import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { TextCP } from 'main/common/components/text/TextCP'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import { TableColumnValidationErrorDescICP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/TableColumnValidationErrorDescICP'

/**
 * COLUNAS
 * Configuracao para exibir tabela de descricao de falhas de validacao em arquivo .csv para importacao.
 */
export const TABLE_COLUMNS_VALIDATION_ERRORS: Array<ColumnProps<ImportValidationErrorsLineTP>> = [
    {
        title: 'Linha',
        dataIndex: 'line',
        key: 'line',
        align: 'center',
        render: (text, lineErrors: ImportValidationErrorsLineTP) => (
            <TextCP size={'small'} strong={true} text={`nº ${lineErrors.line}`}/>
        ),
    },
    {
        title: 'Falhas',
        dataIndex: 'line',
        align: 'center',
        key: 'line',
        render: (text, lineErrors: ImportValidationErrorsLineTP) => (
            <TableColumnValidationErrorDescICP lineErrors={lineErrors}/>
        ),
    },
]
