import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect } from 'react'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { StepsDropdownButtonCP } from 'main/modules/sales-funnel/components/steps-dropdown-button/StepsDropdownButtonCP'
import { TagOpportunityStatusCP } from 'main/modules/sales-funnel/components/tag-opportunity-status/TagOpportunityStatusCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ButtonArchiveOpportunityCP } from 'main/modules/sales-funnel/components/button-archive-opportunity/ButtonArchiveOpportunityCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import { IFunnelStepResponseDTO } from 'main/modules/sales-funnel/services/funnel/dtos/response/IFunnelStepResponseDTO'
import { SystemUtils } from 'main/common/utils/SystemUtils'

interface ICPProps {
    opportunity: IOpportunityListItemResponseDTO
    onSelectOpportunity: (code: number) => void
    onOpportunityDataChanged: () => void
    funnelStepsData?: IFunnelStepResponseDTO[]
}

/**
 * Coluna de acoes da tabela
 */
export function OpportunityTableActionColumnICP(props: ICPProps): JSX.Element {

    const deleteOpportunityReq = useRequest('none')
    useEffect(onDeleteOpportunityRequestChange, [deleteOpportunityReq.isAwaiting])

    const moveStepOpportunityRequest = useRequest<IFunnelStepResponseDTO>()
    useEffect(onMoveStepOpportunityRequestChange, [moveStepOpportunityRequest.isAwaiting])

    /**
     * Move oportunidade de etapa do followup.
     */
    function moveStepOpportunity(followUpCode: number, newFunnelStepCode: number): void {
        moveStepOpportunityRequest.runRequest(OpportunityRequests.moveStepOpportunity(followUpCode, { newFunnelStepCode }))
    }

    /**
     * Retorno da requisicao.
     */
    function onMoveStepOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(moveStepOpportunityRequest, 'Erro ao mover oportunidade no funil de follow-up.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade movida com sucesso!')
        props.onOpportunityDataChanged()
    }

    /**
     * Deleta uma oportunidade.
     */
    function deleteOpportunity(): void {
        deleteOpportunityReq.runRequest(OpportunityRequests.deleteOpportunity(props.opportunity.code))
    }

    /**
     * Retorno da remocao de oportunidade.
     */
    function onDeleteOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteOpportunityReq, 'Erro ao tentar remover oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade removida com sucesso')
        props.onOpportunityDataChanged()
    }

    return (
        <FlexCP justifyContent={'flex-end'} alignItems={'center'}>
            <>
                {
                    (!SystemUtils.isEmpty(props.funnelStepsData) && props.opportunity.type === OpportunityTypeEnum.FOLLOWUP && props.opportunity.status === OpportunityStatusEnum.OPEN)
                        ?
                        <StepsDropdownButtonCP
                            opportunityCode={props.opportunity.code}
                            funnelSteps={props.funnelStepsData ?? []}
                            moveStepOpportunity={moveStepOpportunity}
                            currentStepName={props.opportunity.folowUpData?.funnelStep.name}
                            loading={moveStepOpportunityRequest.isAwaiting}
                        />
                        :
                        <TagOpportunityStatusCP status={props.opportunity.status}/>
                }
            </>
            <ButtonCP
                type={'primary'}
                icon={'eye'}
                onClick={() => props.onSelectOpportunity(props.opportunity.code)}
                marginLeft={5}
                size={'small'}
            />
            <>
                {
                    props.opportunity.status === OpportunityStatusEnum.OPEN &&
                    <ButtonArchiveOpportunityCP
                        opportunityCode={props.opportunity.code}
                        onArchive={props.onOpportunityDataChanged}
                        buttonType={'ghost'}
                        buttonSize={'small'}
                        showIcon={true}
                        marginLeft={5}
                    />
                }
            </>
            <>
                {
                    props.opportunity.status !== OpportunityStatusEnum.CONCLUDED &&
                    <ButtonCP
                        type={'danger'}
                        confirmMsg={'Deseja mesmo excluir essa oportunidade? O comum é arquivar um lead para não perder os dados, pense bem!'}
                        onClick={deleteOpportunity}
                        icon={'delete'}
                        marginLeft={5}
                        size={'small'}
                        loading={deleteOpportunityReq.isAwaiting}
                    />

                }
            </>
        </FlexCP>
    )
}

