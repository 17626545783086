import * as styledComponents from 'styled-components'
// eslint-disable-next-line no-duplicate-imports
import { ThemedStyledComponentsModule } from 'styled-components'
import { ITheme } from 'main/config/theme/ITheme'

/**
 * Wrapper do styled components para que o theme esteja tipado
 * @author Lucas Rosa
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const { default: styled, css, keyframes, ThemeProvider, withTheme, ThemeContext } = (styledComponents as any as ThemedStyledComponentsModule<ITheme>)

export { css, keyframes, ThemeProvider, styled, withTheme, ThemeContext }
export default styled
