/**
 * Determina operadores lógicos para uso em filtros de segmento:
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export enum SegmentLogicOperatorsEnum {
    AND = 'AND',
    OR = 'OR'
}
