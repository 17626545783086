/**
 * ENUM
 * Status de verificação de informações usadas no contexto do marketing:
 * e.g: email, sms, etc.
 *
 * @author hjcostabr
 */
export enum MktStatusEnum {
    VALID = 'VALID',
    INVALID = 'INVALID',
    UNCHECKED = 'UNCHECKED',
}