import React, { useState } from 'react'
import { OpportunityFiltersFormModel } from 'main/modules/sales-funnel/components/opportunity-filters/inner/OpportunityFiltersFormModel'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { OpportunityTypeEnum, OpportunityTypeLabelEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { SalesFunnelOpportunityViewEnum, SalesFunnelOpportunityViewLabelEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { HeaderButtonICP } from 'main/common/components/screen-layout/header/inner/HeaderButtonICP'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { StepsSettingModalCP } from 'main/modules/sales-funnel/components/steps-setting-modal/StepsSettingModalCP'
import { ModalLeadImportCP } from 'main/modules/sales-funnel/components/modal-lead-import/ModalLeadImportCP'
import { ButtonSearchOpportunityCP } from 'main/modules/sales-funnel/components/button-search-opportunity/ButtonSearchOpportunityCP'

// Escondendo o botao de importar leads temporariamente
const SHOULD_HIDE_TEMP_IMPORT = true

interface ICPProps {
    formStateManager: IFormStateManager<OpportunityFiltersFormModel>
    view: SalesFunnelOpportunityViewEnum
    onShouldReloadData: () => void
    onAddOpportunity: () => void
    onSelectOportunity: (code: number) => void
}

/**
 * Conteudo de oportunidade.
 */
export function ScreenContentSalesFunnelOpportunityHeaderICP(props: ICPProps): JSX.Element {

    const [showConfigFunnelModal, setShowConfigFunnelModal] = useState<boolean>(false)
    const [showModalLeadImport, setShowModalLeadImport] = useState<boolean>(false)

    return (
        <>
            <HeaderCP title={`${OpportunityTypeLabelEnum[props.formStateManager.getFieldValue('type')]}: ${SalesFunnelOpportunityViewLabelEnum[props.view]}`}>

                <ButtonSearchOpportunityCP
                    onSelectOportunity={props.onSelectOportunity}
                />

                {
                    !SHOULD_HIDE_TEMP_IMPORT &&
                    <HeaderButtonICP
                        icon={'usergroup-add'}
                        label={'Importar'}
                        tooltip={'Importar lista de Leads'}
                        onClick={() => setShowModalLeadImport(true)}
                    />
                }

                <HeaderButtonICP
                    icon={'plus'}
                    label={'Novo Lead'}
                    onClick={() => props.onAddOpportunity()}
                />

                <AccessControlCP permissions={[PermissionEnum.ROLE_ADMIN]} redirect={false}>
                    <ConditionalRenderCP shouldRender={props.formStateManager.getFieldValue('type') === OpportunityTypeEnum.FOLLOWUP}>
                        <HeaderButtonICP icon={'setting'} onClick={() => setShowConfigFunnelModal(true)}/>
                    </ConditionalRenderCP>
                </AccessControlCP>
            </HeaderCP>

            <StepsSettingModalCP
                visible={showConfigFunnelModal}
                onCancel={() => setShowConfigFunnelModal(false)}
                onSave={(closeModal: boolean) => {
                    setShowConfigFunnelModal(!closeModal)
                    props.onShouldReloadData()
                }}
            />

            <ModalLeadImportCP
                isVisible={showModalLeadImport}
                onCancel={() => setShowModalLeadImport(false)}
                onUploadSuccess={() => setShowModalLeadImport(false)}
            />
        </>

    )
}
