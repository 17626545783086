import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TagCPColorTP } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCPColorTP'
import styled from 'styled-components'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ITagCPProps {
    texts?: string[]
    color?: TagCPColorTP
}

/**
 * Passando mais de uma tag.
 */
export function TagsCP(props: ITagCPProps): JSX.Element {

    if (ArrayUtils.isEmpty(props.texts))
        return <></>

    return (
        <WrapperSCP>
            <FlexCP flexWrap={'wrap'}>
                {
                    props.texts?.map((text, index) => (
                        <TagCP
                            color={props.color}
                            key={`key-tag-${index}`}
                            content={text}
                        />
                    ))
                }
            </FlexCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  .ant-tag {
    margin: 5px 5px 5px 0;
  }
`
