import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetLeadEnum } from 'main/modules/dashboard/services/enums/WidgetLeadEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de total de leads abertos.
 */
export function WidgetTotalLeadsOpenCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Leads'}
            help={'Total de LEADs que estão em aberto'}
            requestConfigGetter={
                DashboardRequests.getLeadWidgets(
                    WidgetLeadEnum.OPENS,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
        />
    )
}
