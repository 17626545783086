import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { ListCP } from 'main/common/components/list/ListCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { DrawerOpportunityDetailsCP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/DrawerOpportunityDetailsCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { PersonReferralItemICP } from 'main/modules/people/components/list-person-referrals/inner/PersonReferralItemICP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { IPersonReferralsResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonReferralsResponseDTO'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'
import { PersonReferralsTP } from 'main/modules/people/services/person/types/PersonReferralsTP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { HelpCP } from 'main/common/components/help/HelpCP'
import { PersonReferralPhaseLabelEnum } from 'main/modules/people/services/person/enums/PersonReferralPhaseEnum'

interface IListPersonOpportunitiesCPProps {
    person: INameAndCodeResponseDTO
}

/**
 * Componente Lista de indicacoes de uma pessoa.
 */
export function ListPersonReferralsCP(props: IListPersonOpportunitiesCPProps): JSX.Element {

    const [isOpportunityDrawerVisible, setIsOpportunityDrawerVisible] = useState<boolean>(false)

    const [referralsData, setReferralsData] = useState<IPersonReferralsResponseDTO>()

    const getReferralsRequest = useRequest<IPersonReferralsResponseDTO>()
    useEffect(onGetReferralsRequestChange, [getReferralsRequest.isAwaiting])

    useEffect(init, [props.person])

    /**
     * Inicializa a tela.
     */
    function init(): void {
        getReferralsRequest.runRequest(PersonRequests.getReferrals(props.person.code))
    }

    /**
     * Retorno ao obter lista.
     */
    function onGetReferralsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReferralsRequest, 'Erro ao buscar indicações do cliente'))
            return

        setReferralsData(getReferralsRequest.responseData)
    }

    return (
        <>
            {
                referralsData?.personReferredBy &&
                <TitleCP textSize={'normal'} marginBottom={30}>
                    <TitleAndTextCP title={'Indicado por:'} text={referralsData.personReferredBy.name}/>
                </TitleCP>
            }

            <FlexCP justifyContent={'space-between'} marginTop={20}>
                <TitleCP textSize={'normal'} underLine={true}>Indicações</TitleCP>
                <ButtonCP
                    icon={'plus'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => setIsOpportunityDrawerVisible(true)}
                >
                    Nova Indicação
                </ButtonCP>
            </FlexCP>

            <ListContainerSCP>
                <ListCP<PersonReferralsTP>
                    itemLayout={'vertical'}
                    bordered={false}
                    dataSource={referralsData?.referrals ?? []}
                    pagination={false}
                    loading={getReferralsRequest.isAwaiting}
                    locale={{ emptyText: 'Nenhuma indicação encontrada' }}
                    renderItem={(item) => <PersonReferralItemICP referral={item}/>}
                />
                <HelpCP
                    text={`Fluxo: ${PersonReferralPhaseLabelEnum.LEAD} ➡ ${PersonReferralPhaseLabelEnum.EVALUATION} ➡ 🚩${PersonReferralPhaseLabelEnum.EVALUATION_DONE} ➡ ✩${PersonReferralPhaseLabelEnum.SALE}`}
                    type={'text'}
                />
            </ListContainerSCP>

            <DrawerOpportunityDetailsCP
                visible={isOpportunityDrawerVisible}
                initialData={{
                    personResponsibleCode: AppStateUtils.getLoggedUserData()!.user.code,
                    sourcePersonLink: props.person,
                    sourcePersonLinkCode: props.person.code
                }}
                onClose={() => {
                    setIsOpportunityDrawerVisible(false)
                    init()
                }}
            />
        </>
    )
}

const ListContainerSCP = styled.div`
    width: 100%;
    margin-top: 10px
`