import React, { useEffect, useState } from 'react'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import styled from 'styled-components'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { CardWidgetWrapperCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-wrapper/CardWidgetWrapperCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { WidgetOnlyValuesResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/dashboard/dtos/response/WidgetOnlyValuesResponseDTO'
import { CardWidgetFooterOnlyValueICP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/inner/CardWidgetFooterOnlyValueICP'

interface ICPProps extends BasicStyleWrapperCPProps {
    initialValue?: WidgetOnlyValuesResponseDTO
    requestConfigGetter?: RequestConfigTP
    loading?: boolean
    onClick?: () => void
    main: {
        title: string
        valueMask?: InputMaskTypeEnum.MONEY | string
        secondaryValueMask?: InputMaskTypeEnum.MONEY | string
        icon?: JSX.Element
        help?: string
        isZeroTarget?: boolean
        blinkOnZeroTarget?: boolean
    }
    style?: {
        maxWidth?: string
    }
}

export function CardWidgetOnlyValuesCP(props: ICPProps): JSX.Element {

    const [widgetData, setWidgetData] = useState<WidgetOnlyValuesResponseDTO | undefined>(props.initialValue)
    useEffect(() => setWidgetData(props.initialValue), [props.initialValue])

    const widgetRequest = useRequest<WidgetOnlyValuesResponseDTO>()
    useEffect(onWidgetRequestChange, [widgetRequest.isAwaiting])

    useEffect(init, [props.requestConfigGetter])

    /**
     * Busca valor do widget na API.
     */
    function init(): void {

        if (!props.requestConfigGetter)
            return

        widgetRequest.runRequest(props.requestConfigGetter)
    }

    /**
     * Retorno da requisicao para pegar valores do widget.
     */
    function onWidgetRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(widgetRequest, 'Erro ao obter valores do widget'))
            return

        setWidgetData(widgetRequest.responseData)
    }

    /**
     * Formata um valor baseado no tipo da mascara.
     */
    function getFormattedValue(value: number): string {
        return props.main.valueMask === InputMaskTypeEnum.MONEY
            ? MaskUtils.applyMoneyMask(value)
            : `${MaskUtils.applyNumberMask(value, 2)}${props.main.valueMask ?? ''}`
    }

    return (
        <CardWidgetWrapperCP
            className={'card-dashboard-widget'}
            maxWidth={props.style?.maxWidth ?? '300px'}
            title={props.main.title}
            onClick={props.onClick}
            marginTop={props.margin?.top}
            marginBottom={props.margin?.bottom}
            marginRight={props.margin?.right}
            marginLeft={props.margin?.left}
            footer={
                <>
                    {
                        widgetData?.secondaryValues?.map((secondaryValue) => (
                            <CardWidgetFooterOnlyValueICP
                                title={secondaryValue.label}
                                value={secondaryValue.value}
                                valueMask={props.main.secondaryValueMask}
                            />
                        ))
                    }
                </>
            }
            help={props.main.help}
            loading={props.loading ?? widgetRequest.isAwaiting}
        >
            <>
                {
                    !!widgetData && widgetData.value !== undefined &&
                    <WrapperSCP className={props.main.isZeroTarget && widgetData.value > 0 && props.main.blinkOnZeroTarget ? 'blink' : undefined}>
                        <ValueSCP
                            className={'card-dashboard-widget-value'}
                            color={props.main.isZeroTarget && widgetData.value > 0 ? ThemeAnt.errorColor : undefined}
                        >
                            { getFormattedValue(widgetData.value) }
                            {
                                !props.main.isZeroTarget && !!props.main.icon &&
                                <>
                                    { props.main.icon }
                                </>
                            }
                            {
                                props.main.isZeroTarget && widgetData.value > 0 &&
                                <IconCP antIcon={'exclamation-circle'}/>
                            }
                        </ValueSCP>
                    </WrapperSCP>
                }
            </>
        </CardWidgetWrapperCP>
    )
}

const ValueSCP = styled.div<{ color?: string }>`
  font-size: 30px;
  color: ${props => props.color};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const WrapperSCP = styled.div`
  
  &.blink {
    animation: blink 1s linear infinite;

    @keyframes blink{
      0%{opacity: .2;}
      20%{opacity: .6;}
      40%{opacity: 1;}
      60%{opacity: 1;}
      80%{opacity: .6;}
      100%{opacity: .2;}
    }
  }
`
