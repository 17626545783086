import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'

/**
 */
export function WidgetFranchiseSalePerformanceCP(): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Meta Geral de Vendas'}
            help={`Meta geral de vendas da unidade: ${AppStateUtils.getCurrentFranchise()!.name}`}
            maxWidth={450}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.SALES_REVENUE,
                    {
                        franchiseCodes: [ AppStateUtils.getCurrentFranchise()!.code ],
                        dateRange: DtoUtils.convertToDateRangeDto(DateUtils.getFirstAndLastDayOfMonth())
                    }
                )
            }
        />
    )
}
