import { IRadioOptionProps } from 'common/old-refactory/radio-group/RadioGroupCPOLD'
import React from 'react'
import styled from 'styled-components'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import {
    DatePeriodGroupTypeEnum,
    DatePeriodGroupTypeLabelEnum
} from 'main/modules/reports/services/sale-reports/enums/DatePeriodGroupTypeEnum'

interface ISaleSourcesAnalyticViewTypeButtonICPProps {
    currentView: DatePeriodGroupTypeEnum
    onChangeView: (view: DatePeriodGroupTypeEnum) => void
}

/**
 * Botoes para mudar visão das informacoes do relatorio
 */
export function SaleSourcesAnalyticViewTypeButtonICP(props: ISaleSourcesAnalyticViewTypeButtonICPProps): JSX.Element {

    function getViewOptions(): IRadioOptionProps[] {
        return Object.keys(DatePeriodGroupTypeLabelEnum).map(item => ({
            value: item,
            content: DatePeriodGroupTypeLabelEnum[item]
        }))
    }

    return (
        <ContentSCP>
            <RadioGroupCP
                onChange={props.onChangeView}
                buttonHeight={25}
                buttonWidth={90}
                paddingTop={0}
                secondary={true}
                selected={props.currentView}
                fontSize={'small'}
                options={getViewOptions()}
                type={'button'}
            />
        </ContentSCP>
    )
}

const ContentSCP = styled.div`

`
