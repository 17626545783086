import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { IconCP } from 'main/common/components/icons/IconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { SaleStatusLabelEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleStatusEnum'

interface ICPProps {
    sale: {
        code: number,
        status: SaleStatusEnum
    }
    treatmentName: string
    onSaleClick?: () => void
}

/**
 */
export function ColumnTreatmentNameAppointmentDetailsTableICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TextCP text={props.treatmentName}/>
            <FlexCP alignItems={'center'}>
                <ConditionalRenderCP
                    shouldRender={[SaleStatusEnum.CANCELLED, SaleStatusEnum.DEFAULTING, SaleStatusEnum.PENDING].includes(props.sale.status)}>
                    <TooltipCP text={`Atenção: venda ${SaleStatusLabelEnum[props.sale.status]}`} showSpan={true}>
                        <IconCP antIcon={'exclamation-circle'} color={ThemeAnt.errorColor}/>
                    </TooltipCP>
                </ConditionalRenderCP>
                <TextCP
                    text={`Venda #${props.sale.code}`}
                    onClick={props.onSaleClick}
                    fontStyle={'italic'}
                    color={ThemeAnt.gray}
                    marginLeft={[SaleStatusEnum.CANCELLED, SaleStatusEnum.DEFAULTING, SaleStatusEnum.PENDING].includes(props.sale.status)  ? 5 : undefined}
                />
            </FlexCP>
        </>
    )

}
