import { OrNullTP } from 'main/common/types/OrNullTP'
import { ReduxHelper } from 'main/common/redux/helpers/ReduxHelper'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { DomainDataTP } from 'main/common/redux/types/DomainDataTP'
import { UserDataTP } from 'main/common/redux/types/UserDataTP'
import { IAuthUserFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IAuthUserFranchiseResponseDTO'

/**
 * UTILITARIOS
 * Para manipular estado global da aplicacao (redux).
 *
 * NOTE: Esses metodos nao podem pertencer a utilitaria do redux por problemas de depencia circular com a helper do redux.
 *
 * @see IReduxState
 * @see ReduxUtils
 * @see ReduxHelper
 */
export const AppStateUtils = {

    /** Retorna dados do usuario logado atual. */
    getLoggedUserData(): OrNullTP<UserDataTP> {
        return ReduxHelper.getInstance().store.getState().userData
    },

    getCurrentFranchise(): OrNullTP<IAuthUserFranchiseResponseDTO> {
        return ReduxHelper.getInstance().store.getState().currentFranchise
    },

    getUserFranchises(): OrUndefTP<IAuthUserFranchiseResponseDTO[]> {
        return AppStateUtils.getLoggedUserData()?.user?.franchises
    },

    getUserFranchisor(): OrUndefTP<IAuthUserFranchiseResponseDTO> {
        return AppStateUtils.getLoggedUserData()?.user?.franchisor
    },

    /** Retorna dados do cliente (schema) a que pertence o usuario logado atual. */
    getDomainData(): OrNullTP<DomainDataTP> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

    /** Retorna nome do schema/slug do dominio atual. */
    getDomainSlug(): OrUndefTP<string> {
        return AppStateUtils.getDomainData()?.schema?.slug
    },
}
