import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'
import { CheckboxCP } from 'main/common/components/checkbox/CheckboxCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import styled from 'styled-components'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { EditMultipleFinancialTransactionFormModel } from 'main/modules/financial/components/modal-edit-multiple-financial-transaction/inner/EditMultipleFinancialTransactionFormModel'
import { IFinancialTransactionMultipleEditRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionMultipleEditRequestDTO'

interface IModalEditMultipleFinancialTransactionCPProps {
    visible: boolean
    financialTransactionCodes: number[]
    onClose: () => void
    onSave: () => void
}

/**
 * Componente de modal com dados para pagamento de multiplas transacoes financeiras.
 */
export function ModalEditMultipleFinancialTransactionCP(props: IModalEditMultipleFinancialTransactionCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<EditMultipleFinancialTransactionFormModel>(new EditMultipleFinancialTransactionFormModel())
    const formStateManager = useFormStateManager<EditMultipleFinancialTransactionFormModel>(formValidator)

    const multipleUpdateRequest = useRequest<void>('none')
    useEffect(onMultipleEditRequestChange, [multipleUpdateRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        setFormValidator(new EditMultipleFinancialTransactionFormModel({
            changeExpirationDate: false,
            changeReferenceDate: false,
            changeBankAccount: false,
            changePaymentMethod: false,
            expirationDate: new Date(),
            referenceDate: new Date(),
        }))
    }

    /**
     * Edita a transacation Financeira.
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (formValues.changeReferenceDate && !formValues.referenceDate)
            return NotificationHelper.error('Ops!', 'Preencha a data de pagamento')

        if (formValues.changeExpirationDate && !formValues.expirationDate)
            return NotificationHelper.error('Ops!', 'Preencha a data de pagamento')

        if (formValues.changePaymentMethod && !formValues.paymentMethod)
            return NotificationHelper.error('Ops!', 'Preencha a forma de pagamento')

        if (formValues.changeBankAccount && !formValues.bankAccountCode)
            return NotificationHelper.error('Ops!', 'Preencha a data de pagamento')

        if (SystemUtils.isEmpty(props.financialTransactionCodes))
            return NotificationHelper.error('Ops!', 'Nenhuma fatura selecionada para pagamento')

        const dto: IFinancialTransactionMultipleEditRequestDTO = {
            financialTransactionCodes: props.financialTransactionCodes,
            expirationDate: !formValues.changeExpirationDate ? undefined : DateUtils.getFormatted(formValues.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
            referenceDate: !formValues.changeReferenceDate ? undefined : DateUtils.getFormatted(formValues.referenceDate, DateFormatEnum.US_WITHOUT_TIME),
            paymentMethod: !formValues.changePaymentMethod ? undefined : formValues.paymentMethod,
            bankAccountCode: !formValues.changeBankAccount ? undefined : formValues.bankAccountCode,
        }
        multipleUpdateRequest.runRequest(FinancialTransactionsRequests.updateMultiple(dto))
    }

    /**
     * Retorno da API ao editar.
     */
    function onMultipleEditRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(multipleUpdateRequest, 'Erro ao editar faturas', true))
            return

        NotificationHelper.success('Yes!', 'Faturas editadas com sucesso!')
        props.onSave()
        formStateManager.reset(new EditMultipleFinancialTransactionFormModel())
    }

    return (
        <ModalCP
            title={'Editar Despesas'}
            width={340}
            top={10}
            visible={props.visible}
            onCancel={props.onClose}
            onOk={onSave}
            actionLoading={multipleUpdateRequest.isAwaiting}
            destroyOnClose={true}
        >
            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('changeExpirationDate', checked)}
                    isChecked={formStateManager.getFieldValue('changeExpirationDate')}
                >
                    <>Alterar data de vencimento</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={formStateManager.getFieldValue('changeExpirationDate')}>
                    <FieldFormSCP>
                        <InputCP
                            label={'Dt. de Vencimento'}
                            required={true}
                            type={'date'}
                            formStateManager={formStateManager}
                            fieldName={'expirationDate'}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>

            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('changeReferenceDate', checked)}
                    isChecked={formStateManager.getFieldValue('changeReferenceDate')}
                >
                    <>Alterar data de referência</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={formStateManager.getFieldValue('changeReferenceDate')}>
                    <FieldFormSCP>
                        <InputCP
                            label={'Dt. de Referência'}
                            required={true}
                            type={'date'}
                            formStateManager={formStateManager}
                            fieldName={'referenceDate'}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>

            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('changePaymentMethod', checked)}
                    isChecked={formStateManager.getFieldValue('changePaymentMethod')}
                >
                    <>Alterar forma de pagamento</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={formStateManager.getFieldValue('changePaymentMethod')}>
                    <FieldFormSCP>
                        <SelectPaymentMethodCP
                            label={'Forma de pagamento'}
                            formStateManager={formStateManager}
                            fieldName={'paymentMethod'}
                            required={true}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>

            <FieldSCP>
                <CheckboxCP
                    onChange={(checked) => formStateManager.changeFieldValue('changeBankAccount', checked)}
                    isChecked={formStateManager.getFieldValue('changeBankAccount')}
                >
                    <>Alterar conta bancária</>
                </CheckboxCP>

                <ConditionalRenderCP shouldRender={formStateManager.getFieldValue('changeBankAccount')}>
                    <FieldFormSCP>
                        <AutocompleteBankAccountCP
                            formStateManager={formStateManager}
                            label={'Conta Bancária'}
                            fieldName={'bankAccountCode'}
                        />
                    </FieldFormSCP>
                </ConditionalRenderCP>
            </FieldSCP>
        </ModalCP>
    )
}

const FieldSCP = styled.div`
  margin-bottom: 10px;
`

const FieldFormSCP = styled.div`
  margin-bottom: 30px;
  margin-left: 20px;
`
