import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { Moment } from 'moment'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { IsOptional } from 'class-validator/decorator/decorators'

export class PayMultipleFinancialTransactionFormModel extends GenericFormValidator {

    @IsNotEmpty()
    onExpirationDate: boolean

    @IsOptional()
    paidDate: Moment

    @IsNotEmpty()
    samePaymentMethod: boolean

    @IsOptional()
    @IsIn(Object.values(PaymentMethodEnum))
    paymentMethod: PaymentMethodEnum

    @IsNotEmpty()
    sameBankAccount: boolean

    @IsOptional()
    bankAccountCode: number

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
