import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

/**
 * ICONE: Busca
 *
 * @author Stella
 * @author hjcostabr
 */
export const SearchIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'search'} {...props}/>
}
