import { faCheckDouble, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IMarketingDomainResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainResponseDTO'
import { MarketingDomainStatusEnum } from 'main/modules/marketing/enums/MarketingDomainStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface IDomainStatusCPProps {
    status: MarketingDomainStatusEnum
    showUpdateButton: boolean
    domainCode?: number
    onValidateDomain?: () => void
}

/**
 *
 * @author renatofs
 */
export function DomainStatusCP(props: IDomainStatusCPProps): JSX.Element | null {

    const validateDomainRequest = useRequest<IMarketingDomainResponseDTO>()

    const screenSize = useScreenSize()

    useEffect(onValidateDomainRequestChange, [validateDomainRequest.isAwaiting])

    /**
     * Busca agendas na API.
     */
    async function validateDomain(): Promise<void> {

        if (!props.domainCode)
            return

        validateDomainRequest.runRequest(await MarketingRequests.getValidateDomainConfig(props.domainCode))
    }

    /**
     * Trata quando request das lista de requisicao retorna resultado.
     */
    function onValidateDomainRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(validateDomainRequest, 'Não foi remover a agenda', true))
            return

        NotificationHelper.info('Validação realizada com sucesso', `Status do domínio: ${validateDomainRequest.responseData!.status}`)

        if (props.onValidateDomain)
            props.onValidateDomain()
    }

    return (
        <StatusSCP>
            {
                props.status === MarketingDomainStatusEnum.VALID &&
                <FontAwsomeIconCP
                    style={{ cursor: 'pointer' }}
                    type={faCheckDouble}
                    color={ThemeAnt.successColor}
                />
            }
            {
                (props.status === MarketingDomainStatusEnum.INVALID || props.status === MarketingDomainStatusEnum.DEACTIVATED) &&
                <FontAwsomeIconCP
                    style={{ cursor: 'pointer' }}
                    type={faExclamationCircle}
                    color={ThemeAnt.warningColor}
                />
            }
            {!screenSize.smd && <TextCP text={MarketingUtils.getDomainStatusEnumLabel(props.status)}/>}
            {
                (props.status === MarketingDomainStatusEnum.INVALID || props.status === MarketingDomainStatusEnum.DEACTIVATED) && props.showUpdateButton &&
                <ButtonCP
                    type={'primary'}
                    icon={'reload'}
                    size={'small'}
                    loading={validateDomainRequest.isAwaiting}
                    onClick={validateDomain}
                >
                    {!screenSize.smd && 'Atualizar'}
                </ButtonCP>
            }
        </StatusSCP>
    )
}

const StatusSCP = styled.div`
  white-space: nowrap;
  display: flex;

  .ant-typography {
    margin-left: 5px
  }

  button {
    margin-left: 15px;
  }
`
