import { TextCP } from 'main/common/components/text/TextCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface ICPProps {
    value: number
    paidValue?: number
}

/**
 * Coluna com o valor de transacao, contendo o valor original e pago.
 */
export function ColumnTransactionValueCP(props: ICPProps): JSX.Element {

    return (
        <>
            { MaskUtils.applyMoneyMask(props.value) }
            {
                !!props.paidValue &&
                <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                    <TextCP text={'pago '} marginRight={5} size={'small'} color={ThemeAnt.gray}/>
                    <TextCP text={MaskUtils.applyMoneyMask(props.paidValue)} size={'small'} color={ThemeAnt.gray}/>
                </FlexCP>
            }
        </>
    )

}
