import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsIn, IsOptional } from 'class-validator'
import { BankAccountModalityEnum } from 'main/modules/financial/enums/BankAccountModalityEnum'
import { PaymentAccountConfig } from 'submodules/neritclin-sdk/services/sale/paymentconfig/types/PaymentAccountConfig'

export class BankAccountFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    bankCode: string

    @IsOptional()
    initialBalance: string

    @IsOptional()
    @IsIn(Object.values(BankAccountModalityEnum))
    modality: BankAccountModalityEnum

    @IsOptional()
    integrationConfig: PaymentAccountConfig

    @IsOptional()
    bankAccountTargetCode: number

    @IsNotEmpty()
    isActive: number

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
