import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { ISegmentSearchByNameAndTypeRequestDTO } from './dtos/request/ISegmentSearchByNameAndTypeRequestDTO'
import { ISegmentSearchRequestDTO } from './dtos/request/ISegmentSearchRequestDTO'
import { ISegmentSaveRequestDTO } from './dtos/request/ISegmentSaveRequestDTO'

/**
 * REQUISICOES relacionadas a LISTAs de SEGMENTACAO.
 */
export class SegmentRequests {

    private static readonly _URL_MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/segments`

    static getMany = (dto: ISegmentSearchRequestDTO): RequestConfigTP => ({
        url: SegmentRequests._URL_MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getNames = (dto: ISegmentSearchByNameAndTypeRequestDTO): RequestConfigTP => ({
        url: `${SegmentRequests._URL_MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getOne = (segmentCode: number): RequestConfigTP<number> => ({
        url: `${SegmentRequests._URL_MODULE_BASE}/${segmentCode}`,
        method: HttpMethodEnum.GET,
    })

    static create = (dto: ISegmentSaveRequestDTO): RequestConfigTP => ({
        url: SegmentRequests._URL_MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (segmentCode: number, dto: ISegmentSaveRequestDTO): RequestConfigTP => ({
        url: `${SegmentRequests._URL_MODULE_BASE}/${segmentCode}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })
}
