import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import React from 'react'
import styled from 'styled-components'
import { FinancialTransactionValueFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionValueFormModel'
import { CardCP } from 'main/common/components/card/CardCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import * as _ from 'lodash'
import { AutocompleteFinancialCategoryCP } from 'main/modules/financial/components/autocomplete-financial-category/AutocompleteFinancialCategoryCP'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'

interface IExpenseValueDataFieldsCPProps {
    type: FinancialTransactionTypeEnum
    formStateManager: IFormStateManager<FinancialTransactionFormModel>
}

/**
 * Campos de valor de 1 despesa (categora, descriçao e valor)
 */
export function FinancialTransactionValuesFormICP(props: IExpenseValueDataFieldsCPProps): JSX.Element {

    /**
     * Remove um item da lista de valores de uma categoria.
     */
    function removeValue(index: number): void {

        let expenseValues: FinancialTransactionValueFormModel[] = _.clone(props.formStateManager.getFieldValue('values')) ?? []
        expenseValues = expenseValues.filter((item, _i) => index !== _i)

        props.formStateManager.changeFieldValue('values', expenseValues)
    }

    /**
     * Adiciona uma linha de valor de uma categoria
     */
    function addNewValue(): void {

        const expenseValues: FinancialTransactionValueFormModel[] = props.formStateManager.getFieldValue('values') ?? []
        expenseValues.push(new FinancialTransactionValueFormModel())

        props.formStateManager.changeFieldValue('values', expenseValues)
    }

    return (
        <CardCP
            title={
                <FlexCP justifyContent={'space-between'}>
                    <TextCP text={'Valores'}/>
                    <ButtonCP
                        type={'default'}
                        icon={'plus'}
                        size={'small'}
                        onClick={addNewValue}
                    />
                </FlexCP>
            }
            margin={'none'}
        >
            {
                _.map(props.formStateManager.getFieldValue('values'), (value: FinancialTransactionValueFormModel, index: number) => (
                    <FlexCP key={value.categoryCode} alignItems={'center'}>
                        <WrapperSCP>
                            <FormWrapperSCP>
                                <AutocompleteFinancialCategoryCP
                                    label={'Categoria'}
                                    categoryType={props.type === FinancialTransactionTypeEnum.CREDIT ? FinancialCategoryTypeEnum.CREDIT : FinancialCategoryTypeEnum.DEBIT}
                                    value={props.formStateManager.getFieldValue('values')[index].categoryCode}
                                    onChange={(val) => props.formStateManager.getFieldValue('values')[index].categoryCode = val}
                                />
                                <InputCP
                                    value={props.formStateManager.getFieldValue('values')[index].description}
                                    type={'text'}
                                    label={'Descrição'}
                                    onChange={(val) => props.formStateManager.getFieldValue('values')[index].description = val}
                                />
                                <InputCP
                                    value={props.formStateManager.getFieldValue('values')[index].value}
                                    type={'text'}
                                    label={'Valor'}
                                    mask={InputMaskTypeEnum.MONEY}
                                    onChange={(val) => props.formStateManager.getFieldValue('values')[index].value = val}
                                    required={true}
                                />
                            </FormWrapperSCP>
                        </WrapperSCP>
                        <ButtonCP
                            type={'danger'}
                            onClick={() => removeValue(index)}
                            icon={'delete'}
                            size={'small'}
                            marginLeft={5}
                        />
                    </FlexCP>
                ))
            }
        </CardCP>
    )
}

const WrapperSCP = styled.div`
    .ant-btn{
        width: 100%;
        justify-content: center;
    }
`

const FormWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    column-gap: 10px;
    align-items: center;
    .ant-form-explain{
        max-width: 150px;
    }
`

