import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { IconCP } from 'main/common/components/icons/IconCP'
import { IBankAccountWithdrawResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IBankAccountWithdrawResponseDTO'

export const BankAccountWithdrawTableUtils = {

    getTableColumns(
        onSelect: (code: number) => void,
    ): Array<ColumnProps<IBankAccountWithdrawResponseDTO>> {
        return [
            {
                title: 'Data',
                key: 'date',
                render: (text, item) => DateUtils.getFormatted(item.date, DateFormatEnum.BR_WITHOUT_TIME)
            },
            {
                title: 'Conta Origem',
                key: 'cr_bank',
                render: (text, item) => item.bankAccountSource.name
            },
            {
                key: 'icon',
                align: 'center',
                render: () => <IconCP antIcon={'arrow-right'}/>
            },
            {
                title: 'Conta Destino',
                key: 'cr_bank',
                render: (text, item) => item.bankAccountTarget.name
            },
            {
                title: 'Valor',
                key: 'cr_value',
                align: 'right',
                render: (text, item) => <TextCP text={MaskUtils.applyMoneyMask(item.value)}/>
            },
            {
                key: 'cr_actions',
                align: 'right',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            size={'small'}
                            onClick={(): void => onSelect(item.code)}
                            icon={'eye'}
                            marginLeft={5}
                        />
                    </FlexCP>
                )
            }
        ]
    },

}
