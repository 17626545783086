import React from 'react'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faUserCheck, faUserClock } from '@fortawesome/free-solid-svg-icons'

interface ICPProps {
    isPresenceConfirmed: boolean
    hidePresenceNotConfirmed?: boolean
}

/**
 * Icone para de presenca confirmada.
 */
export function IconPresenceConfirmationCP(props: ICPProps): JSX.Element {

    if (props.isPresenceConfirmed)
        return <FontAwsomeIconCP type={faUserCheck}/>

    if (props.hidePresenceNotConfirmed)
        return <></>

    return <FontAwsomeIconCP type={faUserClock}/>
}

