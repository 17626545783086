import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import { Icon, TimePicker } from 'antd'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { FormModel } from 'main/common/form-state-manager/FormModel'

interface IScpProps {
    width?: string
    margin?: string
}

interface ITimePickerCPProps<FModelTP extends FormModel = any> extends IScpProps {
    disabled?: boolean
    value?: Moment
    onChange?: (t: Moment, time: string) => void
    size?: 'large' | 'default' | 'small'
    suffixIcon?: JSX.Element
    inputReadOnly?: boolean
    allowClear?: boolean
    formStateManager?: IFormStateManager<FModelTP>
    fieldName?: keyof FModelTP
    initAsNow?: boolean
}

/**
 * Componente wrapper de TimePicker Input.
 */
export function TimePickerCP<FModelTP extends FormModel = any>(props: ITimePickerCPProps<FModelTP>): JSX.Element {

    const [selectedTime, setSelectedTime] = useState<Moment>()
    useEffect(init, [props.value, props.formStateManager])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.formStateManager && props.fieldName)
            return setSelectedTime(props.formStateManager.getFieldValue(props.fieldName))

        if (props.initAsNow && !props.value)
            setSelectedTime(moment())

        return setSelectedTime(props.value)
    }

    /**
     * Ao alterar o valor do campo.
     */
    function onChange(t: Moment, time: string): void {

        if (props.formStateManager && props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, t)

        if (props.onChange)
            props.onChange(t, time)
    }

    return (
        <TimePickerSCP
            width={props.width}
            margin={props.margin}
        >
            <TimePicker
                disabled={props.disabled}
                value={selectedTime}
                defaultOpenValue={moment('08:00', 'HH:mm')}
                minuteStep={5}
                inputReadOnly={props.inputReadOnly ?? false}
                onChange={onChange}
                format={'HH:mm'}
                size={props.size ?? 'small'}
                suffixIcon={props.suffixIcon ?? <Icon type={'clock-circle'}/>}
                placeholder={'Hora'}
                allowClear={props.allowClear}
            />
        </TimePickerSCP>
    )

}

const TimePickerSCP = styled.div<IScpProps>`

   .ant-time-picker{
        width: ${props => props.width ?? '78px'};
        margin: ${props => props.margin ?? '0 10px'};
        color: #cacaca;
   }
   
   .ant-time-picker-input {
        border: none;
        border-bottom: solid 1px #BBBBBB;
        border-radius: 0px;
        padding-bottom: 7px !important;
   }
   
   .ant-time-picker-icon {
          top: 40%;
   }

`
