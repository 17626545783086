import React, { useState } from 'react'
import { PopOverCP } from 'main/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'

interface IPopoverPercentageValueCalculatorProps {
    onClose: (value?: number) => void
    totalValue: number
}

/**
 * Calculadora de um valor a partir de um percentual
 */
export function PopoverPercentageValueCalculator(props: IPopoverPercentageValueCalculatorProps): JSX.Element {

    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [calculatedValue, setCalculatedValue] = useState<string>('0,00')

    return (
        <PopOverCP
            visible={isVisible}
            onClose={() => setIsVisible(false)}
            content={
                <>
                    <RowCP spaceBetweenCols={16}>
                        <ColumnCP size={12}>
                            <InputCP
                                label={'Desconto (%)'}
                                type={'number'}
                                max={100}
                                min={0}
                                onChange={(val) => setCalculatedValue(MaskUtils.applyMoneyMask(Number((val / 100) * props.totalValue).toFixed(2)))}
                            />
                        </ColumnCP>
                        <ColumnCP size={12}>
                            <InputCP
                                label={'Valor Desc.'}
                                value={calculatedValue}
                                mask={InputMaskTypeEnum.MONEY}
                                disabled={true}
                            />
                        </ColumnCP>
                    </RowCP>

                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            onClick={() => {
                                setIsVisible(false)
                                props.onClose(MoneyUtils.convertToFloat(calculatedValue))
                            }}
                        >
                            Utilizar percentual
                        </ButtonCP>
                    </FlexCP>
                </>
            }
        >
            <ButtonCP
                icon={'calculator'}
                type={'ghost'}
                marginTop={15}
                onClick={() => setIsVisible(true)}
            />
        </PopOverCP>
    )
}
