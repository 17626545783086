import * as _ from 'lodash'
import { ContentCP } from 'main/common/components/screen-layout/content/ContentCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { LoadingScreen } from 'main/common/screens/LoadingScreen'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { ModalUserTreatmentGroupCP } from 'main/modules/people/components/modal-user-treatment-group/ModalUserTreatmentGroupCP'
import { ListContentICP } from 'main/modules/people/screens/screen-person-list/screen-content/inner/ListContentICP'
import { PersonListScreenHeaderICP } from 'main/modules/people/screens/screen-person-list/screen-content/inner/PersonListScreenHeaderICP'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import { PersonRoutes } from 'main/modules/people/routes/PersonRoutes'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ScreenPersonListQueryParamsTP } from 'main/modules/people/types/ScreenPersonListQueryParamsTP'
import { SegmentExpressionBarCP } from 'main/modules/segment/components/segment-expression-bar/SegmentExpressionBarCP'
import { SegmentSavingConfirmationModalCP } from 'main/modules/segment/components/segment-saving-confirmation-modal/SegmentSavingConfirmationModalCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { ISegmentSaveRequestDTO } from 'main/modules/segment/services/dtos/request/ISegmentSaveRequestDTO'
import { ISegmentListItemResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentListItemResponseDTO'
import { ISegmentSaveResponseDTO } from 'main/modules/segment/services/dtos/response/ISegmentSaveResponseDTO'
import { SegmentRequests } from 'main/modules/segment/services/SegmentRequests'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { SegmentTypeNameTP } from 'main/modules/segment/types/generic/SegmentTypeNameTP'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

/**
 * COMPONENTE
 * Representa o conteúdo da tela do CRM.
 */
export const PersonListScreenContentCP = ComponentUtils.memoizeCp(true, (): JSX.Element => {

    const screenContext = PersonContext.use()
    const routeParams = useParams<ScreenPersonListQueryParamsTP>()

    const [enableCreateSegment, setEnableCreateSegment] = useState<boolean>(false)
    const [enableEditSegment, setEnableEditSegment] = useState<boolean>(false)
    const [showSegmentModal, setShowSegmentModal] = useState<boolean>(false)

    const segmentSavingRequest = useRequest<ISegmentSaveResponseDTO>()
    const getDefaultSegmentRequest = useRequest<ISegmentListItemResponseDTO>('api-return')

    const selectedSegment = screenContext.state.segment

    useEffect(init, [])
    useEffect(onRouteChange, [routeParams.segmentType])
    useEffect(onRouteInnerChange, [screenContext.state.typeToGoTo])
    useEffect(setSegmentSavingPermissions, [selectedSegment])
    useEffect(onSegmentSavingRequestChange, [segmentSavingRequest.isAwaiting])
    useEffect(onGetDefaultSegmentRequestChange, [getDefaultSegmentRequest.isAwaiting])

    const segmentType: OrUndefTP<SegmentTypeEnum> = selectedSegment?.type ?? SegmentUtils.getDefaultSegmentType()
    const isScreenLoading = (screenContext.state.isLoadingSegment || screenContext.state.isLoadingPeople)

    function init(segType?: SegmentTypeEnum): void {

        segType = segType ?? getRouteType()
        if (!segType)
            return

        const segSpecialCode = SegmentUtils.getDefaultSegmentCode(segType, true)
        getDefaultSegmentRequest.runRequest(SegmentRequests.getOne(segSpecialCode))
    }

    function onRouteChange(): void {

        const segType = getRouteType()
        if (!segType)
            return

        const isInnerChange = (segType === screenContext.state.typeToGoTo)
        if (!!screenContext.state.typeToGoTo)
            screenContext.actions.clearTypeToGoTo()

        // Rota alterada automaticamente: Nao precisa fazer nada
        if (isInnerChange)
            return

        // Rota alterada pelo usuario: Reinicia a tela toda
        init(segType)
    }

    function getRouteType(): OrUndefTP<SegmentTypeEnum> {

        if (!routeParams.segmentType)
            return

        const segType = SegmentUtils.getSegmentTypeByTypeName(routeParams.segmentType as SegmentTypeNameTP, false, true)
        if (segType)
            return segType

        RoutingHelper.historyReplace(PersonRoutes.ROOT)
    }

    function onRouteInnerChange(): void {
        if (!!screenContext.state.typeToGoTo && screenContext.state.typeToGoTo !== getRouteType())
            RoutingHelper.historyPush(`${PersonRoutes.ROOT}/${SegmentUtils.getSegmentTypeName(screenContext.state.typeToGoTo, true)}`)
    }

    function onGetDefaultSegmentRequestChange(): void {
        if (RequestUtils.isValidRequestReturn({
            request: getDefaultSegmentRequest,
            errorMsg: 'Falha ao tentar buscar lista de segmentação',
            failureLogMsg: `FALHA - ${PersonListScreenContentCP.name}.${onGetDefaultSegmentRequestChange.name}`,
        }))
            screenContext.actions.setSegment(getDefaultSegmentRequest.responseData!)
        console.log("SEGMENTACAO ", getDefaultSegmentRequest.responseData,getDefaultSegmentRequest.isAwaiting)

    }

    function onSegmentSavingRequestChange(): void {
        if (RequestUtils.isValidRequestReturn({
            request: segmentSavingRequest,
            errorMsg: 'Falha ao tentar salvar Lista de Segmentação',
            failureLogMsg: `FALHA - ${PersonListScreenContentCP.name}.${onSegmentSavingRequestChange.name}`,
        })) {
            const nextSelectedSegment = SegmentUtils.getListItemDtoFromSavingDto(segmentSavingRequest.responseData!, selectedSegment!)
            screenContext.actions.setSegment(nextSelectedSegment)
            setShowSegmentModal(false)
            NotificationHelper.success('Sucesso', 'Lista de segmentação salva com sucesso!')
        }
    }

    function onSegmentExpressionChange(currentExpression: SegmentExpressionTP): void {
        if (selectedSegment) {
            setSegmentSavingPermissions(!_.isEqual(currentExpression, selectedSegment.expression))
            screenContext.actions.setExpression(currentExpression)
        }
    }

    function setSegmentSavingPermissions(_enableCreateSegment = false): void {
        if (!!selectedSegment) {
            setEnableCreateSegment(_enableCreateSegment)
            setEnableEditSegment(!SegmentUtils.getSpecialSegmentCodes().includes(selectedSegment.code))
        }
    }

    function onPersonSaved(person: IPersonResponseDTO): void {
        screenContext.actions.setShowAddPersonDrawer(false)
        screenContext.actions.setSelectedPersonCode(person.code)
        screenContext.actions.setShowPersonDetailsDrawer(!!person.code)
        screenContext.actions.setMustUpdatePeopleList(true)
    }

    function saveSegment(segmentName: string, isUpdate: boolean, isOnlyName = false): void {

        if (!selectedSegment)
            return

        const dto: ISegmentSaveRequestDTO = {
            name: segmentName,
            type: selectedSegment.type,
            expression: isOnlyName ? selectedSegment.expression : screenContext.state.expression,
        }

        const reqConfig = isUpdate ? SegmentRequests.update(selectedSegment.code, dto) : SegmentRequests.create(dto)
        segmentSavingRequest.runRequest(reqConfig)
    }

    if (!segmentType)
        return <LoadingScreen/>

    return (
        <ContentCP>
            <MainContentWrapperSCP>
                {
                    !!selectedSegment &&
                    <>
                        <PersonListScreenHeaderICP
                            segment={selectedSegment}
                            onAddPerson={() => screenContext.actions.setShowAddPersonDrawer(true)}
                            onSaveSegmentEdition={() => setShowSegmentModal(true)}
                            showSaveButton={enableCreateSegment}
                            enableNameChange={enableEditSegment && !isScreenLoading}
                            onSegmentNameChange={segmentName => saveSegment(segmentName, true, true)}
                        />

                        <SegmentExpressionBarCP
                            segmentType={segmentType}
                            onChange={onSegmentExpressionChange}
                            expression={screenContext.state.expression ?? []}
                            isLoading={isScreenLoading}
                        />
                    </>
                }

                <ListContentICP/>
            </MainContentWrapperSCP>

            <DrawerPersonDataCP
                visible={screenContext.state.showAddPersonDrawer}
                segmentType={segmentType}
                onSave={onPersonSaved}
                onClose={(): void => screenContext.actions.setShowAddPersonDrawer(false)}
            />

            <DrawerPersonDetailsCP
                visible={screenContext.state.showPersonDetailsDrawer}
                personCode={screenContext.state.selectedPersonCode}
                segmentType={segmentType}
                onClose={(): void => screenContext.actions.setShowPersonDetailsDrawer(false)}
                onSave={(): void => screenContext.actions.setShowPersonDetailsDrawer(false)}
            />

            <ModalUserTreatmentGroupCP
                visible={screenContext.state.showAddJobFunctionModal}
                userCode={screenContext.state.selectedPersonCode}
                onCancelModal={(): void => screenContext.actions.setShowAddJobFunctionModal(false)}
                onSave={(): void => {
                    screenContext.actions.setShowAddJobFunctionModal(false)
                    screenContext.actions.setMustUpdatePeopleList(true)
                }}
            />

            {
                selectedSegment &&
                <SegmentSavingConfirmationModalCP
                    enableUpdate={!SegmentUtils.getSpecialSegmentCodes().includes(selectedSegment.code)}
                    onAddSegment={segmentName => saveSegment(segmentName, false)}
                    onUpdateSegment={segmentName => saveSegment(segmentName, true)}
                    onCancel={() => setShowSegmentModal(false)}
                    isVisible={showSegmentModal}
                    isLoading={segmentSavingRequest.isAwaiting}
                    segmentName={selectedSegment.name}
                />
            }
        </ContentCP>
    )
})

const MainContentWrapperSCP = styled.div`
    overflow: hidden auto;
`
