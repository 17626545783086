import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Timeline } from 'antd'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { EmptyCP } from 'main/common/components/empty/EmptyCP'

export interface ITimelineItem {
    color?: string
    dot?: string | JSX.Element
    position?: 'left' | 'right'
    content: string | JSX.Element
    style?: CSSProperties
}

interface ITimelineCPProps {
    pending?: boolean | string | JSX.Element
    pendingDot?: string | JSX.Element
    reverse?: boolean
    mode?: 'left' | 'alternate' | 'right'
    items?: ITimelineItem[]
    loading?: boolean
}

/**
 * Componente wrapper TIMELINE do antd
 */
export function TimelineCP(props: ITimelineCPProps): JSX.Element {

    if (props.loading)
        return <LoadingOverlayCP show={props.loading}/>

    if (ArrayUtils.isEmpty(props.items))
        return <EmptyCP description={'Nenhum registro encontrado'}/>

    return (
        <TimelineCPWrapperSCP>

            <Timeline
                pending={props.pending}
                pendingDot={props.pendingDot}
                reverse={props.reverse}
                mode={props.mode}
            >
                {props.items?.map(item => (
                    // eslint-disable-next-line react/jsx-key
                    <Timeline.Item
                        color={item.color}
                        dot={item.dot}
                        position={item.position}
                    >
                        {item.content}
                    </Timeline.Item>
                ))}

            </Timeline>
        </TimelineCPWrapperSCP>
    )

}

const TimelineCPWrapperSCP = styled.div`

  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 0;
  }
`
