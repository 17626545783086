import React from 'react'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import * as _ from 'lodash'
import { CardCP } from 'main/common/components/card/CardCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { CreateSaleUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'

interface IModalCreateSaleConfirmCPProps {
    paymentDataFormStateManager: IFormStateManager<SalePaymentDataFormModel>
    visible: boolean
    loading?: boolean
    onSave: () => void
    onCancel: () => void
}

/**
 * Modal para input de alguns dados de confirmacao antes de finalizar a venda.
 */
export function ModalCreateSaleConfirmCP(props: IModalCreateSaleConfirmCPProps): JSX.Element {

    /**
     * Ao salvar dados
     */
    function onSave(): void {

        let isMissingRequiredFields: boolean = false
        _.each(CreateSaleUtils.getPhysicalCharges(props.paymentDataFormStateManager), (charge) => {
            if (!charge.authNumber || !charge.nsu)
                isMissingRequiredFields = true
        })

        if (isMissingRequiredFields) {
            NotificationHelper.error('Ops!', 'Preencha todos os campos para prosseguir')
            return
        }

        props.onSave()
    }

    return (
        <ModalCP
            title={'Passe o cartão do Cliente'}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={onSave}
            okText={'Confirmar venda'}
            singleButton={true}
            loading={props.loading}
            top={10}
        >
            <TextCP
                text={'Passe agora o cartão do cliente e informe os números da transação'}
                fontStyle={'italic'}
            />

            {
                // Filtra a lista de compras apenas por aquelas que possui o tipo de pagamento fisico
                // Entre essas itera para renderizar o campo para pedir os dados
                _.map(
                    CreateSaleUtils.getPhysicalCharges(props.paymentDataFormStateManager),
                    ((charge, index) => {
                        return (
                            <CardCP title={`Cartão de Crédito #${index + 1}`} margin={'vertical'}>

                                <TextCP
                                    text={`Valor total de ${MaskUtils.applyMoneyMask(_.sumBy(charge.installments, 'value'))} dividido em ${charge.installments.length}x`}
                                    fontStyle={'italic'}
                                    marginBottom={15}
                                />

                                <RowCP spaceBetweenCols={24}>
                                    <ColumnCP size={12}>
                                        <InputCP
                                            required={true}
                                            label={'NSU'}
                                            onChange={(val) => charge.nsu = val}
                                        />
                                    </ColumnCP>
                                    <ColumnCP size={12}>
                                        <InputCP
                                            required={true}
                                            label={'Número de Autorização'}
                                            onChange={(val) => charge.authNumber = val}
                                        />
                                    </ColumnCP>
                                </RowCP>

                            </CardCP>
                        )
                    })
                )
            }
        </ModalCP>
    )
}
