import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { CancelationReasonTypeEnum } from 'main/modules/sales-funnel/services/cancelation-reason/enums/CancelationReasonTypeEnum'

/**
 * REQUISIÇÕES relacionadas a Motivos de Cancelamento.
 */
export class CancelationReasonRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/cancellation-reasons`

    static search = (dto?: CancelationReasonTypeEnum): RequestConfigTP => ({
        url: CancelationReasonRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
