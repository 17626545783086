import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ITreatmentGroupListItemResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentGroupListItemResponseDTO'

/**
 * Utils da tabela de grupos de tratamento.
 */
export const TreatmentGroupTableUtils = {

    getTableColumns(
        onEdit: (code: number) => void
    ): Array<ColumnProps<ITreatmentGroupListItemResponseDTO>> {

        return [
            {
                key: 'title',
                title: 'Nome',
                render: (text, item) => item.name
            },
            // {
            //     key: 'countTreatment',
            //     title: 'Total atendimentos',
            //     render: (text, item) => MaskUtils.applyNumberMask(item.totalTreatments, 0)
            // },
            // {
            //     key: 'countTeam',
            //     title: 'Total funcionários',
            //     render: (text, item) => MaskUtils.applyNumberMask(item.totalUsers, 0)
            // },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEdit(item.code)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

}
