import { ActionTP } from 'main/common/types/ActionTP'
import { TotalWithFlagTP } from 'main/common/types/TotalWithFlagTP'
import { PersonContextActionTypeTP } from 'main/modules/people/contexts/person-context/PersonContextActionTypeTP'
import { PersonContextStateTP } from 'main/modules/people/contexts/person-context/PersonContextStateTP'

type PersonListScContextActionTP = ActionTP<PersonContextActionTypeTP, PersonContextStateTP>

/**
 * UTILS de CONTEXTO da Tela de Listagem de pessoas.
 * Utilitários para hook de acesso a contexto.
 */
export class PersonContextUtils {

    private static _isLoadingPeopleAuxCount = 0     // Diferença entre numero de chamadas para setar/resetar status de carregamento
    private static _isLoadingSegmentAuxCount = 0    // O status é setado (se > 0) ou resetado (se == 0)
    private static _mustUpdatePeopleAuxCount = 0

    /**
     * Controla atualização de estado de propriedades booleanas considerando chamadas assíncronas de set/reset
     * para evitar alterações desencontradas.
     */
    static getFlagState(currentState: PersonContextStateTP, action: PersonListScContextActionTP): PersonContextStateTP {

        // Verifica se deve haver atualização
        const countingParse = this._getCountingParse(action)
        if (!countingParse || countingParse.total < 0)
            return currentState

        // Atualiza estado
        let isLoadingPeople = currentState.isLoadingPeople
        let isLoadingSegment = currentState.isLoadingSegment
        let mustUpdatePeopleList = currentState.mustUpdatePeopleList

        switch (action.type) {
            case 'setIsLoadingPeople':
                PersonContextUtils._isLoadingPeopleAuxCount = countingParse.total
                isLoadingPeople = countingParse.isTrue
                break
            case 'setIsLoadingSegment':
                PersonContextUtils._isLoadingSegmentAuxCount = countingParse.total
                isLoadingSegment = countingParse.isTrue
                break
            case 'setMustUpdatePeopleList':
                PersonContextUtils._mustUpdatePeopleAuxCount = countingParse.total
                mustUpdatePeopleList = countingParse.isTrue
                if (mustUpdatePeopleList)
                    isLoadingPeople = true
                break
            default:
                return currentState
        }

        const nextState = {
            ...currentState,
            isLoadingPeople,
            isLoadingSegment,
            mustUpdatePeopleList,
        }

        return nextState
    }

    /**
     * Retorna valores atualizados para uso de 01 flag + contagem atualizada de chamadas ativas para seta-lo:
     * Método auxiliar para atualização de propriedades de estado booleanas;
     */
    private static _getCountingParse(action: PersonListScContextActionTP): TotalWithFlagTP | void {

        let handledProp: keyof PersonContextStateTP
        let currentCount: number

        switch (action.type) {
            case 'setIsLoadingPeople':
                handledProp = 'isLoadingPeople'
                currentCount = PersonContextUtils._isLoadingPeopleAuxCount
                break
            case 'setIsLoadingSegment':
                handledProp = 'isLoadingSegment'
                currentCount = PersonContextUtils._isLoadingSegmentAuxCount
                break
            case 'setMustUpdatePeopleList':
                handledProp = 'mustUpdatePeopleList'
                currentCount = PersonContextUtils._mustUpdatePeopleAuxCount
                break
            default:
                return
        }

        const isHandledFlagTrue = !!action.payload?.[handledProp]
        const total = (currentCount + (isHandledFlagTrue ? 1 : -1))
        return { total, isTrue: (total > 0) }
    }
}
