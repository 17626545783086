import ListItemICPOLD from 'common/old-refactory/list/inner/ListItemICPOLD'
import { ListCPOLD } from 'common/old-refactory/list/ListCPOLD'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { ShotListItemMobileCP } from 'main/modules/marketing/components/shot-list-item-mobile/ShotListItemMobileCP'
import { ShotListItemCP } from 'main/modules/marketing/components/shot-list-item/ShotListItemCP'
import { ShotListItemTP } from 'main/modules/marketing/components/shot-list/inner/ShotListItemTP'
import { IMarketingSearchShotRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingSearchShotRequestDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingShotSearchOrderingEnum } from 'main/modules/marketing/enums/MarketingShotSearchOrderingEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentRequests } from 'main/modules/segment/services/SegmentRequests'
import { ISegmentSearchByNameAndTypeRequestDTO } from 'main/modules/segment/services/dtos/request/ISegmentSearchByNameAndTypeRequestDTO'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface IShotListCPProps {
    shotStatuses: MarketingShotStatusEnum[]
    shotType: MarketingAccountTypeEnum
}

/**
 * Listagem de disparos de mkt.
 */
export function ShotListCP<ShotTP extends ShotListItemTP>(props: IShotListCPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [shotList, setShotList] = useState<ShotTP[]>()
    const searchShotsRequest = useRequest<IGenericListResponseDTO<ShotTP>>()
    useEffect(onSearchShotRequestChange, [searchShotsRequest.isAwaiting])

    const [segmentList, setSegmentList] = useState<INameAndCodeResponseDTO[]>([])
    const searchSegmentListRequest = useRequest<IGenericListResponseDTO<INameAndCodeResponseDTO>>()
    useEffect(onSearchSegmentListRequestChange, [searchSegmentListRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { defineShotList() }, [props.shotType, props.shotStatuses])

    /**
     * Inicializa.
     */
    function init(): void {

        // Busca todas as listas de segmentacao para mostra dados na listagem dos disparos
        // Busca aqui todas de uma vez, para evitar buscar por linha
        const dto: ISegmentSearchByNameAndTypeRequestDTO = {
            types: [SegmentTypeEnum.CUSTOMER, SegmentTypeEnum.COLLABORATOR, SegmentTypeEnum.SUPPLIER],
        }
        searchSegmentListRequest.runRequest(SegmentRequests.getNames(dto))
    }

    /**
     * Retorno ao buscar listas.
     */
    function onSearchSegmentListRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchSegmentListRequest, 'Erro ao tentar buscar listas de segmentação'))
            return

        setSegmentList(searchSegmentListRequest.responseData?.list ?? [])
    }

    /**
     * Obtem lista de disparo
     */
    async function defineShotList(): Promise<void> {

        let orderBy: MarketingShotSearchOrderingEnum

        if (props.shotStatuses.includes(MarketingShotStatusEnum.SCHEDULED))
            orderBy = MarketingShotSearchOrderingEnum.SCHEDULE_DATE
        else if (props.shotStatuses.includes(MarketingShotStatusEnum.SENT))
            orderBy = MarketingShotSearchOrderingEnum.START_DATE
        else
            orderBy = MarketingShotSearchOrderingEnum.CODE

        const dto: IMarketingSearchShotRequestDTO = {
            status: props.shotStatuses,
            itemsPerPage: 100, // @ TODO Nao esta com paginacao na tela
            page: 1,
            orderingCriteria: orderBy,
            orderingAscending: false
        }

        searchShotsRequest.runRequest(await MarketingRequests.searchCampaignShot(props.shotType, dto))
    }

    function onSearchShotRequestChange(): void {
        if (RequestUtils.isValidRequestReturn(searchShotsRequest, 'Não foi possível obter a lista de envios'))
            setShotList(searchShotsRequest.responseData?.list ?? [])
    }

    return (
        <ContentSCP>
            <ListCPOLD
                bordered={false}
                loading={searchShotsRequest.isAwaiting}
                dataSource={shotList as any}
                renderItem={shot => (
                    <ListItemICPOLD>
                        {
                            screenSize.smd ?
                                <ShotListItemMobileCP
                                    showActions={true}
                                    shot={shot}
                                    shotType={props.shotType}
                                    onUpdateList={defineShotList}
                                    semegmentList={segmentList}
                                />
                                :
                                <ShotListItemCP
                                    showActions={true}
                                    shot={shot}
                                    shotType={props.shotType}
                                    onUpdateList={defineShotList}
                                    semegmentList={segmentList}
                                />
                        }
                    </ListItemICPOLD>
                )}
            />
        </ContentSCP>
    )

}

const ContentSCP = styled.div`
  .ant-list-item {
    padding: 0;
    border-radius: 10px;
    margin-bottom: 20px;
    justify-content: center;
    border: none !important;

    .ant-list-item-meta {
      flex: inherit;
    }
  }
`
