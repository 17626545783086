import { IsArray, IsOptional } from 'class-validator/decorator/decorators'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { ObjectPropsTP } from 'main/common/types/ObjectPropsTP'
import { IsEmail } from 'main/common/validation/decorators/IsEmail'
import { IsIn } from 'main/common/validation/decorators/IsIn'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsRequiredOneOf } from 'main/common/validation/decorators/IsRequiredOneOf'
import { IsString } from 'main/common/validation/decorators/IsString'
import { IsValidCpfOrCnpj } from 'main/common/validation/decorators/IsValidCpfOrCnpj'
import { GenderEnum } from 'main/modules/people/enums/GenderEnum'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'

/**
 * VALIDADOR
 * Define validação dos campos do formulário de dados do perfil de usuário
 */
export class ProfileFormModel extends FormModel {

    /**
     * Usado para validação de combinação de campos.
     * NAO ha input para isso!!
     */
    @IsRequiredOneOf(['name', 'email', 'phone'])
    _atLeastOneRequired: any   // eslint-disable-line @typescript-eslint/naming-convention

    @IsNotEmpty()
    @IsString()
    name: string

    @IsValidCpfOrCnpj()
    @IsNotEmpty()
    cpfCnpj: string

    @IsOptional()
    @IsEmail()
    email?: string

    @IsOptional()
    phone?: string

    @IsOptional()
    birthDate?: string

    @IsOptional()
    @IsIn(Object.values(GenderEnum))
    gender?: GenderEnum

    @IsOptional()
    @IsArray()
    @IsIn(Object.values(SegmentTypeEnum))
    types?: SegmentTypeEnum[]

    @IsOptional()
    rg?: string

    // address

    @IsOptional()
    @IsString()
    zipCode?: string

    @IsOptional()
    @IsString()
    street?: string

    @IsOptional()
    @IsString()
    number?: string

    @IsOptional()
    @IsString()
    complement?: string

    @IsOptional()
    @IsString()
    neighborhood?: string

    @IsOptional()
    @IsString()
    city?: string

    @IsOptional()
    @IsString()
    state?: string

    constructor(initialData: ObjectPropsTP<ProfileFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
