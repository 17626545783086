import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { IGenerateBilletRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IGenerateBilletRequestDTO'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { RoutingHelper } from 'config/RoutingHelper'

interface IGenerateBilletModalCPProps {
    visible: boolean
    transactionCode: number
    onSave: () => void
    onCancel: () => void
}

/**
 * COMPONENTE
 * modal para geração de 2a via de boleto com nova data de vencimento
 */
export function GenerateBilletModalCP(props: IGenerateBilletModalCPProps): JSX.Element | null {

    const [newExpirationDate, setNewExpirationDate] = useState<string>(DateUtils.getFormatted(moment().format(), DateFormatEnum.BR_WITHOUT_TIME))

    const generateBilletReq = useRequest<string>()
    useEffect(onGenerateBilletReqChange, [generateBilletReq.isAwaiting])

    function generateBillet(): void{

        const dto: IGenerateBilletRequestDTO = {
            transactionCode: props.transactionCode,
            newExpirationDate: DateUtils.transformDateStrFormat(newExpirationDate, DateFormatEnum.BR_WITHOUT_TIME, DateFormatEnum.US_WITH_TIME_H_M_2)
        }

        generateBilletReq.runRequest(FinancialTransactionsRequests.generateBillet(dto))
    }

    function onGenerateBilletReqChange(): void {

        if (!RequestUtils.isValidRequestReturn(generateBilletReq, 'Ocorreu algum erro ao salvar remetente'))
            return

        if (!generateBilletReq.responseData)
            return

        NotificationHelper.info('Yes', 'Segunda via gerado com sucesso')
        RoutingHelper.openInNewTab(generateBilletReq.responseData)
        props.onSave()
    }

    return (
        <ModalCP
            title={'Nova data de vencimento'}
            onOk={generateBillet}
            onCancel={props.onCancel}
            visible={props.visible}
            actionLoading={generateBilletReq.isAwaiting}
            width={280}
        >
            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        label={'Data de vencimento'}
                        value={newExpirationDate}
                        onChange={setNewExpirationDate}
                        required={true}
                        mask={InputMaskTypeEnum.DATE}
                    />
                </ColumnCP>
            </RowCP>

        </ModalCP>
    )
}
