import React, { useEffect } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { CardDashboardWidgetWrapperCP } from 'main/modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { TotalFollowupsTodayByUserTableUtils } from 'main/modules/dashboard/components/widget-table-total-followups-today-by-user/inner/TotalFollowupsTodayByUserTableUtils'
import { IFollowUpsByUserListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IFollowUpsByUserListItemResponseDTO'

interface ICPProps {
    franchiseCodes?: number[]
}

/**
 * Componente de tabela que contas bancarias
 */
export function WidgetTableTotalFollowupsTodayByUserCP(props: ICPProps): JSX.Element {

    const getFollowupsByUserRequest = useRequest<IGenericListResponseDTO<IFollowUpsByUserListItemResponseDTO>>()
    useEffect(onGetFollowupsByUserRequestChange, [getFollowupsByUserRequest.isAwaiting])

    useEffect(init, [props.franchiseCodes])

    /**
     * Busca dados na api.
     */
    function init(): void {
        getFollowupsByUserRequest.runRequest(DashboardRequests.getFollowupsByUserData({ franchiseCodes: props.franchiseCodes }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetFollowupsByUserRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(getFollowupsByUserRequest, 'Não foi possível obter o relatorio de followups por usuario'))
            return
    }

    return (
        <CardDashboardWidgetWrapperCP
            title={'Follow-ups para hoje por colaborador'}
            className={'card-dashboard-widget'}
            maxWidth={800}
        >
            <TableCPOLD2
                columns={TotalFollowupsTodayByUserTableUtils.getTableColumns()}
                data={getFollowupsByUserRequest.responseData?.list}
                loading={getFollowupsByUserRequest.isAwaiting}
            />
        </CardDashboardWidgetWrapperCP>
    )
}
