import React, { useEffect } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IFinancialCategoryResponseDTO } from 'main/modules/financial/services/financial-category/response/IFinancialCategoryResponseDTO'
import { FinancialCategoryTypeEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryTypeEnum'
import { FinancialCategoryDebitLabelEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryDebitEnum'
import { FinancialCategoryCreditLabelEnum } from 'main/modules/financial/services/financial-category/enums/FinancialCategoryCreditEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { FinancialCategoryRequests } from 'main/modules/financial/services/financial-category/FinancialCategoryRequests'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'

interface ICPProps {
    category: IFinancialCategoryResponseDTO
    onAdd: () => void
    onEdit: () => void
    onRemove: () => void
    showAddButton?: boolean
}

/**
 * Titulo do item do plano de contas.
 */
export function FinancialCategoryTreeTitleItemICP(props: ICPProps): JSX.Element {

    const deleteCategoryRequest = useRequest<void>('none')
    useEffect(onDeleteCategoryRequestChange, [deleteCategoryRequest.isAwaiting])

    /**
     * Remover plano de contas.
     */
    function deleteCategory(): void {
        deleteCategoryRequest.runRequest(FinancialCategoryRequests.delete(props.category.code))
    }

    /**
     *  Retorno da remocao de plano de conta.
     */
    function onDeleteCategoryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteCategoryRequest, 'Não foi remover plano de contas', true))
            return

        props.onRemove()
    }

    return (
        <FlexCP alignItems={'center'} justifyContent={'space-between'}>
            <div>
                <FlexCP>
                    <>{`${props.category.order}. ${props.category.name}`}</>
                    <ConditionalRenderCP shouldRender={!!props.category.parentCategoryCode}>
                        <TextCP
                            fontStyle={'italic'}
                            icon={'edit'}
                            color={ThemeAnt.gray}
                            marginLeft={10}
                            onClick={props.onEdit}
                        />
                        <PopConfirmCP
                            title={'Você tem certeza que deseja excluir esse plano de contas?'}
                            onConfirm={deleteCategory}
                        >
                            <TextCP
                                fontStyle={'italic'}
                                icon={deleteCategoryRequest.isAwaiting ? 'loading' : 'delete'}
                                color={ThemeAnt.errorColor}
                                marginLeft={5}
                            />
                        </PopConfirmCP>
                    </ConditionalRenderCP>
                </FlexCP>
                <FlexCP>
                    <TextCP
                        text={'adicionar filho'}
                        onClick={props.onAdd}
                        fontStyle={'italic'}
                        icon={'plus'}
                        color={ThemeAnt.gray}
                    />
                </FlexCP>
            </div>
            {
                !!props.category.type &&
                <FlexCP marginLeft={20}>
                    <TagCP
                        content={props.category.category === FinancialCategoryTypeEnum.DEBIT ? FinancialCategoryDebitLabelEnum[props.category.type] : FinancialCategoryCreditLabelEnum[props.category.type]}
                        small={true}
                    />
                </FlexCP>
            }
        </FlexCP>
    )
}
