import React, { useState } from 'react'
import { TextActivityDescriptionCP } from 'main/modules/sales-funnel/components/text-activity-description/TextActivityDescriptionCP'
import { PopoverPersonContactCP } from 'main/modules/people/components/popover-person-contact/PopoverPersonContactCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { IOpportunityListItemResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityListItemResponseDTO'
import { TextOpportunityEvaluationScheduleDescriptionCP } from 'main/modules/sales-funnel/components/text-opportunity-evaluation-schedule-description/TextOpportunityEvaluationScheduleDescriptionCP'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import styled from 'main/config/theme/styledWithTheme'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'

interface IActivityColumnItemICPProps {
    opportunity: IOpportunityListItemResponseDTO
    onActivitiesActionsDone: (conversionType?: OpportunityDrawerActionTP) => void
}

/**
 * Exibe a próxima atividade agendada para uma oportunidade
 */
export function ActivityColumnItemICP(props: IActivityColumnItemICPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

    if (props.opportunity.type === OpportunityTypeEnum.EVALUATION) {
        return (
            <>
                {
                    !!props.opportunity.evaluationData?.firstNonConcludedEvaluation
                        ?
                        <TextOpportunityEvaluationScheduleDescriptionCP evaluationSchedule={props.opportunity.evaluationData.firstNonConcludedEvaluation} showDate={true} showIcon={true}/>
                        :
                        'Sem avaliação'
                }
            </>
        )
    }

    return (
        <WrapperSCP>
            <PopoverPersonContactCP
                visible={isPopoverVisible}
                onSave={(conversionType) => {
                    props.onActivitiesActionsDone(conversionType)
                }}
                onClose={() => setIsPopoverVisible(false)}
                entityCode={props.opportunity.code}
                entity={PersonContactEntityEnum.OPPORTUNITY}
                activity={props.opportunity.firstNonConcludedActivity}
            >
                <ButtonCP
                    icon={!props.opportunity.firstNonConcludedActivity ? 'exclamation-circle' : 'clock-circle'}
                    type={!props.opportunity.firstNonConcludedActivity ? 'danger' : 'link'}
                    size={'small'}
                    marginTop={10}
                    onClick={() => setIsPopoverVisible(true)}
                >
                    {
                        props.opportunity.firstNonConcludedActivity
                            ?
                            <TextActivityDescriptionCP
                                activity={props.opportunity.firstNonConcludedActivity}
                                showDate={true}
                                showTitle={true}
                            />
                            :
                            'Sem atividade'
                    }
                </ButtonCP>
            </PopoverPersonContactCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  .ant-btn-link {
    padding: 0;
  }
`
