import React from 'react'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import moment from 'moment'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faCheckCircle, faClock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface IIFinancialTransactionStatusIconCPProps {
    status: FinancialTransactionStatusEnum
    expirationDate?: string
}

/**
 * Icone para status de uma movimentacao.
 */
export function FinancialTransactionStatusIconCP(props: IIFinancialTransactionStatusIconCPProps): JSX.Element {

    const overdueIcon = <FontAwsomeIconCP type={faExclamationCircle} color={ThemeAnt.errorColor}/>

    if (props.status === FinancialTransactionStatusEnum.PAID)
        return <FontAwsomeIconCP type={faCheckCircle} color={ThemeAnt.successColor}/>

    if (props.status === FinancialTransactionStatusEnum.OVERDUE)
        return overdueIcon

    if (props.status === FinancialTransactionStatusEnum.UNPAID) {

        // status UNPAID + data de vencimento anterior a hoje == ATRASADO
        if (!!props.expirationDate && moment(props.expirationDate).isBefore(moment().startOf('date')))
            return overdueIcon

        return <FontAwsomeIconCP type={faClock} color={ThemeAnt.warningColor}/>
    }

    return <></>
}

