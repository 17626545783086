import { LogoCP } from 'main/common/components/logo/LogoCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React from 'react'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { SaleStatusEnum, SaleStatusToNameEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { StringUtils } from 'main/common/utils/StringUtils'
import { SaleTypeEnum, SaleTypeLabelEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { FinancialUtils } from 'main/modules/financial/utils/FinancialUtils'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'

interface ICPProps {
    type: SaleTypeEnum
    code: number
    sellerName: string
    customerName: string
    totalInstallments?: number
    saleDate: Date
    paymentMethods?: PaymentMethodEnum[]
    status?: SaleStatusEnum
}

/**
 * Cabecalho do PDF da Venda
 */
export function SaleDetailsPdfHeaderICP(props: ICPProps): JSX.Element {

    return (
        <>
            <FlexCP justifyContent={'space-between'} alignItems={'center'} className={'header-1'}>
                <LogoCP
                    marginTop={0}
                    marginBottom={0}
                    imageUrl={AppStateUtils.getDomainData()!.schema.logoUrl}
                />
                <div>
                    <TextCP text={SaleTypeLabelEnum[props.type]} size={'extraLarge'}/>
                    <TextCP text={`Cód. ${props.code}`} style={{ fontSize: '15px' }} strong={true}/>
                </div>

            </FlexCP>

            <FlexCP justifyContent={'space-between'} alignItems={'flex-start'} className={'header-2'} marginTop={10}>
                <div>
                    {
                        AppStateUtils.getCurrentFranchise()!.address &&
                        <>
                            <TextCP text={`${AppStateUtils.getCurrentFranchise()!.address?.city ?? ''} / ${AppStateUtils.getCurrentFranchise()!.address?.state ?? ''}`}/>
                            <TextCP
                                wrap={true}
                                text={`${AppStateUtils.getCurrentFranchise()!.address?.street ?? ''}, ${AppStateUtils.getCurrentFranchise()!.address?.number ?? ''} - ${AppStateUtils.getCurrentFranchise()!.address?.complement ?? ''}`}
                            />
                        </>
                    }
                </div>

                <FlexCP flexDirection={'column'} alignItems={'flex-end'} className={'header-3'}>
                    <TitleAndTextCP title={'Vendedor(a):'} text={StringUtils.getFirstAndSecondName(props.sellerName)}/>
                    {
                        !!props.status &&
                        <TitleAndTextCP title={'Status:'} text={SaleStatusToNameEnum[props.status]}/>
                    }
                    <TitleAndTextCP title={'Cliente:'} text={StringUtils.getFirstAndSecondName(props.customerName)}/>
                    <TitleAndTextCP title={'Data:'} text={DateUtils.getFormatted(props.saleDate, DateFormatEnum.BR_WITH_TIME_H_M)}/>
                </FlexCP>
            </FlexCP>

            <FlexCP flexDirection={'column'} alignItems={'flex-end'} className={'header-3'}>
                {
                    !!props.totalInstallments &&
                    <TitleAndTextCP title={'Parcelamento:'} text={`${props.totalInstallments}x`}/>
                }
                {
                    !ArrayUtils.isEmpty(props.paymentMethods) &&
                    <TitleAndTextCP title={'Forma de pagamento:'} text={FinancialUtils.getPaymentMethodsLabel(props.paymentMethods!)}/>
                }
            </FlexCP>
        </>
    )

}
