import { MaskUtils } from 'main/common/utils/MaskUtils'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { TableCreateChargeInstallments } from 'main/modules/sale/components/table-create-charge-installments/TableCreateChargeInstallments'
import { CreateChargeFormDataICP } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeFormDataICP'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { CreateChargeFormModel } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeFormModel'
import { ICreateChargeInstallment } from 'main/modules/sale/components/table-create-charge-installments/inner/ICreateChargeInstallment'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import * as _ from 'lodash'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { CreateChargeUtils } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeUtils'
import { PaymentConfigRulesResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/inner/PaymentConfigRulesResponseDTO'

interface IModalCreateChargeCPProps {
    visible: boolean
    onSave: (saleCharge: ISaleCharge) => void
    onCancel: () => void
    charge?: ISaleCharge
    saleDate: Date
    totalRemainingValue: number
    isEditingSaleCharges: boolean
}

/**
 * Modal de criacao de uma corbranca, que eh o grupo de parcelas de uma venda.
 */
export function ModalCreateChargeCP(props: IModalCreateChargeCPProps): JSX.Element {

    const [formModel, setFormModel] = useState<CreateChargeFormModel>(new CreateChargeFormModel())
    const formStateManager = useFormStateManager<CreateChargeFormModel>(formModel)

    const [partnerConfig, setPartnerConfig] = useState<PaymentConfigRulesResponseDTO>()
    const [sumInstallmentsValue, setSumInstallmentsValue] = useState<number>(0)
    const [installmentsTableList, setInstallmentsTableList] = useState<ICreateChargeInstallment[]>([])

    useEffect(updateSumInstallmentsValue, [installmentsTableList])
    useEffect(onChangeFormFields, [
        formStateManager.getFieldValue('totalValue'),
        formStateManager.getFieldValue('hasGracePeriod'),
        formStateManager.getFieldValue('totalInstallments')
    ])

    useEffect(init, [props.visible])

    /**
     * Inicializa os dados.
     */
    function init(): void {

        setFormModel(new CreateChargeFormModel({}))
        if (!props.visible)
            return

        if (props.charge) {

            // Se tiver periodo de carencia, o total de parcelas tem que ser 1 a menos que o guardado
            // Pois o sistema aumenta 1 no final
            const shouldConsiderGracePeriod: boolean = (props.charge.paymentMethod === PaymentMethodEnum.CREDIT_CARD_RECURRENT && props.charge.hasGracePeriod) ?? false
            const _totalInstallments: number = shouldConsiderGracePeriod ? props.charge.installments.length - 1 : props.charge.installments.length

            setFormModel(new CreateChargeFormModel({
                totalInstallments: _totalInstallments,
                paymentMethod: props.charge.paymentMethod,
                totalValue: MoneyUtils.format(Number(_.sumBy(props.charge.installments, 'value')).toFixed(2)),
                firstInstallmentDate: props.charge.installments[0].expirationDate,
                paymentType: props.charge.paymentType,
                hasGracePeriod: props.charge.hasGracePeriod,
            }))
            setInstallmentsTableList(props.charge.installments)

            return
        }

        setFormModel(new CreateChargeFormModel({
            totalInstallments: 1,
            firstInstallmentDate: props.saleDate,
            totalValue: MaskUtils.applyMoneyMask(props.totalRemainingValue),
        }))
        setInstallmentsTableList([])
    }

    /**
     * Evento chamado ao alterar campos do form que impactam nas parcelas.
     */
    function onChangeFormFields(): void {

        // Apaga as parcelas caso o valor total da venda tenha sido alterado e seja diferente do somatorio das parcelas.
        const hasChangedTotalValue: boolean = Number(_.sumBy(installmentsTableList, 'value')) !== MoneyUtils.convertToFloat(formStateManager.getFieldValue('totalValue'))

        // Verifica se mudou o total de parcelas, considerando o periodo de carencia
        const shouldConsiderGracePeriod: boolean = (props.charge?.paymentMethod === PaymentMethodEnum.CREDIT_CARD_RECURRENT && props.charge?.hasGracePeriod) ?? false
        const totalInstallmentsToConsider: number = shouldConsiderGracePeriod ? (installmentsTableList.length - 1) : installmentsTableList.length
        const hasChangedInstallments: boolean = formStateManager.getFieldValue('totalInstallments') !== totalInstallmentsToConsider

        const hasChangedGracePeriod: boolean = formStateManager.getFieldValue('hasGracePeriod') !== props.charge?.hasGracePeriod

        if (hasChangedTotalValue || hasChangedGracePeriod || hasChangedInstallments)
            setInstallmentsTableList([])
    }

    /**
     * Define a tabela de parcelas
     */
    function defineInstallmentsTableList(foundedPartnerConfig?: PaymentConfigRulesResponseDTO): void {

        setPartnerConfig(foundedPartnerConfig)
        setInstallmentsTableList(CreateChargeUtils.mountInstallmentsTable(
            formStateManager,
            props.saleDate
        ) ?? [])
    }

    /**
     * Soma das parcelas.
     */
    function updateSumInstallmentsValue(): void {

        let sumValues: number = 0
        installmentsTableList.forEach((installment) => {
            sumValues += Number(installment.value.toFixed(2))
        })
        setSumInstallmentsValue(Number(sumValues.toFixed(2)))

    }

    /**
     * Altera um dos valores da parcela (data de vencimentou ou forma de pagamento ou valor da parcela)
     */
    function onDataChange(parcelNumberSelected: number, newDate?: Date, newPaymentMethod?: PaymentMethodEnum, newValue?: string, installmentCode?: number): void {

        if (!newDate && !newPaymentMethod && !newValue)
            return

        let sumValues: number = 0
        // Itera na lista das parcelas editar um dos valores passados como parametro
        const modInstallmentsTable = installmentsTableList.map(installment => {

            if (installment.installmentNumber !== parcelNumberSelected) {
                sumValues += Number(installment.value.toFixed(2))
                return installment
            }

            sumValues += Number((newValue ? MoneyUtils.convertToFloat(newValue) : installment.value).toFixed(2))

            return ({
                installmentNumber: parcelNumberSelected,
                expirationDate: newDate ?? installment.expirationDate,
                value: newValue ? MoneyUtils.convertToFloat(newValue) : installment.value,
                code: installmentCode,
            })
        })
        setInstallmentsTableList(modInstallmentsTable)

        sumValues = Number(sumValues.toFixed(2))
        setSumInstallmentsValue(sumValues)
    }

    /**
     * Ao salvar os dados.
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (_.isEmpty(installmentsTableList)) {
            NotificationHelper.error('Ops!', 'Clique em "Gerar Parcelas" para poder prosseguir')
            return
        }

        const saleCharge: ISaleCharge = {
            paymentAccountCode: formValues.paymentAccountCode,
            paymentMethod: formValues.paymentMethod,
            installments: installmentsTableList,
            code: props.charge?.code,
        }

        // So deixa adicionar o paymentType para casos especificos
        if (formValues.paymentMethod === PaymentMethodEnum.CREDIT_CARD) {

            if (!formValues.paymentType) {
                NotificationHelper.error('Ops!', 'Informe se será por maquinha ou link')
                return
            }

            saleCharge.paymentType = formValues.paymentType
        }

        if (formValues.paymentMethod === PaymentMethodEnum.CREDIT_CARD_RECURRENT)
            saleCharge.hasGracePeriod = formValues.hasGracePeriod

        formStateManager.reset(new CreateChargeFormModel({}))
        props.onSave(saleCharge)
    }

    // Variaveis que checam se as informacoes estao validas para prosseguir
    const isSumValueValid = sumInstallmentsValue === MoneyUtils.convertToFloat(formStateManager.getFieldValue('totalValue'))
    const isPaymentRulesChecked = CreateChargeUtils.checkPaymentConfigRules(installmentsTableList, partnerConfig)

    return (
        <ModalCP
            title={'Definir valores da cobrança'}
            width={700}
            visible={props.visible}
            destroyOnClose={true}
            onOk={onSave}
            okButtonProps={{ disabled: !isSumValueValid || !isPaymentRulesChecked }}
            onCancel={props.onCancel}
            top={10}
        >
            <PaymentMethodFormWrapperSCP>
                <CreateChargeFormDataICP
                    formStateManager={formStateManager}
                    saleDate={props.saleDate}
                    totalRemainingValue={props.totalRemainingValue}
                    onGenerateInstallments={defineInstallmentsTableList}
                    initialCharge={props.charge}
                    isEditingSaleCharges={props.isEditingSaleCharges}
                />

                <ConditionalRenderCP shouldRender={!_.isEmpty(installmentsTableList)}>
                    <TableCreateChargeInstallments
                        chargeInstallments={installmentsTableList}
                        paymentMethod={formStateManager.getFieldValue('paymentMethod')}
                        onChangeData={onDataChange}
                        isEditingCharge={!!props.charge?.code}
                    />

                    <AlertCP
                        message={`Existe uma diferença de valores das parcelas e do total informado para esta forma de pagamento: ${MaskUtils.applyMoneyMask(MoneyUtils.getNumber(formStateManager.getFieldValue('totalValue')) - MoneyUtils.getNumber(sumInstallmentsValue))}`}
                        type={'error'}
                        show={!isSumValueValid}
                    />

                    <AlertCP
                        message={`Para a forma de pagamento escolhida, a data do primeiro vencimento deve ser no mínimo ${partnerConfig?.rules?.minDaysToExpire} dias a partir de hoje, e no máximo ${partnerConfig?.rules?.maxDaysToExpire} dias.`}
                        type={'error'}
                        show={!isPaymentRulesChecked}
                    />
                </ConditionalRenderCP>
            </PaymentMethodFormWrapperSCP>

        </ModalCP>
    )
}

const PaymentMethodFormWrapperSCP = styled.div`
    display: grid;
    min-width: 100%;
    row-gap: 25px;
`
