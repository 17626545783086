import * as _ from 'lodash'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { SelectCustomerWithSessionsCP } from 'main/modules/scheduler/components/select-customer-and-treament/inner/SelectCustomerWithSessionsCP'
import { TreatmentSelectCP } from 'main/modules/scheduler/components/select-customer-and-treament/inner/TreatmentSelectCP'
import { ISessionScheduleRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/ISessionScheduleRequestDTO'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { AppointmentDetailsTableUtils } from 'main/modules/scheduler/components/appointments-details-table/inner/AppointmentDetailsTableUtils'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

type RenderComponentsConfigTP = {
    renderSelectsAs: 'column' | 'row',
    showResourceColumnOnTable: boolean,
    showTimeColumnOnTable: boolean,
    showDurationColumnOnTable: boolean,
    showActionsTable: boolean,
}

interface ICPProps {
    onSelectCustomer: (customer: INameAndCodeResponseDTO) => void
    onChangeSessions?: (sessions: ISessionResponseDTO[]) => void
    onChangeSessionsToSchedule?: (sessions: ISessionScheduleRequestDTO[]) => void
    renderComponentsConfig: RenderComponentsConfigTP
    scheduleSessionInitialData?: IScheduleSlotInitialData
}

/**
 * COMPONENTE Mostra Seletor de Cliente + Seletor de Tratamento + Tabela com os tratamentos selecionados
 */
export function SelectCustomerAndTreatmentCP(props: ICPProps): JSX.Element {

    const [treatmentOptions, setTreatmentOptions] = useState<ISessionResponseDTO[]>([])
    const [treatmentsResourcesData, setTreatmentsResourcesData] = useState<ISessionResponseDTO[]>([])

    const [selectedTreatmentsList, setSelectedTreatments] = useState<ISessionScheduleRequestDTO[]>([])
    const [clientSessions, setClientSessions] = useState<ISessionResponseDTO[]>([])
    const [treatmentInitTime, setTreatmentInitTime] = useState<Moment>(props.scheduleSessionInitialData?.initialTime ?? moment())

    useEffect(defineTreatmentOptions, [clientSessions])
    useEffect(init, [props.scheduleSessionInitialData])

    /**
     * Inicializa componente.
     */
    function init(): void {

        if (!props.scheduleSessionInitialData)
            return

        // Caso tenha vido sessoes de parametro, percorre a lista para atualizar o horario de inicio, pois deve
        const newSelectedTreatments: any = []
        const treatmentsToSchedule: ISessionScheduleRequestDTO[] = []
        let initTime = treatmentInitTime

        props.scheduleSessionInitialData.sessions?.forEach((session) => {

            // Intervalo de tempo
            const finalTime = moment(initTime).add(session.sessionDuration, 'minutes')
            const formattedTime = `${moment(initTime).format(DateFormatEnum.TIME_H_M)} às ${finalTime.format(DateFormatEnum.TIME_H_M)}`

            newSelectedTreatments.push({
                ...session,
                timeRange: formattedTime,
            })

            // define a lista de tratamentos selecionados
            treatmentsToSchedule.push({
                code: session.code,
                beginDate: moment(initTime).format(DateFormatEnum.US_WITH_TIME_H_M_2),
                endDate: moment(finalTime).format(DateFormatEnum.US_WITH_TIME_H_M_2)
            })

            initTime = finalTime
        })
        setTreatmentsResourcesData(newSelectedTreatments)
        setSelectedTreatments(treatmentsToSchedule)

        if (!!props.onChangeSessionsToSchedule)
            props.onChangeSessionsToSchedule(treatmentsToSchedule)
    }

    /**
     * Necessário para que, caso o usuário alterne a seleção entre clientes disponíveis para um mesmo atendente,
     * as opções de tratamentos disponíveis, os tratamentos selecionados e os recursos alocados para o cliente
     * anterior não interfiram nos dados do novo cliente selecionado.
     */
    function defineTreatmentOptions(): void {
        if (!clientSessions || _.isEmpty(clientSessions))
            return

        setSelectedTreatments([]) // Reseta os tratementos selecionados
        setTreatmentsResourcesData([]) // Reseta o registro de recursos
        setTreatmentOptions(_.filter(clientSessions, ap => !selectedTreatmentsList.map(s => s.code).includes(ap.code))) // Remonta as opções
    }

    function onTreatmentSelected(selectedTreatment?: ISessionResponseDTO): void {

        if (!selectedTreatment || _.find(selectedTreatmentsList, treat => treat.code === selectedTreatment.code))
            return

        // Intervalo de tempo
        const finalTime = moment(treatmentInitTime).add(selectedTreatment.sessionDuration, 'minutes')
        const formattedTime = `${moment(treatmentInitTime).format(DateFormatEnum.TIME_H_M)} às ${finalTime.format(DateFormatEnum.TIME_H_M)}`

        const newTreatmentSelected = {
            ...selectedTreatment,
            timeRange: formattedTime,
        }

        // define a lista de tratamentos selecionados
        const newSelectedTreatments = [...selectedTreatmentsList, {
            code: selectedTreatment.code,
            beginDate: moment(treatmentInitTime).format(DateFormatEnum.US_WITH_TIME_H_M_2),
            endDate: moment(finalTime).format(DateFormatEnum.US_WITH_TIME_H_M_2)
        }]
        setSelectedTreatments(newSelectedTreatments)

        const newTreatmentList = [...treatmentsResourcesData, newTreatmentSelected]

        setTreatmentsResourcesData(newTreatmentList)
        setTreatmentOptions(_.remove(treatmentOptions, opt => opt.code !== selectedTreatment.code)) // Remove o tratamentos selecionado das demais opções
        setTreatmentInitTime(finalTime)

        if (!!props.onChangeSessions)
            props.onChangeSessions(newTreatmentList)

        if (!!props.onChangeSessionsToSchedule)
            props.onChangeSessionsToSchedule(newSelectedTreatments)
    }

    /**
     */
    function onRemoveTreatment(treatmentToRemove: ISessionResponseDTO): void {

        delete treatmentToRemove.schedule?.beginDate
        setTreatmentOptions([...treatmentOptions, treatmentToRemove]) // Volta o serviço removido da lista de consumo para as demais opções do select

        const _treatmentResources = _.remove(treatmentsResourcesData, treat => treat.code !== treatmentToRemove.code)
        setTreatmentsResourcesData(_treatmentResources)

        const _treatmentList = _.remove(selectedTreatmentsList, treat => treat.code !== treatmentToRemove.code)
        setSelectedTreatments(_treatmentList)

        if (!!props.onChangeSessionsToSchedule)
            props.onChangeSessionsToSchedule(_treatmentList)
    }

    return (
        <>
            <RowCP>
                <ColumnCP size={props.renderComponentsConfig.renderSelectsAs === 'column' ? 12 : 24}>
                    <SelectCustomerWithSessionsCP
                        initialValue={props.scheduleSessionInitialData?.customer}
                        attendantCode={props.scheduleSessionInitialData?.userProfessional?.code}
                        clientSessions={(_clientSessions: ISessionResponseDTO[]) => setClientSessions(_clientSessions)}
                        onSelectCustomer={props.onSelectCustomer}
                        disabled={!!props.scheduleSessionInitialData?.customer}
                    />
                </ColumnCP>

                <ColumnCP size={props.renderComponentsConfig.renderSelectsAs === 'column' ? 12 : 24}>
                    <ConditionalRenderCP shouldRender={!props.scheduleSessionInitialData?.customer}>
                        <TreatmentSelectCP
                            disabled={_.isEmpty(clientSessions)}
                            onTreatmentSelected={onTreatmentSelected}
                            sessionOptions={treatmentOptions}
                        />
                    </ConditionalRenderCP>
                </ColumnCP>
            </RowCP>

            {
                !_.isEmpty(treatmentsResourcesData) &&
                <TableCPOLD2
                    data={treatmentsResourcesData}
                    columns={AppointmentDetailsTableUtils.getCreateTableColumnsConfig(
                        onRemoveTreatment,
                        props.renderComponentsConfig.showResourceColumnOnTable,
                        props.renderComponentsConfig.showTimeColumnOnTable,
                        props.renderComponentsConfig.showDurationColumnOnTable,
                        props.renderComponentsConfig.showActionsTable
                    )}
                />
            }

        </>
    )
}
