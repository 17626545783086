import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { ITreatmentResponseDTO } from 'main/modules/admin/services/treatment/dtos/response/ITreatmentResponseDTO'
import { TagCP } from 'main/common/components/tag/TagCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'

/**
 * Utils da tabela de cargos e funcoes
 */
export const TreatmentTableUtils = {

    getTableColumns(
        onEdit: (code: number) => void
    ): Array<ColumnProps<ITreatmentResponseDTO>> {

        return [
            {
                key: 'title',
                title: 'Atendimento',
                render: (text, item) => item.name
            },
            {
                key: 'group',
                title: 'Grupo',
                render: (text, item) => {
                    return item.groups.map((group: INameAndCodeResponseDTO) => (
                        <TagCP
                            key={`key-tgg-${group.code}`}
                            color={'purple'}
                            content={group.name}
                        />
                    ))
                }
            },
            {
                key: 'form',
                title: 'Form. Evolução',
                render: (text, item) => item.form?.name
            },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEdit(item.code)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

}
