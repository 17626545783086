import * as _ from 'lodash'
import React from 'react'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { UnitValueFieldEnumICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueFieldEnumICP'
import { AutocompleteValueTP } from 'main/common/components/form-fields/autocomplete-picker/inner/AutocompleteValueTP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { SegmentUnitValueFieldTP } from 'main/modules/segment/types/unit/SegmentUnitValueFieldTP'
import { SelectFullOptionReturnTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionReturnTP'
import { SegmentUnitValueTP } from 'main/modules/segment/types/unit/SegmentUnitValueTP'
import { SegmentUnitCompareOperatorsEnum } from 'main/modules/segment/enums/SegmentUnitCompareOperatorsEnum'
import { UnitValueFieldInputIndexTP } from 'main/modules/segment/types/unit/UnitValueFieldInputIndexTP'
import { UnitValueFieldAutocompleteICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitValueFieldAutocompleteICP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { SegmentUnitValueWithLabelTP } from 'main/modules/segment/types/unit/SegmentUnitValueWithLabelTP'
import { SegmentUnitValueDateTP } from 'main/modules/segment/types/unit/SegmentUnitValueDateTP'
import { DateComboCP } from 'main/common/components/form-fields/date-combo/DateComboCP'

const INPUT_WIDTH = 170

interface IUnitValueFieldICPProps {
    index: UnitValueFieldInputIndexTP
    valueWithLabel: Array<SegmentUnitValueWithLabelTP<true>>
    onValueChange: (inputIndex: UnitValueFieldInputIndexTP, newValue: SegmentUnitValueFieldTP | SelectFullOptionReturnTP, isValid: boolean) => void
}

/**
 * COMPONENTE
 * Input/select para determinacao de 01 campo que compoe o valor de 01 unidade.
 */
export function UnitValueFieldICP(props: IUnitValueFieldICPProps): JSX.Element | null {

    const unitContext = UnitContext.useState()

    if (!SegmentUtils.isUnitSelected(unitContext.state.field, unitContext.state.operator))
        return null

    const field = unitContext.state.field!
    const operator = unitContext.state.operator!

    const fieldLabel = (operator === SegmentUnitCompareOperatorsEnum.BETWEEN)
        ? ((props.index === 0) ? 'de' : 'à')
        : SegmentUtils.getUnitCompareOperatorLabel(operator)

    const onChange = (value: any, isValid = true): void => props.onValueChange(props.index, value, isValid)
    const valueFieldsCount = SegmentUtils.getUnitValueFieldsCount(field, operator)
    const isMultiple = (valueFieldsCount === 'n-values')

    function getValueForInput(): OrUndefTP<SegmentUnitValueFieldTP | SegmentUnitValueTP> {

        if (operator === SegmentUnitCompareOperatorsEnum.BETWEEN && props.index === 1)
            return props.valueWithLabel[0]?.value as SegmentUnitValueFieldTP

        if (isMultiple)
            return props.valueWithLabel.map(vWithLabel => vWithLabel?.value) as SegmentUnitValueTP

        return props.valueWithLabel[props.index]?.value as SegmentUnitValueFieldTP
    }

    if (SegmentUtils.isDateField(field)) {
        return (
            <DateComboCP
                onChange={(isValid: boolean, _value?: SegmentUnitValueDateTP) => onChange(_value, isValid)}
                initialValue={props.valueWithLabel[props.index]?.value as SegmentUnitValueDateTP}
                enableQuickDate={valueFieldsCount !== '2-dates'}
                label={(valueFieldsCount === '2-dates') ? fieldLabel : undefined}
            />
        )
    }

    if (SegmentUtils.isEnumSelectOptionField(field)) {
        return (
            <UnitValueFieldEnumICP<any>
                onChange={onChange}
                inputIndex={props.index}
                label={fieldLabel}
                isMultiple={isMultiple}
                value={getValueForInput()}
            />
        )
    }

    if (SegmentUtils.isAutocompleteSelectOptionField(field)) {
        return (
            <UnitValueFieldAutocompleteICP
                fieldLabel={fieldLabel}
                onChange={onChange}
                valueForInput={getValueForInput() as AutocompleteValueTP}
                width={INPUT_WIDTH}
                isMultiple={isMultiple}
                valueWithLabel={props.valueWithLabel}
            />
        )
    }

    return (
        <InputCP
            label={fieldLabel}
            value={getValueForInput()}
            onChange={_.debounce(onChange, 200)}
            type={SegmentUtils.getInputTypeByUnitFieldsCount(valueFieldsCount)}
            width={INPUT_WIDTH}
            noValidation={true}
            autoFocus={props.index === 0}
        />
    )
}
