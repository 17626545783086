export enum OpportunityScheduleStatusEnum {
    SCHEDULED = 'SCHEDULED',
    COMPLETED = 'COMPLETED',
    NO_SHOW = 'NO_SHOW',
}

export enum OpportunityScheduleStatusLabelEnum {
    SCHEDULED = 'Agendada',
    COMPLETED = 'Realizada',
    NO_SHOW = 'Não compareceu',
}
