import React, { useEffect, useState } from 'react'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FastFiltersWithDrawerCP } from 'main/common/components/screen-layout/filters/fast-filters-with-drawer/FastFiltersWithDrawerCP'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { SalesAdvancedFiltersCP } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesAdvancedFiltersCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalesFiltersFormModel } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersFormModel'
import { SalesFiltersUtils } from 'main/modules/sales-funnel/components/sales-filters/inner/SalesFiltersUtils'

interface ISalesFiltersCPProps {
    formStateManager: IFormStateManager<SalesFiltersFormModel>
    onClearFilters?: () => void
    onFilter: () => void
    isLoading?: boolean
}

/**
 * Filtros da tela de Vendas.
 */
export function SalesFiltersCP(props: ISalesFiltersCPProps): JSX.Element {

    // Mantem as datas em estado aqui local pq senao nao o componente de datas nao gerencia
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    useEffect(() => setDateFilters(props.formStateManager.getFieldValue('dateRange')), [props.formStateManager.getFieldValue('dateRange')])

    /**
     * Ao mudar as datas
     */
    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
        props.formStateManager.changeFieldValue('dateRange', dates)
        props.onFilter()
    }

    return (
        <FastFiltersWithDrawerCP
            onFilter={props.onFilter}
            isLoading={props.isLoading}
            onClearFilters={() => {
                SalesFiltersUtils.clearFilters(props.formStateManager)
                props.onClearFilters?.()
            }}
            drawerContent={<SalesAdvancedFiltersCP formStateManager={props.formStateManager}/>}
            leftContent={
                <DateRangePickerCP
                    value={dateFilters}
                    onChange={onChangeDates}
                    marginBottom={10}
                    fastFilter={'all'}
                    allowClear={true}
                    showBorder={true}
                />
            }
            rightContent={
                <FlexCP>
                    <InputCP
                        placeholder={'Descrição / Cliente / ...'}
                        width={230}
                        noValidation={true}
                        marginTop={0}
                        marginBottom={0}
                        formStateManager={props.formStateManager}
                        fieldName={'searchString'}
                        allowClear={true}
                    />
                    <ButtonCP
                        marginLeft={10}
                        icon={'search'}
                        tooltip={'Buscar'}
                        type={'ghost'}
                        onClick={props.onFilter}
                        loading={props.isLoading}
                    />
                </FlexCP>
            }
        />
    )
}
