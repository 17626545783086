import { ShotDetailReportTabConfigTP } from 'main/modules/marketing/components/shot-details-report/inner/ShotDetailReportTabConfigTP'
import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'

/**
 * ABAS
 * Lista de configuracoes para abas do componente de abas de relatorios de disparo.
 *
 * @see ShotDetailsReportCP
 */
export const TAB_CONFIG_LIST_SMS: ShotDetailReportTabConfigTP[] = [
    {
        key: 'delivered',
        title: 'Recebeu',
        description: 'Veja todas as pessoas que conseguimos entregar o SMS',
        reportType: MarketingShotReportTypeEnum.DELIVERIES,
    },
    {
        key: 'not-delivered',
        title: 'Não recebeu',
        description: 'Veja para quais pessoas não conseguimos entregar o SMS',
        reportType: MarketingShotReportTypeEnum.ERRORS,
    },
]
