import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PaymentConfigRequests } from 'submodules/neritclin-sdk/services/sale/paymentconfig/PaymentConfigRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ActivePaymentMethodsResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/ActivePaymentMethodsResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { PaymentConfigRulesResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/inner/PaymentConfigRulesResponseDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
    formStateManager: IFormStateManager<any>
    paymentMethodFieldName: string
    paymentAccountFieldName: string
    disabled: boolean
    onSelectPaymentPartner: (paymentPartner: PaymentConfigRulesResponseDTO) => void
}

/**
 * Seleciona a FORMA de PAGAMENTO e depois a PAYMENT CONFIG.
 */
export function SelectPaymentConfigCP(props: ICPProps): JSX.Element {

    const [selectedPaymentConfig, setSelectedPaymentConfig] = useState<ActivePaymentMethodsResponseDTO>()

    const [activePaymentConfigs, setActivePaymentConfigs] = useState<ActivePaymentMethodsResponseDTO[]>([])
    const getEnabledPaymentMethodsRequest = useRequest<IGenericListResponseDTO<ActivePaymentMethodsResponseDTO>>()
    useEffect(onGetEnabledPaymentMethodsRequestChange, [getEnabledPaymentMethodsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        getEnabledPaymentMethodsRequest.runRequest(PaymentConfigRequests.findActivePaymentMethods())
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEnabledPaymentMethodsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEnabledPaymentMethodsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const result = getEnabledPaymentMethodsRequest.responseData!.list
        setActivePaymentConfigs(result)
    }

    /**
     * Retorna o paymentPartner correto baseado no codigo selecionado
     */
    function onSelectPaymentAccount(paymentAccountCode: number): void {

        if (!selectedPaymentConfig)
            return

        const foundPaymentAccount = selectedPaymentConfig.paymentConfigs.find((partnerConfig) => partnerConfig.paymentAccount.code === paymentAccountCode)
        if (!foundPaymentAccount)
            return

        props.onSelectPaymentPartner(foundPaymentAccount)
    }

    return (
        <RowCP>
            <ColumnCP size={12}>
                <SelectCP
                    label={'Forma de pagamento'}
                    formStateManager={props.formStateManager}
                    fieldName={props.paymentMethodFieldName}
                    disabled={props.disabled || !!selectedPaymentConfig}
                    required={true}
                    options={activePaymentConfigs.map((paymentConfig, i) => ({
                        key: `key-method-${i}`,
                        label: PaymentMethodLabelEnum[paymentConfig.paymentMethod],
                        value: paymentConfig.paymentMethod
                    }))}
                    onChange={(paymentMethod) => {

                        // Guarda a config selecionada, de acordo com a forma de pagamento escolhida
                        const _selectedPaymentConfig = activePaymentConfigs.find((config) => config.paymentMethod === paymentMethod)
                        setSelectedPaymentConfig(_selectedPaymentConfig)

                        // Obtem a primeira paymentAccount como selecionada
                        if (!ArrayUtils.isEmpty(_selectedPaymentConfig?.paymentConfigs))
                            props.formStateManager.changeFieldValue(props.paymentAccountFieldName, _selectedPaymentConfig!.paymentConfigs[0].paymentAccount.code)
                    }}
                    hint={{ type: 'tooltip', text: 'Serão mostradas apenas as suas formas de pagamento configuradas. Caso precise configurar entre em contato com a equipe de suporte.' }}
                />
            </ColumnCP>
            <>
                {
                    !!selectedPaymentConfig &&
                    <ColumnCP size={12}>
                        <SelectCP
                            label={'Meio de pagamento desejado'}
                            required={true}
                            value={props.formStateManager.getFieldValue(props.paymentAccountFieldName)}
                            formStateManager={props.formStateManager}
                            fieldName={props.paymentAccountFieldName}
                            disabled={props.disabled}
                            options={selectedPaymentConfig.paymentConfigs.map((paymentConfig, i) => ({
                                key: `key-account-${paymentConfig.paymentAccount.code}`,
                                label: paymentConfig.name,
                                value: paymentConfig.paymentAccount.code
                            }))}
                            onChange={onSelectPaymentAccount}
                        />
                    </ColumnCP>
                }
            </>
        </RowCP>
    )
}
