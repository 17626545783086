import React from 'react'
import { TagCP } from 'main/common/components/tag/TagCP'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import moment from 'moment'
import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'

interface ITagFinancialTransactionStatusCPProps {
    status: FinancialTransactionStatusEnum
    expirationDate: Date
}

/**
 * COMPONENTE Tag de status da Transacao Financeira
 */
export function TagFinancialTransactionStatusCP(props: ITagFinancialTransactionStatusCPProps): JSX.Element {

    let color: TagCPColorTP
    let text: string

    if (props.status === FinancialTransactionStatusEnum.PAID) {
        color = 'green'
        text = 'Pago'

    } else if (moment(props.expirationDate).isBefore(moment().startOf('date'))) {
        color = 'red'
        text = 'Atrasado'
    } else {
        color = 'yellow'
        text = 'Em aberto'
    }

    return (
        <TagCP
            key={props.status}
            color={color}
            content={text}
        />
    )
}
