import { TextCP } from 'main/common/components/text/TextCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import React from 'react'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TitleAndTextCP } from 'main/common/components/title-and-text/TitleAndTextCP'

interface ICPProps {
    type: SaleTypeEnum
    totalValue: number
    subtotalValue: number
    totalPaidValue?: number
    balanceValue?: number
}

/**
 * Rodape do PDF
 */
export function SaleDetailsPdfFooterCP(props: ICPProps): JSX.Element {

    return (
        <>
            <FlexCP flexDirection={'column'} alignItems={'flex-end'} className={'footer-1'} marginBottom={30}>
                <TitleAndTextCP title={'Subtotal:'} text={MaskUtils.applyMoneyMask(props.subtotalValue)}/>
                <TitleAndTextCP title={'Desconto:'} text={`- ${MaskUtils.applyMoneyMask((props.subtotalValue - props.totalValue))}`}/>
                <TitleAndTextCP title={'Total:'} text={MaskUtils.applyMoneyMask(props.totalValue)}/>

                {
                    props.type === SaleTypeEnum.SALE &&
                    <>
                        <TitleAndTextCP title={'Total já pago:'} text={MaskUtils.applyMoneyMask(props.totalPaidValue)} marginTop={10}/>
                        <TitleAndTextCP title={'Valor à receber:'} text={MaskUtils.applyMoneyMask(props.balanceValue)}/>
                    </>
                }
            </FlexCP>

            {
                props.type === SaleTypeEnum.SALE
                    ?
                    <TextCP text={'Obrigado por comprar conosco.'}/>
                    :
                    <TextCP text={'Obrigado. Aguardamos o seu retorno.'}/>
            }
        </>
    )
}
