export enum SessionStatusEnum {
    AWAITING = 'AWAITING', /** Se a sessão existe para aquele cliente e esta 'aguardando' ser consumida. Não confundir com o presence confirmation */
    SCHEDULED = 'SCHEDULED', /** Atendimento Agendado. */
    CHECK_IN_DONE = 'CHECK_IN_DONE',
    IN_PROGRESS = 'IN_PROGRESS',
    CONCLUDED = 'CONCLUDED', /** Atendido. */
    NO_SHOW = 'NO_SHOW' /** Não compareceu. */
}

export enum SessionStatusLabelEnum {
    AWAITING = 'Aguardando',
    SCHEDULED = 'Agendado',
    CHECK_IN_DONE = 'Check-in realizado',
    IN_PROGRESS = 'Em atendimento',
    CONCLUDED = 'Realizado',
    NO_SHOW = 'Não compareceu'
}
