import { BankAccountModalityEnum } from 'main/modules/financial/enums/BankAccountModalityEnum'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'

/**
 * UTILITARIOS de contas bancarias
 */
export const BankAccountUtils = {

    getBankAccountModalityLabel(modality: BankAccountModalityEnum): string {

        switch (modality) {

            case BankAccountModalityEnum.CURRENT_ACCOUNT:
                return 'Conta Corrente'

            case BankAccountModalityEnum.INVESTMENT:
                return 'Investimento'

            case BankAccountModalityEnum.SAFE:
                return 'Cofrinho'

            default:
                return ''
        }

    },

    getBankAccountTypeLabel(type: BankAccountTypeEnum): string {

        switch (type) {

            case BankAccountTypeEnum.CREDIT_CARD:
                return 'Cartão de Crédito'

            case BankAccountTypeEnum.BANK_ACCOUNT:
                return 'Conta Bancária'

            case BankAccountTypeEnum.PAYMENT_ACCOUNT:
                return 'Meio de Pagamento'

            default:
                return ''
        }

    },

}
