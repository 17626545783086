/* eslint-disable */
import { Radio } from 'antd'
import { StyledComponentProps } from 'main/config/theme/Theme'
import React from 'react'
import styled, { CSSProperties } from 'styled-components'

export interface IRadioOptionProps {
    value: any
    icon?: JSX.Element
    content: string | JSX.Element
    disabled?: boolean
}

interface IRadioGroupCPProps {
    options: IRadioOptionProps[]
    type?: 'radio' | 'button'
    direction?: 'vertical' | 'horizontal'
    size?: 'large' | 'default' | 'small'
    selected: any
    onChange: (value: any) => void
}

/**
 * Radio group do ant estilizado para o projeto usando o tipo button
 *
 * @author Lucas Rosa
 * @todo 03/11/2020 - Corrigir os tipos any
 */
export function RadioGroupCPOLD(props: IRadioGroupCPProps): JSX.Element {
    const radioStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
    }
    return (
        <RadioGroupSCP
            value={props.selected}
            onChange={(e: any) => props.onChange(e.target.value)}
            size={props.size}
            style={props.direction === 'vertical' ? radioStyle : {}}
        >

            {props.options.map(opt =>
                (props.type === 'button' ?
                    <RadioButtonSCP key={`opt-radio-group-${opt.value}`} value={opt.value} disabled={opt.disabled}>
                        {opt.icon}
                        {' '}
                        {opt.content}
                    </RadioButtonSCP>
                    :
                    <RadioSCP key={`opt-radio-group-${opt.value}`} value={opt.value} disabled={opt.disabled}>
                        {opt.icon}
                        {' '}
                        {opt.content}
                    </RadioSCP>))}
        </RadioGroupSCP>
    )
}

const RadioGroupSCP = styled(Radio.Group)`
    &.ant-radio-group {
        display: flex;
    }
    &.ant-radio-group-large {
        span{
            font-size: 12px;
        }
    }

`
const RadioButtonSCP = styled(Radio.Button)`
    color: ${(props: StyledComponentProps) => props.theme.darkerGray};
    font-weight: bold;
    &:active, &:focus, &:hover{
       color: #fff!important;
       background: ${(props: StyledComponentProps) => props.theme.primaryColor};
       border: none;
       box-shadow: none;
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #fff!important;
        border: none;
        background: ${(props: StyledComponentProps) => props.theme.primaryColor};
        box-shadow: none;
    }
  
    &.ant-radio-button-wrapper {
        text-align: center;        
    }
`
const RadioSCP = styled(Radio)`
     .ant-radio-checked .ant-radio-inner{
         
        border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        &:hover{
            border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
    }
    .ant-radio-inner::after{
        background-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
    }
    span.ant-radio + *{
        color: ${(props: StyledComponentProps) => props.theme.darkGray};
        font-family: ${(props: StyledComponentProps) => props.theme.textFontFamily};
        font-weight: ${(props: StyledComponentProps) => props.theme.textFontWeights.regular};
        font-size: ${(props: StyledComponentProps) => props.theme.fontSizes.small}
    }  
    .ant-radio:hover .ant-radio-inner{
        border-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
    }
`
