/** Labels para cores utilizados pelo ant. */
import { TagCPColorTP } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCPColorTP'

type _AntTagColorTP = 'magenta' | 'red' | 'volcano' | 'orange' | 'gold' | 'lime' | 'green' | 'cyan' | 'blue' | 'geekblue' | 'purple'

/**
 * Funcoes auxiliares para componente de visualizacao de conteudo em forma de 'tag'.
 */
export const TagCpUtils = {

    /** Analiza valor para definicao da cor de fundo do componente & retorna valor apropriado para uso no componente do framework ant.d. */
    getParsedColor(color?: TagCPColorTP | string): _AntTagColorTP | string {
        switch (color) {
            case 'pink':
                return 'magenta'
            case 'darkOrange':
                return 'volcano'
            case 'yellow':
                return 'gold'
            case 'lightGreen':
                return 'lime'
            case 'lightBlue':
                return 'cyan'
            case 'navyBlue':
                return 'geekblue'
            case 'red':
            case 'orange':
            case 'green':
            case 'purple':
            case 'blue':
            default:
                return !!color ? color : 'blue'
        }
    }
}
