import React from 'react'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { CancelationReasonTypeEnum } from 'main/modules/sales-funnel/services/cancelation-reason/enums/CancelationReasonTypeEnum'
import { CancelationReasonRequests } from 'main/modules/sales-funnel/services/cancelation-reason/CancelationReasonRequests'

interface IAutoCompletePickerOpportunitySourceCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    type: CancelationReasonTypeEnum
}

/**
 * Seletor de motivos de cancelamento.
 */
export function AutoCompleteCancelationReasonCP<FModelTP extends FormModel = any>(props: IAutoCompletePickerOpportunitySourceCPProps<FModelTP>): JSX.Element {

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={CancelationReasonRequests.search}
            customFilters={{ type: props.type }}
            showOptionsOnLoad={true}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
