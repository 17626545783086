import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { StartOrFinishAttendanceRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/StartOrFinishAttendanceRequestDTO'
import { ToogleCheckinRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/ToogleCheckinRequestDTO'

export class SessionsRequests {

	public static CONTROLLER_ROOT = 'sessions'
	public static TOOGLE_CHECKIN_EP = 'toogle-checkin'
	public static START_ATTENDANCE_EP = 'start-attendance'
	public static CANCEL_STARTED_ATTENDANCE_EP = 'cancel-started-attendance'
	public static FINISH_ATTENDANCE_EP = 'finish-attendance'
	public static SESSION_STATUS_HISTORY = 'session-status-history/:sessionCode'

	static startAttendance = (dto: StartOrFinishAttendanceRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SessionsRequests.CONTROLLER_ROOT}/${SessionsRequests.START_ATTENDANCE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static cancelStartedAttendance = (dto: StartOrFinishAttendanceRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SessionsRequests.CONTROLLER_ROOT}/${SessionsRequests.CANCEL_STARTED_ATTENDANCE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static finishAttendance = (dto: StartOrFinishAttendanceRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SessionsRequests.CONTROLLER_ROOT}/${SessionsRequests.FINISH_ATTENDANCE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static toogleCheckin = (dto: ToogleCheckinRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${SessionsRequests.CONTROLLER_ROOT}/${SessionsRequests.TOOGLE_CHECKIN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

}
