import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { WidgetsRowCP } from 'main/modules/dashboard/components/widgets-row/WidgetsRowCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { WidgetSessionPerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSessionPerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
    dateFilters?: IDateRangeFilter
}

/**
 * Widgets de indicadores de atendimento.
 */
export function WidgetsRowAttendancePerformanceICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP textSize={'normal'} marginLeft={10} titleIcon={'heart'}>
                Performance de atendimentos
            </TitleCP>

            <WidgetsRowCP marginBottom={20}>

                <CardDashboardWidgetCP
                    title={'Clientes atendidos'}
                    help={'Número de clientes atendidos no período'}
                    footerTitle={'Taxa de revenda'}
                    footerHelp={'Taxa entre o número de vendas realizas pela número de clientes atendidos'}
                    secondCalc={'ratioBetweenSecondValueAndMainValue'}
                    requestConfigGetter={
                        DashboardRequests.getSessionPerformanceWidgets(
                            WidgetSessionPerformanceEnum.CUSTOMERS_WITH_EVOLUTION,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />

                <CardDashboardWidgetCP
                    title={'Procedimentos realizados'}
                    help={'Número de procedimetnos realizados no período'}
                    requestConfigGetter={
                        DashboardRequests.getSessionPerformanceWidgets(
                            WidgetSessionPerformanceEnum.TREATMENTS_CONCLUDED,
                            { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                        )
                    }
                />

            </WidgetsRowCP>
        </>
    )
}
