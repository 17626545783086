import React from 'react'
import { TargetConfigTP } from 'main/modules/auth/services/dtos/types/TargetConfigTP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'

interface ICPProps {
    fields?: TargetConfigTP
}

export function FranchiseTargetConfigFieldsICP(props: ICPProps): JSX.Element {

    if (!props.fields)
        return <></>

    return (
        <>
            <>
                <TitleCP underLine={true} textSize={'normal'} marginBottom={40}>Metas de Lead</TitleCP>
                <InputCP label={'Leads planejados por dia'} type={'number'} value={props.fields.lead!.plannedForToday} onChange={(val) => props.fields!.lead!.plannedForToday = +val}/>
                <InputCP label={'Leads captados por dia'} type={'number'} value={props.fields.lead!.registeredForToday} onChange={(val) => props.fields!.lead!.registeredForToday = +val}/>
            </>
            <>
                <TitleCP underLine={true} textSize={'normal'} marginBottom={40} marginTop={40}>Metas de Avaliação</TitleCP>
                <InputCP label={'Avaliações planejadas por dia'} type={'number'} value={props.fields.evaluation!.plannedForToday} onChange={(val) => props.fields!.evaluation!.plannedForToday = +val}/>
                <InputCP label={'Avaliações agendada hoje para hoje'} type={'number'} value={props.fields.evaluation!.scheduledForToday} onChange={(val) => props.fields!.evaluation!.scheduledForToday = +val}/>
                <InputCP label={'Avaliações agendada hoje para amanhã'} type={'number'} value={props.fields.evaluation!.scheduledForToomorrow} onChange={(val) => props.fields!.evaluation!.scheduledForToomorrow = +val}/>
                <InputCP label={'Avaliações agendada hoje para + de 48hrs'} type={'number'} value={props.fields.evaluation!.scheduledForMoreThan48hrs} onChange={(val) => props.fields!.evaluation!.scheduledForMoreThan48hrs = +val}/>
            </>
            <>
                <TitleCP underLine={true} textSize={'normal'} marginBottom={40} marginTop={40}>Metas de Follow-up</TitleCP>
                <InputCP label={'Follow-ups por dia'} type={'number'} value={props.fields.followup!.plannedForToday} onChange={(val) => props.fields!.followup!.plannedForToday = +val}/>
            </>
            <>
                <TitleCP underLine={true} textSize={'normal'} marginBottom={40} marginTop={40}>Metas de Venda</TitleCP>
                <InputCP label={'Pacotes por venta'} type={'number'} value={props.fields.sale!.packagePerSale} onChange={(val) => props.fields!.sale!.packagePerSale = +val}/>
                <InputCP label={'Desconto médio concedido'} type={'number'} value={props.fields.sale!.averageDiscountRatio} onChange={(val) => props.fields!.sale!.averageDiscountRatio = +val}/>
                <InputCP label={'Revenda de Cabine'} type={'number'} value={props.fields.sale!.resellInApplicationRoom} onChange={(val) => props.fields!.sale!.resellInApplicationRoom = +val}/>
            </>
            <>
                <TitleCP underLine={true} textSize={'normal'} marginBottom={40} marginTop={40}>Metas de Sessão</TitleCP>
                <InputCP label={'Clientes agendados por dia'} type={'number'} value={props.fields.session!.plannedCustomerToday} onChange={(val) => props.fields!.session!.plannedCustomerToday = +val}/>
                <InputCP label={'Tratamentos agendados por dia'} type={'number'} value={props.fields.session!.plannedTreatmentToday} onChange={(val) => props.fields!.session!.plannedTreatmentToday = +val}/>
                <InputCP label={'Clientes realizados por dia'} type={'number'} value={props.fields.session!.customerWithEvolution} onChange={(val) => props.fields!.session!.customerWithEvolution = +val}/>
                <InputCP label={'Tratamentos realiados por dia'} type={'number'} value={props.fields.session!.treatmentsConcluded} onChange={(val) => props.fields!.session!.treatmentsConcluded = +val}/>
            </>
        </>
    )

}