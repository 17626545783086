import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'

/**
 * UTILITARIOS
 */
export const OpportunityUtils = {

    /** Retorna texto para exibicao de 01 TIPO de oportunidade. */
    getOpportunityTypeLabel(opportunity: OpportunityTypeEnum): string {
        switch (opportunity) {
            case OpportunityTypeEnum.LEAD:
                return 'Lead'
            case OpportunityTypeEnum.EVALUATION:
                return 'Avaliação'
            case OpportunityTypeEnum.FOLLOWUP:
                return 'Follow Up'
            default:
                return ''
        }
    },

    /** Retorna texto para exibicao de 01 STATUS de oportunidade. */
    getOpportunityStatusLabel(group: OpportunityStatusEnum): string {
        switch (group) {
            case OpportunityStatusEnum.OPEN:
                return 'Aberta'
            case OpportunityStatusEnum.CONCLUDED:
                return 'Concluída'
            case OpportunityStatusEnum.LOST:
                return 'Perdida'
            default:
                return ''
        }
    },

}
