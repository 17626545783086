import React, { useEffect, useRef, useState } from 'react'
import GoogleMap, { BootstrapURLKeys, NESWBounds } from 'google-map-react'
import { fitBounds } from 'google-map-react/utils'
import { GoogleErrorNotificationICP } from './inner/components/GoogleErrorNotificationICP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { CoordinatesEnum } from 'main/common/components/map/inner/CoordinatesEnum'
import { SystemConfig } from 'main/config/SystemConfig'
import { MapPinTP } from 'main/common/components/map/inner/MapPinTP'
import { MapCpUtils } from 'main/common/components/map/inner/MapCpUtils'
import { LoadingOverlayCP } from 'main/common/components/loading/loading-overlay/LoadingOverlayCP'
import { PinICP } from 'main/common/components/map/inner/components/PinICP'
import { styled } from 'main/config/theme/styledWithTheme'

type GoogleLoadingStateTP = 'untouched' | 'loading' |'loaded' | 'failed'
type TimeoutTP = number     // ALIAS: Explicita que nao eh 01 numero qualquer mas sim o id de 01 temporizador

const DEFAULT_CENTER_LATITUDE = MathUtils.getMidValue(CoordinatesEnum.BR_MIN_LAT, CoordinatesEnum.BR_MAX_LAT)
const DEFAULT_CENTER_LONGITUDE = MathUtils.getMidValue(CoordinatesEnum.BR_MIN_LON, CoordinatesEnum.BR_MAX_LON)
const DEFAULT_ZOOM = 4

const GOOGLE_TIMEOUT = 10_000   // Tempo maximo a se aguardar pelo carregamento da api do google

const CONFIG_GOOGLE_MAP: BootstrapURLKeys = {
    key: SystemConfig.getInstance().googleApiKey,
    language: 'pt-BR',
    region: 'BR',
} as const

type WrapperScpPropsTP = { height: number | string }

interface IMapsCPProps extends Partial<WrapperScpPropsTP> {
    pins: MapPinTP[]
    isLoading?: boolean
    defaultZoom?: number
    initialLatitude?: number
    initialLongitude?: number
}

/**
 * COMPONENTE
 * Exibicao do mapa do google.
 */
export function MapCP(props: IMapsCPProps): JSX.Element {

    const [zoom, setZoom] = useState<number>(props.defaultZoom ?? DEFAULT_ZOOM)
    const [centerLat, setCenterLatitude] = useState<number>(props.initialLatitude ?? DEFAULT_CENTER_LATITUDE)
    const [centerLon, setCenterLongitude] = useState<number>(props.initialLongitude ?? DEFAULT_CENTER_LONGITUDE)

    const [bounds, setBounds] = useState<NESWBounds>()
    const [width, setWidth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)

    const [googleLoadingTimeoutId, setGoogleLoadingTimeoutId] = useState<TimeoutTP>()
    const [googleLoadingState, setGoogleLoadingState] = useState<GoogleLoadingStateTP>('untouched')

    const mapRef = useRef<HTMLDivElement>(null)

    useEffect(init, [])
    useEffect(setDimensions, [mapRef.current])
    useEffect(updateCenter, [height, width, bounds])
    useEffect(() => setBounds(MapCpUtils.getBounds(props.pins)), [props.pins])

    function init(): void {

        setGoogleLoadingState('loading')

        /**
         * Se ate o tempo limite para carregamento ser atingido este temporizador nao tiver sido
         * cancelado consideramos que houve falha por 'timeout'. Neste caso atualizamos o status
         * de carregameto para FALHA.
         */

        const timeoutId = setTimeout(() => endLoadingTimeout(false), GOOGLE_TIMEOUT)
        setGoogleLoadingTimeoutId(timeoutId)
    }

    function onGoogleApiIsLoaded(): void {
        endLoadingTimeout(true)
        updateCenter()
    }

    function endLoadingTimeout(success: boolean): void {
        clearTimeout(googleLoadingTimeoutId)
        setGoogleLoadingTimeoutId(undefined)
        setGoogleLoadingState(success ? 'loaded' : 'failed')
    }

    function setDimensions(): void {
        setWidth(mapRef.current?.offsetWidth ?? width)
        setHeight(mapRef.current?.offsetHeight ?? height)
    }

    function updateCenter(): void {
        if (bounds) {
            const nextBounds = fitBounds(bounds, { height, width })
            setCenterLatitude(nextBounds.center.lat)
            setCenterLongitude(nextBounds.center.lng)
            setZoom(nextBounds.zoom)
        }
    }

    const isLoading = (googleLoadingState === 'loading' || !!props.isLoading)

    const wrapperHeight = (typeof props.height === 'number')
        ? `${props.height}px`
        : (typeof props.height === 'string') ? props.height : '100%'

    return (
        <WrapperSCP height={wrapperHeight} ref={mapRef}>
            <LoadingOverlayCP show={isLoading}/>

            <GoogleMap
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={onGoogleApiIsLoaded}
                bootstrapURLKeys={CONFIG_GOOGLE_MAP}
                center={{ lat: centerLat, lng: centerLon }}
                zoom={zoom}
            >
                {
                    props.pins.map(pin => (
                        <PinICP
                            key={`marker-prs-${pin.lat}${pin.lng}`}
                            lat={pin.lat}
                            lng={pin.lng}
                            text={pin.text}
                            color={pin.color}
                            onClick={pin.onClick}
                            popOverTitle={pin.popOverTitle}
                            popOverContent={pin.popOverContent}
                        />
                    ))
                }
            </GoogleMap>

            <GoogleErrorNotificationICP show={googleLoadingState === 'failed'}/>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<WrapperScpPropsTP>`
    height: ${props => props.height};
    width: 100%;
    position: relative;
    overflow: hidden;
`
