import _ from 'lodash'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { FormSessionAnswerCP, SessionFormActionTP } from 'main/modules/scheduler/components/form-session-answer/FormSessionAnswerCP'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CollapseSessionFormHistoryCP } from 'main/modules/scheduler/components/collapse-session-form-history/CollapseSessionFormHistoryCP'
import { SessionFormModalHeaderICP } from 'main/modules/scheduler/components/modal-session-form/inner/SessionFormModalHeaderICP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { TitleCP } from 'main/common/components/title/TitleCP'

interface IModalSessionFormCPProps {
    visible: boolean
    session: ISessionResponseDTO
    nextSessions?: ISessionResponseDTO[]
    onCancel: () => void
    onSave: () => void
    initTime?: Date
}

/**
 * Componente de modal para poder evoluir sessões
 */
export function ModalSessionFormCP(props: IModalSessionFormCPProps): JSX.Element {

    const [initTime, setInitTime] = useState<Date>()
    const [isSessionHistoryVisible, setIsSessionHistoryVisible] = useState<boolean>(false)

    const [currentSessionToConclude, setCurrentSessionToConclude] = useState<ISessionResponseDTO>(props.session)
    const [allValidSessionsToConclude, setAllValidSessionsToConclude] = useState<ISessionResponseDTO[]>([])
    const [currentIndex, setCurrentIndex] = useState<number>()
    const [lastValidIndex, setLastValidIndex] = useState<number>()
    const [showNextButton, setShowNextButton] = useState<boolean>(false)

    useEffect(init, [props.visible])

    /**
     * Faz as verificações para descobrir quantas das sessões totais são válidas para evolução em sequência
     */
    function init(): void {

        if (!props.visible)
            return

        setInitTime(props.initTime)
        setCurrentSessionToConclude(props.session)
        if (!props.nextSessions || _.isEmpty(props.nextSessions))
            return

        const _allValidSessionsToConclude: ISessionResponseDTO[] = []
        props.nextSessions.map(session => {
            if (session.status === SessionStatusEnum.CONCLUDED)
                return
            _allValidSessionsToConclude.push(session)
        })

        setAllValidSessionsToConclude(_allValidSessionsToConclude)
        if (_allValidSessionsToConclude.length === 1)
            return

        setShowNextButton(true)
        setCurrentIndex(_.indexOf(_allValidSessionsToConclude, props.session))
        setLastValidIndex(_allValidSessionsToConclude.length - 1)
    }

    /**
     * Obtem a proxima sessao e atualiza a atual.
     */
    function onSaveAnswers(sessionFormActionType: SessionFormActionTP): void {

        // Marca como concluida a sessao para atualizar a tela anterior e reseta o tempo inicial
        currentSessionToConclude.status = SessionStatusEnum.CONCLUDED
        setInitTime(new Date())
        if (sessionFormActionType === 'save') {
            props.onSave()
            return
        }

        // Uma situação provável é o currentIndex ser === 0. Se aqui abaixo for usada a notação padrão ( !currentIndex ),
        // considerando que o intuito é prevenir o undef, no momento em que o currentIndex for === 0, vai gerar um falso positivo
        if (currentIndex === undefined || !lastValidIndex)
            return

        if (currentIndex < lastValidIndex) {
            setCurrentIndex(currentIndex + 1)
            setCurrentSessionToConclude(allValidSessionsToConclude[currentIndex + 1])
        }

        // como o setCurrentIndex precisa ser feito dentro do if acima, aqui vai precisa desse "- 1"
        if (currentIndex === (lastValidIndex - 1))
            setShowNextButton(false)
    }

    return (
        <ModalCP
            title={props.session.customer ? `Evoluir sessão de ${props.session.customer.name}` : 'Evoluir sessão'}
            width={'98%'}
            top={10}
            visible={props.visible}
            onCancel={props.onCancel}
            noFooter={true}
        >
            <FlexCP justifyContent={'space-between'}>

                <LeftWrapperSCP>
                    <SessionFormModalHeaderICP
                        session={currentSessionToConclude}
                        onShowHistoryClick={() => setIsSessionHistoryVisible(!isSessionHistoryVisible)}
                        isHistoryVisible={isSessionHistoryVisible}
                        initTime={initTime}
                    />

                    <FormSessionAnswerCP
                        showNextButton={showNextButton}
                        sessionCode={currentSessionToConclude.code}
                        sessionStatus={currentSessionToConclude.status}
                        onSaveAnswers={onSaveAnswers}
                        initTime={initTime ?? new Date()}
                        appearance={{ fieldColumnSize: isSessionHistoryVisible ? 24 : 12 }}
                    />
                </LeftWrapperSCP>

                <ConditionalRenderCP shouldRender={isSessionHistoryVisible}>
                    <RightWrapperSCP>
                        <TitleCP underLine={true} textSize={'normal'}>
                            Histórico de sessões já realizadas
                        </TitleCP>

                        <CollapseSessionFormHistoryCP personCode={props.session.customer.code}/>
                    </RightWrapperSCP>
                </ConditionalRenderCP>
            </FlexCP>
        </ModalCP>
    )
}

const LeftWrapperSCP = styled.div`
  flex: 1;
`

const RightWrapperSCP = styled.div`
  width: 50%;
  border-left: dashed 2px #ecececad;
  margin-left: 20px;
  padding-left: 20px;
`
