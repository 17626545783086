import React from 'react'
import * as _ from 'lodash'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { WorkScheduleTypeEnum } from 'main/modules/scheduler/enums/WorkScheduleTypeEnum'
import { IAttendantDisplay } from 'main/modules/scheduler/interfaces/IAttendantDisplay'
import { IUserAttendancesResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendancesResponseDTO'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import { SessionUtils } from 'main/modules/scheduler/utils/SessionUtils'
import moment, { Moment } from 'moment'
import { StringUtils } from 'main/common/utils/StringUtils'
import { IBusinessTime } from 'main/modules/scheduler/interfaces/IBusinessTime'
import { IAppointmentDetails } from 'main/modules/scheduler/interfaces/IAppointmentDetails'
import { EventApi } from '@fullcalendar/core/api/EventApi'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { TimeBaseEnum } from 'main/common/enums/TimeBaseEnum'
import { IFullCalendarEventDropInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventDropInfo'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { SchedulerEventExtraTitleCP } from 'main/modules/scheduler/components/scheduler-event-extra-title/SchedulerEventExtraTitleCP'
import { SchedulerEventExtraDescriptionCP } from 'main/modules/scheduler/components/scheduler-event-extra-description/SchedulerEventExtraDescriptionCP'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { IFullCalendarExtendedProps } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarExtendedProps'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { ScheduleRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleRequestDTO'
import { SessionScheduleRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/SessionScheduleRequestDTO'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IBusinessHoursFranchiseResponseDTO } from 'main/modules/auth/services/dtos/response/IBusinessHoursFranchiseResponseDTO'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { OpportunityScheduleStatusEnum, OpportunityScheduleStatusLabelEnum } from 'submodules/neritclin-sdk/services/opportunity-schedule/enums/OpportunityScheduleStatusEnum'
import { ThemeProjectCommon } from 'submodules/neritclin-components-ui/theme/project/white-labels/ThemeProjectCommon'

/**
 * Utilitário de eventos do calendário
 */
export const ScheduleEventUtils = {

    /**
     * Configura os horarios bloqueados da clinica na visao semanal, dessa forma teremos que interar na configuracao da clinica.
     * Na visa por usuario nao eh necessario pois o proprio calendar bloqueia.
     */
    mountUnavailableEventsDataByFranchise(dateRange?: IDateRangeFilter): IFullCalendarEvent[] {

        if (!dateRange?.beginDate || !dateRange?.endDate)
            return []

        const franchiseHours: IBusinessHoursFranchiseResponseDTO[] = AppStateUtils.getCurrentFranchise()!.businessHours

        const unavailableEvents: IFullCalendarEvent[] = []

        // Itera nos dias da semana
        let currentDate = dateRange.beginDate
        while (DateUtils.getDiff(TimeBaseEnum.DAY, currentDate, dateRange.endDate) >= 0) {

            let foundConfigHour = franchiseHours.find((hourConfig) => hourConfig.weekDay === currentDate.getDay())
            if (!foundConfigHour) {
                // Se nao encontrou signifca que a clinica esta fechada o dia inteira. Simula o horario fechado
                foundConfigHour = {
                    weekDay: currentDate.getDay(),
                    beginTime: '00:00',
                    endTime: '00:00'
                }
            }

            // Monta os 2 blocoes de horario. De meia noite ate estar aberto
            const openHourEndTime = DateUtils.setTime(currentDate, DateUtils.getHourAndMinutes(foundConfigHour.beginTime).hour, DateUtils.getHourAndMinutes(foundConfigHour.beginTime).minutes)

            // Monta os 2 blocoes de horario. Do horario que fecha ate meia noite
            const closeHourStartTime = DateUtils.setTime(currentDate, DateUtils.getHourAndMinutes(foundConfigHour.endTime).hour, DateUtils.getHourAndMinutes(foundConfigHour.endTime).minutes)

            unavailableEvents.push(
                {
                    id: WorkScheduleTypeEnum.UNAVAILABLE,
                    title: 'Clínica Fechada',
                    start: moment(DateUtils.setTime(currentDate, 0, 0)).toISOString(true),
                    end: moment(openHourEndTime).toISOString(true),
                    className: 'unavailable',
                },
                {
                    id: WorkScheduleTypeEnum.UNAVAILABLE,
                    title: 'Clínica Fechada',
                    start: moment(closeHourStartTime).toISOString(true),
                    end: moment(DateUtils.setTime(currentDate, 24, 0)).toISOString(true),
                    className: 'unavailable',
                },
            )

            currentDate = DateUtils.add(currentDate, 1, TimeBaseEnum.DAY)
        }


        return unavailableEvents
    },

    /**
     * Obtem os horarios de todos os usuarios que nao possuem sessao atrelada, ou seja, eh horario bloqueado.
     */
    mountUnavailableEventsDataByUser(userAttendances: IUserAttendancesResponseDTO[]): IFullCalendarEvent[] {

        const unavailableEvents: IFullCalendarEvent[] = []

        userAttendances.forEach((userAtt) => {
            userAtt.userAttendancesScheduled.forEach((userAttScheduled) => {

                // Obtem apenas as que nao possuem sessao
                if (userAttScheduled.sessions)
                    return

                unavailableEvents.push({
                    id: WorkScheduleTypeEnum.UNAVAILABLE,
                    title: userAttScheduled.description ?? 'Indisponível',
                    resourceId: `${userAtt.user.code}`,
                    start: moment(userAttScheduled.beginDate).toISOString(true),
                    end: moment(userAttScheduled.endDate).toISOString(true),
                    className: 'unavailable',
                    extendedProps: {
                        code: [userAttScheduled.code], // Código do agendamento
                        type: 'unavailable',
                    }
                })
            })
        })

        return unavailableEvents
    },

    /**
     * Monta os horarios de avaliacoes.
     */
    mountEvaluationsEventsData(evaluations: IOpportunityEvaluationScheduleResponseDTO[]): IFullCalendarEvent[] {

        return evaluations.map(evaluation => ({
            id: evaluation.status,
            resourceId: `${evaluation.userResponsible.code}`,
            title: ScheduleEventUtils.getEvaluationTitle(evaluation.personCustomer.name, evaluation.status, evaluation.presenceConfirmation),
            start: moment(evaluation.beginDate).toISOString(true),
            end: moment(evaluation.endDate).toISOString(true),
            borderColor: 'transparent',
            backgroundColor: ThemeProjectCommon.schedulerEvaluation,
            extendedProps: {
                code: [evaluation.code], // Código das sessões que estão sendo agrupadas nesse agendamento
                comments: evaluation.notes, // Itera nos comentários da sessão agrupando em uma string, separando por quebra de linha. Alem disso remove os duplicados
                type: 'evaluation',
                presenceConfirmation: evaluation.presenceConfirmation,
            }
        }))
    },

    /**
     * Monta o titulo de um evento de avaliacao.
     */
    getEvaluationTitle(customerName: string, evaluationStatus: OpportunityScheduleStatusEnum, isPresenceConfirmed: boolean): string {

        let details = `${OpportunityScheduleStatusLabelEnum[evaluationStatus]}`
        // Se nao estiver concluido e tiver marcado como presenca confirmada, mostra na tela
        if (isPresenceConfirmed && evaluationStatus !== OpportunityScheduleStatusEnum.COMPLETED)
            details = details.concat(' - Confirmada')

        return `Avaliação: ${customerName} (${details})`
    },

    /**
     * Obtem todas as sessoes de um atendimento de usuario.
     */
    getUserScheduledWithSessions(userAttendancesResponseDTO: IUserAttendancesResponseDTO): ISessionResponseDTO[] {
        const userAttendancesSessions: ISessionResponseDTO[] = []
        userAttendancesResponseDTO.userAttendancesScheduled.forEach((userAttendancesScheduled) => {
            if (userAttendancesScheduled.sessions)
                userAttendancesSessions.push(...userAttendancesScheduled.sessions)
        })

        return userAttendancesSessions
    },

    /**
     * Agrupa os AGENDAMENTOS de um cliente que possua SESSÕES SEGUIDAS.
     */
    groupNearestAppointmentsByCustomer(userAttendancesResponseDTO: IUserAttendancesResponseDTO, isWeekView: boolean): IFullCalendarEvent[] {

        let customerName: OrUndefTP<string> = undefined // Nome do cliente atual
        let nearSessions: ISessionResponseDTO[] = [] // Agrupa os agendamentos próximos que sao da mesma pessoa
        const resultSessions: IFullCalendarEvent[] = [] // Salva os agendamentos finais

        const sessions = ScheduleEventUtils.getUserScheduledWithSessions(userAttendancesResponseDTO)
            .sort((sesA, sesB) => moment(sesA.schedule?.endDate).unix() - moment(sesB.schedule?.endDate).unix())

        for (const session of sessions) {

            // Enquanto for a mesma pessoa e os horários sequenciais, agrupa os agendamentos
            if (!customerName || (customerName === session.customer.name && session.schedule?.beginDate === nearSessions[nearSessions.length - 1].schedule?.endDate)) {
                nearSessions.push(session)
                customerName = session.customer.name
                continue
            }

            // Cria evento
            const eventDisplay = this.mountAppointmentsEventData(nearSessions, userAttendancesResponseDTO, isWeekView, customerName)
            resultSessions.push(eventDisplay)
            customerName = session.customer.name
            nearSessions = [session]
        }

        // Pode haver um ultimo agendamento que foi agrupado no nearAppointments, se tiver coloca na lista final
        if (!_.isEmpty(nearSessions)) {
            const eventDisplay = this.mountAppointmentsEventData(nearSessions, userAttendancesResponseDTO, isWeekView, customerName)
            resultSessions.push(eventDisplay)
        }

        return resultSessions
    },

    /**
     * Monta os dados do atendente que sao mostrados na agenda.
     */
    getAttendantConfig(userAttendancesResponseDTO: IUserAttendancesResponseDTO): IAttendantDisplay {
        console.log("Montando configuração do atendenten")
        // O que sera retornado em comum
        const id = userAttendancesResponseDTO.user.code.toString()
        const title = StringUtils.getFirstAndSecondName(userAttendancesResponseDTO.user.name)

        // Obtem os horarios disponiveis do profissional
        const userWorkScheduleAvailables = userAttendancesResponseDTO.workSchedule.filter(workSchedule => workSchedule.type === WorkScheduleTypeEnum.AVAILABLE)

        // Se nao tiver nenhum horario disponivel
        // Necessário para que os horário vazios apareçam bloqueados
        if (!userWorkScheduleAvailables) {
            return {
                id,
                title,
                businessHours: [{
                    daysOfWeek: [],
                    startTime: '00:00',
                    endTime: '00:00',
                }]
            }
        }

        // Itera sobre os horarios disponivels do atendente e monta por dia da semana
        const businessHours: IBusinessTime[] = userWorkScheduleAvailables.map((availables) => ({
            daysOfWeek: [availables.weekDay],
            startTime: availables.beginTime,
            endTime: availables.endTime,
        }))
        return { id, title, businessHours}
    },

    /**
     * Verifica se o horário selecionado está dentro do horário de trabalho do profissional.
     */
    checkBusinessHours(selectedDate: Date, selectedUserCode: number, resourcesList: IAttendantDisplay[]): boolean {

        // Obtem o usuario selecionado pela lista de recursos (colunas do calendario)
        const selectedUserData = resourcesList.find((resource) => +resource.id == selectedUserCode)

        // Obtem o horario de trabalho do dia da semana correto
        const userBusinessHours = selectedUserData?.businessHours.find((bHour) => bHour.daysOfWeek?.includes(selectedDate.getDay()))

        // Verifica se o usuario possui todas as configuracoes necessarios
        if (!userBusinessHours || !userBusinessHours.startTime || !userBusinessHours.endTime || _.isEmpty(userBusinessHours.daysOfWeek))
            return false

        if (!userBusinessHours.daysOfWeek!.includes(selectedDate.getDay()))
            return false

        // Agora verifica se o horario selecionado esta dentro do hoario de trabalho do profissional
        const selectedTime = moment(`${selectedDate.getHours()}:${selectedDate.getMinutes()}`, DateFormatEnum.TIME_H_M)
        const userBusinessStartTime = moment(userBusinessHours.startTime, DateFormatEnum.TIME_H_M)
        const userBusinessEndTime = moment(userBusinessHours.endTime, DateFormatEnum.TIME_H_M)

        if (!moment(selectedTime).isBetween(moment(userBusinessStartTime).subtract(1, 'seconds'), userBusinessEndTime))
            return false

        return true
    },

    /**
     * Verifica se existe algum AGENDAMENTO que intersecta o intervalo selecionado, se sim retorna o horário final dele para o próximo agendamento.
     */
    checkIntervalForAppointmentOverlap(selectedInterval: Date, collaboratorCode: number, events: IFullCalendarEvent[]): Moment {

        const collaboratorEvents = _.filter(events, ((o) => +o.resourceId! === collaboratorCode))
        const intersectEvent = _.find(collaboratorEvents, ((s) => moment(selectedInterval).isBetween(moment(s.start).subtract(1, 'seconds'), s.end)))

        return intersectEvent ? moment(intersectEvent.end) : moment(selectedInterval)
    },

    /**
     * Monta um evento no fullcalendar para representar um AGENDAMENTO da clínica.
     */
    mountAppointmentsEventData(nearSessions: ISessionResponseDTO[], userAttendancesResponseDTO: IUserAttendancesResponseDTO, isWeekView: boolean, customerName?: string): IFullCalendarEvent {

        // Primeira e ultima sessao do bloco
        const minStart = _.minBy(nearSessions, (j: ISessionResponseDTO) => moment(j.schedule?.beginDate).unix())
        const maxEnd = _.maxBy(nearSessions, (j: ISessionResponseDTO) => moment(j.schedule?.endDate).unix())

        // Ultima data do bloco
        const maxEndDate = maxEnd?.schedule?.endDate ? DateUtils.toDate(maxEnd.schedule?.endDate, DateFormatEnum.US_WITH_TIME_H_M) : undefined

        const backgroundColor = SessionUtils.getSessionColor(nearSessions[0].status, nearSessions[0].presenceConfirmation, maxEndDate)

        // Obtem status da venda por ordem. Ou seja, sera inadimplente se uma for inadimplente, sera cancelado se uma for cancelada, etc.
        let saleStatusByOrder = !!nearSessions.find((session) => session.saleStatus === SaleStatusEnum.CANCELLED) ? SaleStatusEnum.CANCELLED : undefined
        if (!saleStatusByOrder)
            saleStatusByOrder = !!nearSessions.find((session) => session.saleStatus === SaleStatusEnum.DEFAULTING) ? SaleStatusEnum.DEFAULTING : undefined
        if (!saleStatusByOrder)
            saleStatusByOrder = !!nearSessions.find((session) => session.saleStatus === SaleStatusEnum.PENDING) ? SaleStatusEnum.PENDING : undefined

        // Se for visao semanal, mostra o nome da atendente
        const title: string = isWeekView
            ? `[${userAttendancesResponseDTO.user.name}] ${customerName}`
            : (customerName ?? '')

        return {
            id: nearSessions[0].status, // Status do agendamento
            resourceId: `${userAttendancesResponseDTO.user.code}`,
            title,
            start: !!minStart ? moment(minStart.schedule?.beginDate).toISOString(true) : '2',
            end: !!maxEnd ? moment(maxEndDate).toISOString(true) : '2',
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className: `status-${nearSessions[0].status} ${!!saleStatusByOrder ? 'event-alert' : undefined}`,
            borderColor: backgroundColor, // CSS para a tela
            backgroundColor, // CSS para a tela
            extendedProps: {
                customer: nearSessions[0].customer,
                code: nearSessions.map(s => s.code), // Código das sessões que estão sendo agrupadas nesse agendamento
                comments: _.uniq(nearSessions.map(r => r.comments)).join('\n'), // Itera nos comentários da sessão agrupando em uma string, separando por quebra de linha. Alem disso remove os duplicados
                presenceConfirmation: nearSessions[0].presenceConfirmation,
                titleExtra:
                    <SchedulerEventExtraTitleCP
                        status={nearSessions[0].status}
                        isPresenceConfirmed={nearSessions[0].presenceConfirmation ?? false}
                        scheduledDate={maxEndDate}
                        comments={nearSessions[0].comments}
                        saleStatus={saleStatusByOrder ?? nearSessions[0].saleStatus}
                    />,
                descriptionExtra:
                    <SchedulerEventExtraDescriptionCP
                        saleStatus={saleStatusByOrder ?? nearSessions[0].saleStatus}
                        treatments={_.flattenDeep(nearSessions.map((session) => session.treatment))}
                    />
            }
        }
    },

    /**
     * Monta o IAppointmentDetails a partir de um evento (selecionado)
     */
    mountAppointmentDetails(fullcalendarEvent: EventApi, userAttendances: IUserAttendancesResponseDTO[], userCodeResponsible?: number): IAppointmentDetails | undefined {

        const userCode: number = userCodeResponsible ?? +fullcalendarEvent._def.resourceIds[0]

        if (!userCode)
            return

        // Busca os atendimentos do usuario selecionado
        const currentUserAttendances: IUserAttendancesResponseDTO = _.find(userAttendances, r => r.user.code === userCode)!

        // Obtem dados que foram enviados ao calendario
        const sessionCodesFromEvent: number[] = _.isArray(fullcalendarEvent.extendedProps.code) ? fullcalendarEvent.extendedProps.code : [fullcalendarEvent.extendedProps.code]
        const sessionStatus: SessionStatusEnum = SessionStatusEnum[fullcalendarEvent.id]

        if (!currentUserAttendances || !sessionStatus || !sessionCodesFromEvent)
            return

        // Obtem as sessoes atendidas, percorrendo cada um dos agendamentos do usuario
        const userAttendancesSessions: ISessionResponseDTO[] = ScheduleEventUtils.getUserScheduledWithSessions(currentUserAttendances)

        const sessionsFilteredByCustomer = userAttendancesSessions.filter((ses) => sessionCodesFromEvent.includes(ses.code))

        return {
            customer: fullcalendarEvent.extendedProps.customer,
            beginDate: fullcalendarEvent.start ? fullcalendarEvent.start : undefined,
            endDate: fullcalendarEvent.end ? fullcalendarEvent.end : undefined,
            observation: fullcalendarEvent.extendedProps.comments,
            hasConfirmed: fullcalendarEvent.extendedProps.presenceConfirmation,
            attendantName: currentUserAttendances.user.name,
            sessionStatus,
            sessionCodes: sessionCodesFromEvent,
            sessions: sessionsFilteredByCustomer,
        }

    },

    /**
     * Monta o IAppointmentDetails a partir de um horario bloqueado selecionado.
     */
    getUnavailableAppointmentDetails(fullcalendarEvent: EventApi, userAttendances: IUserAttendancesResponseDTO[]): IScheduleSlotInitialData | undefined {

        const userCode: number = +fullcalendarEvent._def.resourceIds[0]
        const extendedProps = (fullcalendarEvent.extendedProps as IFullCalendarExtendedProps)

        if (!userCode || SystemUtils.isEmpty(extendedProps.code))
            return

        // Busca os atendimentos do usuario selecionado
        const currentUserAttendances: IUserAttendancesResponseDTO = _.find(userAttendances, r => r.user.code === userCode)!
        if (!currentUserAttendances)
            return

        return {
            initialTime: moment(fullcalendarEvent.start),
            userProfessional: currentUserAttendances.user,
            userAttendanceScheduleCode: extendedProps.code![0]
        }
    },

    /**
     * Monta DTO para reagendar um appointment quando arrasta o evento para outro profissional/horario.
     */
    mountRescheduleEventDto(eventDropInfo: IFullCalendarEventDropInfo, userAttendances: IUserAttendancesResponseDTO[]): ScheduleRequestDTO | undefined {

        // Obtem dados que foram enviados ao calendario
        const sessionCodesFromEvent: number[] = _.isArray(eventDropInfo.event.extendedProps.code) ? eventDropInfo.event.extendedProps.code : [eventDropInfo.event.extendedProps.code]
        const sessionStatus: SessionStatusEnum = SessionStatusEnum[eventDropInfo.event.id]

        if (!sessionStatus || !sessionCodesFromEvent)
            return

        if (sessionStatus === SessionStatusEnum.CONCLUDED) {
            NotificationHelper.error('Ops', 'Não é possível reagender sessões já concluídas')
            return
        }

        let oldUserCode: number
        // Se moveu apenas o horario, no mesmo usuario, os 'resources' esta nulos
        if (eventDropInfo.oldResource)
            oldUserCode = +eventDropInfo.oldResource.id
        else
            oldUserCode = +eventDropInfo.event._def.resourceIds[0]

        // Busca os atendimentos no usuario original, pois pode ter alterado
        const oldUserAttendances = _.find(userAttendances, r => r.user.code === oldUserCode)
        if (!oldUserAttendances)
            return

        // Obtem as sessoes atendidas pelo usuario que tem as sessoes desejadas
        const userAttendancesSessions: ISessionResponseDTO[] = ScheduleEventUtils.getUserScheduledWithSessions(oldUserAttendances)
        const appointmentSessions = userAttendancesSessions.filter((ses) => sessionCodesFromEvent.includes(ses.code))

        // Percorre as sessoes encontradas mudando a data e hora para a final
        const sessionRequestDto: SessionScheduleRequestDTO[] = []
        let initialDate: Date = eventDropInfo.event.start!
        appointmentSessions.forEach((ses) => {

            const endDateCaluculated: Date = DateUtils.add(initialDate, ses.sessionDuration!, TimeBaseEnum.MINUTE)
            sessionRequestDto.push({
                code: ses.code,
                beginDate: initialDate,
                endDate: endDateCaluculated,
            })

            initialDate = endDateCaluculated
        })

        return {
            professionalCode: +eventDropInfo.event._def.resourceIds[0],
            clientCode: appointmentSessions[0].customer.code,
            sessions: sessionRequestDto,
            comment: appointmentSessions[0].comments
        }
    },

    /**
     * Verifica se a agenda esta na visal semanal ou diaria
     */
    isWeekView(dateInterval?: IDateRangeFilter): boolean {

        if (!dateInterval?.beginDate)
            return false

        return DateUtils.getDiff(TimeBaseEnum.DAY, dateInterval.beginDate, dateInterval.endDate) !== 0
    },

}
