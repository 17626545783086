import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { PaymentMethodEnum, PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PaymentConfigRequests } from 'submodules/neritclin-sdk/services/sale/paymentconfig/PaymentConfigRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ActivePaymentMethodsResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/ActivePaymentMethodsResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'

interface IPaymentMethodSelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP, PaymentMethodEnum> {
    allowClear?: boolean
    considerOnlyAvailable?: boolean
}

/**
 * Componente para select de formas de pagamento ativas no sistema do cliente.
 */
export function SelectPaymentMethodCP(props: IPaymentMethodSelectCPProps): JSX.Element {

    const [selectOptions, setSelectOptions] = useState<SelectOptionTP[]>([])

    const getEnabledPaymentMethodsRequest = useRequest<IGenericListResponseDTO<ActivePaymentMethodsResponseDTO>>()
    useEffect(onGetEnabledPaymentMethodsRequestChange, [getEnabledPaymentMethodsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.considerOnlyAvailable) {
            getEnabledPaymentMethodsRequest.runRequest(PaymentConfigRequests.findActivePaymentMethods())
            return
        }

        // Caso nao deva considerar apenas as formas de pagamentos habilitada, mostra todas do enum
        defineSelectOptions(Object.values(PaymentMethodEnum))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEnabledPaymentMethodsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEnabledPaymentMethodsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const result = getEnabledPaymentMethodsRequest.responseData!.list
        defineSelectOptions(result.map((paymentMethodConfig) => paymentMethodConfig.paymentMethod))
    }

    /**
     * Monta os select options de acordo com as opcoes a serem montadas
     */
    function defineSelectOptions(paymentMethods: PaymentMethodEnum[]): void {

        const options: SelectOptionTP[] = Object.values(paymentMethods).map((method, i) => ({
            key: `key-method-${i}`,
            label: PaymentMethodLabelEnum[method],
            value: method
        }))
        setSelectOptions(options)
    }

    return (
        <SelectWrapperSCP>
            <SelectCP
                placeholder={props.placeholder}
                label={props.label}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                formStateManager={props.formStateManager}
                selectedOptions={props.value ? [props.value] : undefined}
                fieldName={props.fieldName}
                fontSize={props.fontSize}
                isMultiple={false}
                allowClear={props.allowClear}
                options={selectOptions}
                defaultOpen={false}
                onChange={props.onChange}
                width={props.width}
                hint={
                    props.considerOnlyAvailable
                        ? { type: 'tooltip', text: 'Serão mostradas apenas as suas formas de pagamento configuradas. Caso precise configurar entre em contato com a equipe de suporte.' }
                        : undefined
                }
            />
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width:100%;
`
