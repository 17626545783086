/**
 * @todo: Add descricao
 *
 * @author Wagner Coelho
 * @author hjcostabr
 */
export enum MarketingDomainStatusEnum {
    VALID = 'VALID',
    INVALID = 'INVALID',
    DEACTIVATED = 'DEACTIVATED',
}
