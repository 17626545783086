import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IProductSearchRequestDTO } from 'main/modules/products/services/product/dtos/request/IProductSearchRequestDTO'

/**
 * Classe auxiliar para realizar requisições do modulo PRODUTOS
 *
 */
export class ProductRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/products`

    static search = (dto: IProductSearchRequestDTO): RequestConfigTP => ({
        url: ProductRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
