import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { CollapseCPPanelTP } from 'main/common/components/collapse/inner/CollapseCPPanelTP'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'
import { styled } from 'main/config/theme/styledWithTheme'
import React from 'react'

interface IPersonFromWrapperCPProps {
    onSubmit: () => void
    onDelete?: () => void
    showDeleteButton?: boolean
    panelMainInfoCP: JSX.Element
    collapsiblePanelCPs: CollapseCPPanelTP[]
    isLoading: boolean
    isDeleteLoading?: boolean
    shouldDisableSubmit?: boolean
    footerToBodyDistance?: number
    defaultCollapsiblePanelKeys?: string[]
}

/**
 * COMPONENTE
 * Wrapper generico para montar visulazacao de formularios de cadastro empregando
 * 01 layout padrao.
 *
 * @author hjcostabr
 */
export function FormWrapperCP(props: IPersonFromWrapperCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <BodySCP paddingBottom={props.footerToBodyDistance ?? 70}>
                {props.panelMainInfoCP}
                <CollapseCP panels={props.collapsiblePanelCPs} defaultPanelKeys={props.defaultCollapsiblePanelKeys} bordered={false}/>
            </BodySCP>

            <FooterSCP hasDeleteButton={!!props.onDelete}>
                {!!props.showDeleteButton &&
                    <PopConfirmCP
                        title={'Você tem certeza que deseja remover?'}
                        onConfirm={props.onDelete}
                    >
                        <ButtonCP
                            type={'danger'}
                            loading={props.isDeleteLoading}
                        >
                            Remover
                        </ButtonCP>
                    </PopConfirmCP>
                }
                <ButtonCP
                    type={'primary'}
                    onClick={props.onSubmit}
                    loading={props.isLoading}
                    disabled={!!props.shouldDisableSubmit}
                >
                    Salvar
                </ButtonCP>
            </FooterSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`

const BodySCP = styled.div<{ paddingBottom: number }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: ${props => props.paddingBottom}px;
    padding-top: 10px;
    
    .ant-collapse-content-box {
      padding: 0px !important;
    }
`

const FooterSCP = styled.div<{hasDeleteButton: boolean}>`
    background-color: ${props => props.theme.white};
    display: flex;
    padding: 15px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: ${props => (props.hasDeleteButton ? 'space-between' : 'flex-end')};
    border-top: solid 1px #e2e2e2;
`
