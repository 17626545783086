import React from 'react'
import * as _ from 'lodash'
import { LayoutCP } from 'main/common/components/screen-layout/layout/LayoutCP'
import { RoutingHelper } from 'config/RoutingHelper'
import styled from 'main/config/theme/styledWithTheme'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ContentAuthCP } from 'main/modules/auth/components/content-auth/ContentAuthCP'
import { useScreenSize } from 'main/common/responsiveness/use-screen-size/UseScreenSize'
import { AuthScreenCoverCP } from 'main/modules/auth/components/auth-screen-cover/AuthScreenCoverCP'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'

interface IContentAuthCPProps {
    content: JSX.Element
}

/**
 * Layout das telas de autenticacao.
 */
export function AuthLayoutCP(props: IContentAuthCPProps): JSX.Element | null {

    const loggedUserData = AppStateUtils.getLoggedUserData()
    const screenSize = useScreenSize()

    if (!_.isEmpty(loggedUserData?.user)) {
        RoutingHelper.historyReplace('default')
        return null
    }

    return (
        <LayoutWrapperSCP>
            <TopBarHomEnvironmentCP positionFixed={true}/>
            <LayoutCP
                mainLayout={true}
                sider={screenSize.smd ? undefined : <AuthScreenCoverCP/>}
                content={
                    <ContentAuthCP>

                        {props.content}
                    </ContentAuthCP>
                }
            />
        </LayoutWrapperSCP>
    )
}

const LayoutWrapperSCP = styled.div`
    .ant-layout {
	    background: #fff;
	}
`
