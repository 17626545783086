import { ShotDetailReportTabConfigTP } from 'main/modules/marketing/components/shot-details-report/inner/ShotDetailReportTabConfigTP'
import { MarketingShotReportTypeEnum } from 'main/modules/marketing/enums/MarketingShotReportTypeEnum'

/**
 * ABAS
 * Lista de configuracoes para abas do componente de abas de relatorios de disparo.
 *
 * @see ShotDetailsReportCP
 */
export const TAB_CONFIG_LIST_EMAIL: ShotDetailReportTabConfigTP[] = [
    {
        key: 'delivered',
        title: 'Recebeu',
        description: 'Veja todas as pessoas que conseguimos entregar o e-mail',
        reportType: MarketingShotReportTypeEnum.DELIVERIES,
    },
    {
        key: 'viewed',
        title: 'Visualizou',
        description: 'Veja apenas as pessoas que visualizaram o e-mail, e quantas vezes ela abriu o e-mail (considera encaminhamento de e-mail)',
        reportType: MarketingShotReportTypeEnum.VIEWS,
    },
    {
        key: 'clicked',
        title: 'Clicou',
        description: 'Das pessoas que abriram o e-mail, veja quem clicou e em qual link',
        reportType: MarketingShotReportTypeEnum.CLICKS,
    },
    {
        key: 'unsub',
        title: 'Descadastrou',
        description: 'Todas as pessoas que se descadastraram e qual o motivo pela qual não quer mais receber seus e-mails',
        reportType: MarketingShotReportTypeEnum.UNSUBSCRIBES,
    },
    {
        key: 'not-delivered',
        title: 'Não recebeu',
        description: 'Veja para quais pessoas não conseguimos entregar o e-mail',
        reportType: MarketingShotReportTypeEnum.ERRORS,
    },
    {
        key: 'not-viewed',
        title: 'Não visualizou',
        description: 'Das pessoas que conseguimos entregar o e-mails, veja quem não abriu',
        reportType: MarketingShotReportTypeEnum.NO_VIEWS,
    },
    {
        key: 'not-clicked',
        title: 'Não clicou',
        description: 'Das pessoas que abriram o e-mail, veja quem não clicou em nenhum link',
        reportType: MarketingShotReportTypeEnum.NO_CLICKS,
    },
]
