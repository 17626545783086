import React from 'react'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { StringUtils } from 'main/common/utils/StringUtils'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { ScreenFinancial } from 'main/modules/financial/screens/screen-financial/ScreenFinancial'

FinancialRoutes.ROOT = 'financeiro'

FinancialRoutes.TRANSACTIONS_CREDIT = `${FinancialRoutes.ROOT}/movimentacoes/receita`
FinancialRoutes.TRANSACTIONS_DEBIT = `${FinancialRoutes.ROOT}/movimentacoes/despesa`
FinancialRoutes.WITHDRAWS = `${FinancialRoutes.ROOT}/movimentacoes/transferencias`

FinancialRoutes.REPORTS = `${FinancialRoutes.ROOT}/relatorios`
FinancialRoutes.CHARGING = `${FinancialRoutes.REPORTS}/cobrancas`

FinancialRoutes.ACCOUNTS = `${FinancialRoutes.ROOT}/contas`
FinancialRoutes.BANK_ACCOUNT = `${FinancialRoutes.ACCOUNTS}/contas-bancarias`
FinancialRoutes.CREDIT_CARD = `${FinancialRoutes.ACCOUNTS}/cartao-de-credito`
FinancialRoutes.PAYMENT_ACCOUNT = `${FinancialRoutes.ACCOUNTS}/meios-de-recebimento`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(FinancialRoutes.ROOT),
        path: FinancialRoutes.ROOT,
        component: ScreenFinancial,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de FINANCEIRO
 */
export function FinancialRoutes(): JSX.Element {
    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_FINANCIAL]}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

