import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetSalePerformanceEnum } from 'main/modules/dashboard/services/enums/WidgetSalePerformanceEnum'
import { DtoUtils } from 'main/common/dtos/utils/DtoUtils'

interface ICPProps {
    titleExtra?: string
    dateFilters?: IDateRangeFilter
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de valor total vendido.
 */
export function WidgetTotalSaleValueCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={`Valor total vendido ${props.titleExtra ?? ''}`}
            valueMask={InputMaskTypeEnum.MONEY}
            help={'Valor total vendido no período'}
            requestConfigGetter={
                DashboardRequests.getSalePerformanceWidgets(
                    WidgetSalePerformanceEnum.TOTAL_AMMOUNT_SOLD,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes, dateRange: DtoUtils.convertToDateRangeDto(props.dateFilters) }
                )
            }
        />
    )
}
