import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import ListItemICPOLD from 'common/old-refactory/list/inner/ListItemICPOLD'
import { TextCP } from 'main/common/components/text/TextCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { ISalesSummaryReportFilterRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISalesSummaryReportFilterRequestDTO'
import { ISalesSummaryReportRespDTO } from 'main/modules/sale/services/sale/dtos/responses/ISalesSummaryReportRespDTO'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ListCP } from 'main/common/components/list/ListCP'
import { SaleRequests } from 'main/modules/sale/services/sale/SaleRequests'

interface ISalesSummaryList {
    title: string
    value: string
}

interface ICPProps {
    dateFilter: IDateRangeFilter
    franchiseCodes?: number[]
}

/**
 * Quadro com analise geral de vendas
 */
export function ListSalesSummaryCP(props: ICPProps): JSX.Element {

    const salesSummaryRequest = useRequest<ISalesSummaryReportRespDTO>()
    useEffect(onSalesSummaryRequestChange, [salesSummaryRequest.isAwaiting])
    useEffect(defineSalesSummary, [props.dateFilter])

    const [salesSummaryList, setSalesSummaryList] = useState<ISalesSummaryList[]>()

    /**
     * Obtem analise de vendas da API.
     */
    function defineSalesSummary(): void {

        const requestDto: ISalesSummaryReportFilterRequestDTO = {
            beginDate: moment(props.dateFilter.beginDate).format('YYYY-MM-DD'),
            endDate: moment(props.dateFilter.endDate).format('YYYY-MM-DD'),
            franchiseCodes: props.franchiseCodes,
        }

        salesSummaryRequest.runRequest(SaleRequests.getSalesSummaryConfig(requestDto))
    }

    /**
     * Trata quando request das lista de requisicao retorna resultado.
     */
    function onSalesSummaryRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(salesSummaryRequest, 'Erro dados de vendas'))
            return

        const _salesSummary = salesSummaryRequest.responseData!

        const saleSummaryList: ISalesSummaryList[] = []
        saleSummaryList.push({ title: 'Venda Bruta', value: _salesSummary.grossValue ? `${MaskUtils.applyMoneyMask(`${_salesSummary.grossValue}`)}` : 'R$0,00' })
        saleSummaryList.push({ title: 'Descontos', value: _salesSummary.discountValue ? `${MaskUtils.applyMoneyMask(`${_salesSummary.discountValue}`)}` : 'R$0,00' })
        saleSummaryList.push({ title: 'Venda Líquida', value: _salesSummary.netValue ? `${MaskUtils.applyMoneyMask(`${_salesSummary.netValue}`)}` : 'R$0,00' })
        saleSummaryList.push({ title: 'Qtd. de Vendas', value: _salesSummary.totalSales ? `${_salesSummary.totalSales}` : '0' })
        saleSummaryList.push({ title: 'Qtd. de Pacotes', value: _salesSummary.totalPackages ? `${_salesSummary.totalPackages}` : '0' })
        saleSummaryList.push({ title: 'PPV', value: MoneyUtils.format(_salesSummary.packagesPerSale) })
        setSalesSummaryList(saleSummaryList)
    }

    return (
        <ListCP
            bordered={false}
            dataSource={salesSummaryList}
            loading={salesSummaryRequest.isAwaiting}
            renderItem={(item: ISalesSummaryList) => (
                <ListItemICPOLD title={item.title}>
                    <TextCP text={item.value}/>
                </ListItemICPOLD>
            )}
        />
    )
}
