import React from 'react'
import { TagCP } from 'main/common/components/tag/TagCP'
import { TagCPColorTP } from 'main/common/components/tag/inner/TagCPColorTP'
import { ChargeStatusEnum, ChargeStatusLabelEnum } from 'submodules/neritclin-sdk/services/sale/charge/enums/ChargeStatusEnum'

interface ITagChargeStatusCPProps {
    status: ChargeStatusEnum
}

/**
 * COMPONENTE Tag de status de cobranca
 */
export function TagChargeStatusCP(props: ITagChargeStatusCPProps): JSX.Element {

    let color: TagCPColorTP
    switch (props.status) {

        case ChargeStatusEnum.CANCELED:
            color = 'darkOrange'
            break

        case ChargeStatusEnum.CREATED:
            color = 'blue'
            break

        case ChargeStatusEnum.PAID:
        case ChargeStatusEnum.CHARGED:
            color = 'green'
            break

        case ChargeStatusEnum.REFUND:
            color = 'yellow'
            break

        case ChargeStatusEnum.DECLINED:
        default:
            color = 'red'
            break
    }

    return (
        <TagCP
            key={props.status}
            color={color}
            content={ChargeStatusLabelEnum[props.status]}
        />
    )
}
