import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { SelectFullOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type _ActionTypeTP = 'edit' | 'add'

const ERR_REQUIRED_NAME = 'Nome é obrigatório'
const ERR_DUPLICATED_NAME = 'Defina outro nome'

interface ISegmentEditionConfirmationModalCPProps {
    enableUpdate: boolean
    onAddSegment: (segmentName: string) => void
    onUpdateSegment: (segmentName: string) => void
    onCancel: () => void
    isVisible: boolean
    isLoading?: boolean
    segmentName?: string
}

/**
 * COMPONENTE
 * Modal de confirmacao sobre interesse do usuario em como salvar 01 lista de segmentacao recem alterada.
 */
export const SegmentSavingConfirmationModalCP = ComponentUtils.memoizeCp((props: ISegmentEditionConfirmationModalCPProps): JSX.Element => {

    const [actionType, setActionType] = useState<_ActionTypeTP>('edit')
    const [segmentName, setSegmentName] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isInputDirty, setIsInputDirty] = useState<boolean>(false)

    useEffect(onVisibilityChange, [props.isVisible])
    useEffect(_setActionType, [props.enableUpdate])
    useEffect(() => setSegmentName(props.segmentName), [props.segmentName])

    function onVisibilityChange(): void {
        if (props.isVisible)
            _setActionType()
    }

    function _setActionType(_actionType?: _ActionTypeTP): void {

        _actionType = _actionType ?? (props.enableUpdate ? 'edit' : 'add')
        setActionType(_actionType)

        if (_actionType === 'add')
            setSegmentName('')
    }

    function getSelectOptions(): SelectFullOptionTP[] {

        const options: SelectFullOptionTP[] = [
            {
                value: 'add',
                label: 'Nova lista'
            },
        ]

        if (props.enableUpdate) {
            options.push({
                value: 'edit',
                label: 'Atualizar'
            })
        }

        return options
    }

    function onSegmentNameChange(_segmentName: string): void {

        isValidSegmentName(false, _segmentName)
        setSegmentName(_segmentName)

        if (!isInputDirty)
            setIsInputDirty(true)
    }

    function isValidSegmentName(isSubmit: boolean, _segmentName?: string): boolean {

        _segmentName = _segmentName ?? segmentName
        let _errorMessage: OrUndefTP<string>

        if (!_segmentName && (isInputDirty || isSubmit))
            _errorMessage = ERR_REQUIRED_NAME
        else if (isSubmit && actionType === 'add' && _segmentName === props.segmentName)
            _errorMessage = ERR_DUPLICATED_NAME

        setErrorMessage(_errorMessage)
        return (_errorMessage === undefined)
    }

    function onSubmit(): void {

        if (!isValidSegmentName(true))
            return

        if (actionType === 'add')
            props.onAddSegment(segmentName!)
        else
            props.onUpdateSegment(segmentName!)
    }

    const description = props.enableUpdate
        ? 'Voce pode atualizar a lista de segmentação atual ou criar uma nova lista a partir desta.'
        : 'Deseja realmente modificar a lista de segmentação atual?'

    return (
        <ModalCP
            title={'O que você quer fazer?'}
            visible={props.isVisible}
            onCancel={props.onCancel}
            noFooter={true}
        >
            <ContentWrapperSCP>
                <DescriptionSCP>{description}</DescriptionSCP>

                <FormWrapperSCP>
                    <FormItemWrapperSCP minWidthPercentage={35}>
                        <SelectCP
                            label={'Ação'}
                            value={actionType}
                            onChange={_setActionType}
                            options={getSelectOptions()}
                        />
                    </FormItemWrapperSCP>

                    <FormItemWrapperSCP>
                        <InputCP
                            label={'Nome do Segmento'}
                            onChange={onSegmentNameChange}
                            value={segmentName}
                            onFormSubmit={onSubmit}
                            errorMessage={errorMessage}
                        />
                    </FormItemWrapperSCP>

                    <ButtonCP
                        type={'primary'}
                        onClick={onSubmit}
                        loading={props.isLoading}
                        icon={'check'}
                    />
                </FormWrapperSCP>
            </ContentWrapperSCP>
        </ModalCP>
    )
})

const ContentWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
`

const DescriptionSCP = styled.div`
    margin-bottom: 15px;
`

const FormWrapperSCP = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
`

const FormItemWrapperSCP = styled.div<{ minWidthPercentage?: number }>`
    min-width: ${props => props.minWidthPercentage ?? 0}%;

`
