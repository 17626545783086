import React, { useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { PdfViewerCP } from 'main/common/components/pdf-viewer/PdfViewerCP'
import { IDokfyDocumentResponseDTO } from 'main/modules/documents/services/dokfy/dtos/responses/IDokfyDocumentResponseDTO'
import { DokfyDocumentStatusEnum } from 'main/modules/documents/services/dokfy/enums/DokfyDocumentStatusEnum'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { LinkHtmlCP } from 'main/common/components/link-html/LinkHtmlCP'
import { DownloadIconCP } from 'main/common/components/icons/DownloadIconCP'
import { DocumentAssigneesTableCP } from 'main/modules/documents/components/document-assignees-table/DocumentAssigneesTableCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { ButtonResendDokfyDocumentCP } from 'main/modules/documents/components/button-resend-dokfy-document/ButtonResendDokfyDocumentCP'

interface IDokfyResultContentICPProps {
    document: IDokfyDocumentResponseDTO
    sale: ISaleResponseDTO
    onRefreshDocument: () => void
    loading: boolean
}

/**
 * Conteudo do modal na visao de um contrato gerado.
 */
export function DokfyResultContentICP(props: IDokfyResultContentICPProps): JSX.Element {

    const [isPdfViewerVisible, setIsPdfViewerVisible] = useState<boolean>(false)

    return (
        <>
            <FallbackScreenContentCP
                status={props.document.status === DokfyDocumentStatusEnum.SIGNED ? 'success' : 'info'}
                title={props.document.status === DokfyDocumentStatusEnum.SIGNED ? 'O Contrato foi Assinado' : 'Aguardando Assinaturas'}
                message={props.document.status === DokfyDocumentStatusEnum.AWAITING_SIGNATURE ? 'O Contrato foi enviado aos signatários com sucesso.' : undefined}
                bottomContent={
                    <>
                        {
                            props.document.status === DokfyDocumentStatusEnum.SIGNED &&
                            <ButtonsWrapperSCP>
                                <ButtonCP
                                    type={'ghost'}
                                    icon={'eye'}
                                    size={'large'}
                                    onClick={() => setIsPdfViewerVisible(true)}
                                >
                                    Visualizar Contrato Assinado
                                </ButtonCP>
                                <LinkHtmlCP
                                    type={'primary'}
                                    href={props.document.files.signedFileUrl!}
                                    size={'large'}
                                >
                                    <DownloadIconCP style={{ marginRight: '10px' }}/>
                                    <>Fazer download do Contrato Assinado</>
                                </LinkHtmlCP>
                            </ButtonsWrapperSCP>
                        }
                        {
                            props.document?.status === DokfyDocumentStatusEnum.AWAITING_SIGNATURE &&
                            <>
                                <FlexCP justifyContent={'flex-end'} marginBottom={10}>
                                    <ButtonCP
                                        icon={'reload'}
                                        onClick={props.onRefreshDocument}
                                        type={'primary'}
                                        loading={props.loading}
                                    >
                                        Atualizar
                                    </ButtonCP>
                                </FlexCP>
                                <DocumentAssigneesTableCP
                                    originalAssigneesData={[props.sale.personCustomerPayer]}
                                    assignees={props.document.assignees}
                                    onUpdateData={props.onRefreshDocument}
                                />
                                <FlexCP justifyContent={'space-between'} marginTop={10}>
                                    <LinkHtmlCP
                                        type={'ghost'}
                                        href={props.document.files.originalFileUrl}
                                    >
                                        <FlexCP>
                                            <DownloadIconCP style={{ marginRight: '10px' }}/>
                                            <>Baixar Contrato</>
                                        </FlexCP>
                                    </LinkHtmlCP>

                                    <ButtonResendDokfyDocumentCP
                                        documentCode={props.document.code}
                                    />
                                </FlexCP>
                            </>
                        }
                    </>
                }
            />

            <PdfViewerCP
                file={props.document.files.signedFileUrl}
                paginationType={'scroll'}
                viewType={'modal'}
                visible={isPdfViewerVisible}
                onSetVisibleFalse={() => setIsPdfViewerVisible(false)}
            />
        </>
    )

}

const ButtonsWrapperSCP = styled.div`

  button, a {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

`
