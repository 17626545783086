import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import 'moment/locale/pt-br'
import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { SessionsRequests } from 'submodules/neritclin-sdk/services/sessions/SessionsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { StartOrFinishAttendanceRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/StartOrFinishAttendanceRequestDTO'

interface ICPProps {
    sessionCodes: number[]
    sessionStatus: SessionStatusEnum
    onDone: () => void
}

/**
 * Botao para cancelar um atendimento iniciado
 */
export function ButtonCancelStartedAttendanceCP(props: ICPProps): JSX.Element {

    const cancelStartedAttendanceRequest = useRequest<void>('none')
    useEffect(onCancelStartedAttendanceRequestChange, [cancelStartedAttendanceRequest.isAwaiting])

    /**
     * Cancela.
     */
    function cancel(): void {

        const dto: StartOrFinishAttendanceRequestDTO = {
            sessionCodes: props.sessionCodes,
        }
        cancelStartedAttendanceRequest.runRequest(SessionsRequests.cancelStartedAttendance(dto))
    }

    /**
     * Retorno.
     */
    function onCancelStartedAttendanceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(cancelStartedAttendanceRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onDone()
    }

    if (props.sessionStatus !== SessionStatusEnum.IN_PROGRESS)
        return <></>

    return (
        <ButtonCP
            type={'danger'}
            onClick={cancel}
            loading={cancelStartedAttendanceRequest.isAwaiting}
            tooltip={'Esse atendimento foi iniciado, essa ação cancela o inicio do atendimento e volta para o status AGENDADO'}
        >
            Voltar atendimento para fila
        </ButtonCP>
    )
}
