import React from 'react'
import { RecoverPasswordFormCP } from 'main/modules/auth/components/recover-password-form/RecoverPasswordFormCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AuthRoutes } from 'main/modules/auth/AuthRoutes'
import { AuthLayoutCP } from 'main/modules/auth/components/auth-layout/AuthLayoutCP'

/**
 * TELA: Recuperar senha.
 *
 * @author hjcostabr
 */
export function RecoverPasswordScreen(): JSX.Element {

    return (
        <AuthLayoutCP
            content={
                <RecoverPasswordFormCP goToLogin={() => RoutingHelper.historyPush(AuthRoutes.USER_LOGIN)}/>
            }
        />
    )
}
