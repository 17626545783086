import { IGenerateBilletRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IGenerateBilletRequestDTO'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IFinancialTransactionSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSaveRequestDTO'
import { IFinancialTransactionsSummaryRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionsSummaryRequestDTO'
import { IFinancialTransactionSummaryByPaymentMethodRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSummaryByPaymentMethodRequestDTO'
import { IFinancialTransactionPaymentRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionPaymentRequestDTO'
import { IBankBilletDownloadRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankBilletDownloadRequestDTO'
import { IBankAccountWithdrawSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankAccountWithdrawSaveRequestDTO'
import { IBankAccountWithdrawSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IBankAccountWithdrawSearchRequestDTO'
import { IFinancialTransactionMultiplePaymentRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionMultiplePaymentRequestDTO'
import { IFinancialTransactionMultipleEditRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionMultipleEditRequestDTO'

export class FinancialTransactionsRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/financial-transactions`

    /** Busca as transações de RECEITA de uma franquia */
    static searchCreditTransactionsConfig = (dto: IFinancialTransactionSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/credits`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    /** Busca as transações de DESPESA de uma franquia */
    static searchDebitTransactionsConfig = (dto: IFinancialTransactionSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/expenses`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchBankAccountWithdraws = (dto: IBankAccountWithdrawSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/withdraws`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static findOne = (transactionCode: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/${transactionCode}`,
        method: HttpMethodEnum.GET,
    })

    static getBankAccountWithdraw = (code: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/withdraws/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getSummaryConfig = (dto: IFinancialTransactionsSummaryRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/summary`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getSummaryByPaymentMethodConfig = (dto: IFinancialTransactionSummaryByPaymentMethodRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/summary-by-payment-method`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static generateBillet = (dto: IGenerateBilletRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/create-bank-billet/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static generateMultipleBankBillets = (dto: IBankBilletDownloadRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/download-bank-billets/`,
        method: HttpMethodEnum.GET,
        params: dto,
        responseType: 'blob'
    })

    static payConfig = (financialTransactionCode: number, dto: IFinancialTransactionPaymentRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/${financialTransactionCode}/pay`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static payMultiple = (dto: IFinancialTransactionMultiplePaymentRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/pay-multiple`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static unPay = (financialTransactionCode: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/${financialTransactionCode}/unpay`,
        method: HttpMethodEnum.PUT,
    })

    static createBankAccountWithdraw = (dto: IBankAccountWithdrawSaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/withdraws`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static create = (dto: IFinancialTransactionSaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IFinancialTransactionSaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static updateBankAccountWithdraw = (code: number, dto: IBankAccountWithdrawSaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/withdraws/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static updateMultiple = (dto: IFinancialTransactionMultipleEditRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/update-multiple`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static updateGroup = (code: number, dto: IFinancialTransactionSaveRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/grouped-transaction/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static deleteBankAccountWithdraw = (code: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/withdraws/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static deleteGroupExpense = (code: number): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/grouped-expense/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static exportExpense = (dto: IFinancialTransactionSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/expenses/export`,
        method: HttpMethodEnum.GET,
        params: dto,
        responseType: 'blob'
    })

    static exportCredit = (dto: IFinancialTransactionSearchRequestDTO): RequestConfigTP => ({
        url: `${FinancialTransactionsRequests._MODULE_BASE}/credits/export`,
        method: HttpMethodEnum.GET,
        params: dto,
        responseType: 'blob'
    })

}