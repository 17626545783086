import { ArrowIconCP } from 'main/common/components/icons/ArrowIconCP'
import { TextCP } from 'main/common/components/text/TextCP'
import styled from 'main/config/theme/styledWithTheme'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import React from 'react'

interface ITableColumnValidationErrorDescICPProps {
    lineErrors: ImportValidationErrorsLineTP
}

/**
 * COMPONENTE
 * Coluna de detalhamento de falhas em 01 linha da tabela de exibicao de falhas de validacao
 * de arquivo .csv para importacao de pessoas.
 */
export function TableColumnValidationErrorDescICP(props: ITableColumnValidationErrorDescICPProps): JSX.Element {

    /* eslint-disable react/jsx-no-useless-fragment */
    return (
        <>
            {
                Object.keys(props.lineErrors.errors).map((errorProp, index) => {

                    const propErrors = props.lineErrors.errors[errorProp]

                    return (
                        <ErrorDescriptionsWrapperSCP key={`validation_error_${props.lineErrors.line}_${errorProp}_${index}`}>
                            <TextCP
                                text={errorProp}
                                strong={true}
                                center={true}
                                style={{ width: 125 }}
                                size={'small'}
                            />

                            {
                                propErrors.map((error, idx) => (
                                    <ErrorDescriptionsWrapperSCP key={`validation_error_${props.lineErrors.line}_${errorProp}_${idx}`}>
                                        <ArrowIconCP
                                            direction={'right'}
                                            style={{ margin: '0 4px' }}
                                            size={10}
                                        />

                                        <TextCP size={'small'} text={error}/>
                                    </ErrorDescriptionsWrapperSCP>
                                ))
                            }
                        </ErrorDescriptionsWrapperSCP>
                    )
                })
            }
        </>
    )
    /* eslint-enable react/jsx-no-useless-fragment */
}

const ErrorDescriptionsWrapperSCP = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
`
