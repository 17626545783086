import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { TreatmentFormSaveRequestDTO } from 'submodules/neritclin-sdk/services/treatment/forms/dtos/request/TreatmentFormSaveRequestDTO'

export class TreatmentFormsRequests {

	public static CONTROLLER_ROOT = 'treatment-forms'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'

	static create = (dto: TreatmentFormSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${TreatmentFormsRequests.CONTROLLER_ROOT}/${TreatmentFormsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static update = (code: number, dto: TreatmentFormSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${TreatmentFormsRequests.CONTROLLER_ROOT}/${TreatmentFormsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

}
