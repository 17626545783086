import React, { useEffect, useState } from 'react'
import { ChangePasswordFormValidator } from 'main/modules/people/components/change-password-modal/inner/ChangePasswordFormValidator'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IUserChangePasswordRequestDTO } from 'main/modules/user/services/dtos/request/IUserChangePasswordRequestDTO'
import md5 from 'md5'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface IChangePasswordModalCPProps {
    visible: boolean
    onClose: () => void
}

/**
 * Modal de alteracao de senha.
 */
export function ChangePasswordModalCP(props: IChangePasswordModalCPProps): JSX.Element {

    const [formValidator] = useState<ChangePasswordFormValidator>(new ChangePasswordFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const changePasswordRequest = useRequest('none')
    useEffect(onChangePasswordRequestChange, [changePasswordRequest.isAwaiting])

    /**
     * Faz requisicao na API para alteracao de senha.
     */
    async function changePassword(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (formValues.newPassword !== formValues.confirmNewPassword)
            return NotificationHelper.error('Ops!', 'A nova senha e sua confirmação não estão iguais')

        const dto: IUserChangePasswordRequestDTO = {
            newPassword: md5(formValues.newPassword),
            oldPassword: md5(formValues.currentPassword)
        }

        changePasswordRequest.runRequest(UserRequests.changePasswordConfig(AppStateUtils.getLoggedUserData()!.user.code, dto))
    }

    /**
     * Faz as validações de request, manipula o novo tokem que a API retorna após a troca de senha e reseta o modal
     */
    function onChangePasswordRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changePasswordRequest, 'Não foi possível alterar a sua senha'))
            return

        formStateManager.reset()

        NotificationHelper.success('Sucesso', 'Senha alterada com sucesso!')
        props.onClose()
    }

    return (
        <ModalCP
            title={'Alterar Senha'}
            visible={props.visible}
            onOk={changePassword}
            onCancel={props.onClose}
            actionLoading={changePasswordRequest.isAwaiting}
        >
            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'currentPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Senha Atual '}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'newPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Nova senha '}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'confirmNewPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Confirmar senha '}
                    />
                </ColumnCP>
            </RowCP>
        </ModalCP>
    )
}

