import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IMarketingDestinationResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDestinationResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingDomainStatusEnum } from 'main/modules/marketing/enums/MarketingDomainStatusEnum'
import { MarketingSenderStatusEnum } from 'main/modules/marketing/enums/MarketingSenderStatusEnum'
import { MarketingShotStatusEnum } from 'main/modules/marketing/enums/MarketingShotStatusEnum'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { ScreenNewShot } from 'main/modules/marketing/screens/screen-new-shot/ScreenNewShot'

/**
 * UTILITARIOS
 * Marketing.
 *
 * @author renatofs
 * @author hjcostabr
 */
export const MarketingUtils = {

    getShotTypeLabel(shotType: MarketingAccountTypeEnum): string {
        switch (shotType) {
            case MarketingAccountTypeEnum.EMAIL:
                return 'E-mail'

            case MarketingAccountTypeEnum.SMS:
                return 'SMS'

            default:
                return ''
        }
    },

    getDomainStatusEnumLabel(status: MarketingDomainStatusEnum): string {
        switch (status) {
            case MarketingDomainStatusEnum.VALID:
                return 'Verificado'

            case MarketingDomainStatusEnum.DEACTIVATED:
                return 'Desativado'

            case MarketingDomainStatusEnum.INVALID:
                return 'Inválido'
            default:
                return ''
        }
    },

    getSenderStatusEnumLabel(status: MarketingSenderStatusEnum): string {
        switch (status) {
            case MarketingSenderStatusEnum.ACTIVE:
                return 'Ativo'

            case MarketingSenderStatusEnum.INACTIVE:
                return 'Desativado'

            case MarketingSenderStatusEnum.PENDING_VALIDATION:
                return 'Pendente'
            default:
                return ''
        }
    },

    getShotStatusLabel(shotStatus: MarketingShotStatusEnum): string {
        switch (shotStatus) {
            case MarketingShotStatusEnum.SENT:
                return 'Enviado'

            case MarketingShotStatusEnum.ERROR:
                return 'Erro'

            case MarketingShotStatusEnum.SENDING:
                return 'Enviando'

            case MarketingShotStatusEnum.DRAFT:
                return 'Editando'

            case MarketingShotStatusEnum.SCHEDULED:
                return 'Agendado'

            default:
                return ''
        }
    },

    getDestinationName(destination: IMarketingDestinationResponseDTO, segmentName: string): string {
        const userFranchises = AppStateUtils.getUserFranchises()

        const destinationFranchises = destination.franchises.map(dstFranchiseCode => {
            return userFranchises?.find(usrFranchise => dstFranchiseCode === usrFranchise.code)!.name
        })

        return `${destinationFranchises.join(', ')} - ${segmentName}`
    },

    getNewShotScreenPath(segmentCode: number, accountType: MarketingAccountTypeEnum): string {
        return `${MarketingRoutes.NEW_SHOT}/${accountType}?${ScreenNewShot.SEGMENT_CODE_PARAM}=${segmentCode}`
    },
}
