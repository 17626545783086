import moment from 'moment'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'

const NOW = moment()

export const OpportunityEvaluationUtils = {

    /**
     * Obtem a cor de um agendamento de avaliacao de acordo com a data.
     */
    getScreeningStatusColor(evaluationSchedule: IOpportunityEvaluationScheduleResponseDTO): string {

        if (moment(evaluationSchedule.beginDate).isBefore(NOW))
            return '#e48150'

        return '#52a999'
    },

}
