import { LayoutCP } from 'main/common/components/screen-layout/layout/LayoutCP'
import { PersonListScreenContentCP } from 'main/modules/people/screens/screen-person-list/screen-content/PersonListScreenContentCP'
import { PersonListScreenSiderCP } from 'main/modules/people/components/person-list-screen-sider/PersonListScreenSiderCP'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import React from 'react'

/**
 * TELA CRM
 * Mostra a listagem de pessoas por segmentações.
 */
export function PersonListScreen(): JSX.Element {
    return (
        <PersonContext.Provider>
            <LayoutCP
                sider={<PersonListScreenSiderCP/>}
                content={<PersonListScreenContentCP/>}
            />
        </PersonContext.Provider>
    )
}
