import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { ListCPOLD } from 'common/old-refactory/list/ListCPOLD'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import styled from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { NewShotConfirmModalUtils } from 'main/modules/marketing/components/new-shot-confirm-modal/inner/NewShotConfirmModalUtils'
import { ScheduleShotFormValidator } from 'main/modules/marketing/components/new-shot-confirm-modal/inner/ScheduleShotFormValidator'
import { IMarketingScheduleShotRequetDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingScheduleShotRequetDTO'
import { IMarketingCampaignEmailResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignEmailResponseDTO'
import { IMarketingCampaignSmsResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingCampaignSmsResponseDTO'
import { MarketingAccountTypeEnum } from 'main/modules/marketing/enums/MarketingAccountTypeEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import { ShotActionTypeTP } from 'main/modules/marketing/types/ShotActionTypeTP'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React, { useEffect, useState } from 'react'

interface INewShotConfirmModalCPProps {
    actionType?: ShotActionTypeTP
    visible: boolean
    onConfirmSend: () => void // Tipar com objeto de data hora
    shot: IMarketingCampaignEmailResponseDTO | IMarketingCampaignSmsResponseDTO
    segmentName: string
    shotType: MarketingAccountTypeEnum
    onCancel: () => void
}

/**
 * Modal de confirmacao de um disparo.
 */
export function NewShotConfirmModalCP(props: INewShotConfirmModalCPProps): JSX.Element {

    const [formValidator] = useState<ScheduleShotFormValidator>(new ScheduleShotFormValidator())
    const formStateManager = useFormStateManager<ScheduleShotFormValidator>(formValidator)

    const scheduleShotRequest = useRequest<void>(undefined, 'none')
    useEffect(onScheduleShotRequestChange, [scheduleShotRequest.isAwaiting])

    async function scheduleShot(): Promise<void> {

        if (!props.shot)
            return

        let dateTime: Date

        if (props.actionType === 'sendNow')
            dateTime = new Date()

        else {
            formStateManager.setConsiderAllErrors(true)
            if (!await formStateManager.validate())
                return

            const formValues = formStateManager.getFormValues()
            if (!formValues)
                return

            const hourAndMinutes = DateUtilsOLD.getHourAndMinutes(formValues.hour)
            dateTime = DateUtilsOLD.setTime(formValues.date, hourAndMinutes.hour, hourAndMinutes.minutes)
        }

        const dto: IMarketingScheduleShotRequetDTO = {
            scheduleDate: DateUtilsOLD.getFormatted(dateTime, DateFormatEnum.US_WITH_TIME_H_M)
        }

        scheduleShotRequest.runRequest(await MarketingRequests.scheduleCampaignShot(props.shotType, props.shot.code, dto))
    }

    /**
     * Retorno da api no pedido de agedar um disparo
     */
    function onScheduleShotRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(scheduleShotRequest, 'Erro ao agendar envio', true))
            return

        NotificationHelper.success(
            'Yes',
            (props.actionType === 'sendNow')
                ? `O seu ${MarketingUtils.getShotTypeLabel(props.shotType)} já está em processo de envio`
                : 'Envio agendado com sucesso'
        )

        props.onConfirmSend()
    }

    return (
        <ModalCP
            visible={props.visible}
            title={'Confirme os dados antes de enviar'}
            onOk={scheduleShot}
            actionLoading={scheduleShotRequest.isAwaiting}
            onCancel={props.onCancel}
            okText={props.actionType === 'sendNow' ? 'Enviar agora' : 'Agendar envio'}
        >
            <ListCPOLD
                dataSource={
                    (props.shotType === MarketingAccountTypeEnum.EMAIL)
                        ? NewShotConfirmModalUtils.getEmailTableColumnsConfig(props.shot as IMarketingCampaignEmailResponseDTO, formStateManager, props.segmentName, props.actionType)
                        : NewShotConfirmModalUtils.getSmsTableColumnsConfig(props.shot as IMarketingCampaignSmsResponseDTO, formStateManager, props.segmentName, props.actionType)
                }
                renderItem={item => (
                    <WrapperSCP>
                        {item.avatar}
                        <ContentSCP>
                            <TextCP text={item.title} strong={true} wrap={true}/>
                            <TextCP text={item.value} style={{ color: ThemeAnt.gray }} wrap={true}/>
                        </ContentSCP>
                    </WrapperSCP>
                )}
            />
        </ModalCP>
    )
}
const WrapperSCP = styled.div`
    display: grid;
    grid-template-columns: .2fr 4fr;
    align-items: center;
    column-gap: 10px;
`

const ContentSCP = styled.div`
   margin: 8px 0;
`
