import { DokfySignatureSecurityLevelEnum } from 'main/modules/documents/services/dokfy/enums/DokfySignatureSecurityLevelEnum'

/**
 * UTILITARIOS para nivel de seguranca de assinatura.
 */
export const DokfySignatureSecurityLevelUtils = {

    getSignatureSecurityLevelLabel(secLevel: DokfySignatureSecurityLevelEnum): string {
        switch (secLevel) {
            case DokfySignatureSecurityLevelEnum.SIMPLE_REGISTER:
                return 'Cadastro Simples'

            case DokfySignatureSecurityLevelEnum.FULL_REGISTER:
                return 'Cadastro completo'

            default:
                return ''
        }
    },

    getSignatureSecurityLevelDescription(secLevel: DokfySignatureSecurityLevelEnum): string {
        switch (secLevel) {
            case DokfySignatureSecurityLevelEnum.SIMPLE_REGISTER:
                return 'Neste nível de segurança o signatário precisa informar apenas: CPF / Nome / E-mail'

            case DokfySignatureSecurityLevelEnum.FULL_REGISTER:
                return 'Neste nível de segurança o signatário inseriu todos os documentos. Além dos dados, os documentos que comprovem as informações'

            default:
                return ''
        }
    },

}
