import React from 'react'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { styled } from 'main/config/theme/styledWithTheme'
import { StatisticCP } from 'main/common/components/statistic/StatisticCP'
import { IconPaymentMethodCP } from 'main/modules/financial/components/icon-payment-method/IconPaymentMethodCP'
import * as _ from 'lodash'
import { PaymentMethodLabelEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

interface IChargingSummaryHeaderICPProps {
    paymentMethod: PaymentMethodEnum
    value?: number
    valueCount?: number
    loading: boolean
    onSelectPaymentMethod: () => void
    isSelected?: boolean
}

export function ResumeWidgetByPaymentMethodICP(props: IChargingSummaryHeaderICPProps): JSX.Element {

    return (
        <CardWrapperSCP>
            <StatisticCP
                margin={'5px'}
                loading={props.loading}
                label={_.truncate(PaymentMethodLabelEnum[props.paymentMethod], { length: 18 })}
                value={props.value ?? 0}
                icon={<IconPaymentMethodCP paymentMethod={props.paymentMethod}/>}
                footer={`${props.valueCount ?? 0} faturas`}
                onClick={props.onSelectPaymentMethod}
                selected={props.isSelected}
            />
        </CardWrapperSCP>
    )
}

const CardWrapperSCP = styled.div`
    width: 25%;
`
