import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'
import { IconICP } from 'main/common/components/icons/inner/IconICP'

/**
 * ICONE: Relogio
 *
 * @author renatofs
 */
export const ClockIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'clock-circle'} {...props}/>
}
