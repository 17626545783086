import React from 'react'
import { CardDashboardWidgetCP } from 'main/modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { DashboardRequests } from 'main/modules/dashboard/services/DashboardRequests'
import { WidgetFollowUpEnum } from 'main/modules/dashboard/services/enums/WidgetFollowUpEnum'

interface ICPProps {
    userCode?: number
    franchiseCodes?: number[]
}

/**
 * Widgets de total de leads abertos sem atividade.
 */
export function WidgetTotalFollowupsWithoutActivityCP(props: ICPProps): JSX.Element {

    return (
        <CardDashboardWidgetCP
            title={'Follow-ups sem atividade'}
            help={'Total de Follow-ups que não possui atividade planejada'}
            isZeroTarget={true}
            requestConfigGetter={
                DashboardRequests.getFollowupWidgets(
                    WidgetFollowUpEnum.WITHOUT_ACTIVITIES,
                    { userCode: props.userCode, franchiseCodes: props.franchiseCodes }
                )
            }
        />
    )
}
