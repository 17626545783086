import React, { useState } from 'react'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { TABLE_COLUMNS_VALIDATION_ERRORS } from 'main/modules/sales-funnel/components/modal-lead-import/inner/components/TableColumnsValidationErrors'
import { ImportValidationErrorsLineTP } from 'main/modules/sales-funnel/components/modal-lead-import/inner/types/ImportValidationErrorsLineTP'
import { SystemUtils } from 'main/common/utils/SystemUtils'

const MAX_LINES = 4

interface ITableValidationErrorsICPProps {
    errors: ImportValidationErrorsLineTP[]
}

/**
 * COMPONENTE
 * Listagem de falhas de validacao em arquivos .csv para importacao de pessoas.
 */
export function TableValidationErrorsICP(props: ITableValidationErrorsICPProps): JSX.Element {

    const [currentPage, setCurrentPage] = useState<number>(1)

    return (
        <TableCPOLD2<ImportValidationErrorsLineTP>
            columns={TABLE_COLUMNS_VALIDATION_ERRORS}
            bordered={true}
            data={(props.errors ?? []).filter(lineErrors => !SystemUtils.isEmpty(lineErrors.errors))}
            rowKeyGetter={lineErrors => lineErrors.line.toString()}
            onPaginationChange={({ current }) => setCurrentPage(current ?? 1)}
            pagination={{
                current: currentPage,
                pageSize: MAX_LINES,
                total: Object.keys(props.errors).length
            }}
        />
    )
}
