import { ColumnProps } from 'antd/lib/table'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { TagCP } from 'main/common/components/tag/TagCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IMarketingRecurrentPlanResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingRecurrentPlanResponseDTO'
import { MarketingUtils } from 'main/modules/marketing/utils/MarketingUtils'
import React from 'react'

/**
 * UTILITARIOS
 * Encapsula funcoes uteis para componente de tabela de listagem de planos de mkt.
 */
export const MktPlanTableUtils = {
    getColumnsConfig(): Array<ColumnProps<IMarketingRecurrentPlanResponseDTO>> {
        return [
            {
                dataIndex: 'type',
                key: 'type',
                render: (text, plan) => <TagCP key={'type'} content={MarketingUtils.getShotTypeLabel(plan.type)}/>
            },
            {
                dataIndex: 'active',
                key: 'active',
                render: (text, plan) => <SwitchCP isTextInside={true} isChecked={plan.active} disabled={true} textInsideChecked={'Ativo'} textInsideUnchecked={'Desativado'}/>
            },
            {
                title: 'Data ativação',
                dataIndex: 'date',
                key: 'date',
                render: (text, plan) => DateUtilsOLD.getFormatted(plan.acquiredDate, DateFormatEnum.BR_WITHOUT_TIME)
            },
            {
                title: 'Créditos',
                dataIndex: 'credit',
                key: 'credit',
                align: 'right',
                render: (text, plan) => <>{`${MaskUtils.applyNumberMask(plan.total, 0)} ${MarketingUtils.getShotTypeLabel(plan.type).toLowerCase()}(s)`}</>
            },
            {
                dataIndex: 'type',
                key: 'type',
                align: 'right',
                render: (text, plan) => <TagCP key={'type'} color={'green'} content={`Renova a cada ${plan.periodicity} dias`}/>
            },
            {
                dataIndex: 'extra',
                key: 'extra',
                align: 'right',
                render: (text, plan) => (
                    <>
                        {
                            plan.extra &&
                            <TagCP key={'extra'} color={'darkOrange'} content={'Extra'}/>
                        }
                    </>
                )
            }
        ]
    },
}
