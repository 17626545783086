import { IFinancialTransactionValueSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionValueSaveRequestDTO'
import * as _ from 'lodash'
import { FinancialTransactionValueFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionValueFormModel'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { IFinancialTransactionSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSaveRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { IFinancialTransactionGroupSaveRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionGroupSaveRequestDTO'
import { FinancialTransactionFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionFormModel'
import { FinancialTransactionGroupTypeEnum } from 'main/modules/financial/services/financial-transactions/enums/FinancialTransactionGroupTypeEnum'
import { FinancialTransactionGroupFormModel } from 'main/modules/financial/components/drawer-financial-transaction/inner/models/FinancialTransactionGroupFormModel'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { FinancialTransactionStatusEnum } from 'main/modules/financial/enums/FinancialTransactionStatusEnum'
import { RecurrencePeriodEnum } from 'main/modules/financial/services/financial-transactions/enums/RecurrencePeriodEnum'
import { FinancialTransactionTypeEnum } from 'main/modules/financial/enums/FinancialTransactionTypeEnum'

/**
 * UTILITARIOS de form de despesas
 */
export const FinancialTransactionDrawerUtils = {

    /**
     * Obtem DTO para salvar uma despesa a partir dos formModels
     */
    getSaveDtoFromFormModel(
        type: FinancialTransactionTypeEnum,
        formValues: FinancialTransactionFormModel,
        formRecurrenceGroupValues: FinancialTransactionGroupFormModel
    ): IFinancialTransactionSaveRequestDTO | undefined {

        // Se estiver pago, valida se preencheu tudo corretamente
        if (formValues.isPaid && (!formValues.paymentDate || !formValues.paidValue)) {
            NotificationHelper.error('Ops!', 'Preenche todas os campos de pagamento')
            return
        }

        // Valores por categoria
        const valuesDto = FinancialTransactionDrawerUtils.getValueSaveDtoFromFormModel(formValues.values)
        if (!valuesDto)
            return

        // Grupo de recorrencia, caso tenha selecionado
        const groupDto = FinancialTransactionDrawerUtils.getRecurrenceSaveDtoFromFormModel(formRecurrenceGroupValues)

        // Dto principal
        return {
            type,
            description: formValues.description,
            expirationDate: DateUtils.getFormatted(formValues.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
            referenceDate: formValues.referenceDate ? DateUtils.getFormatted(formValues.referenceDate, DateFormatEnum.US_WITHOUT_TIME) : DateUtils.getFormatted(formValues.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
            personCode: formValues.suplierCode,
            paymentMethod: formValues.paymentMethod,
            bankCode: formValues.bankCode,
            paymentDate: formValues.isPaid ? DateUtils.getFormatted(formValues.paymentDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            paidValue: formValues.isPaid ? MoneyUtils.convertToFloat(formValues.paidValue!) : undefined,
            values: valuesDto,
            group: groupDto,
            note: formValues.nfeNumber
        }
    },

    /**
     * Itera nos vampos de valores da despesa para montar o DTO interno de valores
     */
    getValueSaveDtoFromFormModel(formValues: FinancialTransactionValueFormModel[]): IFinancialTransactionValueSaveRequestDTO[] | undefined {

        // Itera nos vampos de valores da despesa para montar o DTO interno de valores
        let isMissingRequiredField = false
        const categoryValues: IFinancialTransactionValueSaveRequestDTO[] = []
        _.each(formValues, (catVal: FinancialTransactionValueFormModel) => {

            if (!catVal.categoryCode || !catVal.value) {
                isMissingRequiredField = true
                return
            }

            categoryValues.push({
                categoryCode: catVal.categoryCode,
                description: catVal.description,
                value: MoneyUtils.convertToFloat(catVal.value),
            })
        })

        if (isMissingRequiredField) {
            NotificationHelper.error('Ops!', 'Preencha os campos de valores')
            return
        }

        return categoryValues
    },

    /**
     * Monta DTO de agrupamento, caso tenha preenchido os valores
     */
    getRecurrenceSaveDtoFromFormModel(formValues: FinancialTransactionGroupFormModel): IFinancialTransactionGroupSaveRequestDTO | undefined {

        let groupDTO: IFinancialTransactionGroupSaveRequestDTO | undefined
        if (formValues.isRecurrent) {
            groupDTO = {
                type: formValues.type!,
                period: formValues.recurrencyPeriod ?? RecurrencePeriodEnum.MONTHLY,
                totalInstallments: formValues.type === FinancialTransactionGroupTypeEnum.INSTALLMENT ? Number(formValues.totalInstallments) : undefined,
            }
        }

        return groupDTO
    },

    /**
     * Monta FormModel a partir do DTO recebido da API.
     */
    getFormModelFromDto(dto: IFinancialTransactionResponseDTO): FinancialTransactionFormModel {

        // Valores
        const categoryValues = dto.valuesByCategory.map((categoryValue) => {
            return new FinancialTransactionValueFormModel({
                code: categoryValue.code,
                categoryCode: categoryValue.category.code,
                description: categoryValue.description,
                value: MaskUtils.applyMoneyMask(categoryValue.value)
            })
        })

        return new FinancialTransactionFormModel({
            description: dto.description,
            expirationDate: DateUtils.toDate(dto.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
            referenceDate: DateUtils.toDate(dto.referenceDate, DateFormatEnum.US_WITHOUT_TIME),
            suplierCode: dto.personSupplier?.code,
            paymentMethod: dto.paymentMethod,
            bankCode: dto.bank.code,
            isPaid: dto.status === FinancialTransactionStatusEnum.PAID,
            paymentDate: dto.status === FinancialTransactionStatusEnum.PAID ? DateUtils.toDate(dto.paymentDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            paidValue: dto.status === FinancialTransactionStatusEnum.PAID ? MaskUtils.applyMoneyMask(dto.paidValue) : undefined,
            values: categoryValues,
            nfeNumber: dto.note,
        })
    },

    /**
     * Monta FormModel de dados de recorrencia a partir do DTO recebido da API.
     */
    getRecurrenceGroupFormModelFromDto(dto: IFinancialTransactionResponseDTO): FinancialTransactionGroupFormModel {

        // Se recorrente
        return new FinancialTransactionGroupFormModel({
            isRecurrent: !!dto.group,
            type: dto.group?.type,
            recurrencyPeriod: dto.group?.period,
            totalInstallments: dto.group?.totalInstallments,
        })
    },

}
