import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { BasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChargeRequests } from 'submodules/neritclin-sdk/services/sale/charge/ChargeRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IChargeListItemResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/IChargeListItemResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ChargeCancellationFormModel, RefundTP } from 'main/modules/financial/components/modals/modal-charge-cancellation/inner/ChargeCancellationFormModel'
import { ChargeCancellationRequestDTO } from 'submodules/neritclin-sdk/services/sale/charge/dtos/request/ChargeCancellationRequestDTO'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import * as _ from 'lodash'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { ChargeStatusEnum } from 'submodules/neritclin-sdk/services/sale/charge/enums/ChargeStatusEnum'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

interface ICPProps extends BasicModalCPProps {
    charge: IChargeListItemResponseDTO
    financialTransaction?: IFinancialTransactionCreditListItemResponseDTO
    alternativo?:boolean
}

/**
 */
export function ModalChargeCancellationCP(props: ICPProps): JSX.Element {
    const maxPartialValue = props.financialTransaction?.value ?? _.sumBy(props.charge.financialTransactions, 'value')
    let canRefund: boolean = false
    let label: string
    if (!!props.financialTransaction) {
        canRefund = !!props.financialTransaction?.chargeData && [ChargeStatusEnum.CHARGED, ChargeStatusEnum.PAID].includes(props.financialTransaction.chargeData.chargeStatus)
        label = 'parcela'
    } else if (props.charge.chargeData.paymentMethod === PaymentMethodEnum.CREDIT_CARD) {
        canRefund = !!props.charge.chargeData?.chargeStatus && [ChargeStatusEnum.CHARGED, ChargeStatusEnum.PAID].includes(props.charge.chargeData.chargeStatus)
        label = 'cobrança'
    } else
        label = 'recorrência'

    const [formValidator] = useState<ChargeCancellationFormModel>(new ChargeCancellationFormModel())
    const formStateManager = useFormStateManager<ChargeCancellationFormModel>(formValidator)

    const chargeCancellationRequest = useRequest<void>('none')
    useEffect(onChargeCancellationRequestChange, [chargeCancellationRequest.isAwaiting])

    /**
     */
    async function cancelCharge(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        if (canRefund && !formValues.refundType) {
            NotificationHelper.error('Ops', 'Escolha o tipo de estorno, se parcial ou completo')
            return
        }

        if (formValues.refundType === 'partial') {

            if (!formValues.refundValue) {
                NotificationHelper.error('Ops', 'Para estorno parcial, informe o valor')
                return
            }

            if (!isRefundValueValid()) {
                NotificationHelper.error('Ops', 'Valor informado é maior do que o valor da cobrança')
                return
            }

        }

        const dto: ChargeCancellationRequestDTO = {
            chargeCode: props.charge.code,
            alternativo:props.alternativo,
            financialTransactionCode: props.financialTransaction?.code,
            reason: formValues.reason,
            refundValue: formValues.refundType === 'partial' ? MoneyUtils.getNumber(formValues.refundValue) : undefined,
        }
        chargeCancellationRequest.runRequest(ChargeRequests.chargeCancellation(dto))
    }

    /**
     */
    function onChargeCancellationRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(chargeCancellationRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    /**
     */
    function isRefundValueValid(): boolean {
        return MoneyUtils.getNumber(formStateManager.getFieldValue('refundValue')) <= maxPartialValue
    }

    return (
        <ModalCP
            title={'Cancelamento de Cobrança'}
            width={440}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={cancelCharge}
            actionLoading={chargeCancellationRequest.isAwaiting}
        >
            <AlertCP
                message={`Você tem certeza que deseja cancelar essa ${label}? Esta ação não pode ser desfeita.`}
                type={'error'}
                margin={{ bottom: 20 }}
            />

            {
                canRefund &&
                <>
                    <TitleCP textSize={'normal'} underLine={true}>
                        Estorno
                    </TitleCP>

                    <FlexCP justifyContent={'center'} margin={{ bottom: 30 }}>
                        <RadioGroupCP<RefundTP>
                            type={'button'}
                            formStateManager={formStateManager}
                            fieldName={'refundType'}
                            buttonWidth={100}
                            options={[
                                { value: 'full', content: 'Total' },
                                { value: 'partial', content: 'Parcial' },
                            ]}
                        />
                    </FlexCP>

                    {
                        formStateManager.getFieldValue('refundType') === 'partial' &&
                        <InputCP
                            label={'Valor a ser estornado'}
                            required={true}
                            formStateManager={formStateManager}
                            fieldName={'refundValue'}
                            mask={InputMaskTypeEnum.MONEY}
                            errorMessage={
                                isRefundValueValid()
                                    ? undefined
                                    : 'Valor informado é maior do que o valor da cobrança'
                            }
                            hint={{
                                text: `Valor máximo da cobrança ${MaskUtils.applyMoneyMask(maxPartialValue)}`,
                                type: 'text'
                            }}
                        />
                    }
                </>
            }

            <TextAreaCP
                formStateManager={formStateManager}
                fieldName={'reason'}
                label={'Motivo'}
                required={true}
            />

            <FlexCP justifyContent={'center'}>
                <HelpCP
                    marginTop={30}
                    text={canRefund ? 'Este cancelamento irá gerar estorno!' : 'Este cancelamento NÃO irá gerar estorno'}
                    type={'text'}
                />
            </FlexCP>
        </ModalCP>
    )
}
