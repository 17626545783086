import React from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { WhatsappIconCP } from 'main/common/components/icons/WhatsappIconCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
//import { AppStateUtils } from 'main/common/utils/AppStateUtils'
//import { PlanEnum } from 'main/modules/admin/enums/PlanEnum'
//import { SystemConfig } from 'main/config/SystemConfig'
//import { EnvironmentEnum } from 'main/common/enums/EnvironmentEnum'

/**
 * Encapsula chat. Podem ser intercom ou whatsapp
 */
export function ChatBalloonCP(): JSX.Element {
    const slug = AppStateUtils.getDomainSlug();
    function abrirTicket(){
        window.open('https://onesolucoes.tomticket.com/?account=4157568P22022024034421', '_blank');
    }
    function Ticket(){
        if(slug!='lisolaser')return <></>;
        return (
            <div className='ticket' onClick={abrirTicket}>
                TICKET
            </div>
        )
    }
 //   const loggedUser = AppStateUtils.getLoggedUserData()
 //   const domainData = AppStateUtils.getDomainData()

 //   if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
 //       return <></>

 //   if (domainData === null || loggedUser === null)
  //      return <></>
        
    return (
        <>
            {/* <IntercomCP/> */}

            {
             //   AppStateUtils.getDomainData()!.schema.plan.id === PlanEnum.TRIAL &&
                <WhatsappWrapperSCP>
                    <Ticket/>
                    <a
                        href={'https://api.whatsapp.com/send?phone=5537991243109'}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <WhatsappIconCP size={30} color={'#FFF'}/>
                    </a>
                    <div className="indicador-botao">Suporte</div>
                </WhatsappWrapperSCP>
            }
        </>
    )
}

const WhatsappWrapperSCP = styled.div`

    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    width: 60px;
    height: 60px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background: #5cb85c;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 70px;

    .indicador-botao{
        position: fixed;
        bottom: 5px;
        font-weight: 599;
        text-shadow: 1px 1px white;
    }

    .ticket{
        font-size: 11px;
        position: fixed;
        bottom: 68px;
        width: 50px;
        line-height: 10px;
        background-color: #5cb85c;
        right: 2px;
        border-bottom: 2px solid white;
        padding: 2px;
        text-align: right;
        cursor: pointer;
        color: white;
    }

`
