import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import React, { useEffect, useState } from 'react'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { ISaleResponseDTO } from 'main/modules/sale/services/sale/dtos/responses/ISaleResponseDTO'
import { AddressUtils } from 'main/modules/people/utils/AddressUtils'
import { ButtonTypeTP } from 'main/common/components/button/inner/ButtonTypeTP'
import { NfeStatusEnum } from 'main/modules/sale/enums/NfeStatusEnum'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { ButtonSizeTP } from 'main/common/components/button/inner/ButtonSizeTP'
import { SaleGenerateNfeRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleGenerateNfeRequestDTO'
import { NFeResponseDTO } from 'submodules/nerit-payments-sdk/services/nfe/dtos/response/NFeResponseDTO'
import { NFeCustomArgsTP } from 'submodules/neritclin-sdk/services/sale/sale/types/NFeCustomArgsTP'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'

type ButtonNFeTP = {
    title?: string,
    type?: ButtonTypeTP,
    tooltip?: string,
    onClick?: () => void,
    hide?: boolean
}

interface ICPProps {
    viewMode: 'saleNfe' | 'installmentNfe'
    size?: ButtonSizeTP
    sale: ISaleResponseDTO
    nfe?: NFeResponseDTO<NFeCustomArgsTP>
    financialTransactionCode?: number
    onLoadNfe?: (nfe?: NFeResponseDTO<NFeCustomArgsTP>) => void
    onReloadNfe?: () => void
}

/**
 * Faz a requisição para o payments para gerar e/ou baixar o pdf na nota se já estiver gerada.
 */
export function ButtonNFeCP(props: ICPProps): JSX.Element {

    const [showPersonForm, setShowPersonForm] = useState<boolean>(false)
    const [buttonInfo, setButtonInfo] = useState<ButtonNFeTP>({
        title: 'Nota Fiscal',
        tooltip: 'Obtendo detalhes da Nota Fiscal',
        type: 'ghost'
    })

    const getNFeRequest = useRequest<NFeResponseDTO<NFeCustomArgsTP>>()
    useEffect(onGetNFeRequestChange, [getNFeRequest.isAwaiting])

    const generateNFeRequest = useRequest<NFeResponseDTO<NFeCustomArgsTP>>()
    useEffect(onGenerateNFeRequestChange, [generateNFeRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.viewMode === 'installmentNfe') {
            defineButtonByStatus(props.nfe)
            return
        }

        getNFeRequest.runRequest(SaleRequests.getSaleNfe(props.sale.code))
    }

    /**
     * Retorno ao buscar dados da venda na api.
     */
    function onGetNFeRequestChange(): void {

        if (getNFeRequest.isAwaiting || !getNFeRequest.wasTried)
            return
        props.onLoadNfe?.(getNFeRequest.responseData)

        if (!(getNFeRequest.isSuccess && !!getNFeRequest.responseData)) {

            // Se o retorno foi de nao encontrado OK, ainda nao tem nota
            // Se for outro erro, mostra mensagem para o usuario
            if (![HttpStatusEnum.NOT_FOUND].includes(getNFeRequest.responseStatus!))
                return NotificationHelper.error('Ops', 'Ocorreu algum erro ao buscar a NFe')
        }

        defineButtonByStatus(getNFeRequest.responseData)
    }

    /**
     * Gera a NFe.
     */
    function generateNFe(): void {

        if (props.sale.status === SaleStatusEnum.CANCELLED)
            return NotificationHelper.warning('Ops', 'A venda está cancelada!')

        // verfica se o pagador tem todos os campos de endereço cadastrados para geraçao da nota
        // caso não tenha, abre o form de ediçao para que o usuario termine o cadastro
        if (!AddressUtils.checkIfAddressIsComplete(props.sale.personCustomerPayer.address)) {
            setShowPersonForm(true)
            NotificationHelper.warning('Ops!', 'Complete o endereço do cliente para emissão da nota fiscal')
            return
        }

        if (!props.financialTransactionCode && props.viewMode === 'installmentNfe')
            return NotificationHelper.error('Ops', 'Pedido para gerar por parcela, porém código não informado')

        let dto: SaleGenerateNfeRequestDTO = {
            saleCode: props.sale.code
        }
        if (props.viewMode === 'installmentNfe')
            dto.finacialTransactionCode = props.financialTransactionCode

        generateNFeRequest.runRequest(SaleRequests.createNFe(dto))
    }

    /**
     * Retorno da geracao da nota pela API.
     */
    function onGenerateNFeRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(generateNFeRequest, 'Erro ao tentar gerar nota fiscal!'))
            return

        // Atualiza informacoes
        defineButtonByStatus(generateNFeRequest.responseData)
    }

    /**
     * Define os parametros do Botao de NFe de acordo com o status caso tenha NFe, ou libera para gerar.
     */
    function defineButtonByStatus(nfeResult?: NFeResponseDTO<NFeCustomArgsTP>): void {
        if (!nfeResult) {

            // Se a visao for de venda e esta configurado o sistema para gerar por parcela, nao deve mostrar o botao.
            // Faz esse tratamento apenas se nao encontrou, pois se tiver NFe para alguma venda tem que mostrar o botao com a nota da venda.
            if (props.viewMode === 'saleNfe' && 
                AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.shouldIssueNFEByInstallment
            && !(props.sale.paymentMethods.includes(PaymentMethodEnum.CREDIT_CARD) && AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.sale?.ignorarCartaoSemRecorrencia)
            ) {
                setButtonInfo({ hide: true })
                return
            }

            setButtonInfo({
                title: 'Gerar Nota Fiscal',
                type: 'primary',
                onClick: generateNFe
            })
            return
        }

        switch (nfeResult.status) {

            case NfeStatusEnum.AUTHORIZED:
                setButtonInfo({
                    title: 'Baixar NFe',
                    type: 'primary',
                    onClick: () => downloadNfe(nfeResult.url)
                })
                break

            case NfeStatusEnum.CANCELED:
                setButtonInfo({
                    title: 'NFe Cancelada',
                    type: 'danger',
                    tooltip: 'A NFe foi cancelada, clique para baixar',
                    onClick: () => downloadNfe(nfeResult.url)
                })
                break

            case NfeStatusEnum.PENDING:
                setButtonInfo({
                    title: '... Gerando NFe ...',
                    type: 'default',
                    tooltip: 'A NFe está sendo gerada junto à prefeitura. Clique para atualizar o status.',
                    onClick: props.onReloadNfe ?? init
                })
                break

            case NfeStatusEnum.CANCELLATION_DENIED:
                setButtonInfo({
                    title: 'Erro NFe',
                    type: 'ghost',
                    tooltip: `Não foi possível cancelar NFe junto à prefeitura. ${nfeResult.statusReason ? nfeResult.statusReason : ''}}`,
                    onClick: () => downloadNfe(nfeResult.url)
                })
                break

            case NfeStatusEnum.DENIED:
                setButtonInfo({
                    title: 'Erro NFe',
                    type: 'ghost',
                    tooltip: `Não foi possível gerar a NFe junto à prefeitura. ${nfeResult.statusReason ? nfeResult.statusReason : ''}}. Clique para regerar a NFe.`,
                    onClick: generateNFe
                })
                break

            default:
                break
        }
    }

    /**
     * Faz download da NFe
     */
    function downloadNfe(nfeUrl?: string): void {
        if (!nfeUrl)
            return

        window.open(nfeUrl, '_blank')
    }

    return (
        <>
            {
                !buttonInfo.hide &&
                <ButtonCP
                    type={buttonInfo.type}
                    onClick={buttonInfo.onClick}
                    tooltip={buttonInfo.tooltip}
                    loading={generateNFeRequest.isAwaiting || getNFeRequest.isAwaiting}
                    icon={'audit'}
                    disabled={props.sale.saleTotalValuesData.totalValue === 0 || !buttonInfo.onClick}
                    size={props.size}
                >
                    {buttonInfo.title}
                </ButtonCP>
            }

            <DrawerPersonDataCP
                visible={showPersonForm}
                personCode={props.sale.personCustomerPayer.code}
                segmentType={SegmentTypeEnum.CUSTOMER}
                onClose={() => setShowPersonForm(false)}
                onSave={(person) => {
                    props.sale.personCustomerPayer = person
                    setShowPersonForm(false)
                }}
            />
        </>
    )
}
