import React, { ReactNode } from 'react'
import { Descriptions } from 'antd'
import styled from 'main/config/theme/styledWithTheme'

const CLASS_BOLD_FIRST_LINE = 'description_first_row_bold'

interface IDescriptionCPProps {
    children: ReactNode
    title?: string
    bordered?: boolean
    boldFirstRow?: boolean
}

/**
 * COMPONENTE para exibir detalhes
 *
 * @author renatofs
 */
export function DescriptionCP(props: IDescriptionCPProps): JSX.Element {

    return (
        <DescriptionSCP className={!!props.boldFirstRow ? CLASS_BOLD_FIRST_LINE : ''}>
            <Descriptions
                title={props.title}
                column={1}
                layout={'horizontal'}
                bordered={props.bordered}
            >
                {props.children}
            </Descriptions>
        </DescriptionSCP>
    )
}

const DescriptionSCP = styled.div`

    .ant-descriptions-item-label {
      font-weight: 600;
    }
  
    .ant-descriptions-row > td {
      padding-bottom: 10px !important;
    }
  
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: .8rem;
    }
    
    &.${CLASS_BOLD_FIRST_LINE} {
        .ant-descriptions-row {
            &:first-child {
                .ant-descriptions-item-label,
                .ant-descriptions-item-content {
                    font-weight: bold;
                    font-size: .75rem;
                }
            }
        }
    }
`
