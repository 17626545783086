import React, { useState } from 'react'
import styled from 'styled-components'
import { DateRangePickerCP } from 'main/common/components/date-range-picker/DateRangePickerCP'
import { TabsSalesAnalysisCP } from 'main/modules/reports/components/tabs-sales-analysis/TabsSalesAnalysisCP'
import moment from 'moment'
import { ListSalesSummaryCP } from 'main/modules/financial/components/sales-summary/ListSalesSummaryCP'
import { ListSalesByPaymentMethodCP } from 'main/modules/financial/components/sales-by-payment-method/ListSalesByPaymentMethodCP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { CardCP } from 'main/common/components/card/CardCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { TextCP } from 'main/common/components/text/TextCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

const DEFAULT_FILTERS: IDateRangeFilter = {
    beginDate: moment().add(-5, 'days').toDate(),
    endDate: moment().add(5, 'days').toDate()
}

/**
 * Relatorios de venda
 */
export function FinancialSalesReportCP(): JSX.Element {

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>(DEFAULT_FILTERS)

    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
    }

    return (
        <ContentWrapperSCP>
            <ContentSCP>

                <FlexCP justifyContent={'center'}>
                    <CardCP innerSpacing={'small'}>
                        <DateRangePickerCP
                            value={dateFilters}
                            onChange={onChangeDates}
                            fastFilter={'all'}
                            allowClear={false}
                        />
                    </CardCP>
                </FlexCP>

                <ContainerRowSCP>
                    <CardFlex40SCP>
                        <CardCP title={'Análise das Vendas'} innerSpacing={'small'}>
                            <ListSalesSummaryCP
                                franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}
                                dateFilter={dateFilters}
                            />
                        </CardCP>
                    </CardFlex40SCP>
                    <CardFlex60SCP style={{ marginTop: '23px' }}>
                        <ListSalesByPaymentMethodCP
                            franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}
                            dateFilter={dateFilters}
                        />
                    </CardFlex60SCP>
                </ContainerRowSCP>

                <TabsSalesAnalysisCP
                    dateFilter={dateFilters}
                    franchiseCodes={[AppStateUtils.getCurrentFranchise()!.code]}
                />

                <FlexCP justifyContent={'flex-end'} marginBottom={20} marginRight={20}>
                    <TextCP text={'* As GRATUIDADES não estão sendo consideradas neste relatório'} fontStyle={'italic'} color={ThemeAnt.darkGray}/>
                </FlexCP>
            </ContentSCP>
        </ContentWrapperSCP>
    )
}

const ContainerRowSCP = styled.div`
  display: flex;
  justify-content: center;
`

const CardFlex40SCP = styled.div`
  width: 40%;
`

const CardFlex60SCP = styled.div`
  width: 60%;

  .ant-list-item {
    padding: 5.5px 0 !important;
  }
`

const ContentSCP = styled.div`
    width: 100%;
`

const ContentWrapperSCP = styled.div`
    margin-top: 25px;
    display: flex;
    overflow-x: auto;
    justify-content: center;
`
