import { IPersonContactResponseDTO } from 'main/modules/people/services/person-contact/dtos/response/IPersonContactResponseDTO'
import React, { useState } from 'react'
import { PopoverPersonContactCP } from 'main/modules/people/components/popover-person-contact/PopoverPersonContactCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextActivityDescriptionCP } from 'main/modules/sales-funnel/components/text-activity-description/TextActivityDescriptionCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { PersonContactEntityEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityEnum'
import { PersonContactUtils } from 'main/modules/people/utils/PersonContactUtils'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'

interface IPersonContactTimelineItemICPProps {
    activity: IPersonContactResponseDTO
    entityCode: number
    entity: PersonContactEntityEnum
    onActionsDone: (conversionType?: OpportunityDrawerActionTP) => void
}

/**
 * Componente contendo linha do tempo de Interacoes
 */
export function PersonContactTimelineItemICP(props: IPersonContactTimelineItemICPProps): JSX.Element {

    const [isEditPopoverVisible, setIsEditPopoverVisible] = useState<boolean>(false)

    return (
        <>
            <FlexCP justifyContent={'space-between'}>

                <TextActivityDescriptionCP
                    showTitle={true}
                    showDate={false}
                    activity={props.activity}
                    showFullTitle={true}
                />

                <FlexCP>
                    <PopoverPersonContactCP
                        visible={isEditPopoverVisible}
                        onSave={(conversionType) => {
                            setIsEditPopoverVisible(false)
                            props.onActionsDone(conversionType)
                        }}
                        onClose={() => setIsEditPopoverVisible(false)}
                        entityCode={props.entityCode}
                        entity={props.entity}
                        activity={props.activity}
                    >
                        <ButtonCP
                            icon={'edit'}
                            type={'ghost'}
                            onClick={() => setIsEditPopoverVisible(true)}
                            size={'small'}
                            marginRight={5}
                        />
                    </PopoverPersonContactCP>
                </FlexCP>
            </FlexCP>

            <TextCP
                text={PersonContactUtils.getActivityStatusName(props.activity)}
                icon={'clock-circle'}
                color={PersonContactUtils.getActivityStatusColor(props.activity)}
                size={'small'}
            />

            <TextCP text={DateUtils.getPresentableDateHour(props.activity.beginDate)} icon={'calendar'} color={'#cacaca'} size={'small'}/>
            <TextCP text={props.activity.userResponsible.name} icon={'user'} color={'#cacaca'} size={'small'}/>
        </>
    )
}
