import { IWizardSteps } from 'main/common/components/wizard/inner/IWizardSteps'
import { IVoucherResponseDTO } from 'main/modules/sale/services/voucher/dtos/response/IVoucherResponseDTO'
import { VoucherDiscountTypeEnum } from 'main/modules/sale/services/sale/enums/VoucherDiscountTypeEnum'
import { ISaleProductReleaseRequestDTO } from 'main/modules/sale/services/sale/dtos/requests/ISaleProductReleaseRequestDTO'
import { ISaleProductItem } from 'main/modules/sale/components/sale-item-table/inner/interfaces/ISaleProductItem'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SaleProductDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleProductDataFormModel'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { SaleBasicDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleBasicDataFormModel'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import * as _ from 'lodash'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { PaymentTypeEnum } from 'main/modules/sale/services/sale/enums/PaymentTypeEnum'
import { SaleOriginEnum } from 'submodules/neritclin-sdk/services/sale/sale/enums/SaleOriginEnum'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { ICreateChargeInstallment } from 'main/modules/sale/components/table-create-charge-installments/inner/ICreateChargeInstallment'
import { SaleSaveRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/SaleSaveRequestDTO'
import { SaleChargeRequestDTO } from 'submodules/neritclin-sdk/services/sale/sale/dtos/requests/inner/SaleChargeRequestDTO'

/**
 * Utilitario do processo de criacao de uma venda.
 */
export const CreateSaleUtils = {

    getWizardSteps(
        contentBasicData: JSX.Element,
        contentProductData: JSX.Element,
        contentPaymentData: JSX.Element,
        contentConfirmData: JSX.Element,
        canContinueBasicData: () => Promise<boolean>,
        canContinueProdutcData: () => boolean,
        canContinuePaymentData: () => boolean,
    ): IWizardSteps[] {
        return [
            {
                key: 'salesData',
                title: 'Cliente',
                content: contentBasicData,
                icon: 'user',
                nextAction: canContinueBasicData
            },
            {
                key: 'salesProduct',
                title: 'Pacotes',
                content: contentProductData,
                icon: 'shopping-cart',
                canContinue: canContinueProdutcData
            },
            {
                key: 'paymentMethod',
                title: 'Pagamento',
                content: contentPaymentData,
                icon: 'file-search',
                canContinue: canContinuePaymentData
            },
            {
                key: 'saleSummary',
                title: 'Resumo',
                content: contentConfirmData,
                icon: 'check-circle',
            }
        ]
    },

    /**
     * Calcula valor de um cupom de desconto.
     */
    calculateVoucherValue(voucher: IVoucherResponseDTO, value: number): number {

        if (voucher.discountType === VoucherDiscountTypeEnum.VALUE)
            return voucher.discountValue

        return voucher.discountValue * value
    },

    /**
     * Monta DTO que realiza a venda
     */
    prepareDataToSell(
        basicDataFormStateManager: IFormStateManager<SaleBasicDataFormModel>,
        productDataFormStateManager: IFormStateManager<SaleProductDataFormModel>,
        paymentDataFormStateManager: IFormStateManager<SalePaymentDataFormModel>,
        opportunityCode?: number
    ): SaleSaveRequestDTO | undefined {

        const basicDataFormValues = basicDataFormStateManager.getFormValues()
        const productDataFormValues = productDataFormStateManager.getFormValues()
        const paymentDataFormValues = paymentDataFormStateManager.getFormValues()
        if (!basicDataFormValues || !paymentDataFormValues || !productDataFormValues)
            return

        const saleItems: ISaleProductReleaseRequestDTO[] = productDataFormValues.products.map((product: ISaleProductItem) => {
            return ({
                releaseFranchiseCode: product.code,
                quantity: product.quantity,
            })
        })

        // Monta DTO das cobrancas
        const charges: SaleChargeRequestDTO[] = []
        _.each(paymentDataFormValues.saleCharges, (_charge: ISaleCharge) => {

            const chargeItem: SaleChargeRequestDTO = {
                paymentAccountCode: _charge.paymentAccountCode,
                paymentMethod: _charge.paymentMethod,
                paymentType: _charge.paymentType,
                authNumber: _charge.authNumber,
                nsu: _charge.nsu,
                installments: _charge.installments,
                hasGracePeriod: _charge.hasGracePeriod ?? false,
            }

            charges.push(chargeItem)
        })

        // Se o valor do voucher nao foi alterado, envia ele. Caso tenha sido alterado nao envia o voucher e apenas o discountvalue
        let shouldUseVoucher = false
        if (paymentDataFormValues.voucher) {
            const voucherDiscountValue = CreateSaleUtils.calculateVoucherValue(paymentDataFormValues.voucher, paymentDataFormValues.originalTotalValue)
            shouldUseVoucher = voucherDiscountValue === MoneyUtils.convertToFloat(paymentDataFormValues.discountValue)
        }

        return {
            personCustomerCode: basicDataFormValues.personCustomerCode,
            personPayerCode: basicDataFormValues.personPayerCode,
            personSellerCode: basicDataFormValues.sellerCode,
            opportunityCode,
            sourceCode: basicDataFormValues.sourceCode,
            sourcePersonLinkCode: basicDataFormValues.sourcePersonLinkCode,
            value: paymentDataFormValues.originalTotalValue,
            saleDate: basicDataFormValues.saleDate.toDate(),
            voucherCode: shouldUseVoucher ? paymentDataFormValues.voucher?.code : undefined,
            discountValue: shouldUseVoucher ? undefined : MoneyUtils.convertToFloat(paymentDataFormValues.discountValue),
            saleItems,
            charges: SystemUtils.isEmpty(charges) ? undefined : charges,
            origin: basicDataFormValues.saleOrigin,
        }
    },

    /**
     * Monta modelos da tela a partir de dados de entrada.
     */
    mountFormModel(opportunity?: IOpportunityResponseDTO, customer?: INameAndCodeResponseDTO): SaleBasicDataFormModel {

        if (!opportunity) {

            return new SaleBasicDataFormModel({
                personCustomerCode: customer?.code,
                personCustomer: customer,
                saleDate: new Date(),
                saleOrigin: SaleOriginEnum.FACE_TO_FACE,
                sellerCode: AppStateUtils.getLoggedUserData()!.user.code,
                personSeller: {
                    code: AppStateUtils.getLoggedUserData()!.user.code,
                    name: AppStateUtils.getLoggedUserData()!.user.name,
                },
            })
        }

        return new SaleBasicDataFormModel({
            personCustomerCode: opportunity.personCustomer!.code,
            personCustomer: opportunity.personCustomer,
            sellerCode: opportunity.userResponsible.code,
            personSeller: opportunity.userResponsible,
            sourceCode: opportunity.source.code,
            source: opportunity.source,
            sourcePersonLinkCode: opportunity.sourcePersonLink?.code,
            sourcePersonLink: opportunity.sourcePersonLink,
            personPayerCode: opportunity.personCustomer!.code,
            saleDate: new Date(),
            saleOrigin: SaleOriginEnum.FACE_TO_FACE,
        })
    },

    /**
     * Obtem as cobrancas que devera ser passado o cartao fisicamente.
     */
    getPhysicalCharges(paymentDataFormStateManager: IFormStateManager<SalePaymentDataFormModel>): ISaleCharge[] {
        return _.filter(paymentDataFormStateManager.getFieldValue('saleCharges'), (charge: ISaleCharge) => charge.paymentType === PaymentTypeEnum.PHYSICAL)
    },

    /**
     * Verifica se tem parcelas ja pagas na cobranca..
     */
    hasPaidInstallments(installments: ICreateChargeInstallment[]): boolean {
        return !!_.find(installments, ins => ins.isPaid)
    },

    /**
     * Verifica se o somatorio das parcelas bate com o total ta venda.
     */
    isInstallmentAndTotalValueValids(sumInstallmentValues: number, totalSaleValue: number): boolean {
        return sumInstallmentValues === totalSaleValue
    },

}
