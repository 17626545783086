import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { MarketingViewTP } from 'main/modules/marketing/screens/screen-marketing/inner/MarketingViewTP'
import {
    MARKETING_SUBMENU_EMAIL_KEY,
    MARKETING_SUBMENU_SMS_KEY,
    MARKETING_SUBMENU_TRANSACTIONAL_KEY,
    MarketingScreenUtils
} from 'main/modules/marketing/screens/screen-marketing/inner/MarketingScreenUtils'
import React from 'react'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * TELA de listagem de Disparos
 */
export function ScreenMarketing(): JSX.Element {

    return (
        <LayoutSubmenuCP<MarketingViewTP>
            defaultSubmenu={'emailSent'}
            menuTitle={'Marketing'}
            loggedDomain={AppStateUtils.getDomainSlug()}
            submenuConfigs={MarketingScreenUtils.getSubMenuConfigs()}
            defaultOpenKeys={[MARKETING_SUBMENU_EMAIL_KEY, MARKETING_SUBMENU_SMS_KEY, MARKETING_SUBMENU_TRANSACTIONAL_KEY]}
        />
    )

}
