import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'main/config/SystemConfig'
import { IProductReleaseFranchiseSaveRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/IProductReleaseFranchiseSaveRequestDTO'
import { IReleaseFranchiseSearchRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/IReleaseFranchiseSearchRequestDTO'
import { ISearchProductReleaseRequestDTO } from 'main/modules/products/services/product-release-franchise/dtos/request/ISearchProductReleaseRequestDTO'

/**
 * Classe auxiliar para realizar requisições do modulo RELEASES com acoes especificas de uma franquia
 *
 */
export class ProductReleaseFranchiseRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/product-releases/franchise`

    static searchProductReleasesFranchiseNameAndPrice = (dto?: IReleaseFranchiseSearchRequestDTO): RequestConfigTP => ({
        url: `${ProductReleaseFranchiseRequests._MODULE_BASE}/name-code-price`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static searchProductReleasesFranchise = (dto: ISearchProductReleaseRequestDTO): RequestConfigTP => ({
        url: `${ProductReleaseFranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static countUnsetProductReleasesFranchise = (): RequestConfigTP => ({
        url: `${ProductReleaseFranchiseRequests._MODULE_BASE}/count-unset`,
        method: HttpMethodEnum.GET
    })

    static update = (dto: IProductReleaseFranchiseSaveRequestDTO, code: number): RequestConfigTP => ({
        url: `${ProductReleaseFranchiseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static create = (dto: IProductReleaseFranchiseSaveRequestDTO): RequestConfigTP => ({
        url: `${ProductReleaseFranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}
