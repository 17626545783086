import React from 'react'
import styled from 'styled-components'
import { TextCP } from 'main/common/components/text/TextCP'
import { SegmentExpForEditionTP } from 'main/modules/segment/types/expression/SegmentExpForEditionTP'
import { SegmentExpressionTP } from 'main/modules/segment/types/expression/SegmentExpressionTP'
import { StringUtils } from 'main/common/utils/StringUtils'

type ExpressionBlockTP = {
    expression?: SegmentExpressionTP | SegmentExpForEditionTP,
    title: string,
}

interface IExpressionPreviewICPProps {
    height?: number
    innerExpression?: SegmentExpForEditionTP
    outerExpression?: SegmentExpressionTP
}

/**
 * COMPONENTE
 * Exibe objetos que representam a expressao de 01 lista de segmentacao (valor na forma editavel e/ou na forma trafegada com a api).
 */
export function ExpressionPreviewICP(props: IExpressionPreviewICPProps): JSX.Element {

    const expressionBlocks: ExpressionBlockTP[] = [
        { expression: props.innerExpression, title: 'Expressão [edicao]:' },
        { expression: props.outerExpression, title: 'Expressão [finalizada]:' },
    ]

    return (
        <WrapperSCP>
            {
                expressionBlocks.map(expBlock => {

                    if (!expBlock.expression)
                        return null

                    return (
                        <BlockSCP key={StringUtils.getSlugStyleString(expBlock.title)}>
                            <LabelContainerSCP>
                                <TextCP text={expBlock.title}/>
                            </LabelContainerSCP>

                            <PreviewSCP
                                disabled={true}
                                value={JSON.stringify(expBlock.expression, null, 4)}
                                height={props.height}
                            />
                        </BlockSCP>
                    )
                })
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    height: 300px;
    overflow-x: auto;
    flex-direction: row;
`

const BlockSCP = styled.div`
    display: flex;
    border: 1px solid #b7b7b7;
    width: 100%;
    padding: 5px 0;
    flex-direction: column;
    align-items: center;
`

const LabelContainerSCP = styled.div`
    align-self: flex-start;
    padding: 0 0 3px 10px;
`

const PreviewSCP = styled.textarea<{ height?: number }>`
    width: 97%;
    min-height: 260px;
`
