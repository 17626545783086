import { ModalCP } from 'main/common/components/modal/ModalCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IScheduleBlockIntervalRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IScheduleBlockIntervalRequestDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { PayFinancialTransactionFormValidator } from 'main/modules/financial/components/modal-pay-financial-transaction/inner/PayFinancialTransactionFormValidator'
import { IFinancialTransactionPaymentRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionPaymentRequestDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IFinancialTransactionResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionResponseDTO'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { SelectPaymentMethodCP } from 'main/modules/sale/components/select-payment-method/SelectPaymentMethodCP'

interface IModalPayFinancialTransactionCPProps {
    visible: boolean
    financialTransactionCode: number
    onClose: () => void
    onSave: () => void
}

/**
 * Componente de modal com dados para pagamento de uma transacao financeira.
 */
export function ModalPayFinancialTransactionCP(props: IModalPayFinancialTransactionCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<PayFinancialTransactionFormValidator>(new PayFinancialTransactionFormValidator())
    const formStateManager = useFormStateManager<PayFinancialTransactionFormValidator>(formValidator)

    useEffect(init, [props.visible])

    const payRequest = useRequest<IScheduleBlockIntervalRequestDTO>('none')
    useEffect(onPayRequestChange, [payRequest.isAwaiting])

    const [loadedFinancialTransaction, setLoadedFinancialTransaction] = useState<IFinancialTransactionResponseDTO>()
    const getFinancialTransactionRequest = useRequest<IFinancialTransactionResponseDTO>()
    useEffect(onGetFinancialTransactionRequestChange, [getFinancialTransactionRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        setLoadedFinancialTransaction(undefined)
        formStateManager.reset(new PayFinancialTransactionFormValidator({}))

        getFinancialTransactionRequest.runRequest(FinancialTransactionsRequests.findOne(props.financialTransactionCode))
    }

    /**
     * Retorno da requisicao para pegar a transacao financeira.
     */
    function onGetFinancialTransactionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFinancialTransactionRequest, 'Erro obter conta bancária para atualizacao'))
            return

        const financialTransactionResult = getFinancialTransactionRequest.responseData!
        setLoadedFinancialTransaction(financialTransactionResult)

        setFormValidator(new PayFinancialTransactionFormValidator({
            paidValue: MaskUtils.applyMoneyMask(Math.abs(financialTransactionResult.value)),
            paidDate: DateUtils.toDate(financialTransactionResult.expirationDate, DateFormatEnum.US_WITHOUT_TIME),
            bankAccountCode: financialTransactionResult.bank.code,
            paymentMethod: financialTransactionResult.paymentMethod
        }))
    }

    /**
     * Paga a transacation Financeira.
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: IFinancialTransactionPaymentRequestDTO = {
            paymentDate: DateUtils.getFormatted(formValues.paidDate, DateFormatEnum.US_WITH_TIME_H_M),
            paymentValue: MoneyUtils.convertToFloat(formValues.paidValue),
            bankAccountCode: formValues.bankAccountCode,
            paymentMethod: formValues.paymentMethod
        }
        const t = FinancialTransactionsRequests.payConfig(props.financialTransactionCode, dto);
        console.log("PARA ", t);
        payRequest.runRequest(t);
    }

    /**
     * Retorno da API ao salvar pagar.
     */
    function onPayRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(payRequest, 'Erro ao pagar fatura', true))
            return

        NotificationHelper.success('Yes!', 'Fatura paga com sucesso!')
        props.onSave()
    }

    return (
        <ModalCP
            title={'Pagar Fatura'}
            width={440}
            visible={props.visible}
            onCancel={props.onClose}
            onOk={onSave}
            loading={getFinancialTransactionRequest.isAwaiting}
            actionLoading={payRequest.isAwaiting}
        >
            <RowCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Data de Pagamento'}
                        required={true}
                        type={'date'}
                        formStateManager={formStateManager}
                        fieldName={'paidDate'}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Valor Pago'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'paidValue'}
                        mask={InputMaskTypeEnum.MONEY}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <ColumnCP size={12}>
                    <AutocompleteBankAccountCP
                        formStateManager={formStateManager}
                        label={'Conta Bancária'}
                        fieldName={'bankAccountCode'}
                        initialOptions={loadedFinancialTransaction?.bank ? [loadedFinancialTransaction?.bank] : undefined}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <SelectPaymentMethodCP
                        label={'Forma de pagamento'}
                        formStateManager={formStateManager}
                        fieldName={'paymentMethod'}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

        </ModalCP>
    )
}
