import { ColumnProps } from 'antd/lib/table'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { IFollowUpsByFranchiseListItemResponseDTO } from 'main/modules/dashboard/services/dtos/response/IFollowUpsByFranchiseListItemResponseDTO'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import React from 'react'
import { MathUtils } from 'main/common/utils/MathUtils'

/**
 * UTILITARIOS da tabela de leads por unidade.
 */
export const FollowupsByFranchiseTableUtils = {

    getTableColumns(): Array<ColumnProps<IFollowUpsByFranchiseListItemResponseDTO>> {
        return [
            {
                title: 'Unidade',
                dataIndex: 'franchise',
                render: (text, dto) => dto.franchise.name
            },
            {
                title: 'Total opportunidades',
                dataIndex: 'total',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalOpen, 2)
            },
            {
                title: 'p/ Hoje',
                dataIndex: 'today',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalPlannedToday, 2)
            },
            { key: 'prog-f-int-today',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={'Progresso de realização das atividades de hoje'}
                        percent={MathUtils.getPercentage(dto.totalPlannedToday, dto.totalContactedToday, true)}
                        gradientColor={true}
                    />
                ) },
            { title: 'Em relação à meta',
                key: 'prog-follo-today',
                render: (text, dto) => (
                    <ProgressCP
                        width={80}
                        tooltip={`Taxa dos planejados do dia em relação à meta de: ${dto.plannedTodayTarget}`}
                        percent={MathUtils.getPercentage(dto.totalPlannedToday, dto.plannedTodayTarget, true)}
                        gradientColor={true}
                    />
                ) },
            {
                title: 'Atrasados',
                key: 'delayed',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalDelayed, 2)
            },
            {
                title: 'Sem Atividade',
                key: 'act',
                align: 'right',
                render: (text, dto) => MaskUtils.applyNumberMask(dto.totalWithoutActivities, 2)
            },
        ]
    },

}
