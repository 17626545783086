import { ButtonCP } from 'main/common/components/button/ButtonCP'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FormFilterSchedulerFormModel } from 'main/modules/scheduler/components/form-filter-scheduler/inner/FormFilterSchedulerFormModel'
import { SelectCustomerAndTreatmentCP } from 'main/modules/scheduler/components/select-customer-and-treament/SelectCustomerAndTreatmentCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { CalendarCP } from 'main/common/components/calendar/CalendarCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
    formStateManager: IFormStateManager<FormFilterSchedulerFormModel>
    onSearch: () => void
}

/**
 * COMPONENTE Form com dados para procurar um horario para agendar sessao.
 */
export function FormFilterSchedulerCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <CalendarCP
                currentDate={props.formStateManager.getFieldValue('dateInterval')}
                onChangeSelectedDate={(date) => props.formStateManager.changeFieldValue('dateInterval', { beginDate: date, endDate: date })}
            />

            <SectionInfoWrapperSCP>

                <TitleCP underLine={true} marginBottom={30} textSize={'normal'}>
                    Escolha Cliente/Serviços
                </TitleCP>
                <SelectCustomerAndTreatmentCP
                    onSelectCustomer={(customer) => {
                        props.formStateManager.changeFieldValue('customerCode', customer.code)
                        props.formStateManager.changeFieldValue('customerName', customer.name)
                    }}
                    onChangeSessions={(sessions) => {
                        props.formStateManager.changeFieldValue('sessions', sessions)
                    }}
                    renderComponentsConfig={{
                        renderSelectsAs: 'row',
                        showResourceColumnOnTable: false,
                        showTimeColumnOnTable: false,
                        showDurationColumnOnTable: true,
                        showActionsTable: true,
                    }}
                />

                <TitleCP underLine={true} marginBottom={30} marginTop={20} textSize={'normal'}>
                    Filtre por um Profissional
                </TitleCP>
                <RowCP>
                    <ColumnCP size={24}>
                        <AutocompleteUserCP
                            label={'Profissional'}
                            onlyWithWorkSchedule={true}
                            formStateManager={props.formStateManager}
                            fieldName={'userProfessionalCode'}
                        />
                    </ColumnCP>
                </RowCP>

                <FlexCP justifyContent={'flex-end'}>
                    <ButtonCP
                        marginTop={20}
                        type={'primary'}
                        onClick={props.onSearch}
                    >
                        Ver horários
                    </ButtonCP>
                </FlexCP>
            </SectionInfoWrapperSCP>
        </WrapperSCP>
    )
}

const SectionInfoWrapperSCP = styled.div`
  margin: 20px 10px 0 10px
`

const WrapperSCP = styled.div`
  overflow: auto;
`
