import { TextCP } from 'main/common/components/text/TextCP'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { SaleItemTableUtils } from 'main/modules/sale/components/sale-item-table/inner/SaleItemTableUtils'
import { SaleItemTableCP } from 'main/modules/sale/components/sale-item-table/SaleItemTableCP'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SaleProductDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleProductDataFormModel'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { TableSaleCharges } from 'main/modules/sale/components/table-sale-charges/TableSaleCharges'

interface ISaleSummaryCPProps {
    productDataFormStateManager: IFormStateManager<SaleProductDataFormModel>
    paymentDataFormStateManager: IFormStateManager<SalePaymentDataFormModel>
}

export function SaleSummaryStepCP(props: ISaleSummaryCPProps): JSX.Element {

    return (
        <SaleSummaryWrapperSCP>

            <AlertCP
                message={'Confirme os valores antes de finalizar'}
                type={'warning'}
            />

            <TitleCP underLine={true} marginBottom={0}>Pacotes</TitleCP>
            <SaleItemTableCP
                productList={props.productDataFormStateManager.getFieldValue('products')}
                columns={SaleItemTableUtils.getSalesDataColumns()}
            />

            <TitleCP underLine={true} marginBottom={0}>Forma de Pagamento</TitleCP>
            {
                props.paymentDataFormStateManager.getFieldValue('totalValue') === 0
                    ?
                    <AlertCP message={'Não possui parcelas, valor da venda é R$ 0,00'} type={'warning'}/>
                    :
                    <TableSaleCharges
                        charges={props.paymentDataFormStateManager.getFieldValue('saleCharges')}
                    />
            }

            <FlexCP justifyContent={'flex-end'}>
                <div style={{ textAlign: 'right' }}>
                    <TextCP text={`Total: ${MaskUtils.applyMoneyMask(props.paymentDataFormStateManager.getFieldValue('originalTotalValue'))}`}/>
                    <TextCP text={`Desconto: ${MaskUtils.applyMoneyMask(MoneyUtils.convertToFloat(props.paymentDataFormStateManager.getFieldValue('discountValue')))}`}/>
                    <TextCP
                        strong={true}
                        size={'large'}
                        text={`Total líquido: ${MaskUtils.applyMoneyMask(props.paymentDataFormStateManager.getFieldValue('totalValue'))}`}
                    />
                </div>
            </FlexCP>
        </SaleSummaryWrapperSCP>
    )
}

const SaleSummaryWrapperSCP = styled.div`
    display: grid;
    min-width: 100%;
    row-gap: 25px;
`
