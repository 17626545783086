import { Icon } from 'antd'
import React from 'react'
import { IIconProps } from 'main/common/components/icons/inner/IIconProps'

interface ICaretIconCPProps extends IIconProps {
    direction: 'left' | 'right'
}

/**
 * ICONE
 * Setas triangulares.
 * @author Stella
 */
export function CaretIconCP(props: ICaretIconCPProps): JSX.Element {

    let type

    switch (props.direction) {
        case 'left':
            type = 'caret-left'
            break

        case 'right':
        default:
            type = 'caret-right'
            break
    }
    return (
        <Icon
            type={type}
            style={{
                color: props.color || 'inherit',
                fontSize: props.size,
                ...props.style
            }}
        />
    )

}
