import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { styled } from 'main/config/theme/styledWithTheme'
import { TransientValueTrimmerDebugTP } from 'main/common/transient-value-trimmer/types/TransientValueTrimmerDebugTP'
import { TransientValueTrimmer } from 'main/common/transient-value-trimmer/TransientValueTrimmer'
import { TransientValueTrimmerConfigTP } from 'main/common/transient-value-trimmer/types/TransientValueTrimmerConfigTP'
import { AggregatorUnitICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorUnitICP'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { CssHoverUtils } from 'main/common/utils/CssHoverUtils'
import { CloseIconCP } from 'main/common/components/icons/CloseIconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { SegmentAggForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggForEditionTP'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { AggregatorAddingButtonICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorAddingButtonICP'
import { AggregatorContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorContext'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'

type HoverStateTP = 'wrapper' | 'remove-btn'

const DEBUG_TRIMMER: TransientValueTrimmerDebugTP = 'disabled'
const DELAY_HOVER = 350

interface ISegmentAggregatorCPProps {
    aggregator: SegmentEditableItemTP<SegmentAggForEditionTP>
    onMouse: (mouseIn: boolean) => void
    enableRemove: boolean
}

/**
 * COMPONENTE
 * Componente de montagem de 'agregador' na construcao de filtros de lista de segmentacao.
 */
export const AggregatorICP = ComponentUtils.memoizeCp((props: ISegmentAggregatorCPProps): JSX.Element => {

    const outerUnits = (props.aggregator as SegmentAggForEditionTP).units ?? []

    const [showAddBtn, setShowAddBtn] = useState<boolean>(false)
    const [isWrapperHovered, setIsWrapperHovered] = useState<boolean>(false)
    const [hoverWrapperTrimmer] = useState<TransientValueTrimmer<boolean>>(getHoverTrimmer('wrapper'))
    const [hoverRemoveTrimmer] = useState<TransientValueTrimmer<boolean>>(getHoverTrimmer('remove-btn'))

    const expressionContext = ExpressionContext.use()
    const aggregatorContext = AggregatorContext.use()

    useEffect(initUnitsList, [])
    useEffect(onOuterUnitsChange, [outerUnits])
    useEffect(triggerHoverStatesUpdate, [showAddBtn])
    useEffect(onInnerUnitsChange, [aggregatorContext.state.units])
    useEffect(_setShowAddBtn, [expressionContext.state.expression])
    useEffect(() => props.onMouse(isWrapperHovered), [isWrapperHovered])

    const unitsRef = React.createRef<HTMLDivElement>()
    const addBtnRef = React.createRef<HTMLDivElement>()
    const wrapperRef = React.createRef<HTMLDivElement>()
    const removeBtnRef = React.createRef<HTMLDivElement>()

    function initUnitsList(): void {
        aggregatorContext.actions.initUnitList(outerUnits ?? [])
    }

    function onOuterUnitsChange(): void {
        if (aggregatorContext.state.units.length && !_.isEqual(outerUnits, aggregatorContext.state.units))
            initUnitsList()
    }

    function onInnerUnitsChange(): void {

        if (!aggregatorContext.state.units.length || _.isEqual(aggregatorContext.state.units, outerUnits))
            return

        expressionContext.actions.editUnit({
            itemType: 'agg',
            id: props.aggregator.id,
            units: aggregatorContext.state.units
        })
    }

    function _setShowAddBtn(): void {
        const lastAggregator = _.last(expressionContext.state.expression) as SegmentAggForEditionTP
        const isLastAggregator = (props.aggregator.id === lastAggregator?.id)
        setShowAddBtn(isLastAggregator && !SegmentUtils.isExpressionEmpty(expressionContext.state.expression))
    }

    function getHoverTrimmer(hoverEventType: HoverStateTP): TransientValueTrimmer<boolean> {

        const trimmerConfig: TransientValueTrimmerConfigTP<boolean> = {
            delay: DELAY_HOVER,
            debugType: DEBUG_TRIMMER,
            debugName: hoverEventType,
            onFinish: (mouseIn: boolean): void => trimmerOnFinish(hoverEventType, mouseIn),
        }

        return new TransientValueTrimmer<boolean>(trimmerConfig)
    }

    function trimmerOnFinish(event: HoverStateTP, mouseIn: boolean): void {
        if (event === 'wrapper')
            setIsWrapperHovered(mouseIn)
        else
            aggregatorContext.actions.setIsRemoveBtnHovered(mouseIn)
    }

    function triggerHoverStatesUpdate(): void {

        const isMouseOverWrapper = CssHoverUtils.isHovered(wrapperRef)
        const isMouseOverUnits = CssHoverUtils.isHovered(unitsRef)
        const isMouseOverRemoveBtn = CssHoverUtils.isHovered(removeBtnRef)
        const isMouseOverAddBtn = (showAddBtn && CssHoverUtils.isHovered(addBtnRef))

        hoverWrapperTrimmer.onChange(!isMouseOverUnits && !isMouseOverAddBtn && (isMouseOverWrapper || isMouseOverRemoveBtn))
        hoverRemoveTrimmer.onChange(isMouseOverRemoveBtn)
    }

    function onRemove(): void {
        expressionContext.actions.removeUnit(props.aggregator.id)
        triggerHoverStatesUpdate()
    }

    return (
        <WrapperSCP
            ref={wrapperRef}
            isFullySet={SegmentUtils.isAggregatorFullySet(aggregatorContext.state.units)}
            hover={isWrapperHovered}
            onMouseEnter={() => triggerHoverStatesUpdate()}
            onMouseLeave={() => triggerHoverStatesUpdate()}
        >
            <UnitsWrapperSCP
                ref={unitsRef}
                onMouseEnter={() => triggerHoverStatesUpdate()}
                onMouseLeave={() => triggerHoverStatesUpdate()}
            >
                {
                    aggregatorContext.state.units.map((unit, i: number) => (
                        <AggregatorUnitICP
                            i={i}
                            key={`aggregator_unit_${unit.id}`}
                            unit={unit}
                            onChangeUnitValuePopoverVisibility={() => triggerHoverStatesUpdate()}
                        />
                    ))
                }
            </UnitsWrapperSCP>

            {
                (props.enableRemove && isWrapperHovered) &&
                <TooltipCP text={'Remover Grupo'} placement={'right'}>
                    <RemoveButtonWrapperSCP
                        ref={removeBtnRef}
                        onMouseEnter={() => triggerHoverStatesUpdate()}
                        onMouseLeave={() => triggerHoverStatesUpdate()}
                        className={CLASS_NAME_REMOVE_BTN}
                        onClick={onRemove}
                    >
                        <CloseIconCP size={23} type={'circle'}/>
                    </RemoveButtonWrapperSCP>
                </TooltipCP>
            }

            {
                showAddBtn &&
                <AddAggregatorBtnContainerSCP
                    ref={addBtnRef}
                    onMouseEnter={() => triggerHoverStatesUpdate()}
                    onMouseLeave={() => triggerHoverStatesUpdate()}
                >
                    <AggregatorAddingButtonICP
                        disable={expressionContext.state.isLoading || !SegmentUtils.isExpressionFullySet(expressionContext.state.expression)}
                        onClick={expressionContext.actions.addUnit as (() => void)}
                    />
                </AddAggregatorBtnContainerSCP>
            }
        </WrapperSCP>
    )
})

const CLASS_NAME_REMOVE_BTN = 'aggregator_remove'
const COLOR_BORDER_INITIAL = '#a1b4d6'

const WrapperSCP = styled.div<{ isFullySet: boolean, hover: boolean }>`

    border: 1px solid ${COLOR_BORDER_INITIAL};
    border-radius: 4px;
    padding: ${props => (props.isFullySet ? '7px 26px 7px 7px' : '7px 9px 7px 9px')};
    transition: border-color .3s, box-shadow .3s;
    display: flex;
    align-items: center;
    margin: 4px 0;
    position: relative;
    cursor: ${props => (props.isFullySet ? 'unset' : 'pointer')};
    border-color: ${props => (props.hover ? props.theme.infoColor : COLOR_BORDER_INITIAL)};
    box-shadow: ${props => (props.hover ? '0 0 12px rgba(26, 162, 196, 0.19)' : 'unset')};

    ${CssHoverUtils.getCssForHoverControl()}

    &:last-child {
        margin-right: 30px;
    }

    .${CLASS_NAME_REMOVE_BTN} {

        opacity: ${props => (props.hover ? .8 : 0)};
        color: ${props => props.theme.primaryColor};

        :hover {
            color: ${props => props.theme.processingColor};
            opacity: 1;
        }
    }
`

const UnitsWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ${CssHoverUtils.getCssForHoverControl()}
`

const RemoveButtonWrapperSCP = styled.div`
    position: absolute;
    right: -50px;
    display: flex;
    cursor: pointer;
    height: 50px;
    transition: opacity .3s,color .3s;
    width: 56px;
    justify-content: center;
    ${CssHoverUtils.getCssForHoverControl()}
`

const AddAggregatorBtnContainerSCP = styled.div`
    position: absolute;
    bottom: -23px;
    display: flex;
    width: 70px;
    right: 0;
    justify-content: flex-end;
    ${CssHoverUtils.getCssForHoverControl()}
`
