import React from 'react'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { PdfViewerContentICP } from 'main/common/components/pdf-viewer/inner/PdfViewerContentICP'

interface IPdfViewerCPProps {
    file: any
    paginationType: 'scroll' | 'paginator'
    viewType: 'inline' | 'modal'
    visible?: boolean
    onSetVisibleFalse?: () => void
    onLoadDocument?: () => void
}

/**
 * COMPONENTE: Visualizador de PDF
 */
export function PdfViewerCP(props: IPdfViewerCPProps): JSX.Element | null {

    function getPdfViewerContent(): JSX.Element {
        return (
            <PdfViewerContentICP
                file={props.file}
                paginationType={props.paginationType}
                onLoadDocument={props.onLoadDocument}
            />
        )
    }

    return (
        <>
            {
                props.viewType === 'inline' &&
                getPdfViewerContent()
            }

            {
                props.viewType === 'modal' &&
                <ModalCP
                    visible={props.visible}
                    onClose={props.onSetVisibleFalse}
                    noFooter={true}
                    top={5}
                    width={800}
                >
                    { getPdfViewerContent() }
                </ModalCP>
            }

        </>
    )
}