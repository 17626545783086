import React, { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InputTextAreaCP } from '../input-text-area/InputTextAreaCP'
import { ButtonCPOLD } from 'common/old-refactory/button/ButtonCPOLD'
import { TextCP } from '../text/TextCP'
import CursorPen from '../../../../common/assets/cursor-pen.png'
import { FontAwsomeIconCP } from '../icons/FontAwsomeIconCP'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

interface IInputTextAreaEditableCPProps {
    value?: string
    emptyValueLabel: string
    rows: number
    style?: CSSProperties
    isTextAreaInitialVisible: boolean
    changeTextAreaVisibleCallback?: (visible: boolean) => void // Caso o controle de visibilidade do TEXTO ou TEXT AREA dependa de um estado de fora, passar o callback para controlar
    onConfirm?: (valueEntered: string) => void
}

/**
 * Componente de TextArea Editavel. E exibido um texto e ao clicar transforma em um textArea
 * @param {IInputTextAreaEditableCPProps} props
 *
 * @author renatofs
 */
export function InputTextAreaEditableCP(props: IInputTextAreaEditableCPProps): JSX.Element {

    const [isTextAreaVisible, setIsTextAreaVisible] = useState<boolean>(props.isTextAreaInitialVisible)
    const [textAreaValueEntered, setTextAreaValueEntered] = useState<string>(props.value ? props.value : '')

    useEffect(() => onInitialVisibleChange(), [props.isTextAreaInitialVisible])

    /**
     * Muda a variavel de controle se exibe o TEXTO ou o TEXT AREA
     * @param {boolean} visible
     */
    function changeTextAreaVisible(visible: boolean) {
        setIsTextAreaVisible(visible)

        // Se o controle de visible estiver atrelado a um comportamento externo, chama o callback
        if (props.changeTextAreaVisibleCallback)
            props.changeTextAreaVisibleCallback(visible)
    }

    /**
     * Caso o controle de visibilidade do TEXTO ou TEXT AREA dependa de um estado de fora, altera o estado se mudar o valor de exibicao
     */
    function onInitialVisibleChange() {
        if (props.isTextAreaInitialVisible)
            return

        setIsTextAreaVisible(false)
    }

    /**
     * Ao concluir alteracao e salvar.
     */
    function onConfirm() {
        changeTextAreaVisible(false)

        if (props.onConfirm)
            props.onConfirm(textAreaValueEntered)
    }

    /**
     * Valor formatado. Caso exista o valor, apenas formata para exibir no formato HTML, caso esteja em branco,
     * coloca o texto padrao de adicionar.
     */
    const formattedValue = (
        props.value
            ? props.value.split('\n').map((item) => (
                // eslint-disable-next-line react/jsx-key
                <span>
                    {item}
                    <br/>
                </span>
            ))
            : <TextCP text={props.emptyValueLabel} icon={'plus-circle'}/>
    )

    return (

        <>
            {
                !isTextAreaVisible &&

                <EditableCommentSCP onClick={() => changeTextAreaVisible(true)}>
                    {formattedValue}
                </EditableCommentSCP>
            }

            {
                isTextAreaVisible &&
                <>
                    <InputTextAreaCP
                        rows={props.rows}
                        style={props.style}
                        value={props.value}
                        onChange={setTextAreaValueEntered}
                    />

                    <ButtonSCP>
                        <ButtonCPOLD
                            action={() => onConfirm()}
                            style={{ marginRight: '5px' }}
                            type={'primary'}
                            icon={<FontAwsomeIconCP type={faCheck}/>}
                        />
                        <ButtonCPOLD
                            action={() => changeTextAreaVisible(false)}
                            type={'danger'}
                            icon={<FontAwsomeIconCP type={faTimes}/>}
                        />
                    </ButtonSCP>
                </>
            }
        </>
    )

}

const EditableCommentSCP = styled.div`
    padding: 10px;
    margin-top: 10px;
    &:hover{
        border: 1px #f5f5f5 solid;
        border-radius: 5px;
        transition: 0.3s;
        -webkit-box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        -moz-box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        box-shadow: -6px 7px 13px 1px rgba(224,217,224,0.3);
        cursor: url(${CursorPen}), auto;
    }
`
const ButtonSCP = styled.div`
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn{
        min-height: 26px;
    }
`
