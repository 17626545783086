import React, { useEffect, useState } from 'react'
import { TinyMceCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tiny-mce/TinyMceCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { DrawerSaleContractVarsCP } from 'main/modules/sale/components/drawer-sale-contract-vars/DrawerSaleContractVarsCP'

interface ISaleContractTemplateEditorCPProps {
    onEditorContentChange: (e: any) => void
    franchise: IFranchiseResponseDTO
}

/**
 * Editor de template de contrato de venda
 */
export function SaleContractTemplateEditorCP(props: ISaleContractTemplateEditorCPProps): JSX.Element {

    useEffect(init, [])

    const [logoEditorComponent, setLogoEditorComponent] = useState<string>()
    const [editorInitialContent, setEditorInitialContent] = useState<string>()

    const [isVarsDrawerVisible, setIsVarsDrawerVisible] = useState<boolean>(false)

    /**
     * Inicializa configuracoes do editor e da tela.
     */
    function init(): void {

        // Modelo de componente da logo que podera ser adicionado no editor com um botao de atalho
        // Verifica se o sistema possui logo definida, para entao criar o atalho de adicionar a logo
        let _logoEditorComponent

        if (AppStateUtils.getDomainData()?.schema.logoUrl) {
            _logoEditorComponent = `
                <div style="text-align: center">
                    <img src="${AppStateUtils.getDomainData()!.schema.logoUrl!}" width="200"/>
                </div>
            `
            setLogoEditorComponent(_logoEditorComponent)
        }

        // Verifica se ja tem modelo de contrato definido. Se ja tiver apenas carrega
        if (props.franchise.htmlContractTemplate) {
            setEditorInitialContent(props.franchise.htmlContractTemplate)
            return
        }

        // Se tiver logo e o modelo não existe ainda, inicializa o conteudo com a logo
        if (_logoEditorComponent)
            setEditorInitialContent(_logoEditorComponent)

    }

    return (
        <>
            <TinyMceCP
                onEditorContentChange={props.onEditorContentChange}
                initialEditorContent={editorInitialContent}
                onSetup={function(editor) {

                    editor.ui.registry.addButton('showVars', {
                        text: 'VAR',
                        onAction() {
                            setIsVarsDrawerVisible(true)
                        }
                    })

                    editor.ui.registry.addButton('addLogo', {
                        icon: 'edit-image',
                        onAction() {
                            editor.insertContent(logoEditorComponent)
                        }
                    })
                }}
            />

            <DrawerSaleContractVarsCP
                onClose={() => setIsVarsDrawerVisible(false)}
                visible={isVarsDrawerVisible}
            />
        </>
    )
}
