export enum MarketingChannelEnum {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    WHATSAPP = 'WHATSAPP',
}

export enum MarketingChannelLabelEnum {
    EMAIL = 'E-mail',
    SMS = 'SMS',
    WHATSAPP = 'Whatsapp',
}
