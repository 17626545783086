import React from 'react'
import { FiltersFranchiseDashboardCP } from 'main/modules/dashboard/components/filters-franchise-dashboard/FiltersFranchiseDashboardCP'
import { WidgetsRowAttendancePerformanceICP } from 'main/modules/dashboard/components/widgets-row-attendance-performance/WidgetsRowAttendancePerformanceICP'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    dateFilters?: IDateRangeFilter
    onDateRangeChange: (dateFilters: IDateRangeFilter) => void
    franchiseCodes?: number[]
}

/**
 * Aba de qualidade tecnica.
 */
export function FranchiseManagerQualityTabICP(props: ICPProps): JSX.Element {

    return (
        <>
            <FiltersFranchiseDashboardCP dateFilters={props.dateFilters} onDateRangeChange={props.onDateRangeChange}/>
            <WidgetsRowAttendancePerformanceICP franchiseCodes={props.franchiseCodes} dateFilters={props.dateFilters}/>
        </>
    )
}
