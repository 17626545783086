import { IFormItemCommonProps } from 'main/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IMarketingDomainResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingDomainResponseDTO'
import { MarketingDomainStatusEnum } from 'main/modules/marketing/enums/MarketingDomainStatusEnum'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

interface ISelectDomainCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {}

/**
 * Select de Dominios
 *
 * @author renatofs
 */
export function SelectDomainCP(props: ISelectDomainCPProps): JSX.Element {

    useEffect(() => { defineDomains() }, [])

    const [domainSelectList, setDomainSelectList] = useState<SelectOptionTP[]>([])

    const getDomainsRequest = useRequest<IGenericListResponseDTO<IMarketingDomainResponseDTO>>()
    useEffect(onGetDomainsRequestChange, [getDomainsRequest.isAwaiting])

    /**
     * Busca dominios na api
     */
    async function defineDomains(): Promise<void> {
        getDomainsRequest.runRequest(await MarketingRequests.getDomainsReqConfig({ status: MarketingDomainStatusEnum.VALID }))
    }

    /**
     * Retorno da requisicao que busca dominios.
     */
    function onGetDomainsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getDomainsRequest, 'Não foi possível obter a lista de dominios'))
            return

        setDomainSelectList(
            getDomainsRequest.responseData!.list.map((domain, i) => ({
                key: `key-sender-select-${i}`,
                value: domain.code,
                label: `${domain.name}`
            }))
        )

    }

    return (
        <SelectCP
            label={'Domínio'}
            options={domainSelectList}
            loading={getDomainsRequest.isAwaiting}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}
