/**
 * status de um agendamento
*/
export enum OpportunityScheduleTypeEnum {
    IN_PERSON = 'IN_PERSON',
    REMOTE = 'REMOTE',
}

export enum OpportunityScheduleTypeLabelEnum {
    IN_PERSON = 'Avaliação Presencial',
    REMOTE = 'Avaliação Remota',
}
