import { OrUndefTP } from 'main/common/types/OrUndefTP'
import { PersonTableCP } from 'main/modules/people/components/person-table/PersonTableCP'
import { PersonContext } from 'main/modules/people/contexts/person-context/PersonContext'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React from 'react'

/**
 * COMPONENTE
 * Conteúdo principal da tela de listagem de pessoas -> Listagem de pessoas.
 *
 * @see PersonListScreenContentCP
 */
export function ListContentICP(): JSX.Element | null {

    const screenContext = PersonContext.use()
    const segmentType: OrUndefTP<SegmentTypeEnum> = screenContext.state.segment?.type ?? SegmentUtils.getDefaultSegmentType()

    function onSelectPerson(_selectedPersonCode?: number): void {
        if (!_selectedPersonCode)
            return
        screenContext.actions.setSelectedPersonCode(_selectedPersonCode)
    }

    function onLoadingStateChange(isLoading: boolean): void {
        screenContext.actions.setIsLoadingPeople(isLoading)
        if (!isLoading)
            screenContext.actions.setMustUpdatePeopleList(false)
    }

    if (!segmentType)
        return null

    return (
        <PersonTableCP
            segmentType={segmentType}
            mustUpdate={screenContext.state.mustUpdatePeopleList}
            segmentExpression={screenContext.state.expression}
            onSelectPerson={onSelectPerson}
            onLoadingStateChange={onLoadingStateChange}
            openPersonDetailsDrawer={(): void => screenContext.actions.setShowPersonDetailsDrawer(true)}
            openAddJobFunctionModal={(): void => screenContext.actions.setShowAddJobFunctionModal(true)}
        />
    )
}
