import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { MathUtils } from 'main/common/utils/MathUtils'
import { TagSessionStatusCP } from 'main/modules/scheduler/components/tag-session-status/TagSessionStatusCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import moment from 'moment'
import React from 'react'
import _ from 'lodash'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ColumnTreatmentNameAppointmentDetailsTableICP } from 'main/modules/scheduler/components/appointments-details-table/columns/ColumnTreatmentNameAppointmentDetailsTableICP'

/**
 * UTILITARIOS do componente de listagem de sessoes de um appointment
 */
export const AppointmentDetailsTableUtils = {

    getDetailsTableColumnsConfig(
        onAnswerSession: (session: ISessionResponseDTO) => void,
        onCancelSession: (code: number, rowIndex: number) => void,
        onShowSale: (saleCode: number) => void,
        hideAnswerAction?: boolean
    ): Array<ColumnProps<ISessionResponseDTO>> {

        return [
            {
                key: 'status',
                align: 'center',
                render: (text, session) => <TagSessionStatusCP status={session.status}/>,
            },
            {
                title: 'Serviço',
                key: 'title',
                render: (text, session) => (
                    <ColumnTreatmentNameAppointmentDetailsTableICP
                        sale={{
                            code: session.saleCode,
                            status: session.saleStatus,
                        }}
                        treatmentName={session.treatment.name}
                        onSaleClick={() => onShowSale(session.saleCode)}
                    />
                )
            },
            {
                key: 'prog',
                render: (text, session) => (
                    <ProgressCP
                        width={50}
                        percent={MathUtils.getPercentage(session.sessionNumber, session.totalSessions)}
                        tooltip={`${session.sessionNumber} de ${session.totalSessions} sessões`}
                        showInfo={false}
                    />
                )
            },
            {
                title: 'Horário',
                width: '150px',
                key: 'startTime',
                render: (text, session) => {
                    if (!session.schedule)
                        return <TextCP text={'Não agendada'}/>
                    return (
                        <>
                            <TextCP wrap={true} text={`${moment(session.schedule.beginDate).format(DateFormatEnum.TIME_H_M)} às ${moment(session.schedule.endDate).format(DateFormatEnum.TIME_H_M)}`}/>
                            <TextCP wrap={true} text={`(${session.sessionDuration!}m)`}/>
                        </>
                    )
                },
            },
            {
                title: '',
                key: 'actions',
                align: 'right',
                render: (text, session, rowIndex) => {
                    return (
                        <FlexCP justifyContent={'flex-end'}>
                            {
                                !hideAnswerAction && ([SessionStatusEnum.SCHEDULED, SessionStatusEnum.CONCLUDED, SessionStatusEnum.IN_PROGRESS, SessionStatusEnum.CHECK_IN_DONE].includes(session.status)) &&
                                <ButtonCP
                                    size={'small'}
                                    type={'primary'}
                                    onClick={() => onAnswerSession(session)}
                                    icon={session.status === SessionStatusEnum.CONCLUDED ? 'edit' : 'form'}
                                />
                            }
                            <ButtonCP
                                size={'small'}
                                type={'danger'}
                                onClick={() => onCancelSession(session.code, rowIndex)}
                                icon={'delete'}
                                confirmMsg={
                                    session.status === SessionStatusEnum.CONCLUDED
                                        ? 'Essa sessão já foi evoluída, você tem certeza que deseja remover a evolução e cancelar o agendamento?'
                                        : 'Você tem certeza que deseja cancelar o agendamento dessa sessão?'
                                }
                                marginLeft={5}
                            />
                        </FlexCP>
                    )
                }
            }
        ]
    },

    getCreateTableColumnsConfig(
        onRemoveTreatment: (treatment: ISessionResponseDTO) => void,
        showResourceColumn?: boolean,
        showTimeColumn?: boolean,
        showDurationColumn?: boolean,
        showActionsColumn?: boolean,
    ): Array<ColumnProps<ISessionResponseDTO>> {

        const columns: Array<ColumnProps<ISessionResponseDTO>> = []

        columns.push({
            title: 'Serviço',
            key: 'title',
            render: (text, session) => (
                <ColumnTreatmentNameAppointmentDetailsTableICP
                    sale={{
                        code: session.saleCode,
                        status: session.saleStatus,
                    }}
                    treatmentName={session.treatment.name}
                />
            )
        })

        if (!!showResourceColumn) {
            columns.push({
                title: 'Recurso',
                dataIndex: 'resources',
                key: 'resources',
                render: (text, session) => {
                    if (_.isEmpty(session.resources))
                        return <TextCP text={'-'}/>
                    return session.resources!.map((resource) => <TextCP key={`resource-${resource.code}`} text={resource.name}/>)
                },
            })
        }

        if (!!showTimeColumn) {
            columns.push({
                title: 'Horário',
                dataIndex: 'timeRange',
                align: 'center',
                key: 'timeRange',
            })
        }

        if (!!showDurationColumn) {
            columns.push({
                title: 'Dur.',
                key: 'sessionDuration',
                dataIndex: 'sessionDuration',
                align: 'center',
                render: (text, session) => <TextCP text={`${session.sessionDuration!} min`}/>,
            })
        }

        if (!!showActionsColumn) {
            columns.push({
                title: '',
                key: 'action',
                align: 'center',
                render: (session) => {
                    return (
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                type={'danger'}
                                icon={'delete'}
                                size={'small'}
                                onClick={() => onRemoveTreatment(session)}
                            />
                        </FlexCP>
                    )
                },
            })
        }

        return columns
    }

}
