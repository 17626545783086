import { SelectOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { NeritPaymentsRequests } from 'main/modules/financial/services/nerit-payments/NeritPaymentsRequests'
import { ICreditCardResponseDTO } from 'main/modules/financial/services/nerit-payments/dtos/response/ICreditCardResponseDTO'
import { IFormModelValidator } from 'main/common/interfaces/IFormModelValidator'

interface ISelectCreditCardFlagCPProps<FModelTP extends IFormModelValidator> {
    formStateManager: IFormStateManager<FModelTP>
}

/**
 * COMPONENTE Seletor de bancos - List todos bancos cadastrados no NeritPayments
 */
export function SelectCreditCardFlagCP<FModelTP extends IFormModelValidator>(props: ISelectCreditCardFlagCPProps<FModelTP>): JSX.Element {

    useEffect(() => { init() }, [])

    const [bankOptions, setBankOptions] = useState<SelectOptionTP[]>([])
    const request = useRequest<IGenericListResponseDTO<ICreditCardResponseDTO>>()
    useEffect(onRequestChange, [request.isAwaiting])

    /**
     * Busca bancos
     */
    async function init(): Promise<void> {
        request.runRequest(await NeritPaymentsRequests.searchCreditCardFlags())
    }

    /**
     * Retorno da API que busca bancos.
     */
    function onRequestChange(): void{

        if (!RequestUtils.isValidRequestReturn(request, 'Ops! Erro ao buscar bandeiras de cartao de credito'))
            return

        const options = request.responseData!.list.map(ccFlag => ({
            value: ccFlag.id,
            key: `key-${ccFlag.id}`,
            label: ccFlag.name
        }))
        setBankOptions(options)
    }

    return (
        <SelectCP
            label={'Selecione a bandeira'}
            formStateManager={props.formStateManager}
            fieldName={'bankCode'}
            options={bankOptions}
            required={true}
            loading={request.isAwaiting}
        />
    )
}