import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { MktAccountTableUtils } from 'main/modules/marketing/components/mkt-account-table/inner/MktAccountTableUtils'
import { IMarketingAccountSearchRequestDTO } from 'main/modules/marketing/services/marketing/dtos/requests/IMarketingAccountSearchRequestDTO'
import { IMarketingAccountResponseDTO } from 'main/modules/marketing/services/marketing/dtos/responses/IMarketingAccountResponseDTO'
import { MarketingRequests } from 'main/modules/marketing/services/marketing/MarketingRequests'
import React, { useEffect, useState } from 'react'

/**
 * COMPONENTE da listagem das contas de marketing.
 *
 * @author renatofs
 */
export function MktAccountTableCP(): JSX.Element | null {

    useEffect(() => { defineAccounts() }, [])

    const [accounts, setAccounts] = useState<IMarketingAccountResponseDTO[]>()
    const getAccountsRequest = useRequest<IGenericListResponseDTO<IMarketingAccountResponseDTO>>()
    useEffect(onGetAccountsRequestChange, [getAccountsRequest.isAwaiting])

    /**
     * Faz a requisicao para buscar contas
     */
    async function defineAccounts(): Promise<void> {

        const filterDto: IMarketingAccountSearchRequestDTO = {
            page: 1,
            itemsPerPage: 20,
        }

        getAccountsRequest.runRequest(await MarketingRequests.searchAccount(filterDto))
    }

    /**
     * Retorno da requisicao que busca contas.
     */
    function onGetAccountsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getAccountsRequest, 'Não foi possível obter contas de marketing'))
            return

        setAccounts(getAccountsRequest.responseData!.list)
    }

    return (
        <TableCPOLD2
            columns={MktAccountTableUtils.getColumnsConfig()}
            data={accounts}
            loading={getAccountsRequest.isAwaiting}
            showLoadingAsSkeleton={true}
            showHeader={true}
        />
    )
}
