import React, { useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { LogoCP } from 'main/common/components/logo/LogoCP'
import { useParams } from 'react-router'
import { ScreenContentUpdateChargePaymentData } from 'main/modules/financial/screens/screen-update-charge-payment-data/inner/ScreenContentUpdateChargePaymentData'
import { CustomerPortalUtils } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/utils/CustomerPortalUtils'
import { AuthActions } from 'main/modules/auth/AuthActions'

/**
 * Tela para anexo de documentos em um exame.
 */
export function ScreenUpdateChargePaymentData(): JSX.Element {

    const routeParams = useParams<{ token: string }>()
    const token = CustomerPortalUtils.decryptChangePaymentDataToken(routeParams.token)

    const [showResult, setShowResult] = useState<boolean>(false)

    if (!token)
        return <ScreenResultCP status={'500'} title={'Parametrização inválida'}/>

    AuthActions.refreshLoginData(token.userBearerToken)

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={`Dados de Pagamento`}
                    bgColor={ThemeProject.headerBackgroundColor}
                    color={ThemeProject.checkedColor}
                />
            }
            content={
                <>
                    {
                        showResult
                            ?
                            <ScreenResultCP
                                status={'success'}
                                title={'Dados alterados com sucesso'}
                            />
                            :
                            <ScreenContentUpdateChargePaymentData
                                onSave={() => setShowResult(true)}
                                chargeCode={token.chargeCode}
                            />
                    }
                </>
            }
            footer={
                <FlexCP justifyContent={'center'} margin={{ top: 50 }}>
                    <LogoCP
                        marginBottom={35}
                        imageUrl={AppStateUtils.getDomainData()?.schema?.logoUrl ?? `${process.env.PUBLIC_URL}/logos/logo-sistema.png`}
                        width={'150px'}
                    />
                </FlexCP>
            }
            backgroundColor={'#FFF'}
        />
    )
}
