import { AutocompletePickerCP } from 'main/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { OrArrayTP } from 'main/common/types/OrArrayTP'
import { ProductReleaseRequests } from 'main/modules/products/services/product-release/ProductReleaseRequests'
import React, { useEffect, useState } from 'react'
import { IReleaseNameResponseDTO } from '../../services/dtos/response/IReleaseNameResponseDTO'

interface IProductAutocompletePickerCPProps<FModelTP extends FormModel = any>
    extends Omit<IAutocompleteCommonProps<IReleaseNameResponseDTO, FModelTP>, 'label'> {}

/**
 * COMPONENTE
 * Seletor de produtos com autocomplete.
 */
export function ProductReleaseAutocompletePickerCP<FModelTP extends FormModel = any>(props: IProductAutocompletePickerCPProps<FModelTP>): JSX.Element {

    const [initialOptions, setInitialOptions] = useState<IReleaseNameResponseDTO[]>()
    const [value, setValue] = useState<OrArrayTP<number>>()
    const [shouldClearSearchStr, setShouldClearSearchStr] = useState<boolean>(false)

    useEffect(() => setInitialOptions(props.initialOptions), [props.initialOptions])
    useEffect(() => setValue(props.value), [props.value])

    return (
        <AutocompletePickerCP<IReleaseNameResponseDTO, FModelTP>
            label={'Produtos'}
            requestConfigGetter={ProductReleaseRequests.searchNames}
            initialOptions={initialOptions}
            returnFullOption={props.returnFullOption}
            onChange={props.onChange}
            isMultiple={props.isMultiple}
            value={value}
            searchItems={props.searchItems}
            searchPage={props.searchPage}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            onFormSubmit={props.onFormSubmit}
            fontSize={props.fontSize}
            disabled={props.disabled}
            mustClearSearch={shouldClearSearchStr}
            onSearchCleared={() => setShouldClearSearchStr(false)}
        />
    )
}
