import React from 'react'
import { LogicOperatorICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/logic-operator/LogicOperatorICP'
import { UnitICP } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitICP'
import { SegmentAggUnitForEditionTP } from 'main/modules/segment/types/aggregator/SegmentAggUnitForEditionTP'
import { SegmentEditableItemTP } from 'main/modules/segment/types/generic/SegmentEditableItemTP'
import { SegmentUnitForEditionTP } from 'main/modules/segment/types/unit/SegmentUnitForEditionTP'
import { ExpressionContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/expression/ExpressionContext'
import { AggregatorContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/aggregator/AggregatorContext'
import { UnitContext } from 'main/modules/segment/components/segment-expression-bar/inner/components/unit/UnitContext'
import { ArrayUtils } from 'main/common/utils/ArrayUtils'

interface IAggregatorUnitICPPProps {
    i: number
    unit: SegmentEditableItemTP<SegmentAggUnitForEditionTP>
    onChangeUnitValuePopoverVisibility: () => void
}

/**
 * COMPONENTE
 * Item de 01 lista de unidades dentro de 01 agregador numa lista de segmentacao.
 */
export function AggregatorUnitICP(props: IAggregatorUnitICPPProps): JSX.Element {

    const expressionContext = ExpressionContext.useState()
    const aggregatorContext = AggregatorContext.use()

    if (props.unit.itemType === 'op') {
        return (
            <LogicOperatorICP
                onChange={_operator => aggregatorContext.actions.editUnit(_operator)}
                operator={props.unit}
                disabled={expressionContext.state.isLoading}
                horizontalMargin={5}
            />
        )
    }

    return (
        <UnitContext.Provider>
            <UnitICP
                id={props.unit.id}
                isLastUnit={props.i === ArrayUtils.getLastIndex(aggregatorContext.state.units)}
                field={(props.unit as SegmentUnitForEditionTP).field}
                operator={(props.unit as SegmentUnitForEditionTP).operator}
                value={(props.unit as SegmentUnitForEditionTP).value}
                onChangePopoverVisibility={props.onChangeUnitValuePopoverVisibility}
            />
        </UnitContext.Provider>
    )
}
