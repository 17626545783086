import { OpportunityTypeEnum } from 'main/modules/sales-funnel/enums/OpportunityTypeEnum'
import React, { useEffect } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { OpportunityStatusEnum } from 'main/modules/sales-funnel/enums/OpportunityStatusEnum'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { OpportunityDrawerActionTP } from 'main/modules/sales-funnel/components/drawer-opportunity-details/inner/OpportunityDrawerActionTP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { ButtonArchiveOpportunityCP } from 'main/modules/sales-funnel/components/button-archive-opportunity/ButtonArchiveOpportunityCP'

interface IOpportunityDetailsDrawerButtonsICPProps {
    opportunity: IOpportunityResponseDTO
    onArchiveOpportunity: () => void
    onMoveOpportunityToFollowup: () => void
    onRollbackOpportunity: () => void
    onSaleDetailsClick: () => void
    onConversionActionClick: (action: OpportunityDrawerActionTP) => void
}

/**
 * Componente wrapper para conteúdo do drawer detalhes da oportunidade
 */
export function OpportunityDetailsDrawerButtonsICP(props: IOpportunityDetailsDrawerButtonsICPProps): JSX.Element {

    const isOpportunityOpen = props.opportunity.status === OpportunityStatusEnum.OPEN
    const isOpportunityLead = props.opportunity.type === OpportunityTypeEnum.LEAD

    const unarchiveOpportunityRequest = useRequest<void>('none')
    useEffect(unarchiveOpportunityRequestChange, [unarchiveOpportunityRequest.isAwaiting])

    const changeToFollowupRequest = useRequest<string>()
    useEffect(changeToFollowupRequestChange, [changeToFollowupRequest.isAwaiting])

    const rollbackOpportunityTypeRequest = useRequest<string>()
    useEffect(rollbackOpportunityTypeRequestChange, [rollbackOpportunityTypeRequest.isAwaiting])

    /**
     * DesArquiva uma oportunidade.
     */
    function unarchiveOpportunity(): void {
        unarchiveOpportunityRequest.runRequest(OpportunityRequests.unarchiveOpportunity(props.opportunity.code))
    }

    /**
     * Retorno da api ao dearquivar uma oportunidade.
     */
    function unarchiveOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(unarchiveOpportunityRequest, 'Erro ao tentar arquivar oportunidade.', true))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade reaberta com sucesso')
        props.onRollbackOpportunity()
    }

    /**
     * Muda oportunidade para followup.
     */
    function moveOpportunityToFollowup(): void {
        changeToFollowupRequest.runRequest(OpportunityRequests.changeToFollowUp(props.opportunity.code))
    }

    /**
     * Retorno da api ao mudar uma oportunidade para followup.
     */
    function changeToFollowupRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changeToFollowupRequest, 'Erro ao tentar arquivar oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade já está no funil de followup, prossiga e não perca essa venda')
        props.onMoveOpportunityToFollowup()
    }

    /**
     * Volta a oportunidade para etapa (tipo) anterior.
     */
    function rollbackOpportunityType(): void {
        rollbackOpportunityTypeRequest.runRequest(OpportunityRequests.rollbackOpportunityType(props.opportunity.code))
    }

    /**
     * Retorno da api que volta o tipo da oportunidade.
     */
    function rollbackOpportunityTypeRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(rollbackOpportunityTypeRequest, 'Erro ao tentar mudar etapa da oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Mudamos a etapa da sua oportunidade')
        props.onRollbackOpportunity()
    }

    return (
        <FlexCP justifyContent={'space-between'} marginBottom={30}>
            <LeftButtonsSCP>
                <ConditionalRenderCP shouldRender={!isOpportunityOpen}>
                    <ButtonCP
                        icon={'folder-open'}
                        onClick={unarchiveOpportunity}
                        loading={unarchiveOpportunityRequest.isAwaiting}
                        confirmMsg={'Tem certeza que deseja abrir essa oportunidade novamente, ela será desarquivada?'}
                        marginRight={5}
                    >
                        Reabrir oportunidade
                    </ButtonCP>
                </ConditionalRenderCP>
                <ConditionalRenderCP shouldRender={isOpportunityOpen}>
                    <FlexCP>
                        <ButtonArchiveOpportunityCP
                            opportunityCode={props.opportunity.code}
                            onArchive={props.onArchiveOpportunity}
                            buttonType={'ghost'}
                            showIcon={true}
                            marginRight={5}
                        />

                        <ConditionalRenderCP shouldRender={props.opportunity.type !== OpportunityTypeEnum.LEAD}>
                            <ButtonCP
                                type={'ghost'}
                                icon={'rollback'}
                                onClick={rollbackOpportunityType}
                                loading={rollbackOpportunityTypeRequest.isAwaiting}
                                confirmMsg={'Tem certeza que deseja voltar uma etapa dessa oportuinidade?'}
                                tooltip={`Voltar oportunidade para ${props.opportunity.type === OpportunityTypeEnum.EVALUATION ? 'Lead' : 'Avaliação'}`}
                                marginRight={5}
                            />
                        </ConditionalRenderCP>
                    </FlexCP>
                </ConditionalRenderCP>
            </LeftButtonsSCP>

            <RightButtonsSCP>
                <FlexCP>
                    {
                        isOpportunityOpen &&
                        <>
                            {
                                isOpportunityLead &&
                                <ButtonCP
                                    onClick={() => props.onConversionActionClick('evaluation')}
                                    type={'primary'}
                                    icon={'calendar'}
                                    marginRight={5}
                                >
                                    Agendar avaliação
                                </ButtonCP>
                            }
                            {
                                props.opportunity.type === OpportunityTypeEnum.EVALUATION &&
                                <ButtonCP
                                    onClick={moveOpportunityToFollowup}
                                    type={'primary'}
                                    icon={'filter'}
                                    marginRight={5}
                                    confirmMsg={'Você tem certeza que deseja mover essa oportunidade para o funil de followup?'}
                                    loading={changeToFollowupRequest.isAwaiting}
                                >
                                    Mover para Follow-up
                                </ButtonCP>
                            }
                            {
                                !isOpportunityLead &&
                                <ButtonCP
                                    onClick={() => props.onConversionActionClick('sale')}
                                    type={'primary'}
                                    icon={'shopping-cart'}
                                >
                                    Gerar venda
                                </ButtonCP>
                            }
                        </>
                    }

                    {
                        !!props.opportunity.saleCode &&
                        <ButtonCP
                            onClick={props.onSaleDetailsClick}
                            type={'primary'}
                            marginLeft={5}
                        >
                            Detalhes da venda
                        </ButtonCP>
                    }
                </FlexCP>
            </RightButtonsSCP>
        </FlexCP>
    )

}

const LeftButtonsSCP = styled.div``

const RightButtonsSCP = styled.div``
