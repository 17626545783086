import { DragAndDropItemTP } from 'main/common/components/drag-and-drop-list/inner/types/DragAndDropItemTP'

export const DragAndDropListUtils = {

    getReorderedList<DnDListTP>(currentList: Array<DragAndDropItemTP<DnDListTP>>, sourceIndex: number, destinationIndex: number): Array<DragAndDropItemTP<DnDListTP>> {

        const result = Array.from(currentList)
        const [removed] = result.splice(sourceIndex, 1)
        result.splice(destinationIndex, 0, removed)

        return result

        // let newOrder = 1
        //
        // const listResult = [...currentList]
        //
        // // remove um elemento do índice "sourceIndex" no array listResult
        // const [removed] = listResult.splice(sourceIndex, 1)
        //
        // // coloca o elemento removido na posição 0 do array listResult no índice "destinationIndex"
        // listResult.splice(destinationIndex, 0, removed)
        //
        // listResult.map(item => {
        //     item.order = newOrder
        //     newOrder++
        // })
        //
        // return listResult
    }
}